import React from "react";

import FalconCardHeader from "../../../../../../components/common/FalconCardHeader";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

// import { Formik, Form, Field, ErrorMessage } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { Row, Col, Form } from "react-bootstrap";

import { v1 as uuidv1, v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import Loader from "../../../../../../components/common/Loader";
import {duration} from "moment";
import { CalendarToday } from "@mui/icons-material";

import DatePicker from "react-datepicker";

export default function UnavailableDays({
  amenityCurrent,
  updateAmenityUnavailableDays,
}) {
  const { unavailabledates, unavailable_range_dates } = amenityCurrent;

  const [loader, setLoader] = React.useState(false);
  const [fields, setFields] = React.useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let arr = [];

    fields?.forEach((v) => {
      // const startDate = new Date(v.start_date_value);
      // const endDate = new Date(v.end_date_value);
      const startDate = v.start_date_value;
      const endDate = v.end_date_value;
      console.log(startDate);
      console.log(endDate);

      const durationn = duration(endDate - startDate);
      console.log(durationn);
      const numDays = duration.asDays();
      console.log(numDays);
      // Add each date in the range to the 'arr' array
      for (let i = 0; i <= numDays; i++) {
        const currentDate = new Date(
          startDate.getTime() + i * 24 * 60 * 60 * 1000
        );
        if (!arr.includes(currentDate)) {
          arr.push(currentDate);
        }
      }
    });

    arr = arr.map((v) => v.toISOString());

    console.log(arr);
    // return;
    updateAmenityUnavailableDays(amenityCurrent, arr, [...fields]);
  };

  React.useEffect(() => {
    let arr = [...unavailable_range_dates];
    arr = arr?.map((v) => {
      let obj = {};
      obj.start_date_value = new Date(v.start_date_value);
      obj.entry_id = v.entry_id;
      obj.end_date_value = new Date(v.end_date_value);
      return obj;
    });
    console.log(arr);
    setFields(arr);
  }, []);

  const addEntry = () => {
    setLoader(true);
    let obj = {};
    obj.entry_id = uuidv4();
    obj.start_date_value = new Date();
    obj.start_date_value.setHours(0);
    obj.start_date_value.setMinutes(0);
    obj.start_date_value.setSeconds(0);
    obj.start_date_value.setMilliseconds(0);

    obj.end_date_value = new Date();
    obj.end_date_value.setHours(0);
    obj.end_date_value.setMinutes(0);
    obj.end_date_value.setSeconds(0);
    obj.end_date_value.setMilliseconds(0);
    console.log(obj);
    setFields(fields.concat(obj));
    // setFields(fields.concat(obj));
    toast.success("entry added.", { theme: "colored" });
    setLoader(false);
  };

  const handleChange = (date, entry_id, fromEndDate = false) => {
    setLoader(true);
    console.log(entry_id);
    // console.log(e.target.name);
    // console.log(e.target.value);

    let arr = [...fields];
    console.log(arr);
    let single = arr.filter((v) => v.entry_id === entry_id)[0];
    console.log(single);

    let index = 0;
    arr.forEach((v, i) => {
      if (v.entry_id === entry_id) index = i;
    });

    if (single.entry_id === entry_id) {
      if (fromEndDate) {
        console.log("from end");
        // console.log(e.target.value);
        single.end_date_value = date;
      } else {
        // console.log(e.target.value);
        single.start_date_value = date;
      }
    }
    arr[index] = single;
    setFields([...arr]);
    setLoader(false);
  };

  const removeEntry = (entry_id) => {
    // console.log(entry_id + " " + "remove");

    let arr = [...fields];
    arr = arr.filter((v) => v.entry_id !== entry_id);
    setFields([...arr]);
    toast.warning("entry removed!", { theme: "colored" });
  };

  const ref1 = React.useRef(null);

  const handeRef1 = () => {
    ref1.current?.setFocus();
  };
  const ref2 = React.useRef(null);

  const handeRef2 = () => {
    ref2.current?.setFocus();
  };

  return (
    <div>
      <Card>
        <FalconCardHeader title="Unavailable Days" />
        <Card.Body className="bg-light">
          {loader && <Loader noHeight />}

          {!loader && (
            <form onSubmit={handleSubmit}>
              {fields?.map((v, i) => (
                <Row className="mb-3" key={v.entry_id}>
                  <Col md={5}>
                    <Form.Group>
                      <Form.Label>Start Date</Form.Label>

                      <div className="position-relative">
                        <DatePicker
                          ref={ref1}
                          placeholderText="Start Date here"
                          // selected={
                          //   v.start_date_value === ""
                          //     ? new Date()
                          //     : v.start_date_value
                          // }
                          selected={v.start_date_value}
                          onChange={(date) => handleChange(date, v.entry_id)}
                          className="form-control"
                          minDate={new Date()}
                        />
                        <span
                          className="position-absolute translate-middle top-50 end-0 cursor-pointer"
                          onClick={handeRef1}
                        >
                          <CalendarToday fontSize="small" />
                        </span>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={5}>
                    <Form.Group>
                      <Form.Label>End Date</Form.Label>

                      <div className="position-relative">
                        <DatePicker
                          ref={ref2}
                          placeholderText="End Date here"
                          selected={v.end_date_value}
                          // selected={
                          //   v.end_date_value === ""
                          //     ? new Date()
                          //     : v.end_date_value
                          // }

                          onChange={(date) =>
                            handleChange(date, v.entry_id, true)
                          }
                          className="form-control"
                          minDate={v.start_date_value || new Date()}
                        />
                        <span
                          className="position-absolute translate-middle top-50 end-0 cursor-pointer"
                          onClick={handeRef2}
                        >
                          <CalendarToday fontSize="small" />
                        </span>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col
                    md={2}
                    className=" d-flex justify-content-center align-items-end"
                  >
                    <span
                      className="bg-white rounded-3 cursor-pointer border border-3"
                      onClick={() => removeEntry(v.entry_id)}
                    >
                      <CloseIcon color="error" />
                    </span>
                  </Col>
                </Row>
              ))}

              <div className={`${fields.length > 0 ? "mt-5" : "mt-3"} mb-3`}>
                <Button variant="falcon-primary" onClick={addEntry}>
                  + Add Entry
                </Button>
                <Button
                  variant="info"
                  className="float-end"
                  type="submit"
                  // disabled={!fields.length}
                >
                  Update
                </Button>
              </div>
            </form>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}
