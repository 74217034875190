import axios from "axios";
import { startCase } from "lodash";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

export default function EConsentModal({
  show,
  handleClose,
  eConsentData,
  noAdmin = false,
  userData = {},
  jwtToken,
  hostname,
}) {
  // const cookies = new Cookies();
  // let userData = cookies.get("userData");

  const { property_name } = useParams();

  console.log(eConsentData);
  console.log(userData);

  function useWindowDimensions() {
    const hasWindow = typeof window !== "undefined";

    function getWindowDimensions() {
      const width = hasWindow ? window.innerWidth : null;
      const height = hasWindow ? window.innerHeight : null;
      return {
        width,
        height,
      };
    }

    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    useEffect(() => {
      if (hasWindow) {
        handleResize();

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }
    }, [hasWindow]);

    return windowDimensions;
  }

  const { width } = useWindowDimensions();
  //console.log(width);
  let accepted_by = userData.data;
  let user_id = "";
  if (userData.role === "Site-Administrators") {
    user_id = userData.data;
    accepted_by = userData.data;
  }

  const acceptEconsentAgreement = () => {
    axios
      .post(
        `${hostname}/api/property/AcceptEConsent`,
        {
          e_id: eConsentData?._id,
          accepted_by: userData?.data,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.success(res.data.message || "Succesfully Accepted", {
          theme: "colored",
        });
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      });
  };

  const ignoreEconsentAgreement = () => {
    axios
      .post(
        `${hostname}/api/property/IgnoreEConsent`,
        {
          e_id: eConsentData?._id,
          accepted_by: userData?.data,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.info(res.data.message || "Succesfully Ignored", {
          theme: "colored",
        });
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      style={{ marginTop: "45px" }}
      size={width > 576 && "lg"}
    >
      <Modal.Header closeButton>
        <Modal.Title>E-Consent</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <h5 className="fw-bold mb-3">
          Agreement to Receives Notices Electronically
        </h5>
        <p className="lead">
          Agreement by owner or tenant to receive notices from the corporation
          by electronic delivery.
        </p>
        <Form.Group className="mb-3">
          <Form.Label>Owner's or Tenant's name</Form.Label>
          <Form.Control
            value={
              `${startCase(userData?.firstname)} ${startCase(
                userData?.lastname
              )}` || "user name here"
            }
            disabled
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Condominium corporation's name</Form.Label>
          {!noAdmin && (
            <Form.Control
              value={startCase(userData?.property_name) || "corp name here"}
              disabled
            />
          )}
          {noAdmin &&
            (userData.role === "Site-Administrators" ||
              userData.role === "Condozak-Basic-Users") && (
              <Form.Control
                value={startCase(property_name) || "corp name here"}
                disabled
              />
            )}
          {noAdmin && (userData.role === "unit_owner" || userData.role === "supp_resident" || userData.role === "supp_tenant") && (
            <Form.Control
              value={startCase(userData?.property_name) || "corp name here"}
              disabled
            />
          )}
        </Form.Group>
        <p className="lead">
          The corporation will use the email method to deliver notices to me:
        </p>
        <Form.Group className="mb-4">
          <Form.Label>Email Address</Form.Label>
          <Form.Control value={userData?.email || "email here"} disabled />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Check
            type={"checkbox"}
            id={`e-consent-check`}
            label={
              <p className="ms-3">
                I agree that I am sufficiently served, as described in section
                54 of the Condominium Act, 1998. If the corporation uses the
                method of delivering notices identified in this agreement.
              </p>
            }
            defaultChecked
            disabled
          />
        </Form.Group>
        {!noAdmin && (
          <div className="text-end">
            <Button
              variant="secondary"
              size="md"
              onClick={() => ignoreEconsentAgreement()}
            >
              Don't Show Again
            </Button>
            <Button
              variant="primary"
              size="md"
              className="ms-2"
              onClick={() => acceptEconsentAgreement()}
            >
              Yes, I Agree
            </Button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
