import React from "react";

import { Dropdown } from "react-bootstrap";
import { Table } from "react-bootstrap";

import CardDropdown from "../../../../../components/common/CardDropdown";
import { formatToCurrency } from "../../../../../helpers/utils";

const TableRow = ({ data, sr, updateBudget, propLength }) => {
  return (
    <tr
      className="align-middle"
      style={{ height: propLength === 1 ? "100px" : "" }}
    >
      <td className="text-center">{sr || "id"}</td>
      <td className="text-center">{data.start_of_fiscal_year || "start"}</td>
      <td className="text-center">{data.end_of_fiscal_year || "end"}</td>
      <td className="text-center">
        {formatToCurrency(+data.total_budget) || "XXXXXX"}
      </td>

      <td className="text-center">
        <CardDropdown>
          <div className="py-2">
            <Dropdown.Item
              className="text-primary"
              onClick={() => updateBudget(data)}
            >
              Edit
            </Dropdown.Item>
            {/* <Dropdown.Item
              className="text-danger"
              // style={{'fontWeight':'bold'}}
              onClick={() => deleteBudget(data)}
            >
              delete
            </Dropdown.Item> */}
          </div>
        </CardDropdown>
      </td>
    </tr>
  );
};

const BudgetsTable = ({ budgets, updateBudget, deleteBudget }) => {
  // console.log(budgets);
  let i = 1;
  return (
    <Table responsive striped hover bordered style={{ fontSize: "14px" }}>
      <thead>
        <tr>
          <th scope="col" className="text-center">
            #
          </th>
          <th scope="col" className="text-center">
            Start of Fiscal Year
          </th>
          <th scope="col" className="text-center">
            End of Fiscal Year
          </th>
          <th scope="col" className="text-center">
            Total Budget
          </th>
          <th scope="col" className="text-center">
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        {budgets?.map((budget, index) => (
          <TableRow
            data={budget}
            key={index}
            sr={i++}
            propLength={budgets?.length}
            updateBudget={updateBudget}
            deleteBudget={deleteBudget}
            // selectbudget={selectbudget}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default BudgetsTable;
