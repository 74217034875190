import FalconCloseButton from "../../../../../components/common/FalconCloseButton";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";

import Description from "./Description/Description";
import BookingHours from "./BookingHours/BookingHours";
import Billing from "./Billing/Billing";
import Rules from "./AmenityRules/Rules";
import ImagesDes from "./ImagesDes/ImagesDes";
import UnavailableDays from "./UnavailableDays/UnavailableDays";
import DangerZone from "./DangerZone/DangerZone";

import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

import ConfirmationModel from "../../../../../components/pages/sharedUI/ConfirmationModel";

export default function EditAmenitySingle({
  setshowEditAmenityTab,
  amenity,
  getAmenitiesData,
  clear_amenity,
  hostname,
  jwtToken,
}) {
  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  const { property_id, property_name } = useParams();

  console.log(hostname);

  // close tab
  const handleClearAmenity = () => {
    setshowEditAmenityTab(false);
    clear_amenity({});
  };

  // updating basic
  const updateAmenityBasic = (data) => {
    console.log(data);
    // return;
    axios
      .post(
        `${hostname}/api/amenity/EditBasic`,
        {
          Amenity_id: amenity._id,
          amenityname: data.amenity_name,
          description: data.description,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.success("Updated successfully", { theme: "colored" });
        getAmenitiesData();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!", { theme: "colored" });
      });
  };
  // updating images
  const updateAmenityImages = (data) => {
    const { toUpload, toDelete } = data;
    console.log(data);
    let imgs = toUpload?.map((v) => v.img);
    console.log(imgs);
    console.log(amenity.am_id);
    // return;
    if (toDelete?.length)
      axios
        .post(
          `${hostname}/api/amenity/EditImages`,
          {
            Amenity_id: amenity._id,
            Links: [...toDelete],
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          toast.info("Deleted successfully", { theme: "colored" });
          getAmenitiesData();
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong!", { theme: "colored" });
        });

    if (amenity.am_id && imgs?.length)
      axios
        .post(
          `${hostname}/api/amenity/Imageupload`,
          {
            amenityimages: [...imgs],
            id: amenity.am_id,
            p_id: amenity.p_id,
            amenityname: amenity.amenity_name,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          toast.success("Uploaded successfully", { theme: "colored" });
          getAmenitiesData();
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong!", { theme: "colored" });
        });
    else toast.warning("Nothing to Upload", { theme: "colored" });
  };

  // updating time
  const updateAmenityTime = (data) => {
    console.log(data);
    const { availableday, blackoutday } = data.input;
    const {
      max_book_length,
      duration,
      min_book_lead_time,
      max_book_lead_time,
      check_in_time_for_hotel_style,
      check_in_time_zone_for_hotel_style,
      check_out_time_for_hotel_style,
      check_out_time_zone_for_hotel_style,
      max_length_of_stay_hotel_style,
      booking_type,
    } = data;
    // return;
    console.log("We here                     " + duration);
    axios
      .post(
        `${hostname}/api/amenity/EditTime`,
        {
          Amenity_id: amenity._id,
          availableday,
          duration: max_book_length,
          blackoutday,
          min_book_lead_time,
          max_book_lead_time,
          check_in_time_for_hotel_style,
          check_in_time_zone_for_hotel_style,
          check_out_time_for_hotel_style,
          check_out_time_zone_for_hotel_style,
          max_length_of_stay_hotel_style,
          booking_type,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.success("Updated successfully", { theme: "colored" });
        getAmenitiesData();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!", { theme: "colored" });
      });
    //
    // Amenity_id,availableday,duration,blackoutday,min_book_lead_time,max_book_lead_time,check_in_time_for_hotel_style,check_in_time_zone_for_hotel_style,check_out_time_for_hotel_style,check_out_time_zone_for_hotel_style,max_length_of_stay_hotel_style
  };

  // updating rules
  const updateAmenityRules = (data) => {
    console.log(data);

    axios
      .post(
        `${hostname}/api/amenity/EditRules`,
        {
          Amenity_id: amenity._id,
          ...data,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.success("Updated successfully", { theme: "colored" });
        getAmenitiesData();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!", { theme: "colored" });
      });
    //
    // Amenity_id,amenity_rules,volume_of_booking,limit_by_unit_value,limit_by_unit_date,multiple_bookings_on_time_slots,max_guest_limit
  };

  // updating billing
  const updateAmenityBilling = (data) => {
    console.log(data);
    axios
      .post(
        `${hostname}/api/amenity/EditBilling`,
        {
          Amenity_id: amenity._id,
          ...data,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.success("Updated successfully", { theme: "colored" });
        getAmenitiesData();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!", { theme: "colored" });
      });
    //
    // Amenity_id,payment_type,amenity_price,allow_cancel,payment_instructions_cash,payment_instructions_check,payment_method
  };

  // updating billing
  const updateAmenityUnavailableDays = (
    amenity = {},
    days = [],
    range = []
  ) => {
    console.log(amenity);
    console.log(days);
    console.log(range);
    // return;
    axios
      .post(
        `${hostname}/api/amenity/Editunavailable`,
        {
          amenity_id: amenity._id,
          unavailabledates: days,
          unavailable_range_dates: range,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.success("Updated successfully", { theme: "colored" });
        getAmenitiesData();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!", { theme: "colored" });
      });
    //
    // Amenity_id,payment_type,amenity_price,allow_cancel,payment_instructions_cash,payment_instructions_check,payment_method
  };

  // enable and disbale

  const [confirmationModel, setConfirmationModel] = React.useState(false);

  const handleCloseConfirmationModel = () => {
    setConfirmationModel(false);
  };

  const [payloadForConfirmationModel, setPayloadForConfirmationModel] =
    React.useState({});

  const handleConfirmation = (payload) => {
    setPayloadForConfirmationModel(payload);
    setConfirmationModel(true);
  };

  return (
    <div className="">
      <Row className="g-3 mb-3 ">
        <Col>
          <Card>
            <Card.Body
              className="d-flex align-items-center justify-content-between scrollbar"
              style={{ overflowX: "scroll" }}
            >
              <div className="d-flex align-items-center">
                <Link className="me-2 text-secondary" to="/dashboard">
                  Home
                </Link>
                {"/"}
                <Link
                  className="me-2 ms-2 text-secondary"
                  to="/property/management/buildings"
                >
                  Properties
                </Link>
                {"/"}
                <Link
                  className="me-2 ms-2 text-secondary"
                  to={`/property/management/buildings/${property_id}/${property_name}/profile`}
                >
                  {property_name}
                </Link>
                {"/"}
                <Link
                  className="me-2 ms-2 text-secondary"
                  to={`/property/management/buildings/${property_id}/${property_name}/profile`}
                >
                  Amenities
                </Link>
                {"/"}
                <Link
                  className="me-2 ms-2"
                  to={`/property/management/buildings/${property_id}/${property_name}/profile`}
                >
                  {amenity.amenityname}
                </Link>
              </div>
              <FalconCloseButton
                onClick={handleClearAmenity}
                // onClick={() => setshowEditAmenityTab(false)}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* <Card className="mb-3">
          <Card.Body className="d-flex justify-content-between">
            <h5>Edit Amenity</h5>
          </Card.Body>
        </Card> */}
      {/* <Card>
          <Card.Body className="" style={{ minHeight: '90vh' }}></Card.Body>
        </Card> */}
      <Row className="g-3">
        <Col lg={8}>
          <Description
            updateAmenityBasic={updateAmenityBasic}
            amenityCurrent={amenity}
          />
          <BookingHours
            updateAmenityTime={updateAmenityTime}
            amenityCurrent={amenity}
          />
          <Rules
            updateAmenityRules={updateAmenityRules}
            amenityCurrent={amenity}
          />
          <UnavailableDays
            amenityCurrent={amenity}
            updateAmenityUnavailableDays={updateAmenityUnavailableDays}
          />
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar">
            <ImagesDes
              amenityCurrent={amenity}
              updateAmenityImages={updateAmenityImages}
            />
            <Billing
              updateAmenityBilling={updateAmenityBilling}
              amenityCurrent={amenity}
            />
            <DangerZone
              amenityCurrent={amenity}
              getAmenitiesData={getAmenitiesData}
              handleConfirmation={handleConfirmation}
            />
          </div>
        </Col>
      </Row>
      <ConfirmationModel
        show={confirmationModel}
        handleClose={handleCloseConfirmationModel}
        payload={payloadForConfirmationModel}
        updator={getAmenitiesData}
        setshowEditAmenityTab={setshowEditAmenityTab}
      />
    </div>
  );
}
