export default  [
  {
    id: 872,
    name: "Alberta",
    country_id: 39,
    country_code: "CA",
    state_code: "AB",
  },
  {
    id: 875,
    name: "British Columbia",
    country_id: 39,
    country_code: "CA",
    state_code: "BC",
  },
  {
    id: 867,
    name: "Manitoba",
    country_id: 39,
    country_code: "CA",
    state_code: "MB",
  },
  {
    id: 868,
    name: "New Brunswick",
    country_id: 39,
    country_code: "CA",
    state_code: "NB",
  },
  {
    id: 877,
    name: "Newfoundland and Labrador",
    country_id: 39,
    country_code: "CA",
    state_code: "NL",
  },
  {
    id: 878,
    name: "Northwest Territories",
    country_id: 39,
    country_code: "CA",
    state_code: "NT",
  },
  {
    id: 874,
    name: "Nova Scotia",
    country_id: 39,
    country_code: "CA",
    state_code: "NS",
  },
  {
    id: 876,
    name: "Nunavut",
    country_id: 39,
    country_code: "CA",
    state_code: "NU",
  },
  {
    id: 866,
    name: "Ontario",
    country_id: 39,
    country_code: "CA",
    state_code: "ON",
  },
  {
    id: 871,
    name: "Prince Edward Island",
    country_id: 39,
    country_code: "CA",
    state_code: "PE",
  },
  {
    id: 873,
    name: "Quebec",
    country_id: 39,
    country_code: "CA",
    state_code: "QC",
  },
  {
    id: 870,
    name: "Saskatchewan",
    country_id: 39,
    country_code: "CA",
    state_code: "SK",
  },
  {
    id: 869,
    name: "Yukon",
    country_id: 39,
    country_code: "CA",
    state_code: "YT",
  },
  {
    id: 1456,
    name: "Alabama",
    country_id: 233,
    country_code: "US",
    state_code: "AL",
  },
  {
    id: 1400,
    name: "Alaska",
    country_id: 233,
    country_code: "US",
    state_code: "AK",
  },
  {
    id: 1424,
    name: "American Samoa",
    country_id: 233,
    country_code: "US",
    state_code: "AS",
  },
  {
    id: 1434,
    name: "Arizona",
    country_id: 233,
    country_code: "US",
    state_code: "AZ",
  },
  {
    id: 1444,
    name: "Arkansas",
    country_id: 233,
    country_code: "US",
    state_code: "AR",
  },
  {
    id: 1402,
    name: "Baker Island",
    country_id: 233,
    country_code: "US",
    state_code: "UM-81",
  },
  {
    id: 1416,
    name: "California",
    country_id: 233,
    country_code: "US",
    state_code: "CA",
  },
  {
    id: 1450,
    name: "Colorado",
    country_id: 233,
    country_code: "US",
    state_code: "CO",
  },
  {
    id: 1435,
    name: "Connecticut",
    country_id: 233,
    country_code: "US",
    state_code: "CT",
  },
  {
    id: 1399,
    name: "Delaware",
    country_id: 233,
    country_code: "US",
    state_code: "DE",
  },
  {
    id: 1437,
    name: "District of Columbia",
    country_id: 233,
    country_code: "US",
    state_code: "DC",
  },
  {
    id: 1436,
    name: "Florida",
    country_id: 233,
    country_code: "US",
    state_code: "FL",
  },
  {
    id: 1455,
    name: "Georgia",
    country_id: 233,
    country_code: "US",
    state_code: "GA",
  },
  {
    id: 1412,
    name: "Guam",
    country_id: 233,
    country_code: "US",
    state_code: "GU",
  },
  {
    id: 1411,
    name: "Hawaii",
    country_id: 233,
    country_code: "US",
    state_code: "HI",
  },
  {
    id: 1398,
    name: "Howland Island",
    country_id: 233,
    country_code: "US",
    state_code: "UM-84",
  },
  {
    id: 1460,
    name: "Idaho",
    country_id: 233,
    country_code: "US",
    state_code: "ID",
  },
  {
    id: 1425,
    name: "Illinois",
    country_id: 233,
    country_code: "US",
    state_code: "IL",
  },
  {
    id: 1440,
    name: "Indiana",
    country_id: 233,
    country_code: "US",
    state_code: "IN",
  },
  {
    id: 1459,
    name: "Iowa",
    country_id: 233,
    country_code: "US",
    state_code: "IA",
  },
  {
    id: 1410,
    name: "Jarvis Island",
    country_id: 233,
    country_code: "US",
    state_code: "UM-86",
  },
  {
    id: 1428,
    name: "Johnston Atoll",
    country_id: 233,
    country_code: "US",
    state_code: "UM-67",
  },
  {
    id: 1406,
    name: "Kansas",
    country_id: 233,
    country_code: "US",
    state_code: "KS",
  },
  {
    id: 1419,
    name: "Kentucky",
    country_id: 233,
    country_code: "US",
    state_code: "KY",
  },
  {
    id: 1403,
    name: "Kingman Reef",
    country_id: 233,
    country_code: "US",
    state_code: "UM-89",
  },
  {
    id: 1457,
    name: "Louisiana",
    country_id: 233,
    country_code: "US",
    state_code: "LA",
  },
  {
    id: 1453,
    name: "Maine",
    country_id: 233,
    country_code: "US",
    state_code: "ME",
  },
  {
    id: 1401,
    name: "Maryland",
    country_id: 233,
    country_code: "US",
    state_code: "MD",
  },
  {
    id: 1433,
    name: "Massachusetts",
    country_id: 233,
    country_code: "US",
    state_code: "MA",
  },
  {
    id: 1426,
    name: "Michigan",
    country_id: 233,
    country_code: "US",
    state_code: "MI",
  },
  {
    id: 1438,
    name: "Midway Atoll",
    country_id: 233,
    country_code: "US",
    state_code: "UM-71",
  },
  {
    id: 1420,
    name: "Minnesota",
    country_id: 233,
    country_code: "US",
    state_code: "MN",
  },
  {
    id: 1430,
    name: "Mississippi",
    country_id: 233,
    country_code: "US",
    state_code: "MS",
  },
  {
    id: 1451,
    name: "Missouri",
    country_id: 233,
    country_code: "US",
    state_code: "MO",
  },
  {
    id: 1446,
    name: "Montana",
    country_id: 233,
    country_code: "US",
    state_code: "MT",
  },
  {
    id: 1439,
    name: "Navassa Island",
    country_id: 233,
    country_code: "US",
    state_code: "UM-76",
  },
  {
    id: 1408,
    name: "Nebraska",
    country_id: 233,
    country_code: "US",
    state_code: "NE",
  },
  {
    id: 1458,
    name: "Nevada",
    country_id: 233,
    country_code: "US",
    state_code: "NV",
  },
  {
    id: 1404,
    name: "New Hampshire",
    country_id: 233,
    country_code: "US",
    state_code: "NH",
  },
  {
    id: 1417,
    name: "New Jersey",
    country_id: 233,
    country_code: "US",
    state_code: "NJ",
  },
  {
    id: 1423,
    name: "New Mexico",
    country_id: 233,
    country_code: "US",
    state_code: "NM",
  },
  {
    id: 1452,
    name: "New York",
    country_id: 233,
    country_code: "US",
    state_code: "NY",
  },
  {
    id: 1447,
    name: "North Carolina",
    country_id: 233,
    country_code: "US",
    state_code: "NC",
  },
  {
    id: 1418,
    name: "North Dakota",
    country_id: 233,
    country_code: "US",
    state_code: "ND",
  },
  {
    id: 1431,
    name: "Northern Mariana Islands",
    country_id: 233,
    country_code: "US",
    state_code: "MP",
  },
  {
    id: 4851,
    name: "Ohio",
    country_id: 233,
    country_code: "US",
    state_code: "OH",
  },
  {
    id: 1421,
    name: "Oklahoma",
    country_id: 233,
    country_code: "US",
    state_code: "OK",
  },
  {
    id: 1415,
    name: "Oregon",
    country_id: 233,
    country_code: "US",
    state_code: "OR",
  },
  {
    id: 1448,
    name: "Palmyra Atoll",
    country_id: 233,
    country_code: "US",
    state_code: "UM-95",
  },
  {
    id: 1422,
    name: "Pennsylvania",
    country_id: 233,
    country_code: "US",
    state_code: "PA",
  },
  {
    id: 1449,
    name: "Puerto Rico",
    country_id: 233,
    country_code: "US",
    state_code: "PR",
  },
  {
    id: 1461,
    name: "Rhode Island",
    country_id: 233,
    country_code: "US",
    state_code: "RI",
  },
  {
    id: 1443,
    name: "South Carolina",
    country_id: 233,
    country_code: "US",
    state_code: "SC",
  },
  {
    id: 1445,
    name: "South Dakota",
    country_id: 233,
    country_code: "US",
    state_code: "SD",
  },
  {
    id: 1454,
    name: "Tennessee",
    country_id: 233,
    country_code: "US",
    state_code: "TN",
  },
  {
    id: 1407,
    name: "Texas",
    country_id: 233,
    country_code: "US",
    state_code: "TX",
  },
  {
    id: 1432,
    name: "United States Minor Outlying Islands",
    country_id: 233,
    country_code: "US",
    state_code: "UM",
  },
  {
    id: 1413,
    name: "United States Virgin Islands",
    country_id: 233,
    country_code: "US",
    state_code: "VI",
  },
  {
    id: 1414,
    name: "Utah",
    country_id: 233,
    country_code: "US",
    state_code: "UT",
  },
  {
    id: 1409,
    name: "Vermont",
    country_id: 233,
    country_code: "US",
    state_code: "VT",
  },
  {
    id: 1427,
    name: "Virginia",
    country_id: 233,
    country_code: "US",
    state_code: "VA",
  },
  {
    id: 1405,
    name: "Wake Island",
    country_id: 233,
    country_code: "US",
    state_code: "UM-79",
  },
  {
    id: 1462,
    name: "Washington",
    country_id: 233,
    country_code: "US",
    state_code: "WA",
  },
  {
    id: 1429,
    name: "West Virginia",
    country_id: 233,
    country_code: "US",
    state_code: "WV",
  },
  {
    id: 1441,
    name: "Wisconsin",
    country_id: 233,
    country_code: "US",
    state_code: "WI",
  },
  {
    id: 1442,
    name: "Wyoming",
    country_id: 233,
    country_code: "US",
    state_code: "WY",
  },
];
