import React from 'react';
import Flex from '../../../components/common/Flex';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function CustomTitle({ heading, handleCloseDetail }) {
  return (
    <Flex className="p-2 mt-2">
      <span onClick={handleCloseDetail} className="cursor-pointer">
        <ArrowBackIcon fontSize="large" />
      </span>
      <h4 className="ms-3 mt-1">{heading}</h4>
    </Flex>
  );
}
