import React from "react";
import { Table } from "react-bootstrap";
import { formatLogInfo } from "../../../../../../../helpers/utils";

const TableRow = ({ data }) => {
  return (
    <tr
      className="align-middle"
      // style={{ 'height': '100px' }}
    >
      <td className="text-center">{formatLogInfo(data.date_time) || "id"}</td>
      <td className="text-center">{data.action || "address"}</td>
      <td className="text-center">{data.description || "address"}</td>
    </tr>
  );
};

const MailOutsTable = ({ list }) => {
  // console.log(list);

  return (
    <Table
      responsive
      striped
      hover
      bordered
      style={{ fontSize: "14px" }}
      className="pb-5"
    >
      <thead>
        <tr>
          <th scope="col" className="text-center">
            Date & Time
          </th>
          {/* <th scope="col" className="text-center">
            Created By
          </th> */}
          <th scope="col" className="text-center">
            Action
          </th>
          <th scope="col" className="text-center">
            Description
          </th>
        </tr>
      </thead>
      <tbody>
        {list?.map((item, index) => (
          <TableRow data={item} key={index} propLength={list.length} />
        ))}
      </tbody>
    </Table>
  );
};

export default MailOutsTable;
