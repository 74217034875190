import React from "react";
import { Modal } from "react-bootstrap";
// import { Offcanvas, Button, Form, InputGroup, Row, Col } from 'react-bootstrap';
// import Offcanvas from 'react-bootstrap/Offcanvas';
import Wizard from "./wizard/Wizard";
// import Compose from "../../../../components/app/email/compose/Compose";
import FalconCloseButton from "../../../../components/common/FalconCloseButton";
// import FalconCardHeader from "../../../../components/common/FalconCardHeader";
import axios from "axios";
import { toast } from "react-toastify";

export default function CreateNewMailModal({
  show,
  handleClose,
  payload,
  setPayload,
  getAllData,
  userData,
  hostname,
  jwtToken,
}) {
  console.log(payload);

  const [loader, setLoader] = React.useState(false);

  React.useEffect(() => {
    // setLoader(true);
    // setTimeout(() => {
    //   setLoader(false);
    // }, 400);

    let obj = {
      mail_type: payload.mail_type || "",
      budget_year: payload.budget_id || "",
      groups: payload.groups || [],
      // generate_paper_copies: payload.generate_paper_copies || false,
      mail_subject: payload.mail_subject || "no Subject",
      mail_content: payload.mail_content || "",
      will_draft_letter: payload.draft_letter ? "Yes" : "No",
      mail_files: payload.attachments || [],
      letter_file_name: payload.letter_filename || "Letter.pdf",
      letter_content: payload.letter_content || "",
      not_from_market: true,
      step: +payload.step || 1,
      status: "draft",
      page_type: payload.page_type || "Blank Page",
    };

    setMailDetailsState({ ...obj });

    if (payload?.step === "2" || payload?.step === "3") {
      setModelSize("xl");
    }
  }, [show]);

  const [modelSize, setModelSize] = React.useState(
    payload?.step === "2" || payload?.step === "3" ? "xl" : "lg"
  );

  const closeModelAndSetSize = (saveAsDraft = true) => {
    console.log(mailDetailsState);
    handleClose();
    setPayload({});
    setModelSize("lg");

    if (saveAsDraft) {
      if (mailDetailsState.mail_type) sendDataToDB();
      else clearState();
    }
  };

  const [mailDetailsState, setMailDetailsState] = React.useState({
    mail_type: payload.mail_type || "",
    budget_year: payload.budget_id || "",
    groups: payload.groups || [],
    // generate_paper_copies: payload.generate_paper_copies || false,
    mail_subject: payload.mail_subject || "",
    mail_content: payload.mail_content || "",
    will_draft_letter: payload.draft_letter ? "Yes" : "No",
    mail_files: [],
    letter_file_name: payload.letter_filename || "Letter.pdf",
    letter_content: payload.letter_content || "",
    not_from_market: true,
    step: +payload.step || 1,
    status: "draft",
    page_type: payload.page_type || "Blank Page",
  });

  console.log(mailDetailsState);

  const [mailDetailsStateErrors, setMailDetailsStateErrors] = React.useState({
    mail_type: "",
    budget_year: "",
    groups: [],
    generate_paper_copies: false,
    mail_subject: "",
    mail_content: "",
    will_draft_letter: "No",
    mail_files: [],
    letter_file_name: "",
    letter_content: "",
    not_from_market: true,
    step: 1,
    status: "draft",
    page_type: "",
  });

  const handleChange = (e) => {
    let state = { ...mailDetailsState };
    let { name, value } = e.target;
    state[name] = value;
    console.log(state);
    setMailDetailsState(state);
  };

  const validate = () => {
    let errors = {};
    let state = { ...mailDetailsState };

    if (!state.mail_type) errors.mail_type = "mail type is required!";
    if (state.mail_type === "Budget Letter" && !state.budget_year)
      errors.budget_year = "budget year is required!";
    if (state.groups.length < 1) errors.groups = "select at least one group!";
    if (!state.mail_subject) errors.mail_subject = "mail subject is required!";
    if (!state.mail_content) errors.mail_content = "mail content is required!";
    if (!state.letter_file_name)
      errors.letter_file_name = "filename is required!";
    if (!state.letter_content)
      errors.letter_content = "letter content is required!";
  };

  const sendDataToDB = (saveAsDraft = true) => {
    setLoader(true);

    console.log(mailDetailsState);
    console.log("worklet ready");

    // let mailfiles = mailDetailsState.mail_files;
    // mailfiles = payload.

    let taskData = {
      user_id: payload.userData.data,
      p_id: payload.userData.p_id,
      mail_type: mailDetailsState.mail_type,
      budget_id: mailDetailsState.budget_year || null,
      groups: mailDetailsState.groups,
      // generate_paper_copies: mailDetailsState.generate_paper_copies,
      mail_subject: mailDetailsState.mail_subject,
      mail_content: mailDetailsState.mail_content,
      draft_letter: mailDetailsState.will_draft_letter === "Yes" ? true : false,
      letter_content: mailDetailsState.letter_content,
      letter_filename: mailDetailsState.letter_file_name,
      step: mailDetailsState.step,
      status: saveAsDraft ? "In Draft" : "Sent",
      mail_files: mailDetailsState.mail_files,
      page_type: mailDetailsState.page_type || "Blank Page",
    };

    let link = "CreateMailOut";
    let config = {};
    if (payload?._id) {
      link = "UpdateMailOut";
      taskData.mailout_id = payload._id;
    }

    console.log(taskData);

    console.log(link);
    console.log("break0000000000000000000000000000000000000000000");
    // return;

    let mfiles = taskData.mail_files?.filter((v) => v !== undefined);
    if (payload?._id) {
      mfiles = mfiles?.map((v) => {
        let obj = { ...v };
        // let binaryString = window.atob(obj.file);
        // let binaryLength = binaryString.length;
        // let bytes = new Uint8Array(binaryLength);

        // for (let i = 0; i < binaryLength; i++) {
        //   let ascii = binaryString.charCodeAt(i);
        //   bytes[i] = ascii;
        // }
        // obj.file = v.buffer;
        // if (obj.file_id) delete obj.file;
        return obj;
      });
    }

    taskData.mail_files = mfiles;
    taskData.mail_files_length = mfiles.length;

    console.log(taskData);
    // if (payload?._id) return;

    axios
      .post(
        `${hostname}/api/property/${link}`,
        { ...taskData },
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        getAllData();
        setLoader(false);
        if (saveAsDraft) {
          toast.info("Draft Saved", { theme: "colored" });
          clearState();
        } else {
          toast.success("Mails Sent", { theme: "colored" });
          getAllData();
          handleClose();
          // setMailDetailsState({
          //   ...mailDetailsState,
          //   step: mailDetailsState.step + 1,
          // });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);

        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };

  const clearState = () => {
    setMailDetailsState({
      mail_type: "",
      budget_year: "",
      groups: [],
      generate_paper_copies: false,
      mail_subject: "",
      mail_content: "",
      will_draft_letter: "No",
      mail_files: [],
      letter_file_name: "Letter.pdf",
      letter_content: "",
      not_from_market: true,
      step: 1,
      status: "draft",
      page_type: "Blank Page",
    });
  };

  return (
    <Modal
      show={show}
      onHide={closeModelAndSetSize}
      backdrop="static"
      keyboard={false}
      size={modelSize}
      // fullscreen
      // style={{ transition: "all 0.5s linear" }}
    >
      <Modal.Header>
        <Modal.Title>{payload.loadType}</Modal.Title>
        {mailDetailsState.step !== 5 && (
          <FalconCloseButton onClick={closeModelAndSetSize} />
        )}
      </Modal.Header>
      <Modal.Body>
        {loader ? (
          <div>Loading</div>
        ) : (
          (payload.loadType === "Create New Mail" ||
            payload.loadType === "Edit Mail Details") && (
            <Wizard
              handleClose={closeModelAndSetSize}
              progressBar={true}
              getAllData={getAllData}
              setModelSize={setModelSize}
              userData={payload.userData}
              mailOutState={mailDetailsState}
              setMailOutState={setMailDetailsState}
              mailOutErrors={mailDetailsStateErrors}
              setMailOutErrors={setMailDetailsStateErrors}
              handleFieldValueChange={handleChange}
              sendDataToDB={sendDataToDB}
              payload={payload}
              setPayload={setPayload}
              hostname={hostname}
              jwtToken={jwtToken}
            />
          )
        )}
      </Modal.Body>
    </Modal>
  );
}

// export default CreatePropertyModal;
