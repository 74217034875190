import React from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import FalconCloseButton from "../../../components/common/FalconCloseButton";

import axios from "axios";

import { toast } from "react-toastify";

export default function ConfirmationModel(props) {
  const { payload, updator, setshowEditAmenityTab, hostname, jwtToken } = props;

  const disableAmenity = () => {
    axios
      .post(
        `${hostname}/api/amenity/DisableAmenity`,
        {
          amenity_id: payload.id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.success(res.data, { theme: "colored" });
        // payload.updatorFunction();
        updator();
        props.handleClose();
        // getAmenitiesData();
        // setshowEditAmenityTab(false);

        // navigate(
        //   `${window.location.hostname}/property/features/${property_id}/${property_name}/amenities`
        // );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deactivateAmenity = () => {
    axios
      .post(
        `${hostname}/api/amenity/DeactivateAmenity`,
        {
          amenity_id: payload.id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.success(res.data, { theme: "colored" });
        // payload.updaterFunction();

        updator();
        props.handleClose();
        setshowEditAmenityTab(false);

        // navigate(
        //   `${window.location.hostname}/property/features/${property_id}/${property_name}/amenities`
        // );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const enableAmenity = () => {
    axios
      .post(
        `${hostname}/api/amenity/EnableAmenity`,
        {
          amenity_id: payload.id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.success(res.data, { theme: "colored" });
        payload.updatorFunction();
        props.handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      style={{ backdropFilter: "blur(3px)" }}
      keyboard={false}
      centered
    >
      <Modal.Header>
        <Modal.Title className="fs-1">
          {payload.loadType || "action here"}
        </Modal.Title>
        <FalconCloseButton onClick={props.handleClose} />
      </Modal.Header>
      <Modal.Body className="">
        {payload.loadType === "Enable Amenity" && (
          <>
            <p className="">
              Are you sure to enable this amenity ?
              {/* <span className="text-primary">"{payload.amenity_name}"</span>? */}
            </p>
            <br />
            <div className="text-end">
              <Button
                variant="primary"
                className="me-3 "
                size="md"
                onClick={enableAmenity}
                style={{ width: 80 }}
              >
                Yes
              </Button>
              <Button
                variant="falcon-default"
                size="md"
                onClick={props.handleClose}
                style={{ width: 80 }}
              >
                No
              </Button>
            </div>
          </>
        )}
        {payload.loadType === "Disable Amenity" && (
          <>
            <p className="" style={{ fontSize: "15px" }}>
              Are you sure you want to temporarily disable this amenity ?
              {/* <span className="text-primary">"{payload.amenity_name}"</span>? */}
            </p>
            <div className="text-end">
              <Button
                variant="falcon-warning"
                className="me-3 "
                size="md"
                onClick={disableAmenity}
                style={{ width: 80 }}
              >
                Yes
              </Button>
              <Button
                variant="falcon-default"
                size="md"
                onClick={props.handleClose}
                style={{ width: 80 }}
              >
                No
              </Button>
            </div>
          </>
        )}
        {payload.loadType === "Deactivate Amenity" && (
          <>
            <p className="">
              Are you sure you want to permanently deactivate this amenity ?
              {/* <span className="text-primary">"{payload.amenity_name}"</span>? */}
            </p>
            <div className="text-end">
              <Button
                variant="falcon-warning"
                className="me-3 "
                size="md"
                onClick={deactivateAmenity}
                style={{ width: 80 }}
              >
                Yes
              </Button>
              <Button
                variant="falcon-default"
                size="md"
                onClick={props.handleClose}
                style={{ width: 80 }}
              >
                No
              </Button>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
