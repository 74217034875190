import { useState } from "react";

import Flex from "../../../../components/common/Flex";
import React from "react";
import { Card, Col, Row, Modal } from "react-bootstrap";

import MailOutsTable from "./MailOutsTable";
import { toast } from "react-toastify";

import ReceivePaymentsModel from "../../../../components/pages/sharedUI/PayReceivePaymentsModel";

import axios from "axios";
// import Cookies from "universal-cookie";
import InvoiceModel from "../../../../components/pages/sharedUI/InvoiceModel";
import { Link, useParams } from "react-router-dom";
import Loader from "../../../../components/common/Loader";
import CustomSearchbar from "../../../../components/common/CustomSearchbar";
import CustomFilterbar from "../../../../components/common/CustomFilterbar";

import Paymenttest from "../../../../components/app/e-commerce/billing/Paymenttest";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getBase64Type } from "../../../../helpers/utils";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName } from "../../../../helpers/utils";
import CustomBreadCrumbs from "../../../common/CustomBreadCrumbs";
import startCase from "lodash/startCase";

const stripePromise = loadStripe(
  "pk_test_51NPrO8C1KCwmpA0kgy5EoTWIKe0kCLEi6esEybr2gDrARuAdwnB7o48PqADCUcFglGZ0XWW4qgvbl6ynTez1ikc8001LzJsdHi"
);

const MyInvoices = ({ from = "" }) => {
  let hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const { property_id, property_name, invoiceDetailId } = useParams();

  if (
    userData.role === "Site-Administrators" ||
    userData.role === "Condozak-Basic-Users"
  )
    userData.property_name = property_name;

  let link = "";
  let postData = {};
  if (
    userData.role === "Site-Administrators" ||
    userData.role === "Condozak-Basic-Users"
  ) {
    link = `${hostname}/api/amenity/GetAllInvoices`;
    // postData.user_id = userData.data || "";
    postData.p_id = property_id;
  }

  if (
    userData.role === "unit_owner" ||
    userData.role === "supp_resident" ||
    userData.role === "supp_tenant"
  ) {
    link = `${hostname}/api/amenity/GetInvoices`;
    postData.user_id = userData.data || "";
    postData.p_id = userData.propid;
  }

  const [invoicesData, setInvoicesData] = useState([]);
  const [invoicesDataTemp, setInvoicesDataTemp] = useState([...invoicesData]);
  const [loader, setLoader] = useState(false);

  // invoice model

  const [showInvoiceModel, setShowInvoiceModel] = useState(false);

  const handleCloseInvoiceModel = () => {
    if (invoiceDetailId) {
      setShowInvoiceModel(false);
      window.location.href = `/owner-portal/my-invoices/${userData?.UnitsOwner[0]?._id}/${userData?.UnitsOwner[0]?.unit_number}`;
      // navigate("/owner-portal/my-invoices");
    } else setShowInvoiceModel(false);
  };
  const handleShowInvoiceModel = () => setShowInvoiceModel(true);

  const [invoice, setInvoice] = React.useState({});

  const handleShowInvoiceModelFunction = (data) => {
    data.loadType = "From Owner";
    if (data.invoice_type === "Event Registration") {
      let arr = [];

      arr = data?.booking_id?.referred_id?.Product_info?.filter(
        (v) => v.quantity > 0
      );

      data.booking_id.referred_id.Product_info = arr;
    }
    setInvoice(data);
    handleShowInvoiceModel();
  };

  // ######
  const [showModal, setShowModal] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [error, setError] = useState(null);
  // Function to show the modal

  const handleShowModal = (invoice = {}) => {
    // console.log(data);
    console.log(invoice);
    try {
      axios
        .post(
          `${hostname}/api/pay/PayInvoice`,
          {
            invoice_id: invoice?._id,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          //return res.data.clientSecret;
          setClientSecret(res.data.clientSecret);

          setShowModal(true);

          // console.log(res.data);
        })
        .catch((err) => {
          setShowModal(false);
          console.log(err);
          toast.error(err.response.data.error || "Something went wrong!", {
            theme: "colored",
          });
          setError(err);
        });
      //const data = await response.json();
      //return data.clientSecret; // Assuming your API returns the clientSecret
    } catch (error) {
      console.error("Error fetching clientSecret:", error);
      setShowModal(false);
      return null;
    }
  };

  // Function to hide the modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleStripePayment = (invoice = {}) => {
    console.log(invoice);
    setInvoice(invoice);
    handleShowModal(invoice);
  };

  // ####

  // //show receive payment modal
  const [showReceivePaymentModal, setShowReceivePaymentModal] = useState(false);

  const handleCloseReceivePaymentModal = () =>
    setShowReceivePaymentModal(false);
  const handleShowReceivePaymentModal = () => setShowReceivePaymentModal(true);

  const [amenity, setAmenity] = React.useState({});

  const handleReceivePayment = (data) => {
    console.log(data);
    setAmenity(data);
    data.loadType = "Receive Payment Manager";
    handleShowReceivePaymentModal();
  };

  React.useEffect(() => {
    // setPropertiesData(propertiesDataArray);
    getAllInvoicesData();
  }, []);

  const getAllInvoicesData = () => {
    axios
      .post(
        link,
        {
          ...postData,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        let arr = res.data.data?.map((v) => {
          let obj = v;
          if (obj.status === "Active")
            obj.status = { type: "success", title: "Active", icon: "check" };

          if (obj.status === "Paid")
            obj.status = { type: "success", title: "Paid", icon: "check" };

          if (obj.status === "Pending")
            obj.status = { type: "warning", title: "Unpaid", icon: "redo" };

          if (obj.status === "Disabled" || obj.status === "Cancelled")
            obj.status = { type: "danger", title: "Disabled", icon: "ban" };

          return obj;
        });
        let logoType = getBase64Type(res.data.logo);
        // if (res.data.logo?.startsWith("http")) logoType = "link";
        // if (res.data.logo?.startsWith("/9g")) logoType = "jpg";
        // if (res.data.logo?.startsWith("/9j")) logoType = "jpeg";
        // if (res.data.logo?.startsWith("iVBORw0KGgo=")) logoType = "png";

        arr = res.data.data?.map((v) => {
          let obj = Object.assign(
            {},
            {
              property_address: res.data.PropertyAddress,
              user_address: res.data.user_address,
              property_logo: res.data?.logo,
              property_logo_type: logoType,
            },
            v
          );
          return obj;
        });

        if (invoiceDetailId !== undefined) {
          let eventD = arr?.filter((v) => v._id === invoiceDetailId);
          handleShowInvoiceModelFunction(eventD[0]);
          setLoader(false);
          return;
        }

        console.log(arr);
        setInvoicesData(arr);
        setInvoicesDataTemp(arr);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  if (loader) {
    return <Loader />;
  } else {
    return (
      <>
        {(userData.role === "Site-Administrators" ||
          userData.role === "Condozak-Basic-Users") && (
          <Row className="g-3 mb-3">
            <Col>
            <CustomBreadCrumbs
            links={[
              { path: "/dashboard", label: "Home" },
              { path: "/property/management/buildings", label: "Properties" },
              {
                path: `/property/management/buildings/${property_id}/${property_name}/profile`,
                label: startCase(property_name),
              },
              { path: "", label: "Invoices" },
            ]}
          />
            </Col>
          </Row>
        )}

        {((userData.role === "unit_owner" ||
          userData.role === "supp_resident" ||
          userData.role === "supp_tenant") && (
            <Row className="g-3 mb-3">
              <Col>
                <Card>
                  <Card.Body className="d-flex align-items-center">
                    <Link className="me-2 text-secondary" to="/dashboard">
                      Home
                    </Link>
                    {"/"}

                    <Link className="me-2 ms-2" to={`#!`}>
                      My Invoices
                    </Link>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ))}
        <Card>
          <Card.Body className="" style={{ minHeight: "90vh" }}>
            <Flex justifyContent={"between"} alignItems={"start"} className="">
              {(userData.role === "Site-Administrators" ||
                userData.role === "Condozak-Basic-Users") && <h4>Invoices</h4>}
              {((userData.role === "unit_owner" ||
                userData.role === "supp_resident" ||
                userData.role === "supp_tenant") && <h4> My Invoices</h4>)}
              {/* <Button variant="primary" size="sm" /> */}
              {/* <Link
              to="/property/management/buildingInfo"
              variant="primary"
              size="sm"
            >
              Details
            </Link> */}
              {/* <Button variant="primary" size="sm" onClick={handleShow}>
                New Mail
              </Button> */}
            </Flex>
            <br />
            {/* <Row>
              <Col md="6">
                <p>long long text for property management</p>
              </Col>
            </Row> */}
            <Row className="flex-end-center mb-3 mt-3">
              <Col className=" d-flex justify-content-sm-end">
                <CustomFilterbar
                  filterFlags={["Paid Invoice", "Unpaid Invoice"]}
                  dataListTemp={invoicesDataTemp}
                  stateUpdator={setInvoicesData}
                  from="Invoices Table"
                />

                <CustomSearchbar
                  searchFlags={[
                    "amenity_booking_id.amenity_id.amenityname",
                    "amenity_booking_id.amenity_fee",
                    "amenity_booking_id.unit_num",
                    "amenity_booking_id.status.title",
                    "property_address.address",
                    "invoice_number",
                    "status.title",
                  ]}
                  dataListTemp={invoicesDataTemp}
                  stateUpdator={setInvoicesData}
                  placeholders={["number", "type", "price"]}
                />
              </Col>
            </Row>
            <br />
            <MailOutsTable
              properties={invoicesData}
              // selectBuilding={selectBuilding}
              getAllPropertiesData={getAllInvoicesData}
              handleShowInvoiceModelFunction={handleShowInvoiceModelFunction}
              handleReceivePayment={handleReceivePayment}
              userData={userData}
              getAllInvoicesData={getAllInvoicesData}
              handleStripePayment={handleStripePayment}
            />

            <ReceivePaymentsModel
              show={showReceivePaymentModal}
              handleClose={handleCloseReceivePaymentModal}
              amenity={amenity}
              userData={userData}
              getAllData={getAllInvoicesData}
              hostname={hostname}
              jwtToken={jwtToken}
            />
            <br />
            <InvoiceModel
              show={showInvoiceModel}
              handleClose={handleCloseInvoiceModel}
              userData={userData}
              updator={getAllInvoicesData}
              loadType={invoice}
              cleaTmpState={setInvoice}
              handleStripePayment={handleStripePayment}
            />
            <Modal show={showModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Payment </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Elements stripe={stripePromise}>
                  <Paymenttest
                    clientSecret={clientSecret}
                    handleCloseModal={handleCloseModal}
                    invoiceId={invoice._id} // Pass the function to close the modal
                    getAllInvoicesData={getAllInvoicesData}
                  />
                </Elements>
              </Modal.Body>
            </Modal>
          </Card.Body>
        </Card>
      </>
    );
  }
};

export default MyInvoices;
