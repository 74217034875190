import React, { useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import WizardInput from "./WizardInput";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { getHostName } from "../../../../helpers/utils";
import { toast } from "react-toastify";

const AccountForm = ({ register, errors, watch, setValue }) => {
  const { EParameter } = useParams();
  const hostname = getHostName();
  const [email, setEmail] = React.useState('');

  useEffect(() => {
    getUserEmail();
  }, []);

  useEffect(() => {
    // Update the email field in the form when the email state is updated
    if (email) {
      setValue("email", email);
    }
  }, [email, setValue]); // Dependency array ensures it runs when email or setValue changes

  const getUserEmail = () => {
    axios
      .post(`${hostname}/api/property/getEmailData`, { id: EParameter })
      .then((res) => {
        setEmail(res.data.data);
      })
      .catch((err) => {
        if (err.response)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("Something went wrong!", { theme: "colored" });
      });
  };

  return (
    <>
      <WizardInput
        label="First Name"
        name="firstName"
        errors={errors}
        formGroupProps={{ className: "mb-2" }}
        formControlProps={{
          ...register("firstName", {
            required: "First Name is required!",
          }),
        }}
      />
      <WizardInput
        label="Last Name"
        name="lastName"
        errors={errors}
        formGroupProps={{ className: "mb-2" }}
        formControlProps={{
          ...register("lastName", {
            required: "Last Name is required!",
          }),
        }}
      />

      <WizardInput
        type="email"
        errors={errors}
        label="Email Address *"
        name="email"
        formGroupProps={{ className: "mb-2" }}
        formControlProps={{
          ...register("email", {
            required: "Email is required!",
            pattern: {
              value:
                /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/i,
              message: "Email must be valid",
            },
          }),
        }}
      />

      <WizardInput
        type="password"
        errors={errors}
        label="Password*"
        name="password"
        formGroupProps={{ className: "mb-2" }}
        formControlProps={{
          ...register("password", {
            required: "You must specify a password",
            minLength: {
              value: 6,
              message: "Password must have at least 6 characters",
            },
            pattern: {
              value: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
              message:
                "Password must have at least one number and one special character",
            },
          }),
        }}
      />
      <WizardInput
        type="password"
        errors={errors}
        label="Confirm Password*"
        name="confirmPassword"
        formGroupProps={{ className: "mb-2" }}
        formControlProps={{
          ...register("confirmPassword", {
            required: "Confirm Password field is required",
            validate: (value) =>
              value === watch("password") || "The password do not match",
          }),
        }}
      />

      <WizardInput
        type="checkbox"
        errors={errors}
        label={
          <>
            I accept the <Link to="#!">terms</Link> and{" "}
            <Link to="#!">privacy policy</Link>
          </>
        }
        name="agreedToTerms"
        formControlProps={{
          ...register("agreedToTerms", {
            required: "You need to agree to the terms and privacy policy.",
          }),
        }}
      />
    </>
  );
};

AccountForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  watch: PropTypes.func,
  setValue: PropTypes.func.isRequired, // Ensure setValue is passed from useForm
};

export default AccountForm;
