import React from "react";
import EventsCalenderON from "../../../../../components/pages/Features/EventsCalender/EventsCalender";

export default function EventsCalender() {
  return (
    <div>
      <EventsCalenderON />
    </div>
  );
}
