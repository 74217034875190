import React from "react";
import { Form as BSForm } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";

export default function CustomFilterbar({
  filterFlags = [],
  dataListTemp = [],
  stateUpdator = () => {},
  from = "",
}) {
  // console.log(dataListTemp);
  const handleChange = (e) => {
    try {
      const { value, checked } = e.target;
      if (checked) {
        let updatedArr = handleFilterBar(value);
        // console.log(updatedArr);
        stateUpdator(updatedArr);
      }
      if (!checked) {
        // console.log("cleared");
        stateUpdator(dataListTemp);
      }
    } catch (e) {
      // console.log(e);
    }
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   let arr = e.target.checked;

  //   let filterValues = [];

  //   for (let i = 0; i < arr.length; i++) {
  //     if (arr[i].checked) filterValues.push(arr[i].value);
  //   }

  //   setOptions(filterValues);
  // console.log(filterValues);

  //   if (!filterValues?.length) return;

  //   let calculatedDataList = [];

  //   calculatedDataList = handleFilterBar(filterValues);

  // console.log(calculatedDataList);

  //   // stateUpdator();
  // };

  const handleFilterBar = (filterValue = []) => {
    try {
      let res = [];

      if (from === "Users table" || from === "Users Table") {
        res = [];
        if (filterValue === "Status Active") {
          res = dataListTemp?.filter((v) => v.status.title === "Active");
          // console.log(res);
        }
        if (filterValue === "Status Disabled") {
          res = dataListTemp?.filter((v) => v.status.title === "Disabled");
        }
        if (filterValue === "Status Invited") {
          res = dataListTemp?.filter((v) => v.status.title === "Invited");
        }
        if (filterValue === "Status Ignored") {
          res = dataListTemp?.filter((v) => v.status.title === "Ignored");
        }
        if (filterValue === "Status Revoked") {
          res = dataListTemp?.filter((v) => v.status.title === "Revoked");
        }
        if (filterValue === "Role Admin") {
          res = dataListTemp?.filter((v) =>
            v.groups.includes("Site-Administrators")
          );
        }
      }

      if (from === "User Owners Table") {
        res = [];

        if (filterValue === "Status Active") {
          res = dataListTemp?.filter((v) => v.status.title === "Active");
        }

        if (filterValue === "Status Invited") {
          res = dataListTemp?.filter((v) => v.status.title === "Invited");
        }
        if (filterValue === "Status Ignored") {
          res = dataListTemp?.filter((v) => v.status.title === "Ignored");
        }
        if (filterValue === "Status Revoked") {
          res = dataListTemp?.filter((v) => v.status.title === "Revoked");
        }
      }

      if (from === "Units Table Manager") {
        res = [];

        if (filterValue === "Status Active") {
          res = dataListTemp?.filter((v) => v.status.title === "Active");
        }
        if (filterValue === "Status Invited") {
          res = dataListTemp?.filter((v) => v.status.title === "Invited");
        }
        if (filterValue === "Status Ignored") {
          res = dataListTemp?.filter((v) => v.status.title === "Ignored");
        }
        if (filterValue === "Type Condo") {
          res = dataListTemp?.filter((v) => v.unit_type === "condo");
        }
        if (filterValue === "Type Non-Condo") {
          res = dataListTemp?.filter((v) => v.unit_type !== "condo");
        }
      }

      if (from === "Events Table") {
        res = [];

        if (filterValue === "Free Tickets") {
          res = dataListTemp?.filter(
            (v) => v.event_ticket.event_ticket_type === "Free Ticket"
          );
        }
        if (filterValue === "Paid Tickets") {
          res = dataListTemp?.filter(
            (v) => v.event_ticket.event_ticket_type === "Paid Ticket"
          );
        }
        if (filterValue === "Donation Tickets") {
          res = dataListTemp?.filter(
            (v) => v.event_ticket.event_ticket_type === "Donation Ticket"
          );
        }
      }
      if (from === "Maintenance Table") {
        res = [];

        if (filterValue === "Status Open") {
          res = dataListTemp?.filter((v) => v.status.title === "Open");
        }
        if (filterValue === "Status Processing") {
          res = dataListTemp?.filter((v) => v.status.title === "Processing");
        }
        if (filterValue === "Status Closed") {
          res = dataListTemp?.filter((v) => v.status.title === "Closed");
        }
      }
      if (from === "Invoices Table") {
        res = [];

        if (filterValue === "Paid Invoice") {
          res = dataListTemp?.filter((v) => v.status.title === "Paid");
        }
        if (filterValue === "Unpaid Invoice") {
          res = dataListTemp?.filter((v) => v.status.title === "Unpaid");
        }
      }
      if (from === "Mail Details Inner Table") {
        res = [];

        if (filterValue === "Status Sent") {
          res = dataListTemp?.filter((v) => v.status === "Sent");
        }
        if (filterValue === "Status No-Consent") {
          res = dataListTemp?.filter((v) => v.status === "E-consent Inactive");
        }
      }
      if (from === "MailOuts Table") {
        res = [];

        if (filterValue === "Status Draft") {
          // console.log(dataListTemp);
          res = dataListTemp?.filter((v) => v.status.title === "Draft");
        }
        if (filterValue === "Status Sent") {
          res = dataListTemp?.filter((v) => v.status.title === "Sent");
        }
      }
      if (from === "Visitor Parking Table") {
        res = [];

        if (filterValue === "Parking Reserved") {
          // console.log(dataListTemp);
          res = dataListTemp?.filter((v) => v.status === "Reserved");
        }
        if (filterValue === "Parking Expired") {
          res = dataListTemp?.filter((v) => v.status !== "Reserved");
        }
      }
      if (from === "Violations Tracking Table") {
        res = [];

        if (filterValue === "Resolved") {
          // console.log(dataListTemp);
          res = dataListTemp?.filter((v) => v.violation_stage === "Resolved");
        }
        if (filterValue === "Not Resolved") {
          res = dataListTemp?.filter((v) => v.violation_stage !== "Resolved");
        }
      }
      if (from === "Document Drive Table") {
        res = [];

        if (filterValue === "Files") {
          // console.log(dataListTemp);
          res = dataListTemp?.filter((v) => v.file_type === "file");
        }
        if (filterValue === "Folders") {
          res = dataListTemp?.filter((v) => v.file_type === "folder");
        }
        if (filterValue === "Expired") {
          res = dataListTemp?.filter((v) => v?.expiry_date < new Date());
        }
      }
      // console.log(res);
      return res;
    } catch (e) {
      // console.log(e);
    }
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        as={Button}
        variant="falcon-default"
        className="me-3 mb-2"
      >
        Filter By{" "}
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-card mt-0 dropdown-caret dropdown-caret-bg">
        <Card className="">
          <Card.Body className="fw-normal">
            {filterFlags?.map((v, i) => (
              <div
                className="d-flex justify-content-between align-items-center"
                key={v + i + 654}
              >
                <BSForm.Check
                  type="checkbox"
                  name="checked"
                  value={v}
                  onChange={handleChange}
                />
                <Dropdown.Item
                  onClick={(e) => e.preventDefault()}
                  className="d-flex justify-content-between"
                >
                  {v}
                </Dropdown.Item>
              </div>
            ))}
          </Card.Body>
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  );
  // return (
  //   <Dropdown>
  //     <Dropdown.Toggle
  //       as={Button}
  //       variant="falcon-default"
  //       className="me-3 mb-2"
  //     >
  //       Filter By{" "}
  //     </Dropdown.Toggle>
  //     <Dropdown.Menu className="dropdown-menu-card mt-0 dropdown-caret dropdown-caret-bg">
  //       <Card className="">
  //         <Card.Body className="fw-normal">
  //           <form onSubmit={handleSubmit}>
  //             {filterFlags?.map((v, i) => (
  //               <div
  //                 className="d-flex justify-content-between align-items-center"
  //                 key={v + i + 654}
  //               >
  //                 <BSForm.Check type="checkbox" name="checked" value={v} />
  //                 <Dropdown.Item
  //                   onClick={(e) => e.preventDefault()}
  //                   className="d-flex justify-content-between"
  //                 >
  //                   {v}
  //                 </Dropdown.Item>
  //               </div>
  //             ))}
  //             <div className="mt-2">
  //               {options.length < 1 && (
  //                 <Button
  //                   variant="falcon-success"
  //                   size="sm"
  //                   style={{ display: "block", minWidth: "100%" }}
  //                   type="submit"
  //                 >
  //                   Apply Filter
  //                 </Button>
  //               )}
  //               {options.length > 0 && (
  //                 <Button
  //                   variant="falcon-warning"
  //                   size="sm"
  //                   style={{ display: "block", minWidth: "100%" }}
  //                   type="reset"
  //                   onClick={handleClear}
  //                 >
  //                   Clear Filter
  //                 </Button>
  //               )}
  //             </div>
  //           </form>
  //         </Card.Body>
  //       </Card>
  //     </Dropdown.Menu>
  //   </Dropdown>
  // );
}
