// import React, { useState, useContext } from 'react';
import React from "react";
// import PropTypes from 'prop-types';
// import WizardInput from './WizardInput';
// import FalconDropzone from 'components/common/FalconDropzone';
// import avatarImg from 'assets/img/team/avatar.png';
// import { isIterableArray } from 'helpers/utils';
// import Avatar from 'components/common/Avatar';
// import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { AuthWizardContext } from "../../../../context/Context";
// import Flex from 'components/common/Flex';
import { Formik, Form, Field, ErrorMessage } from "formik";

import { Button, Col, Row } from "react-bootstrap";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { getHostName } from "../../../../helpers/utils";

const PersonalForm = () => {
  const { user, setUser, step, setStep } = React.useContext(AuthWizardContext);
  console.log(user);

  let hostname = getHostName();

  try {
    const handleSubmit = (data) => {
      axios
        .post(`${hostname}/api/auth/VerifyCompany`, {
          otpPass: data.verify_code,
          email: user.email,
        })
        .then((res) => {
          console.log(res.data);

          setUser(Object.assign({}, user, data));

          toast.success(`Verified as ${user.email}`, {
            theme: "colored",
          });
          setStep(step + 1);
        })
        .catch((err) => {
          console.log(err);
          toast.error(`Invalid OTP : ${user.email}`, {
            theme: "colored",
          });
        });
    };

    return (
      <>
        <Formik
          initialValues={{ verify_code: user.verify_code || "" }}
          validationSchema={Yup.object({
            verify_code: Yup.string().required(
              "verification code is required!"
            ),
          })}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Row className="mb-3">
                <Col md="auto">
                  <h3>Check your Inbox</h3>
                </Col>
                <hr />
                <Col md="auto">
                  <h5 className="fw-light">
                    A verification email has been dispatched to{" "}
                    {(
                      <span className="text-secondary fw-bold">
                        {user.email}
                      </span>
                    ) || "name@gmail.com"}
                  </h5>
                  <br />
                  <p>Enter code below to proceed!</p>
                </Col>
              </Row>
              <div className="mb-4">
                <label>Verification Code </label>
                <Field
                  type="text"
                  name="verify_code"
                  className="form-control"
                />
                <ErrorMessage
                  name="verify_code"
                  component="div"
                  className="text-danger fs--1"
                />
              </div>
              <div>
                <Button
                  variant="secondary"
                  type="button"
                  onClick={() => setStep(step - 1)}
                >
                  Previous
                </Button>
                <Button
                  variant="primary"
                  className="float-end"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Next
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
};

export default PersonalForm;
