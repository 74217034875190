import React from "react";
import { Col, Row, Card, Tooltip, OverlayTrigger } from "react-bootstrap";

import EventsDetailModal from "./EventsDetailModal";

import EventDetailBanner from "./EventDetailBanner";
import EventDetailPrice from "./EventDetailPrice";
import EventDetailDescription from "./EventDetailDescription";
import EventDetailTime from "./EventDetailTime";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { formatLogInfo, getHostName } from "../../../../../helpers/utils";
import { startCase } from "lodash";
import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";
import IconButton from "../../../../../components/common/IconButton";
import Loader from "../../../../../components/common/Loader";

let months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const EventDetail = () => {
  let hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const [event, setEvent] = React.useState({});
  const [show, setShow] = React.useState(false);
  const [payload, setPayload] = React.useState({});
  const [loader, setLoader] = React.useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const { event_id } = useParams();

  React.useEffect(() => {
    // window.scrollTo({ top: 0, behavior: "smooth" });
    getEventDetails();
  }, []);

  const ItemButton = ({ tootltip, icon, className, onClick }) => {
    return (
      <OverlayTrigger
        overlay={<Tooltip id="overlay-trigger-example">{tootltip}</Tooltip>}
      >
        <div className="d-inline-block">
          <IconButton
            variant="falcon-default"
            size="sm"
            icon={icon}
            className={className}
            onClick={onClick}
          />
        </div>
      </OverlayTrigger>
    );
  };

  const getEventDetails = async () => {
    try {
      setLoader(true);

      const res = await axios.post(
        `${hostname}/api/property/EventDetails`,
        {
          event_id: event_id,
          user_id: userData.data,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      // .then((res) => {
      console.log(res.data);

      let arr = res.data.Data?.map((v) => {
        let obj = { ...v };
        obj.event_days = obj.eventDays;
        let tmp_date_start = new Date(obj.event_start_date);
        let tmp_date_end = new Date(obj.event_end_date);
        let calendar = {
          day: tmp_date_start.getDate().toString(),
          month: months[tmp_date_start.getMonth()],
        };
        obj.calendar = calendar;
        obj.event_name = startCase(obj.event_name);
        obj.event_organizer = startCase(obj.event_organizer);
        obj.duration = `${
          months[tmp_date_start.getMonth()]
        } ${tmp_date_start.getDate()} - ${
          months[tmp_date_end.getMonth()]
        } ${tmp_date_end.getDate()}`;

        let tmped = obj.event_days[0];
        obj.time = `${tmped?.start_time} ${tmped?.start_timezone} - ${tmped?.end_time} ${tmped?.end_timezone}`;
        obj.event_registration_deadline_pure = obj.event_registration_deadline;
        obj.event_registration_deadline = formatLogInfo(
          obj.event_registration_deadline,
          true
        );
        if (obj.event_ticket.event_ticket_type !== "Free Ticket") {
          obj.event_paid_price = obj.event_ticket.ticket_options?.filter(
            (v) => v.checked
          )[0]?.price;
          // console.log(obj);
        }
        // let event_days = obj.event_days?.map((v) => {
        //   let inObj = {};
        //   inObj.id = v._id;
        //   inObj.start_time = v.event_start_time;
        //   inObj.start_timezone = v.event_start_timezone;
        //   inObj.end_time = v.event_end_time;
        //   inObj.end_timezone = v.event_end_timezone;
        //   return inObj;
        // });
        // obj.event_days = event_days;

        return obj;
      });

      let tdata = {
        user_id: userData.data,
        // p_id: userData.propid,
        event_id: event_id,
      };

      const res2 = await axios.post(
        `${hostname}/api/property/ReadEventTickets`,
        { ...tdata },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      arr[0].registeredTickets = res2.data.Data;

      console.log(arr[0]);

      setEvent(arr[0]);

      setLoader(false);
    } catch (e) {
      console.log(e);
    }
  };

  const viewEventTickets = (event) => {
    console.log("calling viewEventTickets function");

    // setPayload({});
    event.loadType = "View Event Tickets";
    event.userData = userData;
    console.log(event);

    setPayload(event);
    handleShow();
  };

  const registerEvent = (event) => {
    // setPayload({});
    console.log("calling registerEvent function cuvgbhnjxycv");

    event.loadType = "Register Event";
    event.userData = userData;
    console.log(event);

    setPayload(event);
    handleShow();
  };

  const BuyMoreEventTickets = (event) => {
    // setPayload({});
    console.log("calling BuyMoreEventTickets function");

    event.loadType = "Buy More Tickets";
    event.userData = userData;
    setPayload(event);
    handleShow();
  };

  const navigate = useNavigate();

  if (loader) return <Loader />;
  return (
    <>
      <Row className="g-3 mb-3 ">
        <Col>
          <Card>
            <Card.Body className="d-flex justify-content-start align-items-center">
              <ItemButton
                tootltip="Back to Events"
                icon="arrow-left"
                onClick={() => navigate(-1)}
              />
              <h5 className="ms-3">
                {event?.event_name}
                {event?.isRegistered && (
                  <div className="badge bg-success ms-1 fs--1 ">Registered</div>
                )}
              </h5>
              {/* <CloseButton onClick={handlePageClose} /> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="g-3">
        <Col lg={8}>
          <EventDetailBanner event={event} />
          <EventDetailDescription event={event} />
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar">
            <EventDetailPrice
              event={event}
              registerEvent={registerEvent}
              viewEventTickets={viewEventTickets}
              BuyMoreEventTickets={BuyMoreEventTickets}
              userData={userData}
              // showPastEvents={showPastEvents}
            />
            <EventDetailTime event={event} />
          </div>
        </Col>
      </Row>
      <EventsDetailModal
        show={show}
        handleClose={handleClose}
        payload={payload}
        setPayload={setPayload}
        getAllData={getEventDetails}
        userData={userData}
        hostname={hostname}
        jwtToken={jwtToken}
      />
    </>
  );
};

export default EventDetail;
