import React, { useContext, useState } from "react";
import {
  Button,
  Form as BSForm,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { AuthWizardContext } from "../../../../context/Context";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from "axios";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { flatten } from "lodash";
import { convertUppercaseToLowercase } from "../../../../helpers/utils";
import HelpIcon from "@mui/icons-material/HelpOutline";


export default function PropertyDetail({ userData, hostname, jwtToken }) {
  const { user, setUser, step, setStep } = useContext(AuthWizardContext);

  // console.log(user);

  const handleSubmit = (data) => {
    setUser(() => Object.assign({}, user, data));

    axios
      .post(
        `${hostname}/api/property/propertyverify`,
        {
          property_id: data.property_id,
          property_url: data.property_url?.toLowerCase(),
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then(() => {
        toast.success("Site Name Available.", { theme: "colored" });
        setTimeout(() => {
          setStep(step + 1);
        }, 500);
      })
      .catch((err) => {
        console.log(err);
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };

  const [managersList, setManagersList] = useState([]);

  React.useEffect(() => {
    getManagersData(userData.compid);
  }, []);

  const getManagersData = (compid) => {
    axios
      .post(
        `${hostname}/api/auth/RoleTable`,
        {
          c_id: compid,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        let usersTmpArry = [flatten(res.data.data), flatten(res.data.invited)];
        usersTmpArry = flatten(usersTmpArry);
        // console.log(usersTmpArry);
        usersTmpArry = usersTmpArry?.filter(
          (v) => v.Status === "1" && v.verified === "Verified"
        );

        let arr = usersTmpArry?.map((v) => (
          <option key={v._id} value={v._id}>{`${v.firstname} ${v.lastname} ${
            v.groups.includes("Site-Administrators") ? "" : ""
          }`}</option>
        ));
        setManagersList([...arr]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="d-flex justify-content-center">
      <div className="col-xl-8 col-lg-8 col-md-8">
        <Formik
          initialValues={{
            property_id: user.property_id || "",
            property_name: user.property_name || "",
            property_url: user.property_url || "",
            property_phone: user.property_phone || "",
            property_manager: user.property_manager || "",
          }}
          validationSchema={Yup.object().shape({
            property_id: Yup.string().required("Property ID is required!"),
            property_name: Yup.string().required("Property Name is required!"),
            property_url: Yup.string()
              .required("Property URL is required!")
              .matches(
                // /^[a-z]+(-[a-z]+)*$|^[A-Z]+(-[A-Z]+)*$/,
                /^[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*$/,
                "Invalid site name. Please enter a valid site name like this 'acmesite' or 'acme-site'."
              ),
            property_phone: Yup.string().required(
              "Property Phone is required!"
            ),
            property_manager: Yup.string().required(
              "Property Manager Profile is required!"
            ),
          })}
          onSubmit={(values, { setSubmitting }) => {
            // setTimeout(() => {
            //   alert(JSON.stringify(values, null, 2));
            // }, 400);
            // console.log(values);
            handleSubmit(values);
            setSubmitting(false);
          }}
        >
          {({
            isSubmitting,
            values,
            handleChange,
            handleBlur,
            setFieldValue,
          }) => (
            <Form>
              <Row className="mb-3">
                <Col md={12} className="mb-3">
                  <BSForm.Group>
                    <BSForm.Label>Property ID</BSForm.Label>
                    <Field
                      type="text"
                      name="property_id"
                      className="form-control"
                      placeholder="Enter Property ID"
                    />
                    <ErrorMessage
                      name="property_id"
                      component="div"
                      className="text-danger fs--1"
                    />
                  </BSForm.Group>
                </Col>
                <Col md={12}>
                  <BSForm.Group>
                    <BSForm.Label>Property Name</BSForm.Label>
                    <Field
                      type="text"
                      name="property_name"
                      className="form-control"
                      placeholder="Enter Name here"
                    />
                    <ErrorMessage
                      name="property_name"
                      component="div"
                      className="text-danger fs--1"
                    />
                  </BSForm.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12} className="mb-3">
                  <BSForm.Group>
                    <BSForm.Label>
                      Property URL{" "}
                      <OverlayTrigger
                        placement="auto"
                        overlay={
                          <Tooltip
                            style={{
                              position: "fixed",
                              minWidth: "350px",
                              backgroundColor:'#000',borderRadius:'3px'
                            }}
                            id="overlay-trigger-example"
                          >
                            <div
                              style={{
                                width: "340px",
                                textAlign:'left'                                
                              }}
                            >
                              Must contain only letters (a-z), numbers (0-9).
                              <br />
                              No spaces or special characters allowed.
                              <br /> Should be unique and descriptive of your
                              property.
                              <br /> like "my-property-site" or "propertysite"',
                            </div>
                          </Tooltip>
                        }
                      >
                        <HelpIcon />
                      </OverlayTrigger>
                    </BSForm.Label>
                    {/* <div className="d-flex flex-column position-relative">
                      <Field
                        type="text"
                        name="property_url"
                        className="form-control"
                        placeholder="Create Property URL"
                        style={{ paddingRight: "40%", overflow: "hidden" }}
                      />
                      <span
                        className="translate-middle"
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "78%",
                        }}
                      >
                        .condozak.app
                      </span>
                    </div> */}

                    <Field
                      type="text"
                      name="property_url"
                      className="form-control"
                      value={values.property_url}
                      onChange={(e) =>
                        setFieldValue(
                          "property_url",
                          convertUppercaseToLowercase(e.target.value)
                        )
                      }
                      placeholder="Create Property URL"
                    />

                    <ErrorMessage
                      name="property_url"
                      component="div"
                      className="text-danger fs--1"
                    />
                  </BSForm.Group>{" "}
                </Col>
                <Col md={12}>
                  <BSForm.Group>
                    <BSForm.Label>Property Phone</BSForm.Label>
                    <Field
                      type="text"
                      name="property_phone"
                      className="form-control"
                      placeholder="Enter Main Phone No. here"
                    />
                    <ErrorMessage
                      name="property_phone"
                      component="div"
                      className="text-danger fs--1"
                    />
                  </BSForm.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12}>
                  <BSForm.Group className="mb-3">
                    <BSForm.Label>Property Manager</BSForm.Label>
                    <select
                      // component="select"
                      id="property_manager"
                      name="property_manager"
                      multiple={false}
                      className="form-select"
                      value={values.property_manager}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Select</option>
                      {managersList}
                    </select>
                    <ErrorMessage
                      name="property_manager"
                      component="div"
                      className="text-danger fs--1"
                    />
                  </BSForm.Group>
                </Col>
              </Row>

              <div className="text-end">
                <Button
                  variant="primary"
                  className="float-end"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Next
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
