// import FalconCardHeader from 'components/common/FalconCardHeader';
import React, { useContext, useState } from "react";
import { Button, Col, Row, Form as BSForm } from "react-bootstrap";
import { toast } from "react-toastify";
// import Cookies from 'universal-cookie';
// import axios from "axios";
import { AuthWizardContext } from "../../../../../context/Context";

import { Formik, Field, ErrorMessage, Form } from "formik";

import axios from "axios";

import { useNavigate, useParams } from "react-router-dom";
//import Cookies from "universal-cookie";
import * as Yup from "yup";

import TicketsPriceTable from "./TicketsPriceTable";
import TicketsEditPriceTable from "./TicketsEditPriceTable";
import Loader from "../../../../../components/common/Loader";

const EventPrice = ({
  from = "",
  event = {},
  handleClose,
  getAllData,
  updateEvent,
  ticketsData,
  addTicket,
  removeTicket,
  updateTicket,
  getAllTicketsData,
  actionData,
  setActionData,
  enableTicket,
  disableTicket,
  userData,
  hostname,
  jwtToken,
}) => {


  const [loader, setLoader] = React.useState(false);

  const { step, setStep, user, setUser } = useContext(AuthWizardContext);
  console.log(user);
  const { property_id } = useParams();

  const getDurationState = () => {
    let tmparr = [{ name: "Ticket Name", price: 0.0, checked: true }];

    if (from === "Edit Event Page") {
      if (event.event_ticket.ticket_options?.length > 0) {
        return event.event_ticket.ticket_options;
        // if (ticketsData?.length > 0) {
        //   console.log("from edit page ^^^^^^^^^^");
        //   console.log(ticketsData);
        //   return ticketsData;
      } else {
        return tmparr;
      }
    } else if (user.ticket_options?.length > 0) return user.ticket_options;
    else return tmparr;
  };

  const [tickets, setTickets] = useState(
    getDurationState()
    // { name: "Ticket Name", price: 0.0, checked: true },
    // { name: "Green gallery", price: 5.0 },
    // { name: "VIP", price: 20.0 },
  );
  const [priceErrors, setPriceErrors] = React.useState([]);

  const handleSubmit = (values) => {
    console.log(values);

    if (values.event_ticket_type !== "Free Ticket") {
      let errors = validate();
      console.log(errors);

      let areErrors = false;

      errors?.forEach((v) => {
        console.log(v);
        if (Object.keys(v).length) areErrors = true;
      });

      if (areErrors) {
        toast.error("please correct tickets data!", { theme: "colored" });
        console.log(errors);
        setPriceErrors(errors);
        return;
      }
      setPriceErrors([]);
      console.log("move forward");
    }

    values.ticket_options = tickets;
    console.log({ ...user, ...values });

    // return;
    setUser(Object.assign({}, user, values));
    handleSubmitFinal(Object.assign({}, user, values));
    setStep(step + 1);
  };

  const validate = () => {
    // let re = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;

    let state = [...tickets];
    let arr = state?.map((v, i) => {
      let obj = {};
      if (!v.name) obj.name = "name is required!";
      if (!v.price) obj.price = "price is required!";
      // if (!v.name) obj.name = "name is required!";

      return obj;
    });
    return arr;
  };

  const handleSubmitFinal = (user) => {
    try {
      console.log("ok");
      let eventReq = { ...user };

      eventReq.generated_id = userData.data;
      // if(userData?.role)
      eventReq.p_id = property_id;

      // if (eventReq.show_remaining_tickets)
      //   eventReq.show_remaining_tickets = "Yes";
      // if (!eventReq.show_remaining_tickets)
      //   eventReq.show_remaining_tickets = "No";

      if (user.event_ticket_type !== "Free Ticket") {
        let errors = validate();
        console.log(errors);

        let areErrors = false;

        errors?.forEach((v) => {
          console.log(v);
          if (Object.keys(v).length) areErrors = true;
        });

        if (areErrors) {
          toast.error("please correct tickets data!", { theme: "colored" });
          console.log(errors);
          setPriceErrors(errors);
          return;
        }
        setPriceErrors([]);
        console.log("move forward");
      }

      if (eventReq.event_ticket_type === "Free Ticket")
        eventReq.ticket_options = [];

      console.log(eventReq);

      // return;

      axios
        .post(
          `${hostname}/api/property/CreateEvent`,
          { ...eventReq },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          if (eventReq.event_cover_image !== "") {
            addCoverImageAndShowSuccess(res.data.Data);
          } else {
            toast.success("Event created successfully", { theme: "colored" });
            getAllData();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong", { theme: "colored" });
    }
  };

  const addCoverImageAndShowSuccess = (event) => {
    let e_data = {};
    e_data.event_id = event._id;
    e_data.req_images = user.event_cover_image;

    console.log(e_data);
    axios
      .post(
        `${hostname}/api/property/AddEventImage`,
        { ...e_data },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.success("Event created successfully", { theme: "colored" });
        getAllData();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong", { theme: "colored" });
      });
  };

  const handleStepMinus = (values) => {
    setUser(Object.assign({}, user, values));

    setStep(step - 1);
  };

  const handleUpdateEvent = (values) => {
    if (values.event_ticket_type !== "Free Ticket") {
      let errors = validate();
      console.log(errors);

      let areErrors = false;

      errors?.forEach((v) => {
        console.log(v);
        if (Object.keys(v).length) areErrors = true;
      });

      if (areErrors) {
        toast.error("please correct tickets data!", { theme: "colored" });
        console.log(errors);
        setPriceErrors(errors);
        return;
      }
      setPriceErrors([]);
      console.log("move forward");
    }

    let obj = { ...values };
    obj.ticket_options = tickets;
    console.log(obj);
    updateEvent(obj);
  };

  if (loader) return <Loader />;

  return (
    <div className={`${from === "Edit Event Page" && "card mt-3"}`}>
      {from === "Edit Event Page" && (
        <div className="card-header">Edit Pricing</div>
      )}

      <div className={`${from === "Edit Event Page" && "card-body bg-light"}`}>
        <Formik
          initialValues={{
            event_ticket_type:
              from === "Edit Event Page"
                ? event.event_ticket.event_ticket_type
                : user.event_ticket_type || "",
            static_total_tickets:
              from === "Edit Event Page"
                ? event.event_ticket.static_total_tickets
                : user.static_total_tickets || "",
            show_remaining_tickets:
              from === "Edit Event Page"
                ? event.event_ticket.show_remaining_tickets
                : user.show_remaining_tickets || false,
          }}
          validationSchema={Yup.object({
            event_ticket_type: Yup.string().required("sponsor is required!"),
            static_total_tickets: Yup.string().required(
              "tickets number is required!"
            ),
            // show_remaining_tickets: Yup.string().required(
            //   "event type is required!"
            // ),
          })}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            handleSubmit(values);
          }}
        >
          {({
            values,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            handleBlur,
            handleChange,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={12} className="mb-3">
                  <label htmlFor="event_ticket_type">Event Ticket Type</label>
                  <Field
                    component="select"
                    id="typeProperty"
                    name="event_ticket_type"
                    className="form-select"
                    disabled={from === "Edit Event Page"}
                    // multiple={true}
                  >
                    <option>select</option>
                    {["Free Ticket", "Paid Ticket", "Donation Ticket"]?.map(
                      (v, i) => (
                        <option key={v} value={v}>
                          {v}
                        </option>
                      )
                    )}
                  </Field>{" "}
                  <ErrorMessage
                    name="event_ticket_type"
                    className="text-danger fs--1"
                    component={"div"}
                  />
                </Col>
                {(values.event_ticket_type === "Paid Ticket" ||
                  values.event_ticket_type === "Donation Ticket") &&
                  from !== "Edit Event Page" && (
                    <Col md={12} className="mb-3 mt-2">
                      <TicketsPriceTable
                        tickets={tickets}
                        setTickets={setTickets}
                        priceErrors={priceErrors}
                      />
                    </Col>
                  )}
                {(values.event_ticket_type === "Paid Ticket" ||
                  values.event_ticket_type === "Donation Ticket") &&
                  from === "Edit Event Page" && (
                    <Col md={12} className="mb-3 mt-2">
                      <TicketsEditPriceTable
                        tickets={ticketsData}
                        // setTickets={setTickets}
                        priceErrors={priceErrors}
                        from={from}
                        // ticketsData={ticketsData}
                        getAllTicketsData={getAllTicketsData}
                        addTicketD={addTicket}
                        removeTicketD={removeTicket}
                        updateTicketD={updateTicket}
                        actionData={actionData}
                        setActionData={setActionData}
                        event={event}
                        enableTicket={enableTicket}
                        disableTicket={disableTicket}
                      />
                    </Col>
                  )}
                <Col md={12} className="mb-3">
                  <label htmlFor="static_total_tickets">
                    Total number of Tickets
                  </label>
                  <Field
                    name="static_total_tickets"
                    className="form-control"
                    placeholder="enter total tickets count ..."
                  />
                  <ErrorMessage
                    name="static_total_tickets"
                    className="text-danger fs--1"
                    component={"div"}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12}>
                  <label htmlFor="timezone">Remaining Tickets</label>
                  <div className="my-3">
                    <div className="d-flex gap-3">
                      <div>
                        {/* <BSForm.Check
                          type="checkbox"
                          name="show_remaining_tickets"
                          value={values.show_remaining_tickets}
                          className="form-check-input"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        /> */}
                        <Field
                          type="checkbox"
                          name="show_remaining_tickets"
                          className="form-check-input"
                        />
                      </div>
                      <div>
                        <p
                          className="fs--1 text-secondary"
                          style={{ marginTop: "2px" }}
                        >
                          Show number of remaining tickets
                        </p>
                      </div>
                    </div>
                  </div>

                  <ErrorMessage
                    name="show_remaining_tickets"
                    className="text-danger fs--1"
                    component={"div"}
                  />
                </Col>
              </Row>

              {from !== "Edit Event Page" && (
                <div className="">
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={() => handleStepMinus(values)}
                  >
                    Previous
                  </Button>
                  <Button
                    variant="primary"
                    className="float-end"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Next
                  </Button>
                </div>
              )}
              {from === "Edit Event Page" && (
                <div className="">
                  <Button
                    variant="primary"
                    className="float-end"
                    // type="submit"
                    // disabled={isSubmitting}
                    // onClick={() => updateEvent(values)}
                    onClick={() => handleUpdateEvent(values)}
                  >
                    Update
                  </Button>
                </div>
              )}
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EventPrice;
