import React from "react";

import { Form as BSForm, Button } from "react-bootstrap";
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import * as Yup from "yup";

import axios from "axios";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
// import { toast } from "react-toastify";

// import { AuthWizardContext } from "context/Context";

import { formatLogInfo } from "../../../../../helpers/utils";
import { useParams, Link } from "react-router-dom";
import { arrayBufferFileLoader } from "@cyntler/react-doc-viewer";
// import Select from "react-select";
// import CustomSearchbar from "components/common/CustomSearchbar";
// import { Col, Form } from "react-bootstrap";
// import "./ReactSelect.css";
// import Fuse from "fuse.js";

let groupsArr = [
  "All Residents",
  "Primary Owners",
  "Supplementary Owners",
  "Primary Tenants",
  "Supplementary Tenants",
];

const MailDetailsPage = ({
  // from = "",
  // event = {},
  // updateEvent,
  setModelSize,
  mailOutState,
  setMailOutState,
  mailOutErrors,
  // setMailOutErrors,
  handleFieldValueChange,
  hostname,
  jwtToken,
}) => {
  // const { step, setStep, user, setUser } = React.useContext(AuthWizardContext);
  // console.log(user);

  const { property_id, property_name } = useParams();

  const [groups, setGroups] = React.useState([]);

  console.log(mailOutState.groups);

  const [budgetsData, setBudgetsData] = React.useState([]);

  React.useEffect(() => {
    getBudgetsData();
    getGroupsData();
  }, []);

  const getBudgetsData = () => {
    axios
      .post(
        `${hostname}/api/property/ReadAllBudgets`,
        {
          p_id: property_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);

        let arr = res.data.Data?.map((v) => {
          let obj = { ...v };
          obj.start_of_fiscal_year = formatLogInfo(
            obj.start_of_fiscal_year,
            true
          );
          obj.end_of_fiscal_year = formatLogInfo(obj.end_of_fiscal_year, true);
          return obj;
        });

        setBudgetsData(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getGroupsData = () => {
    axios
      .post(
        `${hostname}/api/property/groupfilter`,
        {
          p_id: property_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data.data);

        let arr = res.data.data || [];

        // setMailOutState({
        //   ...mailOutState,
        //   groups: arr,
        // });

        setGroups(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = () => {
    console.log(mailOutState);
    setMailOutState({ ...mailOutState, step: mailOutState.step + 1 });
    setModelSize("xl");
    // setStep(step + 1);
    // setUser(Object.assign({}, user, data));
  };
  if (budgetsData.length < 1 && mailOutState.mail_type === "Budget Letter")
    return (
      <div>
        <div
          style={{
            minHeight: "50vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Button
            onClick={() => setMailOutState({ ...mailOutState, mail_type: "" })}
          >
            <KeyboardArrowLeftIcon /> Go Back
          </Button>

          <p className="text-center mt-3">
            Please add the budget in budget page.
          </p>
          <p className="text-center">
            Go to{" "}
            <Link
              to={`/property/management/buildings/${property_id}/${property_name}/budget`}
            >
              Budget
            </Link>{" "}
            page.
          </p>
        </div>
      </div>
    );

  return (
    <div>
      <BSForm.Group className="mb-3">
        <BSForm.Label>Mail Type</BSForm.Label>
        <BSForm.Select
          name="mail_type"
          value={mailOutState.mail_type}
          onChange={handleFieldValueChange}
        >
          <option value="">Select</option>
          <option value="Budget Letter">Budget Letter</option>
          <option value="Meeting Invitation">Meeting Invitation</option>
          <option value="General Announcement">General Announcement</option>
        </BSForm.Select>
        <div className="fs--1 text-danger">{mailOutErrors.mail_type}</div>
      </BSForm.Group>
      {mailOutState.mail_type === "Budget Letter" && (
        <BSForm.Group className="mb-3">
          <BSForm.Label>Budget Year</BSForm.Label>
          <BSForm.Select
            name="budget_year"
            value={mailOutState.budget_year}
            onChange={handleFieldValueChange}
          >
            <option value="">Select</option>
            {budgetsData?.map((v) => (
              <option value={v._id} key={v._id}>
                {v.start_of_fiscal_year} - {v.end_of_fiscal_year}
              </option>
            ))}
          </BSForm.Select>
          <div className="fs--1 text-danger">{mailOutErrors.budget_year}</div>
        </BSForm.Group>
      )}

      <BSForm.Group className="mb-3">
        <BSForm.Label id="checkbox-group">
          Send To{" "}
          <span className="ms-5 text-success">
            * click on plus icon + to add groups.
          </span>
        </BSForm.Label>
        <div
          role="group"
          aria-labelledby="checkbox-group"
          className="row"
        ></div>

        <div className="">
          {mailOutState.groups?.map((v) => (
            <CustomChip
              key={v + "hk"}
              showRemoveKey
              text={v}
              mailOutState={mailOutState}
              setMailOutState={setMailOutState}
            />
          ))}
        </div>
        <BSForm.Control
          // as={"textarea"}
          // rows={2}
          // onKeyUp={handleSearchBarAction}
          placeholder="Search here for groups..."
        />
        <div className="mt-3">
          {groups?.map((v) => (
            <CustomChip
              key={v}
              text={v}
              mailOutState={mailOutState}
              setMailOutState={setMailOutState}
            />
          ))}
        </div>
        <div className="fs--1 text-danger">{mailOutErrors.groups}</div>
        {/* <BSForm.Group className="my-3">
          <div className="d-flex">
            <BSForm.Check
              type="checkbox"
              name="generate_paper_copies"
            
              defaultChecked={mailOutState.generate_paper_copies}
              onChange={() =>
                setMailOutState({
                  ...mailOutState,
                  generate_paper_copies: !mailOutState.generate_paper_copies,
                })
              }
            />
            <label className="ms-3 form-check-label">
              Would you like to generate paper copies for the selected group?
            </label>
          </div>
        </BSForm.Group> */}
      </BSForm.Group>
      <br />
      <div className="text-end">
        <Button variant="primary" type="button" onClick={handleSubmit}>
          Next
        </Button>
      </div>
    </div>
  );

  // return (
  //   <div className={`${from === "Edit Event Page" && "card"}`}>
  //     {from === "Edit Event Page" && (
  //       <div className="card-header">Edit Details</div>
  //     )}

  //     <div className={`${from === "Edit Event Page" && "card-body bg-light"}`}>
  //       <Formik
  //         initialValues={{
  //           mail_type: user.mail_type || "",
  //           budget_year: user.budget_year || "",
  //           groups: user.groups || [],
  //         }}
  //         validationSchema={Yup.object({
  //           mail_type: Yup.string().required("mail type is required!"),
  //           // groups: Yup.string().required("groups is a required field!"),
  //         })}
  //         onSubmit={(values, { setSubmitting }) => {
  //           handleSubmit(values);
  //           setSubmitting(false);
  //         }}
  //       >
  //         {({
  //           isSubmitting,
  //           values,
  //           handleChange,
  //           handleBlur,
  //           setFieldValue,
  //         }) => (
  //           <Form>
  //             <BSForm.Group className="mb-3">
  //               <BSForm.Label>Mail Type</BSForm.Label>
  //               <Field
  //                 component="select"
  //                 id="mail_type"
  //                 name="mail_type"
  //                 className="form-select"
  //               >
  //                 <option value="">Select</option>
  //                 <option value="Budget Letter">Budget Letter</option>
  //                 <option value="Meeting Invitation">Meeting Invitation</option>
  //                 <option value="General Announcement">
  //                   General Announcement
  //                 </option>
  //               </Field>
  //               <ErrorMessage
  //                 name="mail_type"
  //                 component={"div"}
  //                 className="fs--1 text-danger"
  //               />
  //             </BSForm.Group>
  //             {values.mail_type === "Budget Letter" && (
  //               <BSForm.Group className="mb-3">
  //                 <BSForm.Label>Budget Year</BSForm.Label>
  //                 <Field
  //                   component="select"
  //                   id="budget_year"
  //                   name="budget_year"
  //                   className="form-select"
  //                 >
  //                   <option value="">Select</option>
  //                   {budgetsData?.map((v) => (
  //                     <option value={v._id} key={v._id}>
  //                       {v.start_of_fiscal_year} - {v.end_of_fiscal_year}
  //                     </option>
  //                   ))}
  //                 </Field>
  //                 <ErrorMessage
  //                   name="budget_year"
  //                   component={"div"}
  //                   className="fs--1 text-danger"
  //                 />
  //               </BSForm.Group>
  //             )}

  //             <BSForm.Group className="mb-3">
  //               <BSForm.Label id="checkbox-group">Groups</BSForm.Label>
  //               <div
  //                 role="group"
  //                 aria-labelledby="checkbox-group"
  //                 className="row"
  //               >
  //                 {/* {groupsArr?.map((v) => (
  //                   <Col key={v} md={4}>
  //                     <label>
  //                       <Field
  //                         type="checkbox"
  //                         className="form-check-inline"
  //                         name="groups"
  //                         value={v}
  //                         style={{ height: "16px", width: "16px" }}
  //                       />
  //                       {v}
  //                     </label>
  //                   </Col>
  //                 ))} */}
  //               </div>
  //               {/* <Select
  //                 closeMenuOnSelect={false}
  //                 // options={[]}
  //                 placeholder="Select..."
  //                 isMulti
  //                 classNamePrefix="react-select"
  //                 value={values.groups}
  //                 onChange={(value) => setFieldValue("groups", value)}
  //               /> */}
  //               <div className="">
  //                 {groups?.map((v) => (
  //                   <CustomChip
  //                   key={v+'hk'}
  //                     showRemoveKey
  //                     text={v}
  //                     groups={groups}
  //                     setGroups={setGroups}
  //                   />
  //                 ))}
  //               </div>
  //               <BSForm.Control
  //                 // as={"textarea"}
  //                 // rows={2}
  //                 onKeyUp={handleSearchBarAction}
  //                 placeholder="Search here for groups..."
  //               />
  //               <div className="mt-3">
  //                 {groupsArr?.map((v) => (
  //                   <CustomChip
  //                     key={v}
  //                     text={v}
  //                     groups={groups}
  //                     setGroups={setGroups}
  //                   />
  //                 ))}
  //               </div>
  //               <ErrorMessage
  //                 name="groups"
  //                 component={"div"}
  //                 className="fs--1 text-danger"
  //               />
  //             </BSForm.Group>
  //             <br />
  //             <div className="text-end">
  //               <Button
  //                 variant="primary"
  //                 type="submit"
  //                 disabled={isSubmitting || groups?.length === 0}
  //               >
  //                 Next
  //               </Button>
  //             </div>
  //           </Form>
  //         )}
  //       </Formik>
  //     </div>
  //   </div>
  // );
};

export default MailDetailsPage;

const CustomChip = ({
  showRemoveKey = false,
  text = "Group Here",
  mailOutState,
  setMailOutState,
}) => {
  const handleAddField = (text) => {
    let updatedGroups = [];
    if (mailOutState.groups?.includes(text)) {
      updatedGroups = [...mailOutState.groups];
    } else {
      if (
        text === "All Residents" ||
        mailOutState.groups.includes("All Residents")
      ) {
        updatedGroups = ["All Residents"];
      } else updatedGroups = mailOutState.groups?.concat(text);
    }
    setMailOutState({
      ...mailOutState,
      groups: updatedGroups,
    });
  };

  return (
    <div
      style={{
        borderRadius: "10px",
        backgroundColor: !showRemoveKey ? "#E5EDFF" : "#e5e4e2 ",
        opacity:
          !showRemoveKey &&
          mailOutState.groups?.length > 0 &&
          mailOutState?.groups?.includes("All Residents") &&
          text !== "All Residents"
            ? 0.5
            : 1,
        padding: "3px 8px",
        color: "#000",
        maxWidth: "fit-content",
        display: "inline-block",
        marginInlineEnd: "10px",
      }}
      className="mb-3"
    >
      {!showRemoveKey && (
        <button
          type="button"
          style={{
            border: "0",
            outline: "0",
            backgroundColor: "transparent",
            cursor: "pointer",
          }}
          onClick={() => handleAddField(text)}
        >
          +
        </button>
      )}
      {showRemoveKey && (
        <button
          type="button"
          style={{
            border: "0",
            outline: "0",
            backgroundColor: "transparent",
            cursor: "pointer",
            color: "red",
          }}
          onClick={() =>
            setMailOutState({
              ...mailOutState,
              groups: mailOutState.groups?.filter((j) => j !== text),
            })
          }
        >
          x
        </button>
      )}
      {text}
    </div>
  );
};
