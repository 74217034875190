import Modal from "react-bootstrap/Modal";

import React from "react";
import { Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

export default function PMModel({
  show,
  handleClose,
  payload,
  managerProfile,
  // updateProperty,
  getPropertyData,
  getManagersData,
  setPayload,
  setCurrentManagerID,
  newManager,
  hostname,
  jwtToken,
}) {
  // console.log(payload._id);
  console.log(managerProfile);
  let managerProfileActive = [];
  managerProfileActive = managerProfile?.filter(
    (v) =>
      v.verified === "Verified" &&
      v.Status === "1" &&
      (v.groups.includes("Site-Administrators") ||
        v.groups.includes("Condozak-Basic-Users"))
  );

  const { property_id } = useParams();

  const updatePropertyFun = (pm) => {
    // updateProperty({}, {}, true, pm);

    axios
      .post(
        `${hostname}/api/property/AssignPropertyManager`,
        {
          p_id: property_id,
          property_manager: pm,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then(() => {
        // console.log(res.data);
        toast.success("Property Updated Successfully", { theme: "colored" });
        getPropertyData();
        getManagersData();
        handleClose();
        setPayload({});
        setCurrentManagerID(pm);
        setTimeout(() => {
          window.location.reload();
        }, 10);
      })
      .catch((err) => {
        console.log(err);
        toast.error("something went wrong", { theme: "colored" });
      });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {newManager?._id ? "Transfer Property" : "Add Manager"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-3">
            Transfer the property manager responsibilities to another team
            member from today. All pending tasks will be moved to the new
            property manager.
          </p>

          <Formik
            initialValues={{ prop_manager: newManager._id || "" }}
            validate={(values) => {
              const errors = {};
              if (!values.prop_manager)
                errors.prop_manager = "required option!";
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              updatePropertyFun(values.prop_manager);
              //   updatePropertyManager(values.prop_manager, pm);
              // console.log(values.prop_manager);
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <label className="form-label">
                  Property Manager <span className="text-danger">*</span>
                </label>
                <Field
                  component="select"
                  id="prop_manager"
                  name="prop_manager"
                  className="form-select"
                  //  multiple={true}
                >
                  {/* <option value="">select</option> */}
                  {managerProfileActive?.map((v, i) => (
                    <option
                      key={v._id}
                      value={v._id}
                      // selected={property_manager === v._id}
                      className={payload._id === v._id ? "bg-success" : ""}
                    >
                      {`${v.firstname} ${v.lastname}`}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="manager"
                  component="div"
                  className="text-danger fs--1"
                />

                <br />

                <div className="text-end">
                  <Button
                    variant="falcon-warning"
                    type="submit"
                    disabled={isSubmitting}
                    className="me-3"
                  >
                    Confirm
                  </Button>
                  <Button
                    variant="falcon-default"
                    type="button"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}
