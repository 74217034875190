import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import { Card, Dropdown, ListGroup } from "react-bootstrap";
import {
  rawEarlierNotifications,
  rawNewNotifications,
} from "../../../data/notification/notification";
// import { isIterableArray } from "../../../helpers/utils";
import useFakeFetch from "../../../hooks/useFakeFetch";
import FalconCardHeader from "../../../components/common/FalconCardHeader";
// import Notification from "../../../components/notification/Notification";
import Cookies from "universal-cookie";
import axios from "axios";
import Avatar from "../../../components/common/Avatar";
import { startCase } from "lodash";
import "./BellStyles.css";
import { getHostName } from "../../../helpers/utils";
// let notificationsArr = [
//   {
//     id : '1q',
//     text : 'this is notification one',
//     time : 'Just Now',

//   }
// ]

const NotificationDropdown = ({ userData = {} }) => {
  const cookies = new Cookies();
   const jwtToken = cookies.get("_expire_t");
let hostname = getHostName();
  const [list, setList] = React.useState([]);

  const navigate = useNavigate();

  // State
  const { data: newNotifications, setData: setNewNotifications } =
    useFakeFetch(rawNewNotifications);
  const { data: earlierNotifications, setData: setEarlierNotifications } =
    useFakeFetch(rawEarlierNotifications);
  const [isOpen, setIsOpen] = useState(false);
  const [isAllRead, setIsAllRead] = useState(false);

  // Handler
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    //getInvitationData();
    window.addEventListener("scroll", () => {
      window.innerWidth < 1200 && setIsOpen(false);
    });
  }, []);

  const markAsRead = (e) => {
    e.preventDefault();

    const updatedNewNotifications = newNotifications.map((notification) =>
      Object.prototype.hasOwnProperty.call(notification, "unread")
        ? { ...notification, unread: false }
        : notification
    );
    const updatedEarlierNotifications = earlierNotifications.map(
      (notification) =>
        Object.prototype.hasOwnProperty.call(notification, "unread")
          ? { ...notification, unread: false }
          : notification
    );

    setIsAllRead(true);
    setNewNotifications(updatedNewNotifications);
    setEarlierNotifications(updatedEarlierNotifications);
  };

  const getInvitationData = () => {
    let email = userData.email;
    let property_url = window.location.hostname;
    let company_url = window.location.hostname;
    // console.log(property_url);
    // console.log(company_url);
    let link = `${hostname}/api/auth/ShowInvites`;
    if (userData.role === "No role Assigned") {
      //nothing, link already set
    }
    if (userData.role === "none" || userData.role === "unit_owner" || userData.role === "supp_resident" || userData.role === "supp_tenant") {
      link = `${hostname}/api/owner/OwnerInvites`;
    }

    axios
      .post(link, { email, property_url, company_url })
      .then((res) => {
        // console.log(res.data);
        if (userData.role === "none" || userData.role === "unit_owner" || userData.role === "supp_resident" || userData.role === "supp_tenant") {
          setList(res.data.data);
        } else {
          setList(res.data.data[0]);
        }
        // setLoader(false);
      })
      .catch((err) => {
        // console.log(err);
        // setLoader(false);
      });
  };

  return (
    <Dropdown navbar={true} as="li" show={isOpen} onToggle={handleToggle}>
      {/* <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className={classNames("px-0 nav-link", {
          "notification-indicator notification-indicator-success": !isAllRead,
        })}
      >
        <FontAwesomeIcon icon="bell" transform="shrink-6" className="fs-4" />
      </Dropdown.Toggle> */}
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className={classNames("px-0 nav-link", {
          // "notification-indicator notification-indicator-danger": !isAllRead,
        })}
      >
        <span className="">
          <FontAwesomeIcon icon="bell" transform="shrink-6" className="fs-4" />
          {list?.length > 0 && (
            <span className="chip-custom">
              {list?.length ? list?.length : ""}
            </span>
          )}
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret dropdown-caret-bg">
        <Card
          className="dropdown-menu-notification dropdown-menu-end shadow-none"
          style={{ maxWidth: "20rem" }}
        >
          <FalconCardHeader
            className="card-header"
            title="Notifications"
            titleTag="h6"
            light={false}
            endEl={
              <Link
                className="card-link fw-normal"
                to="#!"
                onClick={markAsRead}
              >
                Mark all as read
              </Link>
            }
          />
          <ListGroup
            variant="flush"
            className="fw-normal fs--1 scrollbar"
            style={{ maxHeight: "19rem" }}
          >
            <div className="list-group-title">NEW</div>{" "}
            {userData.role === "No role Assigned" &&
              list?.map((invite) => (
                <ListGroup.Item key={invite._id} onClick={handleToggle}>
                  {/* <Notification {...notification} flush /> */}
                  <div
                    className="p-3 d-flex justify-content-between align-items-center cursor-pointer"
                    onClick={() => navigate("/user/notifications")}
                  >
                    <Avatar name={invite?.company_name[0]} />
                    <span>
                      Invitation from{" "}
                      <span className="text-success">
                        {startCase(invite?.company_name) || "company name"}
                      </span>
                    </span>
                  </div>
                </ListGroup.Item>
              ))}
            {(userData.role === "none" || userData.role === "unit_owner" || userData.role === "supp_resident" || userData.role === "supp_tenant") &&
              list?.map((invite) => (
                <ListGroup.Item key={invite._id} onClick={handleToggle}>
                  {/* <Notification {...notification} flush /> */}
                  <div
                    className="p-3 d-flex justify-content-between align-items-center cursor-pointer"
                    onClick={() => navigate("/user/notifications")}
                  >
                    <Avatar name={invite?.property_name[0]} />
                    <span>
                      Invitation from{" "}
                      <span className="text-success">
                        {startCase(invite?.property_name) || "property name"}
                      </span>
                    </span>
                  </div>
                </ListGroup.Item>
              ))}
            {/* <div className="list-group-title">EARLIER</div>
            {isIterableArray(earlierNotifications) &&
              earlierNotifications.map((notification) => (
                <ListGroup.Item key={notification.id} onClick={handleToggle}>
                  <Notification {...notification} flush />
                </ListGroup.Item>
              ))} */}
          </ListGroup>
          <div
            className="card-footer text-center border-top"
            onClick={handleToggle}
          >
            <Link className="card-link d-block" to="/user/notifications">
              View all
            </Link>
          </div>
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NotificationDropdown;
