import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SoftBadge from "../../../../components/common/SoftBadge";
import { Table, Dropdown } from "react-bootstrap";
// import CardDropdown from "components/common/CardDropdown";

import { formatLogInfo } from "../../../../helpers/utils";

const TableRow = ({
  data,
  propLength,
  userData,
  // cancelBooking,
  // handleSetShowPayNowModal,
  // handlePaymentReceipt,
  functionHandleShowBookingDetailsModel,
}) => {
  console.log(userData);

  try {
    return (
      <tr
        className="align-middle"
        style={{ height: propLength === 1 ? "100px" : "" }}
      >
        <td
          className="text-nowrap text-center text-primary cursor-pointer"
          onClick={() => functionHandleShowBookingDetailsModel(data)}
        >
          {data._id.slice(0, 8)}
        </td>
        <td className="text-nowrap text-center">{data.amenityName}</td>
        <td className="text-nowrap text-center">{`${userData.firstname} ${userData.lastname}`}</td>
        <td className="text-nowrap text-center">{data.unit_num || "-"}</td>
        <td className="text-nowrap text-center">
          {formatLogInfo(data.date, true)}
          <br />
          {/* {data.timeslot?.split('-')[0] || 'timeslot'} */}
          {data.amenityType === "Hotel Style"
            ? data.timeslot?.split("-")[0]
            : data.timeslot || "timeslot"}
        </td>
        <td className="text-nowrap text-center">
          {formatLogInfo(data.createdAt)}
        </td>
        <td className="text-center">
          {/* {data.status.title} */}
          {/* {data.status || 'status'} */}
          <SoftBadge pill bg={data.status.type}>
            {data.status.title}
            <FontAwesomeIcon icon={data.status.icon} className="ms-2" />
          </SoftBadge>
        </td>
        {/* <td className="text-center">
          <CardDropdown>
            <div className="py-2">
              {data.status.title === "Confirmed" && (
                <>
                  <Dropdown.Item
                    onClick={() => cancelBooking(data._id)}
                    className="text-danger"
                  >
                    Cancel
                  </Dropdown.Item>
                </>
              )}

              {data.status.title === "Payment Pending" && (
                <>
                  <Dropdown.Item
                    onClick={() => cancelBooking(data._id)}
                    className="text-danger"
                  >
                    Cancel
                  </Dropdown.Item>
                </>
              )}

              {data.status.title === "Cancelled" && (
                <Dropdown.Item className="text-danger">Delete</Dropdown.Item>
              )}
            </div>
          </CardDropdown>
        </td> */}
      </tr>
    );
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
};

const EmptyRow = () => {
  return (
    <tr className="text-center text-info fw-bold fs-1">
      <td colSpan={8}>Nothing Found!</td>
    </tr>
  );
};

const BookedAmenitiesTable = ({
  list,
  userData,
  cancelBooking,
  handleSetShowPayNowModal,
  handlePaymentReceipt,
  functionHandleShowBookingDetailsModel,
}) => {
  try {
    return (
      <Table
        responsive
        striped
        hover
        bordered
        style={{ fontSize: "14px", textAlign: "center" }}
      >
        <thead>
          <tr>
            <th scope="col">Booking ID</th>
            <th scope="col">Amenity</th>
            <th scope="col">Booked By</th>
            <th scope="col">Unit #</th>
            <th scope="col">Booking Date</th>
            <th scope="col">Created On</th>
            <th scope="col" className="text-center">
              Status
            </th>
            {/* <th scope="col" className="text-center">
              Actions
            </th> */}
          </tr>
        </thead>
        <tbody>
          {list?.length > 0 ? (
            list?.map((amenity) => (
              <TableRow
                data={amenity}
                propLength={list.length}
                key={amenity._id}
                userData={userData}
                cancelBooking={cancelBooking}
                handleSetShowPayNowModal={handleSetShowPayNowModal}
                handlePaymentReceipt={handlePaymentReceipt}
                functionHandleShowBookingDetailsModel={
                  functionHandleShowBookingDetailsModel
                }
              />
            ))
          ) : (
            <EmptyRow />
          )}
        </tbody>
      </Table>
    );
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
};

export default BookedAmenitiesTable;
