import React, { useEffect, useState } from "react";

import Amenities from "./Amenities";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import axios from "axios";

import BookAmenityModal from "./BookAmenityModal";

// for 2nd
import {
  // Button,
  Card,
  Col,
  Row,
} from "react-bootstrap";

import Loader from "../../../../../components/common/Loader";

import { Link } from "react-router-dom";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName } from "../../../../../helpers/utils";

export default function AmenityBookings() {
  let hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const [amenitesList, setAmenitesList] = useState([]);
  const [amenitesListTemp, setAmenitesListTemp] = useState([]);
  const [loader, setLoader] = useState(false);

  //for select amenity to book
  const [selectedAmenity, setSelectedAmenity] = useState({});

  // booking modal
  const [showBookModal, setShowBookModal] = useState(false);
  const handleShowBookModal = () => setShowBookModal(true);
  const handleCloseBookModal = () => setShowBookModal(false);

  useEffect(() => {
    setLoader(true);

    getAmenitiesData();

    // let interverid = setInterval(() => {
    //   getAmenitiesData();
    // }, 5000);

    // return () => {
    //   clearInterval(interverid);
    // };
  }, []);

  const getAmenitiesData = () => {
    let p_id = userData.propid;
    

    axios
      .post(
        `${hostname}/api/amenity/Showallamenity`,
        {
          p_id,
          // p_id: '6401b0feb12357a2a59c303c'
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        let status = { type: "success", title: "Active", icon: "check" };
        let arr = [];
        res.data?.forEach((v) => {
          if (v.status === "Active") arr.push(Object.assign({ status }, v));
        });
        setAmenitesList(arr);
        setAmenitesListTemp(arr);
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const handleBookSelectedAmenity = (id) => {
    setLoader(true);
    let tmp = amenitesList.filter((v) => v._id === id);
    if (id)
      axios
        .post(
          `${hostname}/api/amenity/Showamenityinfo`,
          {
            Amenity_id: id,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          setSelectedAmenity({ ...tmp[0], ...res.data });
          setLoader(false);
        })
        .catch((e) => {
          console.log(e);
        });
    console.log(id);
    handleShowBookModal();
  };

  const refreshTable = () => {
    setLoader(true);
    getAmenitiesData();
  };

  if (loader) {
    return <Loader />;
  }

  return (
    <>
      <Row className="g-3 mb-3">
        <Col>
          <Card>
            <Card.Body className="d-flex align-items-center">
              <Link className="me-2 text-secondary" to="/dashboard">
                Home
              </Link>
              {"/"}

              <Link className="me-2 ms-2" to={`#!`}>
                Amenities
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="g-3">
        <Col>
          <Row className="mb-3 g-3">
            {amenitesList.length > 0 ? (
              amenitesList?.map((amenity) => (
                <Col key={amenity._id} md={6} xxl={4}>
                  {/* <CourseGrid course={course} /> */}
                  <Amenities
                    amenity={amenity}
                    handleBookSelectedAmenity={handleBookSelectedAmenity}
                  />
                </Col>
              ))
            ) : (
              <Card className="bg-transparent shadow-none">
                <Card.Body className="border-2 border-dashed border-400 border rounded text-center py-5">
                  <div className="fs--1">
                    <FontAwesomeIcon
                      icon="exclamation-triangle"
                      className="fs-6 mb-3"
                    />
                    <h5>Nothing Found!</h5>
                  </div>
                </Card.Body>
              </Card>
            )}
          </Row>
        </Col>
      </Row>

      <BookAmenityModal
        show={showBookModal}
        handleClose={handleCloseBookModal}
        selectedAmenity={selectedAmenity}
        getAmenitiesData={getAmenitiesData}
        userData={userData}
        hostname={hostname}
        jwtToken={jwtToken}
      />
    </>
  );
}
