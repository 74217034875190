import React from "react";

import Flex from "../../../../../../../components/common/Flex";
import { Button, Card, Col, Row } from "react-bootstrap";

import MailOutsTable from "./MailOutsTable";

// import CreateNewMailModal from "./CreateNewMailModal";

import axios from "axios";

// import Cookies from "universal-cookie";

import { Link } from "react-router-dom";
import Loader from "../../../../../../../components/common/Loader";
import { useParams } from "react-router-dom";
import CustomSearchbar from "../../../../../../../components/common/CustomSearchbar";
import UnitsManagementModel from "../../../../../../../components/pages/sharedUI/UnitsManagementModel";
import UnitDetailsComp from "./UnitDetailsComp";
import FalconCardHeader from "../../../../../../../components/common/FalconCardHeader";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName } from "../../../../../../../helpers/utils";

const AttachedUnits = ({ fromOwner, unitData }) => {
  let hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const { property_id, property_name, unit_number, unit_id } = useParams();

  const [propertiesData, setPropertiesData] = React.useState([]);
  const [propertiesDataTemp, setPropertiesDataTemp] = React.useState([
    ...propertiesData,
  ]);
  const [loader, setLoader] = React.useState(false);

  React.useEffect(() => {
    if (userData.role === "unit_owner" || userData.role === "supp_resident" || userData.role === "supp_tenant") {
      setPropertiesData(unitData);
    } else {
      getUnitsDetailData();
      getAvailabeFacility();
    }
  }, []);

  const getAllPropertiesData = () => {
    if (unit_id) {
      axios
        .post(
          `${hostname}/api/property/AllLinkedUnits`,
          {
            unit_id,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          setLoader(false);
           console.log(res.data);

          setPropertiesData(res.data.data);
          setPropertiesDataTemp(res.data.data);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  };
  const getUnitsDetailData = () => {
    setLoader(true);
    axios
      .post(
        `${hostname}/api/property/UnitDetails`,
        { unit_id },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
         console.log(res.data);
        let unitDataTmp = [
          res.data,
          ...res.data?.lockers,
          ...res.data?.parkings,
        ];
        setPropertiesData(unitDataTmp);
        setPropertiesDataTemp(unitDataTmp);

        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };

  const [payload, setPayload] = React.useState({});
  const [availabeFacility, setAvailablefacility] = React.useState([]);

  // create property modal

  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const attachUnit = () => {
    let obj = {};
    obj.loadType = "Attach Unit ";
    obj.property_id = property_id;
    obj.unit_id = unit_id;
    obj.availabeFacility = [...availabeFacility];
    setPayload({ ...obj });
    handleShow();
  };
  const detachUnit = (unit) => {
    unit.loadType = "Detach Unit";
    unit.property_id = property_id;
    unit.unit_id = unit_id;
    unit.availabeFacility = [...availabeFacility];
    setPayload({ ...unit });
    handleShow();
  };

  const getAvailabeFacility = () => {
    axios
      .post(
        `${hostname}/api/property/AvailableFacility`,
        {
          p_id: property_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setAvailablefacility(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addUnitOwner = (unit) => {
    unit.property_id = property_id;
    unit.loadType = "Add Unit Owner";
    setPayload({ ...unit });
    handleShow();
  };

  const reinviteUnitOwner = (unit) => {
    unit.property_id = property_id;
    unit.loadType = "Reinvite Unit Owner";
    setPayload({ ...unit });
    handleShow();
  };

  if (loader) {
    return <Loader />;
  } else {
    return (
      <>
        {!fromOwner && (
          <Row className="g-3 mb-3">
            <Col>
              <Card>
                <Card.Body className="d-flex align-items-center overflow-scroll scrollbar">
                  <Link className="me-2 text-secondary" to="/dashboard">
                    Home
                  </Link>
                  {"/"}
                  <Link
                    className="me-2 ms-2 text-secondary"
                    to="/property/management/buildings"
                  >
                    Properties
                  </Link>
                  {"/"}
                  <Link
                    className="me-2 ms-2 text-secondary"
                    to={`/property/management/buildings/${property_id}/${property_name}/profile`}
                  >
                    {property_name}
                  </Link>
                  {"/"}
                  <Link
                    className="me-2 ms-2 text-secondary"
                    to={`/property/management/buildings/${property_id}/${property_name}/units`}
                  >
                    Units
                  </Link>
                  {"/"}
                  <Link
                    className="me-2 ms-2 text-primary"
                    // to={`/property/management/buildings/${property_id}/${property_name}/${unit_number}/${unit_id}/units`}
                    to="#!"
                  >
                    {unit_number}
                  </Link>
                  {"/"}
                  <Link
                    className="me-2 ms-2 text-primary"
                    // to={`/${property_id}/${property_name}/${unit_number}/${unit_id}/owners`}
                    to={"#!"}
                  >
                    Unit Details
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
        {fromOwner && (
          <Row className="g-3 mb-3">
            <Col>
              <Card>
                <Card.Body className="d-flex align-items-center">
                  <Link className="me-2 text-secondary" to="/dashboard">
                    Home
                  </Link>
                  {"/"}
                  <Link className="ms-2 me-2" to="#!">
                    Unit Details
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
        <Row className="g-3 mb-3">
          <Col>
            <UnitDetailsComp
              unit={fromOwner ? unitData[0] : propertiesData[0]}
              fromOwner={fromOwner}
              addUnitOwner={addUnitOwner}
              reinviteUnitOwner={reinviteUnitOwner}
            />
          </Col>
        </Row>
        <Card>
          <FalconCardHeader title="Attached Units" className="bg-light" />
          <Card.Body className="" style={{ minHeight: "50vh" }}>
            <Flex justifyContent={"end"} alignItems={"start"} className="">
              {/* <h4>Attached Units</h4> */}
              {/* <Button variant="primary" size="sm" onClick={handleShow} /> */}
              {/* <Link
              to="/property/management/buildingInfo"
              variant="primary"
              size="sm"
            >
              Details
            </Link> */}
              {(userData.role === "Site-Administrators" ||
                userData.role === "Condozak-Basic-Users") && (
                <Button variant="primary" size="sm" onClick={attachUnit}>
                  + Attach Unit
                </Button>
              )}
            </Flex>
            <br />
            {/* <Row>
              <Col md="6">
                <p>long long text for property management</p>
              </Col>
            </Row> */}
            <Row className="flex-end-center mb-3 mt-3">
              <Col className=" d-flex justify-content-sm-end">
                <CustomSearchbar
                  searchFlags={["unit_number", "unit_type"]}
                  dataListTemp={propertiesDataTemp}
                  stateUpdator={setPropertiesData}
                  placeholders={["type", "number"]}
                />
              </Col>
            </Row>

            <MailOutsTable
              properties={propertiesData}
              getAllPropertiesData={getAllPropertiesData}
              detachUnit={detachUnit}
              userData={userData}
            />
            <br />
            
            <UnitsManagementModel
              show={show}
              handleClose={handleClose}
              payload={payload}
              setPayload={setPayload}
              userData={userData}
              getAllData={getUnitsDetailData}
              hostname={hostname}
              jwtToken={jwtToken}
            />
          </Card.Body>
        </Card>
      </>
    );
  }
};

export default AttachedUnits;
