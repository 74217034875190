// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import CartNotification from 'components/navbar/top/CartNotification';
import NotificationDropdown from "../../../components/navbar/top/NotificationDropdown";
import ProfileDropdown from "../../../components/navbar/top/ProfileDropdown";
// import AppContext from "../../../context/Context";
import React, { useContext } from "react";
import { Nav, OverlayTrigger, Tooltip, Form } from "react-bootstrap";
// import NineDotMenu from './NineDotMenu';
import PropTypes from "prop-types";
// import { useTranslation } from "react-i18next";

const TopNavRightSideNavItem = (props) => {
  // const {
  //   config: { isDark, isRTL },
  //   setConfig,
  // } = useContext(AppContext);

  // const [languageOfApp, setLanguageOfApp] = React.useState(
  //   window.localStorage.getItem("language") || "en"
  // );

  // const { i18n } = useTranslation();
  // const changeAppLanguage = (e) => {
  //   window.localStorage.setItem("language", e.target.value);
  //   setLanguageOfApp(e.target.value);
  //   i18n.changeLanguage(e.target.value);
  // };

  // React.useEffect(() => {
  //   setLanguageOfApp(window.localStorage.getItem("language"));
  // }, []);

  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      {/* <div>
        <Form.Select
          size="sm"
          onChange={changeAppLanguage}
          value={languageOfApp || "en"}
        >
          <option value={"en"}>English</option>
          <option value={"fr"}>French</option>
        </Form.Select>
      </div> */}
      {/* <Nav.Item>
        <Nav.Link href="#link">
          <Form.Select size="sm" id="customLang" onChange={changeAppLanguage}>
            <option value={'en'}>English</option>
            <option value={'fr'}>French</option>
          </Form.Select>
        </Nav.Link>
      </Nav.Item> */}
      {/* <Nav.Item as={"li"}>
        <Nav.Link
          className="px-2 theme-control-toggle"
          onClick={() => setConfig("isDark", !isDark)}
        >
          <OverlayTrigger
            key="left"
            placement={isRTL ? "bottom" : "left"}
            overlay={
              <Tooltip id="ThemeColor">
                {isDark ? "Switch to light theme" : "Switch to dark theme"}
              </Tooltip>
            }
          >
            <div className="theme-control-toggle-label">
              <FontAwesomeIcon
                icon={isDark ? "sun" : "moon"}
                className="fs-0"
              />
            </div>
          </OverlayTrigger>
        </Nav.Link>
      </Nav.Item> */}

      {/* <CartNotification /> */}

      <NotificationDropdown userData={props.userData} />
      {/* <NineDotMenu /> */}
      <ProfileDropdown
        userData={props.userData}
        avatar={props.avatar}
        profileDropdownData={props.profileDropdownData}
      />
    </Nav>
  );
};

TopNavRightSideNavItem.propTypes = {
  userData: PropTypes.object,
};

export default TopNavRightSideNavItem;
