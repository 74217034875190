// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import IconButton from "components/common/IconButton";
import React from "react";
import {
  // Button,
  // Card,
  Form,
  // FormControl,
  // InputGroup,
  Table,
} from "react-bootstrap";
// import PropTypes from 'prop-types';
import SimpleBarReact from "simplebar-react";

const TicketRow = ({
  day,
  start_time,
  start_timezone,
  end_time,
  end_timezone,
  id,
  handleChange,
  handleRemove,
  index,
  durationError
}) => {
  return (
    <tr>
      <td>{index + 1}</td>
      <td>
        <Form.Control
          size="sm"
          type="text"
          placeholder="HH:MM"
          value={start_time}
          onChange={({ target }) =>
            handleChange(id, "start_time", target.value)
          }
        />
        <div className="text-danger fs--1">{durationError?.start_time}</div>
        <div className="d-flex justify-content-end mt-2">
          AM{" "}
          <Form.Check
            type="radio"
            className="ms-1"
            name={`start_timezone_${index}`}
            value={"AM"}
            onChange={({ target }) =>
              handleChange(id, "start_timezone", target.value)
            }
            checked={start_timezone === "AM"}
          />
          <span className="ms-3"></span>
          PM
          <Form.Check
            type="radio"
            className="ms-1"
            name={`start_timezone_${index}`}
            value={"PM"}
            onChange={({ target }) =>
              handleChange(id, "start_timezone", target.value)
            }
            checked={start_timezone === "PM"}
          />{" "}
        </div>
        <div className="text-danger fs--1">{durationError?.start_timezone}</div>

      </td>
      <td className="text-center align-middle">
        <Form.Control
          type="text"
          placeholder="HH:MM"
          value={end_time}
          aria-label="Dollar amount (with dot and two decimal places)"
          size="sm"
          step="0.01"
          onChange={({ target }) => handleChange(id, "end_time", target.value)}
        />
        <div className="text-danger fs--1">{durationError?.end_time}</div>

        <div className="d-flex justify-content-end mt-2">
          AM{" "}
          <Form.Check
            type="radio"
            className="ms-1"
            name={`end_timezone_${index}`}
            value={"AM"}
            onChange={({ target }) =>
              handleChange(id, "end_timezone", target.value)
            }
            checked={end_timezone === "AM"}
          />
          <span className="ms-3"></span>
          PM
          <Form.Check
            type="radio"
            className="ms-1"
            name={`end_timezone_${index}`}
            value={"PM"}
            onChange={({ target }) =>
              handleChange(id, "end_timezone", target.value)
            }
            checked={end_timezone === "PM"}
          />{" "}
        </div>
        <div className="text-danger fs--1">{durationError?.end_timezone}</div>

      </td>
      {/* <td className="text-center align-middle">
        <Button variant="link" size="sm" onClick={() => handleRemove(id)}>
          <FontAwesomeIcon className="text-danger" icon="times-circle" />
        </Button>
      </td> */}
    </tr>
  );
};

const DurationTable = ({ tickets = [], setTickets, durationErrors = [] }) => {
  // Change Ticket

  console.log(tickets);

  const changeTicket = (id, name, value) => {
    const updatedTickets = [...tickets];
    const updatedTicket = { ...tickets[id], [name]: value };

    setTickets([
      ...updatedTickets.slice(0, id),
      updatedTicket,
      ...updatedTickets.slice(id + 1),
    ]);
  };

  // Remove Ticket
  // const removeTicket = (id) =>
  //   setTickets([...tickets.slice(0, id), ...tickets.slice(id + 1)]);

  // const handleAddTicket = () => {
  //   setTickets([...tickets, { name: "", price: 0 }]);
  // };

  return (
    <div className="mb-3">
      <SimpleBarReact>
        <Table
          className="bg-white text-center mb-2 dark__bg-1100 mt-3"
          bordered
        >
          <thead>
            <tr className="fs--1">
              <th scope="col">Day</th>
              <th scope="col">Start</th>
              <th scope="col">End</th>
              {/* <th></th> */}
            </tr>
          </thead>
          <tbody className="event-ticket-body">
            {tickets.map((item, index) => (
              <TicketRow
                {...item}
                id={index}
                key={index}
                handleChange={changeTicket}
                // handleRemove={removeTicket}
                index={index}
                durationError={durationErrors[index]}
              />
            ))}
          </tbody>
        </Table>
      </SimpleBarReact>
      <div className="mt-3"></div>
      {/* <IconButton
        onClick={handleAddTicket}
        variant="falcon-default"
        size="sm"
        icon="plus"
        transform="shrink-3"
      >
        Add Item
      </IconButton> */}
    </div>
  );
};

// TicketRow.propTypes = {
//   name: PropTypes.string.isRequired,
//   price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
//   checked: PropTypes.bool,
//   id: PropTypes.number.isRequired,
//   handleRemove: PropTypes.func.isRequired,
//   handleChange: PropTypes.func.isRequired
// };

export default DurationTable;
