import React from "react";

import {
  Form as BSForm,
  Button,
  Row,
  Col,
  // Image,
  // Table,
} from "react-bootstrap";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// import axios from "axios";
import { toast } from "react-toastify";
import { CalendarToday } from "@mui/icons-material";

import { AuthWizardContext } from "../../../../../context/Context";

import DatePicker from "react-datepicker";

import DurationTable from "./DurationTable";

// import * as Yup from "yup";

const EventDuration = ({ from = "", event = {}, updateEvent }) => {
  console.log(event);

  const { step, setStep, user, setUser } = React.useContext(AuthWizardContext);
  console.log(user);

  const [durationErrors, setDurationErrors] = React.useState([]);

  const getDurationState = () => {
    let tmparr = [
      {
        day: "1",
        start_time: "",
        start_timezone: "",
        end_time: "",
        end_timezone: "",
      },
    ];

    if (from === "Edit Event Page") return event.event_days;
    else if (user.eventDays?.length > 0) return user.eventDays;
    else return tmparr;
  };

  const [eventDays, setEventDays] = React.useState(
    getDurationState()

    // { name: "Ticket Name", price: 0.0, checked: true },
    // user.eventDays?.length > 0
    //   ? user.eventDays
    //   : [
    //       {
    //         day: "1",
    //         start_time: "",
    //         start_timezone: "",
    //         end_time: "",
    //         end_timezone: "",
    //       },
    //     ]

    // { name: "Green gallery", price: 5.0 },
    // { name: "VIP", price: 20.0 },
  );

  const forInputrefHSS = React.useRef(null);

  const handeInputRefHSS = () => {
    forInputrefHSS.current?.setFocus();
  };

  const forInputrefHSE = React.useRef(null);

  const handeInputRefHSE = () => {
    forInputrefHSE.current?.setFocus();
  };

  const forInputrefHSR = React.useRef(null);

  const handeInputRefHSR = () => {
    forInputrefHSR.current?.setFocus();
  };

  const handleSubmit = (data) => {
    console.log(data);
    console.log(eventDays);

    let errors = validate();
    console.log(errors);

    let areErrors = false;

    errors?.forEach((v) => {
      console.log(v);
      if (Object.keys(v).length) areErrors = true;
    });

    if (areErrors) {
      toast.error("please correct time data!", { theme: "colored" });
      console.log(errors);
      setDurationErrors(errors);
      return;
    }
    setDurationErrors([]);
    console.log("move forward");

    data.eventDays = eventDays;
    //return;
    setUser(Object.assign({}, user, data));
    setStep(step + 1);
  };

  const handleDateUpdate = (e, setFieldValue, isEndDate, values) => {
    let date = e;
    let date_formatted = new Date(date);

    let days = 0;

    if (!isEndDate) {
      setFieldValue("event_start_date", date);
      if (date > values.event_end_date) {
        setFieldValue("event_end_date", date);
        // setFieldValue("event_registration_deadline", date);
      }
      days = getDaysFromDatesRange(date_formatted, values.event_end_date);
    }
    if (isEndDate) {
      setFieldValue("event_end_date", date);
      days = getDaysFromDatesRange(values.event_start_date, date_formatted);
    }

    console.log(days);

    if (days < 1) days = 1;

    setFieldValue("event_total_days", days);

    // { name: "Ticket Name", price: 0.0, checked: true },

    let arr = [];

    for (let i = 0; i < days; i++) {
      let obj = {};
      obj.day = `${i + 1}`;
      obj.start_time = eventDays[i]?.start_time || "";
      obj.start_timezone = eventDays[i]?.start_timezone || "";
      obj.end_time = eventDays[i]?.end_time || "";
      obj.end_timezone = eventDays[i]?.end_timezone || "";
      arr.push(obj);
    }

    console.log(arr);

    setEventDays([...arr]);
  };

  const getDaysFromDatesRange = (start, end) => {
    let days = (end - start) / (1000 * 3600 * 24);
    days = Math.ceil(days);
    days++; // includes last day
    console.log(days);

    return days;
  };

  const handleStepMinus = (values) => {
    setUser(Object.assign({}, user, values));

    setStep(step - 1);
  };

  const handleUpdateEvent = (values) => {
    let errors = validate();
    console.log(errors);

    let areErrors = false;

    errors?.forEach((v) => {
      console.log(v);
      if (Object.keys(v).length) areErrors = true;
    });

    if (areErrors) {
      toast.error("please correct time data!", { theme: "colored" });
      console.log(errors);
      setDurationErrors(errors);
      return;
    }
    setDurationErrors([]);
    console.log("move forward");

    let obj = { ...values };
    obj.eventDays = eventDays;
    updateEvent(obj);
  };

  const copyEntryInAllFields = () => {
    let sObj = { ...eventDays[0] };

    let arr = [];

    for (let i = 0; i < eventDays?.length; i++) {
      let obj = {};
      obj.day = `${i + 1}`;
      obj.start_time = sObj.start_time;
      obj.start_timezone = sObj.start_timezone;
      obj.end_time = sObj.end_time;
      obj.end_timezone = sObj.end_timezone;
      arr.push(obj);
    }

    toast.success("Copied entry in all Fields!", { theme: "colored" });
    console.log(arr);

    setEventDays([...arr]);
  };

  const validate = () => {
    let re = /^(0?[1-9]|1[0-2]):[0-5][0-9]$/; // Time format regex for 12-hour HH:mm format (without AM/PM)
    
    // Helper function to convert 12-hour time with AM/PM to 24-hour format
    const convertTo24Hour = (time, ampm) => {
      let [hours, minutes] = time.split(':').map(Number);
      if (ampm.toUpperCase() === 'PM' && hours !== 12) {
        hours += 12; // PM case, convert hours to 24-hour format
      }
      if (ampm.toUpperCase() === 'AM' && hours === 12) {
        hours = 0; // Midnight edge case
      }
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    };
  
    let state = [...eventDays];
    let arr = state?.map((v, i) => {
      let obj = {};
  
      // Validate start time
      if (!v.start_time) {
        obj.start_time = "Start time is required!";
      } else if (!re.test(v.start_time)) {
        obj.start_time = "Invalid start time (HH:mm)!";
      }
  
      // Validate start timezone
      if (!v.start_timezone) {
        obj.start_timezone = "Start time zone is required!";
      }
  
      // Validate end time
      if (!v.end_time) {
        obj.end_time = "End time is required!";
      } else if (!re.test(v.end_time)) {
        obj.end_time = "Invalid end time (HH:mm)!";
      }
  
      // Validate end timezone
      if (!v.end_timezone) {
        obj.end_timezone = "End time zone is required!";
      }
  
      // Compare times if all fields are valid
      if (
        v.start_time &&
        v.end_time &&
        re.test(v.start_time) &&
        re.test(v.end_time) &&
        v.start_timezone &&
        v.end_timezone
      ) {
        // Convert start and end times to 24-hour format
        const startTime24 = convertTo24Hour(v.start_time, v.start_timezone);
        const endTime24 = convertTo24Hour(v.end_time, v.end_timezone);
  
        // Create Date objects for comparison
        let startDate = new Date(`1970-01-01T${startTime24}:00`);
        let endDate = new Date(`1970-01-01T${endTime24}:00`);
  
        // Compare the times
        if (endDate < startDate) {
          obj.end_time = "End time cannot be before start time!";
        }
      }
  
      return obj;
    });
  
    return arr;
  };
  


  return (
    <div className={`${from === "Edit Event Page" && "card"}`}>
      {from === "Edit Event Page" && (
        <div className="card-header">Edit Details</div>
      )}

      <div className={`${from === "Edit Event Page" && "card-body bg-light"}`}>
        <Formik
          initialValues={{
            event_start_date:
              from === "Edit Event Page"
                ? new Date(event.event_start_date)
                : user.event_start_date || new Date(),
            event_end_date:
              from === "Edit Event Page"
                ? new Date(event.event_end_date)
                : user.event_end_date || new Date(),
            event_registration_deadline:
              from === "Edit Event Page"
                ? new Date(event.event_registration_deadline)
                : user.event_registration_deadline || "",
            event_total_days:
              from === "Edit Event Page"
                ? event.event_days.length
                : user.event_total_days || 1,

            // event_start_time: user.event_start_time || "",
            // event_start_timezone: user.event_start_timezone || "",
            // event_end_time: user.event_end_time || "",
            // event_end_timezone: user.event_end_timezone || "",
            // ...getInitialState(this.event_start_date, this.event_end_date),
          }}
          validate={(values) => {
            const errors = {};
            if (values.event_start_date > values.event_end_date) {
              errors.event_start_date = "invalid date value!";
            }
            return errors;
          }}
          validationSchema={Yup.object({
            event_start_date: Yup.string().required("start date is required!"),
            event_end_date: Yup.string().required("end date is required!"),
            event_registration_deadline: Yup.string().required(
              "registration is required!"
            ),

            // event_start_time: Yup.string().required("start time is required!"),
            // event_start_timezone: Yup.string().required(
            //   "timezone is required!"
            // ),
            // event_end_time: Yup.string().required("end time is required!"),
            // event_end_timezone: Yup.string().required("timezone is required!"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values);
            setSubmitting(false);
          }}
        >
          {({
            isSubmitting,
            values,
            handleChange,
            handleBlur,
            setFieldValue,
          }) => (
            <Form>
              <Row className="mb-3">
                <Col md={6}>
                  <BSForm.Group>
                    <BSForm.Label>Start Date</BSForm.Label>
                    <div className=" position-relative">
                      <DatePicker
                        ref={forInputrefHSS}
                        // showIcon
                        placeholderText="Select Start Date"
                        selected={values.event_start_date}
                        name="event_start_date"
                        type="date"
                        onChange={(e) =>
                          handleDateUpdate(e, setFieldValue, false, values)
                        }
                        onBlur={handleBlur}
                        value={values.event_start_date}
                        className="form-control"
                        minDate={new Date()}
                        // maxDate={values.event_end_date}
                      />
                      <span
                        className="position-absolute translate-middle top-50 end-0 cursor-pointer"
                        onClick={handeInputRefHSS}
                      >
                        <CalendarToday fontSize="small" />
                      </span>
                    </div>
                    <ErrorMessage
                      name="event_start_date"
                      component={"div"}
                      className="fs--1 text-danger"
                    />
                  </BSForm.Group>
                </Col>
                <Col md={6}>
                  <BSForm.Group>
                    <BSForm.Label>End Date</BSForm.Label>

                    <div className=" position-relative">
                      <DatePicker
                        ref={forInputrefHSE}
                        // showIcon
                        placeholderText="Select End Date"
                        selected={values.event_end_date}
                        name="event_end_date"
                        type="date"
                        onChange={(e) =>
                          handleDateUpdate(e, setFieldValue, true, values)
                        }
                        onBlur={handleBlur}
                        value={values.event_end_date}
                        className="form-control"
                        minDate={values.event_start_date}
                      />
                      <span
                        className="position-absolute translate-middle top-50 end-0 cursor-pointer"
                        onClick={handeInputRefHSE}
                      >
                        <CalendarToday fontSize="small" />
                      </span>
                    </div>

                    <ErrorMessage
                      name="event_end_date"
                      component={"div"}
                      className="fs--1 text-danger"
                    />
                  </BSForm.Group>
                </Col>
              </Row>

              <div className="d-flex justify-content-between">
                <div>
                  Event Duration : {eventDays?.length} Day
                  {eventDays?.length > 1 && "s"}
                </div>
                <Button
                  size="sm"
                  variant="falcon-success"
                  disabled={
                    eventDays[0]?.start_time === "" ||
                    eventDays[0]?.start_timezone === "" ||
                    eventDays[0]?.end_time === "" ||
                    eventDays[0]?.end_timezone === ""
                  }
                  onClick={() => copyEntryInAllFields()}
                >
                  copy entry in all fields
                </Button>
              </div>
              <DurationTable
                tickets={eventDays}
                durationErrors={durationErrors}
                setTickets={setEventDays}
              />
              <BSForm.Group className="mb-3">
                <BSForm.Label>Registration Deadline</BSForm.Label>
                <div className=" position-relative">
                  <DatePicker
                    ref={forInputrefHSR}
                    // showIcon
                    placeholderText="Registration deadline"
                    selected={values.event_registration_deadline}
                    name="event_registration_deadline"
                    type="date"
                    onChange={(date) =>
                      setFieldValue("event_registration_deadline", date)
                    }
                    onBlur={handleBlur}
                    value={values.event_registration_deadline}
                    className="form-control"
                    minDate={new Date()}
                    // minDate={values.event_start_date}
                    maxDate={values.event_end_date}
                  />
                  <span
                    className="position-absolute translate-middle top-50 end-0 cursor-pointer"
                    onClick={handeInputRefHSR}
                  >
                    <CalendarToday fontSize="small" />
                  </span>
                </div>
                {/* <Field
                  name="event_registration_deadline"
                  type="date"
                  className="form-control"
                /> */}
                <ErrorMessage
                  name="event_registration_deadline"
                  component={"div"}
                  className="fs--1 text-danger"
                />
              </BSForm.Group>
              <br />
              {from !== "Edit Event Page" && (
                <div className="">
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={() => handleStepMinus(values)}
                  >
                    Previous
                  </Button>
                  <Button
                    variant="primary"
                    className="float-end"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Next
                  </Button>
                </div>
              )}
              {from === "Edit Event Page" && (
                <div className="text-end">
                  <Button
                    variant="primary"
                    onClick={() => handleUpdateEvent(values)}
                  >
                    Update
                  </Button>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EventDuration;
