import Calendar from "../../../../components/common/Calendar";
import CardDropdown from "../../../../components/common/CardDropdown";
import Flex from "../../../../components/common/Flex";
import React from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function EventsList({
  eventsList = [],
  editEvent,
  viewEvent,
  deleteEvent,
  userData = {},
}) {
  return (
    <Row className="fs--1">
      {eventsList.length < 1 && (
        <Col md={12} className="h-100">
          <p className="text-center fs-2">
            There are currently no events scheduled.
          </p>
        </Col>
      )}
      {eventsList?.map((event, index) => (
        <Col key={event._id} md={6} className="h-100">
          <Event
            event={event}
            isLast={index === eventsList.length - 1}
            editEvent={editEvent}
            viewEvent={viewEvent}
            deleteEvent={deleteEvent}
            userData={userData}
          />
        </Col>
      ))}
    </Row>
  );
}

function Event({
  event = {},
  editEvent,
  viewEvent,
  deleteEvent,
  userData = {},
}) {
  // console.log(userData);
  return (
    <Flex className="position-relative">
      <Calendar {...event.calendar} />
      {/* <DatePoster /> */}

      <div className="flex-1 position-relative ps-3">
        <h6 className="fs-0 mb-2">
          <span
            className="text-primary cursor-pointer"
            onClick={() => viewEvent({ event_id: event._id })}
          >
            <span className="me-1 mb-2">{event.event_name}</span>

            {/* <span className={`badge badge-success rounded-pill `}> */}
            {event.event_ticket?.event_ticket_type === "Free Ticket" && (
              <span className={`badge bg-success`}>Free</span>
            )}
          </span>
        </h6>
        <p className="mb-1">
          <b>Organized by:</b>
          <Link to="#!" className="text-700 ps-1 mb-2">
            {event.event_organizer}
          </Link>
        </p>
        <p className="text-1000 mb-0">
          <b>Time:</b> {event.time}
        </p>

        {event.duration && (
          <p className="text-1000 mb-0">
            <b>Duration:</b> {event.duration}
          </p>
        )}
        {event.duration && (
          <p className="text-1000 mb-0">
            <b>Days:</b> {event.event_days?.length}{" "}
          </p>
        )}
        {/* {interested && <p className="text-1000 mb-0">{interested}</p>} */}
        {event.venue && (
          <p className="text-1000 mb-0">
            <b>Venue:</b> {event.venue}
          </p>
        )}
        {event?.isRegistered && (
          <div className="badge bg-success ms-1 fs--1 ">Registered</div>
        )}

        {/* <p className="mb-0">Place: {place}</p> */}
        {/* {!event.isLast && <div className="my-3"></div>} */}
        {!event.isLast && (
          <div className="border-dashed border-bottom my-5"></div>
        )}
      </div>
      {userData?.role === "Site-Administrators" && (
        <CardDropdown
          iconClassName="fs-1"
          className="position-absolute top-0 end-0 translate-middle me-3"
        >
          <div className="py-2">
            <Dropdown.Item onClick={() => viewEvent({ event_id: event._id })}>
              View as Resident
            </Dropdown.Item>
            <Dropdown.Item className="text-info" onClick={() => editEvent(event)}>Edit</Dropdown.Item>
            <Dropdown.Item
              onClick={() => deleteEvent(event)}
              className="text-danger"
            >
              Delete
            </Dropdown.Item>
          </div>
        </CardDropdown>
      )}
    </Flex>
  );
}
