import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Avatar from 'components/common/Avatar';
// import Image from './Image';
import SoftBadge from "../../../../../components/common/SoftBadge";
// import { Badge, Table, Dropdown } from 'react-bootstrap';
// import { Dropdown } from 'react-bootstrap';
import { Badge, Table } from "react-bootstrap";
import { formatLogInfo } from "../../../../../helpers/utils";
// import { Link } from 'react-router-dom';
// import CardDropdown from 'components/common/CardDropdown';

const TableRow = ({ data, showRequestDetail }) => {
  // const TableRow = ({ data, selectBuilding, propLength }) => {
  // console.log(data.status.type);

  return (
    <tr
      className="align-middle"
      // style={{ 'height': '100px' }}
    >
      <td className="text-center">{data?._id?.slice(0, 8) || "id"}</td>
      <td className="text-center">{data?.generated_by_id?.firstname || "name"}</td>
      <td className="text-center">{data?.unit_id?.unit_number || "number"}</td>
      <td className="text-center">
        {data.request_type || "type"}
        {/* <Badge className="bg-success">{data.request_type || "type"}</Badge> */}
      </td>
      

      <td
        className="text-center text-primary cursor-pointer"
        onClick={() => showRequestDetail(data)}
      >
        {data.subject || "subject"}
      </td>

      <td className="text-center">
        <SoftBadge pill bg={data?.status?.type}>
          {data?.status?.title}
          <FontAwesomeIcon icon={data?.status?.icon} className="ms-2" />
        </SoftBadge>
      </td>
      {/* <td className="text-center">
        <div
          style={{
            "--falcon-circle-progress-bar": {
              title: "Urgent",
              color: "#e63757",
              data: 75,
            },
          }}
        >
          <svg
            className="circle-progress-svg"
            width="26"
            height="26"
            viewBox="0 0 120 120"
          >
            <circle
              className="progress-bar-rail"
              cx="60"
              cy="60"
              r="54"
              fill="none"
              strokeLinecap="round"
              strokeWidth="12"
            ></circle>
            <circle
              className="progress-bar-top"
              cx="60"
              cy="60"
              r="54"
              fill="none"
              strokeLinecap="round"
              stroke={data?.priority_level?.type?.color}
              strokeWidth="12"
            ></circle>
          </svg>
        </div>
      </td> */}
      <td className="text-center">
        {formatLogInfo(data.createdAt) || "address"}
      </td>
      {/* <td className="text-center"> */}
        {/* <CardDropdown>
          <div className="py-2">
            <Dropdown.Item>
              <Link
                to={`/property/management/buildings/${data._id}/${data.property_name}/profile`}
                className="d-block"
              >
                Edit
              </Link>
            </Dropdown.Item>
            <Dropdown.Item
              className="text-danger"
              style={{'fontWeight':'bold'}}
              onClick={() => inviteUser(data)}
            >
              Deactivate
            </Dropdown.Item>
          </div>
        </CardDropdown> */}
        {/* {data.sent} */}
      {/* </td> */}
    </tr>
  );
};

const ServiceRequestsTable = ({ list, showRequestDetail }) => {
  console.log(list);

  return (
    <Table
      responsive
      striped
      hover
      bordered
      style={{ fontSize: "14px" }}
      className="pb-5"
    >
      <thead>
        <tr>
          <th scope="col" className="text-center">
            Request ID
          </th>
          <th scope="col" className="text-center">
            Resident
          </th>
          <th scope="col" className="text-center">
            Unit #
          </th>

          <th scope="col" className="text-center">
            Request Type
          </th>
          <th scope="col" className="text-center">
            Subject
          </th>
          <th scope="col" className="text-center">
            Status
          </th>
          {/* <th scope="col" className="text-center">
            Priority
          </th> */}
          <th scope="col" className="text-center">
            Created On
          </th>
        </tr>
      </thead>
      <tbody>
        {list?.map((item, index) => (
          <TableRow
            key={item?._id || index}
            data={item}
            showRequestDetail={showRequestDetail}
            propLength={list.length}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default ServiceRequestsTable;
