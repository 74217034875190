import React from "react";
import { Card, Col, Row } from "react-bootstrap";

let BookedAmenityArr = [
  {
    name: "Party Room",
    date: "11 Nov, 2024 - 12 Nov, 2024",
    time: "09:00 AM - 05:00 PM",
  },
];

const AmenityItem = ({ item, index }) => {
  //const percentage = ((value * 100) / total).toFixed(0);
  return (
    <div className="bg-dange pb-0 mb-0">
      <div style={{ display: "flex" }}>
        <div
          style={{ width: "3px", marginRight: "12px", borderRadius: "3px" }}
          className="bg-primary"
        ></div>
        <div>
          <p className="m-0 p-0 fw-bold">{item.name}</p>

          <span className="me-3 fs--1 fw-bold">{item.date}</span>
          <span className="fs--1">{item.time}</span>
        </div>
      </div>
      <hr />
    </div>
  );
};

const UpcomingAmenityBookings = (props) => {

  // console.log(props);
  const {BookedAmenityDB} = props;
  // console.log(typeof BookedAmenityDB);
  

  return (
    <Card className="h-md-100">
      <Card.Body>
        <Row className=" g-0">
          <Col className="pe-2">
            <h6 className="mt-1 mb-4">Upcoming Amenity Booking</h6>

            {BookedAmenityDB.map((item, index) => (
              <AmenityItem item={item} index={index} key={index} />
            ))}

            {BookedAmenityDB.length < 1 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // height: "100%",
                }}
              >
                No upcoming booking
              </div>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default UpcomingAmenityBookings;
