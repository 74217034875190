import SoftBadge from "../../../../components/common/SoftBadge";
import React from "react";
// import { Dropdown } from "react-bootstrap";
import { Table } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import CardDropdown from "common/CardDropdown";

const TableRow = ({ data, propLength, viewVoilation }) => {
  return (
    <tr
      className="align-middle"
      style={{ height: propLength === 1 ? "100px" : "" }}
    >
      <td className="text-center">{data?._id.slice(0, 8) || "234234"}</td>
      <td
        className="text-primary cursor-pointer"
        onClick={() => viewVoilation(data)}
      >
        {data.subject || "name"}
      </td>
      <td className="text-center">{data.violation_type || "type"}</td>
      <td className="text-center">{data.unit_id.unit_number || "12"}</td>
      <td className="text-center">
        <SoftBadge bg={data.stage.type} className="px-3 py-2">
          {data.stage.title}
        </SoftBadge>
      </td>
      <td className="text-center">{data.createdAt}</td>
    </tr>
  );
};

const VoilationTrackingTable = ({ list = [], viewVoilation }) => {
  // console.log(list);

  return (
    <Table
      responsive
      striped
      hover
      bordered
      style={{ fontSize: "14px" }}
      className="pb-5"
    >
      <thead>
        <tr>
          <th scope="col" className="text-center">
            Violation ID
          </th>
          <th scope="col" className="text-center">
            Subject
          </th>
          <th scope="col" className="text-center">
            Violation Type
          </th>
          <th scope="col" className="text-center">
            Unit #
          </th>
          <th scope="col" className="text-center">
            Violation Stage
          </th>
          {/* <th scope="col" className="text-center">
            Date
          </th>
          <th scope="col" className="text-center">
            Deadline
          </th> */}
          <th scope="col" className="text-center">
            Created On
          </th>
        </tr>
      </thead>
      <tbody>
        {list?.map((item, index) => (
          <TableRow
            data={item}
            key={index}
            viewVoilation={viewVoilation}
            propLength={list.length}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default VoilationTrackingTable;
