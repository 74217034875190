import React from "react";
import { Modal, Button, Form as BSForm } from "react-bootstrap";

import FalconCloseButton from "../../../../../components/common/FalconCloseButton";
import { CalendarToday } from "@mui/icons-material";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";

import DatePicker from "react-datepicker";
// import { formatToCurrency } from "helpers/utils";

function BudgetModel({
  show,
  handleClose,
  payload,
  budgetDates,
  setPayload,
  getAllData,
  hostname,
  jwtToken,
}) {
  const ref1 = React.useRef(null);

  const handeRef1 = () => {
    ref1.current?.setFocus();
  };
  const ref2 = React.useRef(null);

  const handeRef2 = () => {
    ref2.current?.setFocus();
  };

  const createBudget = (values) => {
    let budget_data = { ...values };
    budget_data.p_id = payload.p_id;
    // budget_data.start_of_fiscal_year.setHours(24, 0, 0, 0);
    // budget_data.end_of_fiscal_year.setHours(24, 0, 0, 0);
    if (!budgetDates.start_date) {
      budgetDates.end_date = getDateInit(true, true);
    }
    axios
      .post(
        `${hostname}/api/property/CreateBudget`,
        {
          ...budget_data,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        getAllData();
        handleClose();
        toast.success("Budget created.", { theme: "colored" });
        setPayload({});
      })
      .catch((err) => {
        console.log(err);
        getAllData();
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };
  const updateBudget = (values) => {
    let budget_data = { ...values };
    budget_data.budget_id = payload?._id;
    // budget_data.total_budget = formatToCurrency(+values.total_budget);
    // budget_data.start_of_fiscal_year.setHours(24, 0, 0, 0);
    // budget_data.end_of_fiscal_year.setHours(24, 0, 0, 0);
    axios
      .post(
        `${hostname}/api/property/UpdateBudget`,
        {
          ...budget_data,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        getAllData();
        handleClose();
        toast.info("Budget updated.", { theme: "colored" });
        setPayload({});
      })
      .catch((err) => {
        console.log(err);
        getAllData();
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };
  const deleteBudget = () => {
    axios
      .post(
        `${hostname}/api/property/DeleteBudget`,
        {
          budget_id: payload?._id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        getAllData();
        handleClose();
        toast.warning("Budget deleted.", { theme: "colored" });
        setPayload({});
      })
      .catch((err) => {
        console.log(err);
        getAllData();
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };

  const getDateInit = (end = false, regOnly = false) => {
    let year = new Date().getFullYear();
    let month = new Date().getMonth();
    let day = new Date().getDate();
    let date = null;
    if (!end) date = new Date(year, month, day, 0, 0, 0, 0);
    else if (regOnly) date = new Date(year + 1, month, day, 0, 0, 0, 0);
    else date = new Date(year + 1, month, day - 1, 0, 0, 0, 0);

    return date;
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>{payload.loadType}</Modal.Title>
        <FalconCloseButton onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        {payload.loadType === "Create Budget" && (
          <div>
            <Formik
              initialValues={{
                start_of_fiscal_year: budgetDates?.start_date || getDateInit(),
                end_of_fiscal_year: budgetDates?.end_date || getDateInit(true),
                total_budget: "",
              }}
              validate={(values) => {
                const errors = {};

                return errors;
              }}
              validationSchema={Yup.object({
                start_of_fiscal_year: Yup.string().required("required field!"),
                end_of_fiscal_year: Yup.string().required("required field!"),
                total_budget: Yup.string()
                  .required("required field!")
                  .matches(
                    /^\d+(\.\d+)?$/,
                    "Invalid value! use only positive numbers!"
                  ),
              })}
              onSubmit={(values, { setSubmitting }) => {
                createBudget(values);
                setSubmitting(false);
              }}
            >
              {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                  <BSForm.Group className="mb-3">
                    <BSForm.Label>Start of Fiscal Year</BSForm.Label>
                    <div className="position-relative">
                      <DatePicker
                        ref={ref1}
                        selected={values.start_of_fiscal_year}
                        name="start_of_fiscal_year"
                        className="form-control"
                        placeholderText="Start of fiscal year"
                        onChange={(date) => {
                          setFieldValue("start_of_fiscal_year", date);
                          var year = date.getFullYear();
                          var month = date.getMonth();
                          var day = date.getDate();
                          var c = new Date(year + 1, month, day - 1);
                          // console.log(c);
                          setFieldValue("end_of_fiscal_year", c);
                        }}
                        disabled={budgetDates.start_date}
                      />
                      <span
                        className="position-absolute translate-middle top-50 end-0 cursor-pointer"
                        onClick={handeRef1}
                      >
                        <CalendarToday fontSize="small" />
                      </span>
                    </div>
                    <ErrorMessage
                      name="start_of_fiscal_year"
                      component="div"
                      className="text-danger fs--1"
                    />
                  </BSForm.Group>
                  <BSForm.Group className="mb-3">
                    <BSForm.Label>End of Fiscal Year</BSForm.Label>
                    <div className="position-relative">
                      <DatePicker
                        ref={ref2}
                        selected={values.end_of_fiscal_year}
                        minDate={values.start_of_fiscal_year}
                        name="end_of_fiscal_year"
                        className="form-control"
                        placeholderText="end of fiscal year"
                        onChange={(date) =>
                          setFieldValue("end_of_fiscal_year", date)
                        }
                        disabled
                      />
                      <span
                        className="position-absolute translate-middle top-50 end-0 cursor-pointer"
                        onClick={handeRef2}
                      >
                        <CalendarToday fontSize="small" />
                      </span>
                    </div>

                    <ErrorMessage
                      name="end_of_fiscal_year"
                      component="div"
                      className="text-danger fs--1"
                    />
                  </BSForm.Group>
                  <BSForm.Group className="mb-3">
                    <BSForm.Label>Total Budget</BSForm.Label>
                    <Field
                      type="text"
                      name="total_budget"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="total_budget"
                      component="div"
                      className="text-danger fs--1"
                    />
                  </BSForm.Group>
                  <div className="text-end mt-2">
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Create
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
        {payload.loadType === "Update Budget" && (
          <div>
            <Formik
              initialValues={{
                start_of_fiscal_year: new Date(payload.start_of_fiscal_year),
                end_of_fiscal_year: new Date(payload.end_of_fiscal_year),
                // start_of_fiscal_year: budgetDates?.start_date || "",
                // end_of_fiscal_year: budgetDates?.end_date || "",
                total_budget: payload.total_budget,
              }}
              validate={(values) => {
                const errors = {};

                return errors;
              }}
              validationSchema={Yup.object({
                start_of_fiscal_year: Yup.string().required("required field!"),
                end_of_fiscal_year: Yup.string().required("required field!"),
                total_budget: Yup.string()
                  .required("required field!")
                  .matches(
                    /^\d+(\.\d+)?$/,
                    "Invalid value! use only positive numbers!"
                  ),
              })}
              onSubmit={(values, { setSubmitting }) => {
                updateBudget(values);
                setSubmitting(false);
              }}
            >
              {({ isSubmitting, values }) => (
                <Form>
                  <BSForm.Group className="mb-3">
                    <BSForm.Label>Start of Fiscal Year</BSForm.Label>
                    <DatePicker
                      selected={values.start_of_fiscal_year}
                      name="start_of_fiscal_year"
                      className="form-control"
                      disabled
                    />
                    <ErrorMessage name="start_of_fiscal_year" component="div" />
                  </BSForm.Group>
                  <BSForm.Group className="mb-3">
                    <BSForm.Label>End of Fiscal Year</BSForm.Label>
                    <DatePicker
                      selected={values.end_of_fiscal_year}
                      name="end_of_fiscal_year"
                      className="form-control"
                      disabled
                    />
                    <ErrorMessage name="end_of_fiscal_year" component="div" />
                  </BSForm.Group>
                  <BSForm.Group className="mb-3">
                    <BSForm.Label>Total Budget</BSForm.Label>
                    <Field
                      type="text"
                      name="total_budget"
                      className="form-control"
                    />
                    <ErrorMessage name="total_budget" component="div" className="text-danger fs--1"/>
                  </BSForm.Group>
                  <div className="text-end mt-2">
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Update
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
        {payload.loadType === "Delete Budget" && (
          <div>
            <>
              <p className="">
                Are you sure you want to delete this budget ?
                {/* <span className="text-primary">"{payload.amenity_name}"</span>? */}
              </p>
              <div className="text-end">
                <Button
                  variant="falcon-warning"
                  className="me-3 "
                  size="md"
                  onClick={deleteBudget}
                  style={{ width: 80 }}
                >
                  Yes
                </Button>
                <Button
                  variant="falcon-default"
                  size="md"
                  onClick={handleClose}
                  style={{ width: 80 }}
                >
                  No
                </Button>
              </div>
            </>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default BudgetModel;
