// import React, { useEffect, useState } from 'react';
import React from "react";
import { Button, Card, Form, Row } from "react-bootstrap";
import FalconCardHeader from "../../../../components/common/FalconCardHeader";
// import Cookies from 'universal-cookie';
// import { toast } from 'react-toastify';
// import axios from 'axios';
import { Formik, Field } from "formik";
import Loader from "../../../../components/common/Loader";
import * as Yup from "yup";
const Profile = ({ company, updateCompanyData }) => {
  const [loader, setLoader] = React.useState(false);

  React.useEffect(() => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 400);
  }, []);

  if (loader) return <Loader />;
  else
    return (
      <Card className="mb-3">
        <FalconCardHeader title="Company Profile" />
        <Card.Body className="bg-light">
          <div>
            <Formik
              initialValues={{
                c_name: company.company_name || "name",
                c_site: company.site_name || "sitename",
                c_type: company.buisness_type || "type",
                user_id: company._id || "id",
              }}
              validate={(values) => {
                const errors = {};
                if (!values.c_name) {
                  errors.c_name = "company name is required!";
                }
                if (!values.c_site) {
                  errors.c_site = "company url is required!";
                }

                if (!values.c_type) {
                  errors.c_type = "company type is required!";
                }
                return errors;
              }}
              validationSchema={Yup.object({
                c_site: Yup.string().matches(
                  // /^[a-z]+(-[a-z]+)*$|^[A-Z]+(-[A-Z]+)*$/,
                  /^[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*$/,
                  "Invalid site name. Please enter a valid site name like this 'acmesite' or 'acme-site'."
                ),
              })}
              onSubmit={(values, { setSubmitting }) => {
                // setTimeout(() => {
                // alert(JSON.stringify(values, null, 2));
                console.log(values);
                setSubmitting(false);
                // }, 400);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <Row className="mb-2 g-3">
                    <Form.Group controlId="c_name">
                      <Form.Label>
                        Company Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="company name here"
                        value={values.c_name}
                        name="c_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <Form.Text className="text-danger ms-2">
                        {errors.c_name && touched.c_name && errors.c_name}
                      </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="c_site">
                      <Form.Label>
                        Company Site <span className="text-danger">*</span>
                      </Form.Label>
                      <div className="d-flex flex-column position-relative">
                        <Form.Control
                          type="text"
                          placeholder="company website url here"
                          // value={values.c_site + ".condozak.app"}
                          value={values.c_site}
                          name="c_site"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ paddingRight: "40%", overflow: "hidden" }}
                          disabled
                        />
                        <span
                          className="translate-middle"
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "23%",
                          }}
                        >
                          {/* .condozak.app */}
                        </span>
                      </div>

                      <Form.Text className="text-danger ms-2">
                        {errors.c_site && touched.c_site && errors.c_site}
                      </Form.Text>
                    </Form.Group>
                  </Row>
                  <Row className="g-3">
                    <Form.Group controlId="c_type">
                      <Form.Label>
                        Company Type <span className="text-danger">*</span>
                      </Form.Label>

                      <Field
                        component="select"
                        id="c_type"
                        name="c_type"
                        className="form-select"
                      >
                        <option value="Association">Association</option>
                        <option value="company">
                          Property Management Company
                        </option>
                      </Field>

                      <Form.Text className="text-danger ms-2">
                        {errors.c_type && touched.c_type && errors.c_type}
                      </Form.Text>
                    </Form.Group>
                  </Row>
                  <br />
                  <div className="text-end">
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                      onClick={() => updateCompanyData(values)}
                    >
                      Update
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Card.Body>
      </Card>
    );
};

export default Profile;
