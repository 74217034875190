import FalconCardHeader from "../../../../../components/common/FalconCardHeader";
import React from "react";
import { Card,Row,Col } from "react-bootstrap";

export default function EventDetailTime({ event = {} }) {
  return (
    <Card className="mb-3">
      <FalconCardHeader title='Details'/>
      <Card.Body style={{ minHeight: "300px" }} className="bg-light">
        <h6>Date And Time</h6>
        <div className="mb-1">
          Start : {new Date(event.event_start_date).toDateString()}
          <br />
          End : {new Date(event.event_end_date).toDateString()}
          <br />
          <br />
          <Row>
            {event.event_days?.map((v) => (
              <Col md={6} key={v._id}>
                Day : {v.day}
                <br />
                Start : {`${v.start_time} ${v.start_timezone} `}
                <br />
                End : {`${v.end_time} ${v.end_timezone} `}
                <hr />
              </Col>
            ))}
          </Row>
        </div>
        {/* <Link to="#!">Add to Calendar</Link> */}
        <h6 className="mt-2">Venue</h6>
        <p className="mb-1" style={{ whiteSpace: "pre-line" }}>
          {event.venue}
        </p>
        <h6 className="mt-2">Registration Deadline:</h6>
        <p className="mb-1" style={{ whiteSpace: "pre-line" }}>
          {event.event_registration_deadline}
        </p>
        {/* <Link to="#!">View Map</Link> */}
        {/* <h6 className="mt-4">Refund Policy</h6>
          <p className="fs--1 mb-0">No Refunds</p> */}
      </Card.Body>
    </Card>
  );
}
