import React from "react";
import Flex from "../../../../../../../components/common/Flex";
import { Card, Col, Row, Button } from "react-bootstrap";
import BookedAmenitiesTable from "./BookedAmenitiesTable";

import axios from "axios";

import { Link, useParams } from "react-router-dom";

import PaginateComponentCustom from "../../../../../../../components/common/PaginateComponentCustom";

import CustomSearchbar from "../../../../../../../components/common/CustomSearchbar";
import Loader from "../../../../../../../components/common/Loader";

import ShowPaymentRecieptModal from "../../../../../../../components/pages/sharedUI/PaymentReceipt";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName } from "../../../../../../../helpers/utils";

const PaymentsHistory = () => {
  let hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const { property_id, property_name, unit_number, unit_id } = useParams();

  let optionsForReq = {};
  let link = "";
  if (
    userData.role === "Site-Administrators" ||
    userData.role === "Condozak-Basic-Users"
  ) {
    optionsForReq.p_id = property_id;
    link = `${hostname}/api/pay/PaymentHistory`;
  }

  if (
    userData.role === "unit_owner" ||
    userData.role === "supp_resident" ||
    userData.role === "supp_tenant"
  ) {
    optionsForReq.p_id = userData.propid;
    optionsForReq.user_id = userData.data;
    link = `${hostname}/api/pay/PaymentHistoryOwner`;
  }

  const [amenitiesList, setAmenitiesList] = React.useState([]);
  const [amenitiesListTmp, setAmenitiesListTemp] = React.useState([
    ...amenitiesList,
  ]);
  // const [images, setImages] = React.useState([]);
  // // invite user modal
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [booking_id, setBookingID] = React.useState("");

  // //show detail modal
  const [showReceivePaymentModal, setShowReceivePaymentModal] =
    React.useState(false);

  const handleCloseReceivePaymentModal = () =>
    setShowReceivePaymentModal(false);
  const handleShowReceivePaymentModal = () => setShowReceivePaymentModal(true);

  const [loader, setLoader] = React.useState(false);

  const [payload, setPayload] = React.useState({});

  React.useEffect(() => {
    setLoader(true);
    // getBookedAmenitiesData();
    getPaymentHistory();
  }, []);

  const getPaymentHistory = () => {
    axios
      .post(
        link,
        {
          ...optionsForReq,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data.filteredPaymentHistory);
        let arr = res.data.filteredPaymentHistory?.filter(
          (v) => v?.Invoice_id?.unit_id?.unit_number === unit_number
        );
        setAmenitiesList(arr);
        setAmenitiesListTemp(arr);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const cancelBooking = (booking_id) => {
    console.log(booking_id);
    setBookingID(booking_id);
    handleShow();
  };

  const handleReceivePayment = (data) => {
    setPayload({});
    console.log(data);
    data.loadType = "Owner";
    setPayload(data);
    handleShowReceivePaymentModal();
  };

  if (loader) return <Loader />;

  return (
    <>
      {(userData.role === "Site-Administrators" ||
        userData.role === "Condozak-Basic-Users") && (
        <Row className="g-3 mb-3">
          <Col>
            <Card>
              <Card.Body className="d-flex align-items-center overflow-scroll scrollbar">
                <Link className="me-2 text-secondary" to="/dashboard">
                  Home
                </Link>
                {"/"}
                <Link
                  className="me-2 ms-2 text-secondary"
                  to="/property/management/buildings"
                >
                  Properties
                </Link>
                {"/"}
                <Link
                  className="me-2 ms-2 text-secondary"
                  to={`/property/management/buildings/${property_id}/${property_name}/profile`}
                >
                  {property_name}
                </Link>
                {"/"}
                <Link
                  className="me-2 ms-2 text-secondary"
                  to={`/property/management/buildings/${property_id}/${property_name}/units`}
                >
                  Units
                </Link>
                {"/"}
                <Link
                  className="me-2 ms-2 text-primary"
                  // to={`/${property_id}/${property_name}/${unit_number}/${unit_id}/history`}
                >
                  {unit_number}
                </Link>
                {"/"}
                <Link
                  className="me-2 ms-2 text-primary"
                  // to={`/${property_id}/${property_name}/${unit_number}/${unit_id}/owners`}
                  to={"#!"}
                >
                  Payments History
                </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}

      {(userData.role === "unit_owner" ||
        userData.role === "supp_resident" ||
        userData.role === "supp_tenant") && (
        <Row className="g-3 mb-3">
          <Col>
            <Card>
              <Card.Body className="d-flex align-items-center">
                <Link className="me-2 text-secondary" to="/dashboard">
                  Home
                </Link>
                {"/"}

                <Link className="me-2 ms-2" to={`/dashboard/Payments History`}>
                  Payments History
                </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      <Card>
        <Card.Body className="" style={{ minHeight: "90vh" }}>
          <Flex
            justifyContent={"between"}
            alignItems={"start"}
            className="mb-3"
          >
            <h4>Payments History</h4>
            {/* <Button
                variant="info"
                size="sm"
                onClick={() => handleSetShowBookedAmenities(true)}
              >
                View Booked Amenities
              </Button> */}
            {/* <Button
              variant="primary"
              size="sm"
              // onClick={handleShow}
            >
              Export to Excel
            </Button> */}
          </Flex>

          <br />
          <Row className="flex-end-center mb-3 mt-3">
            <Col className=" d-flex justify-content-sm-end">
              <CustomSearchbar
                searchFlags={[
                  "amenityName",
                  "userName",
                  "payment_option",
                  "status.title",
                ]}
                dataListTemp={amenitiesListTmp}
                stateUpdator={setAmenitiesList}
                placeholders={["type", "user"]}
              />
            </Col>
          </Row>

          <PaginateComponentCustom
            itemsPerPage={8}
            TableElement={BookedAmenitiesTable}
            dataList={amenitiesList}
            cancelBooking={cancelBooking}
            receivePayment={handleReceivePayment}
          />

          <br />
          <ShowPaymentRecieptModal
            booking={payload}
            show={showReceivePaymentModal}
            handleClose={handleCloseReceivePaymentModal}
            userData={userData}
            setAmenity={setPayload}
            hostname={hostname}
            jwtToken={jwtToken}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default PaymentsHistory;
