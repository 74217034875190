import React from "react";
import Modal from "react-bootstrap/Modal";
import "./MailDetailsModal.css";

// import axios from "axios";

export default function MailDetailsModal({
  show,
  handleClose,
  payload,
  setPayload,
  companyLetterHead,
  propertyLetterHead,
  updatedLetterHead,
}) {

  

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size={"lg"}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{payload.loadType}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Modal.Body>
            {payload.loadType === "Preview Mail" && (
              <div
                dangerouslySetInnerHTML={{ __html: payload.mail_content }}
              ></div>
            )}
            {payload.loadType === "Preview Letter" && (
              <div
                dangerouslySetInnerHTML={{ __html: payload.letter_content }}
              ></div>
            )}
          </Modal.Body>
        </Modal.Body>
      </Modal>
    </>
  );
}
