import React from "react";
import { useState } from "react";

import Flex from "../../../../components/common/Flex";

import { Button, Card, Col, Row } from "react-bootstrap";

import AmenityBookingsTable from "./AmenityBookingsTable";

import axios from "axios";

import { Link, useParams } from "react-router-dom";

import AddAmenityModal from "./AddAmenityModal";

import AmenityImagesModal from "./AmenityImagesModal";

import PaginateComponentCustom from "../../../../components/common/PaginateComponentCustom";

import { toast } from "react-toastify";

import EditAmenitySingle from "./EditAmenity/EditAmenitySingle";

import ConfirmationModel from "../../../../components/pages/sharedUI/ConfirmationModel";

import CustomSearchbar from "../../../../components/common/CustomSearchbar";

import Loader from "../../../../components/common/Loader";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName } from "../../../../helpers/utils";

const AmenityBookings = () => {
  let hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const { property_id, property_name } = useParams();

  const [amenitiesList, setAmenitiesList] = useState([]);
  const [amenitiesListTmp, setAmenitiesListTemp] = useState([...amenitiesList]);
  const [images, setImages] = useState([]);
  // invite user modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //show detail modal
  const [showAmenityImagesModal, setShowAmenityImagesModal] = useState(false);

  const handleCloseAmenityImagesModal = () => setShowAmenityImagesModal(false);
  const handleShowAmenityImagesModal = () => setShowAmenityImagesModal(true);

  // show edit amenity tab
  const [showEditAmenityTab, setshowEditAmenityTab] = useState(false);
  const [amenity_id_edit, set_amenity_id_edit] = useState(null);

  const handleShowEditAmenityTab = (id) => {
    setLoader(true);
    console.log(id);
    if (id) {
      axios
        .post(
          `${hostname}/api/amenity/ShowamenityInfo`,
          {
            Amenity_id: id,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          set_amenity_id_edit(res.data);
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });

      setshowEditAmenityTab(true);
    } else toast.warning("ID missing", { theme: "colored" });
  };

  const [loader, setLoader] = useState(false);

  React.useEffect(() => {
    setLoader(true);
    getAmenitiesData();
  }, []);

  // enable and disbale

  const [confirmationModel, setConfirmationModel] = React.useState(false);

  const handleCloseConfirmationModel = () => {
    setConfirmationModel(false);
  };

  const [payloadForConfirmationModel, setPayloadForConfirmationModel] =
    React.useState({});

  const handleConfirmation = (payload) => {
    setPayloadForConfirmationModel(payload);
    setConfirmationModel(true);
  };

  const getAmenitiesData = () => {
    // let p_id = userData.compid;
    console.log(property_id);
    console.log("Testing");
    axios
      .post(
        `${hostname}/api/amenity/Showallamenity`,
        {
          p_id: property_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        // let status = { type: 'success', title: 'Active', icon: 'check' };
        // let arr = res.data.map(v => {
        //   return Object.assign({ status }, v);
        // });

        setAmenitiesList(res.data);
        setAmenitiesListTemp(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const funcShowAmenityImagesModal = (images = []) => {
    console.log(images);
    setImages(images);
    handleShowAmenityImagesModal();
  };

  if (loader) return <Loader />;

  if (showEditAmenityTab)
    return (
      <EditAmenitySingle
        amenity={amenity_id_edit}
        clear_amenity={set_amenity_id_edit}
        setshowEditAmenityTab={setshowEditAmenityTab}
        getAmenitiesData={getAmenitiesData}
        userData={userData}
        hostname={hostname}
        jwtToken={jwtToken}
      />
    );

  return (
    <>
      <Row className="g-3 mb-3">
        <Col>
          <Card>
            <Card.Body
              className="d-flex align-items-center scrollbar"
              style={{ overflowX: "scroll" }}
            >
              <Link className="me-2 text-secondary" to="/dashboard">
                Home
              </Link>
              {"/"}
              <Link
                className="me-2 ms-2 text-secondary"
                to="/property/management/buildings"
              >
                Properties
              </Link>
              {"/"}
              <Link
                className="me-2 ms-2 text-secondary"
                to={`/property/management/buildings/${property_id}/${property_name}/profile`}
              >
                {property_name}
              </Link>
              {"/"}
              <Link
                className="me-2 ms-2"
                to={`/property/management/buildings/${property_id}/${property_name}/profile`}
              >
                Amenities
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Card>
        <Card.Body className="" style={{ minHeight: "90vh" }}>
          <Flex
            justifyContent={"between"}
            alignItems={"start"}
            className="mb-3"
          >
            <h4>Amenities</h4>
            {/* <Button
              variant="info"
              size="sm"
              onClick={() => handleSetShowBookedAmenities(true)}
            >
              View Booked Amenities
            </Button> */}
            <Button variant="primary" size="sm" onClick={handleShow}>
              Create Amenity
            </Button>
          </Flex>

          <br />
          <Row className="flex-end-center mb-3 mt-3">
            <Col className=" d-flex justify-content-sm-end">
              <CustomSearchbar
                searchFlags={["amenityname", "amenity_price", "status.title"]}
                dataListTemp={amenitiesListTmp}
                stateUpdator={setAmenitiesList}
                placeholders={["name", "price", "status"]}
              />
            </Col>
          </Row>

          <PaginateComponentCustom
            itemsPerPage={8}
            TableElement={AmenityBookingsTable}
            dataList={amenitiesList}
            // removeAmenityImages={removeAmenityImages}
            funcShowAmenityImagesModal={funcShowAmenityImagesModal}
            handleShowEditAmenityTab={handleShowEditAmenityTab}
            getAmenitiesData={getAmenitiesData}
            handleEnableDisableAmenity={handleConfirmation}
          />

          <br />
          <AddAmenityModal
            show={show}
            handleClose={handleClose}
            getAmenitiesData={getAmenitiesData}
            userData={userData}
            hostname={hostname}
            jwtToken={jwtToken}
          />
          <AmenityImagesModal
            show={showAmenityImagesModal}
            handleClose={handleCloseAmenityImagesModal}
            images={images}
          />
          <ConfirmationModel
            show={confirmationModel}
            handleClose={handleCloseConfirmationModel}
            payload={payloadForConfirmationModel}
            updator={getAmenitiesData}
            hostname={hostname}
            jwtToken={jwtToken}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default AmenityBookings;
