import { useState, useEffect } from "react";

import Flex from "../../../../components/common/Flex";
import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";

import MailOutsTable from "./MailOutsTable";

import CreateNewMailModal from "./CreateNewMailModal";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
// import Cookies from "universal-cookie";
import PaginateComponentCustom from "../../../../components/common/PaginateComponentCustom";
import Loader from "../../../../components/common/Loader";
import { Link, useParams } from "react-router-dom";
import CustomSearchbar from "../../../../components/common/CustomSearchbar";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName } from "../../../../helpers/utils";

const PetInfo = () => {
  let hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const { unit_id } = useParams();

  const [propertiesData, setPropertiesData] = useState([]);
  const [propertiesDataTemp, setPropertiesDataTemp] = useState([
    ...propertiesData,
  ]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getAllPropertiesData();
  }, []);

  const getAllPropertiesData = () => {
    console.log("working");
    // return;
    if (unit_id) {
      axios
        .post(
          `${hostname}/api/unit/GetAllPets`,
          {
            unit_id,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          setLoader(false);
          console.log(res.data);

          let arr = res.data.Data?.map((v) => {
            let obj = { ...v };
            // if (obj.pet_type === "Other")
            //   obj.pet_type = obj.other_pet_type || "other_type_here";
            return obj;
          });
          console.log(arr);
          setPropertiesData([...arr]);
          setPropertiesDataTemp([...arr]);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  };

  // create  modal

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [payload, setPayload] = React.useState({});

  const addItem = () => {
    let obj = {};
    obj.loadType = "Add Pet";
    obj.unit_id = unit_id;
    setPayload({ ...obj });
    handleShow();
  };
  const updateItem = (entry = {}) => {
    entry.loadType = "Update Pet";
    entry.unit_id = unit_id;
    setPayload({ ...entry });
    handleShow();
  };
  const deleteItem = (entry = {}) => {
    entry.loadType = "Delete Pet";
    entry.unit_id = unit_id;
    setPayload({ ...entry });
    handleShow();
  };

  if (loader) {
    return <Loader />;
  } else {
    return (
      <>
        <Row className="g-3 mb-3">
          <Col>
            <Card>
              <Card.Body className="">
                <Link className="text-secondary" to={"/"}>
                  Home
                </Link>
                {" / "}
                <Link to={"#!"}>Pets</Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Card>
          <Card.Body className="" style={{ minHeight: "90vh" }}>
            <Flex justifyContent={"between"} alignItems={"start"} className="">
              <h4>Pets</h4>
              {/* <Button variant="primary" size="sm" onClick={handleShow} /> */}
              {/* <Link
              to="/property/management/buildingInfo"
              variant="primary"
              size="sm"
            >
              Details
            </Link> */}
              <Button variant="primary" size="sm" onClick={addItem}>
                + Add Pet
              </Button>
            </Flex>
            <br />

            <Row className="flex-end-center mb-3 mt-3">
              <Col className=" d-flex justify-content-sm-end">
                <CustomSearchbar
                  searchFlags={[
                    "name",
                    "pet_type",
                    "weight",
                    "description",
                    "comments",
                  ]}
                  dataListTemp={propertiesDataTemp}
                  stateUpdator={setPropertiesData}
                  placeholders={["name", "type", "des"]}
                />
              </Col>
            </Row>

            <PaginateComponentCustom
              dataList={propertiesData}
              itemsPerPage={20}
              TableElement={MailOutsTable}
              addItem={addItem}
              updateItem={updateItem}
              deleteItem={deleteItem}
            />
            <br />
            <CreateNewMailModal
              show={show}
              handleClose={handleClose}
              userData={userData}
              payload={payload}
              setPayload={setPayload}
              getAllData={getAllPropertiesData}
              hostname={hostname}
              jwtToken={jwtToken}
            />
          </Card.Body>
        </Card>
      </>
    );
  }
};

export default PetInfo;
