export default [
  {
    id: 16151,
    name: "Airdrie",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "51.30011000",
    longitude: "-114.03528000",
  },
  {
    id: 16178,
    name: "Athabasca",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "54.71687000",
    longitude: "-113.28537000",
  },
  {
    id: 16190,
    name: "Banff",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "51.17622000",
    longitude: "-115.56982000",
  },
  {
    id: 16192,
    name: "Barrhead",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "54.13345000",
    longitude: "-114.40211000",
  },
  {
    id: 16196,
    name: "Bassano",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "50.78342000",
    longitude: "-112.46854000",
  },
  {
    id: 16204,
    name: "Beaumont",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.35013000",
    longitude: "-113.41871000",
  },
  {
    id: 16207,
    name: "Beaverlodge",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "55.21664000",
    longitude: "-119.43605000",
  },
  {
    id: 16219,
    name: "Black Diamond",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "50.70011000",
    longitude: "-114.23530000",
  },
  {
    id: 16220,
    name: "Blackfalds",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "52.38342000",
    longitude: "-113.78530000",
  },
  {
    id: 16226,
    name: "Bon Accord",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.83345000",
    longitude: "-113.41872000",
  },
  {
    id: 16229,
    name: "Bonnyville",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "54.26684000",
    longitude: "-110.73505000",
  },
  {
    id: 16234,
    name: "Bow Island",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.86676000",
    longitude: "-111.36843000",
  },
  {
    id: 16245,
    name: "Brooks",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "50.58341000",
    longitude: "-111.88509000",
  },
  {
    id: 16259,
    name: "Calgary",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "51.05011000",
    longitude: "-114.08529000",
  },
  {
    id: 16260,
    name: "Calmar",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.26683000",
    longitude: "-113.81874000",
  },
  {
    id: 16265,
    name: "Camrose",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.01684000",
    longitude: "-112.83525000",
  },
  {
    id: 16267,
    name: "Canmore",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "51.08335000",
    longitude: "-115.35206000",
  },
  {
    id: 16277,
    name: "Cardston",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.19998000",
    longitude: "-113.30190000",
  },
  {
    id: 16284,
    name: "Carstairs",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "51.56681000",
    longitude: "-114.10200000",
  },
  {
    id: 16305,
    name: "Chestermere",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "51.03341000",
    longitude: "-113.81867000",
  },
  {
    id: 16314,
    name: "Claresholm",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "50.03332000",
    longitude: "-113.58524000",
  },
  {
    id: 16316,
    name: "Coaldale",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.71670000",
    longitude: "-112.61854000",
  },
  {
    id: 16317,
    name: "Coalhurst",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.74640000",
    longitude: "-112.93246000",
  },
  {
    id: 16320,
    name: "Cochrane",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "51.18341000",
    longitude: "-114.46871000",
  },
  {
    id: 16323,
    name: "Cold Lake",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "54.46525000",
    longitude: "-110.18154000",
  },
  {
    id: 16349,
    name: "Crossfield",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "51.43341000",
    longitude: "-114.03528000",
  },
  {
    id: 16371,
    name: "Devon",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.36683000",
    longitude: "-113.73533000",
  },
  {
    id: 16372,
    name: "Didsbury",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "51.66681000",
    longitude: "-114.13529000",
  },
  {
    id: 16383,
    name: "Drayton Valley",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.21682000",
    longitude: "-114.98544000",
  },
  {
    id: 16396,
    name: "Edmonton",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.55014000",
    longitude: "-113.46871000",
  },
  {
    id: 16398,
    name: "Edson",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.58345000",
    longitude: "-116.43559000",
  },
  {
    id: 16399,
    name: "Elk Point",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.90017000",
    longitude: "-110.90170000",
  },
  {
    id: 16413,
    name: "Fairview",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "56.06675000",
    longitude: "-118.38606000",
  },
  {
    id: 16415,
    name: "Falher",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "55.73339000",
    longitude: "-117.20262000",
  },
  {
    id: 16429,
    name: "Fort Macleod",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.71671000",
    longitude: "-113.41857000",
  },
  {
    id: 16430,
    name: "Fort McMurray",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "56.72676000",
    longitude: "-111.38103000",
  },
  {
    id: 16433,
    name: "Fort Saskatchewan",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.71684000",
    longitude: "-113.21870000",
  },
  {
    id: 16438,
    name: "Fox Creek",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "54.40007000",
    longitude: "-116.80238000",
  },
  {
    id: 16450,
    name: "Gibbons",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.83345000",
    longitude: "-113.33531000",
  },
  {
    id: 16463,
    name: "Grand Centre",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "54.41628000",
    longitude: "-110.21304000",
  },
  {
    id: 16466,
    name: "Grande Cache",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.88335000",
    longitude: "-119.13585000",
  },
  {
    id: 16467,
    name: "Grande Prairie",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "55.16667000",
    longitude: "-118.80271000",
  },
  {
    id: 16476,
    name: "Grimshaw",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "56.18339000",
    longitude: "-117.60270000",
  },
  {
    id: 16488,
    name: "Hanna",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "51.63343000",
    longitude: "-111.90181000",
  },
  {
    id: 16502,
    name: "Heritage Pointe",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "50.84213000",
    longitude: "-114.00493000",
  },
  {
    id: 16503,
    name: "High Level",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "58.51688000",
    longitude: "-117.13605000",
  },
  {
    id: 16504,
    name: "High Prairie",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "55.43340000",
    longitude: "-116.48580000",
  },
  {
    id: 16505,
    name: "High River",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "50.58341000",
    longitude: "-113.86867000",
  },
  {
    id: 16506,
    name: "Hinton",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.40009000",
    longitude: "-117.58567000",
  },
  {
    id: 16526,
    name: "Irricana",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "51.32372000",
    longitude: "-113.60475000",
  },
  {
    id: 16528,
    name: "Jasper Park Lodge",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "52.88633000",
    longitude: "-118.05625000",
  },
  {
    id: 16543,
    name: "Killam",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "52.78344000",
    longitude: "-111.85175000",
  },
  {
    id: 16575,
    name: "Lac La Biche",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "54.76690000",
    longitude: "-111.96861000",
  },
  {
    id: 16585,
    name: "Lacombe",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "52.46681000",
    longitude: "-113.73530000",
  },
  {
    id: 16592,
    name: "Lamont",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.76686000",
    longitude: "-112.80195000",
  },
  {
    id: 16602,
    name: "Larkspur",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.47942000",
    longitude: "-113.38142000",
  },
  {
    id: 16603,
    name: "Laurel",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.44667000",
    longitude: "-113.38197000",
  },
  {
    id: 16610,
    name: "Leduc",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.26682000",
    longitude: "-113.55201000",
  },
  {
    id: 16614,
    name: "Lethbridge",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.69999000",
    longitude: "-112.81856000",
  },
  {
    id: 16625,
    name: "Lloydminster",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.27237000",
    longitude: "-110.02256000",
  },
  {
    id: 16645,
    name: "Magrath",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.41668000",
    longitude: "-112.86856000",
  },
  {
    id: 16653,
    name: "Manning",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "56.91683000",
    longitude: "-117.61945000",
  },
  {
    id: 16654,
    name: "Mannville",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.33764000",
    longitude: "-111.17750000",
  },
  {
    id: 16657,
    name: "Maple Ridge",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.50172000",
    longitude: "-113.36274000",
  },
  {
    id: 16671,
    name: "Mayerthorpe",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.95015000",
    longitude: "-115.13547000",
  },
  {
    id: 16675,
    name: "Medicine Hat",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "50.03928000",
    longitude: "-110.67661000",
  },
  {
    id: 16689,
    name: "Mill Woods Town Centre",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.45639000",
    longitude: "-113.42751000",
  },
  {
    id: 16691,
    name: "Millet",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.10013000",
    longitude: "-113.46870000",
  },
  {
    id: 16719,
    name: "Morinville",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.80014000",
    longitude: "-113.65203000",
  },
  {
    id: 16729,
    name: "Nanton",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "50.35008000",
    longitude: "-113.76866000",
  },
  {
    id: 16774,
    name: "Okotoks",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "50.72885000",
    longitude: "-113.98281000",
  },
  {
    id: 16775,
    name: "Olds",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "51.78341000",
    longitude: "-114.10199000",
  },
  {
    id: 16803,
    name: "Peace River",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "56.23715000",
    longitude: "-117.29176000",
  },
  {
    id: 16810,
    name: "Penhold",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "52.13342000",
    longitude: "-113.86870000",
  },
  {
    id: 16820,
    name: "Picture Butte",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.88330000",
    longitude: "-112.78516000",
  },
  {
    id: 16824,
    name: "Pincher Creek",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.48328000",
    longitude: "-113.95195000",
  },
  {
    id: 16834,
    name: "Ponoka",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "52.67680000",
    longitude: "-113.58147000",
  },
  {
    id: 16861,
    name: "Provost",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "52.35014000",
    longitude: "-110.26828000",
  },
  {
    id: 16871,
    name: "Raymond",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.44998000",
    longitude: "-112.65185000",
  },
  {
    id: 16873,
    name: "Red Deer",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "52.26682000",
    longitude: "-113.80200000",
  },
  {
    id: 16889,
    name: "Rideau Park",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.47899000",
    longitude: "-113.50470000",
  },
  {
    id: 16892,
    name: "Rimbey",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "52.63340000",
    longitude: "-114.23532000",
  },
  {
    id: 16901,
    name: "Rocky Mountain House",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "52.36683000",
    longitude: "-114.91880000",
  },
  {
    id: 17029,
    name: "Sexsmith",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "55.34998000",
    longitude: "-118.78602000",
  },
  {
    id: 17040,
    name: "Sherwood Park",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.51684000",
    longitude: "-113.31870000",
  },
  {
    id: 17044,
    name: "Silver Berry",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.45787000",
    longitude: "-113.38170000",
  },
  {
    id: 17049,
    name: "Slave Lake",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "55.28344000",
    longitude: "-114.76896000",
  },
  {
    id: 17052,
    name: "Smoky Lake",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "54.11687000",
    longitude: "-112.46863000",
  },
  {
    id: 17061,
    name: "Spirit River",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "55.78327000",
    longitude: "-118.83607000",
  },
  {
    id: 17062,
    name: "Springbrook",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "52.17920000",
    longitude: "-113.87981000",
  },
  {
    id: 17065,
    name: "Spruce Grove",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.53344000",
    longitude: "-113.91874000",
  },
  {
    id: 17068,
    name: "St. Albert",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.63344000",
    longitude: "-113.63533000",
  },
  {
    id: 17078,
    name: "Stettler",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "52.31683000",
    longitude: "-112.71861000",
  },
  {
    id: 17082,
    name: "Stony Plain",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.53343000",
    longitude: "-114.00205000",
  },
  {
    id: 17084,
    name: "Strathmore",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "51.05011000",
    longitude: "-113.38523000",
  },
  {
    id: 17088,
    name: "Sundre",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "51.80010000",
    longitude: "-114.63532000",
  },
  {
    id: 17092,
    name: "Swan Hills",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "54.71681000",
    longitude: "-115.40226000",
  },
  {
    id: 17097,
    name: "Sylvan Lake",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "52.31100000",
    longitude: "-114.08375000",
  },
  {
    id: 17098,
    name: "Taber",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.78703000",
    longitude: "-112.14603000",
  },
  {
    id: 17099,
    name: "Tamarack",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.46441000",
    longitude: "-113.36235000",
  },
  {
    id: 17110,
    name: "Three Hills",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "51.70012000",
    longitude: "-113.26863000",
  },
  {
    id: 17118,
    name: "Tofield",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.36684000",
    longitude: "-112.66862000",
  },
  {
    id: 17131,
    name: "Two Hills",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.71686000",
    longitude: "-111.75181000",
  },
  {
    id: 17143,
    name: "Valleyview",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "55.06673000",
    longitude: "-117.28585000",
  },
  {
    id: 17151,
    name: "Vegreville",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.50015000",
    longitude: "-112.05182000",
  },
  {
    id: 17154,
    name: "Vermilion",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.35409000",
    longitude: "-110.85849000",
  },
  {
    id: 17158,
    name: "Viking",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.10014000",
    longitude: "-111.76844000",
  },
  {
    id: 17163,
    name: "Vulcan",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "50.40008000",
    longitude: "-113.25189000",
  },
  {
    id: 17166,
    name: "Wainwright",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "52.83482000",
    longitude: "-110.85342000",
  },
  {
    id: 17187,
    name: "Wembley",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "55.14995000",
    longitude: "-119.13602000",
  },
  {
    id: 17194,
    name: "Westlake",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "55.22228000",
    longitude: "-118.80415000",
  },
  {
    id: 17195,
    name: "Westlock",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "54.15016000",
    longitude: "-113.86876000",
  },
  {
    id: 17197,
    name: "Wetaskiwin",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "52.96683000",
    longitude: "-113.36869000",
  },
  {
    id: 17203,
    name: "Whitecourt",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "54.15015000",
    longitude: "-115.68548000",
  },
  {
    id: 17205,
    name: "Wild Rose",
    state_id: 872,
    state_code: "AB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.47080000",
    longitude: "-113.38119000",
  },
  {
    id: 16146,
    name: "Abbotsford",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.05798000",
    longitude: "-122.25257000",
  },
  {
    id: 16150,
    name: "Agassiz",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.23298000",
    longitude: "-121.76926000",
  },
  {
    id: 16155,
    name: "Aldergrove",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.05801000",
    longitude: "-122.47087000",
  },
  {
    id: 16156,
    name: "Aldergrove East",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.05593000",
    longitude: "-122.42299000",
  },
  {
    id: 16169,
    name: "Anmore",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.31637000",
    longitude: "-122.85263000",
  },
  {
    id: 16172,
    name: "Arbutus Ridge",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.24966000",
    longitude: "-123.16934000",
  },
  {
    id: 16173,
    name: "Armstrong",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "50.44979000",
    longitude: "-119.20235000",
  },
  {
    id: 16176,
    name: "Ashcroft",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "50.72372000",
    longitude: "-121.28207000",
  },
  {
    id: 16194,
    name: "Barrière",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "51.18308000",
    longitude: "-120.11920000",
  },
  {
    id: 16235,
    name: "Bowen Island",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.38470000",
    longitude: "-123.33622000",
  },
  {
    id: 16253,
    name: "Burnaby",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.26636000",
    longitude: "-122.95263000",
  },
  {
    id: 16254,
    name: "Burns Lake",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "54.22972000",
    longitude: "-125.76084000",
  },
  {
    id: 16257,
    name: "Cache Creek",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "50.81011000",
    longitude: "-121.32460000",
  },
  {
    id: 16263,
    name: "Campbell River",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "50.01634000",
    longitude: "-125.24459000",
  },
  {
    id: 16286,
    name: "Castlegar",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.32317000",
    longitude: "-117.65831000",
  },
  {
    id: 16288,
    name: "Cedar",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.11633000",
    longitude: "-123.85270000",
  },
  {
    id: 16289,
    name: "Central Coast Regional District",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "52.16638000",
    longitude: "-127.00323000",
  },
  {
    id: 16298,
    name: "Chase",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "50.81650000",
    longitude: "-119.68571000",
  },
  {
    id: 16302,
    name: "Chemainus",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.91633000",
    longitude: "-123.71937000",
  },
  {
    id: 16306,
    name: "Chetwynd",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "55.69988000",
    longitude: "-121.63627000",
  },
  {
    id: 16308,
    name: "Chilliwack",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.16638000",
    longitude: "-121.95257000",
  },
  {
    id: 16326,
    name: "Colwood",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.43293000",
    longitude: "-123.48591000",
  },
  {
    id: 16334,
    name: "Coombs",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.29963000",
    longitude: "-124.41946000",
  },
  {
    id: 16335,
    name: "Coquitlam",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.28460000",
    longitude: "-122.78217000",
  },
  {
    id: 16341,
    name: "Courtenay",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.68657000",
    longitude: "-124.99360000",
  },
  {
    id: 16344,
    name: "Cowichan Bay",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.73366000",
    longitude: "-123.61739000",
  },
  {
    id: 16346,
    name: "Cranbrook",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.49991000",
    longitude: "-115.76879000",
  },
  {
    id: 16347,
    name: "Creston",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.09987000",
    longitude: "-116.50211000",
  },
  {
    id: 16350,
    name: "Cumberland",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.61634000",
    longitude: "-125.03613000",
  },
  {
    id: 16359,
    name: "Dawson Creek",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "55.75984000",
    longitude: "-120.24030000",
  },
  {
    id: 16366,
    name: "Delta",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.14399000",
    longitude: "-122.90680000",
  },
  {
    id: 16367,
    name: "Denman Island",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.53294000",
    longitude: "-124.81950000",
  },
  {
    id: 16368,
    name: "Denman Island Trust Area",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.55189000",
    longitude: "-124.79881000",
  },
  {
    id: 16386,
    name: "Duck Lake",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "50.00880000",
    longitude: "-119.39672000",
  },
  {
    id: 16387,
    name: "Duncan",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.78293000",
    longitude: "-123.70266000",
  },
  {
    id: 16394,
    name: "East Wellington",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.17385000",
    longitude: "-124.01745000",
  },
  {
    id: 16400,
    name: "Elkford",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "50.05007000",
    longitude: "-114.88540000",
  },
  {
    id: 16402,
    name: "Ellison",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.93307000",
    longitude: "-119.36907000",
  },
  {
    id: 16404,
    name: "Enderby",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "50.54980000",
    longitude: "-119.15234000",
  },
  {
    id: 16414,
    name: "Fairwinds",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.27588000",
    longitude: "-124.12782000",
  },
  {
    id: 16421,
    name: "Fernie",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.49996000",
    longitude: "-115.06871000",
  },
  {
    id: 16432,
    name: "Fort Nelson",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "58.80533000",
    longitude: "-122.70020000",
  },
  {
    id: 16435,
    name: "Fort St. John",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "56.24988000",
    longitude: "-120.85292000",
  },
  {
    id: 16440,
    name: "Fraser Valley Regional District",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.58299000",
    longitude: "-121.83587000",
  },
  {
    id: 16442,
    name: "French Creek",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.34123000",
    longitude: "-124.35541000",
  },
  {
    id: 16443,
    name: "Fruitvale",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.11654000",
    longitude: "-117.55222000",
  },
  {
    id: 16451,
    name: "Gibsons",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.39539000",
    longitude: "-123.50555000",
  },
  {
    id: 16458,
    name: "Golden",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "51.29995000",
    longitude: "-116.96890000",
  },
  {
    id: 16465,
    name: "Grand Forks",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.03309000",
    longitude: "-118.43560000",
  },
  {
    id: 16487,
    name: "Hanceville",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "51.91922000",
    longitude: "-123.04458000",
  },
  {
    id: 16507,
    name: "Hope",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.38299000",
    longitude: "-121.44144000",
  },
  {
    id: 16508,
    name: "Hornby Island",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.53448000",
    longitude: "-124.66923000",
  },
  {
    id: 16510,
    name: "Houston",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "54.39976000",
    longitude: "-126.67008000",
  },
  {
    id: 16523,
    name: "Invermere",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "50.51666000",
    longitude: "-116.03538000",
  },
  {
    id: 16531,
    name: "Kamloops",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "50.66648000",
    longitude: "-120.31920000",
  },
  {
    id: 16536,
    name: "Kelowna",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.88307000",
    longitude: "-119.48568000",
  },
  {
    id: 16545,
    name: "Kimberley",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.67071000",
    longitude: "-115.97760000",
  },
  {
    id: 16554,
    name: "Kitimat",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "54.05244000",
    longitude: "-128.65342000",
  },
  {
    id: 16586,
    name: "Ladner",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.08938000",
    longitude: "-123.08241000",
  },
  {
    id: 16587,
    name: "Ladysmith",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.99016000",
    longitude: "-123.81699000",
  },
  {
    id: 16588,
    name: "Lake Cowichan",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.82495000",
    longitude: "-124.05461000",
  },
  {
    id: 16595,
    name: "Langford",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.44963000",
    longitude: "-123.50261000",
  },
  {
    id: 16597,
    name: "Langley",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.10107000",
    longitude: "-122.65883000",
  },
  {
    id: 16616,
    name: "Lillooet",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "50.68560000",
    longitude: "-121.94200000",
  },
  {
    id: 16621,
    name: "Lions Bay",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.45218000",
    longitude: "-123.23760000",
  },
  {
    id: 16626,
    name: "Logan Lake",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "50.49976000",
    longitude: "-120.80253000",
  },
  {
    id: 16635,
    name: "Lumby",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "50.24979000",
    longitude: "-118.96904000",
  },
  {
    id: 16641,
    name: "Mackenzie",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "55.33637000",
    longitude: "-123.09374000",
  },
  {
    id: 16656,
    name: "Maple Ridge",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.21939000",
    longitude: "-122.60193000",
  },
  {
    id: 16681,
    name: "Merritt",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "50.11225000",
    longitude: "-120.79420000",
  },
  {
    id: 16684,
    name: "Metchosin",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.38293000",
    longitude: "-123.53591000",
  },
  {
    id: 16685,
    name: "Metro Vancouver Regional District",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.33296000",
    longitude: "-123.00264000",
  },
  {
    id: 16696,
    name: "Mission",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.13298000",
    longitude: "-122.30258000",
  },
  {
    id: 16727,
    name: "Nakusp",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "50.24987000",
    longitude: "-117.80226000",
  },
  {
    id: 16728,
    name: "Nanaimo",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.16638000",
    longitude: "-123.94003000",
  },
  {
    id: 16735,
    name: "Nelson",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.49985000",
    longitude: "-117.28553000",
  },
  {
    id: 16742,
    name: "New Westminster",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.20678000",
    longitude: "-122.91092000",
  },
  {
    id: 16757,
    name: "North Cowichan",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.84133000",
    longitude: "-123.68596000",
  },
  {
    id: 16758,
    name: "North Oyster/Yellow Point",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.04807000",
    longitude: "-123.83395000",
  },
  {
    id: 16760,
    name: "North Saanich",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.66634000",
    longitude: "-123.41933000",
  },
  {
    id: 16761,
    name: "North Vancouver",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.31636000",
    longitude: "-123.06934000",
  },
  {
    id: 16769,
    name: "Oak Bay",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.44964000",
    longitude: "-123.30260000",
  },
  {
    id: 16772,
    name: "Okanagan",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "50.36386000",
    longitude: "-119.34997000",
  },
  {
    id: 16773,
    name: "Okanagan Falls",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.35000000",
    longitude: "-119.56667000",
  },
  {
    id: 16776,
    name: "Oliver",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.18306000",
    longitude: "-119.55240000",
  },
  {
    id: 16784,
    name: "Osoyoos",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.03306000",
    longitude: "-119.45237000",
  },
  {
    id: 16798,
    name: "Parksville",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.31947000",
    longitude: "-124.31575000",
  },
  {
    id: 16804,
    name: "Peace River Regional District",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "56.66650000",
    longitude: "-122.75302000",
  },
  {
    id: 16805,
    name: "Peachland",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.76647000",
    longitude: "-119.73568000",
  },
  {
    id: 16808,
    name: "Pemberton",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "50.31641000",
    longitude: "-122.80273000",
  },
  {
    id: 16811,
    name: "Penticton",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.48062000",
    longitude: "-119.58584000",
  },
  {
    id: 16826,
    name: "Pitt Meadows",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.22119000",
    longitude: "-122.68965000",
  },
  {
    id: 16837,
    name: "Port Alberni",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.24133000",
    longitude: "-124.80280000",
  },
  {
    id: 16839,
    name: "Port Coquitlam",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.26637000",
    longitude: "-122.76932000",
  },
  {
    id: 16842,
    name: "Port McNeill",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "50.58716000",
    longitude: "-127.08053000",
  },
  {
    id: 16843,
    name: "Port Moody",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.28124000",
    longitude: "-122.82457000",
  },
  {
    id: 16851,
    name: "Powell River",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.83278000",
    longitude: "-124.52368000",
  },
  {
    id: 16856,
    name: "Prince George",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "53.91660000",
    longitude: "-122.75301000",
  },
  {
    id: 16857,
    name: "Prince Rupert",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "54.31507000",
    longitude: "-130.32098000",
  },
  {
    id: 16858,
    name: "Princeton",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.45802000",
    longitude: "-120.51076000",
  },
  {
    id: 16863,
    name: "Puntledge",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.66168000",
    longitude: "-125.05686000",
  },
  {
    id: 16865,
    name: "Quesnel",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "52.97842000",
    longitude: "-122.49310000",
  },
  {
    id: 16877,
    name: "Regional District of Alberni-Clayoquot",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.24962000",
    longitude: "-125.33615000",
  },
  {
    id: 16878,
    name: "Regional District of Central Okanagan",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.99978000",
    longitude: "-119.41908000",
  },
  {
    id: 16882,
    name: "Revelstoke",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "50.99712000",
    longitude: "-118.19530000",
  },
  {
    id: 16885,
    name: "Richmond",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.17003000",
    longitude: "-123.13683000",
  },
  {
    id: 16904,
    name: "Rossland",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.08313000",
    longitude: "-117.80224000",
  },
  {
    id: 16908,
    name: "Royston",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.64703000",
    longitude: "-124.94670000",
  },
  {
    id: 17016,
    name: "Salmo",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.19986000",
    longitude: "-117.26890000",
  },
  {
    id: 17017,
    name: "Salmon Arm",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "50.69980000",
    longitude: "-119.30237000",
  },
  {
    id: 17018,
    name: "Salt Spring Island",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.81852000",
    longitude: "-123.49061000",
  },
  {
    id: 17019,
    name: "Saltair",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.94963000",
    longitude: "-123.76939000",
  },
  {
    id: 17025,
    name: "Sechelt",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.47512000",
    longitude: "-123.75903000",
  },
  {
    id: 17043,
    name: "Sicamous",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "50.83312000",
    longitude: "-118.98565000",
  },
  {
    id: 17047,
    name: "Six Mile",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.45767000",
    longitude: "-123.46088000",
  },
  {
    id: 17050,
    name: "Smithers",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "54.78036000",
    longitude: "-127.17428000",
  },
  {
    id: 17053,
    name: "Sooke",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.37463000",
    longitude: "-123.72762000",
  },
  {
    id: 17058,
    name: "South Pender Harbour",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.62202000",
    longitude: "-124.02484000",
  },
  {
    id: 17060,
    name: "Sparwood",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.73332000",
    longitude: "-114.88532000",
  },
  {
    id: 17086,
    name: "Summerland",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.59977000",
    longitude: "-119.66911000",
  },
  {
    id: 17089,
    name: "Surrey",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.10635000",
    longitude: "-122.82509000",
  },
  {
    id: 17102,
    name: "Terrace",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "54.51634000",
    longitude: "-128.60345000",
  },
  {
    id: 17119,
    name: "Tofino",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.15314000",
    longitude: "-125.90744000",
  },
  {
    id: 17125,
    name: "Trail",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.09983000",
    longitude: "-117.70223000",
  },
  {
    id: 17128,
    name: "Tsawwassen",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.01667000",
    longitude: "-123.08333000",
  },
  {
    id: 17129,
    name: "Tumbler Ridge",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "55.13027000",
    longitude: "-120.99415000",
  },
  {
    id: 17133,
    name: "Ucluelet",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.94153000",
    longitude: "-125.54635000",
  },
  {
    id: 17145,
    name: "Vancouver",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.24966000",
    longitude: "-123.11934000",
  },
  {
    id: 17146,
    name: "Vanderhoof",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "54.01657000",
    longitude: "-124.01982000",
  },
  {
    id: 17155,
    name: "Vernon",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "50.26729000",
    longitude: "-119.27337000",
  },
  {
    id: 17156,
    name: "Victoria",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.43590000",
    longitude: "-123.35155000",
  },
  {
    id: 17168,
    name: "Walnut Grove",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.16473000",
    longitude: "-122.64042000",
  },
  {
    id: 17183,
    name: "Welcome Beach",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.47959000",
    longitude: "-123.89239000",
  },
  {
    id: 17189,
    name: "West End",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.28333000",
    longitude: "-123.13333000",
  },
  {
    id: 17190,
    name: "West Kelowna",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.86250000",
    longitude: "-119.58333000",
  },
  {
    id: 17193,
    name: "West Vancouver",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.36672000",
    longitude: "-123.16652000",
  },
  {
    id: 17200,
    name: "Whistler",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "50.11817000",
    longitude: "-122.95396000",
  },
  {
    id: 17202,
    name: "White Rock",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.01636000",
    longitude: "-122.80260000",
  },
  {
    id: 17207,
    name: "Williams Lake",
    state_id: 875,
    state_code: "BC",
    country_id: 39,
    country_code: "CA",
    latitude: "52.14153000",
    longitude: "-122.14451000",
  },
  {
    id: 16160,
    name: "Altona",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.10469000",
    longitude: "-97.55961000",
  },
  {
    id: 16206,
    name: "Beausejour",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "50.06220000",
    longitude: "-96.51669000",
  },
  {
    id: 16225,
    name: "Boissevain",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.23062000",
    longitude: "-100.05586000",
  },
  {
    id: 16238,
    name: "Brandon",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.84692000",
    longitude: "-99.95306000",
  },
  {
    id: 16275,
    name: "Carberry",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.86893000",
    longitude: "-99.36021000",
  },
  {
    id: 16283,
    name: "Carman",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.49920000",
    longitude: "-98.00156000",
  },
  {
    id: 16348,
    name: "Cross Lake 19A",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "54.65135000",
    longitude: "-97.76848000",
  },
  {
    id: 16356,
    name: "Dauphin",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "51.14941000",
    longitude: "-100.05023000",
  },
  {
    id: 16360,
    name: "De Salaberry",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.39999000",
    longitude: "-97.00894000",
  },
  {
    id: 16364,
    name: "Deloraine",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.19082000",
    longitude: "-100.49477000",
  },
  {
    id: 16422,
    name: "Flin Flon",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "54.76703000",
    longitude: "-101.87433000",
  },
  {
    id: 16452,
    name: "Gimli",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "50.63362000",
    longitude: "-96.99066000",
  },
  {
    id: 16477,
    name: "Grunthal",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.40668000",
    longitude: "-96.85873000",
  },
  {
    id: 16500,
    name: "Headingley",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.87530000",
    longitude: "-97.40896000",
  },
  {
    id: 16518,
    name: "Ile des Chênes",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.71060000",
    longitude: "-96.98893000",
  },
  {
    id: 16544,
    name: "Killarney",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.18332000",
    longitude: "-99.66364000",
  },
  {
    id: 16563,
    name: "La Broquerie",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.51688000",
    longitude: "-96.50029000",
  },
  {
    id: 16576,
    name: "Lac du Bonnet",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "50.25360000",
    longitude: "-96.06116000",
  },
  {
    id: 16593,
    name: "Landmark",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.67169000",
    longitude: "-96.82232000",
  },
  {
    id: 16629,
    name: "Lorette",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.73919000",
    longitude: "-96.87232000",
  },
  {
    id: 16677,
    name: "Melita",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.26811000",
    longitude: "-100.99669000",
  },
  {
    id: 16693,
    name: "Minnedosa",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "50.24532000",
    longitude: "-99.84364000",
  },
  {
    id: 16714,
    name: "Moose Lake",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.20559000",
    longitude: "-95.30629000",
  },
  {
    id: 16717,
    name: "Morden",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.19190000",
    longitude: "-98.10136000",
  },
  {
    id: 16720,
    name: "Morris",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.35499000",
    longitude: "-97.36567000",
  },
  {
    id: 16734,
    name: "Neepawa",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "50.22892000",
    longitude: "-99.46642000",
  },
  {
    id: 16749,
    name: "Niverville",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.60559000",
    longitude: "-97.04234000",
  },
  {
    id: 16848,
    name: "Portage la Prairie",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.97282000",
    longitude: "-98.29263000",
  },
  {
    id: 16894,
    name: "Rivers",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "50.03081000",
    longitude: "-100.24029000",
  },
  {
    id: 16898,
    name: "Roblin",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "51.22999000",
    longitude: "-101.35650000",
  },
  {
    id: 17026,
    name: "Selkirk",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "50.14360000",
    longitude: "-96.88452000",
  },
  {
    id: 17041,
    name: "Shilo",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.80509000",
    longitude: "-99.63175000",
  },
  {
    id: 17056,
    name: "Souris",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.61720000",
    longitude: "-100.26120000",
  },
  {
    id: 17067,
    name: "St. Adolphe",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.67440000",
    longitude: "-97.11124000",
  },
  {
    id: 17075,
    name: "Steinbach",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.52579000",
    longitude: "-96.68451000",
  },
  {
    id: 17080,
    name: "Stonewall",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "50.13441000",
    longitude: "-97.32676000",
  },
  {
    id: 17093,
    name: "Swan River",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "52.10580000",
    longitude: "-101.26759000",
  },
  {
    id: 17105,
    name: "The Pas",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "53.82509000",
    longitude: "-101.25413000",
  },
  {
    id: 17108,
    name: "Thompson",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "55.74350000",
    longitude: "-97.85579000",
  },
  {
    id: 17161,
    name: "Virden",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.85080000",
    longitude: "-100.93262000",
  },
  {
    id: 17192,
    name: "West St. Paul",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.99940000",
    longitude: "-97.16284000",
  },
  {
    id: 17214,
    name: "Winkler",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.18170000",
    longitude: "-97.94104000",
  },
  {
    id: 17215,
    name: "Winnipeg",
    state_id: 867,
    state_code: "MB",
    country_id: 39,
    country_code: "CA",
    latitude: "49.88440000",
    longitude: "-97.14704000",
  },
  {
    id: 16184,
    name: "Baie Ste. Anne",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "47.05231000",
    longitude: "-64.95355000",
  },
  {
    id: 16198,
    name: "Bathurst",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "47.61814000",
    longitude: "-65.65112000",
  },
  {
    id: 16232,
    name: "Bouctouche",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "46.46844000",
    longitude: "-64.73905000",
  },
  {
    id: 16264,
    name: "Campbellton",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "48.00751000",
    longitude: "-66.67272000",
  },
  {
    id: 16373,
    name: "Dieppe",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "46.07844000",
    longitude: "-64.68735000",
  },
  {
    id: 16397,
    name: "Edmundston",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "47.37370000",
    longitude: "-68.32512000",
  },
  {
    id: 16423,
    name: "Florenceville-Bristol",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "46.44353000",
    longitude: "-67.61536000",
  },
  {
    id: 16441,
    name: "Fredericton",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "45.94541000",
    longitude: "-66.66558000",
  },
  {
    id: 16444,
    name: "Fundy Bay",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "44.74100000",
    longitude: "-66.76041000",
  },
  {
    id: 16468,
    name: "Grande-Digue",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "46.30014000",
    longitude: "-64.56546000",
  },
  {
    id: 16471,
    name: "Greater Lakeburn",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "46.07651000",
    longitude: "-64.66818000",
  },
  {
    id: 16486,
    name: "Hampton",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "45.52876000",
    longitude: "-65.85354000",
  },
  {
    id: 16493,
    name: "Harrison Brook",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "47.21304000",
    longitude: "-67.92847000",
  },
  {
    id: 16542,
    name: "Keswick Ridge",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "46.00011000",
    longitude: "-66.88218000",
  },
  {
    id: 16618,
    name: "Lincoln",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "45.90012000",
    longitude: "-66.58218000",
  },
  {
    id: 16638,
    name: "Lutes Mountain",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "46.13544000",
    longitude: "-64.90504000",
  },
  {
    id: 16672,
    name: "McEwen",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "46.14520000",
    longitude: "-64.78615000",
  },
  {
    id: 16695,
    name: "Miramichi",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "47.02895000",
    longitude: "-65.50186000",
  },
  {
    id: 16700,
    name: "Moncton",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "46.09454000",
    longitude: "-64.79650000",
  },
  {
    id: 16726,
    name: "Nackawic",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "45.99666000",
    longitude: "-67.24028000",
  },
  {
    id: 16741,
    name: "New Maryland",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "45.87932000",
    longitude: "-66.66828000",
  },
  {
    id: 16750,
    name: "Noonan",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "45.96682000",
    longitude: "-66.53218000",
  },
  {
    id: 16781,
    name: "Oromocto",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "45.83512000",
    longitude: "-66.47917000",
  },
  {
    id: 16884,
    name: "Richibucto",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "46.68073000",
    longitude: "-64.88044000",
  },
  {
    id: 16910,
    name: "Sackville",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "45.91875000",
    longitude: "-64.38455000",
  },
  {
    id: 16913,
    name: "Saint Andrews",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "45.07370000",
    longitude: "-67.05312000",
  },
  {
    id: 16914,
    name: "Saint John",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "45.27271000",
    longitude: "-66.06766000",
  },
  {
    id: 16921,
    name: "Saint-Antoine",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "46.36294000",
    longitude: "-64.74985000",
  },
  {
    id: 16967,
    name: "Saint-Léonard",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "47.16317000",
    longitude: "-67.92460000",
  },
  {
    id: 17014,
    name: "Salisbury",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "46.03905000",
    longitude: "-65.04628000",
  },
  {
    id: 17034,
    name: "Shediac",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "46.21981000",
    longitude: "-64.54107000",
  },
  {
    id: 17035,
    name: "Shediac Bridge-Shediac River",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "46.26886000",
    longitude: "-64.60047000",
  },
  {
    id: 17042,
    name: "Shippagan",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "47.74424000",
    longitude: "-64.70804000",
  },
  {
    id: 17074,
    name: "Starlight Village",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "45.88308000",
    longitude: "-66.76905000",
  },
  {
    id: 17090,
    name: "Sussex",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "45.72266000",
    longitude: "-65.50663000",
  },
  {
    id: 17124,
    name: "Tracadie-Sheila",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "47.51444000",
    longitude: "-64.91806000",
  },
  {
    id: 17186,
    name: "Wells",
    state_id: 868,
    state_code: "NB",
    country_id: 39,
    country_code: "CA",
    latitude: "45.39274000",
    longitude: "-65.92313000",
  },
  {
    id: 16199,
    name: "Bay Roberts",
    state_id: 877,
    state_code: "NL",
    country_id: 39,
    country_code: "CA",
    latitude: "47.59989000",
    longitude: "-53.26478000",
  },
  {
    id: 16200,
    name: "Bay St. George South",
    state_id: 877,
    state_code: "NL",
    country_id: 39,
    country_code: "CA",
    latitude: "48.22781000",
    longitude: "-58.84162000",
  },
  {
    id: 16228,
    name: "Bonavista",
    state_id: 877,
    state_code: "NL",
    country_id: 39,
    country_code: "CA",
    latitude: "48.64989000",
    longitude: "-53.11474000",
  },
  {
    id: 16230,
    name: "Botwood",
    state_id: 877,
    state_code: "NL",
    country_id: 39,
    country_code: "CA",
    latitude: "49.14994000",
    longitude: "-55.34819000",
  },
  {
    id: 16251,
    name: "Burgeo",
    state_id: 877,
    state_code: "NL",
    country_id: 39,
    country_code: "CA",
    latitude: "47.61668000",
    longitude: "-57.61516000",
  },
  {
    id: 16276,
    name: "Carbonear",
    state_id: 877,
    state_code: "NL",
    country_id: 39,
    country_code: "CA",
    latitude: "47.73319000",
    longitude: "-53.21478000",
  },
  {
    id: 16287,
    name: "Catalina",
    state_id: 877,
    state_code: "NL",
    country_id: 39,
    country_code: "CA",
    latitude: "48.51659000",
    longitude: "-53.08135000",
  },
  {
    id: 16294,
    name: "Channel-Port aux Basques",
    state_id: 877,
    state_code: "NL",
    country_id: 39,
    country_code: "CA",
    latitude: "47.57286000",
    longitude: "-59.13808000",
  },
  {
    id: 16313,
    name: "Clarenville-Shoal Harbour",
    state_id: 877,
    state_code: "NL",
    country_id: 39,
    country_code: "CA",
    latitude: "48.18050000",
    longitude: "-53.96982000",
  },
  {
    id: 16327,
    name: "Conception Bay South",
    state_id: 877,
    state_code: "NL",
    country_id: 39,
    country_code: "CA",
    latitude: "47.49989000",
    longitude: "-52.99806000",
  },
  {
    id: 16336,
    name: "Corner Brook",
    state_id: 877,
    state_code: "NL",
    country_id: 39,
    country_code: "CA",
    latitude: "48.95001000",
    longitude: "-57.95202000",
  },
  {
    id: 16362,
    name: "Deer Lake",
    state_id: 877,
    state_code: "NL",
    country_id: 39,
    country_code: "CA",
    latitude: "49.16671000",
    longitude: "-57.43163000",
  },
  {
    id: 16425,
    name: "Fogo Island",
    state_id: 877,
    state_code: "NL",
    country_id: 39,
    country_code: "CA",
    latitude: "49.71649000",
    longitude: "-54.16981000",
  },
  {
    id: 16445,
    name: "Gambo",
    state_id: 877,
    state_code: "NL",
    country_id: 39,
    country_code: "CA",
    latitude: "48.78320000",
    longitude: "-54.21482000",
  },
  {
    id: 16460,
    name: "Goulds",
    state_id: 877,
    state_code: "NL",
    country_id: 39,
    country_code: "CA",
    latitude: "47.45532000",
    longitude: "-52.77552000",
  },
  {
    id: 16462,
    name: "Grand Bank",
    state_id: 877,
    state_code: "NL",
    country_id: 39,
    country_code: "CA",
    latitude: "47.09995000",
    longitude: "-55.76504000",
  },
  {
    id: 16464,
    name: "Grand Falls-Windsor",
    state_id: 877,
    state_code: "NL",
    country_id: 39,
    country_code: "CA",
    latitude: "48.93324000",
    longitude: "-55.66492000",
  },
  {
    id: 16491,
    name: "Happy Valley-Goose Bay",
    state_id: 877,
    state_code: "NL",
    country_id: 39,
    country_code: "CA",
    latitude: "53.30380000",
    longitude: "-60.32576000",
  },
  {
    id: 16492,
    name: "Harbour Breton",
    state_id: 877,
    state_code: "NL",
    country_id: 39,
    country_code: "CA",
    latitude: "47.48325000",
    longitude: "-55.79833000",
  },
  {
    id: 16574,
    name: "Labrador City",
    state_id: 877,
    state_code: "NL",
    country_id: 39,
    country_code: "CA",
    latitude: "52.94626000",
    longitude: "-66.91137000",
  },
  {
    id: 16615,
    name: "Lewisporte",
    state_id: 877,
    state_code: "NL",
    country_id: 39,
    country_code: "CA",
    latitude: "49.24993000",
    longitude: "-55.04816000",
  },
  {
    id: 16664,
    name: "Marystown",
    state_id: 877,
    state_code: "NL",
    country_id: 39,
    country_code: "CA",
    latitude: "47.16663000",
    longitude: "-55.14829000",
  },
  {
    id: 16724,
    name: "Mount Pearl",
    state_id: 877,
    state_code: "NL",
    country_id: 39,
    country_code: "CA",
    latitude: "47.51659000",
    longitude: "-52.78135000",
  },
  {
    id: 16802,
    name: "Pasadena",
    state_id: 877,
    state_code: "NL",
    country_id: 39,
    country_code: "CA",
    latitude: "49.01671000",
    longitude: "-57.59837000",
  },
  {
    id: 17063,
    name: "Springdale",
    state_id: 877,
    state_code: "NL",
    country_id: 39,
    country_code: "CA",
    latitude: "49.49995000",
    longitude: "-56.06492000",
  },
  {
    id: 17069,
    name: "St. Anthony",
    state_id: 877,
    state_code: "NL",
    country_id: 39,
    country_code: "CA",
    latitude: "51.37039000",
    longitude: "-55.59743000",
  },
  {
    id: 17072,
    name: "St. John's",
    state_id: 877,
    state_code: "NL",
    country_id: 39,
    country_code: "CA",
    latitude: "47.56494000",
    longitude: "-52.70931000",
  },
  {
    id: 17076,
    name: "Stephenville",
    state_id: 877,
    state_code: "NL",
    country_id: 39,
    country_code: "CA",
    latitude: "48.55001000",
    longitude: "-58.58180000",
  },
  {
    id: 17077,
    name: "Stephenville Crossing",
    state_id: 877,
    state_code: "NL",
    country_id: 39,
    country_code: "CA",
    latitude: "48.50001000",
    longitude: "-58.43180000",
  },
  {
    id: 17120,
    name: "Torbay",
    state_id: 877,
    state_code: "NL",
    country_id: 39,
    country_code: "CA",
    latitude: "47.66659000",
    longitude: "-52.73135000",
  },
  {
    id: 17135,
    name: "Upper Island Cove",
    state_id: 877,
    state_code: "NL",
    country_id: 39,
    country_code: "CA",
    latitude: "47.64989000",
    longitude: "-53.21478000",
  },
  {
    id: 17164,
    name: "Wabana",
    state_id: 877,
    state_code: "NL",
    country_id: 39,
    country_code: "CA",
    latitude: "47.63319000",
    longitude: "-52.94806000",
  },
  {
    id: 16209,
    name: "Behchokǫ̀",
    state_id: 878,
    state_code: "NT",
    country_id: 39,
    country_code: "CA",
    latitude: "62.80250000",
    longitude: "-116.04639000",
  },
  {
    id: 16431,
    name: "Fort McPherson",
    state_id: 878,
    state_code: "NT",
    country_id: 39,
    country_code: "CA",
    latitude: "67.43863000",
    longitude: "-134.88543000",
  },
  {
    id: 16434,
    name: "Fort Smith",
    state_id: 878,
    state_code: "NT",
    country_id: 39,
    country_code: "CA",
    latitude: "60.00439000",
    longitude: "-111.88871000",
  },
  {
    id: 16498,
    name: "Hay River",
    state_id: 878,
    state_code: "NT",
    country_id: 39,
    country_code: "CA",
    latitude: "60.81555000",
    longitude: "-115.79993000",
  },
  {
    id: 16522,
    name: "Inuvik",
    state_id: 878,
    state_code: "NT",
    country_id: 39,
    country_code: "CA",
    latitude: "68.34986000",
    longitude: "-133.72181000",
  },
  {
    id: 16753,
    name: "Norman Wells",
    state_id: 878,
    state_code: "NT",
    country_id: 39,
    country_code: "CA",
    latitude: "65.28201000",
    longitude: "-126.83290000",
  },
  {
    id: 17221,
    name: "Yellowknife",
    state_id: 878,
    state_code: "NT",
    country_id: 39,
    country_code: "CA",
    latitude: "62.45411000",
    longitude: "-114.37248000",
  },
  {
    id: 16161,
    name: "Amherst",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "45.83345000",
    longitude: "-64.19874000",
  },
  {
    id: 16170,
    name: "Annapolis County",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "44.58345000",
    longitude: "-65.16551000",
  },
  {
    id: 16171,
    name: "Antigonish",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "45.61685000",
    longitude: "-61.99858000",
  },
  {
    id: 16216,
    name: "Berwick",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "45.05015000",
    longitude: "-64.73208000",
  },
  {
    id: 16242,
    name: "Bridgewater",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "44.37856000",
    longitude: "-64.51882000",
  },
  {
    id: 16272,
    name: "Cape Breton County",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "46.00014000",
    longitude: "-60.31516000",
  },
  {
    id: 16304,
    name: "Chester",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "44.54225000",
    longitude: "-64.23891000",
  },
  {
    id: 16322,
    name: "Colchester",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "45.33345000",
    longitude: "-63.24868000",
  },
  {
    id: 16324,
    name: "Cole Harbour",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "44.67244000",
    longitude: "-63.47506000",
  },
  {
    id: 16342,
    name: "Cow Bay",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "44.63141000",
    longitude: "-63.43218000",
  },
  {
    id: 16355,
    name: "Dartmouth",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "44.67134000",
    longitude: "-63.57719000",
  },
  {
    id: 16374,
    name: "Digby",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "44.62188000",
    longitude: "-65.75860000",
  },
  {
    id: 16375,
    name: "Digby County",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "44.36685000",
    longitude: "-65.69884000",
  },
  {
    id: 16406,
    name: "English Corner",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "44.73345000",
    longitude: "-63.78201000",
  },
  {
    id: 16407,
    name: "Eskasoni 3",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "45.94522000",
    longitude: "-60.61617000",
  },
  {
    id: 16416,
    name: "Fall River",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "44.81685000",
    longitude: "-63.61540000",
  },
  {
    id: 16454,
    name: "Glace Bay",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "46.19695000",
    longitude: "-59.95698000",
  },
  {
    id: 16475,
    name: "Greenwood",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "44.97413000",
    longitude: "-64.93169000",
  },
  {
    id: 16482,
    name: "Halifax",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "44.64533000",
    longitude: "-63.57239000",
  },
  {
    id: 16490,
    name: "Hantsport",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "45.06685000",
    longitude: "-64.16544000",
  },
  {
    id: 16499,
    name: "Hayes Subdivision",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "45.05519000",
    longitude: "-64.58795000",
  },
  {
    id: 16539,
    name: "Kentville",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "45.07710000",
    longitude: "-64.49605000",
  },
  {
    id: 16589,
    name: "Lake Echo",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "44.73345000",
    longitude: "-63.38198000",
  },
  {
    id: 16600,
    name: "Lantz",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "44.98345000",
    longitude: "-63.48199000",
  },
  {
    id: 16632,
    name: "Lower Sackville",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "44.77599000",
    longitude: "-63.67865000",
  },
  {
    id: 16637,
    name: "Lunenburg",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "44.37847000",
    longitude: "-64.31658000",
  },
  {
    id: 16686,
    name: "Middleton",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "44.94284000",
    longitude: "-65.07022000",
  },
  {
    id: 16739,
    name: "New Glasgow",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "45.58344000",
    longitude: "-62.64863000",
  },
  {
    id: 16791,
    name: "Oxford",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "45.73345000",
    longitude: "-63.86542000",
  },
  {
    id: 16799,
    name: "Parrsboro",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "45.40567000",
    longitude: "-64.32585000",
  },
  {
    id: 16818,
    name: "Pictou",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "45.67875000",
    longitude: "-62.70936000",
  },
  {
    id: 16819,
    name: "Pictou County",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "45.50015000",
    longitude: "-62.58193000",
  },
  {
    id: 16840,
    name: "Port Hawkesbury",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "45.61685000",
    longitude: "-61.34853000",
  },
  {
    id: 16846,
    name: "Port Williams",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "45.10015000",
    longitude: "-64.41546000",
  },
  {
    id: 16859,
    name: "Princeville",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "45.76684000",
    longitude: "-61.29853000",
  },
  {
    id: 17037,
    name: "Shelburne",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "43.76325000",
    longitude: "-65.32355000",
  },
  {
    id: 17064,
    name: "Springhill",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "45.65015000",
    longitude: "-64.04873000",
  },
  {
    id: 17095,
    name: "Sydney",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "46.13510000",
    longitude: "-60.18310000",
  },
  {
    id: 17096,
    name: "Sydney Mines",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "46.23669000",
    longitude: "-60.21767000",
  },
  {
    id: 17127,
    name: "Truro",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "45.36685000",
    longitude: "-63.26538000",
  },
  {
    id: 17211,
    name: "Windsor",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "44.98345000",
    longitude: "-64.13204000",
  },
  {
    id: 17216,
    name: "Wolfville",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "45.08345000",
    longitude: "-64.36546000",
  },
  {
    id: 17220,
    name: "Yarmouth",
    state_id: 874,
    state_code: "NS",
    country_id: 39,
    country_code: "CA",
    latitude: "43.83345000",
    longitude: "-66.11557000",
  },
  {
    id: 16315,
    name: "Clyde River",
    state_id: 876,
    state_code: "NU",
    country_id: 39,
    country_code: "CA",
    latitude: "70.47233000",
    longitude: "-68.58987000",
  },
  {
    id: 16453,
    name: "Gjoa Haven",
    state_id: 876,
    state_code: "NU",
    country_id: 39,
    country_code: "CA",
    latitude: "68.62602000",
    longitude: "-95.87836000",
  },
  {
    id: 16524,
    name: "Iqaluit",
    state_id: 876,
    state_code: "NU",
    country_id: 39,
    country_code: "CA",
    latitude: "63.74697000",
    longitude: "-68.51727000",
  },
  {
    id: 16555,
    name: "Kugluktuk",
    state_id: 876,
    state_code: "NU",
    country_id: 39,
    country_code: "CA",
    latitude: "67.82743000",
    longitude: "-115.09649000",
  },
  {
    id: 16793,
    name: "Pangnirtung",
    state_id: 876,
    state_code: "NU",
    country_id: 39,
    country_code: "CA",
    latitude: "66.14642000",
    longitude: "-65.69996000",
  },
  {
    id: 16869,
    name: "Rankin Inlet",
    state_id: 876,
    state_code: "NU",
    country_id: 39,
    country_code: "CA",
    latitude: "62.80906000",
    longitude: "-92.08534000",
  },
  {
    id: 16152,
    name: "Ajax",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.85012000",
    longitude: "-79.03288000",
  },
  {
    id: 16157,
    name: "Algoma",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "47.88364000",
    longitude: "-84.42406000",
  },
  {
    id: 16158,
    name: "Alliston",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.15011000",
    longitude: "-79.86635000",
  },
  {
    id: 16162,
    name: "Amherstburg",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "42.10009000",
    longitude: "-83.09985000",
  },
  {
    id: 16163,
    name: "Amigo Beach",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.70011000",
    longitude: "-79.39963000",
  },
  {
    id: 16166,
    name: "Ancaster",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.21806000",
    longitude: "-79.98716000",
  },
  {
    id: 16168,
    name: "Angus",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.31681000",
    longitude: "-79.88295000",
  },
  {
    id: 16174,
    name: "Arnprior",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.43341000",
    longitude: "-76.34939000",
  },
  {
    id: 16179,
    name: "Atikokan",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "48.75667000",
    longitude: "-91.62409000",
  },
  {
    id: 16180,
    name: "Attawapiskat",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "52.92774000",
    longitude: "-82.41669000",
  },
  {
    id: 16181,
    name: "Aurora",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.00011000",
    longitude: "-79.46632000",
  },
  {
    id: 16182,
    name: "Aylmer",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "42.76679000",
    longitude: "-80.98302000",
  },
  {
    id: 16183,
    name: "Azilda",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "46.55008000",
    longitude: "-81.09975000",
  },
  {
    id: 16188,
    name: "Ballantrae",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.03342000",
    longitude: "-79.29960000",
  },
  {
    id: 16189,
    name: "Bancroft",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.05752000",
    longitude: "-77.85702000",
  },
  {
    id: 16193,
    name: "Barrie",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.40011000",
    longitude: "-79.66634000",
  },
  {
    id: 16197,
    name: "Bath",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.18342000",
    longitude: "-76.78273000",
  },
  {
    id: 16211,
    name: "Belleville",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.16682000",
    longitude: "-77.38277000",
  },
  {
    id: 16212,
    name: "Bells Corners",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.31588000",
    longitude: "-75.83012000",
  },
  {
    id: 16213,
    name: "Belmont",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "42.88339000",
    longitude: "-81.08303000",
  },
  {
    id: 16218,
    name: "Binbrook",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.12135000",
    longitude: "-79.81104000",
  },
  {
    id: 16222,
    name: "Bluewater",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.46679000",
    longitude: "-81.59977000",
  },
  {
    id: 16233,
    name: "Bourget",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.43340000",
    longitude: "-75.14930000",
  },
  {
    id: 16236,
    name: "Bracebridge",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.03341000",
    longitude: "-79.31633000",
  },
  {
    id: 16237,
    name: "Brampton",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.68341000",
    longitude: "-79.76633000",
  },
  {
    id: 16239,
    name: "Brant",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.13340000",
    longitude: "-80.34967000",
  },
  {
    id: 16240,
    name: "Brantford",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.13340000",
    longitude: "-80.26636000",
  },
  {
    id: 16243,
    name: "Brockville",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.59132000",
    longitude: "-75.68705000",
  },
  {
    id: 16248,
    name: "Brussels",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.73339000",
    longitude: "-81.24975000",
  },
  {
    id: 16250,
    name: "Burford",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.10292000",
    longitude: "-80.42869000",
  },
  {
    id: 16252,
    name: "Burlington",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.38621000",
    longitude: "-79.83713000",
  },
  {
    id: 16261,
    name: "Cambridge",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.36010000",
    longitude: "-80.31269000",
  },
  {
    id: 16262,
    name: "Camlachie",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.03596000",
    longitude: "-82.16160000",
  },
  {
    id: 16274,
    name: "Capreol",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "46.70626000",
    longitude: "-80.92109000",
  },
  {
    id: 16280,
    name: "Carleton Place",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.13341000",
    longitude: "-76.14938000",
  },
  {
    id: 16285,
    name: "Casselman",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.31680000",
    longitude: "-75.08260000",
  },
  {
    id: 16299,
    name: "Chatham",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "42.41224000",
    longitude: "-82.18494000",
  },
  {
    id: 16300,
    name: "Chatham-Kent",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "42.40009000",
    longitude: "-82.18310000",
  },
  {
    id: 16312,
    name: "Clarence-Rockland",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.55010000",
    longitude: "-75.29101000",
  },
  {
    id: 16319,
    name: "Cobourg",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.95977000",
    longitude: "-78.16515000",
  },
  {
    id: 16321,
    name: "Cochrane District",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "50.00022000",
    longitude: "-82.99979000",
  },
  {
    id: 16325,
    name: "Collingwood",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.48340000",
    longitude: "-80.21638000",
  },
  {
    id: 16328,
    name: "Concord",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.80011000",
    longitude: "-79.48291000",
  },
  {
    id: 16329,
    name: "Constance Bay",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.50011000",
    longitude: "-76.08267000",
  },
  {
    id: 16333,
    name: "Cookstown",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.18341000",
    longitude: "-79.69964000",
  },
  {
    id: 16337,
    name: "Cornwall",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.01809000",
    longitude: "-74.72815000",
  },
  {
    id: 16339,
    name: "Corunna",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "42.88338000",
    longitude: "-82.43313000",
  },
  {
    id: 16361,
    name: "Deep River",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "46.10012000",
    longitude: "-77.49949000",
  },
  {
    id: 16363,
    name: "Delaware",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "42.91679000",
    longitude: "-81.41646000",
  },
  {
    id: 16369,
    name: "Deseronto",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.20012000",
    longitude: "-77.04944000",
  },
  {
    id: 16380,
    name: "Dorchester",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "42.98339000",
    longitude: "-81.06643000",
  },
  {
    id: 16382,
    name: "Dowling",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "46.59111000",
    longitude: "-81.33917000",
  },
  {
    id: 16385,
    name: "Dryden",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "49.78334000",
    longitude: "-92.75032000",
  },
  {
    id: 16389,
    name: "Durham",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.20012000",
    longitude: "-78.99957000",
  },
  {
    id: 16390,
    name: "Ear Falls",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "50.63955000",
    longitude: "-93.23526000",
  },
  {
    id: 16393,
    name: "East Gwillimbury",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.10087000",
    longitude: "-79.43785000",
  },
  {
    id: 16395,
    name: "East York",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.69053000",
    longitude: "-79.32794000",
  },
  {
    id: 16401,
    name: "Elliot Lake",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "46.38336000",
    longitude: "-82.63315000",
  },
  {
    id: 16403,
    name: "Elmvale",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.58340000",
    longitude: "-79.86636000",
  },
  {
    id: 16405,
    name: "Englehart",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "47.81686000",
    longitude: "-79.86640000",
  },
  {
    id: 16408,
    name: "Espanola",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "46.25837000",
    longitude: "-81.76649000",
  },
  {
    id: 16409,
    name: "Essex",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "42.17509000",
    longitude: "-82.82483000",
  },
  {
    id: 16412,
    name: "Etobicoke",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.65421000",
    longitude: "-79.56711000",
  },
  {
    id: 16427,
    name: "Fort Erie",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "42.90012000",
    longitude: "-78.93286000",
  },
  {
    id: 16428,
    name: "Fort Frances",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "48.61667000",
    longitude: "-93.40030000",
  },
  {
    id: 16446,
    name: "Gananoque",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.33342000",
    longitude: "-76.16607000",
  },
  {
    id: 16455,
    name: "Glencoe",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "42.75009000",
    longitude: "-81.71648000",
  },
  {
    id: 16457,
    name: "Goderich",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.74171000",
    longitude: "-81.71339000",
  },
  {
    id: 16459,
    name: "Golden",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "51.05917000",
    longitude: "-93.73568000",
  },
  {
    id: 16470,
    name: "Gravenhurst",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.91681000",
    longitude: "-79.36633000",
  },
  {
    id: 16472,
    name: "Greater Napanee",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.25012000",
    longitude: "-76.94944000",
  },
  {
    id: 16473,
    name: "Greater Sudbury",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "46.49000000",
    longitude: "-80.99001000",
  },
  {
    id: 16474,
    name: "Greenstone",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "49.73343000",
    longitude: "-87.16668000",
  },
  {
    id: 16478,
    name: "Guelph",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.54594000",
    longitude: "-80.25599000",
  },
  {
    id: 16480,
    name: "Haldimand County",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "42.98341000",
    longitude: "-79.86633000",
  },
  {
    id: 16481,
    name: "Haliburton Village",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.05154000",
    longitude: "-78.52245000",
  },
  {
    id: 16483,
    name: "Halton",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.50011000",
    longitude: "-79.88294000",
  },
  {
    id: 16484,
    name: "Hamilton",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.25011000",
    longitude: "-79.84963000",
  },
  {
    id: 16489,
    name: "Hanover",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.15009000",
    longitude: "-81.03303000",
  },
  {
    id: 16494,
    name: "Harriston",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.90009000",
    longitude: "-80.88302000",
  },
  {
    id: 16497,
    name: "Hawkesbury",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.60009000",
    longitude: "-74.61595000",
  },
  {
    id: 16501,
    name: "Hearst",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "49.68351000",
    longitude: "-83.66654000",
  },
  {
    id: 16509,
    name: "Hornepayne",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "49.21451000",
    longitude: "-84.77617000",
  },
  {
    id: 16515,
    name: "Huntsville",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.33341000",
    longitude: "-79.21632000",
  },
  {
    id: 16516,
    name: "Huron East",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.61679000",
    longitude: "-81.29975000",
  },
  {
    id: 16520,
    name: "Ingersoll",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.03339000",
    longitude: "-80.88302000",
  },
  {
    id: 16521,
    name: "Innisfil",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.30011000",
    longitude: "-79.64964000",
  },
  {
    id: 16525,
    name: "Iroquois Falls",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "48.76688000",
    longitude: "-80.68307000",
  },
  {
    id: 16527,
    name: "Jarvis",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "42.88341000",
    longitude: "-80.09965000",
  },
  {
    id: 16533,
    name: "Kanata",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.30010000",
    longitude: "-75.91606000",
  },
  {
    id: 16534,
    name: "Kapuskasing",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "49.41694000",
    longitude: "-82.43308000",
  },
  {
    id: 16535,
    name: "Kawartha Lakes",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.58342000",
    longitude: "-78.83288000",
  },
  {
    id: 16537,
    name: "Kenora",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "49.76741000",
    longitude: "-94.48985000",
  },
  {
    id: 16541,
    name: "Keswick",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.25011000",
    longitude: "-79.46632000",
  },
  {
    id: 16546,
    name: "Kincardine",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.18339000",
    longitude: "-81.63307000",
  },
  {
    id: 16548,
    name: "King",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.96514000",
    longitude: "-79.59011000",
  },
  {
    id: 16550,
    name: "Kingston",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.22976000",
    longitude: "-76.48098000",
  },
  {
    id: 16552,
    name: "Kirkland Lake",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "48.14461000",
    longitude: "-80.03767000",
  },
  {
    id: 16553,
    name: "Kitchener",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.42537000",
    longitude: "-80.51120000",
  },
  {
    id: 16560,
    name: "L'Orignal",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.61980000",
    longitude: "-74.69150000",
  },
  {
    id: 16590,
    name: "Lakefield",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.43342000",
    longitude: "-78.26623000",
  },
  {
    id: 16591,
    name: "Lambton Shores",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.16678000",
    longitude: "-81.93309000",
  },
  {
    id: 16601,
    name: "Lappe",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "48.56680000",
    longitude: "-89.35013000",
  },
  {
    id: 16607,
    name: "Leamington",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "42.05009000",
    longitude: "-82.59981000",
  },
  {
    id: 16617,
    name: "Limoges",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.33340000",
    longitude: "-75.24931000",
  },
  {
    id: 16619,
    name: "Lindsay",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.35012000",
    longitude: "-78.73286000",
  },
  {
    id: 16622,
    name: "Listowel",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.73340000",
    longitude: "-80.94973000",
  },
  {
    id: 16623,
    name: "Little Current",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.97927000",
    longitude: "-81.92480000",
  },
  {
    id: 16624,
    name: "Lively",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "46.43338000",
    longitude: "-81.14975000",
  },
  {
    id: 16627,
    name: "London",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "42.98339000",
    longitude: "-81.23304000",
  },
  {
    id: 16633,
    name: "Lucan",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.18339000",
    longitude: "-81.39976000",
  },
  {
    id: 16643,
    name: "Madoc",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.50842000",
    longitude: "-77.47448000",
  },
  {
    id: 16650,
    name: "Manitoulin District",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.75007000",
    longitude: "-82.49985000",
  },
  {
    id: 16651,
    name: "Manitouwadge",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "49.12152000",
    longitude: "-85.84030000",
  },
  {
    id: 16658,
    name: "Marathon",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "48.75010000",
    longitude: "-86.43322000",
  },
  {
    id: 16661,
    name: "Markdale",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.31680000",
    longitude: "-80.64971000",
  },
  {
    id: 16662,
    name: "Markham",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.86682000",
    longitude: "-79.26630000",
  },
  {
    id: 16669,
    name: "Mattawa",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "46.31681000",
    longitude: "-78.69957000",
  },
  {
    id: 16674,
    name: "Meaford",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.60725000",
    longitude: "-80.61081000",
  },
  {
    id: 16683,
    name: "Metcalfe",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.23340000",
    longitude: "-75.46603000",
  },
  {
    id: 16687,
    name: "Midland",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.75010000",
    longitude: "-79.88296000",
  },
  {
    id: 16688,
    name: "Mildmay",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.05009000",
    longitude: "-81.11644000",
  },
  {
    id: 16690,
    name: "Millbrook",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.15012000",
    longitude: "-78.44954000",
  },
  {
    id: 16692,
    name: "Milton",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.51681000",
    longitude: "-79.88294000",
  },
  {
    id: 16697,
    name: "Mississauga",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.57890000",
    longitude: "-79.65830000",
  },
  {
    id: 16698,
    name: "Mississauga Beach",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.26682000",
    longitude: "-79.08287000",
  },
  {
    id: 16712,
    name: "Moose Factory",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "51.26689000",
    longitude: "-80.61624000",
  },
  {
    id: 16716,
    name: "Moosonee",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "51.27931000",
    longitude: "-80.63450000",
  },
  {
    id: 16721,
    name: "Morrisburg",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.90010000",
    longitude: "-75.18261000",
  },
  {
    id: 16722,
    name: "Mount Albert",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.13341000",
    longitude: "-79.31630000",
  },
  {
    id: 16723,
    name: "Mount Brydges",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "42.90009000",
    longitude: "-81.48306000",
  },
  {
    id: 16730,
    name: "Napanee",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.25012000",
    longitude: "-76.94944000",
  },
  {
    id: 16731,
    name: "Napanee Downtown",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.24832000",
    longitude: "-76.95069000",
  },
  {
    id: 16733,
    name: "Neebing",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "48.16680000",
    longitude: "-89.41683000",
  },
  {
    id: 16736,
    name: "Nepean",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.35215000",
    longitude: "-75.73975000",
  },
  {
    id: 16740,
    name: "New Hamburg",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.38339000",
    longitude: "-80.69970000",
  },
  {
    id: 16744,
    name: "Newmarket",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.05011000",
    longitude: "-79.46631000",
  },
  {
    id: 16745,
    name: "Niagara Falls",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.10012000",
    longitude: "-79.06627000",
  },
  {
    id: 16748,
    name: "Nipissing District",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "46.00010000",
    longitude: "-78.99959000",
  },
  {
    id: 16752,
    name: "Norfolk County",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "42.83340000",
    longitude: "-80.38297000",
  },
  {
    id: 16756,
    name: "North Bay",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "46.31680000",
    longitude: "-79.46633000",
  },
  {
    id: 16759,
    name: "North Perth",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.72510000",
    longitude: "-80.96723000",
  },
  {
    id: 16762,
    name: "North York",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.76681000",
    longitude: "-79.41630000",
  },
  {
    id: 16763,
    name: "Norwood",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.38342000",
    longitude: "-77.98281000",
  },
  {
    id: 16770,
    name: "Oakville",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.45011000",
    longitude: "-79.68292000",
  },
  {
    id: 16777,
    name: "Omemee",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.29897000",
    longitude: "-78.55989000",
  },
  {
    id: 16778,
    name: "Orangeville",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.91680000",
    longitude: "-80.09967000",
  },
  {
    id: 16779,
    name: "Orillia",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.60868000",
    longitude: "-79.42068000",
  },
  {
    id: 16782,
    name: "Osgoode",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.14887000",
    longitude: "-75.59778000",
  },
  {
    id: 16783,
    name: "Oshawa",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.90012000",
    longitude: "-78.84957000",
  },
  {
    id: 16785,
    name: "Ottawa",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.41117000",
    longitude: "-75.69812000",
  },
  {
    id: 16789,
    name: "Owen Sound",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.56717000",
    longitude: "-80.94349000",
  },
  {
    id: 16792,
    name: "Paisley",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.30641000",
    longitude: "-81.27265000",
  },
  {
    id: 16796,
    name: "Paris",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.20000000",
    longitude: "-80.38333000",
  },
  {
    id: 16797,
    name: "Parkhill",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.15993000",
    longitude: "-81.68464000",
  },
  {
    id: 16800,
    name: "Parry Sound",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.34732000",
    longitude: "-80.03527000",
  },
  {
    id: 16801,
    name: "Parry Sound District",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.75011000",
    longitude: "-79.83297000",
  },
  {
    id: 16806,
    name: "Peel",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.75011000",
    longitude: "-79.78293000",
  },
  {
    id: 16809,
    name: "Pembroke",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.81681000",
    longitude: "-77.11616000",
  },
  {
    id: 16812,
    name: "Perth",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.90011000",
    longitude: "-76.24939000",
  },
  {
    id: 16813,
    name: "Petawawa",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.89452000",
    longitude: "-77.28007000",
  },
  {
    id: 16814,
    name: "Peterborough",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.30012000",
    longitude: "-78.31623000",
  },
  {
    id: 16815,
    name: "Petrolia",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "42.86678000",
    longitude: "-82.14981000",
  },
  {
    id: 16816,
    name: "Pickering",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.90012000",
    longitude: "-79.13289000",
  },
  {
    id: 16817,
    name: "Picton",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.00012000",
    longitude: "-77.13275000",
  },
  {
    id: 16827,
    name: "Plantagenet",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.53260000",
    longitude: "-74.99369000",
  },
  {
    id: 16828,
    name: "Plattsville",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.30010000",
    longitude: "-80.61639000",
  },
  {
    id: 16838,
    name: "Port Colborne",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "42.90012000",
    longitude: "-79.23288000",
  },
  {
    id: 16841,
    name: "Port Hope",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.01682000",
    longitude: "-78.39953000",
  },
  {
    id: 16844,
    name: "Port Rowan",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "42.61680000",
    longitude: "-80.46638000",
  },
  {
    id: 16845,
    name: "Port Stanley",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "42.66679000",
    longitude: "-81.21644000",
  },
  {
    id: 16850,
    name: "Powassan",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "46.03340000",
    longitude: "-79.34961000",
  },
  {
    id: 16853,
    name: "Prescott",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.71681000",
    longitude: "-75.51604000",
  },
  {
    id: 16855,
    name: "Prince Edward",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.00012000",
    longitude: "-77.24946000",
  },
  {
    id: 16864,
    name: "Queenswood Heights",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.47083000",
    longitude: "-75.50556000",
  },
  {
    id: 16866,
    name: "Quinte West",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.18342000",
    longitude: "-77.56618000",
  },
  {
    id: 16868,
    name: "Rainy River District",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "48.49981000",
    longitude: "-92.50031000",
  },
  {
    id: 16872,
    name: "Rayside-Balfour",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "46.60873000",
    longitude: "-81.20763000",
  },
  {
    id: 16874,
    name: "Red Lake",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "51.01678000",
    longitude: "-93.82736000",
  },
  {
    id: 16879,
    name: "Regional Municipality of Waterloo",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.50010000",
    longitude: "-80.49969000",
  },
  {
    id: 16880,
    name: "Renfrew",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.46681000",
    longitude: "-76.68272000",
  },
  {
    id: 16887,
    name: "Richmond",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.18340000",
    longitude: "-75.83266000",
  },
  {
    id: 16888,
    name: "Richmond Hill",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.87111000",
    longitude: "-79.43725000",
  },
  {
    id: 16890,
    name: "Ridgetown",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "42.43339000",
    longitude: "-81.89978000",
  },
  {
    id: 16900,
    name: "Rockwood",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.61899000",
    longitude: "-80.14441000",
  },
  {
    id: 16909,
    name: "Russell",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.25010000",
    longitude: "-75.36602000",
  },
  {
    id: 17020,
    name: "Sarnia",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "42.97866000",
    longitude: "-82.40407000",
  },
  {
    id: 17022,
    name: "Sault Ste. Marie",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "46.51677000",
    longitude: "-84.33325000",
  },
  {
    id: 17023,
    name: "Scarborough",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.77223000",
    longitude: "-79.25666000",
  },
  {
    id: 17024,
    name: "Seaforth",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.55009000",
    longitude: "-81.39976000",
  },
  {
    id: 17036,
    name: "Shelburne",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.07870000",
    longitude: "-80.20408000",
  },
  {
    id: 17045,
    name: "Simcoe",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "42.83340000",
    longitude: "-80.29967000",
  },
  {
    id: 17046,
    name: "Sioux Lookout",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "50.06676000",
    longitude: "-91.98358000",
  },
  {
    id: 17048,
    name: "Skatepark",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.25122000",
    longitude: "-76.94424000",
  },
  {
    id: 17051,
    name: "Smiths Falls",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.90452000",
    longitude: "-76.02333000",
  },
  {
    id: 17057,
    name: "South Huron",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.31679000",
    longitude: "-81.51647000",
  },
  {
    id: 17059,
    name: "South River",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.83340000",
    longitude: "-79.38293000",
  },
  {
    id: 17070,
    name: "St. Catharines",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.17126000",
    longitude: "-79.24267000",
  },
  {
    id: 17071,
    name: "St. George",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.24495000",
    longitude: "-80.25144000",
  },
  {
    id: 17073,
    name: "St. Thomas",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "42.77361000",
    longitude: "-81.18038000",
  },
  {
    id: 17079,
    name: "Stirling",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.30012000",
    longitude: "-77.54948000",
  },
  {
    id: 17081,
    name: "Stoney Point",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.26681000",
    longitude: "-79.53292000",
  },
  {
    id: 17083,
    name: "Stratford",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.36679000",
    longitude: "-80.94972000",
  },
  {
    id: 17085,
    name: "Sudbury",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "47.16679000",
    longitude: "-81.99980000",
  },
  {
    id: 17100,
    name: "Tavistock",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.31679000",
    longitude: "-80.83302000",
  },
  {
    id: 17101,
    name: "Temiskaming Shores",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "47.49376000",
    longitude: "-79.71529000",
  },
  {
    id: 17106,
    name: "Thessalon",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "46.25006000",
    longitude: "-83.56660000",
  },
  {
    id: 17109,
    name: "Thorold",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.11682000",
    longitude: "-79.19958000",
  },
  {
    id: 17111,
    name: "Thunder Bay",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "48.38202000",
    longitude: "-89.25018000",
  },
  {
    id: 17112,
    name: "Thunder Bay District",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "49.50011000",
    longitude: "-88.50004000",
  },
  {
    id: 17114,
    name: "Timiskaming District",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "47.75016000",
    longitude: "-80.33303000",
  },
  {
    id: 17115,
    name: "Timmins",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "48.46686000",
    longitude: "-81.33312000",
  },
  {
    id: 17117,
    name: "Tobermory",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.25007000",
    longitude: "-81.66647000",
  },
  {
    id: 17121,
    name: "Toronto",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.70011000",
    longitude: "-79.41630000",
  },
  {
    id: 17122,
    name: "Toronto county",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.69655000",
    longitude: "-79.42909000",
  },
  {
    id: 17123,
    name: "Tottenham",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.02437000",
    longitude: "-79.80553000",
  },
  {
    id: 17130,
    name: "Tweed",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.47512000",
    longitude: "-77.31616000",
  },
  {
    id: 17136,
    name: "Uxbridge",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.10012000",
    longitude: "-79.11628000",
  },
  {
    id: 17142,
    name: "Valley East",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "46.66773000",
    longitude: "-81.00028000",
  },
  {
    id: 17147,
    name: "Vanier",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.43990000",
    longitude: "-75.66498000",
  },
  {
    id: 17150,
    name: "Vaughan",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.83610000",
    longitude: "-79.49827000",
  },
  {
    id: 17160,
    name: "Vineland",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.15012000",
    longitude: "-79.39960000",
  },
  {
    id: 17162,
    name: "Virgil",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.21682000",
    longitude: "-79.13288000",
  },
  {
    id: 17169,
    name: "Walpole Island",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "42.61520000",
    longitude: "-82.51398000",
  },
  {
    id: 17172,
    name: "Wasaga Beach",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.51680000",
    longitude: "-80.01637000",
  },
  {
    id: 17175,
    name: "Waterford",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "42.93340000",
    longitude: "-80.28296000",
  },
  {
    id: 17176,
    name: "Waterloo",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.46680000",
    longitude: "-80.51639000",
  },
  {
    id: 17178,
    name: "Watford",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "42.95008000",
    longitude: "-81.88309000",
  },
  {
    id: 17181,
    name: "Wawa",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "47.98877000",
    longitude: "-84.77411000",
  },
  {
    id: 17184,
    name: "Welland",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "42.98342000",
    longitude: "-79.24958000",
  },
  {
    id: 17185,
    name: "Wellesley",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.47691000",
    longitude: "-80.76209000",
  },
  {
    id: 17188,
    name: "Wendover",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.57275000",
    longitude: "-75.12757000",
  },
  {
    id: 17191,
    name: "West Lorne",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "42.60009000",
    longitude: "-81.59976000",
  },
  {
    id: 17208,
    name: "Willowdale",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.76672000",
    longitude: "-79.39909000",
  },
  {
    id: 17209,
    name: "Winchester",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "45.08340000",
    longitude: "-75.34933000",
  },
  {
    id: 17212,
    name: "Windsor",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "42.30008000",
    longitude: "-83.01654000",
  },
  {
    id: 17213,
    name: "Wingham",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.88793000",
    longitude: "-81.31145000",
  },
  {
    id: 17217,
    name: "Woodstock",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "43.13339000",
    longitude: "-80.74970000",
  },
  {
    id: 17222,
    name: "York",
    state_id: 866,
    state_code: "ON",
    country_id: 39,
    country_code: "CA",
    latitude: "44.00011000",
    longitude: "-79.46632000",
  },
  {
    id: 16154,
    name: "Alberton",
    state_id: 871,
    state_code: "PE",
    country_id: 39,
    country_code: "CA",
    latitude: "46.81685000",
    longitude: "-64.06542000",
  },
  {
    id: 16210,
    name: "Belfast",
    state_id: 871,
    state_code: "PE",
    country_id: 39,
    country_code: "CA",
    latitude: "46.08341000",
    longitude: "-62.88197000",
  },
  {
    id: 16297,
    name: "Charlottetown",
    state_id: 871,
    state_code: "PE",
    country_id: 39,
    country_code: "CA",
    latitude: "46.23899000",
    longitude: "-63.13414000",
  },
  {
    id: 16338,
    name: "Cornwall",
    state_id: 871,
    state_code: "PE",
    country_id: 39,
    country_code: "CA",
    latitude: "46.22652000",
    longitude: "-63.21809000",
  },
  {
    id: 16417,
    name: "Fallingbrook",
    state_id: 871,
    state_code: "PE",
    country_id: 39,
    country_code: "CA",
    latitude: "45.47558000",
    longitude: "-75.48401000",
  },
  {
    id: 16538,
    name: "Kensington",
    state_id: 871,
    state_code: "PE",
    country_id: 39,
    country_code: "CA",
    latitude: "46.43343000",
    longitude: "-63.64871000",
  },
  {
    id: 16707,
    name: "Montague",
    state_id: 871,
    state_code: "PE",
    country_id: 39,
    country_code: "CA",
    latitude: "46.16681000",
    longitude: "-62.64866000",
  },
  {
    id: 17055,
    name: "Souris",
    state_id: 871,
    state_code: "PE",
    country_id: 39,
    country_code: "CA",
    latitude: "46.35010000",
    longitude: "-62.24862000",
  },
  {
    id: 17087,
    name: "Summerside",
    state_id: 871,
    state_code: "PE",
    country_id: 39,
    country_code: "CA",
    latitude: "46.39593000",
    longitude: "-63.78762000",
  },
  {
    id: 16147,
    name: "Abitibi-Témiscamingue",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.10018000",
    longitude: "-77.78280000",
  },
  {
    id: 16148,
    name: "Acton Vale",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.65007000",
    longitude: "-72.56582000",
  },
  {
    id: 16149,
    name: "Adstock",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.05007000",
    longitude: "-71.08235000",
  },
  {
    id: 16153,
    name: "Albanel",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.88324000",
    longitude: "-72.44867000",
  },
  {
    id: 16159,
    name: "Alma",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.55009000",
    longitude: "-71.64910000",
  },
  {
    id: 16164,
    name: "Amos",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.56688000",
    longitude: "-78.11624000",
  },
  {
    id: 16165,
    name: "Amqui",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.46382000",
    longitude: "-67.43134000",
  },
  {
    id: 16167,
    name: "Ange-Gardien",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.35008000",
    longitude: "-72.93244000",
  },
  {
    id: 16175,
    name: "Asbestos",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.76678000",
    longitude: "-71.93240000",
  },
  {
    id: 16185,
    name: "Baie-Comeau",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.21679000",
    longitude: "-68.14894000",
  },
  {
    id: 16186,
    name: "Baie-D'Urfé",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.41397000",
    longitude: "-73.91586000",
  },
  {
    id: 16187,
    name: "Baie-Saint-Paul",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "47.44109000",
    longitude: "-70.49858000",
  },
  {
    id: 16191,
    name: "Barraute",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.43349000",
    longitude: "-77.63279000",
  },
  {
    id: 16195,
    name: "Bas-Saint-Laurent",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.05030000",
    longitude: "-68.02266000",
  },
  {
    id: 16201,
    name: "Beaconsfield",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.43341000",
    longitude: "-73.86586000",
  },
  {
    id: 16202,
    name: "Beauceville",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.21785000",
    longitude: "-70.77873000",
  },
  {
    id: 16203,
    name: "Beauharnois",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.31341000",
    longitude: "-73.87250000",
  },
  {
    id: 16205,
    name: "Beaupré",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "47.04428000",
    longitude: "-70.89529000",
  },
  {
    id: 16255,
    name: "Bécancour",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.34106000",
    longitude: "-72.43224000",
  },
  {
    id: 16208,
    name: "Bedford",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.11678000",
    longitude: "-72.98244000",
  },
  {
    id: 16214,
    name: "Beloeil",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.56839000",
    longitude: "-73.20568000",
  },
  {
    id: 16215,
    name: "Berthierville",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.08336000",
    longitude: "-73.18245000",
  },
  {
    id: 16221,
    name: "Blainville",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.66678000",
    longitude: "-73.88249000",
  },
  {
    id: 16223,
    name: "Bois-des-Filion",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.66678000",
    longitude: "-73.74918000",
  },
  {
    id: 16224,
    name: "Boisbriand",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.61678000",
    longitude: "-73.83249000",
  },
  {
    id: 16227,
    name: "Bonaventure",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.04573000",
    longitude: "-65.49259000",
  },
  {
    id: 16231,
    name: "Boucherville",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.59104000",
    longitude: "-73.43605000",
  },
  {
    id: 16241,
    name: "Breakeyville",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.68037000",
    longitude: "-71.22327000",
  },
  {
    id: 16244,
    name: "Bromont",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.31678000",
    longitude: "-72.64912000",
  },
  {
    id: 16246,
    name: "Brossard",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.45008000",
    longitude: "-73.46583000",
  },
  {
    id: 16247,
    name: "Brownsburg-Chatham",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.68342000",
    longitude: "-74.41590000",
  },
  {
    id: 16249,
    name: "Buckingham",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.58563000",
    longitude: "-75.42080000",
  },
  {
    id: 16256,
    name: "Cabano",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "47.68065000",
    longitude: "-68.87810000",
  },
  {
    id: 16258,
    name: "Cacouna",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "47.91657000",
    longitude: "-69.50054000",
  },
  {
    id: 16266,
    name: "Candiac",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.38338000",
    longitude: "-73.51587000",
  },
  {
    id: 16269,
    name: "Cantley",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.56680000",
    longitude: "-75.78265000",
  },
  {
    id: 16270,
    name: "Cap-Chat",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.10009000",
    longitude: "-66.68212000",
  },
  {
    id: 16271,
    name: "Cap-Santé",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.67159000",
    longitude: "-71.78812000",
  },
  {
    id: 16273,
    name: "Capitale-Nationale",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "47.37600000",
    longitude: "-71.12337000",
  },
  {
    id: 16278,
    name: "Carignan",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.45008000",
    longitude: "-73.29916000",
  },
  {
    id: 16279,
    name: "Carleton",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.09838000",
    longitude: "-66.10036000",
  },
  {
    id: 16281,
    name: "Carleton-sur-Mer",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.10749000",
    longitude: "-66.12800000",
  },
  {
    id: 16290,
    name: "Centre-du-Québec",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.01985000",
    longitude: "-71.98242000",
  },
  {
    id: 16291,
    name: "Chambly",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.45008000",
    longitude: "-73.28246000",
  },
  {
    id: 16292,
    name: "Chambord",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.43339000",
    longitude: "-72.06583000",
  },
  {
    id: 16293,
    name: "Chandler",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.34935000",
    longitude: "-64.67926000",
  },
  {
    id: 16295,
    name: "Chapais",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.78344000",
    longitude: "-74.84919000",
  },
  {
    id: 16296,
    name: "Charlemagne",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.71678000",
    longitude: "-73.48247000",
  },
  {
    id: 16310,
    name: "Château-Richer",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.96031000",
    longitude: "-71.03219000",
  },
  {
    id: 16311,
    name: "Châteauguay",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.38338000",
    longitude: "-73.74919000",
  },
  {
    id: 16301,
    name: "Chaudière-Appalaches",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.55500000",
    longitude: "-70.83080000",
  },
  {
    id: 16303,
    name: "Chertsey",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.07109000",
    longitude: "-73.89095000",
  },
  {
    id: 16307,
    name: "Chibougamau",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.91684000",
    longitude: "-74.36586000",
  },
  {
    id: 16309,
    name: "Chute-aux-Outardes",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.11679000",
    longitude: "-68.39896000",
  },
  {
    id: 16318,
    name: "Coaticook",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.13339000",
    longitude: "-71.79907000",
  },
  {
    id: 16330,
    name: "Contrecoeur",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.85008000",
    longitude: "-73.23245000",
  },
  {
    id: 16331,
    name: "Cookshire",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.41536000",
    longitude: "-71.62962000",
  },
  {
    id: 16332,
    name: "Cookshire-Eaton",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.41675000",
    longitude: "-71.63240000",
  },
  {
    id: 16351,
    name: "Côte-Nord",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "50.86683000",
    longitude: "-65.81541000",
  },
  {
    id: 16352,
    name: "Côte-Saint-Luc",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.46536000",
    longitude: "-73.66585000",
  },
  {
    id: 16340,
    name: "Coteau-du-Lac",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.30008000",
    longitude: "-74.18253000",
  },
  {
    id: 16343,
    name: "Cowansville",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.20008000",
    longitude: "-72.74913000",
  },
  {
    id: 16345,
    name: "Crabtree",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.96677000",
    longitude: "-73.46586000",
  },
  {
    id: 16354,
    name: "Danville",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.78337000",
    longitude: "-72.01580000",
  },
  {
    id: 16357,
    name: "Daveluyville",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.20006000",
    longitude: "-72.13239000",
  },
  {
    id: 16365,
    name: "Delson",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.36678000",
    longitude: "-73.54917000",
  },
  {
    id: 16370,
    name: "Deux-Montagnes",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.53455000",
    longitude: "-73.90168000",
  },
  {
    id: 16376,
    name: "Disraeli",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.90007000",
    longitude: "-71.34907000",
  },
  {
    id: 16377,
    name: "Dolbeau-Mistassini",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.87860000",
    longitude: "-72.23142000",
  },
  {
    id: 16378,
    name: "Dollard-Des Ormeaux",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.49452000",
    longitude: "-73.82419000",
  },
  {
    id: 16379,
    name: "Donnacona",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.68042000",
    longitude: "-71.72390000",
  },
  {
    id: 16381,
    name: "Dorval",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.44730000",
    longitude: "-73.75335000",
  },
  {
    id: 16384,
    name: "Drummondville",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.88336000",
    longitude: "-72.48241000",
  },
  {
    id: 16388,
    name: "Dunham",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.13338000",
    longitude: "-72.79913000",
  },
  {
    id: 16391,
    name: "East Angus",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.48338000",
    longitude: "-71.66577000",
  },
  {
    id: 16392,
    name: "East Broughton",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.21358000",
    longitude: "-71.07674000",
  },
  {
    id: 16418,
    name: "Farnham",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.28338000",
    longitude: "-72.98244000",
  },
  {
    id: 16419,
    name: "Ferme-Neuve",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.70011000",
    longitude: "-75.44929000",
  },
  {
    id: 16420,
    name: "Fermont",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "52.78345000",
    longitude: "-67.08204000",
  },
  {
    id: 16426,
    name: "Forestville",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.73808000",
    longitude: "-69.08478000",
  },
  {
    id: 16436,
    name: "Fort-Coulonge",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.85011000",
    longitude: "-76.73272000",
  },
  {
    id: 16437,
    name: "Fossambault-sur-le-Lac",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.87662000",
    longitude: "-71.61541000",
  },
  {
    id: 16439,
    name: "Franklin",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.03338000",
    longitude: "-73.91591000",
  },
  {
    id: 16447,
    name: "Gaspé",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.83341000",
    longitude: "-64.48194000",
  },
  {
    id: 16448,
    name: "Gaspésie-Îles-de-la-Madeleine",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.87555000",
    longitude: "-65.40710000",
  },
  {
    id: 16449,
    name: "Gatineau",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.47723000",
    longitude: "-75.70164000",
  },
  {
    id: 16456,
    name: "Godefroy",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "51.75012000",
    longitude: "-68.08213000",
  },
  {
    id: 16461,
    name: "Granby",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.40008000",
    longitude: "-72.73243000",
  },
  {
    id: 16485,
    name: "Hampstead",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.48064000",
    longitude: "-73.66307000",
  },
  {
    id: 16495,
    name: "Hauterive",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.19572000",
    longitude: "-68.25813000",
  },
  {
    id: 16496,
    name: "Havre-Saint-Pierre",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "50.24342000",
    longitude: "-63.60264000",
  },
  {
    id: 16517,
    name: "Hérouxville",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.66617000",
    longitude: "-72.62512000",
  },
  {
    id: 16511,
    name: "Hudson",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.45008000",
    longitude: "-74.14922000",
  },
  {
    id: 16514,
    name: "Huntingdon",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.08339000",
    longitude: "-74.16593000",
  },
  {
    id: 16529,
    name: "Joliette",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.01640000",
    longitude: "-73.42360000",
  },
  {
    id: 16530,
    name: "Jonquière",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.41648000",
    longitude: "-71.24884000",
  },
  {
    id: 16549,
    name: "Kingsey Falls",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.85007000",
    longitude: "-72.06580000",
  },
  {
    id: 16551,
    name: "Kirkland",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.45008000",
    longitude: "-73.86586000",
  },
  {
    id: 16556,
    name: "L'Ancienne-Lorette",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.79392000",
    longitude: "-71.35191000",
  },
  {
    id: 16557,
    name: "L'Ange-Gardien",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.91976000",
    longitude: "-71.08253000",
  },
  {
    id: 16558,
    name: "L'Ascension-de-Notre-Seigneur",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.68339000",
    longitude: "-71.66580000",
  },
  {
    id: 16559,
    name: "L'Assomption",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.82318000",
    longitude: "-73.42940000",
  },
  {
    id: 16561,
    name: "L'Épiphanie",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.85008000",
    longitude: "-73.48246000",
  },
  {
    id: 16562,
    name: "L'Île-Perrot",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.38338000",
    longitude: "-73.94920000",
  },
  {
    id: 16564,
    name: "La Conception",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.15009000",
    longitude: "-74.69925000",
  },
  {
    id: 16565,
    name: "La Haute-Saint-Charles",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.89028000",
    longitude: "-71.37222000",
  },
  {
    id: 16566,
    name: "La Malbaie",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "47.65400000",
    longitude: "-70.15268000",
  },
  {
    id: 16567,
    name: "La Minerve",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.25009000",
    longitude: "-74.93257000",
  },
  {
    id: 16568,
    name: "La Pocatière",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "47.36733000",
    longitude: "-70.03484000",
  },
  {
    id: 16569,
    name: "La Prairie",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.41678000",
    longitude: "-73.49917000",
  },
  {
    id: 16571,
    name: "La Sarre",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.80019000",
    longitude: "-79.19964000",
  },
  {
    id: 16572,
    name: "La Tuque",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "47.43337000",
    longitude: "-72.78240000",
  },
  {
    id: 16573,
    name: "Labelle",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.28339000",
    longitude: "-74.73255000",
  },
  {
    id: 16577,
    name: "Lac-Alouette",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.81698000",
    longitude: "-73.95920000",
  },
  {
    id: 16578,
    name: "Lac-Brome",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.21678000",
    longitude: "-72.51581000",
  },
  {
    id: 16579,
    name: "Lac-Connelly",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.89788000",
    longitude: "-73.97230000",
  },
  {
    id: 16580,
    name: "Lac-Lapierre",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.89837000",
    longitude: "-73.77308000",
  },
  {
    id: 16581,
    name: "Lac-Mégantic",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.58338000",
    longitude: "-70.88234000",
  },
  {
    id: 16582,
    name: "Lac-Simon",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.15699000",
    longitude: "-74.74129000",
  },
  {
    id: 16583,
    name: "Lachute",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.65008000",
    longitude: "-74.33253000",
  },
  {
    id: 16584,
    name: "Lacolle",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.08338000",
    longitude: "-73.36585000",
  },
  {
    id: 16599,
    name: "Lanoraie",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.96677000",
    longitude: "-73.21585000",
  },
  {
    id: 16604,
    name: "Laval",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.56995000",
    longitude: "-73.69200000",
  },
  {
    id: 16605,
    name: "Lavaltrie",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.88338000",
    longitude: "-73.28245000",
  },
  {
    id: 16606,
    name: "Le Bic",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.37549000",
    longitude: "-68.69415000",
  },
  {
    id: 17224,
    name: "le Plateau",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.43514000",
    longitude: "-75.78030000",
  },
  {
    id: 16608,
    name: "Lebel-sur-Quévillon",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.05018000",
    longitude: "-76.98273000",
  },
  {
    id: 16609,
    name: "Leblanc",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.26683000",
    longitude: "-74.34914000",
  },
  {
    id: 16612,
    name: "Les Cèdres",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.30008000",
    longitude: "-74.04922000",
  },
  {
    id: 16611,
    name: "Les Coteaux",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.28338000",
    longitude: "-74.23254000",
  },
  {
    id: 16613,
    name: "Les Escoumins",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.35191000",
    longitude: "-69.40724000",
  },
  {
    id: 16639,
    name: "Lévis",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.80326000",
    longitude: "-71.17793000",
  },
  {
    id: 16620,
    name: "Linière",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.00007000",
    longitude: "-70.41572000",
  },
  {
    id: 16628,
    name: "Longueuil",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.53121000",
    longitude: "-73.51806000",
  },
  {
    id: 16630,
    name: "Lorraine",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.68338000",
    longitude: "-73.78249000",
  },
  {
    id: 16631,
    name: "Louiseville",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.25594000",
    longitude: "-72.94145000",
  },
  {
    id: 16634,
    name: "Luceville",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.54498000",
    longitude: "-68.39658000",
  },
  {
    id: 16640,
    name: "Macamic",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.75018000",
    longitude: "-78.99962000",
  },
  {
    id: 16644,
    name: "Magog",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.26678000",
    longitude: "-72.14909000",
  },
  {
    id: 16646,
    name: "Malartic",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.13348000",
    longitude: "-78.13283000",
  },
  {
    id: 16647,
    name: "Maliotenam",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "50.21119000",
    longitude: "-66.18164000",
  },
  {
    id: 16648,
    name: "Manawan",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "47.22029000",
    longitude: "-74.38606000",
  },
  {
    id: 16649,
    name: "Mandeville",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.36677000",
    longitude: "-73.34915000",
  },
  {
    id: 16652,
    name: "Maniwaki",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.38341000",
    longitude: "-75.96605000",
  },
  {
    id: 16659,
    name: "Maria",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.17490000",
    longitude: "-65.98595000",
  },
  {
    id: 16660,
    name: "Marieville",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.43338000",
    longitude: "-73.16585000",
  },
  {
    id: 16665,
    name: "Mascouche",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.74965000",
    longitude: "-73.59956000",
  },
  {
    id: 16666,
    name: "Maskinongé",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.22860000",
    longitude: "-73.01917000",
  },
  {
    id: 16667,
    name: "Matagami",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.75018000",
    longitude: "-77.63277000",
  },
  {
    id: 16668,
    name: "Matane",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.82857000",
    longitude: "-67.52197000",
  },
  {
    id: 16670,
    name: "Mauricie",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "47.55009000",
    longitude: "-73.41583000",
  },
  {
    id: 16678,
    name: "Melocheville",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.31726000",
    longitude: "-73.93710000",
  },
  {
    id: 16680,
    name: "Mercier",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.31678000",
    longitude: "-73.74919000",
  },
  {
    id: 16725,
    name: "Métabetchouan",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.36679000",
    longitude: "-72.01583000",
  },
  {
    id: 16682,
    name: "Metabetchouan-Lac-a-la-Croix",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.41000000",
    longitude: "-71.78000000",
  },
  {
    id: 16694,
    name: "Mirabel",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.65008000",
    longitude: "-74.08251000",
  },
  {
    id: 16699,
    name: "Mistissini",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "50.41667000",
    longitude: "-73.88333000",
  },
  {
    id: 16701,
    name: "Mont-Joli",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.58388000",
    longitude: "-68.19214000",
  },
  {
    id: 16702,
    name: "Mont-Laurier",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.55011000",
    longitude: "-75.49930000",
  },
  {
    id: 16703,
    name: "Mont-Royal",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.51675000",
    longitude: "-73.64918000",
  },
  {
    id: 16704,
    name: "Mont-Saint-Grégoire",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.33338000",
    longitude: "-73.16585000",
  },
  {
    id: 16705,
    name: "Mont-Saint-Hilaire",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.56515000",
    longitude: "-73.18680000",
  },
  {
    id: 16706,
    name: "Mont-Tremblant",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.21274000",
    longitude: "-74.58438000",
  },
  {
    id: 16708,
    name: "Montmagny",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.98043000",
    longitude: "-70.55493000",
  },
  {
    id: 16709,
    name: "Montréal",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.50008000",
    longitude: "-73.68248000",
  },
  {
    id: 16710,
    name: "Montréal-Est",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.63202000",
    longitude: "-73.50750000",
  },
  {
    id: 16711,
    name: "Montréal-Ouest",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.45286000",
    longitude: "-73.64918000",
  },
  {
    id: 16718,
    name: "Morin-Heights",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.90009000",
    longitude: "-74.24922000",
  },
  {
    id: 16732,
    name: "Napierville",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.18648000",
    longitude: "-73.40468000",
  },
  {
    id: 16737,
    name: "Neuville",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.69823000",
    longitude: "-71.58275000",
  },
  {
    id: 16738,
    name: "New Carlisle",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.00956000",
    longitude: "-65.33621000",
  },
  {
    id: 16743,
    name: "New-Richmond",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.16059000",
    longitude: "-65.85823000",
  },
  {
    id: 16746,
    name: "Nicolet",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.21676000",
    longitude: "-72.61582000",
  },
  {
    id: 16751,
    name: "Nord-du-Québec",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "51.96200000",
    longitude: "-74.89610000",
  },
  {
    id: 16754,
    name: "Normandin",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.83328000",
    longitude: "-72.53209000",
  },
  {
    id: 16764,
    name: "Notre-Dame-de-Grâce",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.47675000",
    longitude: "-73.61432000",
  },
  {
    id: 16765,
    name: "Notre-Dame-de-l'Île-Perrot",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.36678000",
    longitude: "-73.93250000",
  },
  {
    id: 16766,
    name: "Notre-Dame-des-Prairies",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.05007000",
    longitude: "-73.43245000",
  },
  {
    id: 16767,
    name: "Notre-Dame-du-Lac",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.75012000",
    longitude: "-79.04961000",
  },
  {
    id: 16768,
    name: "Notre-Dame-du-Mont-Carmel",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.01680000",
    longitude: "-75.08259000",
  },
  {
    id: 16771,
    name: "Oka",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.46489000",
    longitude: "-74.08892000",
  },
  {
    id: 16780,
    name: "Ormstown",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.13338000",
    longitude: "-73.99922000",
  },
  {
    id: 16786,
    name: "Otterburn Park",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.53338000",
    longitude: "-73.21585000",
  },
  {
    id: 16787,
    name: "Outaouais",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.26681000",
    longitude: "-76.31606000",
  },
  {
    id: 16794,
    name: "Papineauville",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.61680000",
    longitude: "-75.01599000",
  },
  {
    id: 16795,
    name: "Parc-Boutin",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.29209000",
    longitude: "-73.26154000",
  },
  {
    id: 16821,
    name: "Piedmont",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.90008000",
    longitude: "-74.13251000",
  },
  {
    id: 16822,
    name: "Pierreville",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.07034000",
    longitude: "-72.81125000",
  },
  {
    id: 16825,
    name: "Pincourt",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.38338000",
    longitude: "-73.98250000",
  },
  {
    id: 16829,
    name: "Plessisville",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.21856000",
    longitude: "-71.76201000",
  },
  {
    id: 16830,
    name: "Pohénégamook",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "47.46315000",
    longitude: "-69.22666000",
  },
  {
    id: 16831,
    name: "Pointe-Calumet",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.50008000",
    longitude: "-73.96590000",
  },
  {
    id: 16832,
    name: "Pointe-Claire",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.44868000",
    longitude: "-73.81669000",
  },
  {
    id: 16833,
    name: "Pointe-du-Lac",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.50009000",
    longitude: "-71.78241000",
  },
  {
    id: 16835,
    name: "Pont Rouge",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.88332000",
    longitude: "-72.08247000",
  },
  {
    id: 16836,
    name: "Pont-Rouge",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.75468000",
    longitude: "-71.69566000",
  },
  {
    id: 16847,
    name: "Port-Cartier",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "50.03339000",
    longitude: "-66.86545000",
  },
  {
    id: 16849,
    name: "Portneuf",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.69058000",
    longitude: "-71.89011000",
  },
  {
    id: 16862,
    name: "Prévost",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.86678000",
    longitude: "-74.08251000",
  },
  {
    id: 16860,
    name: "Princeville",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.17163000",
    longitude: "-71.87462000",
  },
  {
    id: 16867,
    name: "Québec",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.81228000",
    longitude: "-71.21454000",
  },
  {
    id: 16870,
    name: "Rawdon",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.05007000",
    longitude: "-73.71587000",
  },
  {
    id: 16881,
    name: "Repentigny",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.74222000",
    longitude: "-73.45008000",
  },
  {
    id: 16883,
    name: "Richelieu",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.44336000",
    longitude: "-73.24602000",
  },
  {
    id: 16886,
    name: "Richmond",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.66677000",
    longitude: "-72.14910000",
  },
  {
    id: 16891,
    name: "Rigaud",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.47927000",
    longitude: "-74.30238000",
  },
  {
    id: 16893,
    name: "Rimouski",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.44879000",
    longitude: "-68.52396000",
  },
  {
    id: 16896,
    name: "Rivière-du-Loup",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "47.82699000",
    longitude: "-69.54243000",
  },
  {
    id: 16895,
    name: "Rivière-Rouge",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.41679000",
    longitude: "-74.86596000",
  },
  {
    id: 16897,
    name: "Roberval",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.51680000",
    longitude: "-72.23244000",
  },
  {
    id: 16899,
    name: "Rock Forest",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.35699000",
    longitude: "-71.99676000",
  },
  {
    id: 16902,
    name: "Rosemère",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.63338000",
    longitude: "-73.79919000",
  },
  {
    id: 16906,
    name: "Rougemont",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.43338000",
    longitude: "-73.04914000",
  },
  {
    id: 16907,
    name: "Rouyn-Noranda",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.23656000",
    longitude: "-79.02311000",
  },
  {
    id: 16911,
    name: "Sacré-Coeur",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.22970000",
    longitude: "-69.80061000",
  },
  {
    id: 16912,
    name: "Saguenay",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.41675000",
    longitude: "-71.06573000",
  },
  {
    id: 16915,
    name: "Saint-Adolphe-d'Howard",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.96679000",
    longitude: "-74.33253000",
  },
  {
    id: 16916,
    name: "Saint-Alexandre",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.50010000",
    longitude: "-75.74935000",
  },
  {
    id: 16917,
    name: "Saint-Amable",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.65008000",
    longitude: "-73.29916000",
  },
  {
    id: 16918,
    name: "Saint-Ambroise",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.55009000",
    longitude: "-71.33238000",
  },
  {
    id: 16919,
    name: "Saint-André-Avellin",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.71680000",
    longitude: "-75.06599000",
  },
  {
    id: 16920,
    name: "Saint-Anselme",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.62922000",
    longitude: "-70.97340000",
  },
  {
    id: 16922,
    name: "Saint-Antoine-de-Tilly",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.66346000",
    longitude: "-71.57335000",
  },
  {
    id: 16923,
    name: "Saint-Augustin",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "51.22602000",
    longitude: "-58.65017000",
  },
  {
    id: 16924,
    name: "Saint-Augustin-de-Desmaures",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.74064000",
    longitude: "-71.45131000",
  },
  {
    id: 16925,
    name: "Saint-Barnabé-Sud",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.72977000",
    longitude: "-72.92244000",
  },
  {
    id: 16926,
    name: "Saint-Basile-le-Grand",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.53338000",
    longitude: "-73.28246000",
  },
  {
    id: 16927,
    name: "Saint-Boniface",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.50011000",
    longitude: "-75.98264000",
  },
  {
    id: 16928,
    name: "Saint-Bruno",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.46679000",
    longitude: "-71.64910000",
  },
  {
    id: 16929,
    name: "Saint-Bruno-de-Guigues",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "47.46685000",
    longitude: "-79.43296000",
  },
  {
    id: 16930,
    name: "Saint-Bruno-de-Montarville",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.53341000",
    longitude: "-73.34916000",
  },
  {
    id: 16931,
    name: "Saint-Canut",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.71502000",
    longitude: "-74.08376000",
  },
  {
    id: 16935,
    name: "Saint-Césaire",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.41678000",
    longitude: "-72.99914000",
  },
  {
    id: 16932,
    name: "Saint-Charles",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.70288000",
    longitude: "-73.55417000",
  },
  {
    id: 16936,
    name: "Saint-Côme--Linière",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.06677000",
    longitude: "-70.51573000",
  },
  {
    id: 16933,
    name: "Saint-Constant",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.36678000",
    longitude: "-73.56588000",
  },
  {
    id: 16934,
    name: "Saint-Cyrille-de-Wendover",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.93336000",
    longitude: "-72.43241000",
  },
  {
    id: 16937,
    name: "Saint-Damase",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.53341000",
    longitude: "-72.99914000",
  },
  {
    id: 16938,
    name: "Saint-Denis-sur-Richelieu",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.78338000",
    longitude: "-73.14915000",
  },
  {
    id: 16939,
    name: "Saint-Donat-de-Montcalm",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.31868000",
    longitude: "-74.22171000",
  },
  {
    id: 16992,
    name: "Saint-Édouard",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.23338000",
    longitude: "-73.51588000",
  },
  {
    id: 16940,
    name: "Saint-Elzéar",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.60338000",
    longitude: "-73.72698000",
  },
  {
    id: 16993,
    name: "Saint-Éphrem-de-Beauce",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.06677000",
    longitude: "-70.94905000",
  },
  {
    id: 16941,
    name: "Saint-Eustache",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.56500000",
    longitude: "-73.90554000",
  },
  {
    id: 16942,
    name: "Saint-Félicien",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.65007000",
    longitude: "-72.44906000",
  },
  {
    id: 16943,
    name: "Saint-Félix-de-Valois",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.16977000",
    longitude: "-73.42525000",
  },
  {
    id: 16944,
    name: "Saint-Gabriel",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.30007000",
    longitude: "-73.38245000",
  },
  {
    id: 16947,
    name: "Saint-Gédéon",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.50009000",
    longitude: "-71.76581000",
  },
  {
    id: 16945,
    name: "Saint-Georges",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.11353000",
    longitude: "-70.66526000",
  },
  {
    id: 16946,
    name: "Saint-Germain-de-Grantham",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.83337000",
    longitude: "-72.56582000",
  },
  {
    id: 16948,
    name: "Saint-Henri",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.69314000",
    longitude: "-71.06927000",
  },
  {
    id: 16949,
    name: "Saint-Hippolyte",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.93338000",
    longitude: "-74.01590000",
  },
  {
    id: 16950,
    name: "Saint-Honoré",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.53338000",
    longitude: "-71.08236000",
  },
  {
    id: 16951,
    name: "Saint-Hyacinthe",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.63076000",
    longitude: "-72.95699000",
  },
  {
    id: 16952,
    name: "Saint-Isidore",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "47.43345000",
    longitude: "-79.29965000",
  },
  {
    id: 16953,
    name: "Saint-Jacques-le-Mineur",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.28338000",
    longitude: "-73.41587000",
  },
  {
    id: 16954,
    name: "Saint-Jean-Baptiste",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.38060000",
    longitude: "-74.01210000",
  },
  {
    id: 16955,
    name: "Saint-Jean-sur-Richelieu",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.30713000",
    longitude: "-73.26259000",
  },
  {
    id: 16960,
    name: "Saint-Jérôme",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.78036000",
    longitude: "-74.00365000",
  },
  {
    id: 16956,
    name: "Saint-Joseph",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.95817000",
    longitude: "-73.22025000",
  },
  {
    id: 16957,
    name: "Saint-Joseph-de-Beauce",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.30000000",
    longitude: "-70.86667000",
  },
  {
    id: 16958,
    name: "Saint-Joseph-de-Coleraine",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.96677000",
    longitude: "-71.36577000",
  },
  {
    id: 16959,
    name: "Saint-Joseph-du-Lac",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.53338000",
    longitude: "-73.99920000",
  },
  {
    id: 16961,
    name: "Saint-Lambert-de-Lauzon",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.58624000",
    longitude: "-71.20892000",
  },
  {
    id: 16962,
    name: "Saint-Laurent",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.50008000",
    longitude: "-73.66585000",
  },
  {
    id: 16963,
    name: "Saint-Lazare",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.40008000",
    longitude: "-74.13256000",
  },
  {
    id: 16966,
    name: "Saint-Léonard",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.58773000",
    longitude: "-73.59501000",
  },
  {
    id: 16968,
    name: "Saint-Léonard-d'Aston",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.10006000",
    longitude: "-72.36580000",
  },
  {
    id: 16964,
    name: "Saint-Liboire",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.65068000",
    longitude: "-72.76348000",
  },
  {
    id: 16965,
    name: "Saint-Lin-Laurentides",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.85008000",
    longitude: "-73.76588000",
  },
  {
    id: 16969,
    name: "Saint-Marc-des-Carrières",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.68335000",
    longitude: "-72.04910000",
  },
  {
    id: 16970,
    name: "Saint-Mathieu",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.31678000",
    longitude: "-73.51587000",
  },
  {
    id: 16971,
    name: "Saint-Michel",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.56758000",
    longitude: "-73.62168000",
  },
  {
    id: 16972,
    name: "Saint-Michel-des-Saints",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.67702000",
    longitude: "-73.91881000",
  },
  {
    id: 16973,
    name: "Saint-Nazaire",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.58944000",
    longitude: "-71.55247000",
  },
  {
    id: 16974,
    name: "Saint-Norbert",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.16949000",
    longitude: "-73.31494000",
  },
  {
    id: 16975,
    name: "Saint-Pacôme",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "47.40457000",
    longitude: "-69.95025000",
  },
  {
    id: 16976,
    name: "Saint-Pascal",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "47.51813000",
    longitude: "-69.80301000",
  },
  {
    id: 16977,
    name: "Saint-Philippe-de-La Prairie",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.35723000",
    longitude: "-73.47706000",
  },
  {
    id: 16978,
    name: "Saint-Pie",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.50277000",
    longitude: "-72.90890000",
  },
  {
    id: 16979,
    name: "Saint-Pierre-les-Becquets",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.50005000",
    longitude: "-72.19910000",
  },
  {
    id: 16980,
    name: "Saint-Prime",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.58339000",
    longitude: "-72.33244000",
  },
  {
    id: 16981,
    name: "Saint-Raphaël",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.25011000",
    longitude: "-76.01605000",
  },
  {
    id: 16982,
    name: "Saint-Raymond",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.46698000",
    longitude: "-73.60948000",
  },
  {
    id: 16983,
    name: "Saint-Rémi",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.26678000",
    longitude: "-73.61588000",
  },
  {
    id: 16984,
    name: "Saint-Rémi-de-Tingwick",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.86677000",
    longitude: "-71.81581000",
  },
  {
    id: 16985,
    name: "Saint-Sauveur",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.88686000",
    longitude: "-74.17943000",
  },
  {
    id: 16986,
    name: "Saint-Sauveur-des-Monts",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.90008000",
    longitude: "-74.16591000",
  },
  {
    id: 16987,
    name: "Saint-Siméon",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "47.84431000",
    longitude: "-69.87837000",
  },
  {
    id: 16988,
    name: "Saint-Thomas",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.01677000",
    longitude: "-73.34915000",
  },
  {
    id: 16989,
    name: "Saint-Tite",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.73336000",
    longitude: "-72.56581000",
  },
  {
    id: 16990,
    name: "Saint-Victor",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.61118000",
    longitude: "-73.51527000",
  },
  {
    id: 16991,
    name: "Saint-Zotique",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.25009000",
    longitude: "-74.24924000",
  },
  {
    id: 16994,
    name: "Sainte Catherine de la Jacques Cartier",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.85244000",
    longitude: "-71.62056000",
  },
  {
    id: 16995,
    name: "Sainte-Adèle",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.95008000",
    longitude: "-74.13251000",
  },
  {
    id: 16996,
    name: "Sainte-Agathe-des-Monts",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.05009000",
    longitude: "-74.28252000",
  },
  {
    id: 16997,
    name: "Sainte-Anne-de-Bellevue",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.40618000",
    longitude: "-73.94560000",
  },
  {
    id: 16998,
    name: "Sainte-Anne-des-Monts",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.12402000",
    longitude: "-66.49243000",
  },
  {
    id: 16999,
    name: "Sainte-Anne-des-Plaines",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.76468000",
    longitude: "-73.81156000",
  },
  {
    id: 17000,
    name: "Sainte-Béatrix",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.20007000",
    longitude: "-73.61587000",
  },
  {
    id: 17001,
    name: "Sainte-Catherine",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.40008000",
    longitude: "-73.58248000",
  },
  {
    id: 17002,
    name: "Sainte-Croix",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.13368000",
    longitude: "-72.80083000",
  },
  {
    id: 17012,
    name: "Sainte-Élisabeth",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.09502000",
    longitude: "-73.35176000",
  },
  {
    id: 17003,
    name: "Sainte-Julie",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.58338000",
    longitude: "-73.33246000",
  },
  {
    id: 17004,
    name: "Sainte-Julienne",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.96677000",
    longitude: "-73.71587000",
  },
  {
    id: 17005,
    name: "Sainte-Madeleine",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.60008000",
    longitude: "-73.09914000",
  },
  {
    id: 17006,
    name: "Sainte-Marie",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.43401000",
    longitude: "-71.01168000",
  },
  {
    id: 17007,
    name: "Sainte-Marthe-sur-le-Lac",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.53338000",
    longitude: "-73.93250000",
  },
  {
    id: 17008,
    name: "Sainte-Martine",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.25008000",
    longitude: "-73.79919000",
  },
  {
    id: 17009,
    name: "Sainte-Sophie",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.81678000",
    longitude: "-73.89919000",
  },
  {
    id: 17010,
    name: "Sainte-Thècle",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.81676000",
    longitude: "-72.49911000",
  },
  {
    id: 17011,
    name: "Sainte-Thérèse",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.63922000",
    longitude: "-73.82757000",
  },
  {
    id: 17013,
    name: "Salaberry-de-Valleyfield",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.25008000",
    longitude: "-74.13253000",
  },
  {
    id: 17015,
    name: "Salluit",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "62.20411000",
    longitude: "-75.64344000",
  },
  {
    id: 17027,
    name: "Senneterre",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.39302000",
    longitude: "-77.23951000",
  },
  {
    id: 17028,
    name: "Sept-Îles",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "50.20011000",
    longitude: "-66.38208000",
  },
  {
    id: 17030,
    name: "Shannon",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.88026000",
    longitude: "-71.51464000",
  },
  {
    id: 17032,
    name: "Shawinigan",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.56675000",
    longitude: "-72.74913000",
  },
  {
    id: 17033,
    name: "Shawville",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.60011000",
    longitude: "-76.48270000",
  },
  {
    id: 17039,
    name: "Sherbrooke",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.40008000",
    longitude: "-71.89908000",
  },
  {
    id: 17054,
    name: "Sorel-Tracy",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.04178000",
    longitude: "-73.11358000",
  },
  {
    id: 17066,
    name: "St-Jean-Port-Joli",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "47.21418000",
    longitude: "-70.26969000",
  },
  {
    id: 17091,
    name: "Sutton",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.10008000",
    longitude: "-72.61582000",
  },
  {
    id: 17132,
    name: "Témiscaming",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.72122000",
    longitude: "-79.09712000",
  },
  {
    id: 17103,
    name: "Terrasse-des-Pins",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.86449000",
    longitude: "-74.06627000",
  },
  {
    id: 17104,
    name: "Terrebonne",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.70004000",
    longitude: "-73.64732000",
  },
  {
    id: 17107,
    name: "Thetford-Mines",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.09371000",
    longitude: "-71.30539000",
  },
  {
    id: 17113,
    name: "Thurso",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.60010000",
    longitude: "-75.24931000",
  },
  {
    id: 17126,
    name: "Trois-Rivières",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.34515000",
    longitude: "-72.54770000",
  },
  {
    id: 17139,
    name: "Val-d'Or",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "48.09740000",
    longitude: "-77.79737000",
  },
  {
    id: 17137,
    name: "Val-David",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.03338000",
    longitude: "-74.21592000",
  },
  {
    id: 17140,
    name: "Val-des-Monts",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.65010000",
    longitude: "-75.66604000",
  },
  {
    id: 17138,
    name: "Val-Morin",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.00008000",
    longitude: "-74.18251000",
  },
  {
    id: 17141,
    name: "Valcourt",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.50008000",
    longitude: "-72.31581000",
  },
  {
    id: 17144,
    name: "Vallée-Jonction",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.37441000",
    longitude: "-70.91881000",
  },
  {
    id: 17148,
    name: "Varennes",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.68338000",
    longitude: "-73.43246000",
  },
  {
    id: 17149,
    name: "Vaudreuil-Dorion",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.40008000",
    longitude: "-74.03251000",
  },
  {
    id: 17152,
    name: "Venise-en-Québec",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.08338000",
    longitude: "-73.13245000",
  },
  {
    id: 17153,
    name: "Verchères",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.78338000",
    longitude: "-73.34916000",
  },
  {
    id: 17157,
    name: "Victoriaville",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.05007000",
    longitude: "-71.96579000",
  },
  {
    id: 17159,
    name: "Ville-Marie",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "47.33345000",
    longitude: "-79.43297000",
  },
  {
    id: 17167,
    name: "Wakefield",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.66680000",
    longitude: "-75.83265000",
  },
  {
    id: 17171,
    name: "Warwick",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.95007000",
    longitude: "-71.98240000",
  },
  {
    id: 17173,
    name: "Waskaganish",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "51.48333000",
    longitude: "-78.75000000",
  },
  {
    id: 17174,
    name: "Waswanipi",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "49.73346000",
    longitude: "-76.16604000",
  },
  {
    id: 17177,
    name: "Waterloo",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.35008000",
    longitude: "-72.51582000",
  },
  {
    id: 17182,
    name: "Weedon Centre",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.70802000",
    longitude: "-71.45986000",
  },
  {
    id: 17196,
    name: "Westmount",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.48341000",
    longitude: "-73.59918000",
  },
  {
    id: 17199,
    name: "Weymontachie",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "47.89940000",
    longitude: "-73.77720000",
  },
  {
    id: 17210,
    name: "Windsor",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "45.56678000",
    longitude: "-71.99909000",
  },
  {
    id: 17219,
    name: "Yamachiche",
    state_id: 873,
    state_code: "QC",
    country_id: 39,
    country_code: "CA",
    latitude: "46.26676000",
    longitude: "-72.83243000",
  },
  {
    id: 16177,
    name: "Assiniboia",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "49.63336000",
    longitude: "-105.98446000",
  },
  {
    id: 16217,
    name: "Biggar",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "52.06680000",
    longitude: "-108.00135000",
  },
  {
    id: 16268,
    name: "Canora",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "51.63328000",
    longitude: "-102.43425000",
  },
  {
    id: 16282,
    name: "Carlyle",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "49.63334000",
    longitude: "-102.26765000",
  },
  {
    id: 16353,
    name: "Dalmeny",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "52.33339000",
    longitude: "-106.76792000",
  },
  {
    id: 16410,
    name: "Esterhazy",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "50.65001000",
    longitude: "-102.08426000",
  },
  {
    id: 16411,
    name: "Estevan",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "49.13337000",
    longitude: "-102.98422000",
  },
  {
    id: 16424,
    name: "Foam Lake",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "51.65001000",
    longitude: "-103.53431000",
  },
  {
    id: 16469,
    name: "Gravelbourg",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "49.88336000",
    longitude: "-106.55122000",
  },
  {
    id: 16512,
    name: "Hudson Bay",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "52.85003000",
    longitude: "-102.38425000",
  },
  {
    id: 16513,
    name: "Humboldt",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "52.20005000",
    longitude: "-105.12550000",
  },
  {
    id: 16519,
    name: "Indian Head",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "50.53336000",
    longitude: "-103.66775000",
  },
  {
    id: 16532,
    name: "Kamsack",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "51.56668000",
    longitude: "-101.90093000",
  },
  {
    id: 16540,
    name: "Kerrobert",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "51.91682000",
    longitude: "-109.13479000",
  },
  {
    id: 16547,
    name: "Kindersley",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "51.46681000",
    longitude: "-109.16818000",
  },
  {
    id: 16570,
    name: "La Ronge",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "55.10013000",
    longitude: "-105.28422000",
  },
  {
    id: 16594,
    name: "Langenburg",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "50.84999000",
    longitude: "-101.71763000",
  },
  {
    id: 16596,
    name: "Langham",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "52.36680000",
    longitude: "-106.96793000",
  },
  {
    id: 16598,
    name: "Lanigan",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "51.85006000",
    longitude: "-105.03443000",
  },
  {
    id: 16636,
    name: "Lumsden",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "50.65009000",
    longitude: "-104.86783000",
  },
  {
    id: 16642,
    name: "Macklin",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "52.33344000",
    longitude: "-109.93484000",
  },
  {
    id: 16655,
    name: "Maple Creek",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "49.91678000",
    longitude: "-109.48481000",
  },
  {
    id: 16663,
    name: "Martensville",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "52.28339000",
    longitude: "-106.66792000",
  },
  {
    id: 16673,
    name: "Meadow Lake",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "54.13348000",
    longitude: "-108.43471000",
  },
  {
    id: 16676,
    name: "Melfort",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "52.86673000",
    longitude: "-104.61768000",
  },
  {
    id: 16679,
    name: "Melville",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "50.91671000",
    longitude: "-102.80099000",
  },
  {
    id: 16713,
    name: "Moose Jaw",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "50.40005000",
    longitude: "-105.53445000",
  },
  {
    id: 16715,
    name: "Moosomin",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "50.13332000",
    longitude: "-101.66766000",
  },
  {
    id: 16747,
    name: "Nipawin",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "53.36678000",
    longitude: "-104.00092000",
  },
  {
    id: 16755,
    name: "North Battleford",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "52.77972000",
    longitude: "-108.29670000",
  },
  {
    id: 16788,
    name: "Outlook",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "51.50008000",
    longitude: "-107.05128000",
  },
  {
    id: 16790,
    name: "Oxbow",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "49.23335000",
    longitude: "-102.16760000",
  },
  {
    id: 16807,
    name: "Pelican Narrows",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "55.16685000",
    longitude: "-102.93410000",
  },
  {
    id: 16823,
    name: "Pilot Butte",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "50.46678000",
    longitude: "-104.41778000",
  },
  {
    id: 16852,
    name: "Preeceville",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "51.94998000",
    longitude: "-102.66766000",
  },
  {
    id: 16854,
    name: "Prince Albert",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "53.20008000",
    longitude: "-105.76772000",
  },
  {
    id: 16875,
    name: "Regina",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "50.45008000",
    longitude: "-104.61780000",
  },
  {
    id: 16876,
    name: "Regina Beach",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "50.78338000",
    longitude: "-105.00112000",
  },
  {
    id: 16903,
    name: "Rosetown",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "51.55010000",
    longitude: "-108.00136000",
  },
  {
    id: 16905,
    name: "Rosthern",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "52.66679000",
    longitude: "-106.33446000",
  },
  {
    id: 17021,
    name: "Saskatoon",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "52.13238000",
    longitude: "-106.66892000",
  },
  {
    id: 17031,
    name: "Shaunavon",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "49.65005000",
    longitude: "-108.41810000",
  },
  {
    id: 17038,
    name: "Shellbrook",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "53.21679000",
    longitude: "-106.40109000",
  },
  {
    id: 17094,
    name: "Swift Current",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "50.28337000",
    longitude: "-107.80135000",
  },
  {
    id: 17116,
    name: "Tisdale",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "52.85002000",
    longitude: "-104.05096000",
  },
  {
    id: 17134,
    name: "Unity",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "52.45014000",
    longitude: "-109.16816000",
  },
  {
    id: 17165,
    name: "Wadena",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "51.94999000",
    longitude: "-103.80102000",
  },
  {
    id: 17170,
    name: "Warman",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "52.31679000",
    longitude: "-106.56791000",
  },
  {
    id: 17179,
    name: "Watrous",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "51.66677000",
    longitude: "-105.46788000",
  },
  {
    id: 17198,
    name: "Weyburn",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "49.66675000",
    longitude: "-103.85109000",
  },
  {
    id: 17201,
    name: "White City",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "50.43338000",
    longitude: "-104.36778000",
  },
  {
    id: 17206,
    name: "Wilkie",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "52.41683000",
    longitude: "-108.70142000",
  },
  {
    id: 17218,
    name: "Wynyard",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "51.76674000",
    longitude: "-104.18436000",
  },
  {
    id: 17223,
    name: "Yorkton",
    state_id: 870,
    state_code: "SK",
    country_id: 39,
    country_code: "CA",
    latitude: "51.21670000",
    longitude: "-102.46766000",
  },
  {
    id: 16358,
    name: "Dawson City",
    state_id: 869,
    state_code: "YT",
    country_id: 39,
    country_code: "CA",
    latitude: "64.06013000",
    longitude: "-139.43328000",
  },
  {
    id: 16479,
    name: "Haines Junction",
    state_id: 869,
    state_code: "YT",
    country_id: 39,
    country_code: "CA",
    latitude: "60.75216000",
    longitude: "-137.51082000",
  },
  {
    id: 17180,
    name: "Watson Lake",
    state_id: 869,
    state_code: "YT",
    country_id: 39,
    country_code: "CA",
    latitude: "60.06349000",
    longitude: "-128.70893000",
  },
  {
    id: 17204,
    name: "Whitehorse",
    state_id: 869,
    state_code: "YT",
    country_id: 39,
    country_code: "CA",
    latitude: "60.71611000",
    longitude: "-135.05375000",
  },
  {
    id: 110968,
    name: "Abbeville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.57184000",
    longitude: "-85.25049000",
  },
  {
    id: 111032,
    name: "Adamsville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.60094000",
    longitude: "-86.95611000",
  },
  {
    id: 111083,
    name: "Alabaster",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.24428000",
    longitude: "-86.81638000",
  },
  {
    id: 111120,
    name: "Albertville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.26783000",
    longitude: "-86.20878000",
  },
  {
    id: 111146,
    name: "Alexander City",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.94401000",
    longitude: "-85.95385000",
  },
  {
    id: 111152,
    name: "Alexandria",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.77399000",
    longitude: "-85.88552000",
  },
  {
    id: 111172,
    name: "Aliceville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.12957000",
    longitude: "-88.15142000",
  },
  {
    id: 111309,
    name: "Andalusia",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.30808000",
    longitude: "-86.48243000",
  },
  {
    id: 111355,
    name: "Anniston",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.65983000",
    longitude: "-85.83163000",
  },
  {
    id: 111407,
    name: "Arab",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.31815000",
    longitude: "-86.49582000",
  },
  {
    id: 111448,
    name: "Argo",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.68778000",
    longitude: "-86.50051000",
  },
  {
    id: 111524,
    name: "Ashford",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.18296000",
    longitude: "-85.23632000",
  },
  {
    id: 111533,
    name: "Ashland",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.27373000",
    longitude: "-85.83607000",
  },
  {
    id: 111548,
    name: "Ashville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.83704000",
    longitude: "-86.25442000",
  },
  {
    id: 111577,
    name: "Athens",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.80243000",
    longitude: "-86.97219000",
  },
  {
    id: 111602,
    name: "Atmore",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.02379000",
    longitude: "-87.49387000",
  },
  {
    id: 111608,
    name: "Attalla",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.02176000",
    longitude: "-86.08859000",
  },
  {
    id: 111623,
    name: "Auburn",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.60986000",
    longitude: "-85.48078000",
  },
  {
    id: 111671,
    name: "Autauga County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.53492000",
    longitude: "-86.64276000",
  },
  {
    id: 111761,
    name: "Baldwin County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "30.66097000",
    longitude: "-87.74984000",
  },
  {
    id: 111774,
    name: "Ballplay",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.05871000",
    longitude: "-85.80802000",
  },
  {
    id: 111815,
    name: "Barbour County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.86960000",
    longitude: "-85.39320000",
  },
  {
    id: 111929,
    name: "Bay Minette",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "30.88296000",
    longitude: "-87.77305000",
  },
  {
    id: 111948,
    name: "Bayou La Batre",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "30.40352000",
    longitude: "-88.24852000",
  },
  {
    id: 111975,
    name: "Bear Creek",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.27482000",
    longitude: "-87.70058000",
  },
  {
    id: 112252,
    name: "Berry",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.65983000",
    longitude: "-87.60001000",
  },
  {
    id: 112267,
    name: "Bessemer",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.40178000",
    longitude: "-86.95444000",
  },
  {
    id: 112309,
    name: "Bibb County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.99864000",
    longitude: "-87.12644000",
  },
  {
    id: 112358,
    name: "Birmingham",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.52066000",
    longitude: "-86.80249000",
  },
  {
    id: 112457,
    name: "Blount County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.98087000",
    longitude: "-86.56737000",
  },
  {
    id: 112460,
    name: "Blountsville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.08149000",
    longitude: "-86.59110000",
  },
  {
    id: 112474,
    name: "Blue Ridge",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.49264000",
    longitude: "-86.19052000",
  },
  {
    id: 112490,
    name: "Boaz",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.20065000",
    longitude: "-86.16637000",
  },
  {
    id: 112725,
    name: "Brent",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.93735000",
    longitude: "-87.16472000",
  },
  {
    id: 112747,
    name: "Brewton",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.10518000",
    longitude: "-87.07219000",
  },
  {
    id: 112758,
    name: "Bridgeport",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.94758000",
    longitude: "-85.71442000",
  },
  {
    id: 112786,
    name: "Brighton",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.43428000",
    longitude: "-86.94721000",
  },
  {
    id: 112848,
    name: "Brook Highland",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.43566000",
    longitude: "-86.67388000",
  },
  {
    id: 112886,
    name: "Brookside",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.63788000",
    longitude: "-86.91666000",
  },
  {
    id: 112897,
    name: "Brookwood",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.25567000",
    longitude: "-87.32083000",
  },
  {
    id: 112946,
    name: "Brundidge",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.72016000",
    longitude: "-85.81606000",
  },
  {
    id: 113034,
    name: "Bullock County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.10055000",
    longitude: "-85.71570000",
  },
  {
    id: 113106,
    name: "Butler",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.08959000",
    longitude: "-88.22197000",
  },
  {
    id: 113114,
    name: "Butler County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.75243000",
    longitude: "-86.68029000",
  },
  {
    id: 113138,
    name: "Bynum",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.61316000",
    longitude: "-85.96108000",
  },
  {
    id: 113167,
    name: "Cahaba Heights",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.46400000",
    longitude: "-86.73193000",
  },
  {
    id: 113194,
    name: "Calera",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.10290000",
    longitude: "-86.75360000",
  },
  {
    id: 113200,
    name: "Calhoun County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.77143000",
    longitude: "-85.82603000",
  },
  {
    id: 113259,
    name: "Camden",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.99098000",
    longitude: "-87.29055000",
  },
  {
    id: 113386,
    name: "Carbon Hill",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.89177000",
    longitude: "-87.52612000",
  },
  {
    id: 113407,
    name: "Carlisle-Rockledge",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.11445000",
    longitude: "-86.12407000",
  },
  {
    id: 113463,
    name: "Carrollton",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.26169000",
    longitude: "-88.09503000",
  },
  {
    id: 113601,
    name: "Cedar Bluff",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.22009000",
    longitude: "-85.60774000",
  },
  {
    id: 113646,
    name: "Center Point",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.64566000",
    longitude: "-86.68360000",
  },
  {
    id: 113688,
    name: "Centre",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.15204000",
    longitude: "-85.67885000",
  },
  {
    id: 113691,
    name: "Centreville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.94620000",
    longitude: "-87.11669000",
  },
  {
    id: 113713,
    name: "Chalkville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.65316000",
    longitude: "-86.64777000",
  },
  {
    id: 113720,
    name: "Chambers County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.91437000",
    longitude: "-85.39204000",
  },
  {
    id: 113804,
    name: "Chatom",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.46517000",
    longitude: "-88.25446000",
  },
  {
    id: 113829,
    name: "Chelsea",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.34011000",
    longitude: "-86.63026000",
  },
  {
    id: 113845,
    name: "Cherokee",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.75703000",
    longitude: "-87.97281000",
  },
  {
    id: 113849,
    name: "Cherokee County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.17596000",
    longitude: "-85.60379000",
  },
  {
    id: 113938,
    name: "Chickasaw",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "30.76380000",
    longitude: "-88.07472000",
  },
  {
    id: 113949,
    name: "Childersburg",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.27817000",
    longitude: "-86.35498000",
  },
  {
    id: 113957,
    name: "Chilton County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.84785000",
    longitude: "-86.71881000",
  },
  {
    id: 113984,
    name: "Choccolocco",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.65927000",
    longitude: "-85.70357000",
  },
  {
    id: 113986,
    name: "Choctaw County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.01961000",
    longitude: "-88.26320000",
  },
  {
    id: 114033,
    name: "Citronelle",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.09073000",
    longitude: "-88.22806000",
  },
  {
    id: 114099,
    name: "Clanton",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.83874000",
    longitude: "-86.62943000",
  },
  {
    id: 114134,
    name: "Clarke County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.67666000",
    longitude: "-87.83081000",
  },
  {
    id: 114165,
    name: "Clay",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.70260000",
    longitude: "-86.59971000",
  },
  {
    id: 114173,
    name: "Clay County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.26909000",
    longitude: "-85.86055000",
  },
  {
    id: 114196,
    name: "Clayton",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.87822000",
    longitude: "-85.44966000",
  },
  {
    id: 114230,
    name: "Cleburne County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.67456000",
    longitude: "-85.51877000",
  },
  {
    id: 114240,
    name: "Cleveland",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.99093000",
    longitude: "-86.57749000",
  },
  {
    id: 114301,
    name: "Clio",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.70878000",
    longitude: "-85.61050000",
  },
  {
    id: 114337,
    name: "Coaling",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.15901000",
    longitude: "-87.34083000",
  },
  {
    id: 114367,
    name: "Coffee County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.40263000",
    longitude: "-85.98821000",
  },
  {
    id: 114379,
    name: "Colbert County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.70043000",
    longitude: "-87.80498000",
  },
  {
    id: 114433,
    name: "Collinsville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.26398000",
    longitude: "-85.86053000",
  },
  {
    id: 114487,
    name: "Columbiana",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.17817000",
    longitude: "-86.60721000",
  },
  {
    id: 114534,
    name: "Concord",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.46761000",
    longitude: "-87.03111000",
  },
  {
    id: 114550,
    name: "Conecuh County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.42927000",
    longitude: "-86.99368000",
  },
  {
    id: 114613,
    name: "Coosa County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.93623000",
    longitude: "-86.24766000",
  },
  {
    id: 114614,
    name: "Coosada",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.49791000",
    longitude: "-86.33136000",
  },
  {
    id: 114638,
    name: "Cordova",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.75983000",
    longitude: "-87.18333000",
  },
  {
    id: 114699,
    name: "Cottonwood",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.04879000",
    longitude: "-85.30493000",
  },
  {
    id: 114742,
    name: "Covington County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.24849000",
    longitude: "-86.45125000",
  },
  {
    id: 114745,
    name: "Cowarts",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.20018000",
    longitude: "-85.30465000",
  },
  {
    id: 114791,
    name: "Crenshaw County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.73149000",
    longitude: "-86.31355000",
  },
  {
    id: 114792,
    name: "Creola",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "30.89185000",
    longitude: "-88.03972000",
  },
  {
    id: 114855,
    name: "Crossville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.28759000",
    longitude: "-85.99414000",
  },
  {
    id: 114895,
    name: "Cullman",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.17482000",
    longitude: "-86.84361000",
  },
  {
    id: 114896,
    name: "Cullman County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.13194000",
    longitude: "-86.86762000",
  },
  {
    id: 114968,
    name: "Dadeville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.83124000",
    longitude: "-85.76357000",
  },
  {
    id: 114982,
    name: "Dale County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.43182000",
    longitude: "-85.61103000",
  },
  {
    id: 114983,
    name: "Daleville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.31017000",
    longitude: "-85.71299000",
  },
  {
    id: 114994,
    name: "Dallas County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.32597000",
    longitude: "-87.10648000",
  },
  {
    id: 115032,
    name: "Danville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.41454000",
    longitude: "-87.08751000",
  },
  {
    id: 115040,
    name: "Daphne",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "30.60353000",
    longitude: "-87.90360000",
  },
  {
    id: 115057,
    name: "Dauphin Island",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "30.25548000",
    longitude: "-88.10972000",
  },
  {
    id: 115148,
    name: "Deatsville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.60819000",
    longitude: "-86.39581000",
  },
  {
    id: 115149,
    name: "Decatur",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.60593000",
    longitude: "-86.98334000",
  },
  {
    id: 115128,
    name: "DeKalb County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.45977000",
    longitude: "-85.80414000",
  },
  {
    id: 115232,
    name: "Demopolis",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.51764000",
    longitude: "-87.83640000",
  },
  {
    id: 115360,
    name: "Dixiana",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.74021000",
    longitude: "-86.64938000",
  },
  {
    id: 115407,
    name: "Dora",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.72872000",
    longitude: "-87.09028000",
  },
  {
    id: 115415,
    name: "Dothan",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.22323000",
    longitude: "-85.39049000",
  },
  {
    id: 115417,
    name: "Double Springs",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.14637000",
    longitude: "-87.40247000",
  },
  {
    id: 115636,
    name: "East Brewton",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.09323000",
    longitude: "-87.06275000",
  },
  {
    id: 115662,
    name: "East Florence",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.80953000",
    longitude: "-87.64947000",
  },
  {
    id: 115816,
    name: "Eclectic",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.63541000",
    longitude: "-86.03441000",
  },
  {
    id: 115941,
    name: "Elba",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.41461000",
    longitude: "-86.06772000",
  },
  {
    id: 115944,
    name: "Elberta",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "30.41436000",
    longitude: "-87.59776000",
  },
  {
    id: 116056,
    name: "Elmore",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.53874000",
    longitude: "-86.31497000",
  },
  {
    id: 116057,
    name: "Elmore County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.59665000",
    longitude: "-86.14915000",
  },
  {
    id: 116092,
    name: "Emerald Mountain",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.44793000",
    longitude: "-86.09429000",
  },
  {
    id: 116148,
    name: "Enterprise",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.31517000",
    longitude: "-85.85522000",
  },
  {
    id: 116179,
    name: "Escambia County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.12612000",
    longitude: "-87.16162000",
  },
  {
    id: 116219,
    name: "Etowah County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.04525000",
    longitude: "-86.03476000",
  },
  {
    id: 116224,
    name: "Eufaula",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.89127000",
    longitude: "-85.14549000",
  },
  {
    id: 116242,
    name: "Eutaw",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.84059000",
    longitude: "-87.88762000",
  },
  {
    id: 116261,
    name: "Evergreen",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.43350000",
    longitude: "-86.95692000",
  },
  {
    id: 116309,
    name: "Fairfield",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.48594000",
    longitude: "-86.91194000",
  },
  {
    id: 116326,
    name: "Fairhope",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "30.52297000",
    longitude: "-87.90333000",
  },
  {
    id: 116369,
    name: "Falkville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.36843000",
    longitude: "-86.90862000",
  },
  {
    id: 116438,
    name: "Fayette",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.68455000",
    longitude: "-87.83085000",
  },
  {
    id: 116443,
    name: "Fayette County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.72121000",
    longitude: "-87.73886000",
  },
  {
    id: 116453,
    name: "Fayetteville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.14567000",
    longitude: "-86.40581000",
  },
  {
    id: 116567,
    name: "Flint City",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.52315000",
    longitude: "-86.97029000",
  },
  {
    id: 116569,
    name: "Flomaton",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.00018000",
    longitude: "-87.26081000",
  },
  {
    id: 116576,
    name: "Florala",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.00518000",
    longitude: "-86.32800000",
  },
  {
    id: 116577,
    name: "Florence",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.79981000",
    longitude: "-87.67725000",
  },
  {
    id: 116619,
    name: "Foley",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "30.40659000",
    longitude: "-87.68360000",
  },
  {
    id: 116669,
    name: "Forestdale",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.57011000",
    longitude: "-86.89638000",
  },
  {
    id: 116705,
    name: "Fort Deposit",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.98459000",
    longitude: "-86.57859000",
  },
  {
    id: 116738,
    name: "Fort Payne",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.44425000",
    longitude: "-85.71969000",
  },
  {
    id: 116747,
    name: "Fort Rucker",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.34282000",
    longitude: "-85.71538000",
  },
  {
    id: 116843,
    name: "Franklin County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.44167000",
    longitude: "-87.84381000",
  },
  {
    id: 116957,
    name: "Frisco City",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.43350000",
    longitude: "-87.40138000",
  },
  {
    id: 116999,
    name: "Fultondale",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.60483000",
    longitude: "-86.79388000",
  },
  {
    id: 117003,
    name: "Fyffe",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.44676000",
    longitude: "-85.90414000",
  },
  {
    id: 117004,
    name: "Gadsden",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.01434000",
    longitude: "-86.00639000",
  },
  {
    id: 117070,
    name: "Gardendale",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.66010000",
    longitude: "-86.81277000",
  },
  {
    id: 117139,
    name: "Geneva",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.03296000",
    longitude: "-85.86382000",
  },
  {
    id: 117146,
    name: "Geneva County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.09502000",
    longitude: "-85.83898000",
  },
  {
    id: 117167,
    name: "Georgiana",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.63710000",
    longitude: "-86.74192000",
  },
  {
    id: 117276,
    name: "Glencoe",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.95704000",
    longitude: "-85.93247000",
  },
  {
    id: 117377,
    name: "Good Hope",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.11593000",
    longitude: "-86.86361000",
  },
  {
    id: 117391,
    name: "Goodwater",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.06567000",
    longitude: "-86.05330000",
  },
  {
    id: 117395,
    name: "Gordo",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.32012000",
    longitude: "-87.90280000",
  },
  {
    id: 117449,
    name: "Grand Bay",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "30.47631000",
    longitude: "-88.34223000",
  },
  {
    id: 117565,
    name: "Grayson Valley",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.64816000",
    longitude: "-86.63943000",
  },
  {
    id: 117566,
    name: "Graysville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.62066000",
    longitude: "-86.97138000",
  },
  {
    id: 117631,
    name: "Greene County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.85314000",
    longitude: "-87.95223000",
  },
  {
    id: 117664,
    name: "Greensboro",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.70415000",
    longitude: "-87.59550000",
  },
  {
    id: 117680,
    name: "Greenville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.82960000",
    longitude: "-86.61775000",
  },
  {
    id: 117757,
    name: "Grove Hill",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.70877000",
    longitude: "-87.77722000",
  },
  {
    id: 117789,
    name: "Guin",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.96566000",
    longitude: "-87.91475000",
  },
  {
    id: 117795,
    name: "Gulf Shores",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "30.24604000",
    longitude: "-87.70082000",
  },
  {
    id: 117806,
    name: "Guntersville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.35823000",
    longitude: "-86.29446000",
  },
  {
    id: 117830,
    name: "Hackleburg",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.27732000",
    longitude: "-87.82864000",
  },
  {
    id: 117848,
    name: "Hale County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.76266000",
    longitude: "-87.62912000",
  },
  {
    id: 117853,
    name: "Haleyville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.22649000",
    longitude: "-87.62141000",
  },
  {
    id: 117887,
    name: "Hamilton",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.14232000",
    longitude: "-87.98864000",
  },
  {
    id: 117946,
    name: "Hanceville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.06065000",
    longitude: "-86.76750000",
  },
  {
    id: 118036,
    name: "Harpersville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.34400000",
    longitude: "-86.43804000",
  },
  {
    id: 118084,
    name: "Hartford",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.10240000",
    longitude: "-85.69688000",
  },
  {
    id: 118102,
    name: "Hartselle",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.44343000",
    longitude: "-86.93528000",
  },
  {
    id: 118110,
    name: "Harvest",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.85564000",
    longitude: "-86.75083000",
  },
  {
    id: 118177,
    name: "Hayden",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.89260000",
    longitude: "-86.75777000",
  },
  {
    id: 118189,
    name: "Hayneville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.18403000",
    longitude: "-86.58025000",
  },
  {
    id: 118204,
    name: "Hazel Green",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.93231000",
    longitude: "-86.57194000",
  },
  {
    id: 118216,
    name: "Headland",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.35128000",
    longitude: "-85.34216000",
  },
  {
    id: 118243,
    name: "Heflin",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.64899000",
    longitude: "-85.58746000",
  },
  {
    id: 118246,
    name: "Helena",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.29622000",
    longitude: "-86.84360000",
  },
  {
    id: 118272,
    name: "Henagar",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.63508000",
    longitude: "-85.76719000",
  },
  {
    id: 118297,
    name: "Henry County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.51469000",
    longitude: "-85.24141000",
  },
  {
    id: 118408,
    name: "Highland Lakes",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.39838000",
    longitude: "-86.65130000",
  },
  {
    id: 118516,
    name: "Hokes Bluff",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.99815000",
    longitude: "-85.86636000",
  },
  {
    id: 118574,
    name: "Holt",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.23401000",
    longitude: "-87.48445000",
  },
  {
    id: 118581,
    name: "Holtville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.63624000",
    longitude: "-86.32664000",
  },
  {
    id: 118607,
    name: "Homewood",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.47177000",
    longitude: "-86.80082000",
  },
  {
    id: 118638,
    name: "Hoover",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.40539000",
    longitude: "-86.81138000",
  },
  {
    id: 118702,
    name: "Houston County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.15318000",
    longitude: "-85.30252000",
  },
  {
    id: 118756,
    name: "Hueytown",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.45122000",
    longitude: "-86.99666000",
  },
  {
    id: 118770,
    name: "Huguley",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.83457000",
    longitude: "-85.22966000",
  },
  {
    id: 118814,
    name: "Huntsville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.73040000",
    longitude: "-86.58594000",
  },
  {
    id: 118919,
    name: "Indian Springs Village",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.35539000",
    longitude: "-86.75443000",
  },
  {
    id: 118954,
    name: "Inverness",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.01488000",
    longitude: "-85.74606000",
  },
  {
    id: 118994,
    name: "Irondale",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.53816000",
    longitude: "-86.70721000",
  },
  {
    id: 119060,
    name: "Jackson",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.50905000",
    longitude: "-87.89444000",
  },
  {
    id: 119075,
    name: "Jackson County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.77941000",
    longitude: "-85.99930000",
  },
  {
    id: 119099,
    name: "Jacksonville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.81382000",
    longitude: "-85.76130000",
  },
  {
    id: 119138,
    name: "Jasper",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.83122000",
    longitude: "-87.27751000",
  },
  {
    id: 119175,
    name: "Jefferson County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.55431000",
    longitude: "-86.89649000",
  },
  {
    id: 119211,
    name: "Jemison",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.95985000",
    longitude: "-86.74665000",
  },
  {
    id: 119569,
    name: "Kimberly",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.77344000",
    longitude: "-86.81388000",
  },
  {
    id: 119641,
    name: "Kinsey",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.29906000",
    longitude: "-85.34438000",
  },
  {
    id: 119826,
    name: "Ladonia",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.46820000",
    longitude: "-85.07910000",
  },
  {
    id: 119831,
    name: "Lafayette",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.89985000",
    longitude: "-85.40106000",
  },
  {
    id: 119967,
    name: "Lake Purdy",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.43011000",
    longitude: "-86.68054000",
  },
  {
    id: 119986,
    name: "Lake View",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.28067000",
    longitude: "-87.13750000",
  },
  {
    id: 120061,
    name: "Lamar County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.77921000",
    longitude: "-88.09690000",
  },
  {
    id: 120108,
    name: "Lanett",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.86874000",
    longitude: "-85.19050000",
  },
  {
    id: 120179,
    name: "Lauderdale County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.90137000",
    longitude: "-87.65400000",
  },
  {
    id: 120229,
    name: "Lawrence County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.52165000",
    longitude: "-87.31104000",
  },
  {
    id: 120306,
    name: "Lee County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.60114000",
    longitude: "-85.35556000",
  },
  {
    id: 120320,
    name: "Leeds",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.54816000",
    longitude: "-86.54443000",
  },
  {
    id: 120323,
    name: "Leesburg",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.17982000",
    longitude: "-85.76136000",
  },
  {
    id: 120404,
    name: "Level Plains",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.29962000",
    longitude: "-85.77799000",
  },
  {
    id: 120494,
    name: "Limestone County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.81008000",
    longitude: "-86.98137000",
  },
  {
    id: 120499,
    name: "Lincoln",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.61316000",
    longitude: "-86.11831000",
  },
  {
    id: 120560,
    name: "Linden",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.30625000",
    longitude: "-87.79807000",
  },
  {
    id: 120577,
    name: "Lineville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.31067000",
    longitude: "-85.75441000",
  },
  {
    id: 120595,
    name: "Lipscomb",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.42566000",
    longitude: "-86.92666000",
  },
  {
    id: 120647,
    name: "Livingston",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.58430000",
    longitude: "-88.18725000",
  },
  {
    id: 120685,
    name: "Locust Fork",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.90760000",
    longitude: "-86.61527000",
  },
  {
    id: 120846,
    name: "Lowndes County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.15475000",
    longitude: "-86.65011000",
  },
  {
    id: 120852,
    name: "Loxley",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "30.61825000",
    longitude: "-87.75305000",
  },
  {
    id: 120899,
    name: "Luverne",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.71655000",
    longitude: "-86.26385000",
  },
  {
    id: 120967,
    name: "Macon County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.38597000",
    longitude: "-85.69267000",
  },
  {
    id: 120984,
    name: "Madison",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.69926000",
    longitude: "-86.74833000",
  },
  {
    id: 121002,
    name: "Madison County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.76309000",
    longitude: "-86.55021000",
  },
  {
    id: 121070,
    name: "Malvern",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.13934000",
    longitude: "-85.51910000",
  },
  {
    id: 121192,
    name: "Marbury",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.70124000",
    longitude: "-86.47109000",
  },
  {
    id: 121203,
    name: "Marengo County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.24761000",
    longitude: "-87.78952000",
  },
  {
    id: 121205,
    name: "Margaret",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.68621000",
    longitude: "-86.47498000",
  },
  {
    id: 121231,
    name: "Marion",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.63235000",
    longitude: "-87.31917000",
  },
  {
    id: 121247,
    name: "Marion County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.13655000",
    longitude: "-87.88714000",
  },
  {
    id: 121310,
    name: "Marshall County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.36696000",
    longitude: "-86.30664000",
  },
  {
    id: 121565,
    name: "Meadowbrook",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.40205000",
    longitude: "-86.69665000",
  },
  {
    id: 121698,
    name: "Meridianville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.85148000",
    longitude: "-86.57222000",
  },
  {
    id: 121796,
    name: "Midfield",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.46150000",
    longitude: "-86.90888000",
  },
  {
    id: 121803,
    name: "Midland City",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.31906000",
    longitude: "-85.49382000",
  },
  {
    id: 121829,
    name: "Mignon",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.18345000",
    longitude: "-86.26109000",
  },
  {
    id: 121872,
    name: "Millbrook",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.47986000",
    longitude: "-86.36192000",
  },
  {
    id: 121963,
    name: "Minor",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.53733000",
    longitude: "-86.94055000",
  },
  {
    id: 122010,
    name: "Mobile",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "30.69436000",
    longitude: "-88.04305000",
  },
  {
    id: 122011,
    name: "Mobile County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "30.68515000",
    longitude: "-88.19753000",
  },
  {
    id: 122067,
    name: "Monroe County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.57084000",
    longitude: "-87.36543000",
  },
  {
    id: 122084,
    name: "Monroeville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.52794000",
    longitude: "-87.32471000",
  },
  {
    id: 122125,
    name: "Montevallo",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.10067000",
    longitude: "-86.86416000",
  },
  {
    id: 122130,
    name: "Montgomery",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.36681000",
    longitude: "-86.29997000",
  },
  {
    id: 122139,
    name: "Montgomery County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.22026000",
    longitude: "-86.20761000",
  },
  {
    id: 122195,
    name: "Moody",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.59094000",
    longitude: "-86.49082000",
  },
  {
    id: 122207,
    name: "Moores Mill",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.84398000",
    longitude: "-86.51832000",
  },
  {
    id: 122236,
    name: "Morgan County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.45347000",
    longitude: "-86.85293000",
  },
  {
    id: 122265,
    name: "Morris",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.74816000",
    longitude: "-86.80860000",
  },
  {
    id: 122314,
    name: "Moulton",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.48121000",
    longitude: "-87.29335000",
  },
  {
    id: 122328,
    name: "Moundville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.99762000",
    longitude: "-87.63001000",
  },
  {
    id: 122367,
    name: "Mount Olive",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.67094000",
    longitude: "-86.85610000",
  },
  {
    id: 122393,
    name: "Mount Vernon",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.08518000",
    longitude: "-88.01333000",
  },
  {
    id: 122410,
    name: "Mountain Brook",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.50094000",
    longitude: "-86.75221000",
  },
  {
    id: 122470,
    name: "Munford",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.52983000",
    longitude: "-85.95080000",
  },
  {
    id: 122503,
    name: "Muscle Shoals",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.74481000",
    longitude: "-87.66753000",
  },
  {
    id: 122676,
    name: "New Brockton",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.38572000",
    longitude: "-85.92939000",
  },
  {
    id: 122723,
    name: "New Hope",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.53712000",
    longitude: "-86.39426000",
  },
  {
    id: 122749,
    name: "New Market",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.91003000",
    longitude: "-86.42779000",
  },
  {
    id: 122857,
    name: "Newton",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.33517000",
    longitude: "-85.60521000",
  },
  {
    id: 122990,
    name: "North Bibb",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.20401000",
    longitude: "-87.15305000",
  },
  {
    id: 123160,
    name: "Northport",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.22901000",
    longitude: "-87.57723000",
  },
  {
    id: 123357,
    name: "Odenville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.67732000",
    longitude: "-86.39665000",
  },
  {
    id: 123381,
    name: "Ohatchee",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.78343000",
    longitude: "-86.00247000",
  },
  {
    id: 123478,
    name: "Oneonta",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.94815000",
    longitude: "-86.47276000",
  },
  {
    id: 123496,
    name: "Opelika",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.64541000",
    longitude: "-85.37828000",
  },
  {
    id: 123498,
    name: "Opp",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.28267000",
    longitude: "-86.25551000",
  },
  {
    id: 123511,
    name: "Orange Beach",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "30.29437000",
    longitude: "-87.57359000",
  },
  {
    id: 123663,
    name: "Owens Cross Roads",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.58815000",
    longitude: "-86.45888000",
  },
  {
    id: 123675,
    name: "Oxford",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.61427000",
    longitude: "-85.83496000",
  },
  {
    id: 123696,
    name: "Ozark",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.45906000",
    longitude: "-85.64049000",
  },
  {
    id: 123988,
    name: "Pelham",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.28567000",
    longitude: "-86.80999000",
  },
  {
    id: 123997,
    name: "Pell City",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.58621000",
    longitude: "-86.28609000",
  },
  {
    id: 124074,
    name: "Perry County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.63847000",
    longitude: "-87.29440000",
  },
  {
    id: 124123,
    name: "Phenix City",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.47098000",
    longitude: "-85.00077000",
  },
  {
    id: 124124,
    name: "Phil Campbell",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.35093000",
    longitude: "-87.70642000",
  },
  {
    id: 124156,
    name: "Pickens County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.28088000",
    longitude: "-88.08869000",
  },
  {
    id: 124164,
    name: "Piedmont",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.92455000",
    longitude: "-85.61135000",
  },
  {
    id: 124185,
    name: "Pike County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.80272000",
    longitude: "-85.94092000",
  },
  {
    id: 124196,
    name: "Pike Road",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.28431000",
    longitude: "-86.10302000",
  },
  {
    id: 124235,
    name: "Pine Level",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.58374000",
    longitude: "-86.46553000",
  },
  {
    id: 124272,
    name: "Pinson",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.68899000",
    longitude: "-86.68332000",
  },
  {
    id: 124366,
    name: "Pleasant Grove",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.49094000",
    longitude: "-86.97027000",
  },
  {
    id: 124427,
    name: "Point Clear",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "30.47408000",
    longitude: "-87.91916000",
  },
  {
    id: 124638,
    name: "Prattville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.46402000",
    longitude: "-86.45970000",
  },
  {
    id: 124666,
    name: "Priceville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.52509000",
    longitude: "-86.89473000",
  },
  {
    id: 124667,
    name: "Prichard",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "30.73880000",
    longitude: "-88.07889000",
  },
  {
    id: 124821,
    name: "Ragland",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.74454000",
    longitude: "-86.15581000",
  },
  {
    id: 124824,
    name: "Rainbow City",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.95482000",
    longitude: "-86.04192000",
  },
  {
    id: 124829,
    name: "Rainsville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.49425000",
    longitude: "-85.84775000",
  },
  {
    id: 124872,
    name: "Randolph County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.29379000",
    longitude: "-85.45907000",
  },
  {
    id: 124939,
    name: "Red Bay",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.43982000",
    longitude: "-88.14087000",
  },
  {
    id: 124981,
    name: "Redstone Arsenal",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.68387000",
    longitude: "-86.64764000",
  },
  {
    id: 124999,
    name: "Reform",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.37845000",
    longitude: "-88.01530000",
  },
  {
    id: 125003,
    name: "Rehobeth",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.12296000",
    longitude: "-85.45271000",
  },
  {
    id: 125201,
    name: "Riverside",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.60621000",
    longitude: "-86.20442000",
  },
  {
    id: 125226,
    name: "Roanoke",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.15123000",
    longitude: "-85.37217000",
  },
  {
    id: 125244,
    name: "Robertsdale",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "30.55380000",
    longitude: "-87.71193000",
  },
  {
    id: 125275,
    name: "Rock Creek",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.47705000",
    longitude: "-87.08027000",
  },
  {
    id: 125299,
    name: "Rockford",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.88957000",
    longitude: "-86.21969000",
  },
  {
    id: 125354,
    name: "Rogersville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.82578000",
    longitude: "-87.29676000",
  },
  {
    id: 125536,
    name: "Russell County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.28838000",
    longitude: "-85.18496000",
  },
  {
    id: 125542,
    name: "Russellville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.50787000",
    longitude: "-87.72864000",
  },
  {
    id: 125621,
    name: "Saint Clair County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.71570000",
    longitude: "-86.31469000",
  },
  {
    id: 125721,
    name: "Saks",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.69871000",
    longitude: "-85.83969000",
  },
  {
    id: 125781,
    name: "Samson",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.11295000",
    longitude: "-86.04605000",
  },
  {
    id: 125934,
    name: "Saraland",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "30.82074000",
    longitude: "-88.07056000",
  },
  {
    id: 125949,
    name: "Sardis City",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.17426000",
    longitude: "-86.12275000",
  },
  {
    id: 125957,
    name: "Satsuma",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "30.85324000",
    longitude: "-88.05611000",
  },
  {
    id: 126061,
    name: "Scottsboro",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.67231000",
    longitude: "-86.03415000",
  },
  {
    id: 126138,
    name: "Selma",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.40736000",
    longitude: "-87.02110000",
  },
  {
    id: 126143,
    name: "Selmont-West Selmont",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.37843000",
    longitude: "-87.00740000",
  },
  {
    id: 126151,
    name: "Semmes",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "30.77824000",
    longitude: "-88.25917000",
  },
  {
    id: 126259,
    name: "Sheffield",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.76509000",
    longitude: "-87.69864000",
  },
  {
    id: 126267,
    name: "Shelby",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.11040000",
    longitude: "-86.58415000",
  },
  {
    id: 126272,
    name: "Shelby County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.26428000",
    longitude: "-86.66065000",
  },
  {
    id: 126363,
    name: "Shoal Creek",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.43076000",
    longitude: "-86.61092000",
  },
  {
    id: 126506,
    name: "Slocomb",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.10823000",
    longitude: "-85.59438000",
  },
  {
    id: 126523,
    name: "Smiths Station",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.54014000",
    longitude: "-85.09855000",
  },
  {
    id: 126532,
    name: "Smoke Rise",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.89177000",
    longitude: "-86.82027000",
  },
  {
    id: 126786,
    name: "Southside",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.92454000",
    longitude: "-86.02247000",
  },
  {
    id: 126799,
    name: "Spanish Fort",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "30.67491000",
    longitude: "-87.91527000",
  },
  {
    id: 126914,
    name: "Springville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.77505000",
    longitude: "-86.47191000",
  },
  {
    id: 127007,
    name: "Steele",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.93982000",
    longitude: "-86.20164000",
  },
  {
    id: 127044,
    name: "Stevenson",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.86869000",
    longitude: "-85.83942000",
  },
  {
    id: 127054,
    name: "Stewartville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.07929000",
    longitude: "-86.24442000",
  },
  {
    id: 127180,
    name: "Sulligent",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.90177000",
    longitude: "-88.13448000",
  },
  {
    id: 127200,
    name: "Sumiton",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.75566000",
    longitude: "-87.05000000",
  },
  {
    id: 127201,
    name: "Summerdale",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "30.48770000",
    longitude: "-87.69971000",
  },
  {
    id: 127228,
    name: "Sumter County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.59101000",
    longitude: "-88.19879000",
  },
  {
    id: 127354,
    name: "Sylacauga",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.17317000",
    longitude: "-86.25164000",
  },
  {
    id: 127358,
    name: "Sylvan Springs",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.51566000",
    longitude: "-87.01499000",
  },
  {
    id: 127359,
    name: "Sylvania",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.56231000",
    longitude: "-85.81247000",
  },
  {
    id: 127389,
    name: "Talladega",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.43594000",
    longitude: "-86.10580000",
  },
  {
    id: 127390,
    name: "Talladega County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.38006000",
    longitude: "-86.16591000",
  },
  {
    id: 127394,
    name: "Tallapoosa County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.86240000",
    longitude: "-85.79750000",
  },
  {
    id: 127395,
    name: "Tallassee",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.53597000",
    longitude: "-85.89329000",
  },
  {
    id: 127434,
    name: "Tarrant",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.58344000",
    longitude: "-86.77277000",
  },
  {
    id: 127445,
    name: "Taylor",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.16490000",
    longitude: "-85.46827000",
  },
  {
    id: 127568,
    name: "Theodore",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "30.54769000",
    longitude: "-88.17528000",
  },
  {
    id: 127587,
    name: "Thomasville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.91349000",
    longitude: "-87.73584000",
  },
  {
    id: 127608,
    name: "Thorsby",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.91568000",
    longitude: "-86.71582000",
  },
  {
    id: 127651,
    name: "Tillmans Corner",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "30.59019000",
    longitude: "-88.17084000",
  },
  {
    id: 127757,
    name: "Town Creek",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.68120000",
    longitude: "-87.40613000",
  },
  {
    id: 127825,
    name: "Trinity",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.60676000",
    longitude: "-87.08835000",
  },
  {
    id: 127841,
    name: "Troy",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.80877000",
    longitude: "-85.96995000",
  },
  {
    id: 127860,
    name: "Trussville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.61983000",
    longitude: "-86.60888000",
  },
  {
    id: 127909,
    name: "Tuscaloosa",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.20984000",
    longitude: "-87.56917000",
  },
  {
    id: 127910,
    name: "Tuscaloosa County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.28955000",
    longitude: "-87.52503000",
  },
  {
    id: 127914,
    name: "Tuscumbia",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.73120000",
    longitude: "-87.70253000",
  },
  {
    id: 127916,
    name: "Tuskegee",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.42415000",
    longitude: "-85.69096000",
  },
  {
    id: 127968,
    name: "Underwood-Petersville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.87695000",
    longitude: "-87.69717000",
  },
  {
    id: 128011,
    name: "Union Springs",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.14432000",
    longitude: "-85.71495000",
  },
  {
    id: 128015,
    name: "Uniontown",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.44958000",
    longitude: "-87.51417000",
  },
  {
    id: 128097,
    name: "Valley",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.81874000",
    longitude: "-85.17939000",
  },
  {
    id: 128110,
    name: "Valley Grande",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.50902000",
    longitude: "-86.98749000",
  },
  {
    id: 128140,
    name: "Vance",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.17428000",
    longitude: "-87.23361000",
  },
  {
    id: 128153,
    name: "Vandiver",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.47066000",
    longitude: "-86.51332000",
  },
  {
    id: 128188,
    name: "Vernon",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.75705000",
    longitude: "-88.10892000",
  },
  {
    id: 128212,
    name: "Vestavia Hills",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.44872000",
    longitude: "-86.78777000",
  },
  {
    id: 128264,
    name: "Vincent",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.38455000",
    longitude: "-86.41192000",
  },
  {
    id: 128386,
    name: "Walker County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.80333000",
    longitude: "-87.29736000",
  },
  {
    id: 128513,
    name: "Warrior",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.81427000",
    longitude: "-86.80944000",
  },
  {
    id: 128552,
    name: "Washington County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.40760000",
    longitude: "-88.20788000",
  },
  {
    id: 128721,
    name: "Weaver",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.75205000",
    longitude: "-85.81135000",
  },
  {
    id: 128724,
    name: "Webb",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.26045000",
    longitude: "-85.27327000",
  },
  {
    id: 128750,
    name: "Wedowee",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.30900000",
    longitude: "-85.48467000",
  },
  {
    id: 128827,
    name: "West Blocton",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.11817000",
    longitude: "-87.12500000",
  },
  {
    id: 128864,
    name: "West End-Cobb Town",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.65250000",
    longitude: "-85.87420000",
  },
  {
    id: 129067,
    name: "Westover",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.34955000",
    longitude: "-86.53582000",
  },
  {
    id: 129092,
    name: "Wetumpka",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.54374000",
    longitude: "-86.21191000",
  },
  {
    id: 129180,
    name: "Whitesboro",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.16343000",
    longitude: "-86.06942000",
  },
  {
    id: 129228,
    name: "Wilcox County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "31.98924000",
    longitude: "-87.30820000",
  },
  {
    id: 129340,
    name: "Wilsonville",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.23428000",
    longitude: "-86.48359000",
  },
  {
    id: 129392,
    name: "Winfield",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.92899000",
    longitude: "-87.81725000",
  },
  {
    id: 129434,
    name: "Winston County",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "34.14923000",
    longitude: "-87.37368000",
  },
  {
    id: 129560,
    name: "Woodstock",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "33.20678000",
    longitude: "-87.15000000",
  },
  {
    id: 129690,
    name: "York",
    state_id: 1456,
    state_code: "AL",
    country_id: 233,
    country_code: "US",
    latitude: "32.48625000",
    longitude: "-88.29642000",
  },
  {
    id: 111081,
    name: "Akutan",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "54.13350000",
    longitude: "-165.77686000",
  },
  {
    id: 111143,
    name: "Aleutians East Borough",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "54.85000000",
    longitude: "-163.41667000",
  },
  {
    id: 111144,
    name: "Aleutians West Census Area",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "52.16298000",
    longitude: "-174.28505000",
  },
  {
    id: 111303,
    name: "Anchor Point",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "59.77667000",
    longitude: "-151.83139000",
  },
  {
    id: 111305,
    name: "Anchorage",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "61.21806000",
    longitude: "-149.90028000",
  },
  {
    id: 111306,
    name: "Anchorage Municipality",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "61.16667000",
    longitude: "-149.25056000",
  },
  {
    id: 111724,
    name: "Badger",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "64.80000000",
    longitude: "-147.53333000",
  },
  {
    id: 111852,
    name: "Barrow",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "71.29058000",
    longitude: "-156.78872000",
  },
  {
    id: 111976,
    name: "Bear Creek",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "60.16417000",
    longitude: "-149.39500000",
  },
  {
    id: 112281,
    name: "Bethel",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "60.79222000",
    longitude: "-161.75583000",
  },
  {
    id: 112284,
    name: "Bethel Census Area",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "60.75056000",
    longitude: "-160.50056000",
  },
  {
    id: 112325,
    name: "Big Lake",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "61.52139000",
    longitude: "-149.95444000",
  },
  {
    id: 112813,
    name: "Bristol Bay Borough",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "58.75056000",
    longitude: "-156.83333000",
  },
  {
    id: 113124,
    name: "Butte",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "61.54222000",
    longitude: "-149.03333000",
  },
  {
    id: 113917,
    name: "Chevak",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "61.52778000",
    longitude: "-165.58639000",
  },
  {
    id: 114045,
    name: "City and Borough of Wrangell",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "56.41331000",
    longitude: "-132.32009000",
  },
  {
    id: 114374,
    name: "Cohoe",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "60.36861000",
    longitude: "-151.30639000",
  },
  {
    id: 114412,
    name: "College",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "64.85694000",
    longitude: "-147.80278000",
  },
  {
    id: 114640,
    name: "Cordova",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "60.54320000",
    longitude: "-145.75867000",
  },
  {
    id: 114756,
    name: "Craig",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "55.47639000",
    longitude: "-133.14833000",
  },
  {
    id: 115227,
    name: "Deltana",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "63.87217000",
    longitude: "-145.21773000",
  },
  {
    id: 115235,
    name: "Denali Borough",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "63.78889000",
    longitude: "-150.19167000",
  },
  {
    id: 115315,
    name: "Diamond Ridge",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "59.67611000",
    longitude: "-151.55750000",
  },
  {
    id: 115340,
    name: "Dillingham",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "59.03972000",
    longitude: "-158.45750000",
  },
  {
    id: 115341,
    name: "Dillingham Census Area",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "59.76193000",
    longitude: "-158.31848000",
  },
  {
    id: 115578,
    name: "Dutch Harbor",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "53.88980000",
    longitude: "-166.54220000",
  },
  {
    id: 115612,
    name: "Eagle River",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "61.32139000",
    longitude: "-149.56778000",
  },
  {
    id: 115909,
    name: "Eielson Air Force Base",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "64.66327000",
    longitude: "-147.05442000",
  },
  {
    id: 116049,
    name: "Elmendorf Air Force Base",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "61.25703000",
    longitude: "-149.63139000",
  },
  {
    id: 116206,
    name: "Ester",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "64.84722000",
    longitude: "-148.01444000",
  },
  {
    id: 116290,
    name: "Fairbanks",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "64.83778000",
    longitude: "-147.71639000",
  },
  {
    id: 116291,
    name: "Fairbanks North Star Borough",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "64.83333000",
    longitude: "-146.41667000",
  },
  {
    id: 116401,
    name: "Farm Loop",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "61.63891000",
    longitude: "-149.14215000",
  },
  {
    id: 116404,
    name: "Farmers Loop",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "64.90822000",
    longitude: "-147.69866000",
  },
  {
    id: 116531,
    name: "Fishhook",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "61.74402000",
    longitude: "-149.23613000",
  },
  {
    id: 116959,
    name: "Fritz Creek",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "59.73611000",
    longitude: "-151.29528000",
  },
  {
    id: 117124,
    name: "Gateway",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "61.57278000",
    longitude: "-149.24083000",
  },
  {
    id: 117230,
    name: "Girdwood",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "60.94250000",
    longitude: "-149.16639000",
  },
  {
    id: 117843,
    name: "Haines",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "59.23595000",
    longitude: "-135.44533000",
  },
  {
    id: 117844,
    name: "Haines Borough",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "59.25056000",
    longitude: "-135.50056000",
  },
  {
    id: 118219,
    name: "Healy",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "63.85694000",
    longitude: "-148.96611000",
  },
  {
    id: 118597,
    name: "Homer",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "59.64250000",
    longitude: "-151.54940000",
  },
  {
    id: 118632,
    name: "Hoonah-Angoon Census Area",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "57.94807000",
    longitude: "-134.94153000",
  },
  {
    id: 118634,
    name: "Hooper Bay",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "61.53111000",
    longitude: "-166.09667000",
  },
  {
    id: 118701,
    name: "Houston",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "61.63028000",
    longitude: "-149.81806000",
  },
  {
    id: 119338,
    name: "Juneau",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "58.30194000",
    longitude: "-134.41972000",
  },
  {
    id: 119364,
    name: "Kalifornsky",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "60.41833000",
    longitude: "-151.29000000",
  },
  {
    id: 119440,
    name: "Kenai",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "60.55444000",
    longitude: "-151.25833000",
  },
  {
    id: 119441,
    name: "Kenai Peninsula Borough",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "60.41667000",
    longitude: "-151.25056000",
  },
  {
    id: 119523,
    name: "Ketchikan",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "55.34180000",
    longitude: "-131.64757000",
  },
  {
    id: 119524,
    name: "Ketchikan Gateway Borough",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "55.50056000",
    longitude: "-131.41667000",
  },
  {
    id: 119583,
    name: "King Cove",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "55.06087000",
    longitude: "-162.31853000",
  },
  {
    id: 119677,
    name: "Knik-Fairview",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "61.51262000",
    longitude: "-149.60012000",
  },
  {
    id: 119698,
    name: "Kodiak",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "57.78852000",
    longitude: "-152.40533000",
  },
  {
    id: 119699,
    name: "Kodiak Island Borough",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "57.33333000",
    longitude: "-153.33333000",
  },
  {
    id: 119700,
    name: "Kodiak Station",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "57.76587000",
    longitude: "-152.60004000",
  },
  {
    id: 119712,
    name: "Kotzebue",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "66.89846000",
    longitude: "-162.59809000",
  },
  {
    id: 120003,
    name: "Lake and Peninsula Borough",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "57.00056000",
    longitude: "-158.00056000",
  },
  {
    id: 120024,
    name: "Lakes",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "61.60713000",
    longitude: "-149.30861000",
  },
  {
    id: 120256,
    name: "Lazy Mountain",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "61.62611000",
    longitude: "-148.94556000",
  },
  {
    id: 121399,
    name: "Matanuska-Susitna Borough",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "62.50056000",
    longitude: "-150.00556000",
  },
  {
    id: 121561,
    name: "Meadow Lakes",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "61.62472000",
    longitude: "-149.60111000",
  },
  {
    id: 121735,
    name: "Metlakatla",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "55.12848000",
    longitude: "-131.57519000",
  },
  {
    id: 122898,
    name: "Nikiski",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "60.69028000",
    longitude: "-151.28889000",
  },
  {
    id: 122934,
    name: "Nome",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "64.50111000",
    longitude: "-165.40639000",
  },
  {
    id: 122935,
    name: "Nome Census Area",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "64.83333000",
    longitude: "-163.75056000",
  },
  {
    id: 123084,
    name: "North Pole",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "64.75111000",
    longitude: "-147.34944000",
  },
  {
    id: 123107,
    name: "North Slope Borough",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "69.25056000",
    longitude: "-152.00056000",
  },
  {
    id: 123173,
    name: "Northwest Arctic Borough",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "66.83333000",
    longitude: "-161.00056000",
  },
  {
    id: 123760,
    name: "Palmer",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "61.59941000",
    longitude: "-149.11456000",
  },
  {
    id: 124108,
    name: "Petersburg",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "56.81250000",
    longitude: "-132.95556000",
  },
  {
    id: 124110,
    name: "Petersburg Borough",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "56.76529000",
    longitude: "-133.19313000",
  },
  {
    id: 124724,
    name: "Prudhoe Bay",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "70.25528000",
    longitude: "-148.33722000",
  },
  {
    id: 125130,
    name: "Ridgeway",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "60.53194000",
    longitude: "-151.08528000",
  },
  {
    id: 125724,
    name: "Salcha",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "64.52399000",
    longitude: "-146.90210000",
  },
  {
    id: 125860,
    name: "Sand Point",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "55.33655000",
    longitude: "-160.49880000",
  },
  {
    id: 126187,
    name: "Seward",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "60.10426000",
    longitude: "-149.44350000",
  },
  {
    id: 126470,
    name: "Sitka",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "57.05315000",
    longitude: "-135.33088000",
  },
  {
    id: 126471,
    name: "Sitka City and Borough",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "57.08255000",
    longitude: "-135.26917000",
  },
  {
    id: 126475,
    name: "Skagway Municipality",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "59.46852000",
    longitude: "-135.30596000",
  },
  {
    id: 126567,
    name: "Soldotna",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "60.48778000",
    longitude: "-151.05833000",
  },
  {
    id: 126766,
    name: "Southeast Fairbanks Census Area",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "63.75056000",
    longitude: "-143.25056000",
  },
  {
    id: 127031,
    name: "Sterling",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "60.53722000",
    longitude: "-150.76472000",
  },
  {
    id: 127316,
    name: "Sutton-Alpine",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "61.77789000",
    longitude: "-148.76450000",
  },
  {
    id: 127409,
    name: "Tanaina",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "61.62694000",
    longitude: "-149.42806000",
  },
  {
    id: 127697,
    name: "Tok",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "63.33667000",
    longitude: "-142.98556000",
  },
  {
    id: 127966,
    name: "Unalaska",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "53.87413000",
    longitude: "-166.53408000",
  },
  {
    id: 128083,
    name: "Valdez",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "61.13083000",
    longitude: "-146.34833000",
  },
  {
    id: 128084,
    name: "Valdez-Cordova Census Area",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "61.50056000",
    longitude: "-144.50056000",
  },
  {
    id: 128593,
    name: "Wasilla",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "61.58090000",
    longitude: "-149.44150000",
  },
  {
    id: 129304,
    name: "Willow",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "61.74722000",
    longitude: "-150.03750000",
  },
  {
    id: 129601,
    name: "Wrangell",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "56.47083000",
    longitude: "-132.37667000",
  },
  {
    id: 129646,
    name: "Y",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "62.16129000",
    longitude: "-149.85075000",
  },
  {
    id: 129652,
    name: "Yakutat City and Borough",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "59.66667000",
    longitude: "-139.13333000",
  },
  {
    id: 129731,
    name: "Yukon-Koyukuk Census Area",
    state_id: 1400,
    state_code: "AK",
    country_id: 233,
    country_code: "US",
    latitude: "65.70000000",
    longitude: "-152.71667000",
  },
  {
    id: 111065,
    name: "Ahwatukee Foothills",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.34171000",
    longitude: "-111.98403000",
  },
  {
    id: 111075,
    name: "Ajo",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.37172000",
    longitude: "-112.86071000",
  },
  {
    id: 111170,
    name: "Alhambra",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.49838000",
    longitude: "-112.13432000",
  },
  {
    id: 111367,
    name: "Anthem",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.86726000",
    longitude: "-112.14682000",
  },
  {
    id: 111380,
    name: "Apache County",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "35.39560000",
    longitude: "-109.48884000",
  },
  {
    id: 111381,
    name: "Apache Junction",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.41505000",
    longitude: "-111.54958000",
  },
  {
    id: 111453,
    name: "Arivaca Junction",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "31.72731000",
    longitude: "-111.06120000",
  },
  {
    id: 111454,
    name: "Arizona City",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.75589000",
    longitude: "-111.67096000",
  },
  {
    id: 111679,
    name: "Avenue B and C",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.71904000",
    longitude: "-114.66005000",
  },
  {
    id: 111701,
    name: "Avondale",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.43560000",
    longitude: "-112.34960000",
  },
  {
    id: 111705,
    name: "Avra Valley",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.43785000",
    longitude: "-111.31539000",
  },
  {
    id: 111727,
    name: "Bagdad",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.58113000",
    longitude: "-113.20464000",
  },
  {
    id: 112002,
    name: "Beaver Dam",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "36.89942000",
    longitude: "-113.93274000",
  },
  {
    id: 112184,
    name: "Benson",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "31.96786000",
    longitude: "-110.29452000",
  },
  {
    id: 112327,
    name: "Big Park",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.78030000",
    longitude: "-111.76265000",
  },
  {
    id: 112360,
    name: "Bisbee",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "31.44815000",
    longitude: "-109.92841000",
  },
  {
    id: 112371,
    name: "Black Canyon City",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.07087000",
    longitude: "-112.15071000",
  },
  {
    id: 112395,
    name: "Blackwater",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.03117000",
    longitude: "-111.58263000",
  },
  {
    id: 112977,
    name: "Buckeye",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.37032000",
    longitude: "-112.58378000",
  },
  {
    id: 113031,
    name: "Bullhead City",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "35.14778000",
    longitude: "-114.56830000",
  },
  {
    id: 113136,
    name: "Bylas",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.13428000",
    longitude: "-110.12004000",
  },
  {
    id: 113158,
    name: "Cactus Flat",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.75840000",
    longitude: "-109.71619000",
  },
  {
    id: 113295,
    name: "Camp Verde",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.56364000",
    longitude: "-111.85432000",
  },
  {
    id: 113357,
    name: "Canyon Day",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.78477000",
    longitude: "-110.02649000",
  },
  {
    id: 113392,
    name: "Carefree",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.82226000",
    longitude: "-111.91820000",
  },
  {
    id: 113508,
    name: "Casa Blanca",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.12033000",
    longitude: "-111.88819000",
  },
  {
    id: 113510,
    name: "Casa Grande",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.87950000",
    longitude: "-111.75735000",
  },
  {
    id: 113512,
    name: "Casas Adobes",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.32341000",
    longitude: "-110.99510000",
  },
  {
    id: 113563,
    name: "Catalina",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.50556000",
    longitude: "-110.92111000",
  },
  {
    id: 113564,
    name: "Catalina Foothills",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.29785000",
    longitude: "-110.91870000",
  },
  {
    id: 113585,
    name: "Cave Creek",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.83333000",
    longitude: "-111.95083000",
  },
  {
    id: 113635,
    name: "Centennial Park",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "36.95381000",
    longitude: "-112.98132000",
  },
  {
    id: 113672,
    name: "Central City",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.44001000",
    longitude: "-112.05805000",
  },
  {
    id: 113676,
    name: "Central Heights-Midland City",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.40372000",
    longitude: "-110.81541000",
  },
  {
    id: 113731,
    name: "Chandler",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.30616000",
    longitude: "-111.84125000",
  },
  {
    id: 113967,
    name: "Chinle",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "36.15445000",
    longitude: "-109.55261000",
  },
  {
    id: 113970,
    name: "Chino Valley",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.75752000",
    longitude: "-112.45378000",
  },
  {
    id: 114016,
    name: "Cibecue",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.04477000",
    longitude: "-110.48539000",
  },
  {
    id: 114021,
    name: "Cienega Springs",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.18863000",
    longitude: "-114.22467000",
  },
  {
    id: 114040,
    name: "Citrus Park",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.54865000",
    longitude: "-112.44433000",
  },
  {
    id: 114133,
    name: "Clarkdale",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.77113000",
    longitude: "-112.05794000",
  },
  {
    id: 114193,
    name: "Claypool",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.41117000",
    longitude: "-110.84261000",
  },
  {
    id: 114260,
    name: "Clifton",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.05090000",
    longitude: "-109.29618000",
  },
  {
    id: 114349,
    name: "Cochise County",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "31.87957000",
    longitude: "-109.75114000",
  },
  {
    id: 114360,
    name: "Coconino County",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "35.83873000",
    longitude: "-111.77050000",
  },
  {
    id: 114454,
    name: "Colorado City",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "36.99026000",
    longitude: "-112.97577000",
  },
  {
    id: 114558,
    name: "Congress",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.16253000",
    longitude: "-112.85074000",
  },
  {
    id: 114598,
    name: "Coolidge",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.97784000",
    longitude: "-111.51762000",
  },
  {
    id: 114637,
    name: "Cordes Lakes",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.30781000",
    longitude: "-112.10349000",
  },
  {
    id: 114657,
    name: "Cornville",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.71780000",
    longitude: "-111.92154000",
  },
  {
    id: 114663,
    name: "Corona de Tucson",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "31.96536000",
    longitude: "-110.77564000",
  },
  {
    id: 114701,
    name: "Cottonwood",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.73919000",
    longitude: "-112.00988000",
  },
  {
    id: 115180,
    name: "Deer Valley",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.68393000",
    longitude: "-112.13488000",
  },
  {
    id: 115281,
    name: "Desert Hills",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.55390000",
    longitude: "-114.37246000",
  },
  {
    id: 115305,
    name: "Dewey-Humboldt",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.53000000",
    longitude: "-112.24222000",
  },
  {
    id: 115338,
    name: "Dilkon",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "35.38529000",
    longitude: "-110.32068000",
  },
  {
    id: 115386,
    name: "Dolan Springs",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "35.59194000",
    longitude: "-114.27329000",
  },
  {
    id: 115402,
    name: "Donovan Estates",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.70935000",
    longitude: "-114.67822000",
  },
  {
    id: 115423,
    name: "Douglas",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "31.34455000",
    longitude: "-109.54534000",
  },
  {
    id: 115476,
    name: "Drexel Heights",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.14119000",
    longitude: "-111.02843000",
  },
  {
    id: 115594,
    name: "Eagar",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.11124000",
    longitude: "-109.29238000",
  },
  {
    id: 115753,
    name: "East Sahuarita",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "31.94286000",
    longitude: "-110.92842000",
  },
  {
    id: 115907,
    name: "Ehrenberg",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.60419000",
    longitude: "-114.52523000",
  },
  {
    id: 115927,
    name: "El Mirage",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.61309000",
    longitude: "-112.32460000",
  },
  {
    id: 116065,
    name: "Eloy",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.75590000",
    longitude: "-111.55484000",
  },
  {
    id: 116116,
    name: "Encanto",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.47937000",
    longitude: "-112.07823000",
  },
  {
    id: 116523,
    name: "First Mesa",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "35.83667000",
    longitude: "-110.38152000",
  },
  {
    id: 116546,
    name: "Flagstaff",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "35.19807000",
    longitude: "-111.65127000",
  },
  {
    id: 116583,
    name: "Florence",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.03145000",
    longitude: "-111.38734000",
  },
  {
    id: 116603,
    name: "Flowing Wells",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.29396000",
    longitude: "-111.00982000",
  },
  {
    id: 116704,
    name: "Fort Defiance",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "35.74446000",
    longitude: "-109.07648000",
  },
  {
    id: 116768,
    name: "Fortuna Foothills",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.65783000",
    longitude: "-114.41189000",
  },
  {
    id: 116782,
    name: "Fountain Hills",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.61171000",
    longitude: "-111.71736000",
  },
  {
    id: 116898,
    name: "Fredonia",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "36.94554000",
    longitude: "-112.52659000",
  },
  {
    id: 117048,
    name: "Ganado",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "35.71140000",
    longitude: "-109.54205000",
  },
  {
    id: 117200,
    name: "Gila Bend",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.94782000",
    longitude: "-112.71683000",
  },
  {
    id: 117201,
    name: "Gila County",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.79975000",
    longitude: "-110.81174000",
  },
  {
    id: 117204,
    name: "Gilbert",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.35283000",
    longitude: "-111.78903000",
  },
  {
    id: 117285,
    name: "Glendale",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.53865000",
    longitude: "-112.18599000",
  },
  {
    id: 117321,
    name: "Globe",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.39422000",
    longitude: "-110.78650000",
  },
  {
    id: 117341,
    name: "Gold Camp",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.29367000",
    longitude: "-111.30429000",
  },
  {
    id: 117342,
    name: "Gold Canyon",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.37145000",
    longitude: "-111.43691000",
  },
  {
    id: 117353,
    name: "Golden Shores",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.78188000",
    longitude: "-114.47775000",
  },
  {
    id: 117356,
    name: "Golden Valley",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "35.22333000",
    longitude: "-114.22301000",
  },
  {
    id: 117393,
    name: "Goodyear",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.43532000",
    longitude: "-112.35821000",
  },
  {
    id: 117439,
    name: "Graham County",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.93272000",
    longitude: "-109.88744000",
  },
  {
    id: 117452,
    name: "Grand Canyon",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "36.05443000",
    longitude: "-112.13934000",
  },
  {
    id: 117453,
    name: "Grand Canyon Village",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "36.04637000",
    longitude: "-112.15406000",
  },
  {
    id: 117610,
    name: "Green Valley",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "31.85425000",
    longitude: "-110.99370000",
  },
  {
    id: 117659,
    name: "Greenlee County",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.21536000",
    longitude: "-109.24010000",
  },
  {
    id: 117776,
    name: "Guadalupe",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.37088000",
    longitude: "-111.96292000",
  },
  {
    id: 118231,
    name: "Heber-Overgaard",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.41414000",
    longitude: "-110.56956000",
  },
  {
    id: 118519,
    name: "Holbrook",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.90225000",
    longitude: "-110.15818000",
  },
  {
    id: 118688,
    name: "Houck",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "35.28308000",
    longitude: "-109.20704000",
  },
  {
    id: 118729,
    name: "Huachuca City",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "31.62787000",
    longitude: "-110.33397000",
  },
  {
    id: 119319,
    name: "Joseph City",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.95586000",
    longitude: "-110.33401000",
  },
  {
    id: 119349,
    name: "Kachina Village",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "35.09696000",
    longitude: "-111.69266000",
  },
  {
    id: 119356,
    name: "Kaibito",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "36.59722000",
    longitude: "-111.07431000",
  },
  {
    id: 119402,
    name: "Kayenta",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "36.72778000",
    longitude: "-110.25458000",
  },
  {
    id: 119413,
    name: "Kearny",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.05701000",
    longitude: "-110.91067000",
  },
  {
    id: 119595,
    name: "Kingman",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "35.18944000",
    longitude: "-114.05301000",
  },
  {
    id: 119771,
    name: "La Paz County",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.72926000",
    longitude: "-113.98134000",
  },
  {
    id: 119918,
    name: "Lake Havasu City",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.48390000",
    longitude: "-114.32245000",
  },
  {
    id: 119946,
    name: "Lake Montezuma",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.63224000",
    longitude: "-111.77793000",
  },
  {
    id: 120007,
    name: "Lake of the Woods",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.16393000",
    longitude: "-109.98955000",
  },
  {
    id: 120217,
    name: "Laveen",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.36282000",
    longitude: "-112.16932000",
  },
  {
    id: 120266,
    name: "LeChee",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "36.86211000",
    longitude: "-111.44063000",
  },
  {
    id: 120565,
    name: "Linden",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.28504000",
    longitude: "-110.15706000",
  },
  {
    id: 120609,
    name: "Litchfield Park",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.49337000",
    longitude: "-112.35794000",
  },
  {
    id: 120874,
    name: "Lukachukai",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "36.41695000",
    longitude: "-109.22871000",
  },
  {
    id: 121076,
    name: "Mammoth",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.72257000",
    longitude: "-110.64065000",
  },
  {
    id: 121164,
    name: "Many Farms",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "36.35278000",
    longitude: "-109.61789000",
  },
  {
    id: 121183,
    name: "Marana",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.43674000",
    longitude: "-111.22538000",
  },
  {
    id: 121211,
    name: "Maricopa",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.05811000",
    longitude: "-112.04764000",
  },
  {
    id: 121213,
    name: "Maricopa County",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.34883000",
    longitude: "-112.49123000",
  },
  {
    id: 121362,
    name: "Maryvale",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.50199000",
    longitude: "-112.17765000",
  },
  {
    id: 121433,
    name: "Mayer",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.39781000",
    longitude: "-112.23627000",
  },
  {
    id: 121573,
    name: "Meadview",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "36.00221000",
    longitude: "-114.06829000",
  },
  {
    id: 121720,
    name: "Mesa",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.42227000",
    longitude: "-111.82264000",
  },
  {
    id: 121723,
    name: "Mescal",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "31.99008000",
    longitude: "-110.43535000",
  },
  {
    id: 121748,
    name: "Miami",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.39922000",
    longitude: "-110.86872000",
  },
  {
    id: 122019,
    name: "Mohave County",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "35.70404000",
    longitude: "-113.75791000",
  },
  {
    id: 122020,
    name: "Mohave Valley",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.93306000",
    longitude: "-114.58885000",
  },
  {
    id: 122230,
    name: "Morenci",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.07867000",
    longitude: "-109.36535000",
  },
  {
    id: 122436,
    name: "Mountainaire",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "35.08529000",
    longitude: "-111.66599000",
  },
  {
    id: 122531,
    name: "Naco",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "31.33538000",
    longitude: "-109.94813000",
  },
  {
    id: 122598,
    name: "Navajo County",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "35.39963000",
    longitude: "-110.32140000",
  },
  {
    id: 122736,
    name: "New Kingman-Butler",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "35.26504000",
    longitude: "-114.03226000",
  },
  {
    id: 122771,
    name: "New River",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.91587000",
    longitude: "-112.13599000",
  },
  {
    id: 122927,
    name: "Nogales",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "31.34038000",
    longitude: "-110.93425000",
  },
  {
    id: 123027,
    name: "North Fork",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.00167000",
    longitude: "-109.96355000",
  },
  {
    id: 123502,
    name: "Oracle",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.61091000",
    longitude: "-110.77093000",
  },
  {
    id: 123571,
    name: "Oro Valley",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.39091000",
    longitude: "-110.96649000",
  },
  {
    id: 123717,
    name: "Page",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "36.91472000",
    longitude: "-111.45583000",
  },
  {
    id: 123822,
    name: "Paradise Valley",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.53115000",
    longitude: "-111.94265000",
  },
  {
    id: 123861,
    name: "Parker",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.15002000",
    longitude: "-114.28912000",
  },
  {
    id: 123873,
    name: "Parks",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "35.26057000",
    longitude: "-111.94877000",
  },
  {
    id: 123921,
    name: "Paulden",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.88558000",
    longitude: "-112.46823000",
  },
  {
    id: 123949,
    name: "Payson",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.23087000",
    longitude: "-111.32514000",
  },
  {
    id: 123958,
    name: "Peach Springs",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "35.52916000",
    longitude: "-113.42549000",
  },
  {
    id: 124047,
    name: "Peoria",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.58060000",
    longitude: "-112.23738000",
  },
  {
    id: 124060,
    name: "Peridot",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.31034000",
    longitude: "-110.45538000",
  },
  {
    id: 124148,
    name: "Phoenix",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.44838000",
    longitude: "-112.07404000",
  },
  {
    id: 124163,
    name: "Picture Rocks",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.34591000",
    longitude: "-111.24621000",
  },
  {
    id: 124203,
    name: "Pima",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.89656000",
    longitude: "-109.82835000",
  },
  {
    id: 124204,
    name: "Pima County",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.09738000",
    longitude: "-111.78995000",
  },
  {
    id: 124206,
    name: "Pinal County",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.90431000",
    longitude: "-111.34471000",
  },
  {
    id: 124212,
    name: "Pine",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.38447000",
    longitude: "-111.45514000",
  },
  {
    id: 124257,
    name: "Pinetop-Lakeside",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.14254000",
    longitude: "-109.96038000",
  },
  {
    id: 124279,
    name: "Pirtleville",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "31.35716000",
    longitude: "-109.56352000",
  },
  {
    id: 124643,
    name: "Prescott",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.54002000",
    longitude: "-112.46850000",
  },
  {
    id: 124645,
    name: "Prescott Valley",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.61002000",
    longitude: "-112.31572000",
  },
  {
    id: 124783,
    name: "Quartzsite",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.66391000",
    longitude: "-114.22995000",
  },
  {
    id: 124788,
    name: "Queen Creek",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.24866000",
    longitude: "-111.63430000",
  },
  {
    id: 125159,
    name: "Rio Rico",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "31.47148000",
    longitude: "-110.97648000",
  },
  {
    id: 125160,
    name: "Rio Verde",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.72254000",
    longitude: "-111.67569000",
  },
  {
    id: 125573,
    name: "Sacaton",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.07672000",
    longitude: "-111.73930000",
  },
  {
    id: 125580,
    name: "Saddle Brooke",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.53472000",
    longitude: "-110.87361000",
  },
  {
    id: 125583,
    name: "Safford",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.83395000",
    longitude: "-109.70758000",
  },
  {
    id: 125593,
    name: "Sahuarita",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "31.95758000",
    longitude: "-110.95565000",
  },
  {
    id: 125630,
    name: "Saint David",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "31.90425000",
    longitude: "-110.21424000",
  },
  {
    id: 125666,
    name: "Saint Johns",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.50587000",
    longitude: "-109.36093000",
  },
  {
    id: 125699,
    name: "Saint Michaels",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "35.64474000",
    longitude: "-109.09565000",
  },
  {
    id: 125766,
    name: "Salome",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.78114000",
    longitude: "-113.61465000",
  },
  {
    id: 125797,
    name: "San Carlos",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.34562000",
    longitude: "-110.45504000",
  },
  {
    id: 125827,
    name: "San Luis",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.48700000",
    longitude: "-114.78218000",
  },
  {
    id: 125831,
    name: "San Manuel",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.59979000",
    longitude: "-110.63093000",
  },
  {
    id: 125850,
    name: "San Tan Valley",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.19110000",
    longitude: "-111.52800000",
  },
  {
    id: 125911,
    name: "Santa Cruz County",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "31.52600000",
    longitude: "-110.84657000",
  },
  {
    id: 125999,
    name: "Scenic",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "36.79359000",
    longitude: "-114.01275000",
  },
  {
    id: 126063,
    name: "Scottsdale",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.50921000",
    longitude: "-111.89903000",
  },
  {
    id: 126121,
    name: "Sedona",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.86974000",
    longitude: "-111.76099000",
  },
  {
    id: 126137,
    name: "Sells",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "31.91202000",
    longitude: "-111.88123000",
  },
  {
    id: 126381,
    name: "Show Low",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.25421000",
    longitude: "-110.02983000",
  },
  {
    id: 126406,
    name: "Sierra Vista",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "31.55454000",
    longitude: "-110.30369000",
  },
  {
    id: 126407,
    name: "Sierra Vista Southeast",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "31.45385000",
    longitude: "-110.21637000",
  },
  {
    id: 126472,
    name: "Six Shooter Canyon",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.36678000",
    longitude: "-110.77460000",
  },
  {
    id: 126547,
    name: "Snowflake",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.51337000",
    longitude: "-110.07845000",
  },
  {
    id: 126591,
    name: "Somerton",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.59644000",
    longitude: "-114.70968000",
  },
  {
    id: 126737,
    name: "South Tucson",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.19952000",
    longitude: "-110.96842000",
  },
  {
    id: 126878,
    name: "Spring Valley",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.34503000",
    longitude: "-112.15905000",
  },
  {
    id: 126888,
    name: "Springerville",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.13355000",
    longitude: "-109.28834000",
  },
  {
    id: 126981,
    name: "Star Valley",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.25504000",
    longitude: "-111.25847000",
  },
  {
    id: 127214,
    name: "Summit",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.06702000",
    longitude: "-110.95148000",
  },
  {
    id: 127232,
    name: "Sun City",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.59754000",
    longitude: "-112.27182000",
  },
  {
    id: 127235,
    name: "Sun City West",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.66198000",
    longitude: "-112.34127000",
  },
  {
    id: 127236,
    name: "Sun Lakes",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.21116000",
    longitude: "-111.87542000",
  },
  {
    id: 127240,
    name: "Sun Valley",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.25420000",
    longitude: "-111.26125000",
  },
  {
    id: 127280,
    name: "Superior",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.29395000",
    longitude: "-111.09623000",
  },
  {
    id: 127290,
    name: "Surprise",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.63059000",
    longitude: "-112.33322000",
  },
  {
    id: 127346,
    name: "Swift Trail Junction",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.72979000",
    longitude: "-109.71397000",
  },
  {
    id: 127420,
    name: "Tanque Verde",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.25174000",
    longitude: "-110.73731000",
  },
  {
    id: 127450,
    name: "Taylor",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.46504000",
    longitude: "-110.09123000",
  },
  {
    id: 127494,
    name: "Tempe",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.41477000",
    longitude: "-111.90931000",
  },
  {
    id: 127495,
    name: "Tempe Junction",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.41421000",
    longitude: "-111.94348000",
  },
  {
    id: 127550,
    name: "Thatcher",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.84923000",
    longitude: "-109.75925000",
  },
  {
    id: 127616,
    name: "Three Points",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.07675000",
    longitude: "-111.31371000",
  },
  {
    id: 127703,
    name: "Tolleson",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.45004000",
    longitude: "-112.25932000",
  },
  {
    id: 127711,
    name: "Tombstone",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "31.71287000",
    longitude: "-110.06758000",
  },
  {
    id: 127724,
    name: "Tonto Basin",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.83171000",
    longitude: "-111.29457000",
  },
  {
    id: 127746,
    name: "Tortolita",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.41035000",
    longitude: "-111.01732000",
  },
  {
    id: 127864,
    name: "Tsaile",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "36.30330000",
    longitude: "-109.21566000",
  },
  {
    id: 127866,
    name: "Tuba City",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "36.13499000",
    longitude: "-111.23986000",
  },
  {
    id: 127867,
    name: "Tubac",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "31.61259000",
    longitude: "-111.04592000",
  },
  {
    id: 127874,
    name: "Tucson",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.22174000",
    longitude: "-110.92648000",
  },
  {
    id: 127875,
    name: "Tucson Estates",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.18758000",
    longitude: "-111.09093000",
  },
  {
    id: 128075,
    name: "Vail",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.04786000",
    longitude: "-110.71203000",
  },
  {
    id: 128090,
    name: "Valencia West",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.13238000",
    longitude: "-111.11414000",
  },
  {
    id: 128095,
    name: "Valle Vista",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "35.41088000",
    longitude: "-113.86271000",
  },
  {
    id: 128179,
    name: "Verde Village",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.71050000",
    longitude: "-112.01152000",
  },
  {
    id: 128253,
    name: "Village of Oak Creek (Big Park)",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.78090000",
    longitude: "-111.76227000",
  },
  {
    id: 128795,
    name: "Wellton",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.67283000",
    longitude: "-114.14688000",
  },
  {
    id: 128978,
    name: "West Sedona",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.86724000",
    longitude: "-111.80543000",
  },
  {
    id: 129122,
    name: "Whetstone",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "31.95731000",
    longitude: "-110.34202000",
  },
  {
    id: 129147,
    name: "White Mountain Lake",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.34865000",
    longitude: "-109.99789000",
  },
  {
    id: 129179,
    name: "Whiteriver",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.83699000",
    longitude: "-109.96427000",
  },
  {
    id: 129219,
    name: "Wickenburg",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.96864000",
    longitude: "-112.72962000",
  },
  {
    id: 129259,
    name: "Willcox",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.25285000",
    longitude: "-109.83201000",
  },
  {
    id: 129260,
    name: "Williams",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "35.24946000",
    longitude: "-112.19100000",
  },
  {
    id: 129275,
    name: "Williamson",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.69002000",
    longitude: "-112.54101000",
  },
  {
    id: 129312,
    name: "Willow Valley",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.91195000",
    longitude: "-114.60663000",
  },
  {
    id: 129376,
    name: "Window Rock",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "35.68057000",
    longitude: "-109.05259000",
  },
  {
    id: 129429,
    name: "Winslow",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "35.02419000",
    longitude: "-110.69736000",
  },
  {
    id: 129672,
    name: "Yavapai County",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "34.59988000",
    longitude: "-112.55387000",
  },
  {
    id: 129721,
    name: "Youngtown",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "33.59393000",
    longitude: "-112.30294000",
  },
  {
    id: 129733,
    name: "Yuma",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.72532000",
    longitude: "-114.62440000",
  },
  {
    id: 129735,
    name: "Yuma County",
    state_id: 1434,
    state_code: "AZ",
    country_id: 233,
    country_code: "US",
    latitude: "32.76940000",
    longitude: "-113.90556000",
  },
  {
    id: 111145,
    name: "Alexander",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.62954000",
    longitude: "-92.44127000",
  },
  {
    id: 111206,
    name: "Alma",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.47787000",
    longitude: "-94.22188000",
  },
  {
    id: 111455,
    name: "Arkadelphia",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.12093000",
    longitude: "-93.05378000",
  },
  {
    id: 111456,
    name: "Arkansas City",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.60872000",
    longitude: "-91.20678000",
  },
  {
    id: 111458,
    name: "Arkansas County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.29081000",
    longitude: "-91.37491000",
  },
  {
    id: 111510,
    name: "Ash Flat",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.22396000",
    longitude: "-91.60848000",
  },
  {
    id: 111518,
    name: "Ashdown",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.67429000",
    longitude: "-94.13131000",
  },
  {
    id: 111545,
    name: "Ashley County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.19122000",
    longitude: "-91.76845000",
  },
  {
    id: 111584,
    name: "Atkins",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.24647000",
    longitude: "-92.93656000",
  },
  {
    id: 111645,
    name: "Augusta",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.28231000",
    longitude: "-91.36541000",
  },
  {
    id: 111666,
    name: "Austin",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.99842000",
    longitude: "-91.98376000",
  },
  {
    id: 111751,
    name: "Bald Knob",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.30981000",
    longitude: "-91.56791000",
  },
  {
    id: 111824,
    name: "Barling",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.32565000",
    longitude: "-94.30160000",
  },
  {
    id: 111892,
    name: "Batesville",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.76980000",
    longitude: "-91.64097000",
  },
  {
    id: 111917,
    name: "Baxter County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.28719000",
    longitude: "-92.33697000",
  },
  {
    id: 111920,
    name: "Bay",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.74230000",
    longitude: "-90.56233000",
  },
  {
    id: 112036,
    name: "Beebe",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.07064000",
    longitude: "-91.87959000",
  },
  {
    id: 112071,
    name: "Bella Vista",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.48070000",
    longitude: "-94.27134000",
  },
  {
    id: 112191,
    name: "Benton",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.56454000",
    longitude: "-92.58683000",
  },
  {
    id: 112199,
    name: "Benton County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.33872000",
    longitude: "-94.25619000",
  },
  {
    id: 112210,
    name: "Bentonville",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.37285000",
    longitude: "-94.20882000",
  },
  {
    id: 112254,
    name: "Berryville",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.36479000",
    longitude: "-93.56797000",
  },
  {
    id: 112285,
    name: "Bethel Heights",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.21424000",
    longitude: "-94.12937000",
  },
  {
    id: 112486,
    name: "Blytheville",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.92730000",
    longitude: "-89.91898000",
  },
  {
    id: 112549,
    name: "Bono",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.90868000",
    longitude: "-90.80262000",
  },
  {
    id: 112555,
    name: "Boone County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.30859000",
    longitude: "-93.09150000",
  },
  {
    id: 112562,
    name: "Booneville",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.14009000",
    longitude: "-93.92159000",
  },
  {
    id: 112675,
    name: "Bradley County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.46642000",
    longitude: "-92.16240000",
  },
  {
    id: 112799,
    name: "Brinkley",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.88787000",
    longitude: "-91.19457000",
  },
  {
    id: 112864,
    name: "Brookland",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.90007000",
    longitude: "-90.58205000",
  },
  {
    id: 112963,
    name: "Bryant",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.59593000",
    longitude: "-92.48905000",
  },
  {
    id: 113028,
    name: "Bull Shoals",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.38396000",
    longitude: "-92.58155000",
  },
  {
    id: 113154,
    name: "Cabot",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.97453000",
    longitude: "-92.01653000",
  },
  {
    id: 113201,
    name: "Calhoun County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.55803000",
    longitude: "-92.50304000",
  },
  {
    id: 113212,
    name: "Calico Rock",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.11951000",
    longitude: "-92.13599000",
  },
  {
    id: 113260,
    name: "Camden",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.58456000",
    longitude: "-92.83433000",
  },
  {
    id: 113380,
    name: "Caraway",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.75813000",
    longitude: "-90.32232000",
  },
  {
    id: 113401,
    name: "Carlisle",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.78315000",
    longitude: "-91.74652000",
  },
  {
    id: 113450,
    name: "Carroll County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.34102000",
    longitude: "-93.53818000",
  },
  {
    id: 113583,
    name: "Cave City",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.94174000",
    longitude: "-91.54847000",
  },
  {
    id: 113589,
    name: "Cave Springs",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.26341000",
    longitude: "-94.23187000",
  },
  {
    id: 113628,
    name: "Cedarville",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.56981000",
    longitude: "-94.36688000",
  },
  {
    id: 113651,
    name: "Centerton",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.35980000",
    longitude: "-94.28521000",
  },
  {
    id: 113761,
    name: "Charleston",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.29704000",
    longitude: "-94.03632000",
  },
  {
    id: 113857,
    name: "Cherokee Village",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.29784000",
    longitude: "-91.51597000",
  },
  {
    id: 113947,
    name: "Chicot County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.26725000",
    longitude: "-91.29397000",
  },
  {
    id: 114110,
    name: "Clarendon",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.69315000",
    longitude: "-91.31374000",
  },
  {
    id: 114121,
    name: "Clark County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.05096000",
    longitude: "-93.17637000",
  },
  {
    id: 114152,
    name: "Clarksville",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.47147000",
    longitude: "-93.46657000",
  },
  {
    id: 114174,
    name: "Clay County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.36839000",
    longitude: "-90.41738000",
  },
  {
    id: 114231,
    name: "Cleburne County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.53811000",
    longitude: "-92.02674000",
  },
  {
    id: 114248,
    name: "Cleveland County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.89836000",
    longitude: "-92.18514000",
  },
  {
    id: 114267,
    name: "Clinton",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.59147000",
    longitude: "-92.46044000",
  },
  {
    id: 114331,
    name: "Coal Hill",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.43731000",
    longitude: "-93.67297000",
  },
  {
    id: 114477,
    name: "Columbia County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.21429000",
    longitude: "-93.22731000",
  },
  {
    id: 114581,
    name: "Conway",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.08870000",
    longitude: "-92.44210000",
  },
  {
    id: 114587,
    name: "Conway County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.26221000",
    longitude: "-92.70132000",
  },
  {
    id: 114651,
    name: "Corning",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.40784000",
    longitude: "-90.57983000",
  },
  {
    id: 114759,
    name: "Craighead County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.83079000",
    longitude: "-90.63285000",
  },
  {
    id: 114774,
    name: "Crawford County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.58883000",
    longitude: "-94.24312000",
  },
  {
    id: 114825,
    name: "Crittenden County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.20794000",
    longitude: "-90.30886000",
  },
  {
    id: 114848,
    name: "Cross County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.29573000",
    longitude: "-90.77123000",
  },
  {
    id: 114854,
    name: "Crossett",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.12818000",
    longitude: "-91.96124000",
  },
  {
    id: 114995,
    name: "Dallas County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.96983000",
    longitude: "-92.65444000",
  },
  {
    id: 115031,
    name: "Danville",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.05398000",
    longitude: "-93.39352000",
  },
  {
    id: 115042,
    name: "Dardanelle",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.22314000",
    longitude: "-93.15795000",
  },
  {
    id: 115113,
    name: "De Queen",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.03789000",
    longitude: "-94.34132000",
  },
  {
    id: 115121,
    name: "De Witt",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.29288000",
    longitude: "-91.33790000",
  },
  {
    id: 115150,
    name: "Decatur",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.33591000",
    longitude: "-94.46077000",
  },
  {
    id: 115264,
    name: "Dermott",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.52539000",
    longitude: "-91.43595000",
  },
  {
    id: 115270,
    name: "Des Arc",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.97704000",
    longitude: "-91.49513000",
  },
  {
    id: 115285,
    name: "Desha County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.83333000",
    longitude: "-91.25395000",
  },
  {
    id: 115318,
    name: "Diaz",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.63841000",
    longitude: "-91.26513000",
  },
  {
    id: 115334,
    name: "Dierks",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.11928000",
    longitude: "-94.01658000",
  },
  {
    id: 115444,
    name: "Dover",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.40147000",
    longitude: "-93.11434000",
  },
  {
    id: 115474,
    name: "Drew County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.58945000",
    longitude: "-91.72002000",
  },
  {
    id: 115514,
    name: "Dumas",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.88705000",
    longitude: "-91.49179000",
  },
  {
    id: 115616,
    name: "Earle",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.27509000",
    longitude: "-90.46677000",
  },
  {
    id: 115656,
    name: "East End",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.55065000",
    longitude: "-92.34099000",
  },
  {
    id: 115919,
    name: "El Dorado",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.20763000",
    longitude: "-92.66627000",
  },
  {
    id: 116005,
    name: "Elkins",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.00147000",
    longitude: "-94.00825000",
  },
  {
    id: 116045,
    name: "Elm Springs",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.20619000",
    longitude: "-94.23437000",
  },
  {
    id: 116128,
    name: "England",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.54426000",
    longitude: "-91.96903000",
  },
  {
    id: 116222,
    name: "Eudora",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.10957000",
    longitude: "-91.26206000",
  },
  {
    id: 116240,
    name: "Eureka Springs",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.40118000",
    longitude: "-93.73797000",
  },
  {
    id: 116318,
    name: "Fairfield Bay",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.59424000",
    longitude: "-92.27793000",
  },
  {
    id: 116412,
    name: "Farmington",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.04202000",
    longitude: "-94.24715000",
  },
  {
    id: 116434,
    name: "Faulkner County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.14698000",
    longitude: "-92.33204000",
  },
  {
    id: 116454,
    name: "Fayetteville",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.06258000",
    longitude: "-94.15743000",
  },
  {
    id: 116568,
    name: "Flippin",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.27896000",
    longitude: "-92.59711000",
  },
  {
    id: 116642,
    name: "Fordyce",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.81372000",
    longitude: "-92.41293000",
  },
  {
    id: 116679,
    name: "Forrest City",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.00815000",
    longitude: "-90.78983000",
  },
  {
    id: 116750,
    name: "Fort Smith",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.38592000",
    longitude: "-94.39855000",
  },
  {
    id: 116844,
    name: "Franklin County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.51235000",
    longitude: "-93.89062000",
  },
  {
    id: 116992,
    name: "Fulton County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.38167000",
    longitude: "-91.81824000",
  },
  {
    id: 117091,
    name: "Garland County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.57669000",
    longitude: "-93.15043000",
  },
  {
    id: 117112,
    name: "Gassville",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.28312000",
    longitude: "-92.49405000",
  },
  {
    id: 117150,
    name: "Gentry",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.26758000",
    longitude: "-94.48466000",
  },
  {
    id: 117186,
    name: "Gibson",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.88426000",
    longitude: "-92.23570000",
  },
  {
    id: 117312,
    name: "Glenwood",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.32677000",
    longitude: "-93.55074000",
  },
  {
    id: 117407,
    name: "Goshen",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.10119000",
    longitude: "-93.99131000",
  },
  {
    id: 117412,
    name: "Gosnell",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.95979000",
    longitude: "-89.97203000",
  },
  {
    id: 117508,
    name: "Grant County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.29002000",
    longitude: "-92.42358000",
  },
  {
    id: 117546,
    name: "Gravel Ridge",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.86842000",
    longitude: "-92.19070000",
  },
  {
    id: 117549,
    name: "Gravette",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.42202000",
    longitude: "-94.45355000",
  },
  {
    id: 117595,
    name: "Green Forest",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.33535000",
    longitude: "-93.43602000",
  },
  {
    id: 117619,
    name: "Greenbrier",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.23397000",
    longitude: "-92.38765000",
  },
  {
    id: 117632,
    name: "Greene County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.11769000",
    longitude: "-90.55908000",
  },
  {
    id: 117656,
    name: "Greenland",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.99425000",
    longitude: "-94.17520000",
  },
  {
    id: 117701,
    name: "Greenwood",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.21565000",
    longitude: "-94.25577000",
  },
  {
    id: 117808,
    name: "Gurdon",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.92094000",
    longitude: "-93.15406000",
  },
  {
    id: 117881,
    name: "Hamburg",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.22818000",
    longitude: "-91.79763000",
  },
  {
    id: 117926,
    name: "Hampton",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.53789000",
    longitude: "-92.46988000",
  },
  {
    id: 118047,
    name: "Harrisburg",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.56425000",
    longitude: "-90.71678000",
  },
  {
    id: 118054,
    name: "Harrison",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.22979000",
    longitude: "-93.10768000",
  },
  {
    id: 118122,
    name: "Haskell",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.50148000",
    longitude: "-92.63655000",
  },
  {
    id: 118209,
    name: "Hazen",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.78093000",
    longitude: "-91.58097000",
  },
  {
    id: 118230,
    name: "Heber Springs",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.49147000",
    longitude: "-92.03126000",
  },
  {
    id: 118247,
    name: "Helena",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.52955000",
    longitude: "-90.59177000",
  },
  {
    id: 118257,
    name: "Helena-West Helena",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.52910000",
    longitude: "-90.59000000",
  },
  {
    id: 118271,
    name: "Hempstead County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.73530000",
    longitude: "-93.66844000",
  },
  {
    id: 118392,
    name: "Highland",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.27590000",
    longitude: "-91.52403000",
  },
  {
    id: 118533,
    name: "Holiday Island",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.48535000",
    longitude: "-93.73214000",
  },
  {
    id: 118642,
    name: "Hope",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.66706000",
    longitude: "-93.59157000",
  },
  {
    id: 118662,
    name: "Horatio",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.93845000",
    longitude: "-94.35715000",
  },
  {
    id: 118676,
    name: "Horseshoe Bend",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.22923000",
    longitude: "-91.76431000",
  },
  {
    id: 118681,
    name: "Hot Spring County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.31763000",
    longitude: "-92.94601000",
  },
  {
    id: 118682,
    name: "Hot Springs",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.50370000",
    longitude: "-93.05518000",
  },
  {
    id: 118685,
    name: "Hot Springs National Park",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.53170000",
    longitude: "-93.06377000",
  },
  {
    id: 118686,
    name: "Hot Springs Village",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.67204000",
    longitude: "-92.99879000",
  },
  {
    id: 118712,
    name: "Howard County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.08874000",
    longitude: "-93.99349000",
  },
  {
    id: 118726,
    name: "Hoxie",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.05035000",
    longitude: "-90.97512000",
  },
  {
    id: 118757,
    name: "Hughes",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.94926000",
    longitude: "-90.47149000",
  },
  {
    id: 118815,
    name: "Huntsville",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.08619000",
    longitude: "-93.74130000",
  },
  {
    id: 118898,
    name: "Independence County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.74158000",
    longitude: "-91.56972000",
  },
  {
    id: 119054,
    name: "Izard County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.09487000",
    longitude: "-91.91342000",
  },
  {
    id: 119076,
    name: "Jackson County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.59923000",
    longitude: "-91.21457000",
  },
  {
    id: 119100,
    name: "Jacksonville",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.86620000",
    longitude: "-92.11015000",
  },
  {
    id: 119139,
    name: "Jasper",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.00813000",
    longitude: "-93.18657000",
  },
  {
    id: 119176,
    name: "Jefferson County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.26879000",
    longitude: "-91.93151000",
  },
  {
    id: 119254,
    name: "Johnson",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.13286000",
    longitude: "-94.16548000",
  },
  {
    id: 119260,
    name: "Johnson County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.57005000",
    longitude: "-93.45991000",
  },
  {
    id: 119299,
    name: "Jonesboro",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.84230000",
    longitude: "-90.70428000",
  },
  {
    id: 119328,
    name: "Judsonia",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.27009000",
    longitude: "-91.63986000",
  },
  {
    id: 119479,
    name: "Kensett",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.23175000",
    longitude: "-91.66764000",
  },
  {
    id: 119838,
    name: "Lafayette County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.24098000",
    longitude: "-93.60704000",
  },
  {
    id: 119878,
    name: "Lake City",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.81619000",
    longitude: "-90.43427000",
  },
  {
    id: 119916,
    name: "Lake Hamilton",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.42453000",
    longitude: "-93.09518000",
  },
  {
    id: 119989,
    name: "Lake Village",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.32873000",
    longitude: "-91.28178000",
  },
  {
    id: 120058,
    name: "Lamar",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.44064000",
    longitude: "-93.38796000",
  },
  {
    id: 120101,
    name: "Landmark",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.61120000",
    longitude: "-92.31960000",
  },
  {
    id: 120213,
    name: "Lavaca",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.33620000",
    longitude: "-94.17326000",
  },
  {
    id: 120230,
    name: "Lawrence County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.04125000",
    longitude: "-91.10708000",
  },
  {
    id: 120269,
    name: "Leachville",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.93591000",
    longitude: "-90.25788000",
  },
  {
    id: 120307,
    name: "Lee County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.78062000",
    longitude: "-90.78207000",
  },
  {
    id: 120394,
    name: "Lepanto",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.61119000",
    longitude: "-90.32982000",
  },
  {
    id: 120434,
    name: "Lewisville",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.35846000",
    longitude: "-93.57768000",
  },
  {
    id: 120500,
    name: "Lincoln",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.94953000",
    longitude: "-94.42355000",
  },
  {
    id: 120516,
    name: "Lincoln County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.95747000",
    longitude: "-91.73332000",
  },
  {
    id: 120621,
    name: "Little Flock",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.38591000",
    longitude: "-94.13520000",
  },
  {
    id: 120624,
    name: "Little River County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.70054000",
    longitude: "-94.23434000",
  },
  {
    id: 120626,
    name: "Little Rock",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.74648000",
    longitude: "-92.28959000",
  },
  {
    id: 120628,
    name: "Little Rock Air Force Base",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.89149000",
    longitude: "-92.15955000",
  },
  {
    id: 120696,
    name: "Logan County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.21527000",
    longitude: "-93.71631000",
  },
  {
    id: 120720,
    name: "London",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.32897000",
    longitude: "-93.25296000",
  },
  {
    id: 120761,
    name: "Lonoke",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.78398000",
    longitude: "-91.89986000",
  },
  {
    id: 120762,
    name: "Lonoke County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.75427000",
    longitude: "-91.88867000",
  },
  {
    id: 120833,
    name: "Lowell",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.25535000",
    longitude: "-94.13076000",
  },
  {
    id: 120902,
    name: "Luxora",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.75619000",
    longitude: "-89.92814000",
  },
  {
    id: 121003,
    name: "Madison County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.01096000",
    longitude: "-93.72456000",
  },
  {
    id: 121035,
    name: "Magnolia",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.26707000",
    longitude: "-93.23933000",
  },
  {
    id: 121071,
    name: "Malvern",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.36231000",
    longitude: "-92.81295000",
  },
  {
    id: 121119,
    name: "Manila",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.88007000",
    longitude: "-90.16704000",
  },
  {
    id: 121142,
    name: "Mansfield",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.05954000",
    longitude: "-94.25271000",
  },
  {
    id: 121208,
    name: "Marianna",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.77371000",
    longitude: "-90.75761000",
  },
  {
    id: 121232,
    name: "Marion",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.21453000",
    longitude: "-90.19648000",
  },
  {
    id: 121248,
    name: "Marion County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.26835000",
    longitude: "-92.68422000",
  },
  {
    id: 121267,
    name: "Marked Tree",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.53286000",
    longitude: "-90.42066000",
  },
  {
    id: 121289,
    name: "Marmaduke",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.18701000",
    longitude: "-90.38316000",
  },
  {
    id: 121301,
    name: "Marshall",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.90896000",
    longitude: "-92.63127000",
  },
  {
    id: 121352,
    name: "Marvell",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.55566000",
    longitude: "-90.91289000",
  },
  {
    id: 121421,
    name: "Maumelle",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.86676000",
    longitude: "-92.40432000",
  },
  {
    id: 121438,
    name: "Mayflower",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.95703000",
    longitude: "-92.42738000",
  },
  {
    id: 121464,
    name: "McAlmont",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.80842000",
    longitude: "-92.18181000",
  },
  {
    id: 121487,
    name: "McCrory",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.25620000",
    longitude: "-91.20012000",
  },
  {
    id: 121501,
    name: "McGehee",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.62900000",
    longitude: "-91.39956000",
  },
  {
    id: 121621,
    name: "Melbourne",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.05951000",
    longitude: "-91.90848000",
  },
  {
    id: 121643,
    name: "Mena",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.58622000",
    longitude: "-94.23966000",
  },
  {
    id: 121812,
    name: "Midway",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.38534000",
    longitude: "-92.46183000",
  },
  {
    id: 121880,
    name: "Miller County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.31215000",
    longitude: "-93.89156000",
  },
  {
    id: 121939,
    name: "Mineral Springs",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.87512000",
    longitude: "-93.91380000",
  },
  {
    id: 121989,
    name: "Mississippi County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.76390000",
    longitude: "-90.05417000",
  },
  {
    id: 122040,
    name: "Monette",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.89063000",
    longitude: "-90.34427000",
  },
  {
    id: 122068,
    name: "Monroe County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.67784000",
    longitude: "-91.20389000",
  },
  {
    id: 122140,
    name: "Montgomery County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.53879000",
    longitude: "-93.65953000",
  },
  {
    id: 122158,
    name: "Monticello",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.62900000",
    longitude: "-91.79096000",
  },
  {
    id: 122264,
    name: "Morrilton",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.15092000",
    longitude: "-92.74405000",
  },
  {
    id: 122355,
    name: "Mount Ida",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.55676000",
    longitude: "-93.63408000",
  },
  {
    id: 122415,
    name: "Mountain Home",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.33534000",
    longitude: "-92.38516000",
  },
  {
    id: 122428,
    name: "Mountain View",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.86841000",
    longitude: "-92.11765000",
  },
  {
    id: 122452,
    name: "Mulberry",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.50064000",
    longitude: "-94.05159000",
  },
  {
    id: 122480,
    name: "Murfreesboro",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.06233000",
    longitude: "-93.68990000",
  },
  {
    id: 122572,
    name: "Nashville",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.94567000",
    longitude: "-93.84713000",
  },
  {
    id: 122652,
    name: "Nevada County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.66397000",
    longitude: "-93.30714000",
  },
  {
    id: 122800,
    name: "Newark",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.70174000",
    longitude: "-91.44152000",
  },
  {
    id: 122839,
    name: "Newport",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.60480000",
    longitude: "-91.28180000",
  },
  {
    id: 122868,
    name: "Newton County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.91997000",
    longitude: "-93.21787000",
  },
  {
    id: 123012,
    name: "North Crossett",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.16568000",
    longitude: "-91.94152000",
  },
  {
    id: 123059,
    name: "North Little Rock",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.76954000",
    longitude: "-92.26709000",
  },
  {
    id: 123411,
    name: "Ola",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.03231000",
    longitude: "-93.22323000",
  },
  {
    id: 123597,
    name: "Osceola",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.70508000",
    longitude: "-89.96953000",
  },
  {
    id: 123644,
    name: "Ouachita County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.59336000",
    longitude: "-92.88193000",
  },
  {
    id: 123697,
    name: "Ozark",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.48703000",
    longitude: "-93.82770000",
  },
  {
    id: 123823,
    name: "Paragould",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.05840000",
    longitude: "-90.49733000",
  },
  {
    id: 123829,
    name: "Paris",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.29203000",
    longitude: "-93.72992000",
  },
  {
    id: 123870,
    name: "Parkin",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.26342000",
    longitude: "-90.57122000",
  },
  {
    id: 123951,
    name: "Pea Ridge",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.45396000",
    longitude: "-94.11520000",
  },
  {
    id: 124075,
    name: "Perry County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.94737000",
    longitude: "-92.93147000",
  },
  {
    id: 124088,
    name: "Perryville",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.00481000",
    longitude: "-92.80267000",
  },
  {
    id: 124135,
    name: "Phillips County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.42829000",
    longitude: "-90.84802000",
  },
  {
    id: 124184,
    name: "Piggott",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.38284000",
    longitude: "-90.19065000",
  },
  {
    id: 124186,
    name: "Pike County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.16350000",
    longitude: "-93.65634000",
  },
  {
    id: 124214,
    name: "Pine Bluff",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.22843000",
    longitude: "-92.00320000",
  },
  {
    id: 124266,
    name: "Piney",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.50314000",
    longitude: "-93.12602000",
  },
  {
    id: 124413,
    name: "Pocahontas",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.26146000",
    longitude: "-90.97123000",
  },
  {
    id: 124425,
    name: "Poinsett County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.57404000",
    longitude: "-90.66293000",
  },
  {
    id: 124440,
    name: "Polk County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.48584000",
    longitude: "-94.22807000",
  },
  {
    id: 124481,
    name: "Pope County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.44763000",
    longitude: "-93.03416000",
  },
  {
    id: 124600,
    name: "Pottsville",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.24814000",
    longitude: "-93.04906000",
  },
  {
    id: 124624,
    name: "Prairie County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.82979000",
    longitude: "-91.55277000",
  },
  {
    id: 124626,
    name: "Prairie Creek",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.34202000",
    longitude: "-94.06187000",
  },
  {
    id: 124627,
    name: "Prairie Grove",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.97591000",
    longitude: "-94.31771000",
  },
  {
    id: 124642,
    name: "Prescott",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.80261000",
    longitude: "-93.38101000",
  },
  {
    id: 124737,
    name: "Pulaski County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.76993000",
    longitude: "-92.31180000",
  },
  {
    id: 124873,
    name: "Randolph County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.34148000",
    longitude: "-91.02772000",
  },
  {
    id: 124935,
    name: "Rector",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.26312000",
    longitude: "-90.29260000",
  },
  {
    id: 124967,
    name: "Redfield",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.44510000",
    longitude: "-92.18320000",
  },
  {
    id: 125172,
    name: "Rison",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.95843000",
    longitude: "-92.19015000",
  },
  {
    id: 125328,
    name: "Rockwell",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.46426000",
    longitude: "-93.13379000",
  },
  {
    id: 125348,
    name: "Rogers",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.33202000",
    longitude: "-94.11854000",
  },
  {
    id: 125543,
    name: "Russellville",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.27842000",
    longitude: "-93.13379000",
  },
  {
    id: 125636,
    name: "Saint Francis County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.02200000",
    longitude: "-90.74778000",
  },
  {
    id: 125726,
    name: "Salem",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.62898000",
    longitude: "-92.55822000",
  },
  {
    id: 125749,
    name: "Saline County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.64662000",
    longitude: "-92.67657000",
  },
  {
    id: 126043,
    name: "Scott County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.86077000",
    longitude: "-94.06325000",
  },
  {
    id: 126092,
    name: "Searcy",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.25064000",
    longitude: "-91.73625000",
  },
  {
    id: 126093,
    name: "Searcy County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.91090000",
    longitude: "-92.69949000",
  },
  {
    id: 126107,
    name: "Sebastian County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.19926000",
    longitude: "-94.27391000",
  },
  {
    id: 126180,
    name: "Sevier County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.99720000",
    longitude: "-94.24122000",
  },
  {
    id: 126221,
    name: "Shannon Hills",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.62009000",
    longitude: "-92.39543000",
  },
  {
    id: 126233,
    name: "Sharp County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.16116000",
    longitude: "-91.47986000",
  },
  {
    id: 126317,
    name: "Sheridan",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.30704000",
    longitude: "-92.40127000",
  },
  {
    id: 126340,
    name: "Sherwood",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.81509000",
    longitude: "-92.22432000",
  },
  {
    id: 126417,
    name: "Siloam Springs",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.18814000",
    longitude: "-94.54050000",
  },
  {
    id: 126508,
    name: "Smackover",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.36485000",
    longitude: "-92.72488000",
  },
  {
    id: 126787,
    name: "Southside",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.69841000",
    longitude: "-91.62347000",
  },
  {
    id: 126883,
    name: "Springdale",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.18674000",
    longitude: "-94.12881000",
  },
  {
    id: 126946,
    name: "Stamps",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.36540000",
    longitude: "-93.49518000",
  },
  {
    id: 126979,
    name: "Star City",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.94288000",
    longitude: "-91.84347000",
  },
  {
    id: 127080,
    name: "Stone County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.85987000",
    longitude: "-92.15668000",
  },
  {
    id: 127155,
    name: "Stuttgart",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.50037000",
    longitude: "-91.55263000",
  },
  {
    id: 127196,
    name: "Sulphur Springs",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.18065000",
    longitude: "-92.12348000",
  },
  {
    id: 127544,
    name: "Texarkana",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.44179000",
    longitude: "-94.03769000",
  },
  {
    id: 127723,
    name: "Tontitown",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.17786000",
    longitude: "-94.23354000",
  },
  {
    id: 127856,
    name: "Trumann",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.67369000",
    longitude: "-90.50733000",
  },
  {
    id: 127872,
    name: "Tuckerman",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.73063000",
    longitude: "-91.19846000",
  },
  {
    id: 127988,
    name: "Union County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.17136000",
    longitude: "-92.59729000",
  },
  {
    id: 128126,
    name: "Van Buren",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.43676000",
    longitude: "-94.34827000",
  },
  {
    id: 128129,
    name: "Van Buren County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.58065000",
    longitude: "-92.51570000",
  },
  {
    id: 128261,
    name: "Vilonia",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.08398000",
    longitude: "-92.20793000",
  },
  {
    id: 128370,
    name: "Waldo",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.35151000",
    longitude: "-93.29573000",
  },
  {
    id: 128377,
    name: "Waldron",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.89843000",
    longitude: "-94.09076000",
  },
  {
    id: 128423,
    name: "Walnut Ridge",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.06840000",
    longitude: "-90.95595000",
  },
  {
    id: 128457,
    name: "Ward",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.03036000",
    longitude: "-91.95042000",
  },
  {
    id: 128476,
    name: "Warren",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.61261000",
    longitude: "-92.06458000",
  },
  {
    id: 128553,
    name: "Washington County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.97907000",
    longitude: "-94.21558000",
  },
  {
    id: 128854,
    name: "West Crossett",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "33.14096000",
    longitude: "-91.99402000",
  },
  {
    id: 128872,
    name: "West Fork",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.92425000",
    longitude: "-94.18854000",
  },
  {
    id: 128893,
    name: "West Helena",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.55066000",
    longitude: "-90.64177000",
  },
  {
    id: 128928,
    name: "West Memphis",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.14648000",
    longitude: "-90.18454000",
  },
  {
    id: 129134,
    name: "White County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.25627000",
    longitude: "-91.74555000",
  },
  {
    id: 129139,
    name: "White Hall",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.27399000",
    longitude: "-92.09098000",
  },
  {
    id: 129548,
    name: "Woodruff County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.18633000",
    longitude: "-91.24307000",
  },
  {
    id: 129613,
    name: "Wrightsville",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "34.60232000",
    longitude: "-92.21681000",
  },
  {
    id: 129632,
    name: "Wynne",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.22453000",
    longitude: "-90.78678000",
  },
  {
    id: 129677,
    name: "Yell County",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "35.00260000",
    longitude: "-93.41125000",
  },
  {
    id: 129680,
    name: "Yellville",
    state_id: 1444,
    state_code: "AR",
    country_id: 233,
    country_code: "US",
    latitude: "36.22618000",
    longitude: "-92.68489000",
  },
  {
    id: 110992,
    name: "Acalanes Ridge",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.90472000",
    longitude: "-122.07857000",
  },
  {
    id: 111001,
    name: "Acton",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.46999000",
    longitude: "-118.19674000",
  },
  {
    id: 111043,
    name: "Adelanto",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.58277000",
    longitude: "-117.40922000",
  },
  {
    id: 111056,
    name: "Agoura",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.14306000",
    longitude: "-118.73787000",
  },
  {
    id: 111057,
    name: "Agoura Hills",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.13639000",
    longitude: "-118.77453000",
  },
  {
    id: 111058,
    name: "Agua Dulce",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.49638000",
    longitude: "-118.32563000",
  },
  {
    id: 111061,
    name: "Aguanga",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.44281000",
    longitude: "-116.86502000",
  },
  {
    id: 111064,
    name: "Ahwahnee",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.36550000",
    longitude: "-119.72627000",
  },
  {
    id: 111088,
    name: "Alameda",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.76521000",
    longitude: "-122.24164000",
  },
  {
    id: 111089,
    name: "Alameda County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.65055000",
    longitude: "-121.91789000",
  },
  {
    id: 111093,
    name: "Alamo",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.85020000",
    longitude: "-122.03218000",
  },
  {
    id: 111110,
    name: "Albany",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.88687000",
    longitude: "-122.29775000",
  },
  {
    id: 111169,
    name: "Alhambra",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.09529000",
    longitude: "-118.12701000",
  },
  {
    id: 111175,
    name: "Aliso Viejo",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.56504000",
    longitude: "-117.72712000",
  },
  {
    id: 111196,
    name: "Allendale",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.44463000",
    longitude: "-121.94302000",
  },
  {
    id: 111215,
    name: "Alondra Park",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.88946000",
    longitude: "-118.33091000",
  },
  {
    id: 111217,
    name: "Alpaugh",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.88773000",
    longitude: "-119.48734000",
  },
  {
    id: 111223,
    name: "Alpine",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.83505000",
    longitude: "-116.76641000",
  },
  {
    id: 111226,
    name: "Alpine County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.59725000",
    longitude: "-119.82065000",
  },
  {
    id: 111230,
    name: "Alta Sierra",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.73126000",
    longitude: "-118.55390000",
  },
  {
    id: 111231,
    name: "Altadena",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.18973000",
    longitude: "-118.13118000",
  },
  {
    id: 111251,
    name: "Alturas",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "41.48714000",
    longitude: "-120.54349000",
  },
  {
    id: 111254,
    name: "Alum Rock",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.36605000",
    longitude: "-121.82718000",
  },
  {
    id: 111261,
    name: "Amador County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.44639000",
    longitude: "-120.65112000",
  },
  {
    id: 111271,
    name: "American Canyon",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.17492000",
    longitude: "-122.26080000",
  },
  {
    id: 111280,
    name: "Amesti",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.96356000",
    longitude: "-121.77912000",
  },
  {
    id: 111299,
    name: "Anaheim",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.83529000",
    longitude: "-117.91450000",
  },
  {
    id: 111314,
    name: "Anderson",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.44821000",
    longitude: "-122.29778000",
  },
  {
    id: 111337,
    name: "Angels Camp",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.06826000",
    longitude: "-120.53965000",
  },
  {
    id: 111343,
    name: "Angwin",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.57574000",
    longitude: "-122.44998000",
  },
  {
    id: 111364,
    name: "Antelope",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.70824000",
    longitude: "-121.32995000",
  },
  {
    id: 111373,
    name: "Antioch",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.00492000",
    longitude: "-121.80579000",
  },
  {
    id: 111378,
    name: "Anza",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.55503000",
    longitude: "-116.67363000",
  },
  {
    id: 111394,
    name: "Apple Valley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.50083000",
    longitude: "-117.18588000",
  },
  {
    id: 111403,
    name: "Aptos",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.97717000",
    longitude: "-121.89940000",
  },
  {
    id: 111404,
    name: "Aptos Hills-Larkin Valley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.96064000",
    longitude: "-121.83404000",
  },
  {
    id: 111416,
    name: "Arbuckle",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.01740000",
    longitude: "-122.05775000",
  },
  {
    id: 111425,
    name: "Arcadia",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.13973000",
    longitude: "-118.03534000",
  },
  {
    id: 111426,
    name: "Arcata",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.86652000",
    longitude: "-124.08284000",
  },
  {
    id: 111440,
    name: "Arden-Arcade",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.60250000",
    longitude: "-121.37854000",
  },
  {
    id: 111477,
    name: "Armona",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.31578000",
    longitude: "-119.70846000",
  },
  {
    id: 111488,
    name: "Arnold",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.25547000",
    longitude: "-120.35103000",
  },
  {
    id: 111491,
    name: "Aromas",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.88856000",
    longitude: "-121.64300000",
  },
  {
    id: 111494,
    name: "Arroyo Grande",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.11859000",
    longitude: "-120.59073000",
  },
  {
    id: 111496,
    name: "Artesia",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.86585000",
    longitude: "-118.08312000",
  },
  {
    id: 111505,
    name: "Arvin",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.20913000",
    longitude: "-118.82843000",
  },
  {
    id: 111538,
    name: "Ashland",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.69465000",
    longitude: "-122.11385000",
  },
  {
    id: 111564,
    name: "Atascadero",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.48942000",
    longitude: "-120.67073000",
  },
  {
    id: 111582,
    name: "Atherton",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.46133000",
    longitude: "-122.19774000",
  },
  {
    id: 111613,
    name: "Atwater",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.34772000",
    longitude: "-120.60908000",
  },
  {
    id: 111617,
    name: "Auberry",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.08078000",
    longitude: "-119.48541000",
  },
  {
    id: 111631,
    name: "Auburn",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.89657000",
    longitude: "-121.07689000",
  },
  {
    id: 111635,
    name: "Auburn Lake Trails",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.91434000",
    longitude: "-120.95244000",
  },
  {
    id: 111644,
    name: "August",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.97881000",
    longitude: "-121.26217000",
  },
  {
    id: 111675,
    name: "Avalon",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.34281000",
    longitude: "-118.32785000",
  },
  {
    id: 111676,
    name: "Avenal",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.00412000",
    longitude: "-120.12903000",
  },
  {
    id: 111683,
    name: "Avila Beach",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.17998000",
    longitude: "-120.73184000",
  },
  {
    id: 111689,
    name: "Avocado Heights",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.03612000",
    longitude: "-117.99118000",
  },
  {
    id: 111713,
    name: "Azusa",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.13362000",
    longitude: "-117.90756000",
  },
  {
    id: 111744,
    name: "Bakersfield",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.37329000",
    longitude: "-119.01871000",
  },
  {
    id: 111763,
    name: "Baldwin Park",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.08529000",
    longitude: "-117.96090000",
  },
  {
    id: 111803,
    name: "Banning",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.92557000",
    longitude: "-116.87641000",
  },
  {
    id: 111857,
    name: "Barstow",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.89859000",
    longitude: "-117.02282000",
  },
  {
    id: 111858,
    name: "Barstow Heights",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.86971000",
    longitude: "-117.05615000",
  },
  {
    id: 111932,
    name: "Bay Point",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.02909000",
    longitude: "-121.96163000",
  },
  {
    id: 111957,
    name: "Bayside",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.84235000",
    longitude: "-124.06367000",
  },
  {
    id: 111959,
    name: "Bayview",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.77263000",
    longitude: "-124.18395000",
  },
  {
    id: 111971,
    name: "Beale Air Force Base",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.10917000",
    longitude: "-121.35444000",
  },
  {
    id: 111979,
    name: "Bear Valley Springs",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.15913000",
    longitude: "-118.62842000",
  },
  {
    id: 111990,
    name: "Beaumont",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.92946000",
    longitude: "-116.97725000",
  },
  {
    id: 112066,
    name: "Bell",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.97751000",
    longitude: "-118.18702000",
  },
  {
    id: 112070,
    name: "Bell Gardens",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.96529000",
    longitude: "-118.15146000",
  },
  {
    id: 112072,
    name: "Bella Vista",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.64071000",
    longitude: "-122.23250000",
  },
  {
    id: 112118,
    name: "Bellflower",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.88168000",
    longitude: "-118.11701000",
  },
  {
    id: 112142,
    name: "Belmont",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.52021000",
    longitude: "-122.27580000",
  },
  {
    id: 112153,
    name: "Belvedere",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.87270000",
    longitude: "-122.46442000",
  },
  {
    id: 112163,
    name: "Ben Lomond",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.08911000",
    longitude: "-122.08635000",
  },
  {
    id: 112168,
    name: "Benicia",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.04937000",
    longitude: "-122.15858000",
  },
  {
    id: 112223,
    name: "Berkeley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.87159000",
    longitude: "-122.27275000",
  },
  {
    id: 112240,
    name: "Bermuda Dunes",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.74280000",
    longitude: "-116.28918000",
  },
  {
    id: 112253,
    name: "Berry Creek",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.64516000",
    longitude: "-121.40330000",
  },
  {
    id: 112260,
    name: "Bertsch-Oceanview",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "41.75250000",
    longitude: "-124.15875000",
  },
  {
    id: 112286,
    name: "Bethel Island",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.01492000",
    longitude: "-121.64051000",
  },
  {
    id: 112306,
    name: "Beverly Hills",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.07362000",
    longitude: "-118.40036000",
  },
  {
    id: 112315,
    name: "Big Bear City",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.26112000",
    longitude: "-116.84503000",
  },
  {
    id: 112316,
    name: "Big Bear Lake",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.24390000",
    longitude: "-116.91142000",
  },
  {
    id: 112328,
    name: "Big Pine",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.16493000",
    longitude: "-118.28955000",
  },
  {
    id: 112331,
    name: "Big River",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.14002000",
    longitude: "-114.36134000",
  },
  {
    id: 112340,
    name: "Biggs",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.41239000",
    longitude: "-121.71275000",
  },
  {
    id: 112352,
    name: "Biola",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.80217000",
    longitude: "-120.01627000",
  },
  {
    id: 112364,
    name: "Bishop",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.36354000",
    longitude: "-118.39511000",
  },
  {
    id: 112381,
    name: "Black Point-Green Point",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.11547000",
    longitude: "-122.51318000",
  },
  {
    id: 112387,
    name: "Blackhawk",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.82076000",
    longitude: "-121.90774000",
  },
  {
    id: 112453,
    name: "Bloomington",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.07029000",
    longitude: "-117.39588000",
  },
  {
    id: 112470,
    name: "Blue Lake",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.88291000",
    longitude: "-123.98395000",
  },
  {
    id: 112485,
    name: "Blythe",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.61030000",
    longitude: "-114.59635000",
  },
  {
    id: 112495,
    name: "Bodega Bay",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.33325000",
    longitude: "-123.04806000",
  },
  {
    id: 112496,
    name: "Bodfish",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.58801000",
    longitude: "-118.49203000",
  },
  {
    id: 112514,
    name: "Bolinas",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.90937000",
    longitude: "-122.68637000",
  },
  {
    id: 112529,
    name: "Bonadelle Ranchos-Madera Ranchos",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.98467000",
    longitude: "-119.87463000",
  },
  {
    id: 112537,
    name: "Bonita",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.65783000",
    longitude: "-117.03003000",
  },
  {
    id: 112548,
    name: "Bonny Doon",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.04162000",
    longitude: "-122.15052000",
  },
  {
    id: 112550,
    name: "Bonsall",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.28892000",
    longitude: "-117.22559000",
  },
  {
    id: 112571,
    name: "Boonville",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.00907000",
    longitude: "-123.36612000",
  },
  {
    id: 112578,
    name: "Boron",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.99942000",
    longitude: "-117.64978000",
  },
  {
    id: 112579,
    name: "Boronda",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.69885000",
    longitude: "-121.67495000",
  },
  {
    id: 112581,
    name: "Borrego Springs",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.25587000",
    longitude: "-116.37501000",
  },
  {
    id: 112591,
    name: "Bostonia",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.80755000",
    longitude: "-116.93642000",
  },
  {
    id: 112604,
    name: "Boulder Creek",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.12606000",
    longitude: "-122.12219000",
  },
  {
    id: 112646,
    name: "Boyes Hot Springs",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.31380000",
    longitude: "-122.48193000",
  },
  {
    id: 112649,
    name: "Boyle Heights",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.03390000",
    longitude: "-118.20535000",
  },
  {
    id: 112658,
    name: "Bradbury",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.14695000",
    longitude: "-117.97090000",
  },
  {
    id: 112700,
    name: "Brawley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.97866000",
    longitude: "-115.53027000",
  },
  {
    id: 112707,
    name: "Brea",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.91668000",
    longitude: "-117.90006000",
  },
  {
    id: 112733,
    name: "Brentwood",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.93187000",
    longitude: "-121.69579000",
  },
  {
    id: 112736,
    name: "Bret Harte",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.60207000",
    longitude: "-121.00519000",
  },
  {
    id: 112765,
    name: "Bridgeport",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.25575000",
    longitude: "-119.23127000",
  },
  {
    id: 112800,
    name: "Brisbane",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.68077000",
    longitude: "-122.39997000",
  },
  {
    id: 112825,
    name: "Broadmoor",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.68660000",
    longitude: "-122.48275000",
  },
  {
    id: 112851,
    name: "Brookdale",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.10634000",
    longitude: "-122.10608000",
  },
  {
    id: 112893,
    name: "Brooktrails",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.44377000",
    longitude: "-123.38529000",
  },
  {
    id: 112983,
    name: "Buckhorn",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.45216000",
    longitude: "-120.52854000",
  },
  {
    id: 112997,
    name: "Buellton",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.61360000",
    longitude: "-120.19265000",
  },
  {
    id: 112999,
    name: "Buena Park",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.86751000",
    longitude: "-117.99812000",
  },
  {
    id: 113003,
    name: "Buena Vista",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.32133000",
    longitude: "-121.91662000",
  },
  {
    id: 113047,
    name: "Burbank",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.18084000",
    longitude: "-118.30897000",
  },
  {
    id: 113065,
    name: "Burlingame",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.58410000",
    longitude: "-122.36608000",
  },
  {
    id: 113082,
    name: "Burney",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.88238000",
    longitude: "-121.66082000",
  },
  {
    id: 113125,
    name: "Butte County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.66693000",
    longitude: "-121.60067000",
  },
  {
    id: 113129,
    name: "Buttonwillow",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.40052000",
    longitude: "-119.46956000",
  },
  {
    id: 113146,
    name: "Byron",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.86715000",
    longitude: "-121.63801000",
  },
  {
    id: 113148,
    name: "Bystrom",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.62076000",
    longitude: "-120.98577000",
  },
  {
    id: 113150,
    name: "Cabazon",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.91752000",
    longitude: "-116.78724000",
  },
  {
    id: 113173,
    name: "Calabasas",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.15778000",
    longitude: "-118.63842000",
  },
  {
    id: 113176,
    name: "Calaveras County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.20461000",
    longitude: "-120.55413000",
  },
  {
    id: 113196,
    name: "Calexico",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.67895000",
    longitude: "-115.49888000",
  },
  {
    id: 113218,
    name: "California City",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.12580000",
    longitude: "-117.98590000",
  },
  {
    id: 113219,
    name: "Calimesa",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.00390000",
    longitude: "-117.06198000",
  },
  {
    id: 113220,
    name: "Calipatria",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.12560000",
    longitude: "-115.51415000",
  },
  {
    id: 113221,
    name: "Calistoga",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.57880000",
    longitude: "-122.57971000",
  },
  {
    id: 113226,
    name: "Callender",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.05303000",
    longitude: "-120.59628000",
  },
  {
    id: 113242,
    name: "Camarillo",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.21639000",
    longitude: "-119.03760000",
  },
  {
    id: 113246,
    name: "Cambria",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.56414000",
    longitude: "-121.08075000",
  },
  {
    id: 113249,
    name: "Cambrian Park",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.25689000",
    longitude: "-121.93079000",
  },
  {
    id: 113281,
    name: "Cameron Park",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.66879000",
    longitude: "-120.98716000",
  },
  {
    id: 113285,
    name: "Camino",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.73824000",
    longitude: "-120.67493000",
  },
  {
    id: 113289,
    name: "Camp Meeker",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.42519000",
    longitude: "-122.95944000",
  },
  {
    id: 113290,
    name: "Camp Pendleton North",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.31465000",
    longitude: "-117.31603000",
  },
  {
    id: 113291,
    name: "Camp Pendleton South",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.22844000",
    longitude: "-117.37929000",
  },
  {
    id: 113298,
    name: "Campbell",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.28717000",
    longitude: "-121.94996000",
  },
  {
    id: 113308,
    name: "Campo",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.60645000",
    longitude: "-116.46891000",
  },
  {
    id: 113335,
    name: "Canoga Park",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.20112000",
    longitude: "-118.59814000",
  },
  {
    id: 113359,
    name: "Canyon Lake",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.68502000",
    longitude: "-117.27309000",
  },
  {
    id: 113376,
    name: "Capitola",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.97523000",
    longitude: "-121.95329000",
  },
  {
    id: 113408,
    name: "Carlsbad",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.15809000",
    longitude: "-117.35059000",
  },
  {
    id: 113420,
    name: "Carmel Valley Village",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.50605000",
    longitude: "-121.76594000",
  },
  {
    id: 113421,
    name: "Carmel-by-the-Sea",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.55524000",
    longitude: "-121.92329000",
  },
  {
    id: 113423,
    name: "Carmichael",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.61713000",
    longitude: "-121.32828000",
  },
  {
    id: 113440,
    name: "Carpinteria",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.39888000",
    longitude: "-119.51846000",
  },
  {
    id: 113474,
    name: "Carson",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.83141000",
    longitude: "-118.28202000",
  },
  {
    id: 113498,
    name: "Caruthers",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.54273000",
    longitude: "-119.83320000",
  },
  {
    id: 113509,
    name: "Casa Conejo",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.18362000",
    longitude: "-118.94343000",
  },
  {
    id: 113511,
    name: "Casa de Oro-Mount Helix",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.76397000",
    longitude: "-116.96877000",
  },
  {
    id: 113540,
    name: "Castaic",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.48888000",
    longitude: "-118.62287000",
  },
  {
    id: 113557,
    name: "Castro Valley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.69410000",
    longitude: "-122.08635000",
  },
  {
    id: 113559,
    name: "Castroville",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.76579000",
    longitude: "-121.75800000",
  },
  {
    id: 113570,
    name: "Cathedral City",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.77974000",
    longitude: "-116.46529000",
  },
  {
    id: 113592,
    name: "Cayucos",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.44275000",
    longitude: "-120.89213000",
  },
  {
    id: 113622,
    name: "Cedar Ridge",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.06576000",
    longitude: "-120.27686000",
  },
  {
    id: 113683,
    name: "Central Valley (historical)",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.68043000",
    longitude: "-122.37112000",
  },
  {
    id: 113698,
    name: "Century City",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.05557000",
    longitude: "-118.41786000",
  },
  {
    id: 113700,
    name: "Ceres",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.59493000",
    longitude: "-120.95771000",
  },
  {
    id: 113701,
    name: "Cerritos",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.85835000",
    longitude: "-118.06479000",
  },
  {
    id: 113714,
    name: "Challenge-Brownsville",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.46447000",
    longitude: "-121.26338000",
  },
  {
    id: 113734,
    name: "Channel Islands Beach",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.15806000",
    longitude: "-119.22316000",
  },
  {
    id: 113790,
    name: "Charter Oak",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.10306000",
    longitude: "-117.84589000",
  },
  {
    id: 113807,
    name: "Chatsworth",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.25723000",
    longitude: "-118.60120000",
  },
  {
    id: 113865,
    name: "Cherry Valley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.97252000",
    longitude: "-116.97725000",
  },
  {
    id: 113867,
    name: "Cherryland",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.67938000",
    longitude: "-122.10330000",
  },
  {
    id: 113892,
    name: "Chester",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.30627000",
    longitude: "-121.23191000",
  },
  {
    id: 113943,
    name: "Chico",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.72849000",
    longitude: "-121.83748000",
  },
  {
    id: 113963,
    name: "China Lake Acres",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.64051000",
    longitude: "-117.76395000",
  },
  {
    id: 113964,
    name: "Chinatown",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.79660000",
    longitude: "-122.40858000",
  },
  {
    id: 113968,
    name: "Chino",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.01223000",
    longitude: "-117.68894000",
  },
  {
    id: 113969,
    name: "Chino Hills",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.99380000",
    longitude: "-117.75888000",
  },
  {
    id: 113993,
    name: "Chowchilla",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.12300000",
    longitude: "-120.26018000",
  },
  {
    id: 114003,
    name: "Chualar",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.57052000",
    longitude: "-121.51855000",
  },
  {
    id: 114005,
    name: "Chula Vista",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.64005000",
    longitude: "-117.08420000",
  },
  {
    id: 114034,
    name: "Citrus",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.11501000",
    longitude: "-117.89173000",
  },
  {
    id: 114037,
    name: "Citrus Heights",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.70712000",
    longitude: "-121.28106000",
  },
  {
    id: 114046,
    name: "City and County of San Francisco",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.77823000",
    longitude: "-122.44250000",
  },
  {
    id: 114106,
    name: "Claremont",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.09668000",
    longitude: "-117.71978000",
  },
  {
    id: 114169,
    name: "Clay",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.33602000",
    longitude: "-121.15939000",
  },
  {
    id: 114203,
    name: "Clayton",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.94103000",
    longitude: "-121.93579000",
  },
  {
    id: 114213,
    name: "Clear Lake Riviera",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.95406000",
    longitude: "-122.72082000",
  },
  {
    id: 114219,
    name: "Clearlake",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.95823000",
    longitude: "-122.62637000",
  },
  {
    id: 114220,
    name: "Clearlake Oaks",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.02628000",
    longitude: "-122.67193000",
  },
  {
    id: 114311,
    name: "Cloverdale",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.80546000",
    longitude: "-123.01722000",
  },
  {
    id: 114315,
    name: "Clovis",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.82523000",
    longitude: "-119.70292000",
  },
  {
    id: 114324,
    name: "Coachella",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.68030000",
    longitude: "-116.17389000",
  },
  {
    id: 114338,
    name: "Coalinga",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.13968000",
    longitude: "-120.36015000",
  },
  {
    id: 114340,
    name: "Coarsegold",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.26217000",
    longitude: "-119.70098000",
  },
  {
    id: 114343,
    name: "Cobb",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.82213000",
    longitude: "-122.72305000",
  },
  {
    id: 114408,
    name: "Colfax",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.10073000",
    longitude: "-120.95328000",
  },
  {
    id: 114425,
    name: "Collierville",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.21464000",
    longitude: "-121.26884000",
  },
  {
    id: 114440,
    name: "Colma",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.67688000",
    longitude: "-122.45969000",
  },
  {
    id: 114463,
    name: "Colton",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.07390000",
    longitude: "-117.31365000",
  },
  {
    id: 114474,
    name: "Columbia",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.03631000",
    longitude: "-120.40131000",
  },
  {
    id: 114504,
    name: "Colusa",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.21433000",
    longitude: "-122.00942000",
  },
  {
    id: 114505,
    name: "Colusa County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.17757000",
    longitude: "-122.23703000",
  },
  {
    id: 114525,
    name: "Commerce",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.00057000",
    longitude: "-118.15979000",
  },
  {
    id: 114529,
    name: "Compton",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.89585000",
    longitude: "-118.22007000",
  },
  {
    id: 114543,
    name: "Concord",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.97798000",
    longitude: "-122.03107000",
  },
  {
    id: 114575,
    name: "Contra Costa Centre",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.92752000",
    longitude: "-122.05786000",
  },
  {
    id: 114576,
    name: "Contra Costa County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.92342000",
    longitude: "-121.95121000",
  },
  {
    id: 114596,
    name: "Cool",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.88722000",
    longitude: "-121.01472000",
  },
  {
    id: 114622,
    name: "Copperopolis",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.98104000",
    longitude: "-120.64187000",
  },
  {
    id: 114633,
    name: "Corcoran",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.09801000",
    longitude: "-119.56040000",
  },
  {
    id: 114654,
    name: "Corning",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.92766000",
    longitude: "-122.17916000",
  },
  {
    id: 114662,
    name: "Corona",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.87529000",
    longitude: "-117.56644000",
  },
  {
    id: 114664,
    name: "Coronado",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.68589000",
    longitude: "-117.18309000",
  },
  {
    id: 114667,
    name: "Corralitos",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.98856000",
    longitude: "-121.80634000",
  },
  {
    id: 114672,
    name: "Corte Madera",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.92548000",
    longitude: "-122.52748000",
  },
  {
    id: 114686,
    name: "Costa Mesa",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.64113000",
    longitude: "-117.91867000",
  },
  {
    id: 114688,
    name: "Cotati",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.32686000",
    longitude: "-122.70721000",
  },
  {
    id: 114689,
    name: "Coto De Caza",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.60419000",
    longitude: "-117.58699000",
  },
  {
    id: 114702,
    name: "Cottonwood",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.38571000",
    longitude: "-122.28084000",
  },
  {
    id: 114715,
    name: "Country Club",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.96881000",
    longitude: "-121.34078000",
  },
  {
    id: 114731,
    name: "Covelo",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.79327000",
    longitude: "-123.24922000",
  },
  {
    id: 114734,
    name: "Covina",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.09001000",
    longitude: "-117.89034000",
  },
  {
    id: 114796,
    name: "Crescent City",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "41.75595000",
    longitude: "-124.20175000",
  },
  {
    id: 114802,
    name: "Crest",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.80727000",
    longitude: "-116.86808000",
  },
  {
    id: 114805,
    name: "Crestline",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.24195000",
    longitude: "-117.28560000",
  },
  {
    id: 114830,
    name: "Crockett",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.05242000",
    longitude: "-122.21302000",
  },
  {
    id: 114890,
    name: "Cudahy",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.96057000",
    longitude: "-118.18535000",
  },
  {
    id: 114904,
    name: "Culver City",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.02112000",
    longitude: "-118.39647000",
  },
  {
    id: 114924,
    name: "Cupertino",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.32300000",
    longitude: "-122.03218000",
  },
  {
    id: 114947,
    name: "Cutler",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.52328000",
    longitude: "-119.28678000",
  },
  {
    id: 114951,
    name: "Cutten",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.76985000",
    longitude: "-124.14284000",
  },
  {
    id: 114954,
    name: "Cypress",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.81696000",
    longitude: "-118.03729000",
  },
  {
    id: 115006,
    name: "Daly City",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.70577000",
    longitude: "-122.46192000",
  },
  {
    id: 115012,
    name: "Dana Point",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.46697000",
    longitude: "-117.69811000",
  },
  {
    id: 115038,
    name: "Danville",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.82159000",
    longitude: "-121.99996000",
  },
  {
    id: 115073,
    name: "Davis",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.54491000",
    longitude: "-121.74052000",
  },
  {
    id: 115090,
    name: "Day Valley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.03578000",
    longitude: "-121.86246000",
  },
  {
    id: 115178,
    name: "Deer Park",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.68185000",
    longitude: "-120.82327000",
  },
  {
    id: 115186,
    name: "Del Aire",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.91613000",
    longitude: "-118.36952000",
  },
  {
    id: 115188,
    name: "Del Mar",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.95949000",
    longitude: "-117.26531000",
  },
  {
    id: 115189,
    name: "Del Monte Forest",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.58635000",
    longitude: "-121.94746000",
  },
  {
    id: 115191,
    name: "Del Norte County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "41.74496000",
    longitude: "-123.95781000",
  },
  {
    id: 115192,
    name: "Del Rey",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.65912000",
    longitude: "-119.59374000",
  },
  {
    id: 115193,
    name: "Del Rey Oaks",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.59329000",
    longitude: "-121.83495000",
  },
  {
    id: 115194,
    name: "Del Rio",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.74354000",
    longitude: "-121.01188000",
  },
  {
    id: 115199,
    name: "Delano",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.76884000",
    longitude: "-119.24705000",
  },
  {
    id: 115213,
    name: "Delhi",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.43216000",
    longitude: "-120.77854000",
  },
  {
    id: 115234,
    name: "Denair",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.52632000",
    longitude: "-120.79687000",
  },
  {
    id: 115276,
    name: "Descanso",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.85283000",
    longitude: "-116.61585000",
  },
  {
    id: 115280,
    name: "Desert Edge",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.92417000",
    longitude: "-116.44139000",
  },
  {
    id: 115282,
    name: "Desert Hot Springs",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.96173000",
    longitude: "-116.50353000",
  },
  {
    id: 115283,
    name: "Desert Shores",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.40420000",
    longitude: "-116.03972000",
  },
  {
    id: 115284,
    name: "Desert View Highlands",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.59082000",
    longitude: "-118.15257000",
  },
  {
    id: 115312,
    name: "Diablo",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.83493000",
    longitude: "-121.95801000",
  },
  {
    id: 115314,
    name: "Diamond Bar",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.02862000",
    longitude: "-117.81034000",
  },
  {
    id: 115316,
    name: "Diamond Springs",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.69463000",
    longitude: "-120.81494000",
  },
  {
    id: 115351,
    name: "Dinuba",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.54328000",
    longitude: "-119.38707000",
  },
  {
    id: 115353,
    name: "Discovery Bay",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.90854000",
    longitude: "-121.60023000",
  },
  {
    id: 115367,
    name: "Dixon",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.44546000",
    longitude: "-121.82330000",
  },
  {
    id: 115369,
    name: "Dixon Lane-Meadow Creek",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.38639000",
    longitude: "-118.41527000",
  },
  {
    id: 115385,
    name: "Dogtown",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.21381000",
    longitude: "-121.08855000",
  },
  {
    id: 115390,
    name: "Dollar Point",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.18796000",
    longitude: "-120.09991000",
  },
  {
    id: 115414,
    name: "Dos Palos",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.98606000",
    longitude: "-120.62657000",
  },
  {
    id: 115460,
    name: "Downey",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.94001000",
    longitude: "-118.13257000",
  },
  {
    id: 115461,
    name: "Downieville",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.55934000",
    longitude: "-120.82689000",
  },
  {
    id: 115490,
    name: "Duarte",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.13945000",
    longitude: "-117.97729000",
  },
  {
    id: 115496,
    name: "Dublin",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.70215000",
    longitude: "-121.93579000",
  },
  {
    id: 115551,
    name: "Dunnigan",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.88518000",
    longitude: "-121.96969000",
  },
  {
    id: 115553,
    name: "Dunsmuir",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "41.20821000",
    longitude: "-122.27195000",
  },
  {
    id: 115573,
    name: "Durham",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.64627000",
    longitude: "-121.79998000",
  },
  {
    id: 115618,
    name: "Earlimart",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.88412000",
    longitude: "-119.27233000",
  },
  {
    id: 115663,
    name: "East Foothills",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.38105000",
    longitude: "-121.81745000",
  },
  {
    id: 115689,
    name: "East Hemet",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.74002000",
    longitude: "-116.93891000",
  },
  {
    id: 115699,
    name: "East La Mirada",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.92446000",
    longitude: "-117.98895000",
  },
  {
    id: 115706,
    name: "East Los Angeles",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.02390000",
    longitude: "-118.17202000",
  },
  {
    id: 115725,
    name: "East Oakdale",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.78798000",
    longitude: "-120.80382000",
  },
  {
    id: 115728,
    name: "East Palo Alto",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.46883000",
    longitude: "-122.14108000",
  },
  {
    id: 115729,
    name: "East Pasadena",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.13814000",
    longitude: "-118.07384000",
  },
  {
    id: 115739,
    name: "East Porterville",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.05745000",
    longitude: "-118.97566000",
  },
  {
    id: 115742,
    name: "East Quincy",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.93406000",
    longitude: "-120.89801000",
  },
  {
    id: 115744,
    name: "East Rancho Dominguez",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.89807000",
    longitude: "-118.19535000",
  },
  {
    id: 115746,
    name: "East Richmond Heights",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.94492000",
    longitude: "-122.31358000",
  },
  {
    id: 115755,
    name: "East San Gabriel",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.09168000",
    longitude: "-118.09118000",
  },
  {
    id: 115759,
    name: "East Sonora",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.97770000",
    longitude: "-120.36130000",
  },
  {
    id: 115791,
    name: "Easton",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.65023000",
    longitude: "-119.79070000",
  },
  {
    id: 115797,
    name: "Eastvale",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.96358000",
    longitude: "-117.56418000",
  },
  {
    id: 115814,
    name: "Echo Park",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.07808000",
    longitude: "-118.26066000",
  },
  {
    id: 115888,
    name: "Edwards Air Force Base",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.91637000",
    longitude: "-117.93535000",
  },
  {
    id: 115911,
    name: "El Cajon",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.79477000",
    longitude: "-116.96253000",
  },
  {
    id: 115914,
    name: "El Centro",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.79200000",
    longitude: "-115.56305000",
  },
  {
    id: 115915,
    name: "El Cerrito",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.91576000",
    longitude: "-122.31164000",
  },
  {
    id: 115916,
    name: "El Cerrito Corona",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.84057000",
    longitude: "-117.52283000",
  },
  {
    id: 115921,
    name: "El Dorado County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.77874000",
    longitude: "-120.52465000",
  },
  {
    id: 115922,
    name: "El Dorado Hills",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.68574000",
    longitude: "-121.08217000",
  },
  {
    id: 115924,
    name: "El Granada",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.50272000",
    longitude: "-122.46942000",
  },
  {
    id: 115928,
    name: "El Monte",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.06862000",
    longitude: "-118.02757000",
  },
  {
    id: 115936,
    name: "El Rio",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.23578000",
    longitude: "-119.16383000",
  },
  {
    id: 115937,
    name: "El Segundo",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.91918000",
    longitude: "-118.41647000",
  },
  {
    id: 115938,
    name: "El Sobrante",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.97715000",
    longitude: "-122.29525000",
  },
  {
    id: 115940,
    name: "El Verano",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.29769000",
    longitude: "-122.49165000",
  },
  {
    id: 115957,
    name: "Eldridge",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.34880000",
    longitude: "-122.51081000",
  },
  {
    id: 115987,
    name: "Elk Grove",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.40880000",
    longitude: "-121.37162000",
  },
  {
    id: 116003,
    name: "Elkhorn",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.82440000",
    longitude: "-121.74050000",
  },
  {
    id: 116075,
    name: "Elverta",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.71379000",
    longitude: "-121.46273000",
  },
  {
    id: 116090,
    name: "Emerald Lake Hills",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.46466000",
    longitude: "-122.26802000",
  },
  {
    id: 116097,
    name: "Emeryville",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.83132000",
    longitude: "-122.28525000",
  },
  {
    id: 116110,
    name: "Empire",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.63826000",
    longitude: "-120.90215000",
  },
  {
    id: 116118,
    name: "Encinitas",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.03699000",
    longitude: "-117.29198000",
  },
  {
    id: 116119,
    name: "Encino",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.15917000",
    longitude: "-118.50119000",
  },
  {
    id: 116178,
    name: "Escalon",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.79781000",
    longitude: "-120.99792000",
  },
  {
    id: 116184,
    name: "Escondido",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.11921000",
    longitude: "-117.08642000",
  },
  {
    id: 116187,
    name: "Esparto",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.69213000",
    longitude: "-122.01719000",
  },
  {
    id: 116221,
    name: "Eucalyptus Hills",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.87977000",
    longitude: "-116.94669000",
  },
  {
    id: 116235,
    name: "Eureka",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.80207000",
    longitude: "-124.16367000",
  },
  {
    id: 116274,
    name: "Exeter",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.29606000",
    longitude: "-119.14205000",
  },
  {
    id: 116286,
    name: "Fair Oaks",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.64463000",
    longitude: "-121.27217000",
  },
  {
    id: 116292,
    name: "Fairbanks Ranch",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.99393000",
    longitude: "-117.18726000",
  },
  {
    id: 116306,
    name: "Fairfax",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.98715000",
    longitude: "-122.58887000",
  },
  {
    id: 116316,
    name: "Fairfield",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.24936000",
    longitude: "-122.03997000",
  },
  {
    id: 116333,
    name: "Fairmead",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.07633000",
    longitude: "-120.19295000",
  },
  {
    id: 116355,
    name: "Fairview",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.67854000",
    longitude: "-122.04580000",
  },
  {
    id: 116376,
    name: "Fallbrook",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.37642000",
    longitude: "-117.25115000",
  },
  {
    id: 116407,
    name: "Farmersville",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.29773000",
    longitude: "-119.20678000",
  },
  {
    id: 116471,
    name: "Felton",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.05134000",
    longitude: "-122.07330000",
  },
  {
    id: 116489,
    name: "Ferndale",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.57624000",
    longitude: "-124.26394000",
  },
  {
    id: 116503,
    name: "Fetters Hot Springs-Agua Caliente",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.32140000",
    longitude: "-122.48682000",
  },
  {
    id: 116508,
    name: "Fillmore",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.39916000",
    longitude: "-118.91815000",
  },
  {
    id: 116520,
    name: "Firebaugh",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.85884000",
    longitude: "-120.45601000",
  },
  {
    id: 116589,
    name: "Florence-Graham",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.96772000",
    longitude: "-118.24438000",
  },
  {
    id: 116595,
    name: "Florin",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.49602000",
    longitude: "-121.40884000",
  },
  {
    id: 116626,
    name: "Folsom",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.67796000",
    longitude: "-121.17606000",
  },
  {
    id: 116631,
    name: "Fontana",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.09223000",
    longitude: "-117.43505000",
  },
  {
    id: 116632,
    name: "Foothill Farms",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.67877000",
    longitude: "-121.35114000",
  },
  {
    id: 116633,
    name: "Foothill Ranch",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.68641000",
    longitude: "-117.66088000",
  },
  {
    id: 116635,
    name: "Ford City",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.15441000",
    longitude: "-119.45623000",
  },
  {
    id: 116662,
    name: "Forest Meadows",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.16851000",
    longitude: "-120.40659000",
  },
  {
    id: 116667,
    name: "Forest Ranch",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.88211000",
    longitude: "-121.67275000",
  },
  {
    id: 116671,
    name: "Foresthill",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.02018000",
    longitude: "-120.81799000",
  },
  {
    id: 116673,
    name: "Forestville",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.47352000",
    longitude: "-122.89027000",
  },
  {
    id: 116695,
    name: "Fort Bragg",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.44572000",
    longitude: "-123.80529000",
  },
  {
    id: 116719,
    name: "Fort Irwin",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.26275000",
    longitude: "-116.68475000",
  },
  {
    id: 116767,
    name: "Fortuna",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.59819000",
    longitude: "-124.15728000",
  },
  {
    id: 116776,
    name: "Foster City",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.55855000",
    longitude: "-122.27108000",
  },
  {
    id: 116784,
    name: "Fountain Valley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.70918000",
    longitude: "-117.95367000",
  },
  {
    id: 116797,
    name: "Fowler",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.63051000",
    longitude: "-119.67847000",
  },
  {
    id: 116883,
    name: "Frazier Park",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.82276000",
    longitude: "-118.94482000",
  },
  {
    id: 116903,
    name: "Freedom",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.93523000",
    longitude: "-121.77301000",
  },
  {
    id: 116924,
    name: "Fremont",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.54827000",
    longitude: "-121.98857000",
  },
  {
    id: 116929,
    name: "French Camp",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.88409000",
    longitude: "-121.27106000",
  },
  {
    id: 116939,
    name: "Fresno",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.74773000",
    longitude: "-119.77237000",
  },
  {
    id: 116940,
    name: "Fresno County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.75818000",
    longitude: "-119.64932000",
  },
  {
    id: 116975,
    name: "Fruitridge Pocket",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.53265000",
    longitude: "-121.45581000",
  },
  {
    id: 116983,
    name: "Fullerton",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.87029000",
    longitude: "-117.92534000",
  },
  {
    id: 117040,
    name: "Galt",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.25464000",
    longitude: "-121.29995000",
  },
  {
    id: 117053,
    name: "Garden Acres",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.96381000",
    longitude: "-121.22939000",
  },
  {
    id: 117065,
    name: "Garden Grove",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.77391000",
    longitude: "-117.94145000",
  },
  {
    id: 117069,
    name: "Gardena",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.88835000",
    longitude: "-118.30896000",
  },
  {
    id: 117094,
    name: "Garnet",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.90196000",
    longitude: "-116.54557000",
  },
  {
    id: 117165,
    name: "Georgetown",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.90684000",
    longitude: "-120.83855000",
  },
  {
    id: 117169,
    name: "Gerber",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.05627000",
    longitude: "-122.15027000",
  },
  {
    id: 117225,
    name: "Gilroy",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.00578000",
    longitude: "-121.56828000",
  },
  {
    id: 117261,
    name: "Glen Avon",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.01168000",
    longitude: "-117.48477000",
  },
  {
    id: 117286,
    name: "Glendale",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.14251000",
    longitude: "-118.25508000",
  },
  {
    id: 117291,
    name: "Glendora",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.13612000",
    longitude: "-117.86534000",
  },
  {
    id: 117296,
    name: "Glenn County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.59840000",
    longitude: "-122.39221000",
  },
  {
    id: 117345,
    name: "Gold River",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.62629000",
    longitude: "-121.24662000",
  },
  {
    id: 117351,
    name: "Golden Hills",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.14247000",
    longitude: "-118.49036000",
  },
  {
    id: 117367,
    name: "Goleta",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.43583000",
    longitude: "-119.82764000",
  },
  {
    id: 117372,
    name: "Gonzales",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.50663000",
    longitude: "-121.44438000",
  },
  {
    id: 117378,
    name: "Good Hope",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.76474000",
    longitude: "-117.26698000",
  },
  {
    id: 117410,
    name: "Goshen",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.35106000",
    longitude: "-119.42012000",
  },
  {
    id: 117476,
    name: "Grand Terrace",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.03390000",
    longitude: "-117.31365000",
  },
  {
    id: 117493,
    name: "Granite Bay",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.76323000",
    longitude: "-121.16384000",
  },
  {
    id: 117499,
    name: "Granite Hills",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.80311000",
    longitude: "-116.90475000",
  },
  {
    id: 117543,
    name: "Grass Valley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.21906000",
    longitude: "-121.06106000",
  },
  {
    id: 117545,
    name: "Graton",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.43630000",
    longitude: "-122.86972000",
  },
  {
    id: 117590,
    name: "Green Acres",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.73808000",
    longitude: "-117.07642000",
  },
  {
    id: 117611,
    name: "Green Valley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.25297000",
    longitude: "-122.16219000",
  },
  {
    id: 117613,
    name: "Greenacres",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.38329000",
    longitude: "-119.10983000",
  },
  {
    id: 117654,
    name: "Greenfield",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.32080000",
    longitude: "-121.24381000",
  },
  {
    id: 117696,
    name: "Greenville",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.13961000",
    longitude: "-120.95107000",
  },
  {
    id: 117730,
    name: "Gridley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.36378000",
    longitude: "-121.69358000",
  },
  {
    id: 117760,
    name: "Grover Beach",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.12164000",
    longitude: "-120.62128000",
  },
  {
    id: 117777,
    name: "Guadalupe",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.97164000",
    longitude: "-120.57184000",
  },
  {
    id: 117780,
    name: "Guerneville",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.50186000",
    longitude: "-122.99611000",
  },
  {
    id: 117810,
    name: "Gustine",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.25772000",
    longitude: "-120.99882000",
  },
  {
    id: 117825,
    name: "Hacienda Heights",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.99307000",
    longitude: "-117.96868000",
  },
  {
    id: 117856,
    name: "Half Moon Bay",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.46355000",
    longitude: "-122.42859000",
  },
  {
    id: 117895,
    name: "Hamilton City",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.74266000",
    longitude: "-122.01359000",
  },
  {
    id: 117960,
    name: "Hanford",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.32745000",
    longitude: "-119.64568000",
  },
  {
    id: 117982,
    name: "Happy Camp",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "41.79275000",
    longitude: "-123.38080000",
  },
  {
    id: 117986,
    name: "Harbison Canyon",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.82033000",
    longitude: "-116.83002000",
  },
  {
    id: 118099,
    name: "Hartley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.41713000",
    longitude: "-121.94691000",
  },
  {
    id: 118160,
    name: "Hawaiian Gardens",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.83140000",
    longitude: "-118.07284000",
  },
  {
    id: 118175,
    name: "Hawthorne",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.91640000",
    longitude: "-118.35257000",
  },
  {
    id: 118186,
    name: "Hayfork",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.55431000",
    longitude: "-123.18308000",
  },
  {
    id: 118197,
    name: "Hayward",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.66882000",
    longitude: "-122.08080000",
  },
  {
    id: 118217,
    name: "Healdsburg",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.61047000",
    longitude: "-122.86916000",
  },
  {
    id: 118228,
    name: "Heber",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.73089000",
    longitude: "-115.52972000",
  },
  {
    id: 118264,
    name: "Hemet",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.74761000",
    longitude: "-116.97307000",
  },
  {
    id: 118311,
    name: "Herald",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.29575000",
    longitude: "-121.24439000",
  },
  {
    id: 118314,
    name: "Hercules",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.01714000",
    longitude: "-122.28858000",
  },
  {
    id: 118331,
    name: "Hermosa Beach",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.86224000",
    longitude: "-118.39952000",
  },
  {
    id: 118344,
    name: "Hesperia",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.42639000",
    longitude: "-117.30088000",
  },
  {
    id: 118376,
    name: "Hidden Hills",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.16028000",
    longitude: "-118.65231000",
  },
  {
    id: 118377,
    name: "Hidden Meadows",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.22531000",
    longitude: "-117.11253000",
  },
  {
    id: 118380,
    name: "Hidden Valley Lake",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.80796000",
    longitude: "-122.55832000",
  },
  {
    id: 118391,
    name: "Highgrove",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.01585000",
    longitude: "-117.33338000",
  },
  {
    id: 118398,
    name: "Highland",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.12834000",
    longitude: "-117.20865000",
  },
  {
    id: 118421,
    name: "Highlands-Baywood Park",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.52272000",
    longitude: "-122.34506000",
  },
  {
    id: 118455,
    name: "Hillsborough",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.57410000",
    longitude: "-122.37942000",
  },
  {
    id: 118471,
    name: "Hilmar-Irwin",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.40454000",
    longitude: "-120.85042000",
  },
  {
    id: 118552,
    name: "Hollister",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.85245000",
    longitude: "-121.40160000",
  },
  {
    id: 118567,
    name: "Hollywood",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.09834000",
    longitude: "-118.32674000",
  },
  {
    id: 118582,
    name: "Holtville",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.81116000",
    longitude: "-115.38026000",
  },
  {
    id: 118586,
    name: "Home Garden",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.30328000",
    longitude: "-119.63624000",
  },
  {
    id: 118587,
    name: "Home Gardens",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.87807000",
    longitude: "-117.52088000",
  },
  {
    id: 118590,
    name: "Homeland",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.74308000",
    longitude: "-117.10920000",
  },
  {
    id: 118764,
    name: "Hughson",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.59688000",
    longitude: "-120.86604000",
  },
  {
    id: 118779,
    name: "Humboldt County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.70501000",
    longitude: "-123.91582000",
  },
  {
    id: 118781,
    name: "Humboldt Hill",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.72596000",
    longitude: "-124.18978000",
  },
  {
    id: 118805,
    name: "Huntington Beach",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.66030000",
    longitude: "-117.99923000",
  },
  {
    id: 118807,
    name: "Huntington Park",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.98168000",
    longitude: "-118.22507000",
  },
  {
    id: 118825,
    name: "Huron",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.20273000",
    longitude: "-120.10292000",
  },
  {
    id: 118855,
    name: "Hydesville",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.54763000",
    longitude: "-124.09727000",
  },
  {
    id: 118874,
    name: "Idyllwild",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.74002000",
    longitude: "-116.71891000",
  },
  {
    id: 118875,
    name: "Idyllwild-Pine Cove",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.74429000",
    longitude: "-116.72587000",
  },
  {
    id: 118883,
    name: "Imperial",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.84755000",
    longitude: "-115.56944000",
  },
  {
    id: 118885,
    name: "Imperial Beach",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.58394000",
    longitude: "-117.11308000",
  },
  {
    id: 118886,
    name: "Imperial County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.03951000",
    longitude: "-115.36532000",
  },
  {
    id: 118921,
    name: "Indian Wells",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.71791000",
    longitude: "-116.34311000",
  },
  {
    id: 118929,
    name: "Indio",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.72070000",
    longitude: "-116.21677000",
  },
  {
    id: 118937,
    name: "Inglewood",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.96168000",
    longitude: "-118.35313000",
  },
  {
    id: 118951,
    name: "Interlaken",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.95134000",
    longitude: "-121.73384000",
  },
  {
    id: 118957,
    name: "Inverness",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.10103000",
    longitude: "-122.85694000",
  },
  {
    id: 118964,
    name: "Inyo County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.51113000",
    longitude: "-117.41079000",
  },
  {
    id: 118965,
    name: "Inyokern",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.64690000",
    longitude: "-117.81257000",
  },
  {
    id: 118970,
    name: "Ione",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.35269000",
    longitude: "-120.93272000",
  },
  {
    id: 119004,
    name: "Irvine",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.66946000",
    longitude: "-117.82311000",
  },
  {
    id: 119014,
    name: "Irwindale",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.10695000",
    longitude: "-117.93534000",
  },
  {
    id: 119021,
    name: "Isla Vista",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.41333000",
    longitude: "-119.86097000",
  },
  {
    id: 119049,
    name: "Ivanhoe",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.38717000",
    longitude: "-119.21789000",
  },
  {
    id: 119073,
    name: "Jackson",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.34880000",
    longitude: "-120.77410000",
  },
  {
    id: 119126,
    name: "Jamestown",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.95326000",
    longitude: "-120.42270000",
  },
  {
    id: 119128,
    name: "Jamul",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.71700000",
    longitude: "-116.87613000",
  },
  {
    id: 119132,
    name: "Janesville",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.29656000",
    longitude: "-120.52411000",
  },
  {
    id: 119283,
    name: "Johnstonville",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.38434000",
    longitude: "-120.58745000",
  },
  {
    id: 119323,
    name: "Joshua Tree",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.13473000",
    longitude: "-116.31307000",
  },
  {
    id: 119330,
    name: "Julian",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.07866000",
    longitude: "-116.60196000",
  },
  {
    id: 119343,
    name: "Jurupa Valley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.99251000",
    longitude: "-117.51644000",
  },
  {
    id: 119431,
    name: "Kelseyville",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.97795000",
    longitude: "-122.83944000",
  },
  {
    id: 119469,
    name: "Kennedy",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.92992000",
    longitude: "-121.25272000",
  },
  {
    id: 119484,
    name: "Kensington",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.91048000",
    longitude: "-122.28025000",
  },
  {
    id: 119495,
    name: "Kentfield",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.95215000",
    longitude: "-122.55720000",
  },
  {
    id: 119505,
    name: "Kenwood",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.41380000",
    longitude: "-122.54609000",
  },
  {
    id: 119512,
    name: "Kerman",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.72356000",
    longitude: "-120.05988000",
  },
  {
    id: 119514,
    name: "Kern County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.34285000",
    longitude: "-118.72990000",
  },
  {
    id: 119516,
    name: "Kernville",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.75467000",
    longitude: "-118.42536000",
  },
  {
    id: 119528,
    name: "Kettleman City",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.00829000",
    longitude: "-119.96180000",
  },
  {
    id: 119543,
    name: "Keyes",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.55660000",
    longitude: "-120.91549000",
  },
  {
    id: 119579,
    name: "King City",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.21274000",
    longitude: "-121.12603000",
  },
  {
    id: 119598,
    name: "Kings Beach",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.23768000",
    longitude: "-120.02658000",
  },
  {
    id: 119600,
    name: "Kings County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.07536000",
    longitude: "-119.81550000",
  },
  {
    id: 119609,
    name: "Kingsburg",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.51384000",
    longitude: "-119.55402000",
  },
  {
    id: 119675,
    name: "Knightsen",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.96881000",
    longitude: "-121.66801000",
  },
  {
    id: 119708,
    name: "Koreatown",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.05779000",
    longitude: "-118.30091000",
  },
  {
    id: 119736,
    name: "La Cañada Flintridge",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.19917000",
    longitude: "-118.18785000",
  },
  {
    id: 119742,
    name: "La Crescenta-Montrose",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.23216000",
    longitude: "-118.23529000",
  },
  {
    id: 119755,
    name: "La Habra",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.93196000",
    longitude: "-117.94617000",
  },
  {
    id: 119756,
    name: "La Habra Heights",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.96085000",
    longitude: "-117.95062000",
  },
  {
    id: 119760,
    name: "La Jolla",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.84727000",
    longitude: "-117.27420000",
  },
  {
    id: 119765,
    name: "La Mesa",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.76783000",
    longitude: "-117.02308000",
  },
  {
    id: 119767,
    name: "La Mirada",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.91724000",
    longitude: "-118.01201000",
  },
  {
    id: 119769,
    name: "La Palma",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.84640000",
    longitude: "-118.04673000",
  },
  {
    id: 119779,
    name: "La Presa",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.70811000",
    longitude: "-116.99725000",
  },
  {
    id: 119782,
    name: "La Puente",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.02001000",
    longitude: "-117.94951000",
  },
  {
    id: 119783,
    name: "La Quinta",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.66336000",
    longitude: "-116.31001000",
  },
  {
    id: 119784,
    name: "La Riviera",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.56685000",
    longitude: "-121.35690000",
  },
  {
    id: 119789,
    name: "La Selva Beach",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.93662000",
    longitude: "-121.86468000",
  },
  {
    id: 119793,
    name: "La Verne",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.10084000",
    longitude: "-117.76784000",
  },
  {
    id: 119823,
    name: "Ladera",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.39994000",
    longitude: "-122.19830000",
  },
  {
    id: 119824,
    name: "Ladera Heights",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.99418000",
    longitude: "-118.37535000",
  },
  {
    id: 119825,
    name: "Ladera Ranch",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.57086000",
    longitude: "-117.63561000",
  },
  {
    id: 119835,
    name: "Lafayette",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.88576000",
    longitude: "-122.11802000",
  },
  {
    id: 119849,
    name: "Laguna",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.42102000",
    longitude: "-121.42384000",
  },
  {
    id: 119852,
    name: "Laguna Beach",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.54225000",
    longitude: "-117.78311000",
  },
  {
    id: 119854,
    name: "Laguna Hills",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.61252000",
    longitude: "-117.71283000",
  },
  {
    id: 119855,
    name: "Laguna Niguel",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.52253000",
    longitude: "-117.70755000",
  },
  {
    id: 119858,
    name: "Laguna Woods",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.61030000",
    longitude: "-117.72533000",
  },
  {
    id: 119859,
    name: "Lagunitas-Forest Knolls",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.01793000",
    longitude: "-122.69124000",
  },
  {
    id: 119866,
    name: "Lake Arrowhead",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.24834000",
    longitude: "-117.18921000",
  },
  {
    id: 119896,
    name: "Lake County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.09965000",
    longitude: "-122.75318000",
  },
  {
    id: 119906,
    name: "Lake Elsinore",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.66808000",
    longitude: "-117.32726000",
  },
  {
    id: 119911,
    name: "Lake Forest",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.64697000",
    longitude: "-117.68922000",
  },
  {
    id: 119923,
    name: "Lake Isabella",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.61801000",
    longitude: "-118.47314000",
  },
  {
    id: 119931,
    name: "Lake Los Angeles",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.61249000",
    longitude: "-117.82812000",
  },
  {
    id: 119950,
    name: "Lake Nacimiento",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.72830000",
    longitude: "-120.87963000",
  },
  {
    id: 120005,
    name: "Lake of the Pines",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.03962000",
    longitude: "-121.05661000",
  },
  {
    id: 119973,
    name: "Lake San Marcos",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.12615000",
    longitude: "-117.20837000",
  },
  {
    id: 119993,
    name: "Lake Wildwood",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.23295000",
    longitude: "-121.20051000",
  },
  {
    id: 120020,
    name: "Lakeland Village",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.63863000",
    longitude: "-117.34393000",
  },
  {
    id: 120023,
    name: "Lakeport",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.04295000",
    longitude: "-122.91583000",
  },
  {
    id: 120032,
    name: "Lakeside",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.85727000",
    longitude: "-116.92225000",
  },
  {
    id: 120040,
    name: "Lakeview",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.83863000",
    longitude: "-117.11809000",
  },
  {
    id: 120051,
    name: "Lakewood",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.85363000",
    longitude: "-118.13396000",
  },
  {
    id: 120074,
    name: "Lamont",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.25968000",
    longitude: "-118.91427000",
  },
  {
    id: 120089,
    name: "Lancaster",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.69804000",
    longitude: "-118.13674000",
  },
  {
    id: 120147,
    name: "Larkfield-Wikiup",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.51342000",
    longitude: "-122.75094000",
  },
  {
    id: 120148,
    name: "Larkspur",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.93409000",
    longitude: "-122.53525000",
  },
  {
    id: 120156,
    name: "Las Flores",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.58808000",
    longitude: "-117.62672000",
  },
  {
    id: 120158,
    name: "Las Lomas",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.86523000",
    longitude: "-121.73495000",
  },
  {
    id: 120166,
    name: "Lassen County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.67359000",
    longitude: "-120.59433000",
  },
  {
    id: 120170,
    name: "Lathrop",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.82270000",
    longitude: "-121.27661000",
  },
  {
    id: 120174,
    name: "Laton",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.43328000",
    longitude: "-119.68680000",
  },
  {
    id: 120222,
    name: "Lawndale",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.88724000",
    longitude: "-118.35257000",
  },
  {
    id: 120255,
    name: "Laytonville",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.68821000",
    longitude: "-123.48279000",
  },
  {
    id: 120260,
    name: "Le Grand",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.22855000",
    longitude: "-120.24823000",
  },
  {
    id: 120298,
    name: "Lebec",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.84164000",
    longitude: "-118.86482000",
  },
  {
    id: 120359,
    name: "Lemon Grove",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.74255000",
    longitude: "-117.03142000",
  },
  {
    id: 120362,
    name: "Lemoore",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.30078000",
    longitude: "-119.78291000",
  },
  {
    id: 120363,
    name: "Lemoore Station",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.26326000",
    longitude: "-119.90476000",
  },
  {
    id: 120371,
    name: "Lennox",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.93807000",
    longitude: "-118.35258000",
  },
  {
    id: 120378,
    name: "Lenwood",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.87665000",
    longitude: "-117.10393000",
  },
  {
    id: 120387,
    name: "Leona Valley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.61832000",
    longitude: "-118.28813000",
  },
  {
    id: 120427,
    name: "Lewiston",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.70737000",
    longitude: "-122.80752000",
  },
  {
    id: 120454,
    name: "Lexington Hills",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.16467000",
    longitude: "-121.97301000",
  },
  {
    id: 120509,
    name: "Lincoln",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.89156000",
    longitude: "-121.29301000",
  },
  {
    id: 120547,
    name: "Lincoln Village",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.00520000",
    longitude: "-121.32828000",
  },
  {
    id: 120557,
    name: "Linda",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.12767000",
    longitude: "-121.55080000",
  },
  {
    id: 120566,
    name: "Linden",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.02131000",
    longitude: "-121.08383000",
  },
  {
    id: 120574,
    name: "Lindsay",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.20301000",
    longitude: "-119.08816000",
  },
  {
    id: 120633,
    name: "Littlerock",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.52110000",
    longitude: "-117.98368000",
  },
  {
    id: 120640,
    name: "Live Oak",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.98356000",
    longitude: "-121.98052000",
  },
  {
    id: 120644,
    name: "Livermore",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.68187000",
    longitude: "-121.76801000",
  },
  {
    id: 120652,
    name: "Livingston",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.38688000",
    longitude: "-120.72353000",
  },
  {
    id: 120675,
    name: "Lockeford",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.16353000",
    longitude: "-121.14994000",
  },
  {
    id: 120691,
    name: "Lodi",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.13020000",
    longitude: "-121.27245000",
  },
  {
    id: 120712,
    name: "Loma Linda",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.04835000",
    longitude: "-117.26115000",
  },
  {
    id: 120713,
    name: "Loma Rica",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.31183000",
    longitude: "-121.41774000",
  },
  {
    id: 120716,
    name: "Lomita",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.79224000",
    longitude: "-118.31507000",
  },
  {
    id: 120717,
    name: "Lompico",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.10550000",
    longitude: "-122.05274000",
  },
  {
    id: 120718,
    name: "Lompoc",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.63915000",
    longitude: "-120.45794000",
  },
  {
    id: 120722,
    name: "London",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.47606000",
    longitude: "-119.44318000",
  },
  {
    id: 120729,
    name: "Lone Pine",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.60626000",
    longitude: "-118.06462000",
  },
  {
    id: 120738,
    name: "Long Beach",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.76696000",
    longitude: "-118.18923000",
  },
  {
    id: 120767,
    name: "Loomis",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.82129000",
    longitude: "-121.19300000",
  },
  {
    id: 120778,
    name: "Los Alamitos",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.80307000",
    longitude: "-118.07256000",
  },
  {
    id: 120779,
    name: "Los Alamos",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.74443000",
    longitude: "-120.27821000",
  },
  {
    id: 120782,
    name: "Los Altos",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.38522000",
    longitude: "-122.11413000",
  },
  {
    id: 120783,
    name: "Los Altos Hills",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.37966000",
    longitude: "-122.13746000",
  },
  {
    id: 120784,
    name: "Los Angeles",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.05223000",
    longitude: "-118.24368000",
  },
  {
    id: 120785,
    name: "Los Angeles County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.19801000",
    longitude: "-118.26102000",
  },
  {
    id: 120786,
    name: "Los Banos",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.05828000",
    longitude: "-120.84992000",
  },
  {
    id: 120789,
    name: "Los Gatos",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.22661000",
    longitude: "-121.97468000",
  },
  {
    id: 120792,
    name: "Los Molinos",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.02127000",
    longitude: "-122.10027000",
  },
  {
    id: 120793,
    name: "Los Olivos",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.66776000",
    longitude: "-120.11487000",
  },
  {
    id: 120794,
    name: "Los Osos",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.31109000",
    longitude: "-120.83240000",
  },
  {
    id: 120796,
    name: "Los Serranos",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.97279000",
    longitude: "-117.70811000",
  },
  {
    id: 120798,
    name: "Lost Hills",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.61635000",
    longitude: "-119.69429000",
  },
  {
    id: 120842,
    name: "Lower Lake",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.91045000",
    longitude: "-122.61026000",
  },
  {
    id: 120855,
    name: "Loyola",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.35133000",
    longitude: "-122.10052000",
  },
  {
    id: 120862,
    name: "Lucas Valley-Marinwood",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.04011000",
    longitude: "-122.57550000",
  },
  {
    id: 120865,
    name: "Lucerne",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.38078000",
    longitude: "-119.66430000",
  },
  {
    id: 120866,
    name: "Lucerne Valley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.44389000",
    longitude: "-116.96781000",
  },
  {
    id: 120934,
    name: "Lynwood",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.93029000",
    longitude: "-118.21146000",
  },
  {
    id: 120980,
    name: "Madera",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.96134000",
    longitude: "-120.06072000",
  },
  {
    id: 120981,
    name: "Madera Acres",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.01911000",
    longitude: "-120.06683000",
  },
  {
    id: 120982,
    name: "Madera County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.21804000",
    longitude: "-119.76265000",
  },
  {
    id: 121031,
    name: "Magalia",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.81211000",
    longitude: "-121.57831000",
  },
  {
    id: 121062,
    name: "Malibu",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.02577000",
    longitude: "-118.78040000",
  },
  {
    id: 121077,
    name: "Mammoth Lakes",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.64855000",
    longitude: "-118.97208000",
  },
  {
    id: 121117,
    name: "Manhattan Beach",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.88474000",
    longitude: "-118.41091000",
  },
  {
    id: 121154,
    name: "Manteca",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.79743000",
    longitude: "-121.21605000",
  },
  {
    id: 121196,
    name: "March Air Force Base",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.89209000",
    longitude: "-117.26310000",
  },
  {
    id: 121212,
    name: "Maricopa",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.05886000",
    longitude: "-119.40095000",
  },
  {
    id: 121221,
    name: "Marin City",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.86854000",
    longitude: "-122.50914000",
  },
  {
    id: 121222,
    name: "Marin County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.05518000",
    longitude: "-122.74886000",
  },
  {
    id: 121223,
    name: "Marina",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.68440000",
    longitude: "-121.80217000",
  },
  {
    id: 121224,
    name: "Marina del Rey",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.98029000",
    longitude: "-118.45174000",
  },
  {
    id: 121264,
    name: "Mariposa",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.48494000",
    longitude: "-119.96628000",
  },
  {
    id: 121265,
    name: "Mariposa County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.58152000",
    longitude: "-119.90552000",
  },
  {
    id: 121345,
    name: "Martinez",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.01937000",
    longitude: "-122.13413000",
  },
  {
    id: 121360,
    name: "Marysville",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.14573000",
    longitude: "-121.59135000",
  },
  {
    id: 121401,
    name: "Matheny",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.17066000",
    longitude: "-119.35158000",
  },
  {
    id: 121430,
    name: "Maxwell",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.27628000",
    longitude: "-122.19137000",
  },
  {
    id: 121439,
    name: "Mayflower Village",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.11501000",
    longitude: "-118.00979000",
  },
  {
    id: 121459,
    name: "Maywood",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.98668000",
    longitude: "-118.18535000",
  },
  {
    id: 121471,
    name: "McCloud",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "41.25571000",
    longitude: "-122.13945000",
  },
  {
    id: 121500,
    name: "McFarland",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.67801000",
    longitude: "-119.22927000",
  },
  {
    id: 121524,
    name: "McKinleyville",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.94652000",
    longitude: "-124.10062000",
  },
  {
    id: 121554,
    name: "Mead Valley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.83335000",
    longitude: "-117.29615000",
  },
  {
    id: 121563,
    name: "Meadow Vista",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.00101000",
    longitude: "-121.02189000",
  },
  {
    id: 121567,
    name: "Meadowbrook",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.72578000",
    longitude: "-117.28509000",
  },
  {
    id: 121578,
    name: "Mecca",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.57219000",
    longitude: "-116.07820000",
  },
  {
    id: 121619,
    name: "Meiners Oaks",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.44694000",
    longitude: "-119.27928000",
  },
  {
    id: 121652,
    name: "Mendocino County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.43362000",
    longitude: "-123.43155000",
  },
  {
    id: 121657,
    name: "Mendota",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.75356000",
    longitude: "-120.38156000",
  },
  {
    id: 121659,
    name: "Menifee",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.72835000",
    longitude: "-117.14642000",
  },
  {
    id: 121661,
    name: "Menlo Park",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.45383000",
    longitude: "-122.18219000",
  },
  {
    id: 121667,
    name: "Mentone",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.07001000",
    longitude: "-117.13448000",
  },
  {
    id: 121671,
    name: "Merced",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.30216000",
    longitude: "-120.48297000",
  },
  {
    id: 121672,
    name: "Merced County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.19186000",
    longitude: "-120.71767000",
  },
  {
    id: 121722,
    name: "Mesa Verde",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.60586000",
    longitude: "-114.73107000",
  },
  {
    id: 121794,
    name: "Middletown",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.75240000",
    longitude: "-122.61499000",
  },
  {
    id: 121809,
    name: "Midpines",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.54438000",
    longitude: "-119.92045000",
  },
  {
    id: 121821,
    name: "Midway City",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.74474000",
    longitude: "-117.98923000",
  },
  {
    id: 121868,
    name: "Mill Valley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.90604000",
    longitude: "-122.54498000",
  },
  {
    id: 121871,
    name: "Millbrae",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.59855000",
    longitude: "-122.38719000",
  },
  {
    id: 121909,
    name: "Milpitas",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.42827000",
    longitude: "-121.90662000",
  },
  {
    id: 121949,
    name: "Minkler",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.72384000",
    longitude: "-119.45818000",
  },
  {
    id: 121971,
    name: "Mira Mesa",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.91560000",
    longitude: "-117.14392000",
  },
  {
    id: 121972,
    name: "Mira Monte",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.43361000",
    longitude: "-119.28511000",
  },
  {
    id: 121984,
    name: "Mission Canyon",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.45083000",
    longitude: "-119.71291000",
  },
  {
    id: 121985,
    name: "Mission District",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.75993000",
    longitude: "-122.41914000",
  },
  {
    id: 121987,
    name: "Mission Hills",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.68609000",
    longitude: "-120.43683000",
  },
  {
    id: 121988,
    name: "Mission Viejo",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.60002000",
    longitude: "-117.67200000",
  },
  {
    id: 122014,
    name: "Modesto",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.63910000",
    longitude: "-120.99688000",
  },
  {
    id: 122015,
    name: "Modoc County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "41.58985000",
    longitude: "-120.72497000",
  },
  {
    id: 122023,
    name: "Mojave",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.05247000",
    longitude: "-118.17396000",
  },
  {
    id: 122048,
    name: "Mono County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.93899000",
    longitude: "-118.88671000",
  },
  {
    id: 122049,
    name: "Mono Vista",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.99770000",
    longitude: "-120.26991000",
  },
  {
    id: 122088,
    name: "Monrovia",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.14806000",
    longitude: "-117.99895000",
  },
  {
    id: 122098,
    name: "Montague",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "41.72820000",
    longitude: "-122.52780000",
  },
  {
    id: 122100,
    name: "Montalvin",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.99548000",
    longitude: "-122.33275000",
  },
  {
    id: 122102,
    name: "Montara",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.54216000",
    longitude: "-122.51609000",
  },
  {
    id: 122107,
    name: "Montclair",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.07751000",
    longitude: "-117.68978000",
  },
  {
    id: 122109,
    name: "Monte Rio",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.46547000",
    longitude: "-123.00889000",
  },
  {
    id: 122110,
    name: "Monte Sereno",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.23633000",
    longitude: "-121.99246000",
  },
  {
    id: 122114,
    name: "Montebello",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.00946000",
    longitude: "-118.10535000",
  },
  {
    id: 122115,
    name: "Montecito",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.43666000",
    longitude: "-119.63208000",
  },
  {
    id: 122120,
    name: "Monterey",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.60024000",
    longitude: "-121.89468000",
  },
  {
    id: 122121,
    name: "Monterey County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.23977000",
    longitude: "-121.30890000",
  },
  {
    id: 122122,
    name: "Monterey Park",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.06251000",
    longitude: "-118.12285000",
  },
  {
    id: 122193,
    name: "Monument Hills",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.66429000",
    longitude: "-121.87566000",
  },
  {
    id: 122213,
    name: "Moorpark",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.28556000",
    longitude: "-118.88204000",
  },
  {
    id: 122221,
    name: "Morada",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.03853000",
    longitude: "-121.24578000",
  },
  {
    id: 122222,
    name: "Moraga",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.83493000",
    longitude: "-122.12969000",
  },
  {
    id: 122231,
    name: "Moreno Valley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.93752000",
    longitude: "-117.23059000",
  },
  {
    id: 122246,
    name: "Morgan Hill",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.13050000",
    longitude: "-121.65439000",
  },
  {
    id: 122261,
    name: "Morongo Valley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.04695000",
    longitude: "-116.58085000",
  },
  {
    id: 122288,
    name: "Morro Bay",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.36581000",
    longitude: "-120.84990000",
  },
  {
    id: 122307,
    name: "Moss Beach",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.52744000",
    longitude: "-122.51331000",
  },
  {
    id: 122347,
    name: "Mount Hermon",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.05106000",
    longitude: "-122.05857000",
  },
  {
    id: 122388,
    name: "Mount Shasta",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "41.31024000",
    longitude: "-122.31225000",
  },
  {
    id: 122418,
    name: "Mountain House",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.78326000",
    longitude: "-121.54273000",
  },
  {
    id: 122425,
    name: "Mountain Ranch",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.22825000",
    longitude: "-120.54076000",
  },
  {
    id: 122431,
    name: "Mountain View",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.38605000",
    longitude: "-122.08385000",
  },
  {
    id: 122434,
    name: "Mountain View Acres",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.49666000",
    longitude: "-117.34894000",
  },
  {
    id: 122488,
    name: "Murphys",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.13762000",
    longitude: "-120.46105000",
  },
  {
    id: 122498,
    name: "Murrieta",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.55391000",
    longitude: "-117.21392000",
  },
  {
    id: 122499,
    name: "Murrieta Hot Springs",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.56058000",
    longitude: "-117.15809000",
  },
  {
    id: 122506,
    name: "Muscoy",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.15418000",
    longitude: "-117.34421000",
  },
  {
    id: 122525,
    name: "Myrtletown",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.78874000",
    longitude: "-124.13034000",
  },
  {
    id: 122546,
    name: "Napa",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.29714000",
    longitude: "-122.28553000",
  },
  {
    id: 122547,
    name: "Napa County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.50647000",
    longitude: "-122.33053000",
  },
  {
    id: 122591,
    name: "National City",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.67811000",
    longitude: "-117.09920000",
  },
  {
    id: 122614,
    name: "Needles",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.84806000",
    longitude: "-114.61413000",
  },
  {
    id: 122651,
    name: "Nevada City",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.26173000",
    longitude: "-121.01779000",
  },
  {
    id: 122653,
    name: "Nevada County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.30137000",
    longitude: "-120.76875000",
  },
  {
    id: 122805,
    name: "Newark",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.52966000",
    longitude: "-122.04024000",
  },
  {
    id: 122821,
    name: "Newcastle",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.87407000",
    longitude: "-121.13328000",
  },
  {
    id: 122834,
    name: "Newman",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.31383000",
    longitude: "-121.02076000",
  },
  {
    id: 122853,
    name: "Newport Beach",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.61891000",
    longitude: "-117.92895000",
  },
  {
    id: 122885,
    name: "Nice",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.12323000",
    longitude: "-122.84833000",
  },
  {
    id: 122899,
    name: "Niland",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.24004000",
    longitude: "-115.51888000",
  },
  {
    id: 122905,
    name: "Nipomo",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.04275000",
    longitude: "-120.47600000",
  },
  {
    id: 122925,
    name: "Noe Valley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.75018000",
    longitude: "-122.43369000",
  },
  {
    id: 122939,
    name: "Norco",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.93113000",
    longitude: "-117.54866000",
  },
  {
    id: 122968,
    name: "North Auburn",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.93129000",
    longitude: "-121.08189000",
  },
  {
    id: 123020,
    name: "North Edwards",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.01664000",
    longitude: "-117.83284000",
  },
  {
    id: 123021,
    name: "North El Monte",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.10279000",
    longitude: "-118.02423000",
  },
  {
    id: 123024,
    name: "North Fair Oaks",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.47438000",
    longitude: "-122.19663000",
  },
  {
    id: 123039,
    name: "North Highlands",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.68574000",
    longitude: "-121.37217000",
  },
  {
    id: 123041,
    name: "North Hills",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.23639000",
    longitude: "-118.48472000",
  },
  {
    id: 123042,
    name: "North Hollywood",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.17223000",
    longitude: "-118.37897000",
  },
  {
    id: 123050,
    name: "North Lakeport",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.08831000",
    longitude: "-122.90538000",
  },
  {
    id: 123094,
    name: "North Richmond",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.95881000",
    longitude: "-122.36747000",
  },
  {
    id: 123117,
    name: "North Tustin",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.76446000",
    longitude: "-117.79394000",
  },
  {
    id: 123163,
    name: "Northridge",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.22834000",
    longitude: "-118.53675000",
  },
  {
    id: 123191,
    name: "Norwalk",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.90224000",
    longitude: "-118.08173000",
  },
  {
    id: 123207,
    name: "Novato",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.10742000",
    longitude: "-122.56970000",
  },
  {
    id: 123216,
    name: "Nuevo",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.80141000",
    longitude: "-117.14587000",
  },
  {
    id: 123248,
    name: "Oak Hills",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.38313000",
    longitude: "-117.38135000",
  },
  {
    id: 123257,
    name: "Oak Park",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.17917000",
    longitude: "-118.76287000",
  },
  {
    id: 123266,
    name: "Oak View",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.40000000",
    longitude: "-119.30011000",
  },
  {
    id: 123274,
    name: "Oakdale",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.76659000",
    longitude: "-120.84715000",
  },
  {
    id: 123281,
    name: "Oakhurst",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.32800000",
    longitude: "-119.64932000",
  },
  {
    id: 123293,
    name: "Oakland",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.80437000",
    longitude: "-122.27080000",
  },
  {
    id: 123298,
    name: "Oakley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.99742000",
    longitude: "-121.71245000",
  },
  {
    id: 123313,
    name: "Oasis",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.46586000",
    longitude: "-116.09889000",
  },
  {
    id: 123321,
    name: "Occidental",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.40741000",
    longitude: "-122.94833000",
  },
  {
    id: 123341,
    name: "Oceano",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.09886000",
    longitude: "-120.61239000",
  },
  {
    id: 123344,
    name: "Oceanside",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.19587000",
    longitude: "-117.37948000",
  },
  {
    id: 123388,
    name: "Oildale",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.41968000",
    longitude: "-119.01955000",
  },
  {
    id: 123390,
    name: "Ojai",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.44805000",
    longitude: "-119.24289000",
  },
  {
    id: 123418,
    name: "Old Fig Garden",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.79885000",
    longitude: "-119.80515000",
  },
  {
    id: 123441,
    name: "Olivehurst",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.09545000",
    longitude: "-121.55219000",
  },
  {
    id: 123486,
    name: "Ontario",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.06334000",
    longitude: "-117.65089000",
  },
  {
    id: 123495,
    name: "Opal Cliffs",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.96078000",
    longitude: "-121.96413000",
  },
  {
    id: 123510,
    name: "Orange",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.78779000",
    longitude: "-117.85311000",
  },
  {
    id: 123521,
    name: "Orange County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.67691000",
    longitude: "-117.77617000",
  },
  {
    id: 123522,
    name: "Orange Cove",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.62439000",
    longitude: "-119.31373000",
  },
  {
    id: 123530,
    name: "Orangevale",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.67851000",
    longitude: "-121.22578000",
  },
  {
    id: 123541,
    name: "Orcutt",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.86526000",
    longitude: "-120.43600000",
  },
  {
    id: 123554,
    name: "Orinda",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.87715000",
    longitude: "-122.17969000",
  },
  {
    id: 123559,
    name: "Orland",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.74738000",
    longitude: "-122.19637000",
  },
  {
    id: 123577,
    name: "Orosi",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.54495000",
    longitude: "-119.28734000",
  },
  {
    id: 123578,
    name: "Oroville",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.51394000",
    longitude: "-121.55776000",
  },
  {
    id: 123580,
    name: "Oroville East",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.51126000",
    longitude: "-121.47519000",
  },
  {
    id: 123689,
    name: "Oxnard",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.19750000",
    longitude: "-119.17705000",
  },
  {
    id: 123705,
    name: "Pacheco",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.98353000",
    longitude: "-122.07524000",
  },
  {
    id: 123710,
    name: "Pacific Grove",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.61774000",
    longitude: "-121.91662000",
  },
  {
    id: 123711,
    name: "Pacifica",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.61383000",
    longitude: "-122.48692000",
  },
  {
    id: 123729,
    name: "Pajaro",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.90412000",
    longitude: "-121.74856000",
  },
  {
    id: 123735,
    name: "Palermo",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.43544000",
    longitude: "-121.53802000",
  },
  {
    id: 123748,
    name: "Palm Desert",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.72255000",
    longitude: "-116.37697000",
  },
  {
    id: 123752,
    name: "Palm Springs",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.83030000",
    longitude: "-116.54529000",
  },
  {
    id: 123757,
    name: "Palmdale",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.57943000",
    longitude: "-118.11646000",
  },
  {
    id: 123781,
    name: "Palo Alto",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.44188000",
    longitude: "-122.14302000",
  },
  {
    id: 123783,
    name: "Palo Cedro",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.56376000",
    longitude: "-122.23889000",
  },
  {
    id: 123791,
    name: "Palos Verdes Estates",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.80105000",
    longitude: "-118.39245000",
  },
  {
    id: 123818,
    name: "Paradise",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.75961000",
    longitude: "-121.62192000",
  },
  {
    id: 123824,
    name: "Paramount",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.88946000",
    longitude: "-118.15979000",
  },
  {
    id: 123874,
    name: "Parksdale",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.94717000",
    longitude: "-120.02294000",
  },
  {
    id: 123881,
    name: "Parkway",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.49602000",
    longitude: "-121.45884000",
  },
  {
    id: 123882,
    name: "Parkwood",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.92689000",
    longitude: "-120.04461000",
  },
  {
    id: 123884,
    name: "Parlier",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.61162000",
    longitude: "-119.52707000",
  },
  {
    id: 123897,
    name: "Pasadena",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.14778000",
    longitude: "-118.14452000",
  },
  {
    id: 123899,
    name: "Pasatiempo",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.00439000",
    longitude: "-122.02580000",
  },
  {
    id: 123904,
    name: "Paso Robles",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.62664000",
    longitude: "-120.69100000",
  },
  {
    id: 123916,
    name: "Patterson",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.47160000",
    longitude: "-121.12966000",
  },
  {
    id: 123917,
    name: "Patterson Tract",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.37952000",
    longitude: "-119.29560000",
  },
  {
    id: 123983,
    name: "Pedley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.97529000",
    longitude: "-117.47588000",
  },
  {
    id: 124024,
    name: "Penn Valley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.19600000",
    longitude: "-121.19107000",
  },
  {
    id: 124028,
    name: "Penngrove",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.29964000",
    longitude: "-122.66665000",
  },
  {
    id: 124066,
    name: "Perris",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.78252000",
    longitude: "-117.22865000",
  },
  {
    id: 124101,
    name: "Petaluma",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.23242000",
    longitude: "-122.63665000",
  },
  {
    id: 124119,
    name: "Phelan",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.42611000",
    longitude: "-117.57228000",
  },
  {
    id: 124150,
    name: "Phoenix Lake",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.00594000",
    longitude: "-120.30702000",
  },
  {
    id: 124162,
    name: "Pico Rivera",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.98307000",
    longitude: "-118.09673000",
  },
  {
    id: 124168,
    name: "Piedmont",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.82437000",
    longitude: "-122.23163000",
  },
  {
    id: 124222,
    name: "Pine Grove",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.41297000",
    longitude: "-120.65882000",
  },
  {
    id: 124226,
    name: "Pine Hills",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.73318000",
    longitude: "-124.15228000",
  },
  {
    id: 124239,
    name: "Pine Mountain Club",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.84637000",
    longitude: "-119.14955000",
  },
  {
    id: 124246,
    name: "Pine Valley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.82144000",
    longitude: "-116.52918000",
  },
  {
    id: 124271,
    name: "Pinole",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.00437000",
    longitude: "-122.29886000",
  },
  {
    id: 124309,
    name: "Piñon Hills",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.43333000",
    longitude: "-117.64672000",
  },
  {
    id: 124274,
    name: "Pioneer",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.43186000",
    longitude: "-120.57187000",
  },
  {
    id: 124280,
    name: "Piru",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.41527000",
    longitude: "-118.79398000",
  },
  {
    id: 124283,
    name: "Pismo Beach",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.14275000",
    longitude: "-120.64128000",
  },
  {
    id: 124294,
    name: "Pittsburg",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.02798000",
    longitude: "-121.88468000",
  },
  {
    id: 124308,
    name: "Pixley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.96856000",
    longitude: "-119.29178000",
  },
  {
    id: 124310,
    name: "Placentia",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.87224000",
    longitude: "-117.87034000",
  },
  {
    id: 124311,
    name: "Placer County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.06343000",
    longitude: "-120.71766000",
  },
  {
    id: 124312,
    name: "Placerville",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.72963000",
    longitude: "-120.79855000",
  },
  {
    id: 124338,
    name: "Planada",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.29077000",
    longitude: "-120.31852000",
  },
  {
    id: 124372,
    name: "Pleasant Hill",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.94798000",
    longitude: "-122.06080000",
  },
  {
    id: 124384,
    name: "Pleasanton",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.66243000",
    longitude: "-121.87468000",
  },
  {
    id: 124395,
    name: "Plumas County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.00468000",
    longitude: "-120.83860000",
  },
  {
    id: 124396,
    name: "Plumas Lake",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.02073000",
    longitude: "-121.55802000",
  },
  {
    id: 124453,
    name: "Pollock Pines",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.76158000",
    longitude: "-120.58611000",
  },
  {
    id: 124459,
    name: "Pomona",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.05529000",
    longitude: "-117.75228000",
  },
  {
    id: 124486,
    name: "Poplar-Cotton Center",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.05635000",
    longitude: "-119.14919000",
  },
  {
    id: 124510,
    name: "Port Hueneme",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.14778000",
    longitude: "-119.19511000",
  },
  {
    id: 124556,
    name: "Porterville",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.06523000",
    longitude: "-119.01677000",
  },
  {
    id: 124566,
    name: "Portola",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.81046000",
    longitude: "-120.46910000",
  },
  {
    id: 124567,
    name: "Portola Hills",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.67919000",
    longitude: "-117.63116000",
  },
  {
    id: 124568,
    name: "Portola Valley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.38411000",
    longitude: "-122.23524000",
  },
  {
    id: 124606,
    name: "Poway",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.96282000",
    longitude: "-117.03586000",
  },
  {
    id: 124725,
    name: "Prunedale",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.77579000",
    longitude: "-121.66967000",
  },
  {
    id: 124777,
    name: "Quail Valley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.70697000",
    longitude: "-117.24504000",
  },
  {
    id: 124782,
    name: "Quartz Hill",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.64526000",
    longitude: "-118.21813000",
  },
  {
    id: 124799,
    name: "Quincy",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.93682000",
    longitude: "-120.94647000",
  },
  {
    id: 124823,
    name: "Rainbow",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.41031000",
    longitude: "-117.14781000",
  },
  {
    id: 124838,
    name: "Ramona",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.04171000",
    longitude: "-116.86808000",
  },
  {
    id: 124848,
    name: "Rancho Calaveras",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.12742000",
    longitude: "-120.85827000",
  },
  {
    id: 124849,
    name: "Rancho Cordova",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.58907000",
    longitude: "-121.30273000",
  },
  {
    id: 124850,
    name: "Rancho Cucamonga",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.10640000",
    longitude: "-117.59311000",
  },
  {
    id: 124851,
    name: "Rancho Mirage",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.73974000",
    longitude: "-116.41279000",
  },
  {
    id: 124852,
    name: "Rancho Murieta",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.50185000",
    longitude: "-121.09467000",
  },
  {
    id: 124853,
    name: "Rancho Palos Verdes",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.74446000",
    longitude: "-118.38702000",
  },
  {
    id: 124854,
    name: "Rancho Penasquitos",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.95949000",
    longitude: "-117.11531000",
  },
  {
    id: 124855,
    name: "Rancho San Diego",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.74727000",
    longitude: "-116.93530000",
  },
  {
    id: 124856,
    name: "Rancho Santa Fe",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.02032000",
    longitude: "-117.20281000",
  },
  {
    id: 124857,
    name: "Rancho Santa Margarita",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.64086000",
    longitude: "-117.60310000",
  },
  {
    id: 124858,
    name: "Rancho Tehama Reserve",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.01569000",
    longitude: "-122.40072000",
  },
  {
    id: 124940,
    name: "Red Bluff",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.17849000",
    longitude: "-122.23583000",
  },
  {
    id: 124945,
    name: "Red Corral",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.41165000",
    longitude: "-120.60552000",
  },
  {
    id: 124966,
    name: "Redding",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.58654000",
    longitude: "-122.39168000",
  },
  {
    id: 124976,
    name: "Redlands",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.05557000",
    longitude: "-117.18254000",
  },
  {
    id: 124980,
    name: "Redondo Beach",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.84918000",
    longitude: "-118.38841000",
  },
  {
    id: 124983,
    name: "Redway",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.12014000",
    longitude: "-123.82336000",
  },
  {
    id: 124986,
    name: "Redwood City",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.48522000",
    longitude: "-122.23635000",
  },
  {
    id: 124989,
    name: "Redwood Shores",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.53188000",
    longitude: "-122.24802000",
  },
  {
    id: 124990,
    name: "Redwood Valley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.26544000",
    longitude: "-123.20445000",
  },
  {
    id: 124993,
    name: "Reedley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.59634000",
    longitude: "-119.45040000",
  },
  {
    id: 125049,
    name: "Rialto",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.10640000",
    longitude: "-117.37032000",
  },
  {
    id: 125066,
    name: "Richgrove",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.79662000",
    longitude: "-119.10788000",
  },
  {
    id: 125096,
    name: "Richmond",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.93576000",
    longitude: "-122.34775000",
  },
  {
    id: 125118,
    name: "Ridgecrest",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.62246000",
    longitude: "-117.67090000",
  },
  {
    id: 125127,
    name: "Ridgemark",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.81246000",
    longitude: "-121.36577000",
  },
  {
    id: 125151,
    name: "Rio Del Mar",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.96828000",
    longitude: "-121.90023000",
  },
  {
    id: 125152,
    name: "Rio Dell",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.49930000",
    longitude: "-124.10644000",
  },
  {
    id: 125157,
    name: "Rio Linda",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.69101000",
    longitude: "-121.44857000",
  },
  {
    id: 125161,
    name: "Rio Vista",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.16389000",
    longitude: "-121.69583000",
  },
  {
    id: 125168,
    name: "Ripon",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.74159000",
    longitude: "-121.12438000",
  },
  {
    id: 125190,
    name: "Riverbank",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.73604000",
    longitude: "-120.93549000",
  },
  {
    id: 125196,
    name: "Riverdale",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.43106000",
    longitude: "-119.85958000",
  },
  {
    id: 125199,
    name: "Riverdale Park",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.60938000",
    longitude: "-121.05188000",
  },
  {
    id: 125209,
    name: "Riverside",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.95335000",
    longitude: "-117.39616000",
  },
  {
    id: 125210,
    name: "Riverside County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.74368000",
    longitude: "-115.99386000",
  },
  {
    id: 125313,
    name: "Rocklin",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.79073000",
    longitude: "-121.23578000",
  },
  {
    id: 125341,
    name: "Rodeo",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.03298000",
    longitude: "-122.26691000",
  },
  {
    id: 125358,
    name: "Rohnert Park",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.33964000",
    longitude: "-122.70110000",
  },
  {
    id: 125366,
    name: "Rolling Hills",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.75739000",
    longitude: "-118.35752000",
  },
  {
    id: 125367,
    name: "Rolling Hills Estates",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.78779000",
    longitude: "-118.35813000",
  },
  {
    id: 125370,
    name: "Rollingwood",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.96520000",
    longitude: "-122.32997000",
  },
  {
    id: 125384,
    name: "Romoland",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.74585000",
    longitude: "-117.17503000",
  },
  {
    id: 125397,
    name: "Rosamond",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.86414000",
    longitude: "-118.16341000",
  },
  {
    id: 125419,
    name: "Rosedale",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.38357000",
    longitude: "-119.14538000",
  },
  {
    id: 125424,
    name: "Roseland",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.42213000",
    longitude: "-122.72804000",
  },
  {
    id: 125429,
    name: "Rosemead",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.08057000",
    longitude: "-118.07285000",
  },
  {
    id: 125431,
    name: "Rosemont",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.55185000",
    longitude: "-121.36467000",
  },
  {
    id: 125441,
    name: "Roseville",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.75212000",
    longitude: "-121.28801000",
  },
  {
    id: 125451,
    name: "Ross",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.96242000",
    longitude: "-122.55498000",
  },
  {
    id: 125455,
    name: "Rossmoor",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.78557000",
    longitude: "-118.08506000",
  },
  {
    id: 125480,
    name: "Rowland Heights",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.97612000",
    longitude: "-117.90534000",
  },
  {
    id: 125501,
    name: "Rubidoux",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.99613000",
    longitude: "-117.40560000",
  },
  {
    id: 125514,
    name: "Running Springs",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.20779000",
    longitude: "-117.10920000",
  },
  {
    id: 125577,
    name: "Sacramento",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.58157000",
    longitude: "-121.49440000",
  },
  {
    id: 125578,
    name: "Sacramento County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.44932000",
    longitude: "-121.34424000",
  },
  {
    id: 125647,
    name: "Saint Helena",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.50519000",
    longitude: "-122.47026000",
  },
  {
    id: 125742,
    name: "Salida",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.70576000",
    longitude: "-121.08494000",
  },
  {
    id: 125747,
    name: "Salinas",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.67774000",
    longitude: "-121.65550000",
  },
  {
    id: 125770,
    name: "Salton City",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.29865000",
    longitude: "-115.95611000",
  },
  {
    id: 125783,
    name: "San Andreas",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.19603000",
    longitude: "-120.68049000",
  },
  {
    id: 125785,
    name: "San Anselmo",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.97465000",
    longitude: "-122.56164000",
  },
  {
    id: 125788,
    name: "San Antonio Heights",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.15556000",
    longitude: "-117.65644000",
  },
  {
    id: 125792,
    name: "San Benito County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.60571000",
    longitude: "-121.07500000",
  },
  {
    id: 125793,
    name: "San Bernardino",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.10834000",
    longitude: "-117.28977000",
  },
  {
    id: 125794,
    name: "San Bernardino County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.84143000",
    longitude: "-116.17846000",
  },
  {
    id: 125795,
    name: "San Bruno",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.63049000",
    longitude: "-122.41108000",
  },
  {
    id: 125798,
    name: "San Carlos",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.50716000",
    longitude: "-122.26052000",
  },
  {
    id: 125800,
    name: "San Clemente",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.42697000",
    longitude: "-117.61199000",
  },
  {
    id: 125802,
    name: "San Diego",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.71571000",
    longitude: "-117.16472000",
  },
  {
    id: 125803,
    name: "San Diego Country Estates",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.00671000",
    longitude: "-116.78364000",
  },
  {
    id: 125804,
    name: "San Diego County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.02820000",
    longitude: "-116.77021000",
  },
  {
    id: 125805,
    name: "San Dimas",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.10668000",
    longitude: "-117.80673000",
  },
  {
    id: 125808,
    name: "San Fernando",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.28195000",
    longitude: "-118.43897000",
  },
  {
    id: 125809,
    name: "San Francisco",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.77493000",
    longitude: "-122.41942000",
  },
  {
    id: 125810,
    name: "San Gabriel",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.09611000",
    longitude: "-118.10583000",
  },
  {
    id: 125811,
    name: "San Jacinto",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.78391000",
    longitude: "-116.95864000",
  },
  {
    id: 125813,
    name: "San Joaquin",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.60662000",
    longitude: "-120.18904000",
  },
  {
    id: 125814,
    name: "San Joaquin County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.93478000",
    longitude: "-121.27145000",
  },
  {
    id: 125815,
    name: "San Joaquin Hills",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.61169000",
    longitude: "-117.83672000",
  },
  {
    id: 125816,
    name: "San Jose",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.33939000",
    longitude: "-121.89496000",
  },
  {
    id: 125818,
    name: "San Juan Bautista",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.84551000",
    longitude: "-121.53800000",
  },
  {
    id: 125819,
    name: "San Juan Capistrano",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.50169000",
    longitude: "-117.66255000",
  },
  {
    id: 125824,
    name: "San Leandro",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.72493000",
    longitude: "-122.15608000",
  },
  {
    id: 125826,
    name: "San Lorenzo",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.68104000",
    longitude: "-122.12441000",
  },
  {
    id: 125829,
    name: "San Luis Obispo",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.28275000",
    longitude: "-120.65962000",
  },
  {
    id: 125830,
    name: "San Luis Obispo County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.38742000",
    longitude: "-120.45220000",
  },
  {
    id: 125833,
    name: "San Marcos",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.14337000",
    longitude: "-117.16614000",
  },
  {
    id: 125834,
    name: "San Marino",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.12140000",
    longitude: "-118.10646000",
  },
  {
    id: 125835,
    name: "San Martin",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.08495000",
    longitude: "-121.61022000",
  },
  {
    id: 125836,
    name: "San Mateo",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.56299000",
    longitude: "-122.32553000",
  },
  {
    id: 125837,
    name: "San Mateo County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.43621000",
    longitude: "-122.35566000",
  },
  {
    id: 125838,
    name: "San Miguel",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.75247000",
    longitude: "-120.69628000",
  },
  {
    id: 125842,
    name: "San Pablo",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.96215000",
    longitude: "-122.34553000",
  },
  {
    id: 125843,
    name: "San Pasqual",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.09171000",
    longitude: "-116.95392000",
  },
  {
    id: 125845,
    name: "San Pedro",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.73585000",
    longitude: "-118.29229000",
  },
  {
    id: 125846,
    name: "San Rafael",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.97353000",
    longitude: "-122.53109000",
  },
  {
    id: 125847,
    name: "San Ramon",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.77993000",
    longitude: "-121.97802000",
  },
  {
    id: 125892,
    name: "Sanger",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.70801000",
    longitude: "-119.55597000",
  },
  {
    id: 125899,
    name: "Santa Ana",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.74557000",
    longitude: "-117.86783000",
  },
  {
    id: 125901,
    name: "Santa Barbara",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.42083000",
    longitude: "-119.69819000",
  },
  {
    id: 125902,
    name: "Santa Barbara County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.53834000",
    longitude: "-120.03078000",
  },
  {
    id: 125903,
    name: "Santa Clara",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.35411000",
    longitude: "-121.95524000",
  },
  {
    id: 125906,
    name: "Santa Clara County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.23249000",
    longitude: "-121.69627000",
  },
  {
    id: 125908,
    name: "Santa Clarita",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.39166000",
    longitude: "-118.54259000",
  },
  {
    id: 125910,
    name: "Santa Cruz",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.97412000",
    longitude: "-122.03080000",
  },
  {
    id: 125912,
    name: "Santa Cruz County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.02161000",
    longitude: "-122.00979000",
  },
  {
    id: 125916,
    name: "Santa Fe Springs",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.94724000",
    longitude: "-118.08535000",
  },
  {
    id: 125917,
    name: "Santa Margarita",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.38997000",
    longitude: "-120.60906000",
  },
  {
    id: 125918,
    name: "Santa Maria",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.95303000",
    longitude: "-120.43572000",
  },
  {
    id: 125919,
    name: "Santa Monica",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.01945000",
    longitude: "-118.49119000",
  },
  {
    id: 125920,
    name: "Santa Paula",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.35417000",
    longitude: "-119.05927000",
  },
  {
    id: 125922,
    name: "Santa Rosa",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.44047000",
    longitude: "-122.71443000",
  },
  {
    id: 125925,
    name: "Santa Susana",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.27167000",
    longitude: "-118.70898000",
  },
  {
    id: 125927,
    name: "Santa Venetia",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.99853000",
    longitude: "-122.52525000",
  },
  {
    id: 125928,
    name: "Santa Ynez",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.61443000",
    longitude: "-120.07987000",
  },
  {
    id: 125930,
    name: "Santee",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.83838000",
    longitude: "-116.97392000",
  },
  {
    id: 125937,
    name: "Saranap",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.88492000",
    longitude: "-122.07607000",
  },
  {
    id: 125941,
    name: "Saratoga",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.26383000",
    longitude: "-122.02301000",
  },
  {
    id: 125956,
    name: "Saticoy",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.28306000",
    longitude: "-119.14983000",
  },
  {
    id: 125970,
    name: "Sausalito",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.85909000",
    longitude: "-122.48525000",
  },
  {
    id: 126059,
    name: "Scotts Valley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.05106000",
    longitude: "-122.01468000",
  },
  {
    id: 126075,
    name: "Sea Ranch",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.71519000",
    longitude: "-123.45445000",
  },
  {
    id: 126083,
    name: "Seacliff",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.97412000",
    longitude: "-121.91579000",
  },
  {
    id: 126090,
    name: "Seal Beach",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.74141000",
    longitude: "-118.10479000",
  },
  {
    id: 126095,
    name: "Searles Valley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.76745000",
    longitude: "-117.40395000",
  },
  {
    id: 126099,
    name: "Seaside",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.61107000",
    longitude: "-121.85162000",
  },
  {
    id: 126108,
    name: "Sebastopol",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.40214000",
    longitude: "-122.82388000",
  },
  {
    id: 126116,
    name: "Sedco Hills",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.64169000",
    longitude: "-117.29087000",
  },
  {
    id: 126124,
    name: "Seeley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.79311000",
    longitude: "-115.69111000",
  },
  {
    id: 126141,
    name: "Selma",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.57078000",
    longitude: "-119.61208000",
  },
  {
    id: 126176,
    name: "Seven Trees",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.28605000",
    longitude: "-121.83856000",
  },
  {
    id: 126198,
    name: "Shackelford",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.61382000",
    longitude: "-120.99271000",
  },
  {
    id: 126202,
    name: "Shadow Hills",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.26195000",
    longitude: "-118.35175000",
  },
  {
    id: 126210,
    name: "Shafter",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.50051000",
    longitude: "-119.27178000",
  },
  {
    id: 126217,
    name: "Shandon",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.65525000",
    longitude: "-120.37543000",
  },
  {
    id: 126238,
    name: "Shasta",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.59932000",
    longitude: "-122.49196000",
  },
  {
    id: 126239,
    name: "Shasta County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.76377000",
    longitude: "-122.04052000",
  },
  {
    id: 126240,
    name: "Shasta Lake",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.68043000",
    longitude: "-122.37084000",
  },
  {
    id: 126321,
    name: "Sheridan",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.97962000",
    longitude: "-121.37551000",
  },
  {
    id: 126336,
    name: "Sherman Oaks",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.15112000",
    longitude: "-118.44925000",
  },
  {
    id: 126351,
    name: "Shingle Springs",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.66574000",
    longitude: "-120.92605000",
  },
  {
    id: 126353,
    name: "Shingletown",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.49238000",
    longitude: "-121.88916000",
  },
  {
    id: 126402,
    name: "Sierra County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.58040000",
    longitude: "-120.51600000",
  },
  {
    id: 126404,
    name: "Sierra Madre",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.16167000",
    longitude: "-118.05285000",
  },
  {
    id: 126411,
    name: "Signal Hill",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.80446000",
    longitude: "-118.16785000",
  },
  {
    id: 126431,
    name: "Silver Lake",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.08668000",
    longitude: "-118.27023000",
  },
  {
    id: 126432,
    name: "Silver Lakes",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.74558000",
    longitude: "-117.34098000",
  },
  {
    id: 126445,
    name: "Simi Valley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.26945000",
    longitude: "-118.78148000",
  },
  {
    id: 126465,
    name: "Siskiyou County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "41.59265000",
    longitude: "-122.54037000",
  },
  {
    id: 126486,
    name: "Sky Valley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.89001000",
    longitude: "-116.35251000",
  },
  {
    id: 126499,
    name: "Sleepy Hollow",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.01048000",
    longitude: "-122.58442000",
  },
  {
    id: 126561,
    name: "Soda Bay",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.00101000",
    longitude: "-122.78916000",
  },
  {
    id: 126565,
    name: "Solana Beach",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.99115000",
    longitude: "-117.27115000",
  },
  {
    id: 126566,
    name: "Solano County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.26692000",
    longitude: "-121.94001000",
  },
  {
    id: 126568,
    name: "Soledad",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.42469000",
    longitude: "-121.32632000",
  },
  {
    id: 126572,
    name: "Solvang",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.59582000",
    longitude: "-120.13765000",
  },
  {
    id: 126598,
    name: "Sonoma",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.29186000",
    longitude: "-122.45804000",
  },
  {
    id: 126599,
    name: "Sonoma County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.52529000",
    longitude: "-122.92254000",
  },
  {
    id: 126600,
    name: "Sonora",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.98409000",
    longitude: "-120.38214000",
  },
  {
    id: 126604,
    name: "Soquel",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.98801000",
    longitude: "-121.95663000",
  },
  {
    id: 126606,
    name: "Sorrento Valley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.89991000",
    longitude: "-117.19451000",
  },
  {
    id: 126608,
    name: "Soulsbyville",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.98465000",
    longitude: "-120.26380000",
  },
  {
    id: 126648,
    name: "South Dos Palos",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.96439000",
    longitude: "-120.65324000",
  },
  {
    id: 126650,
    name: "South El Monte",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.05195000",
    longitude: "-118.04673000",
  },
  {
    id: 126659,
    name: "South Gate",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.95474000",
    longitude: "-118.21202000",
  },
  {
    id: 126684,
    name: "South Lake Tahoe",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.93324000",
    longitude: "-119.98435000",
  },
  {
    id: 126699,
    name: "South Oroville",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.49655000",
    longitude: "-121.55219000",
  },
  {
    id: 126706,
    name: "South Pasadena",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.11612000",
    longitude: "-118.15035000",
  },
  {
    id: 126723,
    name: "South San Francisco",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.65466000",
    longitude: "-122.40775000",
  },
  {
    id: 126724,
    name: "South San Gabriel",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.04915000",
    longitude: "-118.09462000",
  },
  {
    id: 126725,
    name: "South San Jose Hills",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.01279000",
    longitude: "-117.90478000",
  },
  {
    id: 126733,
    name: "South Taft",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.13469000",
    longitude: "-119.45623000",
  },
  {
    id: 126748,
    name: "South Whittier",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.95015000",
    longitude: "-118.03917000",
  },
  {
    id: 126756,
    name: "South Yuba City",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.11656000",
    longitude: "-121.63913000",
  },
  {
    id: 126880,
    name: "Spring Valley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.74477000",
    longitude: "-116.99892000",
  },
  {
    id: 126882,
    name: "Spring Valley Lake",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.49364000",
    longitude: "-117.26832000",
  },
  {
    id: 126924,
    name: "Squaw Valley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.74023000",
    longitude: "-119.24679000",
  },
  {
    id: 126941,
    name: "Stallion Springs",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.08886000",
    longitude: "-118.64259000",
  },
  {
    id: 126954,
    name: "Stanford",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.42411000",
    longitude: "-122.16608000",
  },
  {
    id: 126957,
    name: "Stanislaus County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.55914000",
    longitude: "-120.99769000",
  },
  {
    id: 126969,
    name: "Stanton",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.80252000",
    longitude: "-117.99312000",
  },
  {
    id: 127046,
    name: "Stevenson Ranch",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.39048000",
    longitude: "-118.57372000",
  },
  {
    id: 127074,
    name: "Stockton",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.95770000",
    longitude: "-121.29078000",
  },
  {
    id: 127127,
    name: "Stratford",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.18940000",
    longitude: "-119.82319000",
  },
  {
    id: 127131,
    name: "Strathmore",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.14551000",
    longitude: "-119.06066000",
  },
  {
    id: 127133,
    name: "Strawberry",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.89687000",
    longitude: "-122.50886000",
  },
  {
    id: 127146,
    name: "Studio City",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.14862000",
    longitude: "-118.39647000",
  },
  {
    id: 127177,
    name: "Suisun",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.23825000",
    longitude: "-122.04024000",
  },
  {
    id: 127204,
    name: "Summerland",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.42138000",
    longitude: "-119.59652000",
  },
  {
    id: 127233,
    name: "Sun City",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.70919000",
    longitude: "-117.19726000",
  },
  {
    id: 127243,
    name: "Sun Village",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.55952000",
    longitude: "-117.95676000",
  },
  {
    id: 127253,
    name: "Sunland",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.26695000",
    longitude: "-118.30230000",
  },
  {
    id: 127259,
    name: "Sunnyside",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.74912000",
    longitude: "-119.69931000",
  },
  {
    id: 127261,
    name: "Sunnyside-Tahoe City",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.15023000",
    longitude: "-120.16120000",
  },
  {
    id: 127262,
    name: "Sunnyslope",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.01196000",
    longitude: "-117.43338000",
  },
  {
    id: 127265,
    name: "Sunnyvale",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.36883000",
    longitude: "-122.03635000",
  },
  {
    id: 127296,
    name: "Susanville",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.41628000",
    longitude: "-120.65301000",
  },
  {
    id: 127308,
    name: "Sutter",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.15989000",
    longitude: "-121.75275000",
  },
  {
    id: 127309,
    name: "Sutter County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.03452000",
    longitude: "-121.69484000",
  },
  {
    id: 127310,
    name: "Sutter Creek",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.39297000",
    longitude: "-120.80244000",
  },
  {
    id: 127373,
    name: "Taft",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.14247000",
    longitude: "-119.45651000",
  },
  {
    id: 127374,
    name: "Taft Heights",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.13469000",
    longitude: "-119.47262000",
  },
  {
    id: 127375,
    name: "Taft Mosswood",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.91385000",
    longitude: "-121.28316000",
  },
  {
    id: 127378,
    name: "Tahoe Vista",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.23991000",
    longitude: "-120.05102000",
  },
  {
    id: 127380,
    name: "Tahoma",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.06741000",
    longitude: "-120.12824000",
  },
  {
    id: 127397,
    name: "Talmage",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.13323000",
    longitude: "-123.16778000",
  },
  {
    id: 127402,
    name: "Tamalpais Valley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.87965000",
    longitude: "-122.54581000",
  },
  {
    id: 127403,
    name: "Tamalpais-Homestead Valley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.87834000",
    longitude: "-122.53625000",
  },
  {
    id: 127427,
    name: "Tara Hills",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.99353000",
    longitude: "-122.31636000",
  },
  {
    id: 127432,
    name: "Tarpey Village",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.79301000",
    longitude: "-119.70097000",
  },
  {
    id: 127483,
    name: "Tehachapi",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.13219000",
    longitude: "-118.44897000",
  },
  {
    id: 127484,
    name: "Tehama County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.12574000",
    longitude: "-122.23388000",
  },
  {
    id: 127492,
    name: "Temecula",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.49364000",
    longitude: "-117.14836000",
  },
  {
    id: 127493,
    name: "Temelec",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.26658000",
    longitude: "-122.49276000",
  },
  {
    id: 127501,
    name: "Temple City",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.10723000",
    longitude: "-118.05785000",
  },
  {
    id: 127505,
    name: "Templeton",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.54969000",
    longitude: "-120.70600000",
  },
  {
    id: 127515,
    name: "Terra Bella",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.96245000",
    longitude: "-119.04427000",
  },
  {
    id: 127541,
    name: "Teviston",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.92894000",
    longitude: "-119.27831000",
  },
  {
    id: 127570,
    name: "Thermal",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.64030000",
    longitude: "-116.13945000",
  },
  {
    id: 127571,
    name: "Thermalito",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.51128000",
    longitude: "-121.58692000",
  },
  {
    id: 127604,
    name: "Thornton",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.22603000",
    longitude: "-121.42467000",
  },
  {
    id: 127609,
    name: "Thousand Oaks",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.17056000",
    longitude: "-118.83759000",
  },
  {
    id: 127610,
    name: "Thousand Palms",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.82002000",
    longitude: "-116.39029000",
  },
  {
    id: 127620,
    name: "Three Rivers",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.43884000",
    longitude: "-118.90454000",
  },
  {
    id: 127632,
    name: "Tiburon",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.87354000",
    longitude: "-122.45664000",
  },
  {
    id: 127637,
    name: "Tierra Buena",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.14878000",
    longitude: "-121.66691000",
  },
  {
    id: 127678,
    name: "Tipton",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.05940000",
    longitude: "-119.31206000",
  },
  {
    id: 127730,
    name: "Topanga",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.09362000",
    longitude: "-118.60147000",
  },
  {
    id: 127741,
    name: "Toro Canyon",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.42000000",
    longitude: "-119.56707000",
  },
  {
    id: 127742,
    name: "Torrance",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.83585000",
    longitude: "-118.34063000",
  },
  {
    id: 127769,
    name: "Trabuco Canyon",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.66252000",
    longitude: "-117.59033000",
  },
  {
    id: 127771,
    name: "Tracy",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.73987000",
    longitude: "-121.42618000",
  },
  {
    id: 127829,
    name: "Trinity County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.65069000",
    longitude: "-123.11263000",
  },
  {
    id: 127853,
    name: "Truckee",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.32796000",
    longitude: "-120.18325000",
  },
  {
    id: 127878,
    name: "Tujunga",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.25223000",
    longitude: "-118.28841000",
  },
  {
    id: 127882,
    name: "Tulare",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.20773000",
    longitude: "-119.34734000",
  },
  {
    id: 127883,
    name: "Tulare County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.22016000",
    longitude: "-118.80047000",
  },
  {
    id: 127895,
    name: "Tuolumne City",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.96270000",
    longitude: "-120.24130000",
  },
  {
    id: 127896,
    name: "Tuolumne County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.02760000",
    longitude: "-119.95475000",
  },
  {
    id: 127900,
    name: "Turlock",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.49466000",
    longitude: "-120.84659000",
  },
  {
    id: 127917,
    name: "Tustin",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.74585000",
    longitude: "-117.82617000",
  },
  {
    id: 127920,
    name: "Twain Harte",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.03965000",
    longitude: "-120.23269000",
  },
  {
    id: 127921,
    name: "Twentynine Palms",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.13556000",
    longitude: "-116.05417000",
  },
  {
    id: 127930,
    name: "Twin Lakes",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.96745000",
    longitude: "-121.99802000",
  },
  {
    id: 127958,
    name: "Ukiah",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.15017000",
    longitude: "-123.20778000",
  },
  {
    id: 127987,
    name: "Union City",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.59577000",
    longitude: "-122.01913000",
  },
  {
    id: 128023,
    name: "Universal City",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.13890000",
    longitude: "-118.35341000",
  },
  {
    id: 128039,
    name: "Upland",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.09751000",
    longitude: "-117.64839000",
  },
  {
    id: 128045,
    name: "Upper Lake",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.16461000",
    longitude: "-122.91055000",
  },
  {
    id: 128072,
    name: "Vacaville",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.35658000",
    longitude: "-121.98774000",
  },
  {
    id: 128078,
    name: "Val Verde",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.44500000",
    longitude: "-118.65759000",
  },
  {
    id: 128087,
    name: "Valencia",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.44361000",
    longitude: "-118.60953000",
  },
  {
    id: 128093,
    name: "Valinda",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.04529000",
    longitude: "-117.94367000",
  },
  {
    id: 128094,
    name: "Valle Vista",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.74780000",
    longitude: "-116.89336000",
  },
  {
    id: 128096,
    name: "Vallejo",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.10409000",
    longitude: "-122.25664000",
  },
  {
    id: 128100,
    name: "Valley Center",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.21837000",
    longitude: "-117.03420000",
  },
  {
    id: 128109,
    name: "Valley Glen",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.18991000",
    longitude: "-118.44953000",
  },
  {
    id: 128115,
    name: "Valley Springs",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.19159000",
    longitude: "-120.82910000",
  },
  {
    id: 128137,
    name: "Van Nuys",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.18667000",
    longitude: "-118.44897000",
  },
  {
    id: 128147,
    name: "Vandenberg Air Force Base",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.74830000",
    longitude: "-120.51817000",
  },
  {
    id: 128148,
    name: "Vandenberg Village",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.70832000",
    longitude: "-120.46766000",
  },
  {
    id: 128172,
    name: "Venice",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.99084000",
    longitude: "-118.46008000",
  },
  {
    id: 128175,
    name: "Ventura",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.27834000",
    longitude: "-119.29317000",
  },
  {
    id: 128176,
    name: "Ventura County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.35753000",
    longitude: "-119.12603000",
  },
  {
    id: 128224,
    name: "Victorville",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.53611000",
    longitude: "-117.29116000",
  },
  {
    id: 128238,
    name: "View Park-Windsor Hills",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.99551000",
    longitude: "-118.34835000",
  },
  {
    id: 128244,
    name: "Villa Park",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.81446000",
    longitude: "-117.81311000",
  },
  {
    id: 128265,
    name: "Vincent",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.50055000",
    longitude: "-118.11646000",
  },
  {
    id: 128269,
    name: "Vine Hill",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.00853000",
    longitude: "-122.09608000",
  },
  {
    id: 128273,
    name: "Vineyard",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.46449000",
    longitude: "-121.34692000",
  },
  {
    id: 128293,
    name: "Visalia",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.33023000",
    longitude: "-119.29206000",
  },
  {
    id: 128294,
    name: "Visitacion Valley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.71715000",
    longitude: "-122.40433000",
  },
  {
    id: 128295,
    name: "Vista",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.20004000",
    longitude: "-117.24254000",
  },
  {
    id: 128297,
    name: "Vista Santa Rosa",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.62780000",
    longitude: "-116.21806000",
  },
  {
    id: 128374,
    name: "Waldon",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.92631000",
    longitude: "-122.05552000",
  },
  {
    id: 128414,
    name: "Walnut",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.02029000",
    longitude: "-117.86534000",
  },
  {
    id: 128416,
    name: "Walnut Creek",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.90631000",
    longitude: "-122.06496000",
  },
  {
    id: 128419,
    name: "Walnut Grove",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.24214000",
    longitude: "-121.51162000",
  },
  {
    id: 128422,
    name: "Walnut Park",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.96807000",
    longitude: "-118.22507000",
  },
  {
    id: 128528,
    name: "Wasco",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.59412000",
    longitude: "-119.34095000",
  },
  {
    id: 128609,
    name: "Waterford",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.64132000",
    longitude: "-120.76048000",
  },
  {
    id: 128641,
    name: "Watsonville",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.91023000",
    longitude: "-121.75689000",
  },
  {
    id: 128723,
    name: "Weaverville",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.73098000",
    longitude: "-122.94197000",
  },
  {
    id: 128751,
    name: "Weed",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "41.42265000",
    longitude: "-122.38613000",
  },
  {
    id: 128752,
    name: "Weedpatch",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.23802000",
    longitude: "-118.91510000",
  },
  {
    id: 128770,
    name: "Weldon",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.66579000",
    longitude: "-118.29036000",
  },
  {
    id: 128818,
    name: "West Athens",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.92335000",
    longitude: "-118.30341000",
  },
  {
    id: 128826,
    name: "West Bishop",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.36104000",
    longitude: "-118.45511000",
  },
  {
    id: 128842,
    name: "West Carson",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.82168000",
    longitude: "-118.29257000",
  },
  {
    id: 128853,
    name: "West Covina",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.06862000",
    longitude: "-117.93895000",
  },
  {
    id: 128897,
    name: "West Hills",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.19731000",
    longitude: "-118.64398000",
  },
  {
    id: 128899,
    name: "West Hollywood",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.09001000",
    longitude: "-118.36174000",
  },
  {
    id: 128929,
    name: "West Menlo Park",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.43355000",
    longitude: "-122.20302000",
  },
  {
    id: 128934,
    name: "West Modesto",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.61754000",
    longitude: "-121.03914000",
  },
  {
    id: 128950,
    name: "West Park",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.71023000",
    longitude: "-119.85126000",
  },
  {
    id: 128964,
    name: "West Puente Valley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.05168000",
    longitude: "-117.96840000",
  },
  {
    id: 128966,
    name: "West Rancho Dominguez",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.89390000",
    longitude: "-118.27063000",
  },
  {
    id: 128971,
    name: "West Sacramento",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.58046000",
    longitude: "-121.53023000",
  },
  {
    id: 129005,
    name: "West Whittier-Los Nietos",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.97600000",
    longitude: "-118.06909000",
  },
  {
    id: 129037,
    name: "Westhaven-Moonstone",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "41.04489000",
    longitude: "-124.10239000",
  },
  {
    id: 129040,
    name: "Westlake Village",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.14584000",
    longitude: "-118.80565000",
  },
  {
    id: 129048,
    name: "Westminster",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.75918000",
    longitude: "-118.00673000",
  },
  {
    id: 129051,
    name: "Westmont",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.94140000",
    longitude: "-118.30230000",
  },
  {
    id: 129057,
    name: "Westmorland",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.03727000",
    longitude: "-115.62138000",
  },
  {
    id: 129087,
    name: "Westwood",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.05612000",
    longitude: "-118.43063000",
  },
  {
    id: 129108,
    name: "Wheatland",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.00989000",
    longitude: "-121.42301000",
  },
  {
    id: 129210,
    name: "Whittier",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.97918000",
    longitude: "-118.03284000",
  },
  {
    id: 129235,
    name: "Wildomar",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.59891000",
    longitude: "-117.28004000",
  },
  {
    id: 129261,
    name: "Williams",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.15461000",
    longitude: "-122.14942000",
  },
  {
    id: 129302,
    name: "Willits",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.40961000",
    longitude: "-123.35557000",
  },
  {
    id: 129305,
    name: "Willow Creek",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "40.93958000",
    longitude: "-123.63144000",
  },
  {
    id: 129315,
    name: "Willowbrook",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.91696000",
    longitude: "-118.25507000",
  },
  {
    id: 129316,
    name: "Willows",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.52433000",
    longitude: "-122.19359000",
  },
  {
    id: 129346,
    name: "Wilton",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.41186000",
    longitude: "-121.27217000",
  },
  {
    id: 129360,
    name: "Winchester",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.70697000",
    longitude: "-117.08447000",
  },
  {
    id: 129386,
    name: "Windsor",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.54713000",
    longitude: "-122.81638000",
  },
  {
    id: 129439,
    name: "Winter Gardens",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "32.83116000",
    longitude: "-116.93336000",
  },
  {
    id: 129445,
    name: "Winters",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.52491000",
    longitude: "-121.97080000",
  },
  {
    id: 129454,
    name: "Winton",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.38938000",
    longitude: "-120.61325000",
  },
  {
    id: 129467,
    name: "Wofford Heights",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "35.70690000",
    longitude: "-118.45620000",
  },
  {
    id: 129493,
    name: "Woodacre",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.01270000",
    longitude: "-122.64526000",
  },
  {
    id: 129502,
    name: "Woodbridge",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.15408000",
    longitude: "-121.30134000",
  },
  {
    id: 129516,
    name: "Woodcrest",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.88224000",
    longitude: "-117.35727000",
  },
  {
    id: 129524,
    name: "Woodlake",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.41356000",
    longitude: "-119.09872000",
  },
  {
    id: 129526,
    name: "Woodland",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.67852000",
    longitude: "-121.77330000",
  },
  {
    id: 129530,
    name: "Woodland Hills",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.16834000",
    longitude: "-118.60592000",
  },
  {
    id: 129556,
    name: "Woodside",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.42994000",
    longitude: "-122.25386000",
  },
  {
    id: 129574,
    name: "Woodville",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "36.09356000",
    longitude: "-119.19900000",
  },
  {
    id: 129617,
    name: "Wrightwood",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.36083000",
    longitude: "-117.63339000",
  },
  {
    id: 129686,
    name: "Yolo County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.68665000",
    longitude: "-121.90162000",
  },
  {
    id: 129689,
    name: "Yorba Linda",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "33.88863000",
    longitude: "-117.81311000",
  },
  {
    id: 129711,
    name: "Yosemite Lakes",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.19106000",
    longitude: "-119.77265000",
  },
  {
    id: 129712,
    name: "Yosemite Valley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "37.74075000",
    longitude: "-119.57788000",
  },
  {
    id: 129723,
    name: "Yountville",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "38.40158000",
    longitude: "-122.36081000",
  },
  {
    id: 129725,
    name: "Yreka",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "41.73542000",
    longitude: "-122.63447000",
  },
  {
    id: 129726,
    name: "Yuba City",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.14045000",
    longitude: "-121.61691000",
  },
  {
    id: 129727,
    name: "Yuba County",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "39.26902000",
    longitude: "-121.35126000",
  },
  {
    id: 129728,
    name: "Yucaipa",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.03363000",
    longitude: "-117.04309000",
  },
  {
    id: 129729,
    name: "Yucca Valley",
    state_id: 1416,
    state_code: "CA",
    country_id: 233,
    country_code: "US",
    latitude: "34.11417000",
    longitude: "-116.43224000",
  },
  {
    id: 110998,
    name: "Acres Green",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.55666000",
    longitude: "-104.89609000",
  },
  {
    id: 111024,
    name: "Adams County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.87363000",
    longitude: "-104.33791000",
  },
  {
    id: 111070,
    name: "Air Force Academy",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.99425000",
    longitude: "-104.86375000",
  },
  {
    id: 111080,
    name: "Akron",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.16054000",
    longitude: "-103.21438000",
  },
  {
    id: 111098,
    name: "Alamosa",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.46945000",
    longitude: "-105.87002000",
  },
  {
    id: 111099,
    name: "Alamosa County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.57289000",
    longitude: "-105.78829000",
  },
  {
    id: 111100,
    name: "Alamosa East",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.47735000",
    longitude: "-105.84217000",
  },
  {
    id: 111399,
    name: "Applewood",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.75778000",
    longitude: "-105.16250000",
  },
  {
    id: 111415,
    name: "Arapahoe County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.64977000",
    longitude: "-104.33924000",
  },
  {
    id: 111434,
    name: "Archuleta County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.19360000",
    longitude: "-107.04833000",
  },
  {
    id: 111452,
    name: "Aristocrat Ranchettes",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.10915000",
    longitude: "-104.76247000",
  },
  {
    id: 111503,
    name: "Arvada",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.80276000",
    longitude: "-105.08748000",
  },
  {
    id: 111552,
    name: "Aspen",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.19110000",
    longitude: "-106.81754000",
  },
  {
    id: 111653,
    name: "Ault",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.58248000",
    longitude: "-104.73191000",
  },
  {
    id: 111662,
    name: "Aurora",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.72943000",
    longitude: "-104.83192000",
  },
  {
    id: 111695,
    name: "Avon",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.63137000",
    longitude: "-106.52225000",
  },
  {
    id: 111717,
    name: "Baca County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.31918000",
    longitude: "-102.56047000",
  },
  {
    id: 111875,
    name: "Basalt",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.36887000",
    longitude: "-107.03282000",
  },
  {
    id: 111910,
    name: "Battlement Mesa",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.44137000",
    longitude: "-108.02507000",
  },
  {
    id: 111941,
    name: "Bayfield",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.22556000",
    longitude: "-107.59811000",
  },
  {
    id: 112173,
    name: "Bennett",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.75887000",
    longitude: "-104.42746000",
  },
  {
    id: 112188,
    name: "Bent County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.95509000",
    longitude: "-103.07170000",
  },
  {
    id: 112231,
    name: "Berkley",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.80443000",
    longitude: "-105.02609000",
  },
  {
    id: 112257,
    name: "Berthoud",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.30832000",
    longitude: "-105.08109000",
  },
  {
    id: 112376,
    name: "Black Forest",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.01305000",
    longitude: "-104.70081000",
  },
  {
    id: 112600,
    name: "Boulder",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.01499000",
    longitude: "-105.27055000",
  },
  {
    id: 112603,
    name: "Boulder County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.09246000",
    longitude: "-105.35770000",
  },
  {
    id: 112713,
    name: "Breckenridge",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.48165000",
    longitude: "-106.03835000",
  },
  {
    id: 112791,
    name: "Brighton",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.98526000",
    longitude: "-104.82053000",
  },
  {
    id: 112900,
    name: "Broomfield",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.92054000",
    longitude: "-105.08665000",
  },
  {
    id: 112901,
    name: "Broomfield County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.95413000",
    longitude: "-105.05266000",
  },
  {
    id: 112953,
    name: "Brush",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.25887000",
    longitude: "-103.62384000",
  },
  {
    id: 113004,
    name: "Buena Vista",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.84222000",
    longitude: "-106.13113000",
  },
  {
    id: 113074,
    name: "Burlington",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.30611000",
    longitude: "-102.26936000",
  },
  {
    id: 113134,
    name: "Byers",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.71137000",
    longitude: "-104.22774000",
  },
  {
    id: 113307,
    name: "Campion",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.34943000",
    longitude: "-105.07776000",
  },
  {
    id: 113597,
    name: "Cañon City",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.44098000",
    longitude: "-105.24245000",
  },
  {
    id: 113390,
    name: "Carbondale",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.40221000",
    longitude: "-107.21116000",
  },
  {
    id: 113443,
    name: "Carriage Club",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.53249000",
    longitude: "-104.90109000",
  },
  {
    id: 113518,
    name: "Cascade-Chipita Park",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.94354000",
    longitude: "-105.00237000",
  },
  {
    id: 113546,
    name: "Castle Pines",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.45804000",
    longitude: "-104.89609000",
  },
  {
    id: 113547,
    name: "Castle Pines North",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.47174000",
    longitude: "-104.89482000",
  },
  {
    id: 113549,
    name: "Castle Rock",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.37221000",
    longitude: "-104.85609000",
  },
  {
    id: 113555,
    name: "Castlewood",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.58471000",
    longitude: "-104.90109000",
  },
  {
    id: 113625,
    name: "Cedaredge",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.90165000",
    longitude: "-107.92645000",
  },
  {
    id: 113634,
    name: "Centennial",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.57916000",
    longitude: "-104.87692000",
  },
  {
    id: 113638,
    name: "Center",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.75306000",
    longitude: "-106.10864000",
  },
  {
    id: 113671,
    name: "Central City",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.80193000",
    longitude: "-105.51416000",
  },
  {
    id: 113710,
    name: "Chaffee County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.74690000",
    longitude: "-106.19407000",
  },
  {
    id: 113859,
    name: "Cherry Creek",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.63455000",
    longitude: "-104.88286000",
  },
  {
    id: 113863,
    name: "Cherry Hills Village",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.64165000",
    longitude: "-104.95943000",
  },
  {
    id: 113927,
    name: "Cheyenne County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.82794000",
    longitude: "-102.60340000",
  },
  {
    id: 113930,
    name: "Cheyenne Wells",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.82140000",
    longitude: "-102.35324000",
  },
  {
    id: 114025,
    name: "Cimarron Hills",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.85861000",
    longitude: "-104.69886000",
  },
  {
    id: 114208,
    name: "Clear Creek County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.68910000",
    longitude: "-105.64436000",
  },
  {
    id: 114261,
    name: "Clifton",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.09193000",
    longitude: "-108.44898000",
  },
  {
    id: 114329,
    name: "Coal Creek",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.90638000",
    longitude: "-105.37749000",
  },
  {
    id: 114455,
    name: "Colorado City",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.94529000",
    longitude: "-104.83526000",
  },
  {
    id: 114458,
    name: "Colorado Springs",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.83388000",
    longitude: "-104.82136000",
  },
  {
    id: 114488,
    name: "Columbine",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.58777000",
    longitude: "-105.06943000",
  },
  {
    id: 114489,
    name: "Columbine Valley",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.60110000",
    longitude: "-105.03221000",
  },
  {
    id: 114526,
    name: "Commerce City",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.80832000",
    longitude: "-104.93387000",
  },
  {
    id: 114552,
    name: "Conejos",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.08835000",
    longitude: "-106.01974000",
  },
  {
    id: 114553,
    name: "Conejos County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.20070000",
    longitude: "-106.19163000",
  },
  {
    id: 114674,
    name: "Cortez",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.34888000",
    longitude: "-108.58593000",
  },
  {
    id: 114687,
    name: "Costilla County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.27810000",
    longitude: "-105.42827000",
  },
  {
    id: 114755,
    name: "Craig",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.51525000",
    longitude: "-107.54645000",
  },
  {
    id: 114787,
    name: "Creede",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.84917000",
    longitude: "-106.92643000",
  },
  {
    id: 114804,
    name: "Crested Butte",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.86971000",
    longitude: "-106.98782000",
  },
  {
    id: 114821,
    name: "Cripple Creek",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.74666000",
    longitude: "-105.17831000",
  },
  {
    id: 114865,
    name: "Crowley County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.32666000",
    longitude: "-103.78483000",
  },
  {
    id: 114937,
    name: "Custer County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.10868000",
    longitude: "-105.36747000",
  },
  {
    id: 114962,
    name: "Dacono",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.08471000",
    longitude: "-104.93942000",
  },
  {
    id: 115190,
    name: "Del Norte",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.67889000",
    longitude: "-106.35337000",
  },
  {
    id: 115222,
    name: "Delta",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.74221000",
    longitude: "-108.06896000",
  },
  {
    id: 115226,
    name: "Delta County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.86137000",
    longitude: "-107.86288000",
  },
  {
    id: 115253,
    name: "Denver",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.73915000",
    longitude: "-104.98470000",
  },
  {
    id: 115255,
    name: "Denver County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.76204000",
    longitude: "-104.87635000",
  },
  {
    id: 115262,
    name: "Derby",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.83943000",
    longitude: "-104.91859000",
  },
  {
    id: 115391,
    name: "Dolores County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.75160000",
    longitude: "-108.51722000",
  },
  {
    id: 115433,
    name: "Douglas County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.32972000",
    longitude: "-104.92956000",
  },
  {
    id: 115442,
    name: "Dove Creek",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.76610000",
    longitude: "-108.90594000",
  },
  {
    id: 115443,
    name: "Dove Valley",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.57771000",
    longitude: "-104.82940000",
  },
  {
    id: 115565,
    name: "Durango",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.27528000",
    longitude: "-107.88007000",
  },
  {
    id: 115592,
    name: "Eads",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.48056000",
    longitude: "-102.78186000",
  },
  {
    id: 115597,
    name: "Eagle",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.65526000",
    longitude: "-106.82865000",
  },
  {
    id: 115600,
    name: "Eagle County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.62783000",
    longitude: "-106.69530000",
  },
  {
    id: 115802,
    name: "Eaton",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.53026000",
    longitude: "-104.71135000",
  },
  {
    id: 115855,
    name: "Edgewater",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.75304000",
    longitude: "-105.06415000",
  },
  {
    id: 115887,
    name: "Edwards",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.64499000",
    longitude: "-106.59420000",
  },
  {
    id: 115925,
    name: "El Jebel",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.39498000",
    longitude: "-107.09033000",
  },
  {
    id: 115931,
    name: "El Paso County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.83209000",
    longitude: "-104.52558000",
  },
  {
    id: 115943,
    name: "Elbert County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.28656000",
    longitude: "-104.13589000",
  },
  {
    id: 115974,
    name: "Elizabeth",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.36027000",
    longitude: "-104.59691000",
  },
  {
    id: 116023,
    name: "Ellicott",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.83833000",
    longitude: "-104.38691000",
  },
  {
    id: 116133,
    name: "Englewood",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.64777000",
    longitude: "-104.98776000",
  },
  {
    id: 116167,
    name: "Erie",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.05026000",
    longitude: "-105.04998000",
  },
  {
    id: 116208,
    name: "Estes Park",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.37721000",
    longitude: "-105.52167000",
  },
  {
    id: 116246,
    name: "Evans",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.37637000",
    longitude: "-104.69219000",
  },
  {
    id: 116263,
    name: "Evergreen",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.63332000",
    longitude: "-105.31721000",
  },
  {
    id: 116344,
    name: "Fairplay",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.22471000",
    longitude: "-106.00196000",
  },
  {
    id: 116464,
    name: "Federal Heights",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.85137000",
    longitude: "-104.99859000",
  },
  {
    id: 116521,
    name: "Firestone",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.11248000",
    longitude: "-104.93664000",
  },
  {
    id: 116585,
    name: "Florence",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.39028000",
    longitude: "-105.11860000",
  },
  {
    id: 116699,
    name: "Fort Carson",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.73749000",
    longitude: "-104.78886000",
  },
  {
    id: 116701,
    name: "Fort Collins",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.58526000",
    longitude: "-105.08442000",
  },
  {
    id: 116726,
    name: "Fort Lupton",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.08471000",
    longitude: "-104.81303000",
  },
  {
    id: 116733,
    name: "Fort Morgan",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.25026000",
    longitude: "-103.79995000",
  },
  {
    id: 116778,
    name: "Fountain",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.68222000",
    longitude: "-104.70081000",
  },
  {
    id: 116796,
    name: "Fowler",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.12917000",
    longitude: "-104.02329000",
  },
  {
    id: 116881,
    name: "Fraser",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.94499000",
    longitude: "-105.81723000",
  },
  {
    id: 116887,
    name: "Frederick",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.09915000",
    longitude: "-104.93720000",
  },
  {
    id: 116926,
    name: "Fremont County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.47297000",
    longitude: "-105.43966000",
  },
  {
    id: 116956,
    name: "Frisco",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.57443000",
    longitude: "-106.09752000",
  },
  {
    id: 116968,
    name: "Fruita",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.15887000",
    longitude: "-108.72899000",
  },
  {
    id: 116976,
    name: "Fruitvale",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.08165000",
    longitude: "-108.49676000",
  },
  {
    id: 117084,
    name: "Garfield County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.59931000",
    longitude: "-107.90395000",
  },
  {
    id: 117134,
    name: "Genesee",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.68582000",
    longitude: "-105.27277000",
  },
  {
    id: 117164,
    name: "Georgetown",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.70610000",
    longitude: "-105.69750000",
  },
  {
    id: 117209,
    name: "Gilcrest",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.28193000",
    longitude: "-104.77775000",
  },
  {
    id: 117224,
    name: "Gilpin County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.85756000",
    longitude: "-105.52253000",
  },
  {
    id: 117287,
    name: "Glendale",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.70499000",
    longitude: "-104.93359000",
  },
  {
    id: 117292,
    name: "Gleneagle",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.04527000",
    longitude: "-104.82442000",
  },
  {
    id: 117318,
    name: "Glenwood Springs",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.55054000",
    longitude: "-107.32478000",
  },
  {
    id: 117346,
    name: "Golden",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.75554000",
    longitude: "-105.22110000",
  },
  {
    id: 117448,
    name: "Granby",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.08610000",
    longitude: "-105.93946000",
  },
  {
    id: 117456,
    name: "Grand County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.10261000",
    longitude: "-106.11836000",
  },
  {
    id: 117465,
    name: "Grand Junction",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.06387000",
    longitude: "-108.55065000",
  },
  {
    id: 117586,
    name: "Greeley",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.42331000",
    longitude: "-104.70913000",
  },
  {
    id: 117712,
    name: "Greenwood Village",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.61721000",
    longitude: "-104.95081000",
  },
  {
    id: 117801,
    name: "Gunbarrel",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.06335000",
    longitude: "-105.17107000",
  },
  {
    id: 117802,
    name: "Gunnison",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.54582000",
    longitude: "-106.92532000",
  },
  {
    id: 117804,
    name: "Gunnison County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.66680000",
    longitude: "-107.03170000",
  },
  {
    id: 117822,
    name: "Gypsum",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.64693000",
    longitude: "-106.95171000",
  },
  {
    id: 118178,
    name: "Hayden",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.49529000",
    longitude: "-107.25729000",
  },
  {
    id: 118420,
    name: "Highlands Ranch",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.55388000",
    longitude: "-104.96943000",
  },
  {
    id: 118487,
    name: "Hinsdale County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.82134000",
    longitude: "-107.30031000",
  },
  {
    id: 118558,
    name: "Holly Hills",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.66757000",
    longitude: "-104.91797000",
  },
  {
    id: 118584,
    name: "Holyoke",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.58444000",
    longitude: "-102.30241000",
  },
  {
    id: 118687,
    name: "Hot Sulphur Springs",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.07304000",
    longitude: "-106.10280000",
  },
  {
    id: 118747,
    name: "Hudson",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.07359000",
    longitude: "-104.64302000",
  },
  {
    id: 118755,
    name: "Huerfano County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.68468000",
    longitude: "-104.96058000",
  },
  {
    id: 118767,
    name: "Hugo",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.13610000",
    longitude: "-103.46994000",
  },
  {
    id: 118872,
    name: "Idaho Springs",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.74249000",
    longitude: "-105.51361000",
  },
  {
    id: 118908,
    name: "Indian Hills",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.61665000",
    longitude: "-105.23721000",
  },
  {
    id: 118958,
    name: "Inverness",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.57738000",
    longitude: "-104.86135000",
  },
  {
    id: 119094,
    name: "Jackson County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.66643000",
    longitude: "-106.34279000",
  },
  {
    id: 119194,
    name: "Jefferson County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.58642000",
    longitude: "-105.25047000",
  },
  {
    id: 119286,
    name: "Johnstown",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.33693000",
    longitude: "-104.91220000",
  },
  {
    id: 119329,
    name: "Julesburg",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.98833000",
    longitude: "-102.26435000",
  },
  {
    id: 119422,
    name: "Keenesburg",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.10832000",
    longitude: "-104.51996000",
  },
  {
    id: 119439,
    name: "Ken Caryl",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.57582000",
    longitude: "-105.11221000",
  },
  {
    id: 119519,
    name: "Kersey",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.38748000",
    longitude: "-104.56163000",
  },
  {
    id: 119547,
    name: "Keystone",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.59943000",
    longitude: "-105.98724000",
  },
  {
    id: 119645,
    name: "Kiowa",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.34721000",
    longitude: "-104.46441000",
  },
  {
    id: 119648,
    name: "Kiowa County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.43269000",
    longitude: "-102.74034000",
  },
  {
    id: 119659,
    name: "Kit Carson County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.30544000",
    longitude: "-102.60289000",
  },
  {
    id: 119666,
    name: "Kittredge",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.65471000",
    longitude: "-105.29971000",
  },
  {
    id: 119716,
    name: "Kremmling",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.05887000",
    longitude: "-106.38892000",
  },
  {
    id: 119762,
    name: "La Junta",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.98501000",
    longitude: "-103.54383000",
  },
  {
    id: 119775,
    name: "La Plata County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.28656000",
    longitude: "-107.84334000",
  },
  {
    id: 119786,
    name: "La Salle",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.34887000",
    longitude: "-104.70191000",
  },
  {
    id: 119836,
    name: "Lafayette",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.99360000",
    longitude: "-105.08971000",
  },
  {
    id: 119886,
    name: "Lake City",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.03000000",
    longitude: "-107.31533000",
  },
  {
    id: 119897,
    name: "Lake County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.20238000",
    longitude: "-106.34484000",
  },
  {
    id: 120050,
    name: "Lakewood",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.70471000",
    longitude: "-105.08137000",
  },
  {
    id: 120060,
    name: "Lamar",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.08723000",
    longitude: "-102.62075000",
  },
  {
    id: 120136,
    name: "Laporte",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.62633000",
    longitude: "-105.13916000",
  },
  {
    id: 120145,
    name: "Larimer County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.66641000",
    longitude: "-105.46116000",
  },
  {
    id: 120153,
    name: "Las Animas",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.06667000",
    longitude: "-103.22271000",
  },
  {
    id: 120154,
    name: "Las Animas County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.31585000",
    longitude: "-104.03872000",
  },
  {
    id: 120271,
    name: "Leadville",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.25082000",
    longitude: "-106.29252000",
  },
  {
    id: 120272,
    name: "Leadville North",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.25760000",
    longitude: "-106.30138000",
  },
  {
    id: 120498,
    name: "Limon",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.26388000",
    longitude: "-103.69217000",
  },
  {
    id: 120530,
    name: "Lincoln County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.98807000",
    longitude: "-103.51397000",
  },
  {
    id: 120545,
    name: "Lincoln Park",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.42916000",
    longitude: "-105.21999000",
  },
  {
    id: 120636,
    name: "Littleton",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.61332000",
    longitude: "-105.01665000",
  },
  {
    id: 120670,
    name: "Lochbuie",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.00721000",
    longitude: "-104.71608000",
  },
  {
    id: 120703,
    name: "Logan County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.72468000",
    longitude: "-103.11010000",
  },
  {
    id: 120711,
    name: "Loma",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.19581000",
    longitude: "-108.81316000",
  },
  {
    id: 120732,
    name: "Lone Tree",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.55171000",
    longitude: "-104.88630000",
  },
  {
    id: 120754,
    name: "Longmont",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.16721000",
    longitude: "-105.10193000",
  },
  {
    id: 120817,
    name: "Louisville",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.97776000",
    longitude: "-105.13193000",
  },
  {
    id: 120822,
    name: "Loveland",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.39776000",
    longitude: "-105.07498000",
  },
  {
    id: 120945,
    name: "Lyons",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.22471000",
    longitude: "-105.27138000",
  },
  {
    id: 121105,
    name: "Mancos",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.34500000",
    longitude: "-108.28925000",
  },
  {
    id: 121126,
    name: "Manitou Springs",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.85971000",
    longitude: "-104.91720000",
  },
  {
    id: 121552,
    name: "Mead",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.23332000",
    longitude: "-104.99859000",
  },
  {
    id: 121613,
    name: "Meeker",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.03747000",
    longitude: "-107.91313000",
  },
  {
    id: 121694,
    name: "Meridian",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.53957000",
    longitude: "-104.84528000",
  },
  {
    id: 121721,
    name: "Mesa County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.01828000",
    longitude: "-108.46645000",
  },
  {
    id: 121890,
    name: "Milliken",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.32943000",
    longitude: "-104.85525000",
  },
  {
    id: 121935,
    name: "Mineral County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.66900000",
    longitude: "-106.92409000",
  },
  {
    id: 121969,
    name: "Minturn",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.58637000",
    longitude: "-106.43086000",
  },
  {
    id: 122016,
    name: "Moffat County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.61843000",
    longitude: "-108.20730000",
  },
  {
    id: 122111,
    name: "Monte Vista",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.57917000",
    longitude: "-106.14808000",
  },
  {
    id: 122129,
    name: "Montezuma County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.33841000",
    longitude: "-108.59671000",
  },
  {
    id: 122184,
    name: "Montrose",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.47832000",
    longitude: "-107.87617000",
  },
  {
    id: 122185,
    name: "Montrose County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.40218000",
    longitude: "-108.26936000",
  },
  {
    id: 122191,
    name: "Monument",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.09166000",
    longitude: "-104.87276000",
  },
  {
    id: 122244,
    name: "Morgan County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.26271000",
    longitude: "-103.80982000",
  },
  {
    id: 122435,
    name: "Mountain Village",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.93138000",
    longitude: "-107.85645000",
  },
  {
    id: 122611,
    name: "Nederland",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.96138000",
    longitude: "-105.51083000",
  },
  {
    id: 122689,
    name: "New Castle",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.57276000",
    longitude: "-107.53644000",
  },
  {
    id: 122911,
    name: "Niwot",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.10387000",
    longitude: "-105.17082000",
  },
  {
    id: 123155,
    name: "Northglenn",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.88554000",
    longitude: "-104.98720000",
  },
  {
    id: 123413,
    name: "Olathe",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.60499000",
    longitude: "-107.98229000",
  },
  {
    id: 123532,
    name: "Orchard City",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.82832000",
    longitude: "-107.97090000",
  },
  {
    id: 123537,
    name: "Orchard Mesa",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.04304000",
    longitude: "-108.55232000",
  },
  {
    id: 123543,
    name: "Ordway",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.21806000",
    longitude: "-103.75606000",
  },
  {
    id: 123625,
    name: "Otero County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.90270000",
    longitude: "-103.71645000",
  },
  {
    id: 123646,
    name: "Ouray",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.02277000",
    longitude: "-107.67145000",
  },
  {
    id: 123647,
    name: "Ouray County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.15550000",
    longitude: "-107.76932000",
  },
  {
    id: 123722,
    name: "Pagosa Springs",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.26945000",
    longitude: "-107.00976000",
  },
  {
    id: 123738,
    name: "Palisade",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.11026000",
    longitude: "-108.35092000",
  },
  {
    id: 123762,
    name: "Palmer Lake",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.12221000",
    longitude: "-104.91720000",
  },
  {
    id: 123813,
    name: "Paonia",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.86832000",
    longitude: "-107.59200000",
  },
  {
    id: 123815,
    name: "Parachute",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.45192000",
    longitude: "-108.05285000",
  },
  {
    id: 123841,
    name: "Park County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.11930000",
    longitude: "-105.71717000",
  },
  {
    id: 123863,
    name: "Parker",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.51860000",
    longitude: "-104.76136000",
  },
  {
    id: 124043,
    name: "Penrose",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.42500000",
    longitude: "-105.02276000",
  },
  {
    id: 124084,
    name: "Perry Park",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.25666000",
    longitude: "-104.99248000",
  },
  {
    id: 124137,
    name: "Phillips County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.59388000",
    longitude: "-102.35758000",
  },
  {
    id: 124286,
    name: "Pitkin County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.21711000",
    longitude: "-106.91658000",
  },
  {
    id: 124358,
    name: "Platteville",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.21498000",
    longitude: "-104.82275000",
  },
  {
    id: 124470,
    name: "Ponderosa Park",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.40832000",
    longitude: "-104.65108000",
  },
  {
    id: 124722,
    name: "Prowers County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.95518000",
    longitude: "-102.39335000",
  },
  {
    id: 124728,
    name: "Pueblo",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.25445000",
    longitude: "-104.60914000",
  },
  {
    id: 124729,
    name: "Pueblo County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.17342000",
    longitude: "-104.51285000",
  },
  {
    id: 124730,
    name: "Pueblo West",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.35000000",
    longitude: "-104.72275000",
  },
  {
    id: 124881,
    name: "Rangely",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.08748000",
    longitude: "-108.80483000",
  },
  {
    id: 124977,
    name: "Redlands",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.07887000",
    longitude: "-108.63565000",
  },
  {
    id: 125136,
    name: "Rifle",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.53470000",
    longitude: "-107.78312000",
  },
  {
    id: 125148,
    name: "Rio Blanco County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.97984000",
    longitude: "-108.21721000",
  },
  {
    id: 125155,
    name: "Rio Grande County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.58252000",
    longitude: "-106.38321000",
  },
  {
    id: 125334,
    name: "Rocky Ford",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.05251000",
    longitude: "-103.72023000",
  },
  {
    id: 125476,
    name: "Routt County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.48507000",
    longitude: "-106.99119000",
  },
  {
    id: 125485,
    name: "Roxborough Park",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.47388000",
    longitude: "-105.08526000",
  },
  {
    id: 125591,
    name: "Saguache",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.08750000",
    longitude: "-106.14197000",
  },
  {
    id: 125592,
    name: "Saguache County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.08055000",
    longitude: "-106.28156000",
  },
  {
    id: 125743,
    name: "Salida",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.53472000",
    longitude: "-105.99890000",
  },
  {
    id: 125820,
    name: "San Juan County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.76404000",
    longitude: "-107.67615000",
  },
  {
    id: 125828,
    name: "San Luis",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.20085000",
    longitude: "-105.42390000",
  },
  {
    id: 125840,
    name: "San Miguel County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.00374000",
    longitude: "-108.40583000",
  },
  {
    id: 126113,
    name: "Security-Widefield",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.74728000",
    longitude: "-104.71439000",
  },
  {
    id: 126120,
    name: "Sedgwick County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.87591000",
    longitude: "-102.35179000",
  },
  {
    id: 126177,
    name: "Severance",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.52415000",
    longitude: "-104.85108000",
  },
  {
    id: 126246,
    name: "Shaw Heights",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.85250000",
    longitude: "-105.04306000",
  },
  {
    id: 126320,
    name: "Sheridan",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.64693000",
    longitude: "-105.02526000",
  },
  {
    id: 126337,
    name: "Sherrelwood",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.83776000",
    longitude: "-105.00137000",
  },
  {
    id: 126419,
    name: "Silt",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.54859000",
    longitude: "-107.65617000",
  },
  {
    id: 126440,
    name: "Silverthorne",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.63214000",
    longitude: "-106.07428000",
  },
  {
    id: 126441,
    name: "Silverton",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.81194000",
    longitude: "-107.66451000",
  },
  {
    id: 126548,
    name: "Snowmass Village",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.21304000",
    longitude: "-106.93782000",
  },
  {
    id: 126776,
    name: "Southglenn",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.58721000",
    longitude: "-104.95276000",
  },
  {
    id: 126906,
    name: "Springfield",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.40835000",
    longitude: "-102.61436000",
  },
  {
    id: 127003,
    name: "Steamboat Springs",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.48498000",
    longitude: "-106.83172000",
  },
  {
    id: 127030,
    name: "Sterling",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.62554000",
    longitude: "-103.20771000",
  },
  {
    id: 127088,
    name: "Stonegate",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.53082000",
    longitude: "-104.80386000",
  },
  {
    id: 127121,
    name: "Strasburg",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.73832000",
    longitude: "-104.32329000",
  },
  {
    id: 127132,
    name: "Stratmoor",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.77388000",
    longitude: "-104.77970000",
  },
  {
    id: 127216,
    name: "Summit County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.63417000",
    longitude: "-106.11638000",
  },
  {
    id: 127281,
    name: "Superior",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.95276000",
    longitude: "-105.16860000",
  },
  {
    id: 127489,
    name: "Teller County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.88217000",
    longitude: "-105.16183000",
  },
  {
    id: 127491,
    name: "Telluride",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.93749000",
    longitude: "-107.81229000",
  },
  {
    id: 127563,
    name: "The Pinery",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.45527000",
    longitude: "-104.73442000",
  },
  {
    id: 127603,
    name: "Thornton",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.86804000",
    longitude: "-104.97192000",
  },
  {
    id: 127695,
    name: "Todd Creek",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.97804000",
    longitude: "-104.87331000",
  },
  {
    id: 127753,
    name: "Towaoc",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.20444000",
    longitude: "-108.72954000",
  },
  {
    id: 127823,
    name: "Trinidad",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.16946000",
    longitude: "-104.50054000",
  },
  {
    id: 127931,
    name: "Twin Lakes",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.82499000",
    longitude: "-105.00470000",
  },
  {
    id: 128041,
    name: "Upper Bear Creek",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.62385000",
    longitude: "-105.41780000",
  },
  {
    id: 128076,
    name: "Vail",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.64026000",
    longitude: "-106.37420000",
  },
  {
    id: 128369,
    name: "Walden",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.73164000",
    longitude: "-106.28364000",
  },
  {
    id: 128426,
    name: "Walsenburg",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "37.62418000",
    longitude: "-104.78026000",
  },
  {
    id: 128577,
    name: "Washington County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.97106000",
    longitude: "-103.20125000",
  },
  {
    id: 128764,
    name: "Welby",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.83665000",
    longitude: "-104.95915000",
  },
  {
    id: 128768,
    name: "Weld County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.55484000",
    longitude: "-104.39253000",
  },
  {
    id: 128778,
    name: "Wellington",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.70387000",
    longitude: "-105.00859000",
  },
  {
    id: 128957,
    name: "West Pleasant View",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.73256000",
    longitude: "-105.17852000",
  },
  {
    id: 129021,
    name: "Westcliffe",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.13472000",
    longitude: "-105.46584000",
  },
  {
    id: 129047,
    name: "Westminster",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.83665000",
    longitude: "-105.03720000",
  },
  {
    id: 129107,
    name: "Wheat Ridge",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.76610000",
    longitude: "-105.07721000",
  },
  {
    id: 129387,
    name: "Windsor",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.47748000",
    longitude: "-104.90136000",
  },
  {
    id: 129533,
    name: "Woodland Park",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "38.99388000",
    longitude: "-105.05693000",
  },
  {
    id: 129543,
    name: "Woodmoor",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "39.10138000",
    longitude: "-104.84748000",
  },
  {
    id: 129602,
    name: "Wray",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.07582000",
    longitude: "-102.22325000",
  },
  {
    id: 129734,
    name: "Yuma",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.12221000",
    longitude: "-102.72521000",
  },
  {
    id: 129736,
    name: "Yuma County",
    state_id: 1450,
    state_code: "CO",
    country_id: 233,
    country_code: "US",
    latitude: "40.00290000",
    longitude: "-102.42423000",
  },
  {
    id: 111362,
    name: "Ansonia",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.34621000",
    longitude: "-73.07900000",
  },
  {
    id: 111781,
    name: "Baltic",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.61704000",
    longitude: "-72.08452000",
  },
  {
    id: 112280,
    name: "Bethel",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.37121000",
    longitude: "-73.41401000",
  },
  {
    id: 112292,
    name: "Bethlehem Village",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.64010000",
    longitude: "-73.20308000",
  },
  {
    id: 112468,
    name: "Blue Hills",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.81288000",
    longitude: "-72.69759000",
  },
  {
    id: 112693,
    name: "Branford",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.27954000",
    longitude: "-72.81510000",
  },
  {
    id: 112694,
    name: "Branford Center",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.27738000",
    longitude: "-72.81511000",
  },
  {
    id: 112766,
    name: "Bridgeport",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.17923000",
    longitude: "-73.18945000",
  },
  {
    id: 112809,
    name: "Bristol",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.67176000",
    longitude: "-72.94927000",
  },
  {
    id: 113140,
    name: "Byram",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.00426000",
    longitude: "-73.65374000",
  },
  {
    id: 113313,
    name: "Canaan",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "42.02731000",
    longitude: "-73.32928000",
  },
  {
    id: 113351,
    name: "Canton Valley",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.83426000",
    longitude: "-72.89177000",
  },
  {
    id: 113684,
    name: "Central Waterford",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.34504000",
    longitude: "-72.12948000",
  },
  {
    id: 113877,
    name: "Cheshire",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.49899000",
    longitude: "-72.90066000",
  },
  {
    id: 113879,
    name: "Cheshire Village",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.50260000",
    longitude: "-72.89952000",
  },
  {
    id: 113894,
    name: "Chester Center",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.40132000",
    longitude: "-72.45270000",
  },
  {
    id: 114071,
    name: "City of Milford (balance)",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.22374000",
    longitude: "-73.06164000",
  },
  {
    id: 114285,
    name: "Clinton",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.27871000",
    longitude: "-72.52759000",
  },
  {
    id: 114383,
    name: "Colchester",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.57565000",
    longitude: "-72.33203000",
  },
  {
    id: 114439,
    name: "Collinsville",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.81288000",
    longitude: "-72.92010000",
  },
  {
    id: 114566,
    name: "Conning Towers-Nautilus Park",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.38548000",
    longitude: "-72.06877000",
  },
  {
    id: 114684,
    name: "Cos Cob",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.03343000",
    longitude: "-73.59957000",
  },
  {
    id: 114733,
    name: "Coventry Lake",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.77232000",
    longitude: "-72.33258000",
  },
  {
    id: 114835,
    name: "Cromwell",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.59510000",
    longitude: "-72.64537000",
  },
  {
    id: 114879,
    name: "Crystal Lake",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.93176000",
    longitude: "-72.37842000",
  },
  {
    id: 115015,
    name: "Danbury",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.39482000",
    longitude: "-73.45401000",
  },
  {
    id: 115025,
    name: "Danielson",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.80260000",
    longitude: "-71.88591000",
  },
  {
    id: 115046,
    name: "Darien",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.07871000",
    longitude: "-73.46929000",
  },
  {
    id: 115170,
    name: "Deep River Center",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.38221000",
    longitude: "-72.43862000",
  },
  {
    id: 115261,
    name: "Derby",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.32065000",
    longitude: "-73.08900000",
  },
  {
    id: 115570,
    name: "Durham",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.48176000",
    longitude: "-72.68121000",
  },
  {
    id: 115640,
    name: "East Brooklyn",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.79677000",
    longitude: "-71.89729000",
  },
  {
    id: 115677,
    name: "East Haddam",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.45315000",
    longitude: "-72.46120000",
  },
  {
    id: 115678,
    name: "East Hampton",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.57593000",
    longitude: "-72.50259000",
  },
  {
    id: 115684,
    name: "East Hartford",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.78232000",
    longitude: "-72.61203000",
  },
  {
    id: 115686,
    name: "East Haven",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.27621000",
    longitude: "-72.86843000",
  },
  {
    id: 115723,
    name: "East Norwalk",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.10565000",
    longitude: "-73.39845000",
  },
  {
    id: 115774,
    name: "East Windsor",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.91232000",
    longitude: "-72.54509000",
  },
  {
    id: 115786,
    name: "Easton",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.25287000",
    longitude: "-73.29734000",
  },
  {
    id: 116026,
    name: "Ellington",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.90399000",
    longitude: "-72.46981000",
  },
  {
    id: 116125,
    name: "Enfield",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.97621000",
    longitude: "-72.59176000",
  },
  {
    id: 116200,
    name: "Essex Village",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.35544000",
    longitude: "-72.39101000",
  },
  {
    id: 116312,
    name: "Fairfield",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.14121000",
    longitude: "-73.26373000",
  },
  {
    id: 116320,
    name: "Fairfield County",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.22496000",
    longitude: "-73.37120000",
  },
  {
    id: 116415,
    name: "Farmington",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.71982000",
    longitude: "-72.83204000",
  },
  {
    id: 117026,
    name: "Gales Ferry",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.42982000",
    longitude: "-72.08202000",
  },
  {
    id: 117161,
    name: "Georgetown",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.25565000",
    longitude: "-73.43484000",
  },
  {
    id: 117255,
    name: "Glastonbury",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.71232000",
    longitude: "-72.60815000",
  },
  {
    id: 117256,
    name: "Glastonbury Center",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.70093000",
    longitude: "-72.59953000",
  },
  {
    id: 117311,
    name: "Glenville",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.03538000",
    longitude: "-73.65985000",
  },
  {
    id: 117698,
    name: "Greenwich",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.02649000",
    longitude: "-73.62846000",
  },
  {
    id: 117749,
    name: "Groton",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.35010000",
    longitude: "-72.07841000",
  },
  {
    id: 117784,
    name: "Guilford",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.28899000",
    longitude: "-72.68176000",
  },
  {
    id: 117786,
    name: "Guilford Center",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.28156000",
    longitude: "-72.67619000",
  },
  {
    id: 117886,
    name: "Hamden",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.39593000",
    longitude: "-72.89677000",
  },
  {
    id: 118087,
    name: "Hartford",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.76371000",
    longitude: "-72.68509000",
  },
  {
    id: 118095,
    name: "Hartford County",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.80642000",
    longitude: "-72.73284000",
  },
  {
    id: 118201,
    name: "Hazardville",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.98732000",
    longitude: "-72.54481000",
  },
  {
    id: 118234,
    name: "Hebron",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.65788000",
    longitude: "-72.36592000",
  },
  {
    id: 118322,
    name: "Heritage Village",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.48565000",
    longitude: "-73.23789000",
  },
  {
    id: 118383,
    name: "Higganum",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.49704000",
    longitude: "-72.55703000",
  },
  {
    id: 119245,
    name: "Jewett City",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.60677000",
    longitude: "-71.98091000",
  },
  {
    id: 119481,
    name: "Kensington",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.63538000",
    longitude: "-72.76871000",
  },
  {
    id: 119486,
    name: "Kent",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.72482000",
    longitude: "-73.47707000",
  },
  {
    id: 119561,
    name: "Killingly Center",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.83871000",
    longitude: "-71.86924000",
  },
  {
    id: 119965,
    name: "Lake Pocotopaug",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.59843000",
    longitude: "-72.51037000",
  },
  {
    id: 120302,
    name: "Ledyard",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.43982000",
    longitude: "-72.01424000",
  },
  {
    id: 120597,
    name: "Lisbon",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.60399000",
    longitude: "-72.01174000",
  },
  {
    id: 120604,
    name: "Litchfield",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.74732000",
    longitude: "-73.18872000",
  },
  {
    id: 120608,
    name: "Litchfield County",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.79249000",
    longitude: "-73.24532000",
  },
  {
    id: 120744,
    name: "Long Hill",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.35399000",
    longitude: "-72.05230000",
  },
  {
    id: 120992,
    name: "Madison",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.27954000",
    longitude: "-72.59843000",
  },
  {
    id: 121001,
    name: "Madison Center",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.27925000",
    longitude: "-72.60048000",
  },
  {
    id: 121094,
    name: "Manchester",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.77593000",
    longitude: "-72.52148000",
  },
  {
    id: 121149,
    name: "Mansfield City",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.76593000",
    longitude: "-72.23369000",
  },
  {
    id: 121689,
    name: "Meriden",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.53815000",
    longitude: "-72.80704000",
  },
  {
    id: 121771,
    name: "Middlebury",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.52787000",
    longitude: "-73.12761000",
  },
  {
    id: 121779,
    name: "Middlesex County",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.43538000",
    longitude: "-72.52312000",
  },
  {
    id: 121788,
    name: "Middletown",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.56232000",
    longitude: "-72.65065000",
  },
  {
    id: 121847,
    name: "Milford",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.22232000",
    longitude: "-73.05650000",
  },
  {
    id: 122189,
    name: "Montville Center",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.47899000",
    longitude: "-72.15119000",
  },
  {
    id: 122194,
    name: "Moodus",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.50288000",
    longitude: "-72.45009000",
  },
  {
    id: 122217,
    name: "Moosup",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.71288000",
    longitude: "-71.88091000",
  },
  {
    id: 122526,
    name: "Mystic",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.35427000",
    longitude: "-71.96646000",
  },
  {
    id: 122595,
    name: "Naugatuck",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.48593000",
    longitude: "-73.05066000",
  },
  {
    id: 122674,
    name: "New Britain",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.66121000",
    longitude: "-72.77954000",
  },
  {
    id: 122679,
    name: "New Canaan",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.14676000",
    longitude: "-73.49484000",
  },
  {
    id: 122705,
    name: "New Fairfield",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.46648000",
    longitude: "-73.48568000",
  },
  {
    id: 122713,
    name: "New Hartford Center",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.87996000",
    longitude: "-72.97530000",
  },
  {
    id: 122716,
    name: "New Haven",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.30815000",
    longitude: "-72.92816000",
  },
  {
    id: 122719,
    name: "New Haven County",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.34882000",
    longitude: "-72.89986000",
  },
  {
    id: 122741,
    name: "New London",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.35565000",
    longitude: "-72.09952000",
  },
  {
    id: 122746,
    name: "New London County",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.46678000",
    longitude: "-72.10650000",
  },
  {
    id: 122754,
    name: "New Milford",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.57704000",
    longitude: "-73.40845000",
  },
  {
    id: 122767,
    name: "New Preston",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.67510000",
    longitude: "-73.35179000",
  },
  {
    id: 122831,
    name: "Newington",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.69788000",
    longitude: "-72.72371000",
  },
  {
    id: 122875,
    name: "Newtown",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.41398000",
    longitude: "-73.30345000",
  },
  {
    id: 122883,
    name: "Niantic",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.32538000",
    longitude: "-72.19313000",
  },
  {
    id: 122915,
    name: "Noank",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.32788000",
    longitude: "-71.99063000",
  },
  {
    id: 122995,
    name: "North Branford",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.32760000",
    longitude: "-72.76732000",
  },
  {
    id: 123031,
    name: "North Granby",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.99593000",
    longitude: "-72.82954000",
  },
  {
    id: 123033,
    name: "North Grosvenor Dale",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.98565000",
    longitude: "-71.89868000",
  },
  {
    id: 123037,
    name: "North Haven",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.39093000",
    longitude: "-72.85954000",
  },
  {
    id: 123111,
    name: "North Stamford",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.13815000",
    longitude: "-73.54346000",
  },
  {
    id: 123177,
    name: "Northwest Harwinton",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.77685000",
    longitude: "-73.07922000",
  },
  {
    id: 123190,
    name: "Norwalk",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.11760000",
    longitude: "-73.40790000",
  },
  {
    id: 123195,
    name: "Norwich",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.52426000",
    longitude: "-72.07591000",
  },
  {
    id: 123307,
    name: "Oakville",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.59343000",
    longitude: "-73.08539000",
  },
  {
    id: 123420,
    name: "Old Greenwich",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.02287000",
    longitude: "-73.56485000",
  },
  {
    id: 123423,
    name: "Old Mystic",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.39149000",
    longitude: "-71.96174000",
  },
  {
    id: 123427,
    name: "Old Saybrook",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.29177000",
    longitude: "-72.37620000",
  },
  {
    id: 123428,
    name: "Old Saybrook Center",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.29150000",
    longitude: "-72.36528000",
  },
  {
    id: 123507,
    name: "Orange",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.27843000",
    longitude: "-73.02566000",
  },
  {
    id: 123681,
    name: "Oxford",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.43399000",
    longitude: "-73.11678000",
  },
  {
    id: 123690,
    name: "Oxoboxo River",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.44391000",
    longitude: "-72.12502000",
  },
  {
    id: 123929,
    name: "Pawcatuck",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.37732000",
    longitude: "-71.83368000",
  },
  {
    id: 124002,
    name: "Pemberwick",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.02565000",
    longitude: "-73.66068000",
  },
  {
    id: 124319,
    name: "Plainfield",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.67649000",
    longitude: "-71.91507000",
  },
  {
    id: 124322,
    name: "Plainfield Village",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.67686000",
    longitude: "-71.92489000",
  },
  {
    id: 124334,
    name: "Plainville",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.67454000",
    longitude: "-72.85816000",
  },
  {
    id: 124400,
    name: "Plymouth",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.67204000",
    longitude: "-73.05289000",
  },
  {
    id: 124488,
    name: "Poquonock Bridge",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.34510000",
    longitude: "-72.02480000",
  },
  {
    id: 124559,
    name: "Portland",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.57288000",
    longitude: "-72.64065000",
  },
  {
    id: 124658,
    name: "Preston City",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.52899000",
    longitude: "-71.97396000",
  },
  {
    id: 124706,
    name: "Prospect",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.50232000",
    longitude: "-72.97872000",
  },
  {
    id: 124760,
    name: "Putnam",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.91510000",
    longitude: "-71.90896000",
  },
  {
    id: 124801,
    name: "Quinebaug",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "42.02371000",
    longitude: "-71.94980000",
  },
  {
    id: 125119,
    name: "Ridgefield",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.28148000",
    longitude: "-73.49818000",
  },
  {
    id: 125204,
    name: "Riverside",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.03371000",
    longitude: "-73.57818000",
  },
  {
    id: 125323,
    name: "Rockville",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.86676000",
    longitude: "-72.44953000",
  },
  {
    id: 125732,
    name: "Salem",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.49038000",
    longitude: "-72.27536000",
  },
  {
    id: 125764,
    name: "Salmon Brook",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.95649000",
    longitude: "-72.79537000",
  },
  {
    id: 125986,
    name: "Saybrook Manor",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.28538000",
    longitude: "-72.39897000",
  },
  {
    id: 126196,
    name: "Seymour",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.39676000",
    longitude: "-73.07594000",
  },
  {
    id: 126297,
    name: "Shelton",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.31649000",
    longitude: "-73.09316000",
  },
  {
    id: 126331,
    name: "Sherman",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.57926000",
    longitude: "-73.49568000",
  },
  {
    id: 126343,
    name: "Sherwood Manor",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "42.01343000",
    longitude: "-72.56425000",
  },
  {
    id: 126453,
    name: "Simsbury Center",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.88088000",
    longitude: "-72.81116000",
  },
  {
    id: 126575,
    name: "Somers",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.98537000",
    longitude: "-72.44620000",
  },
  {
    id: 126644,
    name: "South Coventry",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.77010000",
    longitude: "-72.30508000",
  },
  {
    id: 126751,
    name: "South Windham",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.67954000",
    longitude: "-72.17036000",
  },
  {
    id: 126753,
    name: "South Windsor",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.82371000",
    longitude: "-72.62120000",
  },
  {
    id: 126754,
    name: "South Woodstock",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.93899000",
    longitude: "-71.95952000",
  },
  {
    id: 126763,
    name: "Southbury",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.48148000",
    longitude: "-73.21317000",
  },
  {
    id: 126784,
    name: "Southport",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.13649000",
    longitude: "-73.28345000",
  },
  {
    id: 126792,
    name: "Southwood Acres",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.96260000",
    longitude: "-72.57148000",
  },
  {
    id: 126934,
    name: "Stafford",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.98482000",
    longitude: "-72.28897000",
  },
  {
    id: 126938,
    name: "Stafford Springs",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.95426000",
    longitude: "-72.30230000",
  },
  {
    id: 126944,
    name: "Stamford",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.05343000",
    longitude: "-73.53873000",
  },
  {
    id: 127105,
    name: "Storrs",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.80843000",
    longitude: "-72.24952000",
  },
  {
    id: 127124,
    name: "Stratford",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.18454000",
    longitude: "-73.13317000",
  },
  {
    id: 127165,
    name: "Suffield Depot",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.98121000",
    longitude: "-72.64981000",
  },
  {
    id: 127430,
    name: "Tariffville",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.90871000",
    longitude: "-72.76010000",
  },
  {
    id: 127519,
    name: "Terramuggus",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.63510000",
    longitude: "-72.47036000",
  },
  {
    id: 127535,
    name: "Terryville",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.67815000",
    longitude: "-73.01094000",
  },
  {
    id: 127584,
    name: "Thomaston",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.67399000",
    longitude: "-73.07316000",
  },
  {
    id: 127590,
    name: "Thompson",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.95871000",
    longitude: "-71.86257000",
  },
  {
    id: 127594,
    name: "Thompsonville",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.99704000",
    longitude: "-72.59898000",
  },
  {
    id: 127701,
    name: "Tolland",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.87149000",
    longitude: "-72.36869000",
  },
  {
    id: 127702,
    name: "Tolland County",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.85501000",
    longitude: "-72.33649000",
  },
  {
    id: 127744,
    name: "Torrington",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.80065000",
    longitude: "-73.12122000",
  },
  {
    id: 127858,
    name: "Trumbull",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.24287000",
    longitude: "-73.20067000",
  },
  {
    id: 127967,
    name: "Uncasville",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.43454000",
    longitude: "-72.10980000",
  },
  {
    id: 128406,
    name: "Wallingford",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.45704000",
    longitude: "-72.82316000",
  },
  {
    id: 128407,
    name: "Wallingford Center",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.44987000",
    longitude: "-72.81892000",
  },
  {
    id: 128545,
    name: "Washington",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.63148000",
    longitude: "-73.31067000",
  },
  {
    id: 128602,
    name: "Waterbury",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.55815000",
    longitude: "-73.05150000",
  },
  {
    id: 128610,
    name: "Waterford",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.34170000",
    longitude: "-72.13597000",
  },
  {
    id: 128620,
    name: "Watertown",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.60621000",
    longitude: "-73.11817000",
  },
  {
    id: 128658,
    name: "Wauregan",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.74427000",
    longitude: "-71.90924000",
  },
  {
    id: 128720,
    name: "Weatogue",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.84371000",
    longitude: "-72.82843000",
  },
  {
    id: 128885,
    name: "West Hartford",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.76204000",
    longitude: "-72.74204000",
  },
  {
    id: 128887,
    name: "West Haven",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.27065000",
    longitude: "-72.94705000",
  },
  {
    id: 128982,
    name: "West Simsbury",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.87315000",
    longitude: "-72.85815000",
  },
  {
    id: 128991,
    name: "West Torrington",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.81843000",
    longitude: "-73.14372000",
  },
  {
    id: 129014,
    name: "Westbrook Center",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.27997000",
    longitude: "-72.44254000",
  },
  {
    id: 129072,
    name: "Westport",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.14149000",
    longitude: "-73.35790000",
  },
  {
    id: 129090,
    name: "Wethersfield",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.71427000",
    longitude: "-72.65259000",
  },
  {
    id: 129293,
    name: "Willimantic",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.71065000",
    longitude: "-72.20813000",
  },
  {
    id: 129342,
    name: "Wilton",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.19537000",
    longitude: "-73.43790000",
  },
  {
    id: 129362,
    name: "Winchester Center",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.90010000",
    longitude: "-73.13483000",
  },
  {
    id: 129371,
    name: "Windham",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.69982000",
    longitude: "-72.15702000",
  },
  {
    id: 129373,
    name: "Windham County",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.83003000",
    longitude: "-71.98749000",
  },
  {
    id: 129382,
    name: "Windsor",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.85260000",
    longitude: "-72.64370000",
  },
  {
    id: 129390,
    name: "Windsor Locks",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.92926000",
    longitude: "-72.62731000",
  },
  {
    id: 129430,
    name: "Winsted",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.92121000",
    longitude: "-73.06011000",
  },
  {
    id: 129468,
    name: "Wolcott",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.60232000",
    longitude: "-72.98677000",
  },
  {
    id: 129499,
    name: "Woodbridge",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.35260000",
    longitude: "-73.00844000",
  },
  {
    id: 129508,
    name: "Woodbury",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.54454000",
    longitude: "-73.20900000",
  },
  {
    id: 129511,
    name: "Woodbury Center",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.54453000",
    longitude: "-73.20476000",
  },
  {
    id: 129542,
    name: "Woodmont",
    state_id: 1435,
    state_code: "CT",
    country_id: 233,
    country_code: "US",
    latitude: "41.22815000",
    longitude: "-72.99149000",
  },
  {
    id: 111974,
    name: "Bear",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.62928000",
    longitude: "-75.65826000",
  },
  {
    id: 112098,
    name: "Bellefonte",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.76622000",
    longitude: "-75.50936000",
  },
  {
    id: 112277,
    name: "Bethany Beach",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "38.53956000",
    longitude: "-75.05518000",
  },
  {
    id: 112401,
    name: "Blades",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "38.63567000",
    longitude: "-75.60993000",
  },
  {
    id: 112772,
    name: "Bridgeville",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "38.74261000",
    longitude: "-75.60437000",
  },
  {
    id: 112887,
    name: "Brookside",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.66706000",
    longitude: "-75.72688000",
  },
  {
    id: 113261,
    name: "Camden",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.11345000",
    longitude: "-75.54187000",
  },
  {
    id: 113914,
    name: "Cheswold",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.21928000",
    longitude: "-75.58576000",
  },
  {
    id: 114192,
    name: "Claymont",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.80067000",
    longitude: "-75.45964000",
  },
  {
    id: 114197,
    name: "Clayton",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.29067000",
    longitude: "-75.63437000",
  },
  {
    id: 115203,
    name: "Delaware City",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.57789000",
    longitude: "-75.58881000",
  },
  {
    id: 115217,
    name: "Delmar",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "38.45651000",
    longitude: "-75.57715000",
  },
  {
    id: 115445,
    name: "Dover",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.15817000",
    longitude: "-75.52437000",
  },
  {
    id: 115453,
    name: "Dover Base Housing",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.11763000",
    longitude: "-75.48393000",
  },
  {
    id: 115847,
    name: "Edgemoor",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.75011000",
    longitude: "-75.49964000",
  },
  {
    id: 116070,
    name: "Elsmere",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.73928000",
    longitude: "-75.59798000",
  },
  {
    id: 116470,
    name: "Felton",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.00845000",
    longitude: "-75.57798000",
  },
  {
    id: 117155,
    name: "Georgetown",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "38.69011000",
    longitude: "-75.38547000",
  },
  {
    id: 117245,
    name: "Glasgow",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.60483000",
    longitude: "-75.74521000",
  },
  {
    id: 117681,
    name: "Greenville",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.77900000",
    longitude: "-75.59826000",
  },
  {
    id: 117700,
    name: "Greenwood",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "38.80706000",
    longitude: "-75.59132000",
  },
  {
    id: 118041,
    name: "Harrington",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "38.92372000",
    longitude: "-75.57770000",
  },
  {
    id: 118401,
    name: "Highland Acres",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.12095000",
    longitude: "-75.52187000",
  },
  {
    id: 118503,
    name: "Hockessin",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.78761000",
    longitude: "-75.69660000",
  },
  {
    id: 119488,
    name: "Kent Acres",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.13178000",
    longitude: "-75.52492000",
  },
  {
    id: 119490,
    name: "Kent County",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.09595000",
    longitude: "-75.50461000",
  },
  {
    id: 120187,
    name: "Laurel",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "38.55650000",
    longitude: "-75.57131000",
  },
  {
    id: 120410,
    name: "Lewes",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "38.77456000",
    longitude: "-75.13935000",
  },
  {
    id: 120748,
    name: "Long Neck",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "38.62011000",
    longitude: "-75.15074000",
  },
  {
    id: 121785,
    name: "Middletown",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.44956000",
    longitude: "-75.71632000",
  },
  {
    id: 121846,
    name: "Milford",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "38.91261000",
    longitude: "-75.42797000",
  },
  {
    id: 121900,
    name: "Millsboro",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "38.59150000",
    longitude: "-75.29130000",
  },
  {
    id: 121911,
    name: "Milton",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "38.77761000",
    longitude: "-75.30991000",
  },
  {
    id: 122683,
    name: "New Castle",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.66206000",
    longitude: "-75.56631000",
  },
  {
    id: 122690,
    name: "New Castle County",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.57833000",
    longitude: "-75.63898000",
  },
  {
    id: 122799,
    name: "Newark",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.68372000",
    longitude: "-75.74966000",
  },
  {
    id: 122838,
    name: "Newport",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.71372000",
    longitude: "-75.60937000",
  },
  {
    id: 123112,
    name: "North Star",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.76122000",
    longitude: "-75.71910000",
  },
  {
    id: 123338,
    name: "Ocean View",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "38.54511000",
    longitude: "-75.08907000",
  },
  {
    id: 124194,
    name: "Pike Creek",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.73095000",
    longitude: "-75.70410000",
  },
  {
    id: 124195,
    name: "Pike Creek Valley",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.73622000",
    longitude: "-75.69827000",
  },
  {
    id: 125005,
    name: "Rehoboth Beach",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "38.72095000",
    longitude: "-75.07601000",
  },
  {
    id: 125171,
    name: "Rising Sun-Lebanon",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.09977000",
    longitude: "-75.50488000",
  },
  {
    id: 125217,
    name: "Riverview",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.02650000",
    longitude: "-75.51076000",
  },
  {
    id: 125342,
    name: "Rodney Village",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.13206000",
    longitude: "-75.53242000",
  },
  {
    id: 126085,
    name: "Seaford",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "38.64123000",
    longitude: "-75.61104000",
  },
  {
    id: 126132,
    name: "Selbyville",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "38.46039000",
    longitude: "-75.22074000",
  },
  {
    id: 126534,
    name: "Smyrna",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.29983000",
    longitude: "-75.60465000",
  },
  {
    id: 127303,
    name: "Sussex County",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "38.68330000",
    longitude: "-75.33954000",
  },
  {
    id: 127764,
    name: "Townsend",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.39511000",
    longitude: "-75.69160000",
  },
  {
    id: 129321,
    name: "Wilmington",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.74595000",
    longitude: "-75.54659000",
  },
  {
    id: 129326,
    name: "Wilmington Manor",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.68678000",
    longitude: "-75.58437000",
  },
  {
    id: 129557,
    name: "Woodside East",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.06756000",
    longitude: "-75.53748000",
  },
  {
    id: 129634,
    name: "Wyoming",
    state_id: 1399,
    state_code: "DE",
    country_id: 233,
    country_code: "US",
    latitude: "39.11817000",
    longitude: "-75.55881000",
  },
  {
    id: 111028,
    name: "Adams Morgan",
    state_id: 1437,
    state_code: "DC",
    country_id: 233,
    country_code: "US",
    latitude: "38.92150000",
    longitude: "-77.04220000",
  },
  {
    id: 112444,
    name: "Bloomingdale",
    state_id: 1437,
    state_code: "DC",
    country_id: 233,
    country_code: "US",
    latitude: "38.91678000",
    longitude: "-77.01137000",
  },
  {
    id: 113920,
    name: "Chevy Chase",
    state_id: 1437,
    state_code: "DC",
    country_id: 233,
    country_code: "US",
    latitude: "38.96400000",
    longitude: "-77.06776000",
  },
  {
    id: 126244,
    name: "Shaw",
    state_id: 1437,
    state_code: "DC",
    country_id: 233,
    country_code: "US",
    latitude: "38.91206000",
    longitude: "-77.02137000",
  },
  {
    id: 128587,
    name: "Washington, D.C.",
    state_id: 1437,
    state_code: "DC",
    country_id: 233,
    country_code: "US",
    latitude: "38.89511000",
    longitude: "-77.03637000",
  },
  {
    id: 110972,
    name: "Aberdeen",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.55063000",
    longitude: "-80.14866000",
  },
  {
    id: 111084,
    name: "Alachua",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.75163000",
    longitude: "-82.42483000",
  },
  {
    id: 111085,
    name: "Alachua County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.67476000",
    longitude: "-82.35770000",
  },
  {
    id: 111086,
    name: "Alafaya",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.56410000",
    longitude: "-81.21140000",
  },
  {
    id: 111177,
    name: "Allapattah",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.81454000",
    longitude: "-80.22394000",
  },
  {
    id: 111237,
    name: "Altamonte Springs",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.66111000",
    longitude: "-81.36562000",
  },
  {
    id: 111250,
    name: "Alturas",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.87169000",
    longitude: "-81.71508000",
  },
  {
    id: 111255,
    name: "Alva",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.71562000",
    longitude: "-81.61008000",
  },
  {
    id: 111321,
    name: "Andover",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.96843000",
    longitude: "-80.21283000",
  },
  {
    id: 111348,
    name: "Anna Maria",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.53115000",
    longitude: "-82.73343000",
  },
  {
    id: 111382,
    name: "Apalachicola",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.72600000",
    longitude: "-84.98560000",
  },
  {
    id: 111388,
    name: "Apollo Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.77308000",
    longitude: "-82.40759000",
  },
  {
    id: 111389,
    name: "Apopka",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.67617000",
    longitude: "-81.51186000",
  },
  {
    id: 111420,
    name: "Arcadia",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.21588000",
    longitude: "-81.85842000",
  },
  {
    id: 111429,
    name: "Archer",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.52997000",
    longitude: "-82.51900000",
  },
  {
    id: 111507,
    name: "Asbury Lake",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.04913000",
    longitude: "-81.82149000",
  },
  {
    id: 111559,
    name: "Astatula",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.70972000",
    longitude: "-81.73285000",
  },
  {
    id: 111560,
    name: "Astor",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.16248000",
    longitude: "-81.52535000",
  },
  {
    id: 111595,
    name: "Atlantic Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.33441000",
    longitude: "-81.39870000",
  },
  {
    id: 111601,
    name: "Atlantis",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.59090000",
    longitude: "-80.10088000",
  },
  {
    id: 111636,
    name: "Auburndale",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.06530000",
    longitude: "-81.78869000",
  },
  {
    id: 111678,
    name: "Aventura",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.95648000",
    longitude: "-80.13921000",
  },
  {
    id: 111696,
    name: "Avon Park",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.59587000",
    longitude: "-81.50619000",
  },
  {
    id: 111709,
    name: "Azalea Park",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.54111000",
    longitude: "-81.30062000",
  },
  {
    id: 111715,
    name: "Babson Park",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.83197000",
    longitude: "-81.52230000",
  },
  {
    id: 111726,
    name: "Bagdad",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.59880000",
    longitude: "-87.03223000",
  },
  {
    id: 111741,
    name: "Baker County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.33107000",
    longitude: "-82.28459000",
  },
  {
    id: 111747,
    name: "Bal Harbour",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.89176000",
    longitude: "-80.12699000",
  },
  {
    id: 111753,
    name: "Baldwin",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.30274000",
    longitude: "-81.97539000",
  },
  {
    id: 111778,
    name: "Balm",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.75947000",
    longitude: "-82.26120000",
  },
  {
    id: 111872,
    name: "Bartow",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.89641000",
    longitude: "-81.84314000",
  },
  {
    id: 111924,
    name: "Bay County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.23765000",
    longitude: "-85.63262000",
  },
  {
    id: 111927,
    name: "Bay Harbor Islands",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.88759000",
    longitude: "-80.13116000",
  },
  {
    id: 111928,
    name: "Bay Hill",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.46806000",
    longitude: "-81.51618000",
  },
  {
    id: 111931,
    name: "Bay Pines",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.81419000",
    longitude: "-82.77816000",
  },
  {
    id: 111944,
    name: "Bayonet Point",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.32667000",
    longitude: "-82.68343000",
  },
  {
    id: 111954,
    name: "Bayshore Gardens",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.42532000",
    longitude: "-82.59038000",
  },
  {
    id: 111969,
    name: "Beacon Square",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.20862000",
    longitude: "-82.75538000",
  },
  {
    id: 112035,
    name: "Bee Ridge",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.28394000",
    longitude: "-82.48065000",
  },
  {
    id: 112073,
    name: "Bellair-Meadowbrook Terrace",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.17881000",
    longitude: "-81.74341000",
  },
  {
    id: 112082,
    name: "Belle Glade",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.68451000",
    longitude: "-80.66756000",
  },
  {
    id: 112083,
    name: "Belle Glade Camp",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.65757000",
    longitude: "-80.68284000",
  },
  {
    id: 112086,
    name: "Belle Isle",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.45834000",
    longitude: "-81.35924000",
  },
  {
    id: 112094,
    name: "Belleair",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.93585000",
    longitude: "-82.80621000",
  },
  {
    id: 112095,
    name: "Belleair Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.92308000",
    longitude: "-82.84316000",
  },
  {
    id: 112096,
    name: "Belleair Bluffs",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.92141000",
    longitude: "-82.81705000",
  },
  {
    id: 112102,
    name: "Belleview",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.05526000",
    longitude: "-82.06231000",
  },
  {
    id: 112128,
    name: "Bellview",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.46159000",
    longitude: "-87.31497000",
  },
  {
    id: 112303,
    name: "Beverly Hills",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.91692000",
    longitude: "-82.45815000",
  },
  {
    id: 112319,
    name: "Big Coppitt Key",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "24.59653000",
    longitude: "-81.66009000",
  },
  {
    id: 112329,
    name: "Big Pine Key",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "24.66987000",
    longitude: "-81.35397000",
  },
  {
    id: 112361,
    name: "Biscayne Park",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.88260000",
    longitude: "-80.18060000",
  },
  {
    id: 112369,
    name: "Bithlo",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.55472000",
    longitude: "-81.10645000",
  },
  {
    id: 112373,
    name: "Black Diamond",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.91248000",
    longitude: "-82.48593000",
  },
  {
    id: 112445,
    name: "Bloomingdale",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.89364000",
    longitude: "-82.24037000",
  },
  {
    id: 112459,
    name: "Blountstown",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.44379000",
    longitude: "-85.04744000",
  },
  {
    id: 112492,
    name: "Boca Del Mar",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.34508000",
    longitude: "-80.14671000",
  },
  {
    id: 112493,
    name: "Boca Pointe",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.33313000",
    longitude: "-80.15949000",
  },
  {
    id: 112494,
    name: "Boca Raton",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.35869000",
    longitude: "-80.08310000",
  },
  {
    id: 112511,
    name: "Bokeelia",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.70563000",
    longitude: "-82.15898000",
  },
  {
    id: 112536,
    name: "Bonifay",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.79186000",
    longitude: "-85.67965000",
  },
  {
    id: 112538,
    name: "Bonita Springs",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.33981000",
    longitude: "-81.77870000",
  },
  {
    id: 112606,
    name: "Boulevard Gardens",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.12326000",
    longitude: "-80.17997000",
  },
  {
    id: 112627,
    name: "Bowling Green",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.63837000",
    longitude: "-81.82397000",
  },
  {
    id: 112647,
    name: "Boyette",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.81753000",
    longitude: "-82.22259000",
  },
  {
    id: 112652,
    name: "Boynton Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.52535000",
    longitude: "-80.06643000",
  },
  {
    id: 112662,
    name: "Bradenton",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.49893000",
    longitude: "-82.57482000",
  },
  {
    id: 112663,
    name: "Bradenton Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.46698000",
    longitude: "-82.70399000",
  },
  {
    id: 112668,
    name: "Bradford County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.94996000",
    longitude: "-82.16878000",
  },
  {
    id: 112688,
    name: "Brandon",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.93780000",
    longitude: "-82.28592000",
  },
  {
    id: 112726,
    name: "Brent",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.46881000",
    longitude: "-87.23608000",
  },
  {
    id: 112738,
    name: "Brevard County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.30031000",
    longitude: "-80.70121000",
  },
  {
    id: 112802,
    name: "Bristol",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.43247000",
    longitude: "-84.97702000",
  },
  {
    id: 112828,
    name: "Broadview Park",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.09953000",
    longitude: "-80.20866000",
  },
  {
    id: 112844,
    name: "Bronson",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.44774000",
    longitude: "-82.64233000",
  },
  {
    id: 112880,
    name: "Brookridge",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.55110000",
    longitude: "-82.49204000",
  },
  {
    id: 112890,
    name: "Brooksville",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.55554000",
    longitude: "-82.38991000",
  },
  {
    id: 112904,
    name: "Broward County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.15186000",
    longitude: "-80.45589000",
  },
  {
    id: 112905,
    name: "Broward Estates",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.12564000",
    longitude: "-80.19338000",
  },
  {
    id: 112928,
    name: "Brownsville",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.82176000",
    longitude: "-80.24116000",
  },
  {
    id: 112982,
    name: "Buckhead Ridge",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.13033000",
    longitude: "-80.89367000",
  },
  {
    id: 112984,
    name: "Buckingham",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.67507000",
    longitude: "-81.73203000",
  },
  {
    id: 113006,
    name: "Buenaventura Lakes",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.33584000",
    longitude: "-81.35313000",
  },
  {
    id: 113037,
    name: "Bunche Park",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.92065000",
    longitude: "-80.23699000",
  },
  {
    id: 113045,
    name: "Bunnell",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.46609000",
    longitude: "-81.25784000",
  },
  {
    id: 113092,
    name: "Burnt Store Marina",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.76507000",
    longitude: "-82.05092000",
  },
  {
    id: 113101,
    name: "Bushnell",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.66499000",
    longitude: "-82.11286000",
  },
  {
    id: 113113,
    name: "Butler Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.79830000",
    longitude: "-81.26701000",
  },
  {
    id: 113202,
    name: "Calhoun County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.40603000",
    longitude: "-85.19721000",
  },
  {
    id: 113222,
    name: "Callahan",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.56218000",
    longitude: "-81.83066000",
  },
  {
    id: 113224,
    name: "Callaway",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.15298000",
    longitude: "-85.56993000",
  },
  {
    id: 113296,
    name: "Campbell",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.25890000",
    longitude: "-81.45646000",
  },
  {
    id: 113352,
    name: "Cantonment",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.60853000",
    longitude: "-87.33998000",
  },
  {
    id: 113363,
    name: "Cape Canaveral",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.40584000",
    longitude: "-80.60477000",
  },
  {
    id: 113366,
    name: "Cape Coral",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.56285000",
    longitude: "-81.94953000",
  },
  {
    id: 113433,
    name: "Carol City",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.94065000",
    longitude: "-80.24560000",
  },
  {
    id: 113441,
    name: "Carrabelle",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.85326000",
    longitude: "-84.66435000",
  },
  {
    id: 113472,
    name: "Carrollwood",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.05002000",
    longitude: "-82.49287000",
  },
  {
    id: 113473,
    name: "Carrollwood Village",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.06752000",
    longitude: "-82.52093000",
  },
  {
    id: 113503,
    name: "Carver Ranches",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.98842000",
    longitude: "-80.19227000",
  },
  {
    id: 113535,
    name: "Casselberry",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.67778000",
    longitude: "-81.32785000",
  },
  {
    id: 113610,
    name: "Cedar Grove",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.17103000",
    longitude: "-85.62520000",
  },
  {
    id: 113629,
    name: "Celebration",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.32529000",
    longitude: "-81.53313000",
  },
  {
    id: 113643,
    name: "Center Hill",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.64999000",
    longitude: "-81.99258000",
  },
  {
    id: 113697,
    name: "Century",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.97324000",
    longitude: "-87.26386000",
  },
  {
    id: 113781,
    name: "Charlotte County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.89985000",
    longitude: "-81.95031000",
  },
  {
    id: 113785,
    name: "Charlotte Harbor",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.95839000",
    longitude: "-82.06703000",
  },
  {
    id: 113786,
    name: "Charlotte Park",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.91006000",
    longitude: "-82.05398000",
  },
  {
    id: 113808,
    name: "Chattahoochee",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.70546000",
    longitude: "-84.84574000",
  },
  {
    id: 113918,
    name: "Cheval",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.14862000",
    longitude: "-82.51454000",
  },
  {
    id: 113948,
    name: "Chiefland",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.47496000",
    longitude: "-82.85984000",
  },
  {
    id: 113972,
    name: "Chipley",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.78186000",
    longitude: "-85.53854000",
  },
  {
    id: 114001,
    name: "Christmas",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.53639000",
    longitude: "-81.01756000",
  },
  {
    id: 114006,
    name: "Chuluota",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.64194000",
    longitude: "-81.12340000",
  },
  {
    id: 114032,
    name: "Citra",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.41192000",
    longitude: "-82.10982000",
  },
  {
    id: 114036,
    name: "Citrus County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.84757000",
    longitude: "-82.52011000",
  },
  {
    id: 114038,
    name: "Citrus Hills",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.88831000",
    longitude: "-82.43260000",
  },
  {
    id: 114039,
    name: "Citrus Park",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.07835000",
    longitude: "-82.56982000",
  },
  {
    id: 114041,
    name: "Citrus Ridge",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.33385000",
    longitude: "-81.64232000",
  },
  {
    id: 114042,
    name: "Citrus Springs",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.99748000",
    longitude: "-82.47065000",
  },
  {
    id: 114101,
    name: "Clarcona",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.61278000",
    longitude: "-81.49868000",
  },
  {
    id: 114175,
    name: "Clay County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.98307000",
    longitude: "-81.85789000",
  },
  {
    id: 114222,
    name: "Clearwater",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.96585000",
    longitude: "-82.80010000",
  },
  {
    id: 114238,
    name: "Clermont",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.54944000",
    longitude: "-81.77285000",
  },
  {
    id: 114241,
    name: "Cleveland",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.96173000",
    longitude: "-81.98398000",
  },
  {
    id: 114252,
    name: "Clewiston",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.75423000",
    longitude: "-80.93368000",
  },
  {
    id: 114357,
    name: "Cocoa",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.38612000",
    longitude: "-80.74200000",
  },
  {
    id: 114358,
    name: "Cocoa Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.32055000",
    longitude: "-80.60922000",
  },
  {
    id: 114359,
    name: "Cocoa West",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.35942000",
    longitude: "-80.77109000",
  },
  {
    id: 114361,
    name: "Coconut Creek",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.25175000",
    longitude: "-80.17894000",
  },
  {
    id: 114362,
    name: "Coconut Grove",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.71260000",
    longitude: "-80.25699000",
  },
  {
    id: 114423,
    name: "Collier County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.09924000",
    longitude: "-81.38097000",
  },
  {
    id: 114478,
    name: "Columbia County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.22424000",
    longitude: "-82.62154000",
  },
  {
    id: 114515,
    name: "Combee Settlement",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.05835000",
    longitude: "-81.90536000",
  },
  {
    id: 114565,
    name: "Connerton",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.31441000",
    longitude: "-82.47539000",
  },
  {
    id: 114582,
    name: "Conway",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.50278000",
    longitude: "-81.33062000",
  },
  {
    id: 114602,
    name: "Cooper City",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.05731000",
    longitude: "-80.27172000",
  },
  {
    id: 114624,
    name: "Coral Gables",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.72149000",
    longitude: "-80.26838000",
  },
  {
    id: 114626,
    name: "Coral Springs",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.27119000",
    longitude: "-80.27060000",
  },
  {
    id: 114627,
    name: "Coral Terrace",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.74593000",
    longitude: "-80.30450000",
  },
  {
    id: 114673,
    name: "Cortez",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.46921000",
    longitude: "-82.68621000",
  },
  {
    id: 114714,
    name: "Country Club",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.94815000",
    longitude: "-80.31700000",
  },
  {
    id: 114724,
    name: "Country Walk",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.63399000",
    longitude: "-80.43228000",
  },
  {
    id: 114785,
    name: "Crawfordville",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.17604000",
    longitude: "-84.37518000",
  },
  {
    id: 114795,
    name: "Crescent City",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.43025000",
    longitude: "-81.51063000",
  },
  {
    id: 114807,
    name: "Crestview",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.76213000",
    longitude: "-86.57051000",
  },
  {
    id: 114838,
    name: "Crooked Lake Park",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.82919000",
    longitude: "-81.58397000",
  },
  {
    id: 114847,
    name: "Cross City",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.63465000",
    longitude: "-83.12694000",
  },
  {
    id: 114878,
    name: "Crystal Lake",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.03558000",
    longitude: "-81.90841000",
  },
  {
    id: 114882,
    name: "Crystal River",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.90248000",
    longitude: "-82.59260000",
  },
  {
    id: 114883,
    name: "Crystal Springs",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.18140000",
    longitude: "-82.15758000",
  },
  {
    id: 114891,
    name: "Cudjoe Key",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "24.67153000",
    longitude: "-81.49842000",
  },
  {
    id: 114946,
    name: "Cutler",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.61510000",
    longitude: "-80.31061000",
  },
  {
    id: 114948,
    name: "Cutler Bay",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.57830000",
    longitude: "-80.33770000",
  },
  {
    id: 114949,
    name: "Cutler Ridge",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.58066000",
    longitude: "-80.34672000",
  },
  {
    id: 114955,
    name: "Cypress Gardens",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.99391000",
    longitude: "-81.69008000",
  },
  {
    id: 114957,
    name: "Cypress Lake",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.53813000",
    longitude: "-81.89925000",
  },
  {
    id: 114958,
    name: "Cypress Quarters",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.25199000",
    longitude: "-80.81395000",
  },
  {
    id: 114964,
    name: "Dade City",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.36472000",
    longitude: "-82.19592000",
  },
  {
    id: 114965,
    name: "Dade City North",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.38334000",
    longitude: "-82.19389000",
  },
  {
    id: 115021,
    name: "Dania Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.05231000",
    longitude: "-80.14393000",
  },
  {
    id: 115058,
    name: "Davenport",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.16140000",
    longitude: "-81.60174000",
  },
  {
    id: 115067,
    name: "Davie",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.06287000",
    longitude: "-80.23310000",
  },
  {
    id: 115102,
    name: "Daytona Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.21081000",
    longitude: "-81.02283000",
  },
  {
    id: 115103,
    name: "Daytona Beach Shores",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.17609000",
    longitude: "-80.98283000",
  },
  {
    id: 115108,
    name: "De Land Southwest",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.00770000",
    longitude: "-81.31129000",
  },
  {
    id: 115110,
    name: "De Leon Springs",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.11989000",
    longitude: "-81.35286000",
  },
  {
    id: 115124,
    name: "DeBary",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.88305000",
    longitude: "-81.30868000",
  },
  {
    id: 115184,
    name: "Deerfield Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.31841000",
    longitude: "-80.09977000",
  },
  {
    id: 115126,
    name: "DeFuniak Springs",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.72102000",
    longitude: "-86.11522000",
  },
  {
    id: 115134,
    name: "DeLand",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.02832000",
    longitude: "-81.30312000",
  },
  {
    id: 115221,
    name: "Delray Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.46146000",
    longitude: "-80.07282000",
  },
  {
    id: 115229,
    name: "Deltona",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.90054000",
    longitude: "-81.26367000",
  },
  {
    id: 115139,
    name: "DeSoto County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.18632000",
    longitude: "-81.80930000",
  },
  {
    id: 115287,
    name: "Desoto Lakes",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.37143000",
    longitude: "-82.48982000",
  },
  {
    id: 115289,
    name: "Destin",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.39353000",
    longitude: "-86.49578000",
  },
  {
    id: 115361,
    name: "Dixie County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.58124000",
    longitude: "-83.18703000",
  },
  {
    id: 115373,
    name: "Doctor Phillips",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.44945000",
    longitude: "-81.49229000",
  },
  {
    id: 115408,
    name: "Doral",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.81954000",
    longitude: "-80.35533000",
  },
  {
    id: 115446,
    name: "Dover",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.99419000",
    longitude: "-82.21953000",
  },
  {
    id: 115528,
    name: "Dundee",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.02252000",
    longitude: "-81.61924000",
  },
  {
    id: 115534,
    name: "Dunedin",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.01990000",
    longitude: "-82.77323000",
  },
  {
    id: 115550,
    name: "Dunnellon",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.04914000",
    longitude: "-82.46093000",
  },
  {
    id: 115580,
    name: "Duval County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.33544000",
    longitude: "-81.64801000",
  },
  {
    id: 115602,
    name: "Eagle Lake",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.97836000",
    longitude: "-81.75647000",
  },
  {
    id: 115638,
    name: "East Bronson",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.45928000",
    longitude: "-82.59040000",
  },
  {
    id: 115700,
    name: "East Lake",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.11085000",
    longitude: "-82.69482000",
  },
  {
    id: 115701,
    name: "East Lake-Orient Park",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.98269000",
    longitude: "-82.37878000",
  },
  {
    id: 115714,
    name: "East Milton",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.61519000",
    longitude: "-87.02163000",
  },
  {
    id: 115718,
    name: "East Naples",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.13842000",
    longitude: "-81.76648000",
  },
  {
    id: 115727,
    name: "East Palatka",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.65830000",
    longitude: "-81.59841000",
  },
  {
    id: 115731,
    name: "East Pensacola Heights",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.42881000",
    longitude: "-87.17997000",
  },
  {
    id: 115734,
    name: "East Perrine",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.60872000",
    longitude: "-80.33894000",
  },
  {
    id: 115793,
    name: "Eastpoint",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.73660000",
    longitude: "-84.87852000",
  },
  {
    id: 115808,
    name: "Eatonville",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.61472000",
    longitude: "-81.38062000",
  },
  {
    id: 115851,
    name: "Edgewater",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.98888000",
    longitude: "-80.90228000",
  },
  {
    id: 115857,
    name: "Edgewood",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.48612000",
    longitude: "-81.37229000",
  },
  {
    id: 115903,
    name: "Eglin Air Force Base",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.45907000",
    longitude: "-86.55026000",
  },
  {
    id: 115904,
    name: "Eglin Village",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.46298000",
    longitude: "-86.53940000",
  },
  {
    id: 115906,
    name: "Egypt Lake-Leto",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.01769000",
    longitude: "-82.50619000",
  },
  {
    id: 115933,
    name: "El Portal",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.85537000",
    longitude: "-80.19310000",
  },
  {
    id: 115962,
    name: "Elfers",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.21668000",
    longitude: "-82.72232000",
  },
  {
    id: 116019,
    name: "Ellenton",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.52171000",
    longitude: "-82.52760000",
  },
  {
    id: 116129,
    name: "Englewood",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.96201000",
    longitude: "-82.35260000",
  },
  {
    id: 116147,
    name: "Ensley",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.51881000",
    longitude: "-87.27275000",
  },
  {
    id: 116180,
    name: "Escambia County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.61440000",
    longitude: "-87.34136000",
  },
  {
    id: 116207,
    name: "Estero",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.43814000",
    longitude: "-81.80675000",
  },
  {
    id: 116241,
    name: "Eustis",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.85277000",
    longitude: "-81.68535000",
  },
  {
    id: 116360,
    name: "Fairview Shores",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.59111000",
    longitude: "-81.39424000",
  },
  {
    id: 116463,
    name: "Feather Sound",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.90079000",
    longitude: "-82.67349000",
  },
  {
    id: 116469,
    name: "Fellsmere",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.76781000",
    longitude: "-80.60144000",
  },
  {
    id: 116483,
    name: "Fern Park",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.64916000",
    longitude: "-81.35118000",
  },
  {
    id: 116485,
    name: "Fernandina Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.66968000",
    longitude: "-81.46259000",
  },
  {
    id: 116499,
    name: "Ferry Pass",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.51020000",
    longitude: "-87.21247000",
  },
  {
    id: 116525,
    name: "Fish Hawk",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.85058000",
    longitude: "-82.21092000",
  },
  {
    id: 116540,
    name: "Five Points",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.20912000",
    longitude: "-82.63735000",
  },
  {
    id: 116542,
    name: "Flagami",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.76232000",
    longitude: "-80.31616000",
  },
  {
    id: 116543,
    name: "Flagler Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.47498000",
    longitude: "-81.12700000",
  },
  {
    id: 116544,
    name: "Flagler County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.47115000",
    longitude: "-81.29299000",
  },
  {
    id: 116545,
    name: "Flagler Estates",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.64553000",
    longitude: "-81.45700000",
  },
  {
    id: 116560,
    name: "Fleming Island",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.09330000",
    longitude: "-81.71898000",
  },
  {
    id: 116574,
    name: "Floral City",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.74999000",
    longitude: "-82.29676000",
  },
  {
    id: 116593,
    name: "Florida City",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.44789000",
    longitude: "-80.47922000",
  },
  {
    id: 116594,
    name: "Florida Ridge",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.58031000",
    longitude: "-80.38672000",
  },
  {
    id: 116646,
    name: "Forest City",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.66678000",
    longitude: "-81.44334000",
  },
  {
    id: 116722,
    name: "Fort Lauderdale",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.12231000",
    longitude: "-80.14338000",
  },
  {
    id: 116728,
    name: "Fort Meade",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.75225000",
    longitude: "-81.80175000",
  },
  {
    id: 116734,
    name: "Fort Myers",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.62168000",
    longitude: "-81.84059000",
  },
  {
    id: 116735,
    name: "Fort Myers Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.45271000",
    longitude: "-81.95011000",
  },
  {
    id: 116736,
    name: "Fort Myers Shores",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.70924000",
    longitude: "-81.74592000",
  },
  {
    id: 116739,
    name: "Fort Pierce",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.44671000",
    longitude: "-80.32561000",
  },
  {
    id: 116740,
    name: "Fort Pierce North",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.47364000",
    longitude: "-80.35930000",
  },
  {
    id: 116741,
    name: "Fort Pierce South",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.40962000",
    longitude: "-80.35483000",
  },
  {
    id: 116759,
    name: "Fort Walton Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.42059000",
    longitude: "-86.61707000",
  },
  {
    id: 116785,
    name: "Fountainebleau",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.77288000",
    longitude: "-80.34783000",
  },
  {
    id: 116791,
    name: "Four Corners",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.33287000",
    longitude: "-81.64738000",
  },
  {
    id: 116845,
    name: "Franklin County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.81168000",
    longitude: "-84.80046000",
  },
  {
    id: 116910,
    name: "Freeport",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.49825000",
    longitude: "-86.13605000",
  },
  {
    id: 116965,
    name: "Frostproof",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.74586000",
    longitude: "-81.53063000",
  },
  {
    id: 116966,
    name: "Fruit Cove",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.11107000",
    longitude: "-81.64176000",
  },
  {
    id: 116973,
    name: "Fruitland Park",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.86138000",
    longitude: "-81.90647000",
  },
  {
    id: 116977,
    name: "Fruitville",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.32977000",
    longitude: "-82.45760000",
  },
  {
    id: 116980,
    name: "Fuller Heights",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.90919000",
    longitude: "-81.99814000",
  },
  {
    id: 117002,
    name: "Fussels Corner",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.05419000",
    longitude: "-81.86064000",
  },
  {
    id: 117006,
    name: "Gadsden County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.57947000",
    longitude: "-84.61360000",
  },
  {
    id: 117014,
    name: "Gainesville",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.65163000",
    longitude: "-82.32483000",
  },
  {
    id: 117049,
    name: "Gandy",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.86850000",
    longitude: "-82.61612000",
  },
  {
    id: 117123,
    name: "Gateway",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.57757000",
    longitude: "-81.75036000",
  },
  {
    id: 117140,
    name: "Geneva",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.73972000",
    longitude: "-81.11506000",
  },
  {
    id: 117191,
    name: "Gibsonia",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.11474000",
    longitude: "-81.97369000",
  },
  {
    id: 117193,
    name: "Gibsonton",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.85364000",
    longitude: "-82.38259000",
  },
  {
    id: 117197,
    name: "Gifford",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.67531000",
    longitude: "-80.40922000",
  },
  {
    id: 117208,
    name: "Gilchrist County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.72582000",
    longitude: "-82.80037000",
  },
  {
    id: 117233,
    name: "Glades County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.95648000",
    longitude: "-81.18898000",
  },
  {
    id: 117234,
    name: "Gladeview",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.83926000",
    longitude: "-80.23560000",
  },
  {
    id: 117277,
    name: "Glencoe",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.02582000",
    longitude: "-80.97200000",
  },
  {
    id: 117308,
    name: "Glenvar Heights",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.70760000",
    longitude: "-80.32561000",
  },
  {
    id: 117348,
    name: "Golden Gate",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.18787000",
    longitude: "-81.69509000",
  },
  {
    id: 117349,
    name: "Golden Glades",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.91176000",
    longitude: "-80.20033000",
  },
  {
    id: 117361,
    name: "Goldenrod",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.61028000",
    longitude: "-81.28868000",
  },
  {
    id: 117374,
    name: "Gonzalez",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.58158000",
    longitude: "-87.29136000",
  },
  {
    id: 117414,
    name: "Gotha",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.52778000",
    longitude: "-81.52313000",
  },
  {
    id: 117416,
    name: "Goulding",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.44298000",
    longitude: "-87.22247000",
  },
  {
    id: 117417,
    name: "Goulds",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.56261000",
    longitude: "-80.38228000",
  },
  {
    id: 117425,
    name: "Graceville",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.95685000",
    longitude: "-85.51660000",
  },
  {
    id: 117524,
    name: "Grant-Valkaria",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.93980000",
    longitude: "-80.57104000",
  },
  {
    id: 117581,
    name: "Greater Northdale",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.10545000",
    longitude: "-82.52594000",
  },
  {
    id: 117594,
    name: "Green Cove Springs",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.99191000",
    longitude: "-81.67815000",
  },
  {
    id: 117614,
    name: "Greenacres City",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.62368000",
    longitude: "-80.12532000",
  },
  {
    id: 117618,
    name: "Greenbriar",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.01128000",
    longitude: "-82.75272000",
  },
  {
    id: 117724,
    name: "Gretna",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.61714000",
    longitude: "-84.65991000",
  },
  {
    id: 117755,
    name: "Grove City",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.91423000",
    longitude: "-82.32704000",
  },
  {
    id: 117758,
    name: "Groveland",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.55805000",
    longitude: "-81.85119000",
  },
  {
    id: 117790,
    name: "Gulf Breeze",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.35714000",
    longitude: "-87.16386000",
  },
  {
    id: 117791,
    name: "Gulf County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.90862000",
    longitude: "-85.26101000",
  },
  {
    id: 117792,
    name: "Gulf Gate Estates",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.25173000",
    longitude: "-82.51471000",
  },
  {
    id: 117796,
    name: "Gulfport",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.74836000",
    longitude: "-82.70343000",
  },
  {
    id: 117845,
    name: "Haines City",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.11450000",
    longitude: "-81.62009000",
  },
  {
    id: 117869,
    name: "Hallandale Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.98120000",
    longitude: "-80.14838000",
  },
  {
    id: 117896,
    name: "Hamilton County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.49643000",
    longitude: "-82.94796000",
  },
  {
    id: 117989,
    name: "Harbor Bluffs",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.90947000",
    longitude: "-82.82760000",
  },
  {
    id: 117992,
    name: "Harbour Heights",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.99089000",
    longitude: "-82.00231000",
  },
  {
    id: 117993,
    name: "Hardee County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.49270000",
    longitude: "-81.80993000",
  },
  {
    id: 118021,
    name: "Harlem",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.73757000",
    longitude: "-80.95090000",
  },
  {
    id: 118024,
    name: "Harlem Heights",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.51619000",
    longitude: "-81.92787000",
  },
  {
    id: 118145,
    name: "Havana",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.62381000",
    longitude: "-84.41463000",
  },
  {
    id: 118149,
    name: "Haverhill",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.69118000",
    longitude: "-80.12004000",
  },
  {
    id: 118172,
    name: "Hawthorne",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.59191000",
    longitude: "-82.08732000",
  },
  {
    id: 118224,
    name: "Heathrow",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.76333000",
    longitude: "-81.37225000",
  },
  {
    id: 118289,
    name: "Hendry County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.55349000",
    longitude: "-81.16590000",
  },
  {
    id: 118321,
    name: "Heritage Pines",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.42522000",
    longitude: "-82.62111000",
  },
  {
    id: 118332,
    name: "Hernando",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.89998000",
    longitude: "-82.37454000",
  },
  {
    id: 118334,
    name: "Hernando Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.46944000",
    longitude: "-82.65927000",
  },
  {
    id: 118335,
    name: "Hernando County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.55617000",
    longitude: "-82.46849000",
  },
  {
    id: 118354,
    name: "Hialeah",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.85760000",
    longitude: "-80.27811000",
  },
  {
    id: 118355,
    name: "Hialeah Gardens",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.86510000",
    longitude: "-80.32450000",
  },
  {
    id: 118387,
    name: "High Point",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.54687000",
    longitude: "-82.52468000",
  },
  {
    id: 118389,
    name: "High Springs",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.82691000",
    longitude: "-82.59678000",
  },
  {
    id: 118402,
    name: "Highland Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.39952000",
    longitude: "-80.06560000",
  },
  {
    id: 118403,
    name: "Highland City",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.96530000",
    longitude: "-81.87786000",
  },
  {
    id: 118419,
    name: "Highlands County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.34340000",
    longitude: "-81.34097000",
  },
  {
    id: 118427,
    name: "Hiland Park",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.20103000",
    longitude: "-85.62687000",
  },
  {
    id: 118432,
    name: "Hill 'n Dale",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.51972000",
    longitude: "-82.29926000",
  },
  {
    id: 118444,
    name: "Hilliard",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.69107000",
    longitude: "-81.91733000",
  },
  {
    id: 118453,
    name: "Hillsboro Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.29397000",
    longitude: "-80.07893000",
  },
  {
    id: 118457,
    name: "Hillsborough County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.90623000",
    longitude: "-82.34692000",
  },
  {
    id: 118501,
    name: "Hobe Sound",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.05950000",
    longitude: "-80.13643000",
  },
  {
    id: 118525,
    name: "Holden Heights",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.49667000",
    longitude: "-81.38785000",
  },
  {
    id: 118529,
    name: "Holiday",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.18779000",
    longitude: "-82.73955000",
  },
  {
    id: 118542,
    name: "Holley",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.44686000",
    longitude: "-86.90691000",
  },
  {
    id: 118556,
    name: "Holly Hill",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.24359000",
    longitude: "-81.03756000",
  },
  {
    id: 118565,
    name: "Hollywood",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.01120000",
    longitude: "-80.14949000",
  },
  {
    id: 118570,
    name: "Holmes Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.49532000",
    longitude: "-82.71093000",
  },
  {
    id: 118571,
    name: "Holmes County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.86789000",
    longitude: "-85.81410000",
  },
  {
    id: 118601,
    name: "Homestead",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.46872000",
    longitude: "-80.47756000",
  },
  {
    id: 118610,
    name: "Homosassa",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.78137000",
    longitude: "-82.61510000",
  },
  {
    id: 118611,
    name: "Homosassa Springs",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.80359000",
    longitude: "-82.57593000",
  },
  {
    id: 118665,
    name: "Horizon West",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.43383000",
    longitude: "-81.62270000",
  },
  {
    id: 118724,
    name: "Howey-in-the-Hills",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.71694000",
    longitude: "-81.77341000",
  },
  {
    id: 118735,
    name: "Hudson",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.36445000",
    longitude: "-82.69343000",
  },
  {
    id: 118790,
    name: "Hunters Creek",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.36056000",
    longitude: "-81.42229000",
  },
  {
    id: 118839,
    name: "Hutchinson Island South",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.29949000",
    longitude: "-80.22045000",
  },
  {
    id: 118856,
    name: "Hypoluxo",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.56646000",
    longitude: "-80.05337000",
  },
  {
    id: 118880,
    name: "Immokalee",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.41869000",
    longitude: "-81.41730000",
  },
  {
    id: 118901,
    name: "Indialantic",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.08946000",
    longitude: "-80.56561000",
  },
  {
    id: 118902,
    name: "Indian Harbour Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.14890000",
    longitude: "-80.58839000",
  },
  {
    id: 118913,
    name: "Indian River County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.69639000",
    longitude: "-80.57409000",
  },
  {
    id: 118914,
    name: "Indian River Estates",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.36449000",
    longitude: "-80.30977000",
  },
  {
    id: 118915,
    name: "Indian River Shores",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.71670000",
    longitude: "-80.38422000",
  },
  {
    id: 118916,
    name: "Indian Rocks Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.87530000",
    longitude: "-82.85122000",
  },
  {
    id: 118917,
    name: "Indian Shores",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.86280000",
    longitude: "-82.84844000",
  },
  {
    id: 118928,
    name: "Indiantown",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.02728000",
    longitude: "-80.48561000",
  },
  {
    id: 118939,
    name: "Inglis",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.03025000",
    longitude: "-82.66872000",
  },
  {
    id: 118950,
    name: "Interlachen",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.62421000",
    longitude: "-81.89256000",
  },
  {
    id: 118955,
    name: "Inverness",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.83582000",
    longitude: "-82.33037000",
  },
  {
    id: 118959,
    name: "Inverness Highlands North",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.86420000",
    longitude: "-82.37688000",
  },
  {
    id: 118960,
    name: "Inverness Highlands South",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.80055000",
    longitude: "-82.33710000",
  },
  {
    id: 118961,
    name: "Inwood",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.03697000",
    longitude: "-81.76508000",
  },
  {
    id: 118968,
    name: "Iona",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.52036000",
    longitude: "-81.96398000",
  },
  {
    id: 119022,
    name: "Islamorada",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "24.92430000",
    longitude: "-80.62784000",
  },
  {
    id: 119028,
    name: "Island Walk",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.25099000",
    longitude: "-81.71101000",
  },
  {
    id: 119031,
    name: "Isle of Normandy",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.85287000",
    longitude: "-80.13505000",
  },
  {
    id: 119050,
    name: "Ives Estates",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.96231000",
    longitude: "-80.17671000",
  },
  {
    id: 119077,
    name: "Jackson County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.79539000",
    longitude: "-85.21546000",
  },
  {
    id: 119101,
    name: "Jacksonville",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.33218000",
    longitude: "-81.65565000",
  },
  {
    id: 119106,
    name: "Jacksonville Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.29469000",
    longitude: "-81.39314000",
  },
  {
    id: 119129,
    name: "Jan-Phyl Village",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.01474000",
    longitude: "-81.77175000",
  },
  {
    id: 119136,
    name: "Jasmine Estates",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.29306000",
    longitude: "-82.69010000",
  },
  {
    id: 119140,
    name: "Jasper",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.51827000",
    longitude: "-82.94819000",
  },
  {
    id: 119177,
    name: "Jefferson County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.42346000",
    longitude: "-83.90047000",
  },
  {
    id: 119222,
    name: "Jensen Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.25449000",
    longitude: "-80.22977000",
  },
  {
    id: 119336,
    name: "June Park",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.07224000",
    longitude: "-80.68006000",
  },
  {
    id: 119341,
    name: "Juno Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.87978000",
    longitude: "-80.05337000",
  },
  {
    id: 119342,
    name: "Jupiter",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.93422000",
    longitude: "-80.09421000",
  },
  {
    id: 119393,
    name: "Kathleen",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.12085000",
    longitude: "-82.02314000",
  },
  {
    id: 119444,
    name: "Kendale Lakes",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.70816000",
    longitude: "-80.40700000",
  },
  {
    id: 119445,
    name: "Kendall",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.67927000",
    longitude: "-80.31727000",
  },
  {
    id: 119448,
    name: "Kendall Green",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.25397000",
    longitude: "-80.12393000",
  },
  {
    id: 119450,
    name: "Kendall West",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.70650000",
    longitude: "-80.43880000",
  },
  {
    id: 119472,
    name: "Kenneth City",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.81558000",
    longitude: "-82.72010000",
  },
  {
    id: 119485,
    name: "Kensington Park",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.35949000",
    longitude: "-82.49649000",
  },
  {
    id: 119537,
    name: "Key Biscayne",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.69371000",
    longitude: "-80.16282000",
  },
  {
    id: 119539,
    name: "Key Largo",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.08652000",
    longitude: "-80.44728000",
  },
  {
    id: 119540,
    name: "Key Vista",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.19470000",
    longitude: "-82.77038000",
  },
  {
    id: 119541,
    name: "Key West",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "24.55524000",
    longitude: "-81.78163000",
  },
  {
    id: 119546,
    name: "Keystone",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.15585000",
    longitude: "-82.62121000",
  },
  {
    id: 119548,
    name: "Keystone Heights",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.78608000",
    longitude: "-82.03149000",
  },
  {
    id: 119607,
    name: "Kings Point",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.44535000",
    longitude: "-80.13977000",
  },
  {
    id: 119658,
    name: "Kissimmee",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.30468000",
    longitude: "-81.41667000",
  },
  {
    id: 119798,
    name: "LaBelle",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.76173000",
    longitude: "-81.43841000",
  },
  {
    id: 119820,
    name: "Lacoochee",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.46583000",
    longitude: "-82.17203000",
  },
  {
    id: 119829,
    name: "Lady Lake",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.91749000",
    longitude: "-81.92286000",
  },
  {
    id: 119839,
    name: "Lafayette County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.98552000",
    longitude: "-83.18107000",
  },
  {
    id: 119851,
    name: "Laguna Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.23965000",
    longitude: "-85.92410000",
  },
  {
    id: 119862,
    name: "Lake Alfred",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.09196000",
    longitude: "-81.72341000",
  },
  {
    id: 119870,
    name: "Lake Belvedere Estates",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.68923000",
    longitude: "-80.13338000",
  },
  {
    id: 119873,
    name: "Lake Butler",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.02274000",
    longitude: "-82.33956000",
  },
  {
    id: 119879,
    name: "Lake City",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.18968000",
    longitude: "-82.63929000",
  },
  {
    id: 119887,
    name: "Lake Clarke Shores",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.64534000",
    longitude: "-80.07588000",
  },
  {
    id: 119889,
    name: "Lake County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.76147000",
    longitude: "-81.71130000",
  },
  {
    id: 119909,
    name: "Lake Forest",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.97759000",
    longitude: "-80.18310000",
  },
  {
    id: 119917,
    name: "Lake Hamilton",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.04446000",
    longitude: "-81.62785000",
  },
  {
    id: 119919,
    name: "Lake Helen",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.98082000",
    longitude: "-81.23339000",
  },
  {
    id: 119930,
    name: "Lake Lorraine",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.44159000",
    longitude: "-86.56523000",
  },
  {
    id: 119933,
    name: "Lake Lucerne",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.96509000",
    longitude: "-80.24144000",
  },
  {
    id: 119936,
    name: "Lake Mack-Forest Hills",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.00074000",
    longitude: "-81.42397000",
  },
  {
    id: 119937,
    name: "Lake Magdalene",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.07418000",
    longitude: "-82.47176000",
  },
  {
    id: 119939,
    name: "Lake Mary",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.75888000",
    longitude: "-81.31784000",
  },
  {
    id: 119957,
    name: "Lake Panasoffkee",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.75582000",
    longitude: "-82.09481000",
  },
  {
    id: 119959,
    name: "Lake Park",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.80034000",
    longitude: "-80.06643000",
  },
  {
    id: 119962,
    name: "Lake Placid",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.35197000",
    longitude: "-81.32631000",
  },
  {
    id: 119974,
    name: "Lake Sarasota",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.29255000",
    longitude: "-82.43760000",
  },
  {
    id: 119991,
    name: "Lake Wales",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.90141000",
    longitude: "-81.58591000",
  },
  {
    id: 119997,
    name: "Lake Worth",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.61708000",
    longitude: "-80.07231000",
  },
  {
    id: 119999,
    name: "Lake Worth Corridor",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.61649000",
    longitude: "-80.10102000",
  },
  {
    id: 120012,
    name: "Lakeland",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.03947000",
    longitude: "-81.94980000",
  },
  {
    id: 120017,
    name: "Lakeland Highlands",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.95975000",
    longitude: "-81.95008000",
  },
  {
    id: 120025,
    name: "Lakes by the Bay",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.57233000",
    longitude: "-80.32533000",
  },
  {
    id: 120029,
    name: "Lakeside",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.12996000",
    longitude: "-81.76815000",
  },
  {
    id: 120054,
    name: "Lakewood Park",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.54309000",
    longitude: "-80.40227000",
  },
  {
    id: 120095,
    name: "Land O' Lakes",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.21890000",
    longitude: "-82.45759000",
  },
  {
    id: 120129,
    name: "Lantana",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.58674000",
    longitude: "-80.05199000",
  },
  {
    id: 120143,
    name: "Largo",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.90979000",
    longitude: "-82.78842000",
  },
  {
    id: 120182,
    name: "Lauderdale Lakes",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.16647000",
    longitude: "-80.20838000",
  },
  {
    id: 120183,
    name: "Lauderdale-by-the-Sea",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.19203000",
    longitude: "-80.09643000",
  },
  {
    id: 120184,
    name: "Lauderhill",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.14036000",
    longitude: "-80.21338000",
  },
  {
    id: 120188,
    name: "Laurel",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.14378000",
    longitude: "-82.46158000",
  },
  {
    id: 120278,
    name: "Lealman",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.82114000",
    longitude: "-82.67927000",
  },
  {
    id: 120299,
    name: "Lecanto",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.85165000",
    longitude: "-82.48760000",
  },
  {
    id: 120308,
    name: "Lee County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.62536000",
    longitude: "-81.84952000",
  },
  {
    id: 120324,
    name: "Leesburg",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.81082000",
    longitude: "-81.87786000",
  },
  {
    id: 120334,
    name: "Lehigh Acres",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.62535000",
    longitude: "-81.62480000",
  },
  {
    id: 120340,
    name: "Leisure City",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.49539000",
    longitude: "-80.42922000",
  },
  {
    id: 120353,
    name: "Lely",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.10065000",
    longitude: "-81.72842000",
  },
  {
    id: 120354,
    name: "Lely Resort",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.08093000",
    longitude: "-81.69786000",
  },
  {
    id: 120384,
    name: "Leon County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.45804000",
    longitude: "-84.27788000",
  },
  {
    id: 120409,
    name: "Levy County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.28221000",
    longitude: "-82.78861000",
  },
  {
    id: 120470,
    name: "Liberty County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.24136000",
    longitude: "-84.88291000",
  },
  {
    id: 120480,
    name: "Lighthouse Point",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.27564000",
    longitude: "-80.08727000",
  },
  {
    id: 120496,
    name: "Limestone Creek",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.94284000",
    longitude: "-80.14115000",
  },
  {
    id: 120638,
    name: "Live Oak",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.29495000",
    longitude: "-82.98402000",
  },
  {
    id: 120672,
    name: "Lochmoor Waterway Estates",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.64424000",
    longitude: "-81.90981000",
  },
  {
    id: 120676,
    name: "Lockhart",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.61944000",
    longitude: "-81.44257000",
  },
  {
    id: 120751,
    name: "Longboat Key",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.41254000",
    longitude: "-82.65899000",
  },
  {
    id: 120760,
    name: "Longwood",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.70305000",
    longitude: "-81.33840000",
  },
  {
    id: 120803,
    name: "Loughman",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.24196000",
    longitude: "-81.56674000",
  },
  {
    id: 120841,
    name: "Lower Grand Lagoon",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.14409000",
    longitude: "-85.75076000",
  },
  {
    id: 120851,
    name: "Loxahatchee Groves",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.68368000",
    longitude: "-80.27977000",
  },
  {
    id: 120898,
    name: "Lutz",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.15112000",
    longitude: "-82.46148000",
  },
  {
    id: 120929,
    name: "Lynn Haven",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.24548000",
    longitude: "-85.64826000",
  },
  {
    id: 120953,
    name: "Macclenny",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.28218000",
    longitude: "-82.12206000",
  },
  {
    id: 120978,
    name: "Madeira Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.79808000",
    longitude: "-82.79732000",
  },
  {
    id: 120985,
    name: "Madison",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.46938000",
    longitude: "-83.41293000",
  },
  {
    id: 121004,
    name: "Madison County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.44414000",
    longitude: "-83.47012000",
  },
  {
    id: 121051,
    name: "Maitland",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.62778000",
    longitude: "-81.36312000",
  },
  {
    id: 121056,
    name: "Malabar",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.00363000",
    longitude: "-80.56561000",
  },
  {
    id: 121065,
    name: "Malone",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.95769000",
    longitude: "-85.16215000",
  },
  {
    id: 121080,
    name: "Manasota Key",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.92534000",
    longitude: "-82.35204000",
  },
  {
    id: 121084,
    name: "Manatee County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.47752000",
    longitude: "-82.35754000",
  },
  {
    id: 121085,
    name: "Manatee Road",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.51302000",
    longitude: "-82.91429000",
  },
  {
    id: 121108,
    name: "Mango",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.97974000",
    longitude: "-82.30648000",
  },
  {
    id: 121109,
    name: "Mangonia Park",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.76034000",
    longitude: "-80.07365000",
  },
  {
    id: 121184,
    name: "Marathon",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "24.71375000",
    longitude: "-81.09035000",
  },
  {
    id: 121197,
    name: "Marco",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.97260000",
    longitude: "-81.72898000",
  },
  {
    id: 121198,
    name: "Marco Island",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.94121000",
    longitude: "-81.71842000",
  },
  {
    id: 121206,
    name: "Margate",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.24453000",
    longitude: "-80.20644000",
  },
  {
    id: 121209,
    name: "Marianna",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.77436000",
    longitude: "-85.22687000",
  },
  {
    id: 121249,
    name: "Marion County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.21020000",
    longitude: "-82.05668000",
  },
  {
    id: 121337,
    name: "Martin County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.08157000",
    longitude: "-80.39851000",
  },
  {
    id: 121354,
    name: "Mary Esther",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.41015000",
    longitude: "-86.66509000",
  },
  {
    id: 121366,
    name: "Masaryktown",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.44167000",
    longitude: "-82.45704000",
  },
  {
    id: 121368,
    name: "Mascotte",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.57833000",
    longitude: "-81.88675000",
  },
  {
    id: 121442,
    name: "Mayo",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.05300000",
    longitude: "-83.17486000",
  },
  {
    id: 121505,
    name: "McGregor",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.56091000",
    longitude: "-81.91453000",
  },
  {
    id: 121562,
    name: "Meadow Oaks",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.34619000",
    longitude: "-82.60284000",
  },
  {
    id: 121564,
    name: "Meadow Woods",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.38556000",
    longitude: "-81.36646000",
  },
  {
    id: 121609,
    name: "Medulla",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.96780000",
    longitude: "-81.97342000",
  },
  {
    id: 121620,
    name: "Melbourne",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.08363000",
    longitude: "-80.60811000",
  },
  {
    id: 121622,
    name: "Melbourne Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.06835000",
    longitude: "-80.56033000",
  },
  {
    id: 121631,
    name: "Melrose Park",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.11342000",
    longitude: "-80.19338000",
  },
  {
    id: 121638,
    name: "Memphis",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.53587000",
    longitude: "-82.56121000",
  },
  {
    id: 121714,
    name: "Merritt Island",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.53917000",
    longitude: "-80.67200000",
  },
  {
    id: 121744,
    name: "Mexico Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.94809000",
    longitude: "-85.41995000",
  },
  {
    id: 121746,
    name: "Miami",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.77427000",
    longitude: "-80.19366000",
  },
  {
    id: 121750,
    name: "Miami Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.79065000",
    longitude: "-80.13005000",
  },
  {
    id: 121753,
    name: "Miami Gardens",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.94204000",
    longitude: "-80.24560000",
  },
  {
    id: 121754,
    name: "Miami Lakes",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.90871000",
    longitude: "-80.30866000",
  },
  {
    id: 121755,
    name: "Miami Shores",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.86315000",
    longitude: "-80.19283000",
  },
  {
    id: 121756,
    name: "Miami Springs",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.82232000",
    longitude: "-80.28950000",
  },
  {
    id: 121757,
    name: "Miami-Dade County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.60897000",
    longitude: "-80.49867000",
  },
  {
    id: 121758,
    name: "Micco",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.88058000",
    longitude: "-80.50033000",
  },
  {
    id: 121768,
    name: "Middleburg",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.06885000",
    longitude: "-81.86038000",
  },
  {
    id: 121820,
    name: "Midway",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.40648000",
    longitude: "-87.00553000",
  },
  {
    id: 121912,
    name: "Milton",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.63241000",
    longitude: "-87.03969000",
  },
  {
    id: 121926,
    name: "Mims",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.66527000",
    longitude: "-80.84478000",
  },
  {
    id: 121954,
    name: "Minneola",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.57444000",
    longitude: "-81.74619000",
  },
  {
    id: 121973,
    name: "Miramar",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.98731000",
    longitude: "-80.23227000",
  },
  {
    id: 121974,
    name: "Miramar Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.37437000",
    longitude: "-86.35856000",
  },
  {
    id: 122029,
    name: "Molino",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.72408000",
    longitude: "-87.31414000",
  },
  {
    id: 122069,
    name: "Monroe County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.55731000",
    longitude: "-80.91705000",
  },
  {
    id: 122159,
    name: "Monticello",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.54515000",
    longitude: "-83.87130000",
  },
  {
    id: 122188,
    name: "Montverde",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.60028000",
    longitude: "-81.67396000",
  },
  {
    id: 122204,
    name: "Moore Haven",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.83312000",
    longitude: "-81.09312000",
  },
  {
    id: 122341,
    name: "Mount Dora",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.80249000",
    longitude: "-81.64452000",
  },
  {
    id: 122383,
    name: "Mount Plymouth",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.80805000",
    longitude: "-81.53313000",
  },
  {
    id: 122453,
    name: "Mulberry",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.89530000",
    longitude: "-81.97342000",
  },
  {
    id: 122522,
    name: "Myrtle Grove",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.42103000",
    longitude: "-87.30747000",
  },
  {
    id: 122552,
    name: "Naples",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.14234000",
    longitude: "-81.79596000",
  },
  {
    id: 122556,
    name: "Naples Manor",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.08870000",
    longitude: "-81.72620000",
  },
  {
    id: 122557,
    name: "Naples Park",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.26175000",
    longitude: "-81.80925000",
  },
  {
    id: 122562,
    name: "Naranja",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.51816000",
    longitude: "-80.42283000",
  },
  {
    id: 122582,
    name: "Nassau County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.61058000",
    longitude: "-81.77142000",
  },
  {
    id: 122584,
    name: "Nassau Village-Ratliff",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.51111000",
    longitude: "-81.80925000",
  },
  {
    id: 122600,
    name: "Navarre",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.40159000",
    longitude: "-86.86357000",
  },
  {
    id: 122637,
    name: "Neptune Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.31191000",
    longitude: "-81.39647000",
  },
  {
    id: 122764,
    name: "New Port Richey",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.24418000",
    longitude: "-82.71927000",
  },
  {
    id: 122765,
    name: "New Port Richey East",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.26027000",
    longitude: "-82.69261000",
  },
  {
    id: 122779,
    name: "New Smyrna Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.02582000",
    longitude: "-80.92700000",
  },
  {
    id: 122810,
    name: "Newberry",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.64635000",
    longitude: "-82.60650000",
  },
  {
    id: 122886,
    name: "Niceville",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.51686000",
    longitude: "-86.48217000",
  },
  {
    id: 122922,
    name: "Nocatee",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.16033000",
    longitude: "-81.88231000",
  },
  {
    id: 122929,
    name: "Nokomis",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.11922000",
    longitude: "-82.44426000",
  },
  {
    id: 122946,
    name: "Norland",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.94898000",
    longitude: "-80.21227000",
  },
  {
    id: 122963,
    name: "North Andrews Gardens",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.19147000",
    longitude: "-80.14421000",
  },
  {
    id: 122976,
    name: "North Bay Village",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.84621000",
    longitude: "-80.15394000",
  },
  {
    id: 122997,
    name: "North Brooksville",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.57305000",
    longitude: "-82.40815000",
  },
  {
    id: 123013,
    name: "North DeLand",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.04943000",
    longitude: "-81.29812000",
  },
  {
    id: 123029,
    name: "North Fort Myers",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.66729000",
    longitude: "-81.88009000",
  },
  {
    id: 123047,
    name: "North Key Largo",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.26734000",
    longitude: "-80.32339000",
  },
  {
    id: 123053,
    name: "North Lauderdale",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.21730000",
    longitude: "-80.22588000",
  },
  {
    id: 123066,
    name: "North Miami",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.89009000",
    longitude: "-80.18671000",
  },
  {
    id: 123067,
    name: "North Miami Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.93315000",
    longitude: "-80.16255000",
  },
  {
    id: 123075,
    name: "North Palm Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.81756000",
    longitude: "-80.08199000",
  },
  {
    id: 123085,
    name: "North Port",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.04422000",
    longitude: "-82.23593000",
  },
  {
    id: 123092,
    name: "North Redington Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.81614000",
    longitude: "-82.82066000",
  },
  {
    id: 123095,
    name: "North River Shores",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.21755000",
    longitude: "-80.26977000",
  },
  {
    id: 123100,
    name: "North Sarasota",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.37393000",
    longitude: "-82.51843000",
  },
  {
    id: 123127,
    name: "North Weeki Wachee",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.55014000",
    longitude: "-82.55888000",
  },
  {
    id: 123145,
    name: "Northdale",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.09390000",
    longitude: "-82.50561000",
  },
  {
    id: 123243,
    name: "Oak Hill",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.86443000",
    longitude: "-80.85450000",
  },
  {
    id: 123260,
    name: "Oak Ridge",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.47112000",
    longitude: "-81.42452000",
  },
  {
    id: 123282,
    name: "Oakland",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.55500000",
    longitude: "-81.63313000",
  },
  {
    id: 123296,
    name: "Oakland Park",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.17231000",
    longitude: "-80.13199000",
  },
  {
    id: 123297,
    name: "Oakleaf Plantation",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.17083000",
    longitude: "-81.83549000",
  },
  {
    id: 123320,
    name: "Ocala",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.18720000",
    longitude: "-82.14009000",
  },
  {
    id: 123325,
    name: "Ocean City",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.44103000",
    longitude: "-86.61356000",
  },
  {
    id: 123335,
    name: "Ocean Ridge",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.52702000",
    longitude: "-80.04837000",
  },
  {
    id: 123347,
    name: "Ocoee",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.56917000",
    longitude: "-81.54396000",
  },
  {
    id: 123358,
    name: "Odessa",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.19390000",
    longitude: "-82.59176000",
  },
  {
    id: 123391,
    name: "Ojus",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.94843000",
    longitude: "-80.15060000",
  },
  {
    id: 123392,
    name: "Okaloosa County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.66503000",
    longitude: "-86.59218000",
  },
  {
    id: 123398,
    name: "Okeechobee",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.24393000",
    longitude: "-80.82978000",
  },
  {
    id: 123399,
    name: "Okeechobee County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.38629000",
    longitude: "-80.88858000",
  },
  {
    id: 123434,
    name: "Oldsmar",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.03418000",
    longitude: "-82.66510000",
  },
  {
    id: 123437,
    name: "Olga",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.71896000",
    longitude: "-81.71230000",
  },
  {
    id: 123461,
    name: "Olympia Heights",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.72677000",
    longitude: "-80.35533000",
  },
  {
    id: 123494,
    name: "Opa-locka",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.90232000",
    longitude: "-80.25033000",
  },
  {
    id: 123512,
    name: "Orange City",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.94888000",
    longitude: "-81.29867000",
  },
  {
    id: 123514,
    name: "Orange County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.51442000",
    longitude: "-81.32348000",
  },
  {
    id: 123525,
    name: "Orange Park",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.16607000",
    longitude: "-81.70648000",
  },
  {
    id: 123529,
    name: "Orangetree",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.29286000",
    longitude: "-81.58842000",
  },
  {
    id: 123555,
    name: "Oriole Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.37381000",
    longitude: "-87.09136000",
  },
  {
    id: 123562,
    name: "Orlando",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.53834000",
    longitude: "-81.37924000",
  },
  {
    id: 123568,
    name: "Orlovista",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.53834000",
    longitude: "-81.46035000",
  },
  {
    id: 123569,
    name: "Ormond Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.28581000",
    longitude: "-81.05589000",
  },
  {
    id: 123570,
    name: "Ormond-by-the-Sea",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.34914000",
    longitude: "-81.06645000",
  },
  {
    id: 123603,
    name: "Osceola County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.06266000",
    longitude: "-81.14948000",
  },
  {
    id: 123613,
    name: "Osprey",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.19616000",
    longitude: "-82.49037000",
  },
  {
    id: 123656,
    name: "Oviedo",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.67000000",
    longitude: "-81.20812000",
  },
  {
    id: 123704,
    name: "Pace",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.59936000",
    longitude: "-87.16108000",
  },
  {
    id: 123723,
    name: "Pahokee",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.82006000",
    longitude: "-80.66534000",
  },
  {
    id: 123732,
    name: "Palatka",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.64858000",
    longitude: "-81.63758000",
  },
  {
    id: 123740,
    name: "Palm Aire",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.20619000",
    longitude: "-80.19171000",
  },
  {
    id: 123741,
    name: "Palm Bay",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.03446000",
    longitude: "-80.58866000",
  },
  {
    id: 123742,
    name: "Palm Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.70562000",
    longitude: "-80.03643000",
  },
  {
    id: 123743,
    name: "Palm Beach County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.64757000",
    longitude: "-80.43651000",
  },
  {
    id: 123744,
    name: "Palm Beach Gardens",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.82339000",
    longitude: "-80.13865000",
  },
  {
    id: 123745,
    name: "Palm Beach Shores",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.77812000",
    longitude: "-80.03560000",
  },
  {
    id: 123746,
    name: "Palm City",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.16783000",
    longitude: "-80.26616000",
  },
  {
    id: 123747,
    name: "Palm Coast",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.58497000",
    longitude: "-81.20784000",
  },
  {
    id: 123749,
    name: "Palm Harbor",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.07807000",
    longitude: "-82.76371000",
  },
  {
    id: 123750,
    name: "Palm River-Clair Mel",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.92386000",
    longitude: "-82.37939000",
  },
  {
    id: 123751,
    name: "Palm Springs",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.63590000",
    longitude: "-80.09615000",
  },
  {
    id: 123753,
    name: "Palm Springs North",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.93510000",
    longitude: "-80.33383000",
  },
  {
    id: 123754,
    name: "Palm Valley",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.17746000",
    longitude: "-81.38758000",
  },
  {
    id: 123764,
    name: "Palmetto",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.52143000",
    longitude: "-82.57232000",
  },
  {
    id: 123766,
    name: "Palmetto Bay",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.62177000",
    longitude: "-80.32477000",
  },
  {
    id: 123767,
    name: "Palmetto Estates",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.62149000",
    longitude: "-80.36200000",
  },
  {
    id: 123769,
    name: "Palmona Park",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.68646000",
    longitude: "-81.89648000",
  },
  {
    id: 123798,
    name: "Panama City",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.15946000",
    longitude: "-85.65983000",
  },
  {
    id: 123799,
    name: "Panama City Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.17659000",
    longitude: "-85.80549000",
  },
  {
    id: 123820,
    name: "Paradise Heights",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.62361000",
    longitude: "-81.54396000",
  },
  {
    id: 123858,
    name: "Parker",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.13104000",
    longitude: "-85.60326000",
  },
  {
    id: 123871,
    name: "Parkland",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.31008000",
    longitude: "-80.23727000",
  },
  {
    id: 123898,
    name: "Pasadena Hills",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.28001000",
    longitude: "-82.22438000",
  },
  {
    id: 123902,
    name: "Pasco County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.30674000",
    longitude: "-82.43887000",
  },
  {
    id: 123974,
    name: "Pebble Creek",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.14835000",
    longitude: "-82.34565000",
  },
  {
    id: 123994,
    name: "Pelican Bay",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.23120000",
    longitude: "-81.80564000",
  },
  {
    id: 124008,
    name: "Pembroke Park",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.98787000",
    longitude: "-80.17477000",
  },
  {
    id: 124009,
    name: "Pembroke Pines",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.00315000",
    longitude: "-80.22394000",
  },
  {
    id: 124045,
    name: "Pensacola",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.42131000",
    longitude: "-87.21691000",
  },
  {
    id: 124067,
    name: "Perry",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.11766000",
    longitude: "-83.58274000",
  },
  {
    id: 124180,
    name: "Pierson",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.23942000",
    longitude: "-81.46563000",
  },
  {
    id: 124217,
    name: "Pine Castle",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.47195000",
    longitude: "-81.36785000",
  },
  {
    id: 124225,
    name: "Pine Hills",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.55778000",
    longitude: "-81.45340000",
  },
  {
    id: 124229,
    name: "Pine Island Center",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.61369000",
    longitude: "-82.11815000",
  },
  {
    id: 124230,
    name: "Pine Island Ridge",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.09481000",
    longitude: "-80.27394000",
  },
  {
    id: 124237,
    name: "Pine Manor",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.57285000",
    longitude: "-81.87814000",
  },
  {
    id: 124242,
    name: "Pine Ridge",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.93831000",
    longitude: "-82.47343000",
  },
  {
    id: 124248,
    name: "Pinecrest",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.66705000",
    longitude: "-80.30811000",
  },
  {
    id: 124254,
    name: "Pinellas County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.90268000",
    longitude: "-82.73955000",
  },
  {
    id: 124255,
    name: "Pinellas Park",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.84280000",
    longitude: "-82.69954000",
  },
  {
    id: 124264,
    name: "Pinewood",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.86898000",
    longitude: "-80.21699000",
  },
  {
    id: 124313,
    name: "Placid Lakes",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.24144000",
    longitude: "-81.40702000",
  },
  {
    id: 124345,
    name: "Plant City",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.01888000",
    longitude: "-82.11469000",
  },
  {
    id: 124346,
    name: "Plantation",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.13421000",
    longitude: "-80.23184000",
  },
  {
    id: 124347,
    name: "Plantation Mobile Home Park",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.70312000",
    longitude: "-80.13227000",
  },
  {
    id: 124424,
    name: "Poinciana",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.14029000",
    longitude: "-81.45841000",
  },
  {
    id: 124426,
    name: "Point Baker",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.69019000",
    longitude: "-87.05358000",
  },
  {
    id: 124438,
    name: "Polk City",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.18251000",
    longitude: "-81.82397000",
  },
  {
    id: 124441,
    name: "Polk County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.94888000",
    longitude: "-81.69767000",
  },
  {
    id: 124460,
    name: "Pompano Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.23786000",
    longitude: "-80.12477000",
  },
  {
    id: 124461,
    name: "Pompano Beach Highlands",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.28286000",
    longitude: "-80.10699000",
  },
  {
    id: 124465,
    name: "Ponce Inlet",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.09637000",
    longitude: "-80.93700000",
  },
  {
    id: 124472,
    name: "Ponte Vedra Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.23969000",
    longitude: "-81.38564000",
  },
  {
    id: 124502,
    name: "Port Charlotte",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.97617000",
    longitude: "-82.09064000",
  },
  {
    id: 124516,
    name: "Port LaBelle",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.75645000",
    longitude: "-81.40508000",
  },
  {
    id: 124524,
    name: "Port Orange",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.13832000",
    longitude: "-80.99561000",
  },
  {
    id: 124529,
    name: "Port Richey",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.27168000",
    longitude: "-82.71955000",
  },
  {
    id: 124532,
    name: "Port Saint Joe",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.81188000",
    longitude: "-85.30297000",
  },
  {
    id: 124533,
    name: "Port Saint John",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.47695000",
    longitude: "-80.78867000",
  },
  {
    id: 124534,
    name: "Port Saint Lucie",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.29393000",
    longitude: "-80.35033000",
  },
  {
    id: 124535,
    name: "Port Salerno",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.14422000",
    longitude: "-80.20060000",
  },
  {
    id: 124662,
    name: "Pretty Bayou",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.19659000",
    longitude: "-85.69660000",
  },
  {
    id: 124680,
    name: "Princeton",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.53844000",
    longitude: "-80.40894000",
  },
  {
    id: 124703,
    name: "Progress Village",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.90030000",
    longitude: "-82.36454000",
  },
  {
    id: 124748,
    name: "Punta Gorda",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.92978000",
    longitude: "-82.04537000",
  },
  {
    id: 124749,
    name: "Punta Gorda Isles",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.91756000",
    longitude: "-82.07842000",
  },
  {
    id: 124750,
    name: "Punta Rassa",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.48786000",
    longitude: "-82.01231000",
  },
  {
    id: 124761,
    name: "Putnam County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.60863000",
    longitude: "-81.74430000",
  },
  {
    id: 124776,
    name: "Quail Ridge",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.34905000",
    longitude: "-82.55532000",
  },
  {
    id: 124795,
    name: "Quincy",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.58714000",
    longitude: "-84.58325000",
  },
  {
    id: 124971,
    name: "Redington Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.80864000",
    longitude: "-82.81121000",
  },
  {
    id: 124972,
    name: "Redington Shores",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.82614000",
    longitude: "-82.82899000",
  },
  {
    id: 125102,
    name: "Richmond Heights",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.63149000",
    longitude: "-80.36894000",
  },
  {
    id: 125106,
    name: "Richmond West",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.61050000",
    longitude: "-80.42971000",
  },
  {
    id: 125115,
    name: "Ridge Manor",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.50750000",
    longitude: "-82.17036000",
  },
  {
    id: 125116,
    name: "Ridge Wood Heights",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.28727000",
    longitude: "-82.51315000",
  },
  {
    id: 125117,
    name: "Ridgecrest",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.89750000",
    longitude: "-82.80529000",
  },
  {
    id: 125184,
    name: "River Park",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.31421000",
    longitude: "-80.34727000",
  },
  {
    id: 125216,
    name: "Riverview",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.86614000",
    longitude: "-82.32648000",
  },
  {
    id: 125221,
    name: "Riviera Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.77534000",
    longitude: "-80.05810000",
  },
  {
    id: 125282,
    name: "Rock Island",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.15509000",
    longitude: "-80.17699000",
  },
  {
    id: 125311,
    name: "Rockledge",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.35084000",
    longitude: "-80.72533000",
  },
  {
    id: 125395,
    name: "Roosevelt Gardens",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.14087000",
    longitude: "-80.18027000",
  },
  {
    id: 125421,
    name: "Roseland",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.83586000",
    longitude: "-80.49311000",
  },
  {
    id: 125467,
    name: "Rotonda West",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.88368000",
    longitude: "-82.29009000",
  },
  {
    id: 125491,
    name: "Royal Palm Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.70840000",
    longitude: "-80.23060000",
  },
  {
    id: 125492,
    name: "Royal Palm Estates",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.68173000",
    longitude: "-80.12504000",
  },
  {
    id: 125531,
    name: "Ruskin",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.72086000",
    longitude: "-82.43315000",
  },
  {
    id: 125582,
    name: "Safety Harbor",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.99085000",
    longitude: "-82.69316000",
  },
  {
    id: 125604,
    name: "Saint Augustine",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.89469000",
    longitude: "-81.31452000",
  },
  {
    id: 125605,
    name: "Saint Augustine Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.85053000",
    longitude: "-81.26535000",
  },
  {
    id: 125606,
    name: "Saint Augustine Shores",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.81080000",
    longitude: "-81.31035000",
  },
  {
    id: 125607,
    name: "Saint Augustine South",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.84249000",
    longitude: "-81.31448000",
  },
  {
    id: 125626,
    name: "Saint Cloud",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.24890000",
    longitude: "-81.28118000",
  },
  {
    id: 125640,
    name: "Saint George",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.05641000",
    longitude: "-82.72788000",
  },
  {
    id: 125657,
    name: "Saint James City",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.49758000",
    longitude: "-82.07843000",
  },
  {
    id: 125667,
    name: "Saint Johns County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.91218000",
    longitude: "-81.40989000",
  },
  {
    id: 125679,
    name: "Saint Leo",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.33723000",
    longitude: "-82.25842000",
  },
  {
    id: 125684,
    name: "Saint Lucie County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.38081000",
    longitude: "-80.44554000",
  },
  {
    id: 125707,
    name: "Saint Pete Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.72531000",
    longitude: "-82.74121000",
  },
  {
    id: 125779,
    name: "Samoset",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.46948000",
    longitude: "-82.54149000",
  },
  {
    id: 125782,
    name: "Samsula-Spruce Creek",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.04932000",
    longitude: "-81.06192000",
  },
  {
    id: 125786,
    name: "San Antonio",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.33611000",
    longitude: "-82.27453000",
  },
  {
    id: 125799,
    name: "San Carlos Park",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.46730000",
    longitude: "-81.80147000",
  },
  {
    id: 125862,
    name: "Sandalfoot Cove",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.33863000",
    longitude: "-80.18690000",
  },
  {
    id: 125886,
    name: "Sanford",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.80055000",
    longitude: "-81.27312000",
  },
  {
    id: 125894,
    name: "Sanibel",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.44897000",
    longitude: "-82.02231000",
  },
  {
    id: 125924,
    name: "Santa Rosa County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.69290000",
    longitude: "-87.01845000",
  },
  {
    id: 125938,
    name: "Sarasota",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.33643000",
    longitude: "-82.53065000",
  },
  {
    id: 125939,
    name: "Sarasota County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.18248000",
    longitude: "-82.36498000",
  },
  {
    id: 125940,
    name: "Sarasota Springs",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.30894000",
    longitude: "-82.47954000",
  },
  {
    id: 125955,
    name: "Satellite Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.17612000",
    longitude: "-80.59005000",
  },
  {
    id: 125980,
    name: "Sawgrass",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.19274000",
    longitude: "-81.37064000",
  },
  {
    id: 126001,
    name: "Schall Circle",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.71562000",
    longitude: "-80.11504000",
  },
  {
    id: 126054,
    name: "Scott Lake",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.94148000",
    longitude: "-80.23199000",
  },
  {
    id: 126097,
    name: "Seaside",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.32103000",
    longitude: "-86.14161000",
  },
  {
    id: 126105,
    name: "Sebastian",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.81641000",
    longitude: "-80.47061000",
  },
  {
    id: 126111,
    name: "Sebring",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.49559000",
    longitude: "-81.44091000",
  },
  {
    id: 126127,
    name: "Seffner",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.98363000",
    longitude: "-82.27565000",
  },
  {
    id: 126144,
    name: "Seminole",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.83975000",
    longitude: "-82.79121000",
  },
  {
    id: 126147,
    name: "Seminole County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.71698000",
    longitude: "-81.23630000",
  },
  {
    id: 126150,
    name: "Seminole Manor",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.58368000",
    longitude: "-80.10032000",
  },
  {
    id: 126184,
    name: "Sewall's Point",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.19949000",
    longitude: "-80.20227000",
  },
  {
    id: 126204,
    name: "Shady Hills",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.41000000",
    longitude: "-82.54288000",
  },
  {
    id: 126234,
    name: "Sharpes",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.43223000",
    longitude: "-80.76005000",
  },
  {
    id: 126409,
    name: "Siesta Key",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.26785000",
    longitude: "-82.54526000",
  },
  {
    id: 126427,
    name: "Silver Lake",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.84193000",
    longitude: "-81.79841000",
  },
  {
    id: 126436,
    name: "Silver Springs",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.21664000",
    longitude: "-82.05759000",
  },
  {
    id: 126437,
    name: "Silver Springs Shores",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.10442000",
    longitude: "-82.02064000",
  },
  {
    id: 126485,
    name: "Sky Lake",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.45723000",
    longitude: "-81.39146000",
  },
  {
    id: 126538,
    name: "Sneads",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.70801000",
    longitude: "-84.92552000",
  },
  {
    id: 126614,
    name: "South Apopka",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.66194000",
    longitude: "-81.50952000",
  },
  {
    id: 126618,
    name: "South Bay",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.66396000",
    longitude: "-80.71617000",
  },
  {
    id: 126619,
    name: "South Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.59115000",
    longitude: "-80.34422000",
  },
  {
    id: 126631,
    name: "South Bradenton",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.46310000",
    longitude: "-82.58176000",
  },
  {
    id: 126632,
    name: "South Brooksville",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.53582000",
    longitude: "-82.38403000",
  },
  {
    id: 126645,
    name: "South Daytona",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.16582000",
    longitude: "-81.00450000",
  },
  {
    id: 126660,
    name: "South Gate Ridge",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.28644000",
    longitude: "-82.49676000",
  },
  {
    id: 126670,
    name: "South Highpoint",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.91697000",
    longitude: "-82.71288000",
  },
  {
    id: 126691,
    name: "South Miami",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.70760000",
    longitude: "-80.29338000",
  },
  {
    id: 126692,
    name: "South Miami Heights",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.59761000",
    longitude: "-80.38061000",
  },
  {
    id: 126701,
    name: "South Palm Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.58896000",
    longitude: "-80.03865000",
  },
  {
    id: 126705,
    name: "South Pasadena",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.75503000",
    longitude: "-82.73760000",
  },
  {
    id: 126707,
    name: "South Patrick Shores",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.20223000",
    longitude: "-80.60950000",
  },
  {
    id: 126727,
    name: "South Sarasota",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.28616000",
    longitude: "-82.53288000",
  },
  {
    id: 126742,
    name: "South Venice",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.05311000",
    longitude: "-82.42426000",
  },
  {
    id: 126764,
    name: "Southchase",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.39306000",
    longitude: "-81.38340000",
  },
  {
    id: 126765,
    name: "Southeast Arcadia",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.18629000",
    longitude: "-81.85210000",
  },
  {
    id: 126773,
    name: "Southgate",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.30810000",
    longitude: "-82.50982000",
  },
  {
    id: 126790,
    name: "Southwest Ranches",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.05870000",
    longitude: "-80.33727000",
  },
  {
    id: 126860,
    name: "Spring Hill",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.47688000",
    longitude: "-82.52546000",
  },
  {
    id: 126889,
    name: "Springfield",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.15326000",
    longitude: "-85.61132000",
  },
  {
    id: 126926,
    name: "St. Johns",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.08150000",
    longitude: "-81.54774000",
  },
  {
    id: 126930,
    name: "St. Petersburg",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.77086000",
    longitude: "-82.67927000",
  },
  {
    id: 126986,
    name: "Starke",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.94413000",
    longitude: "-82.10983000",
  },
  {
    id: 127018,
    name: "Steinhatchee",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.67106000",
    longitude: "-83.38764000",
  },
  {
    id: 127066,
    name: "Stock Island",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "24.56709000",
    longitude: "-81.73842000",
  },
  {
    id: 127142,
    name: "Stuart",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.19755000",
    longitude: "-80.25283000",
  },
  {
    id: 127176,
    name: "Sugarmill Woods",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.73221000",
    longitude: "-82.50621000",
  },
  {
    id: 127229,
    name: "Sumter County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.70482000",
    longitude: "-82.08100000",
  },
  {
    id: 127234,
    name: "Sun City Center",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.71809000",
    longitude: "-82.35176000",
  },
  {
    id: 127246,
    name: "Suncoast Estates",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.71174000",
    longitude: "-81.86897000",
  },
  {
    id: 127256,
    name: "Sunny Isles Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.95065000",
    longitude: "-80.12282000",
  },
  {
    id: 127267,
    name: "Sunrise",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.13397000",
    longitude: "-80.11310000",
  },
  {
    id: 127271,
    name: "Sunset",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.70594000",
    longitude: "-80.35228000",
  },
  {
    id: 127277,
    name: "Sunshine Ranches",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.04592000",
    longitude: "-80.32894000",
  },
  {
    id: 127287,
    name: "Surfside",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.87843000",
    longitude: "-80.12560000",
  },
  {
    id: 127318,
    name: "Suwannee County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.19561000",
    longitude: "-82.99149000",
  },
  {
    id: 127340,
    name: "Sweetwater",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.76343000",
    longitude: "-80.37311000",
  },
  {
    id: 127371,
    name: "Taft",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.42973000",
    longitude: "-81.36507000",
  },
  {
    id: 127391,
    name: "Tallahassee",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.43826000",
    longitude: "-84.28073000",
  },
  {
    id: 127405,
    name: "Tamarac",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.21286000",
    longitude: "-80.24977000",
  },
  {
    id: 127406,
    name: "Tamiami",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.75871000",
    longitude: "-80.39839000",
  },
  {
    id: 127407,
    name: "Tampa",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.94752000",
    longitude: "-82.45843000",
  },
  {
    id: 127412,
    name: "Tangelo Park",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.45584000",
    longitude: "-81.44590000",
  },
  {
    id: 127414,
    name: "Tangerine",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.76499000",
    longitude: "-81.63063000",
  },
  {
    id: 127433,
    name: "Tarpon Springs",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.14612000",
    longitude: "-82.75677000",
  },
  {
    id: 127442,
    name: "Tavares",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.80416000",
    longitude: "-81.72563000",
  },
  {
    id: 127443,
    name: "Tavernier",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.01152000",
    longitude: "-80.51506000",
  },
  {
    id: 127451,
    name: "Taylor County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.01957000",
    longitude: "-83.61897000",
  },
  {
    id: 127458,
    name: "Taylor Creek",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.21671000",
    longitude: "-80.78950000",
  },
  {
    id: 127481,
    name: "Tedder",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.28425000",
    longitude: "-80.12227000",
  },
  {
    id: 127503,
    name: "Temple Terrace",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.03530000",
    longitude: "-82.38926000",
  },
  {
    id: 127513,
    name: "Tequesta",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.96811000",
    longitude: "-80.12865000",
  },
  {
    id: 127516,
    name: "Terra Mar",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.21619000",
    longitude: "-80.09532000",
  },
  {
    id: 127553,
    name: "The Acreage",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.79404000",
    longitude: "-80.26749000",
  },
  {
    id: 127556,
    name: "The Crossings",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.67066000",
    longitude: "-80.40117000",
  },
  {
    id: 127559,
    name: "The Hammocks",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.67149000",
    longitude: "-80.44450000",
  },
  {
    id: 127562,
    name: "The Meadows",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.36171000",
    longitude: "-82.46898000",
  },
  {
    id: 127565,
    name: "The Villages",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.93408000",
    longitude: "-81.95994000",
  },
  {
    id: 127597,
    name: "Thonotosassa",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.06141000",
    longitude: "-82.30231000",
  },
  {
    id: 127612,
    name: "Three Lakes",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.64205000",
    longitude: "-80.39839000",
  },
  {
    id: 127614,
    name: "Three Oaks",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.47008000",
    longitude: "-81.79397000",
  },
  {
    id: 127633,
    name: "Tice",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.67479000",
    longitude: "-81.81508000",
  },
  {
    id: 127638,
    name: "Tierra Verde",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.69197000",
    longitude: "-82.72343000",
  },
  {
    id: 127644,
    name: "Tiger Point",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.37853000",
    longitude: "-87.05552000",
  },
  {
    id: 127657,
    name: "Timber Pines",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.47028000",
    longitude: "-82.60316000",
  },
  {
    id: 127685,
    name: "Titusville",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.61222000",
    longitude: "-80.80755000",
  },
  {
    id: 127756,
    name: "Town 'n' Country",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.01057000",
    longitude: "-82.57732000",
  },
  {
    id: 127790,
    name: "Treasure Island",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.76919000",
    longitude: "-82.76899000",
  },
  {
    id: 127801,
    name: "Trenton",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.61329000",
    longitude: "-82.81762000",
  },
  {
    id: 127824,
    name: "Trinity",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.18085000",
    longitude: "-82.68177000",
  },
  {
    id: 127928,
    name: "Twin Lakes",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.18092000",
    longitude: "-80.16005000",
  },
  {
    id: 127946,
    name: "Tyndall Air Force Base",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.08535000",
    longitude: "-85.60731000",
  },
  {
    id: 127961,
    name: "Umatilla",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.92943000",
    longitude: "-81.66563000",
  },
  {
    id: 127989,
    name: "Union County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.04384000",
    longitude: "-82.37144000",
  },
  {
    id: 128009,
    name: "Union Park",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.56806000",
    longitude: "-81.28618000",
  },
  {
    id: 128025,
    name: "University",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.07389000",
    longitude: "-82.43902000",
  },
  {
    id: 128031,
    name: "University Park",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.74649000",
    longitude: "-80.36755000",
  },
  {
    id: 128044,
    name: "Upper Grand Lagoon",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.16326000",
    longitude: "-85.74076000",
  },
  {
    id: 128120,
    name: "Valparaiso",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.50853000",
    longitude: "-86.50273000",
  },
  {
    id: 128122,
    name: "Valrico",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.93789000",
    longitude: "-82.23644000",
  },
  {
    id: 128123,
    name: "Vamo",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.22200000",
    longitude: "-82.49787000",
  },
  {
    id: 128170,
    name: "Venice",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.09978000",
    longitude: "-82.45426000",
  },
  {
    id: 128173,
    name: "Venice Gardens",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.07311000",
    longitude: "-82.40760000",
  },
  {
    id: 128198,
    name: "Vero Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.63864000",
    longitude: "-80.39727000",
  },
  {
    id: 128199,
    name: "Vero Beach South",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.61638000",
    longitude: "-80.41308000",
  },
  {
    id: 128206,
    name: "Verona Walk",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.08412000",
    longitude: "-81.67985000",
  },
  {
    id: 128236,
    name: "Viera East",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.26234000",
    longitude: "-80.71449000",
  },
  {
    id: 128237,
    name: "Viera West",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.24504000",
    longitude: "-80.73380000",
  },
  {
    id: 128255,
    name: "Villages of Oriole",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.46230000",
    longitude: "-80.15282000",
  },
  {
    id: 128256,
    name: "Villano Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.93858000",
    longitude: "-81.30202000",
  },
  {
    id: 128257,
    name: "Villas",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.55035000",
    longitude: "-81.86870000",
  },
  {
    id: 128275,
    name: "Vineyards",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.22373000",
    longitude: "-81.72798000",
  },
  {
    id: 128291,
    name: "Virginia Gardens",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.81038000",
    longitude: "-80.30227000",
  },
  {
    id: 128303,
    name: "Volusia County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.06345000",
    longitude: "-81.14857000",
  },
  {
    id: 128314,
    name: "Wabasso Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.76475000",
    longitude: "-80.39894000",
  },
  {
    id: 128333,
    name: "Wahneta",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.95280000",
    longitude: "-81.72702000",
  },
  {
    id: 128365,
    name: "Wakulla County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.14777000",
    longitude: "-84.37561000",
  },
  {
    id: 128371,
    name: "Waldo",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.78969000",
    longitude: "-82.16732000",
  },
  {
    id: 128396,
    name: "Wallace",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.67741000",
    longitude: "-87.17997000",
  },
  {
    id: 128437,
    name: "Walton County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.61847000",
    longitude: "-86.17152000",
  },
  {
    id: 128468,
    name: "Warm Mineral Springs",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.05978000",
    longitude: "-82.26009000",
  },
  {
    id: 128512,
    name: "Warrington",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.38409000",
    longitude: "-87.27497000",
  },
  {
    id: 128554,
    name: "Washington County",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.61059000",
    longitude: "-85.66533000",
  },
  {
    id: 128584,
    name: "Washington Park",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.13259000",
    longitude: "-80.18116000",
  },
  {
    id: 128618,
    name: "Watertown",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.19245000",
    longitude: "-82.61457000",
  },
  {
    id: 128645,
    name: "Wauchula",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.54726000",
    longitude: "-81.81147000",
  },
  {
    id: 128747,
    name: "Wedgefield",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.48778000",
    longitude: "-81.07729000",
  },
  {
    id: 128755,
    name: "Weeki Wachee Gardens",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.53361000",
    longitude: "-82.62954000",
  },
  {
    id: 128763,
    name: "Wekiwa Springs",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.69861000",
    longitude: "-81.42563000",
  },
  {
    id: 128772,
    name: "Wellborn",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.23106000",
    longitude: "-82.81957000",
  },
  {
    id: 128776,
    name: "Wellington",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.65868000",
    longitude: "-80.24144000",
  },
  {
    id: 128809,
    name: "Wesley Chapel",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.23973000",
    longitude: "-82.32787000",
  },
  {
    id: 129011,
    name: "West and East Lealman",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.81993000",
    longitude: "-82.68944000",
  },
  {
    id: 128831,
    name: "West Bradenton",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.50254000",
    longitude: "-82.61399000",
  },
  {
    id: 128855,
    name: "West DeLand",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.01582000",
    longitude: "-81.33312000",
  },
  {
    id: 128876,
    name: "West Gate",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.70257000",
    longitude: "-80.09810000",
  },
  {
    id: 128898,
    name: "West Hollywood",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.02065000",
    longitude: "-80.18394000",
  },
  {
    id: 128920,
    name: "West Little River",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.85704000",
    longitude: "-80.23699000",
  },
  {
    id: 128927,
    name: "West Melbourne",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.07168000",
    longitude: "-80.65339000",
  },
  {
    id: 128930,
    name: "West Miami",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.76343000",
    longitude: "-80.29616000",
  },
  {
    id: 128947,
    name: "West Palm Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.71534000",
    longitude: "-80.05337000",
  },
  {
    id: 128949,
    name: "West Park",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.98454000",
    longitude: "-80.19894000",
  },
  {
    id: 128952,
    name: "West Pensacola",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.42659000",
    longitude: "-87.27969000",
  },
  {
    id: 128954,
    name: "West Perrine",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.60594000",
    longitude: "-80.36283000",
  },
  {
    id: 128974,
    name: "West Samoset",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.46948000",
    longitude: "-82.55676000",
  },
  {
    id: 128998,
    name: "West Vero Corridor",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.63775000",
    longitude: "-80.48576000",
  },
  {
    id: 129017,
    name: "Westchase",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.05502000",
    longitude: "-82.60982000",
  },
  {
    id: 129018,
    name: "Westchester",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.75482000",
    longitude: "-80.32727000",
  },
  {
    id: 129058,
    name: "Weston",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.10037000",
    longitude: "-80.39977000",
  },
  {
    id: 129075,
    name: "Westview",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.88204000",
    longitude: "-80.24199000",
  },
  {
    id: 129088,
    name: "Westwood Lake",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "25.72927000",
    longitude: "-80.37283000",
  },
  {
    id: 129094,
    name: "Wewahitchka",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.11270000",
    longitude: "-85.20047000",
  },
  {
    id: 129123,
    name: "Whiskey Creek",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.57258000",
    longitude: "-81.89009000",
  },
  {
    id: 129130,
    name: "White City",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.37393000",
    longitude: "-80.33394000",
  },
  {
    id: 129193,
    name: "Whitfield",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.41171000",
    longitude: "-82.56593000",
  },
  {
    id: 129236,
    name: "Wildwood",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.86542000",
    longitude: "-82.04058000",
  },
  {
    id: 129265,
    name: "Williamsburg",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.41445000",
    longitude: "-81.44285000",
  },
  {
    id: 129296,
    name: "Williston",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.38747000",
    longitude: "-82.44677000",
  },
  {
    id: 129300,
    name: "Williston Highlands",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "29.34052000",
    longitude: "-82.54150000",
  },
  {
    id: 129307,
    name: "Willow Oak",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.91614000",
    longitude: "-82.01786000",
  },
  {
    id: 129347,
    name: "Wilton Manors",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "26.16036000",
    longitude: "-80.13893000",
  },
  {
    id: 129348,
    name: "Wimauma",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.71253000",
    longitude: "-82.29898000",
  },
  {
    id: 129370,
    name: "Windermere",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.49556000",
    longitude: "-81.53480000",
  },
  {
    id: 129432,
    name: "Winston",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.03169000",
    longitude: "-82.01481000",
  },
  {
    id: 129437,
    name: "Winter Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.71920000",
    longitude: "-80.42061000",
  },
  {
    id: 129438,
    name: "Winter Garden",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.56528000",
    longitude: "-81.58618000",
  },
  {
    id: 129440,
    name: "Winter Haven",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.02224000",
    longitude: "-81.73286000",
  },
  {
    id: 129441,
    name: "Winter Park",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.60000000",
    longitude: "-81.33924000",
  },
  {
    id: 129442,
    name: "Winter Springs",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.69889000",
    longitude: "-81.30812000",
  },
  {
    id: 129538,
    name: "Woodlawn Beach",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.38825000",
    longitude: "-86.99080000",
  },
  {
    id: 129570,
    name: "Woodville",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.31409000",
    longitude: "-84.24740000",
  },
  {
    id: 129605,
    name: "Wright",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.45575000",
    longitude: "-86.63829000",
  },
  {
    id: 129653,
    name: "Yalaha",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.73860000",
    longitude: "-81.80869000",
  },
  {
    id: 129716,
    name: "Youngstown",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.36436000",
    longitude: "-85.43826000",
  },
  {
    id: 129732,
    name: "Yulee",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "30.63190000",
    longitude: "-81.60649000",
  },
  {
    id: 129747,
    name: "Zellwood",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.73111000",
    longitude: "-81.60118000",
  },
  {
    id: 129749,
    name: "Zephyrhills",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.23362000",
    longitude: "-82.18119000",
  },
  {
    id: 129750,
    name: "Zephyrhills North",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.25172000",
    longitude: "-82.16557000",
  },
  {
    id: 129751,
    name: "Zephyrhills South",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.21492000",
    longitude: "-82.18873000",
  },
  {
    id: 129752,
    name: "Zephyrhills West",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "28.23081000",
    longitude: "-82.20556000",
  },
  {
    id: 129760,
    name: "Zolfo Springs",
    state_id: 1436,
    state_code: "FL",
    country_id: 233,
    country_code: "US",
    latitude: "27.49337000",
    longitude: "-81.79592000",
  },
  {
    id: 110965,
    name: "Abbeville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.99212000",
    longitude: "-83.30682000",
  },
  {
    id: 111004,
    name: "Acworth",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.06635000",
    longitude: "-84.67837000",
  },
  {
    id: 111012,
    name: "Adairsville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.36870000",
    longitude: "-84.93411000",
  },
  {
    id: 111041,
    name: "Adel",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.13727000",
    longitude: "-83.42408000",
  },
  {
    id: 111090,
    name: "Alamo",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.14712000",
    longitude: "-82.77792000",
  },
  {
    id: 111101,
    name: "Albany",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.57851000",
    longitude: "-84.15574000",
  },
  {
    id: 111207,
    name: "Alma",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.53937000",
    longitude: "-82.46236000",
  },
  {
    id: 111221,
    name: "Alpharetta",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.07538000",
    longitude: "-84.29409000",
  },
  {
    id: 111239,
    name: "Alto",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.46732000",
    longitude: "-83.57378000",
  },
  {
    id: 111274,
    name: "Americus",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.07239000",
    longitude: "-84.23269000",
  },
  {
    id: 111400,
    name: "Appling County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.74928000",
    longitude: "-82.28898000",
  },
  {
    id: 111409,
    name: "Aragon",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.04565000",
    longitude: "-85.05606000",
  },
  {
    id: 111418,
    name: "Arcade",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.07789000",
    longitude: "-83.56155000",
  },
  {
    id: 111460,
    name: "Arlington",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.43990000",
    longitude: "-84.72492000",
  },
  {
    id: 111513,
    name: "Ashburn",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.70601000",
    longitude: "-83.65322000",
  },
  {
    id: 111573,
    name: "Athens",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.96095000",
    longitude: "-83.37794000",
  },
  {
    id: 111589,
    name: "Atkinson County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.29711000",
    longitude: "-82.87999000",
  },
  {
    id: 111590,
    name: "Atlanta",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.74900000",
    longitude: "-84.38798000",
  },
  {
    id: 111619,
    name: "Auburn",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.01372000",
    longitude: "-83.82768000",
  },
  {
    id: 111646,
    name: "Augusta",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.47097000",
    longitude: "-81.97484000",
  },
  {
    id: 111665,
    name: "Austell",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.81261000",
    longitude: "-84.63438000",
  },
  {
    id: 111702,
    name: "Avondale Estates",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.77149000",
    longitude: "-84.26714000",
  },
  {
    id: 111720,
    name: "Bacon County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.55367000",
    longitude: "-82.45269000",
  },
  {
    id: 111732,
    name: "Bainbridge",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "30.90380000",
    longitude: "-84.57547000",
  },
  {
    id: 111742,
    name: "Baker County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.32618000",
    longitude: "-84.44467000",
  },
  {
    id: 111752,
    name: "Baldwin",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.49177000",
    longitude: "-83.53739000",
  },
  {
    id: 111760,
    name: "Baldwin County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.06928000",
    longitude: "-83.24959000",
  },
  {
    id: 111769,
    name: "Ball Ground",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.33815000",
    longitude: "-84.37659000",
  },
  {
    id: 111798,
    name: "Banks County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.35413000",
    longitude: "-83.49737000",
  },
  {
    id: 111829,
    name: "Barnesville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.05457000",
    longitude: "-84.15575000",
  },
  {
    id: 111853,
    name: "Barrow County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.99320000",
    longitude: "-83.71276000",
  },
  {
    id: 111873,
    name: "Bartow County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.23786000",
    longitude: "-84.84050000",
  },
  {
    id: 111913,
    name: "Baxley",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.77825000",
    longitude: "-82.34846000",
  },
  {
    id: 112154,
    name: "Belvedere Park",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.75483000",
    longitude: "-84.26742000",
  },
  {
    id: 112162,
    name: "Ben Hill County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.75978000",
    longitude: "-83.22046000",
  },
  {
    id: 112227,
    name: "Berkeley Lake",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.98371000",
    longitude: "-84.18658000",
  },
  {
    id: 112249,
    name: "Berrien County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.27606000",
    longitude: "-83.22962000",
  },
  {
    id: 112310,
    name: "Bibb County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.80659000",
    longitude: "-83.69776000",
  },
  {
    id: 112391,
    name: "Blackshear",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.30605000",
    longitude: "-82.24207000",
  },
  {
    id: 112412,
    name: "Blairsville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.87620000",
    longitude: "-83.95824000",
  },
  {
    id: 112414,
    name: "Blakely",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.37768000",
    longitude: "-84.93409000",
  },
  {
    id: 112427,
    name: "Bleckley County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.43444000",
    longitude: "-83.32784000",
  },
  {
    id: 112443,
    name: "Bloomingdale",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.13242000",
    longitude: "-81.29900000",
  },
  {
    id: 112475,
    name: "Blue Ridge",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.86397000",
    longitude: "-84.32409000",
  },
  {
    id: 112499,
    name: "Bogart",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.94928000",
    longitude: "-83.53461000",
  },
  {
    id: 112530,
    name: "Bonanza",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.46567000",
    longitude: "-84.33659000",
  },
  {
    id: 112588,
    name: "Boston",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "30.79186000",
    longitude: "-83.78989000",
  },
  {
    id: 112622,
    name: "Bowdon",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.53789000",
    longitude: "-85.25328000",
  },
  {
    id: 112696,
    name: "Brantley County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.19688000",
    longitude: "-81.98190000",
  },
  {
    id: 112697,
    name: "Braselton",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.10927000",
    longitude: "-83.76267000",
  },
  {
    id: 112720,
    name: "Bremen",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.72122000",
    longitude: "-85.14550000",
  },
  {
    id: 112856,
    name: "Brookhaven",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.85844000",
    longitude: "-84.34020000",
  },
  {
    id: 112866,
    name: "Brooklet",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.37963000",
    longitude: "-81.66317000",
  },
  {
    id: 112883,
    name: "Brooks County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "30.84197000",
    longitude: "-83.58021000",
  },
  {
    id: 112941,
    name: "Broxton",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.62519000",
    longitude: "-82.88681000",
  },
  {
    id: 112947,
    name: "Brunswick",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.14995000",
    longitude: "-81.49149000",
  },
  {
    id: 112960,
    name: "Bryan County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.01322000",
    longitude: "-81.44247000",
  },
  {
    id: 112969,
    name: "Buchanan",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.80260000",
    longitude: "-85.18856000",
  },
  {
    id: 113000,
    name: "Buena Vista",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.31904000",
    longitude: "-84.51714000",
  },
  {
    id: 113022,
    name: "Buford",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.12066000",
    longitude: "-84.00435000",
  },
  {
    id: 113033,
    name: "Bulloch County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.39681000",
    longitude: "-81.74318000",
  },
  {
    id: 113056,
    name: "Burke County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.06115000",
    longitude: "-82.00078000",
  },
  {
    id: 113107,
    name: "Butler",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.55709000",
    longitude: "-84.23825000",
  },
  {
    id: 113130,
    name: "Butts County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.28785000",
    longitude: "-83.95717000",
  },
  {
    id: 113143,
    name: "Byron",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.65376000",
    longitude: "-83.75963000",
  },
  {
    id: 113169,
    name: "Cairo",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "30.87751000",
    longitude: "-84.20214000",
  },
  {
    id: 113197,
    name: "Calhoun",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.50259000",
    longitude: "-84.95105000",
  },
  {
    id: 113203,
    name: "Calhoun County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.52920000",
    longitude: "-84.62451000",
  },
  {
    id: 113268,
    name: "Camden County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "30.92249000",
    longitude: "-81.63639000",
  },
  {
    id: 113283,
    name: "Camilla",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.23129000",
    longitude: "-84.21046000",
  },
  {
    id: 113324,
    name: "Candler County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.40344000",
    longitude: "-82.07367000",
  },
  {
    id: 113325,
    name: "Candler-McAfee",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.72672000",
    longitude: "-84.27246000",
  },
  {
    id: 113339,
    name: "Canton",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.23676000",
    longitude: "-84.49076000",
  },
  {
    id: 113427,
    name: "Carnesville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.36983000",
    longitude: "-83.23516000",
  },
  {
    id: 113451,
    name: "Carroll County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.58282000",
    longitude: "-85.07974000",
  },
  {
    id: 113464,
    name: "Carrollton",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.58011000",
    longitude: "-85.07661000",
  },
  {
    id: 113488,
    name: "Cartersville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.16533000",
    longitude: "-84.80231000",
  },
  {
    id: 113577,
    name: "Catoosa County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.90366000",
    longitude: "-85.13825000",
  },
  {
    id: 113587,
    name: "Cave Spring",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.10760000",
    longitude: "-85.33634000",
  },
  {
    id: 113627,
    name: "Cedartown",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.01123000",
    longitude: "-85.25593000",
  },
  {
    id: 113652,
    name: "Centerville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.63014000",
    longitude: "-83.68963000",
  },
  {
    id: 113723,
    name: "Chamblee",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.89205000",
    longitude: "-84.29881000",
  },
  {
    id: 113789,
    name: "Charlton County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "30.78172000",
    longitude: "-82.13769000",
  },
  {
    id: 113801,
    name: "Chatham County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.97402000",
    longitude: "-81.09243000",
  },
  {
    id: 113805,
    name: "Chatsworth",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.76591000",
    longitude: "-84.76994000",
  },
  {
    id: 113809,
    name: "Chattahoochee County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.34697000",
    longitude: "-84.78705000",
  },
  {
    id: 113810,
    name: "Chattahoochee Hills",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.55063000",
    longitude: "-84.76049000",
  },
  {
    id: 113812,
    name: "Chattanooga Valley",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.93285000",
    longitude: "-85.35551000",
  },
  {
    id: 113813,
    name: "Chattooga County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.47502000",
    longitude: "-85.34529000",
  },
  {
    id: 113850,
    name: "Cherokee County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.24393000",
    longitude: "-84.47620000",
  },
  {
    id: 113881,
    name: "Chester",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.39378000",
    longitude: "-83.15293000",
  },
  {
    id: 113937,
    name: "Chickamauga",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.87119000",
    longitude: "-85.29079000",
  },
  {
    id: 114135,
    name: "Clarke County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.95117000",
    longitude: "-83.36733000",
  },
  {
    id: 114139,
    name: "Clarkesville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.61260000",
    longitude: "-83.52489000",
  },
  {
    id: 114148,
    name: "Clarkston",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.80955000",
    longitude: "-84.23964000",
  },
  {
    id: 114164,
    name: "Claxton",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.16158000",
    longitude: "-81.90400000",
  },
  {
    id: 114176,
    name: "Clay County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.62628000",
    longitude: "-84.98010000",
  },
  {
    id: 114198,
    name: "Clayton",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.87815000",
    longitude: "-83.40099000",
  },
  {
    id: 114205,
    name: "Clayton County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.54189000",
    longitude: "-84.35769000",
  },
  {
    id: 114242,
    name: "Cleveland",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.59704000",
    longitude: "-83.76324000",
  },
  {
    id: 114265,
    name: "Clinch County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "30.91495000",
    longitude: "-82.70624000",
  },
  {
    id: 114344,
    name: "Cobb County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.94147000",
    longitude: "-84.57667000",
  },
  {
    id: 114351,
    name: "Cochran",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.38683000",
    longitude: "-83.35461000",
  },
  {
    id: 114368,
    name: "Coffee County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.54927000",
    longitude: "-82.84920000",
  },
  {
    id: 114413,
    name: "College Park",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.65344000",
    longitude: "-84.44937000",
  },
  {
    id: 114459,
    name: "Colquitt",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.17129000",
    longitude: "-84.73325000",
  },
  {
    id: 114460,
    name: "Colquitt County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.18839000",
    longitude: "-83.76885000",
  },
  {
    id: 114479,
    name: "Columbia County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.54412000",
    longitude: "-82.26406000",
  },
  {
    id: 114491,
    name: "Columbus",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.46098000",
    longitude: "-84.98771000",
  },
  {
    id: 114519,
    name: "Comer",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.06372000",
    longitude: "-83.12543000",
  },
  {
    id: 114522,
    name: "Commerce",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.20400000",
    longitude: "-83.45711000",
  },
  {
    id: 114559,
    name: "Conley",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.64483000",
    longitude: "-84.32576000",
  },
  {
    id: 114589,
    name: "Conyers",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.66761000",
    longitude: "-84.01769000",
  },
  {
    id: 114591,
    name: "Cook County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.15399000",
    longitude: "-83.43047000",
  },
  {
    id: 114635,
    name: "Cordele",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.96351000",
    longitude: "-83.78239000",
  },
  {
    id: 114646,
    name: "Cornelia",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.51149000",
    longitude: "-83.52712000",
  },
  {
    id: 114716,
    name: "Country Club Estates",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.21273000",
    longitude: "-81.46427000",
  },
  {
    id: 114735,
    name: "Covington",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.59678000",
    longitude: "-83.86018000",
  },
  {
    id: 114747,
    name: "Coweta County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.35346000",
    longitude: "-84.76337000",
  },
  {
    id: 114775,
    name: "Crawford County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.71450000",
    longitude: "-83.98634000",
  },
  {
    id: 114786,
    name: "Crawfordville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.55402000",
    longitude: "-82.89598000",
  },
  {
    id: 114823,
    name: "Crisp County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.92293000",
    longitude: "-83.76810000",
  },
  {
    id: 114923,
    name: "Cumming",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.20732000",
    longitude: "-84.14019000",
  },
  {
    id: 114933,
    name: "Cusseta",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.30543000",
    longitude: "-84.77270000",
  },
  {
    id: 114945,
    name: "Cuthbert",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.77127000",
    longitude: "-84.78937000",
  },
  {
    id: 114963,
    name: "Dacula",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.98872000",
    longitude: "-83.89796000",
  },
  {
    id: 114966,
    name: "Dade County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.85452000",
    longitude: "-85.50453000",
  },
  {
    id: 114971,
    name: "Dahlonega",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.53259000",
    longitude: "-83.98491000",
  },
  {
    id: 114988,
    name: "Dallas",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.92371000",
    longitude: "-84.84077000",
  },
  {
    id: 115001,
    name: "Dalton",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.76980000",
    longitude: "-84.97022000",
  },
  {
    id: 115026,
    name: "Danielsville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.12428000",
    longitude: "-83.22126000",
  },
  {
    id: 115045,
    name: "Darien",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.37023000",
    longitude: "-81.43399000",
  },
  {
    id: 115077,
    name: "Davisboro",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.97904000",
    longitude: "-82.60791000",
  },
  {
    id: 115081,
    name: "Dawson",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.77382000",
    longitude: "-84.44800000",
  },
  {
    id: 115083,
    name: "Dawson County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.44430000",
    longitude: "-84.17064000",
  },
  {
    id: 115088,
    name: "Dawsonville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.42121000",
    longitude: "-84.11908000",
  },
  {
    id: 115152,
    name: "Decatur",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.77483000",
    longitude: "-84.29631000",
  },
  {
    id: 115159,
    name: "Decatur County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "30.87834000",
    longitude: "-84.57907000",
  },
  {
    id: 115169,
    name: "Deenwood",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.22201000",
    longitude: "-82.38725000",
  },
  {
    id: 115129,
    name: "DeKalb County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.77153000",
    longitude: "-84.22641000",
  },
  {
    id: 115233,
    name: "Demorest",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.56510000",
    longitude: "-83.54517000",
  },
  {
    id: 115372,
    name: "Dock Junction",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.20245000",
    longitude: "-81.51677000",
  },
  {
    id: 115377,
    name: "Dodge County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.17218000",
    longitude: "-83.16840000",
  },
  {
    id: 115395,
    name: "Donalsonville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.04046000",
    longitude: "-84.87909000",
  },
  {
    id: 115404,
    name: "Dooly County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.15718000",
    longitude: "-83.79875000",
  },
  {
    id: 115409,
    name: "Doraville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.89816000",
    longitude: "-84.28326000",
  },
  {
    id: 115418,
    name: "Dougherty County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.53337000",
    longitude: "-84.21625000",
  },
  {
    id: 115419,
    name: "Douglas",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.50881000",
    longitude: "-82.84987000",
  },
  {
    id: 115425,
    name: "Douglas County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.70182000",
    longitude: "-84.76793000",
  },
  {
    id: 115440,
    name: "Douglasville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.75150000",
    longitude: "-84.74771000",
  },
  {
    id: 115480,
    name: "Druid Hills",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.78038000",
    longitude: "-84.33604000",
  },
  {
    id: 115491,
    name: "Dublin",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.54044000",
    longitude: "-82.90375000",
  },
  {
    id: 115512,
    name: "Duluth",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.00288000",
    longitude: "-84.14464000",
  },
  {
    id: 115555,
    name: "Dunwoody",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.94621000",
    longitude: "-84.33465000",
  },
  {
    id: 115623,
    name: "Early County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.32283000",
    longitude: "-84.90364000",
  },
  {
    id: 115651,
    name: "East Dublin",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.54822000",
    longitude: "-82.87181000",
  },
  {
    id: 115675,
    name: "East Griffin",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.24373000",
    longitude: "-84.22881000",
  },
  {
    id: 115721,
    name: "East Newnan",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.35067000",
    longitude: "-84.77660000",
  },
  {
    id: 115737,
    name: "East Point",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.67955000",
    longitude: "-84.43937000",
  },
  {
    id: 115783,
    name: "Eastman",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.19767000",
    longitude: "-83.17765000",
  },
  {
    id: 115806,
    name: "Eatonton",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.32680000",
    longitude: "-83.38850000",
  },
  {
    id: 115815,
    name: "Echols County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "30.71009000",
    longitude: "-82.89394000",
  },
  {
    id: 115874,
    name: "Edison",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.55823000",
    longitude: "-84.73825000",
  },
  {
    id: 115898,
    name: "Effingham County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.36731000",
    longitude: "-81.34134000",
  },
  {
    id: 115942,
    name: "Elbert County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.11679000",
    longitude: "-82.84010000",
  },
  {
    id: 115945,
    name: "Elberton",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.11159000",
    longitude: "-82.86863000",
  },
  {
    id: 116016,
    name: "Ellaville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.23821000",
    longitude: "-84.30908000",
  },
  {
    id: 116025,
    name: "Ellijay",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.69481000",
    longitude: "-84.48215000",
  },
  {
    id: 116088,
    name: "Emanuel County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.58976000",
    longitude: "-82.30171000",
  },
  {
    id: 116093,
    name: "Emerson",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.12704000",
    longitude: "-84.75549000",
  },
  {
    id: 116139,
    name: "Enigma",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.41297000",
    longitude: "-83.32905000",
  },
  {
    id: 116227,
    name: "Euharlee",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.14482000",
    longitude: "-84.93300000",
  },
  {
    id: 116245,
    name: "Evans",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.53375000",
    longitude: "-82.13067000",
  },
  {
    id: 116248,
    name: "Evans County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.15676000",
    longitude: "-81.88688000",
  },
  {
    id: 116276,
    name: "Experiment",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.26539000",
    longitude: "-84.28159000",
  },
  {
    id: 116285,
    name: "Fair Oaks",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.91649000",
    longitude: "-84.54465000",
  },
  {
    id: 116293,
    name: "Fairburn",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.56706000",
    longitude: "-84.58104000",
  },
  {
    id: 116347,
    name: "Fairview",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.94563000",
    longitude: "-85.28440000",
  },
  {
    id: 116392,
    name: "Fannin County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.86411000",
    longitude: "-84.31985000",
  },
  {
    id: 116444,
    name: "Fayette County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.41394000",
    longitude: "-84.49419000",
  },
  {
    id: 116455,
    name: "Fayetteville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.44873000",
    longitude: "-84.45493000",
  },
  {
    id: 116522,
    name: "Firing Range",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.43752000",
    longitude: "-82.69068000",
  },
  {
    id: 116536,
    name: "Fitzgerald",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.71491000",
    longitude: "-83.25265000",
  },
  {
    id: 116602,
    name: "Flowery Branch",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.18510000",
    longitude: "-83.92518000",
  },
  {
    id: 116606,
    name: "Floyd County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.26316000",
    longitude: "-85.21428000",
  },
  {
    id: 116621,
    name: "Folkston",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "30.83095000",
    longitude: "-82.01131000",
  },
  {
    id: 116664,
    name: "Forest Park",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.62205000",
    longitude: "-84.36909000",
  },
  {
    id: 116683,
    name: "Forsyth",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.03430000",
    longitude: "-83.93824000",
  },
  {
    id: 116686,
    name: "Forsyth County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.22551000",
    longitude: "-84.12502000",
  },
  {
    id: 116711,
    name: "Fort Gaines",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.60924000",
    longitude: "-85.04933000",
  },
  {
    id: 116737,
    name: "Fort Oglethorpe",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.94896000",
    longitude: "-85.25690000",
  },
  {
    id: 116751,
    name: "Fort Stewart",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.87217000",
    longitude: "-81.61001000",
  },
  {
    id: 116757,
    name: "Fort Valley",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.55376000",
    longitude: "-83.88741000",
  },
  {
    id: 116826,
    name: "Franklin",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.27762000",
    longitude: "-85.09800000",
  },
  {
    id: 116846,
    name: "Franklin County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.37544000",
    longitude: "-83.22918000",
  },
  {
    id: 116871,
    name: "Franklin Springs",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.28483000",
    longitude: "-83.14432000",
  },
  {
    id: 116993,
    name: "Fulton County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.79025000",
    longitude: "-84.46702000",
  },
  {
    id: 117015,
    name: "Gainesville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.29788000",
    longitude: "-83.82407000",
  },
  {
    id: 117054,
    name: "Garden City",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.11437000",
    longitude: "-81.15400000",
  },
  {
    id: 117156,
    name: "Georgetown",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.98326000",
    longitude: "-81.22733000",
  },
  {
    id: 117187,
    name: "Gibson",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.23348000",
    longitude: "-82.59541000",
  },
  {
    id: 117222,
    name: "Gilmer County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.69121000",
    longitude: "-84.45559000",
  },
  {
    id: 117243,
    name: "Glascock County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.22928000",
    longitude: "-82.61070000",
  },
  {
    id: 117300,
    name: "Glennville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.93659000",
    longitude: "-81.92845000",
  },
  {
    id: 117331,
    name: "Glynn County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.21324000",
    longitude: "-81.49370000",
  },
  {
    id: 117396,
    name: "Gordon",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.88209000",
    longitude: "-83.33238000",
  },
  {
    id: 117398,
    name: "Gordon County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.50336000",
    longitude: "-84.87575000",
  },
  {
    id: 117426,
    name: "Grady County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "30.87467000",
    longitude: "-84.23443000",
  },
  {
    id: 117532,
    name: "Grantville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.23484000",
    longitude: "-84.83577000",
  },
  {
    id: 117550,
    name: "Gray",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.00958000",
    longitude: "-83.53378000",
  },
  {
    id: 117560,
    name: "Grayson",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.89427000",
    longitude: "-83.95574000",
  },
  {
    id: 117633,
    name: "Greene County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.57878000",
    longitude: "-83.16666000",
  },
  {
    id: 117665,
    name: "Greensboro",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.57568000",
    longitude: "-83.18238000",
  },
  {
    id: 117684,
    name: "Greenville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.02874000",
    longitude: "-84.71298000",
  },
  {
    id: 117723,
    name: "Gresham Park",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.70344000",
    longitude: "-84.31437000",
  },
  {
    id: 117731,
    name: "Griffin",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.24678000",
    longitude: "-84.26409000",
  },
  {
    id: 117765,
    name: "Grovetown",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.45042000",
    longitude: "-82.19818000",
  },
  {
    id: 117799,
    name: "Gumlog",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.49177000",
    longitude: "-83.09654000",
  },
  {
    id: 117819,
    name: "Guyton",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.33630000",
    longitude: "-81.39150000",
  },
  {
    id: 117821,
    name: "Gwinnett County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.96173000",
    longitude: "-84.02363000",
  },
  {
    id: 117824,
    name: "Habersham County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.63102000",
    longitude: "-83.53112000",
  },
  {
    id: 117839,
    name: "Hahira",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "30.99131000",
    longitude: "-83.37266000",
  },
  {
    id: 117864,
    name: "Hall County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.31689000",
    longitude: "-83.81968000",
  },
  {
    id: 117888,
    name: "Hamilton",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.75791000",
    longitude: "-84.87493000",
  },
  {
    id: 117927,
    name: "Hampton",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.38706000",
    longitude: "-84.28298000",
  },
  {
    id: 117950,
    name: "Hancock County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.27043000",
    longitude: "-83.00069000",
  },
  {
    id: 117963,
    name: "Hannahs Mill",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.93291000",
    longitude: "-84.34936000",
  },
  {
    id: 117981,
    name: "Hapeville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.66011000",
    longitude: "-84.41020000",
  },
  {
    id: 117985,
    name: "Haralson County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.79423000",
    longitude: "-85.21103000",
  },
  {
    id: 118007,
    name: "Hardwick",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.06820000",
    longitude: "-83.22349000",
  },
  {
    id: 118022,
    name: "Harlem",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.41458000",
    longitude: "-82.31262000",
  },
  {
    id: 118044,
    name: "Harris County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.73600000",
    longitude: "-84.90899000",
  },
  {
    id: 118082,
    name: "Hart County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.35090000",
    longitude: "-82.96425000",
  },
  {
    id: 118107,
    name: "Hartwell",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.35288000",
    longitude: "-82.93209000",
  },
  {
    id: 118167,
    name: "Hawkinsville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.28377000",
    longitude: "-83.47212000",
  },
  {
    id: 118211,
    name: "Hazlehurst",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.86963000",
    longitude: "-82.59430000",
  },
  {
    id: 118220,
    name: "Heard County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.29703000",
    longitude: "-85.12827000",
  },
  {
    id: 118248,
    name: "Helena",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.07379000",
    longitude: "-82.91459000",
  },
  {
    id: 118279,
    name: "Henderson",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.00813000",
    longitude: "-81.25887000",
  },
  {
    id: 118298,
    name: "Henry County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.45300000",
    longitude: "-84.15420000",
  },
  {
    id: 118309,
    name: "Hephzibah",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.31403000",
    longitude: "-82.09679000",
  },
  {
    id: 118356,
    name: "Hiawassee",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.94926000",
    longitude: "-83.75739000",
  },
  {
    id: 118482,
    name: "Hinesville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.84688000",
    longitude: "-81.59595000",
  },
  {
    id: 118490,
    name: "Hiram",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.87566000",
    longitude: "-84.76216000",
  },
  {
    id: 118511,
    name: "Hogansville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.17318000",
    longitude: "-84.91494000",
  },
  {
    id: 118561,
    name: "Holly Springs",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.17399000",
    longitude: "-84.50132000",
  },
  {
    id: 118592,
    name: "Homer",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.33372000",
    longitude: "-83.49905000",
  },
  {
    id: 118600,
    name: "Homerville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.03660000",
    longitude: "-82.74708000",
  },
  {
    id: 118680,
    name: "Hoschton",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.09650000",
    longitude: "-83.76128000",
  },
  {
    id: 118703,
    name: "Houston County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.45901000",
    longitude: "-83.66624000",
  },
  {
    id: 118918,
    name: "Indian Springs",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.95785000",
    longitude: "-85.16440000",
  },
  {
    id: 118995,
    name: "Irondale",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.48067000",
    longitude: "-84.35881000",
  },
  {
    id: 119013,
    name: "Irwin County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.60228000",
    longitude: "-83.27638000",
  },
  {
    id: 119015,
    name: "Irwinton",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.81126000",
    longitude: "-83.17265000",
  },
  {
    id: 119030,
    name: "Isle of Hope",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.98188000",
    longitude: "-81.06094000",
  },
  {
    id: 119061,
    name: "Jackson",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.29457000",
    longitude: "-83.96602000",
  },
  {
    id: 119078,
    name: "Jackson County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.13388000",
    longitude: "-83.56635000",
  },
  {
    id: 119142,
    name: "Jasper",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.46787000",
    longitude: "-84.42909000",
  },
  {
    id: 119145,
    name: "Jasper County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.31643000",
    longitude: "-83.68809000",
  },
  {
    id: 119160,
    name: "Jeff Davis County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.80560000",
    longitude: "-82.63694000",
  },
  {
    id: 119162,
    name: "Jefferson",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.11705000",
    longitude: "-83.57239000",
  },
  {
    id: 119178,
    name: "Jefferson County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.05484000",
    longitude: "-82.41815000",
  },
  {
    id: 119207,
    name: "Jeffersonville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.68765000",
    longitude: "-83.34656000",
  },
  {
    id: 119215,
    name: "Jenkins County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.79247000",
    longitude: "-81.96353000",
  },
  {
    id: 119239,
    name: "Jesup",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.60785000",
    longitude: "-81.88634000",
  },
  {
    id: 119252,
    name: "Johns Creek",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.02893000",
    longitude: "-84.19858000",
  },
  {
    id: 119261,
    name: "Johnson County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.70146000",
    longitude: "-82.66008000",
  },
  {
    id: 119292,
    name: "Jones County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.02513000",
    longitude: "-83.56052000",
  },
  {
    id: 119300,
    name: "Jonesboro",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.52150000",
    longitude: "-84.35381000",
  },
  {
    id: 119471,
    name: "Kennesaw",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.02343000",
    longitude: "-84.61549000",
  },
  {
    id: 119597,
    name: "Kings Bay Base",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "30.79836000",
    longitude: "-81.56589000",
  },
  {
    id: 119615,
    name: "Kingsland",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "30.79996000",
    longitude: "-81.68983000",
  },
  {
    id: 119693,
    name: "Knoxville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.72431000",
    longitude: "-83.99769000",
  },
  {
    id: 119799,
    name: "LaFayette",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.70480000",
    longitude: "-85.28190000",
  },
  {
    id: 119801,
    name: "LaGrange",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.03929000",
    longitude: "-85.03133000",
  },
  {
    id: 119880,
    name: "Lake City",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.60650000",
    longitude: "-84.33520000",
  },
  {
    id: 120013,
    name: "Lakeland",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.04104000",
    longitude: "-83.07515000",
  },
  {
    id: 120037,
    name: "Lakeview",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.97924000",
    longitude: "-85.25884000",
  },
  {
    id: 120042,
    name: "Lakeview Estates",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.70678000",
    longitude: "-84.03158000",
  },
  {
    id: 120062,
    name: "Lamar County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.07654000",
    longitude: "-84.13946000",
  },
  {
    id: 120119,
    name: "Lanier County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.03789000",
    longitude: "-83.06265000",
  },
  {
    id: 120208,
    name: "Laurens County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.46366000",
    longitude: "-82.92224000",
  },
  {
    id: 120220,
    name: "Lavonia",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.43594000",
    longitude: "-83.10682000",
  },
  {
    id: 120243,
    name: "Lawrenceville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.95621000",
    longitude: "-83.98796000",
  },
  {
    id: 120309,
    name: "Lee County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.77951000",
    longitude: "-84.14113000",
  },
  {
    id: 120325,
    name: "Leesburg",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.73212000",
    longitude: "-84.17074000",
  },
  {
    id: 120438,
    name: "Lexington",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.86984000",
    longitude: "-83.11182000",
  },
  {
    id: 120471,
    name: "Liberty County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.80723000",
    longitude: "-81.45626000",
  },
  {
    id: 120485,
    name: "Lilburn",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.89010000",
    longitude: "-84.14297000",
  },
  {
    id: 120517,
    name: "Lincoln County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.79366000",
    longitude: "-82.45121000",
  },
  {
    id: 120551,
    name: "Lincolnton",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.79235000",
    longitude: "-82.47902000",
  },
  {
    id: 120558,
    name: "Lindale",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.18676000",
    longitude: "-85.17467000",
  },
  {
    id: 120610,
    name: "Lithia Springs",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.79400000",
    longitude: "-84.66049000",
  },
  {
    id: 120611,
    name: "Lithonia",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.71233000",
    longitude: "-84.10519000",
  },
  {
    id: 120686,
    name: "Locust Grove",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.34595000",
    longitude: "-84.10908000",
  },
  {
    id: 120708,
    name: "Loganville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.83900000",
    longitude: "-83.90074000",
  },
  {
    id: 120741,
    name: "Long County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.75258000",
    longitude: "-81.74577000",
  },
  {
    id: 120765,
    name: "Lookout Mountain",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.97758000",
    longitude: "-85.35774000",
  },
  {
    id: 120811,
    name: "Louisville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.00154000",
    longitude: "-82.41124000",
  },
  {
    id: 120821,
    name: "Lovejoy",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.43622000",
    longitude: "-84.31437000",
  },
  {
    id: 120847,
    name: "Lowndes County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "30.83386000",
    longitude: "-83.26771000",
  },
  {
    id: 120871,
    name: "Ludowici",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.70799000",
    longitude: "-81.74234000",
  },
  {
    id: 120875,
    name: "Lula",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.38760000",
    longitude: "-83.66629000",
  },
  {
    id: 120878,
    name: "Lumber City",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.92935000",
    longitude: "-82.67958000",
  },
  {
    id: 120882,
    name: "Lumpkin",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.05099000",
    longitude: "-84.79909000",
  },
  {
    id: 120883,
    name: "Lumpkin County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.57219000",
    longitude: "-84.00265000",
  },
  {
    id: 120940,
    name: "Lyons",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.20435000",
    longitude: "-82.32179000",
  },
  {
    id: 120949,
    name: "Mableton",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.81872000",
    longitude: "-84.58243000",
  },
  {
    id: 120964,
    name: "Macon",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.84069000",
    longitude: "-83.63240000",
  },
  {
    id: 120968,
    name: "Macon County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.35839000",
    longitude: "-84.04248000",
  },
  {
    id: 120986,
    name: "Madison",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.59568000",
    longitude: "-83.46794000",
  },
  {
    id: 121005,
    name: "Madison County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.12778000",
    longitude: "-83.20904000",
  },
  {
    id: 121089,
    name: "Manchester",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.85985000",
    longitude: "-84.61993000",
  },
  {
    id: 121216,
    name: "Marietta",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.95260000",
    longitude: "-84.54993000",
  },
  {
    id: 121250,
    name: "Marion County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.35338000",
    longitude: "-84.52464000",
  },
  {
    id: 121324,
    name: "Marshallville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.45626000",
    longitude: "-83.94019000",
  },
  {
    id: 121344,
    name: "Martinez",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.51736000",
    longitude: "-82.07567000",
  },
  {
    id: 121448,
    name: "Maysville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.25288000",
    longitude: "-83.56155000",
  },
  {
    id: 121467,
    name: "McCaysville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.98619000",
    longitude: "-84.37131000",
  },
  {
    id: 121493,
    name: "McDonough",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.44734000",
    longitude: "-84.14686000",
  },
  {
    id: 121497,
    name: "McDuffie County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.48285000",
    longitude: "-82.48137000",
  },
  {
    id: 121513,
    name: "McIntosh County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.48381000",
    longitude: "-81.37557000",
  },
  {
    id: 121550,
    name: "McRae",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.06795000",
    longitude: "-82.90070000",
  },
  {
    id: 121617,
    name: "Meigs",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.07241000",
    longitude: "-84.08907000",
  },
  {
    id: 121699,
    name: "Meriwether County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.04066000",
    longitude: "-84.68831000",
  },
  {
    id: 121737,
    name: "Metter",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.39712000",
    longitude: "-82.06012000",
  },
  {
    id: 121813,
    name: "Midway",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.80577000",
    longitude: "-81.43066000",
  },
  {
    id: 121877,
    name: "Milledgeville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.08014000",
    longitude: "-83.23210000",
  },
  {
    id: 121878,
    name: "Millen",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.80405000",
    longitude: "-81.94928000",
  },
  {
    id: 121881,
    name: "Miller County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.16399000",
    longitude: "-84.73072000",
  },
  {
    id: 121920,
    name: "Milton",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.13216000",
    longitude: "-84.30067000",
  },
  {
    id: 121999,
    name: "Mitchell County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.22532000",
    longitude: "-84.19431000",
  },
  {
    id: 122057,
    name: "Monroe",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.79484000",
    longitude: "-83.71323000",
  },
  {
    id: 122070,
    name: "Monroe County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.01408000",
    longitude: "-83.91872000",
  },
  {
    id: 122127,
    name: "Montezuma",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.30516000",
    longitude: "-84.02741000",
  },
  {
    id: 122131,
    name: "Montgomery",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.94049000",
    longitude: "-81.12205000",
  },
  {
    id: 122141,
    name: "Montgomery County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.17336000",
    longitude: "-82.53482000",
  },
  {
    id: 122160,
    name: "Monticello",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.30485000",
    longitude: "-83.68323000",
  },
  {
    id: 122233,
    name: "Morgan",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.53767000",
    longitude: "-84.59936000",
  },
  {
    id: 122237,
    name: "Morgan County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.59083000",
    longitude: "-83.49238000",
  },
  {
    id: 122289,
    name: "Morrow",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.58317000",
    longitude: "-84.33937000",
  },
  {
    id: 122316,
    name: "Moultrie",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.17991000",
    longitude: "-83.78906000",
  },
  {
    id: 122329,
    name: "Mount Airy",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.51871000",
    longitude: "-83.50073000",
  },
  {
    id: 122395,
    name: "Mount Vernon",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.17851000",
    longitude: "-82.59458000",
  },
  {
    id: 122408,
    name: "Mount Zion",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.63428000",
    longitude: "-85.18717000",
  },
  {
    id: 122411,
    name: "Mountain City",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.91815000",
    longitude: "-83.38544000",
  },
  {
    id: 122424,
    name: "Mountain Park",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.84427000",
    longitude: "-84.12936000",
  },
  {
    id: 122493,
    name: "Murray County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.78845000",
    longitude: "-84.74809000",
  },
  {
    id: 122505,
    name: "Muscogee County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.50996000",
    longitude: "-84.87704000",
  },
  {
    id: 122536,
    name: "Nahunta",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.20439000",
    longitude: "-81.98123000",
  },
  {
    id: 122575,
    name: "Nashville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.20742000",
    longitude: "-83.25015000",
  },
  {
    id: 122624,
    name: "Nelson",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.38204000",
    longitude: "-84.37103000",
  },
  {
    id: 122837,
    name: "Newnan",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.38067000",
    longitude: "-84.79966000",
  },
  {
    id: 122859,
    name: "Newton",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.31296000",
    longitude: "-84.33574000",
  },
  {
    id: 122869,
    name: "Newton County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.55505000",
    longitude: "-83.85019000",
  },
  {
    id: 122890,
    name: "Nicholls",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.51742000",
    longitude: "-82.63486000",
  },
  {
    id: 122892,
    name: "Nicholson",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.11400000",
    longitude: "-83.43155000",
  },
  {
    id: 122940,
    name: "Norcross",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.94121000",
    longitude: "-84.21353000",
  },
  {
    id: 122966,
    name: "North Atlanta",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.86510000",
    longitude: "-84.33659000",
  },
  {
    id: 123014,
    name: "North Decatur",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.79038000",
    longitude: "-84.30603000",
  },
  {
    id: 123015,
    name: "North Druid Hills",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.81677000",
    longitude: "-84.31326000",
  },
  {
    id: 123308,
    name: "Oakwood",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.22760000",
    longitude: "-83.88435000",
  },
  {
    id: 123346,
    name: "Ocilla",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.59447000",
    longitude: "-83.25130000",
  },
  {
    id: 123348,
    name: "Oconee County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.83494000",
    longitude: "-83.43705000",
  },
  {
    id: 123378,
    name: "Oglethorpe",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.29377000",
    longitude: "-84.06102000",
  },
  {
    id: 123379,
    name: "Oglethorpe County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.88065000",
    longitude: "-83.08070000",
  },
  {
    id: 123465,
    name: "Omega",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.34102000",
    longitude: "-83.59350000",
  },
  {
    id: 123676,
    name: "Oxford",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.61900000",
    longitude: "-83.86741000",
  },
  {
    id: 123765,
    name: "Palmetto",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.51789000",
    longitude: "-84.66965000",
  },
  {
    id: 123808,
    name: "Panthersville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.70733000",
    longitude: "-84.27187000",
  },
  {
    id: 123922,
    name: "Paulding County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.92055000",
    longitude: "-84.86729000",
  },
  {
    id: 123956,
    name: "Peach County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.56878000",
    longitude: "-83.82688000",
  },
  {
    id: 123959,
    name: "Peachtree City",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.39678000",
    longitude: "-84.59576000",
  },
  {
    id: 123960,
    name: "Peachtree Corners",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.97010000",
    longitude: "-84.22159000",
  },
  {
    id: 123973,
    name: "Pearson",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.29770000",
    longitude: "-82.85237000",
  },
  {
    id: 123989,
    name: "Pelham",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.12802000",
    longitude: "-84.15304000",
  },
  {
    id: 124004,
    name: "Pembroke",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.13634000",
    longitude: "-81.62348000",
  },
  {
    id: 124068,
    name: "Perry",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.45821000",
    longitude: "-83.73157000",
  },
  {
    id: 124157,
    name: "Pickens County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.46432000",
    longitude: "-84.46564000",
  },
  {
    id: 124171,
    name: "Pierce County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.35876000",
    longitude: "-82.21274000",
  },
  {
    id: 124187,
    name: "Pike County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.09227000",
    longitude: "-84.38923000",
  },
  {
    id: 124238,
    name: "Pine Mountain",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.86485000",
    longitude: "-84.85410000",
  },
  {
    id: 124442,
    name: "Polk County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.00178000",
    longitude: "-85.18815000",
  },
  {
    id: 124479,
    name: "Pooler",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.11548000",
    longitude: "-81.24706000",
  },
  {
    id: 124542,
    name: "Port Wentworth",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.14909000",
    longitude: "-81.16317000",
  },
  {
    id: 124555,
    name: "Porterdale",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.57511000",
    longitude: "-83.89380000",
  },
  {
    id: 124608,
    name: "Powder Springs",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.85955000",
    longitude: "-84.68382000",
  },
  {
    id: 124653,
    name: "Preston",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.06599000",
    longitude: "-84.53742000",
  },
  {
    id: 124738,
    name: "Pulaski County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.23230000",
    longitude: "-83.47596000",
  },
  {
    id: 124762,
    name: "Putnam County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.32177000",
    longitude: "-83.37284000",
  },
  {
    id: 124770,
    name: "Putney",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.47018000",
    longitude: "-84.11768000",
  },
  {
    id: 124805,
    name: "Quitman",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "30.78492000",
    longitude: "-83.55988000",
  },
  {
    id: 124808,
    name: "Quitman County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.86733000",
    longitude: "-85.01878000",
  },
  {
    id: 124810,
    name: "Rabun County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.88168000",
    longitude: "-83.40214000",
  },
  {
    id: 124874,
    name: "Randolph County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.76262000",
    longitude: "-84.75419000",
  },
  {
    id: 124892,
    name: "Raoul",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.44954000",
    longitude: "-83.59434000",
  },
  {
    id: 124915,
    name: "Ray City",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.07464000",
    longitude: "-83.19932000",
  },
  {
    id: 124964,
    name: "Redan",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.74538000",
    longitude: "-84.13158000",
  },
  {
    id: 124992,
    name: "Reed Creek",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.44594000",
    longitude: "-82.92487000",
  },
  {
    id: 125007,
    name: "Reidsville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.08686000",
    longitude: "-82.11790000",
  },
  {
    id: 125013,
    name: "Remerton",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "30.84409000",
    longitude: "-83.31043000",
  },
  {
    id: 125039,
    name: "Reynolds",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.55987000",
    longitude: "-84.09630000",
  },
  {
    id: 125067,
    name: "Richland",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.08793000",
    longitude: "-84.66742000",
  },
  {
    id: 125098,
    name: "Richmond County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.35963000",
    longitude: "-82.07355000",
  },
  {
    id: 125104,
    name: "Richmond Hill",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.93827000",
    longitude: "-81.30344000",
  },
  {
    id: 125139,
    name: "Rincon",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.29603000",
    longitude: "-81.23539000",
  },
  {
    id: 125141,
    name: "Ringgold",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.91591000",
    longitude: "-85.10912000",
  },
  {
    id: 125192,
    name: "Riverdale",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.57261000",
    longitude: "-84.41326000",
  },
  {
    id: 125252,
    name: "Robins Air Force Base",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.60911000",
    longitude: "-83.58444000",
  },
  {
    id: 125258,
    name: "Rochelle",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.95101000",
    longitude: "-83.45627000",
  },
  {
    id: 125297,
    name: "Rockdale County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.65424000",
    longitude: "-84.02661000",
  },
  {
    id: 125314,
    name: "Rockmart",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.00260000",
    longitude: "-85.04161000",
  },
  {
    id: 125375,
    name: "Rome",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.25704000",
    longitude: "-85.16467000",
  },
  {
    id: 125456,
    name: "Rossville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.98313000",
    longitude: "-85.28607000",
  },
  {
    id: 125462,
    name: "Roswell",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.02316000",
    longitude: "-84.36159000",
  },
  {
    id: 125500,
    name: "Royston",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.28705000",
    longitude: "-83.11015000",
  },
  {
    id: 125532,
    name: "Russell",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.97872000",
    longitude: "-83.70017000",
  },
  {
    id: 125560,
    name: "Rydal",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.33537000",
    longitude: "-84.71549000",
  },
  {
    id: 125714,
    name: "Saint Simon Mills",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.17079000",
    longitude: "-81.40732000",
  },
  {
    id: 125715,
    name: "Saint Simons Island",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.15051000",
    longitude: "-81.36954000",
  },
  {
    id: 125865,
    name: "Sandersville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.98154000",
    longitude: "-82.81014000",
  },
  {
    id: 125884,
    name: "Sandy Springs",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.92427000",
    longitude: "-84.37854000",
  },
  {
    id: 125947,
    name: "Sardis",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.79652000",
    longitude: "-84.63881000",
  },
  {
    id: 125974,
    name: "Savannah",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.08354000",
    longitude: "-81.09983000",
  },
  {
    id: 126009,
    name: "Schley County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.26169000",
    longitude: "-84.31472000",
  },
  {
    id: 126055,
    name: "Scottdale",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.78983000",
    longitude: "-84.26409000",
  },
  {
    id: 126068,
    name: "Screven County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.75059000",
    longitude: "-81.61193000",
  },
  {
    id: 126148,
    name: "Seminole County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "30.93878000",
    longitude: "-84.86887000",
  },
  {
    id: 126162,
    name: "Senoia",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.30234000",
    longitude: "-84.55382000",
  },
  {
    id: 126218,
    name: "Shannon",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.33676000",
    longitude: "-85.07134000",
  },
  {
    id: 126479,
    name: "Skidaway Island",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.93494000",
    longitude: "-81.04705000",
  },
  {
    id: 126535,
    name: "Smyrna",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.88399000",
    longitude: "-84.51438000",
  },
  {
    id: 126541,
    name: "Snellville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.85733000",
    longitude: "-84.01991000",
  },
  {
    id: 126555,
    name: "Social Circle",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.65623000",
    longitude: "-83.71823000",
  },
  {
    id: 126602,
    name: "Soperton",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.37712000",
    longitude: "-82.59236000",
  },
  {
    id: 126795,
    name: "Spalding County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.26087000",
    longitude: "-84.28416000",
  },
  {
    id: 126803,
    name: "Sparks",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.16686000",
    longitude: "-83.43738000",
  },
  {
    id: 126807,
    name: "Sparta",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.27570000",
    longitude: "-82.97626000",
  },
  {
    id: 126891,
    name: "Springfield",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.37241000",
    longitude: "-81.31150000",
  },
  {
    id: 126929,
    name: "St. Marys",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "30.73051000",
    longitude: "-81.54649000",
  },
  {
    id: 126996,
    name: "Statenville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "30.70327000",
    longitude: "-83.02764000",
  },
  {
    id: 126997,
    name: "Statesboro",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.44879000",
    longitude: "-81.78317000",
  },
  {
    id: 126999,
    name: "Statham",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.96511000",
    longitude: "-83.59655000",
  },
  {
    id: 127021,
    name: "Stephens County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.55394000",
    longitude: "-83.29343000",
  },
  {
    id: 127050,
    name: "Stewart County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.07846000",
    longitude: "-84.83520000",
  },
  {
    id: 127067,
    name: "Stockbridge",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.54428000",
    longitude: "-84.23381000",
  },
  {
    id: 127083,
    name: "Stone Mountain",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.80816000",
    longitude: "-84.17020000",
  },
  {
    id: 127087,
    name: "Stonecrest",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.70849000",
    longitude: "-84.13485000",
  },
  {
    id: 127172,
    name: "Sugar Hill",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.10649000",
    longitude: "-84.03352000",
  },
  {
    id: 127209,
    name: "Summerville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.48064000",
    longitude: "-85.34773000",
  },
  {
    id: 127230,
    name: "Sumter County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.03997000",
    longitude: "-84.19704000",
  },
  {
    id: 127257,
    name: "Sunnyside",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.23938000",
    longitude: "-82.34207000",
  },
  {
    id: 127317,
    name: "Suwanee",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.05149000",
    longitude: "-84.07130000",
  },
  {
    id: 127320,
    name: "Swainsboro",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.59739000",
    longitude: "-82.33374000",
  },
  {
    id: 127360,
    name: "Sylvania",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.75044000",
    longitude: "-81.63678000",
  },
  {
    id: 127361,
    name: "Sylvester",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.53092000",
    longitude: "-83.83693000",
  },
  {
    id: 127383,
    name: "Talbot County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.69949000",
    longitude: "-84.53301000",
  },
  {
    id: 127385,
    name: "Talbotton",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.67764000",
    longitude: "-84.53937000",
  },
  {
    id: 127387,
    name: "Taliaferro County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.56609000",
    longitude: "-82.87876000",
  },
  {
    id: 127393,
    name: "Tallapoosa",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.74455000",
    longitude: "-85.28801000",
  },
  {
    id: 127439,
    name: "Tattnall County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.04575000",
    longitude: "-82.05818000",
  },
  {
    id: 127452,
    name: "Taylor County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.55546000",
    longitude: "-84.25046000",
  },
  {
    id: 127486,
    name: "Telfair County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.92980000",
    longitude: "-82.93899000",
  },
  {
    id: 127497,
    name: "Temple",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.73705000",
    longitude: "-85.03244000",
  },
  {
    id: 127511,
    name: "Tennille",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.93599000",
    longitude: "-82.81153000",
  },
  {
    id: 127527,
    name: "Terrell County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.77688000",
    longitude: "-84.43692000",
  },
  {
    id: 127579,
    name: "Thomas County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "30.86368000",
    longitude: "-83.91931000",
  },
  {
    id: 127583,
    name: "Thomaston",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.88819000",
    longitude: "-84.32659000",
  },
  {
    id: 127588,
    name: "Thomasville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "30.83658000",
    longitude: "-83.97878000",
  },
  {
    id: 127596,
    name: "Thomson",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.47069000",
    longitude: "-82.50457000",
  },
  {
    id: 127627,
    name: "Thunderbolt",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.03354000",
    longitude: "-81.04983000",
  },
  {
    id: 127641,
    name: "Tift County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.45744000",
    longitude: "-83.52659000",
  },
  {
    id: 127642,
    name: "Tifton",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.45046000",
    longitude: "-83.50850000",
  },
  {
    id: 127691,
    name: "Toccoa",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.57732000",
    longitude: "-83.33239000",
  },
  {
    id: 127729,
    name: "Toombs County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.12172000",
    longitude: "-82.33129000",
  },
  {
    id: 127763,
    name: "Towns County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.91665000",
    longitude: "-83.73728000",
  },
  {
    id: 127802,
    name: "Trenton",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.87202000",
    longitude: "-85.50913000",
  },
  {
    id: 127812,
    name: "Treutlen County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.40388000",
    longitude: "-82.56728000",
  },
  {
    id: 127830,
    name: "Trion",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.54397000",
    longitude: "-85.31051000",
  },
  {
    id: 127836,
    name: "Troup County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.03351000",
    longitude: "-85.02834000",
  },
  {
    id: 127870,
    name: "Tucker",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.85455000",
    longitude: "-84.21714000",
  },
  {
    id: 127903,
    name: "Turner County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.71638000",
    longitude: "-83.62409000",
  },
  {
    id: 127922,
    name: "Twiggs County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.66720000",
    longitude: "-83.42708000",
  },
  {
    id: 127923,
    name: "Twin City",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.58294000",
    longitude: "-82.15512000",
  },
  {
    id: 127937,
    name: "Tybee Island",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.00022000",
    longitude: "-80.84567000",
  },
  {
    id: 127949,
    name: "Tyrone",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.47123000",
    longitude: "-84.59715000",
  },
  {
    id: 127964,
    name: "Unadilla",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.26155000",
    longitude: "-83.73657000",
  },
  {
    id: 127980,
    name: "Union City",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.58706000",
    longitude: "-84.54243000",
  },
  {
    id: 127990,
    name: "Union County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.83401000",
    longitude: "-83.99076000",
  },
  {
    id: 128010,
    name: "Union Point",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.61568000",
    longitude: "-83.07460000",
  },
  {
    id: 128017,
    name: "Unionville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.43491000",
    longitude: "-83.50961000",
  },
  {
    id: 128054,
    name: "Upson County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.88127000",
    longitude: "-84.29934000",
  },
  {
    id: 128085,
    name: "Valdosta",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "30.83334000",
    longitude: "-83.28032000",
  },
  {
    id: 128155,
    name: "Varnell",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.90119000",
    longitude: "-84.97383000",
  },
  {
    id: 128227,
    name: "Vidalia",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.21769000",
    longitude: "-82.41346000",
  },
  {
    id: 128230,
    name: "Vienna",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.09156000",
    longitude: "-83.79545000",
  },
  {
    id: 128245,
    name: "Villa Rica",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.73205000",
    longitude: "-84.91911000",
  },
  {
    id: 128276,
    name: "Vinings",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.86483000",
    longitude: "-84.46437000",
  },
  {
    id: 128325,
    name: "Wadley",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.86682000",
    longitude: "-82.40402000",
  },
  {
    id: 128387,
    name: "Walker County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.73566000",
    longitude: "-85.30098000",
  },
  {
    id: 128417,
    name: "Walnut Grove",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.74261000",
    longitude: "-83.85240000",
  },
  {
    id: 128433,
    name: "Walthourville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.77410000",
    longitude: "-81.63261000",
  },
  {
    id: 128438,
    name: "Walton County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.78156000",
    longitude: "-83.73385000",
  },
  {
    id: 128463,
    name: "Ware County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.05363000",
    longitude: "-82.42368000",
  },
  {
    id: 128474,
    name: "Warner Robins",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.61574000",
    longitude: "-83.62664000",
  },
  {
    id: 128486,
    name: "Warren County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.40896000",
    longitude: "-82.67676000",
  },
  {
    id: 128504,
    name: "Warrenton",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.40708000",
    longitude: "-82.66208000",
  },
  {
    id: 128539,
    name: "Washington",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.73679000",
    longitude: "-82.73931000",
  },
  {
    id: 128555,
    name: "Washington County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.96954000",
    longitude: "-82.79590000",
  },
  {
    id: 128635,
    name: "Watkinsville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.86290000",
    longitude: "-83.40877000",
  },
  {
    id: 128676,
    name: "Waycross",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.21368000",
    longitude: "-82.35570000",
  },
  {
    id: 128689,
    name: "Wayne County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.55143000",
    longitude: "-81.91676000",
  },
  {
    id: 128705,
    name: "Waynesboro",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.08987000",
    longitude: "-82.01567000",
  },
  {
    id: 128736,
    name: "Webster County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.04665000",
    longitude: "-84.55105000",
  },
  {
    id: 128958,
    name: "West Point",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.87791000",
    longitude: "-85.18327000",
  },
  {
    id: 129001,
    name: "West Warrenton",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.41217000",
    longitude: "-82.67517000",
  },
  {
    id: 129116,
    name: "Wheeler County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.11707000",
    longitude: "-82.72459000",
  },
  {
    id: 129135,
    name: "White County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.64636000",
    longitude: "-83.74711000",
  },
  {
    id: 129178,
    name: "Whitemarsh Island",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.02882000",
    longitude: "-81.01678000",
  },
  {
    id: 129195,
    name: "Whitfield County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.80561000",
    longitude: "-84.96722000",
  },
  {
    id: 129229,
    name: "Wilcox County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.97290000",
    longitude: "-83.43236000",
  },
  {
    id: 129244,
    name: "Wilkes County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.78195000",
    longitude: "-82.74323000",
  },
  {
    id: 129250,
    name: "Wilkinson County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.80241000",
    longitude: "-83.17125000",
  },
  {
    id: 129254,
    name: "Willacoochee",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.34076000",
    longitude: "-83.04598000",
  },
  {
    id: 129325,
    name: "Wilmington Island",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.00355000",
    longitude: "-80.97372000",
  },
  {
    id: 129369,
    name: "Winder",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.99261000",
    longitude: "-83.72017000",
  },
  {
    id: 129447,
    name: "Winterville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.96706000",
    longitude: "-83.27821000",
  },
  {
    id: 129494,
    name: "Woodbine",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "30.96396000",
    longitude: "-81.72416000",
  },
  {
    id: 129561,
    name: "Woodstock",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.10149000",
    longitude: "-84.51938000",
  },
  {
    id: 129592,
    name: "Worth County",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "31.55151000",
    longitude: "-83.85088000",
  },
  {
    id: 129603,
    name: "Wrens",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.20765000",
    longitude: "-82.39179000",
  },
  {
    id: 129614,
    name: "Wrightsville",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "32.72933000",
    longitude: "-82.71986000",
  },
  {
    id: 129715,
    name: "Young Harris",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "34.93315000",
    longitude: "-83.84712000",
  },
  {
    id: 129742,
    name: "Zebulon",
    state_id: 1455,
    state_code: "GA",
    country_id: 233,
    country_code: "US",
    latitude: "33.10235000",
    longitude: "-84.34270000",
  },
  {
    id: 129769,
    name: "‘Āhuimanu",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.44472000",
    longitude: "-157.83778000",
  },
  {
    id: 129764,
    name: "‘Aiea",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.38222000",
    longitude: "-157.93361000",
  },
  {
    id: 129765,
    name: "‘Ele‘ele",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.90738000",
    longitude: "-159.58322000",
  },
  {
    id: 129766,
    name: "‘Ewa Beach",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.31556000",
    longitude: "-158.00722000",
  },
  {
    id: 129767,
    name: "‘Ewa Gentry",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.33999000",
    longitude: "-158.03039000",
  },
  {
    id: 129768,
    name: "‘Ewa Villages",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.34127000",
    longitude: "-158.03970000",
  },
  {
    id: 129770,
    name: "‘Ōma‘o",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.92581000",
    longitude: "-159.48818000",
  },
  {
    id: 111068,
    name: "Ainaloa",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "19.52694000",
    longitude: "-154.99306000",
  },
  {
    id: 111300,
    name: "Anahola",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "22.14226000",
    longitude: "-159.31388000",
  },
  {
    id: 113378,
    name: "Captain Cook",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "19.49694000",
    longitude: "-155.92167000",
  },
  {
    id: 115693,
    name: "East Honolulu",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.28906000",
    longitude: "-157.71734000",
  },
  {
    id: 116481,
    name: "Fern Acres",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "19.51222000",
    longitude: "-155.08028000",
  },
  {
    id: 117841,
    name: "Haiku-Pauwela",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "20.92187000",
    longitude: "-156.30508000",
  },
  {
    id: 118859,
    name: "Hālawa",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.37944000",
    longitude: "-157.92167000",
  },
  {
    id: 118860,
    name: "Hālawa Heights",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.37848000",
    longitude: "-157.91388000",
  },
  {
    id: 117854,
    name: "Hale‘iwa",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.59284000",
    longitude: "-158.10339000",
  },
  {
    id: 117941,
    name: "Hana",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "20.75806000",
    longitude: "-155.99028000",
  },
  {
    id: 117943,
    name: "Hanamā‘ulu",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.99773000",
    longitude: "-159.35918000",
  },
  {
    id: 117944,
    name: "Hanapēpē",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.90733000",
    longitude: "-159.59440000",
  },
  {
    id: 117945,
    name: "Hanapēpē Heights",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.91633000",
    longitude: "-159.58995000",
  },
  {
    id: 118144,
    name: "Hau‘ula",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.60760000",
    longitude: "-157.90868000",
  },
  {
    id: 118157,
    name: "Hawaii County",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "19.60240000",
    longitude: "-155.52289000",
  },
  {
    id: 118158,
    name: "Hawaiian Acres",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "19.53806000",
    longitude: "-155.05222000",
  },
  {
    id: 118159,
    name: "Hawaiian Beaches",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "19.54306000",
    longitude: "-154.91583000",
  },
  {
    id: 118161,
    name: "Hawaiian Ocean View",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "19.06861000",
    longitude: "-155.76500000",
  },
  {
    id: 118162,
    name: "Hawaiian Paradise Park",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "19.59333000",
    longitude: "-154.97306000",
  },
  {
    id: 118861,
    name: "Hāwī",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "20.24122000",
    longitude: "-155.83351000",
  },
  {
    id: 118353,
    name: "He‘eia",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.43054000",
    longitude: "-157.81611000",
  },
  {
    id: 118360,
    name: "Hickam Field",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.33967000",
    longitude: "-157.96018000",
  },
  {
    id: 118472,
    name: "Hilo",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "19.72991000",
    longitude: "-155.09073000",
  },
  {
    id: 118862,
    name: "Hōlualoa",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "19.61979000",
    longitude: "-155.94831000",
  },
  {
    id: 118613,
    name: "Honalo",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "19.54639000",
    longitude: "-155.93194000",
  },
  {
    id: 118614,
    name: "Honaunau-Napoopoo",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "19.45627000",
    longitude: "-155.86466000",
  },
  {
    id: 118622,
    name: "Honoka‘a",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "20.07931000",
    longitude: "-155.46691000",
  },
  {
    id: 118623,
    name: "Honolulu",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.30694000",
    longitude: "-157.85833000",
  },
  {
    id: 118624,
    name: "Honolulu County",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.45543000",
    longitude: "-157.96138000",
  },
  {
    id: 119001,
    name: "Iroquois Point",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.32776000",
    longitude: "-157.98301000",
  },
  {
    id: 119404,
    name: "Ka‘a‘awa",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.55445000",
    longitude: "-157.85103000",
  },
  {
    id: 119730,
    name: "Kā‘anapali",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "20.92881000",
    longitude: "-156.69422000",
  },
  {
    id: 119348,
    name: "Kaanapali Landing",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "20.93090000",
    longitude: "-156.68778000",
  },
  {
    id: 119352,
    name: "Kahalu‘u",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.45759000",
    longitude: "-157.84431000",
  },
  {
    id: 119351,
    name: "Kahaluu-Keauhou",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "19.57181000",
    longitude: "-155.96172000",
  },
  {
    id: 119354,
    name: "Kahuku",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.68048000",
    longitude: "-157.95237000",
  },
  {
    id: 119355,
    name: "Kahului",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "20.88953000",
    longitude: "-156.47432000",
  },
  {
    id: 119357,
    name: "Kailua",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.40241000",
    longitude: "-157.74054000",
  },
  {
    id: 119358,
    name: "Kailua-Kona",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "19.64016000",
    longitude: "-155.99912000",
  },
  {
    id: 119369,
    name: "Kalāheo",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.92416000",
    longitude: "-159.52686000",
  },
  {
    id: 119362,
    name: "Kalaoa",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "19.72861000",
    longitude: "-155.98167000",
  },
  {
    id: 119363,
    name: "Kalawao County",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.18569000",
    longitude: "-156.95944000",
  },
  {
    id: 119379,
    name: "Kaneohe",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.39994000",
    longitude: "-157.79895000",
  },
  {
    id: 119386,
    name: "Kapa‘a",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "22.07521000",
    longitude: "-159.31895000",
  },
  {
    id: 119385,
    name: "Kapaau",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "20.23389000",
    longitude: "-155.80194000",
  },
  {
    id: 119388,
    name: "Kapolei",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.33555000",
    longitude: "-158.05820000",
  },
  {
    id: 119396,
    name: "Kauai County",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "22.01108000",
    longitude: "-159.70544000",
  },
  {
    id: 119400,
    name: "Kaunakakai",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.09363000",
    longitude: "-157.02613000",
  },
  {
    id: 119415,
    name: "Kea‘au",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "19.62265000",
    longitude: "-155.03744000",
  },
  {
    id: 119405,
    name: "Kealakekua",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "19.52083000",
    longitude: "-155.92250000",
  },
  {
    id: 119427,
    name: "Kekaha",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.96686000",
    longitude: "-159.71186000",
  },
  {
    id: 119731,
    name: "Kēōkea",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "20.70711000",
    longitude: "-156.35446000",
  },
  {
    id: 119732,
    name: "Kīhei",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "20.76462000",
    longitude: "-156.44578000",
  },
  {
    id: 119733,
    name: "Kīlauea",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "22.21208000",
    longitude: "-159.41342000",
  },
  {
    id: 119697,
    name: "Ko Olina",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.33993000",
    longitude: "-158.12562000",
  },
  {
    id: 119703,
    name: "Koloa",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.90690000",
    longitude: "-159.46994000",
  },
  {
    id: 119721,
    name: "Kualapu‘u",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.15306000",
    longitude: "-157.03677000",
  },
  {
    id: 119722,
    name: "Kula",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "20.79094000",
    longitude: "-156.32695000",
  },
  {
    id: 119727,
    name: "Kurtistown",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "19.60361000",
    longitude: "-155.05722000",
  },
  {
    id: 120947,
    name: "Lā‘ie",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.64547000",
    longitude: "-157.92250000",
  },
  {
    id: 119860,
    name: "Lahaina",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "20.87429000",
    longitude: "-156.67663000",
  },
  {
    id: 120078,
    name: "Lanai City",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "20.82757000",
    longitude: "-156.92399000",
  },
  {
    id: 120221,
    name: "Lawai",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.92159000",
    longitude: "-159.50376000",
  },
  {
    id: 120339,
    name: "Leilani Estates",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "19.46972000",
    longitude: "-154.91778000",
  },
  {
    id: 120483,
    name: "Lihue",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.98121000",
    longitude: "-159.37210000",
  },
  {
    id: 122530,
    name: "Mā‘ili",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.41629000",
    longitude: "-158.17531000",
  },
  {
    id: 122528,
    name: "Mākaha",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.46627000",
    longitude: "-158.21037000",
  },
  {
    id: 122529,
    name: "Mākaha Valley",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.47583000",
    longitude: "-158.19918000",
  },
  {
    id: 121054,
    name: "Makakilo City",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.34694000",
    longitude: "-158.08583000",
  },
  {
    id: 121055,
    name: "Makawao",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "20.85694000",
    longitude: "-156.31306000",
  },
  {
    id: 121226,
    name: "Marine Corps Base Hawaii - MCBH",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.44336000",
    longitude: "-157.74981000",
  },
  {
    id: 121419,
    name: "Maui County",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "20.86774000",
    longitude: "-156.61706000",
  },
  {
    id: 121422,
    name: "Maunawili",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.37278000",
    longitude: "-157.77056000",
  },
  {
    id: 121861,
    name: "Mililani Town",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.45000000",
    longitude: "-158.00111000",
  },
  {
    id: 122025,
    name: "Mokulēia",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.57967000",
    longitude: "-158.15313000",
  },
  {
    id: 122432,
    name: "Mountain View",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "19.55583000",
    longitude: "-155.10806000",
  },
  {
    id: 123224,
    name: "Nānākuli",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.39362000",
    longitude: "-158.15429000",
  },
  {
    id: 122539,
    name: "Nanawale Estates",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "19.50611000",
    longitude: "-154.91194000",
  },
  {
    id: 122551,
    name: "Napili-Honokowai",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "20.97533000",
    longitude: "-156.67826000",
  },
  {
    id: 123334,
    name: "Ocean Pointe",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.31066000",
    longitude: "-158.03638000",
  },
  {
    id: 123540,
    name: "Orchidlands Estates",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "19.56084000",
    longitude: "-155.01527000",
  },
  {
    id: 124773,
    name: "Pāhala",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "19.20297000",
    longitude: "-155.47860000",
  },
  {
    id: 123725,
    name: "Paia",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "20.90333000",
    longitude: "-156.36944000",
  },
  {
    id: 124774,
    name: "Pāpa‘ikou",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "19.78718000",
    longitude: "-155.09326000",
  },
  {
    id: 123965,
    name: "Pearl City",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.39734000",
    longitude: "-157.97516000",
  },
  {
    id: 124052,
    name: "Pepeekeo",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "19.83361000",
    longitude: "-155.10722000",
  },
  {
    id: 124696,
    name: "Princeville",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "22.21758000",
    longitude: "-159.47888000",
  },
  {
    id: 124731,
    name: "Puhi",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.96888000",
    longitude: "-159.40012000",
  },
  {
    id: 124732,
    name: "Pukalani",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "20.83667000",
    longitude: "-156.33667000",
  },
  {
    id: 124747,
    name: "Punalu‘u",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.57045000",
    longitude: "-157.87557000",
  },
  {
    id: 124752,
    name: "Pupukea",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.65456000",
    longitude: "-158.06065000",
  },
  {
    id: 125489,
    name: "Royal Kunia",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.39392000",
    longitude: "-158.02670000",
  },
  {
    id: 126014,
    name: "Schofield Barracks",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.49837000",
    longitude: "-158.06515000",
  },
  {
    id: 128249,
    name: "Village Park",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.39806000",
    longitude: "-158.03028000",
  },
  {
    id: 128299,
    name: "Volcano",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "19.44276000",
    longitude: "-155.23398000",
  },
  {
    id: 128331,
    name: "Wahiawā",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.50279000",
    longitude: "-158.02464000",
  },
  {
    id: 128336,
    name: "Waialua",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.57688000",
    longitude: "-158.13154000",
  },
  {
    id: 128337,
    name: "Waianae",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.43785000",
    longitude: "-158.18555000",
  },
  {
    id: 128338,
    name: "Waihee-Waiehu",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "20.93022000",
    longitude: "-156.50458000",
  },
  {
    id: 128339,
    name: "Waikapū",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "20.85806000",
    longitude: "-156.50694000",
  },
  {
    id: 128340,
    name: "Waikoloa",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "19.93942000",
    longitude: "-155.78931000",
  },
  {
    id: 128341,
    name: "Wailea",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "20.68973000",
    longitude: "-156.44190000",
  },
  {
    id: 128342,
    name: "Wailua",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "22.05271000",
    longitude: "-159.33781000",
  },
  {
    id: 128343,
    name: "Wailua Homesteads",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "22.07244000",
    longitude: "-159.37677000",
  },
  {
    id: 128344,
    name: "Wailuku",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "20.89133000",
    longitude: "-156.50604000",
  },
  {
    id: 128345,
    name: "Waimalu",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.40472000",
    longitude: "-157.94333000",
  },
  {
    id: 128346,
    name: "Waimanalo",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.34614000",
    longitude: "-157.72380000",
  },
  {
    id: 128348,
    name: "Waimānalo Beach",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.33407000",
    longitude: "-157.70003000",
  },
  {
    id: 128347,
    name: "Waimea",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "20.02323000",
    longitude: "-155.67288000",
  },
  {
    id: 128349,
    name: "Wainaku",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "19.74472000",
    longitude: "-155.09500000",
  },
  {
    id: 128350,
    name: "Waipahu",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.38667000",
    longitude: "-158.00917000",
  },
  {
    id: 128352,
    name: "Waipi‘o Acres",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.46485000",
    longitude: "-158.01331000",
  },
  {
    id: 128351,
    name: "Waipio",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.41823000",
    longitude: "-157.99906000",
  },
  {
    id: 129207,
    name: "Whitmore Village",
    state_id: 1411,
    state_code: "HI",
    country_id: 233,
    country_code: "US",
    latitude: "21.51429000",
    longitude: "-158.02464000",
  },
  {
    id: 110978,
    name: "Aberdeen",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.94408000",
    longitude: "-112.83833000",
  },
  {
    id: 111007,
    name: "Ada County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.45112000",
    longitude: "-116.24109000",
  },
  {
    id: 111025,
    name: "Adams County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "44.88965000",
    longitude: "-116.45387000",
  },
  {
    id: 111272,
    name: "American Falls",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.78602000",
    longitude: "-112.85444000",
  },
  {
    id: 111293,
    name: "Ammon",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.46964000",
    longitude: "-111.96664000",
  },
  {
    id: 111435,
    name: "Arco",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.63657000",
    longitude: "-113.30028000",
  },
  {
    id: 111546,
    name: "Ashton",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "44.07158000",
    longitude: "-111.44829000",
  },
  {
    id: 111804,
    name: "Bannock County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.66851000",
    longitude: "-112.22463000",
  },
  {
    id: 111977,
    name: "Bear Lake County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.28479000",
    longitude: "-111.32965000",
  },
  {
    id: 112116,
    name: "Bellevue",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.46352000",
    longitude: "-114.26060000",
  },
  {
    id: 112167,
    name: "Benewah County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "47.21755000",
    longitude: "-116.65883000",
  },
  {
    id: 112349,
    name: "Bingham County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.21652000",
    longitude: "-112.39805000",
  },
  {
    id: 112385,
    name: "Blackfoot",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.19047000",
    longitude: "-112.34498000",
  },
  {
    id: 112407,
    name: "Blaine County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.41233000",
    longitude: "-113.98040000",
  },
  {
    id: 112508,
    name: "Boise",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.61350000",
    longitude: "-116.20345000",
  },
  {
    id: 112510,
    name: "Boise County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.98913000",
    longitude: "-115.73024000",
  },
  {
    id: 112542,
    name: "Bonner County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "48.29975000",
    longitude: "-116.60097000",
  },
  {
    id: 112545,
    name: "Bonners Ferry",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "48.69133000",
    longitude: "-116.31631000",
  },
  {
    id: 112546,
    name: "Bonneville County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.38773000",
    longitude: "-111.61493000",
  },
  {
    id: 112609,
    name: "Boundary County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "48.76702000",
    longitude: "-116.46288000",
  },
  {
    id: 113023,
    name: "Buhl",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.59907000",
    longitude: "-114.75949000",
  },
  {
    id: 113063,
    name: "Burley",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.53574000",
    longitude: "-113.79279000",
  },
  {
    id: 113126,
    name: "Butte County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.72287000",
    longitude: "-113.17202000",
  },
  {
    id: 113182,
    name: "Caldwell",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.66294000",
    longitude: "-116.68736000",
  },
  {
    id: 113244,
    name: "Camas County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.46325000",
    longitude: "-114.80585000",
  },
  {
    id: 113356,
    name: "Canyon County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.62513000",
    longitude: "-116.70929000",
  },
  {
    id: 113395,
    name: "Caribou County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.77051000",
    longitude: "-111.56224000",
  },
  {
    id: 113514,
    name: "Cascade",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "44.51628000",
    longitude: "-116.04180000",
  },
  {
    id: 113537,
    name: "Cassia County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.28387000",
    longitude: "-113.60037000",
  },
  {
    id: 113715,
    name: "Challis",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "44.50464000",
    longitude: "-114.23173000",
  },
  {
    id: 114004,
    name: "Chubbuck",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.92075000",
    longitude: "-112.46609000",
  },
  {
    id: 114130,
    name: "Clark County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "44.28398000",
    longitude: "-112.35135000",
  },
  {
    id: 114227,
    name: "Clearwater County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "46.67370000",
    longitude: "-115.65686000",
  },
  {
    id: 114366,
    name: "Coeur d'Alene",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "47.67768000",
    longitude: "-116.78047000",
  },
  {
    id: 114711,
    name: "Council",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "44.72989000",
    longitude: "-116.43820000",
  },
  {
    id: 114938,
    name: "Custer County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "44.24142000",
    longitude: "-114.28180000",
  },
  {
    id: 115004,
    name: "Dalton Gardens",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "47.72963000",
    longitude: "-116.77019000",
  },
  {
    id: 115478,
    name: "Driggs",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.72325000",
    longitude: "-111.11133000",
  },
  {
    id: 115497,
    name: "Dubois",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "44.17630000",
    longitude: "-112.23082000",
  },
  {
    id: 115598,
    name: "Eagle",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.69544000",
    longitude: "-116.35401000",
  },
  {
    id: 116058,
    name: "Elmore County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.35390000",
    longitude: "-115.46918000",
  },
  {
    id: 116105,
    name: "Emmett",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.87350000",
    longitude: "-116.49930000",
  },
  {
    id: 116317,
    name: "Fairfield",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.34657000",
    longitude: "-114.79173000",
  },
  {
    id: 116507,
    name: "Filer",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.57019000",
    longitude: "-114.60782000",
  },
  {
    id: 116714,
    name: "Fort Hall",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.03325000",
    longitude: "-112.43831000",
  },
  {
    id: 116864,
    name: "Franklin County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.18117000",
    longitude: "-111.81323000",
  },
  {
    id: 116927,
    name: "Fremont County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "44.22879000",
    longitude: "-111.48202000",
  },
  {
    id: 116972,
    name: "Fruitland",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "44.00766000",
    longitude: "-116.91655000",
  },
  {
    id: 117061,
    name: "Garden City",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.62211000",
    longitude: "-116.23817000",
  },
  {
    id: 117133,
    name: "Gem County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "44.06169000",
    longitude: "-116.39723000",
  },
  {
    id: 117299,
    name: "Glenns Ferry",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.95490000",
    longitude: "-115.30090000",
  },
  {
    id: 117381,
    name: "Gooding",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.93879000",
    longitude: "-114.71311000",
  },
  {
    id: 117382,
    name: "Gooding County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.97090000",
    longitude: "-114.81152000",
  },
  {
    id: 117490,
    name: "Grangeville",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "45.92655000",
    longitude: "-116.12237000",
  },
  {
    id: 117842,
    name: "Hailey",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.51963000",
    longitude: "-114.31532000",
  },
  {
    id: 117976,
    name: "Hansen",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.53068000",
    longitude: "-114.30101000",
  },
  {
    id: 118179,
    name: "Hayden",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "47.76602000",
    longitude: "-116.78658000",
  },
  {
    id: 118351,
    name: "Heyburn",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.55852000",
    longitude: "-113.76390000",
  },
  {
    id: 118378,
    name: "Hidden Spring",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.72216000",
    longitude: "-116.25093000",
  },
  {
    id: 118589,
    name: "Homedale",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.61766000",
    longitude: "-116.93376000",
  },
  {
    id: 118869,
    name: "Idaho City",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.82850000",
    longitude: "-115.83455000",
  },
  {
    id: 118870,
    name: "Idaho County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "45.84420000",
    longitude: "-115.46745000",
  },
  {
    id: 118871,
    name: "Idaho Falls",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.46658000",
    longitude: "-112.03414000",
  },
  {
    id: 118969,
    name: "Iona",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.52630000",
    longitude: "-111.93302000",
  },
  {
    id: 119195,
    name: "Jefferson County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.82014000",
    longitude: "-112.31128000",
  },
  {
    id: 119229,
    name: "Jerome",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.72407000",
    longitude: "-114.51865000",
  },
  {
    id: 119230,
    name: "Jerome County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.68990000",
    longitude: "-114.26403000",
  },
  {
    id: 119371,
    name: "Kamiah",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "46.22712000",
    longitude: "-116.02931000",
  },
  {
    id: 119429,
    name: "Kellogg",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "47.53826000",
    longitude: "-116.11933000",
  },
  {
    id: 119525,
    name: "Ketchum",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.68074000",
    longitude: "-114.36366000",
  },
  {
    id: 119571,
    name: "Kimberly",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.53380000",
    longitude: "-114.36476000",
  },
  {
    id: 119707,
    name: "Kootenai County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "47.67456000",
    longitude: "-116.70001000",
  },
  {
    id: 119725,
    name: "Kuna",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.49183000",
    longitude: "-116.42012000",
  },
  {
    id: 120137,
    name: "Lapwai",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "46.40489000",
    longitude: "-116.80487000",
  },
  {
    id: 120167,
    name: "Latah County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "46.81613000",
    longitude: "-116.71168000",
  },
  {
    id: 120356,
    name: "Lemhi County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "44.94331000",
    longitude: "-113.93324000",
  },
  {
    id: 120416,
    name: "Lewis County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "46.23702000",
    longitude: "-116.42625000",
  },
  {
    id: 120428,
    name: "Lewiston",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "46.41655000",
    longitude: "-117.01766000",
  },
  {
    id: 120430,
    name: "Lewiston Orchards",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "46.38044000",
    longitude: "-116.97543000",
  },
  {
    id: 120511,
    name: "Lincoln",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.51297000",
    longitude: "-111.96441000",
  },
  {
    id: 120533,
    name: "Lincoln County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.00241000",
    longitude: "-114.13831000",
  },
  {
    id: 121018,
    name: "Madison County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.78419000",
    longitude: "-111.65934000",
  },
  {
    id: 121057,
    name: "Malad City",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.19159000",
    longitude: "-112.25080000",
  },
  {
    id: 121330,
    name: "Marsing",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.54544000",
    longitude: "-116.81320000",
  },
  {
    id: 121465,
    name: "McCall",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "44.91101000",
    longitude: "-116.09874000",
  },
  {
    id: 121695,
    name: "Meridian",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.61211000",
    longitude: "-116.39151000",
  },
  {
    id: 121784,
    name: "Middleton",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.70683000",
    longitude: "-116.62014000",
  },
  {
    id: 121947,
    name: "Minidoka County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.85440000",
    longitude: "-113.63752000",
  },
  {
    id: 122178,
    name: "Montpelier",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.32215000",
    longitude: "-111.29770000",
  },
  {
    id: 122228,
    name: "Moreland",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.22269000",
    longitude: "-112.44248000",
  },
  {
    id: 122300,
    name: "Moscow",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "46.73239000",
    longitude: "-117.00017000",
  },
  {
    id: 122417,
    name: "Mountain Home",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.13295000",
    longitude: "-115.69120000",
  },
  {
    id: 122487,
    name: "Murphy",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.21822000",
    longitude: "-116.55234000",
  },
  {
    id: 122538,
    name: "Nampa",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.54072000",
    longitude: "-116.56346000",
  },
  {
    id: 122763,
    name: "New Plymouth",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.96989000",
    longitude: "-116.81904000",
  },
  {
    id: 122878,
    name: "Nez Perce County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "46.32676000",
    longitude: "-116.75024000",
  },
  {
    id: 122879,
    name: "Nezperce",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "46.23489000",
    longitude: "-116.24070000",
  },
  {
    id: 123477,
    name: "Oneida County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.19490000",
    longitude: "-112.53962000",
  },
  {
    id: 123572,
    name: "Orofino",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "46.47935000",
    longitude: "-116.25514000",
  },
  {
    id: 123596,
    name: "Osburn",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "47.50604000",
    longitude: "-115.99933000",
  },
  {
    id: 123674,
    name: "Owyhee County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.58153000",
    longitude: "-116.16998000",
  },
  {
    id: 123836,
    name: "Paris",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.22715000",
    longitude: "-111.40104000",
  },
  {
    id: 123885,
    name: "Parma",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.78516000",
    longitude: "-116.94321000",
  },
  {
    id: 123920,
    name: "Paul",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.60796000",
    longitude: "-113.78335000",
  },
  {
    id: 123944,
    name: "Payette",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "44.07822000",
    longitude: "-116.93377000",
  },
  {
    id: 123945,
    name: "Payette County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "44.00674000",
    longitude: "-116.76084000",
  },
  {
    id: 124253,
    name: "Pinehurst",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "47.53881000",
    longitude: "-116.23739000",
  },
  {
    id: 124397,
    name: "Plummer",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "47.33518000",
    longitude: "-116.88851000",
  },
  {
    id: 124418,
    name: "Pocatello",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.87130000",
    longitude: "-112.44553000",
  },
  {
    id: 124469,
    name: "Ponderay",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "48.30548000",
    longitude: "-116.53380000",
  },
  {
    id: 124577,
    name: "Post Falls",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "47.71796000",
    longitude: "-116.95159000",
  },
  {
    id: 124615,
    name: "Power County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.69369000",
    longitude: "-112.84067000",
  },
  {
    id: 124657,
    name: "Preston",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.09631000",
    longitude: "-111.87662000",
  },
  {
    id: 124669,
    name: "Priest River",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "48.18097000",
    longitude: "-116.91157000",
  },
  {
    id: 124900,
    name: "Rathdrum",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "47.81240000",
    longitude: "-116.89659000",
  },
  {
    id: 125038,
    name: "Rexburg",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.82602000",
    longitude: "-111.78969000",
  },
  {
    id: 125137,
    name: "Rigby",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.67241000",
    longitude: "-111.91497000",
  },
  {
    id: 125515,
    name: "Rupert",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.61908000",
    longitude: "-113.67723000",
  },
  {
    id: 125602,
    name: "Saint Anthony",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.96630000",
    longitude: "-111.68218000",
  },
  {
    id: 125685,
    name: "Saint Maries",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "47.31435000",
    longitude: "-116.56267000",
  },
  {
    id: 125763,
    name: "Salmon",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "45.17575000",
    longitude: "-113.89590000",
  },
  {
    id: 125871,
    name: "Sandpoint",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "48.27659000",
    longitude: "-116.55325000",
  },
  {
    id: 126294,
    name: "Shelley",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.38130000",
    longitude: "-112.12331000",
  },
  {
    id: 126379,
    name: "Shoshone",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.93602000",
    longitude: "-114.40588000",
  },
  {
    id: 126380,
    name: "Shoshone County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "47.35167000",
    longitude: "-115.89103000",
  },
  {
    id: 126562,
    name: "Soda Springs",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.65437000",
    longitude: "-111.60467000",
  },
  {
    id: 126839,
    name: "Spirit Lake",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "47.96629000",
    longitude: "-116.86853000",
  },
  {
    id: 126978,
    name: "Star",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.69211000",
    longitude: "-116.49346000",
  },
  {
    id: 127169,
    name: "Sugar City",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.87297000",
    longitude: "-111.74830000",
  },
  {
    id: 127241,
    name: "Sun Valley",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.69713000",
    longitude: "-114.35172000",
  },
  {
    id: 127537,
    name: "Teton County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.75946000",
    longitude: "-111.20770000",
  },
  {
    id: 127924,
    name: "Twin Falls",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.56297000",
    longitude: "-114.46087000",
  },
  {
    id: 127925,
    name: "Twin Falls County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.35598000",
    longitude: "-114.66716000",
  },
  {
    id: 127939,
    name: "Tyhee",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.95158000",
    longitude: "-112.46637000",
  },
  {
    id: 127953,
    name: "Ucon",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.59630000",
    longitude: "-111.96386000",
  },
  {
    id: 128104,
    name: "Valley County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "44.76670000",
    longitude: "-115.56613000",
  },
  {
    id: 128218,
    name: "Victor",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.60270000",
    longitude: "-111.11133000",
  },
  {
    id: 128398,
    name: "Wallace",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "47.47409000",
    longitude: "-115.92794000",
  },
  {
    id: 128579,
    name: "Washington County",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "44.45243000",
    longitude: "-116.78477000",
  },
  {
    id: 128761,
    name: "Weiser",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "44.25100000",
    longitude: "-116.96933000",
  },
  {
    id: 128800,
    name: "Wendell",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "42.77574000",
    longitude: "-114.70422000",
  },
  {
    id: 129233,
    name: "Wilder",
    state_id: 1460,
    state_code: "ID",
    country_id: 233,
    country_code: "US",
    latitude: "43.67655000",
    longitude: "-116.91182000",
  },
  {
    id: 110985,
    name: "Abingdon",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.80448000",
    longitude: "-90.40180000",
  },
  {
    id: 111017,
    name: "Adams County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.98789000",
    longitude: "-91.18849000",
  },
  {
    id: 111035,
    name: "Addison",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.93170000",
    longitude: "-87.98896000",
  },
  {
    id: 111114,
    name: "Albany Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.96836000",
    longitude: "-87.72339000",
  },
  {
    id: 111117,
    name: "Albers",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.54338000",
    longitude: "-89.61231000",
  },
  {
    id: 111123,
    name: "Albion",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.37755000",
    longitude: "-88.05615000",
  },
  {
    id: 111142,
    name: "Aledo",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.19976000",
    longitude: "-90.74931000",
  },
  {
    id: 111147,
    name: "Alexander County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.19160000",
    longitude: "-89.33764000",
  },
  {
    id: 111167,
    name: "Algonquin",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.16558000",
    longitude: "-88.29425000",
  },
  {
    id: 111216,
    name: "Alorton",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.58977000",
    longitude: "-90.12011000",
  },
  {
    id: 111227,
    name: "Alsip",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.66892000",
    longitude: "-87.73866000",
  },
  {
    id: 111232,
    name: "Altamont",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.06199000",
    longitude: "-88.74811000",
  },
  {
    id: 111242,
    name: "Alton",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.89060000",
    longitude: "-90.18428000",
  },
  {
    id: 111265,
    name: "Amboy",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.71420000",
    longitude: "-89.32871000",
  },
  {
    id: 111308,
    name: "Andalusia",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.43920000",
    longitude: "-90.71764000",
  },
  {
    id: 111346,
    name: "Anna",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.46033000",
    longitude: "-89.24703000",
  },
  {
    id: 111372,
    name: "Antioch",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.47724000",
    longitude: "-88.09564000",
  },
  {
    id: 111436,
    name: "Arcola",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.68476000",
    longitude: "-88.30644000",
  },
  {
    id: 111472,
    name: "Arlington Heights",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.08836000",
    longitude: "-87.98063000",
  },
  {
    id: 111498,
    name: "Arthur",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.71476000",
    longitude: "-88.47228000",
  },
  {
    id: 111515,
    name: "Ashburn",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.74753000",
    longitude: "-87.71116000",
  },
  {
    id: 111528,
    name: "Ashland",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.88783000",
    longitude: "-90.00789000",
  },
  {
    id: 111557,
    name: "Assumption",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.52032000",
    longitude: "-89.04897000",
  },
  {
    id: 111561,
    name: "Astoria",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.22754000",
    longitude: "-90.35957000",
  },
  {
    id: 111574,
    name: "Athens",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.96088000",
    longitude: "-89.72399000",
  },
  {
    id: 111592,
    name: "Atlanta",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.25948000",
    longitude: "-89.23342000",
  },
  {
    id: 111614,
    name: "Atwood",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.79948000",
    longitude: "-88.46228000",
  },
  {
    id: 111621,
    name: "Auburn",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.59172000",
    longitude: "-89.74649000",
  },
  {
    id: 111633,
    name: "Auburn Gresham",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.74179000",
    longitude: "-87.65322000",
  },
  {
    id: 111659,
    name: "Aurora",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.76058000",
    longitude: "-88.32007000",
  },
  {
    id: 111686,
    name: "Aviston",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.60672000",
    longitude: "-89.60759000",
  },
  {
    id: 111700,
    name: "Avondale",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.93892000",
    longitude: "-87.71117000",
  },
  {
    id: 111805,
    name: "Bannockburn",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.19336000",
    longitude: "-87.86646000",
  },
  {
    id: 111846,
    name: "Barrington",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.15391000",
    longitude: "-88.13619000",
  },
  {
    id: 111849,
    name: "Barrington Hills",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.14475000",
    longitude: "-88.15563000",
  },
  {
    id: 111854,
    name: "Barry",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.69421000",
    longitude: "-91.03902000",
  },
  {
    id: 111863,
    name: "Bartlett",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.99503000",
    longitude: "-88.18563000",
  },
  {
    id: 111871,
    name: "Bartonville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.65032000",
    longitude: "-89.65205000",
  },
  {
    id: 111887,
    name: "Batavia",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.85003000",
    longitude: "-88.31257000",
  },
  {
    id: 111966,
    name: "Beach Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.42224000",
    longitude: "-87.85730000",
  },
  {
    id: 111980,
    name: "Beardstown",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.01755000",
    longitude: "-90.42429000",
  },
  {
    id: 112011,
    name: "Beckemeyer",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.60560000",
    longitude: "-89.43592000",
  },
  {
    id: 112039,
    name: "Beecher",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.34059000",
    longitude: "-87.62143000",
  },
  {
    id: 112103,
    name: "Belleville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.52005000",
    longitude: "-89.98399000",
  },
  {
    id: 112110,
    name: "Bellevue",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.68448000",
    longitude: "-89.68010000",
  },
  {
    id: 112131,
    name: "Bellwood",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.88142000",
    longitude: "-87.88312000",
  },
  {
    id: 112143,
    name: "Belmont Cragin",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.93170000",
    longitude: "-87.76867000",
  },
  {
    id: 112155,
    name: "Belvidere",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.26391000",
    longitude: "-88.84427000",
  },
  {
    id: 112159,
    name: "Bement",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.92198000",
    longitude: "-88.57201000",
  },
  {
    id: 112172,
    name: "Benld",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.09282000",
    longitude: "-89.80398000",
  },
  {
    id: 112180,
    name: "Bensenville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.95503000",
    longitude: "-87.94007000",
  },
  {
    id: 112193,
    name: "Benton",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.99672000",
    longitude: "-88.92007000",
  },
  {
    id: 112222,
    name: "Berkeley",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.88892000",
    longitude: "-87.90340000",
  },
  {
    id: 112264,
    name: "Berwyn",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.85059000",
    longitude: "-87.79367000",
  },
  {
    id: 112271,
    name: "Bethalto",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.90921000",
    longitude: "-90.04066000",
  },
  {
    id: 112272,
    name: "Bethany",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.64559000",
    longitude: "-88.73813000",
  },
  {
    id: 112332,
    name: "Big Rock",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.76392000",
    longitude: "-88.54702000",
  },
  {
    id: 112447,
    name: "Bloomingdale",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.95753000",
    longitude: "-88.08090000",
  },
  {
    id: 112451,
    name: "Bloomington",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.48420000",
    longitude: "-88.99369000",
  },
  {
    id: 112469,
    name: "Blue Island",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.65726000",
    longitude: "-87.68005000",
  },
  {
    id: 112471,
    name: "Blue Mound",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.70115000",
    longitude: "-89.12314000",
  },
  {
    id: 112516,
    name: "Bolingbrook",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.69864000",
    longitude: "-88.06840000",
  },
  {
    id: 112531,
    name: "Bond County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.88682000",
    longitude: "-89.43555000",
  },
  {
    id: 112559,
    name: "Boone County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.32308000",
    longitude: "-88.82336000",
  },
  {
    id: 112605,
    name: "Boulder Hill",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.71253000",
    longitude: "-88.33618000",
  },
  {
    id: 112615,
    name: "Bourbonnais",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.15376000",
    longitude: "-87.88754000",
  },
  {
    id: 112672,
    name: "Bradley",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.14198000",
    longitude: "-87.86115000",
  },
  {
    id: 112680,
    name: "Braidwood",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.26503000",
    longitude: "-88.21228000",
  },
  {
    id: 112717,
    name: "Breese",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.61060000",
    longitude: "-89.52703000",
  },
  {
    id: 112761,
    name: "Bridgeport",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.83809000",
    longitude: "-87.65116000",
  },
  {
    id: 112771,
    name: "Bridgeview",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.75003000",
    longitude: "-87.80422000",
  },
  {
    id: 112787,
    name: "Brighton",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.03977000",
    longitude: "-90.14066000",
  },
  {
    id: 112793,
    name: "Brighton Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.81892000",
    longitude: "-87.69894000",
  },
  {
    id: 112827,
    name: "Broadview",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.86392000",
    longitude: "-87.85339000",
  },
  {
    id: 112854,
    name: "Brookfield",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.82392000",
    longitude: "-87.85173000",
  },
  {
    id: 112907,
    name: "Brown County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.96181000",
    longitude: "-90.75034000",
  },
  {
    id: 113021,
    name: "Buffalo Grove",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.15141000",
    longitude: "-87.95979000",
  },
  {
    id: 113029,
    name: "Bull Valley",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.32058000",
    longitude: "-88.35509000",
  },
  {
    id: 113040,
    name: "Bunker Hill",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.04282000",
    longitude: "-89.95177000",
  },
  {
    id: 113046,
    name: "Burbank",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.73392000",
    longitude: "-87.77950000",
  },
  {
    id: 113049,
    name: "Bureau County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.40415000",
    longitude: "-89.52868000",
  },
  {
    id: 113083,
    name: "Burnham",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.63892000",
    longitude: "-87.55671000",
  },
  {
    id: 113093,
    name: "Burr Ridge",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.74892000",
    longitude: "-87.91839000",
  },
  {
    id: 113102,
    name: "Bushnell",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.55282000",
    longitude: "-90.50624000",
  },
  {
    id: 113144,
    name: "Byron",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.12697000",
    longitude: "-89.25566000",
  },
  {
    id: 113168,
    name: "Cahokia",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.57088000",
    longitude: "-90.19011000",
  },
  {
    id: 113170,
    name: "Cairo",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.00533000",
    longitude: "-89.17646000",
  },
  {
    id: 113204,
    name: "Calhoun County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.16930000",
    longitude: "-90.66753000",
  },
  {
    id: 113230,
    name: "Calumet City",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.61559000",
    longitude: "-87.52949000",
  },
  {
    id: 113232,
    name: "Calumet Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.66281000",
    longitude: "-87.66060000",
  },
  {
    id: 113245,
    name: "Cambria",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.78144000",
    longitude: "-89.11925000",
  },
  {
    id: 113251,
    name: "Cambridge",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.30365000",
    longitude: "-90.19290000",
  },
  {
    id: 113292,
    name: "Camp Point",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.03921000",
    longitude: "-91.06930000",
  },
  {
    id: 113343,
    name: "Canton",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.55809000",
    longitude: "-90.03512000",
  },
  {
    id: 113377,
    name: "Capron",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.39974000",
    longitude: "-88.74038000",
  },
  {
    id: 113381,
    name: "Carbon Cliff",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.49476000",
    longitude: "-90.39068000",
  },
  {
    id: 113388,
    name: "Carbondale",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.72727000",
    longitude: "-89.21675000",
  },
  {
    id: 113400,
    name: "Carlinville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.27977000",
    longitude: "-89.88177000",
  },
  {
    id: 113414,
    name: "Carlyle",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.61033000",
    longitude: "-89.37258000",
  },
  {
    id: 113422,
    name: "Carmi",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.09088000",
    longitude: "-88.15865000",
  },
  {
    id: 113434,
    name: "Carol Stream",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.91253000",
    longitude: "-88.13479000",
  },
  {
    id: 113439,
    name: "Carpentersville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.12114000",
    longitude: "-88.25786000",
  },
  {
    id: 113444,
    name: "Carrier Mills",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.68422000",
    longitude: "-88.63283000",
  },
  {
    id: 113459,
    name: "Carroll County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.06861000",
    longitude: "-89.93433000",
  },
  {
    id: 113465,
    name: "Carrollton",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.30227000",
    longitude: "-90.40706000",
  },
  {
    id: 113489,
    name: "Carterville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.76005000",
    longitude: "-89.07730000",
  },
  {
    id: 113496,
    name: "Carthage",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.41643000",
    longitude: "-91.13625000",
  },
  {
    id: 113506,
    name: "Cary",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.21197000",
    longitude: "-88.23814000",
  },
  {
    id: 113519,
    name: "Casey",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.29920000",
    longitude: "-87.99253000",
  },
  {
    id: 113521,
    name: "Caseyville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.63672000",
    longitude: "-90.02566000",
  },
  {
    id: 113526,
    name: "Cass County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.97356000",
    longitude: "-90.24738000",
  },
  {
    id: 113573,
    name: "Catlin",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.06504000",
    longitude: "-87.70197000",
  },
  {
    id: 113667,
    name: "Central City",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.54894000",
    longitude: "-89.12701000",
  },
  {
    id: 113685,
    name: "Centralia",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.52505000",
    longitude: "-89.13340000",
  },
  {
    id: 113692,
    name: "Centreville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.58338000",
    longitude: "-90.12511000",
  },
  {
    id: 113702,
    name: "Cerro Gordo",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.89059000",
    longitude: "-88.72813000",
  },
  {
    id: 113724,
    name: "Champaign",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.11642000",
    longitude: "-88.24338000",
  },
  {
    id: 113725,
    name: "Champaign County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.14008000",
    longitude: "-88.19919000",
  },
  {
    id: 113733,
    name: "Channahon",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.42948000",
    longitude: "-88.22867000",
  },
  {
    id: 113735,
    name: "Channel Lake",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.47863000",
    longitude: "-88.13759000",
  },
  {
    id: 113762,
    name: "Charleston",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.49615000",
    longitude: "-88.17615000",
  },
  {
    id: 113797,
    name: "Chatham",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.74115000",
    longitude: "-87.61255000",
  },
  {
    id: 113806,
    name: "Chatsworth",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.75365000",
    longitude: "-88.29199000",
  },
  {
    id: 113820,
    name: "Chebanse",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.00309000",
    longitude: "-87.90810000",
  },
  {
    id: 113841,
    name: "Chenoa",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.74170000",
    longitude: "-88.71979000",
  },
  {
    id: 113864,
    name: "Cherry Valley",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.23474000",
    longitude: "-88.94899000",
  },
  {
    id: 113882,
    name: "Chester",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.91366000",
    longitude: "-89.82205000",
  },
  {
    id: 113931,
    name: "Chicago",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.85003000",
    longitude: "-87.65005000",
  },
  {
    id: 113932,
    name: "Chicago Heights",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.50615000",
    longitude: "-87.63560000",
  },
  {
    id: 113933,
    name: "Chicago Lawn",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.77503000",
    longitude: "-87.69644000",
  },
  {
    id: 113934,
    name: "Chicago Loop",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.88407000",
    longitude: "-87.63330000",
  },
  {
    id: 113935,
    name: "Chicago Ridge",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.70142000",
    longitude: "-87.77922000",
  },
  {
    id: 113954,
    name: "Chillicothe",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.92226000",
    longitude: "-89.48620000",
  },
  {
    id: 113994,
    name: "Chrisman",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.80365000",
    longitude: "-87.67364000",
  },
  {
    id: 113995,
    name: "Christian County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.54579000",
    longitude: "-89.27727000",
  },
  {
    id: 114002,
    name: "Christopher",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.97255000",
    longitude: "-89.05341000",
  },
  {
    id: 114019,
    name: "Cicero",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.84559000",
    longitude: "-87.75394000",
  },
  {
    id: 114114,
    name: "Clarendon Hills",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.79753000",
    longitude: "-87.95478000",
  },
  {
    id: 114122,
    name: "Clark County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.33357000",
    longitude: "-87.78772000",
  },
  {
    id: 114177,
    name: "Clay County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.75416000",
    longitude: "-88.49019000",
  },
  {
    id: 114257,
    name: "Clifton",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.93531000",
    longitude: "-87.93449000",
  },
  {
    id: 114279,
    name: "Clinton",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.15365000",
    longitude: "-88.96453000",
  },
  {
    id: 114289,
    name: "Clinton County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.60645000",
    longitude: "-89.42248000",
  },
  {
    id: 114327,
    name: "Coal City",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.28781000",
    longitude: "-88.28562000",
  },
  {
    id: 114333,
    name: "Coal Valley",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.42865000",
    longitude: "-90.46096000",
  },
  {
    id: 114346,
    name: "Cobden",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.53144000",
    longitude: "-89.25342000",
  },
  {
    id: 114382,
    name: "Colchester",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.42643000",
    longitude: "-90.79263000",
  },
  {
    id: 114402,
    name: "Coles County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.52029000",
    longitude: "-88.22180000",
  },
  {
    id: 114406,
    name: "Colfax",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.56698000",
    longitude: "-88.61645000",
  },
  {
    id: 114434,
    name: "Collinsville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.67033000",
    longitude: "-89.98455000",
  },
  {
    id: 114445,
    name: "Colona",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.48392000",
    longitude: "-90.35318000",
  },
  {
    id: 114465,
    name: "Columbia",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.44366000",
    longitude: "-90.20122000",
  },
  {
    id: 114592,
    name: "Cook County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.89540000",
    longitude: "-87.64616000",
  },
  {
    id: 114675,
    name: "Cortland",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.92003000",
    longitude: "-88.68870000",
  },
  {
    id: 114718,
    name: "Country Club Hills",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.56809000",
    longitude: "-87.72033000",
  },
  {
    id: 114726,
    name: "Countryside",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.78281000",
    longitude: "-87.87811000",
  },
  {
    id: 114761,
    name: "Crainville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.75199000",
    longitude: "-89.06785000",
  },
  {
    id: 114776,
    name: "Crawford County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.00269000",
    longitude: "-87.75956000",
  },
  {
    id: 114803,
    name: "Crest Hill",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.55475000",
    longitude: "-88.09867000",
  },
  {
    id: 114811,
    name: "Crestwood",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.64463000",
    longitude: "-87.74154000",
  },
  {
    id: 114814,
    name: "Crete",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.44448000",
    longitude: "-87.63143000",
  },
  {
    id: 114817,
    name: "Creve Coeur",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.64726000",
    longitude: "-89.59121000",
  },
  {
    id: 114880,
    name: "Crystal Lake",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.24113000",
    longitude: "-88.31620000",
  },
  {
    id: 114881,
    name: "Crystal Lawns",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.57031000",
    longitude: "-88.15812000",
  },
  {
    id: 114886,
    name: "Cuba",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.18391000",
    longitude: "-88.19091000",
  },
  {
    id: 114912,
    name: "Cumberland County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.27332000",
    longitude: "-88.24023000",
  },
  {
    id: 115030,
    name: "Danvers",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.52948000",
    longitude: "-89.17731000",
  },
  {
    id: 115035,
    name: "Danville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.12448000",
    longitude: "-87.63002000",
  },
  {
    id: 115047,
    name: "Darien",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.75198000",
    longitude: "-87.97395000",
  },
  {
    id: 115076,
    name: "Davis Junction",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.10169000",
    longitude: "-89.09316000",
  },
  {
    id: 115116,
    name: "De Soto",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.81755000",
    longitude: "-89.22786000",
  },
  {
    id: 115123,
    name: "De Witt County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.17463000",
    longitude: "-88.90409000",
  },
  {
    id: 115151,
    name: "Decatur",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.84031000",
    longitude: "-88.95480000",
  },
  {
    id: 115176,
    name: "Deer Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.16086000",
    longitude: "-88.08147000",
  },
  {
    id: 115181,
    name: "Deerfield",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.17114000",
    longitude: "-87.84451000",
  },
  {
    id: 115127,
    name: "DeKalb",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.92947000",
    longitude: "-88.75036000",
  },
  {
    id: 115132,
    name: "DeKalb County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.89353000",
    longitude: "-88.77031000",
  },
  {
    id: 115200,
    name: "Delavan",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.37254000",
    longitude: "-89.54732000",
  },
  {
    id: 115259,
    name: "Depue",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.32420000",
    longitude: "-89.30675000",
  },
  {
    id: 115275,
    name: "Des Plaines",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.03336000",
    longitude: "-87.88340000",
  },
  {
    id: 115313,
    name: "Diamond",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.28864000",
    longitude: "-88.25173000",
  },
  {
    id: 115356,
    name: "Divernon",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.56561000",
    longitude: "-89.65732000",
  },
  {
    id: 115363,
    name: "Dixmoor",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.63170000",
    longitude: "-87.66088000",
  },
  {
    id: 115366,
    name: "Dixon",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.83892000",
    longitude: "-89.47955000",
  },
  {
    id: 115392,
    name: "Dolton",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.63892000",
    longitude: "-87.60727000",
  },
  {
    id: 115420,
    name: "Douglas",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.83476000",
    longitude: "-87.61811000",
  },
  {
    id: 115426,
    name: "Douglas County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.76946000",
    longitude: "-88.21735000",
  },
  {
    id: 115459,
    name: "Downers Grove",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.80892000",
    longitude: "-88.01117000",
  },
  {
    id: 115486,
    name: "Du Quoin",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.01144000",
    longitude: "-89.23619000",
  },
  {
    id: 115544,
    name: "Dunlap",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.86170000",
    longitude: "-89.67871000",
  },
  {
    id: 115488,
    name: "DuPage County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.85195000",
    longitude: "-88.08567000",
  },
  {
    id: 115557,
    name: "Dupo",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.51616000",
    longitude: "-90.21039000",
  },
  {
    id: 115562,
    name: "Durand",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.43640000",
    longitude: "-89.33206000",
  },
  {
    id: 115584,
    name: "Dwight",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.09448000",
    longitude: "-88.42506000",
  },
  {
    id: 115621,
    name: "Earlville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.58948000",
    longitude: "-88.92203000",
  },
  {
    id: 115626,
    name: "East Alton",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.88033000",
    longitude: "-90.11122000",
  },
  {
    id: 115652,
    name: "East Dubuque",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.49223000",
    longitude: "-90.64291000",
  },
  {
    id: 115653,
    name: "East Dundee",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.09891000",
    longitude: "-88.27147000",
  },
  {
    id: 115668,
    name: "East Garfield Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.88087000",
    longitude: "-87.70283000",
  },
  {
    id: 115687,
    name: "East Hazel Crest",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.57365000",
    longitude: "-87.64643000",
  },
  {
    id: 115716,
    name: "East Moline",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.50087000",
    longitude: "-90.44430000",
  },
  {
    id: 115732,
    name: "East Peoria",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.66615000",
    longitude: "-89.58010000",
  },
  {
    id: 115754,
    name: "East Saint Louis",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.62450000",
    longitude: "-90.15094000",
  },
  {
    id: 115836,
    name: "Edgar County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.67853000",
    longitude: "-87.74557000",
  },
  {
    id: 115853,
    name: "Edgewater",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.98337000",
    longitude: "-87.66395000",
  },
  {
    id: 115869,
    name: "Edinburg",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.65727000",
    longitude: "-89.38953000",
  },
  {
    id: 115889,
    name: "Edwards County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.41653000",
    longitude: "-88.05327000",
  },
  {
    id: 115894,
    name: "Edwardsville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.81144000",
    longitude: "-89.95316000",
  },
  {
    id: 115896,
    name: "Effingham",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.12004000",
    longitude: "-88.54338000",
  },
  {
    id: 115899,
    name: "Effingham County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.05977000",
    longitude: "-88.58986000",
  },
  {
    id: 115929,
    name: "El Paso",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.73920000",
    longitude: "-89.01646000",
  },
  {
    id: 115948,
    name: "Elburn",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.89225000",
    longitude: "-88.47230000",
  },
  {
    id: 115952,
    name: "Eldorado",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.81366000",
    longitude: "-88.43810000",
  },
  {
    id: 115966,
    name: "Elgin",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.03725000",
    longitude: "-88.28119000",
  },
  {
    id: 115977,
    name: "Elizabethtown",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.44588000",
    longitude: "-88.30504000",
  },
  {
    id: 115988,
    name: "Elk Grove Village",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.00392000",
    longitude: "-87.97035000",
  },
  {
    id: 116051,
    name: "Elmhurst",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.89947000",
    longitude: "-87.94034000",
  },
  {
    id: 116061,
    name: "Elmwood",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.77782000",
    longitude: "-89.96650000",
  },
  {
    id: 116062,
    name: "Elmwood Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.92114000",
    longitude: "-87.80923000",
  },
  {
    id: 116080,
    name: "Elwood",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.40392000",
    longitude: "-88.11172000",
  },
  {
    id: 116122,
    name: "Energy",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.77394000",
    longitude: "-89.02646000",
  },
  {
    id: 116131,
    name: "Englewood",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.77976000",
    longitude: "-87.64588000",
  },
  {
    id: 116165,
    name: "Erie",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.65642000",
    longitude: "-90.07929000",
  },
  {
    id: 116234,
    name: "Eureka",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.72143000",
    longitude: "-89.27286000",
  },
  {
    id: 116251,
    name: "Evanston",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.04114000",
    longitude: "-87.69006000",
  },
  {
    id: 116265,
    name: "Evergreen Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.72059000",
    longitude: "-87.70172000",
  },
  {
    id: 116294,
    name: "Fairbury",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.74726000",
    longitude: "-88.51478000",
  },
  {
    id: 116310,
    name: "Fairfield",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.37894000",
    longitude: "-88.35977000",
  },
  {
    id: 116336,
    name: "Fairmont",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.55614000",
    longitude: "-88.05923000",
  },
  {
    id: 116338,
    name: "Fairmont City",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.64977000",
    longitude: "-90.09316000",
  },
  {
    id: 116358,
    name: "Fairview Heights",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.58894000",
    longitude: "-89.99038000",
  },
  {
    id: 116402,
    name: "Farmer City",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.24337000",
    longitude: "-88.64257000",
  },
  {
    id: 116416,
    name: "Farmington",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.69809000",
    longitude: "-90.00595000",
  },
  {
    id: 116445,
    name: "Fayette County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.00019000",
    longitude: "-89.02414000",
  },
  {
    id: 116527,
    name: "Fisher",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.31476000",
    longitude: "-88.35005000",
  },
  {
    id: 116547,
    name: "Flanagan",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.87809000",
    longitude: "-88.86118000",
  },
  {
    id: 116570,
    name: "Flora",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.66894000",
    longitude: "-88.48560000",
  },
  {
    id: 116598,
    name: "Flossmoor",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.54281000",
    longitude: "-87.68477000",
  },
  {
    id: 116637,
    name: "Ford County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.59718000",
    longitude: "-88.22326000",
  },
  {
    id: 116638,
    name: "Ford Heights",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.50642000",
    longitude: "-87.59171000",
  },
  {
    id: 116660,
    name: "Forest Lake",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.20752000",
    longitude: "-88.05563000",
  },
  {
    id: 116666,
    name: "Forest Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.87948000",
    longitude: "-87.81367000",
  },
  {
    id: 116678,
    name: "Forrest",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.75198000",
    longitude: "-88.41116000",
  },
  {
    id: 116681,
    name: "Forreston",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.12614000",
    longitude: "-89.57928000",
  },
  {
    id: 116682,
    name: "Forsyth",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.93254000",
    longitude: "-88.95119000",
  },
  {
    id: 116803,
    name: "Fox Lake",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.39669000",
    longitude: "-88.18370000",
  },
  {
    id: 116805,
    name: "Fox Lake Hills",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.40808000",
    longitude: "-88.13175000",
  },
  {
    id: 116807,
    name: "Fox River Grove",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.20086000",
    longitude: "-88.21453000",
  },
  {
    id: 116819,
    name: "Frankfort",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.49587000",
    longitude: "-87.84866000",
  },
  {
    id: 116824,
    name: "Frankfort Square",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.51892000",
    longitude: "-87.80310000",
  },
  {
    id: 116847,
    name: "Franklin County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.99229000",
    longitude: "-88.92415000",
  },
  {
    id: 116868,
    name: "Franklin Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.93531000",
    longitude: "-87.86562000",
  },
  {
    id: 116900,
    name: "Freeburg",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.42755000",
    longitude: "-89.91371000",
  },
  {
    id: 116912,
    name: "Freeport",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.29669000",
    longitude: "-89.62123000",
  },
  {
    id: 116990,
    name: "Fulton",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.86725000",
    longitude: "-90.15957000",
  },
  {
    id: 116995,
    name: "Fulton County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.47277000",
    longitude: "-90.20747000",
  },
  {
    id: 117009,
    name: "Gage Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.79503000",
    longitude: "-87.69616000",
  },
  {
    id: 117010,
    name: "Gages Lake",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.35169000",
    longitude: "-87.98258000",
  },
  {
    id: 117024,
    name: "Galena",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.41667000",
    longitude: "-90.42902000",
  },
  {
    id: 117027,
    name: "Galesburg",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.94782000",
    longitude: "-90.37124000",
  },
  {
    id: 117034,
    name: "Gallatin County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.76275000",
    longitude: "-88.23050000",
  },
  {
    id: 117041,
    name: "Galva",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.16754000",
    longitude: "-90.04261000",
  },
  {
    id: 117076,
    name: "Gardner",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.18559000",
    longitude: "-88.30978000",
  },
  {
    id: 117137,
    name: "Geneseo",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.44809000",
    longitude: "-90.15428000",
  },
  {
    id: 117142,
    name: "Geneva",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.88753000",
    longitude: "-88.30535000",
  },
  {
    id: 117147,
    name: "Genoa",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.09725000",
    longitude: "-88.69287000",
  },
  {
    id: 117162,
    name: "Georgetown",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.13975000",
    longitude: "-89.82873000",
  },
  {
    id: 117171,
    name: "Germantown",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.55366000",
    longitude: "-89.53842000",
  },
  {
    id: 117175,
    name: "Germantown Hills",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.76643000",
    longitude: "-89.46787000",
  },
  {
    id: 117188,
    name: "Gibson City",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.45843000",
    longitude: "-88.38460000",
  },
  {
    id: 117198,
    name: "Gifford",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.30587000",
    longitude: "-88.02115000",
  },
  {
    id: 117206,
    name: "Gilberts",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.10336000",
    longitude: "-88.37286000",
  },
  {
    id: 117214,
    name: "Gillespie",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.12977000",
    longitude: "-89.81954000",
  },
  {
    id: 117219,
    name: "Gilman",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.76670000",
    longitude: "-87.99226000",
  },
  {
    id: 117226,
    name: "Girard",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.44644000",
    longitude: "-89.78093000",
  },
  {
    id: 117244,
    name: "Glasford",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.57254000",
    longitude: "-89.81344000",
  },
  {
    id: 117263,
    name: "Glen Carbon",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.74838000",
    longitude: "-89.98316000",
  },
  {
    id: 117265,
    name: "Glen Ellyn",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.87753000",
    longitude: "-88.06701000",
  },
  {
    id: 117278,
    name: "Glencoe",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.13503000",
    longitude: "-87.75812000",
  },
  {
    id: 117288,
    name: "Glendale Heights",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.91460000",
    longitude: "-88.06486000",
  },
  {
    id: 117309,
    name: "Glenview",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.06975000",
    longitude: "-87.78784000",
  },
  {
    id: 117314,
    name: "Glenwood",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.54253000",
    longitude: "-87.60227000",
  },
  {
    id: 117334,
    name: "Godfrey",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.95560000",
    longitude: "-90.18678000",
  },
  {
    id: 117338,
    name: "Golconda",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.36727000",
    longitude: "-88.48643000",
  },
  {
    id: 117383,
    name: "Goodings Grove",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.62920000",
    longitude: "-87.93089000",
  },
  {
    id: 117402,
    name: "Goreville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.55450000",
    longitude: "-88.97229000",
  },
  {
    id: 117451,
    name: "Grand Boulevard",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.81392000",
    longitude: "-87.61727000",
  },
  {
    id: 117478,
    name: "Grandview",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.81644000",
    longitude: "-89.61871000",
  },
  {
    id: 117484,
    name: "Grandwood Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.39308000",
    longitude: "-87.98674000",
  },
  {
    id: 117494,
    name: "Granite City",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.70144000",
    longitude: "-90.14872000",
  },
  {
    id: 117523,
    name: "Grant Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.24114000",
    longitude: "-87.64615000",
  },
  {
    id: 117535,
    name: "Granville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.26115000",
    longitude: "-89.22759000",
  },
  {
    id: 117559,
    name: "Grayslake",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.34447000",
    longitude: "-88.04175000",
  },
  {
    id: 117568,
    name: "Grayville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.25755000",
    longitude: "-87.99364000",
  },
  {
    id: 117580,
    name: "Greater Grand Crossing",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.76113000",
    longitude: "-87.61485000",
  },
  {
    id: 117604,
    name: "Green Oaks",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.29002000",
    longitude: "-87.90341000",
  },
  {
    id: 117607,
    name: "Green Rock",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.47309000",
    longitude: "-90.35763000",
  },
  {
    id: 117634,
    name: "Greene County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.35620000",
    longitude: "-90.39049000",
  },
  {
    id: 117645,
    name: "Greenfield",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.34366000",
    longitude: "-90.21261000",
  },
  {
    id: 117676,
    name: "Greenup",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.24782000",
    longitude: "-88.16337000",
  },
  {
    id: 117683,
    name: "Greenville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.89227000",
    longitude: "-89.41314000",
  },
  {
    id: 117729,
    name: "Gridley",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.74337000",
    longitude: "-88.88146000",
  },
  {
    id: 117735,
    name: "Griggsville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.70894000",
    longitude: "-90.72457000",
  },
  {
    id: 117772,
    name: "Grundy County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.28509000",
    longitude: "-88.41850000",
  },
  {
    id: 117809,
    name: "Gurnee",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.37030000",
    longitude: "-87.90202000",
  },
  {
    id: 117846,
    name: "Hainesville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.34502000",
    longitude: "-88.06786000",
  },
  {
    id: 117892,
    name: "Hamilton",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.39643000",
    longitude: "-91.33904000",
  },
  {
    id: 117897,
    name: "Hamilton County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.08157000",
    longitude: "-88.53911000",
  },
  {
    id: 117920,
    name: "Hampshire",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.09780000",
    longitude: "-88.53036000",
  },
  {
    id: 117932,
    name: "Hampton",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.55587000",
    longitude: "-90.40930000",
  },
  {
    id: 117956,
    name: "Hancock County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.40378000",
    longitude: "-91.16470000",
  },
  {
    id: 117962,
    name: "Hanna City",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.69170000",
    longitude: "-89.79511000",
  },
  {
    id: 117975,
    name: "Hanover Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.99947000",
    longitude: "-88.14507000",
  },
  {
    id: 117997,
    name: "Hardin",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.15671000",
    longitude: "-90.61790000",
  },
  {
    id: 117999,
    name: "Hardin County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.51820000",
    longitude: "-88.26685000",
  },
  {
    id: 118048,
    name: "Harrisburg",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.73838000",
    longitude: "-88.54061000",
  },
  {
    id: 118072,
    name: "Harristown",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.85393000",
    longitude: "-89.08397000",
  },
  {
    id: 118085,
    name: "Hartford",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.83338000",
    longitude: "-90.09594000",
  },
  {
    id: 118108,
    name: "Harvard",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.42224000",
    longitude: "-88.61371000",
  },
  {
    id: 118112,
    name: "Harvey",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.61003000",
    longitude: "-87.64671000",
  },
  {
    id: 118120,
    name: "Harwood Heights",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.96725000",
    longitude: "-87.80756000",
  },
  {
    id: 118146,
    name: "Havana",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.30004000",
    longitude: "-90.06095000",
  },
  {
    id: 118171,
    name: "Hawthorn Woods",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.21697000",
    longitude: "-88.04952000",
  },
  {
    id: 118202,
    name: "Hazel Crest",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.57170000",
    longitude: "-87.69449000",
  },
  {
    id: 118236,
    name: "Hebron",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.47169000",
    longitude: "-88.43232000",
  },
  {
    id: 118284,
    name: "Henderson County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.81812000",
    longitude: "-90.92511000",
  },
  {
    id: 118290,
    name: "Hennepin",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.25420000",
    longitude: "-89.34231000",
  },
  {
    id: 118296,
    name: "Henry",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.11142000",
    longitude: "-89.35648000",
  },
  {
    id: 118305,
    name: "Henry County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.35313000",
    longitude: "-90.13142000",
  },
  {
    id: 118319,
    name: "Heritage Lake",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.54745000",
    longitude: "-89.32581000",
  },
  {
    id: 118339,
    name: "Herrin",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.80311000",
    longitude: "-89.02757000",
  },
  {
    id: 118340,
    name: "Herscher",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.04920000",
    longitude: "-88.09783000",
  },
  {
    id: 118352,
    name: "Heyworth",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.31337000",
    longitude: "-88.97369000",
  },
  {
    id: 118369,
    name: "Hickory Hills",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.72559000",
    longitude: "-87.82506000",
  },
  {
    id: 118393,
    name: "Highland",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.73949000",
    longitude: "-89.67120000",
  },
  {
    id: 118411,
    name: "Highland Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.18169000",
    longitude: "-87.80034000",
  },
  {
    id: 118426,
    name: "Highwood",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.19975000",
    longitude: "-87.80923000",
  },
  {
    id: 118439,
    name: "Hillcrest",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.95114000",
    longitude: "-89.06454000",
  },
  {
    id: 118446,
    name: "Hillsboro",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.16128000",
    longitude: "-89.49540000",
  },
  {
    id: 118463,
    name: "Hillside",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.87781000",
    longitude: "-87.90284000",
  },
  {
    id: 118476,
    name: "Hinckley",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.76892000",
    longitude: "-88.64091000",
  },
  {
    id: 118485,
    name: "Hinsdale",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.80086000",
    longitude: "-87.93701000",
  },
  {
    id: 118509,
    name: "Hodgkins",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.76892000",
    longitude: "-87.85783000",
  },
  {
    id: 118510,
    name: "Hoffman Estates",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.04281000",
    longitude: "-88.07980000",
  },
  {
    id: 118535,
    name: "Holiday Shores",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.92199000",
    longitude: "-89.94066000",
  },
  {
    id: 118594,
    name: "Homer",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.03476000",
    longitude: "-87.95809000",
  },
  {
    id: 118599,
    name: "Homer Glen",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.60003000",
    longitude: "-87.93811000",
  },
  {
    id: 118605,
    name: "Hometown",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.73448000",
    longitude: "-87.73144000",
  },
  {
    id: 118608,
    name: "Homewood",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.55726000",
    longitude: "-87.66560000",
  },
  {
    id: 118636,
    name: "Hoopeston",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.46726000",
    longitude: "-87.66836000",
  },
  {
    id: 118739,
    name: "Hudson",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.60587000",
    longitude: "-88.98730000",
  },
  {
    id: 118812,
    name: "Huntley",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.16808000",
    longitude: "-88.42814000",
  },
  {
    id: 118849,
    name: "Hyde Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.79420000",
    longitude: "-87.59394000",
  },
  {
    id: 118887,
    name: "Ina",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.15116000",
    longitude: "-88.90396000",
  },
  {
    id: 118904,
    name: "Indian Head Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.77031000",
    longitude: "-87.90228000",
  },
  {
    id: 118934,
    name: "Ingalls Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.52253000",
    longitude: "-88.04283000",
  },
  {
    id: 118956,
    name: "Inverness",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.11808000",
    longitude: "-88.09619000",
  },
  {
    id: 119000,
    name: "Iroquois County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.74723000",
    longitude: "-87.82430000",
  },
  {
    id: 119006,
    name: "Irving Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.95336000",
    longitude: "-87.73645000",
  },
  {
    id: 119026,
    name: "Island Lake",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.27613000",
    longitude: "-88.19203000",
  },
  {
    id: 119040,
    name: "Itasca",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.97503000",
    longitude: "-88.00729000",
  },
  {
    id: 119079,
    name: "Jackson County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.78514000",
    longitude: "-89.38212000",
  },
  {
    id: 119102,
    name: "Jacksonville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.73394000",
    longitude: "-90.22901000",
  },
  {
    id: 119146,
    name: "Jasper County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.01003000",
    longitude: "-88.15381000",
  },
  {
    id: 119179,
    name: "Jefferson County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.30052000",
    longitude: "-88.92401000",
  },
  {
    id: 119227,
    name: "Jerome",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.76755000",
    longitude: "-89.68066000",
  },
  {
    id: 119232,
    name: "Jersey County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.08566000",
    longitude: "-90.35668000",
  },
  {
    id: 119235,
    name: "Jerseyville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.12005000",
    longitude: "-90.32845000",
  },
  {
    id: 119249,
    name: "Jo Daviess County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.36571000",
    longitude: "-90.21241000",
  },
  {
    id: 119253,
    name: "Johnsburg",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.38002000",
    longitude: "-88.24203000",
  },
  {
    id: 119262,
    name: "Johnson County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.45963000",
    longitude: "-88.88089000",
  },
  {
    id: 119280,
    name: "Johnston City",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.82061000",
    longitude: "-88.92757000",
  },
  {
    id: 119288,
    name: "Joliet",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.52519000",
    longitude: "-88.08340000",
  },
  {
    id: 119301,
    name: "Jonesboro",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.45172000",
    longitude: "-89.26814000",
  },
  {
    id: 119345,
    name: "Justice",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.74448000",
    longitude: "-87.83783000",
  },
  {
    id: 119377,
    name: "Kane County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.93894000",
    longitude: "-88.42866000",
  },
  {
    id: 119380,
    name: "Kankakee",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.12003000",
    longitude: "-87.86115000",
  },
  {
    id: 119381,
    name: "Kankakee County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.13770000",
    longitude: "-87.86180000",
  },
  {
    id: 119447,
    name: "Kendall County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.59056000",
    longitude: "-88.42885000",
  },
  {
    id: 119456,
    name: "Kenilworth",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.08586000",
    longitude: "-87.71756000",
  },
  {
    id: 119504,
    name: "Kenwood",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.80920000",
    longitude: "-87.59755000",
  },
  {
    id: 119532,
    name: "Kewanee",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.24559000",
    longitude: "-89.92483000",
  },
  {
    id: 119555,
    name: "Kildeer",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.17058000",
    longitude: "-88.04785000",
  },
  {
    id: 119573,
    name: "Kincaid",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.58866000",
    longitude: "-89.41454000",
  },
  {
    id: 119624,
    name: "Kingston",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.09975000",
    longitude: "-88.75898000",
  },
  {
    id: 119651,
    name: "Kirkland",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.09253000",
    longitude: "-88.85121000",
  },
  {
    id: 119679,
    name: "Knollwood",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.28613000",
    longitude: "-87.88563000",
  },
  {
    id: 119689,
    name: "Knox County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.93182000",
    longitude: "-90.21326000",
  },
  {
    id: 119696,
    name: "Knoxville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.90837000",
    longitude: "-90.28485000",
  },
  {
    id: 119752,
    name: "La Grange",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.80503000",
    longitude: "-87.86923000",
  },
  {
    id: 119753,
    name: "La Grange Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.83475000",
    longitude: "-87.86173000",
  },
  {
    id: 119757,
    name: "La Harpe",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.58337000",
    longitude: "-90.96931000",
  },
  {
    id: 119785,
    name: "La Salle",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.33337000",
    longitude: "-89.09175000",
  },
  {
    id: 119818,
    name: "Lacon",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.02476000",
    longitude: "-89.41120000",
  },
  {
    id: 119822,
    name: "Ladd",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.38253000",
    longitude: "-89.21897000",
  },
  {
    id: 119869,
    name: "Lake Barrington",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.21252000",
    longitude: "-88.15258000",
  },
  {
    id: 119871,
    name: "Lake Bluff",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.27891000",
    longitude: "-87.83424000",
  },
  {
    id: 119874,
    name: "Lake Camelot",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.63065000",
    longitude: "-89.74210000",
  },
  {
    id: 119876,
    name: "Lake Catherine",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.47919000",
    longitude: "-88.13342000",
  },
  {
    id: 119891,
    name: "Lake County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.34941000",
    longitude: "-87.86179000",
  },
  {
    id: 119910,
    name: "Lake Forest",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.25863000",
    longitude: "-87.84063000",
  },
  {
    id: 119921,
    name: "Lake Holiday",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.61292000",
    longitude: "-88.67209000",
  },
  {
    id: 120004,
    name: "Lake in the Hills",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.18169000",
    longitude: "-88.33036000",
  },
  {
    id: 120006,
    name: "Lake of the Woods",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.20642000",
    longitude: "-88.36867000",
  },
  {
    id: 119983,
    name: "Lake Summerset",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.45446000",
    longitude: "-89.38956000",
  },
  {
    id: 119988,
    name: "Lake Villa",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.41697000",
    longitude: "-88.07397000",
  },
  {
    id: 120002,
    name: "Lake Zurich",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.19697000",
    longitude: "-88.09341000",
  },
  {
    id: 120022,
    name: "Lakemoor",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.32863000",
    longitude: "-88.19897000",
  },
  {
    id: 120047,
    name: "Lakewood",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.22919000",
    longitude: "-88.35509000",
  },
  {
    id: 120055,
    name: "Lakewood Shores",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.28170000",
    longitude: "-88.14478000",
  },
  {
    id: 120079,
    name: "Lanark",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.10225000",
    longitude: "-89.83345000",
  },
  {
    id: 120126,
    name: "Lansing",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.56476000",
    longitude: "-87.53893000",
  },
  {
    id: 119806,
    name: "LaSalle County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.34399000",
    longitude: "-88.88595000",
  },
  {
    id: 120231,
    name: "Lawrence County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.71995000",
    longitude: "-87.72673000",
  },
  {
    id: 120244,
    name: "Lawrenceville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.72921000",
    longitude: "-87.68169000",
  },
  {
    id: 120262,
    name: "Le Roy",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.35198000",
    longitude: "-88.76424000",
  },
  {
    id: 120284,
    name: "Lebanon",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.60394000",
    longitude: "-89.80732000",
  },
  {
    id: 120317,
    name: "Lee County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.74619000",
    longitude: "-89.30039000",
  },
  {
    id: 120352,
    name: "Leland Grove",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.77700000",
    longitude: "-89.67927000",
  },
  {
    id: 120360,
    name: "Lemont",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.67364000",
    longitude: "-88.00173000",
  },
  {
    id: 120366,
    name: "Lena",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.37946000",
    longitude: "-89.82234000",
  },
  {
    id: 120431,
    name: "Lewistown",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.39310000",
    longitude: "-90.15484000",
  },
  {
    id: 120449,
    name: "Lexington",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.64142000",
    longitude: "-88.78340000",
  },
  {
    id: 120476,
    name: "Libertyville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.28308000",
    longitude: "-87.95313000",
  },
  {
    id: 120487,
    name: "Lily Lake",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.94892000",
    longitude: "-88.47786000",
  },
  {
    id: 120493,
    name: "Limestone",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.13237000",
    longitude: "-87.96840000",
  },
  {
    id: 120503,
    name: "Lincoln",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.14838000",
    longitude: "-89.36482000",
  },
  {
    id: 120540,
    name: "Lincoln Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.92170000",
    longitude: "-87.64783000",
  },
  {
    id: 120546,
    name: "Lincoln Square",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.97587000",
    longitude: "-87.68922000",
  },
  {
    id: 120550,
    name: "Lincolnshire",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.19002000",
    longitude: "-87.90840000",
  },
  {
    id: 120555,
    name: "Lincolnwood",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.00448000",
    longitude: "-87.73006000",
  },
  {
    id: 120567,
    name: "Lindenhurst",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.41058000",
    longitude: "-88.02619000",
  },
  {
    id: 120602,
    name: "Lisle",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.80114000",
    longitude: "-88.07479000",
  },
  {
    id: 120603,
    name: "Litchfield",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.17533000",
    longitude: "-89.65426000",
  },
  {
    id: 120656,
    name: "Livingston County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.89156000",
    longitude: "-88.55772000",
  },
  {
    id: 120680,
    name: "Lockport",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.58948000",
    longitude: "-88.05784000",
  },
  {
    id: 120700,
    name: "Logan County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.12456000",
    longitude: "-89.36755000",
  },
  {
    id: 120705,
    name: "Logan Square",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.92337000",
    longitude: "-87.69922000",
  },
  {
    id: 120714,
    name: "Lombard",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.88003000",
    longitude: "-88.00784000",
  },
  {
    id: 120742,
    name: "Long Creek",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.81198000",
    longitude: "-88.84757000",
  },
  {
    id: 120743,
    name: "Long Grove",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.17836000",
    longitude: "-87.99785000",
  },
  {
    id: 120746,
    name: "Long Lake",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.37085000",
    longitude: "-88.12758000",
  },
  {
    id: 120812,
    name: "Louisville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.77227000",
    longitude: "-88.50255000",
  },
  {
    id: 120826,
    name: "Loves Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.32002000",
    longitude: "-89.05816000",
  },
  {
    id: 120831,
    name: "Lovington",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.71559000",
    longitude: "-88.63256000",
  },
  {
    id: 120843,
    name: "Lower West Side",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.85420000",
    longitude: "-87.66561000",
  },
  {
    id: 120933,
    name: "Lynwood",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.52642000",
    longitude: "-87.53865000",
  },
  {
    id: 120942,
    name: "Lyons",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.81337000",
    longitude: "-87.81811000",
  },
  {
    id: 120955,
    name: "Machesney Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.34724000",
    longitude: "-89.03900000",
  },
  {
    id: 120960,
    name: "Mackinaw",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.53698000",
    longitude: "-89.35759000",
  },
  {
    id: 120961,
    name: "Macomb",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.45921000",
    longitude: "-90.67180000",
  },
  {
    id: 120963,
    name: "Macon",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.71282000",
    longitude: "-88.99702000",
  },
  {
    id: 120969,
    name: "Macon County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.86000000",
    longitude: "-88.96160000",
  },
  {
    id: 120973,
    name: "Macoupin County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.26102000",
    longitude: "-89.92443000",
  },
  {
    id: 120987,
    name: "Madison",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.68255000",
    longitude: "-90.15705000",
  },
  {
    id: 121006,
    name: "Madison County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.82985000",
    longitude: "-89.90517000",
  },
  {
    id: 121045,
    name: "Mahomet",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.19531000",
    longitude: "-88.40422000",
  },
  {
    id: 121067,
    name: "Malta",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.92975000",
    longitude: "-88.86092000",
  },
  {
    id: 121114,
    name: "Manhattan",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.42253000",
    longitude: "-87.98589000",
  },
  {
    id: 121124,
    name: "Manito",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.42587000",
    longitude: "-89.77928000",
  },
  {
    id: 121155,
    name: "Manteno",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.25059000",
    longitude: "-87.83143000",
  },
  {
    id: 121170,
    name: "Maple Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.90753000",
    longitude: "-88.59925000",
  },
  {
    id: 121202,
    name: "Marengo",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.24863000",
    longitude: "-88.60843000",
  },
  {
    id: 121233,
    name: "Marion",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.73061000",
    longitude: "-88.93313000",
  },
  {
    id: 121251,
    name: "Marion County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.64959000",
    longitude: "-88.91897000",
  },
  {
    id: 121266,
    name: "Marissa",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.25005000",
    longitude: "-89.75010000",
  },
  {
    id: 121270,
    name: "Markham",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.59365000",
    longitude: "-87.69477000",
  },
  {
    id: 121291,
    name: "Maroa",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.03643000",
    longitude: "-88.95703000",
  },
  {
    id: 121295,
    name: "Marquette Heights",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.61754000",
    longitude: "-89.60038000",
  },
  {
    id: 121300,
    name: "Marseilles",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.33087000",
    longitude: "-88.70813000",
  },
  {
    id: 121302,
    name: "Marshall",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.39143000",
    longitude: "-87.69364000",
  },
  {
    id: 121318,
    name: "Marshall County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.03317000",
    longitude: "-89.34478000",
  },
  {
    id: 121349,
    name: "Martinsville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.33559000",
    longitude: "-87.88198000",
  },
  {
    id: 121363,
    name: "Maryville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.72366000",
    longitude: "-89.95593000",
  },
  {
    id: 121369,
    name: "Mascoutah",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.49033000",
    longitude: "-89.79315000",
  },
  {
    id: 121376,
    name: "Mason City",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.20227000",
    longitude: "-89.69816000",
  },
  {
    id: 121380,
    name: "Mason County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.23965000",
    longitude: "-89.91678000",
  },
  {
    id: 121388,
    name: "Massac County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.21903000",
    longitude: "-88.70774000",
  },
  {
    id: 121411,
    name: "Matteson",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.50392000",
    longitude: "-87.71310000",
  },
  {
    id: 121414,
    name: "Mattoon",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.48309000",
    longitude: "-88.37283000",
  },
  {
    id: 121457,
    name: "Maywood",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.87920000",
    longitude: "-87.84312000",
  },
  {
    id: 121489,
    name: "McCullom Lake",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.36835000",
    longitude: "-88.29259000",
  },
  {
    id: 121494,
    name: "McDonough County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.45621000",
    longitude: "-90.67791000",
  },
  {
    id: 121508,
    name: "McHenry",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.33335000",
    longitude: "-88.26675000",
  },
  {
    id: 121509,
    name: "McHenry County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.32439000",
    longitude: "-88.45245000",
  },
  {
    id: 121523,
    name: "McKinley Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.83170000",
    longitude: "-87.67366000",
  },
  {
    id: 121529,
    name: "McLean County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.49089000",
    longitude: "-88.84732000",
  },
  {
    id: 121531,
    name: "McLeansboro",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.09338000",
    longitude: "-88.53561000",
  },
  {
    id: 121632,
    name: "Melrose Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.90059000",
    longitude: "-87.85673000",
  },
  {
    id: 121648,
    name: "Menard County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.02740000",
    longitude: "-89.80217000",
  },
  {
    id: 121656,
    name: "Mendota",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.54725000",
    longitude: "-89.11759000",
  },
  {
    id: 121677,
    name: "Mercer County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.20534000",
    longitude: "-90.74141000",
  },
  {
    id: 121688,
    name: "Meredosia",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.83116000",
    longitude: "-90.55957000",
  },
  {
    id: 121713,
    name: "Merrionette Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.68420000",
    longitude: "-87.70033000",
  },
  {
    id: 121731,
    name: "Metamora",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.79059000",
    longitude: "-89.36064000",
  },
  {
    id: 121736,
    name: "Metropolis",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.15117000",
    longitude: "-88.73200000",
  },
  {
    id: 121808,
    name: "Midlothian",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.62531000",
    longitude: "-87.71755000",
  },
  {
    id: 121837,
    name: "Milan",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.45309000",
    longitude: "-90.57208000",
  },
  {
    id: 121851,
    name: "Milford",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.62837000",
    longitude: "-87.69614000",
  },
  {
    id: 121901,
    name: "Millstadt",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.46144000",
    longitude: "-90.09178000",
  },
  {
    id: 121948,
    name: "Minier",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.43365000",
    longitude: "-89.31315000",
  },
  {
    id: 121961,
    name: "Minonk",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.90448000",
    longitude: "-89.03452000",
  },
  {
    id: 121962,
    name: "Minooka",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.45531000",
    longitude: "-88.26173000",
  },
  {
    id: 121995,
    name: "Mitchell",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.76199000",
    longitude: "-90.08538000",
  },
  {
    id: 122024,
    name: "Mokena",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.52614000",
    longitude: "-87.88922000",
  },
  {
    id: 122027,
    name: "Moline",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.50670000",
    longitude: "-90.51513000",
  },
  {
    id: 122030,
    name: "Momence",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.16670000",
    longitude: "-87.66281000",
  },
  {
    id: 122037,
    name: "Monee",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.42003000",
    longitude: "-87.74171000",
  },
  {
    id: 122042,
    name: "Monmouth",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.91143000",
    longitude: "-90.64736000",
  },
  {
    id: 122071,
    name: "Monroe County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.27865000",
    longitude: "-90.17738000",
  },
  {
    id: 122133,
    name: "Montgomery",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.73058000",
    longitude: "-88.34590000",
  },
  {
    id: 122142,
    name: "Montgomery County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.23104000",
    longitude: "-89.47887000",
  },
  {
    id: 122166,
    name: "Monticello",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.02781000",
    longitude: "-88.57340000",
  },
  {
    id: 122238,
    name: "Morgan County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.71556000",
    longitude: "-90.20150000",
  },
  {
    id: 122247,
    name: "Morgan Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.69031000",
    longitude: "-87.66672000",
  },
  {
    id: 122267,
    name: "Morris",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.35725000",
    longitude: "-88.42118000",
  },
  {
    id: 122276,
    name: "Morrison",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.80975000",
    longitude: "-89.96512000",
  },
  {
    id: 122278,
    name: "Morrisonville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.42005000",
    longitude: "-89.45565000",
  },
  {
    id: 122293,
    name: "Morton",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.61282000",
    longitude: "-89.45926000",
  },
  {
    id: 122298,
    name: "Morton Grove",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.04059000",
    longitude: "-87.78256000",
  },
  {
    id: 122317,
    name: "Moultrie County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.64148000",
    longitude: "-88.61930000",
  },
  {
    id: 122320,
    name: "Mound City",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.08533000",
    longitude: "-89.16257000",
  },
  {
    id: 122335,
    name: "Mount Carmel",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.41088000",
    longitude: "-87.76142000",
  },
  {
    id: 122338,
    name: "Mount Carroll",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.09502000",
    longitude: "-89.97818000",
  },
  {
    id: 122345,
    name: "Mount Greenwood",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.69809000",
    longitude: "-87.70866000",
  },
  {
    id: 122363,
    name: "Mount Morris",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.05031000",
    longitude: "-89.43122000",
  },
  {
    id: 122368,
    name: "Mount Olive",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.07227000",
    longitude: "-89.72731000",
  },
  {
    id: 122385,
    name: "Mount Prospect",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.06642000",
    longitude: "-87.93729000",
  },
  {
    id: 122386,
    name: "Mount Pulaski",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.01088000",
    longitude: "-89.28231000",
  },
  {
    id: 122390,
    name: "Mount Sterling",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.98727000",
    longitude: "-90.76346000",
  },
  {
    id: 122396,
    name: "Mount Vernon",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.31727000",
    longitude: "-88.90312000",
  },
  {
    id: 122409,
    name: "Mount Zion",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.77143000",
    longitude: "-88.87424000",
  },
  {
    id: 122444,
    name: "Moweaqua",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.62476000",
    longitude: "-89.01897000",
  },
  {
    id: 122468,
    name: "Mundelein",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.26308000",
    longitude: "-88.00397000",
  },
  {
    id: 122490,
    name: "Murphysboro",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.76450000",
    longitude: "-89.33509000",
  },
  {
    id: 122550,
    name: "Naperville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.78586000",
    longitude: "-88.14729000",
  },
  {
    id: 122574,
    name: "Nashville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.34366000",
    longitude: "-89.38064000",
  },
  {
    id: 122596,
    name: "Nauvoo",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.55004000",
    longitude: "-91.38487000",
  },
  {
    id: 122607,
    name: "Near North Side",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.90003000",
    longitude: "-87.63450000",
  },
  {
    id: 122608,
    name: "Near South Side",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.85670000",
    longitude: "-87.62477000",
  },
  {
    id: 122632,
    name: "Neoga",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.31948000",
    longitude: "-88.45283000",
  },
  {
    id: 122656,
    name: "New Athens",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.32644000",
    longitude: "-89.87705000",
  },
  {
    id: 122658,
    name: "New Baden",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.53505000",
    longitude: "-89.70065000",
  },
  {
    id: 122663,
    name: "New Berlin",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.72533000",
    longitude: "-89.91066000",
  },
  {
    id: 122694,
    name: "New City",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.80753000",
    longitude: "-87.65644000",
  },
  {
    id: 122737,
    name: "New Lenox",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.51198000",
    longitude: "-87.96561000",
  },
  {
    id: 122802,
    name: "Newark",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.53697000",
    longitude: "-88.58341000",
  },
  {
    id: 122864,
    name: "Newton",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.99088000",
    longitude: "-88.16254000",
  },
  {
    id: 122900,
    name: "Niles",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.01892000",
    longitude: "-87.80284000",
  },
  {
    id: 122930,
    name: "Nokomis",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.30116000",
    longitude: "-89.28508000",
  },
  {
    id: 122948,
    name: "Normal",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.51420000",
    longitude: "-88.99063000",
  },
  {
    id: 122953,
    name: "Norridge",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.96336000",
    longitude: "-87.82728000",
  },
  {
    id: 122956,
    name: "Norris City",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.98116000",
    longitude: "-88.32921000",
  },
  {
    id: 122970,
    name: "North Aurora",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.80614000",
    longitude: "-88.32730000",
  },
  {
    id: 122973,
    name: "North Barrington",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.20780000",
    longitude: "-88.14063000",
  },
  {
    id: 123003,
    name: "North Center",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.95392000",
    longitude: "-87.67895000",
  },
  {
    id: 123006,
    name: "North Chicago",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.32558000",
    longitude: "-87.84118000",
  },
  {
    id: 123055,
    name: "North Lawndale",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.86003000",
    longitude: "-87.71839000",
  },
  {
    id: 123077,
    name: "North Pekin",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.61504000",
    longitude: "-89.62232000",
  },
  {
    id: 123079,
    name: "North Peoria",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.71754000",
    longitude: "-89.58426000",
  },
  {
    id: 123096,
    name: "North Riverside",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.84281000",
    longitude: "-87.82311000",
  },
  {
    id: 123141,
    name: "Northbrook",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.12753000",
    longitude: "-87.82895000",
  },
  {
    id: 123151,
    name: "Northfield",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.09975000",
    longitude: "-87.78090000",
  },
  {
    id: 123158,
    name: "Northlake",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.91725000",
    longitude: "-87.89562000",
  },
  {
    id: 123226,
    name: "O'Fallon",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.59227000",
    longitude: "-89.91121000",
  },
  {
    id: 123230,
    name: "Oak Brook",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.83281000",
    longitude: "-87.92895000",
  },
  {
    id: 123233,
    name: "Oak Forest",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.60281000",
    longitude: "-87.74394000",
  },
  {
    id: 123252,
    name: "Oak Lawn",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.71087000",
    longitude: "-87.75811000",
  },
  {
    id: 123255,
    name: "Oak Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.88503000",
    longitude: "-87.78450000",
  },
  {
    id: 123269,
    name: "Oakbrook Terrace",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.85003000",
    longitude: "-87.96451000",
  },
  {
    id: 123309,
    name: "Oakwood",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.11615000",
    longitude: "-87.77836000",
  },
  {
    id: 123312,
    name: "Oakwood Hills",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.24641000",
    longitude: "-88.24286000",
  },
  {
    id: 123319,
    name: "Oblong",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.00199000",
    longitude: "-87.90892000",
  },
  {
    id: 123361,
    name: "Odin",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.61727000",
    longitude: "-89.05229000",
  },
  {
    id: 123376,
    name: "Ogle County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.04264000",
    longitude: "-89.32065000",
  },
  {
    id: 123377,
    name: "Oglesby",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.29531000",
    longitude: "-89.05953000",
  },
  {
    id: 123397,
    name: "Okawville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.43422000",
    longitude: "-89.55037000",
  },
  {
    id: 123455,
    name: "Olney",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.73088000",
    longitude: "-88.08532000",
  },
  {
    id: 123460,
    name: "Olympia Fields",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.51337000",
    longitude: "-87.67421000",
  },
  {
    id: 123470,
    name: "Onarga",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.71504000",
    longitude: "-88.00615000",
  },
  {
    id: 123500,
    name: "Oquawka",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.93198000",
    longitude: "-90.94709000",
  },
  {
    id: 123546,
    name: "Oregon",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.01475000",
    longitude: "-89.33233000",
  },
  {
    id: 123556,
    name: "Orion",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.35476000",
    longitude: "-90.38152000",
  },
  {
    id: 123560,
    name: "Orland Hills",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.58531000",
    longitude: "-87.84311000",
  },
  {
    id: 123561,
    name: "Orland Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.63031000",
    longitude: "-87.85394000",
  },
  {
    id: 123622,
    name: "Oswego",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.68281000",
    longitude: "-88.35146000",
  },
  {
    id: 123637,
    name: "Ottawa",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.34559000",
    longitude: "-88.84258000",
  },
  {
    id: 123731,
    name: "Palatine",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.11030000",
    longitude: "-88.03424000",
  },
  {
    id: 123736,
    name: "Palestine",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.00365000",
    longitude: "-87.61280000",
  },
  {
    id: 123788,
    name: "Palos Heights",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.66809000",
    longitude: "-87.79644000",
  },
  {
    id: 123789,
    name: "Palos Hills",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.69670000",
    longitude: "-87.81700000",
  },
  {
    id: 123790,
    name: "Palos Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.66725000",
    longitude: "-87.83033000",
  },
  {
    id: 123796,
    name: "Pana",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.38893000",
    longitude: "-89.08008000",
  },
  {
    id: 123830,
    name: "Paris",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.61115000",
    longitude: "-87.69614000",
  },
  {
    id: 123839,
    name: "Park City",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.34836000",
    longitude: "-87.88424000",
  },
  {
    id: 123845,
    name: "Park Forest",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.49142000",
    longitude: "-87.67449000",
  },
  {
    id: 123851,
    name: "Park Ridge",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.01114000",
    longitude: "-87.84062000",
  },
  {
    id: 123933,
    name: "Pawnee",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.59172000",
    longitude: "-89.58037000",
  },
  {
    id: 123941,
    name: "Paxton",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.46031000",
    longitude: "-88.09532000",
  },
  {
    id: 123948,
    name: "Payson",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.81699000",
    longitude: "-91.24237000",
  },
  {
    id: 123978,
    name: "Pecatonica",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.31391000",
    longitude: "-89.35928000",
  },
  {
    id: 123986,
    name: "Pekin",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.56754000",
    longitude: "-89.64066000",
  },
  {
    id: 124046,
    name: "Peoria",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.69365000",
    longitude: "-89.58899000",
  },
  {
    id: 124048,
    name: "Peoria County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.78808000",
    longitude: "-89.75999000",
  },
  {
    id: 124049,
    name: "Peoria Heights",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.74726000",
    longitude: "-89.57398000",
  },
  {
    id: 124051,
    name: "Peotone",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.33226000",
    longitude: "-87.78532000",
  },
  {
    id: 124076,
    name: "Perry County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.08376000",
    longitude: "-89.36702000",
  },
  {
    id: 124095,
    name: "Peru",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.32753000",
    longitude: "-89.12897000",
  },
  {
    id: 124106,
    name: "Petersburg",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.01172000",
    longitude: "-89.84817000",
  },
  {
    id: 124143,
    name: "Philo",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.00698000",
    longitude: "-88.15810000",
  },
  {
    id: 124146,
    name: "Phoenix",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.61115000",
    longitude: "-87.63477000",
  },
  {
    id: 124152,
    name: "Piatt County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.01037000",
    longitude: "-88.59109000",
  },
  {
    id: 124188,
    name: "Pike County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.62250000",
    longitude: "-90.88629000",
  },
  {
    id: 124210,
    name: "Pinckneyville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.08033000",
    longitude: "-89.38203000",
  },
  {
    id: 124269,
    name: "Pingree Grove",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.06864000",
    longitude: "-88.41342000",
  },
  {
    id: 124284,
    name: "Pistakee Highlands",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.40863000",
    longitude: "-88.20648000",
  },
  {
    id: 124297,
    name: "Pittsfield",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.60783000",
    longitude: "-90.80513000",
  },
  {
    id: 124320,
    name: "Plainfield",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.62697000",
    longitude: "-88.20395000",
  },
  {
    id: 124344,
    name: "Plano",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.66281000",
    longitude: "-88.53702000",
  },
  {
    id: 124454,
    name: "Polo",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.98614000",
    longitude: "-89.57928000",
  },
  {
    id: 124473,
    name: "Pontiac",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.88087000",
    longitude: "-88.62978000",
  },
  {
    id: 124475,
    name: "Pontoon Beach",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.73172000",
    longitude: "-90.08038000",
  },
  {
    id: 124482,
    name: "Pope County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.41276000",
    longitude: "-88.56158000",
  },
  {
    id: 124485,
    name: "Poplar Grove",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.36835000",
    longitude: "-88.82205000",
  },
  {
    id: 124498,
    name: "Port Barrington",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.24252000",
    longitude: "-88.20203000",
  },
  {
    id: 124499,
    name: "Port Byron",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.60642000",
    longitude: "-90.33541000",
  },
  {
    id: 124548,
    name: "Portage Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.95781000",
    longitude: "-87.76506000",
  },
  {
    id: 124573,
    name: "Posen",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.63170000",
    longitude: "-87.68144000",
  },
  {
    id: 124628,
    name: "Prairie Grove",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.27863000",
    longitude: "-88.26092000",
  },
  {
    id: 124652,
    name: "Prestbury",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.78329000",
    longitude: "-88.41764000",
  },
  {
    id: 124660,
    name: "Preston Heights",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.49170000",
    longitude: "-88.08172000",
  },
  {
    id: 124686,
    name: "Princeton",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.36809000",
    longitude: "-89.46481000",
  },
  {
    id: 124695,
    name: "Princeville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.92976000",
    longitude: "-89.75760000",
  },
  {
    id: 124704,
    name: "Prophetstown",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.67142000",
    longitude: "-89.93622000",
  },
  {
    id: 124708,
    name: "Prospect Heights",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.09530000",
    longitude: "-87.93757000",
  },
  {
    id: 124739,
    name: "Pulaski County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.22291000",
    longitude: "-89.12657000",
  },
  {
    id: 124766,
    name: "Putnam County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.20447000",
    longitude: "-89.28583000",
  },
  {
    id: 124796,
    name: "Quincy",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.93560000",
    longitude: "-91.40987000",
  },
  {
    id: 124840,
    name: "Ramsey",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.14449000",
    longitude: "-89.10868000",
  },
  {
    id: 124875,
    name: "Randolph County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.05212000",
    longitude: "-89.82531000",
  },
  {
    id: 124891,
    name: "Rantoul",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.30837000",
    longitude: "-88.15588000",
  },
  {
    id: 124942,
    name: "Red Bud",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.21172000",
    longitude: "-89.99427000",
  },
  {
    id: 125074,
    name: "Richland County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.71236000",
    longitude: "-88.08510000",
  },
  {
    id: 125089,
    name: "Richmond",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.47585000",
    longitude: "-88.30593000",
  },
  {
    id: 125108,
    name: "Richton Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.48448000",
    longitude: "-87.70338000",
  },
  {
    id: 125179,
    name: "River Forest",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.89781000",
    longitude: "-87.81395000",
  },
  {
    id: 125180,
    name: "River Grove",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.92586000",
    longitude: "-87.83589000",
  },
  {
    id: 125193,
    name: "Riverdale",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.63337000",
    longitude: "-87.63310000",
  },
  {
    id: 125206,
    name: "Riverside",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.83503000",
    longitude: "-87.82284000",
  },
  {
    id: 125211,
    name: "Riverton",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.84422000",
    longitude: "-89.53954000",
  },
  {
    id: 125220,
    name: "Riverwoods",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.16753000",
    longitude: "-87.89701000",
  },
  {
    id: 125229,
    name: "Roanoke",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.79615000",
    longitude: "-89.19730000",
  },
  {
    id: 125235,
    name: "Robbins",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.64392000",
    longitude: "-87.70366000",
  },
  {
    id: 125253,
    name: "Robinson",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.00532000",
    longitude: "-87.73919000",
  },
  {
    id: 125259,
    name: "Rochelle",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.92392000",
    longitude: "-89.06871000",
  },
  {
    id: 125261,
    name: "Rochester",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.74949000",
    longitude: "-89.53176000",
  },
  {
    id: 125277,
    name: "Rock Falls",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.77975000",
    longitude: "-89.68900000",
  },
  {
    id: 125283,
    name: "Rock Island",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.50948000",
    longitude: "-90.57875000",
  },
  {
    id: 125284,
    name: "Rock Island County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.46733000",
    longitude: "-90.56743000",
  },
  {
    id: 125296,
    name: "Rockdale",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.50614000",
    longitude: "-88.11450000",
  },
  {
    id: 125300,
    name: "Rockford",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.27113000",
    longitude: "-89.09400000",
  },
  {
    id: 125320,
    name: "Rockton",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.45252000",
    longitude: "-89.07233000",
  },
  {
    id: 125353,
    name: "Rogers Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.00864000",
    longitude: "-87.66672000",
  },
  {
    id: 125368,
    name: "Rolling Meadows",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.08419000",
    longitude: "-88.01313000",
  },
  {
    id: 125376,
    name: "Rome",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.88309000",
    longitude: "-89.50259000",
  },
  {
    id: 125382,
    name: "Romeoville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.64753000",
    longitude: "-88.08951000",
  },
  {
    id: 125389,
    name: "Roodhouse",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.48394000",
    longitude: "-90.37151000",
  },
  {
    id: 125399,
    name: "Roscoe",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.41335000",
    longitude: "-89.00927000",
  },
  {
    id: 125426,
    name: "Roselle",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.98475000",
    longitude: "-88.07979000",
  },
  {
    id: 125430,
    name: "Rosemont",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.99531000",
    longitude: "-87.88451000",
  },
  {
    id: 125442,
    name: "Rosewood Heights",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.88783000",
    longitude: "-90.08483000",
  },
  {
    id: 125444,
    name: "Rosiclare",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.42366000",
    longitude: "-88.34615000",
  },
  {
    id: 125459,
    name: "Rossville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.37920000",
    longitude: "-87.66863000",
  },
  {
    id: 125469,
    name: "Round Lake",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.35336000",
    longitude: "-88.09341000",
  },
  {
    id: 125470,
    name: "Round Lake Beach",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.37169000",
    longitude: "-88.09008000",
  },
  {
    id: 125471,
    name: "Round Lake Heights",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.38002000",
    longitude: "-88.10425000",
  },
  {
    id: 125472,
    name: "Round Lake Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.35697000",
    longitude: "-88.07675000",
  },
  {
    id: 125483,
    name: "Roxana",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.84838000",
    longitude: "-90.07622000",
  },
  {
    id: 125495,
    name: "Royalton",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.87699000",
    longitude: "-89.11452000",
  },
  {
    id: 125526,
    name: "Rushville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.12116000",
    longitude: "-90.56318000",
  },
  {
    id: 125599,
    name: "Saint Anne",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.02503000",
    longitude: "-87.71392000",
  },
  {
    id: 125613,
    name: "Saint Charles",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.91419000",
    longitude: "-88.30869000",
  },
  {
    id: 125622,
    name: "Saint Clair County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.47031000",
    longitude: "-89.92841000",
  },
  {
    id: 125632,
    name: "Saint Elmo",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.02727000",
    longitude: "-88.84811000",
  },
  {
    id: 125651,
    name: "Saint Jacob",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.71394000",
    longitude: "-89.76815000",
  },
  {
    id: 125672,
    name: "Saint Joseph",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.11170000",
    longitude: "-88.04170000",
  },
  {
    id: 125728,
    name: "Salem",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.62699000",
    longitude: "-88.94562000",
  },
  {
    id: 125750,
    name: "Saline County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.75318000",
    longitude: "-88.54080000",
  },
  {
    id: 125868,
    name: "Sandoval",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.61560000",
    longitude: "-89.11423000",
  },
  {
    id: 125876,
    name: "Sandwich",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.64586000",
    longitude: "-88.62174000",
  },
  {
    id: 125889,
    name: "Sangamon County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.75817000",
    longitude: "-89.65890000",
  },
  {
    id: 125966,
    name: "Sauk Village",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.48837000",
    longitude: "-87.56754000",
  },
  {
    id: 125973,
    name: "Savanna",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.09447000",
    longitude: "-90.15679000",
  },
  {
    id: 125978,
    name: "Savoy",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.05475000",
    longitude: "-88.25172000",
  },
  {
    id: 126002,
    name: "Schaumburg",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.03336000",
    longitude: "-88.08341000",
  },
  {
    id: 126007,
    name: "Schiller Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.95586000",
    longitude: "-87.87090000",
  },
  {
    id: 126022,
    name: "Schuyler County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.15803000",
    longitude: "-90.61507000",
  },
  {
    id: 126040,
    name: "Scott Air Force Base",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.54270000",
    longitude: "-89.85035000",
  },
  {
    id: 126044,
    name: "Scott County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.64414000",
    longitude: "-90.47470000",
  },
  {
    id: 126157,
    name: "Seneca",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.31114000",
    longitude: "-88.60979000",
  },
  {
    id: 126168,
    name: "Sesser",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.09172000",
    longitude: "-89.05035000",
  },
  {
    id: 126253,
    name: "Shawneetown",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.71310000",
    longitude: "-88.18670000",
  },
  {
    id: 126273,
    name: "Shelby County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.39116000",
    longitude: "-88.80554000",
  },
  {
    id: 126281,
    name: "Shelbyville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.40643000",
    longitude: "-88.79007000",
  },
  {
    id: 126287,
    name: "Sheldon",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.76920000",
    longitude: "-87.56392000",
  },
  {
    id: 126319,
    name: "Sheridan",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.53003000",
    longitude: "-88.67980000",
  },
  {
    id: 126329,
    name: "Sherman",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.89366000",
    longitude: "-89.60482000",
  },
  {
    id: 126348,
    name: "Shiloh",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.56144000",
    longitude: "-89.89732000",
  },
  {
    id: 126370,
    name: "Shorewood",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.52003000",
    longitude: "-88.20173000",
  },
  {
    id: 126395,
    name: "Sidney",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.02503000",
    longitude: "-88.07337000",
  },
  {
    id: 126444,
    name: "Silvis",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.51226000",
    longitude: "-90.41513000",
  },
  {
    id: 126483,
    name: "Skokie",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.03336000",
    longitude: "-87.73339000",
  },
  {
    id: 126497,
    name: "Sleepy Hollow",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.09419000",
    longitude: "-88.30258000",
  },
  {
    id: 126525,
    name: "Smithton",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.40866000",
    longitude: "-89.99205000",
  },
  {
    id: 126597,
    name: "Somonauk",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.63364000",
    longitude: "-88.68119000",
  },
  {
    id: 126617,
    name: "South Barrington",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.09142000",
    longitude: "-88.12174000",
  },
  {
    id: 126623,
    name: "South Beloit",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.49307000",
    longitude: "-89.03678000",
  },
  {
    id: 126637,
    name: "South Chicago",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.73977000",
    longitude: "-87.55425000",
  },
  {
    id: 126638,
    name: "South Chicago Heights",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.48087000",
    longitude: "-87.63782000",
  },
  {
    id: 126651,
    name: "South Elgin",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.99419000",
    longitude: "-88.29230000",
  },
  {
    id: 126674,
    name: "South Holland",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.60087000",
    longitude: "-87.60699000",
  },
  {
    id: 126679,
    name: "South Jacksonville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.70866000",
    longitude: "-90.22818000",
  },
  {
    id: 126687,
    name: "South Lawndale",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.84364000",
    longitude: "-87.71255000",
  },
  {
    id: 126709,
    name: "South Pekin",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.49448000",
    longitude: "-89.65177000",
  },
  {
    id: 126720,
    name: "South Roxana",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.82949000",
    longitude: "-90.06288000",
  },
  {
    id: 126729,
    name: "South Shore",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.76198000",
    longitude: "-87.57783000",
  },
  {
    id: 126771,
    name: "Southern View",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.75727000",
    longitude: "-89.65371000",
  },
  {
    id: 126806,
    name: "Sparta",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.12311000",
    longitude: "-89.70177000",
  },
  {
    id: 126858,
    name: "Spring Grove",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.44363000",
    longitude: "-88.23648000",
  },
  {
    id: 126875,
    name: "Spring Valley",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.32754000",
    longitude: "-89.19981000",
  },
  {
    id: 126890,
    name: "Springfield",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.80172000",
    longitude: "-89.64371000",
  },
  {
    id: 126984,
    name: "Stark County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.09336000",
    longitude: "-89.79749000",
  },
  {
    id: 127000,
    name: "Staunton",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.01227000",
    longitude: "-89.79121000",
  },
  {
    id: 127012,
    name: "Steeleville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.00727000",
    longitude: "-89.65843000",
  },
  {
    id: 127016,
    name: "Steger",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.47003000",
    longitude: "-87.63643000",
  },
  {
    id: 127024,
    name: "Stephenson County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.35175000",
    longitude: "-89.66235000",
  },
  {
    id: 127029,
    name: "Sterling",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.78864000",
    longitude: "-89.69622000",
  },
  {
    id: 127056,
    name: "Stickney",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.82142000",
    longitude: "-87.78283000",
  },
  {
    id: 127059,
    name: "Stillman Valley",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.10725000",
    longitude: "-89.17927000",
  },
  {
    id: 127073,
    name: "Stockton",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.34974000",
    longitude: "-90.00679000",
  },
  {
    id: 127084,
    name: "Stone Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.90559000",
    longitude: "-87.88367000",
  },
  {
    id: 127135,
    name: "Streamwood",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.02558000",
    longitude: "-88.17841000",
  },
  {
    id: 127136,
    name: "Streator",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.12087000",
    longitude: "-88.83535000",
  },
  {
    id: 127171,
    name: "Sugar Grove",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.76142000",
    longitude: "-88.44369000",
  },
  {
    id: 127181,
    name: "Sullivan",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.59948000",
    longitude: "-88.60784000",
  },
  {
    id: 127212,
    name: "Summit",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.78809000",
    longitude: "-87.81033000",
  },
  {
    id: 127221,
    name: "Sumner",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.71699000",
    longitude: "-87.86142000",
  },
  {
    id: 127325,
    name: "Swansea",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.53394000",
    longitude: "-89.98899000",
  },
  {
    id: 127351,
    name: "Sycamore",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.98892000",
    longitude: "-88.68675000",
  },
  {
    id: 127467,
    name: "Taylorville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.54894000",
    longitude: "-89.29453000",
  },
  {
    id: 127471,
    name: "Tazewell County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.50752000",
    longitude: "-89.51342000",
  },
  {
    id: 127540,
    name: "Teutopolis",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.13310000",
    longitude: "-88.47199000",
  },
  {
    id: 127558,
    name: "The Galena Territory",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.39343000",
    longitude: "-90.32582000",
  },
  {
    id: 127577,
    name: "Third Lake",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.37391000",
    longitude: "-88.01091000",
  },
  {
    id: 127582,
    name: "Thomasboro",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.24170000",
    longitude: "-88.18421000",
  },
  {
    id: 127601,
    name: "Thornton",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.56809000",
    longitude: "-87.60810000",
  },
  {
    id: 127653,
    name: "Tilton",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.09531000",
    longitude: "-87.64752000",
  },
  {
    id: 127666,
    name: "Tinley Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.57337000",
    longitude: "-87.78449000",
  },
  {
    id: 127698,
    name: "Toledo",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.27365000",
    longitude: "-88.24365000",
  },
  {
    id: 127704,
    name: "Tolono",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.98614000",
    longitude: "-88.25894000",
  },
  {
    id: 127705,
    name: "Toluca",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.00226000",
    longitude: "-89.13342000",
  },
  {
    id: 127749,
    name: "Toulon",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.09365000",
    longitude: "-89.86483000",
  },
  {
    id: 127755,
    name: "Tower Lake",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.23197000",
    longitude: "-88.15202000",
  },
  {
    id: 127793,
    name: "Tremont",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.52754000",
    longitude: "-89.49260000",
  },
  {
    id: 127803,
    name: "Trenton",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.60560000",
    longitude: "-89.68204000",
  },
  {
    id: 127843,
    name: "Troy",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.72921000",
    longitude: "-89.88315000",
  },
  {
    id: 127911,
    name: "Tuscola",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.79920000",
    longitude: "-88.28310000",
  },
  {
    id: 127926,
    name: "Twin Grove",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.49337000",
    longitude: "-89.07980000",
  },
  {
    id: 127991,
    name: "Union County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.47123000",
    longitude: "-89.25509000",
  },
  {
    id: 128035,
    name: "University Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.44298000",
    longitude: "-87.68360000",
  },
  {
    id: 128040,
    name: "Upper Alton",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.91144000",
    longitude: "-90.15066000",
  },
  {
    id: 128058,
    name: "Uptown",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.96590000",
    longitude: "-87.65262000",
  },
  {
    id: 128062,
    name: "Urbana",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.11059000",
    longitude: "-88.20727000",
  },
  {
    id: 128119,
    name: "Valmeyer",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.30561000",
    longitude: "-90.27651000",
  },
  {
    id: 128145,
    name: "Vandalia",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.96060000",
    longitude: "-89.09368000",
  },
  {
    id: 128169,
    name: "Venetian Village",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.39863000",
    longitude: "-88.05258000",
  },
  {
    id: 128171,
    name: "Venice",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.67227000",
    longitude: "-90.16983000",
  },
  {
    id: 128183,
    name: "Vermilion County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.18342000",
    longitude: "-87.73283000",
  },
  {
    id: 128194,
    name: "Vernon Hills",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.21947000",
    longitude: "-87.97952000",
  },
  {
    id: 128231,
    name: "Vienna",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.41533000",
    longitude: "-88.89784000",
  },
  {
    id: 128241,
    name: "Villa Grove",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.86281000",
    longitude: "-88.16227000",
  },
  {
    id: 128243,
    name: "Villa Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.88975000",
    longitude: "-87.98895000",
  },
  {
    id: 128252,
    name: "Village of Campton Hills",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.93660000",
    longitude: "-88.39750000",
  },
  {
    id: 128285,
    name: "Virden",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.50089000",
    longitude: "-89.76787000",
  },
  {
    id: 128286,
    name: "Virginia",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.95116000",
    longitude: "-90.21234000",
  },
  {
    id: 128302,
    name: "Volo",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.32613000",
    longitude: "-88.16786000",
  },
  {
    id: 128310,
    name: "Wabash County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.44607000",
    longitude: "-87.84425000",
  },
  {
    id: 128326,
    name: "Wadsworth",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.42863000",
    longitude: "-87.92397000",
  },
  {
    id: 128413,
    name: "Walnut",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.55670000",
    longitude: "-89.59343000",
  },
  {
    id: 128443,
    name: "Wamac",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.50894000",
    longitude: "-89.14063000",
  },
  {
    id: 128479,
    name: "Warren",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.49640000",
    longitude: "-89.98957000",
  },
  {
    id: 128494,
    name: "Warren County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.84883000",
    longitude: "-90.61503000",
  },
  {
    id: 128501,
    name: "Warrensburg",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.93282000",
    longitude: "-89.06203000",
  },
  {
    id: 128510,
    name: "Warrenville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.81781000",
    longitude: "-88.17340000",
  },
  {
    id: 128519,
    name: "Warsaw",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.35921000",
    longitude: "-91.43460000",
  },
  {
    id: 128527,
    name: "Wasco",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.93808000",
    longitude: "-88.40452000",
  },
  {
    id: 128533,
    name: "Washburn",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.91920000",
    longitude: "-89.29120000",
  },
  {
    id: 128546,
    name: "Washington",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.70365000",
    longitude: "-89.40731000",
  },
  {
    id: 128556,
    name: "Washington County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.35217000",
    longitude: "-89.41045000",
  },
  {
    id: 128585,
    name: "Washington Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.63505000",
    longitude: "-90.09289000",
  },
  {
    id: 128611,
    name: "Waterloo",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.33589000",
    longitude: "-90.14983000",
  },
  {
    id: 128617,
    name: "Waterman",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.77170000",
    longitude: "-88.77369000",
  },
  {
    id: 128638,
    name: "Watseka",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.77615000",
    longitude: "-87.73642000",
  },
  {
    id: 128646,
    name: "Wauconda",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.25891000",
    longitude: "-88.13925000",
  },
  {
    id: 128648,
    name: "Waukegan",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.36363000",
    longitude: "-87.84479000",
  },
  {
    id: 128665,
    name: "Waverly",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.59172000",
    longitude: "-89.95288000",
  },
  {
    id: 128682,
    name: "Wayne",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.95086000",
    longitude: "-88.24230000",
  },
  {
    id: 128688,
    name: "Wayne City",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.34533000",
    longitude: "-88.58783000",
  },
  {
    id: 128690,
    name: "Wayne County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.42956000",
    longitude: "-88.42561000",
  },
  {
    id: 128846,
    name: "West Chicago",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.88475000",
    longitude: "-88.20396000",
  },
  {
    id: 128858,
    name: "West Dundee",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.09808000",
    longitude: "-88.28286000",
  },
  {
    id: 128862,
    name: "West Elsdon",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.79392000",
    longitude: "-87.72450000",
  },
  {
    id: 128865,
    name: "West Englewood",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.77809000",
    longitude: "-87.66672000",
  },
  {
    id: 128873,
    name: "West Frankfort",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.89783000",
    longitude: "-88.93146000",
  },
  {
    id: 128875,
    name: "West Garfield Park",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.88059000",
    longitude: "-87.72922000",
  },
  {
    id: 128913,
    name: "West Lawn",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.77281000",
    longitude: "-87.72227000",
  },
  {
    id: 128953,
    name: "West Peoria",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.69254000",
    longitude: "-89.62788000",
  },
  {
    id: 128969,
    name: "West Ridge",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.99975000",
    longitude: "-87.69284000",
  },
  {
    id: 128992,
    name: "West Town",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.89381000",
    longitude: "-87.67493000",
  },
  {
    id: 129019,
    name: "Westchester",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.85059000",
    longitude: "-87.88200000",
  },
  {
    id: 129025,
    name: "Western Springs",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.80975000",
    longitude: "-87.90062000",
  },
  {
    id: 129049,
    name: "Westmont",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.79586000",
    longitude: "-87.97562000",
  },
  {
    id: 129078,
    name: "Westville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.04226000",
    longitude: "-87.63863000",
  },
  {
    id: 129113,
    name: "Wheaton",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.86614000",
    longitude: "-88.10701000",
  },
  {
    id: 129120,
    name: "Wheeling",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.13919000",
    longitude: "-87.92896000",
  },
  {
    id: 129136,
    name: "White County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.08748000",
    longitude: "-88.17957000",
  },
  {
    id: 129140,
    name: "White Hall",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.43699000",
    longitude: "-90.40318000",
  },
  {
    id: 129186,
    name: "Whiteside County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.75626000",
    longitude: "-89.91409000",
  },
  {
    id: 129253,
    name: "Will County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.44503000",
    longitude: "-87.97866000",
  },
  {
    id: 129276,
    name: "Williamson County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.73025000",
    longitude: "-88.92994000",
  },
  {
    id: 129291,
    name: "Williamsville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.95422000",
    longitude: "-89.54871000",
  },
  {
    id: 129310,
    name: "Willow Springs",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.74087000",
    longitude: "-87.86033000",
  },
  {
    id: 129313,
    name: "Willowbrook",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.76975000",
    longitude: "-87.93589000",
  },
  {
    id: 129320,
    name: "Wilmette",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.07225000",
    longitude: "-87.72284000",
  },
  {
    id: 129323,
    name: "Wilmington",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.30781000",
    longitude: "-88.14672000",
  },
  {
    id: 129352,
    name: "Winchester",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.62977000",
    longitude: "-90.45624000",
  },
  {
    id: 129377,
    name: "Windsor",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "39.44087000",
    longitude: "-88.59478000",
  },
  {
    id: 129397,
    name: "Winfield",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.86170000",
    longitude: "-88.16090000",
  },
  {
    id: 129405,
    name: "Winnebago",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.26613000",
    longitude: "-89.24122000",
  },
  {
    id: 129408,
    name: "Winnebago County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.33626000",
    longitude: "-89.16085000",
  },
  {
    id: 129414,
    name: "Winnetka",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.10808000",
    longitude: "-87.73590000",
  },
  {
    id: 129452,
    name: "Winthrop Harbor",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.47891000",
    longitude: "-87.82368000",
  },
  {
    id: 129483,
    name: "Wonder Lake",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.38530000",
    longitude: "-88.34731000",
  },
  {
    id: 129487,
    name: "Wood Dale",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.96336000",
    longitude: "-87.97896000",
  },
  {
    id: 129488,
    name: "Wood River",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.86116000",
    longitude: "-90.09761000",
  },
  {
    id: 129520,
    name: "Woodford County",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "40.78823000",
    longitude: "-89.21114000",
  },
  {
    id: 129536,
    name: "Woodlawn",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.77948000",
    longitude: "-87.59949000",
  },
  {
    id: 129545,
    name: "Woodridge",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.74697000",
    longitude: "-88.05034000",
  },
  {
    id: 129563,
    name: "Woodstock",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.31474000",
    longitude: "-88.44870000",
  },
  {
    id: 129588,
    name: "Worden",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "38.93144000",
    longitude: "-89.83899000",
  },
  {
    id: 129591,
    name: "Worth",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.68975000",
    longitude: "-87.79728000",
  },
  {
    id: 129635,
    name: "Wyoming",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.06170000",
    longitude: "-89.77316000",
  },
  {
    id: 129709,
    name: "Yorkville",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "41.64114000",
    longitude: "-88.44729000",
  },
  {
    id: 129745,
    name: "Zeigler",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "37.89949000",
    longitude: "-89.05202000",
  },
  {
    id: 129757,
    name: "Zion",
    state_id: 1425,
    state_code: "IL",
    country_id: 233,
    country_code: "US",
    latitude: "42.44613000",
    longitude: "-87.83285000",
  },
  {
    id: 110976,
    name: "Aberdeen",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.43893000",
    longitude: "-87.11142000",
  },
  {
    id: 111021,
    name: "Adams County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.74566000",
    longitude: "-84.93665000",
  },
  {
    id: 111077,
    name: "Akron",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.03838000",
    longitude: "-86.02805000",
  },
  {
    id: 111105,
    name: "Albany",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.30088000",
    longitude: "-85.24191000",
  },
  {
    id: 111124,
    name: "Albion",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.39560000",
    longitude: "-85.42442000",
  },
  {
    id: 111153,
    name: "Alexandria",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.26282000",
    longitude: "-85.67581000",
  },
  {
    id: 111190,
    name: "Allen County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.09087000",
    longitude: "-85.06656000",
  },
  {
    id: 111313,
    name: "Anderson",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.10532000",
    longitude: "-85.68025000",
  },
  {
    id: 111330,
    name: "Andrews",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.86254000",
    longitude: "-85.60165000",
  },
  {
    id: 111340,
    name: "Angola",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.63477000",
    longitude: "-84.99941000",
  },
  {
    id: 111423,
    name: "Arcadia",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.17587000",
    longitude: "-86.02165000",
  },
  {
    id: 111449,
    name: "Argos",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.23780000",
    longitude: "-86.21465000",
  },
  {
    id: 111609,
    name: "Attica",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.29420000",
    longitude: "-87.24890000",
  },
  {
    id: 111625,
    name: "Auburn",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.36699000",
    longitude: "-85.05886000",
  },
  {
    id: 111656,
    name: "Aurora",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.05700000",
    longitude: "-84.90134000",
  },
  {
    id: 111667,
    name: "Austin",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.75839000",
    longitude: "-85.80803000",
  },
  {
    id: 111684,
    name: "Avilla",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.36588000",
    longitude: "-85.23886000",
  },
  {
    id: 111690,
    name: "Avon",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.76282000",
    longitude: "-86.39972000",
  },
  {
    id: 111822,
    name: "Bargersville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.52088000",
    longitude: "-86.16777000",
  },
  {
    id: 111859,
    name: "Bartholomew County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.20597000",
    longitude: "-85.89760000",
  },
  {
    id: 111880,
    name: "Bass Lake",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.20726000",
    longitude: "-86.60196000",
  },
  {
    id: 111893,
    name: "Batesville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.30005000",
    longitude: "-85.22218000",
  },
  {
    id: 111906,
    name: "Battle Ground",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.50837000",
    longitude: "-86.84168000",
  },
  {
    id: 112018,
    name: "Bedford",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.86116000",
    longitude: "-86.48721000",
  },
  {
    id: 112037,
    name: "Beech Grove",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.72199000",
    longitude: "-86.08998000",
  },
  {
    id: 112204,
    name: "Benton County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.60626000",
    longitude: "-87.31091000",
  },
  {
    id: 112246,
    name: "Berne",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.65782000",
    longitude: "-84.95191000",
  },
  {
    id: 112311,
    name: "Bicknell",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.77421000",
    longitude: "-87.30779000",
  },
  {
    id: 112386,
    name: "Blackford County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.47360000",
    longitude: "-85.32482000",
  },
  {
    id: 112434,
    name: "Bloomfield",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.02699000",
    longitude: "-86.93751000",
  },
  {
    id: 112449,
    name: "Bloomington",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.16533000",
    longitude: "-86.52639000",
  },
  {
    id: 112484,
    name: "Bluffton",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.73866000",
    longitude: "-85.17164000",
  },
  {
    id: 112560,
    name: "Boone County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.05080000",
    longitude: "-86.46870000",
  },
  {
    id: 112567,
    name: "Boonville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.04921000",
    longitude: "-87.27417000",
  },
  {
    id: 112612,
    name: "Bourbon",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.29560000",
    longitude: "-86.11639000",
  },
  {
    id: 112703,
    name: "Brazil",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.52365000",
    longitude: "-87.12502000",
  },
  {
    id: 112721,
    name: "Bremen",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.44644000",
    longitude: "-86.14806000",
  },
  {
    id: 112785,
    name: "Bright",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.21839000",
    longitude: "-84.85606000",
  },
  {
    id: 112805,
    name: "Bristol",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.72144000",
    longitude: "-85.81749000",
  },
  {
    id: 112822,
    name: "Broad Ripple",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.86671000",
    longitude: "-86.14165000",
  },
  {
    id: 112869,
    name: "Brooklyn",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.53921000",
    longitude: "-86.36916000",
  },
  {
    id: 112889,
    name: "Brookston",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.60281000",
    longitude: "-86.86723000",
  },
  {
    id: 112894,
    name: "Brookville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.42311000",
    longitude: "-85.01274000",
  },
  {
    id: 112908,
    name: "Brown County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.19621000",
    longitude: "-86.22737000",
  },
  {
    id: 112924,
    name: "Brownsburg",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.84338000",
    longitude: "-86.39777000",
  },
  {
    id: 112926,
    name: "Brownstown",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.87894000",
    longitude: "-86.04192000",
  },
  {
    id: 113089,
    name: "Burns Harbor",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.62587000",
    longitude: "-87.13337000",
  },
  {
    id: 113109,
    name: "Butler",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.42977000",
    longitude: "-84.87135000",
  },
  {
    id: 113257,
    name: "Cambridge City",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.81255000",
    longitude: "-85.17163000",
  },
  {
    id: 113330,
    name: "Cannelton",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "37.91144000",
    longitude: "-86.74443000",
  },
  {
    id: 113416,
    name: "Carmel",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.97837000",
    longitude: "-86.11804000",
  },
  {
    id: 113460,
    name: "Carroll County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.58286000",
    longitude: "-86.56348000",
  },
  {
    id: 113530,
    name: "Cass County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.76149000",
    longitude: "-86.34595000",
  },
  {
    id: 113593,
    name: "Cayuga",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.94865000",
    longitude: "-87.45974000",
  },
  {
    id: 113617,
    name: "Cedar Lake",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.36476000",
    longitude: "-87.44115000",
  },
  {
    id: 113653,
    name: "Centerville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.81782000",
    longitude: "-84.99635000",
  },
  {
    id: 113728,
    name: "Chandler",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.04171000",
    longitude: "-87.36806000",
  },
  {
    id: 113770,
    name: "Charlestown",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.45312000",
    longitude: "-85.67024000",
  },
  {
    id: 113903,
    name: "Chesterfield",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.11254000",
    longitude: "-85.59692000",
  },
  {
    id: 113909,
    name: "Chesterton",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.61059000",
    longitude: "-87.06420000",
  },
  {
    id: 114015,
    name: "Churubusco",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.23060000",
    longitude: "-85.31942000",
  },
  {
    id: 114020,
    name: "Cicero",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.12393000",
    longitude: "-86.01332000",
  },
  {
    id: 114123,
    name: "Clark County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.47718000",
    longitude: "-85.70728000",
  },
  {
    id: 114153,
    name: "Clarksville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.29674000",
    longitude: "-85.75996000",
  },
  {
    id: 114178,
    name: "Clay County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.39273000",
    longitude: "-87.11576000",
  },
  {
    id: 114239,
    name: "Clermont",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.80977000",
    longitude: "-86.32249000",
  },
  {
    id: 114268,
    name: "Clinton",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.65698000",
    longitude: "-87.39807000",
  },
  {
    id: 114293,
    name: "Clinton County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.30169000",
    longitude: "-86.47516000",
  },
  {
    id: 114309,
    name: "Cloverdale",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.51477000",
    longitude: "-86.79390000",
  },
  {
    id: 114475,
    name: "Columbia City",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.15727000",
    longitude: "-85.48831000",
  },
  {
    id: 114490,
    name: "Columbus",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.20144000",
    longitude: "-85.92138000",
  },
  {
    id: 114564,
    name: "Connersville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.64116000",
    longitude: "-85.14107000",
  },
  {
    id: 114579,
    name: "Converse",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.57754000",
    longitude: "-85.87332000",
  },
  {
    id: 114641,
    name: "Cordry Sweetwater Lakes",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.30464000",
    longitude: "-86.11837000",
  },
  {
    id: 114681,
    name: "Corydon",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.21201000",
    longitude: "-86.12192000",
  },
  {
    id: 114723,
    name: "Country Squire Lakes",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.03478000",
    longitude: "-85.69858000",
  },
  {
    id: 114740,
    name: "Covington",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.14170000",
    longitude: "-87.39474000",
  },
  {
    id: 114777,
    name: "Crawford County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.29241000",
    longitude: "-86.45171000",
  },
  {
    id: 114784,
    name: "Crawfordsville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.04115000",
    longitude: "-86.87445000",
  },
  {
    id: 114858,
    name: "Crothersville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.80061000",
    longitude: "-85.84164000",
  },
  {
    id: 114867,
    name: "Crown Point",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.41698000",
    longitude: "-87.36531000",
  },
  {
    id: 114902,
    name: "Culver",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.21893000",
    longitude: "-86.42306000",
  },
  {
    id: 114905,
    name: "Cumberland",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.77615000",
    longitude: "-85.95720000",
  },
  {
    id: 114979,
    name: "Dale",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.16894000",
    longitude: "-86.99000000",
  },
  {
    id: 114985,
    name: "Daleville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.12115000",
    longitude: "-85.55803000",
  },
  {
    id: 115034,
    name: "Danville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.76060000",
    longitude: "-86.52639000",
  },
  {
    id: 115052,
    name: "Darmstadt",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.09921000",
    longitude: "-87.57891000",
  },
  {
    id: 115069,
    name: "Daviess County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.70241000",
    longitude: "-87.07207000",
  },
  {
    id: 115095,
    name: "Dayton",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.37420000",
    longitude: "-86.76890000",
  },
  {
    id: 115146,
    name: "Dearborn County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.14519000",
    longitude: "-84.97326000",
  },
  {
    id: 115156,
    name: "Decatur",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.83060000",
    longitude: "-84.92913000",
  },
  {
    id: 115160,
    name: "Decatur County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.30700000",
    longitude: "-85.50114000",
  },
  {
    id: 115133,
    name: "DeKalb County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.39758000",
    longitude: "-84.99909000",
  },
  {
    id: 115206,
    name: "Delaware County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.22753000",
    longitude: "-85.39690000",
  },
  {
    id: 115220,
    name: "Delphi",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.58754000",
    longitude: "-86.67501000",
  },
  {
    id: 115135,
    name: "DeMotte",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.19504000",
    longitude: "-87.19864000",
  },
  {
    id: 115345,
    name: "Dillsboro",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.01783000",
    longitude: "-85.05884000",
  },
  {
    id: 115498,
    name: "Dubois County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.36428000",
    longitude: "-86.87980000",
  },
  {
    id: 115538,
    name: "Dunkirk",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.75643000",
    longitude: "-86.39361000",
  },
  {
    id: 115543,
    name: "Dunlap",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.63783000",
    longitude: "-85.92166000",
  },
  {
    id: 115586,
    name: "Dyer",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.49420000",
    longitude: "-87.52171000",
  },
  {
    id: 115645,
    name: "East Chicago",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.63920000",
    longitude: "-87.45476000",
  },
  {
    id: 115801,
    name: "Eaton",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.34032000",
    longitude: "-85.35080000",
  },
  {
    id: 115861,
    name: "Edgewood",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.10337000",
    longitude: "-85.73414000",
  },
  {
    id: 115873,
    name: "Edinburgh",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.35422000",
    longitude: "-85.96666000",
  },
  {
    id: 115998,
    name: "Elkhart",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.68199000",
    longitude: "-85.97667000",
  },
  {
    id: 116000,
    name: "Elkhart County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.59738000",
    longitude: "-85.85876000",
  },
  {
    id: 116022,
    name: "Ellettsville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.23393000",
    longitude: "-86.62500000",
  },
  {
    id: 116079,
    name: "Elwood",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.27698000",
    longitude: "-85.84192000",
  },
  {
    id: 116135,
    name: "English",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.33450000",
    longitude: "-86.46415000",
  },
  {
    id: 116253,
    name: "Evansville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "37.97476000",
    longitude: "-87.55585000",
  },
  {
    id: 116323,
    name: "Fairfield Heights",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.82861000",
    longitude: "-86.38224000",
  },
  {
    id: 116340,
    name: "Fairmount",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.41532000",
    longitude: "-85.65053000",
  },
  {
    id: 116359,
    name: "Fairview Park",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.68031000",
    longitude: "-87.41752000",
  },
  {
    id: 116405,
    name: "Farmersburg",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.24865000",
    longitude: "-87.38196000",
  },
  {
    id: 116425,
    name: "Farmland",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.18782000",
    longitude: "-85.12747000",
  },
  {
    id: 116446,
    name: "Fayette County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.64006000",
    longitude: "-85.17873000",
  },
  {
    id: 116477,
    name: "Ferdinand",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.22394000",
    longitude: "-86.86222000",
  },
  {
    id: 116526,
    name: "Fish Lake",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.56671000",
    longitude: "-86.55196000",
  },
  {
    id: 116529,
    name: "Fishers",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.95559000",
    longitude: "-86.01387000",
  },
  {
    id: 116572,
    name: "Flora",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.54726000",
    longitude: "-86.52444000",
  },
  {
    id: 116607,
    name: "Floyd County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.31891000",
    longitude: "-85.90687000",
  },
  {
    id: 116697,
    name: "Fort Branch",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.25116000",
    longitude: "-87.58113000",
  },
  {
    id: 116763,
    name: "Fort Wayne",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.13060000",
    longitude: "-85.12886000",
  },
  {
    id: 116769,
    name: "Fortville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.93226000",
    longitude: "-85.84804000",
  },
  {
    id: 116779,
    name: "Fountain County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.12087000",
    longitude: "-87.24199000",
  },
  {
    id: 116794,
    name: "Fowler",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.61670000",
    longitude: "-87.32085000",
  },
  {
    id: 116820,
    name: "Frankfort",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.27948000",
    longitude: "-86.51084000",
  },
  {
    id: 116833,
    name: "Franklin",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.48061000",
    longitude: "-86.05499000",
  },
  {
    id: 116848,
    name: "Franklin County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.41486000",
    longitude: "-85.06028000",
  },
  {
    id: 116879,
    name: "Frankton",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.22282000",
    longitude: "-85.77887000",
  },
  {
    id: 116920,
    name: "Fremont",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.73088000",
    longitude: "-84.93274000",
  },
  {
    id: 116931,
    name: "French Lick",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.54894000",
    longitude: "-86.61999000",
  },
  {
    id: 116996,
    name: "Fulton County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.04696000",
    longitude: "-86.26358000",
  },
  {
    id: 117021,
    name: "Galena",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.35173000",
    longitude: "-85.94164000",
  },
  {
    id: 117043,
    name: "Galveston",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.57893000",
    longitude: "-86.19027000",
  },
  {
    id: 117098,
    name: "Garrett",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.34949000",
    longitude: "-85.13553000",
  },
  {
    id: 117106,
    name: "Gary",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.59337000",
    longitude: "-87.34643000",
  },
  {
    id: 117109,
    name: "Gas City",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.48726000",
    longitude: "-85.61303000",
  },
  {
    id: 117141,
    name: "Geneva",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.59199000",
    longitude: "-84.95719000",
  },
  {
    id: 117163,
    name: "Georgetown",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.74060000",
    longitude: "-86.50473000",
  },
  {
    id: 117189,
    name: "Gibson County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.31183000",
    longitude: "-87.58459000",
  },
  {
    id: 117384,
    name: "Goodland",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.76337000",
    longitude: "-87.29363000",
  },
  {
    id: 117408,
    name: "Goshen",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.58227000",
    longitude: "-85.83444000",
  },
  {
    id: 117424,
    name: "Grabill",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.21088000",
    longitude: "-84.96691000",
  },
  {
    id: 117488,
    name: "Granger",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.75338000",
    longitude: "-86.11084000",
  },
  {
    id: 117512,
    name: "Grant County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.51584000",
    longitude: "-85.65473000",
  },
  {
    id: 117624,
    name: "Greencastle",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.64449000",
    longitude: "-86.86473000",
  },
  {
    id: 117626,
    name: "Greendale",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.11256000",
    longitude: "-84.86412000",
  },
  {
    id: 117635,
    name: "Greene County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.03633000",
    longitude: "-86.96205000",
  },
  {
    id: 117646,
    name: "Greenfield",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.78504000",
    longitude: "-85.76942000",
  },
  {
    id: 117669,
    name: "Greensburg",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.33727000",
    longitude: "-85.48358000",
  },
  {
    id: 117674,
    name: "Greentown",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.47809000",
    longitude: "-85.96665000",
  },
  {
    id: 117702,
    name: "Greenwood",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.61366000",
    longitude: "-86.10665000",
  },
  {
    id: 117732,
    name: "Griffith",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.52837000",
    longitude: "-87.42365000",
  },
  {
    id: 117741,
    name: "Grissom Air Force Base",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.65753000",
    longitude: "-86.14755000",
  },
  {
    id: 117798,
    name: "Gulivoire Park",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.61338000",
    longitude: "-86.24528000",
  },
  {
    id: 117837,
    name: "Hagerstown",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.91116000",
    longitude: "-85.16163000",
  },
  {
    id: 117891,
    name: "Hamilton",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.53366000",
    longitude: "-84.91274000",
  },
  {
    id: 117901,
    name: "Hamilton County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.07249000",
    longitude: "-86.05201000",
  },
  {
    id: 117913,
    name: "Hammond",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.58337000",
    longitude: "-87.50004000",
  },
  {
    id: 117951,
    name: "Hancock County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.82355000",
    longitude: "-85.77324000",
  },
  {
    id: 117966,
    name: "Hanover",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.71423000",
    longitude: "-85.47357000",
  },
  {
    id: 118017,
    name: "Harlan",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.19616000",
    longitude: "-84.91969000",
  },
  {
    id: 118062,
    name: "Harrison County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.19512000",
    longitude: "-86.11131000",
  },
  {
    id: 118094,
    name: "Hartford City",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.45115000",
    longitude: "-85.36997000",
  },
  {
    id: 118141,
    name: "Haubstadt",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.20504000",
    longitude: "-87.57419000",
  },
  {
    id: 118235,
    name: "Hebron",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.31865000",
    longitude: "-87.20031000",
  },
  {
    id: 118287,
    name: "Hendricks County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.76952000",
    longitude: "-86.50998000",
  },
  {
    id: 118299,
    name: "Henry County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.93104000",
    longitude: "-85.39644000",
  },
  {
    id: 118308,
    name: "Henryville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.54173000",
    longitude: "-85.76774000",
  },
  {
    id: 118320,
    name: "Heritage Lake",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.72779000",
    longitude: "-86.71022000",
  },
  {
    id: 118379,
    name: "Hidden Valley",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.16228000",
    longitude: "-84.84301000",
  },
  {
    id: 118396,
    name: "Highland",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.55365000",
    longitude: "-87.45198000",
  },
  {
    id: 118497,
    name: "Hobart",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.53226000",
    longitude: "-87.25504000",
  },
  {
    id: 118643,
    name: "Hope",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.30394000",
    longitude: "-85.77137000",
  },
  {
    id: 118716,
    name: "Howard County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.48359000",
    longitude: "-86.11693000",
  },
  {
    id: 118751,
    name: "Hudson Lake",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.71032000",
    longitude: "-86.53419000",
  },
  {
    id: 118793,
    name: "Huntertown",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.22838000",
    longitude: "-85.17247000",
  },
  {
    id: 118794,
    name: "Huntingburg",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.29894000",
    longitude: "-86.95500000",
  },
  {
    id: 118801,
    name: "Huntington",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.88310000",
    longitude: "-85.49748000",
  },
  {
    id: 118806,
    name: "Huntington County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.82924000",
    longitude: "-85.48817000",
  },
  {
    id: 118905,
    name: "Indian Heights",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.42726000",
    longitude: "-86.12555000",
  },
  {
    id: 118924,
    name: "Indianapolis",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.76838000",
    longitude: "-86.15804000",
  },
  {
    id: 118933,
    name: "Ingalls",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.95699000",
    longitude: "-85.80526000",
  },
  {
    id: 119080,
    name: "Jackson County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.90642000",
    longitude: "-86.03754000",
  },
  {
    id: 119137,
    name: "Jasonville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.16310000",
    longitude: "-87.19918000",
  },
  {
    id: 119141,
    name: "Jasper",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.39144000",
    longitude: "-86.93111000",
  },
  {
    id: 119152,
    name: "Jasper County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.02300000",
    longitude: "-87.11612000",
  },
  {
    id: 119155,
    name: "Jay County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.43792000",
    longitude: "-85.00564000",
  },
  {
    id: 119180,
    name: "Jefferson County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.78582000",
    longitude: "-85.43857000",
  },
  {
    id: 119208,
    name: "Jeffersonville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.27757000",
    longitude: "-85.73718000",
  },
  {
    id: 119220,
    name: "Jennings County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.99693000",
    longitude: "-85.62806000",
  },
  {
    id: 119263,
    name: "Johnson County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.48997000",
    longitude: "-86.10164000",
  },
  {
    id: 119303,
    name: "Jonesboro",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.47976000",
    longitude: "-85.62775000",
  },
  {
    id: 119451,
    name: "Kendallville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.44144000",
    longitude: "-85.26498000",
  },
  {
    id: 119496,
    name: "Kentland",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.77032000",
    longitude: "-87.44530000",
  },
  {
    id: 119613,
    name: "Kingsford Heights",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.48060000",
    longitude: "-86.69169000",
  },
  {
    id: 119676,
    name: "Knightstown",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.79560000",
    longitude: "-85.52636000",
  },
  {
    id: 119682,
    name: "Knox",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.29588000",
    longitude: "-86.62501000",
  },
  {
    id: 119685,
    name: "Knox County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.68909000",
    longitude: "-87.41801000",
  },
  {
    id: 119702,
    name: "Kokomo",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.48643000",
    longitude: "-86.13360000",
  },
  {
    id: 119706,
    name: "Koontz Lake",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.41810000",
    longitude: "-86.48585000",
  },
  {
    id: 119710,
    name: "Kosciusko County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.24410000",
    longitude: "-85.86072000",
  },
  {
    id: 119714,
    name: "Kouts",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.31671000",
    longitude: "-87.02586000",
  },
  {
    id: 119777,
    name: "La Porte",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.60774000",
    longitude: "-86.71389000",
  },
  {
    id: 119834,
    name: "Lafayette",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.41670000",
    longitude: "-86.87529000",
  },
  {
    id: 119848,
    name: "Lagrange",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.64172000",
    longitude: "-85.41665000",
  },
  {
    id: 119802,
    name: "LaGrange County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.64261000",
    longitude: "-85.42650000",
  },
  {
    id: 119892,
    name: "Lake County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.47221000",
    longitude: "-87.37637000",
  },
  {
    id: 119901,
    name: "Lake Dalecarlia",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.33087000",
    longitude: "-87.39476000",
  },
  {
    id: 119979,
    name: "Lake Station",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.57504000",
    longitude: "-87.23892000",
  },
  {
    id: 120026,
    name: "Lakes of the Four Seasons",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.41032000",
    longitude: "-87.21309000",
  },
  {
    id: 120133,
    name: "Lapel",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.06837000",
    longitude: "-85.84831000",
  },
  {
    id: 119804,
    name: "LaPorte",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.61060000",
    longitude: "-86.72252000",
  },
  {
    id: 119805,
    name: "LaPorte County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.54902000",
    longitude: "-86.74237000",
  },
  {
    id: 120225,
    name: "Lawrence",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.83865000",
    longitude: "-86.02526000",
  },
  {
    id: 120232,
    name: "Lawrence County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.84116000",
    longitude: "-86.48345000",
  },
  {
    id: 120240,
    name: "Lawrenceburg",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.09089000",
    longitude: "-84.84995000",
  },
  {
    id: 120289,
    name: "Lebanon",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.04837000",
    longitude: "-86.46917000",
  },
  {
    id: 120379,
    name: "Leo-Cedarville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.21255000",
    longitude: "-85.01664000",
  },
  {
    id: 120460,
    name: "Liberty",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.63560000",
    longitude: "-84.93107000",
  },
  {
    id: 120481,
    name: "Ligonier",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.46588000",
    longitude: "-85.58748000",
  },
  {
    id: 120589,
    name: "Linton",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.03477000",
    longitude: "-87.16585000",
  },
  {
    id: 120707,
    name: "Logansport",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.75448000",
    longitude: "-86.35667000",
  },
  {
    id: 120736,
    name: "Long Beach",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.73893000",
    longitude: "-86.85697000",
  },
  {
    id: 120764,
    name: "Loogootee",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.67699000",
    longitude: "-86.91417000",
  },
  {
    id: 120835,
    name: "Lowell",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.29142000",
    longitude: "-87.42059000",
  },
  {
    id: 120926,
    name: "Lynn",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.04977000",
    longitude: "-84.93969000",
  },
  {
    id: 120988,
    name: "Madison",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.73589000",
    longitude: "-85.37996000",
  },
  {
    id: 121015,
    name: "Madison County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.16166000",
    longitude: "-85.71935000",
  },
  {
    id: 121243,
    name: "Marion",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.55837000",
    longitude: "-85.65914000",
  },
  {
    id: 121252,
    name: "Marion County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.78171000",
    longitude: "-86.13847000",
  },
  {
    id: 121271,
    name: "Markle",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.82462000",
    longitude: "-85.33884000",
  },
  {
    id: 121319,
    name: "Marshall County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.32485000",
    longitude: "-86.26176000",
  },
  {
    id: 121338,
    name: "Martin County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.70801000",
    longitude: "-86.80307000",
  },
  {
    id: 121348,
    name: "Martinsville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.42783000",
    longitude: "-86.42833000",
  },
  {
    id: 121482,
    name: "McCordsville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.90810000",
    longitude: "-85.92276000",
  },
  {
    id: 121627,
    name: "Melody Hill",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.02615000",
    longitude: "-87.51585000",
  },
  {
    id: 121696,
    name: "Meridian Hills",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.89004000",
    longitude: "-86.15721000",
  },
  {
    id: 121708,
    name: "Merrillville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.48281000",
    longitude: "-87.33281000",
  },
  {
    id: 121752,
    name: "Miami County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.76950000",
    longitude: "-86.04502000",
  },
  {
    id: 121760,
    name: "Michigan City",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.70754000",
    longitude: "-86.89503000",
  },
  {
    id: 121772,
    name: "Middlebury",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.67533000",
    longitude: "-85.70610000",
  },
  {
    id: 121790,
    name: "Middletown",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.05727000",
    longitude: "-85.53720000",
  },
  {
    id: 121835,
    name: "Milan",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.12117000",
    longitude: "-85.13135000",
  },
  {
    id: 121849,
    name: "Milford",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.40977000",
    longitude: "-85.84555000",
  },
  {
    id: 121976,
    name: "Mishawaka",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.66199000",
    longitude: "-86.15862000",
  },
  {
    id: 121996,
    name: "Mitchell",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.73283000",
    longitude: "-86.47360000",
  },
  {
    id: 122050,
    name: "Monon",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.86782000",
    longitude: "-86.87890000",
  },
  {
    id: 122072,
    name: "Monroe County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.16092000",
    longitude: "-86.52314000",
  },
  {
    id: 122085,
    name: "Monroeville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.97477000",
    longitude: "-84.86830000",
  },
  {
    id: 122087,
    name: "Monrovia",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.57894000",
    longitude: "-86.48222000",
  },
  {
    id: 122153,
    name: "Montgomery County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.04038000",
    longitude: "-86.89330000",
  },
  {
    id: 122165,
    name: "Monticello",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.74532000",
    longitude: "-86.76473000",
  },
  {
    id: 122176,
    name: "Montpelier",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.55393000",
    longitude: "-85.27747000",
  },
  {
    id: 122209,
    name: "Mooresville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.61282000",
    longitude: "-86.37416000",
  },
  {
    id: 122239,
    name: "Morgan County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.48155000",
    longitude: "-86.44621000",
  },
  {
    id: 122260,
    name: "Morocco",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.94615000",
    longitude: "-87.45336000",
  },
  {
    id: 122280,
    name: "Morristown",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.67338000",
    longitude: "-85.69859000",
  },
  {
    id: 122394,
    name: "Mount Vernon",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "37.93227000",
    longitude: "-87.89503000",
  },
  {
    id: 122455,
    name: "Mulberry",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.34448000",
    longitude: "-86.66528000",
  },
  {
    id: 122465,
    name: "Muncie",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.19338000",
    longitude: "-85.38636000",
  },
  {
    id: 122478,
    name: "Munster",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.56448000",
    longitude: "-87.51254000",
  },
  {
    id: 122561,
    name: "Nappanee",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.44283000",
    longitude: "-86.00139000",
  },
  {
    id: 122573,
    name: "Nashville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.20727000",
    longitude: "-86.25110000",
  },
  {
    id: 122654,
    name: "New Albany",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.28562000",
    longitude: "-85.82413000",
  },
  {
    id: 122680,
    name: "New Carlisle",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.70032000",
    longitude: "-86.50946000",
  },
  {
    id: 122684,
    name: "New Castle",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.92894000",
    longitude: "-85.37025000",
  },
  {
    id: 122693,
    name: "New Chicago",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.55837000",
    longitude: "-87.27448000",
  },
  {
    id: 122717,
    name: "New Haven",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.07060000",
    longitude: "-85.01441000",
  },
  {
    id: 122758,
    name: "New Palestine",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.72199000",
    longitude: "-85.88915000",
  },
  {
    id: 122760,
    name: "New Paris",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.50033000",
    longitude: "-85.82805000",
  },
  {
    id: 122761,
    name: "New Pekin",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.50506000",
    longitude: "-86.01692000",
  },
  {
    id: 122791,
    name: "New Whiteland",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.55810000",
    longitude: "-86.09526000",
  },
  {
    id: 122816,
    name: "Newburgh",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "37.94449000",
    longitude: "-87.40529000",
  },
  {
    id: 122840,
    name: "Newport",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.88420000",
    longitude: "-87.40863000",
  },
  {
    id: 122873,
    name: "Newton County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.95585000",
    longitude: "-87.39754000",
  },
  {
    id: 122918,
    name: "Noble County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.39860000",
    longitude: "-85.41747000",
  },
  {
    id: 122921,
    name: "Noblesville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.04559000",
    longitude: "-86.00860000",
  },
  {
    id: 123044,
    name: "North Judson",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.21504000",
    longitude: "-86.77585000",
  },
  {
    id: 123057,
    name: "North Liberty",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.53421000",
    longitude: "-86.42723000",
  },
  {
    id: 123061,
    name: "North Madison",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.76784000",
    longitude: "-85.39663000",
  },
  {
    id: 123062,
    name: "North Manchester",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.00060000",
    longitude: "-85.76860000",
  },
  {
    id: 123114,
    name: "North Terre Haute",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.52781000",
    longitude: "-87.36030000",
  },
  {
    id: 123121,
    name: "North Vernon",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.00617000",
    longitude: "-85.62358000",
  },
  {
    id: 123126,
    name: "North Webster",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.32560000",
    longitude: "-85.69776000",
  },
  {
    id: 123204,
    name: "Notre Dame",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.70019000",
    longitude: "-86.23793000",
  },
  {
    id: 123254,
    name: "Oak Park",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.30562000",
    longitude: "-85.69635000",
  },
  {
    id: 123294,
    name: "Oakland City",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.33866000",
    longitude: "-87.34501000",
  },
  {
    id: 123362,
    name: "Odon",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.84283000",
    longitude: "-86.99140000",
  },
  {
    id: 123370,
    name: "Ogden Dunes",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.62281000",
    longitude: "-87.19170000",
  },
  {
    id: 123382,
    name: "Ohio County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.95010000",
    longitude: "-84.96503000",
  },
  {
    id: 123491,
    name: "Oolitic",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.90088000",
    longitude: "-86.52527000",
  },
  {
    id: 123515,
    name: "Orange County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.54178000",
    longitude: "-86.49507000",
  },
  {
    id: 123563,
    name: "Orleans",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.66172000",
    longitude: "-86.45166000",
  },
  {
    id: 123600,
    name: "Osceola",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.66505000",
    longitude: "-86.07584000",
  },
  {
    id: 123608,
    name: "Osgood",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.12922000",
    longitude: "-85.29163000",
  },
  {
    id: 123616,
    name: "Ossian",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.88060000",
    longitude: "-85.16636000",
  },
  {
    id: 123642,
    name: "Otterbein",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.49059000",
    longitude: "-87.09640000",
  },
  {
    id: 123661,
    name: "Owen County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.31281000",
    longitude: "-86.83765000",
  },
  {
    id: 123665,
    name: "Owensville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.27199000",
    longitude: "-87.68780000",
  },
  {
    id: 123682,
    name: "Oxford",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.51976000",
    longitude: "-87.24779000",
  },
  {
    id: 123811,
    name: "Paoli",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.55617000",
    longitude: "-86.46832000",
  },
  {
    id: 123857,
    name: "Parke County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.77363000",
    longitude: "-87.20636000",
  },
  {
    id: 123864,
    name: "Parker City",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.18893000",
    longitude: "-85.20413000",
  },
  {
    id: 124016,
    name: "Pendleton",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.99754000",
    longitude: "-85.74664000",
  },
  {
    id: 124077,
    name: "Perry County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.07965000",
    longitude: "-86.63803000",
  },
  {
    id: 124096,
    name: "Peru",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.75365000",
    longitude: "-86.06888000",
  },
  {
    id: 124103,
    name: "Petersburg",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.49199000",
    longitude: "-87.27862000",
  },
  {
    id: 124176,
    name: "Pierceton",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.20032000",
    longitude: "-85.70554000",
  },
  {
    id: 124189,
    name: "Pike County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.39878000",
    longitude: "-87.23216000",
  },
  {
    id: 124289,
    name: "Pittsboro",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.86393000",
    longitude: "-86.46694000",
  },
  {
    id: 124318,
    name: "Plainfield",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.70421000",
    longitude: "-86.39944000",
  },
  {
    id: 124401,
    name: "Plymouth",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.34366000",
    longitude: "-86.30973000",
  },
  {
    id: 124543,
    name: "Portage",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.57587000",
    longitude: "-87.17615000",
  },
  {
    id: 124551,
    name: "Porter",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.61559000",
    longitude: "-87.07420000",
  },
  {
    id: 124553,
    name: "Porter County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.50884000",
    longitude: "-87.07332000",
  },
  {
    id: 124560,
    name: "Portland",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.43449000",
    longitude: "-84.97775000",
  },
  {
    id: 124574,
    name: "Posey County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.02189000",
    longitude: "-87.86847000",
  },
  {
    id: 124575,
    name: "Poseyville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.17004000",
    longitude: "-87.78308000",
  },
  {
    id: 124678,
    name: "Princes Lakes",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.35366000",
    longitude: "-86.09805000",
  },
  {
    id: 124681,
    name: "Princeton",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.35532000",
    longitude: "-87.56752000",
  },
  {
    id: 124743,
    name: "Pulaski County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.04183000",
    longitude: "-86.69878000",
  },
  {
    id: 124763,
    name: "Putnam County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.66626000",
    longitude: "-86.84500000",
  },
  {
    id: 124879,
    name: "Randolph County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.15764000",
    longitude: "-85.01131000",
  },
  {
    id: 124973,
    name: "Redkey",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.34893000",
    longitude: "-85.14997000",
  },
  {
    id: 125014,
    name: "Remington",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.76087000",
    longitude: "-87.15085000",
  },
  {
    id: 125023,
    name: "Rensselaer",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.93670000",
    longitude: "-87.15086000",
  },
  {
    id: 125084,
    name: "Richmond",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.82894000",
    longitude: "-84.89024000",
  },
  {
    id: 125165,
    name: "Ripley County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.10345000",
    longitude: "-85.26239000",
  },
  {
    id: 125169,
    name: "Rising Sun",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.94950000",
    longitude: "-84.85384000",
  },
  {
    id: 125230,
    name: "Roanoke",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.96255000",
    longitude: "-85.37331000",
  },
  {
    id: 125263,
    name: "Rochester",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.06476000",
    longitude: "-86.21583000",
  },
  {
    id: 125315,
    name: "Rockport",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "37.88311000",
    longitude: "-87.04944000",
  },
  {
    id: 125321,
    name: "Rockville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.76254000",
    longitude: "-87.22918000",
  },
  {
    id: 125380,
    name: "Rome City",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.49616000",
    longitude: "-85.37665000",
  },
  {
    id: 125425,
    name: "Roselawn",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.14170000",
    longitude: "-87.31475000",
  },
  {
    id: 125460,
    name: "Rossville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.41698000",
    longitude: "-86.59472000",
  },
  {
    id: 125520,
    name: "Rush County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.61995000",
    longitude: "-85.46576000",
  },
  {
    id: 125525,
    name: "Rushville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.60921000",
    longitude: "-85.44636000",
  },
  {
    id: 125545,
    name: "Russiaville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.41754000",
    longitude: "-86.27138000",
  },
  {
    id: 125662,
    name: "Saint John",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.45004000",
    longitude: "-87.47004000",
  },
  {
    id: 125675,
    name: "Saint Joseph County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.61672000",
    longitude: "-86.28986000",
  },
  {
    id: 125700,
    name: "Saint Paul",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.42810000",
    longitude: "-85.62831000",
  },
  {
    id: 125727,
    name: "Salem",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.60561000",
    longitude: "-86.10109000",
  },
  {
    id: 125909,
    name: "Santa Claus",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.12005000",
    longitude: "-86.91416000",
  },
  {
    id: 126005,
    name: "Schererville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.47892000",
    longitude: "-87.45476000",
  },
  {
    id: 126045,
    name: "Scott County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.68507000",
    longitude: "-85.74747000",
  },
  {
    id: 126062,
    name: "Scottsburg",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.68561000",
    longitude: "-85.77025000",
  },
  {
    id: 126126,
    name: "Seelyville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.49198000",
    longitude: "-87.26724000",
  },
  {
    id: 126135,
    name: "Sellersburg",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.39812000",
    longitude: "-85.75496000",
  },
  {
    id: 126192,
    name: "Seymour",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.95922000",
    longitude: "-85.89025000",
  },
  {
    id: 126201,
    name: "Shadeland",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.37365000",
    longitude: "-86.94890000",
  },
  {
    id: 126264,
    name: "Shelburn",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.17837000",
    longitude: "-87.39363000",
  },
  {
    id: 126274,
    name: "Shelby County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.52369000",
    longitude: "-85.79170000",
  },
  {
    id: 126280,
    name: "Shelbyville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.52144000",
    longitude: "-85.77692000",
  },
  {
    id: 126318,
    name: "Sheridan",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.13504000",
    longitude: "-86.22055000",
  },
  {
    id: 126364,
    name: "Shoals",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.66644000",
    longitude: "-86.79111000",
  },
  {
    id: 126373,
    name: "Shorewood Forest",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.46315000",
    longitude: "-87.14472000",
  },
  {
    id: 126447,
    name: "Simonton Lake",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.75422000",
    longitude: "-85.97500000",
  },
  {
    id: 126531,
    name: "Smithville-Sanders",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.05969000",
    longitude: "-86.51077000",
  },
  {
    id: 126624,
    name: "South Bend",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.68338000",
    longitude: "-86.25001000",
  },
  {
    id: 126666,
    name: "South Haven",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.54198000",
    longitude: "-87.13726000",
  },
  {
    id: 126747,
    name: "South Whitley",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.08477000",
    longitude: "-85.62804000",
  },
  {
    id: 126782,
    name: "Southport",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.66505000",
    longitude: "-86.12776000",
  },
  {
    id: 126818,
    name: "Speedway",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.80227000",
    longitude: "-86.26721000",
  },
  {
    id: 126820,
    name: "Spencer",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.28671000",
    longitude: "-86.76251000",
  },
  {
    id: 126828,
    name: "Spencer County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.01406000",
    longitude: "-87.00771000",
  },
  {
    id: 126987,
    name: "Starke County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.28093000",
    longitude: "-86.64765000",
  },
  {
    id: 127038,
    name: "Steuben County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.64387000",
    longitude: "-85.00077000",
  },
  {
    id: 127182,
    name: "Sullivan",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.09532000",
    longitude: "-87.40585000",
  },
  {
    id: 127186,
    name: "Sullivan County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.08883000",
    longitude: "-87.41469000",
  },
  {
    id: 127255,
    name: "Sunman",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.23700000",
    longitude: "-85.09468000",
  },
  {
    id: 127339,
    name: "Sweetser",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.57198000",
    longitude: "-85.76915000",
  },
  {
    id: 127349,
    name: "Switzerland County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.82616000",
    longitude: "-85.03700000",
  },
  {
    id: 127363,
    name: "Syracuse",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.42783000",
    longitude: "-85.75249000",
  },
  {
    id: 127488,
    name: "Tell City",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "37.95144000",
    longitude: "-86.76777000",
  },
  {
    id: 127520,
    name: "Terre Haute",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.46670000",
    longitude: "-87.41391000",
  },
  {
    id: 127605,
    name: "Thorntown",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.12948000",
    longitude: "-86.60667000",
  },
  {
    id: 127673,
    name: "Tippecanoe County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.38862000",
    longitude: "-86.89410000",
  },
  {
    id: 127676,
    name: "Tipton",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.28226000",
    longitude: "-86.04110000",
  },
  {
    id: 127680,
    name: "Tipton County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.31135000",
    longitude: "-86.05186000",
  },
  {
    id: 127733,
    name: "Topeka",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.53922000",
    longitude: "-85.53971000",
  },
  {
    id: 127775,
    name: "Trafalgar",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.41616000",
    longitude: "-86.15082000",
  },
  {
    id: 127777,
    name: "Trail Creek",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.69837000",
    longitude: "-86.85920000",
  },
  {
    id: 127817,
    name: "Tri-Lakes",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.24588000",
    longitude: "-85.44192000",
  },
  {
    id: 127983,
    name: "Union City",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.20199000",
    longitude: "-84.80913000",
  },
  {
    id: 127992,
    name: "Union County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.62555000",
    longitude: "-84.92514000",
  },
  {
    id: 128038,
    name: "Upland",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.47560000",
    longitude: "-85.49442000",
  },
  {
    id: 128121,
    name: "Valparaiso",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.47309000",
    longitude: "-87.06114000",
  },
  {
    id: 128150,
    name: "Vanderburgh County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.02514000",
    longitude: "-87.58578000",
  },
  {
    id: 128161,
    name: "Veedersburg",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.11309000",
    longitude: "-87.26251000",
  },
  {
    id: 128186,
    name: "Vermillion County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.85380000",
    longitude: "-87.46397000",
  },
  {
    id: 128208,
    name: "Versailles",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.07200000",
    longitude: "-85.25190000",
  },
  {
    id: 128213,
    name: "Vevay",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.74784000",
    longitude: "-85.06717000",
  },
  {
    id: 128239,
    name: "Vigo County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.43064000",
    longitude: "-87.38996000",
  },
  {
    id: 128263,
    name: "Vincennes",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.67727000",
    longitude: "-87.52863000",
  },
  {
    id: 128309,
    name: "Wabash",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.79782000",
    longitude: "-85.82054000",
  },
  {
    id: 128311,
    name: "Wabash County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.84569000",
    longitude: "-85.79401000",
  },
  {
    id: 128355,
    name: "Wakarusa",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.53616000",
    longitude: "-86.02083000",
  },
  {
    id: 128391,
    name: "Walkerton",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.46671000",
    longitude: "-86.48307000",
  },
  {
    id: 128435,
    name: "Walton",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.66087000",
    longitude: "-86.24194000",
  },
  {
    id: 128450,
    name: "Wanatah",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.43060000",
    longitude: "-86.89836000",
  },
  {
    id: 128477,
    name: "Warren",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.68282000",
    longitude: "-85.42720000",
  },
  {
    id: 128495,
    name: "Warren County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.34690000",
    longitude: "-87.35331000",
  },
  {
    id: 128499,
    name: "Warren Park",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.78199000",
    longitude: "-86.05026000",
  },
  {
    id: 128511,
    name: "Warrick County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.09217000",
    longitude: "-87.27205000",
  },
  {
    id: 128520,
    name: "Warsaw",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.23810000",
    longitude: "-85.85305000",
  },
  {
    id: 128538,
    name: "Washington",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.65922000",
    longitude: "-87.17279000",
  },
  {
    id: 128557,
    name: "Washington County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "38.59998000",
    longitude: "-86.10531000",
  },
  {
    id: 128613,
    name: "Waterloo",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.43199000",
    longitude: "-85.01997000",
  },
  {
    id: 128691,
    name: "Wayne County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.86442000",
    longitude: "-85.00988000",
  },
  {
    id: 128786,
    name: "Wells County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.72919000",
    longitude: "-85.22122000",
  },
  {
    id: 128908,
    name: "West Lafayette",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.42587000",
    longitude: "-86.90807000",
  },
  {
    id: 128989,
    name: "West Terre Haute",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.46504000",
    longitude: "-87.45002000",
  },
  {
    id: 129027,
    name: "Westfield",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.04282000",
    longitude: "-86.12749000",
  },
  {
    id: 129070,
    name: "Westport",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.17589000",
    longitude: "-85.57303000",
  },
  {
    id: 129079,
    name: "Westville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.54143000",
    longitude: "-86.90058000",
  },
  {
    id: 129138,
    name: "White County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.74977000",
    longitude: "-86.86547000",
  },
  {
    id: 129176,
    name: "Whiteland",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.55005000",
    longitude: "-86.07971000",
  },
  {
    id: 129188,
    name: "Whitestown",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.99726000",
    longitude: "-86.34583000",
  },
  {
    id: 129196,
    name: "Whiting",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.67976000",
    longitude: "-87.49449000",
  },
  {
    id: 129201,
    name: "Whitley County",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.13938000",
    longitude: "-85.50512000",
  },
  {
    id: 129280,
    name: "Williamsport",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.28837000",
    longitude: "-87.29390000",
  },
  {
    id: 129350,
    name: "Winamac",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.05143000",
    longitude: "-86.60306000",
  },
  {
    id: 129357,
    name: "Winchester",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.17199000",
    longitude: "-84.98135000",
  },
  {
    id: 129398,
    name: "Winfield",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.40531000",
    longitude: "-87.27531000",
  },
  {
    id: 129426,
    name: "Winona Lake",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.22727000",
    longitude: "-85.82193000",
  },
  {
    id: 129470,
    name: "Wolcottville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.52588000",
    longitude: "-85.36665000",
  },
  {
    id: 129503,
    name: "Woodburn",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "41.12533000",
    longitude: "-84.85330000",
  },
  {
    id: 129596,
    name: "Worthington",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.12504000",
    longitude: "-86.97945000",
  },
  {
    id: 129707,
    name: "Yorktown",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "40.17365000",
    longitude: "-85.49414000",
  },
  {
    id: 129759,
    name: "Zionsville",
    state_id: 1440,
    state_code: "IN",
    country_id: 233,
    country_code: "US",
    latitude: "39.95087000",
    longitude: "-86.26194000",
  },
  {
    id: 110997,
    name: "Ackley",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.55415000",
    longitude: "-93.05326000",
  },
  {
    id: 111010,
    name: "Adair County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.33075000",
    longitude: "-94.47094000",
  },
  {
    id: 111020,
    name: "Adams County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.02898000",
    longitude: "-94.69918000",
  },
  {
    id: 111042,
    name: "Adel",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.61443000",
    longitude: "-94.01745000",
  },
  {
    id: 111076,
    name: "Akron",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.82888000",
    longitude: "-96.55948000",
  },
  {
    id: 111122,
    name: "Albia",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.02667000",
    longitude: "-92.80575000",
  },
  {
    id: 111163,
    name: "Algona",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.06997000",
    longitude: "-94.23302000",
  },
  {
    id: 111176,
    name: "Allamakee County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.28428000",
    longitude: "-91.37809000",
  },
  {
    id: 111201,
    name: "Allison",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.75275000",
    longitude: "-92.79519000",
  },
  {
    id: 111229,
    name: "Alta",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.67359000",
    longitude: "-95.29055000",
  },
  {
    id: 111245,
    name: "Alton",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.98749000",
    longitude: "-96.01057000",
  },
  {
    id: 111247,
    name: "Altoona",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.64416000",
    longitude: "-93.46466000",
  },
  {
    id: 111277,
    name: "Ames",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.03471000",
    longitude: "-93.61994000",
  },
  {
    id: 111302,
    name: "Anamosa",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.10834000",
    longitude: "-91.28516000",
  },
  {
    id: 111344,
    name: "Ankeny",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.72971000",
    longitude: "-93.60577000",
  },
  {
    id: 111386,
    name: "Aplington",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.58415000",
    longitude: "-92.88436000",
  },
  {
    id: 111391,
    name: "Appanoose County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "40.74316000",
    longitude: "-92.86861000",
  },
  {
    id: 111490,
    name: "Arnolds Park",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.37274000",
    longitude: "-95.12388000",
  },
  {
    id: 111506,
    name: "Asbury",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.51445000",
    longitude: "-90.75152000",
  },
  {
    id: 111586,
    name: "Atkins",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.99694000",
    longitude: "-91.86213000",
  },
  {
    id: 111594,
    name: "Atlantic",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.40360000",
    longitude: "-95.01388000",
  },
  {
    id: 111639,
    name: "Audubon",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.71804000",
    longitude: "-94.93249000",
  },
  {
    id: 111641,
    name: "Audubon County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.68459000",
    longitude: "-94.90582000",
  },
  {
    id: 111687,
    name: "Avoca",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.47666000",
    longitude: "-95.33805000",
  },
  {
    id: 111915,
    name: "Baxter",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.82610000",
    longitude: "-93.15159000",
  },
  {
    id: 112022,
    name: "Bedford",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "40.66693000",
    longitude: "-94.72136000",
  },
  {
    id: 112089,
    name: "Belle Plaine",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.89694000",
    longitude: "-92.27824000",
  },
  {
    id: 112111,
    name: "Bellevue",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.25863000",
    longitude: "-90.42291000",
  },
  {
    id: 112134,
    name: "Belmond",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.84608000",
    longitude: "-93.61410000",
  },
  {
    id: 112203,
    name: "Benton County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.08019000",
    longitude: "-92.06569000",
  },
  {
    id: 112294,
    name: "Bettendorf",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.52448000",
    longitude: "-90.51569000",
  },
  {
    id: 112377,
    name: "Black Hawk County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.47010000",
    longitude: "-92.30882000",
  },
  {
    id: 112436,
    name: "Bloomfield",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "40.75169000",
    longitude: "-92.41491000",
  },
  {
    id: 112467,
    name: "Blue Grass",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.50892000",
    longitude: "-90.76598000",
  },
  {
    id: 112534,
    name: "Bondurant",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.70054000",
    longitude: "-93.46216000",
  },
  {
    id: 112553,
    name: "Boone",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.05970000",
    longitude: "-93.88023000",
  },
  {
    id: 112558,
    name: "Boone County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.03655000",
    longitude: "-93.93167000",
  },
  {
    id: 112722,
    name: "Bremer County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.77459000",
    longitude: "-92.31805000",
  },
  {
    id: 112817,
    name: "Britt",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.09774000",
    longitude: "-93.80189000",
  },
  {
    id: 112870,
    name: "Brooklyn",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.73361000",
    longitude: "-92.44546000",
  },
  {
    id: 112975,
    name: "Buchanan County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.47078000",
    longitude: "-91.83784000",
  },
  {
    id: 113005,
    name: "Buena Vista County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.73549000",
    longitude: "-95.15115000",
  },
  {
    id: 113012,
    name: "Buffalo",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.45642000",
    longitude: "-90.72347000",
  },
  {
    id: 113017,
    name: "Buffalo (historical)",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.31110000",
    longitude: "-94.00356000",
  },
  {
    id: 113069,
    name: "Burlington",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "40.80754000",
    longitude: "-91.11292000",
  },
  {
    id: 113118,
    name: "Butler County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.73157000",
    longitude: "-92.79019000",
  },
  {
    id: 113209,
    name: "Calhoun County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.38518000",
    longitude: "-94.64041000",
  },
  {
    id: 113239,
    name: "Camanche",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.78809000",
    longitude: "-90.25624000",
  },
  {
    id: 113403,
    name: "Carlisle",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.50082000",
    longitude: "-93.49105000",
  },
  {
    id: 113449,
    name: "Carroll",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.06582000",
    longitude: "-94.86693000",
  },
  {
    id: 113458,
    name: "Carroll County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.03624000",
    longitude: "-94.86056000",
  },
  {
    id: 113485,
    name: "Carter Lake",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.29055000",
    longitude: "-95.91807000",
  },
  {
    id: 113513,
    name: "Cascade",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.29862000",
    longitude: "-91.01486000",
  },
  {
    id: 113529,
    name: "Cass County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.33149000",
    longitude: "-94.92783000",
  },
  {
    id: 113605,
    name: "Cedar County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.77232000",
    longitude: "-91.13241000",
  },
  {
    id: 113607,
    name: "Cedar Falls",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.52776000",
    longitude: "-92.44547000",
  },
  {
    id: 113621,
    name: "Cedar Rapids",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.00833000",
    longitude: "-91.64407000",
  },
  {
    id: 113647,
    name: "Center Point",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.19083000",
    longitude: "-91.78518000",
  },
  {
    id: 113658,
    name: "Centerville",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "40.73418000",
    longitude: "-92.87409000",
  },
  {
    id: 113668,
    name: "Central City",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.20388000",
    longitude: "-91.52406000",
  },
  {
    id: 113703,
    name: "Cerro Gordo County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.08156000",
    longitude: "-93.26082000",
  },
  {
    id: 113749,
    name: "Chariton",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.01389000",
    longitude: "-93.30660000",
  },
  {
    id: 113754,
    name: "Charles City",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.06636000",
    longitude: "-92.67241000",
  },
  {
    id: 113848,
    name: "Cherokee",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.74943000",
    longitude: "-95.55167000",
  },
  {
    id: 113856,
    name: "Cherokee County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.73562000",
    longitude: "-95.62381000",
  },
  {
    id: 113940,
    name: "Chickasaw County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.06004000",
    longitude: "-92.31766000",
  },
  {
    id: 114115,
    name: "Clarinda",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "40.73981000",
    longitude: "-95.03800000",
  },
  {
    id: 114116,
    name: "Clarion",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.73164000",
    longitude: "-93.73299000",
  },
  {
    id: 114138,
    name: "Clarke County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.02903000",
    longitude: "-93.78516000",
  },
  {
    id: 114156,
    name: "Clarksville",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.78470000",
    longitude: "-92.66769000",
  },
  {
    id: 114187,
    name: "Clay County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.08258000",
    longitude: "-95.15092000",
  },
  {
    id: 114206,
    name: "Clayton County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.84475000",
    longitude: "-91.34143000",
  },
  {
    id: 114209,
    name: "Clear Lake",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.13802000",
    longitude: "-93.37937000",
  },
  {
    id: 114278,
    name: "Clinton",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.84447000",
    longitude: "-90.18874000",
  },
  {
    id: 114292,
    name: "Clinton County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.89804000",
    longitude: "-90.53197000",
  },
  {
    id: 114303,
    name: "Clive",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.60304000",
    longitude: "-93.72411000",
  },
  {
    id: 114405,
    name: "Colfax",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.67777000",
    longitude: "-93.24520000",
  },
  {
    id: 114503,
    name: "Columbus Junction",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.28003000",
    longitude: "-91.36071000",
  },
  {
    id: 114568,
    name: "Conrad",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.22471000",
    longitude: "-92.87465000",
  },
  {
    id: 114599,
    name: "Coon Rapids",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.87082000",
    longitude: "-94.67748000",
  },
  {
    id: 114628,
    name: "Coralville",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.67640000",
    longitude: "-91.58045000",
  },
  {
    id: 114652,
    name: "Corning",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "40.98999000",
    longitude: "-94.74081000",
  },
  {
    id: 114682,
    name: "Corydon",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "40.75695000",
    longitude: "-93.31882000",
  },
  {
    id: 114712,
    name: "Council Bluffs",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.26194000",
    longitude: "-95.86083000",
  },
  {
    id: 114780,
    name: "Crawford County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.03721000",
    longitude: "-95.38197000",
  },
  {
    id: 114798,
    name: "Cresco",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.38136000",
    longitude: "-92.11405000",
  },
  {
    id: 114806,
    name: "Creston",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.05860000",
    longitude: "-94.36135000",
  },
  {
    id: 114974,
    name: "Dakota City",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.72219000",
    longitude: "-94.19718000",
  },
  {
    id: 114993,
    name: "Dallas Center",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.68443000",
    longitude: "-93.96106000",
  },
  {
    id: 114998,
    name: "Dallas County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.68489000",
    longitude: "-94.03974000",
  },
  {
    id: 115059,
    name: "Davenport",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.52364000",
    longitude: "-90.57764000",
  },
  {
    id: 115074,
    name: "Davis County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "40.74769000",
    longitude: "-92.40972000",
  },
  {
    id: 115118,
    name: "De Soto",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.53166000",
    longitude: "-94.00967000",
  },
  {
    id: 115122,
    name: "De Witt",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.82336000",
    longitude: "-90.53819000",
  },
  {
    id: 115162,
    name: "Decatur County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "40.73768000",
    longitude: "-93.78628000",
  },
  {
    id: 115166,
    name: "Decorah",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.30331000",
    longitude: "-91.78571000",
  },
  {
    id: 115205,
    name: "Delaware County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.47121000",
    longitude: "-91.36735000",
  },
  {
    id: 115238,
    name: "Denison",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.01777000",
    longitude: "-95.35528000",
  },
  {
    id: 115251,
    name: "Denver",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.67137000",
    longitude: "-92.33740000",
  },
  {
    id: 115271,
    name: "Des Moines",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.60054000",
    longitude: "-93.60911000",
  },
  {
    id: 115273,
    name: "Des Moines County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "40.92318000",
    longitude: "-91.18147000",
  },
  {
    id: 115328,
    name: "Dickinson County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.37798000",
    longitude: "-95.15083000",
  },
  {
    id: 115337,
    name: "Dike",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.46415000",
    longitude: "-92.62825000",
  },
  {
    id: 115500,
    name: "Dubuque",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.50056000",
    longitude: "-90.66457000",
  },
  {
    id: 115501,
    name: "Dubuque County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.46883000",
    longitude: "-90.88246000",
  },
  {
    id: 115568,
    name: "Durant",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.59975000",
    longitude: "-90.91070000",
  },
  {
    id: 115589,
    name: "Dyersville",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.48444000",
    longitude: "-91.12291000",
  },
  {
    id: 115591,
    name: "Dysart",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.17166000",
    longitude: "-92.30630000",
  },
  {
    id: 115601,
    name: "Eagle Grove",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.66414000",
    longitude: "-93.90439000",
  },
  {
    id: 115617,
    name: "Earlham",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.49193000",
    longitude: "-94.12412000",
  },
  {
    id: 115827,
    name: "Eddyville",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.15650000",
    longitude: "-92.63739000",
  },
  {
    id: 115951,
    name: "Eldora",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.36082000",
    longitude: "-93.09965000",
  },
  {
    id: 115956,
    name: "Eldridge",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.65809000",
    longitude: "-90.58458000",
  },
  {
    id: 115994,
    name: "Elk Run Heights",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.46693000",
    longitude: "-92.25657000",
  },
  {
    id: 115995,
    name: "Elkader",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.85387000",
    longitude: "-91.40542000",
  },
  {
    id: 116084,
    name: "Ely",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.87362000",
    longitude: "-91.58518000",
  },
  {
    id: 116102,
    name: "Emmet County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.37802000",
    longitude: "-94.67848000",
  },
  {
    id: 116104,
    name: "Emmetsburg",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.11274000",
    longitude: "-94.68304000",
  },
  {
    id: 116159,
    name: "Epworth",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.44500000",
    longitude: "-90.93208000",
  },
  {
    id: 116210,
    name: "Estherville",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.40163000",
    longitude: "-94.83276000",
  },
  {
    id: 116250,
    name: "Evansdale",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.46915000",
    longitude: "-92.28102000",
  },
  {
    id: 116289,
    name: "Fairbank",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.63915000",
    longitude: "-92.04712000",
  },
  {
    id: 116304,
    name: "Fairfax",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.91945000",
    longitude: "-91.78101000",
  },
  {
    id: 116313,
    name: "Fairfield",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.00863000",
    longitude: "-91.96267000",
  },
  {
    id: 116399,
    name: "Farley",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.44278000",
    longitude: "-91.00625000",
  },
  {
    id: 116441,
    name: "Fayette",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.84193000",
    longitude: "-91.80211000",
  },
  {
    id: 116451,
    name: "Fayette County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.86259000",
    longitude: "-91.84432000",
  },
  {
    id: 116610,
    name: "Floyd County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.05992000",
    longitude: "-92.78900000",
  },
  {
    id: 116648,
    name: "Forest City",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.26246000",
    longitude: "-93.63716000",
  },
  {
    id: 116707,
    name: "Fort Dodge",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.49747000",
    longitude: "-94.16802000",
  },
  {
    id: 116727,
    name: "Fort Madison",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "40.62976000",
    longitude: "-91.31515000",
  },
  {
    id: 116857,
    name: "Franklin County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.73255000",
    longitude: "-93.26247000",
  },
  {
    id: 116925,
    name: "Fremont County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "40.74559000",
    longitude: "-95.60468000",
  },
  {
    id: 117093,
    name: "Garner",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.10246000",
    longitude: "-93.60188000",
  },
  {
    id: 117152,
    name: "George",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.34386000",
    longitude: "-96.00224000",
  },
  {
    id: 117202,
    name: "Gilbert",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.10693000",
    longitude: "-93.64966000",
  },
  {
    id: 117313,
    name: "Glenwood",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.04694000",
    longitude: "-95.74251000",
  },
  {
    id: 117319,
    name: "Glidden",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.05693000",
    longitude: "-94.72887000",
  },
  {
    id: 117487,
    name: "Granger",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.76110000",
    longitude: "-93.82439000",
  },
  {
    id: 117628,
    name: "Greene",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.89581000",
    longitude: "-92.80242000",
  },
  {
    id: 117641,
    name: "Greene County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.03624000",
    longitude: "-94.39684000",
  },
  {
    id: 117649,
    name: "Greenfield",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.30527000",
    longitude: "-94.46135000",
  },
  {
    id: 117737,
    name: "Grimes",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.68832000",
    longitude: "-93.79106000",
  },
  {
    id: 117740,
    name: "Grinnell",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.74305000",
    longitude: "-92.72241000",
  },
  {
    id: 117769,
    name: "Grundy Center",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.36165000",
    longitude: "-92.76853000",
  },
  {
    id: 117771,
    name: "Grundy County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.40187000",
    longitude: "-92.79142000",
  },
  {
    id: 117814,
    name: "Guthrie Center",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.67721000",
    longitude: "-94.50330000",
  },
  {
    id: 117815,
    name: "Guthrie County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.68375000",
    longitude: "-94.50105000",
  },
  {
    id: 117816,
    name: "Guttenberg",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.78582000",
    longitude: "-91.09957000",
  },
  {
    id: 117882,
    name: "Hamburg",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "40.60445000",
    longitude: "-95.65777000",
  },
  {
    id: 117900,
    name: "Hamilton County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.38377000",
    longitude: "-93.70681000",
  },
  {
    id: 117930,
    name: "Hampton",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.74192000",
    longitude: "-93.20242000",
  },
  {
    id: 117955,
    name: "Hancock County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.08189000",
    longitude: "-93.73427000",
  },
  {
    id: 118003,
    name: "Hardin County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.38388000",
    longitude: "-93.24040000",
  },
  {
    id: 118016,
    name: "Harlan",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.65304000",
    longitude: "-95.32555000",
  },
  {
    id: 118067,
    name: "Harrison County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.68285000",
    longitude: "-95.81692000",
  },
  {
    id: 118098,
    name: "Hartley",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.17997000",
    longitude: "-95.47695000",
  },
  {
    id: 118163,
    name: "Hawarden",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.99582000",
    longitude: "-96.48531000",
  },
  {
    id: 118304,
    name: "Henry County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "40.98794000",
    longitude: "-91.54452000",
  },
  {
    id: 118358,
    name: "Hiawatha",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.03583000",
    longitude: "-91.68212000",
  },
  {
    id: 118573,
    name: "Holstein",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.48915000",
    longitude: "-95.54500000",
  },
  {
    id: 118715,
    name: "Howard County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.35677000",
    longitude: "-92.31720000",
  },
  {
    id: 118738,
    name: "Hudson",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.40665000",
    longitude: "-92.45547000",
  },
  {
    id: 118771,
    name: "Hull",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.18859000",
    longitude: "-96.13363000",
  },
  {
    id: 118777,
    name: "Humboldt",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.72080000",
    longitude: "-94.21524000",
  },
  {
    id: 118778,
    name: "Humboldt County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.77647000",
    longitude: "-94.20719000",
  },
  {
    id: 118841,
    name: "Huxley",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.89527000",
    longitude: "-93.60077000",
  },
  {
    id: 118866,
    name: "Ida County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.38687000",
    longitude: "-95.51350000",
  },
  {
    id: 118867,
    name: "Ida Grove",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.34499000",
    longitude: "-95.47167000",
  },
  {
    id: 118893,
    name: "Independence",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.46860000",
    longitude: "-91.88934000",
  },
  {
    id: 118926,
    name: "Indianola",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.35805000",
    longitude: "-93.55744000",
  },
  {
    id: 118976,
    name: "Iowa City",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.66113000",
    longitude: "-91.53017000",
  },
  {
    id: 118978,
    name: "Iowa County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.68632000",
    longitude: "-92.06552000",
  },
  {
    id: 118980,
    name: "Iowa Falls",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.52248000",
    longitude: "-93.25131000",
  },
  {
    id: 119090,
    name: "Jackson County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.17174000",
    longitude: "-90.57423000",
  },
  {
    id: 119151,
    name: "Jasper County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.68604000",
    longitude: "-93.05376000",
  },
  {
    id: 119168,
    name: "Jefferson",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.01526000",
    longitude: "-94.37747000",
  },
  {
    id: 119189,
    name: "Jefferson County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.03176000",
    longitude: "-91.94888000",
  },
  {
    id: 119240,
    name: "Jesup",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.47554000",
    longitude: "-92.06379000",
  },
  {
    id: 119242,
    name: "Jewell",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.30693000",
    longitude: "-93.64022000",
  },
  {
    id: 119269,
    name: "Johnson County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.67155000",
    longitude: "-91.58808000",
  },
  {
    id: 119278,
    name: "Johnston",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.67304000",
    longitude: "-93.69772000",
  },
  {
    id: 119296,
    name: "Jones County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.12124000",
    longitude: "-91.13144000",
  },
  {
    id: 119368,
    name: "Kalona",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.48307000",
    longitude: "-91.70600000",
  },
  {
    id: 119507,
    name: "Keokuk",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "40.39727000",
    longitude: "-91.38487000",
  },
  {
    id: 119508,
    name: "Keokuk County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.33646000",
    longitude: "-92.17864000",
  },
  {
    id: 119509,
    name: "Keosauqua",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "40.73031000",
    longitude: "-91.96239000",
  },
  {
    id: 119617,
    name: "Kingsley",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.58832000",
    longitude: "-95.96752000",
  },
  {
    id: 119695,
    name: "Knoxville",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.32083000",
    longitude: "-93.10937000",
  },
  {
    id: 119711,
    name: "Kossuth County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.20413000",
    longitude: "-94.20672000",
  },
  {
    id: 119778,
    name: "La Porte City",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.31499000",
    longitude: "-92.19213000",
  },
  {
    id: 119882,
    name: "Lake City",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.26748000",
    longitude: "-94.73387000",
  },
  {
    id: 119942,
    name: "Lake Mills",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.41940000",
    longitude: "-93.53327000",
  },
  {
    id: 119958,
    name: "Lake Panorama",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.71105000",
    longitude: "-94.39059000",
  },
  {
    id: 119961,
    name: "Lake Park",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.45552000",
    longitude: "-95.32083000",
  },
  {
    id: 119987,
    name: "Lake View",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.31165000",
    longitude: "-95.05332000",
  },
  {
    id: 120073,
    name: "Lamoni",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "40.62278000",
    longitude: "-93.93412000",
  },
  {
    id: 120207,
    name: "Laurens",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.84664000",
    longitude: "-94.85193000",
  },
  {
    id: 120258,
    name: "Le Claire",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.59864000",
    longitude: "-90.34346000",
  },
  {
    id: 120261,
    name: "Le Mars",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.79416000",
    longitude: "-96.16558000",
  },
  {
    id: 120316,
    name: "Lee County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "40.64198000",
    longitude: "-91.47926000",
  },
  {
    id: 120375,
    name: "Lenox",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "40.88165000",
    longitude: "-94.56191000",
  },
  {
    id: 120383,
    name: "Leon",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "40.73972000",
    longitude: "-93.74772000",
  },
  {
    id: 120583,
    name: "Linn County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.07895000",
    longitude: "-91.59896000",
  },
  {
    id: 120598,
    name: "Lisbon",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.92112000",
    longitude: "-91.38545000",
  },
  {
    id: 120694,
    name: "Logan",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.64305000",
    longitude: "-95.78890000",
  },
  {
    id: 120731,
    name: "Lone Tree",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.48808000",
    longitude: "-91.42599000",
  },
  {
    id: 120807,
    name: "Louisa County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.21851000",
    longitude: "-91.25962000",
  },
  {
    id: 120861,
    name: "Lucas County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.02937000",
    longitude: "-93.32772000",
  },
  {
    id: 120937,
    name: "Lyon County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.38050000",
    longitude: "-96.21029000",
  },
  {
    id: 121014,
    name: "Madison County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.33071000",
    longitude: "-94.01556000",
  },
  {
    id: 121029,
    name: "Madrid",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.87665000",
    longitude: "-93.82328000",
  },
  {
    id: 121041,
    name: "Maharishi Vedic City",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.05252000",
    longitude: "-91.99490000",
  },
  {
    id: 121042,
    name: "Mahaska County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.33520000",
    longitude: "-92.64091000",
  },
  {
    id: 121072,
    name: "Malvern",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.00278000",
    longitude: "-95.58528000",
  },
  {
    id: 121095,
    name: "Manchester",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.48415000",
    longitude: "-91.45543000",
  },
  {
    id: 121132,
    name: "Manly",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.28718000",
    longitude: "-93.20215000",
  },
  {
    id: 121135,
    name: "Manning",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.90915000",
    longitude: "-95.06499000",
  },
  {
    id: 121150,
    name: "Manson",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.52914000",
    longitude: "-94.53414000",
  },
  {
    id: 121174,
    name: "Mapleton",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.16582000",
    longitude: "-95.79306000",
  },
  {
    id: 121181,
    name: "Maquoketa",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.06891000",
    longitude: "-90.66569000",
  },
  {
    id: 121199,
    name: "Marcus",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.82582000",
    longitude: "-95.80751000",
  },
  {
    id: 121201,
    name: "Marengo",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.79806000",
    longitude: "-92.07074000",
  },
  {
    id: 121240,
    name: "Marion",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.03417000",
    longitude: "-91.59768000",
  },
  {
    id: 121261,
    name: "Marion County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.33445000",
    longitude: "-93.09944000",
  },
  {
    id: 121317,
    name: "Marshall County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.03585000",
    longitude: "-92.99877000",
  },
  {
    id: 121323,
    name: "Marshalltown",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.04943000",
    longitude: "-92.90798000",
  },
  {
    id: 121375,
    name: "Mason City",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.15357000",
    longitude: "-93.20104000",
  },
  {
    id: 121584,
    name: "Mechanicsville",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.90446000",
    longitude: "-91.25461000",
  },
  {
    id: 121599,
    name: "Mediapolis",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.00809000",
    longitude: "-91.16404000",
  },
  {
    id: 121623,
    name: "Melcher-Dallas",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.22500000",
    longitude: "-93.24132000",
  },
  {
    id: 121848,
    name: "Milford",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.32469000",
    longitude: "-95.15000000",
  },
  {
    id: 121898,
    name: "Mills County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.03345000",
    longitude: "-95.62133000",
  },
  {
    id: 121994,
    name: "Missouri Valley",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.55638000",
    longitude: "-95.88779000",
  },
  {
    id: 122002,
    name: "Mitchell County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.35641000",
    longitude: "-92.78903000",
  },
  {
    id: 122005,
    name: "Mitchellville",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.66860000",
    longitude: "-93.35771000",
  },
  {
    id: 122051,
    name: "Monona",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.05165000",
    longitude: "-91.38930000",
  },
  {
    id: 122053,
    name: "Monona County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.05167000",
    longitude: "-95.95992000",
  },
  {
    id: 122060,
    name: "Monroe",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.52221000",
    longitude: "-93.10187000",
  },
  {
    id: 122078,
    name: "Monroe County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.02978000",
    longitude: "-92.86899000",
  },
  {
    id: 122128,
    name: "Montezuma",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.58583000",
    longitude: "-92.52741000",
  },
  {
    id: 122152,
    name: "Montgomery County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.03014000",
    longitude: "-95.15638000",
  },
  {
    id: 122164,
    name: "Monticello",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.23834000",
    longitude: "-91.18709000",
  },
  {
    id: 122334,
    name: "Mount Ayr",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "40.71471000",
    longitude: "-94.23523000",
  },
  {
    id: 122378,
    name: "Mount Pleasant",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "40.96364000",
    longitude: "-91.55794000",
  },
  {
    id: 122400,
    name: "Mount Vernon",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.92195000",
    longitude: "-91.41684000",
  },
  {
    id: 122442,
    name: "Moville",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.48888000",
    longitude: "-96.07252000",
  },
  {
    id: 122501,
    name: "Muscatine",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.42447000",
    longitude: "-91.04321000",
  },
  {
    id: 122502,
    name: "Muscatine County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.48392000",
    longitude: "-91.11276000",
  },
  {
    id: 122570,
    name: "Nashua",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.95275000",
    longitude: "-92.53630000",
  },
  {
    id: 122650,
    name: "Nevada",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.02277000",
    longitude: "-93.45243000",
  },
  {
    id: 122710,
    name: "New Hampton",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.05914000",
    longitude: "-92.31768000",
  },
  {
    id: 122742,
    name: "New London",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "40.92698000",
    longitude: "-91.39960000",
  },
  {
    id: 122776,
    name: "New Sharon",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.47000000",
    longitude: "-92.65130000",
  },
  {
    id: 122863,
    name: "Newton",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.69971000",
    longitude: "-93.04798000",
  },
  {
    id: 122937,
    name: "Nora Springs",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.14275000",
    longitude: "-93.00437000",
  },
  {
    id: 123023,
    name: "North English",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.51390000",
    longitude: "-92.07629000",
  },
  {
    id: 123056,
    name: "North Liberty",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.74918000",
    longitude: "-91.59795000",
  },
  {
    id: 123179,
    name: "Northwood",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.44412000",
    longitude: "-93.22104000",
  },
  {
    id: 123189,
    name: "Norwalk",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.47555000",
    longitude: "-93.67883000",
  },
  {
    id: 123225,
    name: "O'Brien County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.08375000",
    longitude: "-95.62488000",
  },
  {
    id: 123288,
    name: "Oakland",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.30916000",
    longitude: "-95.39667000",
  },
  {
    id: 123363,
    name: "Oelwein",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.67332000",
    longitude: "-91.91350000",
  },
  {
    id: 123368,
    name: "Ogden",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.03915000",
    longitude: "-94.02773000",
  },
  {
    id: 123471,
    name: "Onawa",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.02665000",
    longitude: "-96.09724000",
  },
  {
    id: 123513,
    name: "Orange City",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.00721000",
    longitude: "-96.05835000",
  },
  {
    id: 123586,
    name: "Osage",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.28414000",
    longitude: "-92.81103000",
  },
  {
    id: 123599,
    name: "Osceola",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.03389000",
    longitude: "-93.76550000",
  },
  {
    id: 123604,
    name: "Osceola County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.37857000",
    longitude: "-95.62369000",
  },
  {
    id: 123612,
    name: "Oskaloosa",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.29639000",
    longitude: "-92.64436000",
  },
  {
    id: 123643,
    name: "Ottumwa",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.02001000",
    longitude: "-92.41130000",
  },
  {
    id: 123719,
    name: "Page County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "40.73914000",
    longitude: "-95.15017000",
  },
  {
    id: 123779,
    name: "Palo",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.06611000",
    longitude: "-91.79546000",
  },
  {
    id: 123782,
    name: "Palo Alto County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.08206000",
    longitude: "-94.67814000",
  },
  {
    id: 123805,
    name: "Panora",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.69165000",
    longitude: "-94.36302000",
  },
  {
    id: 123855,
    name: "Park View",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.69420000",
    longitude: "-90.54569000",
  },
  {
    id: 123867,
    name: "Parkersburg",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.57748000",
    longitude: "-92.78686000",
  },
  {
    id: 123924,
    name: "Paullina",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.97915000",
    longitude: "-95.68807000",
  },
  {
    id: 123999,
    name: "Pella",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.40805000",
    longitude: "-92.91631000",
  },
  {
    id: 124050,
    name: "Peosta",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.45056000",
    longitude: "-90.85041000",
  },
  {
    id: 124070,
    name: "Perry",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.83860000",
    longitude: "-94.10718000",
  },
  {
    id: 124370,
    name: "Pleasant Hill",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.58388000",
    longitude: "-93.51994000",
  },
  {
    id: 124387,
    name: "Pleasantville",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.38583000",
    longitude: "-93.26937000",
  },
  {
    id: 124409,
    name: "Plymouth County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.73783000",
    longitude: "-96.21404000",
  },
  {
    id: 124414,
    name: "Pocahontas",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.73553000",
    longitude: "-94.66915000",
  },
  {
    id: 124416,
    name: "Pocahontas County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.73414000",
    longitude: "-94.67875000",
  },
  {
    id: 124439,
    name: "Polk City",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.77138000",
    longitude: "-93.71300000",
  },
  {
    id: 124447,
    name: "Polk County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.68550000",
    longitude: "-93.57353000",
  },
  {
    id: 124578,
    name: "Postville",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.08470000",
    longitude: "-91.56820000",
  },
  {
    id: 124591,
    name: "Pottawattamie County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.33661000",
    longitude: "-95.54239000",
  },
  {
    id: 124617,
    name: "Poweshiek County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.68644000",
    longitude: "-92.53147000",
  },
  {
    id: 124623,
    name: "Prairie City",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.59943000",
    longitude: "-93.23521000",
  },
  {
    id: 124655,
    name: "Preston",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.05030000",
    longitude: "-90.41402000",
  },
  {
    id: 124671,
    name: "Primghar",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.08692000",
    longitude: "-95.62723000",
  },
  {
    id: 124957,
    name: "Red Oak",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.00972000",
    longitude: "-95.22555000",
  },
  {
    id: 125010,
    name: "Reinbeck",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.32360000",
    longitude: "-92.59936000",
  },
  {
    id: 125015,
    name: "Remsen",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.81471000",
    longitude: "-95.97335000",
  },
  {
    id: 125143,
    name: "Ringgold County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "40.73520000",
    longitude: "-94.24397000",
  },
  {
    id: 125205,
    name: "Riverside",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.47974000",
    longitude: "-91.58128000",
  },
  {
    id: 125251,
    name: "Robins",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.07111000",
    longitude: "-91.66684000",
  },
  {
    id: 125286,
    name: "Rock Rapids",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.42719000",
    longitude: "-96.17586000",
  },
  {
    id: 125288,
    name: "Rock Valley",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.20526000",
    longitude: "-96.29503000",
  },
  {
    id: 125330,
    name: "Rockwell",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.98524000",
    longitude: "-93.19187000",
  },
  {
    id: 125331,
    name: "Rockwell City",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.39526000",
    longitude: "-94.63387000",
  },
  {
    id: 125360,
    name: "Roland",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.16637000",
    longitude: "-93.50188000",
  },
  {
    id: 125571,
    name: "Sac City",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.42220000",
    longitude: "-94.98971000",
  },
  {
    id: 125572,
    name: "Sac County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.38626000",
    longitude: "-95.10539000",
  },
  {
    id: 125600,
    name: "Saint Ansgar",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.37830000",
    longitude: "-92.91881000",
  },
  {
    id: 125853,
    name: "Sanborn",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.18164000",
    longitude: "-95.65557000",
  },
  {
    id: 125988,
    name: "Saylorville",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.67860000",
    longitude: "-93.62966000",
  },
  {
    id: 126051,
    name: "Scott County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.63710000",
    longitude: "-90.62324000",
  },
  {
    id: 126167,
    name: "Sergeant Bluff",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.40388000",
    longitude: "-96.35864000",
  },
  {
    id: 126260,
    name: "Sheffield",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.89330000",
    longitude: "-93.21520000",
  },
  {
    id: 126279,
    name: "Shelby County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.68509000",
    longitude: "-95.31021000",
  },
  {
    id: 126286,
    name: "Sheldon",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.18109000",
    longitude: "-95.85613000",
  },
  {
    id: 126292,
    name: "Shell Rock",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.71026000",
    longitude: "-92.58297000",
  },
  {
    id: 126302,
    name: "Shenandoah",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "40.76555000",
    longitude: "-95.37221000",
  },
  {
    id: 126391,
    name: "Sibley",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.39914000",
    longitude: "-95.75196000",
  },
  {
    id: 126394,
    name: "Sidney",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "40.74833000",
    longitude: "-95.64750000",
  },
  {
    id: 126413,
    name: "Sigourney",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.33334000",
    longitude: "-92.20463000",
  },
  {
    id: 126457,
    name: "Sioux Center",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.07971000",
    longitude: "-96.17558000",
  },
  {
    id: 126458,
    name: "Sioux City",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.49999000",
    longitude: "-96.40031000",
  },
  {
    id: 126459,
    name: "Sioux County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.08262000",
    longitude: "-96.17788000",
  },
  {
    id: 126490,
    name: "Slater",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.87776000",
    longitude: "-93.67855000",
  },
  {
    id: 126571,
    name: "Solon",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.80723000",
    longitude: "-91.49406000",
  },
  {
    id: 126825,
    name: "Spencer",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.14136000",
    longitude: "-95.14444000",
  },
  {
    id: 126838,
    name: "Spirit Lake",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.42218000",
    longitude: "-95.10222000",
  },
  {
    id: 126915,
    name: "Springville",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.05945000",
    longitude: "-91.44267000",
  },
  {
    id: 126991,
    name: "State Center",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.01665000",
    longitude: "-93.16354000",
  },
  {
    id: 127103,
    name: "Storm Lake",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.64109000",
    longitude: "-95.20972000",
  },
  {
    id: 127106,
    name: "Story City",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.18721000",
    longitude: "-93.59577000",
  },
  {
    id: 127107,
    name: "Story County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.03624000",
    longitude: "-93.46504000",
  },
  {
    id: 127134,
    name: "Strawberry Point",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.68360000",
    longitude: "-91.53403000",
  },
  {
    id: 127143,
    name: "Stuart",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.50332000",
    longitude: "-94.31857000",
  },
  {
    id: 127222,
    name: "Sumner",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.84748000",
    longitude: "-92.09156000",
  },
  {
    id: 127400,
    name: "Tama",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.96666000",
    longitude: "-92.57686000",
  },
  {
    id: 127401,
    name: "Tama County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.07981000",
    longitude: "-92.53254000",
  },
  {
    id: 127456,
    name: "Taylor County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "40.73743000",
    longitude: "-94.69641000",
  },
  {
    id: 127640,
    name: "Tiffin",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.70585000",
    longitude: "-91.66295000",
  },
  {
    id: 127675,
    name: "Tipton",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.76974000",
    longitude: "-91.12793000",
  },
  {
    id: 127699,
    name: "Toledo",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.99555000",
    longitude: "-92.57686000",
  },
  {
    id: 127774,
    name: "Traer",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.19360000",
    longitude: "-92.46547000",
  },
  {
    id: 127831,
    name: "Tripoli",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.80804000",
    longitude: "-92.25823000",
  },
  {
    id: 127998,
    name: "Union County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.02773000",
    longitude: "-94.24238000",
  },
  {
    id: 128029,
    name: "University Heights",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.65502000",
    longitude: "-91.55684000",
  },
  {
    id: 128061,
    name: "Urbana",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.22416000",
    longitude: "-91.87434000",
  },
  {
    id: 128063,
    name: "Urbandale",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.62666000",
    longitude: "-93.71217000",
  },
  {
    id: 128131,
    name: "Van Buren County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "40.75323000",
    longitude: "-91.94999000",
  },
  {
    id: 128135,
    name: "Van Meter",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.53193000",
    longitude: "-93.95412000",
  },
  {
    id: 128260,
    name: "Villisca",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "40.92971000",
    longitude: "-94.97609000",
  },
  {
    id: 128280,
    name: "Vinton",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.16861000",
    longitude: "-92.02351000",
  },
  {
    id: 128366,
    name: "Walcott",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.58475000",
    longitude: "-90.77209000",
  },
  {
    id: 128381,
    name: "Walford",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.87834000",
    longitude: "-91.83462000",
  },
  {
    id: 128454,
    name: "Wapello",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.18142000",
    longitude: "-91.18543000",
  },
  {
    id: 128455,
    name: "Wapello County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.03058000",
    longitude: "-92.40945000",
  },
  {
    id: 128493,
    name: "Warren County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.33437000",
    longitude: "-93.56136000",
  },
  {
    id: 128542,
    name: "Washington",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.29918000",
    longitude: "-91.69294000",
  },
  {
    id: 128568,
    name: "Washington County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.33559000",
    longitude: "-91.71787000",
  },
  {
    id: 128612,
    name: "Waterloo",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.49276000",
    longitude: "-92.34296000",
  },
  {
    id: 128647,
    name: "Waukee",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.61166000",
    longitude: "-93.88523000",
  },
  {
    id: 128652,
    name: "Waukon",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.26942000",
    longitude: "-91.47570000",
  },
  {
    id: 128668,
    name: "Waverly",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.72581000",
    longitude: "-92.47546000",
  },
  {
    id: 128698,
    name: "Wayne County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "40.73947000",
    longitude: "-93.32736000",
  },
  {
    id: 128735,
    name: "Webster City",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.46942000",
    longitude: "-93.81605000",
  },
  {
    id: 128741,
    name: "Webster County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.42797000",
    longitude: "-94.18179000",
  },
  {
    id: 128781,
    name: "Wellman",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.46418000",
    longitude: "-91.83823000",
  },
  {
    id: 128832,
    name: "West Branch",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.67141000",
    longitude: "-91.34655000",
  },
  {
    id: 128838,
    name: "West Burlington",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "40.82504000",
    longitude: "-91.15654000",
  },
  {
    id: 128857,
    name: "West Des Moines",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.57721000",
    longitude: "-93.71133000",
  },
  {
    id: 128917,
    name: "West Liberty",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.57002000",
    longitude: "-91.26377000",
  },
  {
    id: 128993,
    name: "West Union",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.96276000",
    longitude: "-91.80822000",
  },
  {
    id: 129267,
    name: "Williamsburg",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.66112000",
    longitude: "-92.00907000",
  },
  {
    id: 129343,
    name: "Wilton",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.58892000",
    longitude: "-91.01682000",
  },
  {
    id: 129389,
    name: "Windsor Heights",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.59777000",
    longitude: "-93.70828000",
  },
  {
    id: 129395,
    name: "Winfield",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.12308000",
    longitude: "-91.44127000",
  },
  {
    id: 129407,
    name: "Winnebago County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.37757000",
    longitude: "-93.73420000",
  },
  {
    id: 129413,
    name: "Winneshiek County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.29067000",
    longitude: "-91.84371000",
  },
  {
    id: 129446,
    name: "Winterset",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.33082000",
    longitude: "-94.01384000",
  },
  {
    id: 129496,
    name: "Woodbine",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.73832000",
    longitude: "-95.70278000",
  },
  {
    id: 129512,
    name: "Woodbury County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.38972000",
    longitude: "-96.04477000",
  },
  {
    id: 129576,
    name: "Woodward",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "41.85693000",
    longitude: "-93.92190000",
  },
  {
    id: 129593,
    name: "Worth County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "43.37740000",
    longitude: "-93.26085000",
  },
  {
    id: 129609,
    name: "Wright County",
    state_id: 1459,
    state_code: "IA",
    country_id: 233,
    country_code: "US",
    latitude: "42.73312000",
    longitude: "-93.73515000",
  },
  {
    id: 110982,
    name: "Abilene",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.91722000",
    longitude: "-97.21391000",
  },
  {
    id: 111188,
    name: "Allen County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.88573000",
    longitude: "-95.30139000",
  },
  {
    id: 111208,
    name: "Alma",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.01667000",
    longitude: "-96.28916000",
  },
  {
    id: 111233,
    name: "Altamont",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.19034000",
    longitude: "-95.29719000",
  },
  {
    id: 111315,
    name: "Anderson County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.21420000",
    longitude: "-95.29333000",
  },
  {
    id: 111322,
    name: "Andover",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.71390000",
    longitude: "-97.13643000",
  },
  {
    id: 111368,
    name: "Anthony",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.15336000",
    longitude: "-98.03117000",
  },
  {
    id: 111457,
    name: "Arkansas City",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.06197000",
    longitude: "-97.03837000",
  },
  {
    id: 111475,
    name: "Arma",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.54394000",
    longitude: "-94.70024000",
  },
  {
    id: 111527,
    name: "Ashland",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.18864000",
    longitude: "-99.76568000",
  },
  {
    id: 111567,
    name: "Atchison",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.56305000",
    longitude: "-95.12164000",
  },
  {
    id: 111568,
    name: "Atchison County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.53174000",
    longitude: "-95.31344000",
  },
  {
    id: 111615,
    name: "Atwood",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.80667000",
    longitude: "-101.04210000",
  },
  {
    id: 111622,
    name: "Auburn",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.90611000",
    longitude: "-95.81610000",
  },
  {
    id: 111647,
    name: "Augusta",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.68668000",
    longitude: "-96.97670000",
  },
  {
    id: 111759,
    name: "Baldwin City",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.77501000",
    longitude: "-95.18636000",
  },
  {
    id: 111812,
    name: "Barber County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.22884000",
    longitude: "-98.68479000",
  },
  {
    id: 111866,
    name: "Barton County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.47896000",
    longitude: "-98.75646000",
  },
  {
    id: 111876,
    name: "Basehor",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.14167000",
    longitude: "-94.93858000",
  },
  {
    id: 111919,
    name: "Baxter Springs",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.02368000",
    longitude: "-94.73550000",
  },
  {
    id: 112074,
    name: "Bellaire",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.76251000",
    longitude: "-97.26699000",
  },
  {
    id: 112088,
    name: "Belle Plaine",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.39391000",
    longitude: "-97.28115000",
  },
  {
    id: 112104,
    name: "Belleville",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.82445000",
    longitude: "-97.63254000",
  },
  {
    id: 112145,
    name: "Beloit",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.45612000",
    longitude: "-98.10616000",
  },
  {
    id: 112543,
    name: "Bonner Springs",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.05973000",
    longitude: "-94.88358000",
  },
  {
    id: 112613,
    name: "Bourbon County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.85523000",
    longitude: "-94.84930000",
  },
  {
    id: 112909,
    name: "Brown County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.82650000",
    longitude: "-95.56422000",
  },
  {
    id: 113024,
    name: "Buhler",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.13445000",
    longitude: "-97.77005000",
  },
  {
    id: 113066,
    name: "Burlington",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.19447000",
    longitude: "-95.74276000",
  },
  {
    id: 113115,
    name: "Butler County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.78127000",
    longitude: "-96.83907000",
  },
  {
    id: 113179,
    name: "Caldwell",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.03225000",
    longitude: "-97.60699000",
  },
  {
    id: 113328,
    name: "Caney",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.01146000",
    longitude: "-95.93526000",
  },
  {
    id: 113387,
    name: "Carbondale",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.81862000",
    longitude: "-95.68915000",
  },
  {
    id: 113739,
    name: "Chanute",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.67921000",
    longitude: "-95.45720000",
  },
  {
    id: 113744,
    name: "Chapman",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.97222000",
    longitude: "-97.02251000",
  },
  {
    id: 113792,
    name: "Chase County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.30205000",
    longitude: "-96.59393000",
  },
  {
    id: 113814,
    name: "Chautauqua County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.15006000",
    longitude: "-96.24538000",
  },
  {
    id: 113839,
    name: "Cheney",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.63001000",
    longitude: "-97.78255000",
  },
  {
    id: 113851,
    name: "Cherokee County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.16931000",
    longitude: "-94.84627000",
  },
  {
    id: 113868,
    name: "Cherryvale",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.27034000",
    longitude: "-95.55248000",
  },
  {
    id: 113916,
    name: "Chetopa",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.03729000",
    longitude: "-95.08996000",
  },
  {
    id: 113928,
    name: "Cheyenne County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.78587000",
    longitude: "-101.73110000",
  },
  {
    id: 114023,
    name: "Cimarron",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.80669000",
    longitude: "-100.34820000",
  },
  {
    id: 114124,
    name: "Clark County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.23552000",
    longitude: "-99.82031000",
  },
  {
    id: 114170,
    name: "Clay Center",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.37694000",
    longitude: "-97.12474000",
  },
  {
    id: 114179,
    name: "Clay County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.34971000",
    longitude: "-97.16517000",
  },
  {
    id: 114223,
    name: "Clearwater",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.50280000",
    longitude: "-97.50449000",
  },
  {
    id: 114306,
    name: "Cloud County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.48030000",
    longitude: "-97.64928000",
  },
  {
    id: 114370,
    name: "Coffey County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.23684000",
    longitude: "-95.73411000",
  },
  {
    id: 114371,
    name: "Coffeyville",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.03730000",
    longitude: "-95.61637000",
  },
  {
    id: 114381,
    name: "Colby",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.39584000",
    longitude: "-101.05238000",
  },
  {
    id: 114392,
    name: "Coldwater",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.26891000",
    longitude: "-99.32678000",
  },
  {
    id: 114492,
    name: "Columbus",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.16923000",
    longitude: "-94.84412000",
  },
  {
    id: 114507,
    name: "Colwich",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.77918000",
    longitude: "-97.53644000",
  },
  {
    id: 114512,
    name: "Comanche County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.19128000",
    longitude: "-99.27187000",
  },
  {
    id: 114544,
    name: "Concordia",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.57084000",
    longitude: "-97.66254000",
  },
  {
    id: 114588,
    name: "Conway Springs",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.39030000",
    longitude: "-97.64227000",
  },
  {
    id: 114704,
    name: "Cottonwood Falls",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.37224000",
    longitude: "-96.54278000",
  },
  {
    id: 114713,
    name: "Council Grove",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.66112000",
    longitude: "-96.49195000",
  },
  {
    id: 114748,
    name: "Cowley County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.23775000",
    longitude: "-96.83749000",
  },
  {
    id: 114778,
    name: "Crawford County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.50732000",
    longitude: "-94.85181000",
  },
  {
    id: 115115,
    name: "De Soto",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.97917000",
    longitude: "-94.96858000",
  },
  {
    id: 115163,
    name: "Decatur County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.78476000",
    longitude: "-100.45990000",
  },
  {
    id: 115260,
    name: "Derby",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.54557000",
    longitude: "-97.26893000",
  },
  {
    id: 115327,
    name: "Dickinson County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.86650000",
    longitude: "-97.15270000",
  },
  {
    id: 115336,
    name: "Dighton",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.48196000",
    longitude: "-100.46708000",
  },
  {
    id: 115376,
    name: "Dodge City",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.75280000",
    longitude: "-100.01708000",
  },
  {
    id: 115398,
    name: "Doniphan County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.78808000",
    longitude: "-95.14679000",
  },
  {
    id: 115427,
    name: "Douglas County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.88466000",
    longitude: "-95.29261000",
  },
  {
    id: 115437,
    name: "Douglass",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.51946000",
    longitude: "-97.01281000",
  },
  {
    id: 115848,
    name: "Edgerton",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.76473000",
    longitude: "-95.00802000",
  },
  {
    id: 115890,
    name: "Edwards County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.88765000",
    longitude: "-99.31217000",
  },
  {
    id: 115893,
    name: "Edwardsville",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.06112000",
    longitude: "-94.81968000",
  },
  {
    id: 115920,
    name: "El Dorado",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.81724000",
    longitude: "-96.86225000",
  },
  {
    id: 115984,
    name: "Elk County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.45369000",
    longitude: "-96.24409000",
  },
  {
    id: 115999,
    name: "Elkhart",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.00808000",
    longitude: "-101.89017000",
  },
  {
    id: 116027,
    name: "Ellinwood",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.35557000",
    longitude: "-98.58091000",
  },
  {
    id: 116029,
    name: "Ellis",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.93807000",
    longitude: "-99.56067000",
  },
  {
    id: 116030,
    name: "Ellis County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.91475000",
    longitude: "-99.31723000",
  },
  {
    id: 116037,
    name: "Ellsworth",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.73056000",
    longitude: "-98.22811000",
  },
  {
    id: 116040,
    name: "Ellsworth County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.69663000",
    longitude: "-98.20473000",
  },
  {
    id: 116077,
    name: "Elwood",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.75555000",
    longitude: "-94.87247000",
  },
  {
    id: 116111,
    name: "Emporia",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.40390000",
    longitude: "-96.18166000",
  },
  {
    id: 116164,
    name: "Erie",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.56811000",
    longitude: "-95.24331000",
  },
  {
    id: 116223,
    name: "Eudora",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.94334000",
    longitude: "-95.09858000",
  },
  {
    id: 116232,
    name: "Eureka",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.82392000",
    longitude: "-96.28917000",
  },
  {
    id: 116362,
    name: "Fairway",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.02223000",
    longitude: "-94.63190000",
  },
  {
    id: 116518,
    name: "Finney County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.04430000",
    longitude: "-100.73699000",
  },
  {
    id: 116636,
    name: "Ford County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.69170000",
    longitude: "-99.88794000",
  },
  {
    id: 116746,
    name: "Fort Riley North",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.11081000",
    longitude: "-96.81392000",
  },
  {
    id: 116749,
    name: "Fort Scott",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.83976000",
    longitude: "-94.70830000",
  },
  {
    id: 116849,
    name: "Franklin County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.56452000",
    longitude: "-95.28595000",
  },
  {
    id: 116895,
    name: "Fredonia",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.53394000",
    longitude: "-95.82665000",
  },
  {
    id: 116961,
    name: "Frontenac",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.45560000",
    longitude: "-94.68913000",
  },
  {
    id: 117022,
    name: "Galena",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.07590000",
    longitude: "-94.63967000",
  },
  {
    id: 117059,
    name: "Garden City",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.97169000",
    longitude: "-100.87266000",
  },
  {
    id: 117074,
    name: "Gardner",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.81084000",
    longitude: "-94.92719000",
  },
  {
    id: 117095,
    name: "Garnett",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.28058000",
    longitude: "-95.24192000",
  },
  {
    id: 117131,
    name: "Geary County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.00236000",
    longitude: "-96.75254000",
  },
  {
    id: 117227,
    name: "Girard",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.51116000",
    longitude: "-94.83802000",
  },
  {
    id: 117332,
    name: "Goddard",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.65974000",
    longitude: "-97.57533000",
  },
  {
    id: 117385,
    name: "Goodland",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.35083000",
    longitude: "-101.71017000",
  },
  {
    id: 117420,
    name: "Gove",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.95779000",
    longitude: "-100.48875000",
  },
  {
    id: 117421,
    name: "Gove County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.91610000",
    longitude: "-100.48290000",
  },
  {
    id: 117437,
    name: "Graham County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.34972000",
    longitude: "-99.88325000",
  },
  {
    id: 117482,
    name: "Grandview Plaza",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.02916000",
    longitude: "-96.78917000",
  },
  {
    id: 117516,
    name: "Grant County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.56219000",
    longitude: "-101.30802000",
  },
  {
    id: 117553,
    name: "Gray County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.73820000",
    longitude: "-100.43786000",
  },
  {
    id: 117570,
    name: "Great Bend",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.36446000",
    longitude: "-98.76481000",
  },
  {
    id: 117588,
    name: "Greeley County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.48054000",
    longitude: "-101.80597000",
  },
  {
    id: 117670,
    name: "Greensburg",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.60280000",
    longitude: "-99.29261000",
  },
  {
    id: 117709,
    name: "Greenwood County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.87779000",
    longitude: "-96.23264000",
  },
  {
    id: 117877,
    name: "Halstead",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.00140000",
    longitude: "-97.50865000",
  },
  {
    id: 117904,
    name: "Hamilton County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.99915000",
    longitude: "-101.79126000",
  },
  {
    id: 118031,
    name: "Harper",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.28669000",
    longitude: "-98.02589000",
  },
  {
    id: 118033,
    name: "Harper County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.19160000",
    longitude: "-98.07550000",
  },
  {
    id: 118115,
    name: "Harvey County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.04322000",
    longitude: "-97.42727000",
  },
  {
    id: 118127,
    name: "Haskell County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.56225000",
    longitude: "-100.87119000",
  },
  {
    id: 118148,
    name: "Haven",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.89890000",
    longitude: "-97.78283000",
  },
  {
    id: 118190,
    name: "Hays",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.87918000",
    longitude: "-99.32677000",
  },
  {
    id: 118193,
    name: "Haysville",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.56446000",
    longitude: "-97.35227000",
  },
  {
    id: 118316,
    name: "Herington",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.67112000",
    longitude: "-96.94251000",
  },
  {
    id: 118345,
    name: "Hesston",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.13834000",
    longitude: "-97.43143000",
  },
  {
    id: 118357,
    name: "Hiawatha",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.85250000",
    longitude: "-95.53582000",
  },
  {
    id: 118394,
    name: "Highland",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.85972000",
    longitude: "-95.26970000",
  },
  {
    id: 118434,
    name: "Hill City",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.36473000",
    longitude: "-99.84206000",
  },
  {
    id: 118445,
    name: "Hillsboro",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.35196000",
    longitude: "-97.20447000",
  },
  {
    id: 118507,
    name: "Hodgeman County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.08748000",
    longitude: "-99.89794000",
  },
  {
    id: 118513,
    name: "Hoisington",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.51807000",
    longitude: "-98.77814000",
  },
  {
    id: 118521,
    name: "Holcomb",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.98614000",
    longitude: "-100.98933000",
  },
  {
    id: 118578,
    name: "Holton",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.46527000",
    longitude: "-95.73637000",
  },
  {
    id: 118678,
    name: "Horton",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.66056000",
    longitude: "-95.52637000",
  },
  {
    id: 118707,
    name: "Howard",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.47031000",
    longitude: "-96.26361000",
  },
  {
    id: 118727,
    name: "Hoxie",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.35750000",
    longitude: "-100.44181000",
  },
  {
    id: 118768,
    name: "Hugoton",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.17530000",
    longitude: "-101.34960000",
  },
  {
    id: 118775,
    name: "Humboldt",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.81060000",
    longitude: "-95.43693000",
  },
  {
    id: 118835,
    name: "Hutchinson",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.06084000",
    longitude: "-97.92977000",
  },
  {
    id: 118889,
    name: "Independence",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.22424000",
    longitude: "-95.70831000",
  },
  {
    id: 118944,
    name: "Inman",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.23195000",
    longitude: "-97.77338000",
  },
  {
    id: 118966,
    name: "Iola",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.92448000",
    longitude: "-95.39998000",
  },
  {
    id: 119081,
    name: "Jackson County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.41682000",
    longitude: "-95.79366000",
  },
  {
    id: 119181,
    name: "Jefferson County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.23576000",
    longitude: "-95.38345000",
  },
  {
    id: 119241,
    name: "Jetmore",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.08446000",
    longitude: "-99.89346000",
  },
  {
    id: 119243,
    name: "Jewell County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.78473000",
    longitude: "-98.21834000",
  },
  {
    id: 119256,
    name: "Johnson",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.57057000",
    longitude: "-101.75100000",
  },
  {
    id: 119264,
    name: "Johnson County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.88376000",
    longitude: "-94.82226000",
  },
  {
    id: 119334,
    name: "Junction City",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.02861000",
    longitude: "-96.83140000",
  },
  {
    id: 119383,
    name: "Kansas City",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.11417000",
    longitude: "-94.62746000",
  },
  {
    id: 119414,
    name: "Kearny County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.00020000",
    longitude: "-101.31986000",
  },
  {
    id: 119416,
    name: "Kechi",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.79585000",
    longitude: "-97.27949000",
  },
  {
    id: 119594,
    name: "Kingman",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.64585000",
    longitude: "-98.11367000",
  },
  {
    id: 119596,
    name: "Kingman County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.55888000",
    longitude: "-98.13633000",
  },
  {
    id: 119642,
    name: "Kinsley",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.92307000",
    longitude: "-99.40984000",
  },
  {
    id: 119644,
    name: "Kiowa",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.01725000",
    longitude: "-98.48535000",
  },
  {
    id: 119646,
    name: "Kiowa County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.55826000",
    longitude: "-99.28605000",
  },
  {
    id: 119743,
    name: "La Crosse",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.53140000",
    longitude: "-99.30872000",
  },
  {
    id: 119746,
    name: "La Cygne",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.35002000",
    longitude: "-94.76135000",
  },
  {
    id: 119809,
    name: "Labette County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.19133000",
    longitude: "-95.29758000",
  },
  {
    id: 120056,
    name: "Lakin",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.94058000",
    longitude: "-101.25489000",
  },
  {
    id: 120105,
    name: "Lane County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.48130000",
    longitude: "-100.46640000",
  },
  {
    id: 120125,
    name: "Lansing",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.24861000",
    longitude: "-94.90024000",
  },
  {
    id: 120150,
    name: "Larned",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.18057000",
    longitude: "-99.09871000",
  },
  {
    id: 120226,
    name: "Lawrence",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.97167000",
    longitude: "-95.23525000",
  },
  {
    id: 120280,
    name: "Leavenworth",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.31111000",
    longitude: "-94.92246000",
  },
  {
    id: 120282,
    name: "Leavenworth County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.19932000",
    longitude: "-95.03790000",
  },
  {
    id: 120283,
    name: "Leawood",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.96667000",
    longitude: "-94.61690000",
  },
  {
    id: 120369,
    name: "Lenexa",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.95362000",
    longitude: "-94.73357000",
  },
  {
    id: 120393,
    name: "Leoti",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.47974000",
    longitude: "-101.35877000",
  },
  {
    id: 120458,
    name: "Liberal",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.04308000",
    longitude: "-100.92100000",
  },
  {
    id: 120501,
    name: "Lincoln",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.04084000",
    longitude: "-98.14477000",
  },
  {
    id: 120518,
    name: "Lincoln County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.04533000",
    longitude: "-98.20770000",
  },
  {
    id: 120575,
    name: "Lindsborg",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.57362000",
    longitude: "-97.67448000",
  },
  {
    id: 120581,
    name: "Linn County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.21227000",
    longitude: "-94.84293000",
  },
  {
    id: 120702,
    name: "Logan County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.91734000",
    longitude: "-101.14839000",
  },
  {
    id: 120808,
    name: "Louisburg",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.61946000",
    longitude: "-94.68079000",
  },
  {
    id: 120922,
    name: "Lyndon",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.61001000",
    longitude: "-95.68443000",
  },
  {
    id: 120935,
    name: "Lyon County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.45619000",
    longitude: "-96.15264000",
  },
  {
    id: 120941,
    name: "Lyons",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.34501000",
    longitude: "-98.20173000",
  },
  {
    id: 121052,
    name: "Maize",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.77918000",
    longitude: "-97.46727000",
  },
  {
    id: 121113,
    name: "Manhattan",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.18361000",
    longitude: "-96.57167000",
  },
  {
    id: 121129,
    name: "Mankato",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.78723000",
    longitude: "-98.21005000",
  },
  {
    id: 121234,
    name: "Marion",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.34835000",
    longitude: "-97.01725000",
  },
  {
    id: 121253,
    name: "Marion County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.35887000",
    longitude: "-97.09689000",
  },
  {
    id: 121311,
    name: "Marshall County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.78362000",
    longitude: "-96.52294000",
  },
  {
    id: 121357,
    name: "Marysville",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.84111000",
    longitude: "-96.64724000",
  },
  {
    id: 121476,
    name: "McConnell AFB",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.63007000",
    longitude: "-97.25869000",
  },
  {
    id: 121545,
    name: "McPherson",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.37084000",
    longitude: "-97.66421000",
  },
  {
    id: 121546,
    name: "McPherson County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.39167000",
    longitude: "-97.64808000",
  },
  {
    id: 121555,
    name: "Meade",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.28558000",
    longitude: "-100.34015000",
  },
  {
    id: 121557,
    name: "Meade County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.23820000",
    longitude: "-100.36618000",
  },
  {
    id: 121601,
    name: "Medicine Lodge",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.28113000",
    longitude: "-98.58036000",
  },
  {
    id: 121702,
    name: "Merriam",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.02362000",
    longitude: "-94.69357000",
  },
  {
    id: 121751,
    name: "Miami County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.56358000",
    longitude: "-94.83806000",
  },
  {
    id: 121950,
    name: "Minneapolis",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.12194000",
    longitude: "-97.70670000",
  },
  {
    id: 121979,
    name: "Mission",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.02778000",
    longitude: "-94.65579000",
  },
  {
    id: 121986,
    name: "Mission Hills",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.01778000",
    longitude: "-94.61690000",
  },
  {
    id: 122000,
    name: "Mitchell County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.39327000",
    longitude: "-98.20936000",
  },
  {
    id: 122143,
    name: "Montgomery County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.19252000",
    longitude: "-95.74288000",
  },
  {
    id: 122269,
    name: "Morris County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.68743000",
    longitude: "-96.64985000",
  },
  {
    id: 122296,
    name: "Morton County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.19140000",
    longitude: "-101.79925000",
  },
  {
    id: 122321,
    name: "Mound City",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.14281000",
    longitude: "-94.81357000",
  },
  {
    id: 122324,
    name: "Moundridge",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.20307000",
    longitude: "-97.51921000",
  },
  {
    id: 122464,
    name: "Mulvane",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.47446000",
    longitude: "-97.24393000",
  },
  {
    id: 122629,
    name: "Nemaha County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.78345000",
    longitude: "-96.01410000",
  },
  {
    id: 122631,
    name: "Neodesha",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.41839000",
    longitude: "-95.68026000",
  },
  {
    id: 122634,
    name: "Neosho County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.55849000",
    longitude: "-95.30679000",
  },
  {
    id: 122643,
    name: "Ness City",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.45279000",
    longitude: "-99.90651000",
  },
  {
    id: 122644,
    name: "Ness County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.47942000",
    longitude: "-99.91618000",
  },
  {
    id: 122692,
    name: "New Century, KS",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.82253000",
    longitude: "-94.89971000",
  },
  {
    id: 122858,
    name: "Newton",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.04668000",
    longitude: "-97.34504000",
  },
  {
    id: 122894,
    name: "Nickerson",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.14723000",
    longitude: "-98.08367000",
  },
  {
    id: 123072,
    name: "North Newton",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.07223000",
    longitude: "-97.34559000",
  },
  {
    id: 123182,
    name: "Norton",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.83389000",
    longitude: "-99.89151000",
  },
  {
    id: 123186,
    name: "Norton County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.78439000",
    longitude: "-99.90348000",
  },
  {
    id: 123299,
    name: "Oakley",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.13334000",
    longitude: "-100.86376000",
  },
  {
    id: 123316,
    name: "Oberlin",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.81834000",
    longitude: "-100.52820000",
  },
  {
    id: 123366,
    name: "Ogden",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.11111000",
    longitude: "-96.70612000",
  },
  {
    id: 123412,
    name: "Olathe",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.88140000",
    longitude: "-94.81913000",
  },
  {
    id: 123588,
    name: "Osage City",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.63390000",
    longitude: "-95.82582000",
  },
  {
    id: 123589,
    name: "Osage County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.65233000",
    longitude: "-95.72695000",
  },
  {
    id: 123593,
    name: "Osawatomie",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.49724000",
    longitude: "-94.95052000",
  },
  {
    id: 123594,
    name: "Osborne",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.43914000",
    longitude: "-98.69624000",
  },
  {
    id: 123595,
    name: "Osborne County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.35033000",
    longitude: "-98.76799000",
  },
  {
    id: 123611,
    name: "Oskaloosa",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.21528000",
    longitude: "-95.31275000",
  },
  {
    id: 123621,
    name: "Oswego",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.16757000",
    longitude: "-95.10996000",
  },
  {
    id: 123636,
    name: "Ottawa",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.61557000",
    longitude: "-95.26775000",
  },
  {
    id: 123638,
    name: "Ottawa County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.13254000",
    longitude: "-97.65022000",
  },
  {
    id: 123649,
    name: "Overbrook",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.78056000",
    longitude: "-95.55720000",
  },
  {
    id: 123651,
    name: "Overland Park",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.98223000",
    longitude: "-94.67079000",
  },
  {
    id: 123677,
    name: "Oxford",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.27419000",
    longitude: "-97.16893000",
  },
  {
    id: 123810,
    name: "Paola",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.57224000",
    longitude: "-94.87913000",
  },
  {
    id: 123837,
    name: "Park City",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.80001000",
    longitude: "-97.31838000",
  },
  {
    id: 123891,
    name: "Parsons",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.34034000",
    longitude: "-95.26108000",
  },
  {
    id: 123936,
    name: "Pawnee County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.18133000",
    longitude: "-99.23673000",
  },
  {
    id: 123953,
    name: "Peabody",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.16946000",
    longitude: "-97.10670000",
  },
  {
    id: 124136,
    name: "Phillips County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.78458000",
    longitude: "-99.34702000",
  },
  {
    id: 124139,
    name: "Phillipsburg",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.75612000",
    longitude: "-99.32399000",
  },
  {
    id: 124292,
    name: "Pittsburg",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.41088000",
    longitude: "-94.70496000",
  },
  {
    id: 124325,
    name: "Plains",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.26030000",
    longitude: "-100.59265000",
  },
  {
    id: 124333,
    name: "Plainville",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.23473000",
    longitude: "-99.29816000",
  },
  {
    id: 124382,
    name: "Pleasanton",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.17781000",
    longitude: "-94.71135000",
  },
  {
    id: 124589,
    name: "Pottawatomie County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.37901000",
    longitude: "-96.34244000",
  },
  {
    id: 124632,
    name: "Prairie Village",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.99167000",
    longitude: "-94.63357000",
  },
  {
    id: 124636,
    name: "Pratt",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.64391000",
    longitude: "-98.73759000",
  },
  {
    id: 124637,
    name: "Pratt County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.64774000",
    longitude: "-98.73960000",
  },
  {
    id: 124913,
    name: "Rawlins County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.78524000",
    longitude: "-101.07571000",
  },
  {
    id: 125021,
    name: "Reno County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.95295000",
    longitude: "-98.08601000",
  },
  {
    id: 125033,
    name: "Republic County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.82780000",
    longitude: "-97.65062000",
  },
  {
    id: 125053,
    name: "Rice County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.34714000",
    longitude: "-98.20103000",
  },
  {
    id: 125138,
    name: "Riley County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.29646000",
    longitude: "-96.73518000",
  },
  {
    id: 125345,
    name: "Roeland Park",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.03751000",
    longitude: "-94.63218000",
  },
  {
    id: 125390,
    name: "Rooks County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.35023000",
    longitude: "-99.32505000",
  },
  {
    id: 125403,
    name: "Rose Hill",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.55835000",
    longitude: "-97.13504000",
  },
  {
    id: 125457,
    name: "Rossville",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.13611000",
    longitude: "-95.95166000",
  },
  {
    id: 125521,
    name: "Rush County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.52316000",
    longitude: "-99.30924000",
  },
  {
    id: 125533,
    name: "Russell",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.89527000",
    longitude: "-98.86103000",
  },
  {
    id: 125537,
    name: "Russell County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.91478000",
    longitude: "-98.76235000",
  },
  {
    id: 125567,
    name: "Sabetha",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.90222000",
    longitude: "-95.80082000",
  },
  {
    id: 125635,
    name: "Saint Francis",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.77222000",
    longitude: "-101.79990000",
  },
  {
    id: 125660,
    name: "Saint John",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.00224000",
    longitude: "-98.76009000",
  },
  {
    id: 125692,
    name: "Saint Marys",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.19416000",
    longitude: "-96.07110000",
  },
  {
    id: 125744,
    name: "Salina",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.84028000",
    longitude: "-97.61142000",
  },
  {
    id: 125751,
    name: "Saline County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.78381000",
    longitude: "-97.64993000",
  },
  {
    id: 125954,
    name: "Satanta",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.43725000",
    longitude: "-100.97211000",
  },
  {
    id: 126042,
    name: "Scott City",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.48252000",
    longitude: "-100.90709000",
  },
  {
    id: 126053,
    name: "Scott County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.48217000",
    longitude: "-100.90686000",
  },
  {
    id: 126115,
    name: "Sedan",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.12672000",
    longitude: "-96.18694000",
  },
  {
    id: 126117,
    name: "Sedgwick",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.91668000",
    longitude: "-97.42254000",
  },
  {
    id: 126119,
    name: "Sedgwick County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.68476000",
    longitude: "-97.46097000",
  },
  {
    id: 126154,
    name: "Seneca",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.83416000",
    longitude: "-96.06417000",
  },
  {
    id: 126189,
    name: "Seward County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.19330000",
    longitude: "-100.85129000",
  },
  {
    id: 126232,
    name: "Sharon Springs",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.89779000",
    longitude: "-101.75212000",
  },
  {
    id: 126249,
    name: "Shawnee",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.04167000",
    longitude: "-94.72024000",
  },
  {
    id: 126251,
    name: "Shawnee County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.04151000",
    longitude: "-95.75653000",
  },
  {
    id: 126324,
    name: "Sheridan County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.35036000",
    longitude: "-100.44183000",
  },
  {
    id: 126333,
    name: "Sherman County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.35143000",
    longitude: "-101.71998000",
  },
  {
    id: 126428,
    name: "Silver Lake",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.10417000",
    longitude: "-95.85860000",
  },
  {
    id: 126511,
    name: "Smith Center",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.77918000",
    longitude: "-98.78507000",
  },
  {
    id: 126512,
    name: "Smith County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.78516000",
    longitude: "-98.78547000",
  },
  {
    id: 126569,
    name: "Solomon",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.91944000",
    longitude: "-97.37114000",
  },
  {
    id: 126678,
    name: "South Hutchinson",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.02807000",
    longitude: "-97.94033000",
  },
  {
    id: 126861,
    name: "Spring Hill",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.74306000",
    longitude: "-94.82552000",
  },
  {
    id: 126936,
    name: "Stafford County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.03100000",
    longitude: "-98.71744000",
  },
  {
    id: 126973,
    name: "Stanton County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.56306000",
    longitude: "-101.78418000",
  },
  {
    id: 127026,
    name: "Sterling",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.21001000",
    longitude: "-98.20701000",
  },
  {
    id: 127041,
    name: "Stevens County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.19232000",
    longitude: "-101.31205000",
  },
  {
    id: 127071,
    name: "Stockton",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.43807000",
    longitude: "-99.26510000",
  },
  {
    id: 127157,
    name: "Sublette",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.48169000",
    longitude: "-100.84377000",
  },
  {
    id: 127224,
    name: "Sumner County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.23728000",
    longitude: "-97.47655000",
  },
  {
    id: 127366,
    name: "Syracuse",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.98071000",
    longitude: "-101.75430000",
  },
  {
    id: 127580,
    name: "Thomas County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.35097000",
    longitude: "-101.05553000",
  },
  {
    id: 127719,
    name: "Tonganoxie",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.10972000",
    longitude: "-95.08775000",
  },
  {
    id: 127732,
    name: "Topeka",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.04833000",
    longitude: "-95.67804000",
  },
  {
    id: 127751,
    name: "Towanda",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.79752000",
    longitude: "-96.99976000",
  },
  {
    id: 127792,
    name: "Trego County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.91432000",
    longitude: "-99.87274000",
  },
  {
    id: 127820,
    name: "Tribune",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.46974000",
    longitude: "-101.75267000",
  },
  {
    id: 127842,
    name: "Troy",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.78305000",
    longitude: "-95.08997000",
  },
  {
    id: 127960,
    name: "Ulysses",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.58141000",
    longitude: "-101.35517000",
  },
  {
    id: 128099,
    name: "Valley Center",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.83473000",
    longitude: "-97.37338000",
  },
  {
    id: 128106,
    name: "Valley Falls",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.34333000",
    longitude: "-95.46025000",
  },
  {
    id: 128219,
    name: "Victoria",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.85279000",
    longitude: "-99.14760000",
  },
  {
    id: 128315,
    name: "Wabaunsee County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.95328000",
    longitude: "-96.20499000",
  },
  {
    id: 128308,
    name: "WaKeeney",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.02501000",
    longitude: "-99.87957000",
  },
  {
    id: 128399,
    name: "Wallace County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.91666000",
    longitude: "-101.76357000",
  },
  {
    id: 128444,
    name: "Wamego",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.20194000",
    longitude: "-96.30500000",
  },
  {
    id: 128537,
    name: "Washington",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.81806000",
    longitude: "-97.05086000",
  },
  {
    id: 128558,
    name: "Washington County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.78420000",
    longitude: "-97.08754000",
  },
  {
    id: 128633,
    name: "Wathena",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.75916000",
    longitude: "-94.94969000",
  },
  {
    id: 128777,
    name: "Wellington",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.26530000",
    longitude: "-97.37171000",
  },
  {
    id: 128791,
    name: "Wellsville",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.71834000",
    longitude: "-95.08164000",
  },
  {
    id: 129052,
    name: "Westmoreland",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.39389000",
    longitude: "-96.41361000",
  },
  {
    id: 129082,
    name: "Westwood",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.04056000",
    longitude: "-94.61690000",
  },
  {
    id: 129215,
    name: "Wichita",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.69224000",
    longitude: "-97.33754000",
  },
  {
    id: 129217,
    name: "Wichita County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "38.48208000",
    longitude: "-101.34736000",
  },
  {
    id: 129334,
    name: "Wilson County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.55924000",
    longitude: "-95.74339000",
  },
  {
    id: 129393,
    name: "Winfield",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.23975000",
    longitude: "-96.99559000",
  },
  {
    id: 129558,
    name: "Woodson County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.88667000",
    longitude: "-95.74017000",
  },
  {
    id: 129622,
    name: "Wyandotte County",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "39.11465000",
    longitude: "-94.76448000",
  },
  {
    id: 129670,
    name: "Yates Center",
    state_id: 1406,
    state_code: "KS",
    country_id: 233,
    country_code: "US",
    latitude: "37.88115000",
    longitude: "-95.73332000",
  },
  {
    id: 111008,
    name: "Adair County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.10416000",
    longitude: "-85.28065000",
  },
  {
    id: 111102,
    name: "Albany",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.69090000",
    longitude: "-85.13468000",
  },
  {
    id: 111149,
    name: "Alexandria",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.95951000",
    longitude: "-84.38799000",
  },
  {
    id: 111189,
    name: "Allen County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.75132000",
    longitude: "-86.19042000",
  },
  {
    id: 111304,
    name: "Anchorage",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.26674000",
    longitude: "-85.53302000",
  },
  {
    id: 111316,
    name: "Anderson County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.00391000",
    longitude: "-84.99101000",
  },
  {
    id: 111356,
    name: "Annville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.31925000",
    longitude: "-83.97048000",
  },
  {
    id: 111526,
    name: "Ashland",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.47841000",
    longitude: "-82.63794000",
  },
  {
    id: 111620,
    name: "Auburn",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.86421000",
    longitude: "-86.71027000",
  },
  {
    id: 111642,
    name: "Audubon Park",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.20396000",
    longitude: "-85.72524000",
  },
  {
    id: 111648,
    name: "Augusta",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.77174000",
    longitude: "-84.00576000",
  },
  {
    id: 111771,
    name: "Ballard County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.05843000",
    longitude: "-88.99934000",
  },
  {
    id: 111816,
    name: "Barbourmeade",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.29729000",
    longitude: "-85.60329000",
  },
  {
    id: 111818,
    name: "Barbourville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.86648000",
    longitude: "-83.88881000",
  },
  {
    id: 111820,
    name: "Bardstown",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.80923000",
    longitude: "-85.46690000",
  },
  {
    id: 111821,
    name: "Bardwell",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.87061000",
    longitude: "-89.00979000",
  },
  {
    id: 111843,
    name: "Barren County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.96558000",
    longitude: "-85.93366000",
  },
  {
    id: 111901,
    name: "Bath County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.14497000",
    longitude: "-83.74267000",
  },
  {
    id: 111984,
    name: "Beattyville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.57175000",
    longitude: "-83.70686000",
  },
  {
    id: 112000,
    name: "Beaver Dam",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.40199000",
    longitude: "-86.87583000",
  },
  {
    id: 112019,
    name: "Bedford",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.59256000",
    longitude: "-85.31773000",
  },
  {
    id: 112043,
    name: "Beechwood Village",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.25479000",
    longitude: "-85.63135000",
  },
  {
    id: 112068,
    name: "Bell County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.73065000",
    longitude: "-83.67409000",
  },
  {
    id: 112109,
    name: "Bellevue",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "39.10645000",
    longitude: "-84.47883000",
  },
  {
    id: 112192,
    name: "Benton",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.85728000",
    longitude: "-88.35031000",
  },
  {
    id: 112213,
    name: "Berea",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.56869000",
    longitude: "-84.29632000",
  },
  {
    id: 112433,
    name: "Bloomfield",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.91034000",
    longitude: "-85.31662000",
  },
  {
    id: 112554,
    name: "Boone County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.96986000",
    longitude: "-84.72787000",
  },
  {
    id: 112563,
    name: "Booneville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.47620000",
    longitude: "-83.67491000",
  },
  {
    id: 112614,
    name: "Bourbon County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.20673000",
    longitude: "-84.21715000",
  },
  {
    id: 112628,
    name: "Bowling Green",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.99032000",
    longitude: "-86.44360000",
  },
  {
    id: 112642,
    name: "Boyd County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.35957000",
    longitude: "-82.68773000",
  },
  {
    id: 112648,
    name: "Boyle County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.62433000",
    longitude: "-84.86681000",
  },
  {
    id: 112655,
    name: "Bracken County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.68881000",
    longitude: "-84.09019000",
  },
  {
    id: 112686,
    name: "Brandenburg",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.99896000",
    longitude: "-86.16941000",
  },
  {
    id: 112708,
    name: "Breathitt County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.52162000",
    longitude: "-83.32409000",
  },
  {
    id: 112715,
    name: "Breckinridge Center",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.68282000",
    longitude: "-87.86308000",
  },
  {
    id: 112716,
    name: "Breckinridge County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.77327000",
    longitude: "-86.42928000",
  },
  {
    id: 112837,
    name: "Brodhead",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.40425000",
    longitude: "-84.41383000",
  },
  {
    id: 112881,
    name: "Brooks",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.06118000",
    longitude: "-85.70968000",
  },
  {
    id: 112891,
    name: "Brooksville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.68257000",
    longitude: "-84.06576000",
  },
  {
    id: 112929,
    name: "Brownsville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.19255000",
    longitude: "-86.26775000",
  },
  {
    id: 112989,
    name: "Buckner",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.38368000",
    longitude: "-85.43996000",
  },
  {
    id: 112996,
    name: "Buechel",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.19507000",
    longitude: "-85.65190000",
  },
  {
    id: 113032,
    name: "Bullitt County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.97008000",
    longitude: "-85.69586000",
  },
  {
    id: 113059,
    name: "Burkesville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.79034000",
    longitude: "-85.37052000",
  },
  {
    id: 113067,
    name: "Burlington",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "39.02756000",
    longitude: "-84.72411000",
  },
  {
    id: 113116,
    name: "Butler County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.20728000",
    longitude: "-86.68176000",
  },
  {
    id: 113165,
    name: "Cadiz",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.86505000",
    longitude: "-87.83530000",
  },
  {
    id: 113183,
    name: "Caldwell County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.14533000",
    longitude: "-87.86791000",
  },
  {
    id: 113198,
    name: "Calhoun",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.53894000",
    longitude: "-87.25833000",
  },
  {
    id: 113227,
    name: "Calloway County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.62110000",
    longitude: "-88.27220000",
  },
  {
    id: 113234,
    name: "Calvert City",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.03339000",
    longitude: "-88.35004000",
  },
  {
    id: 113241,
    name: "Camargo",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.99425000",
    longitude: "-83.88770000",
  },
  {
    id: 113300,
    name: "Campbell County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.94648000",
    longitude: "-84.37970000",
  },
  {
    id: 113305,
    name: "Campbellsville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.34340000",
    longitude: "-85.34191000",
  },
  {
    id: 113310,
    name: "Campton",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.73425000",
    longitude: "-83.54741000",
  },
  {
    id: 113402,
    name: "Carlisle",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.31202000",
    longitude: "-84.02743000",
  },
  {
    id: 113406,
    name: "Carlisle County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.85322000",
    longitude: "-88.97106000",
  },
  {
    id: 113452,
    name: "Carroll County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.66786000",
    longitude: "-85.12359000",
  },
  {
    id: 113466,
    name: "Carrollton",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.68090000",
    longitude: "-85.17940000",
  },
  {
    id: 113480,
    name: "Carter County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.31817000",
    longitude: "-83.04954000",
  },
  {
    id: 113520,
    name: "Casey County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.32228000",
    longitude: "-84.92837000",
  },
  {
    id: 113572,
    name: "Catlettsburg",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.40480000",
    longitude: "-82.60044000",
  },
  {
    id: 113584,
    name: "Cave City",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.13672000",
    longitude: "-85.95692000",
  },
  {
    id: 113666,
    name: "Central City",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.29393000",
    longitude: "-87.12333000",
  },
  {
    id: 113996,
    name: "Christian County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.89418000",
    longitude: "-87.49038000",
  },
  {
    id: 114125,
    name: "Clark County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.97085000",
    longitude: "-84.14740000",
  },
  {
    id: 114159,
    name: "Claryville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.91923000",
    longitude: "-84.39549000",
  },
  {
    id: 114166,
    name: "Clay",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.47671000",
    longitude: "-87.82002000",
  },
  {
    id: 114172,
    name: "Clay City",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.85925000",
    longitude: "-83.91853000",
  },
  {
    id: 114180,
    name: "Clay County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.15971000",
    longitude: "-83.71468000",
  },
  {
    id: 114269,
    name: "Clinton",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.66728000",
    longitude: "-88.99340000",
  },
  {
    id: 114290,
    name: "Clinton County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.72748000",
    longitude: "-85.13601000",
  },
  {
    id: 114314,
    name: "Cloverport",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.83339000",
    longitude: "-86.63276000",
  },
  {
    id: 114332,
    name: "Coal Run Village",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.51316000",
    longitude: "-82.55849000",
  },
  {
    id: 114385,
    name: "Cold Spring",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "39.02173000",
    longitude: "-84.43994000",
  },
  {
    id: 114391,
    name: "Coldstream",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.31479000",
    longitude: "-85.52385000",
  },
  {
    id: 114464,
    name: "Columbia",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.10284000",
    longitude: "-85.30635000",
  },
  {
    id: 114631,
    name: "Corbin",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.94870000",
    longitude: "-84.09688000",
  },
  {
    id: 114736,
    name: "Covington",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "39.08367000",
    longitude: "-84.50855000",
  },
  {
    id: 114797,
    name: "Crescent Springs",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "39.05145000",
    longitude: "-84.58161000",
  },
  {
    id: 114808,
    name: "Crestview Hills",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "39.02728000",
    longitude: "-84.58494000",
  },
  {
    id: 114809,
    name: "Crestwood",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.32424000",
    longitude: "-85.47246000",
  },
  {
    id: 114824,
    name: "Crittenden",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.78284000",
    longitude: "-84.60522000",
  },
  {
    id: 114826,
    name: "Crittenden County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.35272000",
    longitude: "-88.09722000",
  },
  {
    id: 114906,
    name: "Cumberland",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.97815000",
    longitude: "-82.98850000",
  },
  {
    id: 114913,
    name: "Cumberland County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.78653000",
    longitude: "-85.38845000",
  },
  {
    id: 114952,
    name: "Cynthiana",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.39035000",
    longitude: "-84.29410000",
  },
  {
    id: 115033,
    name: "Danville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.64563000",
    longitude: "-84.77217000",
  },
  {
    id: 115070,
    name: "Daviess County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.73177000",
    longitude: "-87.08723000",
  },
  {
    id: 115087,
    name: "Dawson Springs",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.16727000",
    longitude: "-87.69251000",
  },
  {
    id: 115091,
    name: "Dayton",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "39.11284000",
    longitude: "-84.47272000",
  },
  {
    id: 115364,
    name: "Dixon",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.51782000",
    longitude: "-87.69029000",
  },
  {
    id: 115383,
    name: "Doe Valley",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.95665000",
    longitude: "-86.11653000",
  },
  {
    id: 115438,
    name: "Douglass Hills",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.23785000",
    longitude: "-85.55274000",
  },
  {
    id: 115483,
    name: "Dry Ridge",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.68201000",
    longitude: "-84.58994000",
  },
  {
    id: 115619,
    name: "Earlington",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.27421000",
    longitude: "-87.51194000",
  },
  {
    id: 115826,
    name: "Eddyville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.09450000",
    longitude: "-88.08030000",
  },
  {
    id: 115858,
    name: "Edgewood",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "39.01867000",
    longitude: "-84.58189000",
  },
  {
    id: 115879,
    name: "Edmonson County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.20884000",
    longitude: "-86.23862000",
  },
  {
    id: 115881,
    name: "Edmonton",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.98006000",
    longitude: "-85.61219000",
  },
  {
    id: 115978,
    name: "Elizabethtown",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.69395000",
    longitude: "-85.85913000",
  },
  {
    id: 115986,
    name: "Elk Creek",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.10034000",
    longitude: "-85.37107000",
  },
  {
    id: 115996,
    name: "Elkfork",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.96481000",
    longitude: "-83.13295000",
  },
  {
    id: 116012,
    name: "Elkton",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.81004000",
    longitude: "-87.15417000",
  },
  {
    id: 116028,
    name: "Elliott County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.11789000",
    longitude: "-83.09762000",
  },
  {
    id: 116071,
    name: "Elsmere",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "39.01256000",
    longitude: "-84.60467000",
  },
  {
    id: 116099,
    name: "Eminence",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.37007000",
    longitude: "-85.18051000",
  },
  {
    id: 116172,
    name: "Erlanger",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "39.01673000",
    longitude: "-84.60078000",
  },
  {
    id: 116212,
    name: "Estill County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.69248000",
    longitude: "-83.96433000",
  },
  {
    id: 116299,
    name: "Fairdale",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.10507000",
    longitude: "-85.75885000",
  },
  {
    id: 116386,
    name: "Falmouth",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.67674000",
    longitude: "-84.33021000",
  },
  {
    id: 116400,
    name: "Farley",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.04634000",
    longitude: "-88.56856000",
  },
  {
    id: 116447,
    name: "Fayette County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.04233000",
    longitude: "-84.45873000",
  },
  {
    id: 116482,
    name: "Fern Creek",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.15979000",
    longitude: "-85.58774000",
  },
  {
    id: 116557,
    name: "Flatwoods",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.52258000",
    longitude: "-82.71711000",
  },
  {
    id: 116559,
    name: "Fleming County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.37011000",
    longitude: "-83.69665000",
  },
  {
    id: 116561,
    name: "Flemingsburg",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.42230000",
    longitude: "-83.73381000",
  },
  {
    id: 116578,
    name: "Florence",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.99895000",
    longitude: "-84.62661000",
  },
  {
    id: 116608,
    name: "Floyd County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.55711000",
    longitude: "-82.74570000",
  },
  {
    id: 116698,
    name: "Fort Campbell North",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.65429000",
    longitude: "-87.46056000",
  },
  {
    id: 116721,
    name: "Fort Knox",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.89113000",
    longitude: "-85.96363000",
  },
  {
    id: 116731,
    name: "Fort Mitchell",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "39.05950000",
    longitude: "-84.54744000",
  },
  {
    id: 116754,
    name: "Fort Thomas",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "39.07506000",
    longitude: "-84.44716000",
  },
  {
    id: 116765,
    name: "Fort Wright",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "39.05173000",
    longitude: "-84.53411000",
  },
  {
    id: 116815,
    name: "Francisville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "39.10506000",
    longitude: "-84.72439000",
  },
  {
    id: 116818,
    name: "Frankfort",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.20091000",
    longitude: "-84.87328000",
  },
  {
    id: 116825,
    name: "Franklin",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.72226000",
    longitude: "-86.57722000",
  },
  {
    id: 116850,
    name: "Franklin County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.23915000",
    longitude: "-84.87707000",
  },
  {
    id: 116933,
    name: "Frenchburg",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.95092000",
    longitude: "-83.62575000",
  },
  {
    id: 116985,
    name: "Fulton",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.50423000",
    longitude: "-88.87423000",
  },
  {
    id: 116994,
    name: "Fulton County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.55408000",
    longitude: "-89.18761000",
  },
  {
    id: 117035,
    name: "Gallatin County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.75687000",
    longitude: "-84.85931000",
  },
  {
    id: 117096,
    name: "Garrard County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.63958000",
    longitude: "-84.53763000",
  },
  {
    id: 117157,
    name: "Georgetown",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.20980000",
    longitude: "-84.55883000",
  },
  {
    id: 117246,
    name: "Glasgow",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.99588000",
    longitude: "-85.91192000",
  },
  {
    id: 117509,
    name: "Grant County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.64881000",
    longitude: "-84.62461000",
  },
  {
    id: 117547,
    name: "Graves County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.72314000",
    longitude: "-88.65121000",
  },
  {
    id: 117557,
    name: "Graymoor-Devondale",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.27313000",
    longitude: "-85.62302000",
  },
  {
    id: 117561,
    name: "Grayson",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.33258000",
    longitude: "-82.94850000",
  },
  {
    id: 117562,
    name: "Grayson County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.46082000",
    longitude: "-86.34388000",
  },
  {
    id: 117592,
    name: "Green County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.26411000",
    longitude: "-85.55311000",
  },
  {
    id: 117668,
    name: "Greensburg",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.26089000",
    longitude: "-85.49885000",
  },
  {
    id: 117677,
    name: "Greenup",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.57313000",
    longitude: "-82.83017000",
  },
  {
    id: 117678,
    name: "Greenup County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.54566000",
    longitude: "-82.92229000",
  },
  {
    id: 117682,
    name: "Greenville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.20115000",
    longitude: "-87.17889000",
  },
  {
    id: 117811,
    name: "Guthrie",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.64838000",
    longitude: "-87.16639000",
  },
  {
    id: 117952,
    name: "Hancock County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.84151000",
    longitude: "-86.77793000",
  },
  {
    id: 118000,
    name: "Hardin County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.69792000",
    longitude: "-85.96337000",
  },
  {
    id: 118006,
    name: "Hardinsburg",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.78006000",
    longitude: "-86.46053000",
  },
  {
    id: 118015,
    name: "Harlan",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.84314000",
    longitude: "-83.32185000",
  },
  {
    id: 118018,
    name: "Harlan County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.85697000",
    longitude: "-83.21795000",
  },
  {
    id: 118063,
    name: "Harrison County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.44181000",
    longitude: "-84.33139000",
  },
  {
    id: 118078,
    name: "Harrodsburg",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.76230000",
    longitude: "-84.84329000",
  },
  {
    id: 118083,
    name: "Hart County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.29993000",
    longitude: "-85.88471000",
  },
  {
    id: 118086,
    name: "Hartford",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.45116000",
    longitude: "-86.90916000",
  },
  {
    id: 118164,
    name: "Hawesville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.90006000",
    longitude: "-86.75499000",
  },
  {
    id: 118200,
    name: "Hazard",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.24954000",
    longitude: "-83.19323000",
  },
  {
    id: 118232,
    name: "Hebron",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "39.06589000",
    longitude: "-84.70106000",
  },
  {
    id: 118240,
    name: "Hebron Estates",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.04951000",
    longitude: "-85.66607000",
  },
  {
    id: 118274,
    name: "Henderson",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.83615000",
    longitude: "-87.59001000",
  },
  {
    id: 118280,
    name: "Henderson County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.79590000",
    longitude: "-87.57316000",
  },
  {
    id: 118288,
    name: "Hendron",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.03950000",
    longitude: "-88.62922000",
  },
  {
    id: 118300,
    name: "Henry County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.44847000",
    longitude: "-85.11893000",
  },
  {
    id: 118317,
    name: "Heritage Creek",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.12368000",
    longitude: "-85.71968000",
  },
  {
    id: 118361,
    name: "Hickman",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.57117000",
    longitude: "-89.18618000",
  },
  {
    id: 118363,
    name: "Hickman County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.67817000",
    longitude: "-88.97622000",
  },
  {
    id: 118406,
    name: "Highland Heights",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "39.03312000",
    longitude: "-84.45189000",
  },
  {
    id: 118425,
    name: "Highview",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.14285000",
    longitude: "-85.62413000",
  },
  {
    id: 118470,
    name: "Hillview",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.06979000",
    longitude: "-85.68551000",
  },
  {
    id: 118478,
    name: "Hindman",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.33593000",
    longitude: "-82.98044000",
  },
  {
    id: 118508,
    name: "Hodgenville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.57395000",
    longitude: "-85.73996000",
  },
  {
    id: 118653,
    name: "Hopkins County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.30882000",
    longitude: "-87.54084000",
  },
  {
    id: 118655,
    name: "Hopkinsville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.86561000",
    longitude: "-87.49117000",
  },
  {
    id: 118670,
    name: "Horse Cave",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.17950000",
    longitude: "-85.90692000",
  },
  {
    id: 118830,
    name: "Hurstbourne",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.23813000",
    longitude: "-85.58829000",
  },
  {
    id: 118831,
    name: "Hurstbourne Acres",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.22118000",
    longitude: "-85.58913000",
  },
  {
    id: 118854,
    name: "Hyden",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.16093000",
    longitude: "-83.37324000",
  },
  {
    id: 118890,
    name: "Independence",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.94312000",
    longitude: "-84.54411000",
  },
  {
    id: 118906,
    name: "Indian Hills",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.27257000",
    longitude: "-85.66274000",
  },
  {
    id: 118910,
    name: "Indian Hills Cherokee Section",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.27951000",
    longitude: "-85.64996000",
  },
  {
    id: 118931,
    name: "Inez",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.86648000",
    longitude: "-82.53876000",
  },
  {
    id: 118998,
    name: "Ironville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.45647000",
    longitude: "-82.69238000",
  },
  {
    id: 119003,
    name: "Irvine",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.70064000",
    longitude: "-83.97381000",
  },
  {
    id: 119007,
    name: "Irvington",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.88034000",
    longitude: "-86.28386000",
  },
  {
    id: 119062,
    name: "Jackson",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.55315000",
    longitude: "-83.38351000",
  },
  {
    id: 119082,
    name: "Jackson County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.41978000",
    longitude: "-84.00577000",
  },
  {
    id: 119120,
    name: "Jamestown",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.98479000",
    longitude: "-85.06301000",
  },
  {
    id: 119182,
    name: "Jefferson County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.18719000",
    longitude: "-85.65916000",
  },
  {
    id: 119205,
    name: "Jeffersontown",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.19424000",
    longitude: "-85.56440000",
  },
  {
    id: 119206,
    name: "Jeffersonville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.97369000",
    longitude: "-83.84186000",
  },
  {
    id: 119214,
    name: "Jenkins",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.17344000",
    longitude: "-82.63099000",
  },
  {
    id: 119236,
    name: "Jessamine County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.87203000",
    longitude: "-84.58093000",
  },
  {
    id: 119265,
    name: "Johnson County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.84664000",
    longitude: "-82.83154000",
  },
  {
    id: 119333,
    name: "Junction City",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.58674000",
    longitude: "-84.79384000",
  },
  {
    id: 119499,
    name: "Kenton County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.93346000",
    longitude: "-84.53334000",
  },
  {
    id: 119680,
    name: "Knott County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.35405000",
    longitude: "-82.95413000",
  },
  {
    id: 119681,
    name: "Knottsville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.77172000",
    longitude: "-86.90416000",
  },
  {
    id: 119686,
    name: "Knox County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.89067000",
    longitude: "-83.85404000",
  },
  {
    id: 119737,
    name: "La Center",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.07672000",
    longitude: "-88.97368000",
  },
  {
    id: 119749,
    name: "La Grange",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.40757000",
    longitude: "-85.37885000",
  },
  {
    id: 120035,
    name: "Lakeside Park",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "39.03562000",
    longitude: "-84.56911000",
  },
  {
    id: 120080,
    name: "Lancaster",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.61952000",
    longitude: "-84.57800000",
  },
  {
    id: 120152,
    name: "Larue County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.54580000",
    longitude: "-85.69792000",
  },
  {
    id: 120195,
    name: "Laurel County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.11067000",
    longitude: "-84.11780000",
  },
  {
    id: 120233,
    name: "Lawrence County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.06788000",
    longitude: "-82.73475000",
  },
  {
    id: 120241,
    name: "Lawrenceburg",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.03730000",
    longitude: "-84.89662000",
  },
  {
    id: 120285,
    name: "Lebanon",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.56979000",
    longitude: "-85.25274000",
  },
  {
    id: 120296,
    name: "Lebanon Junction",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.83451000",
    longitude: "-85.73190000",
  },
  {
    id: 120301,
    name: "Ledbetter",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.04756000",
    longitude: "-88.47699000",
  },
  {
    id: 120310,
    name: "Lee County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.59480000",
    longitude: "-83.71628000",
  },
  {
    id: 120347,
    name: "Leitchfield",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.48005000",
    longitude: "-86.29386000",
  },
  {
    id: 120397,
    name: "Leslie County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.09406000",
    longitude: "-83.38116000",
  },
  {
    id: 120400,
    name: "Letcher County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.12119000",
    longitude: "-82.85528000",
  },
  {
    id: 120411,
    name: "Lewis County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.53154000",
    longitude: "-83.37805000",
  },
  {
    id: 120422,
    name: "Lewisport",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.93700000",
    longitude: "-86.90221000",
  },
  {
    id: 120439,
    name: "Lexington",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.98869000",
    longitude: "-84.47772000",
  },
  {
    id: 120456,
    name: "Lexington-Fayette",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.04980000",
    longitude: "-84.45855000",
  },
  {
    id: 120459,
    name: "Liberty",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.31841000",
    longitude: "-84.93940000",
  },
  {
    id: 120519,
    name: "Lincoln County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.45535000",
    longitude: "-84.66081000",
  },
  {
    id: 120642,
    name: "Livermore",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.49310000",
    longitude: "-87.13194000",
  },
  {
    id: 120654,
    name: "Livingston County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.20970000",
    longitude: "-88.35381000",
  },
  {
    id: 120697,
    name: "Logan County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.85970000",
    longitude: "-86.87894000",
  },
  {
    id: 120721,
    name: "London",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.12898000",
    longitude: "-84.08326000",
  },
  {
    id: 120804,
    name: "Louisa",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.11425000",
    longitude: "-82.60321000",
  },
  {
    id: 120813,
    name: "Louisville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.25424000",
    longitude: "-85.75941000",
  },
  {
    id: 120869,
    name: "Ludlow",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "39.09256000",
    longitude: "-84.54744000",
  },
  {
    id: 120923,
    name: "Lyndon",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.25674000",
    longitude: "-85.60163000",
  },
  {
    id: 120936,
    name: "Lyon County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.01913000",
    longitude: "-88.08328000",
  },
  {
    id: 121007,
    name: "Madison County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.72018000",
    longitude: "-84.27800000",
  },
  {
    id: 121025,
    name: "Madisonville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.32810000",
    longitude: "-87.49889000",
  },
  {
    id: 121039,
    name: "Magoffin County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.70647000",
    longitude: "-83.06491000",
  },
  {
    id: 121091,
    name: "Manchester",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.15370000",
    longitude: "-83.76186000",
  },
  {
    id: 121235,
    name: "Marion",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.33283000",
    longitude: "-88.08113000",
  },
  {
    id: 121254,
    name: "Marion County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.55253000",
    longitude: "-85.26963000",
  },
  {
    id: 121312,
    name: "Marshall County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.88345000",
    longitude: "-88.32938000",
  },
  {
    id: 121339,
    name: "Martin County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.80158000",
    longitude: "-82.51329000",
  },
  {
    id: 121377,
    name: "Mason County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.59517000",
    longitude: "-83.82425000",
  },
  {
    id: 121385,
    name: "Masonville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.67505000",
    longitude: "-87.03472000",
  },
  {
    id: 121387,
    name: "Massac",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.01672000",
    longitude: "-88.73061000",
  },
  {
    id: 121436,
    name: "Mayfield",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.74172000",
    longitude: "-88.63672000",
  },
  {
    id: 121450,
    name: "Maysville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.64119000",
    longitude: "-83.74437000",
  },
  {
    id: 121485,
    name: "McCracken County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.05408000",
    longitude: "-88.71272000",
  },
  {
    id: 121486,
    name: "McCreary County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.73714000",
    longitude: "-84.48417000",
  },
  {
    id: 121517,
    name: "McKee",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.43036000",
    longitude: "-83.99798000",
  },
  {
    id: 121528,
    name: "McLean County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.52919000",
    longitude: "-87.26361000",
  },
  {
    id: 121556,
    name: "Meade County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.96984000",
    longitude: "-86.21718000",
  },
  {
    id: 121572,
    name: "Meads",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.41258000",
    longitude: "-82.70905000",
  },
  {
    id: 121660,
    name: "Menifee County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.94138000",
    longitude: "-83.59887000",
  },
  {
    id: 121675,
    name: "Mercer County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.81103000",
    longitude: "-84.87444000",
  },
  {
    id: 121733,
    name: "Metcalfe County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.99054000",
    longitude: "-85.62925000",
  },
  {
    id: 121776,
    name: "Middlesboro",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.60842000",
    longitude: "-83.71658000",
  },
  {
    id: 121787,
    name: "Middletown",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.24535000",
    longitude: "-85.53885000",
  },
  {
    id: 121814,
    name: "Midway",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.15091000",
    longitude: "-84.68383000",
  },
  {
    id: 122073,
    name: "Monroe County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.71218000",
    longitude: "-85.71652000",
  },
  {
    id: 122144,
    name: "Montgomery County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.03353000",
    longitude: "-83.91310000",
  },
  {
    id: 122162,
    name: "Monticello",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.82979000",
    longitude: "-84.84911000",
  },
  {
    id: 122225,
    name: "Morehead",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.18397000",
    longitude: "-83.43268000",
  },
  {
    id: 122240,
    name: "Morgan County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.92228000",
    longitude: "-83.25889000",
  },
  {
    id: 122248,
    name: "Morganfield",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.68338000",
    longitude: "-87.91669000",
  },
  {
    id: 122251,
    name: "Morgantown",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.22560000",
    longitude: "-86.68360000",
  },
  {
    id: 122371,
    name: "Mount Olivet",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.53146000",
    longitude: "-84.03687000",
  },
  {
    id: 122391,
    name: "Mount Sterling",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.05647000",
    longitude: "-83.94326000",
  },
  {
    id: 122397,
    name: "Mount Vernon",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.35286000",
    longitude: "-84.34049000",
  },
  {
    id: 122406,
    name: "Mount Washington",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.05006000",
    longitude: "-85.54579000",
  },
  {
    id: 122448,
    name: "Muhlenberg County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.21579000",
    longitude: "-87.14204000",
  },
  {
    id: 122472,
    name: "Munfordville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.27228000",
    longitude: "-85.89108000",
  },
  {
    id: 122491,
    name: "Murray",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.61033000",
    longitude: "-88.31476000",
  },
  {
    id: 122626,
    name: "Nelson County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.80513000",
    longitude: "-85.46599000",
  },
  {
    id: 122685,
    name: "New Castle",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.43340000",
    longitude: "-85.16968000",
  },
  {
    id: 122814,
    name: "Newburg",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.16007000",
    longitude: "-85.65968000",
  },
  {
    id: 122841,
    name: "Newport",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "39.09145000",
    longitude: "-84.49578000",
  },
  {
    id: 122887,
    name: "Nicholas County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.33560000",
    longitude: "-84.01533000",
  },
  {
    id: 122889,
    name: "Nicholasville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.88063000",
    longitude: "-84.57300000",
  },
  {
    id: 123010,
    name: "North Corbin",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.96064000",
    longitude: "-84.09326000",
  },
  {
    id: 123148,
    name: "Northfield",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.28701000",
    longitude: "-85.64107000",
  },
  {
    id: 123188,
    name: "Nortonville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.19088000",
    longitude: "-87.45278000",
  },
  {
    id: 123234,
    name: "Oak Grove",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.66505000",
    longitude: "-87.44279000",
  },
  {
    id: 123268,
    name: "Oakbrook",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.99978000",
    longitude: "-84.68522000",
  },
  {
    id: 123383,
    name: "Ohio County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.47819000",
    longitude: "-86.84889000",
  },
  {
    id: 123408,
    name: "Okolona",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.14118000",
    longitude: "-85.68774000",
  },
  {
    id: 123432,
    name: "Oldham County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.39944000",
    longitude: "-85.44837000",
  },
  {
    id: 123440,
    name: "Olive Hill",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.30008000",
    longitude: "-83.17407000",
  },
  {
    id: 123533,
    name: "Orchard Grass Hills",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.32368000",
    longitude: "-85.52135000",
  },
  {
    id: 123662,
    name: "Owen County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.51964000",
    longitude: "-84.82811000",
  },
  {
    id: 123664,
    name: "Owensboro",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.77422000",
    longitude: "-87.11333000",
  },
  {
    id: 123667,
    name: "Owenton",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.53672000",
    longitude: "-84.84338000",
  },
  {
    id: 123670,
    name: "Owingsville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.14480000",
    longitude: "-83.76408000",
  },
  {
    id: 123673,
    name: "Owsley County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.41920000",
    longitude: "-83.68311000",
  },
  {
    id: 123715,
    name: "Paducah",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.08339000",
    longitude: "-88.60005000",
  },
  {
    id: 123728,
    name: "Paintsville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.81454000",
    longitude: "-82.80711000",
  },
  {
    id: 123831,
    name: "Paris",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.20980000",
    longitude: "-84.25299000",
  },
  {
    id: 123849,
    name: "Park Hills",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "39.07145000",
    longitude: "-84.53217000",
  },
  {
    id: 124019,
    name: "Pendleton County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.69563000",
    longitude: "-84.36027000",
  },
  {
    id: 124078,
    name: "Perry County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.24429000",
    longitude: "-83.22148000",
  },
  {
    id: 124116,
    name: "Pewee Valley",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.31062000",
    longitude: "-85.48746000",
  },
  {
    id: 124190,
    name: "Pike County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.46902000",
    longitude: "-82.39587000",
  },
  {
    id: 124198,
    name: "Pikeville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.47927000",
    longitude: "-82.51876000",
  },
  {
    id: 124232,
    name: "Pine Knot",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.65091000",
    longitude: "-84.43855000",
  },
  {
    id: 124259,
    name: "Pineville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.76203000",
    longitude: "-83.69492000",
  },
  {
    id: 124275,
    name: "Pioneer Village",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.06062000",
    longitude: "-85.67774000",
  },
  {
    id: 124342,
    name: "Plano",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.88032000",
    longitude: "-86.41832000",
  },
  {
    id: 124389,
    name: "Pleasure Ridge Park",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.14535000",
    longitude: "-85.85830000",
  },
  {
    id: 124612,
    name: "Powell County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.83115000",
    longitude: "-83.82377000",
  },
  {
    id: 124661,
    name: "Prestonsburg",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.66565000",
    longitude: "-82.77155000",
  },
  {
    id: 124682,
    name: "Princeton",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.10922000",
    longitude: "-87.88196000",
  },
  {
    id: 124705,
    name: "Prospect",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.34507000",
    longitude: "-85.61552000",
  },
  {
    id: 124715,
    name: "Providence",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.57451000",
    longitude: "-85.22107000",
  },
  {
    id: 124740,
    name: "Pulaski County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.10393000",
    longitude: "-84.57718000",
  },
  {
    id: 124812,
    name: "Raceland",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.54008000",
    longitude: "-82.72850000",
  },
  {
    id: 124815,
    name: "Radcliff",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.84035000",
    longitude: "-85.94913000",
  },
  {
    id: 125006,
    name: "Reidland",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.01756000",
    longitude: "-88.53143000",
  },
  {
    id: 125085,
    name: "Richmond",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.74786000",
    longitude: "-84.29465000",
  },
  {
    id: 125245,
    name: "Robertson County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.51882000",
    longitude: "-84.05203000",
  },
  {
    id: 125293,
    name: "Rockcastle County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.36518000",
    longitude: "-84.31594000",
  },
  {
    id: 125477,
    name: "Rowan County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.19626000",
    longitude: "-83.42108000",
  },
  {
    id: 125534,
    name: "Russell",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.51730000",
    longitude: "-82.69766000",
  },
  {
    id: 125538,
    name: "Russell County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.99103000",
    longitude: "-85.05869000",
  },
  {
    id: 125540,
    name: "Russell Springs",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.05618000",
    longitude: "-85.08857000",
  },
  {
    id: 125544,
    name: "Russellville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.84532000",
    longitude: "-86.88722000",
  },
  {
    id: 125565,
    name: "Ryland Heights",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.95756000",
    longitude: "-84.46300000",
  },
  {
    id: 125631,
    name: "Saint Dennis",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.18840000",
    longitude: "-85.84580000",
  },
  {
    id: 125695,
    name: "Saint Matthews",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.25285000",
    longitude: "-85.65579000",
  },
  {
    id: 125711,
    name: "Saint Regis Park",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.22674000",
    longitude: "-85.61663000",
  },
  {
    id: 125776,
    name: "Salyersville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.75259000",
    longitude: "-83.06878000",
  },
  {
    id: 125883,
    name: "Sandy Hook",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.08647000",
    longitude: "-83.12628000",
  },
  {
    id: 126046,
    name: "Scott County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.29156000",
    longitude: "-84.58393000",
  },
  {
    id: 126064,
    name: "Scottsville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.75338000",
    longitude: "-86.19054000",
  },
  {
    id: 126110,
    name: "Sebree",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.60699000",
    longitude: "-87.52862000",
  },
  {
    id: 126275,
    name: "Shelby County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.21544000",
    longitude: "-85.19477000",
  },
  {
    id: 126282,
    name: "Shelbyville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.21201000",
    longitude: "-85.22357000",
  },
  {
    id: 126312,
    name: "Shepherdsville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.98840000",
    longitude: "-85.71579000",
  },
  {
    id: 126362,
    name: "Shively",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.20007000",
    longitude: "-85.82274000",
  },
  {
    id: 126425,
    name: "Silver Grove",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "39.03451000",
    longitude: "-84.39022000",
  },
  {
    id: 126449,
    name: "Simpson County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.74194000",
    longitude: "-86.58232000",
  },
  {
    id: 126451,
    name: "Simpsonville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.22257000",
    longitude: "-85.35523000",
  },
  {
    id: 126522,
    name: "Smithland",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.13894000",
    longitude: "-88.40337000",
  },
  {
    id: 126580,
    name: "Somerset",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.09202000",
    longitude: "-84.60411000",
  },
  {
    id: 126728,
    name: "South Shore",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.72091000",
    longitude: "-82.95823000",
  },
  {
    id: 126774,
    name: "Southgate",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "39.07200000",
    longitude: "-84.47272000",
  },
  {
    id: 126829,
    name: "Spencer County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.03251000",
    longitude: "-85.32785000",
  },
  {
    id: 126892,
    name: "Springfield",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.68534000",
    longitude: "-85.22218000",
  },
  {
    id: 126953,
    name: "Stanford",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.53119000",
    longitude: "-84.66189000",
  },
  {
    id: 126966,
    name: "Stanton",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.84564000",
    longitude: "-83.85825000",
  },
  {
    id: 127004,
    name: "Stearns",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.69897000",
    longitude: "-84.47744000",
  },
  {
    id: 127150,
    name: "Sturgis",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.54671000",
    longitude: "-87.98391000",
  },
  {
    id: 127453,
    name: "Taylor County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.36646000",
    longitude: "-85.32784000",
  },
  {
    id: 127460,
    name: "Taylor Mill",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.99756000",
    longitude: "-84.49633000",
  },
  {
    id: 127463,
    name: "Taylorsville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.03173000",
    longitude: "-85.34245000",
  },
  {
    id: 127692,
    name: "Todd County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.83556000",
    longitude: "-87.17915000",
  },
  {
    id: 127714,
    name: "Tompkinsville",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.70228000",
    longitude: "-85.69164000",
  },
  {
    id: 127821,
    name: "Trigg County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.80632000",
    longitude: "-87.87337000",
  },
  {
    id: 127822,
    name: "Trimble County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.61303000",
    longitude: "-85.33757000",
  },
  {
    id: 127971,
    name: "Union",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.94590000",
    longitude: "-84.68050000",
  },
  {
    id: 127993,
    name: "Union County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.65845000",
    longitude: "-87.94538000",
  },
  {
    id: 128116,
    name: "Valley Station",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.11118000",
    longitude: "-85.87024000",
  },
  {
    id: 128134,
    name: "Van Lear",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.77121000",
    longitude: "-82.75794000",
  },
  {
    id: 128142,
    name: "Vanceburg",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.59924000",
    longitude: "-83.31880000",
  },
  {
    id: 128200,
    name: "Verona",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.81840000",
    longitude: "-84.66078000",
  },
  {
    id: 128209,
    name: "Versailles",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.05258000",
    longitude: "-84.72995000",
  },
  {
    id: 128242,
    name: "Villa Hills",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "39.06339000",
    longitude: "-84.59300000",
  },
  {
    id: 128268,
    name: "Vine Grove",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.81007000",
    longitude: "-85.98135000",
  },
  {
    id: 128434,
    name: "Walton",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.87562000",
    longitude: "-84.61022000",
  },
  {
    id: 128487,
    name: "Warren County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.99358000",
    longitude: "-86.42380000",
  },
  {
    id: 128515,
    name: "Warsaw",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.78340000",
    longitude: "-84.90162000",
  },
  {
    id: 128559,
    name: "Washington County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.75338000",
    longitude: "-85.17475000",
  },
  {
    id: 128642,
    name: "Watterson Park",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.19229000",
    longitude: "-85.68329000",
  },
  {
    id: 128692,
    name: "Wayne County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.80127000",
    longitude: "-84.82863000",
  },
  {
    id: 128737,
    name: "Webster County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.51842000",
    longitude: "-87.68316000",
  },
  {
    id: 128837,
    name: "West Buechel",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.19701000",
    longitude: "-85.66329000",
  },
  {
    id: 128916,
    name: "West Liberty",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.92148000",
    longitude: "-83.25962000",
  },
  {
    id: 129083,
    name: "Westwood",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.48314000",
    longitude: "-82.66988000",
  },
  {
    id: 129185,
    name: "Whitesburg",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.11843000",
    longitude: "-82.82683000",
  },
  {
    id: 129199,
    name: "Whitley City",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.72341000",
    longitude: "-84.47049000",
  },
  {
    id: 129200,
    name: "Whitley County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.75807000",
    longitude: "-84.14518000",
  },
  {
    id: 129221,
    name: "Wickliffe",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.96478000",
    longitude: "-89.08923000",
  },
  {
    id: 129231,
    name: "Wilder",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "39.05645000",
    longitude: "-84.48689000",
  },
  {
    id: 129266,
    name: "Williamsburg",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "36.74342000",
    longitude: "-84.15966000",
  },
  {
    id: 129285,
    name: "Williamstown",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.63812000",
    longitude: "-84.56050000",
  },
  {
    id: 129327,
    name: "Wilmore",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.86202000",
    longitude: "-84.66161000",
  },
  {
    id: 129353,
    name: "Winchester",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.99008000",
    longitude: "-84.17965000",
  },
  {
    id: 129391,
    name: "Windy Hills",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.27396000",
    longitude: "-85.63441000",
  },
  {
    id: 129477,
    name: "Wolfe County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "37.73932000",
    longitude: "-83.49318000",
  },
  {
    id: 129519,
    name: "Woodford County",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.04239000",
    longitude: "-84.74359000",
  },
  {
    id: 129597,
    name: "Worthington",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.54841000",
    longitude: "-82.72433000",
  },
  {
    id: 129599,
    name: "Worthington Hills",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.30896000",
    longitude: "-85.52690000",
  },
  {
    id: 129618,
    name: "Wurtland",
    state_id: 1419,
    state_code: "KY",
    country_id: 233,
    country_code: "US",
    latitude: "38.55036000",
    longitude: "-82.77794000",
  },
  {
    id: 110966,
    name: "Abbeville",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.97465000",
    longitude: "-92.13429000",
  },
  {
    id: 110987,
    name: "Abita Springs",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.47864000",
    longitude: "-90.04008000",
  },
  {
    id: 110991,
    name: "Acadia Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.29053000",
    longitude: "-92.41198000",
  },
  {
    id: 111033,
    name: "Addis",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.35380000",
    longitude: "-91.26539000",
  },
  {
    id: 111103,
    name: "Albany",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.50436000",
    longitude: "-90.58231000",
  },
  {
    id: 111150,
    name: "Alexandria",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.31129000",
    longitude: "-92.44514000",
  },
  {
    id: 111191,
    name: "Allen Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.65287000",
    longitude: "-92.82788000",
  },
  {
    id: 111260,
    name: "Ama",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.95215000",
    longitude: "-90.29647000",
  },
  {
    id: 111268,
    name: "Amelia",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.66632000",
    longitude: "-91.10204000",
  },
  {
    id: 111288,
    name: "Amite",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.72657000",
    longitude: "-90.50898000",
  },
  {
    id: 111408,
    name: "Arabi",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.95437000",
    longitude: "-90.00535000",
  },
  {
    id: 111421,
    name: "Arcadia",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.54904000",
    longitude: "-92.92016000",
  },
  {
    id: 111482,
    name: "Arnaudville",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.39770000",
    longitude: "-91.93151000",
  },
  {
    id: 111509,
    name: "Ascension Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.20354000",
    longitude: "-90.91129000",
  },
  {
    id: 111558,
    name: "Assumption Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.90077000",
    longitude: "-91.06259000",
  },
  {
    id: 111698,
    name: "Avondale",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.91298000",
    longitude: "-90.20369000",
  },
  {
    id: 111704,
    name: "Avoyelles Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.07624000",
    longitude: "-92.00138000",
  },
  {
    id: 111738,
    name: "Baker",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.58824000",
    longitude: "-91.16816000",
  },
  {
    id: 111754,
    name: "Baldwin",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.83798000",
    longitude: "-91.54428000",
  },
  {
    id: 111768,
    name: "Ball",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.41546000",
    longitude: "-92.41180000",
  },
  {
    id: 111799,
    name: "Banks Springs",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.08210000",
    longitude: "-92.09291000",
  },
  {
    id: 111811,
    name: "Barataria",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.72327000",
    longitude: "-90.12369000",
  },
  {
    id: 111877,
    name: "Basile",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.48520000",
    longitude: "-92.59597000",
  },
  {
    id: 111883,
    name: "Bastrop",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.77828000",
    longitude: "-91.91144000",
  },
  {
    id: 111903,
    name: "Baton Rouge",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.44332000",
    longitude: "-91.18747000",
  },
  {
    id: 111912,
    name: "Bawcomville",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.47042000",
    longitude: "-92.16736000",
  },
  {
    id: 111946,
    name: "Bayou Cane",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.62410000",
    longitude: "-90.75120000",
  },
  {
    id: 111947,
    name: "Bayou Gauche",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.78743000",
    longitude: "-90.41314000",
  },
  {
    id: 111949,
    name: "Bayou Vista",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.68965000",
    longitude: "-91.27094000",
  },
  {
    id: 111991,
    name: "Beauregard Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.64847000",
    longitude: "-93.34334000",
  },
  {
    id: 112080,
    name: "Belle Chasse",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.85493000",
    longitude: "-89.99063000",
  },
  {
    id: 112092,
    name: "Belle Rose",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.05048000",
    longitude: "-91.04149000",
  },
  {
    id: 112194,
    name: "Benton",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.69487000",
    longitude: "-93.74185000",
  },
  {
    id: 112247,
    name: "Bernice",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.82209000",
    longitude: "-92.65793000",
  },
  {
    id: 112261,
    name: "Berwick",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.69465000",
    longitude: "-91.21899000",
  },
  {
    id: 112313,
    name: "Bienville Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.34722000",
    longitude: "-93.05595000",
  },
  {
    id: 112416,
    name: "Blanchard",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.58098000",
    longitude: "-93.89268000",
  },
  {
    id: 112498,
    name: "Bogalusa",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.79102000",
    longitude: "-89.84869000",
  },
  {
    id: 112586,
    name: "Bossier City",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.51599000",
    longitude: "-93.73212000",
  },
  {
    id: 112587,
    name: "Bossier Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.67899000",
    longitude: "-93.60500000",
  },
  {
    id: 112616,
    name: "Bourg",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.55355000",
    longitude: "-90.60231000",
  },
  {
    id: 112618,
    name: "Boutte",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.90243000",
    longitude: "-90.38814000",
  },
  {
    id: 112709,
    name: "Breaux Bridge",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.27353000",
    longitude: "-91.89928000",
  },
  {
    id: 112755,
    name: "Bridge City",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.93326000",
    longitude: "-90.17007000",
  },
  {
    id: 112903,
    name: "Broussard",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.14715000",
    longitude: "-91.96123000",
  },
  {
    id: 112925,
    name: "Brownsfield",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.54658000",
    longitude: "-91.12066000",
  },
  {
    id: 112930,
    name: "Brownsville",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.48709000",
    longitude: "-92.15430000",
  },
  {
    id: 112957,
    name: "Brusly",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.39436000",
    longitude: "-91.25372000",
  },
  {
    id: 113044,
    name: "Bunkie",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.95325000",
    longitude: "-92.18263000",
  },
  {
    id: 113162,
    name: "Caddo Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.58017000",
    longitude: "-93.88235000",
  },
  {
    id: 113163,
    name: "Cade",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.08742000",
    longitude: "-91.90540000",
  },
  {
    id: 113177,
    name: "Calcasieu Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.22922000",
    longitude: "-93.35795000",
  },
  {
    id: 113187,
    name: "Caldwell Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.09227000",
    longitude: "-92.11661000",
  },
  {
    id: 113274,
    name: "Cameron",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.79772000",
    longitude: "-93.32515000",
  },
  {
    id: 113279,
    name: "Cameron Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.84687000",
    longitude: "-93.19890000",
  },
  {
    id: 113309,
    name: "Campti",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.89350000",
    longitude: "-93.11822000",
  },
  {
    id: 113393,
    name: "Carencro",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.31714000",
    longitude: "-92.04901000",
  },
  {
    id: 113415,
    name: "Carlyss",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.16882000",
    longitude: "-93.37599000",
  },
  {
    id: 113504,
    name: "Carville",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.21742000",
    longitude: "-91.09621000",
  },
  {
    id: 113561,
    name: "Catahoula",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.21464000",
    longitude: "-91.70900000",
  },
  {
    id: 113562,
    name: "Catahoula Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.66617000",
    longitude: "-91.84707000",
  },
  {
    id: 113600,
    name: "Cecilia",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.33714000",
    longitude: "-91.85317000",
  },
  {
    id: 113663,
    name: "Central",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.55435000",
    longitude: "-91.03677000",
  },
  {
    id: 113705,
    name: "Chackbay",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.88354000",
    longitude: "-90.79732000",
  },
  {
    id: 113716,
    name: "Chalmette",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.94296000",
    longitude: "-89.96537000",
  },
  {
    id: 113748,
    name: "Charenton",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.88159000",
    longitude: "-91.52511000",
  },
  {
    id: 113816,
    name: "Chauvin",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.43855000",
    longitude: "-90.59537000",
  },
  {
    id: 114009,
    name: "Church Point",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.40298000",
    longitude: "-92.21513000",
  },
  {
    id: 114092,
    name: "Claiborne",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.51598000",
    longitude: "-92.19180000",
  },
  {
    id: 114095,
    name: "Claiborne Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.82269000",
    longitude: "-92.99573000",
  },
  {
    id: 114140,
    name: "Clarks",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.02655000",
    longitude: "-92.13903000",
  },
  {
    id: 114270,
    name: "Clinton",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.86574000",
    longitude: "-91.01566000",
  },
  {
    id: 114404,
    name: "Colfax",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.51906000",
    longitude: "-92.70682000",
  },
  {
    id: 114466,
    name: "Columbia",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.10516000",
    longitude: "-92.07791000",
  },
  {
    id: 114547,
    name: "Concordia Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.44584000",
    longitude: "-91.64006000",
  },
  {
    id: 114577,
    name: "Convent",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.02076000",
    longitude: "-90.82982000",
  },
  {
    id: 114698,
    name: "Cottonport",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.98408000",
    longitude: "-92.05346000",
  },
  {
    id: 114729,
    name: "Coushatta",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.01488000",
    longitude: "-93.34212000",
  },
  {
    id: 114737,
    name: "Covington",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.47549000",
    longitude: "-90.10042000",
  },
  {
    id: 114863,
    name: "Crowley",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.21409000",
    longitude: "-92.37458000",
  },
  {
    id: 114894,
    name: "Cullen",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.96903000",
    longitude: "-93.45073000",
  },
  {
    id: 114942,
    name: "Cut Off",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.54272000",
    longitude: "-90.33814000",
  },
  {
    id: 115120,
    name: "De Soto Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.05545000",
    longitude: "-93.73728000",
  },
  {
    id: 115209,
    name: "Delcambre",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.94826000",
    longitude: "-91.98873000",
  },
  {
    id: 115211,
    name: "Delhi",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.45764000",
    longitude: "-91.49317000",
  },
  {
    id: 115236,
    name: "Denham Springs",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.48740000",
    longitude: "-90.95753000",
  },
  {
    id: 115136,
    name: "DeQuincy",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.45048000",
    longitude: "-93.43322000",
  },
  {
    id: 115137,
    name: "DeRidder",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.84631000",
    longitude: "-93.28905000",
  },
  {
    id: 115269,
    name: "Des Allemands",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.82382000",
    longitude: "-90.47508000",
  },
  {
    id: 115290,
    name: "Destrehan",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.94322000",
    longitude: "-90.35345000",
  },
  {
    id: 115297,
    name: "Deville",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.35740000",
    longitude: "-92.16541000",
  },
  {
    id: 115394,
    name: "Donaldsonville",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.10114000",
    longitude: "-90.99412000",
  },
  {
    id: 115509,
    name: "Dulac",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.38883000",
    longitude: "-90.71398000",
  },
  {
    id: 115577,
    name: "Duson",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.23576000",
    longitude: "-92.18540000",
  },
  {
    id: 115630,
    name: "East Baton Rouge Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.53824000",
    longitude: "-91.09562000",
  },
  {
    id: 115643,
    name: "East Carroll Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.73255000",
    longitude: "-91.23507000",
  },
  {
    id: 115659,
    name: "East Feliciana Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.84507000",
    longitude: "-91.04554000",
  },
  {
    id: 115799,
    name: "Eastwood",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.55626000",
    longitude: "-93.56712000",
  },
  {
    id: 115831,
    name: "Eden Isle",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.22853000",
    longitude: "-89.79867000",
  },
  {
    id: 115837,
    name: "Edgard",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.04326000",
    longitude: "-90.56009000",
  },
  {
    id: 116060,
    name: "Elmwood",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.95659000",
    longitude: "-90.18980000",
  },
  {
    id: 116073,
    name: "Elton",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.48131000",
    longitude: "-92.69570000",
  },
  {
    id: 116160,
    name: "Erath",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.95826000",
    longitude: "-92.03596000",
  },
  {
    id: 116177,
    name: "Erwinville",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.53102000",
    longitude: "-91.40789000",
  },
  {
    id: 116205,
    name: "Estelle",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.84576000",
    longitude: "-90.10674000",
  },
  {
    id: 116229,
    name: "Eunice",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.49437000",
    longitude: "-92.41763000",
  },
  {
    id: 116244,
    name: "Evangeline Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.72894000",
    longitude: "-92.40590000",
  },
  {
    id: 116408,
    name: "Farmerville",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.77347000",
    longitude: "-92.40570000",
  },
  {
    id: 116493,
    name: "Ferriday",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.63017000",
    longitude: "-91.55456000",
  },
  {
    id: 116744,
    name: "Fort Polk North",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.10302000",
    longitude: "-93.17913000",
  },
  {
    id: 116745,
    name: "Fort Polk South",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.05110000",
    longitude: "-93.21578000",
  },
  {
    id: 116827,
    name: "Franklin",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.79604000",
    longitude: "-91.50150000",
  },
  {
    id: 116867,
    name: "Franklin Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.13322000",
    longitude: "-91.67377000",
  },
  {
    id: 116873,
    name: "Franklinton",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.84731000",
    longitude: "-90.15527000",
  },
  {
    id: 116932,
    name: "French Settlement",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.29599000",
    longitude: "-90.79630000",
  },
  {
    id: 117037,
    name: "Galliano",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.44216000",
    longitude: "-90.29925000",
  },
  {
    id: 117072,
    name: "Gardere",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.34575000",
    longitude: "-91.14011000",
  },
  {
    id: 117107,
    name: "Garyville",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.05604000",
    longitude: "-90.61926000",
  },
  {
    id: 117294,
    name: "Glenmora",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.97658000",
    longitude: "-92.58514000",
  },
  {
    id: 117352,
    name: "Golden Meadow",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.37911000",
    longitude: "-90.26008000",
  },
  {
    id: 117370,
    name: "Gonzales",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.23853000",
    longitude: "-90.92010000",
  },
  {
    id: 117442,
    name: "Grambling",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.52765000",
    longitude: "-92.71404000",
  },
  {
    id: 117443,
    name: "Gramercy",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.04742000",
    longitude: "-90.68981000",
  },
  {
    id: 117463,
    name: "Grand Isle",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.23662000",
    longitude: "-89.98729000",
  },
  {
    id: 117470,
    name: "Grand Point",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.06131000",
    longitude: "-90.75343000",
  },
  {
    id: 117522,
    name: "Grant Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.59970000",
    longitude: "-92.55952000",
  },
  {
    id: 117551,
    name: "Gray",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.69771000",
    longitude: "-90.78648000",
  },
  {
    id: 117671,
    name: "Greensburg",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.83074000",
    longitude: "-90.67176000",
  },
  {
    id: 117704,
    name: "Greenwood",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.44293000",
    longitude: "-93.97296000",
  },
  {
    id: 117725,
    name: "Gretna",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.91465000",
    longitude: "-90.05396000",
  },
  {
    id: 117782,
    name: "Gueydan",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.02604000",
    longitude: "-92.50847000",
  },
  {
    id: 117826,
    name: "Hackberry",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.99605000",
    longitude: "-93.34210000",
  },
  {
    id: 117840,
    name: "Hahnville",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.97659000",
    longitude: "-90.40897000",
  },
  {
    id: 117912,
    name: "Hammond",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.50463000",
    longitude: "-90.46293000",
  },
  {
    id: 117984,
    name: "Harahan",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.94048000",
    longitude: "-90.20313000",
  },
  {
    id: 118069,
    name: "Harrisonburg",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.77211000",
    longitude: "-91.82152000",
  },
  {
    id: 118111,
    name: "Harvey",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.90354000",
    longitude: "-90.07729000",
  },
  {
    id: 118142,
    name: "Haughton",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.53265000",
    longitude: "-93.50406000",
  },
  {
    id: 118188,
    name: "Haynesville",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.96208000",
    longitude: "-93.14016000",
  },
  {
    id: 118275,
    name: "Henderson",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.31325000",
    longitude: "-91.79039000",
  },
  {
    id: 118593,
    name: "Homer",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.79192000",
    longitude: "-93.05503000",
  },
  {
    id: 118694,
    name: "Houma",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.59577000",
    longitude: "-90.71953000",
  },
  {
    id: 118863,
    name: "Iberia Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.51353000",
    longitude: "-91.83964000",
  },
  {
    id: 118864,
    name: "Iberville Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.25850000",
    longitude: "-91.34936000",
  },
  {
    id: 118891,
    name: "Independence",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.63551000",
    longitude: "-90.50335000",
  },
  {
    id: 118947,
    name: "Inniswold",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.40491000",
    longitude: "-91.08344000",
  },
  {
    id: 118974,
    name: "Iota",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.33131000",
    longitude: "-92.49569000",
  },
  {
    id: 118975,
    name: "Iowa",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.23687000",
    longitude: "-93.01376000",
  },
  {
    id: 119063,
    name: "Jackson",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.83740000",
    longitude: "-91.21761000",
  },
  {
    id: 119098,
    name: "Jackson Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.30203000",
    longitude: "-92.55774000",
  },
  {
    id: 119157,
    name: "Jean Lafitte",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.73604000",
    longitude: "-90.12674000",
  },
  {
    id: 119158,
    name: "Jeanerette",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.91104000",
    longitude: "-91.66345000",
  },
  {
    id: 119163,
    name: "Jefferson",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.96604000",
    longitude: "-90.15313000",
  },
  {
    id: 119200,
    name: "Jefferson Davis Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.26772000",
    longitude: "-92.81412000",
  },
  {
    id: 119203,
    name: "Jefferson Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.68097000",
    longitude: "-90.09798000",
  },
  {
    id: 119212,
    name: "Jena",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.68323000",
    longitude: "-92.13374000",
  },
  {
    id: 119218,
    name: "Jennings",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.22243000",
    longitude: "-92.65708000",
  },
  {
    id: 119302,
    name: "Jonesboro",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.24127000",
    longitude: "-92.71599000",
  },
  {
    id: 119309,
    name: "Jonesville",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.62656000",
    longitude: "-91.81818000",
  },
  {
    id: 119387,
    name: "Kaplan",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.99798000",
    longitude: "-92.28485000",
  },
  {
    id: 119470,
    name: "Kenner",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.99409000",
    longitude: "-90.24174000",
  },
  {
    id: 119500,
    name: "Kentwood",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.93824000",
    longitude: "-90.50898000",
  },
  {
    id: 119560,
    name: "Killian",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.35881000",
    longitude: "-90.58620000",
  },
  {
    id: 119574,
    name: "Kinder",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.48548000",
    longitude: "-92.85070000",
  },
  {
    id: 119718,
    name: "Krotz Springs",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.53686000",
    longitude: "-91.75289000",
  },
  {
    id: 119788,
    name: "La Salle Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.67673000",
    longitude: "-92.16044000",
  },
  {
    id: 119808,
    name: "Labadieville",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.83743000",
    longitude: "-90.95621000",
  },
  {
    id: 119817,
    name: "Lacombe",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.31353000",
    longitude: "-89.94313000",
  },
  {
    id: 119832,
    name: "Lafayette",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.22409000",
    longitude: "-92.01984000",
  },
  {
    id: 119844,
    name: "Lafayette Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.20677000",
    longitude: "-92.06388000",
  },
  {
    id: 119846,
    name: "Lafourche Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.50033000",
    longitude: "-90.40259000",
  },
  {
    id: 119867,
    name: "Lake Arthur",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.08076000",
    longitude: "-92.67153000",
  },
  {
    id: 119877,
    name: "Lake Charles",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.21309000",
    longitude: "-93.20440000",
  },
  {
    id: 119966,
    name: "Lake Providence",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.80499000",
    longitude: "-91.17098000",
  },
  {
    id: 120028,
    name: "Lakeshore",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.53514000",
    longitude: "-92.02958000",
  },
  {
    id: 120134,
    name: "Laplace",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.06698000",
    longitude: "-90.48147000",
  },
  {
    id: 120151,
    name: "Larose",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.57244000",
    longitude: "-90.38175000",
  },
  {
    id: 120249,
    name: "Lawtell",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.51853000",
    longitude: "-92.18485000",
  },
  {
    id: 120300,
    name: "Lecompte",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.09463000",
    longitude: "-92.40041000",
  },
  {
    id: 120328,
    name: "Leesville",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.14352000",
    longitude: "-93.26100000",
  },
  {
    id: 120392,
    name: "Leonville",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.47047000",
    longitude: "-91.97845000",
  },
  {
    id: 120539,
    name: "Lincoln Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.60164000",
    longitude: "-92.66482000",
  },
  {
    id: 120648,
    name: "Livingston",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.50213000",
    longitude: "-90.74787000",
  },
  {
    id: 120660,
    name: "Livingston Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.44014000",
    longitude: "-90.72791000",
  },
  {
    id: 120661,
    name: "Livonia",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.55908000",
    longitude: "-91.55594000",
  },
  {
    id: 120679,
    name: "Lockport",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.64605000",
    longitude: "-90.53925000",
  },
  {
    id: 120682,
    name: "Lockport Heights",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.65049000",
    longitude: "-90.54647000",
  },
  {
    id: 120706,
    name: "Logansport",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.97545000",
    longitude: "-93.99797000",
  },
  {
    id: 120876,
    name: "Luling",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.93215000",
    longitude: "-90.36647000",
  },
  {
    id: 120893,
    name: "Lutcher",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.04048000",
    longitude: "-90.69898000",
  },
  {
    id: 121023,
    name: "Madison Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.36440000",
    longitude: "-91.24258000",
  },
  {
    id: 121078,
    name: "Mamou",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.63381000",
    longitude: "-92.41930000",
  },
  {
    id: 121107,
    name: "Mandeville",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.35825000",
    longitude: "-90.06563000",
  },
  {
    id: 121144,
    name: "Mansfield",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.03766000",
    longitude: "-93.70018000",
  },
  {
    id: 121152,
    name: "Mansura",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.05797000",
    longitude: "-92.04901000",
  },
  {
    id: 121163,
    name: "Many",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.56878000",
    longitude: "-93.48406000",
  },
  {
    id: 121230,
    name: "Maringouin",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.49130000",
    longitude: "-91.51955000",
  },
  {
    id: 121273,
    name: "Marksville",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.12797000",
    longitude: "-92.06624000",
  },
  {
    id: 121296,
    name: "Marrero",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.89937000",
    longitude: "-90.10035000",
  },
  {
    id: 121402,
    name: "Mathews",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.68632000",
    longitude: "-90.54675000",
  },
  {
    id: 121423,
    name: "Maurice",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.10854000",
    longitude: "-92.12457000",
  },
  {
    id: 121634,
    name: "Melville",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.69297000",
    longitude: "-91.74400000",
  },
  {
    id: 121670,
    name: "Meraux",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.93017000",
    longitude: "-89.91623000",
  },
  {
    id: 121716,
    name: "Merrydale",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.50130000",
    longitude: "-91.10844000",
  },
  {
    id: 121717,
    name: "Merryville",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.75437000",
    longitude: "-93.54045000",
  },
  {
    id: 121729,
    name: "Metairie",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.98409000",
    longitude: "-90.15285000",
  },
  {
    id: 121730,
    name: "Metairie Terrace",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.97854000",
    longitude: "-90.16396000",
  },
  {
    id: 121815,
    name: "Midway",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.69212000",
    longitude: "-92.15236000",
  },
  {
    id: 121913,
    name: "Milton",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.10381000",
    longitude: "-92.07651000",
  },
  {
    id: 121928,
    name: "Minden",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.61543000",
    longitude: "-93.28684000",
  },
  {
    id: 121964,
    name: "Minorca",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.57933000",
    longitude: "-91.48179000",
  },
  {
    id: 122058,
    name: "Monroe",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.50931000",
    longitude: "-92.11930000",
  },
  {
    id: 122116,
    name: "Montegut",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.47439000",
    longitude: "-90.55703000",
  },
  {
    id: 122161,
    name: "Monticello",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.48908000",
    longitude: "-91.04872000",
  },
  {
    id: 122190,
    name: "Montz",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.00687000",
    longitude: "-90.46869000",
  },
  {
    id: 122227,
    name: "Morehouse Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.82022000",
    longitude: "-91.80180000",
  },
  {
    id: 122235,
    name: "Morgan City",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.69937000",
    longitude: "-91.20677000",
  },
  {
    id: 122308,
    name: "Moss Bluff",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.30270000",
    longitude: "-93.19071000",
  },
  {
    id: 122560,
    name: "Napoleonville",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.94048000",
    longitude: "-91.02482000",
  },
  {
    id: 122585,
    name: "Natalbany",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.54622000",
    longitude: "-90.48619000",
  },
  {
    id: 122588,
    name: "Natchitoches",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.76072000",
    longitude: "-93.08627000",
  },
  {
    id: 122589,
    name: "Natchitoches Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.72354000",
    longitude: "-93.09624000",
  },
  {
    id: 122730,
    name: "New Iberia",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.00354000",
    longitude: "-91.81873000",
  },
  {
    id: 122739,
    name: "New Llano",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.11491000",
    longitude: "-93.27155000",
  },
  {
    id: 122756,
    name: "New Orleans",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.95465000",
    longitude: "-90.07507000",
  },
  {
    id: 122772,
    name: "New Roads",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.70157000",
    longitude: "-91.43622000",
  },
  {
    id: 122775,
    name: "New Sarpy",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.97817000",
    longitude: "-90.38963000",
  },
  {
    id: 122825,
    name: "Newellton",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.07265000",
    longitude: "-91.24095000",
  },
  {
    id: 122938,
    name: "Norco",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.99909000",
    longitude: "-90.41230000",
  },
  {
    id: 123118,
    name: "North Vacherie",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.99687000",
    longitude: "-90.70565000",
  },
  {
    id: 123235,
    name: "Oak Grove",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.86096000",
    longitude: "-91.38845000",
  },
  {
    id: 123250,
    name: "Oak Hills Place",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.35992000",
    longitude: "-91.08760000",
  },
  {
    id: 123270,
    name: "Oakdale",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.81603000",
    longitude: "-92.66042000",
  },
  {
    id: 123315,
    name: "Oberlin",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.62020000",
    longitude: "-92.76265000",
  },
  {
    id: 123422,
    name: "Old Jefferson",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.38269000",
    longitude: "-91.01705000",
  },
  {
    id: 123451,
    name: "Olla",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.90294000",
    longitude: "-92.24319000",
  },
  {
    id: 123497,
    name: "Opelousas",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.53353000",
    longitude: "-92.08151000",
  },
  {
    id: 123567,
    name: "Orleans Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.06864000",
    longitude: "-89.92813000",
  },
  {
    id: 123619,
    name: "Ossun",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.27603000",
    longitude: "-92.11235000",
  },
  {
    id: 123645,
    name: "Ouachita Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.47831000",
    longitude: "-92.15487000",
  },
  {
    id: 123816,
    name: "Paradis",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.87965000",
    longitude: "-90.43397000",
  },
  {
    id: 123915,
    name: "Patterson",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.69326000",
    longitude: "-91.30205000",
  },
  {
    id: 123923,
    name: "Paulina",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.02631000",
    longitude: "-90.71315000",
  },
  {
    id: 123966,
    name: "Pearl River",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.37603000",
    longitude: "-89.74840000",
  },
  {
    id: 124179,
    name: "Pierre Part",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.96520000",
    longitude: "-91.20316000",
  },
  {
    id: 124241,
    name: "Pine Prairie",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.78381000",
    longitude: "-92.42541000",
  },
  {
    id: 124260,
    name: "Pineville",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.32240000",
    longitude: "-92.43430000",
  },
  {
    id: 124349,
    name: "Plaquemine",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.29005000",
    longitude: "-91.23497000",
  },
  {
    id: 124350,
    name: "Plaquemines Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.54421000",
    longitude: "-89.82047000",
  },
  {
    id: 124435,
    name: "Pointe Coupee Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.70940000",
    longitude: "-91.60079000",
  },
  {
    id: 124466,
    name: "Ponchatoula",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.43880000",
    longitude: "-90.44148000",
  },
  {
    id: 124492,
    name: "Port Allen",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.45214000",
    longitude: "-91.21011000",
  },
  {
    id: 124497,
    name: "Port Barre",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.56020000",
    longitude: "-91.95401000",
  },
  {
    id: 124536,
    name: "Port Sulphur",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.48049000",
    longitude: "-89.69395000",
  },
  {
    id: 124620,
    name: "Poydras",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.86937000",
    longitude: "-89.88895000",
  },
  {
    id: 124635,
    name: "Prairieville",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.30297000",
    longitude: "-90.97205000",
  },
  {
    id: 124651,
    name: "Presquille",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.56383000",
    longitude: "-90.64620000",
  },
  {
    id: 124668,
    name: "Prien",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.18187000",
    longitude: "-93.27377000",
  },
  {
    id: 124811,
    name: "Raceland",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.72743000",
    longitude: "-90.59898000",
  },
  {
    id: 124896,
    name: "Rapides Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.19862000",
    longitude: "-92.53320000",
  },
  {
    id: 124923,
    name: "Rayne",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.23493000",
    longitude: "-92.26846000",
  },
  {
    id: 124927,
    name: "Rayville",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.47736000",
    longitude: "-91.75485000",
  },
  {
    id: 124943,
    name: "Red Chute",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.55598000",
    longitude: "-93.61323000",
  },
  {
    id: 124960,
    name: "Red River Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.09315000",
    longitude: "-93.33988000",
  },
  {
    id: 125034,
    name: "Reserve",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.05381000",
    longitude: "-90.55175000",
  },
  {
    id: 125080,
    name: "Richland Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.41779000",
    longitude: "-91.76349000",
  },
  {
    id: 125109,
    name: "Richwood",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.44876000",
    longitude: "-92.08486000",
  },
  {
    id: 125142,
    name: "Ringgold",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.32849000",
    longitude: "-93.27989000",
  },
  {
    id: 125185,
    name: "River Ridge",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.96020000",
    longitude: "-90.21563000",
  },
  {
    id: 125422,
    name: "Roseland",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.76491000",
    longitude: "-90.51176000",
  },
  {
    id: 125437,
    name: "Rosepine",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.91991000",
    longitude: "-93.28239000",
  },
  {
    id: 125547,
    name: "Ruston",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.52321000",
    longitude: "-92.63793000",
  },
  {
    id: 125570,
    name: "Sabine Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.56401000",
    longitude: "-93.55470000",
  },
  {
    id: 125608,
    name: "Saint Bernard Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.87399000",
    longitude: "-89.82422000",
  },
  {
    id: 125617,
    name: "Saint Charles Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.90553000",
    longitude: "-90.35822000",
  },
  {
    id: 125637,
    name: "Saint Francisville",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.77990000",
    longitude: "-91.37650000",
  },
  {
    id: 125639,
    name: "Saint Gabriel",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.25770000",
    longitude: "-91.09927000",
  },
  {
    id: 125648,
    name: "Saint Helena Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.82198000",
    longitude: "-90.71032000",
  },
  {
    id: 125658,
    name: "Saint James Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.02630000",
    longitude: "-90.79632000",
  },
  {
    id: 125663,
    name: "Saint John the Baptist Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.12646000",
    longitude: "-90.47088000",
  },
  {
    id: 125670,
    name: "Saint Joseph",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.91849000",
    longitude: "-91.23345000",
  },
  {
    id: 125677,
    name: "Saint Landry Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.59885000",
    longitude: "-92.00586000",
  },
  {
    id: 125687,
    name: "Saint Martin Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.12907000",
    longitude: "-91.60830000",
  },
  {
    id: 125689,
    name: "Saint Martinville",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.12520000",
    longitude: "-91.83345000",
  },
  {
    id: 125690,
    name: "Saint Mary Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.63462000",
    longitude: "-91.47293000",
  },
  {
    id: 125713,
    name: "Saint Rose",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.94687000",
    longitude: "-90.32313000",
  },
  {
    id: 125718,
    name: "Saint Tammany Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.40875000",
    longitude: "-89.95393000",
  },
  {
    id: 126019,
    name: "Schriever",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.74215000",
    longitude: "-90.81037000",
  },
  {
    id: 126039,
    name: "Scott",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.23576000",
    longitude: "-92.09457000",
  },
  {
    id: 126300,
    name: "Shenandoah",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.40130000",
    longitude: "-91.00094000",
  },
  {
    id: 126382,
    name: "Shreveport",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.52515000",
    longitude: "-93.75018000",
  },
  {
    id: 126390,
    name: "Sibley",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.53932000",
    longitude: "-93.29628000",
  },
  {
    id: 126446,
    name: "Simmesport",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.98352000",
    longitude: "-91.80012000",
  },
  {
    id: 126501,
    name: "Slidell",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.27519000",
    longitude: "-89.78117000",
  },
  {
    id: 126605,
    name: "Sorrento",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.18436000",
    longitude: "-90.85926000",
  },
  {
    id: 126739,
    name: "South Vacherie",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.92743000",
    longitude: "-90.69981000",
  },
  {
    id: 126909,
    name: "Springhill",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "33.00597000",
    longitude: "-93.46684000",
  },
  {
    id: 127035,
    name: "Sterlington",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.69625000",
    longitude: "-92.08597000",
  },
  {
    id: 127091,
    name: "Stonewall",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.28183000",
    longitude: "-93.82407000",
  },
  {
    id: 127194,
    name: "Sulphur",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.23659000",
    longitude: "-93.37738000",
  },
  {
    id: 127272,
    name: "Sunset",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.41131000",
    longitude: "-92.06845000",
  },
  {
    id: 127283,
    name: "Supreme",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.85937000",
    longitude: "-90.98121000",
  },
  {
    id: 127331,
    name: "Swartz",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.56875000",
    longitude: "-91.98513000",
  },
  {
    id: 127396,
    name: "Tallulah",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.40848000",
    longitude: "-91.18678000",
  },
  {
    id: 127415,
    name: "Tangipahoa Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.62665000",
    longitude: "-90.40568000",
  },
  {
    id: 127512,
    name: "Tensas Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.00166000",
    longitude: "-91.34007000",
  },
  {
    id: 127525,
    name: "Terrebonne Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.33744000",
    longitude: "-90.83764000",
  },
  {
    id: 127533,
    name: "Terrytown",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.91021000",
    longitude: "-90.03257000",
  },
  {
    id: 127573,
    name: "Thibodaux",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.79576000",
    longitude: "-90.82287000",
  },
  {
    id: 127659,
    name: "Timberlane",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.87743000",
    longitude: "-90.03202000",
  },
  {
    id: 128008,
    name: "Union Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.83190000",
    longitude: "-92.37482000",
  },
  {
    id: 128059,
    name: "Urania",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.86378000",
    longitude: "-92.29597000",
  },
  {
    id: 128184,
    name: "Vermilion Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.80939000",
    longitude: "-92.30428000",
  },
  {
    id: 128195,
    name: "Vernon Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.10829000",
    longitude: "-93.18415000",
  },
  {
    id: 128228,
    name: "Vidalia",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.56544000",
    longitude: "-91.42595000",
  },
  {
    id: 128235,
    name: "Vienna Bend",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.73239000",
    longitude: "-93.04100000",
  },
  {
    id: 128250,
    name: "Village Saint George",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.36214000",
    longitude: "-91.06733000",
  },
  {
    id: 128259,
    name: "Ville Platte",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.68797000",
    longitude: "-92.27152000",
  },
  {
    id: 128279,
    name: "Vinton",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.19076000",
    longitude: "-93.58127000",
  },
  {
    id: 128284,
    name: "Violet",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.89576000",
    longitude: "-89.89784000",
  },
  {
    id: 128298,
    name: "Vivian",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.87153000",
    longitude: "-93.98740000",
  },
  {
    id: 128327,
    name: "Waggaman",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.91854000",
    longitude: "-90.21091000",
  },
  {
    id: 128383,
    name: "Walker",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.48797000",
    longitude: "-90.86149000",
  },
  {
    id: 128583,
    name: "Washington Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.85334000",
    longitude: "-90.04052000",
  },
  {
    id: 128639,
    name: "Watson",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.57574000",
    longitude: "-90.95316000",
  },
  {
    id: 128744,
    name: "Webster Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.71345000",
    longitude: "-93.33498000",
  },
  {
    id: 128796,
    name: "Welsh",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.23604000",
    longitude: "-92.82265000",
  },
  {
    id: 128822,
    name: "West Baton Rouge Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.46341000",
    longitude: "-91.31275000",
  },
  {
    id: 128841,
    name: "West Carroll Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.78856000",
    longitude: "-91.45674000",
  },
  {
    id: 128870,
    name: "West Feliciana Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.87977000",
    longitude: "-91.42003000",
  },
  {
    id: 128871,
    name: "West Ferriday",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.64044000",
    longitude: "-91.57318000",
  },
  {
    id: 128935,
    name: "West Monroe",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.51848000",
    longitude: "-92.14764000",
  },
  {
    id: 129038,
    name: "Westlake",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.24215000",
    longitude: "-93.25071000",
  },
  {
    id: 129044,
    name: "Westminster",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.41380000",
    longitude: "-91.08760000",
  },
  {
    id: 129081,
    name: "Westwego",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.90604000",
    longitude: "-90.14230000",
  },
  {
    id: 129128,
    name: "White Castle",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.16992000",
    longitude: "-91.14705000",
  },
  {
    id: 129404,
    name: "Winn Parish",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.94425000",
    longitude: "-92.63677000",
  },
  {
    id: 129416,
    name: "Winnfield",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.92558000",
    longitude: "-92.64131000",
  },
  {
    id: 129418,
    name: "Winnsboro",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "32.16321000",
    longitude: "-91.72068000",
  },
  {
    id: 129540,
    name: "Woodmere",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "29.85798000",
    longitude: "-90.08035000",
  },
  {
    id: 129580,
    name: "Woodworth",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.14658000",
    longitude: "-92.49736000",
  },
  {
    id: 129718,
    name: "Youngsville",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.09965000",
    longitude: "-91.99012000",
  },
  {
    id: 129738,
    name: "Zachary",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "30.64852000",
    longitude: "-91.15650000",
  },
  {
    id: 129763,
    name: "Zwolle",
    state_id: 1457,
    state_code: "LA",
    country_id: 233,
    country_code: "US",
    latitude: "31.63156000",
    longitude: "-93.64407000",
  },
  {
    id: 111000,
    name: "Acton",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.53425000",
    longitude: "-70.90978000",
  },
  {
    id: 111036,
    name: "Addison",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.61841000",
    longitude: "-67.74416000",
  },
  {
    id: 111125,
    name: "Albion",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.53229000",
    longitude: "-69.44254000",
  },
  {
    id: 111159,
    name: "Alfred",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.47647000",
    longitude: "-70.71839000",
  },
  {
    id: 111334,
    name: "Androscoggin County",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.16585000",
    longitude: "-70.20645000",
  },
  {
    id: 111395,
    name: "Appleton",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.28924000",
    longitude: "-69.25088000",
  },
  {
    id: 111492,
    name: "Aroostook County",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "46.65881000",
    longitude: "-68.59889000",
  },
  {
    id: 111502,
    name: "Arundel",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.38259000",
    longitude: "-70.47783000",
  },
  {
    id: 111627,
    name: "Auburn",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.09785000",
    longitude: "-70.23117000",
  },
  {
    id: 111649,
    name: "Augusta",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.31062000",
    longitude: "-69.77949000",
  },
  {
    id: 111792,
    name: "Bangor",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.80118000",
    longitude: "-68.77781000",
  },
  {
    id: 111806,
    name: "Bar Harbor",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.38758000",
    longitude: "-68.20390000",
  },
  {
    id: 111897,
    name: "Bath",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.91064000",
    longitude: "-69.82060000",
  },
  {
    id: 112056,
    name: "Belfast",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.42591000",
    longitude: "-69.00642000",
  },
  {
    id: 112061,
    name: "Belgrade",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.44729000",
    longitude: "-69.83255000",
  },
  {
    id: 112197,
    name: "Benton",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.58618000",
    longitude: "-69.55088000",
  },
  {
    id: 112262,
    name: "Berwick",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.26592000",
    longitude: "-70.86450000",
  },
  {
    id: 112279,
    name: "Bethel",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.40423000",
    longitude: "-70.79062000",
  },
  {
    id: 112312,
    name: "Biddeford",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.49258000",
    longitude: "-70.45338000",
  },
  {
    id: 112572,
    name: "Boothbay",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.87647000",
    longitude: "-69.63366000",
  },
  {
    id: 112573,
    name: "Boothbay Harbor",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.85230000",
    longitude: "-69.62810000",
  },
  {
    id: 112665,
    name: "Bradford",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "45.06673000",
    longitude: "-68.93781000",
  },
  {
    id: 112673,
    name: "Bradley",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.92090000",
    longitude: "-68.62809000",
  },
  {
    id: 112739,
    name: "Brewer",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.79674000",
    longitude: "-68.76142000",
  },
  {
    id: 112779,
    name: "Bridgton",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.05479000",
    longitude: "-70.71284000",
  },
  {
    id: 112806,
    name: "Bristol",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.95758000",
    longitude: "-69.50921000",
  },
  {
    id: 112882,
    name: "Brooks",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.55035000",
    longitude: "-69.12087000",
  },
  {
    id: 112916,
    name: "Brownfield",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.93813000",
    longitude: "-70.90868000",
  },
  {
    id: 112937,
    name: "Brownville",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "45.30700000",
    longitude: "-69.03337000",
  },
  {
    id: 112950,
    name: "Brunswick",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.91452000",
    longitude: "-69.96533000",
  },
  {
    id: 112979,
    name: "Buckfield",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.28951000",
    longitude: "-70.36534000",
  },
  {
    id: 112992,
    name: "Bucksport",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.57369000",
    longitude: "-68.79559000",
  },
  {
    id: 113084,
    name: "Burnham",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.69284000",
    longitude: "-69.42755000",
  },
  {
    id: 113132,
    name: "Buxton",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.63786000",
    longitude: "-70.51894000",
  },
  {
    id: 113175,
    name: "Calais",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "45.18376000",
    longitude: "-67.27662000",
  },
  {
    id: 113266,
    name: "Camden",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.20980000",
    longitude: "-69.06476000",
  },
  {
    id: 113312,
    name: "Canaan",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.76173000",
    longitude: "-69.56144000",
  },
  {
    id: 113346,
    name: "Canton",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.44080000",
    longitude: "-70.31649000",
  },
  {
    id: 113372,
    name: "Cape Neddick",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.19370000",
    longitude: "-70.62089000",
  },
  {
    id: 113394,
    name: "Caribou",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "46.86060000",
    longitude: "-68.01197000",
  },
  {
    id: 113418,
    name: "Carmel",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.79757000",
    longitude: "-69.05115000",
  },
  {
    id: 113542,
    name: "Castine",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.38785000",
    longitude: "-68.79975000",
  },
  {
    id: 113767,
    name: "Charleston",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "45.08506000",
    longitude: "-69.04059000",
  },
  {
    id: 113832,
    name: "Chelsea",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.25035000",
    longitude: "-69.71727000",
  },
  {
    id: 113866,
    name: "Cherryfield",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.60730000",
    longitude: "-67.92584000",
  },
  {
    id: 113911,
    name: "Chesterville",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.55117000",
    longitude: "-70.08617000",
  },
  {
    id: 113960,
    name: "China",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.47868000",
    longitude: "-69.51726000",
  },
  {
    id: 113980,
    name: "Chisholm",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.48145000",
    longitude: "-70.19950000",
  },
  {
    id: 114281,
    name: "Clinton",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.63784000",
    longitude: "-69.50310000",
  },
  {
    id: 114642,
    name: "Corinna",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.92117000",
    longitude: "-69.26171000",
  },
  {
    id: 114655,
    name: "Cornish",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.80480000",
    longitude: "-70.80117000",
  },
  {
    id: 114656,
    name: "Cornville",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.83673000",
    longitude: "-69.67311000",
  },
  {
    id: 114911,
    name: "Cumberland Center",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.79647000",
    longitude: "-70.25894000",
  },
  {
    id: 114918,
    name: "Cumberland County",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.80608000",
    longitude: "-70.33020000",
  },
  {
    id: 114932,
    name: "Cushing",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.01925000",
    longitude: "-69.23977000",
  },
  {
    id: 115008,
    name: "Damariscotta",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.03286000",
    longitude: "-69.51866000",
  },
  {
    id: 115100,
    name: "Dayton",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.54972000",
    longitude: "-70.57555000",
  },
  {
    id: 115168,
    name: "Dedham",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.69174000",
    longitude: "-68.66198000",
  },
  {
    id: 115172,
    name: "Deer Isle",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.22397000",
    longitude: "-68.67753000",
  },
  {
    id: 115240,
    name: "Denmark",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.97035000",
    longitude: "-70.80340000",
  },
  {
    id: 115308,
    name: "Dexter",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "45.02395000",
    longitude: "-69.28977000",
  },
  {
    id: 115359,
    name: "Dixfield",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.53395000",
    longitude: "-70.45590000",
  },
  {
    id: 115362,
    name: "Dixmont",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.68035000",
    longitude: "-69.16282000",
  },
  {
    id: 115457,
    name: "Dover-Foxcroft",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "45.18339000",
    longitude: "-69.22699000",
  },
  {
    id: 115707,
    name: "East Machias",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.73924000",
    longitude: "-67.38999000",
  },
  {
    id: 115713,
    name: "East Millinocket",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "45.62755000",
    longitude: "-68.57448000",
  },
  {
    id: 115788,
    name: "Easton",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "46.64115000",
    longitude: "-67.90947000",
  },
  {
    id: 115795,
    name: "Eastport",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.90619000",
    longitude: "-66.98998000",
  },
  {
    id: 115821,
    name: "Eddington",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.82618000",
    longitude: "-68.69337000",
  },
  {
    id: 115840,
    name: "Edgecomb",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.95841000",
    longitude: "-69.63060000",
  },
  {
    id: 115970,
    name: "Eliot",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.15314000",
    longitude: "-70.80006000",
  },
  {
    id: 116038,
    name: "Ellsworth",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.54341000",
    longitude: "-68.41946000",
  },
  {
    id: 116126,
    name: "Enfield",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "45.24894000",
    longitude: "-68.56836000",
  },
  {
    id: 116215,
    name: "Etna",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.82090000",
    longitude: "-69.11115000",
  },
  {
    id: 116314,
    name: "Fairfield",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.58840000",
    longitude: "-69.59866000",
  },
  {
    id: 116389,
    name: "Falmouth",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.72953000",
    longitude: "-70.24199000",
  },
  {
    id: 116390,
    name: "Falmouth Foreside",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.73480000",
    longitude: "-70.20783000",
  },
  {
    id: 116409,
    name: "Farmingdale",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.24451000",
    longitude: "-69.77143000",
  },
  {
    id: 116418,
    name: "Farmington",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.67062000",
    longitude: "-70.15117000",
  },
  {
    id: 116442,
    name: "Fayette",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.40896000",
    longitude: "-70.03367000",
  },
  {
    id: 116710,
    name: "Fort Fairfield",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "46.77227000",
    longitude: "-67.83391000",
  },
  {
    id: 116720,
    name: "Fort Kent",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "47.25865000",
    longitude: "-68.58949000",
  },
  {
    id: 116822,
    name: "Frankfort",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.60980000",
    longitude: "-68.87670000",
  },
  {
    id: 116835,
    name: "Franklin",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.58702000",
    longitude: "-68.23224000",
  },
  {
    id: 116859,
    name: "Franklin County",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.97417000",
    longitude: "-70.44410000",
  },
  {
    id: 116913,
    name: "Freeport",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.85702000",
    longitude: "-70.10311000",
  },
  {
    id: 116936,
    name: "Frenchville",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "47.28087000",
    longitude: "-68.37976000",
  },
  {
    id: 116948,
    name: "Friendship",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.98369000",
    longitude: "-69.33394000",
  },
  {
    id: 116978,
    name: "Fryeburg",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.01646000",
    longitude: "-70.98062000",
  },
  {
    id: 117073,
    name: "Gardiner",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.23007000",
    longitude: "-69.77532000",
  },
  {
    id: 117089,
    name: "Garland",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "45.03840000",
    longitude: "-69.16032000",
  },
  {
    id: 117403,
    name: "Gorham",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.67952000",
    longitude: "-70.44422000",
  },
  {
    id: 117418,
    name: "Gouldsboro",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.47841000",
    longitude: "-68.03834000",
  },
  {
    id: 117623,
    name: "Greenbush",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "45.08034000",
    longitude: "-68.65086000",
  },
  {
    id: 117629,
    name: "Greene",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.18979000",
    longitude: "-70.14033000",
  },
  {
    id: 117691,
    name: "Greenville",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "45.45949000",
    longitude: "-69.59061000",
  },
  {
    id: 117872,
    name: "Hallowell",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.28590000",
    longitude: "-69.79088000",
  },
  {
    id: 117917,
    name: "Hampden",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.74452000",
    longitude: "-68.83698000",
  },
  {
    id: 117948,
    name: "Hancock",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.52924000",
    longitude: "-68.25363000",
  },
  {
    id: 117957,
    name: "Hancock County",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.56289000",
    longitude: "-68.36821000",
  },
  {
    id: 118037,
    name: "Harpswell Center",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.80175000",
    longitude: "-69.98421000",
  },
  {
    id: 118056,
    name: "Harrison",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.11035000",
    longitude: "-70.67923000",
  },
  {
    id: 118089,
    name: "Hartford",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.37284000",
    longitude: "-70.34673000",
  },
  {
    id: 118237,
    name: "Hebron",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.19813000",
    longitude: "-70.40645000",
  },
  {
    id: 118330,
    name: "Hermon",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.81007000",
    longitude: "-68.91337000",
  },
  {
    id: 118491,
    name: "Hiram",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.87868000",
    longitude: "-70.80340000",
  },
  {
    id: 118506,
    name: "Hodgdon",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "46.05394000",
    longitude: "-67.86668000",
  },
  {
    id: 118524,
    name: "Holden",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.75285000",
    longitude: "-68.67892000",
  },
  {
    id: 118550,
    name: "Hollis Center",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.60508000",
    longitude: "-70.59311000",
  },
  {
    id: 118644,
    name: "Hope",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.26508000",
    longitude: "-69.15893000",
  },
  {
    id: 118693,
    name: "Houlton",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "46.12616000",
    longitude: "-67.84030000",
  },
  {
    id: 118725,
    name: "Howland",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "45.23867000",
    longitude: "-68.66364000",
  },
  {
    id: 118742,
    name: "Hudson",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "45.00118000",
    longitude: "-68.88059000",
  },
  {
    id: 119154,
    name: "Jay",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.50395000",
    longitude: "-70.21617000",
  },
  {
    id: 119169,
    name: "Jefferson",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.20674000",
    longitude: "-69.45254000",
  },
  {
    id: 119305,
    name: "Jonesport",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.53286000",
    longitude: "-67.59833000",
  },
  {
    id: 119452,
    name: "Kenduskeag",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.91951000",
    longitude: "-68.93170000",
  },
  {
    id: 119465,
    name: "Kennebec County",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.40916000",
    longitude: "-69.76726000",
  },
  {
    id: 119466,
    name: "Kennebunk",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.38397000",
    longitude: "-70.54478000",
  },
  {
    id: 119467,
    name: "Kennebunkport",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.36175000",
    longitude: "-70.47672000",
  },
  {
    id: 119591,
    name: "Kingfield",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.95922000",
    longitude: "-70.15395000",
  },
  {
    id: 119662,
    name: "Kittery",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.08814000",
    longitude: "-70.73616000",
  },
  {
    id: 119663,
    name: "Kittery Point",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.08342000",
    longitude: "-70.70783000",
  },
  {
    id: 119690,
    name: "Knox County",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.07575000",
    longitude: "-69.12598000",
  },
  {
    id: 119865,
    name: "Lake Arrowhead",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.66369000",
    longitude: "-70.73478000",
  },
  {
    id: 120290,
    name: "Lebanon",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.39453000",
    longitude: "-70.85089000",
  },
  {
    id: 120321,
    name: "Leeds",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.30340000",
    longitude: "-70.11950000",
  },
  {
    id: 120401,
    name: "Levant",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.86924000",
    longitude: "-68.93476000",
  },
  {
    id: 120423,
    name: "Lewiston",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.10035000",
    longitude: "-70.21478000",
  },
  {
    id: 120490,
    name: "Limerick",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.68841000",
    longitude: "-70.79367000",
  },
  {
    id: 120492,
    name: "Limestone",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "46.90866000",
    longitude: "-67.82585000",
  },
  {
    id: 120497,
    name: "Limington",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.73174000",
    longitude: "-70.71089000",
  },
  {
    id: 120505,
    name: "Lincoln",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "45.36228000",
    longitude: "-68.50502000",
  },
  {
    id: 120526,
    name: "Lincoln County",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.99779000",
    longitude: "-69.52576000",
  },
  {
    id: 120554,
    name: "Lincolnville",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.28119000",
    longitude: "-69.00865000",
  },
  {
    id: 120599,
    name: "Lisbon",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.03146000",
    longitude: "-70.10450000",
  },
  {
    id: 120601,
    name: "Lisbon Falls",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.99619000",
    longitude: "-70.06061000",
  },
  {
    id: 120643,
    name: "Livermore",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.38396000",
    longitude: "-70.24922000",
  },
  {
    id: 120645,
    name: "Livermore Falls",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.47534000",
    longitude: "-70.18811000",
  },
  {
    id: 120823,
    name: "Lovell",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.12674000",
    longitude: "-70.89173000",
  },
  {
    id: 120956,
    name: "Machias",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.71508000",
    longitude: "-67.46138000",
  },
  {
    id: 120958,
    name: "Machiasport",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.69869000",
    longitude: "-67.39471000",
  },
  {
    id: 120976,
    name: "Madawaska",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "46.88421000",
    longitude: "-67.94725000",
  },
  {
    id: 120995,
    name: "Madison",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.79756000",
    longitude: "-69.87978000",
  },
  {
    id: 121097,
    name: "Manchester",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.32451000",
    longitude: "-69.86033000",
  },
  {
    id: 121579,
    name: "Mechanic Falls",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.11174000",
    longitude: "-70.39172000",
  },
  {
    id: 121611,
    name: "Medway",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "45.60894000",
    longitude: "-68.53086000",
  },
  {
    id: 121742,
    name: "Mexico",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.56090000",
    longitude: "-70.54534000",
  },
  {
    id: 121843,
    name: "Milbridge",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.53536000",
    longitude: "-67.88083000",
  },
  {
    id: 121853,
    name: "Milford",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.94618000",
    longitude: "-68.64392000",
  },
  {
    id: 121893,
    name: "Millinocket",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "45.65727000",
    longitude: "-68.70976000",
  },
  {
    id: 121908,
    name: "Milo",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "45.25366000",
    longitude: "-68.98587000",
  },
  {
    id: 121965,
    name: "Minot",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.08563000",
    longitude: "-70.32006000",
  },
  {
    id: 122043,
    name: "Monmouth",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.23868000",
    longitude: "-70.03561000",
  },
  {
    id: 122401,
    name: "Mount Vernon",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.50118000",
    longitude: "-69.98756000",
  },
  {
    id: 122709,
    name: "New Gloucester",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.96285000",
    longitude: "-70.28255000",
  },
  {
    id: 122777,
    name: "New Sharon",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.63895000",
    longitude: "-70.01561000",
  },
  {
    id: 122829,
    name: "Newfield",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.64813000",
    longitude: "-70.84701000",
  },
  {
    id: 122846,
    name: "Newport",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.83534000",
    longitude: "-69.27394000",
  },
  {
    id: 122919,
    name: "Nobleboro",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.07952000",
    longitude: "-69.48505000",
  },
  {
    id: 122954,
    name: "Norridgewock",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.71312000",
    longitude: "-69.79061000",
  },
  {
    id: 122974,
    name: "North Bath",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.93480000",
    longitude: "-69.81588000",
  },
  {
    id: 122988,
    name: "North Berwick",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.30370000",
    longitude: "-70.73339000",
  },
  {
    id: 123131,
    name: "North Windham",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.83424000",
    longitude: "-70.43839000",
  },
  {
    id: 123161,
    name: "Northport",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.33786000",
    longitude: "-68.96142000",
  },
  {
    id: 123193,
    name: "Norway",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.21396000",
    longitude: "-70.54478000",
  },
  {
    id: 123289,
    name: "Oakland",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.54034000",
    longitude: "-69.72199000",
  },
  {
    id: 123380,
    name: "Ogunquit",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.24898000",
    longitude: "-70.59922000",
  },
  {
    id: 123425,
    name: "Old Orchard Beach",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.51731000",
    longitude: "-70.37755000",
  },
  {
    id: 123430,
    name: "Old Town",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.93423000",
    longitude: "-68.64531000",
  },
  {
    id: 123558,
    name: "Orland",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.57035000",
    longitude: "-68.73586000",
  },
  {
    id: 123574,
    name: "Orono",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.88312000",
    longitude: "-68.67198000",
  },
  {
    id: 123581,
    name: "Orrington",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.73118000",
    longitude: "-68.82643000",
  },
  {
    id: 123671,
    name: "Owls Head",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.08230000",
    longitude: "-69.05726000",
  },
  {
    id: 123684,
    name: "Oxford",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.13174000",
    longitude: "-70.49311000",
  },
  {
    id: 123688,
    name: "Oxford County",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.49977000",
    longitude: "-70.75657000",
  },
  {
    id: 123734,
    name: "Palermo",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.40785000",
    longitude: "-69.47393000",
  },
  {
    id: 123774,
    name: "Palmyra",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.84645000",
    longitude: "-69.35866000",
  },
  {
    id: 123835,
    name: "Paris",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.25979000",
    longitude: "-70.50062000",
  },
  {
    id: 123894,
    name: "Parsonsfield",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.72702000",
    longitude: "-70.92868000",
  },
  {
    id: 123914,
    name: "Patten",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "45.99644000",
    longitude: "-68.44614000",
  },
  {
    id: 124041,
    name: "Penobscot",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.46452000",
    longitude: "-68.71114000",
  },
  {
    id: 124042,
    name: "Penobscot County",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "45.40051000",
    longitude: "-68.64943000",
  },
  {
    id: 124097,
    name: "Peru",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.50673000",
    longitude: "-70.40534000",
  },
  {
    id: 124133,
    name: "Phillips",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.82311000",
    longitude: "-70.33951000",
  },
  {
    id: 124145,
    name: "Phippsburg",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.82064000",
    longitude: "-69.81477000",
  },
  {
    id: 124281,
    name: "Piscataquis County",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "45.83736000",
    longitude: "-69.28452000",
  },
  {
    id: 124299,
    name: "Pittsfield",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.78256000",
    longitude: "-69.38338000",
  },
  {
    id: 124303,
    name: "Pittston",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.22174000",
    longitude: "-69.75560000",
  },
  {
    id: 124404,
    name: "Plymouth",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.76729000",
    longitude: "-69.21033000",
  },
  {
    id: 124437,
    name: "Poland",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.06063000",
    longitude: "-70.39367000",
  },
  {
    id: 124552,
    name: "Porter",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.79591000",
    longitude: "-70.93256000",
  },
  {
    id: 124562,
    name: "Portland",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.65737000",
    longitude: "-70.25890000",
  },
  {
    id: 124649,
    name: "Presque Isle",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "46.68115000",
    longitude: "-68.01586000",
  },
  {
    id: 124866,
    name: "Randolph",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.23035000",
    longitude: "-69.76671000",
  },
  {
    id: 124918,
    name: "Raymond",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.90146000",
    longitude: "-70.47033000",
  },
  {
    id: 124928,
    name: "Readfield",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.38785000",
    longitude: "-69.96672000",
  },
  {
    id: 125091,
    name: "Richmond",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.08730000",
    longitude: "-69.79893000",
  },
  {
    id: 125308,
    name: "Rockland",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.10369000",
    longitude: "-69.10893000",
  },
  {
    id: 125318,
    name: "Rockport",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.18452000",
    longitude: "-69.07615000",
  },
  {
    id: 125377,
    name: "Rome",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.58506000",
    longitude: "-69.86922000",
  },
  {
    id: 125507,
    name: "Rumford",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.55367000",
    longitude: "-70.55090000",
  },
  {
    id: 125566,
    name: "Sabattus",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.11980000",
    longitude: "-70.10755000",
  },
  {
    id: 125576,
    name: "Saco",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.50092000",
    longitude: "-70.44283000",
  },
  {
    id: 125585,
    name: "Sagadahoc County",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.91173000",
    longitude: "-69.83931000",
  },
  {
    id: 125595,
    name: "Saint Albans",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.91006000",
    longitude: "-69.41005000",
  },
  {
    id: 125643,
    name: "Saint George",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.01647000",
    longitude: "-69.19893000",
  },
  {
    id: 125888,
    name: "Sanford",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.43925000",
    longitude: "-70.77422000",
  },
  {
    id: 125893,
    name: "Sangerville",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "45.16478000",
    longitude: "-69.35644000",
  },
  {
    id: 125997,
    name: "Scarborough",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.57814000",
    longitude: "-70.32172000",
  },
  {
    id: 126096,
    name: "Searsmont",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.36174000",
    longitude: "-69.19504000",
  },
  {
    id: 126118,
    name: "Sedgwick",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.30369000",
    longitude: "-68.61614000",
  },
  {
    id: 126224,
    name: "Shapleigh",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.54064000",
    longitude: "-70.84812000",
  },
  {
    id: 126396,
    name: "Sidney",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.41312000",
    longitude: "-69.72893000",
  },
  {
    id: 126484,
    name: "Skowhegan",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.76506000",
    longitude: "-69.71922000",
  },
  {
    id: 126587,
    name: "Somerset County",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "45.51385000",
    longitude: "-69.95882000",
  },
  {
    id: 126626,
    name: "South Berwick",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.23453000",
    longitude: "-70.80950000",
  },
  {
    id: 126652,
    name: "South Eliot",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.10814000",
    longitude: "-70.77755000",
  },
  {
    id: 126702,
    name: "South Paris",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.22368000",
    longitude: "-70.51339000",
  },
  {
    id: 126713,
    name: "South Portland",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.64147000",
    longitude: "-70.24088000",
  },
  {
    id: 126714,
    name: "South Portland Gardens",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.63897000",
    longitude: "-70.31533000",
  },
  {
    id: 126726,
    name: "South Sanford",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.41119000",
    longitude: "-70.74256000",
  },
  {
    id: 126735,
    name: "South Thomaston",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.05147000",
    longitude: "-69.12782000",
  },
  {
    id: 126752,
    name: "South Windham",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.73619000",
    longitude: "-70.42366000",
  },
  {
    id: 126912,
    name: "Springvale",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.46675000",
    longitude: "-70.79367000",
  },
  {
    id: 127015,
    name: "Steep Falls",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.79397000",
    longitude: "-70.65256000",
  },
  {
    id: 127036,
    name: "Stetson",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.89173000",
    longitude: "-69.14282000",
  },
  {
    id: 127037,
    name: "Steuben",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.51098000",
    longitude: "-67.96662000",
  },
  {
    id: 127075,
    name: "Stockton Springs",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.48952000",
    longitude: "-68.85698000",
  },
  {
    id: 127095,
    name: "Stonington",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.15619000",
    longitude: "-68.66669000",
  },
  {
    id: 127138,
    name: "Strong",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.80756000",
    longitude: "-70.22090000",
  },
  {
    id: 127184,
    name: "Sullivan",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.52036000",
    longitude: "-68.19668000",
  },
  {
    id: 127293,
    name: "Surry",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.49591000",
    longitude: "-68.50169000",
  },
  {
    id: 127328,
    name: "Swanville",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.52119000",
    longitude: "-68.99781000",
  },
  {
    id: 127585,
    name: "Thomaston",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.07897000",
    longitude: "-69.18171000",
  },
  {
    id: 127736,
    name: "Topsham",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.92758000",
    longitude: "-69.97588000",
  },
  {
    id: 127794,
    name: "Tremont",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.25369000",
    longitude: "-68.35141000",
  },
  {
    id: 127807,
    name: "Trenton",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.43897000",
    longitude: "-68.37002000",
  },
  {
    id: 127849,
    name: "Troy",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.66479000",
    longitude: "-69.24088000",
  },
  {
    id: 127901,
    name: "Turner",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.25646000",
    longitude: "-70.25617000",
  },
  {
    id: 127975,
    name: "Union",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.21147000",
    longitude: "-69.27421000",
  },
  {
    id: 128128,
    name: "Van Buren",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "47.15727000",
    longitude: "-67.93530000",
  },
  {
    id: 128158,
    name: "Vassalboro",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.45923000",
    longitude: "-69.67755000",
  },
  {
    id: 128160,
    name: "Veazie",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.83868000",
    longitude: "-68.70531000",
  },
  {
    id: 128262,
    name: "Vinalhaven",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.04814000",
    longitude: "-68.83170000",
  },
  {
    id: 128372,
    name: "Waldo County",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.48525000",
    longitude: "-69.12188000",
  },
  {
    id: 128373,
    name: "Waldoboro",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.09536000",
    longitude: "-69.37560000",
  },
  {
    id: 128480,
    name: "Warren",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.12036000",
    longitude: "-69.24005000",
  },
  {
    id: 128547,
    name: "Washington",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.27369000",
    longitude: "-69.36727000",
  },
  {
    id: 128569,
    name: "Washington County",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.96946000",
    longitude: "-67.60906000",
  },
  {
    id: 128601,
    name: "Waterboro",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.53564000",
    longitude: "-70.71506000",
  },
  {
    id: 128626,
    name: "Waterville",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.55201000",
    longitude: "-69.63171000",
  },
  {
    id: 128684,
    name: "Wayne",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.34868000",
    longitude: "-70.06616000",
  },
  {
    id: 128784,
    name: "Wells Beach Station",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.32397000",
    longitude: "-70.59144000",
  },
  {
    id: 128906,
    name: "West Kennebunk",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.40870000",
    longitude: "-70.58144000",
  },
  {
    id: 128948,
    name: "West Paris",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.32423000",
    longitude: "-70.57395000",
  },
  {
    id: 128977,
    name: "West Scarborough",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.57036000",
    longitude: "-70.38783000",
  },
  {
    id: 129013,
    name: "Westbrook",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.67703000",
    longitude: "-70.37116000",
  },
  {
    id: 129164,
    name: "Whitefield",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.17007000",
    longitude: "-69.62532000",
  },
  {
    id: 129344,
    name: "Wilton",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.59284000",
    longitude: "-70.22812000",
  },
  {
    id: 129383,
    name: "Windsor",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.31063000",
    longitude: "-69.58060000",
  },
  {
    id: 129428,
    name: "Winslow",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.54701000",
    longitude: "-69.62116000",
  },
  {
    id: 129443,
    name: "Winterport",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.63785000",
    longitude: "-68.84504000",
  },
  {
    id: 129449,
    name: "Winthrop",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.30507000",
    longitude: "-69.97700000",
  },
  {
    id: 129456,
    name: "Wiscasset",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.00286000",
    longitude: "-69.66560000",
  },
  {
    id: 129567,
    name: "Woodstock",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "44.37494000",
    longitude: "-70.60849000",
  },
  {
    id: 129581,
    name: "Woolwich",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.91869000",
    longitude: "-69.80116000",
  },
  {
    id: 129666,
    name: "Yarmouth",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.80064000",
    longitude: "-70.18672000",
  },
  {
    id: 129694,
    name: "York Beach",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.17148000",
    longitude: "-70.60894000",
  },
  {
    id: 129697,
    name: "York County",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.42923000",
    longitude: "-70.67015000",
  },
  {
    id: 129700,
    name: "York Harbor",
    state_id: 1453,
    state_code: "ME",
    country_id: 233,
    country_code: "US",
    latitude: "43.13676000",
    longitude: "-70.64561000",
  },
  {
    id: 110973,
    name: "Aberdeen",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.50956000",
    longitude: "-76.16412000",
  },
  {
    id: 110980,
    name: "Aberdeen Proving Ground",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.46686000",
    longitude: "-76.13066000",
  },
  {
    id: 110993,
    name: "Accokeek",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.66762000",
    longitude: "-77.02831000",
  },
  {
    id: 111029,
    name: "Adamstown",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.31094000",
    longitude: "-77.47471000",
  },
  {
    id: 111044,
    name: "Adelphi",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.00317000",
    longitude: "-76.97192000",
  },
  {
    id: 111166,
    name: "Algonquin",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.58290000",
    longitude: "-76.10577000",
  },
  {
    id: 111181,
    name: "Allegany County",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.62148000",
    longitude: "-78.69890000",
  },
  {
    id: 111332,
    name: "Andrews AFB",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.80531000",
    longitude: "-76.87460000",
  },
  {
    id: 111352,
    name: "Annapolis",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.97845000",
    longitude: "-76.49218000",
  },
  {
    id: 111353,
    name: "Anne Arundel County",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.99416000",
    longitude: "-76.56760000",
  },
  {
    id: 111417,
    name: "Arbutus",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.25455000",
    longitude: "-76.69997000",
  },
  {
    id: 111439,
    name: "Arden on the Severn",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.06594000",
    longitude: "-76.57885000",
  },
  {
    id: 111461,
    name: "Arlington",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.34857000",
    longitude: "-76.68324000",
  },
  {
    id: 111485,
    name: "Arnold",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.03206000",
    longitude: "-76.50274000",
  },
  {
    id: 111547,
    name: "Ashton-Sandy Spring",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.14976000",
    longitude: "-77.00504000",
  },
  {
    id: 111553,
    name: "Aspen Hill",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.07955000",
    longitude: "-77.07303000",
  },
  {
    id: 111722,
    name: "Baden",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.65928000",
    longitude: "-76.77775000",
  },
  {
    id: 111772,
    name: "Ballenger Creek",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.37260000",
    longitude: "-77.43526000",
  },
  {
    id: 111783,
    name: "Baltimore",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.29038000",
    longitude: "-76.61219000",
  },
  {
    id: 111784,
    name: "Baltimore County",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.44307000",
    longitude: "-76.61632000",
  },
  {
    id: 111785,
    name: "Baltimore Highlands",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.23316000",
    longitude: "-76.63663000",
  },
  {
    id: 111869,
    name: "Bartonsville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.39260000",
    longitude: "-77.35804000",
  },
  {
    id: 112046,
    name: "Bel Air",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.53594000",
    longitude: "-76.34829000",
  },
  {
    id: 112047,
    name: "Bel Air North",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.55429000",
    longitude: "-76.37309000",
  },
  {
    id: 112048,
    name: "Bel Air South",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.50506000",
    longitude: "-76.31977000",
  },
  {
    id: 112151,
    name: "Beltsville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.03483000",
    longitude: "-76.90747000",
  },
  {
    id: 112179,
    name: "Bennsville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.60929000",
    longitude: "-77.01220000",
  },
  {
    id: 112234,
    name: "Berlin",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.32262000",
    longitude: "-75.21769000",
  },
  {
    id: 112266,
    name: "Berwyn Heights",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.99400000",
    longitude: "-76.91053000",
  },
  {
    id: 112288,
    name: "Bethesda",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.98067000",
    longitude: "-77.10026000",
  },
  {
    id: 112400,
    name: "Bladensburg",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.93928000",
    longitude: "-76.93386000",
  },
  {
    id: 112565,
    name: "Boonsboro",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.50621000",
    longitude: "-77.65249000",
  },
  {
    id: 112623,
    name: "Bowie",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.94278000",
    longitude: "-76.73028000",
  },
  {
    id: 112626,
    name: "Bowleys Quarters",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.33539000",
    longitude: "-76.39024000",
  },
  {
    id: 112629,
    name: "Bowling Green",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.62370000",
    longitude: "-78.80446000",
  },
  {
    id: 112660,
    name: "Braddock Heights",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.41871000",
    longitude: "-77.50360000",
  },
  {
    id: 112692,
    name: "Brandywine",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.69678000",
    longitude: "-76.84775000",
  },
  {
    id: 112728,
    name: "Brentwood",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.94317000",
    longitude: "-76.95664000",
  },
  {
    id: 112832,
    name: "Brock Hall",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.85011000",
    longitude: "-76.76108000",
  },
  {
    id: 112876,
    name: "Brooklyn Park",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.22844000",
    longitude: "-76.61636000",
  },
  {
    id: 112878,
    name: "Brookmont",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.94206000",
    longitude: "-77.12026000",
  },
  {
    id: 112948,
    name: "Brunswick",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.31427000",
    longitude: "-77.62777000",
  },
  {
    id: 112962,
    name: "Bryans Road",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.62706000",
    longitude: "-77.07303000",
  },
  {
    id: 112978,
    name: "Buckeystown",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.33482000",
    longitude: "-77.43165000",
  },
  {
    id: 113098,
    name: "Burtonsville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.11122000",
    longitude: "-76.93248000",
  },
  {
    id: 113105,
    name: "Butcher's Hill",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.28955000",
    longitude: "-76.58830000",
  },
  {
    id: 113152,
    name: "Cabin John",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.97539000",
    longitude: "-77.15803000",
  },
  {
    id: 113216,
    name: "California",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.30040000",
    longitude: "-76.50745000",
  },
  {
    id: 113235,
    name: "Calvert County",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.53471000",
    longitude: "-76.53056000",
  },
  {
    id: 113236,
    name: "Calverton",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.05761000",
    longitude: "-76.93581000",
  },
  {
    id: 113250,
    name: "Cambridge",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.56317000",
    longitude: "-76.07883000",
  },
  {
    id: 113293,
    name: "Camp Springs",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.80400000",
    longitude: "-76.90664000",
  },
  {
    id: 113373,
    name: "Cape Saint Claire",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.04317000",
    longitude: "-76.44496000",
  },
  {
    id: 113375,
    name: "Capitol Heights",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.88511000",
    longitude: "-76.91581000",
  },
  {
    id: 113428,
    name: "Carney",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.39427000",
    longitude: "-76.52358000",
  },
  {
    id: 113437,
    name: "Caroline County",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.87173000",
    longitude: "-75.83160000",
  },
  {
    id: 113453,
    name: "Carroll County",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.56286000",
    longitude: "-77.02252000",
  },
  {
    id: 113575,
    name: "Catonsville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.27205000",
    longitude: "-76.73192000",
  },
  {
    id: 113590,
    name: "Cavetown",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.64426000",
    longitude: "-77.58582000",
  },
  {
    id: 113598,
    name: "Cecil County",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.56242000",
    longitude: "-75.94811000",
  },
  {
    id: 113693,
    name: "Centreville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.04178000",
    longitude: "-76.06633000",
  },
  {
    id: 113757,
    name: "Charles County",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.47368000",
    longitude: "-77.01348000",
  },
  {
    id: 113760,
    name: "Charles Village",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.32316000",
    longitude: "-76.61330000",
  },
  {
    id: 113771,
    name: "Charlestown",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.57373000",
    longitude: "-75.97495000",
  },
  {
    id: 113784,
    name: "Charlotte Hall",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.48096000",
    longitude: "-76.77802000",
  },
  {
    id: 113875,
    name: "Chesapeake Beach",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.68623000",
    longitude: "-76.53468000",
  },
  {
    id: 113876,
    name: "Chesapeake Ranch Estates",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.34624000",
    longitude: "-76.41773000",
  },
  {
    id: 113883,
    name: "Chester",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.97539000",
    longitude: "-76.28940000",
  },
  {
    id: 113910,
    name: "Chestertown",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.20900000",
    longitude: "-76.06661000",
  },
  {
    id: 113919,
    name: "Cheverly",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.92817000",
    longitude: "-76.91581000",
  },
  {
    id: 113921,
    name: "Chevy Chase",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.00287000",
    longitude: "-77.07115000",
  },
  {
    id: 113923,
    name: "Chevy Chase Village",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.96928000",
    longitude: "-77.07887000",
  },
  {
    id: 113955,
    name: "Chillum",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.96372000",
    longitude: "-76.99081000",
  },
  {
    id: 114048,
    name: "City of Baltimore",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.29038000",
    longitude: "-76.61219000",
  },
  {
    id: 114143,
    name: "Clarksburg",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.23872000",
    longitude: "-77.27943000",
  },
  {
    id: 114272,
    name: "Clinton",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.76511000",
    longitude: "-76.89831000",
  },
  {
    id: 114308,
    name: "Clover Hill",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.45621000",
    longitude: "-77.42887000",
  },
  {
    id: 114313,
    name: "Cloverly",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.10816000",
    longitude: "-76.99775000",
  },
  {
    id: 114345,
    name: "Cobb Island",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.25846000",
    longitude: "-76.84386000",
  },
  {
    id: 114355,
    name: "Cockeysville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.48122000",
    longitude: "-76.64386000",
  },
  {
    id: 114403,
    name: "Colesville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.07566000",
    longitude: "-77.00192000",
  },
  {
    id: 114414,
    name: "College Park",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.98067000",
    longitude: "-76.93692000",
  },
  {
    id: 114441,
    name: "Colmar Manor",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.93317000",
    longitude: "-76.94581000",
  },
  {
    id: 114467,
    name: "Columbia",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.24038000",
    longitude: "-76.83942000",
  },
  {
    id: 114625,
    name: "Coral Hills",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.87039000",
    longitude: "-76.92108000",
  },
  {
    id: 114690,
    name: "Cottage City",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.93817000",
    longitude: "-76.94831000",
  },
  {
    id: 114793,
    name: "Cresaptown",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.59287000",
    longitude: "-78.83335000",
  },
  {
    id: 114822,
    name: "Crisfield",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "37.98346000",
    longitude: "-75.85382000",
  },
  {
    id: 114833,
    name: "Crofton",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.00178000",
    longitude: "-76.68747000",
  },
  {
    id: 114841,
    name: "Croom",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.75262000",
    longitude: "-76.76386000",
  },
  {
    id: 114869,
    name: "Crownsville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.02844000",
    longitude: "-76.60135000",
  },
  {
    id: 114907,
    name: "Cumberland",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.65287000",
    longitude: "-78.76252000",
  },
  {
    id: 115009,
    name: "Damascus",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.28844000",
    longitude: "-77.20387000",
  },
  {
    id: 115053,
    name: "Darnestown",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.10344000",
    longitude: "-77.29082000",
  },
  {
    id: 115065,
    name: "Davidsonville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.92289000",
    longitude: "-76.62830000",
  },
  {
    id: 115144,
    name: "Deale",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.77651000",
    longitude: "-76.55524000",
  },
  {
    id: 115245,
    name: "Denton",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.88456000",
    longitude: "-75.82716000",
  },
  {
    id: 115268,
    name: "Derwood",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.11733000",
    longitude: "-77.16109000",
  },
  {
    id: 115355,
    name: "District Heights",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.85761000",
    longitude: "-76.88942000",
  },
  {
    id: 115410,
    name: "Dorchester County",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.42261000",
    longitude: "-76.08332000",
  },
  {
    id: 115481,
    name: "Drum Point",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.32679000",
    longitude: "-76.42606000",
  },
  {
    id: 115526,
    name: "Dundalk",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.25066000",
    longitude: "-76.52052000",
  },
  {
    id: 115537,
    name: "Dunkirk",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.72178000",
    longitude: "-76.66052000",
  },
  {
    id: 115540,
    name: "Dunkirk Town Center",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.72039000",
    longitude: "-76.65857000",
  },
  {
    id: 115748,
    name: "East Riverdale",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.96206000",
    longitude: "-76.92192000",
  },
  {
    id: 115785,
    name: "Easton",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.77428000",
    longitude: "-76.07633000",
  },
  {
    id: 115844,
    name: "Edgemere",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.24205000",
    longitude: "-76.44802000",
  },
  {
    id: 115852,
    name: "Edgewater",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.95706000",
    longitude: "-76.54996000",
  },
  {
    id: 115859,
    name: "Edgewood",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.41872000",
    longitude: "-76.29440000",
  },
  {
    id: 115880,
    name: "Edmonston",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.94678000",
    longitude: "-76.93109000",
  },
  {
    id: 115949,
    name: "Eldersburg",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.40371000",
    longitude: "-76.95026000",
  },
  {
    id: 116011,
    name: "Elkridge",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.21261000",
    longitude: "-76.71358000",
  },
  {
    id: 116013,
    name: "Elkton",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.60678000",
    longitude: "-75.83327000",
  },
  {
    id: 116024,
    name: "Ellicott City",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.26733000",
    longitude: "-76.79831000",
  },
  {
    id: 116106,
    name: "Emmitsburg",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.70454000",
    longitude: "-77.32693000",
  },
  {
    id: 116191,
    name: "Essex",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.30927000",
    longitude: "-76.47496000",
  },
  {
    id: 116328,
    name: "Fairland",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.07622000",
    longitude: "-76.95775000",
  },
  {
    id: 116342,
    name: "Fairmount Heights",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.90095000",
    longitude: "-76.91553000",
  },
  {
    id: 116364,
    name: "Fairwood",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.95665000",
    longitude: "-76.77772000",
  },
  {
    id: 116385,
    name: "Fallston",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.51455000",
    longitude: "-76.41107000",
  },
  {
    id: 116466,
    name: "Federalsburg",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.69428000",
    longitude: "-75.77216000",
  },
  {
    id: 116486,
    name: "Ferndale",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.18316000",
    longitude: "-76.64024000",
  },
  {
    id: 116652,
    name: "Forest Glen",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.01455000",
    longitude: "-77.05470000",
  },
  {
    id: 116654,
    name: "Forest Heights",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.80956000",
    longitude: "-76.99803000",
  },
  {
    id: 116672,
    name: "Forestville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.84511000",
    longitude: "-76.87497000",
  },
  {
    id: 116712,
    name: "Fort George G Mead Junction",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.12594000",
    longitude: "-76.78914000",
  },
  {
    id: 116729,
    name: "Fort Meade",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.10815000",
    longitude: "-76.74323000",
  },
  {
    id: 116761,
    name: "Fort Washington",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.70734000",
    longitude: "-77.02303000",
  },
  {
    id: 116786,
    name: "Fountainhead-Orchard Hills",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.68636000",
    longitude: "-77.71901000",
  },
  {
    id: 116787,
    name: "Four Corners",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.02039000",
    longitude: "-77.01275000",
  },
  {
    id: 116885,
    name: "Frederick",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.41427000",
    longitude: "-77.41054000",
  },
  {
    id: 116888,
    name: "Frederick County",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.47222000",
    longitude: "-77.39799000",
  },
  {
    id: 116947,
    name: "Friendly",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.75178000",
    longitude: "-76.97859000",
  },
  {
    id: 116951,
    name: "Friendship Village",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.96289000",
    longitude: "-77.08887000",
  },
  {
    id: 116964,
    name: "Frostburg",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.65814000",
    longitude: "-78.92836000",
  },
  {
    id: 116970,
    name: "Fruitland",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.32206000",
    longitude: "-75.62020000",
  },
  {
    id: 116987,
    name: "Fulton",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.15094000",
    longitude: "-76.92303000",
  },
  {
    id: 117019,
    name: "Gaithersburg",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.14344000",
    longitude: "-77.20137000",
  },
  {
    id: 117045,
    name: "Gambrills",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.06705000",
    longitude: "-76.66524000",
  },
  {
    id: 117100,
    name: "Garrett County",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.52860000",
    longitude: "-79.27388000",
  },
  {
    id: 117101,
    name: "Garrett Park",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.03816000",
    longitude: "-77.09303000",
  },
  {
    id: 117102,
    name: "Garrison",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.40594000",
    longitude: "-76.76053000",
  },
  {
    id: 117172,
    name: "Germantown",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.17316000",
    longitude: "-77.27165000",
  },
  {
    id: 117253,
    name: "Glassmanor",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.81900000",
    longitude: "-76.99859000",
  },
  {
    id: 117262,
    name: "Glen Burnie",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.16261000",
    longitude: "-76.62469000",
  },
  {
    id: 117275,
    name: "Glenarden",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.92928000",
    longitude: "-76.86164000",
  },
  {
    id: 117293,
    name: "Glenmont",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.05789000",
    longitude: "-77.04970000",
  },
  {
    id: 117297,
    name: "Glenn Dale",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.98761000",
    longitude: "-76.82053000",
  },
  {
    id: 117333,
    name: "Goddard",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.98955000",
    longitude: "-76.85331000",
  },
  {
    id: 117347,
    name: "Golden Beach",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.48985000",
    longitude: "-76.68218000",
  },
  {
    id: 117541,
    name: "Grasonville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.95817000",
    longitude: "-76.21023000",
  },
  {
    id: 117582,
    name: "Greater Upper Marlboro",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.83142000",
    longitude: "-76.74827000",
  },
  {
    id: 117597,
    name: "Green Haven",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.13955000",
    longitude: "-76.54774000",
  },
  {
    id: 117609,
    name: "Green Valley",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.30927000",
    longitude: "-77.29721000",
  },
  {
    id: 117616,
    name: "Greenbelt",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.00455000",
    longitude: "-76.87553000",
  },
  {
    id: 117666,
    name: "Greensboro",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.97372000",
    longitude: "-75.80493000",
  },
  {
    id: 117838,
    name: "Hagerstown",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.64176000",
    longitude: "-77.71999000",
  },
  {
    id: 117857,
    name: "Halfway",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.62065000",
    longitude: "-77.75888000",
  },
  {
    id: 117923,
    name: "Hampstead",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.60483000",
    longitude: "-76.84998000",
  },
  {
    id: 117928,
    name: "Hampton",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.42288000",
    longitude: "-76.58469000",
  },
  {
    id: 117947,
    name: "Hancock",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.69898000",
    longitude: "-78.17973000",
  },
  {
    id: 117967,
    name: "Hanover",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.19289000",
    longitude: "-76.72414000",
  },
  {
    id: 118012,
    name: "Harford County",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.53644000",
    longitude: "-76.29887000",
  },
  {
    id: 118155,
    name: "Havre de Grace",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.54928000",
    longitude: "-76.09162000",
  },
  {
    id: 118233,
    name: "Hebron",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.42012000",
    longitude: "-75.68771000",
  },
  {
    id: 118312,
    name: "Herald Harbor",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.05372000",
    longitude: "-76.56913000",
  },
  {
    id: 118390,
    name: "Highfield-Cascade",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.71616000",
    longitude: "-77.48282000",
  },
  {
    id: 118395,
    name: "Highland",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.17900000",
    longitude: "-76.95748000",
  },
  {
    id: 118438,
    name: "Hillandale",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.02650000",
    longitude: "-76.97414000",
  },
  {
    id: 118441,
    name: "Hillcrest Heights",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.83289000",
    longitude: "-76.95942000",
  },
  {
    id: 118467,
    name: "Hillsmere Shores",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.94011000",
    longitude: "-76.49496000",
  },
  {
    id: 118713,
    name: "Howard County",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.25072000",
    longitude: "-76.93119000",
  },
  {
    id: 118762,
    name: "Hughesville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.53262000",
    longitude: "-76.78386000",
  },
  {
    id: 118787,
    name: "Hunt Valley",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.49983000",
    longitude: "-76.64108000",
  },
  {
    id: 118810,
    name: "Huntingtown",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.61595000",
    longitude: "-76.61302000",
  },
  {
    id: 118811,
    name: "Huntingtown Town Center",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.62095000",
    longitude: "-76.61607000",
  },
  {
    id: 118823,
    name: "Hurlock",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.62428000",
    longitude: "-75.85438000",
  },
  {
    id: 118844,
    name: "Hyattsville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.95594000",
    longitude: "-76.94553000",
  },
  {
    id: 118877,
    name: "Ilchester",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.25094000",
    longitude: "-76.76469000",
  },
  {
    id: 118903,
    name: "Indian Head",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.60012000",
    longitude: "-77.16220000",
  },
  {
    id: 119008,
    name: "Irvington",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.28288000",
    longitude: "-76.68608000",
  },
  {
    id: 119135,
    name: "Jarrettsville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.60455000",
    longitude: "-76.47774000",
  },
  {
    id: 119164,
    name: "Jefferson",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.36205000",
    longitude: "-77.53165000",
  },
  {
    id: 119237,
    name: "Jessup",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.14927000",
    longitude: "-76.77525000",
  },
  {
    id: 119314,
    name: "Joppatowne",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.45789000",
    longitude: "-76.35524000",
  },
  {
    id: 119417,
    name: "Keedysville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.48621000",
    longitude: "-77.69971000",
  },
  {
    id: 119436,
    name: "Kemp Mill",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.03900000",
    longitude: "-77.01914000",
  },
  {
    id: 119480,
    name: "Kensington",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.02567000",
    longitude: "-77.07637000",
  },
  {
    id: 119491,
    name: "Kent County",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.23560000",
    longitude: "-76.09582000",
  },
  {
    id: 119526,
    name: "Kettering",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.88456000",
    longitude: "-76.81469000",
  },
  {
    id: 119633,
    name: "Kingstown",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.20483000",
    longitude: "-76.05133000",
  },
  {
    id: 119635,
    name: "Kingsville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.44872000",
    longitude: "-76.41774000",
  },
  {
    id: 119773,
    name: "La Plata",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.52929000",
    longitude: "-76.97525000",
  },
  {
    id: 119791,
    name: "La Vale",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.65564000",
    longitude: "-78.81058000",
  },
  {
    id: 119864,
    name: "Lake Arbor",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.90789000",
    longitude: "-76.82969000",
  },
  {
    id: 119976,
    name: "Lake Shore",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.10705000",
    longitude: "-76.48496000",
  },
  {
    id: 120102,
    name: "Landover",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.93400000",
    longitude: "-76.89664000",
  },
  {
    id: 120103,
    name: "Landover Hills",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.94317000",
    longitude: "-76.89220000",
  },
  {
    id: 120115,
    name: "Langley Park",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.98872000",
    longitude: "-76.98136000",
  },
  {
    id: 120117,
    name: "Lanham",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.96875000",
    longitude: "-76.86340000",
  },
  {
    id: 120118,
    name: "Lanham-Seabrook",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.96835000",
    longitude: "-76.85108000",
  },
  {
    id: 120122,
    name: "Lansdowne",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.24511000",
    longitude: "-76.66052000",
  },
  {
    id: 120144,
    name: "Largo",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.89761000",
    longitude: "-76.83025000",
  },
  {
    id: 120189,
    name: "Laurel",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.09928000",
    longitude: "-76.84831000",
  },
  {
    id: 120252,
    name: "Layhill",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.09233000",
    longitude: "-77.04442000",
  },
  {
    id: 120345,
    name: "Leisure World",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.10230000",
    longitude: "-77.06898000",
  },
  {
    id: 120390,
    name: "Leonardtown",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.29124000",
    longitude: "-76.63579000",
  },
  {
    id: 120455,
    name: "Lexington Park",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.26679000",
    longitude: "-76.45384000",
  },
  {
    id: 120578,
    name: "Linganore",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.44038000",
    longitude: "-77.20804000",
  },
  {
    id: 120588,
    name: "Linthicum",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.20511000",
    longitude: "-76.65275000",
  },
  {
    id: 120671,
    name: "Lochearn",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.34066000",
    longitude: "-76.72219000",
  },
  {
    id: 120719,
    name: "Lonaconing",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.56592000",
    longitude: "-78.98030000",
  },
  {
    id: 120725,
    name: "Londontowne",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.93345000",
    longitude: "-76.54941000",
  },
  {
    id: 120733,
    name: "Long Beach",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.46096000",
    longitude: "-76.46884000",
  },
  {
    id: 120891,
    name: "Lusby",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.41068000",
    longitude: "-76.45523000",
  },
  {
    id: 120895,
    name: "Lutherville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.42122000",
    longitude: "-76.62608000",
  },
  {
    id: 120896,
    name: "Lutherville-Timonium",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.43997000",
    longitude: "-76.61099000",
  },
  {
    id: 121090,
    name: "Manchester",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.66121000",
    longitude: "-76.88498000",
  },
  {
    id: 121277,
    name: "Marlboro Meadows",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.83622000",
    longitude: "-76.71497000",
  },
  {
    id: 121278,
    name: "Marlboro Village",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.83054000",
    longitude: "-76.76965000",
  },
  {
    id: 121286,
    name: "Marlow Heights",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.83345000",
    longitude: "-76.95164000",
  },
  {
    id: 121287,
    name: "Marlton",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.77373000",
    longitude: "-76.78997000",
  },
  {
    id: 121355,
    name: "Maryland City",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.09205000",
    longitude: "-76.81775000",
  },
  {
    id: 121418,
    name: "Maugansville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.69287000",
    longitude: "-77.74472000",
  },
  {
    id: 121443,
    name: "Mayo",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.88761000",
    longitude: "-76.51190000",
  },
  {
    id: 121446,
    name: "Mays Chapel",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.43316000",
    longitude: "-76.64941000",
  },
  {
    id: 121582,
    name: "Mechanicsville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.44290000",
    longitude: "-76.74385000",
  },
  {
    id: 121626,
    name: "Mellwood",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.81039000",
    longitude: "-76.82414000",
  },
  {
    id: 121762,
    name: "Middle River",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.33427000",
    longitude: "-76.43941000",
  },
  {
    id: 121786,
    name: "Middletown",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.44371000",
    longitude: "-77.54471000",
  },
  {
    id: 121860,
    name: "Milford Mill",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.34788000",
    longitude: "-76.76997000",
  },
  {
    id: 122004,
    name: "Mitchellville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.92511000",
    longitude: "-76.74275000",
  },
  {
    id: 122145,
    name: "Montgomery County",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.13638000",
    longitude: "-77.20424000",
  },
  {
    id: 122156,
    name: "Montgomery Village",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.17677000",
    longitude: "-77.19526000",
  },
  {
    id: 122257,
    name: "Morningside",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.83011000",
    longitude: "-76.89136000",
  },
  {
    id: 122330,
    name: "Mount Airy",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.37621000",
    longitude: "-77.15470000",
  },
  {
    id: 122387,
    name: "Mount Rainier",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.94150000",
    longitude: "-76.96498000",
  },
  {
    id: 122421,
    name: "Mountain Lake Park",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.39843000",
    longitude: "-79.38171000",
  },
  {
    id: 122519,
    name: "Myersville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.50510000",
    longitude: "-77.56638000",
  },
  {
    id: 122592,
    name: "National Harbor",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.78264000",
    longitude: "-77.01506000",
  },
  {
    id: 122599,
    name: "Naval Academy",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.98568000",
    longitude: "-76.48774000",
  },
  {
    id: 122681,
    name: "New Carrollton",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.96983000",
    longitude: "-76.87997000",
  },
  {
    id: 122793,
    name: "New Windsor",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.54205000",
    longitude: "-77.10804000",
  },
  {
    id: 122977,
    name: "North Beach",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.70734000",
    longitude: "-76.53107000",
  },
  {
    id: 122979,
    name: "North Bel Air",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.53983000",
    longitude: "-76.35496000",
  },
  {
    id: 122989,
    name: "North Bethesda",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.04455000",
    longitude: "-77.11887000",
  },
  {
    id: 123017,
    name: "North East",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.60011000",
    longitude: "-75.94133000",
  },
  {
    id: 123046,
    name: "North Kensington",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.03039000",
    longitude: "-77.07248000",
  },
  {
    id: 123054,
    name: "North Laurel",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.13900000",
    longitude: "-76.87053000",
  },
  {
    id: 123087,
    name: "North Potomac",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.08289000",
    longitude: "-77.26498000",
  },
  {
    id: 123284,
    name: "Oakland",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.40787000",
    longitude: "-79.40671000",
  },
  {
    id: 123326,
    name: "Ocean City",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.33650000",
    longitude: "-75.08491000",
  },
  {
    id: 123333,
    name: "Ocean Pines",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.39539000",
    longitude: "-75.15574000",
  },
  {
    id: 123356,
    name: "Odenton",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.08400000",
    longitude: "-76.70025000",
  },
  {
    id: 123456,
    name: "Olney",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.15316000",
    longitude: "-77.06692000",
  },
  {
    id: 123652,
    name: "Overlea",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.36344000",
    longitude: "-76.52052000",
  },
  {
    id: 123668,
    name: "Owings",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.71762000",
    longitude: "-76.60135000",
  },
  {
    id: 123669,
    name: "Owings Mills",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.41955000",
    longitude: "-76.78025000",
  },
  {
    id: 123691,
    name: "Oxon Hill",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.80345000",
    longitude: "-76.98970000",
  },
  {
    id: 123692,
    name: "Oxon Hill-Glassmanor",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.79615000",
    longitude: "-76.97499000",
  },
  {
    id: 123825,
    name: "Paramount-Long Meadow",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.68042000",
    longitude: "-77.69290000",
  },
  {
    id: 123877,
    name: "Parkville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.37733000",
    longitude: "-76.53969000",
  },
  {
    id: 123887,
    name: "Parole",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.97956000",
    longitude: "-76.53052000",
  },
  {
    id: 123895,
    name: "Pasadena",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.10733000",
    longitude: "-76.57108000",
  },
  {
    id: 124055,
    name: "Peppermill Village",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.89472000",
    longitude: "-76.88654000",
  },
  {
    id: 124083,
    name: "Perry Hall",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.41261000",
    longitude: "-76.46357000",
  },
  {
    id: 124085,
    name: "Perryman",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.46955000",
    longitude: "-76.20440000",
  },
  {
    id: 124090,
    name: "Perryville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.56011000",
    longitude: "-76.07134000",
  },
  {
    id: 124197,
    name: "Pikesville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.37427000",
    longitude: "-76.72247000",
  },
  {
    id: 124305,
    name: "Pittsville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.39539000",
    longitude: "-75.41297000",
  },
  {
    id: 124373,
    name: "Pleasant Hills",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.47955000",
    longitude: "-76.39413000",
  },
  {
    id: 124420,
    name: "Pocomoke City",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.07568000",
    longitude: "-75.56798000",
  },
  {
    id: 124434,
    name: "Point of Rocks",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.27594000",
    longitude: "-77.53915000",
  },
  {
    id: 124480,
    name: "Poolesville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.14594000",
    longitude: "-77.41693000",
  },
  {
    id: 124582,
    name: "Potomac",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.01817000",
    longitude: "-77.20859000",
  },
  {
    id: 124583,
    name: "Potomac Heights",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.60873000",
    longitude: "-77.14053000",
  },
  {
    id: 124585,
    name: "Potomac Park",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.61176000",
    longitude: "-78.80585000",
  },
  {
    id: 124673,
    name: "Prince Frederick",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.54040000",
    longitude: "-76.58440000",
  },
  {
    id: 124676,
    name: "Prince George's County",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.82952000",
    longitude: "-76.84729000",
  },
  {
    id: 124679,
    name: "Princess Anne",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.20290000",
    longitude: "-75.69243000",
  },
  {
    id: 124745,
    name: "Pumphrey",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.21733000",
    longitude: "-76.63719000",
  },
  {
    id: 124785,
    name: "Queen Anne",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.89872000",
    longitude: "-76.67830000",
  },
  {
    id: 124786,
    name: "Queen Anne's County",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.03763000",
    longitude: "-76.08504000",
  },
  {
    id: 124789,
    name: "Queenland",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.80524000",
    longitude: "-76.79126000",
  },
  {
    id: 124863,
    name: "Randallstown",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.36733000",
    longitude: "-76.79525000",
  },
  {
    id: 124974,
    name: "Redland",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.14539000",
    longitude: "-77.14415000",
  },
  {
    id: 125012,
    name: "Reisterstown",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.46976000",
    longitude: "-76.83190000",
  },
  {
    id: 125125,
    name: "Ridgely",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.94789000",
    longitude: "-75.88438000",
  },
  {
    id: 125170,
    name: "Rising Sun",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.69789000",
    longitude: "-76.06273000",
  },
  {
    id: 125175,
    name: "Riva",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.95206000",
    longitude: "-76.57802000",
  },
  {
    id: 125198,
    name: "Riverdale Park",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.96344000",
    longitude: "-76.93164000",
  },
  {
    id: 125202,
    name: "Riverside",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.47372000",
    longitude: "-76.24134000",
  },
  {
    id: 125222,
    name: "Riviera Beach",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.16678000",
    longitude: "-76.50802000",
  },
  {
    id: 125255,
    name: "Robinwood",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.63704000",
    longitude: "-77.65694000",
  },
  {
    id: 125278,
    name: "Rock Hall",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.13817000",
    longitude: "-76.23495000",
  },
  {
    id: 125322,
    name: "Rockville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.08400000",
    longitude: "-77.15276000",
  },
  {
    id: 125398,
    name: "Rosaryville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.75678000",
    longitude: "-76.80969000",
  },
  {
    id: 125416,
    name: "Rosedale",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.32011000",
    longitude: "-76.51552000",
  },
  {
    id: 125453,
    name: "Rossmoor",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.10372000",
    longitude: "-77.07109000",
  },
  {
    id: 125458,
    name: "Rossville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.33844000",
    longitude: "-76.47968000",
  },
  {
    id: 125611,
    name: "Saint Charles",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.60317000",
    longitude: "-76.93858000",
  },
  {
    id: 125652,
    name: "Saint James",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.56260000",
    longitude: "-77.75805000",
  },
  {
    id: 125691,
    name: "Saint Mary's County",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.21586000",
    longitude: "-76.52906000",
  },
  {
    id: 125698,
    name: "Saint Michaels",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.78512000",
    longitude: "-76.22439000",
  },
  {
    id: 125754,
    name: "Salisbury",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.36067000",
    longitude: "-75.59937000",
  },
  {
    id: 125971,
    name: "Savage",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.13789000",
    longitude: "-76.82386000",
  },
  {
    id: 125994,
    name: "Scaggsville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.14511000",
    longitude: "-76.90025000",
  },
  {
    id: 126078,
    name: "Seabrook",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.96805000",
    longitude: "-76.84658000",
  },
  {
    id: 126103,
    name: "Seat Pleasant",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.89622000",
    longitude: "-76.90664000",
  },
  {
    id: 126131,
    name: "Selby-on-the-Bay",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.91622000",
    longitude: "-76.52246000",
  },
  {
    id: 126178,
    name: "Severn",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.13705000",
    longitude: "-76.69830000",
  },
  {
    id: 126179,
    name: "Severna Park",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.07039000",
    longitude: "-76.54524000",
  },
  {
    id: 126207,
    name: "Shady Side",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.84178000",
    longitude: "-76.51218000",
  },
  {
    id: 126426,
    name: "Silver Hill",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.84178000",
    longitude: "-76.94581000",
  },
  {
    id: 126434,
    name: "Silver Spring",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.99067000",
    longitude: "-77.02609000",
  },
  {
    id: 126524,
    name: "Smithsburg",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.65482000",
    longitude: "-77.57277000",
  },
  {
    id: 126545,
    name: "Snow Hill",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.17706000",
    longitude: "-75.39270000",
  },
  {
    id: 126570,
    name: "Solomons",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.31846000",
    longitude: "-76.45412000",
  },
  {
    id: 126579,
    name: "Somerset",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.96594000",
    longitude: "-77.09609000",
  },
  {
    id: 126586,
    name: "Somerset County",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.08007000",
    longitude: "-75.85347000",
  },
  {
    id: 126621,
    name: "South Bel Air",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.53316000",
    longitude: "-76.33746000",
  },
  {
    id: 126658,
    name: "South Gate",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.12900000",
    longitude: "-76.62580000",
  },
  {
    id: 126682,
    name: "South Kensington",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.01900000",
    longitude: "-77.07998000",
  },
  {
    id: 126686,
    name: "South Laurel",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.06983000",
    longitude: "-76.85025000",
  },
  {
    id: 126831,
    name: "Spencerville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.11427000",
    longitude: "-76.97831000",
  },
  {
    id: 126872,
    name: "Spring Ridge",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.40149000",
    longitude: "-77.35248000",
  },
  {
    id: 126884,
    name: "Springdale",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.93761000",
    longitude: "-76.83886000",
  },
  {
    id: 127047,
    name: "Stevensville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.98067000",
    longitude: "-76.31440000",
  },
  {
    id: 127178,
    name: "Suitland",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.84872000",
    longitude: "-76.92386000",
  },
  {
    id: 127179,
    name: "Suitland-Silver Hill",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.84685000",
    longitude: "-76.92591000",
  },
  {
    id: 127203,
    name: "Summerfield",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.90454000",
    longitude: "-76.86830000",
  },
  {
    id: 127352,
    name: "Sykesville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.37371000",
    longitude: "-76.96776000",
  },
  {
    id: 127382,
    name: "Takoma Park",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.97789000",
    longitude: "-77.00748000",
  },
  {
    id: 127384,
    name: "Talbot County",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.74910000",
    longitude: "-76.17862000",
  },
  {
    id: 127411,
    name: "Taneytown",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.65788000",
    longitude: "-77.17443000",
  },
  {
    id: 127502,
    name: "Temple Hills",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.81400000",
    longitude: "-76.94553000",
  },
  {
    id: 127629,
    name: "Thurmont",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.62371000",
    longitude: "-77.41082000",
  },
  {
    id: 127663,
    name: "Timonium",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.43705000",
    longitude: "-76.61969000",
  },
  {
    id: 127768,
    name: "Towson",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.40150000",
    longitude: "-76.60191000",
  },
  {
    id: 127781,
    name: "Trappe",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.65845000",
    longitude: "-76.05800000",
  },
  {
    id: 127786,
    name: "Travilah",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.06900000",
    longitude: "-77.26304000",
  },
  {
    id: 128032,
    name: "University Park",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.97039000",
    longitude: "-76.94192000",
  },
  {
    id: 128046,
    name: "Upper Marlboro",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.81595000",
    longitude: "-76.74969000",
  },
  {
    id: 128060,
    name: "Urbana",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.32594000",
    longitude: "-77.35137000",
  },
  {
    id: 128375,
    name: "Waldorf",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.62456000",
    longitude: "-76.93914000",
  },
  {
    id: 128389,
    name: "Walker Mill",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.87539000",
    longitude: "-76.88831000",
  },
  {
    id: 128390,
    name: "Walkersville",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.48621000",
    longitude: "-77.35193000",
  },
  {
    id: 128560,
    name: "Washington County",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.60367000",
    longitude: "-77.81398000",
  },
  {
    id: 128860,
    name: "West Elkridge",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.20705000",
    longitude: "-76.72692000",
  },
  {
    id: 128912,
    name: "West Laurel",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.10122000",
    longitude: "-76.89970000",
  },
  {
    id: 128943,
    name: "West Ocean City",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.33150000",
    longitude: "-75.10685000",
  },
  {
    id: 129026,
    name: "Westernport",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.48537000",
    longitude: "-79.04475000",
  },
  {
    id: 129043,
    name: "Westminster",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.57538000",
    longitude: "-76.99581000",
  },
  {
    id: 129069,
    name: "Westphalia",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.84539000",
    longitude: "-76.81108000",
  },
  {
    id: 129112,
    name: "Wheaton",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.03983000",
    longitude: "-77.05526000",
  },
  {
    id: 129145,
    name: "White Marsh",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.38372000",
    longitude: "-76.43218000",
  },
  {
    id: 129148,
    name: "White Oak",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.03983000",
    longitude: "-76.99303000",
  },
  {
    id: 129222,
    name: "Wicomico County",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.36942000",
    longitude: "-75.63151000",
  },
  {
    id: 129279,
    name: "Williamsport",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.60065000",
    longitude: "-77.82055000",
  },
  {
    id: 129338,
    name: "Wilson-Conococheague",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.65351000",
    longitude: "-77.83157000",
  },
  {
    id: 129534,
    name: "Woodlawn",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.32288000",
    longitude: "-76.72803000",
  },
  {
    id: 129544,
    name: "Woodmore",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.92122000",
    longitude: "-76.80303000",
  },
  {
    id: 129552,
    name: "Woodsboro",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "39.53316000",
    longitude: "-77.31471000",
  },
  {
    id: 129586,
    name: "Worcester County",
    state_id: 1401,
    state_code: "MD",
    country_id: 233,
    country_code: "US",
    latitude: "38.21650000",
    longitude: "-75.29667000",
  },
  {
    id: 110986,
    name: "Abington",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.10482000",
    longitude: "-70.94532000",
  },
  {
    id: 110999,
    name: "Acton",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.48509000",
    longitude: "-71.43284000",
  },
  {
    id: 111002,
    name: "Acushnet",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.68066000",
    longitude: "-70.90782000",
  },
  {
    id: 111003,
    name: "Acushnet Center",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.68508000",
    longitude: "-70.90642000",
  },
  {
    id: 111013,
    name: "Adams",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.62425000",
    longitude: "-73.11760000",
  },
  {
    id: 111055,
    name: "Agawam",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.06954000",
    longitude: "-72.61481000",
  },
  {
    id: 111279,
    name: "Amesbury",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.85842000",
    longitude: "-70.93005000",
  },
  {
    id: 111282,
    name: "Amherst",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.36723000",
    longitude: "-72.51852000",
  },
  {
    id: 111285,
    name: "Amherst Center",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.37537000",
    longitude: "-72.51925000",
  },
  {
    id: 111323,
    name: "Andover",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.65843000",
    longitude: "-71.13700000",
  },
  {
    id: 111465,
    name: "Arlington",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.41537000",
    longitude: "-71.15644000",
  },
  {
    id: 111516,
    name: "Ashburnham",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.63620000",
    longitude: "-71.90785000",
  },
  {
    id: 111517,
    name: "Ashby",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.67787000",
    longitude: "-71.82035000",
  },
  {
    id: 111523,
    name: "Ashfield",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.52647000",
    longitude: "-72.78843000",
  },
  {
    id: 111534,
    name: "Ashland",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.26121000",
    longitude: "-71.46340000",
  },
  {
    id: 111556,
    name: "Assonet",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.79594000",
    longitude: "-71.06782000",
  },
  {
    id: 111583,
    name: "Athol",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.59592000",
    longitude: "-72.22675000",
  },
  {
    id: 111611,
    name: "Attleboro",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.94454000",
    longitude: "-71.28561000",
  },
  {
    id: 111624,
    name: "Auburn",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.19454000",
    longitude: "-71.83563000",
  },
  {
    id: 111691,
    name: "Avon",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.13066000",
    longitude: "-71.04116000",
  },
  {
    id: 111708,
    name: "Ayer",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.56120000",
    longitude: "-71.58979000",
  },
  {
    id: 111765,
    name: "Baldwinville",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.60842000",
    longitude: "-72.07591000",
  },
  {
    id: 111834,
    name: "Barnstable",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.70011000",
    longitude: "-70.29947000",
  },
  {
    id: 111835,
    name: "Barnstable County",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.68419000",
    longitude: "-70.27405000",
  },
  {
    id: 111841,
    name: "Barre",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.42287000",
    longitude: "-72.10508000",
  },
  {
    id: 112014,
    name: "Becket",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.33203000",
    longitude: "-73.08288000",
  },
  {
    id: 112023,
    name: "Bedford",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.49065000",
    longitude: "-71.27617000",
  },
  {
    id: 112051,
    name: "Belchertown",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.27704000",
    longitude: "-72.40092000",
  },
  {
    id: 112119,
    name: "Bellingham",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.08676000",
    longitude: "-71.47451000",
  },
  {
    id: 112138,
    name: "Belmont",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.39593000",
    longitude: "-71.17867000",
  },
  {
    id: 112229,
    name: "Berkley",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.84593000",
    longitude: "-71.08282000",
  },
  {
    id: 112233,
    name: "Berkshire County",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.37067000",
    longitude: "-73.20640000",
  },
  {
    id: 112237,
    name: "Berlin",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.38120000",
    longitude: "-71.63701000",
  },
  {
    id: 112244,
    name: "Bernardston",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.67092000",
    longitude: "-72.54953000",
  },
  {
    id: 112300,
    name: "Beverly",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.55843000",
    longitude: "-70.88005000",
  },
  {
    id: 112302,
    name: "Beverly Cove",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.55343000",
    longitude: "-70.85366000",
  },
  {
    id: 112342,
    name: "Billerica",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.55843000",
    longitude: "-71.26895000",
  },
  {
    id: 112393,
    name: "Blackstone",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.01788000",
    longitude: "-71.54117000",
  },
  {
    id: 112430,
    name: "Bliss Corner",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.61177000",
    longitude: "-70.93837000",
  },
  {
    id: 112525,
    name: "Bolton",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.43343000",
    longitude: "-71.60784000",
  },
  {
    id: 112532,
    name: "Bondsville",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.21259000",
    longitude: "-72.34536000",
  },
  {
    id: 112589,
    name: "Boston",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.35843000",
    longitude: "-71.05977000",
  },
  {
    id: 112617,
    name: "Bourne",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.74122000",
    longitude: "-70.59892000",
  },
  {
    id: 112638,
    name: "Boxborough",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.49084000",
    longitude: "-71.52851000",
  },
  {
    id: 112639,
    name: "Boxford",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.66120000",
    longitude: "-70.99672000",
  },
  {
    id: 112650,
    name: "Boylston",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.39176000",
    longitude: "-71.70368000",
  },
  {
    id: 112682,
    name: "Braintree",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.20384000",
    longitude: "-71.00215000",
  },
  {
    id: 112741,
    name: "Brewster",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.76011000",
    longitude: "-70.08280000",
  },
  {
    id: 112775,
    name: "Bridgewater",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.99038000",
    longitude: "-70.97504000",
  },
  {
    id: 112797,
    name: "Brimfield",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.12287000",
    longitude: "-72.20091000",
  },
  {
    id: 112814,
    name: "Bristol County",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.75709000",
    longitude: "-71.08852000",
  },
  {
    id: 112834,
    name: "Brockton",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.08343000",
    longitude: "-71.01838000",
  },
  {
    id: 112867,
    name: "Brookline",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.33176000",
    longitude: "-71.12116000",
  },
  {
    id: 112987,
    name: "Buckland",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.59231000",
    longitude: "-72.79176000",
  },
  {
    id: 113070,
    name: "Burlington",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.50482000",
    longitude: "-71.19561000",
  },
  {
    id: 113133,
    name: "Buzzards Bay",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.74538000",
    longitude: "-70.61809000",
  },
  {
    id: 113252,
    name: "Cambridge",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.37510000",
    longitude: "-71.10561000",
  },
  {
    id: 113344,
    name: "Canton",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.15843000",
    longitude: "-71.14477000",
  },
  {
    id: 113404,
    name: "Carlisle",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.52926000",
    longitude: "-71.34950000",
  },
  {
    id: 113500,
    name: "Carver",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.88344000",
    longitude: "-70.76254000",
  },
  {
    id: 113659,
    name: "Centerville",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.64872000",
    longitude: "-70.34808000",
  },
  {
    id: 113752,
    name: "Charlemont",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.62786000",
    longitude: "-72.86982000",
  },
  {
    id: 113788,
    name: "Charlton",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.13565000",
    longitude: "-71.97007000",
  },
  {
    id: 113798,
    name: "Chatham",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.68206000",
    longitude: "-69.95974000",
  },
  {
    id: 113828,
    name: "Chelmsford",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.59981000",
    longitude: "-71.36728000",
  },
  {
    id: 113831,
    name: "Chelsea",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.39176000",
    longitude: "-71.03283000",
  },
  {
    id: 113904,
    name: "Chesterfield",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.39175000",
    longitude: "-72.83982000",
  },
  {
    id: 113945,
    name: "Chicopee",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.14870000",
    longitude: "-72.60787000",
  },
  {
    id: 114280,
    name: "Clinton",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.41676000",
    longitude: "-71.68285000",
  },
  {
    id: 114350,
    name: "Cochituate",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.32093000",
    longitude: "-71.36423000",
  },
  {
    id: 114372,
    name: "Cohasset",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.24177000",
    longitude: "-70.80365000",
  },
  {
    id: 114461,
    name: "Colrain",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.67314000",
    longitude: "-72.69676000",
  },
  {
    id: 114538,
    name: "Concord",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.46037000",
    longitude: "-71.34895000",
  },
  {
    id: 114584,
    name: "Conway",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.50981000",
    longitude: "-72.69953000",
  },
  {
    id: 114634,
    name: "Cordaville",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.26898000",
    longitude: "-71.52395000",
  },
  {
    id: 114707,
    name: "Cotuit",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.61678000",
    longitude: "-70.43697000",
  },
  {
    id: 115002,
    name: "Dalton",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.47370000",
    longitude: "-73.16621000",
  },
  {
    id: 115029,
    name: "Danvers",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.57509000",
    longitude: "-70.93005000",
  },
  {
    id: 115167,
    name: "Dedham",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.24177000",
    longitude: "-71.16616000",
  },
  {
    id: 115242,
    name: "Dennis",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.73539000",
    longitude: "-70.19391000",
  },
  {
    id: 115243,
    name: "Dennis Port",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.65845000",
    longitude: "-70.12863000",
  },
  {
    id: 115296,
    name: "Devens",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.54470000",
    longitude: "-71.61318000",
  },
  {
    id: 115335,
    name: "Dighton",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.81399000",
    longitude: "-71.12032000",
  },
  {
    id: 115421,
    name: "Douglas",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.05426000",
    longitude: "-71.73951000",
  },
  {
    id: 115448,
    name: "Dover",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.24593000",
    longitude: "-71.28283000",
  },
  {
    id: 115466,
    name: "Dracut",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.67037000",
    longitude: "-71.30201000",
  },
  {
    id: 115505,
    name: "Dudley",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.04510000",
    longitude: "-71.93007000",
  },
  {
    id: 115508,
    name: "Dukes County",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.38877000",
    longitude: "-70.69877000",
  },
  {
    id: 115554,
    name: "Dunstable",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.67509000",
    longitude: "-71.48284000",
  },
  {
    id: 115583,
    name: "Duxbury",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.04177000",
    longitude: "-70.67226000",
  },
  {
    id: 115637,
    name: "East Bridgewater",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.03343000",
    longitude: "-70.95921000",
  },
  {
    id: 115639,
    name: "East Brookfield",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.22787000",
    longitude: "-72.04674000",
  },
  {
    id: 115649,
    name: "East Dennis",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.74261000",
    longitude: "-70.16196000",
  },
  {
    id: 115650,
    name: "East Douglas",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.07232000",
    longitude: "-71.71340000",
  },
  {
    id: 115657,
    name: "East Falmouth",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.57844000",
    longitude: "-70.55864000",
  },
  {
    id: 115685,
    name: "East Harwich",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.70011000",
    longitude: "-70.02724000",
  },
  {
    id: 115705,
    name: "East Longmeadow",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.06454000",
    longitude: "-72.51259000",
  },
  {
    id: 115733,
    name: "East Pepperell",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.66537000",
    longitude: "-71.57312000",
  },
  {
    id: 115756,
    name: "East Sandwich",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.74177000",
    longitude: "-70.45169000",
  },
  {
    id: 115778,
    name: "Eastham",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.83011000",
    longitude: "-69.97391000",
  },
  {
    id: 115779,
    name: "Easthampton",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.26676000",
    longitude: "-72.66898000",
  },
  {
    id: 115787,
    name: "Easton",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.02454000",
    longitude: "-71.12866000",
  },
  {
    id: 115838,
    name: "Edgartown",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.38901000",
    longitude: "-70.51336000",
  },
  {
    id: 116174,
    name: "Erving",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.60009000",
    longitude: "-72.39814000",
  },
  {
    id: 116192,
    name: "Essex",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.63204000",
    longitude: "-70.78283000",
  },
  {
    id: 116194,
    name: "Essex County",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.63887000",
    longitude: "-70.86792000",
  },
  {
    id: 116258,
    name: "Everett",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.40843000",
    longitude: "-71.05366000",
  },
  {
    id: 116325,
    name: "Fairhaven",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.63760000",
    longitude: "-70.90365000",
  },
  {
    id: 116373,
    name: "Fall River",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.70149000",
    longitude: "-71.15505000",
  },
  {
    id: 116388,
    name: "Falmouth",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.55150000",
    longitude: "-70.61475000",
  },
  {
    id: 116533,
    name: "Fiskdale",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.11621000",
    longitude: "-72.11341000",
  },
  {
    id: 116534,
    name: "Fitchburg",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.58342000",
    longitude: "-71.80230000",
  },
  {
    id: 116670,
    name: "Forestdale",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.69177000",
    longitude: "-70.49947000",
  },
  {
    id: 116809,
    name: "Foxborough",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.06538000",
    longitude: "-71.24783000",
  },
  {
    id: 116811,
    name: "Framingham",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.27926000",
    longitude: "-71.41617000",
  },
  {
    id: 116812,
    name: "Framingham Center",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.29732000",
    longitude: "-71.43701000",
  },
  {
    id: 116834,
    name: "Franklin",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.08343000",
    longitude: "-71.39673000",
  },
  {
    id: 116858,
    name: "Franklin County",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.58312000",
    longitude: "-72.59187000",
  },
  {
    id: 116918,
    name: "Freetown",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.76677000",
    longitude: "-71.03282000",
  },
  {
    id: 117075,
    name: "Gardner",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.57509000",
    longitude: "-71.99813000",
  },
  {
    id: 117213,
    name: "Gill",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.64036000",
    longitude: "-72.49953000",
  },
  {
    id: 117322,
    name: "Gloucester",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.61405000",
    longitude: "-70.66313000",
  },
  {
    id: 117429,
    name: "Grafton",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.20704000",
    longitude: "-71.68562000",
  },
  {
    id: 117447,
    name: "Granby",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.25648000",
    longitude: "-72.51620000",
  },
  {
    id: 117534,
    name: "Granville",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.06676000",
    longitude: "-72.86149000",
  },
  {
    id: 117569,
    name: "Great Barrington",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.19592000",
    longitude: "-73.36206000",
  },
  {
    id: 117596,
    name: "Green Harbor-Cedar Crest",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.07495000",
    longitude: "-70.65843000",
  },
  {
    id: 117650,
    name: "Greenfield",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.58759000",
    longitude: "-72.59953000",
  },
  {
    id: 117752,
    name: "Groton",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.61120000",
    longitude: "-71.57451000",
  },
  {
    id: 117759,
    name: "Groveland",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.76037000",
    longitude: "-71.03145000",
  },
  {
    id: 117833,
    name: "Hadley",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.34176000",
    longitude: "-72.58842000",
  },
  {
    id: 117861,
    name: "Halifax",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.99121000",
    longitude: "-70.86199000",
  },
  {
    id: 117906,
    name: "Hamilton Worcester",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.25620000",
    longitude: "-71.76757000",
  },
  {
    id: 117916,
    name: "Hampden",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.06398000",
    longitude: "-72.41342000",
  },
  {
    id: 117918,
    name: "Hampden County",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.13511000",
    longitude: "-72.63162000",
  },
  {
    id: 117922,
    name: "Hampshire County",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.34014000",
    longitude: "-72.66377000",
  },
  {
    id: 117970,
    name: "Hanover",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.11316000",
    longitude: "-70.81199000",
  },
  {
    id: 117978,
    name: "Hanson",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.07510000",
    longitude: "-70.88004000",
  },
  {
    id: 118008,
    name: "Hardwick",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.35009000",
    longitude: "-72.19952000",
  },
  {
    id: 118109,
    name: "Harvard",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.50009000",
    longitude: "-71.58284000",
  },
  {
    id: 118117,
    name: "Harwich",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.68622000",
    longitude: "-70.07585000",
  },
  {
    id: 118118,
    name: "Harwich Center",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.69235000",
    longitude: "-70.06938000",
  },
  {
    id: 118119,
    name: "Harwich Port",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.66678000",
    longitude: "-70.07863000",
  },
  {
    id: 118138,
    name: "Hatfield",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.37092000",
    longitude: "-72.59814000",
  },
  {
    id: 118150,
    name: "Haverhill",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.77620000",
    longitude: "-71.07728000",
  },
  {
    id: 118214,
    name: "Head of Westport",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.62094000",
    longitude: "-71.06199000",
  },
  {
    id: 118483,
    name: "Hingham",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.24177000",
    longitude: "-70.88977000",
  },
  {
    id: 118484,
    name: "Hinsdale",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.43870000",
    longitude: "-73.12538000",
  },
  {
    id: 118517,
    name: "Holbrook",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.15510000",
    longitude: "-71.00866000",
  },
  {
    id: 118523,
    name: "Holden",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.35176000",
    longitude: "-71.86341000",
  },
  {
    id: 118538,
    name: "Holland",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.06398000",
    longitude: "-72.15730000",
  },
  {
    id: 118553,
    name: "Holliston",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.20010000",
    longitude: "-71.42450000",
  },
  {
    id: 118583,
    name: "Holyoke",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.20426000",
    longitude: "-72.61620000",
  },
  {
    id: 118647,
    name: "Hopedale",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.13065000",
    longitude: "-71.54117000",
  },
  {
    id: 118656,
    name: "Hopkinton",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.22871000",
    longitude: "-71.52256000",
  },
  {
    id: 118695,
    name: "Housatonic",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.25425000",
    longitude: "-73.36622000",
  },
  {
    id: 118734,
    name: "Hubbardston",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.47370000",
    longitude: "-72.00619000",
  },
  {
    id: 118740,
    name: "Hudson",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.39176000",
    longitude: "-71.56618000",
  },
  {
    id: 118772,
    name: "Hull",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.30204000",
    longitude: "-70.90782000",
  },
  {
    id: 118842,
    name: "Hyannis",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.65289000",
    longitude: "-70.28280000",
  },
  {
    id: 118982,
    name: "Ipswich",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.67926000",
    longitude: "-70.84116000",
  },
  {
    id: 119114,
    name: "Jamaica Plain",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.30982000",
    longitude: "-71.12033000",
  },
  {
    id: 119623,
    name: "Kingston",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.99455000",
    longitude: "-70.72448000",
  },
  {
    id: 120083,
    name: "Lancaster",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.45565000",
    longitude: "-71.67312000",
  },
  {
    id: 120107,
    name: "Lanesborough",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.51731000",
    longitude: "-73.22816000",
  },
  {
    id: 120227,
    name: "Lawrence",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.70704000",
    longitude: "-71.16311000",
  },
  {
    id: 120303,
    name: "Lee",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.30425000",
    longitude: "-73.24816000",
  },
  {
    id: 120337,
    name: "Leicester",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.24593000",
    longitude: "-71.90868000",
  },
  {
    id: 120376,
    name: "Lenox",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.35648000",
    longitude: "-73.28483000",
  },
  {
    id: 120382,
    name: "Leominster",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.52509000",
    longitude: "-71.75979000",
  },
  {
    id: 120406,
    name: "Leverett",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.45203000",
    longitude: "-72.50148000",
  },
  {
    id: 120448,
    name: "Lexington",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.44732000",
    longitude: "-71.22450000",
  },
  {
    id: 120504,
    name: "Lincoln",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.42593000",
    longitude: "-71.30395000",
  },
  {
    id: 120637,
    name: "Littleton Common",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.54593000",
    longitude: "-71.47451000",
  },
  {
    id: 120753,
    name: "Longmeadow",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.05010000",
    longitude: "-72.58287000",
  },
  {
    id: 120836,
    name: "Lowell",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.63342000",
    longitude: "-71.31617000",
  },
  {
    id: 120870,
    name: "Ludlow",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.16009000",
    longitude: "-72.47592000",
  },
  {
    id: 120887,
    name: "Lunenburg",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.59453000",
    longitude: "-71.72452000",
  },
  {
    id: 120927,
    name: "Lynn",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.46676000",
    longitude: "-70.94949000",
  },
  {
    id: 120930,
    name: "Lynnfield",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.53898000",
    longitude: "-71.04811000",
  },
  {
    id: 121060,
    name: "Malden",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.42510000",
    longitude: "-71.06616000",
  },
  {
    id: 121104,
    name: "Manchester-by-the-Sea",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.57787000",
    longitude: "-70.76894000",
  },
  {
    id: 121146,
    name: "Mansfield",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.03343000",
    longitude: "-71.21894000",
  },
  {
    id: 121148,
    name: "Mansfield Center",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.02262000",
    longitude: "-71.21808000",
  },
  {
    id: 121189,
    name: "Marblehead",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.50010000",
    longitude: "-70.85783000",
  },
  {
    id: 121242,
    name: "Marion",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.70010000",
    longitude: "-70.76281000",
  },
  {
    id: 121246,
    name: "Marion Center",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.70424000",
    longitude: "-70.76286000",
  },
  {
    id: 121280,
    name: "Marlborough",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.34593000",
    longitude: "-71.55229000",
  },
  {
    id: 121326,
    name: "Marshfield",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.09177000",
    longitude: "-70.70559000",
  },
  {
    id: 121328,
    name: "Marshfield Hills",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.14594000",
    longitude: "-70.73976000",
  },
  {
    id: 121331,
    name: "Marstons Mills",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.65622000",
    longitude: "-70.41614000",
  },
  {
    id: 121370,
    name: "Mashpee",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.64844000",
    longitude: "-70.48114000",
  },
  {
    id: 121407,
    name: "Mattapoisett",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.65844000",
    longitude: "-70.81615000",
  },
  {
    id: 121408,
    name: "Mattapoisett Center",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.66595000",
    longitude: "-70.80720000",
  },
  {
    id: 121440,
    name: "Maynard",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.43343000",
    longitude: "-71.44951000",
  },
  {
    id: 121590,
    name: "Medfield",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.18760000",
    longitude: "-71.30645000",
  },
  {
    id: 121592,
    name: "Medford",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.41843000",
    longitude: "-71.10616000",
  },
  {
    id: 121610,
    name: "Medway",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.14176000",
    longitude: "-71.39673000",
  },
  {
    id: 121628,
    name: "Melrose",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.45843000",
    longitude: "-71.06616000",
  },
  {
    id: 121653,
    name: "Mendon",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.10565000",
    longitude: "-71.55229000",
  },
  {
    id: 121710,
    name: "Merrimac",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.83065000",
    longitude: "-71.00228000",
  },
  {
    id: 121734,
    name: "Methuen",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.72620000",
    longitude: "-71.19089000",
  },
  {
    id: 121765,
    name: "Middleborough",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.89316000",
    longitude: "-70.91115000",
  },
  {
    id: 121766,
    name: "Middleborough Center",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.89460000",
    longitude: "-70.92618000",
  },
  {
    id: 121780,
    name: "Middlesex County",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.48555000",
    longitude: "-71.39184000",
  },
  {
    id: 121782,
    name: "Middleton",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.59509000",
    longitude: "-71.01616000",
  },
  {
    id: 121850,
    name: "Milford",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.13982000",
    longitude: "-71.51617000",
  },
  {
    id: 121874,
    name: "Millbury",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.19398000",
    longitude: "-71.76007000",
  },
  {
    id: 121885,
    name: "Millers Falls",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.58203000",
    longitude: "-72.49259000",
  },
  {
    id: 121894,
    name: "Millis",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.16760000",
    longitude: "-71.35784000",
  },
  {
    id: 121895,
    name: "Millis-Clicquot",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.16480000",
    longitude: "-71.35442000",
  },
  {
    id: 121905,
    name: "Millville",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.02788000",
    longitude: "-71.58090000",
  },
  {
    id: 121915,
    name: "Milton",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.24954000",
    longitude: "-71.06616000",
  },
  {
    id: 122090,
    name: "Monson",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.10426000",
    longitude: "-72.31897000",
  },
  {
    id: 122091,
    name: "Monson Center",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.09898000",
    longitude: "-72.30481000",
  },
  {
    id: 122096,
    name: "Montague",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.53564000",
    longitude: "-72.53509000",
  },
  {
    id: 122192,
    name: "Monument Beach",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.71955000",
    longitude: "-70.61198000",
  },
  {
    id: 122535,
    name: "Nahant",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.42649000",
    longitude: "-70.91894000",
  },
  {
    id: 122542,
    name: "Nantucket",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.28346000",
    longitude: "-70.09946000",
  },
  {
    id: 122543,
    name: "Nantucket County",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.26955000",
    longitude: "-70.02171000",
  },
  {
    id: 122590,
    name: "Natick",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.28343000",
    longitude: "-71.34950000",
  },
  {
    id: 122613,
    name: "Needham",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.28343000",
    longitude: "-71.23283000",
  },
  {
    id: 122662,
    name: "New Bedford",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.63526000",
    longitude: "-70.92701000",
  },
  {
    id: 122752,
    name: "New Marlborough",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.12287000",
    longitude: "-73.22872000",
  },
  {
    id: 122819,
    name: "Newburyport",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.81259000",
    longitude: "-70.87728000",
  },
  {
    id: 122865,
    name: "Newton",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.33704000",
    longitude: "-71.20922000",
  },
  {
    id: 122942,
    name: "Norfolk",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.11954000",
    longitude: "-71.32506000",
  },
  {
    id: 122945,
    name: "Norfolk County",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.17097000",
    longitude: "-71.18381000",
  },
  {
    id: 122958,
    name: "North Adams",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.70092000",
    longitude: "-73.10871000",
  },
  {
    id: 122960,
    name: "North Amherst",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.41037000",
    longitude: "-72.53092000",
  },
  {
    id: 122962,
    name: "North Andover",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.69870000",
    longitude: "-71.13506000",
  },
  {
    id: 122967,
    name: "North Attleborough Center",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.97263000",
    longitude: "-71.32474000",
  },
  {
    id: 122996,
    name: "North Brookfield",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.26676000",
    longitude: "-72.08285000",
  },
  {
    id: 123007,
    name: "North Chicopee",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.18343000",
    longitude: "-72.59953000",
  },
  {
    id: 123019,
    name: "North Eastham",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.86511000",
    longitude: "-69.99113000",
  },
  {
    id: 123025,
    name: "North Falmouth",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.64594000",
    longitude: "-70.61836000",
  },
  {
    id: 123051,
    name: "North Lakeville",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.85760000",
    longitude: "-70.94226000",
  },
  {
    id: 123078,
    name: "North Pembroke",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.09316000",
    longitude: "-70.79254000",
  },
  {
    id: 123083,
    name: "North Plymouth",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.97094000",
    longitude: "-70.68281000",
  },
  {
    id: 123091,
    name: "North Reading",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.57509000",
    longitude: "-71.07867000",
  },
  {
    id: 123101,
    name: "North Scituate",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.21899000",
    longitude: "-70.78560000",
  },
  {
    id: 123104,
    name: "North Seekonk",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.88927000",
    longitude: "-71.33005000",
  },
  {
    id: 123128,
    name: "North Westport",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.66038000",
    longitude: "-71.08838000",
  },
  {
    id: 123134,
    name: "Northampton",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.32509000",
    longitude: "-72.64120000",
  },
  {
    id: 123139,
    name: "Northborough",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.31954000",
    longitude: "-71.64118000",
  },
  {
    id: 123140,
    name: "Northbridge",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.15148000",
    longitude: "-71.64951000",
  },
  {
    id: 123150,
    name: "Northfield",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.69592000",
    longitude: "-72.45287000",
  },
  {
    id: 123176,
    name: "Northwest Harwich",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.69029000",
    longitude: "-70.10250000",
  },
  {
    id: 123184,
    name: "Norton",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.96677000",
    longitude: "-71.18699000",
  },
  {
    id: 123185,
    name: "Norton Center",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.97254000",
    longitude: "-71.18535000",
  },
  {
    id: 123194,
    name: "Norwell",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.16177000",
    longitude: "-70.79393000",
  },
  {
    id: 123199,
    name: "Norwood",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.19454000",
    longitude: "-71.19950000",
  },
  {
    id: 123229,
    name: "Oak Bluffs",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.45428000",
    longitude: "-70.56197000",
  },
  {
    id: 123278,
    name: "Oakham",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.35287000",
    longitude: "-72.04535000",
  },
  {
    id: 123324,
    name: "Ocean Bluff-Brant Rock",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.10234000",
    longitude: "-70.65736000",
  },
  {
    id: 123330,
    name: "Ocean Grove",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.72927000",
    longitude: "-71.20921000",
  },
  {
    id: 123483,
    name: "Onset",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.74177000",
    longitude: "-70.65781000",
  },
  {
    id: 123508,
    name: "Orange",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.59036000",
    longitude: "-72.30981000",
  },
  {
    id: 123564,
    name: "Orleans",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.78983000",
    longitude: "-69.98974000",
  },
  {
    id: 123620,
    name: "Osterville",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.62844000",
    longitude: "-70.38697000",
  },
  {
    id: 123628,
    name: "Otis",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.19315000",
    longitude: "-73.09177000",
  },
  {
    id: 123683,
    name: "Oxford",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.11676000",
    longitude: "-71.86479000",
  },
  {
    id: 123759,
    name: "Palmer",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.15843000",
    longitude: "-72.32869000",
  },
  {
    id: 123942,
    name: "Paxton",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.31120000",
    longitude: "-71.92813000",
  },
  {
    id: 123954,
    name: "Peabody",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.52787000",
    longitude: "-70.92866000",
  },
  {
    id: 123990,
    name: "Pelham",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.39315000",
    longitude: "-72.40370000",
  },
  {
    id: 124054,
    name: "Pepperell",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.66592000",
    longitude: "-71.58840000",
  },
  {
    id: 124141,
    name: "Phillipston",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.54870000",
    longitude: "-72.13286000",
  },
  {
    id: 124252,
    name: "Pinehurst",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.52926000",
    longitude: "-71.22811000",
  },
  {
    id: 124298,
    name: "Pittsfield",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.45008000",
    longitude: "-73.24538000",
  },
  {
    id: 124335,
    name: "Plainville",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.00427000",
    longitude: "-71.33283000",
  },
  {
    id: 124402,
    name: "Plymouth",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.95844000",
    longitude: "-70.66726000",
  },
  {
    id: 124410,
    name: "Plymouth County",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.98743000",
    longitude: "-70.73707000",
  },
  {
    id: 124412,
    name: "Plympton",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.95288000",
    longitude: "-70.81448000",
  },
  {
    id: 124417,
    name: "Pocasset",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.68622000",
    longitude: "-70.61614000",
  },
  {
    id: 124687,
    name: "Princeton",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.44870000",
    longitude: "-71.87730000",
  },
  {
    id: 124720,
    name: "Provincetown",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.05295000",
    longitude: "-70.18640000",
  },
  {
    id: 124797,
    name: "Quincy",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.25288000",
    longitude: "-71.00227000",
  },
  {
    id: 124865,
    name: "Randolph",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.16260000",
    longitude: "-71.04116000",
  },
  {
    id: 124924,
    name: "Raynham",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.94871000",
    longitude: "-71.07310000",
  },
  {
    id: 124925,
    name: "Raynham Center",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.92371000",
    longitude: "-71.05227000",
  },
  {
    id: 124929,
    name: "Reading",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.52565000",
    longitude: "-71.09533000",
  },
  {
    id: 125004,
    name: "Rehoboth",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.84038000",
    longitude: "-71.24949000",
  },
  {
    id: 125037,
    name: "Revere",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.40843000",
    longitude: "-71.01199000",
  },
  {
    id: 125090,
    name: "Richmond",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.37314000",
    longitude: "-73.36761000",
  },
  {
    id: 125262,
    name: "Rochester",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.73177000",
    longitude: "-70.82004000",
  },
  {
    id: 125309,
    name: "Rockland",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.13066000",
    longitude: "-70.91616000",
  },
  {
    id: 125317,
    name: "Rockport",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.65565000",
    longitude: "-70.62032000",
  },
  {
    id: 125482,
    name: "Rowley",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.71676000",
    longitude: "-70.87866000",
  },
  {
    id: 125494,
    name: "Royalston",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.67759000",
    longitude: "-72.18786000",
  },
  {
    id: 125555,
    name: "Rutland",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.36954000",
    longitude: "-71.94813000",
  },
  {
    id: 125586,
    name: "Sagamore",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.77011000",
    longitude: "-70.52836000",
  },
  {
    id: 125735,
    name: "Salem",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.51954000",
    longitude: "-70.89672000",
  },
  {
    id: 125757,
    name: "Salisbury",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.84176000",
    longitude: "-70.86061000",
  },
  {
    id: 125877,
    name: "Sandwich",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.75900000",
    longitude: "-70.49392000",
  },
  {
    id: 125961,
    name: "Saugus",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.46482000",
    longitude: "-71.01005000",
  },
  {
    id: 126030,
    name: "Scituate",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.19593000",
    longitude: "-70.72587000",
  },
  {
    id: 126123,
    name: "Seekonk",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.80843000",
    longitude: "-71.33700000",
  },
  {
    id: 126228,
    name: "Sharon",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.12371000",
    longitude: "-71.17866000",
  },
  {
    id: 126262,
    name: "Sheffield",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.11037000",
    longitude: "-73.35511000",
  },
  {
    id: 126265,
    name: "Shelburne",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.58981000",
    longitude: "-72.68842000",
  },
  {
    id: 126266,
    name: "Shelburne Falls",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.60425000",
    longitude: "-72.73926000",
  },
  {
    id: 126313,
    name: "Sherborn",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.23899000",
    longitude: "-71.36978000",
  },
  {
    id: 126360,
    name: "Shirley",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.54370000",
    longitude: "-71.64951000",
  },
  {
    id: 126385,
    name: "Shrewsbury",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.29593000",
    longitude: "-71.71285000",
  },
  {
    id: 126389,
    name: "Shutesbury",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.45648000",
    longitude: "-72.40981000",
  },
  {
    id: 126516,
    name: "Smith Mills",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.63899000",
    longitude: "-70.99115000",
  },
  {
    id: 126582,
    name: "Somerset",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.76955000",
    longitude: "-71.12866000",
  },
  {
    id: 126595,
    name: "Somerville",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.38760000",
    longitude: "-71.09950000",
  },
  {
    id: 126613,
    name: "South Amherst",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.34037000",
    longitude: "-72.50509000",
  },
  {
    id: 126615,
    name: "South Ashburnham",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.61037000",
    longitude: "-71.93897000",
  },
  {
    id: 126629,
    name: "South Boston",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.33343000",
    longitude: "-71.04949000",
  },
  {
    id: 126646,
    name: "South Deerfield",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.47731000",
    longitude: "-72.60787000",
  },
  {
    id: 126647,
    name: "South Dennis",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.68956000",
    longitude: "-70.15641000",
  },
  {
    id: 126649,
    name: "South Duxbury",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.02316000",
    longitude: "-70.68281000",
  },
  {
    id: 126665,
    name: "South Hadley",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.25842000",
    longitude: "-72.57453000",
  },
  {
    id: 126685,
    name: "South Lancaster",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.44454000",
    longitude: "-71.68701000",
  },
  {
    id: 126708,
    name: "South Peabody",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.50982000",
    longitude: "-70.94949000",
  },
  {
    id: 126755,
    name: "South Yarmouth",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.66678000",
    longitude: "-70.18474000",
  },
  {
    id: 126757,
    name: "Southampton",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.22926000",
    longitude: "-72.73009000",
  },
  {
    id: 126761,
    name: "Southborough",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.30565000",
    longitude: "-71.52451000",
  },
  {
    id: 126762,
    name: "Southbridge",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.07510000",
    longitude: "-72.03341000",
  },
  {
    id: 126791,
    name: "Southwick",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.05482000",
    longitude: "-72.77037000",
  },
  {
    id: 126826,
    name: "Spencer",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.24398000",
    longitude: "-71.99230000",
  },
  {
    id: 126897,
    name: "Springfield",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.10148000",
    longitude: "-72.58981000",
  },
  {
    id: 127028,
    name: "Sterling",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.43759000",
    longitude: "-71.76063000",
  },
  {
    id: 127068,
    name: "Stockbridge",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.28759000",
    longitude: "-73.32039000",
  },
  {
    id: 127089,
    name: "Stoneham",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.48010000",
    longitude: "-71.09950000",
  },
  {
    id: 127109,
    name: "Stoughton",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.12510000",
    longitude: "-71.10227000",
  },
  {
    id: 127112,
    name: "Stow",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.43704000",
    longitude: "-71.50562000",
  },
  {
    id: 127147,
    name: "Sturbridge",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.10843000",
    longitude: "-72.07869000",
  },
  {
    id: 127161,
    name: "Sudbury",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.38343000",
    longitude: "-71.41617000",
  },
  {
    id: 127167,
    name: "Suffolk County",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.35550000",
    longitude: "-71.06575000",
  },
  {
    id: 127249,
    name: "Sunderland",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.24454000",
    longitude: "-71.77174000",
  },
  {
    id: 127312,
    name: "Sutton",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.15010000",
    longitude: "-71.76285000",
  },
  {
    id: 127321,
    name: "Swampscott",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.47093000",
    longitude: "-70.91755000",
  },
  {
    id: 127326,
    name: "Swansea",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.74816000",
    longitude: "-71.18977000",
  },
  {
    id: 127441,
    name: "Taunton",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.90010000",
    longitude: "-71.08977000",
  },
  {
    id: 127476,
    name: "Teaticket",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.56455000",
    longitude: "-70.59586000",
  },
  {
    id: 127504,
    name: "Templeton",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.55564000",
    longitude: "-72.06758000",
  },
  {
    id: 127542,
    name: "Tewksbury",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.61065000",
    longitude: "-71.23422000",
  },
  {
    id: 127619,
    name: "Three Rivers",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.18120000",
    longitude: "-72.36064000",
  },
  {
    id: 127735,
    name: "Topsfield",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.63759000",
    longitude: "-70.94950000",
  },
  {
    id: 127765,
    name: "Townsend",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.66676000",
    longitude: "-71.70507000",
  },
  {
    id: 127859,
    name: "Truro",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.99344000",
    longitude: "-70.04975000",
  },
  {
    id: 127905,
    name: "Turners Falls",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.60425000",
    longitude: "-72.55648000",
  },
  {
    id: 127947,
    name: "Tyngsboro",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.67676000",
    longitude: "-71.42451000",
  },
  {
    id: 128055,
    name: "Upton",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.17454000",
    longitude: "-71.60229000",
  },
  {
    id: 128071,
    name: "Uxbridge",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.07732000",
    longitude: "-71.62951000",
  },
  {
    id: 128274,
    name: "Vineyard Haven",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.45428000",
    longitude: "-70.60364000",
  },
  {
    id: 128359,
    name: "Wakefield",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.50648000",
    longitude: "-71.07283000",
  },
  {
    id: 128379,
    name: "Wales",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.06954000",
    longitude: "-72.22230000",
  },
  {
    id: 128425,
    name: "Walpole",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.14177000",
    longitude: "-71.24950000",
  },
  {
    id: 128432,
    name: "Waltham",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.37649000",
    longitude: "-71.23561000",
  },
  {
    id: 128462,
    name: "Ware",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.25981000",
    longitude: "-72.23980000",
  },
  {
    id: 128465,
    name: "Wareham Center",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.76677000",
    longitude: "-70.72615000",
  },
  {
    id: 128478,
    name: "Warren",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.21259000",
    longitude: "-72.19119000",
  },
  {
    id: 128621,
    name: "Watertown",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.37093000",
    longitude: "-71.18283000",
  },
  {
    id: 128678,
    name: "Wayland",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.36260000",
    longitude: "-71.36145000",
  },
  {
    id: 128731,
    name: "Webster",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.05010000",
    longitude: "-71.88007000",
  },
  {
    id: 128773,
    name: "Wellesley",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.29649000",
    longitude: "-71.29256000",
  },
  {
    id: 128774,
    name: "Wellfleet",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.93761000",
    longitude: "-70.03280000",
  },
  {
    id: 128799,
    name: "Wendell",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.54814000",
    longitude: "-72.39675000",
  },
  {
    id: 128802,
    name: "Wenham",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.60426000",
    longitude: "-70.89116000",
  },
  {
    id: 128821,
    name: "West Barnstable",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.70566000",
    longitude: "-70.37447000",
  },
  {
    id: 128830,
    name: "West Boylston",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.36676000",
    longitude: "-71.78563000",
  },
  {
    id: 128835,
    name: "West Bridgewater",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.01899000",
    longitude: "-71.00782000",
  },
  {
    id: 128836,
    name: "West Brookfield",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.23537000",
    longitude: "-72.14119000",
  },
  {
    id: 128844,
    name: "West Chatham",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.68122000",
    longitude: "-69.99113000",
  },
  {
    id: 128850,
    name: "West Concord",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.45843000",
    longitude: "-71.39534000",
  },
  {
    id: 128856,
    name: "West Dennis",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.66456000",
    longitude: "-70.17280000",
  },
  {
    id: 128868,
    name: "West Falmouth",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.60427000",
    longitude: "-70.63447000",
  },
  {
    id: 128939,
    name: "West Newbury",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.80148000",
    longitude: "-70.98978000",
  },
  {
    id: 128985,
    name: "West Springfield",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.10704000",
    longitude: "-72.62037000",
  },
  {
    id: 128986,
    name: "West Stockbridge",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.34592000",
    longitude: "-73.36622000",
  },
  {
    id: 128990,
    name: "West Tisbury",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.38122000",
    longitude: "-70.67447000",
  },
  {
    id: 129000,
    name: "West Wareham",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.78983000",
    longitude: "-70.76031000",
  },
  {
    id: 129008,
    name: "West Yarmouth",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.65011000",
    longitude: "-70.24113000",
  },
  {
    id: 129012,
    name: "Westborough",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.26954000",
    longitude: "-71.61618000",
  },
  {
    id: 129028,
    name: "Westfield",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.12509000",
    longitude: "-72.74954000",
  },
  {
    id: 129033,
    name: "Westford",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.57926000",
    longitude: "-71.43784000",
  },
  {
    id: 129034,
    name: "Westhampton",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.30287000",
    longitude: "-72.77454000",
  },
  {
    id: 129046,
    name: "Westminster",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.54592000",
    longitude: "-71.91063000",
  },
  {
    id: 129061,
    name: "Weston",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.36676000",
    longitude: "-71.30311000",
  },
  {
    id: 129085,
    name: "Westwood",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.21399000",
    longitude: "-71.22450000",
  },
  {
    id: 129095,
    name: "Weweantic",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.73538000",
    longitude: "-70.73198000",
  },
  {
    id: 129100,
    name: "Weymouth",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.22093000",
    longitude: "-70.93977000",
  },
  {
    id: 129106,
    name: "Whately",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.43981000",
    longitude: "-72.63481000",
  },
  {
    id: 129144,
    name: "White Island Shores",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.80010000",
    longitude: "-70.63475000",
  },
  {
    id: 129198,
    name: "Whitinsville",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.11121000",
    longitude: "-71.66618000",
  },
  {
    id: 129203,
    name: "Whitman",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.08066000",
    longitude: "-70.93560000",
  },
  {
    id: 129226,
    name: "Wilbraham",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.12371000",
    longitude: "-72.43147000",
  },
  {
    id: 129269,
    name: "Williamsburg",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.39314000",
    longitude: "-72.73009000",
  },
  {
    id: 129288,
    name: "Williamstown",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.71202000",
    longitude: "-73.20372000",
  },
  {
    id: 129324,
    name: "Wilmington",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.54648000",
    longitude: "-71.17367000",
  },
  {
    id: 129351,
    name: "Winchendon",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.68620000",
    longitude: "-72.04397000",
  },
  {
    id: 129358,
    name: "Winchester",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.45232000",
    longitude: "-71.13700000",
  },
  {
    id: 129450,
    name: "Winthrop",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.37510000",
    longitude: "-70.98283000",
  },
  {
    id: 129466,
    name: "Woburn",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.47926000",
    longitude: "-71.15228000",
  },
  {
    id: 129584,
    name: "Worcester",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.26259000",
    longitude: "-71.80229000",
  },
  {
    id: 129587,
    name: "Worcester County",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.35140000",
    longitude: "-71.90774000",
  },
  {
    id: 129604,
    name: "Wrentham",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "42.06677000",
    longitude: "-71.32811000",
  },
  {
    id: 129667,
    name: "Yarmouth",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.70567000",
    longitude: "-70.22863000",
  },
  {
    id: 129668,
    name: "Yarmouth Port",
    state_id: 1433,
    state_code: "MA",
    country_id: 233,
    country_code: "US",
    latitude: "41.70205000",
    longitude: "-70.24947000",
  },
  {
    id: 111046,
    name: "Adrian",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.89755000",
    longitude: "-84.03717000",
  },
  {
    id: 111126,
    name: "Albion",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.24310000",
    longitude: "-84.75303000",
  },
  {
    id: 111133,
    name: "Alcona County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.71161000",
    longitude: "-83.34366000",
  },
  {
    id: 111161,
    name: "Alger County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "46.45110000",
    longitude: "-86.54755000",
  },
  {
    id: 111165,
    name: "Algonac",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.61858000",
    longitude: "-82.53230000",
  },
  {
    id: 111178,
    name: "Allegan",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.52920000",
    longitude: "-85.85530000",
  },
  {
    id: 111179,
    name: "Allegan County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.51766000",
    longitude: "-85.91034000",
  },
  {
    id: 111192,
    name: "Allen Park",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.25754000",
    longitude: "-83.21104000",
  },
  {
    id: 111194,
    name: "Allendale",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.97225000",
    longitude: "-85.95365000",
  },
  {
    id: 111209,
    name: "Alma",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.37892000",
    longitude: "-84.65973000",
  },
  {
    id: 111213,
    name: "Almont",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.92058000",
    longitude: "-83.04493000",
  },
  {
    id: 111218,
    name: "Alpena",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "45.06168000",
    longitude: "-83.43275000",
  },
  {
    id: 111219,
    name: "Alpena County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "45.06350000",
    longitude: "-83.46039000",
  },
  {
    id: 111345,
    name: "Ann Arbor",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.27756000",
    longitude: "-83.74088000",
  },
  {
    id: 111377,
    name: "Antrim County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "45.00737000",
    longitude: "-85.17579000",
  },
  {
    id: 111445,
    name: "Arenac County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.04289000",
    longitude: "-83.74725000",
  },
  {
    id: 111447,
    name: "Argentine",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.79142000",
    longitude: "-83.84634000",
  },
  {
    id: 111476,
    name: "Armada",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.84420000",
    longitude: "-82.88437000",
  },
  {
    id: 111578,
    name: "Athens",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.08866000",
    longitude: "-85.23471000",
  },
  {
    id: 111593,
    name: "Atlanta",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "45.00473000",
    longitude: "-84.14389000",
  },
  {
    id: 111616,
    name: "Au Sable",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.41085000",
    longitude: "-83.33219000",
  },
  {
    id: 111626,
    name: "Auburn",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.60336000",
    longitude: "-84.06970000",
  },
  {
    id: 111634,
    name: "Auburn Hills",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.68753000",
    longitude: "-83.23410000",
  },
  {
    id: 111721,
    name: "Bad Axe",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.80196000",
    longitude: "-83.00078000",
  },
  {
    id: 111755,
    name: "Baldwin",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.90112000",
    longitude: "-85.85173000",
  },
  {
    id: 111791,
    name: "Bangor",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.31254000",
    longitude: "-86.11308000",
  },
  {
    id: 111809,
    name: "Baraga",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "46.77854000",
    longitude: "-88.48902000",
  },
  {
    id: 111810,
    name: "Baraga County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "46.69976000",
    longitude: "-88.35215000",
  },
  {
    id: 111827,
    name: "Barnes Lake-Millers Lake",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.17956000",
    longitude: "-83.31230000",
  },
  {
    id: 111856,
    name: "Barry County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.59503000",
    longitude: "-85.30897000",
  },
  {
    id: 111896,
    name: "Bath",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.81864000",
    longitude: "-84.44859000",
  },
  {
    id: 111904,
    name: "Battle Creek",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.31730000",
    longitude: "-85.17816000",
  },
  {
    id: 111922,
    name: "Bay City",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.59447000",
    longitude: "-83.88886000",
  },
  {
    id: 111925,
    name: "Bay County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.72137000",
    longitude: "-83.94184000",
  },
  {
    id: 111926,
    name: "Bay Harbor",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "45.36413000",
    longitude: "-85.08208000",
  },
  {
    id: 112009,
    name: "Beaverton",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.88225000",
    longitude: "-84.48473000",
  },
  {
    id: 112040,
    name: "Beecher",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.09003000",
    longitude: "-83.69440000",
  },
  {
    id: 112042,
    name: "Beechwood",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.79697000",
    longitude: "-86.12588000",
  },
  {
    id: 112053,
    name: "Belding",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.09781000",
    longitude: "-85.22891000",
  },
  {
    id: 112076,
    name: "Bellaire",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.98028000",
    longitude: "-85.21117000",
  },
  {
    id: 112105,
    name: "Belleville",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.20476000",
    longitude: "-83.48521000",
  },
  {
    id: 112112,
    name: "Bellevue",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.44337000",
    longitude: "-85.01805000",
  },
  {
    id: 112208,
    name: "Benton Harbor",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.11671000",
    longitude: "-86.45419000",
  },
  {
    id: 112209,
    name: "Benton Heights",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.13115000",
    longitude: "-86.40724000",
  },
  {
    id: 112212,
    name: "Benzie County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.61687000",
    longitude: "-86.13899000",
  },
  {
    id: 112230,
    name: "Berkley",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.50309000",
    longitude: "-83.18354000",
  },
  {
    id: 112250,
    name: "Berrien County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.91863000",
    longitude: "-86.42807000",
  },
  {
    id: 112251,
    name: "Berrien Springs",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.94643000",
    longitude: "-86.33890000",
  },
  {
    id: 112268,
    name: "Bessemer",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "46.48134000",
    longitude: "-90.05295000",
  },
  {
    id: 112295,
    name: "Beulah",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.63194000",
    longitude: "-86.09092000",
  },
  {
    id: 112305,
    name: "Beverly Hills",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.52392000",
    longitude: "-83.22326000",
  },
  {
    id: 112330,
    name: "Big Rapids",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.69808000",
    longitude: "-85.48366000",
  },
  {
    id: 112350,
    name: "Bingham Farms",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.51587000",
    longitude: "-83.27326000",
  },
  {
    id: 112354,
    name: "Birch Run",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.25086000",
    longitude: "-83.79413000",
  },
  {
    id: 112359,
    name: "Birmingham",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.54670000",
    longitude: "-83.21132000",
  },
  {
    id: 112431,
    name: "Blissfield",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.83255000",
    longitude: "-83.86244000",
  },
  {
    id: 112441,
    name: "Bloomfield Hills",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.58364000",
    longitude: "-83.24549000",
  },
  {
    id: 112651,
    name: "Boyne City",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "45.21668000",
    longitude: "-85.01394000",
  },
  {
    id: 112685,
    name: "Branch County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.91611000",
    longitude: "-85.05903000",
  },
  {
    id: 112711,
    name: "Breckenridge",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.40808000",
    longitude: "-84.47500000",
  },
  {
    id: 112762,
    name: "Bridgeport",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.35947000",
    longitude: "-83.88164000",
  },
  {
    id: 112778,
    name: "Bridgman",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.94310000",
    longitude: "-86.55697000",
  },
  {
    id: 112789,
    name: "Brighton",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.52948000",
    longitude: "-83.78022000",
  },
  {
    id: 112845,
    name: "Bronson",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.87227000",
    longitude: "-85.19470000",
  },
  {
    id: 112871,
    name: "Brooklyn",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.10587000",
    longitude: "-84.24828000",
  },
  {
    id: 112906,
    name: "Brown City",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.21225000",
    longitude: "-82.98966000",
  },
  {
    id: 112919,
    name: "Brownlee Park",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.31893000",
    longitude: "-85.14249000",
  },
  {
    id: 112971,
    name: "Buchanan",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.82727000",
    longitude: "-86.36112000",
  },
  {
    id: 113002,
    name: "Buena Vista",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.42030000",
    longitude: "-83.89858000",
  },
  {
    id: 113094,
    name: "Burt",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.23669000",
    longitude: "-83.90636000",
  },
  {
    id: 113097,
    name: "Burton",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.99947000",
    longitude: "-83.61634000",
  },
  {
    id: 113147,
    name: "Byron Center",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.81225000",
    longitude: "-85.72281000",
  },
  {
    id: 113164,
    name: "Cadillac",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.25195000",
    longitude: "-85.40116000",
  },
  {
    id: 113189,
    name: "Caledonia",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.78920000",
    longitude: "-85.51669000",
  },
  {
    id: 113210,
    name: "Calhoun County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.24653000",
    longitude: "-85.00559000",
  },
  {
    id: 113316,
    name: "Canadian Lakes",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.57919000",
    longitude: "-85.30170000",
  },
  {
    id: 113345,
    name: "Canton",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.30865000",
    longitude: "-83.48216000",
  },
  {
    id: 113362,
    name: "Capac",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.01253000",
    longitude: "-82.92799000",
  },
  {
    id: 113398,
    name: "Carleton",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.05921000",
    longitude: "-83.39077000",
  },
  {
    id: 113432,
    name: "Caro",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.49073000",
    longitude: "-83.39885000",
  },
  {
    id: 113471,
    name: "Carrollton",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.45864000",
    longitude: "-83.93025000",
  },
  {
    id: 113477,
    name: "Carson City",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.17698000",
    longitude: "-84.84639000",
  },
  {
    id: 113525,
    name: "Cass City",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.60085000",
    longitude: "-83.17467000",
  },
  {
    id: 113531,
    name: "Cass County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.91540000",
    longitude: "-85.99346000",
  },
  {
    id: 113538,
    name: "Cassopolis",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.91171000",
    longitude: "-86.01001000",
  },
  {
    id: 113623,
    name: "Cedar Springs",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.22336000",
    longitude: "-85.55142000",
  },
  {
    id: 113644,
    name: "Center Line",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.48504000",
    longitude: "-83.02770000",
  },
  {
    id: 113696,
    name: "Centreville",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.92338000",
    longitude: "-85.52832000",
  },
  {
    id: 113774,
    name: "Charlevoix",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "45.31806000",
    longitude: "-85.25840000",
  },
  {
    id: 113775,
    name: "Charlevoix County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "45.26715000",
    longitude: "-85.24017000",
  },
  {
    id: 113779,
    name: "Charlotte",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.56365000",
    longitude: "-84.83582000",
  },
  {
    id: 113821,
    name: "Cheboygan",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "45.64696000",
    longitude: "-84.47448000",
  },
  {
    id: 113822,
    name: "Cheboygan County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "45.47294000",
    longitude: "-84.49206000",
  },
  {
    id: 113833,
    name: "Chelsea",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.31807000",
    longitude: "-84.02181000",
  },
  {
    id: 113872,
    name: "Chesaning",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.18475000",
    longitude: "-84.11497000",
  },
  {
    id: 113973,
    name: "Chippewa County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "46.32818000",
    longitude: "-84.52936000",
  },
  {
    id: 114102,
    name: "Clare",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.81947000",
    longitude: "-84.76863000",
  },
  {
    id: 114103,
    name: "Clare County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.98787000",
    longitude: "-84.84784000",
  },
  {
    id: 114149,
    name: "Clarkston",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.73586000",
    longitude: "-83.41883000",
  },
  {
    id: 114163,
    name: "Clawson",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.53337000",
    longitude: "-83.14632000",
  },
  {
    id: 114282,
    name: "Clinton",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.07199000",
    longitude: "-83.97161000",
  },
  {
    id: 114294,
    name: "Clinton County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.94365000",
    longitude: "-84.60152000",
  },
  {
    id: 114297,
    name: "Clinton Township",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.58698000",
    longitude: "-82.91992000",
  },
  {
    id: 114302,
    name: "Clio",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.17753000",
    longitude: "-83.73413000",
  },
  {
    id: 114394,
    name: "Coldwater",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.94033000",
    longitude: "-85.00052000",
  },
  {
    id: 114399,
    name: "Coleman",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.75669000",
    longitude: "-84.58584000",
  },
  {
    id: 114443,
    name: "Coloma",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.18615000",
    longitude: "-86.30836000",
  },
  {
    id: 114444,
    name: "Colon",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.95838000",
    longitude: "-85.32498000",
  },
  {
    id: 114530,
    name: "Comstock Northwest",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.32182000",
    longitude: "-85.51759000",
  },
  {
    id: 114531,
    name: "Comstock Park",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.03864000",
    longitude: "-85.67003000",
  },
  {
    id: 114539,
    name: "Concord",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.17782000",
    longitude: "-84.64302000",
  },
  {
    id: 114573,
    name: "Constantine",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.84116000",
    longitude: "-85.66860000",
  },
  {
    id: 114608,
    name: "Coopersville",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.06391000",
    longitude: "-85.93477000",
  },
  {
    id: 114679,
    name: "Corunna",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.98197000",
    longitude: "-84.11775000",
  },
  {
    id: 114781,
    name: "Crawford County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.68361000",
    longitude: "-84.61030000",
  },
  {
    id: 114857,
    name: "Croswell",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.27558000",
    longitude: "-82.62104000",
  },
  {
    id: 114877,
    name: "Crystal Falls",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "46.09801000",
    longitude: "-88.33402000",
  },
  {
    id: 114950,
    name: "Cutlerville",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.84086000",
    longitude: "-85.66364000",
  },
  {
    id: 115078,
    name: "Davison",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.03475000",
    longitude: "-83.51801000",
  },
  {
    id: 115145,
    name: "Dearborn",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.32226000",
    longitude: "-83.17631000",
  },
  {
    id: 115147,
    name: "Dearborn Heights",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.33698000",
    longitude: "-83.27326000",
  },
  {
    id: 115157,
    name: "Decatur",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.10810000",
    longitude: "-85.97446000",
  },
  {
    id: 115225,
    name: "Delta County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "45.79162000",
    longitude: "-86.87060000",
  },
  {
    id: 115291,
    name: "Detroit",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.33143000",
    longitude: "-83.04575000",
  },
  {
    id: 115292,
    name: "Detroit Beach",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.93116000",
    longitude: "-83.32688000",
  },
  {
    id: 115140,
    name: "DeWitt",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.84226000",
    longitude: "-84.56915000",
  },
  {
    id: 115309,
    name: "Dexter",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.33834000",
    longitude: "-83.88954000",
  },
  {
    id: 115329,
    name: "Dickinson County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "46.00935000",
    longitude: "-87.87021000",
  },
  {
    id: 115350,
    name: "Dimondale",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.64559000",
    longitude: "-84.64887000",
  },
  {
    id: 115388,
    name: "Dollar Bay",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "47.11965000",
    longitude: "-88.51151000",
  },
  {
    id: 115422,
    name: "Douglas",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.64336000",
    longitude: "-86.20059000",
  },
  {
    id: 115458,
    name: "Dowagiac",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.98421000",
    longitude: "-86.10862000",
  },
  {
    id: 115529,
    name: "Dundee",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.95727000",
    longitude: "-83.65966000",
  },
  {
    id: 115563,
    name: "Durand",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.91198000",
    longitude: "-83.98468000",
  },
  {
    id: 115610,
    name: "Eagle River",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "47.41381000",
    longitude: "-88.29566000",
  },
  {
    id: 115671,
    name: "East Grand Rapids",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.94114000",
    longitude: "-85.61003000",
  },
  {
    id: 115697,
    name: "East Jordan",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "45.15806000",
    longitude: "-85.12423000",
  },
  {
    id: 115703,
    name: "East Lansing",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.73698000",
    longitude: "-84.48387000",
  },
  {
    id: 115764,
    name: "East Tawas",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.27946000",
    longitude: "-83.49025000",
  },
  {
    id: 115794,
    name: "Eastpointe",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.46837000",
    longitude: "-82.95547000",
  },
  {
    id: 115800,
    name: "Eastwood",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.30310000",
    longitude: "-85.55028000",
  },
  {
    id: 115803,
    name: "Eaton County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.59607000",
    longitude: "-84.83831000",
  },
  {
    id: 115804,
    name: "Eaton Rapids",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.50920000",
    longitude: "-84.65581000",
  },
  {
    id: 115818,
    name: "Ecorse",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.24448000",
    longitude: "-83.14576000",
  },
  {
    id: 115846,
    name: "Edgemont Park",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.74670000",
    longitude: "-84.59359000",
  },
  {
    id: 115882,
    name: "Edmore",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.40809000",
    longitude: "-85.03863000",
  },
  {
    id: 115892,
    name: "Edwardsburg",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.79560000",
    longitude: "-86.08084000",
  },
  {
    id: 115991,
    name: "Elk Rapids",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.89556000",
    longitude: "-85.41646000",
  },
  {
    id: 116103,
    name: "Emmet County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "45.58754000",
    longitude: "-84.98147000",
  },
  {
    id: 116181,
    name: "Escanaba",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "45.74525000",
    longitude: "-87.06458000",
  },
  {
    id: 116201,
    name: "Essexville",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.61530000",
    longitude: "-83.84192000",
  },
  {
    id: 116256,
    name: "Evart",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.90058000",
    longitude: "-85.25810000",
  },
  {
    id: 116288,
    name: "Fair Plain",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.08699000",
    longitude: "-86.45586000",
  },
  {
    id: 116417,
    name: "Farmington",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.46448000",
    longitude: "-83.37632000",
  },
  {
    id: 116423,
    name: "Farmington Hills",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.48531000",
    longitude: "-83.37716000",
  },
  {
    id: 116473,
    name: "Fennville",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.59392000",
    longitude: "-86.10170000",
  },
  {
    id: 116475,
    name: "Fenton",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.79781000",
    longitude: "-83.70495000",
  },
  {
    id: 116487,
    name: "Ferndale",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.46059000",
    longitude: "-83.13465000",
  },
  {
    id: 116500,
    name: "Ferrysburg",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.08446000",
    longitude: "-86.22033000",
  },
  {
    id: 116552,
    name: "Flat Rock",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.09643000",
    longitude: "-83.29187000",
  },
  {
    id: 116566,
    name: "Flint",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.01253000",
    longitude: "-83.68746000",
  },
  {
    id: 116613,
    name: "Flushing",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.06308000",
    longitude: "-83.85107000",
  },
  {
    id: 116657,
    name: "Forest Hills",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.95947000",
    longitude: "-85.48975000",
  },
  {
    id: 116795,
    name: "Fowler",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.00170000",
    longitude: "-84.73972000",
  },
  {
    id: 116798,
    name: "Fowlerville",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.66059000",
    longitude: "-84.07301000",
  },
  {
    id: 116817,
    name: "Frankenmuth",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.33169000",
    longitude: "-83.73802000",
  },
  {
    id: 116821,
    name: "Frankfort",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.63361000",
    longitude: "-86.23454000",
  },
  {
    id: 116836,
    name: "Franklin",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.52226000",
    longitude: "-83.30604000",
  },
  {
    id: 116880,
    name: "Fraser",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.53920000",
    longitude: "-82.94937000",
  },
  {
    id: 116905,
    name: "Freeland",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.52503000",
    longitude: "-84.12276000",
  },
  {
    id: 116921,
    name: "Fremont",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.46752000",
    longitude: "-85.94200000",
  },
  {
    id: 116974,
    name: "Fruitport",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.13196000",
    longitude: "-86.15478000",
  },
  {
    id: 117028,
    name: "Galesburg",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.28865000",
    longitude: "-85.41806000",
  },
  {
    id: 117057,
    name: "Garden City",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.32559000",
    longitude: "-83.33104000",
  },
  {
    id: 117127,
    name: "Gaylord",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "45.02751000",
    longitude: "-84.67475000",
  },
  {
    id: 117135,
    name: "Genesee County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.02172000",
    longitude: "-83.70671000",
  },
  {
    id: 117185,
    name: "Gibraltar",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.09504000",
    longitude: "-83.18965000",
  },
  {
    id: 117237,
    name: "Gladstone",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "45.85274000",
    longitude: "-87.02180000",
  },
  {
    id: 117240,
    name: "Gladwin",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.98085000",
    longitude: "-84.48640000",
  },
  {
    id: 117241,
    name: "Gladwin County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.99067000",
    longitude: "-84.38825000",
  },
  {
    id: 117337,
    name: "Gogebic County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "46.49552000",
    longitude: "-89.79555000",
  },
  {
    id: 117389,
    name: "Goodrich",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.91697000",
    longitude: "-83.50634000",
  },
  {
    id: 117450,
    name: "Grand Blanc",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.92753000",
    longitude: "-83.62995000",
  },
  {
    id: 117460,
    name: "Grand Haven",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.06307000",
    longitude: "-86.22839000",
  },
  {
    id: 117466,
    name: "Grand Ledge",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.75337000",
    longitude: "-84.74638000",
  },
  {
    id: 117473,
    name: "Grand Rapids",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.96336000",
    longitude: "-85.66809000",
  },
  {
    id: 117477,
    name: "Grand Traverse County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.71624000",
    longitude: "-85.55220000",
  },
  {
    id: 117483,
    name: "Grandville",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.90975000",
    longitude: "-85.76309000",
  },
  {
    id: 117542,
    name: "Grass Lake",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.25087000",
    longitude: "-84.21301000",
  },
  {
    id: 117544,
    name: "Gratiot County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.29273000",
    longitude: "-84.60491000",
  },
  {
    id: 117556,
    name: "Grayling",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.66140000",
    longitude: "-84.71475000",
  },
  {
    id: 117690,
    name: "Greenville",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.17753000",
    longitude: "-85.25280000",
  },
  {
    id: 117719,
    name: "Greilickville",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.78306000",
    longitude: "-85.63869000",
  },
  {
    id: 117743,
    name: "Grosse Ile",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.12921000",
    longitude: "-83.14437000",
  },
  {
    id: 117744,
    name: "Grosse Pointe",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.38615000",
    longitude: "-82.91186000",
  },
  {
    id: 117745,
    name: "Grosse Pointe Farms",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.40920000",
    longitude: "-82.89186000",
  },
  {
    id: 117746,
    name: "Grosse Pointe Park",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.37587000",
    longitude: "-82.93742000",
  },
  {
    id: 117747,
    name: "Grosse Pointe Shores",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.43670000",
    longitude: "-82.87686000",
  },
  {
    id: 117748,
    name: "Grosse Pointe Woods",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.44365000",
    longitude: "-82.90686000",
  },
  {
    id: 117820,
    name: "Gwinn",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "46.28106000",
    longitude: "-87.44097000",
  },
  {
    id: 117940,
    name: "Hamtramck",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.39282000",
    longitude: "-83.04964000",
  },
  {
    id: 117949,
    name: "Hancock",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "47.12687000",
    longitude: "-88.58096000",
  },
  {
    id: 117988,
    name: "Harbor Beach",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.84474000",
    longitude: "-82.65132000",
  },
  {
    id: 117991,
    name: "Harbor Springs",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "45.43168000",
    longitude: "-84.99200000",
  },
  {
    id: 118035,
    name: "Harper Woods",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.43309000",
    longitude: "-82.92408000",
  },
  {
    id: 118057,
    name: "Harrison",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.01919000",
    longitude: "-84.79947000",
  },
  {
    id: 118074,
    name: "Harrisville",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.65640000",
    longitude: "-83.29469000",
  },
  {
    id: 118080,
    name: "Hart",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.69834000",
    longitude: "-86.36397000",
  },
  {
    id: 118088,
    name: "Hartford",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.20671000",
    longitude: "-86.16669000",
  },
  {
    id: 118113,
    name: "Harvey",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "46.49466000",
    longitude: "-87.35431000",
  },
  {
    id: 118129,
    name: "Haslett",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.74698000",
    longitude: "-84.40108000",
  },
  {
    id: 118131,
    name: "Hastings",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.64587000",
    longitude: "-85.29084000",
  },
  {
    id: 118206,
    name: "Hazel Park",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.46254000",
    longitude: "-83.10409000",
  },
  {
    id: 118265,
    name: "Hemlock",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.41475000",
    longitude: "-84.23054000",
  },
  {
    id: 118412,
    name: "Highland Park",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.40559000",
    longitude: "-83.09687000",
  },
  {
    id: 118460,
    name: "Hillsdale",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.92005000",
    longitude: "-84.63051000",
  },
  {
    id: 118462,
    name: "Hillsdale County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.88777000",
    longitude: "-84.59293000",
  },
  {
    id: 118539,
    name: "Holland",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.78752000",
    longitude: "-86.10893000",
  },
  {
    id: 118555,
    name: "Holly",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.79197000",
    longitude: "-83.62773000",
  },
  {
    id: 118575,
    name: "Holt",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.64059000",
    longitude: "-84.51525000",
  },
  {
    id: 118595,
    name: "Homer",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.14588000",
    longitude: "-84.80886000",
  },
  {
    id: 118689,
    name: "Houghton",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "47.12187000",
    longitude: "-88.56901000",
  },
  {
    id: 118691,
    name: "Houghton County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "46.99155000",
    longitude: "-88.65206000",
  },
  {
    id: 118692,
    name: "Houghton Lake",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.31474000",
    longitude: "-84.76475000",
  },
  {
    id: 118711,
    name: "Howard City",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.39558000",
    longitude: "-85.46782000",
  },
  {
    id: 118722,
    name: "Howell",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.60726000",
    longitude: "-83.92940000",
  },
  {
    id: 118733,
    name: "Hubbard Lake",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.75973000",
    longitude: "-83.54442000",
  },
  {
    id: 118741,
    name: "Hudson",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.85505000",
    longitude: "-84.35384000",
  },
  {
    id: 118753,
    name: "Hudsonville",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.87086000",
    longitude: "-85.86504000",
  },
  {
    id: 118809,
    name: "Huntington Woods",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.48059000",
    longitude: "-83.16687000",
  },
  {
    id: 118826,
    name: "Huron County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.91007000",
    longitude: "-82.85551000",
  },
  {
    id: 118879,
    name: "Imlay City",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.02475000",
    longitude: "-83.07772000",
  },
  {
    id: 118912,
    name: "Indian River",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "45.41251000",
    longitude: "-84.61254000",
  },
  {
    id: 118935,
    name: "Ingham County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.59710000",
    longitude: "-84.37354000",
  },
  {
    id: 118943,
    name: "Inkster",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.29420000",
    longitude: "-83.30993000",
  },
  {
    id: 118971,
    name: "Ionia",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.98725000",
    longitude: "-85.07112000",
  },
  {
    id: 118972,
    name: "Ionia County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.94509000",
    longitude: "-85.07460000",
  },
  {
    id: 118973,
    name: "Iosco County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.30125000",
    longitude: "-83.51395000",
  },
  {
    id: 118989,
    name: "Iron County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "46.20869000",
    longitude: "-88.53053000",
  },
  {
    id: 118992,
    name: "Iron Mountain",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "45.82023000",
    longitude: "-88.06596000",
  },
  {
    id: 118993,
    name: "Iron River",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "46.09273000",
    longitude: "-88.64235000",
  },
  {
    id: 118999,
    name: "Ironwood",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "46.45467000",
    longitude: "-90.17101000",
  },
  {
    id: 119016,
    name: "Isabella County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.64060000",
    longitude: "-84.84680000",
  },
  {
    id: 119020,
    name: "Ishpeming",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "46.48855000",
    longitude: "-87.66764000",
  },
  {
    id: 119043,
    name: "Ithaca",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.29170000",
    longitude: "-84.60750000",
  },
  {
    id: 119071,
    name: "Jackson",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.24587000",
    longitude: "-84.40135000",
  },
  {
    id: 119091,
    name: "Jackson County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.24849000",
    longitude: "-84.42344000",
  },
  {
    id: 119213,
    name: "Jenison",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.90725000",
    longitude: "-85.79198000",
  },
  {
    id: 119312,
    name: "Jonesville",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.98421000",
    longitude: "-84.66190000",
  },
  {
    id: 119347,
    name: "K. I. Sawyer Air Force Base",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "46.34651000",
    longitude: "-87.38632000",
  },
  {
    id: 119360,
    name: "Kalamazoo",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.29171000",
    longitude: "-85.58723000",
  },
  {
    id: 119361,
    name: "Kalamazoo County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.24545000",
    longitude: "-85.53118000",
  },
  {
    id: 119366,
    name: "Kalkaska",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.73417000",
    longitude: "-85.17589000",
  },
  {
    id: 119367,
    name: "Kalkaska County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.68466000",
    longitude: "-85.09023000",
  },
  {
    id: 119418,
    name: "Keego Harbor",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.60809000",
    longitude: "-83.34382000",
  },
  {
    id: 119489,
    name: "Kent City",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.22002000",
    longitude: "-85.75115000",
  },
  {
    id: 119492,
    name: "Kent County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.03216000",
    longitude: "-85.54930000",
  },
  {
    id: 119501,
    name: "Kentwood",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.86947000",
    longitude: "-85.64475000",
  },
  {
    id: 119536,
    name: "Keweenaw County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "47.28296000",
    longitude: "-88.21198000",
  },
  {
    id: 119562,
    name: "Kilmanagh",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.75613000",
    longitude: "-83.35690000",
  },
  {
    id: 119612,
    name: "Kingsford",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "45.79496000",
    longitude: "-88.07207000",
  },
  {
    id: 119618,
    name: "Kingsley",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.58473000",
    longitude: "-85.53590000",
  },
  {
    id: 119734,
    name: "L'Anse",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "46.75660000",
    longitude: "-88.45291000",
  },
  {
    id: 119861,
    name: "Laingsburg",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.89031000",
    longitude: "-84.35136000",
  },
  {
    id: 119884,
    name: "Lake City",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.33529000",
    longitude: "-85.21505000",
  },
  {
    id: 119893,
    name: "Lake County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.99001000",
    longitude: "-85.80170000",
  },
  {
    id: 119908,
    name: "Lake Fenton",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.84614000",
    longitude: "-83.70773000",
  },
  {
    id: 119922,
    name: "Lake Isabella",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.64364000",
    longitude: "-84.99725000",
  },
  {
    id: 119941,
    name: "Lake Michigan Beach",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.22087000",
    longitude: "-86.36947000",
  },
  {
    id: 119953,
    name: "Lake Odessa",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.78476000",
    longitude: "-85.13834000",
  },
  {
    id: 119954,
    name: "Lake Orion",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.78448000",
    longitude: "-83.23966000",
  },
  {
    id: 120038,
    name: "Lakeview",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.44642000",
    longitude: "-85.27420000",
  },
  {
    id: 120053,
    name: "Lakewood Club",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.37112000",
    longitude: "-86.26034000",
  },
  {
    id: 120068,
    name: "Lambertville",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.76588000",
    longitude: "-83.62799000",
  },
  {
    id: 120127,
    name: "Lansing",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.73253000",
    longitude: "-84.55553000",
  },
  {
    id: 120131,
    name: "Lapeer",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.05142000",
    longitude: "-83.31883000",
  },
  {
    id: 120132,
    name: "Lapeer County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.09015000",
    longitude: "-83.22178000",
  },
  {
    id: 120171,
    name: "Lathrup Village",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.49642000",
    longitude: "-83.22271000",
  },
  {
    id: 120211,
    name: "Laurium",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "47.23743000",
    longitude: "-88.44317000",
  },
  {
    id: 120251,
    name: "Lawton",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.16726000",
    longitude: "-85.84695000",
  },
  {
    id: 120322,
    name: "Leelanau County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "45.15177000",
    longitude: "-86.03850000",
  },
  {
    id: 120351,
    name: "Leland",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "45.02305000",
    longitude: "-85.75981000",
  },
  {
    id: 120368,
    name: "Lenawee County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.89508000",
    longitude: "-84.06636000",
  },
  {
    id: 120396,
    name: "Leslie",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.45143000",
    longitude: "-84.43247000",
  },
  {
    id: 120403,
    name: "Level Park-Oak Park",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.36418000",
    longitude: "-85.26650000",
  },
  {
    id: 120424,
    name: "Lewiston",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.88390000",
    longitude: "-84.30557000",
  },
  {
    id: 120451,
    name: "Lexington",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.26808000",
    longitude: "-82.53076000",
  },
  {
    id: 120541,
    name: "Lincoln Park",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.25059000",
    longitude: "-83.17854000",
  },
  {
    id: 120563,
    name: "Linden",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.81447000",
    longitude: "-83.78245000",
  },
  {
    id: 120606,
    name: "Litchfield",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.04393000",
    longitude: "-84.75746000",
  },
  {
    id: 120657,
    name: "Livingston County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.60292000",
    longitude: "-83.91153000",
  },
  {
    id: 120662,
    name: "Livonia",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.36837000",
    longitude: "-83.35271000",
  },
  {
    id: 120837,
    name: "Lowell",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.93364000",
    longitude: "-85.34196000",
  },
  {
    id: 120863,
    name: "Luce County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "46.36778000",
    longitude: "-85.50934000",
  },
  {
    id: 120868,
    name: "Ludington",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.95528000",
    longitude: "-86.45258000",
  },
  {
    id: 120885,
    name: "Luna Pier",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.80699000",
    longitude: "-83.44243000",
  },
  {
    id: 120959,
    name: "Mackinac County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "45.87184000",
    longitude: "-84.76227000",
  },
  {
    id: 120962,
    name: "Macomb County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.67279000",
    longitude: "-82.91016000",
  },
  {
    id: 121021,
    name: "Madison Heights",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.48587000",
    longitude: "-83.10520000",
  },
  {
    id: 121087,
    name: "Mancelona",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.90223000",
    longitude: "-85.06088000",
  },
  {
    id: 121096,
    name: "Manchester",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.15032000",
    longitude: "-84.03772000",
  },
  {
    id: 121121,
    name: "Manistee",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.24445000",
    longitude: "-86.32425000",
  },
  {
    id: 121122,
    name: "Manistee County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.23831000",
    longitude: "-86.28799000",
  },
  {
    id: 121123,
    name: "Manistique",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "45.95775000",
    longitude: "-86.24625000",
  },
  {
    id: 121125,
    name: "Manitou Beach-Devils Lake",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.97565000",
    longitude: "-84.28616000",
  },
  {
    id: 121158,
    name: "Manton",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.41084000",
    longitude: "-85.39894000",
  },
  {
    id: 121194,
    name: "Marcellus",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.02588000",
    longitude: "-85.81556000",
  },
  {
    id: 121225,
    name: "Marine City",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.71948000",
    longitude: "-82.49213000",
  },
  {
    id: 121282,
    name: "Marlette",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.32697000",
    longitude: "-83.08022000",
  },
  {
    id: 121292,
    name: "Marquette",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "46.54354000",
    longitude: "-87.39542000",
  },
  {
    id: 121293,
    name: "Marquette County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "46.66295000",
    longitude: "-87.57350000",
  },
  {
    id: 121307,
    name: "Marshall",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.27226000",
    longitude: "-84.96331000",
  },
  {
    id: 121358,
    name: "Marysville",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.91253000",
    longitude: "-82.48686000",
  },
  {
    id: 121373,
    name: "Mason",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.57920000",
    longitude: "-84.44358000",
  },
  {
    id: 121381,
    name: "Mason County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.95625000",
    longitude: "-86.42258000",
  },
  {
    id: 121410,
    name: "Mattawan",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.20948000",
    longitude: "-85.78445000",
  },
  {
    id: 121589,
    name: "Mecosta County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.64080000",
    longitude: "-85.32462000",
  },
  {
    id: 121637,
    name: "Melvindale",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.28254000",
    longitude: "-83.17520000",
  },
  {
    id: 121640,
    name: "Memphis",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.89642000",
    longitude: "-82.76881000",
  },
  {
    id: 121662,
    name: "Menominee",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "45.10776000",
    longitude: "-87.61427000",
  },
  {
    id: 121663,
    name: "Menominee County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "45.52514000",
    longitude: "-87.50969000",
  },
  {
    id: 121759,
    name: "Michigan Center",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.23309000",
    longitude: "-84.32718000",
  },
  {
    id: 121795,
    name: "Middleville",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.71309000",
    longitude: "-85.46196000",
  },
  {
    id: 121798,
    name: "Midland",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.61558000",
    longitude: "-84.24721000",
  },
  {
    id: 121804,
    name: "Midland County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.64686000",
    longitude: "-84.38811000",
  },
  {
    id: 121838,
    name: "Milan",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.08532000",
    longitude: "-83.68244000",
  },
  {
    id: 121852,
    name: "Milford",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.59364000",
    longitude: "-83.59939000",
  },
  {
    id: 121892,
    name: "Millington",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.28141000",
    longitude: "-83.52968000",
  },
  {
    id: 121970,
    name: "Mio",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.65224000",
    longitude: "-84.12973000",
  },
  {
    id: 121978,
    name: "Missaukee County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.33730000",
    longitude: "-85.09467000",
  },
  {
    id: 122061,
    name: "Monroe",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.91643000",
    longitude: "-83.39771000",
  },
  {
    id: 122079,
    name: "Monroe County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.92140000",
    longitude: "-83.49426000",
  },
  {
    id: 122097,
    name: "Montague",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.41668000",
    longitude: "-86.35701000",
  },
  {
    id: 122104,
    name: "Montcalm County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.31096000",
    longitude: "-85.15252000",
  },
  {
    id: 122172,
    name: "Montmorency County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "45.02755000",
    longitude: "-84.12721000",
  },
  {
    id: 122181,
    name: "Montrose",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.17669000",
    longitude: "-83.89274000",
  },
  {
    id: 122229,
    name: "Morenci",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.71949000",
    longitude: "-84.21800000",
  },
  {
    id: 122339,
    name: "Mount Clemens",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.59726000",
    longitude: "-82.87798000",
  },
  {
    id: 122364,
    name: "Mount Morris",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.11864000",
    longitude: "-83.69496000",
  },
  {
    id: 122379,
    name: "Mount Pleasant",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.59781000",
    longitude: "-84.76751000",
  },
  {
    id: 122474,
    name: "Munising",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "46.41120000",
    longitude: "-86.64926000",
  },
  {
    id: 122509,
    name: "Muskegon",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.23418000",
    longitude: "-86.24839000",
  },
  {
    id: 122510,
    name: "Muskegon County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.21919000",
    longitude: "-86.21246000",
  },
  {
    id: 122511,
    name: "Muskegon Heights",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.20113000",
    longitude: "-86.23895000",
  },
  {
    id: 122558,
    name: "Napoleon",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.16059000",
    longitude: "-84.24606000",
  },
  {
    id: 122578,
    name: "Nashville",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.60281000",
    longitude: "-85.09305000",
  },
  {
    id: 122617,
    name: "Negaunee",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "46.49910000",
    longitude: "-87.61180000",
  },
  {
    id: 122660,
    name: "New Baltimore",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.68114000",
    longitude: "-82.73686000",
  },
  {
    id: 122678,
    name: "New Buffalo",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.79393000",
    longitude: "-86.74392000",
  },
  {
    id: 122718,
    name: "New Haven",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.72948000",
    longitude: "-82.80131000",
  },
  {
    id: 122806,
    name: "Newaygo",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.41974000",
    longitude: "-85.80005000",
  },
  {
    id: 122807,
    name: "Newaygo County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.55417000",
    longitude: "-85.80091000",
  },
  {
    id: 122812,
    name: "Newberry",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "46.35500000",
    longitude: "-85.50956000",
  },
  {
    id: 122901,
    name: "Niles",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.82977000",
    longitude: "-86.25418000",
  },
  {
    id: 122993,
    name: "North Branch",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.22947000",
    longitude: "-83.19661000",
  },
  {
    id: 123069,
    name: "North Muskegon",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.25613000",
    longitude: "-86.26756000",
  },
  {
    id: 123170,
    name: "Northview",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.04558000",
    longitude: "-85.60059000",
  },
  {
    id: 123171,
    name: "Northville",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.43115000",
    longitude: "-83.48327000",
  },
  {
    id: 123187,
    name: "Norton Shores",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.16890000",
    longitude: "-86.26395000",
  },
  {
    id: 123192,
    name: "Norway",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "45.78690000",
    longitude: "-87.90374000",
  },
  {
    id: 123208,
    name: "Novi",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.48059000",
    longitude: "-83.47549000",
  },
  {
    id: 123256,
    name: "Oak Park",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.45948000",
    longitude: "-83.18271000",
  },
  {
    id: 123295,
    name: "Oakland County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.66041000",
    longitude: "-83.38580000",
  },
  {
    id: 123340,
    name: "Oceana County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.68178000",
    longitude: "-86.31683000",
  },
  {
    id: 123373,
    name: "Ogemaw County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.33494000",
    longitude: "-84.12641000",
  },
  {
    id: 123402,
    name: "Okemos",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.72226000",
    longitude: "-84.42747000",
  },
  {
    id: 123447,
    name: "Olivet",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.44143000",
    longitude: "-84.92415000",
  },
  {
    id: 123489,
    name: "Ontonagon",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "46.87105000",
    longitude: "-89.31403000",
  },
  {
    id: 123490,
    name: "Ontonagon County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "46.77749000",
    longitude: "-89.30511000",
  },
  {
    id: 123536,
    name: "Orchard Lake",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.58309000",
    longitude: "-83.35938000",
  },
  {
    id: 123583,
    name: "Ortonville",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.85225000",
    longitude: "-83.44300000",
  },
  {
    id: 123605,
    name: "Osceola County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.98987000",
    longitude: "-85.32528000",
  },
  {
    id: 123607,
    name: "Oscoda County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.68175000",
    longitude: "-84.12974000",
  },
  {
    id: 123632,
    name: "Otsego",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.46059000",
    longitude: "-85.69641000",
  },
  {
    id: 123634,
    name: "Otsego County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "45.02144000",
    longitude: "-84.59898000",
  },
  {
    id: 123640,
    name: "Ottawa County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.00264000",
    longitude: "-86.17950000",
  },
  {
    id: 123655,
    name: "Ovid",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.00586000",
    longitude: "-84.37164000",
  },
  {
    id: 123672,
    name: "Owosso",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.99780000",
    longitude: "-84.17664000",
  },
  {
    id: 123685,
    name: "Oxford",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.82475000",
    longitude: "-83.26466000",
  },
  {
    id: 123827,
    name: "Parchment",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.32810000",
    longitude: "-85.56973000",
  },
  {
    id: 123927,
    name: "Paw Paw",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.21782000",
    longitude: "-85.89112000",
  },
  {
    id: 123928,
    name: "Paw Paw Lake",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.21226000",
    longitude: "-86.27197000",
  },
  {
    id: 123964,
    name: "Pearl Beach",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.62670000",
    longitude: "-82.59769000",
  },
  {
    id: 124071,
    name: "Perry",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.82642000",
    longitude: "-84.21941000",
  },
  {
    id: 124107,
    name: "Petersburg",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.90116000",
    longitude: "-83.71494000",
  },
  {
    id: 124111,
    name: "Petoskey",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "45.37334000",
    longitude: "-84.95533000",
  },
  {
    id: 124182,
    name: "Pigeon",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.83002000",
    longitude: "-83.26996000",
  },
  {
    id: 124209,
    name: "Pinckney",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.45700000",
    longitude: "-83.94791000",
  },
  {
    id: 124211,
    name: "Pinconning",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.85363000",
    longitude: "-83.96499000",
  },
  {
    id: 124336,
    name: "Plainwell",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.44004000",
    longitude: "-85.64890000",
  },
  {
    id: 124376,
    name: "Pleasant Ridge",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.47115000",
    longitude: "-83.14215000",
  },
  {
    id: 124403,
    name: "Plymouth",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.37143000",
    longitude: "-83.47021000",
  },
  {
    id: 124474,
    name: "Pontiac",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.63892000",
    longitude: "-83.29105000",
  },
  {
    id: 124511,
    name: "Port Huron",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.97086000",
    longitude: "-82.42491000",
  },
  {
    id: 124544,
    name: "Portage",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.20115000",
    longitude: "-85.58000000",
  },
  {
    id: 124561,
    name: "Portland",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.86920000",
    longitude: "-84.90305000",
  },
  {
    id: 124596,
    name: "Potterville",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.62920000",
    longitude: "-84.73887000",
  },
  {
    id: 124650,
    name: "Presque Isle County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "45.39845000",
    longitude: "-83.84354000",
  },
  {
    id: 124723,
    name: "Prudenville",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.29835000",
    longitude: "-84.65197000",
  },
  {
    id: 124798,
    name: "Quincy",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.94421000",
    longitude: "-84.88385000",
  },
  {
    id: 124803,
    name: "Quinnesec",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "45.80635000",
    longitude: "-87.98846000",
  },
  {
    id: 124893,
    name: "Rapid City",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.83445000",
    longitude: "-85.28256000",
  },
  {
    id: 124907,
    name: "Ravenna",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.18947000",
    longitude: "-85.93699000",
  },
  {
    id: 124930,
    name: "Reading",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.83949000",
    longitude: "-84.74801000",
  },
  {
    id: 124969,
    name: "Redford",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.38337000",
    longitude: "-83.29660000",
  },
  {
    id: 124991,
    name: "Reed City",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.87502000",
    longitude: "-85.51005000",
  },
  {
    id: 124997,
    name: "Reese",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.45058000",
    longitude: "-83.69635000",
  },
  {
    id: 125092,
    name: "Richmond",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.80920000",
    longitude: "-82.75576000",
  },
  {
    id: 125187,
    name: "River Rouge",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.27337000",
    longitude: "-83.13437000",
  },
  {
    id: 125219,
    name: "Riverview",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.17421000",
    longitude: "-83.17937000",
  },
  {
    id: 125264,
    name: "Rochester",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.68059000",
    longitude: "-83.13382000",
  },
  {
    id: 125271,
    name: "Rochester Hills",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.65837000",
    longitude: "-83.14993000",
  },
  {
    id: 125301,
    name: "Rockford",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.12003000",
    longitude: "-85.56003000",
  },
  {
    id: 125333,
    name: "Rockwood",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.07088000",
    longitude: "-83.24660000",
  },
  {
    id: 125351,
    name: "Rogers City",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "45.42140000",
    longitude: "-83.81833000",
  },
  {
    id: 125381,
    name: "Romeo",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.80281000",
    longitude: "-83.01299000",
  },
  {
    id: 125385,
    name: "Romulus",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.22226000",
    longitude: "-83.39660000",
  },
  {
    id: 125396,
    name: "Roosevelt Park",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.19640000",
    longitude: "-86.27228000",
  },
  {
    id: 125401,
    name: "Roscommon",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.49835000",
    longitude: "-84.59197000",
  },
  {
    id: 125402,
    name: "Roscommon County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.33561000",
    longitude: "-84.61160000",
  },
  {
    id: 125439,
    name: "Roseville",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.49726000",
    longitude: "-82.93714000",
  },
  {
    id: 125490,
    name: "Royal Oak",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.48948000",
    longitude: "-83.14465000",
  },
  {
    id: 125588,
    name: "Saginaw",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.41947000",
    longitude: "-83.95081000",
  },
  {
    id: 125589,
    name: "Saginaw County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.33503000",
    longitude: "-84.05319000",
  },
  {
    id: 125590,
    name: "Saginaw Township North",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.46004000",
    longitude: "-84.00674000",
  },
  {
    id: 125614,
    name: "Saint Charles",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.29697000",
    longitude: "-84.14053000",
  },
  {
    id: 125619,
    name: "Saint Clair",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.82087000",
    longitude: "-82.48602000",
  },
  {
    id: 125624,
    name: "Saint Clair County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.93112000",
    longitude: "-82.66437000",
  },
  {
    id: 125625,
    name: "Saint Clair Shores",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.49698000",
    longitude: "-82.88881000",
  },
  {
    id: 125646,
    name: "Saint Helen",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.36363000",
    longitude: "-84.41029000",
  },
  {
    id: 125650,
    name: "Saint Ignace",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "45.86614000",
    longitude: "-84.72751000",
  },
  {
    id: 125665,
    name: "Saint Johns",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.00114000",
    longitude: "-84.55915000",
  },
  {
    id: 125673,
    name: "Saint Joseph",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.10976000",
    longitude: "-86.48002000",
  },
  {
    id: 125676,
    name: "Saint Joseph County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.91441000",
    longitude: "-85.52774000",
  },
  {
    id: 125680,
    name: "Saint Louis",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.40836000",
    longitude: "-84.60667000",
  },
  {
    id: 125748,
    name: "Saline",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.16671000",
    longitude: "-83.78161000",
  },
  {
    id: 125859,
    name: "Sand Lake",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.31918000",
    longitude: "-83.68470000",
  },
  {
    id: 125875,
    name: "Sandusky",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.42030000",
    longitude: "-82.82966000",
  },
  {
    id: 125895,
    name: "Sanilac County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.44331000",
    longitude: "-82.64575000",
  },
  {
    id: 125935,
    name: "Saranac",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.92948000",
    longitude: "-85.21307000",
  },
  {
    id: 125968,
    name: "Sault Ste. Marie",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "46.49530000",
    longitude: "-84.34532000",
  },
  {
    id: 126017,
    name: "Schoolcraft",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.11421000",
    longitude: "-85.63778000",
  },
  {
    id: 126018,
    name: "Schoolcraft County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "46.04249000",
    longitude: "-86.17730000",
  },
  {
    id: 126066,
    name: "Scottville",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.95473000",
    longitude: "-86.28008000",
  },
  {
    id: 126109,
    name: "Sebewaing",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.73224000",
    longitude: "-83.45107000",
  },
  {
    id: 126270,
    name: "Shelby",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.60862000",
    longitude: "-86.36396000",
  },
  {
    id: 126310,
    name: "Shepherd",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.52447000",
    longitude: "-84.69473000",
  },
  {
    id: 126345,
    name: "Shiawassee County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.95373000",
    longitude: "-84.14673000",
  },
  {
    id: 126346,
    name: "Shields",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.41530000",
    longitude: "-84.05637000",
  },
  {
    id: 126375,
    name: "Shorewood-Tower Hills-Harbert",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.88169000",
    longitude: "-86.61409000",
  },
  {
    id: 126480,
    name: "Skidway Lake",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.18335000",
    longitude: "-84.03527000",
  },
  {
    id: 126664,
    name: "South Gull Lake",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.38754000",
    longitude: "-85.39667000",
  },
  {
    id: 126667,
    name: "South Haven",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.40309000",
    longitude: "-86.27364000",
  },
  {
    id: 126690,
    name: "South Lyon",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.46059000",
    longitude: "-83.65161000",
  },
  {
    id: 126694,
    name: "South Monroe",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.89588000",
    longitude: "-83.41771000",
  },
  {
    id: 126718,
    name: "South Rockwood",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.06393000",
    longitude: "-83.26104000",
  },
  {
    id: 126772,
    name: "Southfield",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.47337000",
    longitude: "-83.22187000",
  },
  {
    id: 126775,
    name: "Southgate",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.21393000",
    longitude: "-83.19381000",
  },
  {
    id: 126811,
    name: "Sparta",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.16086000",
    longitude: "-85.71004000",
  },
  {
    id: 126850,
    name: "Spring Arbor",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.20504000",
    longitude: "-84.55274000",
  },
  {
    id: 126866,
    name: "Spring Lake",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.07696000",
    longitude: "-86.19700000",
  },
  {
    id: 126898,
    name: "Springfield",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.32643000",
    longitude: "-85.23916000",
  },
  {
    id: 126942,
    name: "Stambaugh, Iron River",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "46.08107000",
    longitude: "-88.62708000",
  },
  {
    id: 126950,
    name: "Standish",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.98308000",
    longitude: "-83.95888000",
  },
  {
    id: 126967,
    name: "Stanton",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.29253000",
    longitude: "-85.08141000",
  },
  {
    id: 127034,
    name: "Sterling Heights",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.58031000",
    longitude: "-83.03020000",
  },
  {
    id: 127048,
    name: "Stevensville",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.01449000",
    longitude: "-86.51947000",
  },
  {
    id: 127069,
    name: "Stockbridge",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.45115000",
    longitude: "-84.18051000",
  },
  {
    id: 127099,
    name: "Stony Point",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.94143000",
    longitude: "-83.26493000",
  },
  {
    id: 127151,
    name: "Sturgis",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.79922000",
    longitude: "-85.41915000",
  },
  {
    id: 127332,
    name: "Swartz Creek",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.95725000",
    longitude: "-83.83051000",
  },
  {
    id: 127357,
    name: "Sylvan Lake",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.61142000",
    longitude: "-83.32855000",
  },
  {
    id: 127444,
    name: "Tawas City",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.26946000",
    longitude: "-83.51470000",
  },
  {
    id: 127447,
    name: "Taylor",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.24087000",
    longitude: "-83.26965000",
  },
  {
    id: 127479,
    name: "Tecumseh",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.00393000",
    longitude: "-83.94494000",
  },
  {
    id: 127496,
    name: "Temperance",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.77921000",
    longitude: "-83.56882000",
  },
  {
    id: 127615,
    name: "Three Oaks",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.79865000",
    longitude: "-86.61058000",
  },
  {
    id: 127618,
    name: "Three Rivers",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.94394000",
    longitude: "-85.63249000",
  },
  {
    id: 127784,
    name: "Traverse City",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.76306000",
    longitude: "-85.62063000",
  },
  {
    id: 127806,
    name: "Trenton",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.13949000",
    longitude: "-83.17826000",
  },
  {
    id: 127840,
    name: "Trowbridge Park",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "46.55660000",
    longitude: "-87.43736000",
  },
  {
    id: 127848,
    name: "Troy",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.60559000",
    longitude: "-83.14993000",
  },
  {
    id: 127912,
    name: "Tuscola County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.49134000",
    longitude: "-83.43987000",
  },
  {
    id: 127927,
    name: "Twin Lake",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.36279000",
    longitude: "-86.16478000",
  },
  {
    id: 127984,
    name: "Union City",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.06671000",
    longitude: "-85.13609000",
  },
  {
    id: 128066,
    name: "Utica",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.62614000",
    longitude: "-83.03354000",
  },
  {
    id: 128132,
    name: "Van Buren County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.28511000",
    longitude: "-86.30642000",
  },
  {
    id: 128151,
    name: "Vandercook Lake",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.19337000",
    longitude: "-84.39107000",
  },
  {
    id: 128159,
    name: "Vassar",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.37197000",
    longitude: "-83.58329000",
  },
  {
    id: 128216,
    name: "Vicksburg",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.12005000",
    longitude: "-85.53278000",
  },
  {
    id: 128318,
    name: "Wacousta",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.82781000",
    longitude: "-84.70082000",
  },
  {
    id: 128360,
    name: "Wakefield",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "46.47523000",
    longitude: "-89.93989000",
  },
  {
    id: 128384,
    name: "Walker",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.00141000",
    longitude: "-85.76809000",
  },
  {
    id: 128401,
    name: "Walled Lake",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.53781000",
    longitude: "-83.48105000",
  },
  {
    id: 128481,
    name: "Warren",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.49044000",
    longitude: "-83.01304000",
  },
  {
    id: 128592,
    name: "Washtenaw County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.25323000",
    longitude: "-83.83877000",
  },
  {
    id: 128605,
    name: "Waterford",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.69303000",
    longitude: "-83.41181000",
  },
  {
    id: 128631,
    name: "Watervliet",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.18671000",
    longitude: "-86.26058000",
  },
  {
    id: 128669,
    name: "Waverly",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.73920000",
    longitude: "-84.62081000",
  },
  {
    id: 128679,
    name: "Wayland",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.67392000",
    longitude: "-85.64474000",
  },
  {
    id: 128683,
    name: "Wayne",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.28143000",
    longitude: "-83.38632000",
  },
  {
    id: 128699,
    name: "Wayne County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.28478000",
    longitude: "-83.26113000",
  },
  {
    id: 128727,
    name: "Webberville",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.66698000",
    longitude: "-84.17413000",
  },
  {
    id: 128828,
    name: "West Bloomfield Township",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.56891000",
    longitude: "-83.38356000",
  },
  {
    id: 128833,
    name: "West Branch",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.27641000",
    longitude: "-84.23861000",
  },
  {
    id: 128902,
    name: "West Ishpeming",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "46.48355000",
    longitude: "-87.70097000",
  },
  {
    id: 128936,
    name: "West Monroe",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.91393000",
    longitude: "-83.43160000",
  },
  {
    id: 129041,
    name: "Westland",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.32420000",
    longitude: "-83.40021000",
  },
  {
    id: 129084,
    name: "Westwood",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.30282000",
    longitude: "-85.63362000",
  },
  {
    id: 129097,
    name: "Wexford County",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "44.33835000",
    longitude: "-85.57842000",
  },
  {
    id: 129133,
    name: "White Cloud",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.55030000",
    longitude: "-85.77200000",
  },
  {
    id: 129151,
    name: "White Pigeon",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.79811000",
    longitude: "-85.64332000",
  },
  {
    id: 129168,
    name: "Whitehall",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.41001000",
    longitude: "-86.34868000",
  },
  {
    id: 129206,
    name: "Whitmore Lake",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.43970000",
    longitude: "-83.74530000",
  },
  {
    id: 129284,
    name: "Williamston",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.68892000",
    longitude: "-84.28302000",
  },
  {
    id: 129465,
    name: "Wixom",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.52476000",
    longitude: "-83.53633000",
  },
  {
    id: 129472,
    name: "Wolf Lake",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.25474000",
    longitude: "-86.10978000",
  },
  {
    id: 129481,
    name: "Wolverine Lake",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.55670000",
    longitude: "-83.47383000",
  },
  {
    id: 129521,
    name: "Woodhaven",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.13893000",
    longitude: "-83.24160000",
  },
  {
    id: 129528,
    name: "Woodland Beach",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "41.94005000",
    longitude: "-83.31326000",
  },
  {
    id: 129621,
    name: "Wyandotte",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.21421000",
    longitude: "-83.14992000",
  },
  {
    id: 129636,
    name: "Wyoming",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.91336000",
    longitude: "-85.70531000",
  },
  {
    id: 129655,
    name: "Yale",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.13003000",
    longitude: "-82.79826000",
  },
  {
    id: 129724,
    name: "Ypsilanti",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.24115000",
    longitude: "-83.61299000",
  },
  {
    id: 129744,
    name: "Zeeland",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "42.81252000",
    longitude: "-86.01865000",
  },
  {
    id: 129755,
    name: "Zilwaukee",
    state_id: 1426,
    state_code: "MI",
    country_id: 233,
    country_code: "US",
    latitude: "43.47641000",
    longitude: "-83.92053000",
  },
  {
    id: 111006,
    name: "Ada",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.29969000",
    longitude: "-96.51535000",
  },
  {
    id: 111047,
    name: "Adrian",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.63497000",
    longitude: "-95.93280000",
  },
  {
    id: 111053,
    name: "Afton",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.90275000",
    longitude: "-92.78354000",
  },
  {
    id: 111073,
    name: "Aitkin",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.53301000",
    longitude: "-93.71025000",
  },
  {
    id: 111074,
    name: "Aitkin County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.60826000",
    longitude: "-93.41543000",
  },
  {
    id: 111106,
    name: "Albany",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.62996000",
    longitude: "-94.57000000",
  },
  {
    id: 111118,
    name: "Albert Lea",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.64801000",
    longitude: "-93.36827000",
  },
  {
    id: 111121,
    name: "Albertville",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.23774000",
    longitude: "-93.65441000",
  },
  {
    id: 111154,
    name: "Alexandria",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.88524000",
    longitude: "-95.37754000",
  },
  {
    id: 111324,
    name: "Andover",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.23330000",
    longitude: "-93.29134000",
  },
  {
    id: 111350,
    name: "Annandale",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.26274000",
    longitude: "-94.12443000",
  },
  {
    id: 111358,
    name: "Anoka",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.19774000",
    longitude: "-93.38718000",
  },
  {
    id: 111359,
    name: "Anoka County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.27324000",
    longitude: "-93.24645000",
  },
  {
    id: 111393,
    name: "Apple Valley",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.73191000",
    longitude: "-93.21772000",
  },
  {
    id: 111396,
    name: "Appleton",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.19691000",
    longitude: "-96.01977000",
  },
  {
    id: 111438,
    name: "Arden Hills",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.05024000",
    longitude: "-93.15661000",
  },
  {
    id: 111466,
    name: "Arlington",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.60830000",
    longitude: "-94.08053000",
  },
  {
    id: 111486,
    name: "Arnold",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.88022000",
    longitude: "-92.09047000",
  },
  {
    id: 111612,
    name: "Atwater",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.13885000",
    longitude: "-94.77806000",
  },
  {
    id: 111660,
    name: "Aurora",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.52993000",
    longitude: "-92.23712000",
  },
  {
    id: 111669,
    name: "Austin",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.66663000",
    longitude: "-92.97464000",
  },
  {
    id: 111692,
    name: "Avon",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.60913000",
    longitude: "-94.45167000",
  },
  {
    id: 111714,
    name: "Babbitt",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.70853000",
    longitude: "-91.94460000",
  },
  {
    id: 111728,
    name: "Bagley",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.52162000",
    longitude: "-95.39835000",
  },
  {
    id: 111830,
    name: "Barnesville",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.65218000",
    longitude: "-96.41979000",
  },
  {
    id: 111911,
    name: "Baudette",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "48.71247000",
    longitude: "-94.59993000",
  },
  {
    id: 111916,
    name: "Baxter",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.34330000",
    longitude: "-94.28667000",
  },
  {
    id: 111951,
    name: "Bayport",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.02136000",
    longitude: "-92.78104000",
  },
  {
    id: 112012,
    name: "Becker",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.39330000",
    longitude: "-93.87692000",
  },
  {
    id: 112013,
    name: "Becker County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.93465000",
    longitude: "-95.67392000",
  },
  {
    id: 112090,
    name: "Belle Plaine",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.62274000",
    longitude: "-93.76857000",
  },
  {
    id: 112150,
    name: "Beltrami County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.97378000",
    longitude: "-94.93765000",
  },
  {
    id: 112160,
    name: "Bemidji",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.47356000",
    longitude: "-94.88028000",
  },
  {
    id: 112183,
    name: "Benson",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.31496000",
    longitude: "-95.60003000",
  },
  {
    id: 112205,
    name: "Benton County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.69913000",
    longitude: "-93.99884000",
  },
  {
    id: 112323,
    name: "Big Lake",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.33246000",
    longitude: "-93.74608000",
  },
  {
    id: 112336,
    name: "Big Stone County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.42610000",
    longitude: "-96.41092000",
  },
  {
    id: 112355,
    name: "Birchwood",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.06108000",
    longitude: "-92.97605000",
  },
  {
    id: 112403,
    name: "Blaine",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.16080000",
    longitude: "-93.23495000",
  },
  {
    id: 112442,
    name: "Blooming Prairie",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.86663000",
    longitude: "-93.05103000",
  },
  {
    id: 112452,
    name: "Bloomington",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.84080000",
    longitude: "-93.29828000",
  },
  {
    id: 112465,
    name: "Blue Earth",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.63746000",
    longitude: "-94.10218000",
  },
  {
    id: 112466,
    name: "Blue Earth County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.03459000",
    longitude: "-94.06703000",
  },
  {
    id: 112679,
    name: "Braham",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.72274000",
    longitude: "-93.17078000",
  },
  {
    id: 112681,
    name: "Brainerd",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.35802000",
    longitude: "-94.20083000",
  },
  {
    id: 112684,
    name: "Branch",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.48524000",
    longitude: "-92.96188000",
  },
  {
    id: 112712,
    name: "Breckenridge",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.26357000",
    longitude: "-96.58813000",
  },
  {
    id: 112718,
    name: "Breezy Point",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.59001000",
    longitude: "-94.21982000",
  },
  {
    id: 112874,
    name: "Brooklyn Center",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.07608000",
    longitude: "-93.33273000",
  },
  {
    id: 112877,
    name: "Brooklyn Park",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.09413000",
    longitude: "-93.35634000",
  },
  {
    id: 112911,
    name: "Brown County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.24217000",
    longitude: "-94.72748000",
  },
  {
    id: 113013,
    name: "Buffalo",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.17191000",
    longitude: "-93.87469000",
  },
  {
    id: 113091,
    name: "Burnsville",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.76774000",
    longitude: "-93.27772000",
  },
  {
    id: 113145,
    name: "Byron",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.03274000",
    longitude: "-92.64546000",
  },
  {
    id: 113190,
    name: "Caledonia",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.63469000",
    longitude: "-91.49681000",
  },
  {
    id: 113253,
    name: "Cambridge",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.57274000",
    longitude: "-93.22439000",
  },
  {
    id: 113321,
    name: "Canby",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.70885000",
    longitude: "-96.27643000",
  },
  {
    id: 113334,
    name: "Cannon Falls",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.50691000",
    longitude: "-92.90548000",
  },
  {
    id: 113411,
    name: "Carlton",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.66383000",
    longitude: "-92.42491000",
  },
  {
    id: 113413,
    name: "Carlton County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.59240000",
    longitude: "-92.67705000",
  },
  {
    id: 113501,
    name: "Carver",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.76357000",
    longitude: "-93.62579000",
  },
  {
    id: 113502,
    name: "Carver County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.82076000",
    longitude: "-93.80258000",
  },
  {
    id: 113532,
    name: "Cass County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.94959000",
    longitude: "-94.32535000",
  },
  {
    id: 113640,
    name: "Center City",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.39385000",
    longitude: "-92.81660000",
  },
  {
    id: 113660,
    name: "Centerville",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.16302000",
    longitude: "-93.05578000",
  },
  {
    id: 113727,
    name: "Champlin",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.18885000",
    longitude: "-93.39745000",
  },
  {
    id: 113732,
    name: "Chanhassen",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.86219000",
    longitude: "-93.53079000",
  },
  {
    id: 113794,
    name: "Chaska",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.78941000",
    longitude: "-93.60218000",
  },
  {
    id: 113795,
    name: "Chatfield",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.84552000",
    longitude: "-92.18905000",
  },
  {
    id: 113974,
    name: "Chippewa County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.02234000",
    longitude: "-95.56669000",
  },
  {
    id: 113977,
    name: "Chisago City",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.37358000",
    longitude: "-92.88994000",
  },
  {
    id: 113978,
    name: "Chisago County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.50247000",
    longitude: "-92.90834000",
  },
  {
    id: 113979,
    name: "Chisholm",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.48910000",
    longitude: "-92.88380000",
  },
  {
    id: 114030,
    name: "Circle Pines",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.14858000",
    longitude: "-93.15161000",
  },
  {
    id: 114100,
    name: "Clara City",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.95496000",
    longitude: "-95.36640000",
  },
  {
    id: 114188,
    name: "Clay County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.89234000",
    longitude: "-96.49065000",
  },
  {
    id: 114225,
    name: "Clearwater",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.41941000",
    longitude: "-94.04887000",
  },
  {
    id: 114226,
    name: "Clearwater County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.57766000",
    longitude: "-95.37903000",
  },
  {
    id: 114304,
    name: "Cloquet",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.72161000",
    longitude: "-92.45936000",
  },
  {
    id: 114373,
    name: "Cohasset",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.26356000",
    longitude: "-93.62022000",
  },
  {
    id: 114376,
    name: "Cokato",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.07580000",
    longitude: "-94.18998000",
  },
  {
    id: 114386,
    name: "Cold Spring",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.45580000",
    longitude: "-94.42888000",
  },
  {
    id: 114401,
    name: "Coleraine",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.28883000",
    longitude: "-93.42771000",
  },
  {
    id: 114419,
    name: "Collegeville",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.59441000",
    longitude: "-94.36305000",
  },
  {
    id: 114442,
    name: "Cologne",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.77163000",
    longitude: "-93.78135000",
  },
  {
    id: 114486,
    name: "Columbia Heights",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.04080000",
    longitude: "-93.26300000",
  },
  {
    id: 114500,
    name: "Columbus",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.26522000",
    longitude: "-93.05015000",
  },
  {
    id: 114593,
    name: "Cook County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.81684000",
    longitude: "-90.54108000",
  },
  {
    id: 114600,
    name: "Coon Rapids",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.11997000",
    longitude: "-93.28773000",
  },
  {
    id: 114632,
    name: "Corcoran",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.09524000",
    longitude: "-93.54746000",
  },
  {
    id: 114691,
    name: "Cottage Grove",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.82774000",
    longitude: "-92.94382000",
  },
  {
    id: 114700,
    name: "Cottonwood",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.60885000",
    longitude: "-95.67419000",
  },
  {
    id: 114703,
    name: "Cottonwood County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.00711000",
    longitude: "-95.18115000",
  },
  {
    id: 114840,
    name: "Crookston",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.77414000",
    longitude: "-96.60812000",
  },
  {
    id: 114843,
    name: "Crosby",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.48218000",
    longitude: "-93.95776000",
  },
  {
    id: 114849,
    name: "Cross Lake",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.65941000",
    longitude: "-94.11387000",
  },
  {
    id: 114861,
    name: "Crow Wing County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.48237000",
    longitude: "-94.07087000",
  },
  {
    id: 114874,
    name: "Crystal",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.03274000",
    longitude: "-93.36023000",
  },
  {
    id: 114976,
    name: "Dakota County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.67189000",
    longitude: "-93.06544000",
  },
  {
    id: 115055,
    name: "Dassel",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.08163000",
    longitude: "-94.30693000",
  },
  {
    id: 115082,
    name: "Dawson",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.93274000",
    longitude: "-96.05448000",
  },
  {
    id: 115096,
    name: "Dayton",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.24385000",
    longitude: "-93.51496000",
  },
  {
    id: 115171,
    name: "Deephaven",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.92969000",
    longitude: "-93.52246000",
  },
  {
    id: 115198,
    name: "Delano",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.04191000",
    longitude: "-93.78913000",
  },
  {
    id: 115216,
    name: "Dellwood",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.08997000",
    longitude: "-92.97244000",
  },
  {
    id: 115293,
    name: "Detroit Lakes",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.81718000",
    longitude: "-95.84533000",
  },
  {
    id: 115347,
    name: "Dilworth",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.87663000",
    longitude: "-96.70341000",
  },
  {
    id: 115375,
    name: "Dodge Center",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.02802000",
    longitude: "-92.85464000",
  },
  {
    id: 115378,
    name: "Dodge County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.02259000",
    longitude: "-92.86205000",
  },
  {
    id: 115429,
    name: "Douglas County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.93372000",
    longitude: "-95.45352000",
  },
  {
    id: 115513,
    name: "Duluth",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.78327000",
    longitude: "-92.10658000",
  },
  {
    id: 115527,
    name: "Dundas",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.42941000",
    longitude: "-93.20188000",
  },
  {
    id: 115593,
    name: "Eagan",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.80413000",
    longitude: "-93.16689000",
  },
  {
    id: 115604,
    name: "Eagle Lake",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.16497000",
    longitude: "-93.88134000",
  },
  {
    id: 115634,
    name: "East Bethel",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.31941000",
    longitude: "-93.20245000",
  },
  {
    id: 115670,
    name: "East Grand Forks",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.92998000",
    longitude: "-97.02452000",
  },
  {
    id: 115676,
    name: "East Gull Lake",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.40802000",
    longitude: "-94.35584000",
  },
  {
    id: 115832,
    name: "Eden Prairie",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.85469000",
    longitude: "-93.47079000",
  },
  {
    id: 115833,
    name: "Eden Valley",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.32607000",
    longitude: "-94.54611000",
  },
  {
    id: 115849,
    name: "Edgerton",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.87247000",
    longitude: "-96.12864000",
  },
  {
    id: 115867,
    name: "Edina",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.88969000",
    longitude: "-93.34995000",
  },
  {
    id: 115946,
    name: "Elbow Lake",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.99413000",
    longitude: "-95.97672000",
  },
  {
    id: 115967,
    name: "Elgin",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.13024000",
    longitude: "-92.25156000",
  },
  {
    id: 115993,
    name: "Elk River",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.30385000",
    longitude: "-93.56718000",
  },
  {
    id: 116010,
    name: "Elko New Market",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.56472000",
    longitude: "-93.32694000",
  },
  {
    id: 116085,
    name: "Ely",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.90324000",
    longitude: "-91.86709000",
  },
  {
    id: 116185,
    name: "Esko",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.70578000",
    longitude: "-92.36325000",
  },
  {
    id: 116257,
    name: "Eveleth",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.46243000",
    longitude: "-92.53991000",
  },
  {
    id: 116269,
    name: "Excelsior",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.90330000",
    longitude: "-93.56635000",
  },
  {
    id: 116278,
    name: "Eyota",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.98830000",
    longitude: "-92.22850000",
  },
  {
    id: 116305,
    name: "Fairfax",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.52913000",
    longitude: "-94.72082000",
  },
  {
    id: 116337,
    name: "Fairmont",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.65218000",
    longitude: "-94.46108000",
  },
  {
    id: 116365,
    name: "Falcon Heights",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.99163000",
    longitude: "-93.16633000",
  },
  {
    id: 116397,
    name: "Faribault",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.29496000",
    longitude: "-93.26883000",
  },
  {
    id: 116398,
    name: "Faribault County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.67393000",
    longitude: "-93.94800000",
  },
  {
    id: 116419,
    name: "Farmington",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.64024000",
    longitude: "-93.14355000",
  },
  {
    id: 116479,
    name: "Fergus Falls",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.28302000",
    longitude: "-96.07756000",
  },
  {
    id: 116510,
    name: "Fillmore County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.67400000",
    longitude: "-92.09017000",
  },
  {
    id: 116620,
    name: "Foley",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.66469000",
    longitude: "-93.90970000",
  },
  {
    id: 116661,
    name: "Forest Lake",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.27886000",
    longitude: "-92.98522000",
  },
  {
    id: 116773,
    name: "Fosston",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.57635000",
    longitude: "-95.75141000",
  },
  {
    id: 116882,
    name: "Frazee",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.72801000",
    longitude: "-95.70088000",
  },
  {
    id: 116899,
    name: "Freeborn County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.67384000",
    longitude: "-93.34882000",
  },
  {
    id: 116944,
    name: "Fridley",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.08608000",
    longitude: "-93.26328000",
  },
  {
    id: 116979,
    name: "Fulda",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.87052000",
    longitude: "-95.60029000",
  },
  {
    id: 117128,
    name: "Gaylord",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.55302000",
    longitude: "-94.22053000",
  },
  {
    id: 117203,
    name: "Gilbert",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.48882000",
    longitude: "-92.46491000",
  },
  {
    id: 117279,
    name: "Glencoe",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.76913000",
    longitude: "-94.15164000",
  },
  {
    id: 117315,
    name: "Glenwood",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.65024000",
    longitude: "-95.38976000",
  },
  {
    id: 117330,
    name: "Glyndon",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.87524000",
    longitude: "-96.57896000",
  },
  {
    id: 117355,
    name: "Golden Valley",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.00969000",
    longitude: "-93.34912000",
  },
  {
    id: 117379,
    name: "Goodhue",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.40052000",
    longitude: "-92.62380000",
  },
  {
    id: 117380,
    name: "Goodhue County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.40985000",
    longitude: "-92.72259000",
  },
  {
    id: 117390,
    name: "Goodview",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.06246000",
    longitude: "-91.69571000",
  },
  {
    id: 117467,
    name: "Grand Marais",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.75045000",
    longitude: "-90.33427000",
  },
  {
    id: 117468,
    name: "Grand Meadow",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.70580000",
    longitude: "-92.57212000",
  },
  {
    id: 117472,
    name: "Grand Rapids",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.23717000",
    longitude: "-93.53021000",
  },
  {
    id: 117497,
    name: "Granite Falls",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.80996000",
    longitude: "-95.54558000",
  },
  {
    id: 117504,
    name: "Grant",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.08441000",
    longitude: "-92.91049000",
  },
  {
    id: 117513,
    name: "Grant County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.93405000",
    longitude: "-96.01218000",
  },
  {
    id: 117651,
    name: "Greenfield",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.10330000",
    longitude: "-93.69135000",
  },
  {
    id: 117871,
    name: "Hallock",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "48.77443000",
    longitude: "-96.94645000",
  },
  {
    id: 117879,
    name: "Ham Lake",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.25024000",
    longitude: "-93.24995000",
  },
  {
    id: 117971,
    name: "Hanover",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.15580000",
    longitude: "-93.66635000",
  },
  {
    id: 118043,
    name: "Harris",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.58635000",
    longitude: "-92.97466000",
  },
  {
    id: 118132,
    name: "Hastings",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.74330000",
    longitude: "-92.85243000",
  },
  {
    id: 118168,
    name: "Hawley",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.88079000",
    longitude: "-96.31673000",
  },
  {
    id: 118185,
    name: "Hayfield",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.89052000",
    longitude: "-92.84769000",
  },
  {
    id: 118241,
    name: "Hector",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.74385000",
    longitude: "-94.71555000",
  },
  {
    id: 118291,
    name: "Hennepin County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.00458000",
    longitude: "-93.47688000",
  },
  {
    id: 118326,
    name: "Hermantown",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.80689000",
    longitude: "-92.23825000",
  },
  {
    id: 118359,
    name: "Hibbing",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.42715000",
    longitude: "-92.93769000",
  },
  {
    id: 118477,
    name: "Hinckley",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.01134000",
    longitude: "-92.94437000",
  },
  {
    id: 118652,
    name: "Hopkins",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.92496000",
    longitude: "-93.46273000",
  },
  {
    id: 118706,
    name: "Houston County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.67144000",
    longitude: "-91.49283000",
  },
  {
    id: 118719,
    name: "Howard Lake",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.06080000",
    longitude: "-94.07331000",
  },
  {
    id: 118728,
    name: "Hoyt Lakes",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.51965000",
    longitude: "-92.13851000",
  },
  {
    id: 118732,
    name: "Hubbard County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.10865000",
    longitude: "-94.91664000",
  },
  {
    id: 118766,
    name: "Hugo",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.15997000",
    longitude: "-92.99327000",
  },
  {
    id: 118836,
    name: "Hutchinson",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.88774000",
    longitude: "-94.36971000",
  },
  {
    id: 118895,
    name: "Independence",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.02524000",
    longitude: "-93.70746000",
  },
  {
    id: 118952,
    name: "International Falls",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "48.60105000",
    longitude: "-93.41098000",
  },
  {
    id: 118953,
    name: "Inver Grove Heights",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.84802000",
    longitude: "-93.04272000",
  },
  {
    id: 119017,
    name: "Isanti",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.49024000",
    longitude: "-93.24773000",
  },
  {
    id: 119018,
    name: "Isanti County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.56149000",
    longitude: "-93.29518000",
  },
  {
    id: 119041,
    name: "Itasca County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.50953000",
    longitude: "-93.63200000",
  },
  {
    id: 119048,
    name: "Ivanhoe",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.46330000",
    longitude: "-96.24726000",
  },
  {
    id: 119070,
    name: "Jackson",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.62079000",
    longitude: "-94.98860000",
  },
  {
    id: 119092,
    name: "Jackson County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.67417000",
    longitude: "-95.15411000",
  },
  {
    id: 119130,
    name: "Janesville",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.11608000",
    longitude: "-93.70800000",
  },
  {
    id: 119315,
    name: "Jordan",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.66691000",
    longitude: "-93.62690000",
  },
  {
    id: 119373,
    name: "Kanabec County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.94522000",
    longitude: "-93.29343000",
  },
  {
    id: 119375,
    name: "Kandiyohi County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.15238000",
    longitude: "-95.00474000",
  },
  {
    id: 119392,
    name: "Kasson",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.02996000",
    longitude: "-92.75074000",
  },
  {
    id: 119424,
    name: "Keewatin",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.39966000",
    longitude: "-93.07242000",
  },
  {
    id: 119506,
    name: "Kenyon",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.27219000",
    longitude: "-92.98548000",
  },
  {
    id: 119667,
    name: "Kittson County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "48.77663000",
    longitude: "-96.78285000",
  },
  {
    id: 119705,
    name: "Koochiching County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "48.24527000",
    longitude: "-93.78337000",
  },
  {
    id: 119741,
    name: "La Crescent",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.82802000",
    longitude: "-91.30403000",
  },
  {
    id: 119811,
    name: "Lac qui Parle County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.99549000",
    longitude: "-96.17348000",
  },
  {
    id: 119883,
    name: "Lake City",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.44968000",
    longitude: "-92.26820000",
  },
  {
    id: 119894,
    name: "Lake County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.52317000",
    longitude: "-91.40885000",
  },
  {
    id: 119900,
    name: "Lake Crystal",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.10580000",
    longitude: "-94.21885000",
  },
  {
    id: 119905,
    name: "Lake Elmo",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.99580000",
    longitude: "-92.87938000",
  },
  {
    id: 120008,
    name: "Lake of the Woods County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "48.77051000",
    longitude: "-94.90503000",
  },
  {
    id: 119971,
    name: "Lake Saint Croix Beach",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.92080000",
    longitude: "-92.76687000",
  },
  {
    id: 119977,
    name: "Lake Shore",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.48552000",
    longitude: "-94.36056000",
  },
  {
    id: 120009,
    name: "Lakefield",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.67746000",
    longitude: "-95.17166000",
  },
  {
    id: 120015,
    name: "Lakeland",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.95636000",
    longitude: "-92.76576000",
  },
  {
    id: 120043,
    name: "Lakeville",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.64969000",
    longitude: "-93.24272000",
  },
  {
    id: 120178,
    name: "Lauderdale",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.99858000",
    longitude: "-93.20578000",
  },
  {
    id: 120257,
    name: "Le Center",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.38941000",
    longitude: "-93.73023000",
  },
  {
    id: 120264,
    name: "Le Sueur",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.46135000",
    longitude: "-93.91524000",
  },
  {
    id: 120265,
    name: "Le Sueur County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.37143000",
    longitude: "-93.73008000",
  },
  {
    id: 120399,
    name: "Lester Prairie",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.88385000",
    longitude: "-94.04164000",
  },
  {
    id: 120425,
    name: "Lewiston",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.98441000",
    longitude: "-91.86932000",
  },
  {
    id: 120450,
    name: "Lexington",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.14247000",
    longitude: "-93.16328000",
  },
  {
    id: 120527,
    name: "Lincoln County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.41260000",
    longitude: "-96.26709000",
  },
  {
    id: 120576,
    name: "Lindstrom",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.38941000",
    longitude: "-92.84799000",
  },
  {
    id: 120587,
    name: "Lino Lakes",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.16024000",
    longitude: "-93.08883000",
  },
  {
    id: 120605,
    name: "Litchfield",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.12718000",
    longitude: "-94.52805000",
  },
  {
    id: 120613,
    name: "Little Canada",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.02691000",
    longitude: "-93.08772000",
  },
  {
    id: 120617,
    name: "Little Falls",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.97635000",
    longitude: "-94.36250000",
  },
  {
    id: 120627,
    name: "Little Rock",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.86801000",
    longitude: "-95.11055000",
  },
  {
    id: 120747,
    name: "Long Lake",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.98663000",
    longitude: "-93.57162000",
  },
  {
    id: 120749,
    name: "Long Prairie",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.97469000",
    longitude: "-94.86558000",
  },
  {
    id: 120763,
    name: "Lonsdale",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.48024000",
    longitude: "-93.42856000",
  },
  {
    id: 120900,
    name: "Luverne",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.65414000",
    longitude: "-96.21281000",
  },
  {
    id: 120938,
    name: "Lyon County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.41349000",
    longitude: "-95.83897000",
  },
  {
    id: 120979,
    name: "Madelia",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.05079000",
    longitude: "-94.41830000",
  },
  {
    id: 120994,
    name: "Madison",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.00968000",
    longitude: "-96.19588000",
  },
  {
    id: 121022,
    name: "Madison Lake",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.20441000",
    longitude: "-93.81551000",
  },
  {
    id: 121043,
    name: "Mahnomen",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.31524000",
    longitude: "-95.96865000",
  },
  {
    id: 121044,
    name: "Mahnomen County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.32524000",
    longitude: "-95.80905000",
  },
  {
    id: 121047,
    name: "Mahtomedi",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.06969000",
    longitude: "-92.95160000",
  },
  {
    id: 121130,
    name: "Mankato",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.15906000",
    longitude: "-94.00915000",
  },
  {
    id: 121159,
    name: "Mantorville",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.06913000",
    longitude: "-92.75575000",
  },
  {
    id: 121167,
    name: "Maple Grove",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.07246000",
    longitude: "-93.45579000",
  },
  {
    id: 121169,
    name: "Maple Lake",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.22913000",
    longitude: "-94.00192000",
  },
  {
    id: 121171,
    name: "Maple Plain",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.00719000",
    longitude: "-93.65579000",
  },
  {
    id: 121175,
    name: "Mapleton",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.92885000",
    longitude: "-93.95606000",
  },
  {
    id: 121178,
    name: "Maplewood",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.95302000",
    longitude: "-92.99522000",
  },
  {
    id: 121308,
    name: "Marshall",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.44690000",
    longitude: "-95.78835000",
  },
  {
    id: 121320,
    name: "Marshall County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "48.35813000",
    longitude: "-96.36847000",
  },
  {
    id: 121341,
    name: "Martin County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.67436000",
    longitude: "-94.55107000",
  },
  {
    id: 121432,
    name: "Mayer",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.88496000",
    longitude: "-93.88775000",
  },
  {
    id: 121535,
    name: "McLeod County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.82354000",
    longitude: "-94.27242000",
  },
  {
    id: 121593,
    name: "Medford",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.17413000",
    longitude: "-93.24632000",
  },
  {
    id: 121604,
    name: "Medina",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.03524000",
    longitude: "-93.58246000",
  },
  {
    id: 121614,
    name: "Meeker County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.12312000",
    longitude: "-94.52731000",
  },
  {
    id: 121629,
    name: "Melrose",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.67469000",
    longitude: "-94.80752000",
  },
  {
    id: 121644,
    name: "Menahga",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.75385000",
    longitude: "-95.09808000",
  },
  {
    id: 121658,
    name: "Mendota Heights",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.88358000",
    longitude: "-93.13827000",
  },
  {
    id: 121832,
    name: "Milaca",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.75580000",
    longitude: "-93.65441000",
  },
  {
    id: 121876,
    name: "Mille Lacs County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.93805000",
    longitude: "-93.63009000",
  },
  {
    id: 121951,
    name: "Minneapolis",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.97997000",
    longitude: "-93.26384000",
  },
  {
    id: 121955,
    name: "Minneota",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.55885000",
    longitude: "-95.98559000",
  },
  {
    id: 121956,
    name: "Minnetonka",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.91330000",
    longitude: "-93.50329000",
  },
  {
    id: 121957,
    name: "Minnetonka Mills",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.94107000",
    longitude: "-93.44190000",
  },
  {
    id: 121958,
    name: "Minnetrista",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.93830000",
    longitude: "-93.71774000",
  },
  {
    id: 122126,
    name: "Montevideo",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.94803000",
    longitude: "-95.71701000",
  },
  {
    id: 122134,
    name: "Montgomery",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.43885000",
    longitude: "-93.58134000",
  },
  {
    id: 122167,
    name: "Monticello",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.30552000",
    longitude: "-93.79414000",
  },
  {
    id: 122180,
    name: "Montrose",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.06496000",
    longitude: "-93.91108000",
  },
  {
    id: 122212,
    name: "Moorhead",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.87386000",
    longitude: "-96.76951000",
  },
  {
    id: 122214,
    name: "Moose Lake",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.45411000",
    longitude: "-92.76187000",
  },
  {
    id: 122218,
    name: "Mora",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.87690000",
    longitude: "-93.29384000",
  },
  {
    id: 122268,
    name: "Morris",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.58607000",
    longitude: "-95.91394000",
  },
  {
    id: 122277,
    name: "Morrison County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.01262000",
    longitude: "-94.26842000",
  },
  {
    id: 122318,
    name: "Mound",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.93663000",
    longitude: "-93.66607000",
  },
  {
    id: 122326,
    name: "Mounds View",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.10497000",
    longitude: "-93.20856000",
  },
  {
    id: 122419,
    name: "Mountain Iron",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.53243000",
    longitude: "-92.62351000",
  },
  {
    id: 122420,
    name: "Mountain Lake",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.93885000",
    longitude: "-94.92971000",
  },
  {
    id: 122445,
    name: "Mower County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.67147000",
    longitude: "-92.75251000",
  },
  {
    id: 122495,
    name: "Murray County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.02212000",
    longitude: "-95.76328000",
  },
  {
    id: 122671,
    name: "New Brighton",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.06552000",
    longitude: "-93.20189000",
  },
  {
    id: 122726,
    name: "New Hope",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.03802000",
    longitude: "-93.38662000",
  },
  {
    id: 122743,
    name: "New London",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.30108000",
    longitude: "-94.94418000",
  },
  {
    id: 122766,
    name: "New Prague",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.54330000",
    longitude: "-93.57607000",
  },
  {
    id: 122769,
    name: "New Richland",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.89385000",
    longitude: "-93.49383000",
  },
  {
    id: 122788,
    name: "New Ulm",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.31246000",
    longitude: "-94.46053000",
  },
  {
    id: 122797,
    name: "New York Mills",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.51802000",
    longitude: "-95.37615000",
  },
  {
    id: 122845,
    name: "Newport",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.86636000",
    longitude: "-93.00049000",
  },
  {
    id: 122895,
    name: "Nicollet",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.27608000",
    longitude: "-94.18746000",
  },
  {
    id: 122896,
    name: "Nicollet County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.34989000",
    longitude: "-94.24730000",
  },
  {
    id: 122908,
    name: "Nisswa",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.52052000",
    longitude: "-94.28861000",
  },
  {
    id: 122920,
    name: "Nobles County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.67424000",
    longitude: "-95.75339000",
  },
  {
    id: 122950,
    name: "Norman County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.32648000",
    longitude: "-96.45528000",
  },
  {
    id: 122994,
    name: "North Branch",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.51135000",
    longitude: "-92.98022000",
  },
  {
    id: 123063,
    name: "North Mankato",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.17330000",
    longitude: "-94.03385000",
  },
  {
    id: 123073,
    name: "North Oaks",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.10274000",
    longitude: "-93.07911000",
  },
  {
    id: 123098,
    name: "North Saint Paul",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.01247000",
    longitude: "-92.99188000",
  },
  {
    id: 123152,
    name: "Northfield",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.45830000",
    longitude: "-93.16160000",
  },
  {
    id: 123202,
    name: "Norwood (historical)",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.76802000",
    longitude: "-93.92747000",
  },
  {
    id: 123203,
    name: "Norwood Young America",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.77357000",
    longitude: "-93.92163000",
  },
  {
    id: 123211,
    name: "Nowthen",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.32802000",
    longitude: "-93.47023000",
  },
  {
    id: 123239,
    name: "Oak Grove",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.34080000",
    longitude: "-93.32690000",
  },
  {
    id: 123258,
    name: "Oak Park Heights",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.03136000",
    longitude: "-92.79298000",
  },
  {
    id: 123271,
    name: "Oakdale",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.96302000",
    longitude: "-92.96494000",
  },
  {
    id: 123303,
    name: "Oakport",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.93191000",
    longitude: "-96.77897000",
  },
  {
    id: 123450,
    name: "Olivia",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.77635000",
    longitude: "-94.98972000",
  },
  {
    id: 123454,
    name: "Olmsted County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.00375000",
    longitude: "-92.40177000",
  },
  {
    id: 123573,
    name: "Orono",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.97135000",
    longitude: "-93.60440000",
  },
  {
    id: 123575,
    name: "Oronoco",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.16608000",
    longitude: "-92.53491000",
  },
  {
    id: 123584,
    name: "Ortonville",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.30469000",
    longitude: "-96.44478000",
  },
  {
    id: 123592,
    name: "Osakis",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.86691000",
    longitude: "-95.15225000",
  },
  {
    id: 123614,
    name: "Osseo",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.11941000",
    longitude: "-93.40245000",
  },
  {
    id: 123633,
    name: "Otsego",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.27413000",
    longitude: "-93.59135000",
  },
  {
    id: 123641,
    name: "Otter Tail County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.40880000",
    longitude: "-95.70800000",
  },
  {
    id: 123659,
    name: "Owatonna",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.08385000",
    longitude: "-93.22604000",
  },
  {
    id: 123850,
    name: "Park Rapids",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.92218000",
    longitude: "-95.05863000",
  },
  {
    id: 123866,
    name: "Parkers Prairie",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.15302000",
    longitude: "-95.32892000",
  },
  {
    id: 123880,
    name: "Parkville",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.53104000",
    longitude: "-92.57907000",
  },
  {
    id: 123947,
    name: "Paynesville",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.38052000",
    longitude: "-94.71195000",
  },
  {
    id: 123996,
    name: "Pelican Rapids",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.57079000",
    longitude: "-96.08311000",
  },
  {
    id: 124030,
    name: "Pennington County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "48.06623000",
    longitude: "-96.03667000",
  },
  {
    id: 124056,
    name: "Pequot Lakes",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.60302000",
    longitude: "-94.30944000",
  },
  {
    id: 124059,
    name: "Perham",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.59440000",
    longitude: "-95.57254000",
  },
  {
    id: 124181,
    name: "Pierz",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.98163000",
    longitude: "-94.10471000",
  },
  {
    id: 124218,
    name: "Pine City",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.82607000",
    longitude: "-92.96854000",
  },
  {
    id: 124219,
    name: "Pine County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.12077000",
    longitude: "-92.74127000",
  },
  {
    id: 124228,
    name: "Pine Island",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.20135000",
    longitude: "-92.64630000",
  },
  {
    id: 124277,
    name: "Pipestone",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.00053000",
    longitude: "-96.31753000",
  },
  {
    id: 124278,
    name: "Pipestone County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.02300000",
    longitude: "-96.25864000",
  },
  {
    id: 124328,
    name: "Plainview",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.16497000",
    longitude: "-92.17156000",
  },
  {
    id: 124405,
    name: "Plymouth",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.01052000",
    longitude: "-93.45551000",
  },
  {
    id: 124448,
    name: "Polk County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.77385000",
    longitude: "-96.40181000",
  },
  {
    id: 124483,
    name: "Pope County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.58602000",
    longitude: "-95.44448000",
  },
  {
    id: 124656,
    name: "Preston",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.67024000",
    longitude: "-92.08322000",
  },
  {
    id: 124688,
    name: "Princeton",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.56997000",
    longitude: "-93.58163000",
  },
  {
    id: 124698,
    name: "Prior Lake",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.71330000",
    longitude: "-93.42273000",
  },
  {
    id: 124700,
    name: "Proctor",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.74716000",
    longitude: "-92.22547000",
  },
  {
    id: 124841,
    name: "Ramsey",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.26110000",
    longitude: "-93.45000000",
  },
  {
    id: 124843,
    name: "Ramsey County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.01706000",
    longitude: "-93.09961000",
  },
  {
    id: 124949,
    name: "Red Lake",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.87635000",
    longitude: "-95.01694000",
  },
  {
    id: 124950,
    name: "Red Lake County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.87169000",
    longitude: "-96.09530000",
  },
  {
    id: 124951,
    name: "Red Lake Falls",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.88219000",
    longitude: "-96.27421000",
  },
  {
    id: 124963,
    name: "Red Wing",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.56247000",
    longitude: "-92.53380000",
  },
  {
    id: 124965,
    name: "Redby",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.87857000",
    longitude: "-94.91305000",
  },
  {
    id: 124987,
    name: "Redwood County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.40366000",
    longitude: "-95.25383000",
  },
  {
    id: 124988,
    name: "Redwood Falls",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.53940000",
    longitude: "-95.11694000",
  },
  {
    id: 125027,
    name: "Renville",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.78913000",
    longitude: "-95.21167000",
  },
  {
    id: 125028,
    name: "Renville County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.72681000",
    longitude: "-94.94714000",
  },
  {
    id: 125052,
    name: "Rice",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.75191000",
    longitude: "-94.22027000",
  },
  {
    id: 125054,
    name: "Rice County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.35426000",
    longitude: "-93.29668000",
  },
  {
    id: 125061,
    name: "Richfield",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.88330000",
    longitude: "-93.28300000",
  },
  {
    id: 125094,
    name: "Richmond",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.45413000",
    longitude: "-94.51833000",
  },
  {
    id: 125236,
    name: "Robbinsdale",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.03219000",
    longitude: "-93.33856000",
  },
  {
    id: 125266,
    name: "Rochester",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.02163000",
    longitude: "-92.46990000",
  },
  {
    id: 125272,
    name: "Rock County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.67463000",
    longitude: "-96.25321000",
  },
  {
    id: 125276,
    name: "Rock Creek",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.75746000",
    longitude: "-92.96243000",
  },
  {
    id: 125302,
    name: "Rockford",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.08830000",
    longitude: "-93.73441000",
  },
  {
    id: 125324,
    name: "Rockville",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.47191000",
    longitude: "-94.34083000",
  },
  {
    id: 125350,
    name: "Rogers",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.18885000",
    longitude: "-93.55301000",
  },
  {
    id: 125407,
    name: "Roseau",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "48.84609000",
    longitude: "-95.76277000",
  },
  {
    id: 125408,
    name: "Roseau County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "48.77514000",
    longitude: "-95.81082000",
  },
  {
    id: 125432,
    name: "Rosemount",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.73941000",
    longitude: "-93.12577000",
  },
  {
    id: 125440,
    name: "Roseville",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.00608000",
    longitude: "-93.15661000",
  },
  {
    id: 125496,
    name: "Royalton",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.82997000",
    longitude: "-94.29361000",
  },
  {
    id: 125519,
    name: "Rush City",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.68551000",
    longitude: "-92.96549000",
  },
  {
    id: 125523,
    name: "Rushford",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.80830000",
    longitude: "-91.75293000",
  },
  {
    id: 125601,
    name: "Saint Anthony",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.02052000",
    longitude: "-93.21800000",
  },
  {
    id: 125603,
    name: "Saint Augusta",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.45830000",
    longitude: "-94.19804000",
  },
  {
    id: 125610,
    name: "Saint Bonifacius",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.90552000",
    longitude: "-93.74746000",
  },
  {
    id: 125615,
    name: "Saint Charles",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.96941000",
    longitude: "-92.06433000",
  },
  {
    id: 125627,
    name: "Saint Cloud",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.56080000",
    longitude: "-94.16249000",
  },
  {
    id: 125633,
    name: "Saint Francis",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.38691000",
    longitude: "-93.35940000",
  },
  {
    id: 125655,
    name: "Saint James",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.98246000",
    longitude: "-94.62692000",
  },
  {
    id: 125674,
    name: "Saint Joseph",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.56496000",
    longitude: "-94.31833000",
  },
  {
    id: 125682,
    name: "Saint Louis County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.58986000",
    longitude: "-92.46147000",
  },
  {
    id: 125683,
    name: "Saint Louis Park",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.94830000",
    longitude: "-93.34801000",
  },
  {
    id: 125697,
    name: "Saint Michael",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.20996000",
    longitude: "-93.66496000",
  },
  {
    id: 125703,
    name: "Saint Paul",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.94441000",
    longitude: "-93.09327000",
  },
  {
    id: 125705,
    name: "Saint Paul Park",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.84219000",
    longitude: "-92.99132000",
  },
  {
    id: 125708,
    name: "Saint Peter",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.32358000",
    longitude: "-93.95801000",
  },
  {
    id: 125874,
    name: "Sandstone",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.13106000",
    longitude: "-92.86742000",
  },
  {
    id: 125953,
    name: "Sartell",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.62163000",
    longitude: "-94.20694000",
  },
  {
    id: 125962,
    name: "Sauk Centre",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.73747000",
    longitude: "-94.95252000",
  },
  {
    id: 125965,
    name: "Sauk Rapids",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.59191000",
    longitude: "-94.16610000",
  },
  {
    id: 125972,
    name: "Savage",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.77913000",
    longitude: "-93.33634000",
  },
  {
    id: 125995,
    name: "Scandia",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.25358000",
    longitude: "-92.80577000",
  },
  {
    id: 126052,
    name: "Scott County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.64846000",
    longitude: "-93.53593000",
  },
  {
    id: 126209,
    name: "Shafer",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.38691000",
    longitude: "-92.74771000",
  },
  {
    id: 126211,
    name: "Shakopee",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.79802000",
    longitude: "-93.52690000",
  },
  {
    id: 126314,
    name: "Sherburn",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.65218000",
    longitude: "-94.72692000",
  },
  {
    id: 126316,
    name: "Sherburne County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.44395000",
    longitude: "-93.77459000",
  },
  {
    id: 126369,
    name: "Shoreview",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.07913000",
    longitude: "-93.14717000",
  },
  {
    id: 126371,
    name: "Shorewood",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.90080000",
    longitude: "-93.58912000",
  },
  {
    id: 126392,
    name: "Sibley County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.57948000",
    longitude: "-94.23216000",
  },
  {
    id: 126420,
    name: "Silver Bay",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.29436000",
    longitude: "-91.25739000",
  },
  {
    id: 126495,
    name: "Slayton",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.98774000",
    longitude: "-95.75585000",
  },
  {
    id: 126496,
    name: "Sleepy Eye",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.29718000",
    longitude: "-94.72415000",
  },
  {
    id: 126721,
    name: "South Saint Paul",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.89274000",
    longitude: "-93.03494000",
  },
  {
    id: 126834,
    name: "Spicer",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.23302000",
    longitude: "-94.94001000",
  },
  {
    id: 126859,
    name: "Spring Grove",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.56108000",
    longitude: "-91.63598000",
  },
  {
    id: 126869,
    name: "Spring Lake Park",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.10774000",
    longitude: "-93.23800000",
  },
  {
    id: 126871,
    name: "Spring Park",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.93524000",
    longitude: "-93.63218000",
  },
  {
    id: 126876,
    name: "Spring Valley",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.68691000",
    longitude: "-92.38906000",
  },
  {
    id: 126902,
    name: "Springfield",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.23885000",
    longitude: "-94.97582000",
  },
  {
    id: 126931,
    name: "Stacy",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.39802000",
    longitude: "-92.98744000",
  },
  {
    id: 126975,
    name: "Staples",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.35552000",
    longitude: "-94.79224000",
  },
  {
    id: 126983,
    name: "Starbuck",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.61440000",
    longitude: "-95.53115000",
  },
  {
    id: 127005,
    name: "Stearns County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.55215000",
    longitude: "-94.61302000",
  },
  {
    id: 127010,
    name: "Steele County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.02234000",
    longitude: "-93.22604000",
  },
  {
    id: 127040,
    name: "Stevens County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.58613000",
    longitude: "-96.00030000",
  },
  {
    id: 127055,
    name: "Stewartville",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.85552000",
    longitude: "-92.48851000",
  },
  {
    id: 127061,
    name: "Stillwater",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.05636000",
    longitude: "-92.80604000",
  },
  {
    id: 127345,
    name: "Swift County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.28271000",
    longitude: "-95.68143000",
  },
  {
    id: 127462,
    name: "Taylors Falls",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.40191000",
    longitude: "-92.65243000",
  },
  {
    id: 127574,
    name: "Thief River Falls",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "48.11914000",
    longitude: "-96.18115000",
  },
  {
    id: 127693,
    name: "Todd County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.07062000",
    longitude: "-94.89760000",
  },
  {
    id: 127720,
    name: "Tonka Bay",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.90857000",
    longitude: "-93.59301000",
  },
  {
    id: 127770,
    name: "Tracy",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.23329000",
    longitude: "-95.61918000",
  },
  {
    id: 127785,
    name: "Traverse County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.77218000",
    longitude: "-96.47164000",
  },
  {
    id: 127855,
    name: "Truman",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.82773000",
    longitude: "-94.43719000",
  },
  {
    id: 127935,
    name: "Two Harbors",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.02271000",
    longitude: "-91.67073000",
  },
  {
    id: 127941,
    name: "Tyler",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.27830000",
    longitude: "-96.13475000",
  },
  {
    id: 128073,
    name: "Vadnais Heights",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.05747000",
    longitude: "-93.07383000",
  },
  {
    id: 128222,
    name: "Victoria",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.85857000",
    longitude: "-93.66163000",
  },
  {
    id: 128271,
    name: "Vineland",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.16357000",
    longitude: "-93.75747000",
  },
  {
    id: 128287,
    name: "Virginia",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.52326000",
    longitude: "-92.53657000",
  },
  {
    id: 128312,
    name: "Wabasha",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.38386000",
    longitude: "-92.03294000",
  },
  {
    id: 128313,
    name: "Wabasha County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.28428000",
    longitude: "-92.23027000",
  },
  {
    id: 128317,
    name: "Waconia",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.85080000",
    longitude: "-93.78691000",
  },
  {
    id: 128321,
    name: "Wadena",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.44246000",
    longitude: "-95.13614000",
  },
  {
    id: 128322,
    name: "Wadena County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.58576000",
    longitude: "-94.96941000",
  },
  {
    id: 128353,
    name: "Waite Park",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.55719000",
    longitude: "-94.22416000",
  },
  {
    id: 128385,
    name: "Walker",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "47.10135000",
    longitude: "-94.58722000",
  },
  {
    id: 128448,
    name: "Wanamingo",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.30441000",
    longitude: "-92.79047000",
  },
  {
    id: 128482,
    name: "Warren",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "48.19664000",
    longitude: "-96.77284000",
  },
  {
    id: 128514,
    name: "Warroad",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "48.90527000",
    longitude: "-95.31440000",
  },
  {
    id: 128530,
    name: "Waseca",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.07774000",
    longitude: "-93.50744000",
  },
  {
    id: 128531,
    name: "Waseca County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.02212000",
    longitude: "-93.58728000",
  },
  {
    id: 128570,
    name: "Washington County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.03873000",
    longitude: "-92.88396000",
  },
  {
    id: 128622,
    name: "Watertown",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.96357000",
    longitude: "-93.84719000",
  },
  {
    id: 128627,
    name: "Waterville",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.21885000",
    longitude: "-93.56800000",
  },
  {
    id: 128637,
    name: "Watonwan County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.97843000",
    longitude: "-94.61406000",
  },
  {
    id: 128670,
    name: "Waverly",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.06663000",
    longitude: "-93.96636000",
  },
  {
    id: 128713,
    name: "Wayzata",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.97413000",
    longitude: "-93.50662000",
  },
  {
    id: 128782,
    name: "Wells",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.74607000",
    longitude: "-93.72884000",
  },
  {
    id: 128852,
    name: "West Coon Rapids",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.15969000",
    longitude: "-93.34967000",
  },
  {
    id: 128972,
    name: "West Saint Paul",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.91608000",
    longitude: "-93.10161000",
  },
  {
    id: 129114,
    name: "Wheaton",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.80441000",
    longitude: "-96.49923000",
  },
  {
    id: 129126,
    name: "White Bear Lake",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.08469000",
    longitude: "-93.00994000",
  },
  {
    id: 129248,
    name: "Wilkin County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "46.35708000",
    longitude: "-96.46835000",
  },
  {
    id: 129303,
    name: "Willmar",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.12191000",
    longitude: "-95.04334000",
  },
  {
    id: 129375,
    name: "Windom",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.86635000",
    longitude: "-95.11694000",
  },
  {
    id: 129406,
    name: "Winnebago",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.76773000",
    longitude: "-94.16579000",
  },
  {
    id: 129424,
    name: "Winona",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.04996000",
    longitude: "-91.63932000",
  },
  {
    id: 129425,
    name: "Winona County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.98685000",
    longitude: "-91.77913000",
  },
  {
    id: 129431,
    name: "Winsted",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.96385000",
    longitude: "-94.04747000",
  },
  {
    id: 129451,
    name: "Winthrop",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.54302000",
    longitude: "-94.36637000",
  },
  {
    id: 129509,
    name: "Woodbury",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.92386000",
    longitude: "-92.95938000",
  },
  {
    id: 129598,
    name: "Worthington",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "43.61996000",
    longitude: "-95.59640000",
  },
  {
    id: 129610,
    name: "Wright County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.17393000",
    longitude: "-93.96305000",
  },
  {
    id: 129637,
    name: "Wyoming",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.33636000",
    longitude: "-92.99716000",
  },
  {
    id: 129678,
    name: "Yellow Medicine County",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.71625000",
    longitude: "-95.86836000",
  },
  {
    id: 129713,
    name: "Young America (historical)",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.78274000",
    longitude: "-93.91358000",
  },
  {
    id: 129756,
    name: "Zimmerman",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "45.44330000",
    longitude: "-93.58996000",
  },
  {
    id: 129761,
    name: "Zumbrota",
    state_id: 1420,
    state_code: "MN",
    country_id: 233,
    country_code: "US",
    latitude: "44.29413000",
    longitude: "-92.66908000",
  },
  {
    id: 110974,
    name: "Aberdeen",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.82511000",
    longitude: "-88.54366000",
  },
  {
    id: 110996,
    name: "Ackerman",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.31012000",
    longitude: "-89.17284000",
  },
  {
    id: 111018,
    name: "Adams County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.48294000",
    longitude: "-91.35350000",
  },
  {
    id: 111134,
    name: "Alcorn County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.88077000",
    longitude: "-88.58026000",
  },
  {
    id: 111289,
    name: "Amite County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.17440000",
    longitude: "-90.80443000",
  },
  {
    id: 111294,
    name: "Amory",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.98428000",
    longitude: "-88.48810000",
  },
  {
    id: 111489,
    name: "Arnold Line",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.33517000",
    longitude: "-89.37340000",
  },
  {
    id: 111530,
    name: "Ashland",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.83287000",
    longitude: "-89.17590000",
  },
  {
    id: 111607,
    name: "Attala County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.08629000",
    longitude: "-89.58155000",
  },
  {
    id: 111766,
    name: "Baldwyn",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.50954000",
    longitude: "-88.63533000",
  },
  {
    id: 111894,
    name: "Batesville",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.31150000",
    longitude: "-89.94426000",
  },
  {
    id: 111933,
    name: "Bay Saint Louis",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.30881000",
    longitude: "-89.33005000",
  },
  {
    id: 111935,
    name: "Bay Springs",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.97904000",
    longitude: "-89.28728000",
  },
  {
    id: 112041,
    name: "Beechwood",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.32765000",
    longitude: "-90.82677000",
  },
  {
    id: 112135,
    name: "Belmont",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.50982000",
    longitude: "-88.20921000",
  },
  {
    id: 112158,
    name: "Belzoni",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.18429000",
    longitude: "-90.48926000",
  },
  {
    id: 112201,
    name: "Benton County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.81729000",
    longitude: "-89.18848000",
  },
  {
    id: 112347,
    name: "Biloxi",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.39603000",
    longitude: "-88.88531000",
  },
  {
    id: 112521,
    name: "Bolivar County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.79554000",
    longitude: "-90.88040000",
  },
  {
    id: 112564,
    name: "Booneville",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.65815000",
    longitude: "-88.56672000",
  },
  {
    id: 112689,
    name: "Brandon",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.27320000",
    longitude: "-89.98592000",
  },
  {
    id: 112857,
    name: "Brookhaven",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.57906000",
    longitude: "-90.44065000",
  },
  {
    id: 112892,
    name: "Brooksville",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.23457000",
    longitude: "-88.58227000",
  },
  {
    id: 112942,
    name: "Bruce",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.99206000",
    longitude: "-89.34896000",
  },
  {
    id: 112995,
    name: "Bude",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.46295000",
    longitude: "-90.85010000",
  },
  {
    id: 113135,
    name: "Byhalia",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.87232000",
    longitude: "-89.69064000",
  },
  {
    id: 113139,
    name: "Byram",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.17932000",
    longitude: "-90.24537000",
  },
  {
    id: 113188,
    name: "Caledonia",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.68289000",
    longitude: "-88.32448000",
  },
  {
    id: 113199,
    name: "Calhoun City",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.85539000",
    longitude: "-89.31146000",
  },
  {
    id: 113205,
    name: "Calhoun County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.93645000",
    longitude: "-89.33645000",
  },
  {
    id: 113340,
    name: "Canton",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.61264000",
    longitude: "-90.03675000",
  },
  {
    id: 113445,
    name: "Carriere",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.61686000",
    longitude: "-89.65256000",
  },
  {
    id: 113455,
    name: "Carroll County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.44853000",
    longitude: "-89.92020000",
  },
  {
    id: 113468,
    name: "Carrollton",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.50818000",
    longitude: "-89.92036000",
  },
  {
    id: 113492,
    name: "Carthage",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.73264000",
    longitude: "-89.53618000",
  },
  {
    id: 113694,
    name: "Centreville",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.08962000",
    longitude: "-91.06844000",
  },
  {
    id: 113764,
    name: "Charleston",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.00678000",
    longitude: "-90.05676000",
  },
  {
    id: 113939,
    name: "Chickasaw County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.92080000",
    longitude: "-88.94786000",
  },
  {
    id: 113987,
    name: "Choctaw County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.34731000",
    longitude: "-89.24838000",
  },
  {
    id: 114093,
    name: "Claiborne County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.97369000",
    longitude: "-90.91181000",
  },
  {
    id: 114136,
    name: "Clarke County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.04140000",
    longitude: "-88.68940000",
  },
  {
    id: 114145,
    name: "Clarksdale",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.20011000",
    longitude: "-90.57093000",
  },
  {
    id: 114182,
    name: "Clay County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.65567000",
    longitude: "-88.78157000",
  },
  {
    id: 114228,
    name: "Cleary",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.16543000",
    longitude: "-90.18064000",
  },
  {
    id: 114243,
    name: "Cleveland",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.74400000",
    longitude: "-90.72482000",
  },
  {
    id: 114274,
    name: "Clinton",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.34153000",
    longitude: "-90.32176000",
  },
  {
    id: 114325,
    name: "Coahoma County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.22917000",
    longitude: "-90.60269000",
  },
  {
    id: 114393,
    name: "Coldwater",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.69177000",
    longitude: "-89.97731000",
  },
  {
    id: 114431,
    name: "Collins",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.64544000",
    longitude: "-89.55535000",
  },
  {
    id: 114435,
    name: "Collinsville",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.49792000",
    longitude: "-88.84588000",
  },
  {
    id: 114469,
    name: "Columbia",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.25184000",
    longitude: "-89.83758000",
  },
  {
    id: 114494,
    name: "Columbus",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.49567000",
    longitude: "-88.42726000",
  },
  {
    id: 114501,
    name: "Columbus Air Force Base",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.63239000",
    longitude: "-88.45153000",
  },
  {
    id: 114527,
    name: "Como",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.51066000",
    longitude: "-89.93981000",
  },
  {
    id: 114551,
    name: "Conehatta",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.45125000",
    longitude: "-89.28534000",
  },
  {
    id: 114617,
    name: "Copiah County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.86924000",
    longitude: "-90.44880000",
  },
  {
    id: 114643,
    name: "Corinth",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.93425000",
    longitude: "-88.52227000",
  },
  {
    id: 114743,
    name: "Covington County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.63322000",
    longitude: "-89.55263000",
  },
  {
    id: 114884,
    name: "Crystal Springs",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.98738000",
    longitude: "-90.35704000",
  },
  {
    id: 114961,
    name: "D'Iberville",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.42631000",
    longitude: "-88.89086000",
  },
  {
    id: 115106,
    name: "De Kalb",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.76763000",
    longitude: "-88.65088000",
  },
  {
    id: 115111,
    name: "De Lisle",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.37936000",
    longitude: "-89.26449000",
  },
  {
    id: 115119,
    name: "De Soto County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.87540000",
    longitude: "-89.99178000",
  },
  {
    id: 115153,
    name: "Decatur",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.43903000",
    longitude: "-89.10839000",
  },
  {
    id: 115263,
    name: "Derma",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.85567000",
    longitude: "-89.28452000",
  },
  {
    id: 115317,
    name: "Diamondhead",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.39464000",
    longitude: "-89.36394000",
  },
  {
    id: 115473,
    name: "Drew",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.80956000",
    longitude: "-90.52648000",
  },
  {
    id: 115504,
    name: "Duck Hill",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.63318000",
    longitude: "-89.71119000",
  },
  {
    id: 115566,
    name: "Durant",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.07513000",
    longitude: "-89.85453000",
  },
  {
    id: 115886,
    name: "Edwards",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.33015000",
    longitude: "-90.60565000",
  },
  {
    id: 116035,
    name: "Ellisville",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.60405000",
    longitude: "-89.19561000",
  },
  {
    id: 116182,
    name: "Escatawpa",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.44048000",
    longitude: "-88.54363000",
  },
  {
    id: 116231,
    name: "Eupora",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.54068000",
    longitude: "-89.26701000",
  },
  {
    id: 116414,
    name: "Farmington",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.93009000",
    longitude: "-88.45227000",
  },
  {
    id: 116440,
    name: "Fayette",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.71155000",
    longitude: "-91.06066000",
  },
  {
    id: 116571,
    name: "Flora",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.54320000",
    longitude: "-90.30926000",
  },
  {
    id: 116579,
    name: "Florence",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.15348000",
    longitude: "-90.13120000",
  },
  {
    id: 116604,
    name: "Flowood",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.30959000",
    longitude: "-90.13898000",
  },
  {
    id: 116643,
    name: "Forest",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.36459000",
    longitude: "-89.47423000",
  },
  {
    id: 116680,
    name: "Forrest County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.18887000",
    longitude: "-89.25786000",
  },
  {
    id: 116852,
    name: "Franklin County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.47715000",
    longitude: "-90.89785000",
  },
  {
    id: 116942,
    name: "Friars Point",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.37088000",
    longitude: "-90.63834000",
  },
  {
    id: 116988,
    name: "Fulton",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.27399000",
    longitude: "-88.40921000",
  },
  {
    id: 117126,
    name: "Gautier",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.38575000",
    longitude: "-88.61169000",
  },
  {
    id: 117153,
    name: "George County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.86261000",
    longitude: "-88.64403000",
  },
  {
    id: 117281,
    name: "Glendale",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.36462000",
    longitude: "-89.30617000",
  },
  {
    id: 117388,
    name: "Goodman",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.97013000",
    longitude: "-89.91231000",
  },
  {
    id: 117637,
    name: "Greene County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.21422000",
    longitude: "-88.63916000",
  },
  {
    id: 117686,
    name: "Greenville",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.40898000",
    longitude: "-91.05978000",
  },
  {
    id: 117705,
    name: "Greenwood",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.51623000",
    longitude: "-90.17953000",
  },
  {
    id: 117720,
    name: "Grenada",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.76900000",
    longitude: "-89.80842000",
  },
  {
    id: 117721,
    name: "Grenada County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.76995000",
    longitude: "-89.80201000",
  },
  {
    id: 117793,
    name: "Gulf Hills",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.43048000",
    longitude: "-88.84225000",
  },
  {
    id: 117794,
    name: "Gulf Park Estates",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.39187000",
    longitude: "-88.76114000",
  },
  {
    id: 117797,
    name: "Gulfport",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.36742000",
    longitude: "-89.09282000",
  },
  {
    id: 117807,
    name: "Guntown",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.44316000",
    longitude: "-88.65978000",
  },
  {
    id: 117953,
    name: "Hancock County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.39378000",
    longitude: "-89.47456000",
  },
  {
    id: 118064,
    name: "Harrison County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.41605000",
    longitude: "-89.08164000",
  },
  {
    id: 118140,
    name: "Hattiesburg",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.32712000",
    longitude: "-89.29034000",
  },
  {
    id: 118212,
    name: "Hazlehurst",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.86044000",
    longitude: "-90.39593000",
  },
  {
    id: 118249,
    name: "Helena",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.49464000",
    longitude: "-88.49585000",
  },
  {
    id: 118333,
    name: "Hernando",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.82399000",
    longitude: "-89.99370000",
  },
  {
    id: 118368,
    name: "Hickory Hills",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.45687000",
    longitude: "-88.63919000",
  },
  {
    id: 118381,
    name: "Hide-A-Way Lake",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.56492000",
    longitude: "-89.64020000",
  },
  {
    id: 118448,
    name: "Hillsboro",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.45931000",
    longitude: "-89.51146000",
  },
  {
    id: 118479,
    name: "Hinds County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.26670000",
    longitude: "-90.44282000",
  },
  {
    id: 118541,
    name: "Hollandale",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.16901000",
    longitude: "-90.85399000",
  },
  {
    id: 118563,
    name: "Holly Springs",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.76760000",
    longitude: "-89.44869000",
  },
  {
    id: 118572,
    name: "Holmes County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.12351000",
    longitude: "-90.09205000",
  },
  {
    id: 118666,
    name: "Horn Lake",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.95537000",
    longitude: "-90.03481000",
  },
  {
    id: 118698,
    name: "Houston",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.89845000",
    longitude: "-88.99923000",
  },
  {
    id: 118784,
    name: "Humphreys County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.12870000",
    longitude: "-90.52662000",
  },
  {
    id: 118819,
    name: "Hurley",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.66103000",
    longitude: "-88.49418000",
  },
  {
    id: 118925,
    name: "Indianola",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.45095000",
    longitude: "-90.65509000",
  },
  {
    id: 119037,
    name: "Issaquena County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.74139000",
    longitude: "-90.98921000",
  },
  {
    id: 119042,
    name: "Itawamba County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.27999000",
    longitude: "-88.36132000",
  },
  {
    id: 119045,
    name: "Itta Bena",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.49512000",
    longitude: "-90.31981000",
  },
  {
    id: 119046,
    name: "Iuka",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.81176000",
    longitude: "-88.19004000",
  },
  {
    id: 119065,
    name: "Jackson",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.29876000",
    longitude: "-90.18481000",
  },
  {
    id: 119084,
    name: "Jackson County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.46289000",
    longitude: "-88.62284000",
  },
  {
    id: 119148,
    name: "Jasper County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.01911000",
    longitude: "-89.11886000",
  },
  {
    id: 119184,
    name: "Jefferson County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.73421000",
    longitude: "-91.03718000",
  },
  {
    id: 119199,
    name: "Jefferson Davis County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.56964000",
    longitude: "-89.82300000",
  },
  {
    id: 119293,
    name: "Jones County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.62256000",
    longitude: "-89.16879000",
  },
  {
    id: 119306,
    name: "Jonestown",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.31955000",
    longitude: "-90.45565000",
  },
  {
    id: 119410,
    name: "Kearney Park",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.58903000",
    longitude: "-90.31537000",
  },
  {
    id: 119437,
    name: "Kemper County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.75456000",
    longitude: "-88.64116000",
  },
  {
    id: 119564,
    name: "Kiln",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.40908000",
    longitude: "-89.43505000",
  },
  {
    id: 119709,
    name: "Kosciusko",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.05800000",
    longitude: "-89.58956000",
  },
  {
    id: 119841,
    name: "Lafayette County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.35675000",
    longitude: "-89.48492000",
  },
  {
    id: 120063,
    name: "Lamar County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.20587000",
    longitude: "-89.50869000",
  },
  {
    id: 120067,
    name: "Lambert",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.20178000",
    longitude: "-90.28343000",
  },
  {
    id: 120172,
    name: "Latimer",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.53464000",
    longitude: "-88.86670000",
  },
  {
    id: 120180,
    name: "Lauderdale County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.40429000",
    longitude: "-88.66254000",
  },
  {
    id: 120190,
    name: "Laurel",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.69405000",
    longitude: "-89.13061000",
  },
  {
    id: 120235,
    name: "Lawrence County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.55020000",
    longitude: "-90.10699000",
  },
  {
    id: 120275,
    name: "Leake County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.75353000",
    longitude: "-89.52406000",
  },
  {
    id: 120276,
    name: "Leakesville",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.15574000",
    longitude: "-88.55780000",
  },
  {
    id: 120311,
    name: "Lee County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.28989000",
    longitude: "-88.68042000",
  },
  {
    id: 120331,
    name: "Leflore County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.55052000",
    longitude: "-90.30106000",
  },
  {
    id: 120349,
    name: "Leland",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.40539000",
    longitude: "-90.89760000",
  },
  {
    id: 120441,
    name: "Lexington",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.11318000",
    longitude: "-90.05314000",
  },
  {
    id: 120463,
    name: "Liberty",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.15823000",
    longitude: "-90.81232000",
  },
  {
    id: 120521,
    name: "Lincoln County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.53239000",
    longitude: "-90.45400000",
  },
  {
    id: 120735,
    name: "Long Beach",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.35048000",
    longitude: "-89.15282000",
  },
  {
    id: 120814,
    name: "Louisville",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.12374000",
    longitude: "-89.05506000",
  },
  {
    id: 120848,
    name: "Lowndes County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.47291000",
    longitude: "-88.44331000",
  },
  {
    id: 120864,
    name: "Lucedale",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.92519000",
    longitude: "-88.59002000",
  },
  {
    id: 120879,
    name: "Lumberton",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.00129000",
    longitude: "-89.45229000",
  },
  {
    id: 120908,
    name: "Lyman",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.49474000",
    longitude: "-89.12561000",
  },
  {
    id: 120914,
    name: "Lynchburg",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.96232000",
    longitude: "-90.09593000",
  },
  {
    id: 120966,
    name: "Macon",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.10540000",
    longitude: "-88.56088000",
  },
  {
    id: 120989,
    name: "Madison",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.46181000",
    longitude: "-90.11536000",
  },
  {
    id: 121009,
    name: "Madison County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.63466000",
    longitude: "-90.03376000",
  },
  {
    id: 121032,
    name: "Magee",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.87377000",
    longitude: "-89.73369000",
  },
  {
    id: 121036,
    name: "Magnolia",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.14323000",
    longitude: "-90.45871000",
  },
  {
    id: 121153,
    name: "Mantachie",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.32427000",
    longitude: "-88.49116000",
  },
  {
    id: 121237,
    name: "Marion",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.41736000",
    longitude: "-88.64782000",
  },
  {
    id: 121256,
    name: "Marion County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.23082000",
    longitude: "-89.82244000",
  },
  {
    id: 121272,
    name: "Marks",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.25683000",
    longitude: "-90.27298000",
  },
  {
    id: 121313,
    name: "Marshall County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.76225000",
    longitude: "-89.50305000",
  },
  {
    id: 121434,
    name: "Mayersville",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.90207000",
    longitude: "-91.05122000",
  },
  {
    id: 121474,
    name: "McComb",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.24379000",
    longitude: "-90.45315000",
  },
  {
    id: 121574,
    name: "Meadville",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.47239000",
    longitude: "-90.89677000",
  },
  {
    id: 121650,
    name: "Mendenhall",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.96182000",
    longitude: "-89.87008000",
  },
  {
    id: 121690,
    name: "Meridian",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.36431000",
    longitude: "-88.70366000",
  },
  {
    id: 121697,
    name: "Meridian Station",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.55049000",
    longitude: "-88.61849000",
  },
  {
    id: 121732,
    name: "Metcalfe",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.45400000",
    longitude: "-91.00733000",
  },
  {
    id: 121830,
    name: "Mikoma",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.95039000",
    longitude: "-90.28398000",
  },
  {
    id: 122075,
    name: "Monroe County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.89224000",
    longitude: "-88.48047000",
  },
  {
    id: 122147,
    name: "Montgomery County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.49410000",
    longitude: "-89.61640000",
  },
  {
    id: 122163,
    name: "Monticello",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.55378000",
    longitude: "-90.10731000",
  },
  {
    id: 122211,
    name: "Moorhead",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.45012000",
    longitude: "-90.50564000",
  },
  {
    id: 122252,
    name: "Morgantown",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.57267000",
    longitude: "-91.34761000",
  },
  {
    id: 122291,
    name: "Morton",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.35376000",
    longitude: "-89.65452000",
  },
  {
    id: 122309,
    name: "Moss Point",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.41159000",
    longitude: "-88.53446000",
  },
  {
    id: 122319,
    name: "Mound Bayou",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.87817000",
    longitude: "-90.72732000",
  },
  {
    id: 122587,
    name: "Natchez",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.56017000",
    longitude: "-91.40329000",
  },
  {
    id: 122621,
    name: "Nellieburg",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.40681000",
    longitude: "-88.77727000",
  },
  {
    id: 122641,
    name: "Neshoba County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.75350000",
    longitude: "-89.11757000",
  },
  {
    id: 122646,
    name: "Nettleton",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.08900000",
    longitude: "-88.62227000",
  },
  {
    id: 122655,
    name: "New Albany",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.49427000",
    longitude: "-89.00784000",
  },
  {
    id: 122657,
    name: "New Augusta",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.20263000",
    longitude: "-89.03668000",
  },
  {
    id: 122724,
    name: "New Hope",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.46817000",
    longitude: "-88.32670000",
  },
  {
    id: 122860,
    name: "Newton",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.32126000",
    longitude: "-89.16339000",
  },
  {
    id: 122871,
    name: "Newton County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.40023000",
    longitude: "-89.11881000",
  },
  {
    id: 122893,
    name: "Nicholson",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.47714000",
    longitude: "-89.69367000",
  },
  {
    id: 123116,
    name: "North Tunica",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.70093000",
    longitude: "-90.37815000",
  },
  {
    id: 123212,
    name: "Noxubee County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.11011000",
    longitude: "-88.56982000",
  },
  {
    id: 123337,
    name: "Ocean Springs",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.41131000",
    longitude: "-88.82781000",
  },
  {
    id: 123409,
    name: "Okolona",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.00178000",
    longitude: "-88.75533000",
  },
  {
    id: 123410,
    name: "Oktibbeha County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.42495000",
    longitude: "-88.87930000",
  },
  {
    id: 123439,
    name: "Olive Branch",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.96176000",
    longitude: "-89.82953000",
  },
  {
    id: 123678,
    name: "Oxford",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.36650000",
    longitude: "-89.51925000",
  },
  {
    id: 123803,
    name: "Panola County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.36394000",
    longitude: "-89.95057000",
  },
  {
    id: 123900,
    name: "Pascagoula",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.36576000",
    longitude: "-88.55613000",
  },
  {
    id: 123906,
    name: "Pass Christian",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.31575000",
    longitude: "-89.24754000",
  },
  {
    id: 123963,
    name: "Pearl",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.27459000",
    longitude: "-90.13203000",
  },
  {
    id: 123967,
    name: "Pearl River",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.78347000",
    longitude: "-89.22784000",
  },
  {
    id: 123969,
    name: "Pearl River County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.76858000",
    longitude: "-89.58978000",
  },
  {
    id: 123971,
    name: "Pearlington",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.24658000",
    longitude: "-89.61117000",
  },
  {
    id: 123987,
    name: "Pelahatchie",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.31292000",
    longitude: "-89.79841000",
  },
  {
    id: 124080,
    name: "Perry County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.17202000",
    longitude: "-88.99233000",
  },
  {
    id: 124100,
    name: "Petal",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.34656000",
    longitude: "-89.26006000",
  },
  {
    id: 124125,
    name: "Philadelphia",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.77152000",
    longitude: "-89.11673000",
  },
  {
    id: 124153,
    name: "Picayune",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.52556000",
    longitude: "-89.67788000",
  },
  {
    id: 124154,
    name: "Pickens",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.88374000",
    longitude: "-89.97147000",
  },
  {
    id: 124192,
    name: "Pike County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.17491000",
    longitude: "-90.40416000",
  },
  {
    id: 124290,
    name: "Pittsboro",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.94039000",
    longitude: "-89.33757000",
  },
  {
    id: 124348,
    name: "Plantersville",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.21344000",
    longitude: "-88.66450000",
  },
  {
    id: 124476,
    name: "Pontotoc",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.24788000",
    longitude: "-88.99867000",
  },
  {
    id: 124477,
    name: "Pontotoc County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.22544000",
    longitude: "-89.03741000",
  },
  {
    id: 124487,
    name: "Poplarville",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.84019000",
    longitude: "-89.53423000",
  },
  {
    id: 124507,
    name: "Port Gibson",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.96099000",
    longitude: "-90.98399000",
  },
  {
    id: 124640,
    name: "Prentiss",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.59850000",
    longitude: "-89.86702000",
  },
  {
    id: 124641,
    name: "Prentiss County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.61829000",
    longitude: "-88.52010000",
  },
  {
    id: 124758,
    name: "Purvis",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.14324000",
    longitude: "-89.40979000",
  },
  {
    id: 124806,
    name: "Quitman",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.04015000",
    longitude: "-88.72810000",
  },
  {
    id: 124809,
    name: "Quitman County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.25141000",
    longitude: "-90.28912000",
  },
  {
    id: 124830,
    name: "Raleigh",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.03349000",
    longitude: "-89.52229000",
  },
  {
    id: 124885,
    name: "Rankin County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.26412000",
    longitude: "-89.94580000",
  },
  {
    id: 124914,
    name: "Rawls Springs",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.38073000",
    longitude: "-89.37145000",
  },
  {
    id: 124917,
    name: "Raymond",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.25931000",
    longitude: "-90.42260000",
  },
  {
    id: 125069,
    name: "Richland",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.23904000",
    longitude: "-90.15842000",
  },
  {
    id: 125107,
    name: "Richton",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.34934000",
    longitude: "-88.94005000",
  },
  {
    id: 125123,
    name: "Ridgeland",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.42848000",
    longitude: "-90.13231000",
  },
  {
    id: 125162,
    name: "Ripley",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.72982000",
    longitude: "-88.95062000",
  },
  {
    id: 125365,
    name: "Rolling Fork",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.90652000",
    longitude: "-90.87816000",
  },
  {
    id: 125417,
    name: "Rosedale",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.85344000",
    longitude: "-91.02789000",
  },
  {
    id: 125506,
    name: "Ruleville",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.72595000",
    longitude: "-90.55148000",
  },
  {
    id: 125686,
    name: "Saint Martin",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.43798000",
    longitude: "-88.86809000",
  },
  {
    id: 125769,
    name: "Saltillo",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.37649000",
    longitude: "-88.68172000",
  },
  {
    id: 125948,
    name: "Sardis",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.43705000",
    longitude: "-89.91592000",
  },
  {
    id: 125958,
    name: "Saucier",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.63575000",
    longitude: "-89.13504000",
  },
  {
    id: 126048,
    name: "Scott County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.40638000",
    longitude: "-89.53764000",
  },
  {
    id: 126153,
    name: "Senatobia",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.61760000",
    longitude: "-89.96870000",
  },
  {
    id: 126219,
    name: "Shannon",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.11622000",
    longitude: "-88.71172000",
  },
  {
    id: 126226,
    name: "Sharkey County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.87972000",
    longitude: "-90.81321000",
  },
  {
    id: 126227,
    name: "Sharon",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.78960000",
    longitude: "-89.09867000",
  },
  {
    id: 126245,
    name: "Shaw",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.60211000",
    longitude: "-90.77458000",
  },
  {
    id: 126269,
    name: "Shelby",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.95094000",
    longitude: "-90.76788000",
  },
  {
    id: 126450,
    name: "Simpson County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.91317000",
    longitude: "-89.91949000",
  },
  {
    id: 126513,
    name: "Smith County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.01769000",
    longitude: "-89.50668000",
  },
  {
    id: 126760,
    name: "Southaven",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.98898000",
    longitude: "-90.01259000",
  },
  {
    id: 126989,
    name: "Starkville",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.45049000",
    longitude: "-88.81961000",
  },
  {
    id: 127082,
    name: "Stone County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.78995000",
    longitude: "-89.11771000",
  },
  {
    id: 127092,
    name: "Stonewall",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.13181000",
    longitude: "-88.79338000",
  },
  {
    id: 127211,
    name: "Summit",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.28379000",
    longitude: "-90.46843000",
  },
  {
    id: 127226,
    name: "Sumrall",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.41739000",
    longitude: "-89.54229000",
  },
  {
    id: 127251,
    name: "Sunflower",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.54290000",
    longitude: "-90.53703000",
  },
  {
    id: 127252,
    name: "Sunflower County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.60231000",
    longitude: "-90.58862000",
  },
  {
    id: 127392,
    name: "Tallahatchie County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.95047000",
    longitude: "-90.17326000",
  },
  {
    id: 127438,
    name: "Tate County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.65032000",
    longitude: "-89.94478000",
  },
  {
    id: 127464,
    name: "Taylorsville",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.82960000",
    longitude: "-89.42812000",
  },
  {
    id: 127472,
    name: "Tchula",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.18290000",
    longitude: "-90.22286000",
  },
  {
    id: 127530,
    name: "Terry",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.09626000",
    longitude: "-90.29426000",
  },
  {
    id: 127672,
    name: "Tippah County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.76840000",
    longitude: "-88.90890000",
  },
  {
    id: 127683,
    name: "Tishomingo County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.74043000",
    longitude: "-88.23932000",
  },
  {
    id: 127891,
    name: "Tunica",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.68455000",
    longitude: "-90.38288000",
  },
  {
    id: 127892,
    name: "Tunica County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.65194000",
    longitude: "-90.37551000",
  },
  {
    id: 127893,
    name: "Tunica Resorts",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.83613000",
    longitude: "-90.34723000",
  },
  {
    id: 127897,
    name: "Tupelo",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.25807000",
    longitude: "-88.70464000",
  },
  {
    id: 127919,
    name: "Tutwiler",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.01483000",
    longitude: "-90.43176000",
  },
  {
    id: 127944,
    name: "Tylertown",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.11601000",
    longitude: "-90.14203000",
  },
  {
    id: 127972,
    name: "Union",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.57153000",
    longitude: "-89.12145000",
  },
  {
    id: 127994,
    name: "Union County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.49047000",
    longitude: "-89.00386000",
  },
  {
    id: 128024,
    name: "University",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.36594000",
    longitude: "-89.52536000",
  },
  {
    id: 128143,
    name: "Vancleave",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.54047000",
    longitude: "-88.68752000",
  },
  {
    id: 128154,
    name: "Vardaman",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.87567000",
    longitude: "-89.17729000",
  },
  {
    id: 128201,
    name: "Verona",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.19427000",
    longitude: "-88.71977000",
  },
  {
    id: 128215,
    name: "Vicksburg",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.35265000",
    longitude: "-90.87788000",
  },
  {
    id: 128319,
    name: "Wade",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.64242000",
    longitude: "-88.56974000",
  },
  {
    id: 128412,
    name: "Walls",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.95824000",
    longitude: "-90.15256000",
  },
  {
    id: 128418,
    name: "Walnut Grove",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.59042000",
    longitude: "-89.45840000",
  },
  {
    id: 128430,
    name: "Walthall",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.60734000",
    longitude: "-89.27729000",
  },
  {
    id: 128431,
    name: "Walthall County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.14842000",
    longitude: "-90.10614000",
  },
  {
    id: 128489,
    name: "Warren County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.35723000",
    longitude: "-90.85201000",
  },
  {
    id: 128562,
    name: "Washington County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.28370000",
    longitude: "-90.94745000",
  },
  {
    id: 128600,
    name: "Water Valley",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.15150000",
    longitude: "-89.63147000",
  },
  {
    id: 128664,
    name: "Waveland",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.28686000",
    longitude: "-89.37616000",
  },
  {
    id: 128694,
    name: "Wayne County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.64078000",
    longitude: "-88.69580000",
  },
  {
    id: 128706,
    name: "Waynesboro",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.67488000",
    longitude: "-88.64615000",
  },
  {
    id: 128739,
    name: "Webster County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.61307000",
    longitude: "-89.28482000",
  },
  {
    id: 128814,
    name: "Wesson",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.70128000",
    longitude: "-90.39759000",
  },
  {
    id: 128882,
    name: "West Gulfport",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.40409000",
    longitude: "-89.09420000",
  },
  {
    id: 128886,
    name: "West Hattiesburg",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.31906000",
    longitude: "-89.37506000",
  },
  {
    id: 128959,
    name: "West Point",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.60762000",
    longitude: "-88.65033000",
  },
  {
    id: 129223,
    name: "Wiggins",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "30.85824000",
    longitude: "-89.13533000",
  },
  {
    id: 129251,
    name: "Wilkinson County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.16107000",
    longitude: "-91.31092000",
  },
  {
    id: 129422,
    name: "Winona",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.48207000",
    longitude: "-89.72814000",
  },
  {
    id: 129435,
    name: "Winston County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "33.08849000",
    longitude: "-89.03443000",
  },
  {
    id: 129571,
    name: "Woodville",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "31.10462000",
    longitude: "-91.29956000",
  },
  {
    id: 129656,
    name: "Yalobusha County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "34.02821000",
    longitude: "-89.70763000",
  },
  {
    id: 129673,
    name: "Yazoo City",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.85513000",
    longitude: "-90.40565000",
  },
  {
    id: 129674,
    name: "Yazoo County",
    state_id: 1430,
    state_code: "MS",
    country_id: 233,
    country_code: "US",
    latitude: "32.78031000",
    longitude: "-90.39642000",
  },
  {
    id: 111011,
    name: "Adair County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.19056000",
    longitude: "-92.60072000",
  },
  {
    id: 111045,
    name: "Adrian",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.39752000",
    longitude: "-94.35162000",
  },
  {
    id: 111048,
    name: "Advance",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.10455000",
    longitude: "-89.90953000",
  },
  {
    id: 111051,
    name: "Affton",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.55061000",
    longitude: "-90.33317000",
  },
  {
    id: 111107,
    name: "Albany",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.24861000",
    longitude: "-94.33107000",
  },
  {
    id: 111243,
    name: "Alton",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.69423000",
    longitude: "-91.39930000",
  },
  {
    id: 111310,
    name: "Anderson",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.65063000",
    longitude: "-94.44355000",
  },
  {
    id: 111327,
    name: "Andrew County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.98349000",
    longitude: "-94.80205000",
  },
  {
    id: 111398,
    name: "Appleton City",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.19058000",
    longitude: "-94.02939000",
  },
  {
    id: 111433,
    name: "Archie",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.48168000",
    longitude: "-94.35439000",
  },
  {
    id: 111484,
    name: "Arnold",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.43283000",
    longitude: "-90.37762000",
  },
  {
    id: 111511,
    name: "Ash Grove",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.31533000",
    longitude: "-93.58520000",
  },
  {
    id: 111529,
    name: "Ashland",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.77448000",
    longitude: "-92.25713000",
  },
  {
    id: 111569,
    name: "Atchison County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.43085000",
    longitude: "-95.42809000",
  },
  {
    id: 111637,
    name: "Audrain County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.21576000",
    longitude: "-91.84159000",
  },
  {
    id: 111657,
    name: "Aurora",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.97089000",
    longitude: "-93.71798000",
  },
  {
    id: 111672,
    name: "Ava",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.95200000",
    longitude: "-92.66045000",
  },
  {
    id: 111776,
    name: "Ballwin",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.59505000",
    longitude: "-90.54623000",
  },
  {
    id: 111832,
    name: "Barnhart",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.34422000",
    longitude: "-90.39345000",
  },
  {
    id: 111855,
    name: "Barry County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.70987000",
    longitude: "-93.82907000",
  },
  {
    id: 111867,
    name: "Barton County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.50230000",
    longitude: "-94.34711000",
  },
  {
    id: 111889,
    name: "Bates County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.25729000",
    longitude: "-94.34000000",
  },
  {
    id: 111909,
    name: "Battlefield",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.11561000",
    longitude: "-93.37019000",
  },
  {
    id: 112049,
    name: "Bel-Nor",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.70200000",
    longitude: "-90.31678000",
  },
  {
    id: 112050,
    name: "Bel-Ridge",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.70950000",
    longitude: "-90.32539000",
  },
  {
    id: 112078,
    name: "Belle",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.28588000",
    longitude: "-91.72044000",
  },
  {
    id: 112097,
    name: "Bellefontaine Neighbors",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.74033000",
    longitude: "-90.22650000",
  },
  {
    id: 112147,
    name: "Belton",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.81195000",
    longitude: "-94.53190000",
  },
  {
    id: 112195,
    name: "Benton",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.09783000",
    longitude: "-89.56258000",
  },
  {
    id: 112200,
    name: "Benton County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.29485000",
    longitude: "-93.28795000",
  },
  {
    id: 112221,
    name: "Berkeley",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.75450000",
    longitude: "-90.33123000",
  },
  {
    id: 112248,
    name: "Bernie",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.66894000",
    longitude: "-89.96870000",
  },
  {
    id: 112274,
    name: "Bethany",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.26833000",
    longitude: "-94.02829000",
  },
  {
    id: 112343,
    name: "Billings",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.06755000",
    longitude: "-93.55214000",
  },
  {
    id: 112366,
    name: "Bismarck",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.76922000",
    longitude: "-90.62485000",
  },
  {
    id: 112378,
    name: "Black Jack",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.79338000",
    longitude: "-90.26733000",
  },
  {
    id: 112435,
    name: "Bloomfield",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.88589000",
    longitude: "-89.92926000",
  },
  {
    id: 112477,
    name: "Blue Springs",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.01695000",
    longitude: "-94.28161000",
  },
  {
    id: 112517,
    name: "Bolivar",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.61448000",
    longitude: "-93.41047000",
  },
  {
    id: 112524,
    name: "Bollinger County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.32219000",
    longitude: "-90.02595000",
  },
  {
    id: 112539,
    name: "Bonne Terre",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.92311000",
    longitude: "-90.55540000",
  },
  {
    id: 112556,
    name: "Boone County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.99062000",
    longitude: "-92.30968000",
  },
  {
    id: 112568,
    name: "Boonville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.97364000",
    longitude: "-92.74324000",
  },
  {
    id: 112611,
    name: "Bourbon",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.15477000",
    longitude: "-91.24403000",
  },
  {
    id: 112630,
    name: "Bowling Green",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.34199000",
    longitude: "-91.19514000",
  },
  {
    id: 112695,
    name: "Branson",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.64367000",
    longitude: "-93.21851000",
  },
  {
    id: 112714,
    name: "Breckenridge Hills",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.71450000",
    longitude: "-90.36734000",
  },
  {
    id: 112727,
    name: "Brentwood",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.61755000",
    longitude: "-90.34928000",
  },
  {
    id: 112769,
    name: "Bridgeton",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.76700000",
    longitude: "-90.41151000",
  },
  {
    id: 112853,
    name: "Brookfield",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.78447000",
    longitude: "-93.07353000",
  },
  {
    id: 112973,
    name: "Buchanan County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.65986000",
    longitude: "-94.80616000",
  },
  {
    id: 112990,
    name: "Buckner",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.13251000",
    longitude: "-94.19856000",
  },
  {
    id: 113007,
    name: "Buffalo",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.64393000",
    longitude: "-93.09241000",
  },
  {
    id: 113108,
    name: "Butler",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.25863000",
    longitude: "-94.33051000",
  },
  {
    id: 113117,
    name: "Butler County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.71642000",
    longitude: "-90.40656000",
  },
  {
    id: 113142,
    name: "Byrnes Mill",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.43783000",
    longitude: "-90.58179000",
  },
  {
    id: 113153,
    name: "Cabool",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.12394000",
    longitude: "-92.10127000",
  },
  {
    id: 113184,
    name: "Caldwell County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.65575000",
    longitude: "-93.98280000",
  },
  {
    id: 113215,
    name: "California",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.62753000",
    longitude: "-92.56658000",
  },
  {
    id: 113225,
    name: "Callaway County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.83552000",
    longitude: "-91.92601000",
  },
  {
    id: 113238,
    name: "Calverton Park",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.76477000",
    longitude: "-90.31373000",
  },
  {
    id: 113269,
    name: "Camden County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.02704000",
    longitude: "-92.76605000",
  },
  {
    id: 113272,
    name: "Camdenton",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.00809000",
    longitude: "-92.74463000",
  },
  {
    id: 113273,
    name: "Cameron",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.74028000",
    longitude: "-94.24106000",
  },
  {
    id: 113297,
    name: "Campbell",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.49339000",
    longitude: "-90.07509000",
  },
  {
    id: 113348,
    name: "Canton",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.12504000",
    longitude: "-91.62516000",
  },
  {
    id: 113367,
    name: "Cape Girardeau",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.30588000",
    longitude: "-89.51815000",
  },
  {
    id: 113368,
    name: "Cape Girardeau County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.38404000",
    longitude: "-89.68445000",
  },
  {
    id: 113396,
    name: "Carl Junction",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.17672000",
    longitude: "-94.56551000",
  },
  {
    id: 113454,
    name: "Carroll County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.42698000",
    longitude: "-93.50518000",
  },
  {
    id: 113467,
    name: "Carrollton",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.35835000",
    longitude: "-93.49577000",
  },
  {
    id: 113481,
    name: "Carter County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.94127000",
    longitude: "-90.96231000",
  },
  {
    id: 113490,
    name: "Carterville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.14923000",
    longitude: "-94.44300000",
  },
  {
    id: 113491,
    name: "Carthage",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.17645000",
    longitude: "-94.31022000",
  },
  {
    id: 113499,
    name: "Caruthersville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.19312000",
    longitude: "-89.65564000",
  },
  {
    id: 113527,
    name: "Cass County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.64700000",
    longitude: "-94.35482000",
  },
  {
    id: 113539,
    name: "Cassville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.67701000",
    longitude: "-93.86881000",
  },
  {
    id: 113548,
    name: "Castle Point",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.75811000",
    longitude: "-90.24817000",
  },
  {
    id: 113604,
    name: "Cedar County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.72385000",
    longitude: "-93.85661000",
  },
  {
    id: 113613,
    name: "Cedar Hill",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.35339000",
    longitude: "-90.64124000",
  },
  {
    id: 113654,
    name: "Centerville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.43505000",
    longitude: "-90.95846000",
  },
  {
    id: 113686,
    name: "Centralia",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.21032000",
    longitude: "-92.13795000",
  },
  {
    id: 113709,
    name: "Chaffee",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.18005000",
    longitude: "-89.65509000",
  },
  {
    id: 113750,
    name: "Chariton County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.51508000",
    longitude: "-92.96262000",
  },
  {
    id: 113751,
    name: "Charlack",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.70255000",
    longitude: "-90.34345000",
  },
  {
    id: 113763,
    name: "Charleston",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.92089000",
    longitude: "-89.35063000",
  },
  {
    id: 113901,
    name: "Chesterfield",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.66311000",
    longitude: "-90.57707000",
  },
  {
    id: 113953,
    name: "Chillicothe",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.79529000",
    longitude: "-93.55244000",
  },
  {
    id: 113997,
    name: "Christian County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.96957000",
    longitude: "-93.18885000",
  },
  {
    id: 114081,
    name: "City of Saint Louis",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.62727000",
    longitude: "-90.19789000",
  },
  {
    id: 114126,
    name: "Clark County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.41036000",
    longitude: "-91.73840000",
  },
  {
    id: 114147,
    name: "Clarkson Valley",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.61839000",
    longitude: "-90.58929000",
  },
  {
    id: 114158,
    name: "Clarkton",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.45173000",
    longitude: "-89.96704000",
  },
  {
    id: 114181,
    name: "Clay County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.31052000",
    longitude: "-94.42087000",
  },
  {
    id: 114191,
    name: "Claycomo",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.20250000",
    longitude: "-94.49245000",
  },
  {
    id: 114199,
    name: "Clayton",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.64255000",
    longitude: "-90.32373000",
  },
  {
    id: 114251,
    name: "Clever",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.03033000",
    longitude: "-93.47297000",
  },
  {
    id: 114271,
    name: "Clinton",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.36863000",
    longitude: "-93.77827000",
  },
  {
    id: 114291,
    name: "Clinton County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.60178000",
    longitude: "-94.40459000",
  },
  {
    id: 114395,
    name: "Cole Camp",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.46002000",
    longitude: "-93.20270000",
  },
  {
    id: 114396,
    name: "Cole County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.50541000",
    longitude: "-92.28160000",
  },
  {
    id: 114468,
    name: "Columbia",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.95171000",
    longitude: "-92.33407000",
  },
  {
    id: 114535,
    name: "Concord",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.52450000",
    longitude: "-90.35734000",
  },
  {
    id: 114545,
    name: "Concordia",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.98335000",
    longitude: "-93.56855000",
  },
  {
    id: 114597,
    name: "Cool Valley",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.72783000",
    longitude: "-90.31011000",
  },
  {
    id: 114603,
    name: "Cooper County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.84354000",
    longitude: "-92.81012000",
  },
  {
    id: 114696,
    name: "Cottleville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.74616000",
    longitude: "-90.65401000",
  },
  {
    id: 114717,
    name: "Country Club Hills",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.72088000",
    longitude: "-90.27484000",
  },
  {
    id: 114719,
    name: "Country Club Village",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.83222000",
    longitude: "-94.82163000",
  },
  {
    id: 114768,
    name: "Crane",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.90534000",
    longitude: "-93.57158000",
  },
  {
    id: 114779,
    name: "Crawford County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.97638000",
    longitude: "-91.30396000",
  },
  {
    id: 114810,
    name: "Crestwood",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.55700000",
    longitude: "-90.38178000",
  },
  {
    id: 114816,
    name: "Creve Coeur",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.66089000",
    longitude: "-90.42262000",
  },
  {
    id: 114827,
    name: "Crocker",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.94893000",
    longitude: "-92.26378000",
  },
  {
    id: 114875,
    name: "Crystal City",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.22117000",
    longitude: "-90.37901000",
  },
  {
    id: 114885,
    name: "Cuba",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.06282000",
    longitude: "-91.40348000",
  },
  {
    id: 114967,
    name: "Dade County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.43204000",
    longitude: "-93.85029000",
  },
  {
    id: 114996,
    name: "Dallas County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.68041000",
    longitude: "-93.02366000",
  },
  {
    id: 115043,
    name: "Dardenne Prairie",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.76950000",
    longitude: "-90.72902000",
  },
  {
    id: 115071,
    name: "Daviess County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.96075000",
    longitude: "-93.98547000",
  },
  {
    id: 115117,
    name: "De Soto",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.13950000",
    longitude: "-90.55513000",
  },
  {
    id: 115130,
    name: "DeKalb County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.89318000",
    longitude: "-94.40471000",
  },
  {
    id: 115215,
    name: "Dellwood",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.74950000",
    longitude: "-90.28567000",
  },
  {
    id: 115244,
    name: "Dent County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.60663000",
    longitude: "-91.50788000",
  },
  {
    id: 115274,
    name: "Des Peres",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.60089000",
    longitude: "-90.43290000",
  },
  {
    id: 115286,
    name: "Desloge",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.87088000",
    longitude: "-90.52735000",
  },
  {
    id: 115307,
    name: "Dexter",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.79589000",
    longitude: "-89.95787000",
  },
  {
    id: 115365,
    name: "Dixon",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.99171000",
    longitude: "-92.09378000",
  },
  {
    id: 115397,
    name: "Doniphan",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.62089000",
    longitude: "-90.82346000",
  },
  {
    id: 115428,
    name: "Douglas County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.93260000",
    longitude: "-92.49881000",
  },
  {
    id: 115507,
    name: "Duenweg",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.08367000",
    longitude: "-94.41356000",
  },
  {
    id: 115541,
    name: "Dunklin County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.10597000",
    longitude: "-90.16576000",
  },
  {
    id: 115560,
    name: "Duquesne",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.07673000",
    longitude: "-94.45939000",
  },
  {
    id: 115694,
    name: "East Independence",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.09556000",
    longitude: "-94.35523000",
  },
  {
    id: 115740,
    name: "East Prairie",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.77978000",
    longitude: "-89.38563000",
  },
  {
    id: 115866,
    name: "Edina",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.16754000",
    longitude: "-92.17268000",
  },
  {
    id: 115923,
    name: "El Dorado Springs",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.87698000",
    longitude: "-94.02133000",
  },
  {
    id: 115950,
    name: "Eldon",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.34836000",
    longitude: "-92.58158000",
  },
  {
    id: 116034,
    name: "Ellisville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.59255000",
    longitude: "-90.58707000",
  },
  {
    id: 116069,
    name: "Elsberry",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.16672000",
    longitude: "-90.78096000",
  },
  {
    id: 116076,
    name: "Elvins",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.83672000",
    longitude: "-90.53290000",
  },
  {
    id: 116100,
    name: "Eminence",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.15060000",
    longitude: "-91.35764000",
  },
  {
    id: 116209,
    name: "Esther",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.85033000",
    longitude: "-90.49874000",
  },
  {
    id: 116233,
    name: "Eureka",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.50255000",
    longitude: "-90.62790000",
  },
  {
    id: 116270,
    name: "Excelsior Springs",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.33917000",
    longitude: "-94.22606000",
  },
  {
    id: 116281,
    name: "Fair Grove",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.38393000",
    longitude: "-93.15130000",
  },
  {
    id: 116413,
    name: "Farmington",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.78088000",
    longitude: "-90.42179000",
  },
  {
    id: 116439,
    name: "Fayette",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.14587000",
    longitude: "-92.68379000",
  },
  {
    id: 116474,
    name: "Fenton",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.51311000",
    longitude: "-90.43595000",
  },
  {
    id: 116480,
    name: "Ferguson",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.74422000",
    longitude: "-90.30539000",
  },
  {
    id: 116502,
    name: "Festus",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.22061000",
    longitude: "-90.39595000",
  },
  {
    id: 116550,
    name: "Flat River",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.85005000",
    longitude: "-90.51679000",
  },
  {
    id: 116597,
    name: "Florissant",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.78922000",
    longitude: "-90.32261000",
  },
  {
    id: 116684,
    name: "Forsyth",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.68506000",
    longitude: "-93.11990000",
  },
  {
    id: 116725,
    name: "Fort Leonard Wood",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.70573000",
    longitude: "-92.15717000",
  },
  {
    id: 116793,
    name: "Four Seasons",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.19809000",
    longitude: "-92.71102000",
  },
  {
    id: 116851,
    name: "Franklin County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.41114000",
    longitude: "-91.07499000",
  },
  {
    id: 116894,
    name: "Fredericktown",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.55978000",
    longitude: "-90.29401000",
  },
  {
    id: 116962,
    name: "Frontenac",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.63561000",
    longitude: "-90.41512000",
  },
  {
    id: 116986,
    name: "Fulton",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.84671000",
    longitude: "-91.94796000",
  },
  {
    id: 117016,
    name: "Gainesville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.60312000",
    longitude: "-92.42822000",
  },
  {
    id: 117023,
    name: "Galena",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.80534000",
    longitude: "-93.46658000",
  },
  {
    id: 117032,
    name: "Gallatin",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.91445000",
    longitude: "-93.96217000",
  },
  {
    id: 117055,
    name: "Garden City",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.56112000",
    longitude: "-94.19133000",
  },
  {
    id: 117110,
    name: "Gasconade County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.44087000",
    longitude: "-91.50793000",
  },
  {
    id: 117151,
    name: "Gentry County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.21211000",
    longitude: "-94.40992000",
  },
  {
    id: 117168,
    name: "Gerald",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.39977000",
    longitude: "-91.33071000",
  },
  {
    id: 117196,
    name: "Gideon",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.45201000",
    longitude: "-89.91926000",
  },
  {
    id: 117236,
    name: "Gladstone",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.20389000",
    longitude: "-94.55468000",
  },
  {
    id: 117247,
    name: "Glasgow",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.22725000",
    longitude: "-92.84658000",
  },
  {
    id: 117250,
    name: "Glasgow Village",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.75366000",
    longitude: "-90.19844000",
  },
  {
    id: 117280,
    name: "Glendale",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.59589000",
    longitude: "-90.37706000",
  },
  {
    id: 117387,
    name: "Goodman",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.74174000",
    longitude: "-94.39911000",
  },
  {
    id: 117423,
    name: "Gower",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.61083000",
    longitude: "-94.59940000",
  },
  {
    id: 117440,
    name: "Grain Valley",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.01501000",
    longitude: "-94.19856000",
  },
  {
    id: 117446,
    name: "Granby",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.91923000",
    longitude: "-94.25522000",
  },
  {
    id: 117479,
    name: "Grandview",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.88584000",
    longitude: "-94.53301000",
  },
  {
    id: 117506,
    name: "Grant City",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.48749000",
    longitude: "-94.41107000",
  },
  {
    id: 117555,
    name: "Gray Summit",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.48978000",
    longitude: "-90.81680000",
  },
  {
    id: 117605,
    name: "Green Park",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.52366000",
    longitude: "-90.33845000",
  },
  {
    id: 117636,
    name: "Greene County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.25805000",
    longitude: "-93.34199000",
  },
  {
    id: 117647,
    name: "Greenfield",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.41532000",
    longitude: "-93.84104000",
  },
  {
    id: 117685,
    name: "Greenville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.12727000",
    longitude: "-90.45011000",
  },
  {
    id: 117703,
    name: "Greenwood",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.85168000",
    longitude: "-94.34384000",
  },
  {
    id: 117773,
    name: "Grundy County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.11393000",
    longitude: "-93.56534000",
  },
  {
    id: 117875,
    name: "Hallsville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.11699000",
    longitude: "-92.22074000",
  },
  {
    id: 117889,
    name: "Hamilton",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.74362000",
    longitude: "-93.99827000",
  },
  {
    id: 117961,
    name: "Hanley Hills",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.68588000",
    longitude: "-90.32373000",
  },
  {
    id: 117965,
    name: "Hannibal",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.70838000",
    longitude: "-91.35848000",
  },
  {
    id: 118068,
    name: "Harrison County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.35467000",
    longitude: "-93.99206000",
  },
  {
    id: 118071,
    name: "Harrisonville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.65334000",
    longitude: "-94.34884000",
  },
  {
    id: 118106,
    name: "Hartville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.25088000",
    longitude: "-92.51044000",
  },
  {
    id: 118194,
    name: "Hayti",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.23368000",
    longitude: "-89.74953000",
  },
  {
    id: 118207,
    name: "Hazelwood",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.77144000",
    longitude: "-90.37095000",
  },
  {
    id: 118301,
    name: "Henry County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.38516000",
    longitude: "-93.79275000",
  },
  {
    id: 118313,
    name: "Herculaneum",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.26839000",
    longitude: "-90.38012000",
  },
  {
    id: 118325,
    name: "Hermann",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.70421000",
    longitude: "-91.43738000",
  },
  {
    id: 118328,
    name: "Hermitage",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.94142000",
    longitude: "-93.31631000",
  },
  {
    id: 118366,
    name: "Hickory County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.94079000",
    longitude: "-93.32072000",
  },
  {
    id: 118384,
    name: "Higginsville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.07251000",
    longitude: "-93.71716000",
  },
  {
    id: 118388,
    name: "High Ridge",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.45894000",
    longitude: "-90.53651000",
  },
  {
    id: 118447,
    name: "Hillsboro",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.23228000",
    longitude: "-90.56290000",
  },
  {
    id: 118459,
    name: "Hillsdale",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.68338000",
    longitude: "-90.28400000",
  },
  {
    id: 118522,
    name: "Holden",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.71418000",
    longitude: "-93.99133000",
  },
  {
    id: 118551,
    name: "Hollister",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.62117000",
    longitude: "-93.21546000",
  },
  {
    id: 118576,
    name: "Holt County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.09443000",
    longitude: "-95.21551000",
  },
  {
    id: 118579,
    name: "Holts Summit",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.64032000",
    longitude: "-92.12241000",
  },
  {
    id: 118697,
    name: "Houston",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.32616000",
    longitude: "-91.95599000",
  },
  {
    id: 118714,
    name: "Howard County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.14250000",
    longitude: "-92.69627000",
  },
  {
    id: 118723,
    name: "Howell County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.77401000",
    longitude: "-91.88654000",
  },
  {
    id: 118773,
    name: "Humansville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.79448000",
    longitude: "-93.57798000",
  },
  {
    id: 118816,
    name: "Huntsville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.44059000",
    longitude: "-92.54518000",
  },
  {
    id: 118881,
    name: "Imperial",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.36978000",
    longitude: "-90.37845000",
  },
  {
    id: 118892,
    name: "Independence",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.09112000",
    longitude: "-94.41551000",
  },
  {
    id: 118988,
    name: "Iron County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.50426000",
    longitude: "-90.69003000",
  },
  {
    id: 118997,
    name: "Ironton",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.59727000",
    longitude: "-90.62734000",
  },
  {
    id: 119064,
    name: "Jackson",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.38227000",
    longitude: "-89.66621000",
  },
  {
    id: 119083,
    name: "Jackson County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.00850000",
    longitude: "-94.34609000",
  },
  {
    id: 119147,
    name: "Jasper County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.20355000",
    longitude: "-94.34061000",
  },
  {
    id: 119173,
    name: "Jefferson City",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.57670000",
    longitude: "-92.17352000",
  },
  {
    id: 119183,
    name: "Jefferson County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.26107000",
    longitude: "-90.53769000",
  },
  {
    id: 119219,
    name: "Jennings",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.71922000",
    longitude: "-90.26039000",
  },
  {
    id: 119266,
    name: "Johnson County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.74409000",
    longitude: "-93.80637000",
  },
  {
    id: 119313,
    name: "Joplin",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.08423000",
    longitude: "-94.51328000",
  },
  {
    id: 119353,
    name: "Kahoka",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.42032000",
    longitude: "-91.71961000",
  },
  {
    id: 119384,
    name: "Kansas City",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.09973000",
    longitude: "-94.57857000",
  },
  {
    id: 119407,
    name: "Kearney",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.36778000",
    longitude: "-94.36217000",
  },
  {
    id: 119473,
    name: "Kennett",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.23618000",
    longitude: "-90.05565000",
  },
  {
    id: 119549,
    name: "Keytesville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.43447000",
    longitude: "-92.93825000",
  },
  {
    id: 119568,
    name: "Kimberling City",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.63340000",
    longitude: "-93.41685000",
  },
  {
    id: 119578,
    name: "King City",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.05138000",
    longitude: "-94.52412000",
  },
  {
    id: 119620,
    name: "Kingston",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.64417000",
    longitude: "-94.03855000",
  },
  {
    id: 119653,
    name: "Kirksville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.19475000",
    longitude: "-92.58325000",
  },
  {
    id: 119654,
    name: "Kirkwood",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.58339000",
    longitude: "-90.40678000",
  },
  {
    id: 119657,
    name: "Kissee Mills",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.68367000",
    longitude: "-93.04990000",
  },
  {
    id: 119678,
    name: "Knob Noster",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.76668000",
    longitude: "-93.55855000",
  },
  {
    id: 119691,
    name: "Knox County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.12825000",
    longitude: "-92.14807000",
  },
  {
    id: 119768,
    name: "La Monte",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.77418000",
    longitude: "-93.42521000",
  },
  {
    id: 119774,
    name: "La Plata",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.02337000",
    longitude: "-92.49158000",
  },
  {
    id: 119797,
    name: "LaBarque Creek",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.41701000",
    longitude: "-90.67989000",
  },
  {
    id: 119816,
    name: "Laclede County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.65832000",
    longitude: "-92.59035000",
  },
  {
    id: 119828,
    name: "Ladue",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.64977000",
    longitude: "-90.38067000",
  },
  {
    id: 119840,
    name: "Lafayette County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.06559000",
    longitude: "-93.78554000",
  },
  {
    id: 119932,
    name: "Lake Lotawana",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.92306000",
    longitude: "-94.24411000",
  },
  {
    id: 119956,
    name: "Lake Ozark",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.19864000",
    longitude: "-92.63880000",
  },
  {
    id: 119972,
    name: "Lake Saint Louis",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.79755000",
    longitude: "-90.78568000",
  },
  {
    id: 119994,
    name: "Lake Winnebago",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.83140000",
    longitude: "-94.35856000",
  },
  {
    id: 120027,
    name: "Lakeshire",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.53866000",
    longitude: "-90.33512000",
  },
  {
    id: 120059,
    name: "Lamar",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.49505000",
    longitude: "-94.27661000",
  },
  {
    id: 120085,
    name: "Lancaster",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.52086000",
    longitude: "-92.52797000",
  },
  {
    id: 120169,
    name: "Lathrop",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.54834000",
    longitude: "-94.32995000",
  },
  {
    id: 120234,
    name: "Lawrence County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.10635000",
    longitude: "-93.83294000",
  },
  {
    id: 120247,
    name: "Lawson",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.43834000",
    longitude: "-94.20411000",
  },
  {
    id: 120273,
    name: "Leadwood",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.86727000",
    longitude: "-90.59318000",
  },
  {
    id: 120286,
    name: "Lebanon",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.68060000",
    longitude: "-92.66379000",
  },
  {
    id: 120318,
    name: "Lee's Summit",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.91084000",
    longitude: "-94.38217000",
  },
  {
    id: 120355,
    name: "Lemay",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.53339000",
    longitude: "-90.27928000",
  },
  {
    id: 120414,
    name: "Lewis County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.09690000",
    longitude: "-91.72214000",
  },
  {
    id: 120440,
    name: "Lexington",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.18473000",
    longitude: "-93.87994000",
  },
  {
    id: 120461,
    name: "Liberty",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.24611000",
    longitude: "-94.41912000",
  },
  {
    id: 120477,
    name: "Licking",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.49949000",
    longitude: "-91.85710000",
  },
  {
    id: 120484,
    name: "Lilbourn",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.59228000",
    longitude: "-89.61536000",
  },
  {
    id: 120502,
    name: "Lincoln",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.39086000",
    longitude: "-93.33465000",
  },
  {
    id: 120520,
    name: "Lincoln County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.05802000",
    longitude: "-90.96005000",
  },
  {
    id: 120580,
    name: "Linn",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.48587000",
    longitude: "-91.85045000",
  },
  {
    id: 120582,
    name: "Linn County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.87021000",
    longitude: "-93.10718000",
  },
  {
    id: 120585,
    name: "Linneus",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.87863000",
    longitude: "-93.18882000",
  },
  {
    id: 120655,
    name: "Livingston County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.78211000",
    longitude: "-93.54828000",
  },
  {
    id: 120727,
    name: "Lone Jack",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.87084000",
    longitude: "-94.17383000",
  },
  {
    id: 120810,
    name: "Louisiana",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.44894000",
    longitude: "-91.05153000",
  },
  {
    id: 120965,
    name: "Macon",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.74226000",
    longitude: "-92.47269000",
  },
  {
    id: 120970,
    name: "Macon County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.83080000",
    longitude: "-92.56461000",
  },
  {
    id: 121008,
    name: "Madison County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.47810000",
    longitude: "-90.34503000",
  },
  {
    id: 121059,
    name: "Malden",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.55700000",
    longitude: "-89.96648000",
  },
  {
    id: 121092,
    name: "Manchester",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.59700000",
    longitude: "-90.50929000",
  },
  {
    id: 121143,
    name: "Mansfield",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.10672000",
    longitude: "-92.58072000",
  },
  {
    id: 121177,
    name: "Maplewood",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.61255000",
    longitude: "-90.32456000",
  },
  {
    id: 121188,
    name: "Marble Hill",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.30589000",
    longitude: "-89.97038000",
  },
  {
    id: 121193,
    name: "Marceline",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.71197000",
    longitude: "-92.94825000",
  },
  {
    id: 121215,
    name: "Maries County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.16163000",
    longitude: "-91.92489000",
  },
  {
    id: 121255,
    name: "Marion County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.80596000",
    longitude: "-91.62235000",
  },
  {
    id: 121263,
    name: "Marionville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.00311000",
    longitude: "-93.63742000",
  },
  {
    id: 121279,
    name: "Marlborough",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.57033000",
    longitude: "-90.33706000",
  },
  {
    id: 121303,
    name: "Marshall",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.12308000",
    longitude: "-93.19687000",
  },
  {
    id: 121325,
    name: "Marshfield",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.33866000",
    longitude: "-92.90712000",
  },
  {
    id: 121334,
    name: "Marthasville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.62838000",
    longitude: "-91.05764000",
  },
  {
    id: 121356,
    name: "Maryland Heights",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.71311000",
    longitude: "-90.42984000",
  },
  {
    id: 121365,
    name: "Maryville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.34610000",
    longitude: "-94.87247000",
  },
  {
    id: 121449,
    name: "Maysville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.88917000",
    longitude: "-94.36190000",
  },
  {
    id: 121492,
    name: "McDonald County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.62867000",
    longitude: "-94.34836000",
  },
  {
    id: 121616,
    name: "Mehlville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.50839000",
    longitude: "-90.32289000",
  },
  {
    id: 121641,
    name: "Memphis",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.45781000",
    longitude: "-92.17129000",
  },
  {
    id: 121678,
    name: "Mercer County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.42233000",
    longitude: "-93.56856000",
  },
  {
    id: 121703,
    name: "Merriam Woods",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.71395000",
    longitude: "-93.16185000",
  },
  {
    id: 121741,
    name: "Mexico",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.16976000",
    longitude: "-91.88295000",
  },
  {
    id: 121839,
    name: "Milan",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.20224000",
    longitude: "-93.12521000",
  },
  {
    id: 121882,
    name: "Miller County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.21453000",
    longitude: "-92.42841000",
  },
  {
    id: 121990,
    name: "Mississippi County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.82810000",
    longitude: "-89.29118000",
  },
  {
    id: 122009,
    name: "Moberly",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.41837000",
    longitude: "-92.43824000",
  },
  {
    id: 122028,
    name: "Moline Acres",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.74699000",
    longitude: "-90.24011000",
  },
  {
    id: 122039,
    name: "Monett",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.92895000",
    longitude: "-93.92771000",
  },
  {
    id: 122041,
    name: "Moniteau County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.63275000",
    longitude: "-92.58305000",
  },
  {
    id: 122066,
    name: "Monroe City",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.65365000",
    longitude: "-91.73461000",
  },
  {
    id: 122074,
    name: "Monroe County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.49546000",
    longitude: "-92.00074000",
  },
  {
    id: 122138,
    name: "Montgomery City",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.97754000",
    longitude: "-91.50488000",
  },
  {
    id: 122146,
    name: "Montgomery County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.94149000",
    longitude: "-91.47021000",
  },
  {
    id: 122168,
    name: "Monticello",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.11838000",
    longitude: "-91.71211000",
  },
  {
    id: 122241,
    name: "Morgan County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.42374000",
    longitude: "-92.88598000",
  },
  {
    id: 122301,
    name: "Moscow Mills",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.94783000",
    longitude: "-90.91819000",
  },
  {
    id: 122322,
    name: "Mound City",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.13111000",
    longitude: "-95.23164000",
  },
  {
    id: 122398,
    name: "Mount Vernon",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.10367000",
    longitude: "-93.81854000",
  },
  {
    id: 122414,
    name: "Mountain Grove",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.13061000",
    longitude: "-92.26349000",
  },
  {
    id: 122429,
    name: "Mountain View",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.99533000",
    longitude: "-91.70376000",
  },
  {
    id: 122484,
    name: "Murphy",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.49033000",
    longitude: "-90.48707000",
  },
  {
    id: 122633,
    name: "Neosho",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.86896000",
    longitude: "-94.36800000",
  },
  {
    id: 122648,
    name: "Nevada",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.83921000",
    longitude: "-94.35467000",
  },
  {
    id: 122706,
    name: "New Franklin",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.01725000",
    longitude: "-92.73741000",
  },
  {
    id: 122714,
    name: "New Haven",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.60838000",
    longitude: "-91.21904000",
  },
  {
    id: 122740,
    name: "New London",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.58532000",
    longitude: "-91.40098000",
  },
  {
    id: 122747,
    name: "New Madrid",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.58645000",
    longitude: "-89.52785000",
  },
  {
    id: 122748,
    name: "New Madrid County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.59465000",
    longitude: "-89.65183000",
  },
  {
    id: 122870,
    name: "Newton County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.90551000",
    longitude: "-94.33925000",
  },
  {
    id: 122912,
    name: "Nixa",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.04339000",
    longitude: "-93.29435000",
  },
  {
    id: 122924,
    name: "Nodaway County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.36077000",
    longitude: "-94.88343000",
  },
  {
    id: 122926,
    name: "Noel",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.54563000",
    longitude: "-94.48522000",
  },
  {
    id: 122951,
    name: "Normandy",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.72088000",
    longitude: "-90.29734000",
  },
  {
    id: 123045,
    name: "North Kansas City",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.13000000",
    longitude: "-94.56218000",
  },
  {
    id: 123181,
    name: "Northwoods",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.70422000",
    longitude: "-90.28345000",
  },
  {
    id: 123227,
    name: "O'Fallon",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.81061000",
    longitude: "-90.69985000",
  },
  {
    id: 123236,
    name: "Oak Grove",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.00501000",
    longitude: "-94.12939000",
  },
  {
    id: 123283,
    name: "Oakland",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.57644000",
    longitude: "-90.38567000",
  },
  {
    id: 123306,
    name: "Oakville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.47005000",
    longitude: "-90.30456000",
  },
  {
    id: 123359,
    name: "Odessa",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.99917000",
    longitude: "-93.95356000",
  },
  {
    id: 123421,
    name: "Old Jamestown",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.83494000",
    longitude: "-90.28511000",
  },
  {
    id: 123449,
    name: "Olivette",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.66533000",
    longitude: "-90.37595000",
  },
  {
    id: 123504,
    name: "Oran",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.08505000",
    longitude: "-89.65536000",
  },
  {
    id: 123545,
    name: "Oregon",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.98694000",
    longitude: "-95.14498000",
  },
  {
    id: 123549,
    name: "Oregon County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.68672000",
    longitude: "-91.40329000",
  },
  {
    id: 123576,
    name: "Oronogo",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.18839000",
    longitude: "-94.47023000",
  },
  {
    id: 123587,
    name: "Osage Beach",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.12956000",
    longitude: "-92.65277000",
  },
  {
    id: 123590,
    name: "Osage County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.46037000",
    longitude: "-91.86184000",
  },
  {
    id: 123598,
    name: "Osceola",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.04670000",
    longitude: "-93.70438000",
  },
  {
    id: 123650,
    name: "Overland",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.70116000",
    longitude: "-90.36234000",
  },
  {
    id: 123666,
    name: "Owensville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.34560000",
    longitude: "-91.50155000",
  },
  {
    id: 123698,
    name: "Ozark",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.02089000",
    longitude: "-93.20602000",
  },
  {
    id: 123699,
    name: "Ozark County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.64932000",
    longitude: "-92.44466000",
  },
  {
    id: 123706,
    name: "Pacific",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.48200000",
    longitude: "-90.74152000",
  },
  {
    id: 123720,
    name: "Pagedale",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.68338000",
    longitude: "-90.30761000",
  },
  {
    id: 123772,
    name: "Palmyra",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.79421000",
    longitude: "-91.52321000",
  },
  {
    id: 123832,
    name: "Paris",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.48087000",
    longitude: "-92.00128000",
  },
  {
    id: 123848,
    name: "Park Hills",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.85422000",
    longitude: "-90.51818000",
  },
  {
    id: 123878,
    name: "Parkville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.19500000",
    longitude: "-94.68218000",
  },
  {
    id: 123982,
    name: "Peculiar",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.71918000",
    longitude: "-94.45856000",
  },
  {
    id: 124010,
    name: "Pemiscot County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.21145000",
    longitude: "-89.78538000",
  },
  {
    id: 124079,
    name: "Perry County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.70714000",
    longitude: "-89.82441000",
  },
  {
    id: 124089,
    name: "Perryville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.72422000",
    longitude: "-89.86122000",
  },
  {
    id: 124113,
    name: "Pettis County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.72829000",
    longitude: "-93.28510000",
  },
  {
    id: 124114,
    name: "Pevely",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.28339000",
    longitude: "-90.39512000",
  },
  {
    id: 124121,
    name: "Phelps County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.87713000",
    longitude: "-91.79236000",
  },
  {
    id: 124165,
    name: "Piedmont",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.15449000",
    longitude: "-90.69567000",
  },
  {
    id: 124170,
    name: "Pierce City",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.94590000",
    longitude: "-94.00021000",
  },
  {
    id: 124191,
    name: "Pike County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.34384000",
    longitude: "-91.17136000",
  },
  {
    id: 124234,
    name: "Pine Lawn",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.69588000",
    longitude: "-90.27511000",
  },
  {
    id: 124261,
    name: "Pineville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.59452000",
    longitude: "-94.38410000",
  },
  {
    id: 124352,
    name: "Platte City",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.37028000",
    longitude: "-94.78246000",
  },
  {
    id: 124353,
    name: "Platte County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.38050000",
    longitude: "-94.77359000",
  },
  {
    id: 124359,
    name: "Plattsburg",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.56555000",
    longitude: "-94.44801000",
  },
  {
    id: 124368,
    name: "Pleasant Hill",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.78751000",
    longitude: "-94.26939000",
  },
  {
    id: 124377,
    name: "Pleasant Valley",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.21639000",
    longitude: "-94.48412000",
  },
  {
    id: 124443,
    name: "Polk County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.61648000",
    longitude: "-93.40053000",
  },
  {
    id: 124484,
    name: "Poplar Bluff",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.75700000",
    longitude: "-90.39289000",
  },
  {
    id: 124549,
    name: "Portageville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.42534000",
    longitude: "-89.69953000",
  },
  {
    id: 124586,
    name: "Potosi",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.93644000",
    longitude: "-90.78791000",
  },
  {
    id: 124690,
    name: "Princeton",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.40084000",
    longitude: "-93.58050000",
  },
  {
    id: 124741,
    name: "Pulaski County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.82463000",
    longitude: "-92.20766000",
  },
  {
    id: 124756,
    name: "Purdy",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.81729000",
    longitude: "-93.92076000",
  },
  {
    id: 124767,
    name: "Putnam County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.47891000",
    longitude: "-93.01613000",
  },
  {
    id: 124835,
    name: "Ralls County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.52767000",
    longitude: "-91.52202000",
  },
  {
    id: 124876,
    name: "Randolph County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.44017000",
    longitude: "-92.49708000",
  },
  {
    id: 124916,
    name: "Ray County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.35241000",
    longitude: "-93.98988000",
  },
  {
    id: 124922,
    name: "Raymore",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.80195000",
    longitude: "-94.45273000",
  },
  {
    id: 124926,
    name: "Raytown",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.00862000",
    longitude: "-94.46356000",
  },
  {
    id: 125030,
    name: "Republic",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.12005000",
    longitude: "-93.48019000",
  },
  {
    id: 125040,
    name: "Reynolds County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.36233000",
    longitude: "-90.96908000",
  },
  {
    id: 125057,
    name: "Rich Hill",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.09642000",
    longitude: "-94.36106000",
  },
  {
    id: 125068,
    name: "Richland",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.85698000",
    longitude: "-92.40434000",
  },
  {
    id: 125086,
    name: "Richmond",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.27862000",
    longitude: "-93.97689000",
  },
  {
    id: 125103,
    name: "Richmond Heights",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.62866000",
    longitude: "-90.31956000",
  },
  {
    id: 125166,
    name: "Ripley County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.65282000",
    longitude: "-90.86388000",
  },
  {
    id: 125203,
    name: "Riverside",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.17750000",
    longitude: "-94.61301000",
  },
  {
    id: 125218,
    name: "Riverview",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.74783000",
    longitude: "-90.21150000",
  },
  {
    id: 125279,
    name: "Rock Hill",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.60755000",
    longitude: "-90.37845000",
  },
  {
    id: 125285,
    name: "Rock Port",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.41111000",
    longitude: "-95.51693000",
  },
  {
    id: 125355,
    name: "Rogersville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.11700000",
    longitude: "-93.05573000",
  },
  {
    id: 125363,
    name: "Rolla",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.95143000",
    longitude: "-91.77127000",
  },
  {
    id: 125598,
    name: "Saint Ann",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.72727000",
    longitude: "-90.38317000",
  },
  {
    id: 125612,
    name: "Saint Charles",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.78394000",
    longitude: "-90.48123000",
  },
  {
    id: 125616,
    name: "Saint Charles County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.78192000",
    longitude: "-90.67490000",
  },
  {
    id: 125618,
    name: "Saint Clair",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.34533000",
    longitude: "-90.98097000",
  },
  {
    id: 125623,
    name: "Saint Clair County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.03718000",
    longitude: "-93.77598000",
  },
  {
    id: 125638,
    name: "Saint Francois County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.81028000",
    longitude: "-90.47227000",
  },
  {
    id: 125641,
    name: "Saint George",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.53672000",
    longitude: "-90.31484000",
  },
  {
    id: 125653,
    name: "Saint James",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.99726000",
    longitude: "-91.61432000",
  },
  {
    id: 125661,
    name: "Saint John",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.71484000",
    longitude: "-90.34627000",
  },
  {
    id: 125664,
    name: "Saint Johns",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.71338000",
    longitude: "-90.34317000",
  },
  {
    id: 125671,
    name: "Saint Joseph",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.76861000",
    longitude: "-94.84663000",
  },
  {
    id: 125681,
    name: "Saint Louis County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.64068000",
    longitude: "-90.44341000",
  },
  {
    id: 125688,
    name: "Saint Martins",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.59420000",
    longitude: "-92.33713000",
  },
  {
    id: 125701,
    name: "Saint Paul",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.86144000",
    longitude: "-90.74179000",
  },
  {
    id: 125710,
    name: "Saint Peters",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.80033000",
    longitude: "-90.62651000",
  },
  {
    id: 125712,
    name: "Saint Robert",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.82810000",
    longitude: "-92.17767000",
  },
  {
    id: 125719,
    name: "Sainte Genevieve",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.98144000",
    longitude: "-90.04178000",
  },
  {
    id: 125720,
    name: "Sainte Genevieve County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.89440000",
    longitude: "-90.19442000",
  },
  {
    id: 125730,
    name: "Salem",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.64560000",
    longitude: "-91.53598000",
  },
  {
    id: 125752,
    name: "Saline County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.13684000",
    longitude: "-93.20185000",
  },
  {
    id: 125755,
    name: "Salisbury",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.42392000",
    longitude: "-92.80158000",
  },
  {
    id: 125932,
    name: "Sappington",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.53700000",
    longitude: "-90.37984000",
  },
  {
    id: 125946,
    name: "Sarcoxie",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.06923000",
    longitude: "-94.11660000",
  },
  {
    id: 125975,
    name: "Savannah",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.94166000",
    longitude: "-94.83025000",
  },
  {
    id: 126023,
    name: "Schuyler County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.47027000",
    longitude: "-92.52094000",
  },
  {
    id: 126037,
    name: "Scotland County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.45260000",
    longitude: "-92.14705000",
  },
  {
    id: 126041,
    name: "Scott City",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.21672000",
    longitude: "-89.52453000",
  },
  {
    id: 126047,
    name: "Scott County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.05305000",
    longitude: "-89.56851000",
  },
  {
    id: 126114,
    name: "Sedalia",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.70446000",
    longitude: "-93.22826000",
  },
  {
    id: 126152,
    name: "Senath",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.13423000",
    longitude: "-90.15982000",
  },
  {
    id: 126155,
    name: "Seneca",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.84146000",
    longitude: "-94.61106000",
  },
  {
    id: 126193,
    name: "Seymour",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.14644000",
    longitude: "-92.76878000",
  },
  {
    id: 126220,
    name: "Shannon County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.15739000",
    longitude: "-91.40051000",
  },
  {
    id: 126263,
    name: "Shelbina",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.69393000",
    longitude: "-92.04295000",
  },
  {
    id: 126276,
    name: "Shelby County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.79778000",
    longitude: "-92.07662000",
  },
  {
    id: 126283,
    name: "Shelbyville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.80587000",
    longitude: "-92.04156000",
  },
  {
    id: 126289,
    name: "Shell Knob",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.63229000",
    longitude: "-93.63436000",
  },
  {
    id: 126383,
    name: "Shrewsbury",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.59033000",
    longitude: "-90.33678000",
  },
  {
    id: 126414,
    name: "Sikeston",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.87672000",
    longitude: "-89.58786000",
  },
  {
    id: 126489,
    name: "Slater",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.21808000",
    longitude: "-93.06909000",
  },
  {
    id: 126528,
    name: "Smithville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.38694000",
    longitude: "-94.58107000",
  },
  {
    id: 126800,
    name: "Spanish Lake",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.78783000",
    longitude: "-90.21594000",
  },
  {
    id: 126809,
    name: "Sparta",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.00116000",
    longitude: "-93.08157000",
  },
  {
    id: 126893,
    name: "Springfield",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.21533000",
    longitude: "-93.29824000",
  },
  {
    id: 126928,
    name: "St. Louis",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.62727000",
    longitude: "-90.19789000",
  },
  {
    id: 126949,
    name: "Stanberry",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.21777000",
    longitude: "-94.53829000",
  },
  {
    id: 127008,
    name: "Steele",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.08396000",
    longitude: "-89.82925000",
  },
  {
    id: 127014,
    name: "Steelville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.96810000",
    longitude: "-91.35487000",
  },
  {
    id: 127072,
    name: "Stockton",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.69893000",
    longitude: "-93.79604000",
  },
  {
    id: 127077,
    name: "Stoddard County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.85562000",
    longitude: "-89.94431000",
  },
  {
    id: 127081,
    name: "Stone County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.74694000",
    longitude: "-93.45600000",
  },
  {
    id: 127111,
    name: "Stover",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.44086000",
    longitude: "-92.99187000",
  },
  {
    id: 127116,
    name: "Strafford",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.26838000",
    longitude: "-93.11713000",
  },
  {
    id: 127170,
    name: "Sugar Creek",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.10973000",
    longitude: "-94.44467000",
  },
  {
    id: 127183,
    name: "Sullivan",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.20810000",
    longitude: "-91.16042000",
  },
  {
    id: 127188,
    name: "Sullivan County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.21064000",
    longitude: "-93.11152000",
  },
  {
    id: 127275,
    name: "Sunset Hills",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.53894000",
    longitude: "-90.40734000",
  },
  {
    id: 127338,
    name: "Sweet Springs",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.96363000",
    longitude: "-93.41493000",
  },
  {
    id: 127410,
    name: "Taney County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.65473000",
    longitude: "-93.04111000",
  },
  {
    id: 127421,
    name: "Taos",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.50643000",
    longitude: "-92.07101000",
  },
  {
    id: 127431,
    name: "Tarkio",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.44028000",
    longitude: "-95.37776000",
  },
  {
    id: 127523,
    name: "Terre du Lac",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.91172000",
    longitude: "-90.62541000",
  },
  {
    id: 127521,
    name: "Terre Haute",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.43946000",
    longitude: "-93.23410000",
  },
  {
    id: 127547,
    name: "Texas County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.31731000",
    longitude: "-91.96505000",
  },
  {
    id: 127551,
    name: "Thayer",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.52451000",
    longitude: "-91.53820000",
  },
  {
    id: 127674,
    name: "Tipton",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.65558000",
    longitude: "-92.77992000",
  },
  {
    id: 127759,
    name: "Town and Country",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.61228000",
    longitude: "-90.46345000",
  },
  {
    id: 127809,
    name: "Trenton",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.07890000",
    longitude: "-93.61661000",
  },
  {
    id: 127844,
    name: "Troy",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.97949000",
    longitude: "-90.98070000",
  },
  {
    id: 127915,
    name: "Tuscumbia",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.23309000",
    longitude: "-92.45852000",
  },
  {
    id: 127973,
    name: "Union",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.45005000",
    longitude: "-91.00848000",
  },
  {
    id: 128020,
    name: "Unionville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.47696000",
    longitude: "-93.00326000",
  },
  {
    id: 128027,
    name: "University City",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.65588000",
    longitude: "-90.30928000",
  },
  {
    id: 128114,
    name: "Valley Park",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.54922000",
    longitude: "-90.49262000",
  },
  {
    id: 128127,
    name: "Van Buren",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.99561000",
    longitude: "-91.01457000",
  },
  {
    id: 128146,
    name: "Vandalia",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.31087000",
    longitude: "-91.48849000",
  },
  {
    id: 128163,
    name: "Velda Village",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.69005000",
    longitude: "-90.29428000",
  },
  {
    id: 128164,
    name: "Velda Village Hills",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.69061000",
    longitude: "-90.28734000",
  },
  {
    id: 128192,
    name: "Vernon County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.85058000",
    longitude: "-94.34244000",
  },
  {
    id: 128210,
    name: "Versailles",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.43141000",
    longitude: "-92.84103000",
  },
  {
    id: 128232,
    name: "Vienna",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.18671000",
    longitude: "-91.94711000",
  },
  {
    id: 128246,
    name: "Villa Ridge",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.47255000",
    longitude: "-90.88680000",
  },
  {
    id: 128278,
    name: "Vinita Park",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.69005000",
    longitude: "-90.34262000",
  },
  {
    id: 128461,
    name: "Wardsville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.48892000",
    longitude: "-92.17435000",
  },
  {
    id: 128488,
    name: "Warren County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.76462000",
    longitude: "-91.16069000",
  },
  {
    id: 128502,
    name: "Warrensburg",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.76279000",
    longitude: "-93.73605000",
  },
  {
    id: 128505,
    name: "Warrenton",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.81144000",
    longitude: "-91.14154000",
  },
  {
    id: 128517,
    name: "Warsaw",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.24308000",
    longitude: "-93.38187000",
  },
  {
    id: 128522,
    name: "Warson Woods",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.60727000",
    longitude: "-90.38345000",
  },
  {
    id: 128540,
    name: "Washington",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.55811000",
    longitude: "-91.01209000",
  },
  {
    id: 128561,
    name: "Washington County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.96168000",
    longitude: "-90.87742000",
  },
  {
    id: 128693,
    name: "Wayne County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.11264000",
    longitude: "-90.46143000",
  },
  {
    id: 128712,
    name: "Waynesville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.82865000",
    longitude: "-92.20072000",
  },
  {
    id: 128716,
    name: "Weatherby Lake",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.23778000",
    longitude: "-94.69607000",
  },
  {
    id: 128725,
    name: "Webb City",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.14645000",
    longitude: "-94.46300000",
  },
  {
    id: 128738,
    name: "Webster County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.28091000",
    longitude: "-92.87588000",
  },
  {
    id: 128743,
    name: "Webster Groves",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.59255000",
    longitude: "-90.35734000",
  },
  {
    id: 128771,
    name: "Weldon Spring",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.71339000",
    longitude: "-90.68929000",
  },
  {
    id: 128790,
    name: "Wellston",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.67283000",
    longitude: "-90.29928000",
  },
  {
    id: 128792,
    name: "Wellsville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.07198000",
    longitude: "-91.57016000",
  },
  {
    id: 128805,
    name: "Wentzville",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.81144000",
    longitude: "-90.85291000",
  },
  {
    id: 128956,
    name: "West Plains",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.72812000",
    longitude: "-91.85237000",
  },
  {
    id: 129059,
    name: "Weston",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "39.41111000",
    longitude: "-94.90163000",
  },
  {
    id: 129177,
    name: "Whiteman Air Force Base",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.73018000",
    longitude: "-93.55895000",
  },
  {
    id: 129237,
    name: "Wildwood",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.58283000",
    longitude: "-90.66290000",
  },
  {
    id: 129257,
    name: "Willard",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.30505000",
    longitude: "-93.42853000",
  },
  {
    id: 129309,
    name: "Willow Springs",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "36.99228000",
    longitude: "-91.96987000",
  },
  {
    id: 129354,
    name: "Winchester",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.59033000",
    longitude: "-90.52790000",
  },
  {
    id: 129379,
    name: "Windsor",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.53224000",
    longitude: "-93.52215000",
  },
  {
    id: 129394,
    name: "Winfield",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.99727000",
    longitude: "-90.73846000",
  },
  {
    id: 129423,
    name: "Winona",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.00977000",
    longitude: "-91.32347000",
  },
  {
    id: 129559,
    name: "Woodson Terrace",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.72505000",
    longitude: "-90.35845000",
  },
  {
    id: 129594,
    name: "Worth County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "40.47914000",
    longitude: "-94.42210000",
  },
  {
    id: 129607,
    name: "Wright City",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "38.82755000",
    longitude: "-91.02014000",
  },
  {
    id: 129608,
    name: "Wright County",
    state_id: 1451,
    state_code: "MO",
    country_id: 233,
    country_code: "US",
    latitude: "37.27014000",
    longitude: "-92.46870000",
  },
  {
    id: 110989,
    name: "Absarokee",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.52050000",
    longitude: "-109.44294000",
  },
  {
    id: 111296,
    name: "Anaconda",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.12854000",
    longitude: "-112.94226000",
  },
  {
    id: 111739,
    name: "Baker",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.36695000",
    longitude: "-104.28466000",
  },
  {
    id: 112008,
    name: "Beaverhead County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.13273000",
    longitude: "-112.89889000",
  },
  {
    id: 112062,
    name: "Belgrade",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.77604000",
    longitude: "-111.17690000",
  },
  {
    id: 112321,
    name: "Big Horn County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.42346000",
    longitude: "-107.48970000",
  },
  {
    id: 112334,
    name: "Big Sky",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.28465000",
    longitude: "-111.36829000",
  },
  {
    id: 112338,
    name: "Big Timber",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.83494000",
    longitude: "-109.95546000",
  },
  {
    id: 112339,
    name: "Bigfork",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.06329000",
    longitude: "-114.07261000",
  },
  {
    id: 112344,
    name: "Billings",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.78329000",
    longitude: "-108.50069000",
  },
  {
    id: 112408,
    name: "Blaine County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.43276000",
    longitude: "-108.95866000",
  },
  {
    id: 112544,
    name: "Bonner-West Riverside",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.87669000",
    longitude: "-113.88678000",
  },
  {
    id: 112601,
    name: "Boulder",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.23659000",
    longitude: "-112.12083000",
  },
  {
    id: 112653,
    name: "Bozeman",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.67965000",
    longitude: "-111.03856000",
  },
  {
    id: 112826,
    name: "Broadus",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.44387000",
    longitude: "-105.41133000",
  },
  {
    id: 112829,
    name: "Broadwater County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.33199000",
    longitude: "-111.49547000",
  },
  {
    id: 112918,
    name: "Browning",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.55692000",
    longitude: "-113.01342000",
  },
  {
    id: 113123,
    name: "Butte",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.00382000",
    longitude: "-112.53474000",
  },
  {
    id: 113128,
    name: "Butte-Silver Bow (Balance)",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.90194000",
    longitude: "-112.65708000",
  },
  {
    id: 113384,
    name: "Carbon County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.22737000",
    longitude: "-109.02832000",
  },
  {
    id: 113484,
    name: "Carter County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.51677000",
    longitude: "-104.53616000",
  },
  {
    id: 113515,
    name: "Cascade County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.30802000",
    longitude: "-111.34715000",
  },
  {
    id: 113893,
    name: "Chester",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.51054000",
    longitude: "-110.96747000",
  },
  {
    id: 113971,
    name: "Chinook",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.59000000",
    longitude: "-109.23128000",
  },
  {
    id: 113989,
    name: "Choteau",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.81245000",
    longitude: "-112.18363000",
  },
  {
    id: 113991,
    name: "Chouteau County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.88056000",
    longitude: "-110.43520000",
  },
  {
    id: 114028,
    name: "Circle",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.41667000",
    longitude: "-105.59222000",
  },
  {
    id: 114098,
    name: "Clancy",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.46521000",
    longitude: "-111.98638000",
  },
  {
    id: 114287,
    name: "Clinton",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.76909000",
    longitude: "-113.71260000",
  },
  {
    id: 114462,
    name: "Colstrip",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.88416000",
    longitude: "-106.62364000",
  },
  {
    id: 114485,
    name: "Columbia Falls",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.37246000",
    longitude: "-114.18152000",
  },
  {
    id: 114499,
    name: "Columbus",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.63661000",
    longitude: "-109.25211000",
  },
  {
    id: 114569,
    name: "Conrad",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.17025000",
    longitude: "-111.94613000",
  },
  {
    id: 114860,
    name: "Crow Agency",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.60164000",
    longitude: "-107.46119000",
  },
  {
    id: 114939,
    name: "Custer County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.25270000",
    longitude: "-105.57178000",
  },
  {
    id: 114941,
    name: "Cut Bank",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.63304000",
    longitude: "-112.32616000",
  },
  {
    id: 115024,
    name: "Daniels County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.78381000",
    longitude: "-105.54857000",
  },
  {
    id: 115086,
    name: "Dawson County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.26638000",
    longitude: "-104.89946000",
  },
  {
    id: 115173,
    name: "Deer Lodge",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.39576000",
    longitude: "-112.73004000",
  },
  {
    id: 115174,
    name: "Deer Lodge County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.06079000",
    longitude: "-113.06775000",
  },
  {
    id: 115343,
    name: "Dillon",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.21631000",
    longitude: "-112.63752000",
  },
  {
    id: 115688,
    name: "East Helena",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.58966000",
    longitude: "-111.91555000",
  },
  {
    id: 115715,
    name: "East Missoula",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.87076000",
    longitude: "-113.94455000",
  },
  {
    id: 115910,
    name: "Ekalaka",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.88889000",
    longitude: "-104.55273000",
  },
  {
    id: 116236,
    name: "Eureka",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.87996000",
    longitude: "-115.05350000",
  },
  {
    id: 116264,
    name: "Evergreen",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.22579000",
    longitude: "-114.27624000",
  },
  {
    id: 116379,
    name: "Fallon County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.33402000",
    longitude: "-104.41742000",
  },
  {
    id: 116478,
    name: "Fergus County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.26357000",
    longitude: "-109.22433000",
  },
  {
    id: 116554,
    name: "Flathead County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.29516000",
    longitude: "-114.04981000",
  },
  {
    id: 116685,
    name: "Forsyth",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.26638000",
    longitude: "-106.67781000",
  },
  {
    id: 116690,
    name: "Fort Belknap Agency",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.48250000",
    longitude: "-108.76544000",
  },
  {
    id: 116693,
    name: "Fort Benton",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.81830000",
    longitude: "-110.66744000",
  },
  {
    id: 116790,
    name: "Four Corners",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.62965000",
    longitude: "-111.18606000",
  },
  {
    id: 116935,
    name: "Frenchtown",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.01492000",
    longitude: "-114.22984000",
  },
  {
    id: 117036,
    name: "Gallatin County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.54049000",
    longitude: "-111.17035000",
  },
  {
    id: 117086,
    name: "Garfield County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.27770000",
    longitude: "-106.99283000",
  },
  {
    id: 117231,
    name: "Glacier County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.70508000",
    longitude: "-112.99475000",
  },
  {
    id: 117249,
    name: "Glasgow",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.19696000",
    longitude: "-106.63671000",
  },
  {
    id: 117289,
    name: "Glendive",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.10529000",
    longitude: "-104.71246000",
  },
  {
    id: 117358,
    name: "Golden Valley County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.38126000",
    longitude: "-109.17494000",
  },
  {
    id: 117495,
    name: "Granite County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.40444000",
    longitude: "-113.44026000",
  },
  {
    id: 117573,
    name: "Great Falls",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.50024000",
    longitude: "-111.30081000",
  },
  {
    id: 117894,
    name: "Hamilton",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.24687000",
    longitude: "-114.16037000",
  },
  {
    id: 117998,
    name: "Hardin",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.73248000",
    longitude: "-107.61203000",
  },
  {
    id: 118027,
    name: "Harlowton",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.43551000",
    longitude: "-109.83435000",
  },
  {
    id: 118154,
    name: "Havre",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.55000000",
    longitude: "-109.68409000",
  },
  {
    id: 118251,
    name: "Helena",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.59271000",
    longitude: "-112.03611000",
  },
  {
    id: 118252,
    name: "Helena Valley Northeast",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.69882000",
    longitude: "-111.95207000",
  },
  {
    id: 118253,
    name: "Helena Valley Northwest",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.72894000",
    longitude: "-112.06275000",
  },
  {
    id: 118254,
    name: "Helena Valley Southeast",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.61527000",
    longitude: "-111.92156000",
  },
  {
    id: 118255,
    name: "Helena Valley West Central",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.66291000",
    longitude: "-112.06044000",
  },
  {
    id: 118256,
    name: "Helena West Side",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.59672000",
    longitude: "-112.11304000",
  },
  {
    id: 118437,
    name: "Hill County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.62823000",
    longitude: "-110.11131000",
  },
  {
    id: 118858,
    name: "Hysham",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.29277000",
    longitude: "-107.23423000",
  },
  {
    id: 119196,
    name: "Jefferson County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.14849000",
    longitude: "-112.09374000",
  },
  {
    id: 119317,
    name: "Jordan",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.32083000",
    longitude: "-106.91007000",
  },
  {
    id: 119326,
    name: "Judith Basin County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.04546000",
    longitude: "-110.26607000",
  },
  {
    id: 119365,
    name: "Kalispell",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.19579000",
    longitude: "-114.31291000",
  },
  {
    id: 119898,
    name: "Lake County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.64594000",
    longitude: "-114.08935000",
  },
  {
    id: 120033,
    name: "Lakeside",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.01939000",
    longitude: "-114.22457000",
  },
  {
    id: 120070,
    name: "Lame Deer",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.62305000",
    longitude: "-106.66670000",
  },
  {
    id: 120193,
    name: "Laurel",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.66912000",
    longitude: "-108.77153000",
  },
  {
    id: 120418,
    name: "Lewis and Clark County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.12234000",
    longitude: "-112.39035000",
  },
  {
    id: 120433,
    name: "Lewistown",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.06247000",
    longitude: "-109.42824000",
  },
  {
    id: 120457,
    name: "Libby",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.38829000",
    longitude: "-115.55600000",
  },
  {
    id: 120473,
    name: "Liberty County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.56169000",
    longitude: "-111.02461000",
  },
  {
    id: 120512,
    name: "Lincoln",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.95494000",
    longitude: "-112.68171000",
  },
  {
    id: 120534,
    name: "Lincoln County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.54253000",
    longitude: "-115.40519000",
  },
  {
    id: 120653,
    name: "Livingston",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.66244000",
    longitude: "-110.56104000",
  },
  {
    id: 120683,
    name: "Lockwood",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.81912000",
    longitude: "-108.41486000",
  },
  {
    id: 120710,
    name: "Lolo",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.75881000",
    longitude: "-114.08094000",
  },
  {
    id: 121019,
    name: "Madison County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.30074000",
    longitude: "-111.92033000",
  },
  {
    id: 121064,
    name: "Malmstrom Air Force Base",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.50549000",
    longitude: "-111.18302000",
  },
  {
    id: 121068,
    name: "Malta",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.35972000",
    longitude: "-107.87428000",
  },
  {
    id: 121116,
    name: "Manhattan",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.85660000",
    longitude: "-111.33246000",
  },
  {
    id: 121475,
    name: "McCone County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.64523000",
    longitude: "-105.79534000",
  },
  {
    id: 121576,
    name: "Meagher County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.59819000",
    longitude: "-110.88564000",
  },
  {
    id: 121844,
    name: "Miles City",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.40834000",
    longitude: "-105.84056000",
  },
  {
    id: 121937,
    name: "Mineral County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.14732000",
    longitude: "-114.99850000",
  },
  {
    id: 121991,
    name: "Missoula",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.87215000",
    longitude: "-113.99400000",
  },
  {
    id: 121992,
    name: "Missoula County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.03649000",
    longitude: "-113.92371000",
  },
  {
    id: 122101,
    name: "Montana City",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.53771000",
    longitude: "-111.93277000",
  },
  {
    id: 122514,
    name: "Musselshell County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.49655000",
    longitude: "-108.39771000",
  },
  {
    id: 122998,
    name: "North Browning",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.57025000",
    longitude: "-113.00953000",
  },
  {
    id: 123535,
    name: "Orchard Homes",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.86326000",
    longitude: "-114.04844000",
  },
  {
    id: 123703,
    name: "Pablo",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.60021000",
    longitude: "-114.11900000",
  },
  {
    id: 123842,
    name: "Park County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.48834000",
    longitude: "-110.52632000",
  },
  {
    id: 124112,
    name: "Petroleum County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.11751000",
    longitude: "-108.25012000",
  },
  {
    id: 124132,
    name: "Philipsburg",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.33215000",
    longitude: "-113.29423000",
  },
  {
    id: 124138,
    name: "Phillips County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.25909000",
    longitude: "-107.91329000",
  },
  {
    id: 124326,
    name: "Plains",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.46021000",
    longitude: "-114.88291000",
  },
  {
    id: 124390,
    name: "Plentywood",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.77475000",
    longitude: "-104.56246000",
  },
  {
    id: 124455,
    name: "Polson",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.69355000",
    longitude: "-114.16317000",
  },
  {
    id: 124468,
    name: "Pondera County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.22798000",
    longitude: "-112.22639000",
  },
  {
    id: 124607,
    name: "Powder River County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.39501000",
    longitude: "-105.63010000",
  },
  {
    id: 124613,
    name: "Powell County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.85663000",
    longitude: "-112.93620000",
  },
  {
    id: 124625,
    name: "Prairie County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.86049000",
    longitude: "-105.37794000",
  },
  {
    id: 124903,
    name: "Ravalli County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.08170000",
    longitude: "-114.12069000",
  },
  {
    id: 124954,
    name: "Red Lodge",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.18578000",
    longitude: "-109.24682000",
  },
  {
    id: 125078,
    name: "Richland County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.78792000",
    longitude: "-104.56134000",
  },
  {
    id: 125386,
    name: "Ronan",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.52882000",
    longitude: "-114.10150000",
  },
  {
    id: 125394,
    name: "Roosevelt County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.29455000",
    longitude: "-105.01652000",
  },
  {
    id: 125413,
    name: "Rosebud County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.22974000",
    longitude: "-106.73082000",
  },
  {
    id: 125474,
    name: "Roundup",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.44524000",
    longitude: "-108.54180000",
  },
  {
    id: 125564,
    name: "Ryegate",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.29718000",
    longitude: "-109.25879000",
  },
  {
    id: 125863,
    name: "Sanders County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.67483000",
    longitude: "-115.13329000",
  },
  {
    id: 126031,
    name: "Scobey",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.79252000",
    longitude: "-105.42083000",
  },
  {
    id: 126125,
    name: "Seeley Lake",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.17938000",
    longitude: "-113.48452000",
  },
  {
    id: 126271,
    name: "Shelby",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.50526000",
    longitude: "-111.85697000",
  },
  {
    id: 126325,
    name: "Sheridan County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.72120000",
    longitude: "-104.50468000",
  },
  {
    id: 126399,
    name: "Sidney",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.71668000",
    longitude: "-104.15633000",
  },
  {
    id: 126421,
    name: "Silver Bow County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.90236000",
    longitude: "-112.65672000",
  },
  {
    id: 126577,
    name: "Somers",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.08023000",
    longitude: "-114.22151000",
  },
  {
    id: 126633,
    name: "South Browning",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.54608000",
    longitude: "-113.01425000",
  },
  {
    id: 126955,
    name: "Stanford",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.15358000",
    longitude: "-110.21826000",
  },
  {
    id: 127049,
    name: "Stevensville",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.50992000",
    longitude: "-114.09316000",
  },
  {
    id: 127063,
    name: "Stillwater County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.66944000",
    longitude: "-109.39477000",
  },
  {
    id: 127238,
    name: "Sun Prairie",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.53690000",
    longitude: "-111.48136000",
  },
  {
    id: 127282,
    name: "Superior",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.19159000",
    longitude: "-114.89180000",
  },
  {
    id: 127336,
    name: "Sweet Grass County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.81373000",
    longitude: "-109.94105000",
  },
  {
    id: 127531,
    name: "Terry",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.79306000",
    longitude: "-105.31221000",
  },
  {
    id: 127538,
    name: "Teton County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.83729000",
    longitude: "-112.24080000",
  },
  {
    id: 127592,
    name: "Thompson Falls",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.59489000",
    longitude: "-115.33834000",
  },
  {
    id: 127611,
    name: "Three Forks",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.89243000",
    longitude: "-111.55219000",
  },
  {
    id: 127728,
    name: "Toole County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.65530000",
    longitude: "-111.69570000",
  },
  {
    id: 127766,
    name: "Townsend",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.31910000",
    longitude: "-111.52080000",
  },
  {
    id: 127789,
    name: "Treasure County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.21147000",
    longitude: "-107.27170000",
  },
  {
    id: 128105,
    name: "Valley County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.36531000",
    longitude: "-106.66752000",
  },
  {
    id: 128289,
    name: "Virginia City",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.29381000",
    longitude: "-111.94609000",
  },
  {
    id: 128470,
    name: "Warm Springs",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.18131000",
    longitude: "-112.78476000",
  },
  {
    id: 128878,
    name: "West Glendive",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.11085000",
    longitude: "-104.74968000",
  },
  {
    id: 129009,
    name: "West Yellowstone",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "44.66215000",
    longitude: "-111.10411000",
  },
  {
    id: 129110,
    name: "Wheatland County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.46634000",
    longitude: "-109.84440000",
  },
  {
    id: 129163,
    name: "White Sulphur Springs",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.54828000",
    longitude: "-110.90216000",
  },
  {
    id: 129166,
    name: "Whitefish",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.41108000",
    longitude: "-114.33763000",
  },
  {
    id: 129172,
    name: "Whitehall",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.87076000",
    longitude: "-112.09749000",
  },
  {
    id: 129213,
    name: "Wibaux",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.98501000",
    longitude: "-104.18827000",
  },
  {
    id: 129214,
    name: "Wibaux County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "46.96535000",
    longitude: "-104.24897000",
  },
  {
    id: 129415,
    name: "Winnett",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "47.00276000",
    longitude: "-108.35207000",
  },
  {
    id: 129473,
    name: "Wolf Point",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "48.09057000",
    longitude: "-105.64056000",
  },
  {
    id: 129679,
    name: "Yellowstone County",
    state_id: 1446,
    state_code: "MT",
    country_id: 233,
    country_code: "US",
    latitude: "45.93725000",
    longitude: "-108.27435000",
  },
  {
    id: 111022,
    name: "Adams County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.52447000",
    longitude: "-98.50121000",
  },
  {
    id: 111069,
    name: "Ainsworth",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.55000000",
    longitude: "-99.86262000",
  },
  {
    id: 111127,
    name: "Albion",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.69084000",
    longitude: "-98.00367000",
  },
  {
    id: 111200,
    name: "Alliance",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.10163000",
    longitude: "-102.87215000",
  },
  {
    id: 111210,
    name: "Alma",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.09751000",
    longitude: "-99.36204000",
  },
  {
    id: 111365,
    name: "Antelope County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.17690000",
    longitude: "-98.06669000",
  },
  {
    id: 111413,
    name: "Arapahoe",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.30417000",
    longitude: "-99.90040000",
  },
  {
    id: 111467,
    name: "Arlington",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.45250000",
    longitude: "-96.35113000",
  },
  {
    id: 111499,
    name: "Arthur",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.57165000",
    longitude: "-101.69156000",
  },
  {
    id: 111500,
    name: "Arthur County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.56890000",
    longitude: "-101.69591000",
  },
  {
    id: 111536,
    name: "Ashland",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.03916000",
    longitude: "-96.36835000",
  },
  {
    id: 111588,
    name: "Atkinson",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.53139000",
    longitude: "-98.97815000",
  },
  {
    id: 111630,
    name: "Auburn",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.39278000",
    longitude: "-95.83889000",
  },
  {
    id: 111661,
    name: "Aurora",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.86723000",
    longitude: "-98.00422000",
  },
  {
    id: 111800,
    name: "Banner County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.54597000",
    longitude: "-103.71048000",
  },
  {
    id: 111864,
    name: "Bartlett",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.88529000",
    longitude: "-98.55230000",
  },
  {
    id: 111882,
    name: "Bassett",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.58583000",
    longitude: "-99.53789000",
  },
  {
    id: 111905,
    name: "Battle Creek",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.99945000",
    longitude: "-97.59839000",
  },
  {
    id: 111938,
    name: "Bayard",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.75497000",
    longitude: "-103.32410000",
  },
  {
    id: 111981,
    name: "Beatrice",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.26806000",
    longitude: "-96.74697000",
  },
  {
    id: 111996,
    name: "Beaver City",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.13751000",
    longitude: "-99.82956000",
  },
  {
    id: 112113,
    name: "Bellevue",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.13667000",
    longitude: "-95.89084000",
  },
  {
    id: 112171,
    name: "Benkelman",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.04916000",
    longitude: "-101.53294000",
  },
  {
    id: 112176,
    name: "Bennington",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.36472000",
    longitude: "-96.15780000",
  },
  {
    id: 112406,
    name: "Blaine County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.91276000",
    longitude: "-99.97690000",
  },
  {
    id: 112409,
    name: "Blair",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.54444000",
    longitude: "-96.12502000",
  },
  {
    id: 112561,
    name: "Boone County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.70678000",
    longitude: "-98.06726000",
  },
  {
    id: 112635,
    name: "Box Butte County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.21977000",
    longitude: "-103.08568000",
  },
  {
    id: 112643,
    name: "Boyd County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.89968000",
    longitude: "-98.76646000",
  },
  {
    id: 112742,
    name: "Brewster",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.93889000",
    longitude: "-99.86485000",
  },
  {
    id: 112767,
    name: "Bridgeport",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.66525000",
    longitude: "-103.09910000",
  },
  {
    id: 112843,
    name: "Broken Bow",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.40195000",
    longitude: "-99.63928000",
  },
  {
    id: 112912,
    name: "Brown County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.43002000",
    longitude: "-99.92951000",
  },
  {
    id: 113018,
    name: "Buffalo County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.85515000",
    longitude: "-99.07497000",
  },
  {
    id: 113095,
    name: "Burt County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.85156000",
    longitude: "-96.32860000",
  },
  {
    id: 113099,
    name: "Burwell",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.78167000",
    longitude: "-99.13315000",
  },
  {
    id: 113119,
    name: "Butler County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.22608000",
    longitude: "-97.13177000",
  },
  {
    id: 113122,
    name: "Butte",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.91139000",
    longitude: "-98.84926000",
  },
  {
    id: 113256,
    name: "Cambridge",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.28195000",
    longitude: "-100.16569000",
  },
  {
    id: 113534,
    name: "Cass County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.90972000",
    longitude: "-96.14087000",
  },
  {
    id: 113606,
    name: "Cedar County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.59926000",
    longitude: "-97.25240000",
  },
  {
    id: 113637,
    name: "Center",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.60945000",
    longitude: "-97.87673000",
  },
  {
    id: 113669,
    name: "Central City",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.11585000",
    longitude: "-98.00172000",
  },
  {
    id: 113707,
    name: "Chadron",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.82942000",
    longitude: "-102.99991000",
  },
  {
    id: 113711,
    name: "Chalco",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.18389000",
    longitude: "-96.15030000",
  },
  {
    id: 113747,
    name: "Chappell",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.09277000",
    longitude: "-102.47074000",
  },
  {
    id: 113793,
    name: "Chase County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.52420000",
    longitude: "-101.69795000",
  },
  {
    id: 113858,
    name: "Cherry County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.54493000",
    longitude: "-101.11858000",
  },
  {
    id: 113929,
    name: "Cheyenne County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.21978000",
    longitude: "-102.99498000",
  },
  {
    id: 114171,
    name: "Clay Center",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.52168000",
    longitude: "-98.05533000",
  },
  {
    id: 114189,
    name: "Clay County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.52443000",
    longitude: "-98.05128000",
  },
  {
    id: 114410,
    name: "Colfax County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.57402000",
    longitude: "-97.08646000",
  },
  {
    id: 114496,
    name: "Columbus",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.42973000",
    longitude: "-97.36838000",
  },
  {
    id: 114752,
    name: "Cozad",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.85973000",
    longitude: "-99.98734000",
  },
  {
    id: 114790,
    name: "Creighton",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.46667000",
    longitude: "-97.90618000",
  },
  {
    id: 114815,
    name: "Crete",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.62778000",
    longitude: "-96.96142000",
  },
  {
    id: 114922,
    name: "Cuming County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.91640000",
    longitude: "-96.78740000",
  },
  {
    id: 114936,
    name: "Custer County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.39426000",
    longitude: "-99.72614000",
  },
  {
    id: 114975,
    name: "Dakota City",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.41555000",
    longitude: "-96.41836000",
  },
  {
    id: 114977,
    name: "Dakota County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.39111000",
    longitude: "-96.56451000",
  },
  {
    id: 115061,
    name: "David City",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.25279000",
    longitude: "-97.13004000",
  },
  {
    id: 115080,
    name: "Dawes County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.71972000",
    longitude: "-103.13544000",
  },
  {
    id: 115084,
    name: "Dawson County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.86994000",
    longitude: "-99.81957000",
  },
  {
    id: 115295,
    name: "Deuel County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.11160000",
    longitude: "-102.33395000",
  },
  {
    id: 115368,
    name: "Dixon County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.49319000",
    longitude: "-96.86775000",
  },
  {
    id: 115379,
    name: "Dodge County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.57789000",
    longitude: "-96.65398000",
  },
  {
    id: 115430,
    name: "Douglas County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.29535000",
    longitude: "-96.15448000",
  },
  {
    id: 115532,
    name: "Dundy County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.17624000",
    longitude: "-101.68796000",
  },
  {
    id: 115595,
    name: "Eagle",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.81667000",
    longitude: "-96.43029000",
  },
  {
    id: 116001,
    name: "Elkhorn",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.28639000",
    longitude: "-96.23447000",
  },
  {
    id: 116081,
    name: "Elwood",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.59028000",
    longitude: "-99.86095000",
  },
  {
    id: 116295,
    name: "Fairbury",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.13722000",
    longitude: "-97.18059000",
  },
  {
    id: 116381,
    name: "Falls City",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.06084000",
    longitude: "-95.60193000",
  },
  {
    id: 116511,
    name: "Fillmore County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.52467000",
    longitude: "-97.59650000",
  },
  {
    id: 116839,
    name: "Franklin",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.09612000",
    longitude: "-98.95258000",
  },
  {
    id: 116860,
    name: "Franklin County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.17634000",
    longitude: "-98.95280000",
  },
  {
    id: 116923,
    name: "Fremont",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.43333000",
    longitude: "-96.49808000",
  },
  {
    id: 116946,
    name: "Friend",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.65362000",
    longitude: "-97.28616000",
  },
  {
    id: 116963,
    name: "Frontier County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.53008000",
    longitude: "-100.39420000",
  },
  {
    id: 116981,
    name: "Fullerton",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.36335000",
    longitude: "-97.96923000",
  },
  {
    id: 117001,
    name: "Furnas County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.17647000",
    longitude: "-99.91227000",
  },
  {
    id: 117008,
    name: "Gage County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.26193000",
    longitude: "-96.68944000",
  },
  {
    id: 117064,
    name: "Garden County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.61943000",
    longitude: "-102.33544000",
  },
  {
    id: 117083,
    name: "Garfield County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.91436000",
    longitude: "-98.99139000",
  },
  {
    id: 117143,
    name: "Geneva",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.52695000",
    longitude: "-97.59588000",
  },
  {
    id: 117170,
    name: "Gering",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.82580000",
    longitude: "-103.66050000",
  },
  {
    id: 117182,
    name: "Gibbon",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.74835000",
    longitude: "-98.84480000",
  },
  {
    id: 117397,
    name: "Gordon",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.80472000",
    longitude: "-102.20322000",
  },
  {
    id: 117413,
    name: "Gosper County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.51482000",
    longitude: "-99.83070000",
  },
  {
    id: 117415,
    name: "Gothenburg",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.92945000",
    longitude: "-100.16068000",
  },
  {
    id: 117461,
    name: "Grand Island",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.92501000",
    longitude: "-98.34201000",
  },
  {
    id: 117505,
    name: "Grant",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.84194000",
    longitude: "-101.72517000",
  },
  {
    id: 117519,
    name: "Grant County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.91499000",
    longitude: "-101.74052000",
  },
  {
    id: 117585,
    name: "Greeley",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.54862000",
    longitude: "-98.53118000",
  },
  {
    id: 117587,
    name: "Greeley County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.56744000",
    longitude: "-98.52124000",
  },
  {
    id: 117727,
    name: "Gretna",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.14083000",
    longitude: "-96.23974000",
  },
  {
    id: 117865,
    name: "Hall County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.87257000",
    longitude: "-98.50217000",
  },
  {
    id: 117902,
    name: "Hamilton County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.87356000",
    longitude: "-98.02323000",
  },
  {
    id: 118019,
    name: "Harlan County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.17650000",
    longitude: "-99.40464000",
  },
  {
    id: 118052,
    name: "Harrisburg",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.55636000",
    longitude: "-103.73856000",
  },
  {
    id: 118060,
    name: "Harrison",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.68719000",
    longitude: "-103.88271000",
  },
  {
    id: 118096,
    name: "Hartington",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.62250000",
    longitude: "-97.26450000",
  },
  {
    id: 118133,
    name: "Hastings",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.58612000",
    longitude: "-98.38839000",
  },
  {
    id: 118180,
    name: "Hayes Center",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.51084000",
    longitude: "-101.01960000",
  },
  {
    id: 118181,
    name: "Hayes County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.52478000",
    longitude: "-101.06184000",
  },
  {
    id: 118238,
    name: "Hebron",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.16639000",
    longitude: "-97.58588000",
  },
  {
    id: 118362,
    name: "Hickman",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.62000000",
    longitude: "-96.62918000",
  },
  {
    id: 118493,
    name: "Hitchcock County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.17635000",
    longitude: "-101.04227000",
  },
  {
    id: 118528,
    name: "Holdrege",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.44029000",
    longitude: "-99.36982000",
  },
  {
    id: 118577,
    name: "Holt County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.45571000",
    longitude: "-98.78384000",
  },
  {
    id: 118629,
    name: "Hooker County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.91591000",
    longitude: "-101.13533000",
  },
  {
    id: 118717,
    name: "Howard County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.22004000",
    longitude: "-98.51709000",
  },
  {
    id: 118843,
    name: "Hyannis",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.00054000",
    longitude: "-101.76184000",
  },
  {
    id: 118884,
    name: "Imperial",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.51694000",
    longitude: "-101.64323000",
  },
  {
    id: 119190,
    name: "Jefferson County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.17576000",
    longitude: "-97.14272000",
  },
  {
    id: 119270,
    name: "Johnson County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.39263000",
    longitude: "-96.26510000",
  },
  {
    id: 119408,
    name: "Kearney",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.69946000",
    longitude: "-99.08148000",
  },
  {
    id: 119409,
    name: "Kearney County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.50671000",
    longitude: "-98.94802000",
  },
  {
    id: 119425,
    name: "Keith County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.19879000",
    longitude: "-101.66135000",
  },
  {
    id: 119542,
    name: "Keya Paha County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.87883000",
    longitude: "-99.71235000",
  },
  {
    id: 119566,
    name: "Kimball",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.23581000",
    longitude: "-103.66300000",
  },
  {
    id: 119567,
    name: "Kimball County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.19766000",
    longitude: "-103.71495000",
  },
  {
    id: 119692,
    name: "Knox County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.63678000",
    longitude: "-97.89190000",
  },
  {
    id: 119796,
    name: "La Vista",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.18389000",
    longitude: "-96.03113000",
  },
  {
    id: 120092,
    name: "Lancaster County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.78417000",
    longitude: "-96.68776000",
  },
  {
    id: 120452,
    name: "Lexington",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.78084000",
    longitude: "-99.74150000",
  },
  {
    id: 120506,
    name: "Lincoln",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.80000000",
    longitude: "-96.66696000",
  },
  {
    id: 120535,
    name: "Lincoln County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.04777000",
    longitude: "-100.74523000",
  },
  {
    id: 120704,
    name: "Logan County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.56649000",
    longitude: "-100.48286000",
  },
  {
    id: 120816,
    name: "Louisville",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.99778000",
    longitude: "-96.16224000",
  },
  {
    id: 120818,
    name: "Loup City",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.27557000",
    longitude: "-98.96675000",
  },
  {
    id: 120819,
    name: "Loup County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.91385000",
    longitude: "-99.45442000",
  },
  {
    id: 120975,
    name: "Macy",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.11305000",
    longitude: "-96.35642000",
  },
  {
    id: 120997,
    name: "Madison",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.82834000",
    longitude: "-97.45505000",
  },
  {
    id: 121016,
    name: "Madison County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.91670000",
    longitude: "-97.60078000",
  },
  {
    id: 121479,
    name: "McCook",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.20195000",
    longitude: "-100.62571000",
  },
  {
    id: 121548,
    name: "McPherson County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.56808000",
    longitude: "-101.06053000",
  },
  {
    id: 121705,
    name: "Merrick County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.16982000",
    longitude: "-98.03765000",
  },
  {
    id: 121855,
    name: "Milford",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.77445000",
    longitude: "-97.05059000",
  },
  {
    id: 121929,
    name: "Minden",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.49863000",
    longitude: "-98.94786000",
  },
  {
    id: 121998,
    name: "Mitchell",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.94024000",
    longitude: "-103.80856000",
  },
  {
    id: 122263,
    name: "Morrill County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.71600000",
    longitude: "-103.01055000",
  },
  {
    id: 122459,
    name: "Mullen",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.04278000",
    longitude: "-101.04266000",
  },
  {
    id: 122540,
    name: "Nance County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.39730000",
    longitude: "-97.99225000",
  },
  {
    id: 122609,
    name: "Nebraska City",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.67667000",
    longitude: "-95.85917000",
  },
  {
    id: 122620,
    name: "Neligh",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.12862000",
    longitude: "-98.02979000",
  },
  {
    id: 122625,
    name: "Nelson",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.20168000",
    longitude: "-98.06782000",
  },
  {
    id: 122630,
    name: "Nemaha County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.38765000",
    longitude: "-95.84982000",
  },
  {
    id: 122943,
    name: "Norfolk",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.02834000",
    longitude: "-97.41700000",
  },
  {
    id: 122983,
    name: "North Bend",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.46195000",
    longitude: "-96.77975000",
  },
  {
    id: 123082,
    name: "North Platte",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.12389000",
    longitude: "-100.76542000",
  },
  {
    id: 123214,
    name: "Nuckolls County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.17638000",
    longitude: "-98.04718000",
  },
  {
    id: 123228,
    name: "O'Neill",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.45778000",
    longitude: "-98.64759000",
  },
  {
    id: 123291,
    name: "Oakland",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.83583000",
    longitude: "-96.46697000",
  },
  {
    id: 123364,
    name: "Offutt Air Force Base",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.12024000",
    longitude: "-95.92095000",
  },
  {
    id: 123365,
    name: "Ogallala",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.12805000",
    longitude: "-101.71962000",
  },
  {
    id: 123463,
    name: "Omaha",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.25626000",
    longitude: "-95.94043000",
  },
  {
    id: 123542,
    name: "Ord",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.60334000",
    longitude: "-98.92620000",
  },
  {
    id: 123601,
    name: "Osceola",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.17974000",
    longitude: "-97.54755000",
  },
  {
    id: 123610,
    name: "Oshkosh",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.40498000",
    longitude: "-102.34436000",
  },
  {
    id: 123631,
    name: "Otoe County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.64850000",
    longitude: "-96.13478000",
  },
  {
    id: 123814,
    name: "Papillion",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.15444000",
    longitude: "-96.04224000",
  },
  {
    id: 123935,
    name: "Pawnee City",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.10833000",
    longitude: "-96.15445000",
  },
  {
    id: 123938,
    name: "Pawnee County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.13154000",
    longitude: "-96.23706000",
  },
  {
    id: 124014,
    name: "Pender",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.11416000",
    longitude: "-96.70726000",
  },
  {
    id: 124063,
    name: "Perkins County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.85094000",
    longitude: "-101.64961000",
  },
  {
    id: 124122,
    name: "Phelps County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.51111000",
    longitude: "-99.41454000",
  },
  {
    id: 124169,
    name: "Pierce",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.19917000",
    longitude: "-97.52672000",
  },
  {
    id: 124172,
    name: "Pierce County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.26437000",
    longitude: "-97.60129000",
  },
  {
    id: 124329,
    name: "Plainview",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.34973000",
    longitude: "-97.79201000",
  },
  {
    id: 124354,
    name: "Platte County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.57129000",
    longitude: "-97.52116000",
  },
  {
    id: 124362,
    name: "Plattsmouth",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.01139000",
    longitude: "-95.88223000",
  },
  {
    id: 124449,
    name: "Polk County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.18690000",
    longitude: "-97.56842000",
  },
  {
    id: 124463,
    name: "Ponca",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.56250000",
    longitude: "-96.70559000",
  },
  {
    id: 124836,
    name: "Ralston",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.20528000",
    longitude: "-96.04252000",
  },
  {
    id: 124908,
    name: "Ravenna",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.02612000",
    longitude: "-98.91258000",
  },
  {
    id: 124944,
    name: "Red Cloud",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.08890000",
    longitude: "-98.51950000",
  },
  {
    id: 124962,
    name: "Red Willow County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.17583000",
    longitude: "-100.47686000",
  },
  {
    id: 125059,
    name: "Richardson County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.12506000",
    longitude: "-95.71753000",
  },
  {
    id: 125273,
    name: "Rock County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.42129000",
    longitude: "-99.44993000",
  },
  {
    id: 125527,
    name: "Rushville",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.71832000",
    longitude: "-102.46406000",
  },
  {
    id: 125704,
    name: "Saint Paul",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.21473000",
    longitude: "-98.45812000",
  },
  {
    id: 125753,
    name: "Saline County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.52406000",
    longitude: "-97.14091000",
  },
  {
    id: 125952,
    name: "Sarpy County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.11293000",
    longitude: "-96.11199000",
  },
  {
    id: 125969,
    name: "Saunders County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.22637000",
    longitude: "-96.63740000",
  },
  {
    id: 126021,
    name: "Schuyler",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.44723000",
    longitude: "-97.05948000",
  },
  {
    id: 126057,
    name: "Scotts Bluff County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.85061000",
    longitude: "-103.70795000",
  },
  {
    id: 126060,
    name: "Scottsbluff",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.86663000",
    longitude: "-103.66717000",
  },
  {
    id: 126186,
    name: "Seward",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.90695000",
    longitude: "-97.09892000",
  },
  {
    id: 126188,
    name: "Seward County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.87239000",
    longitude: "-97.13951000",
  },
  {
    id: 126298,
    name: "Shelton",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.77918000",
    longitude: "-98.73091000",
  },
  {
    id: 126327,
    name: "Sheridan County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.50477000",
    longitude: "-102.40896000",
  },
  {
    id: 126332,
    name: "Sherman County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.22059000",
    longitude: "-98.97621000",
  },
  {
    id: 126398,
    name: "Sidney",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.14276000",
    longitude: "-102.97798000",
  },
  {
    id: 126461,
    name: "Sioux County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.48771000",
    longitude: "-103.75888000",
  },
  {
    id: 126730,
    name: "South Sioux City",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.47388000",
    longitude: "-96.41364000",
  },
  {
    id: 126899,
    name: "Springfield",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.08194000",
    longitude: "-96.13446000",
  },
  {
    id: 126913,
    name: "Springview",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.82444000",
    longitude: "-99.74901000",
  },
  {
    id: 126968,
    name: "Stanton",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.95028000",
    longitude: "-97.22393000",
  },
  {
    id: 126972,
    name: "Stanton County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.91692000",
    longitude: "-97.19392000",
  },
  {
    id: 126977,
    name: "Stapleton",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.48028000",
    longitude: "-100.51292000",
  },
  {
    id: 127076,
    name: "Stockville",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.53279000",
    longitude: "-100.38348000",
  },
  {
    id: 127137,
    name: "Stromsburg",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.11418000",
    longitude: "-97.59894000",
  },
  {
    id: 127278,
    name: "Superior",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.02085000",
    longitude: "-98.07004000",
  },
  {
    id: 127306,
    name: "Sutherland",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.15694000",
    longitude: "-101.12627000",
  },
  {
    id: 127314,
    name: "Sutton",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.60557000",
    longitude: "-97.85921000",
  },
  {
    id: 127364,
    name: "Syracuse",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.65722000",
    longitude: "-96.18640000",
  },
  {
    id: 127448,
    name: "Taylor",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.77028000",
    longitude: "-99.37872000",
  },
  {
    id: 127480,
    name: "Tecumseh",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.36667000",
    longitude: "-96.19612000",
  },
  {
    id: 127485,
    name: "Tekamah",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.77832000",
    longitude: "-96.22113000",
  },
  {
    id: 127534,
    name: "Terrytown",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.84747000",
    longitude: "-103.66161000",
  },
  {
    id: 127552,
    name: "Thayer County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.17629000",
    longitude: "-97.59492000",
  },
  {
    id: 127567,
    name: "Thedford",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.97833000",
    longitude: "-100.57625000",
  },
  {
    id: 127581,
    name: "Thomas County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.91351000",
    longitude: "-100.55585000",
  },
  {
    id: 127630,
    name: "Thurston County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.15819000",
    longitude: "-96.54410000",
  },
  {
    id: 127810,
    name: "Trenton",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.17555000",
    longitude: "-101.01294000",
  },
  {
    id: 127863,
    name: "Tryon",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.55277000",
    longitude: "-100.95765000",
  },
  {
    id: 128091,
    name: "Valentine",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.87278000",
    longitude: "-100.55097000",
  },
  {
    id: 128098,
    name: "Valley",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.31278000",
    longitude: "-96.34614000",
  },
  {
    id: 128103,
    name: "Valley County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.56731000",
    longitude: "-98.98190000",
  },
  {
    id: 128334,
    name: "Wahoo",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.21139000",
    longitude: "-96.62030000",
  },
  {
    id: 128362,
    name: "Wakefield",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.26917000",
    longitude: "-96.86504000",
  },
  {
    id: 128571,
    name: "Washington County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.53104000",
    longitude: "-96.22203000",
  },
  {
    id: 128614,
    name: "Waterloo",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.28694000",
    longitude: "-96.28558000",
  },
  {
    id: 128671,
    name: "Waverly",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.91750000",
    longitude: "-96.52834000",
  },
  {
    id: 128686,
    name: "Wayne",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.23056000",
    longitude: "-97.01782000",
  },
  {
    id: 128700,
    name: "Wayne County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "42.20929000",
    longitude: "-97.11926000",
  },
  {
    id: 128742,
    name: "Webster County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.17643000",
    longitude: "-98.49995000",
  },
  {
    id: 128756,
    name: "Weeping Water",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.87000000",
    longitude: "-96.14057000",
  },
  {
    id: 128961,
    name: "West Point",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.84167000",
    longitude: "-96.70864000",
  },
  {
    id: 129117,
    name: "Wheeler County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.91477000",
    longitude: "-98.52819000",
  },
  {
    id: 129225,
    name: "Wilber",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.48139000",
    longitude: "-96.96058000",
  },
  {
    id: 129462,
    name: "Wisner",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.98722000",
    longitude: "-96.91421000",
  },
  {
    id: 129489,
    name: "Wood River",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.82057000",
    longitude: "-98.60007000",
  },
  {
    id: 129627,
    name: "Wymore",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.12222000",
    longitude: "-96.66252000",
  },
  {
    id: 129693,
    name: "York",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.86807000",
    longitude: "-97.59200000",
  },
  {
    id: 129698,
    name: "York County",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "40.87275000",
    longitude: "-97.59711000",
  },
  {
    id: 129737,
    name: "Yutan",
    state_id: 1408,
    state_code: "NE",
    country_id: 233,
    country_code: "US",
    latitude: "41.24500000",
    longitude: "-96.39725000",
  },
  {
    id: 111095,
    name: "Alamo",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "37.36496000",
    longitude: "-115.16446000",
  },
  {
    id: 111908,
    name: "Battle Mountain",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "40.64213000",
    longitude: "-116.93427000",
  },
  {
    id: 111982,
    name: "Beatty",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "36.90856000",
    longitude: "-116.75923000",
  },
  {
    id: 112602,
    name: "Boulder City",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "35.97859000",
    longitude: "-114.83249000",
  },
  {
    id: 113043,
    name: "Bunkerville",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "36.77303000",
    longitude: "-114.12802000",
  },
  {
    id: 113213,
    name: "Caliente",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "37.61496000",
    longitude: "-114.51194000",
  },
  {
    id: 113399,
    name: "Carlin",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "40.71381000",
    longitude: "-116.10397000",
  },
  {
    id: 113478,
    name: "Carson City",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "39.16380000",
    longitude: "-119.76740000",
  },
  {
    id: 114012,
    name: "Churchill County",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "39.58088000",
    longitude: "-118.33578000",
  },
  {
    id: 114129,
    name: "Clark County",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "36.21520000",
    longitude: "-115.01356000",
  },
  {
    id: 114389,
    name: "Cold Springs",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "39.68019000",
    longitude: "-119.97659000",
  },
  {
    id: 115098,
    name: "Dayton",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "39.23714000",
    longitude: "-119.59295000",
  },
  {
    id: 115434,
    name: "Douglas County",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "38.91224000",
    longitude: "-119.61637000",
  },
  {
    id: 115768,
    name: "East Valley",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "38.94340000",
    longitude: "-119.69923000",
  },
  {
    id: 116008,
    name: "Elko",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "40.83242000",
    longitude: "-115.76312000",
  },
  {
    id: 116009,
    name: "Elko County",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "41.14583000",
    longitude: "-115.35776000",
  },
  {
    id: 116086,
    name: "Ely",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "39.24744000",
    longitude: "-114.88863000",
  },
  {
    id: 116149,
    name: "Enterprise",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "36.02525000",
    longitude: "-115.24194000",
  },
  {
    id: 116186,
    name: "Esmeralda County",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "37.78470000",
    longitude: "-117.63237000",
  },
  {
    id: 116237,
    name: "Eureka",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "39.51271000",
    longitude: "-115.96061000",
  },
  {
    id: 116238,
    name: "Eureka County",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "39.98389000",
    longitude: "-116.26856000",
  },
  {
    id: 116378,
    name: "Fallon",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "39.47353000",
    longitude: "-118.77737000",
  },
  {
    id: 116491,
    name: "Fernley",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "39.60797000",
    longitude: "-119.25183000",
  },
  {
    id: 117078,
    name: "Gardnerville",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "38.94130000",
    longitude: "-119.74962000",
  },
  {
    id: 117079,
    name: "Gardnerville Ranchos",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "38.88824000",
    longitude: "-119.74129000",
  },
  {
    id: 117357,
    name: "Golden Valley",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "39.61547000",
    longitude: "-119.82658000",
  },
  {
    id: 117363,
    name: "Goldfield",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "37.70854000",
    longitude: "-117.23563000",
  },
  {
    id: 118176,
    name: "Hawthorne",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "38.52464000",
    longitude: "-118.62458000",
  },
  {
    id: 118278,
    name: "Henderson",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "36.03970000",
    longitude: "-114.98194000",
  },
  {
    id: 118780,
    name: "Humboldt County",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "41.40684000",
    longitude: "-118.11197000",
  },
  {
    id: 118888,
    name: "Incline Village",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "39.25130000",
    longitude: "-119.97297000",
  },
  {
    id: 118909,
    name: "Indian Hills",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "39.08602000",
    longitude: "-119.78407000",
  },
  {
    id: 119057,
    name: "Jackpot",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "41.98324000",
    longitude: "-114.67476000",
  },
  {
    id: 119273,
    name: "Johnson Lane",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "39.04796000",
    longitude: "-119.72212000",
  },
  {
    id: 119610,
    name: "Kingsbury",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "38.97713000",
    longitude: "-119.90685000",
  },
  {
    id: 120097,
    name: "Lander County",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "39.93381000",
    longitude: "-117.03791000",
  },
  {
    id: 120163,
    name: "Las Vegas",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "36.17497000",
    longitude: "-115.13722000",
  },
  {
    id: 120185,
    name: "Laughlin",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "35.16778000",
    longitude: "-114.57302000",
  },
  {
    id: 120358,
    name: "Lemmon Valley",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "39.63602000",
    longitude: "-119.84325000",
  },
  {
    id: 120532,
    name: "Lincoln County",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "37.64335000",
    longitude: "-114.87755000",
  },
  {
    id: 120825,
    name: "Lovelock",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "40.17935000",
    longitude: "-118.47348000",
  },
  {
    id: 120939,
    name: "Lyon County",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "39.02040000",
    longitude: "-119.18920000",
  },
  {
    id: 121502,
    name: "McGill",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "39.40494000",
    longitude: "-114.77863000",
  },
  {
    id: 121727,
    name: "Mesquite",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "36.80553000",
    longitude: "-114.06719000",
  },
  {
    id: 121930,
    name: "Minden",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "38.95407000",
    longitude: "-119.76573000",
  },
  {
    id: 121936,
    name: "Mineral County",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "38.53881000",
    longitude: "-118.43521000",
  },
  {
    id: 122007,
    name: "Moapa Town",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "36.68219000",
    longitude: "-114.59416000",
  },
  {
    id: 122008,
    name: "Moapa Valley",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "36.58053000",
    longitude: "-114.47026000",
  },
  {
    id: 122017,
    name: "Mogul",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "39.51380000",
    longitude: "-119.92603000",
  },
  {
    id: 122622,
    name: "Nellis Air Force Base",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "36.24607000",
    longitude: "-115.05721000",
  },
  {
    id: 123052,
    name: "North Las Vegas",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "36.19886000",
    longitude: "-115.11750000",
  },
  {
    id: 123222,
    name: "Nye County",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "38.04238000",
    longitude: "-116.47193000",
  },
  {
    id: 123724,
    name: "Pahrump",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "36.20829000",
    longitude: "-115.98391000",
  },
  {
    id: 123819,
    name: "Paradise",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "36.09719000",
    longitude: "-115.14666000",
  },
  {
    id: 124091,
    name: "Pershing County",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "40.44036000",
    longitude: "-118.40444000",
  },
  {
    id: 124273,
    name: "Pioche",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "37.92969000",
    longitude: "-114.45221000",
  },
  {
    id: 125020,
    name: "Reno",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "39.52963000",
    longitude: "-119.81380000",
  },
  {
    id: 125885,
    name: "Sandy Valley",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "35.81692000",
    longitude: "-115.63223000",
  },
  {
    id: 126435,
    name: "Silver Springs",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "39.41547000",
    longitude: "-119.22461000",
  },
  {
    id: 126510,
    name: "Smith",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "38.80047000",
    longitude: "-119.32738000",
  },
  {
    id: 126517,
    name: "Smith Valley",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "38.78421000",
    longitude: "-119.34425000",
  },
  {
    id: 126801,
    name: "Spanish Springs",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "39.64908000",
    longitude: "-119.70741000",
  },
  {
    id: 126805,
    name: "Sparks",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "39.53491000",
    longitude: "-119.75269000",
  },
  {
    id: 126854,
    name: "Spring Creek",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "40.72659000",
    longitude: "-115.58590000",
  },
  {
    id: 126881,
    name: "Spring Valley",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "36.10803000",
    longitude: "-115.24500000",
  },
  {
    id: 126939,
    name: "Stagecoach",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "39.37380000",
    longitude: "-119.37406000",
  },
  {
    id: 127102,
    name: "Storey County",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "39.44653000",
    longitude: "-119.52917000",
  },
  {
    id: 127205,
    name: "Summerlin South",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "36.11708000",
    longitude: "-115.33001000",
  },
  {
    id: 127242,
    name: "Sun Valley",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "39.59630000",
    longitude: "-119.77602000",
  },
  {
    id: 127269,
    name: "Sunrise Manor",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "36.21108000",
    longitude: "-115.07306000",
  },
  {
    id: 127722,
    name: "Tonopah",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "38.06716000",
    longitude: "-117.23008000",
  },
  {
    id: 127731,
    name: "Topaz Ranch Estates",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "38.73565000",
    longitude: "-119.50079000",
  },
  {
    id: 128180,
    name: "Verdi",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "39.51824000",
    longitude: "-119.98881000",
  },
  {
    id: 128290,
    name: "Virginia City",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "39.30963000",
    longitude: "-119.64962000",
  },
  {
    id: 128590,
    name: "Washoe County",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "40.66542000",
    longitude: "-119.66430000",
  },
  {
    id: 128783,
    name: "Wells",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "41.11159000",
    longitude: "-114.96449000",
  },
  {
    id: 129004,
    name: "West Wendover",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "40.73910000",
    longitude: "-114.07335000",
  },
  {
    id: 129153,
    name: "White Pine County",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "39.44216000",
    longitude: "-114.90159000",
  },
  {
    id: 129209,
    name: "Whitney",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "36.09831000",
    longitude: "-115.03630000",
  },
  {
    id: 129361,
    name: "Winchester",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "36.12997000",
    longitude: "-115.11889000",
  },
  {
    id: 129411,
    name: "Winnemucca",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "40.97296000",
    longitude: "-117.73568000",
  },
  {
    id: 129682,
    name: "Yerington",
    state_id: 1458,
    state_code: "NV",
    country_id: 233,
    country_code: "US",
    latitude: "38.98575000",
    longitude: "-119.16293000",
  },
  {
    id: 111155,
    name: "Alexandria",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.61146000",
    longitude: "-71.79286000",
  },
  {
    id: 111228,
    name: "Alstead",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.14897000",
    longitude: "-72.36064000",
  },
  {
    id: 111326,
    name: "Andover",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.43702000",
    longitude: "-71.82341000",
  },
  {
    id: 111376,
    name: "Antrim",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.03091000",
    longitude: "-71.93897000",
  },
  {
    id: 111535,
    name: "Ashland",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.69535000",
    longitude: "-71.63063000",
  },
  {
    id: 111587,
    name: "Atkinson",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.83842000",
    longitude: "-71.14700000",
  },
  {
    id: 111629,
    name: "Auburn",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.00453000",
    longitude: "-71.34840000",
  },
  {
    id: 111836,
    name: "Barnstead",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.33397000",
    longitude: "-71.29284000",
  },
  {
    id: 111847,
    name: "Barrington",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.22286000",
    longitude: "-71.04701000",
  },
  {
    id: 112024,
    name: "Bedford",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.94647000",
    longitude: "-71.51590000",
  },
  {
    id: 112065,
    name: "Belknap County",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.51869000",
    longitude: "-71.42336000",
  },
  {
    id: 112139,
    name: "Belmont",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.44536000",
    longitude: "-71.47785000",
  },
  {
    id: 112238,
    name: "Berlin",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "44.46867000",
    longitude: "-71.18508000",
  },
  {
    id: 112582,
    name: "Boscawen",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.31508000",
    longitude: "-71.62091000",
  },
  {
    id: 112620,
    name: "Bow Bog",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.12064000",
    longitude: "-71.51146000",
  },
  {
    id: 112730,
    name: "Brentwood",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.97870000",
    longitude: "-71.07284000",
  },
  {
    id: 112776,
    name: "Bridgewater",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.63841000",
    longitude: "-71.73647000",
  },
  {
    id: 112807,
    name: "Bristol",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.59119000",
    longitude: "-71.73675000",
  },
  {
    id: 112868,
    name: "Brookline",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.73481000",
    longitude: "-71.65813000",
  },
  {
    id: 113323,
    name: "Candia",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.07786000",
    longitude: "-71.27673000",
  },
  {
    id: 113337,
    name: "Canterbury",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.33702000",
    longitude: "-71.56535000",
  },
  {
    id: 113461,
    name: "Carroll County",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.87391000",
    longitude: "-71.20277000",
  },
  {
    id: 113642,
    name: "Center Harbor",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.70980000",
    longitude: "-71.46035000",
  },
  {
    id: 113772,
    name: "Charlestown",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.23869000",
    longitude: "-72.42453000",
  },
  {
    id: 113878,
    name: "Cheshire County",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.91932000",
    longitude: "-72.25118000",
  },
  {
    id: 113887,
    name: "Chester",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.95675000",
    longitude: "-71.25728000",
  },
  {
    id: 113905,
    name: "Chesterfield",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.88730000",
    longitude: "-72.47037000",
  },
  {
    id: 113936,
    name: "Chichester",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.24925000",
    longitude: "-71.39979000",
  },
  {
    id: 114105,
    name: "Claremont",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.37674000",
    longitude: "-72.34676000",
  },
  {
    id: 114397,
    name: "Colebrook",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "44.89449000",
    longitude: "-71.49592000",
  },
  {
    id: 114541,
    name: "Concord",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.20814000",
    longitude: "-71.53757000",
  },
  {
    id: 114574,
    name: "Contoocook",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.22202000",
    longitude: "-71.71397000",
  },
  {
    id: 114585,
    name: "Conway",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.97924000",
    longitude: "-71.12035000",
  },
  {
    id: 114611,
    name: "Coos County",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "44.68960000",
    longitude: "-71.30542000",
  },
  {
    id: 115016,
    name: "Danbury",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.52563000",
    longitude: "-71.86175000",
  },
  {
    id: 115036,
    name: "Danville",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.91259000",
    longitude: "-71.12450000",
  },
  {
    id: 115182,
    name: "Deerfield",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "44.23062000",
    longitude: "-71.61703000",
  },
  {
    id: 115185,
    name: "Deering",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.07314000",
    longitude: "-71.84452000",
  },
  {
    id: 115265,
    name: "Derry",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.88064000",
    longitude: "-71.32729000",
  },
  {
    id: 115267,
    name: "Derry Village",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.89175000",
    longitude: "-71.31201000",
  },
  {
    id: 115450,
    name: "Dover",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.19786000",
    longitude: "-70.87367000",
  },
  {
    id: 115494,
    name: "Dublin",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.90758000",
    longitude: "-72.06258000",
  },
  {
    id: 115571,
    name: "Durham",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.13397000",
    longitude: "-70.92645000",
  },
  {
    id: 115647,
    name: "East Concord",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.24202000",
    longitude: "-71.53813000",
  },
  {
    id: 115698,
    name: "East Kingston",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.92564000",
    longitude: "-71.01672000",
  },
  {
    id: 115711,
    name: "East Merrimack",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.86814000",
    longitude: "-71.48340000",
  },
  {
    id: 115897,
    name: "Effingham",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.76119000",
    longitude: "-70.99645000",
  },
  {
    id: 116127,
    name: "Enfield",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.64063000",
    longitude: "-72.14398000",
  },
  {
    id: 116157,
    name: "Epping",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.03342000",
    longitude: "-71.07423000",
  },
  {
    id: 116158,
    name: "Epsom",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.22286000",
    longitude: "-71.33201000",
  },
  {
    id: 116271,
    name: "Exeter",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.98148000",
    longitude: "-70.94783000",
  },
  {
    id: 116420,
    name: "Farmington",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.38980000",
    longitude: "-71.06506000",
  },
  {
    id: 116537,
    name: "Fitzwilliam",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.78064000",
    longitude: "-72.14175000",
  },
  {
    id: 116813,
    name: "Francestown",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.98758000",
    longitude: "-71.81258000",
  },
  {
    id: 116837,
    name: "Franklin",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.44424000",
    longitude: "-71.64730000",
  },
  {
    id: 116901,
    name: "Freedom",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.81230000",
    longitude: "-71.03562000",
  },
  {
    id: 116922,
    name: "Fremont",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.99092000",
    longitude: "-71.14256000",
  },
  {
    id: 117212,
    name: "Gilford",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.54758000",
    longitude: "-71.40674000",
  },
  {
    id: 117220,
    name: "Gilmanton",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.42425000",
    longitude: "-71.41452000",
  },
  {
    id: 117336,
    name: "Goffstown",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.02036000",
    longitude: "-71.60035000",
  },
  {
    id: 117404,
    name: "Gorham",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "44.38784000",
    longitude: "-71.17313000",
  },
  {
    id: 117430,
    name: "Grafton",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.55868000",
    longitude: "-71.94397000",
  },
  {
    id: 117433,
    name: "Grafton County",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.94074000",
    longitude: "-71.82055000",
  },
  {
    id: 117525,
    name: "Grantham",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.48952000",
    longitude: "-72.13759000",
  },
  {
    id: 117652,
    name: "Greenfield",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.95064000",
    longitude: "-71.87230000",
  },
  {
    id: 117657,
    name: "Greenland",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.03620000",
    longitude: "-70.83283000",
  },
  {
    id: 117692,
    name: "Greenville",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.76731000",
    longitude: "-71.81230000",
  },
  {
    id: 117764,
    name: "Groveton",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "44.59867000",
    longitude: "-71.51120000",
  },
  {
    id: 117925,
    name: "Hampstead",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.87453000",
    longitude: "-71.18117000",
  },
  {
    id: 117934,
    name: "Hampton",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.93759000",
    longitude: "-70.83894000",
  },
  {
    id: 117936,
    name: "Hampton Beach",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.90731000",
    longitude: "-70.81200000",
  },
  {
    id: 117938,
    name: "Hampton Falls",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.91620000",
    longitude: "-70.86366000",
  },
  {
    id: 117972,
    name: "Hanover",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.70229000",
    longitude: "-72.28954000",
  },
  {
    id: 118075,
    name: "Harrisville",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.94508000",
    longitude: "-72.09647000",
  },
  {
    id: 118151,
    name: "Haverhill",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "44.03451000",
    longitude: "-72.06398000",
  },
  {
    id: 118293,
    name: "Henniker",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.17980000",
    longitude: "-71.82230000",
  },
  {
    id: 118431,
    name: "Hill",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.52424000",
    longitude: "-71.70091000",
  },
  {
    id: 118456,
    name: "Hillsborough",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.11410000",
    longitude: "-71.89920000",
  },
  {
    id: 118458,
    name: "Hillsborough County",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.91531000",
    longitude: "-71.71601000",
  },
  {
    id: 118486,
    name: "Hinsdale",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.78619000",
    longitude: "-72.48648000",
  },
  {
    id: 118527,
    name: "Holderness",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.73202000",
    longitude: "-71.58841000",
  },
  {
    id: 118548,
    name: "Hollis",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.74314000",
    longitude: "-71.59174000",
  },
  {
    id: 118631,
    name: "Hooksett",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.09675000",
    longitude: "-71.46507000",
  },
  {
    id: 118657,
    name: "Hopkinton",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.19147000",
    longitude: "-71.67535000",
  },
  {
    id: 118743,
    name: "Hudson",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.76481000",
    longitude: "-71.43979000",
  },
  {
    id: 119109,
    name: "Jaffrey",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.81397000",
    longitude: "-72.02314000",
  },
  {
    id: 119170,
    name: "Jefferson",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "44.41895000",
    longitude: "-71.47453000",
  },
  {
    id: 119421,
    name: "Keene",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.93369000",
    longitude: "-72.27814000",
  },
  {
    id: 119482,
    name: "Kensington",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.92703000",
    longitude: "-70.94394000",
  },
  {
    id: 119625,
    name: "Kingston",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.93648000",
    longitude: "-71.05339000",
  },
  {
    id: 119819,
    name: "Laconia",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.52785000",
    longitude: "-71.47035000",
  },
  {
    id: 120084,
    name: "Lancaster",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "44.48895000",
    longitude: "-71.56925000",
  },
  {
    id: 120291,
    name: "Lebanon",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.64229000",
    longitude: "-72.25176000",
  },
  {
    id: 120304,
    name: "Lee",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.12314000",
    longitude: "-71.01145000",
  },
  {
    id: 120365,
    name: "Lempster",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.23841000",
    longitude: "-72.21064000",
  },
  {
    id: 120607,
    name: "Litchfield",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.84425000",
    longitude: "-71.47979000",
  },
  {
    id: 120635,
    name: "Littleton",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "44.30617000",
    longitude: "-71.77009000",
  },
  {
    id: 120723,
    name: "Londonderry",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.86509000",
    longitude: "-71.37395000",
  },
  {
    id: 120912,
    name: "Lyme",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.80951000",
    longitude: "-72.15592000",
  },
  {
    id: 120918,
    name: "Lyndeborough",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.90758000",
    longitude: "-71.76646000",
  },
  {
    id: 120977,
    name: "Madbury",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.16925000",
    longitude: "-70.92395000",
  },
  {
    id: 120996,
    name: "Madison",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.89924000",
    longitude: "-71.14840000",
  },
  {
    id: 121098,
    name: "Manchester",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.99564000",
    longitude: "-71.45479000",
  },
  {
    id: 121281,
    name: "Marlborough",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.90425000",
    longitude: "-72.20786000",
  },
  {
    id: 121374,
    name: "Mason",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.74370000",
    longitude: "-71.76896000",
  },
  {
    id: 121687,
    name: "Meredith",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.65757000",
    longitude: "-71.50035000",
  },
  {
    id: 121711,
    name: "Merrimack",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.86509000",
    longitude: "-71.49340000",
  },
  {
    id: 121712,
    name: "Merrimack County",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.29765000",
    longitude: "-71.68019000",
  },
  {
    id: 121840,
    name: "Milan",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "44.57339000",
    longitude: "-71.18508000",
  },
  {
    id: 121856,
    name: "Milford",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.83536000",
    longitude: "-71.64896000",
  },
  {
    id: 122094,
    name: "Mont Vernon",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.89453000",
    longitude: "-71.67424000",
  },
  {
    id: 122315,
    name: "Moultonborough",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.75480000",
    longitude: "-71.39674000",
  },
  {
    id: 122571,
    name: "Nashua",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.76537000",
    longitude: "-71.46757000",
  },
  {
    id: 122669,
    name: "New Boston",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.97619000",
    longitude: "-71.69396000",
  },
  {
    id: 122687,
    name: "New Castle",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.07231000",
    longitude: "-70.71616000",
  },
  {
    id: 122701,
    name: "New Durham",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.43675000",
    longitude: "-71.17229000",
  },
  {
    id: 122731,
    name: "New Ipswich",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.74814000",
    longitude: "-71.85424000",
  },
  {
    id: 122744,
    name: "New London",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.41396000",
    longitude: "-71.98508000",
  },
  {
    id: 122818,
    name: "Newbury",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.32146000",
    longitude: "-72.03592000",
  },
  {
    id: 122836,
    name: "Newmarket",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.08286000",
    longitude: "-70.93506000",
  },
  {
    id: 122847,
    name: "Newport",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.36535000",
    longitude: "-72.17342000",
  },
  {
    id: 122866,
    name: "Newton",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.86953000",
    longitude: "-71.03450000",
  },
  {
    id: 123009,
    name: "North Conway",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "44.05368000",
    longitude: "-71.12840000",
  },
  {
    id: 123035,
    name: "North Hampton",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.97259000",
    longitude: "-70.82978000",
  },
  {
    id: 123153,
    name: "Northfield",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.43313000",
    longitude: "-71.59230000",
  },
  {
    id: 123164,
    name: "Northumberland",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "44.56339000",
    longitude: "-71.55870000",
  },
  {
    id: 123180,
    name: "Northwood",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.19425000",
    longitude: "-71.15090000",
  },
  {
    id: 123205,
    name: "Nottingham",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.11453000",
    longitude: "-71.09978000",
  },
  {
    id: 123552,
    name: "Orford",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.90535000",
    longitude: "-72.14009000",
  },
  {
    id: 123618,
    name: "Ossipee",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.68536000",
    longitude: "-71.11673000",
  },
  {
    id: 123991,
    name: "Pelham",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.73453000",
    longitude: "-71.32451000",
  },
  {
    id: 124007,
    name: "Pembroke",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.14675000",
    longitude: "-71.45757000",
  },
  {
    id: 124102,
    name: "Peterborough",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.87064000",
    longitude: "-71.95175000",
  },
  {
    id: 124207,
    name: "Pinardville",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.99425000",
    longitude: "-71.50729000",
  },
  {
    id: 124300,
    name: "Pittsfield",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.30591000",
    longitude: "-71.32423000",
  },
  {
    id: 124337,
    name: "Plaistow",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.83648000",
    longitude: "-71.09478000",
  },
  {
    id: 124406,
    name: "Plymouth",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.75702000",
    longitude: "-71.68813000",
  },
  {
    id: 124570,
    name: "Portsmouth",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.07704000",
    longitude: "-70.75766000",
  },
  {
    id: 124919,
    name: "Raymond",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.03620000",
    longitude: "-71.18340000",
  },
  {
    id: 125093,
    name: "Richmond",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.75481000",
    longitude: "-72.27175000",
  },
  {
    id: 125140,
    name: "Rindge",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.75120000",
    longitude: "-72.00980000",
  },
  {
    id: 125265,
    name: "Rochester",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.30453000",
    longitude: "-70.97562000",
  },
  {
    id: 125307,
    name: "Rockingham County",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.98454000",
    longitude: "-71.08897000",
  },
  {
    id: 125371,
    name: "Rollinsford",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.23620000",
    longitude: "-70.82034000",
  },
  {
    id: 125508,
    name: "Rumney",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.80535000",
    longitude: "-71.81258000",
  },
  {
    id: 125561,
    name: "Rye",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.01342000",
    longitude: "-70.77089000",
  },
  {
    id: 125736,
    name: "Salem",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.78842000",
    longitude: "-71.20089000",
  },
  {
    id: 125758,
    name: "Salisbury",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.38008000",
    longitude: "-71.71702000",
  },
  {
    id: 125856,
    name: "Sanbornton",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.48924000",
    longitude: "-71.58230000",
  },
  {
    id: 125857,
    name: "Sanbornville",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.55425000",
    longitude: "-71.03090000",
  },
  {
    id: 125870,
    name: "Sandown",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.92870000",
    longitude: "-71.18701000",
  },
  {
    id: 125878,
    name: "Sandwich",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.79035000",
    longitude: "-71.41118000",
  },
  {
    id: 126080,
    name: "Seabrook",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.89481000",
    longitude: "-70.87116000",
  },
  {
    id: 126590,
    name: "Somersworth",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.26175000",
    longitude: "-70.86534000",
  },
  {
    id: 126675,
    name: "South Hooksett",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.02647000",
    longitude: "-71.43534000",
  },
  {
    id: 126901,
    name: "Springfield",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.49507000",
    longitude: "-72.03342000",
  },
  {
    id: 127117,
    name: "Strafford",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.32703000",
    longitude: "-71.18423000",
  },
  {
    id: 127118,
    name: "Strafford County",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.29743000",
    longitude: "-71.02940000",
  },
  {
    id: 127125,
    name: "Stratford",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "44.65505000",
    longitude: "-71.55564000",
  },
  {
    id: 127129,
    name: "Stratham Station",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.05287000",
    longitude: "-70.89533000",
  },
  {
    id: 127189,
    name: "Sullivan County",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.36122000",
    longitude: "-72.22240000",
  },
  {
    id: 127244,
    name: "Sunapee",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.38757000",
    longitude: "-72.08786000",
  },
  {
    id: 127247,
    name: "Suncook",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.13064000",
    longitude: "-71.45312000",
  },
  {
    id: 127313,
    name: "Sutton",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.33424000",
    longitude: "-71.95147000",
  },
  {
    id: 127329,
    name: "Swanzey",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.86980000",
    longitude: "-72.28175000",
  },
  {
    id: 127408,
    name: "Tamworth",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.85980000",
    longitude: "-71.26313000",
  },
  {
    id: 127499,
    name: "Temple",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.81814000",
    longitude: "-71.85147000",
  },
  {
    id: 127602,
    name: "Thornton",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.89285000",
    longitude: "-71.67591000",
  },
  {
    id: 127654,
    name: "Tilton",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.44230000",
    longitude: "-71.58896000",
  },
  {
    id: 127655,
    name: "Tilton-Northfield",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.44300000",
    longitude: "-71.59364000",
  },
  {
    id: 127850,
    name: "Troy",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.82397000",
    longitude: "-72.18119000",
  },
  {
    id: 127877,
    name: "Tuftonboro",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.69647000",
    longitude: "-71.22201000",
  },
  {
    id: 128021,
    name: "Unity",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.29396000",
    longitude: "-72.26037000",
  },
  {
    id: 128361,
    name: "Wakefield",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.56813000",
    longitude: "-71.03007000",
  },
  {
    id: 128715,
    name: "Weare",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.09480000",
    longitude: "-71.73063000",
  },
  {
    id: 128732,
    name: "Webster",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.32897000",
    longitude: "-71.71786000",
  },
  {
    id: 128987,
    name: "West Swanzey",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.87008000",
    longitude: "-72.32175000",
  },
  {
    id: 129054,
    name: "Westmoreland",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.96203000",
    longitude: "-72.44231000",
  },
  {
    id: 129165,
    name: "Whitefield",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "44.37312000",
    longitude: "-71.61008000",
  },
  {
    id: 129328,
    name: "Wilmot",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.45174000",
    longitude: "-71.91369000",
  },
  {
    id: 129345,
    name: "Wilton",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.84342000",
    longitude: "-71.73507000",
  },
  {
    id: 129359,
    name: "Winchester",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.77342000",
    longitude: "-72.38314000",
  },
  {
    id: 129372,
    name: "Windham",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "42.80064000",
    longitude: "-71.30423000",
  },
  {
    id: 129478,
    name: "Wolfeboro",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.58397000",
    longitude: "-71.20729000",
  },
  {
    id: 129564,
    name: "Woodstock",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "43.97757000",
    longitude: "-71.68508000",
  },
  {
    id: 129569,
    name: "Woodsville",
    state_id: 1404,
    state_code: "NH",
    country_id: 233,
    country_code: "US",
    latitude: "44.15229000",
    longitude: "-72.03731000",
  },
  {
    id: 110990,
    name: "Absecon",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.42845000",
    longitude: "-74.49571000",
  },
  {
    id: 111195,
    name: "Allendale",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.04149000",
    longitude: "-74.12903000",
  },
  {
    id: 111198,
    name: "Allentown",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.17789000",
    longitude: "-74.58349000",
  },
  {
    id: 111204,
    name: "Alloway",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.56095000",
    longitude: "-75.36242000",
  },
  {
    id: 111220,
    name: "Alpha",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.66704000",
    longitude: "-75.15740000",
  },
  {
    id: 111222,
    name: "Alpine",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.95593000",
    longitude: "-73.93125000",
  },
  {
    id: 111351,
    name: "Annandale",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.64093000",
    longitude: "-74.88128000",
  },
  {
    id: 111508,
    name: "Asbury Park",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.22039000",
    longitude: "-74.01208000",
  },
  {
    id: 111531,
    name: "Ashland",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.86317000",
    longitude: "-75.00600000",
  },
  {
    id: 111570,
    name: "Atco",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.76984000",
    longitude: "-74.88739000",
  },
  {
    id: 111598,
    name: "Atlantic City",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.36415000",
    longitude: "-74.42306000",
  },
  {
    id: 111599,
    name: "Atlantic County",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.46883000",
    longitude: "-74.63373000",
  },
  {
    id: 111600,
    name: "Atlantic Highlands",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.40789000",
    longitude: "-74.03431000",
  },
  {
    id: 111638,
    name: "Audubon",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.89095000",
    longitude: "-75.07295000",
  },
  {
    id: 111643,
    name: "Audubon Park",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.89650000",
    longitude: "-75.08767000",
  },
  {
    id: 111673,
    name: "Avalon",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.10122000",
    longitude: "-74.71766000",
  },
  {
    id: 111677,
    name: "Avenel",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.58038000",
    longitude: "-74.28515000",
  },
  {
    id: 111697,
    name: "Avon-by-the-Sea",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.19234000",
    longitude: "-74.01597000",
  },
  {
    id: 111825,
    name: "Barnegat",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.75318000",
    longitude: "-74.22292000",
  },
  {
    id: 111845,
    name: "Barrington",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.86484000",
    longitude: "-75.05517000",
  },
  {
    id: 111945,
    name: "Bayonne",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.66871000",
    longitude: "-74.11431000",
  },
  {
    id: 111960,
    name: "Bayville",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.90929000",
    longitude: "-74.15486000",
  },
  {
    id: 111964,
    name: "Beach Haven",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.55928000",
    longitude: "-74.24320000",
  },
  {
    id: 111965,
    name: "Beach Haven West",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.66984000",
    longitude: "-74.23181000",
  },
  {
    id: 111967,
    name: "Beachwood",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.93901000",
    longitude: "-74.19292000",
  },
  {
    id: 111983,
    name: "Beattystown",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.81315000",
    longitude: "-74.84294000",
  },
  {
    id: 112015,
    name: "Beckett",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.75400000",
    longitude: "-75.35741000",
  },
  {
    id: 112031,
    name: "Bedminster",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.68066000",
    longitude: "-74.64544000",
  },
  {
    id: 112059,
    name: "Belford",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.42594000",
    longitude: "-74.08681000",
  },
  {
    id: 112106,
    name: "Belleville",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.79371000",
    longitude: "-74.15014000",
  },
  {
    id: 112121,
    name: "Bellmawr",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.86761000",
    longitude: "-75.09462000",
  },
  {
    id: 112133,
    name: "Belmar",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.17845000",
    longitude: "-74.02180000",
  },
  {
    id: 112156,
    name: "Belvidere",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.82982000",
    longitude: "-75.07767000",
  },
  {
    id: 112218,
    name: "Bergen County",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.95977000",
    longitude: "-74.07441000",
  },
  {
    id: 112219,
    name: "Bergenfield",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.92760000",
    longitude: "-73.99736000",
  },
  {
    id: 112226,
    name: "Berkeley Heights",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.68343000",
    longitude: "-74.44265000",
  },
  {
    id: 112235,
    name: "Berlin",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.79123000",
    longitude: "-74.92905000",
  },
  {
    id: 112245,
    name: "Bernardsville",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.71871000",
    longitude: "-74.56932000",
  },
  {
    id: 112301,
    name: "Beverly",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.06539000",
    longitude: "-74.91906000",
  },
  {
    id: 112397,
    name: "Blackwood",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.80234000",
    longitude: "-75.06406000",
  },
  {
    id: 112437,
    name: "Bloomfield",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.80677000",
    longitude: "-74.18542000",
  },
  {
    id: 112448,
    name: "Bloomingdale",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.00204000",
    longitude: "-74.32654000",
  },
  {
    id: 112501,
    name: "Bogota",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.87621000",
    longitude: "-74.02986000",
  },
  {
    id: 112566,
    name: "Boonton",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.90260000",
    longitude: "-74.40710000",
  },
  {
    id: 112576,
    name: "Bordentown",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.14622000",
    longitude: "-74.71183000",
  },
  {
    id: 112608,
    name: "Bound Brook",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.56844000",
    longitude: "-74.53849000",
  },
  {
    id: 112674,
    name: "Bradley Beach",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.20234000",
    longitude: "-74.01208000",
  },
  {
    id: 112677,
    name: "Bradley Gardens",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.56288000",
    longitude: "-74.65460000",
  },
  {
    id: 112698,
    name: "Brass Castle",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.76482000",
    longitude: "-75.01101000",
  },
  {
    id: 112770,
    name: "Bridgeton",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.42734000",
    longitude: "-75.23408000",
  },
  {
    id: 112777,
    name: "Bridgewater",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.60079000",
    longitude: "-74.64815000",
  },
  {
    id: 112781,
    name: "Brielle",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.10789000",
    longitude: "-74.05653000",
  },
  {
    id: 112783,
    name: "Brigantine",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.41012000",
    longitude: "-74.36459000",
  },
  {
    id: 112850,
    name: "Brookdale",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.83371000",
    longitude: "-74.18292000",
  },
  {
    id: 112865,
    name: "Brooklawn",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.87817000",
    longitude: "-75.12073000",
  },
  {
    id: 112921,
    name: "Browns Mills",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.97261000",
    longitude: "-74.58293000",
  },
  {
    id: 112939,
    name: "Brownville",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.40066000",
    longitude: "-74.29515000",
  },
  {
    id: 112994,
    name: "Budd Lake",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.87121000",
    longitude: "-74.73405000",
  },
  {
    id: 112998,
    name: "Buena",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.51373000",
    longitude: "-74.92462000",
  },
  {
    id: 113071,
    name: "Burlington",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.07122000",
    longitude: "-74.86489000",
  },
  {
    id: 113077,
    name: "Burlington County",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.87769000",
    longitude: "-74.66820000",
  },
  {
    id: 113110,
    name: "Butler",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.00371000",
    longitude: "-74.34154000",
  },
  {
    id: 113181,
    name: "Caldwell",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.83982000",
    longitude: "-74.27654000",
  },
  {
    id: 113214,
    name: "Califon",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.71954000",
    longitude: "-74.83572000",
  },
  {
    id: 113263,
    name: "Camden",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.92595000",
    longitude: "-75.11962000",
  },
  {
    id: 113271,
    name: "Camden County",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.80353000",
    longitude: "-74.95976000",
  },
  {
    id: 113369,
    name: "Cape May",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "38.93511000",
    longitude: "-74.90601000",
  },
  {
    id: 113370,
    name: "Cape May County",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.08513000",
    longitude: "-74.84998000",
  },
  {
    id: 113371,
    name: "Cape May Court House",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.08261000",
    longitude: "-74.82378000",
  },
  {
    id: 113410,
    name: "Carlstadt",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.84038000",
    longitude: "-74.09070000",
  },
  {
    id: 113429,
    name: "Carneys Point",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.71122000",
    longitude: "-75.47020000",
  },
  {
    id: 113486,
    name: "Carteret",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.57733000",
    longitude: "-74.22820000",
  },
  {
    id: 113608,
    name: "Cedar Glen Lakes",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.95234000",
    longitude: "-74.39987000",
  },
  {
    id: 113609,
    name: "Cedar Glen West",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.04206000",
    longitude: "-74.29265000",
  },
  {
    id: 113611,
    name: "Cedar Grove",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.85177000",
    longitude: "-74.22903000",
  },
  {
    id: 113800,
    name: "Chatham",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.74093000",
    longitude: "-74.38376000",
  },
  {
    id: 113860,
    name: "Cherry Hill",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.93484000",
    longitude: "-75.03073000",
  },
  {
    id: 113862,
    name: "Cherry Hill Mall",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.93595000",
    longitude: "-75.00906000",
  },
  {
    id: 113880,
    name: "Chesilhurst",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.73234000",
    longitude: "-74.88100000",
  },
  {
    id: 113889,
    name: "Chester",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.78427000",
    longitude: "-74.69683000",
  },
  {
    id: 114027,
    name: "Cinnaminson",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.99678000",
    longitude: "-74.99267000",
  },
  {
    id: 114119,
    name: "Clark",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.64094000",
    longitude: "-74.31070000",
  },
  {
    id: 114201,
    name: "Clayton",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.66011000",
    longitude: "-75.09212000",
  },
  {
    id: 114215,
    name: "Clearbrook Park",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.30983000",
    longitude: "-74.46460000",
  },
  {
    id: 114232,
    name: "Clementon",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.81150000",
    longitude: "-74.98294000",
  },
  {
    id: 114253,
    name: "Cliffside Park",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.82149000",
    longitude: "-73.98764000",
  },
  {
    id: 114254,
    name: "Cliffwood Beach",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.44205000",
    longitude: "-74.21681000",
  },
  {
    id: 114259,
    name: "Clifton",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.85843000",
    longitude: "-74.16376000",
  },
  {
    id: 114284,
    name: "Clinton",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.63677000",
    longitude: "-74.90989000",
  },
  {
    id: 114305,
    name: "Closter",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.97315000",
    longitude: "-73.96153000",
  },
  {
    id: 114428,
    name: "Collings Lakes",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.59567000",
    longitude: "-74.88156000",
  },
  {
    id: 114429,
    name: "Collingswood",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.91817000",
    longitude: "-75.07128000",
  },
  {
    id: 114446,
    name: "Colonia",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.57455000",
    longitude: "-74.30209000",
  },
  {
    id: 114546,
    name: "Concordia",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.31094000",
    longitude: "-74.44821000",
  },
  {
    id: 114722,
    name: "Country Lake Estates",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.94262000",
    longitude: "-74.54404000",
  },
  {
    id: 114764,
    name: "Cranbury",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.31622000",
    longitude: "-74.51376000",
  },
  {
    id: 114767,
    name: "Crandon Lakes",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.12426000",
    longitude: "-74.83989000",
  },
  {
    id: 114771,
    name: "Cranford",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.65844000",
    longitude: "-74.29959000",
  },
  {
    id: 114799,
    name: "Cresskill",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.94149000",
    longitude: "-73.95930000",
  },
  {
    id: 114812,
    name: "Crestwood Village",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.94817000",
    longitude: "-74.36070000",
  },
  {
    id: 114915,
    name: "Cumberland County",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.32807000",
    longitude: "-75.12934000",
  },
  {
    id: 115097,
    name: "Dayton",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.37261000",
    longitude: "-74.51015000",
  },
  {
    id: 115197,
    name: "Delanco",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.05067000",
    longitude: "-74.95350000",
  },
  {
    id: 115230,
    name: "Demarest",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.95732000",
    longitude: "-73.96347000",
  },
  {
    id: 115449,
    name: "Dover",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.88399000",
    longitude: "-74.56210000",
  },
  {
    id: 115454,
    name: "Dover Beaches North",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.99123000",
    longitude: "-74.06375000",
  },
  {
    id: 115455,
    name: "Dover Beaches South",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.95567000",
    longitude: "-74.07430000",
  },
  {
    id: 115518,
    name: "Dumont",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.94065000",
    longitude: "-73.99681000",
  },
  {
    id: 115535,
    name: "Dunellen",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.58927000",
    longitude: "-74.47182000",
  },
  {
    id: 115641,
    name: "East Brunswick",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.42788000",
    longitude: "-74.41598000",
  },
  {
    id: 115664,
    name: "East Franklin",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.49330000",
    longitude: "-74.47110000",
  },
  {
    id: 115665,
    name: "East Freehold",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.28094000",
    longitude: "-74.25126000",
  },
  {
    id: 115682,
    name: "East Hanover",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.82010000",
    longitude: "-74.36487000",
  },
  {
    id: 115720,
    name: "East Newark",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.74843000",
    longitude: "-74.16181000",
  },
  {
    id: 115726,
    name: "East Orange",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.76732000",
    longitude: "-74.20487000",
  },
  {
    id: 115752,
    name: "East Rutherford",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.83399000",
    longitude: "-74.09709000",
  },
  {
    id: 115807,
    name: "Eatontown",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.29622000",
    longitude: "-74.05097000",
  },
  {
    id: 115813,
    name: "Echelon",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.84845000",
    longitude: "-74.99572000",
  },
  {
    id: 115854,
    name: "Edgewater",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.82704000",
    longitude: "-73.97569000",
  },
  {
    id: 115856,
    name: "Edgewater Park",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.06817000",
    longitude: "-74.90072000",
  },
  {
    id: 115875,
    name: "Edison",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.51872000",
    longitude: "-74.41210000",
  },
  {
    id: 115901,
    name: "Egg Harbor City",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.52873000",
    longitude: "-74.64794000",
  },
  {
    id: 115972,
    name: "Elizabeth",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.66399000",
    longitude: "-74.21070000",
  },
  {
    id: 116033,
    name: "Ellisburg",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.91372000",
    longitude: "-75.01045000",
  },
  {
    id: 116050,
    name: "Elmer",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.59511000",
    longitude: "-75.17018000",
  },
  {
    id: 116063,
    name: "Elmwood Park",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.90399000",
    longitude: "-74.11848000",
  },
  {
    id: 116078,
    name: "Elwood",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.57651000",
    longitude: "-74.71683000",
  },
  {
    id: 116094,
    name: "Emerson",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.97621000",
    longitude: "-74.02625000",
  },
  {
    id: 116132,
    name: "Englewood",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.89288000",
    longitude: "-73.97264000",
  },
  {
    id: 116134,
    name: "Englewood Cliffs",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.88538000",
    longitude: "-73.95236000",
  },
  {
    id: 116136,
    name: "Englishtown",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.29733000",
    longitude: "-74.35820000",
  },
  {
    id: 116173,
    name: "Erma",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "38.98776000",
    longitude: "-74.90170000",
  },
  {
    id: 116195,
    name: "Essex County",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.78707000",
    longitude: "-74.24687000",
  },
  {
    id: 116198,
    name: "Essex Fells",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.82454000",
    longitude: "-74.28459000",
  },
  {
    id: 116204,
    name: "Estell Manor",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.41206000",
    longitude: "-74.74239000",
  },
  {
    id: 116268,
    name: "Ewing",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.26983000",
    longitude: "-74.79988000",
  },
  {
    id: 116282,
    name: "Fair Haven",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.36067000",
    longitude: "-74.03819000",
  },
  {
    id: 116284,
    name: "Fair Lawn",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.94038000",
    longitude: "-74.13181000",
  },
  {
    id: 116315,
    name: "Fairfield",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.88371000",
    longitude: "-74.30598000",
  },
  {
    id: 116346,
    name: "Fairton",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.38178000",
    longitude: "-75.21991000",
  },
  {
    id: 116352,
    name: "Fairview",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.81260000",
    longitude: "-73.99903000",
  },
  {
    id: 116394,
    name: "Fanwood",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.64094000",
    longitude: "-74.38348000",
  },
  {
    id: 116410,
    name: "Farmingdale",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.19650000",
    longitude: "-74.16848000",
  },
  {
    id: 116515,
    name: "Finderne",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.56316000",
    longitude: "-74.57766000",
  },
  {
    id: 116562,
    name: "Flemington",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.51233000",
    longitude: "-74.85933000",
  },
  {
    id: 116582,
    name: "Florence",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.73428000",
    longitude: "-74.91822000",
  },
  {
    id: 116591,
    name: "Florham Park",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.78788000",
    longitude: "-74.38821000",
  },
  {
    id: 116624,
    name: "Folsom",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.60206000",
    longitude: "-74.84267000",
  },
  {
    id: 116640,
    name: "Fords",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.52927000",
    longitude: "-74.31598000",
  },
  {
    id: 116674,
    name: "Forked River",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.83984000",
    longitude: "-74.19014000",
  },
  {
    id: 116706,
    name: "Fort Dix",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.02984000",
    longitude: "-74.61849000",
  },
  {
    id: 116723,
    name: "Fort Lee",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.85093000",
    longitude: "-73.97014000",
  },
  {
    id: 116838,
    name: "Franklin",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.12204000",
    longitude: "-74.58044000",
  },
  {
    id: 116842,
    name: "Franklin Center",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.53153000",
    longitude: "-74.54141000",
  },
  {
    id: 116866,
    name: "Franklin Lakes",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.01676000",
    longitude: "-74.20570000",
  },
  {
    id: 116869,
    name: "Franklin Park",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.43899000",
    longitude: "-74.53515000",
  },
  {
    id: 116904,
    name: "Freehold",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.26011000",
    longitude: "-74.27376000",
  },
  {
    id: 116934,
    name: "Frenchtown",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.52621000",
    longitude: "-75.06156000",
  },
  {
    id: 117081,
    name: "Garfield",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.88149000",
    longitude: "-74.11320000",
  },
  {
    id: 117105,
    name: "Garwood",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.65177000",
    longitude: "-74.32293000",
  },
  {
    id: 117183,
    name: "Gibbsboro",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.83817000",
    longitude: "-74.96489000",
  },
  {
    id: 117184,
    name: "Gibbstown",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.82511000",
    longitude: "-75.28352000",
  },
  {
    id: 117238,
    name: "Gladstone",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.72260000",
    longitude: "-74.66544000",
  },
  {
    id: 117251,
    name: "Glassboro",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.70289000",
    longitude: "-75.11184000",
  },
  {
    id: 117266,
    name: "Glen Gardner",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.69677000",
    longitude: "-74.94072000",
  },
  {
    id: 117271,
    name: "Glen Ridge",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.80538000",
    longitude: "-74.20376000",
  },
  {
    id: 117273,
    name: "Glen Rock",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.96288000",
    longitude: "-74.13292000",
  },
  {
    id: 117290,
    name: "Glendora",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.83956000",
    longitude: "-75.07351000",
  },
  {
    id: 117323,
    name: "Gloucester City",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.89178000",
    longitude: "-75.11629000",
  },
  {
    id: 117324,
    name: "Gloucester County",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.71731000",
    longitude: "-75.14167000",
  },
  {
    id: 117354,
    name: "Golden Triangle",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.92789000",
    longitude: "-75.03878000",
  },
  {
    id: 117600,
    name: "Green Knoll",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.60010000",
    longitude: "-74.61210000",
  },
  {
    id: 117675,
    name: "Greentree",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.89706000",
    longitude: "-74.95572000",
  },
  {
    id: 117766,
    name: "Groveville",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.16983000",
    longitude: "-74.67155000",
  },
  {
    id: 117817,
    name: "Guttenberg",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.79205000",
    longitude: "-74.00375000",
  },
  {
    id: 117828,
    name: "Hackensack",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.88593000",
    longitude: "-74.04347000",
  },
  {
    id: 117829,
    name: "Hackettstown",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.85399000",
    longitude: "-74.82906000",
  },
  {
    id: 117831,
    name: "Haddon Heights",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.87734000",
    longitude: "-75.06462000",
  },
  {
    id: 117832,
    name: "Haddonfield",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.89150000",
    longitude: "-75.03767000",
  },
  {
    id: 117850,
    name: "Haledon",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.93565000",
    longitude: "-74.18626000",
  },
  {
    id: 117883,
    name: "Hamburg",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.15343000",
    longitude: "-74.57627000",
  },
  {
    id: 117905,
    name: "Hamilton Square",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.22733000",
    longitude: "-74.65321000",
  },
  {
    id: 117915,
    name: "Hammonton",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.63651000",
    longitude: "-74.80239000",
  },
  {
    id: 117933,
    name: "Hampton",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.70704000",
    longitude: "-74.95600000",
  },
  {
    id: 117973,
    name: "Hanover",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.80454000",
    longitude: "-74.36682000",
  },
  {
    id: 118009,
    name: "Hardwick",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.05454000",
    longitude: "-74.93212000",
  },
  {
    id: 118042,
    name: "Harrington Park",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.98371000",
    longitude: "-73.97986000",
  },
  {
    id: 118058,
    name: "Harrison",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.74649000",
    longitude: "-74.15626000",
  },
  {
    id: 118121,
    name: "Hasbrouck Heights",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.85816000",
    longitude: "-74.08070000",
  },
  {
    id: 118170,
    name: "Haworth",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.96093000",
    longitude: "-73.99014000",
  },
  {
    id: 118173,
    name: "Hawthorne",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.94926000",
    longitude: "-74.15375000",
  },
  {
    id: 118223,
    name: "Heathcote",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.38872000",
    longitude: "-74.57571000",
  },
  {
    id: 118260,
    name: "Helmetta",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.37677000",
    longitude: "-74.42460000",
  },
  {
    id: 118385,
    name: "High Bridge",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.66705000",
    longitude: "-74.89572000",
  },
  {
    id: 118407,
    name: "Highland Lake",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.17676000",
    longitude: "-74.45655000",
  },
  {
    id: 118413,
    name: "Highland Park",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.49594000",
    longitude: "-74.42432000",
  },
  {
    id: 118418,
    name: "Highlands",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.40372000",
    longitude: "-73.99153000",
  },
  {
    id: 118424,
    name: "Hightstown",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.26955000",
    longitude: "-74.52321000",
  },
  {
    id: 118461,
    name: "Hillsdale",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.00260000",
    longitude: "-74.04042000",
  },
  {
    id: 118464,
    name: "Hillside",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.70121000",
    longitude: "-74.23015000",
  },
  {
    id: 118494,
    name: "Ho-Ho-Kus",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.99649000",
    longitude: "-74.10125000",
  },
  {
    id: 118502,
    name: "Hoboken",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.74399000",
    longitude: "-74.03236000",
  },
  {
    id: 118530,
    name: "Holiday City South",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.95324000",
    longitude: "-74.23778000",
  },
  {
    id: 118531,
    name: "Holiday City-Berkeley",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.96380000",
    longitude: "-74.27803000",
  },
  {
    id: 118532,
    name: "Holiday Heights",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.94595000",
    longitude: "-74.25403000",
  },
  {
    id: 118640,
    name: "Hopatcong",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.93288000",
    longitude: "-74.65933000",
  },
  {
    id: 118641,
    name: "Hopatcong Hills",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.94399000",
    longitude: "-74.67072000",
  },
  {
    id: 118650,
    name: "Hopewell",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.38927000",
    longitude: "-74.76183000",
  },
  {
    id: 118749,
    name: "Hudson County",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.73094000",
    longitude: "-74.07594000",
  },
  {
    id: 118789,
    name: "Hunterdon County",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.56729000",
    longitude: "-74.91222000",
  },
  {
    id: 119009,
    name: "Irvington",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.73232000",
    longitude: "-74.23487000",
  },
  {
    id: 119019,
    name: "Iselin",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.57538000",
    longitude: "-74.32237000",
  },
  {
    id: 119025,
    name: "Island Heights",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.94206000",
    longitude: "-74.14986000",
  },
  {
    id: 119066,
    name: "Jackson",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.77650000",
    longitude: "-74.86238000",
  },
  {
    id: 119118,
    name: "Jamesburg",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.35261000",
    longitude: "-74.44015000",
  },
  {
    id: 119231,
    name: "Jersey City",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.72816000",
    longitude: "-74.07764000",
  },
  {
    id: 119406,
    name: "Keansburg",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.44177000",
    longitude: "-74.12986000",
  },
  {
    id: 119412,
    name: "Kearny",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.76843000",
    longitude: "-74.14542000",
  },
  {
    id: 119449,
    name: "Kendall Park",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.42094000",
    longitude: "-74.56071000",
  },
  {
    id: 119457,
    name: "Kenilworth",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.67649000",
    longitude: "-74.29070000",
  },
  {
    id: 119502,
    name: "Kenvil",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.87982000",
    longitude: "-74.61849000",
  },
  {
    id: 119544,
    name: "Keyport",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.43316000",
    longitude: "-74.19959000",
  },
  {
    id: 119626,
    name: "Kingston",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.37538000",
    longitude: "-74.61349000",
  },
  {
    id: 119631,
    name: "Kingston Estates",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.92372000",
    longitude: "-74.98795000",
  },
  {
    id: 119639,
    name: "Kinnelon",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.00176000",
    longitude: "-74.36710000",
  },
  {
    id: 119888,
    name: "Lake Como",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.15984000",
    longitude: "-74.02819000",
  },
  {
    id: 119944,
    name: "Lake Mohawk",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.01843000",
    longitude: "-74.66016000",
  },
  {
    id: 119985,
    name: "Lake Telemark",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.95677000",
    longitude: "-74.49793000",
  },
  {
    id: 120011,
    name: "Lakehurst",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.01456000",
    longitude: "-74.31126000",
  },
  {
    id: 120048,
    name: "Lakewood",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.09789000",
    longitude: "-74.21764000",
  },
  {
    id: 120069,
    name: "Lambertville",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.36594000",
    longitude: "-74.94294000",
  },
  {
    id: 120098,
    name: "Landing",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.90510000",
    longitude: "-74.66516000",
  },
  {
    id: 120199,
    name: "Laurel Lake",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.33956000",
    longitude: "-75.02990000",
  },
  {
    id: 120201,
    name: "Laurel Springs",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.82011000",
    longitude: "-75.00628000",
  },
  {
    id: 120205,
    name: "Laurence Harbor",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.45677000",
    longitude: "-74.24653000",
  },
  {
    id: 120216,
    name: "Lavallette",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.97040000",
    longitude: "-74.06875000",
  },
  {
    id: 120223,
    name: "Lawnside",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.86650000",
    longitude: "-75.02823000",
  },
  {
    id: 120246,
    name: "Lawrenceville",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.29733000",
    longitude: "-74.72960000",
  },
  {
    id: 120292,
    name: "Lebanon",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.64177000",
    longitude: "-74.83600000",
  },
  {
    id: 120341,
    name: "Leisure Knoll",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.01901000",
    longitude: "-74.29209000",
  },
  {
    id: 120342,
    name: "Leisure Village",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.04262000",
    longitude: "-74.18486000",
  },
  {
    id: 120343,
    name: "Leisure Village East",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.03012000",
    longitude: "-74.16431000",
  },
  {
    id: 120344,
    name: "Leisure Village West-Pine Lake Park",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.00416000",
    longitude: "-74.26629000",
  },
  {
    id: 120346,
    name: "Leisuretowne",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.89234000",
    longitude: "-74.70210000",
  },
  {
    id: 120389,
    name: "Leonardo",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.41733000",
    longitude: "-74.06208000",
  },
  {
    id: 120391,
    name: "Leonia",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.86149000",
    longitude: "-73.98819000",
  },
  {
    id: 120542,
    name: "Lincoln Park",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.92427000",
    longitude: "-74.30209000",
  },
  {
    id: 120556,
    name: "Lincroft",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.33067000",
    longitude: "-74.12097000",
  },
  {
    id: 120564,
    name: "Linden",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.62205000",
    longitude: "-74.24459000",
  },
  {
    id: 120569,
    name: "Lindenwold",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.82428000",
    longitude: "-74.99767000",
  },
  {
    id: 120592,
    name: "Linwood",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.33984000",
    longitude: "-74.57516000",
  },
  {
    id: 120618,
    name: "Little Falls",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.86899000",
    longitude: "-74.20820000",
  },
  {
    id: 120620,
    name: "Little Ferry",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.85288000",
    longitude: "-74.04208000",
  },
  {
    id: 120630,
    name: "Little Silver",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.33678000",
    longitude: "-74.04708000",
  },
  {
    id: 120651,
    name: "Livingston",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.79593000",
    longitude: "-74.31487000",
  },
  {
    id: 120689,
    name: "Lodi",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.88232000",
    longitude: "-74.08320000",
  },
  {
    id: 120740,
    name: "Long Branch",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.30428000",
    longitude: "-73.99236000",
  },
  {
    id: 120750,
    name: "Long Valley",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.78593000",
    longitude: "-74.78016000",
  },
  {
    id: 120921,
    name: "Lyndhurst",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.81204000",
    longitude: "-74.12431000",
  },
  {
    id: 120998,
    name: "Madison",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.75982000",
    longitude: "-74.41710000",
  },
  {
    id: 121024,
    name: "Madison Park",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.45150000",
    longitude: "-74.30792000",
  },
  {
    id: 121037,
    name: "Magnolia",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.85456000",
    longitude: "-75.03906000",
  },
  {
    id: 121048,
    name: "Mahwah",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.08871000",
    longitude: "-74.14376000",
  },
  {
    id: 121079,
    name: "Manahawkin",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.69540000",
    longitude: "-74.25875000",
  },
  {
    id: 121081,
    name: "Manasquan",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.12623000",
    longitude: "-74.04930000",
  },
  {
    id: 121162,
    name: "Manville",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.54094000",
    longitude: "-74.58766000",
  },
  {
    id: 121172,
    name: "Maple Shade",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.95261000",
    longitude: "-74.99239000",
  },
  {
    id: 121179,
    name: "Maplewood",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.73121000",
    longitude: "-74.27348000",
  },
  {
    id: 121207,
    name: "Margate City",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.32789000",
    longitude: "-74.50349000",
  },
  {
    id: 121274,
    name: "Marlboro",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.31539000",
    longitude: "-74.24626000",
  },
  {
    id: 121288,
    name: "Marlton",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.89122000",
    longitude: "-74.92183000",
  },
  {
    id: 121351,
    name: "Martinsville",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.60121000",
    longitude: "-74.55905000",
  },
  {
    id: 121400,
    name: "Matawan",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.41483000",
    longitude: "-74.22959000",
  },
  {
    id: 121447,
    name: "Mays Landing",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.45234000",
    longitude: "-74.72766000",
  },
  {
    id: 121458,
    name: "Maywood",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.90260000",
    longitude: "-74.06181000",
  },
  {
    id: 121507,
    name: "McGuire AFB",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.03977000",
    longitude: "-74.58174000",
  },
  {
    id: 121597,
    name: "Medford Lakes",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.85845000",
    longitude: "-74.80294000",
  },
  {
    id: 121651,
    name: "Mendham",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.77593000",
    longitude: "-74.60071000",
  },
  {
    id: 121679,
    name: "Mercer County",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.28340000",
    longitude: "-74.70169000",
  },
  {
    id: 121684,
    name: "Mercerville",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.23705000",
    longitude: "-74.68655000",
  },
  {
    id: 121685,
    name: "Mercerville-Hamilton Square",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.23126000",
    longitude: "-74.67223000",
  },
  {
    id: 121686,
    name: "Merchantville",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.94734000",
    longitude: "-75.06656000",
  },
  {
    id: 121738,
    name: "Metuchen",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.54316000",
    longitude: "-74.36320000",
  },
  {
    id: 121774,
    name: "Middlebush",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.49760000",
    longitude: "-74.52932000",
  },
  {
    id: 121777,
    name: "Middlesex",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.57260000",
    longitude: "-74.49265000",
  },
  {
    id: 121781,
    name: "Middlesex County",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.44004000",
    longitude: "-74.40889000",
  },
  {
    id: 121806,
    name: "Midland Park",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.98926000",
    longitude: "-74.14070000",
  },
  {
    id: 121854,
    name: "Milford",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.56871000",
    longitude: "-75.09462000",
  },
  {
    id: 121902,
    name: "Milltown",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.45622000",
    longitude: "-74.44321000",
  },
  {
    id: 121904,
    name: "Millville",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.40206000",
    longitude: "-75.03934000",
  },
  {
    id: 122045,
    name: "Monmouth Beach",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.33039000",
    longitude: "-73.98153000",
  },
  {
    id: 122046,
    name: "Monmouth County",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.28755000",
    longitude: "-74.15815000",
  },
  {
    id: 122047,
    name: "Monmouth Junction",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.37900000",
    longitude: "-74.54654000",
  },
  {
    id: 122106,
    name: "Montclair",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.82593000",
    longitude: "-74.20903000",
  },
  {
    id: 122187,
    name: "Montvale",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.04676000",
    longitude: "-74.02292000",
  },
  {
    id: 122198,
    name: "Moonachie",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.84121000",
    longitude: "-74.04514000",
  },
  {
    id: 122208,
    name: "Moorestown-Lenola",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.96591000",
    longitude: "-74.96441000",
  },
  {
    id: 122254,
    name: "Morganville",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.37650000",
    longitude: "-74.24431000",
  },
  {
    id: 122271,
    name: "Morris County",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.86203000",
    longitude: "-74.54444000",
  },
  {
    id: 122274,
    name: "Morris Plains",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.82177000",
    longitude: "-74.48099000",
  },
  {
    id: 122282,
    name: "Morristown",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.79677000",
    longitude: "-74.48154000",
  },
  {
    id: 122333,
    name: "Mount Arlington",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.92593000",
    longitude: "-74.63488000",
  },
  {
    id: 122342,
    name: "Mount Ephraim",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.87845000",
    longitude: "-75.09267000",
  },
  {
    id: 122348,
    name: "Mount Holly",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.99289000",
    longitude: "-74.78766000",
  },
  {
    id: 122361,
    name: "Mount Laurel",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.93400000",
    longitude: "-74.89100000",
  },
  {
    id: 122422,
    name: "Mountain Lakes",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.89482000",
    longitude: "-74.43293000",
  },
  {
    id: 122438,
    name: "Mountainside",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.67232000",
    longitude: "-74.35737000",
  },
  {
    id: 122461,
    name: "Mullica Hill",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.73928000",
    longitude: "-75.22407000",
  },
  {
    id: 122527,
    name: "Mystic Island",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.54428000",
    longitude: "-74.38237000",
  },
  {
    id: 122593,
    name: "National Park",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.86595000",
    longitude: "-75.17879000",
  },
  {
    id: 122604,
    name: "Navesink",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.39955000",
    longitude: "-74.03542000",
  },
  {
    id: 122638,
    name: "Neptune City",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.20011000",
    longitude: "-74.02792000",
  },
  {
    id: 122645,
    name: "Netcong",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.89899000",
    longitude: "-74.70655000",
  },
  {
    id: 122677,
    name: "New Brunswick",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.48622000",
    longitude: "-74.45182000",
  },
  {
    id: 122703,
    name: "New Egypt",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.06761000",
    longitude: "-74.53071000",
  },
  {
    id: 122755,
    name: "New Milford",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.93510000",
    longitude: "-74.01903000",
  },
  {
    id: 122768,
    name: "New Providence",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.69843000",
    longitude: "-74.40154000",
  },
  {
    id: 122803,
    name: "Newark",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.73566000",
    longitude: "-74.17237000",
  },
  {
    id: 122828,
    name: "Newfield",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.54688000",
    longitude: "-75.02636000",
  },
  {
    id: 122867,
    name: "Newton",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.05815000",
    longitude: "-74.75267000",
  },
  {
    id: 122965,
    name: "North Arlington",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.78843000",
    longitude: "-74.13320000",
  },
  {
    id: 122978,
    name: "North Beach Haven",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.57317000",
    longitude: "-74.23153000",
  },
  {
    id: 122987,
    name: "North Bergen",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.80427000",
    longitude: "-74.01208000",
  },
  {
    id: 122999,
    name: "North Caldwell",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.86482000",
    longitude: "-74.25820000",
  },
  {
    id: 123000,
    name: "North Cape May",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "38.98206000",
    longitude: "-74.95795000",
  },
  {
    id: 123034,
    name: "North Haledon",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.95510000",
    longitude: "-74.18598000",
  },
  {
    id: 123068,
    name: "North Middletown",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.43955000",
    longitude: "-74.11903000",
  },
  {
    id: 123080,
    name: "North Plainfield",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.63010000",
    longitude: "-74.42737000",
  },
  {
    id: 123129,
    name: "North Wildwood",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.00067000",
    longitude: "-74.79933000",
  },
  {
    id: 123149,
    name: "Northfield",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.37039000",
    longitude: "-74.55015000",
  },
  {
    id: 123169,
    name: "Northvale",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.00649000",
    longitude: "-73.94903000",
  },
  {
    id: 123200,
    name: "Norwood",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.99815000",
    longitude: "-73.96180000",
  },
  {
    id: 123219,
    name: "Nutley",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.82232000",
    longitude: "-74.15987000",
  },
  {
    id: 123265,
    name: "Oak Valley",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.80122000",
    longitude: "-75.16240000",
  },
  {
    id: 123280,
    name: "Oakhurst",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.27094000",
    longitude: "-74.01625000",
  },
  {
    id: 123290,
    name: "Oakland",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.01315000",
    longitude: "-74.26431000",
  },
  {
    id: 123301,
    name: "Oaklyn",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.90095000",
    longitude: "-75.08462000",
  },
  {
    id: 123323,
    name: "Ocean Acres",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.74345000",
    longitude: "-74.28098000",
  },
  {
    id: 123327,
    name: "Ocean City",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.27762000",
    longitude: "-74.57460000",
  },
  {
    id: 123328,
    name: "Ocean County",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.86600000",
    longitude: "-74.25003000",
  },
  {
    id: 123329,
    name: "Ocean Gate",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.92679000",
    longitude: "-74.13375000",
  },
  {
    id: 123331,
    name: "Ocean Grove",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.21206000",
    longitude: "-74.00653000",
  },
  {
    id: 123342,
    name: "Oceanport",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.31817000",
    longitude: "-74.01514000",
  },
  {
    id: 123371,
    name: "Ogdensburg",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.08176000",
    longitude: "-74.59238000",
  },
  {
    id: 123416,
    name: "Old Bridge",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.41483000",
    longitude: "-74.36543000",
  },
  {
    id: 123429,
    name: "Old Tappan",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.01065000",
    longitude: "-73.99125000",
  },
  {
    id: 123445,
    name: "Olivet",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.54817000",
    longitude: "-75.15463000",
  },
  {
    id: 123503,
    name: "Oradell",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.95871000",
    longitude: "-74.03681000",
  },
  {
    id: 123509,
    name: "Orange",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.77066000",
    longitude: "-74.23265000",
  },
  {
    id: 123686,
    name: "Oxford",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.80315000",
    longitude: "-74.98962000",
  },
  {
    id: 123739,
    name: "Palisades Park",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.84816000",
    longitude: "-73.99764000",
  },
  {
    id: 123775,
    name: "Palmyra",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.00706000",
    longitude: "-75.02823000",
  },
  {
    id: 123826,
    name: "Paramus",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.94454000",
    longitude: "-74.07542000",
  },
  {
    id: 123852,
    name: "Park Ridge",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.03760000",
    longitude: "-74.04070000",
  },
  {
    id: 123890,
    name: "Parsippany",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.85788000",
    longitude: "-74.42599000",
  },
  {
    id: 123907,
    name: "Passaic",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.85677000",
    longitude: "-74.12848000",
  },
  {
    id: 123908,
    name: "Passaic County",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.03370000",
    longitude: "-74.30032000",
  },
  {
    id: 123911,
    name: "Paterson",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.91677000",
    longitude: "-74.17181000",
  },
  {
    id: 123926,
    name: "Paulsboro",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.83039000",
    longitude: "-75.24046000",
  },
  {
    id: 123961,
    name: "Peapack",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.71677000",
    longitude: "-74.65655000",
  },
  {
    id: 124000,
    name: "Pemberton",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.97206000",
    longitude: "-74.68294000",
  },
  {
    id: 124001,
    name: "Pemberton Heights",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.96261000",
    longitude: "-74.67877000",
  },
  {
    id: 124029,
    name: "Pennington",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.32844000",
    longitude: "-74.79072000",
  },
  {
    id: 124033,
    name: "Penns Grove",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.72956000",
    longitude: "-75.46797000",
  },
  {
    id: 124034,
    name: "Pennsauken",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.95622000",
    longitude: "-75.05795000",
  },
  {
    id: 124039,
    name: "Pennsville",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.65345000",
    longitude: "-75.51659000",
  },
  {
    id: 124094,
    name: "Perth Amboy",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.50677000",
    longitude: "-74.26542000",
  },
  {
    id: 124140,
    name: "Phillipsburg",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.69371000",
    longitude: "-75.19018000",
  },
  {
    id: 124213,
    name: "Pine Beach",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.93595000",
    longitude: "-74.17097000",
  },
  {
    id: 124224,
    name: "Pine Hill",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.78428000",
    longitude: "-74.99211000",
  },
  {
    id: 124233,
    name: "Pine Lake Park",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.00317000",
    longitude: "-74.25653000",
  },
  {
    id: 124245,
    name: "Pine Ridge at Crestwood",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.95456000",
    longitude: "-74.31515000",
  },
  {
    id: 124282,
    name: "Piscataway",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.49927000",
    longitude: "-74.39904000",
  },
  {
    id: 124287,
    name: "Pitman",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.73289000",
    longitude: "-75.13157000",
  },
  {
    id: 124321,
    name: "Plainfield",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.63371000",
    longitude: "-74.40737000",
  },
  {
    id: 124327,
    name: "Plainsboro Center",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.33177000",
    longitude: "-74.59460000",
  },
  {
    id: 124386,
    name: "Pleasantville",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.38984000",
    longitude: "-74.52404000",
  },
  {
    id: 124431,
    name: "Point Pleasant",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.08317000",
    longitude: "-74.06819000",
  },
  {
    id: 124432,
    name: "Point Pleasant Beach",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.09123000",
    longitude: "-74.04791000",
  },
  {
    id: 124457,
    name: "Pomona",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.47845000",
    longitude: "-74.57516000",
  },
  {
    id: 124462,
    name: "Pompton Lakes",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.00538000",
    longitude: "-74.29070000",
  },
  {
    id: 124519,
    name: "Port Monmouth",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.43011000",
    longitude: "-74.09847000",
  },
  {
    id: 124522,
    name: "Port Norris",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.24595000",
    longitude: "-75.03518000",
  },
  {
    id: 124527,
    name: "Port Reading",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.56538000",
    longitude: "-74.26042000",
  },
  {
    id: 124528,
    name: "Port Republic",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.52067000",
    longitude: "-74.48571000",
  },
  {
    id: 124646,
    name: "Presidential Lakes Estates",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.91373000",
    longitude: "-74.56460000",
  },
  {
    id: 124689,
    name: "Princeton",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.34872000",
    longitude: "-74.65905000",
  },
  {
    id: 124692,
    name: "Princeton Junction",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.31733000",
    longitude: "-74.61988000",
  },
  {
    id: 124693,
    name: "Princeton Meadows",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.33177000",
    longitude: "-74.56377000",
  },
  {
    id: 124710,
    name: "Prospect Park",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.93704000",
    longitude: "-74.17431000",
  },
  {
    id: 124822,
    name: "Rahway",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.60816000",
    longitude: "-74.27765000",
  },
  {
    id: 124837,
    name: "Ramblewood",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.92872000",
    longitude: "-74.94378000",
  },
  {
    id: 124842,
    name: "Ramsey",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.05732000",
    longitude: "-74.14098000",
  },
  {
    id: 124845,
    name: "Ramtown",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.12095000",
    longitude: "-74.14375000",
  },
  {
    id: 124871,
    name: "Randolph",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.84829000",
    longitude: "-74.58148000",
  },
  {
    id: 124899,
    name: "Raritan",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.56955000",
    longitude: "-74.63294000",
  },
  {
    id: 124938,
    name: "Red Bank",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.34705000",
    longitude: "-74.06431000",
  },
  {
    id: 125110,
    name: "Richwood",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.72261000",
    longitude: "-75.16546000",
  },
  {
    id: 125120,
    name: "Ridgefield",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.83427000",
    longitude: "-74.00875000",
  },
  {
    id: 125122,
    name: "Ridgefield Park",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.85704000",
    longitude: "-74.02153000",
  },
  {
    id: 125131,
    name: "Ridgewood",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.97926000",
    longitude: "-74.11653000",
  },
  {
    id: 125145,
    name: "Ringwood",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.11343000",
    longitude: "-74.24543000",
  },
  {
    id: 125153,
    name: "Rio Grande",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.01456000",
    longitude: "-74.88156000",
  },
  {
    id: 125177,
    name: "River Edge",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.92871000",
    longitude: "-74.03986000",
  },
  {
    id: 125188,
    name: "River Vale",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.99538000",
    longitude: "-74.01208000",
  },
  {
    id: 125194,
    name: "Riverdale",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.99399000",
    longitude: "-74.30348000",
  },
  {
    id: 125212,
    name: "Riverton",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.01150000",
    longitude: "-75.01489000",
  },
  {
    id: 125238,
    name: "Robbinsville",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.21455000",
    longitude: "-74.61932000",
  },
  {
    id: 125248,
    name: "Robertsville",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.34622000",
    longitude: "-74.28792000",
  },
  {
    id: 125260,
    name: "Rochelle Park",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.90732000",
    longitude: "-74.07514000",
  },
  {
    id: 125289,
    name: "Rockaway",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.90121000",
    longitude: "-74.51432000",
  },
  {
    id: 125343,
    name: "Roebling",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.11594000",
    longitude: "-74.78627000",
  },
  {
    id: 125423,
    name: "Roseland",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.82066000",
    longitude: "-74.29376000",
  },
  {
    id: 125427,
    name: "Roselle",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.65223000",
    longitude: "-74.25882000",
  },
  {
    id: 125428,
    name: "Roselle Park",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.66455000",
    longitude: "-74.26431000",
  },
  {
    id: 125436,
    name: "Rosenhayn",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.47817000",
    longitude: "-75.13129000",
  },
  {
    id: 125454,
    name: "Rossmoor",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.33650000",
    longitude: "-74.47349000",
  },
  {
    id: 125509,
    name: "Rumson",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.37205000",
    longitude: "-73.99903000",
  },
  {
    id: 125513,
    name: "Runnemede",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.85234000",
    longitude: "-75.06795000",
  },
  {
    id: 125549,
    name: "Rutherford",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.82649000",
    longitude: "-74.10681000",
  },
  {
    id: 125579,
    name: "Saddle Brook",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.89899000",
    longitude: "-74.09264000",
  },
  {
    id: 125581,
    name: "Saddle River",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.03176000",
    longitude: "-74.10209000",
  },
  {
    id: 125729,
    name: "Salem",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.57178000",
    longitude: "-75.46714000",
  },
  {
    id: 125741,
    name: "Salem County",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.57658000",
    longitude: "-75.35791000",
  },
  {
    id: 125991,
    name: "Sayreville",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.45927000",
    longitude: "-74.36098000",
  },
  {
    id: 125992,
    name: "Sayreville Junction",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.46538000",
    longitude: "-74.33043000",
  },
  {
    id: 126032,
    name: "Scotch Plains",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.65538000",
    longitude: "-74.38987000",
  },
  {
    id: 126071,
    name: "Sea Bright",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.36150000",
    longitude: "-73.97403000",
  },
  {
    id: 126073,
    name: "Sea Girt",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.13206000",
    longitude: "-74.03458000",
  },
  {
    id: 126074,
    name: "Sea Isle City",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.15345000",
    longitude: "-74.69294000",
  },
  {
    id: 126081,
    name: "Seabrook Farms",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.50095000",
    longitude: "-75.21796000",
  },
  {
    id: 126101,
    name: "Seaside Heights",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.94429000",
    longitude: "-74.07291000",
  },
  {
    id: 126102,
    name: "Seaside Park",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.92679000",
    longitude: "-74.07708000",
  },
  {
    id: 126112,
    name: "Secaucus",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.78955000",
    longitude: "-74.05653000",
  },
  {
    id: 126190,
    name: "Sewaren",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.55205000",
    longitude: "-74.25876000",
  },
  {
    id: 126225,
    name: "Shark River Hills",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.19400000",
    longitude: "-74.04875000",
  },
  {
    id: 126356,
    name: "Ship Bottom",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.64290000",
    longitude: "-74.18042000",
  },
  {
    id: 126376,
    name: "Short Hills",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.74788000",
    longitude: "-74.32543000",
  },
  {
    id: 126386,
    name: "Shrewsbury",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.32955000",
    longitude: "-74.06153000",
  },
  {
    id: 126393,
    name: "Sicklerville",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.71734000",
    longitude: "-74.96933000",
  },
  {
    id: 126433,
    name: "Silver Ridge",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.95928000",
    longitude: "-74.21848000",
  },
  {
    id: 126454,
    name: "Singac",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.88677000",
    longitude: "-74.24098000",
  },
  {
    id: 126473,
    name: "Sixmile Run",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.45761000",
    longitude: "-74.51154000",
  },
  {
    id: 126527,
    name: "Smithville",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.49401000",
    longitude: "-74.45709000",
  },
  {
    id: 126556,
    name: "Society Hill",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.53399000",
    longitude: "-74.45793000",
  },
  {
    id: 126574,
    name: "Somerdale",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.84400000",
    longitude: "-75.02267000",
  },
  {
    id: 126578,
    name: "Somers Point",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.31762000",
    longitude: "-74.59460000",
  },
  {
    id: 126583,
    name: "Somerset",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.49760000",
    longitude: "-74.48849000",
  },
  {
    id: 126588,
    name: "Somerset County",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.56351000",
    longitude: "-74.61631000",
  },
  {
    id: 126596,
    name: "Somerville",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.57427000",
    longitude: "-74.60988000",
  },
  {
    id: 126612,
    name: "South Amboy",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.47788000",
    longitude: "-74.29070000",
  },
  {
    id: 126622,
    name: "South Belmar",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.17095000",
    longitude: "-74.02736000",
  },
  {
    id: 126630,
    name: "South Bound Brook",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.55344000",
    longitude: "-74.53154000",
  },
  {
    id: 126697,
    name: "South Old Bridge",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.40816000",
    longitude: "-74.35432000",
  },
  {
    id: 126698,
    name: "South Orange",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.74899000",
    longitude: "-74.26126000",
  },
  {
    id: 126711,
    name: "South Plainfield",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.57927000",
    longitude: "-74.41154000",
  },
  {
    id: 126717,
    name: "South River",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.44649000",
    longitude: "-74.38598000",
  },
  {
    id: 126736,
    name: "South Toms River",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.94206000",
    longitude: "-74.20431000",
  },
  {
    id: 126743,
    name: "South Vineland",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.44595000",
    longitude: "-75.02879000",
  },
  {
    id: 126812,
    name: "Sparta",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.03343000",
    longitude: "-74.63849000",
  },
  {
    id: 126846,
    name: "Spotswood",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.39177000",
    longitude: "-74.39848000",
  },
  {
    id: 126867,
    name: "Spring Lake",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.15345000",
    longitude: "-74.02819000",
  },
  {
    id: 126868,
    name: "Spring Lake Heights",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.15039000",
    longitude: "-74.03097000",
  },
  {
    id: 126885,
    name: "Springdale",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.90261000",
    longitude: "-74.96628000",
  },
  {
    id: 126900,
    name: "Springfield",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.70491000",
    longitude: "-74.31723000",
  },
  {
    id: 126956,
    name: "Stanhope",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.90288000",
    longitude: "-74.70905000",
  },
  {
    id: 127122,
    name: "Stratford",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.82678000",
    longitude: "-75.01545000",
  },
  {
    id: 127130,
    name: "Strathmore",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.39594000",
    longitude: "-74.21348000",
  },
  {
    id: 127160,
    name: "Succasunna",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.86843000",
    longitude: "-74.64044000",
  },
  {
    id: 127213,
    name: "Summit",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.71562000",
    longitude: "-74.36468000",
  },
  {
    id: 127286,
    name: "Surf City",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.66206000",
    longitude: "-74.16514000",
  },
  {
    id: 127301,
    name: "Sussex",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.20982000",
    longitude: "-74.60766000",
  },
  {
    id: 127305,
    name: "Sussex County",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.13946000",
    longitude: "-74.69023000",
  },
  {
    id: 127334,
    name: "Swedesboro",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.74761000",
    longitude: "-75.31047000",
  },
  {
    id: 127475,
    name: "Teaneck",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.89760000",
    longitude: "-74.01597000",
  },
  {
    id: 127506,
    name: "Ten Mile Run",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.41301000",
    longitude: "-74.60223000",
  },
  {
    id: 127507,
    name: "Tenafly",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.92538000",
    longitude: "-73.96292000",
  },
  {
    id: 127667,
    name: "Tinton Falls",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.30428000",
    longitude: "-74.10042000",
  },
  {
    id: 127716,
    name: "Toms River",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.95373000",
    longitude: "-74.19792000",
  },
  {
    id: 127747,
    name: "Totowa",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.90510000",
    longitude: "-74.20987000",
  },
  {
    id: 127808,
    name: "Trenton",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.21705000",
    longitude: "-74.74294000",
  },
  {
    id: 127873,
    name: "Tuckerton",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.60317000",
    longitude: "-74.34015000",
  },
  {
    id: 127906,
    name: "Turnersville",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.77317000",
    longitude: "-75.05128000",
  },
  {
    id: 127934,
    name: "Twin Rivers",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.26400000",
    longitude: "-74.49126000",
  },
  {
    id: 127977,
    name: "Union",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.69760000",
    longitude: "-74.26320000",
  },
  {
    id: 127979,
    name: "Union Beach",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.44650000",
    longitude: "-74.17820000",
  },
  {
    id: 127985,
    name: "Union City",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.77955000",
    longitude: "-74.02375000",
  },
  {
    id: 127999,
    name: "Union County",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.65980000",
    longitude: "-74.30859000",
  },
  {
    id: 128047,
    name: "Upper Montclair",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.84621000",
    longitude: "-74.20126000",
  },
  {
    id: 128049,
    name: "Upper Pohatcong",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.67747000",
    longitude: "-75.15580000",
  },
  {
    id: 128050,
    name: "Upper Saddle River",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.05843000",
    longitude: "-74.09848000",
  },
  {
    id: 128174,
    name: "Ventnor City",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.34039000",
    longitude: "-74.47737000",
  },
  {
    id: 128191,
    name: "Vernon Center",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.18879000",
    longitude: "-74.50405000",
  },
  {
    id: 128196,
    name: "Vernon Valley",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.23676000",
    longitude: "-74.48710000",
  },
  {
    id: 128203,
    name: "Verona",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.82982000",
    longitude: "-74.24015000",
  },
  {
    id: 128225,
    name: "Victory Gardens",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.87593000",
    longitude: "-74.54238000",
  },
  {
    id: 128226,
    name: "Victory Lakes",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.63317000",
    longitude: "-74.96600000",
  },
  {
    id: 128258,
    name: "Villas",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.02872000",
    longitude: "-74.93851000",
  },
  {
    id: 128266,
    name: "Vincentown",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.93400000",
    longitude: "-74.74849000",
  },
  {
    id: 128270,
    name: "Vineland",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.48623000",
    longitude: "-75.02573000",
  },
  {
    id: 128296,
    name: "Vista Center",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.15928000",
    longitude: "-74.31792000",
  },
  {
    id: 128306,
    name: "Voorhees",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.48122000",
    longitude: "-74.48321000",
  },
  {
    id: 128378,
    name: "Waldwick",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.01065000",
    longitude: "-74.11792000",
  },
  {
    id: 128408,
    name: "Wallington",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.85316000",
    longitude: "-74.11375000",
  },
  {
    id: 128447,
    name: "Wanamassa",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.23178000",
    longitude: "-74.02542000",
  },
  {
    id: 128449,
    name: "Wanaque",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.03815000",
    longitude: "-74.29404000",
  },
  {
    id: 128466,
    name: "Waretown",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.79151000",
    longitude: "-74.19514000",
  },
  {
    id: 128496,
    name: "Warren County",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.85725000",
    longitude: "-74.99702000",
  },
  {
    id: 128500,
    name: "Warren Township",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.60822000",
    longitude: "-74.51803000",
  },
  {
    id: 128548,
    name: "Washington",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.75843000",
    longitude: "-74.97934000",
  },
  {
    id: 128598,
    name: "Watchung",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.63788000",
    longitude: "-74.45099000",
  },
  {
    id: 128685,
    name: "Wayne",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.92538000",
    longitude: "-74.27654000",
  },
  {
    id: 128754,
    name: "Weehawken",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.76955000",
    longitude: "-74.02042000",
  },
  {
    id: 128803,
    name: "Wenonah",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.79456000",
    longitude: "-75.14879000",
  },
  {
    id: 128824,
    name: "West Belmar",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.16928000",
    longitude: "-74.03542000",
  },
  {
    id: 128840,
    name: "West Cape May",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "38.93872000",
    longitude: "-74.94184000",
  },
  {
    id: 128874,
    name: "West Freehold",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.24206000",
    longitude: "-74.30126000",
  },
  {
    id: 128922,
    name: "West Long Branch",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.29039000",
    longitude: "-74.01764000",
  },
  {
    id: 128932,
    name: "West Milford",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.13121000",
    longitude: "-74.36737000",
  },
  {
    id: 128938,
    name: "West New York",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.78788000",
    longitude: "-74.01431000",
  },
  {
    id: 128946,
    name: "West Orange",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.79871000",
    longitude: "-74.23904000",
  },
  {
    id: 129029,
    name: "Westfield",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.65899000",
    longitude: "-74.34737000",
  },
  {
    id: 129062,
    name: "Weston",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.53510000",
    longitude: "-74.59071000",
  },
  {
    id: 129076,
    name: "Westville",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.86789000",
    longitude: "-75.13156000",
  },
  {
    id: 129086,
    name: "Westwood",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.99121000",
    longitude: "-74.03264000",
  },
  {
    id: 129103,
    name: "Wharton",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.89315000",
    longitude: "-74.58183000",
  },
  {
    id: 129142,
    name: "White Horse",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.19067000",
    longitude: "-74.70238000",
  },
  {
    id: 129146,
    name: "White Meadow Lake",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.92371000",
    longitude: "-74.51071000",
  },
  {
    id: 129175,
    name: "Whitehouse Station",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.61538000",
    longitude: "-74.77044000",
  },
  {
    id: 129181,
    name: "Whitesboro",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.03900000",
    longitude: "-74.85684000",
  },
  {
    id: 129184,
    name: "Whitesboro-Burleigh",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.04305000",
    longitude: "-74.86538000",
  },
  {
    id: 129211,
    name: "Whittingham",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.32982000",
    longitude: "-74.44511000",
  },
  {
    id: 129238,
    name: "Wildwood",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "38.99178000",
    longitude: "-74.81489000",
  },
  {
    id: 129241,
    name: "Wildwood Crest",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "38.97484000",
    longitude: "-74.83350000",
  },
  {
    id: 129286,
    name: "Williamstown",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.68623000",
    longitude: "-74.99517000",
  },
  {
    id: 129294,
    name: "Willingboro",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.02789000",
    longitude: "-74.86905000",
  },
  {
    id: 129399,
    name: "Winfield",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.64260000",
    longitude: "-74.28543000",
  },
  {
    id: 129491,
    name: "Wood-Lynne",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.91734000",
    longitude: "-75.09629000",
  },
  {
    id: 129492,
    name: "Wood-Ridge",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.84566000",
    longitude: "-74.08792000",
  },
  {
    id: 129495,
    name: "Woodbine",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.24178000",
    longitude: "-74.81517000",
  },
  {
    id: 129501,
    name: "Woodbridge",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.55760000",
    longitude: "-74.28459000",
  },
  {
    id: 129506,
    name: "Woodbury",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.83817000",
    longitude: "-75.15268000",
  },
  {
    id: 129513,
    name: "Woodbury Heights",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.81706000",
    longitude: "-75.15518000",
  },
  {
    id: 129514,
    name: "Woodcliff Lake",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.02343000",
    longitude: "-74.06653000",
  },
  {
    id: 129532,
    name: "Woodland Park",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.88982000",
    longitude: "-74.19487000",
  },
  {
    id: 129568,
    name: "Woodstown",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "39.65150000",
    longitude: "-75.32825000",
  },
  {
    id: 129623,
    name: "Wyckoff",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "41.00954000",
    longitude: "-74.17292000",
  },
  {
    id: 129665,
    name: "Yardville",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.18122000",
    longitude: "-74.66432000",
  },
  {
    id: 129701,
    name: "Yorketown",
    state_id: 1417,
    state_code: "NJ",
    country_id: 233,
    country_code: "US",
    latitude: "40.30789000",
    longitude: "-74.33765000",
  },
  {
    id: 111060,
    name: "Agua Fria",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.65448000",
    longitude: "-106.02224000",
  },
  {
    id: 111094,
    name: "Alamo",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "34.42089000",
    longitude: "-107.51088000",
  },
  {
    id: 111097,
    name: "Alamogordo",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.89953000",
    longitude: "-105.96027000",
  },
  {
    id: 111130,
    name: "Albuquerque",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.08449000",
    longitude: "-106.65114000",
  },
  {
    id: 111335,
    name: "Angel Fire",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "36.39309000",
    longitude: "-105.28501000",
  },
  {
    id: 111369,
    name: "Anthony",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.00399000",
    longitude: "-106.60583000",
  },
  {
    id: 111446,
    name: "Arenas Valley",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.79396000",
    longitude: "-108.18421000",
  },
  {
    id: 111495,
    name: "Arroyo Seco",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "36.51669000",
    longitude: "-105.56918000",
  },
  {
    id: 111497,
    name: "Artesia",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.84233000",
    longitude: "-104.40330000",
  },
  {
    id: 111605,
    name: "Atoka",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.76956000",
    longitude: "-104.38885000",
  },
  {
    id: 111712,
    name: "Aztec",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "36.82223000",
    longitude: "-107.99285000",
  },
  {
    id: 111937,
    name: "Bayard",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.76174000",
    longitude: "-108.13060000",
  },
  {
    id: 112054,
    name: "Belen",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "34.66284000",
    longitude: "-106.77642000",
  },
  {
    id: 112220,
    name: "Berino",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.07093000",
    longitude: "-106.62138000",
  },
  {
    id: 112242,
    name: "Bernalillo",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.30004000",
    longitude: "-106.55114000",
  },
  {
    id: 112243,
    name: "Bernalillo County",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.05131000",
    longitude: "-106.67017000",
  },
  {
    id: 112384,
    name: "Black Rock",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.08837000",
    longitude: "-108.79119000",
  },
  {
    id: 112440,
    name: "Bloomfield",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "36.71112000",
    longitude: "-107.98451000",
  },
  {
    id: 112512,
    name: "Boles Acres",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.80703000",
    longitude: "-105.98610000",
  },
  {
    id: 112585,
    name: "Bosque Farms",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "34.85478000",
    longitude: "-106.70530000",
  },
  {
    id: 113331,
    name: "Cannon Air Force Base",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "34.39689000",
    longitude: "-103.32444000",
  },
  {
    id: 113374,
    name: "Capitan",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "33.54536000",
    longitude: "-105.57220000",
  },
  {
    id: 113409,
    name: "Carlsbad",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.42067000",
    longitude: "-104.22884000",
  },
  {
    id: 113431,
    name: "Carnuel",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.06394000",
    longitude: "-106.45725000",
  },
  {
    id: 113448,
    name: "Carrizozo",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "33.64174000",
    longitude: "-105.87721000",
  },
  {
    id: 113578,
    name: "Catron County",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "33.91533000",
    longitude: "-108.40473000",
  },
  {
    id: 113717,
    name: "Chama",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "36.90307000",
    longitude: "-106.57948000",
  },
  {
    id: 113740,
    name: "Chaparral",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.02376000",
    longitude: "-106.38566000",
  },
  {
    id: 113817,
    name: "Chaves County",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "33.36319000",
    longitude: "-104.46700000",
  },
  {
    id: 113958,
    name: "Chimayo",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "36.00391000",
    longitude: "-105.94697000",
  },
  {
    id: 114010,
    name: "Church Rock",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.53391000",
    longitude: "-108.59980000",
  },
  {
    id: 114017,
    name: "Cibola County",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "34.91257000",
    longitude: "-107.99971000",
  },
  {
    id: 114204,
    name: "Clayton",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "36.45169000",
    longitude: "-103.18410000",
  },
  {
    id: 114316,
    name: "Clovis",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "34.40480000",
    longitude: "-103.20523000",
  },
  {
    id: 114411,
    name: "Colfax County",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "36.60612000",
    longitude: "-104.64686000",
  },
  {
    id: 114498,
    name: "Columbus",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "31.82760000",
    longitude: "-107.64002000",
  },
  {
    id: 114666,
    name: "Corrales",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.23782000",
    longitude: "-106.60669000",
  },
  {
    id: 114868,
    name: "Crownpoint",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.67808000",
    longitude: "-108.15118000",
  },
  {
    id: 114927,
    name: "Curry County",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "34.57416000",
    longitude: "-103.34691000",
  },
  {
    id: 115104,
    name: "De Baca County",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "34.34239000",
    longitude: "-104.41200000",
  },
  {
    id: 115231,
    name: "Deming",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.26870000",
    longitude: "-107.75864000",
  },
  {
    id: 115311,
    name: "Dexter",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "33.19733000",
    longitude: "-104.37302000",
  },
  {
    id: 115464,
    name: "Doña Ana",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.38954000",
    longitude: "-106.81390000",
  },
  {
    id: 115465,
    name: "Doña Ana County",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.35268000",
    longitude: "-106.83280000",
  },
  {
    id: 115510,
    name: "Dulce",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "36.93362000",
    longitude: "-106.99893000",
  },
  {
    id: 115824,
    name: "Eddy County",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.47149000",
    longitude: "-104.30431000",
  },
  {
    id: 115863,
    name: "Edgewood",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.06144000",
    longitude: "-106.19141000",
  },
  {
    id: 115917,
    name: "El Cerro",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "34.72700000",
    longitude: "-106.71086000",
  },
  {
    id: 115918,
    name: "El Cerro Mission",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "34.76227000",
    longitude: "-106.64450000",
  },
  {
    id: 115934,
    name: "El Rancho",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.88919000",
    longitude: "-106.07975000",
  },
  {
    id: 115939,
    name: "El Valle de Arroyo Seco",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.96280000",
    longitude: "-106.02947000",
  },
  {
    id: 115955,
    name: "Eldorado at Santa Fe",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.52642000",
    longitude: "-105.93474000",
  },
  {
    id: 115961,
    name: "Elephant Butte",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "33.14868000",
    longitude: "-107.18475000",
  },
  {
    id: 116117,
    name: "Enchanted Hills",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.33676000",
    longitude: "-106.59296000",
  },
  {
    id: 116188,
    name: "Española",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.99113000",
    longitude: "-106.08058000",
  },
  {
    id: 116203,
    name: "Estancia",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "34.75839000",
    longitude: "-106.05585000",
  },
  {
    id: 116230,
    name: "Eunice",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.43734000",
    longitude: "-103.15908000",
  },
  {
    id: 116421,
    name: "Farmington",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "36.72806000",
    longitude: "-108.21869000",
  },
  {
    id: 116573,
    name: "Flora Vista",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "36.79445000",
    longitude: "-108.08035000",
  },
  {
    id: 116753,
    name: "Fort Sumner",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "34.47173000",
    longitude: "-104.24553000",
  },
  {
    id: 117039,
    name: "Gallup",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.52808000",
    longitude: "-108.74258000",
  },
  {
    id: 117517,
    name: "Grant County",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.73901000",
    longitude: "-108.38225000",
  },
  {
    id: 117527,
    name: "Grants",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.14760000",
    longitude: "-107.85261000",
  },
  {
    id: 117779,
    name: "Guadalupe County",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "34.86333000",
    longitude: "-104.79070000",
  },
  {
    id: 117836,
    name: "Hagerman",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "33.11511000",
    longitude: "-104.32691000",
  },
  {
    id: 118004,
    name: "Harding County",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.85794000",
    longitude: "-103.81993000",
  },
  {
    id: 118137,
    name: "Hatch",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.66536000",
    longitude: "-107.15307000",
  },
  {
    id: 118375,
    name: "Hidalgo County",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "31.91416000",
    longitude: "-108.71482000",
  },
  {
    id: 118500,
    name: "Hobbs",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.70261000",
    longitude: "-103.13604000",
  },
  {
    id: 118554,
    name: "Holloman Air Force Base",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.84827000",
    longitude: "-106.09977000",
  },
  {
    id: 118822,
    name: "Hurley",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.69924000",
    longitude: "-108.13199000",
  },
  {
    id: 119110,
    name: "Jal",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.11318000",
    longitude: "-103.19351000",
  },
  {
    id: 119133,
    name: "Jarales",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "34.61312000",
    longitude: "-106.76364000",
  },
  {
    id: 119210,
    name: "Jemez Pueblo",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.61422000",
    longitude: "-106.72832000",
  },
  {
    id: 119419,
    name: "Keeler Farm",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.31607000",
    longitude: "-107.76023000",
  },
  {
    id: 119655,
    name: "Kirtland",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "36.73417000",
    longitude: "-108.35980000",
  },
  {
    id: 119739,
    name: "La Cienega",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.56281000",
    longitude: "-106.13086000",
  },
  {
    id: 119759,
    name: "La Huerta",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.44290000",
    longitude: "-104.22106000",
  },
  {
    id: 119763,
    name: "La Luz",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.97787000",
    longitude: "-105.94193000",
  },
  {
    id: 119766,
    name: "La Mesilla",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.94836000",
    longitude: "-106.07058000",
  },
  {
    id: 119781,
    name: "La Puebla",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.98919000",
    longitude: "-105.99641000",
  },
  {
    id: 119790,
    name: "La Union",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "31.95066000",
    longitude: "-106.66166000",
  },
  {
    id: 119850,
    name: "Laguna",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.03671000",
    longitude: "-107.38282000",
  },
  {
    id: 120155,
    name: "Las Cruces",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.31232000",
    longitude: "-106.77834000",
  },
  {
    id: 120159,
    name: "Las Maravillas",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "34.73815000",
    longitude: "-106.66854000",
  },
  {
    id: 120164,
    name: "Las Vegas",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.59393000",
    longitude: "-105.22390000",
  },
  {
    id: 120267,
    name: "Lea County",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.79218000",
    longitude: "-103.41245000",
  },
  {
    id: 120305,
    name: "Lee Acres",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.14866000",
    longitude: "-106.64697000",
  },
  {
    id: 120531,
    name: "Lincoln County",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "33.74524000",
    longitude: "-105.45925000",
  },
  {
    id: 120770,
    name: "Lordsburg",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.35036000",
    longitude: "-108.70866000",
  },
  {
    id: 120780,
    name: "Los Alamos",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.88808000",
    longitude: "-106.30697000",
  },
  {
    id: 120781,
    name: "Los Alamos County",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.86937000",
    longitude: "-106.30729000",
  },
  {
    id: 120787,
    name: "Los Chavez",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "34.72571000",
    longitude: "-106.75726000",
  },
  {
    id: 120791,
    name: "Los Lunas",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "34.80617000",
    longitude: "-106.73336000",
  },
  {
    id: 120795,
    name: "Los Ranchos de Albuquerque",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.16199000",
    longitude: "-106.64280000",
  },
  {
    id: 120828,
    name: "Loving",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.28623000",
    longitude: "-104.09577000",
  },
  {
    id: 120832,
    name: "Lovington",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.94401000",
    longitude: "-103.34855000",
  },
  {
    id: 120884,
    name: "Luna County",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.18215000",
    longitude: "-107.74977000",
  },
  {
    id: 121511,
    name: "McIntosh",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "34.86478000",
    longitude: "-106.05169000",
  },
  {
    id: 121522,
    name: "McKinley County",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.58061000",
    longitude: "-108.26193000",
  },
  {
    id: 121560,
    name: "Meadow Lake",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "34.80144000",
    longitude: "-106.54363000",
  },
  {
    id: 121724,
    name: "Mescalero",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "33.15759000",
    longitude: "-105.77415000",
  },
  {
    id: 121725,
    name: "Mesilla",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.27009000",
    longitude: "-106.80084000",
  },
  {
    id: 121728,
    name: "Mesquite",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.16454000",
    longitude: "-106.69666000",
  },
  {
    id: 121841,
    name: "Milan",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.16976000",
    longitude: "-107.89089000",
  },
  {
    id: 122123,
    name: "Monterey Park",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "34.75898000",
    longitude: "-106.64085000",
  },
  {
    id: 122219,
    name: "Mora",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.97420000",
    longitude: "-105.33001000",
  },
  {
    id: 122220,
    name: "Mora County",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "36.00995000",
    longitude: "-104.94458000",
  },
  {
    id: 122255,
    name: "Moriarty",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "34.99005000",
    longitude: "-106.04919000",
  },
  {
    id: 122306,
    name: "Mosquero",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.77698000",
    longitude: "-103.95664000",
  },
  {
    id: 122537,
    name: "Nambe",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.89336000",
    longitude: "-105.98252000",
  },
  {
    id: 122597,
    name: "Navajo",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.90001000",
    longitude: "-109.03398000",
  },
  {
    id: 123119,
    name: "North Valley",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.17338000",
    longitude: "-106.62336000",
  },
  {
    id: 123386,
    name: "Ohkay Owingeh",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "36.05082000",
    longitude: "-106.06897000",
  },
  {
    id: 123626,
    name: "Otero County",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.61316000",
    longitude: "-105.74168000",
  },
  {
    id: 123821,
    name: "Paradise Hills",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.20060000",
    longitude: "-106.70114000",
  },
  {
    id: 123979,
    name: "Pecos",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.57420000",
    longitude: "-105.67502000",
  },
  {
    id: 124057,
    name: "Peralta",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "34.83700000",
    longitude: "-106.69058000",
  },
  {
    id: 124314,
    name: "Placitas",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.30698000",
    longitude: "-106.42475000",
  },
  {
    id: 124436,
    name: "Pojoaque",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.89280000",
    longitude: "-106.02308000",
  },
  {
    id: 124471,
    name: "Ponderosa Pine",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "34.97699000",
    longitude: "-106.32419000",
  },
  {
    id: 124550,
    name: "Portales",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "34.18619000",
    longitude: "-103.33440000",
  },
  {
    id: 124784,
    name: "Quay County",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.10436000",
    longitude: "-103.54974000",
  },
  {
    id: 124794,
    name: "Questa",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "36.70391000",
    longitude: "-105.59501000",
  },
  {
    id: 124817,
    name: "Radium Springs",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.50120000",
    longitude: "-106.92807000",
  },
  {
    id: 124860,
    name: "Ranchos de Taos",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "36.35864000",
    longitude: "-105.60946000",
  },
  {
    id: 124901,
    name: "Raton",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "36.90336000",
    longitude: "-104.43915000",
  },
  {
    id: 125035,
    name: "Reserve",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "33.71311000",
    longitude: "-108.75784000",
  },
  {
    id: 125147,
    name: "Rio Arriba County",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "36.50957000",
    longitude: "-106.69311000",
  },
  {
    id: 125150,
    name: "Rio Communities",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "34.64959000",
    longitude: "-106.73403000",
  },
  {
    id: 125158,
    name: "Rio Rancho",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.23338000",
    longitude: "-106.66447000",
  },
  {
    id: 125393,
    name: "Roosevelt County",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "34.02127000",
    longitude: "-103.48018000",
  },
  {
    id: 125463,
    name: "Roswell",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "33.39437000",
    longitude: "-104.52491000",
  },
  {
    id: 125504,
    name: "Ruidoso",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "33.33175000",
    longitude: "-105.67304000",
  },
  {
    id: 125505,
    name: "Ruidoso Downs",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "33.32897000",
    longitude: "-105.60443000",
  },
  {
    id: 125807,
    name: "San Felipe Pueblo",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.43392000",
    longitude: "-106.44669000",
  },
  {
    id: 125821,
    name: "San Juan County",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "36.50847000",
    longitude: "-108.32060000",
  },
  {
    id: 125839,
    name: "San Miguel",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.15538000",
    longitude: "-106.73500000",
  },
  {
    id: 125841,
    name: "San Miguel County",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.48029000",
    longitude: "-104.81585000",
  },
  {
    id: 125851,
    name: "San Ysidro",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.35093000",
    longitude: "-106.81112000",
  },
  {
    id: 125866,
    name: "Sandia Heights",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.17699000",
    longitude: "-106.49141000",
  },
  {
    id: 125867,
    name: "Sandia Knolls",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.16393000",
    longitude: "-106.31141000",
  },
  {
    id: 125869,
    name: "Sandoval County",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.68855000",
    longitude: "-106.86584000",
  },
  {
    id: 125904,
    name: "Santa Clara",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.77952000",
    longitude: "-108.15032000",
  },
  {
    id: 125907,
    name: "Santa Clara Pueblo",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.96558000",
    longitude: "-106.08863000",
  },
  {
    id: 125914,
    name: "Santa Fe",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.68698000",
    longitude: "-105.93780000",
  },
  {
    id: 125915,
    name: "Santa Fe County",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.50686000",
    longitude: "-105.97612000",
  },
  {
    id: 125923,
    name: "Santa Rosa",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "34.93867000",
    longitude: "-104.68249000",
  },
  {
    id: 125926,
    name: "Santa Teresa",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "31.85594000",
    longitude: "-106.63916000",
  },
  {
    id: 125931,
    name: "Santo Domingo Pueblo",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.51476000",
    longitude: "-106.36586000",
  },
  {
    id: 126358,
    name: "Shiprock",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "36.78555000",
    longitude: "-108.68703000",
  },
  {
    id: 126403,
    name: "Sierra County",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "33.13047000",
    longitude: "-107.19250000",
  },
  {
    id: 126422,
    name: "Silver City",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.77007000",
    longitude: "-108.28033000",
  },
  {
    id: 126488,
    name: "Skyline-Ganipa",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.03279000",
    longitude: "-107.61396000",
  },
  {
    id: 126557,
    name: "Socorro",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "34.05840000",
    longitude: "-106.89142000",
  },
  {
    id: 126559,
    name: "Socorro County",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "34.00724000",
    longitude: "-106.93033000",
  },
  {
    id: 126740,
    name: "South Valley",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.01005000",
    longitude: "-106.67808000",
  },
  {
    id: 126832,
    name: "Spencerville",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "36.82000000",
    longitude: "-108.05813000",
  },
  {
    id: 127254,
    name: "Sunland Park",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "31.79650000",
    longitude: "-106.57999000",
  },
  {
    id: 127422,
    name: "Taos",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "36.40725000",
    longitude: "-105.57307000",
  },
  {
    id: 127423,
    name: "Taos County",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "36.57830000",
    longitude: "-105.63097000",
  },
  {
    id: 127424,
    name: "Taos Pueblo",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "36.43864000",
    longitude: "-105.54445000",
  },
  {
    id: 127549,
    name: "Texico",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "34.38869000",
    longitude: "-103.05134000",
  },
  {
    id: 127598,
    name: "Thoreau",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.40253000",
    longitude: "-108.22340000",
  },
  {
    id: 127636,
    name: "Tierra Amarilla",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "36.70029000",
    longitude: "-106.54976000",
  },
  {
    id: 127712,
    name: "Tome",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "34.74089000",
    longitude: "-106.72836000",
  },
  {
    id: 127743,
    name: "Torrance County",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "34.64047000",
    longitude: "-105.85078000",
  },
  {
    id: 127861,
    name: "Truth or Consequences",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "33.12840000",
    longitude: "-107.25281000",
  },
  {
    id: 127876,
    name: "Tucumcari",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.17191000",
    longitude: "-103.72686000",
  },
  {
    id: 127884,
    name: "Tularosa",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "33.07397000",
    longitude: "-106.01860000",
  },
  {
    id: 127932,
    name: "Twin Lakes",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.70919000",
    longitude: "-108.77481000",
  },
  {
    id: 128002,
    name: "Union County",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "36.48176000",
    longitude: "-103.47099000",
  },
  {
    id: 128034,
    name: "University Park",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.28343000",
    longitude: "-106.75334000",
  },
  {
    id: 128043,
    name: "Upper Fruitland",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "36.71584000",
    longitude: "-108.31424000",
  },
  {
    id: 128074,
    name: "Vado",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.11176000",
    longitude: "-106.66250000",
  },
  {
    id: 128088,
    name: "Valencia",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "34.79950000",
    longitude: "-106.70030000",
  },
  {
    id: 128089,
    name: "Valencia County",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "34.71545000",
    longitude: "-106.80911000",
  },
  {
    id: 128604,
    name: "Waterflow",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "36.75972000",
    longitude: "-108.48175000",
  },
  {
    id: 128884,
    name: "West Hammond",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "36.68071000",
    longitude: "-108.04921000",
  },
  {
    id: 129158,
    name: "White Rock",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.82753000",
    longitude: "-106.20391000",
  },
  {
    id: 129160,
    name: "White Sands",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "32.38093000",
    longitude: "-106.47944000",
  },
  {
    id: 129762,
    name: "Zuni Pueblo",
    state_id: 1423,
    state_code: "NM",
    country_id: 233,
    country_code: "US",
    latitude: "35.07253000",
    longitude: "-108.85064000",
  },
  {
    id: 111014,
    name: "Adams",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.80923000",
    longitude: "-76.02409000",
  },
  {
    id: 111016,
    name: "Adams Center",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.86006000",
    longitude: "-76.00548000",
  },
  {
    id: 111037,
    name: "Addison",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.10285000",
    longitude: "-77.23359000",
  },
  {
    id: 111071,
    name: "Airmont",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.10093000",
    longitude: "-74.11625000",
  },
  {
    id: 111078,
    name: "Akron",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.02089000",
    longitude: "-78.49530000",
  },
  {
    id: 111082,
    name: "Alabama",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.09645000",
    longitude: "-78.39086000",
  },
  {
    id: 111108,
    name: "Albany",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.65258000",
    longitude: "-73.75623000",
  },
  {
    id: 111112,
    name: "Albany County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.60018000",
    longitude: "-73.97356000",
  },
  {
    id: 111119,
    name: "Albertson",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.77343000",
    longitude: "-73.64318000",
  },
  {
    id: 111128,
    name: "Albion",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.24645000",
    longitude: "-78.19363000",
  },
  {
    id: 111136,
    name: "Alden",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.90006000",
    longitude: "-78.49197000",
  },
  {
    id: 111157,
    name: "Alexandria Bay",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.33588000",
    longitude: "-75.91773000",
  },
  {
    id: 111160,
    name: "Alfred",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.25424000",
    longitude: "-77.79055000",
  },
  {
    id: 111180,
    name: "Allegany",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.09006000",
    longitude: "-78.49419000",
  },
  {
    id: 111182,
    name: "Allegany County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.25739000",
    longitude: "-78.02756000",
  },
  {
    id: 111235,
    name: "Altamont",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.70063000",
    longitude: "-74.03374000",
  },
  {
    id: 111262,
    name: "Amagansett",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.97371000",
    longitude: "-72.14369000",
  },
  {
    id: 111283,
    name: "Amherst",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.97839000",
    longitude: "-78.79976000",
  },
  {
    id: 111292,
    name: "Amityville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.67899000",
    longitude: "-73.41707000",
  },
  {
    id: 111295,
    name: "Amsterdam",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.93869000",
    longitude: "-74.18819000",
  },
  {
    id: 111325,
    name: "Andover",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.15646000",
    longitude: "-77.79555000",
  },
  {
    id: 111341,
    name: "Angola",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.63839000",
    longitude: "-79.02782000",
  },
  {
    id: 111342,
    name: "Angola on the Lake",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.65478000",
    longitude: "-79.04893000",
  },
  {
    id: 111383,
    name: "Apalachin",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.06952000",
    longitude: "-76.15465000",
  },
  {
    id: 111405,
    name: "Aquebogue",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.94454000",
    longitude: "-72.62704000",
  },
  {
    id: 111419,
    name: "Arcade",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.53395000",
    longitude: "-78.42307000",
  },
  {
    id: 111444,
    name: "Ardsley",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.01065000",
    longitude: "-73.84375000",
  },
  {
    id: 111468,
    name: "Arlington",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.69593000",
    longitude: "-73.89680000",
  },
  {
    id: 111478,
    name: "Armonk",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.12648000",
    longitude: "-73.71402000",
  },
  {
    id: 111493,
    name: "Arrochar",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.59844000",
    longitude: "-74.07264000",
  },
  {
    id: 111504,
    name: "Arverne",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.59122000",
    longitude: "-73.79597000",
  },
  {
    id: 111562,
    name: "Astoria",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.77205000",
    longitude: "-73.93014000",
  },
  {
    id: 111579,
    name: "Athens",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.26036000",
    longitude: "-73.80957000",
  },
  {
    id: 111597,
    name: "Atlantic Beach",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.58899000",
    longitude: "-73.72902000",
  },
  {
    id: 111610,
    name: "Attica",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.86423000",
    longitude: "-78.28029000",
  },
  {
    id: 111628,
    name: "Auburn",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.93173000",
    longitude: "-76.56605000",
  },
  {
    id: 111650,
    name: "Augusta",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.97479000",
    longitude: "-75.50129000",
  },
  {
    id: 111680,
    name: "Averill Park",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.63397000",
    longitude: "-73.55373000",
  },
  {
    id: 111693,
    name: "Avon",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.91201000",
    longitude: "-77.74556000",
  },
  {
    id: 111716,
    name: "Babylon",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.69566000",
    longitude: "-73.32568000",
  },
  {
    id: 111733,
    name: "Bainbridge",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.29341000",
    longitude: "-75.47935000",
  },
  {
    id: 111737,
    name: "Baiting Hollow",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.95621000",
    longitude: "-72.74427000",
  },
  {
    id: 111756,
    name: "Baldwin",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.65649000",
    longitude: "-73.60930000",
  },
  {
    id: 111762,
    name: "Baldwin Harbor",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.63955000",
    longitude: "-73.60846000",
  },
  {
    id: 111764,
    name: "Baldwinsville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.15868000",
    longitude: "-76.33271000",
  },
  {
    id: 111775,
    name: "Ballston Spa",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.00091000",
    longitude: "-73.84901000",
  },
  {
    id: 111779,
    name: "Balmville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.53482000",
    longitude: "-74.01486000",
  },
  {
    id: 111819,
    name: "Bardonia",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.10954000",
    longitude: "-73.99625000",
  },
  {
    id: 111837,
    name: "Barnum Island",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.60455000",
    longitude: "-73.64402000",
  },
  {
    id: 111888,
    name: "Batavia",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.99812000",
    longitude: "-78.18752000",
  },
  {
    id: 111898,
    name: "Bath",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.33702000",
    longitude: "-77.31776000",
  },
  {
    id: 111900,
    name: "Bath Beach",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.60455000",
    longitude: "-74.00431000",
  },
  {
    id: 111918,
    name: "Baxter Estates",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.83482000",
    longitude: "-73.69541000",
  },
  {
    id: 111930,
    name: "Bay Park",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.63316000",
    longitude: "-73.67041000",
  },
  {
    id: 111934,
    name: "Bay Shore",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.72510000",
    longitude: "-73.24539000",
  },
  {
    id: 111936,
    name: "Bay Wood",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.75010000",
    longitude: "-73.29123000",
  },
  {
    id: 111940,
    name: "Baychester",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.86928000",
    longitude: "-73.83645000",
  },
  {
    id: 111952,
    name: "Bayport",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.73843000",
    longitude: "-73.05067000",
  },
  {
    id: 111955,
    name: "Bayside",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.76844000",
    longitude: "-73.77708000",
  },
  {
    id: 111961,
    name: "Bayville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.91065000",
    longitude: "-73.56207000",
  },
  {
    id: 111968,
    name: "Beacon",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.50482000",
    longitude: "-73.96958000",
  },
  {
    id: 112003,
    name: "Beaver Dam Lake",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.44743000",
    longitude: "-74.11463000",
  },
  {
    id: 112007,
    name: "Beaverdam Lake-Salisbury Mills",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.44162000",
    longitude: "-74.11629000",
  },
  {
    id: 112025,
    name: "Bedford",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.20426000",
    longitude: "-73.64374000",
  },
  {
    id: 112030,
    name: "Bedford Hills",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.23676000",
    longitude: "-73.69458000",
  },
  {
    id: 112077,
    name: "Bellaire",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.71399000",
    longitude: "-73.75402000",
  },
  {
    id: 112084,
    name: "Belle Harbor",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.57594000",
    longitude: "-73.84819000",
  },
  {
    id: 112100,
    name: "Bellerose",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.72427000",
    longitude: "-73.71513000",
  },
  {
    id: 112101,
    name: "Bellerose Terrace",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.72066000",
    longitude: "-73.72596000",
  },
  {
    id: 112123,
    name: "Bellmore",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.66871000",
    longitude: "-73.52707000",
  },
  {
    id: 112125,
    name: "Bellport",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.75704000",
    longitude: "-72.93927000",
  },
  {
    id: 112140,
    name: "Belmont",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.22312000",
    longitude: "-78.03445000",
  },
  {
    id: 112187,
    name: "Bensonhurst",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.60177000",
    longitude: "-73.99403000",
  },
  {
    id: 112216,
    name: "Bergen",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.08534000",
    longitude: "-77.94223000",
  },
  {
    id: 112217,
    name: "Bergen Beach",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.62038000",
    longitude: "-73.90680000",
  },
  {
    id: 112293,
    name: "Bethpage",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.74427000",
    longitude: "-73.48207000",
  },
  {
    id: 112320,
    name: "Big Flats",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.13730000",
    longitude: "-76.93691000",
  },
  {
    id: 112346,
    name: "Billington Heights",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.78423000",
    longitude: "-78.62642000",
  },
  {
    id: 112351,
    name: "Binghamton",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.09869000",
    longitude: "-75.91797000",
  },
  {
    id: 112382,
    name: "Black River",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.01256000",
    longitude: "-75.79437000",
  },
  {
    id: 112424,
    name: "Blasdell",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.79728000",
    longitude: "-78.82337000",
  },
  {
    id: 112425,
    name: "Blauvelt",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.06343000",
    longitude: "-73.95764000",
  },
  {
    id: 112438,
    name: "Bloomfield",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.61260000",
    longitude: "-74.17820000",
  },
  {
    id: 112473,
    name: "Blue Point",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.74399000",
    longitude: "-73.03455000",
  },
  {
    id: 112502,
    name: "Bohemia",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.76926000",
    longitude: "-73.11511000",
  },
  {
    id: 112520,
    name: "Bolivar",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.06673000",
    longitude: "-78.16779000",
  },
  {
    id: 112570,
    name: "Boonville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.48368000",
    longitude: "-75.33656000",
  },
  {
    id: 112580,
    name: "Borough Park",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.63399000",
    longitude: "-73.99681000",
  },
  {
    id: 112590,
    name: "Boston",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.62895000",
    longitude: "-78.73753000",
  },
  {
    id: 112731,
    name: "Brentwood",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.78121000",
    longitude: "-73.24623000",
  },
  {
    id: 112740,
    name: "Brewerton",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.23812000",
    longitude: "-76.14076000",
  },
  {
    id: 112743,
    name: "Brewster",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.39732000",
    longitude: "-73.61707000",
  },
  {
    id: 112746,
    name: "Brewster Hill",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.42398000",
    longitude: "-73.60429000",
  },
  {
    id: 112750,
    name: "Briarcliff Manor",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.14565000",
    longitude: "-73.82375000",
  },
  {
    id: 112751,
    name: "Briarwood",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.70935000",
    longitude: "-73.81529000",
  },
  {
    id: 112757,
    name: "Bridgehampton",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.93788000",
    longitude: "-72.30092000",
  },
  {
    id: 112763,
    name: "Bridgeport",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.15535000",
    longitude: "-75.96936000",
  },
  {
    id: 112790,
    name: "Brighton",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.14756000",
    longitude: "-77.55055000",
  },
  {
    id: 112792,
    name: "Brighton Beach",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.57788000",
    longitude: "-73.95958000",
  },
  {
    id: 112794,
    name: "Brightwaters",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.72093000",
    longitude: "-73.26734000",
  },
  {
    id: 112798,
    name: "Brinckerhoff",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.54398000",
    longitude: "-73.86819000",
  },
  {
    id: 112820,
    name: "Broad Channel",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.60316000",
    longitude: "-73.82041000",
  },
  {
    id: 112823,
    name: "Broadalbin",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.05868000",
    longitude: "-74.19652000",
  },
  {
    id: 112833,
    name: "Brockport",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.21367000",
    longitude: "-77.93918000",
  },
  {
    id: 112836,
    name: "Brocton",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.38867000",
    longitude: "-79.44116000",
  },
  {
    id: 112846,
    name: "Bronx",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.82732000",
    longitude: "-73.92357000",
  },
  {
    id: 112847,
    name: "Bronxville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.93815000",
    longitude: "-73.83208000",
  },
  {
    id: 112860,
    name: "Brookhaven",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.77927000",
    longitude: "-72.91538000",
  },
  {
    id: 112872,
    name: "Brooklyn",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.65010000",
    longitude: "-73.94958000",
  },
  {
    id: 112875,
    name: "Brooklyn Heights",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.69538000",
    longitude: "-73.99375000",
  },
  {
    id: 112895,
    name: "Brookville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.81316000",
    longitude: "-73.56735000",
  },
  {
    id: 112899,
    name: "Broome County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.16022000",
    longitude: "-75.81962000",
  },
  {
    id: 112933,
    name: "Brownsville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.66094000",
    longitude: "-73.92014000",
  },
  {
    id: 112938,
    name: "Brownville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.00700000",
    longitude: "-75.98409000",
  },
  {
    id: 112972,
    name: "Buchanan",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.26204000",
    longitude: "-73.93819000",
  },
  {
    id: 113014,
    name: "Buffalo",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.88645000",
    longitude: "-78.87837000",
  },
  {
    id: 113103,
    name: "Bushwick",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.69427000",
    longitude: "-73.91875000",
  },
  {
    id: 113171,
    name: "Cairo",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.29897000",
    longitude: "-73.99847000",
  },
  {
    id: 113178,
    name: "Calcium",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.02173000",
    longitude: "-75.84604000",
  },
  {
    id: 113191,
    name: "Caledonia",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.97312000",
    longitude: "-77.85278000",
  },
  {
    id: 113237,
    name: "Calverton",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.90649000",
    longitude: "-72.74343000",
  },
  {
    id: 113248,
    name: "Cambria Heights",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.69455000",
    longitude: "-73.73847000",
  },
  {
    id: 113254,
    name: "Cambridge",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.02813000",
    longitude: "-73.38122000",
  },
  {
    id: 113267,
    name: "Camden",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.33451000",
    longitude: "-75.74796000",
  },
  {
    id: 113284,
    name: "Camillus",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.03923000",
    longitude: "-76.30410000",
  },
  {
    id: 113317,
    name: "Canajoharie",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.90563000",
    longitude: "-74.57181000",
  },
  {
    id: 113318,
    name: "Canandaigua",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.87423000",
    longitude: "-77.28804000",
  },
  {
    id: 113319,
    name: "Canarsie",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.64372000",
    longitude: "-73.90069000",
  },
  {
    id: 113320,
    name: "Canastota",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.07951000",
    longitude: "-75.75074000",
  },
  {
    id: 113329,
    name: "Canisteo",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.27035000",
    longitude: "-77.60582000",
  },
  {
    id: 113347,
    name: "Canton",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.59562000",
    longitude: "-75.16909000",
  },
  {
    id: 113397,
    name: "Carle Place",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.75260000",
    longitude: "-73.61041000",
  },
  {
    id: 113417,
    name: "Carmel",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.43009000",
    longitude: "-73.68013000",
  },
  {
    id: 113419,
    name: "Carmel Hamlet",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.41485000",
    longitude: "-73.68524000",
  },
  {
    id: 113497,
    name: "Carthage",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.97812000",
    longitude: "-75.60936000",
  },
  {
    id: 113553,
    name: "Castleton-on-Hudson",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.51841000",
    longitude: "-73.75123000",
  },
  {
    id: 113579,
    name: "Catskill",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.21731000",
    longitude: "-73.86457000",
  },
  {
    id: 113580,
    name: "Cattaraugus County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.24863000",
    longitude: "-78.67885000",
  },
  {
    id: 113594,
    name: "Cayuga County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.01033000",
    longitude: "-76.57436000",
  },
  {
    id: 113595,
    name: "Cayuga Heights",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.46010000",
    longitude: "-76.48776000",
  },
  {
    id: 113596,
    name: "Cazenovia",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.93007000",
    longitude: "-75.85269000",
  },
  {
    id: 113626,
    name: "Cedarhurst",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.62288000",
    longitude: "-73.72430000",
  },
  {
    id: 113632,
    name: "Celoron",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.10950000",
    longitude: "-79.28310000",
  },
  {
    id: 113645,
    name: "Center Moriches",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.80038000",
    longitude: "-72.78982000",
  },
  {
    id: 113648,
    name: "Centereach",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.85843000",
    longitude: "-73.09955000",
  },
  {
    id: 113650,
    name: "Centerport",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.88538000",
    longitude: "-73.37623000",
  },
  {
    id: 113678,
    name: "Central Islip",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.79065000",
    longitude: "-73.20178000",
  },
  {
    id: 113681,
    name: "Central Square",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.28674000",
    longitude: "-76.14604000",
  },
  {
    id: 113682,
    name: "Central Valley",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.33176000",
    longitude: "-74.12098000",
  },
  {
    id: 113708,
    name: "Chadwicks",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.02785000",
    longitude: "-75.27155000",
  },
  {
    id: 113726,
    name: "Champlain",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.98643000",
    longitude: "-73.44653000",
  },
  {
    id: 113746,
    name: "Chappaqua",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.15954000",
    longitude: "-73.76485000",
  },
  {
    id: 113768,
    name: "Charleston",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.53677000",
    longitude: "-74.23737000",
  },
  {
    id: 113799,
    name: "Chatham",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.36425000",
    longitude: "-73.59484000",
  },
  {
    id: 113815,
    name: "Chautauqua County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.30294000",
    longitude: "-79.40576000",
  },
  {
    id: 113824,
    name: "Cheektowaga",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.90339000",
    longitude: "-78.75475000",
  },
  {
    id: 113834,
    name: "Chelsea",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.60066000",
    longitude: "-74.19487000",
  },
  {
    id: 113836,
    name: "Chemung County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.14125000",
    longitude: "-76.76003000",
  },
  {
    id: 113837,
    name: "Chenango Bridge",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.16674000",
    longitude: "-75.86242000",
  },
  {
    id: 113838,
    name: "Chenango County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.49351000",
    longitude: "-75.61158000",
  },
  {
    id: 113888,
    name: "Chester",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.36259000",
    longitude: "-74.27126000",
  },
  {
    id: 113912,
    name: "Chestnut Ridge",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.08426000",
    longitude: "-74.05570000",
  },
  {
    id: 113981,
    name: "Chittenango",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.04507000",
    longitude: "-75.86658000",
  },
  {
    id: 114013,
    name: "Churchville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.10423000",
    longitude: "-77.88445000",
  },
  {
    id: 114043,
    name: "City Island",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.84732000",
    longitude: "-73.78652000",
  },
  {
    id: 114108,
    name: "Clarence",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.97673000",
    longitude: "-78.59197000",
  },
  {
    id: 114109,
    name: "Clarence Center",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.01061000",
    longitude: "-78.63753000",
  },
  {
    id: 114132,
    name: "Clark Mills",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.09229000",
    longitude: "-75.37962000",
  },
  {
    id: 114146,
    name: "Clarkson",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.23312000",
    longitude: "-77.92751000",
  },
  {
    id: 114202,
    name: "Clayton",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.23949000",
    longitude: "-76.08578000",
  },
  {
    id: 114258,
    name: "Clifton",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.62010000",
    longitude: "-74.07709000",
  },
  {
    id: 114264,
    name: "Clifton Springs",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.96173000",
    longitude: "-77.13998000",
  },
  {
    id: 114283,
    name: "Clinton",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.04840000",
    longitude: "-75.37850000",
  },
  {
    id: 114295,
    name: "Clinton County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.74623000",
    longitude: "-73.67817000",
  },
  {
    id: 114298,
    name: "Clintondale",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.69482000",
    longitude: "-74.05125000",
  },
  {
    id: 114320,
    name: "Clyde",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.08423000",
    longitude: "-76.86940000",
  },
  {
    id: 114322,
    name: "Clymer",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.02089000",
    longitude: "-79.63005000",
  },
  {
    id: 114347,
    name: "Cobleskill",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.67785000",
    longitude: "-74.48542000",
  },
  {
    id: 114375,
    name: "Cohoes",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.77424000",
    longitude: "-73.70012000",
  },
  {
    id: 114387,
    name: "Cold Spring",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.42009000",
    longitude: "-73.95458000",
  },
  {
    id: 114388,
    name: "Cold Spring Harbor",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.87149000",
    longitude: "-73.45679000",
  },
  {
    id: 114416,
    name: "College Point",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.78760000",
    longitude: "-73.84597000",
  },
  {
    id: 114452,
    name: "Colonie",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.71786000",
    longitude: "-73.83346000",
  },
  {
    id: 114480,
    name: "Columbia County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.25008000",
    longitude: "-73.63185000",
  },
  {
    id: 114521,
    name: "Commack",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.84288000",
    longitude: "-73.29289000",
  },
  {
    id: 114540,
    name: "Concord",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.60816000",
    longitude: "-74.08431000",
  },
  {
    id: 114556,
    name: "Coney Island",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.57788000",
    longitude: "-73.99403000",
  },
  {
    id: 114557,
    name: "Congers",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.15065000",
    longitude: "-73.94542000",
  },
  {
    id: 114572,
    name: "Constantia",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.24785000",
    longitude: "-76.00020000",
  },
  {
    id: 114605,
    name: "Cooperstown",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.70048000",
    longitude: "-74.92426000",
  },
  {
    id: 114616,
    name: "Copiague",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.68149000",
    longitude: "-73.39984000",
  },
  {
    id: 114629,
    name: "Coram",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.86871000",
    longitude: "-73.00149000",
  },
  {
    id: 114645,
    name: "Corinth",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.24452000",
    longitude: "-73.83234000",
  },
  {
    id: 114653,
    name: "Corning",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.14285000",
    longitude: "-77.05469000",
  },
  {
    id: 114658,
    name: "Cornwall",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.44482000",
    longitude: "-74.01570000",
  },
  {
    id: 114661,
    name: "Corona",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.74705000",
    longitude: "-73.86014000",
  },
  {
    id: 114676,
    name: "Cortland",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.60118000",
    longitude: "-76.18048000",
  },
  {
    id: 114677,
    name: "Cortland County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.59501000",
    longitude: "-76.07027000",
  },
  {
    id: 114678,
    name: "Cortland West",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.59431000",
    longitude: "-76.22587000",
  },
  {
    id: 114721,
    name: "Country Knolls",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.91508000",
    longitude: "-73.80512000",
  },
  {
    id: 114751,
    name: "Coxsackie",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.35092000",
    longitude: "-73.80290000",
  },
  {
    id: 114834,
    name: "Crompond",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.29509000",
    longitude: "-73.86541000",
  },
  {
    id: 114859,
    name: "Croton-on-Hudson",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.20843000",
    longitude: "-73.89125000",
  },
  {
    id: 114866,
    name: "Crown Heights",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.63732000",
    longitude: "-73.93792000",
  },
  {
    id: 114872,
    name: "Crugers",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.23343000",
    longitude: "-73.92264000",
  },
  {
    id: 114887,
    name: "Cuba",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.21757000",
    longitude: "-78.27529000",
  },
  {
    id: 114920,
    name: "Cumberland Head",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.71643000",
    longitude: "-73.40263000",
  },
  {
    id: 114944,
    name: "Cutchogue",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.01066000",
    longitude: "-72.48509000",
  },
  {
    id: 114956,
    name: "Cypress Hills",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.67705000",
    longitude: "-73.89125000",
  },
  {
    id: 115027,
    name: "Dannemora",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.72143000",
    longitude: "-73.72375000",
  },
  {
    id: 115028,
    name: "Dansville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.56090000",
    longitude: "-77.69611000",
  },
  {
    id: 115177,
    name: "Deer Park",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.76177000",
    longitude: "-73.32929000",
  },
  {
    id: 115207,
    name: "Delaware County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.19809000",
    longitude: "-74.96647000",
  },
  {
    id: 115210,
    name: "Delevan",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.48923000",
    longitude: "-78.48085000",
  },
  {
    id: 115212,
    name: "Delhi",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.27814000",
    longitude: "-74.91599000",
  },
  {
    id: 115218,
    name: "Delmar",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.62202000",
    longitude: "-73.83262000",
  },
  {
    id: 115256,
    name: "Depew",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.90395000",
    longitude: "-78.69225000",
  },
  {
    id: 115258,
    name: "Deposit",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.06008000",
    longitude: "-75.42768000",
  },
  {
    id: 115310,
    name: "Dexter",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.00784000",
    longitude: "-76.04437000",
  },
  {
    id: 115358,
    name: "Dix Hills",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.80482000",
    longitude: "-73.33623000",
  },
  {
    id: 115370,
    name: "Dobbs Ferry",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.01454000",
    longitude: "-73.87264000",
  },
  {
    id: 115387,
    name: "Dolgeville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.10090000",
    longitude: "-74.77293000",
  },
  {
    id: 115396,
    name: "Dongan Hills",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.58844000",
    longitude: "-74.09625000",
  },
  {
    id: 115439,
    name: "Douglaston",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.76871000",
    longitude: "-73.74708000",
  },
  {
    id: 115456,
    name: "Dover Plains",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.74121000",
    longitude: "-73.57652000",
  },
  {
    id: 115485,
    name: "Dryden",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.49091000",
    longitude: "-76.29716000",
  },
  {
    id: 115530,
    name: "Dundee",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.52340000",
    longitude: "-76.97663000",
  },
  {
    id: 115539,
    name: "Dunkirk",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.47950000",
    longitude: "-79.33393000",
  },
  {
    id: 115572,
    name: "Durham",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.39953000",
    longitude: "-74.17236000",
  },
  {
    id: 115579,
    name: "Dutchess County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.76515000",
    longitude: "-73.74286000",
  },
  {
    id: 115590,
    name: "Dyker Heights",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.62149000",
    longitude: "-74.00958000",
  },
  {
    id: 115627,
    name: "East Atlantic Beach",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.78982000",
    longitude: "-73.74708000",
  },
  {
    id: 115628,
    name: "East Aurora",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.76784000",
    longitude: "-78.61336000",
  },
  {
    id: 115655,
    name: "East Elmhurst",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.76121000",
    longitude: "-73.86514000",
  },
  {
    id: 115658,
    name: "East Farmingdale",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.72927000",
    longitude: "-73.41679000",
  },
  {
    id: 115661,
    name: "East Flatbush",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.65371000",
    longitude: "-73.93042000",
  },
  {
    id: 115667,
    name: "East Garden City",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.73083000",
    longitude: "-73.59806000",
  },
  {
    id: 115669,
    name: "East Glenville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.89452000",
    longitude: "-73.92790000",
  },
  {
    id: 115672,
    name: "East Greenbush",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.59091000",
    longitude: "-73.70179000",
  },
  {
    id: 115680,
    name: "East Hampton",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.96343000",
    longitude: "-72.18480000",
  },
  {
    id: 115681,
    name: "East Hampton North",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.97276000",
    longitude: "-72.18911000",
  },
  {
    id: 115683,
    name: "East Harlem",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.79472000",
    longitude: "-73.94250000",
  },
  {
    id: 115692,
    name: "East Hills",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.79371000",
    longitude: "-73.62707000",
  },
  {
    id: 115695,
    name: "East Islip",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.73204000",
    longitude: "-73.18567000",
  },
  {
    id: 115696,
    name: "East Ithaca",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.43952000",
    longitude: "-76.47855000",
  },
  {
    id: 115708,
    name: "East Massapequa",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.67343000",
    longitude: "-73.43651000",
  },
  {
    id: 115710,
    name: "East Meadow",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.71399000",
    longitude: "-73.55902000",
  },
  {
    id: 115717,
    name: "East Moriches",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.80510000",
    longitude: "-72.76093000",
  },
  {
    id: 115719,
    name: "East New York",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.66677000",
    longitude: "-73.88236000",
  },
  {
    id: 115722,
    name: "East Northport",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.87676000",
    longitude: "-73.32456000",
  },
  {
    id: 115724,
    name: "East Norwich",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.84677000",
    longitude: "-73.53512000",
  },
  {
    id: 115730,
    name: "East Patchogue",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.76704000",
    longitude: "-72.99622000",
  },
  {
    id: 115743,
    name: "East Quogue",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.84066000",
    longitude: "-72.58148000",
  },
  {
    id: 115749,
    name: "East Rochester",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.10867000",
    longitude: "-77.48750000",
  },
  {
    id: 115750,
    name: "East Rockaway",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.64205000",
    longitude: "-73.66957000",
  },
  {
    id: 115757,
    name: "East Setauket",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.94149000",
    longitude: "-73.10594000",
  },
  {
    id: 115758,
    name: "East Shoreham",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.94482000",
    longitude: "-72.87955000",
  },
  {
    id: 115763,
    name: "East Syracuse",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.06534000",
    longitude: "-76.07853000",
  },
  {
    id: 115765,
    name: "East Tremont",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.84538000",
    longitude: "-73.89097000",
  },
  {
    id: 115769,
    name: "East Village",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.72927000",
    longitude: "-73.98736000",
  },
  {
    id: 115773,
    name: "East Williston",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.75843000",
    longitude: "-73.63485000",
  },
  {
    id: 115776,
    name: "Eastchester",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.95833000",
    longitude: "-73.80861000",
  },
  {
    id: 115796,
    name: "Eastport",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.82593000",
    longitude: "-72.73177000",
  },
  {
    id: 115805,
    name: "Eatons Neck",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.93065000",
    longitude: "-73.40151000",
  },
  {
    id: 115830,
    name: "Eden",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.65228000",
    longitude: "-78.89698000",
  },
  {
    id: 115845,
    name: "Edgemere",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.59622000",
    longitude: "-73.76763000",
  },
  {
    id: 115872,
    name: "Edinburg",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.22174000",
    longitude: "-74.10402000",
  },
  {
    id: 115902,
    name: "Eggertsville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.96339000",
    longitude: "-78.80392000",
  },
  {
    id: 115947,
    name: "Elbridge",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.03451000",
    longitude: "-76.44799000",
  },
  {
    id: 115980,
    name: "Elizabethtown",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.21616000",
    longitude: "-73.59097000",
  },
  {
    id: 116020,
    name: "Ellenville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.71704000",
    longitude: "-74.39571000",
  },
  {
    id: 116047,
    name: "Elma Center",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.82978000",
    longitude: "-78.63614000",
  },
  {
    id: 116052,
    name: "Elmhurst",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.73649000",
    longitude: "-73.87791000",
  },
  {
    id: 116053,
    name: "Elmira",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.08980000",
    longitude: "-76.80773000",
  },
  {
    id: 116054,
    name: "Elmira Heights",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.12980000",
    longitude: "-76.82079000",
  },
  {
    id: 116055,
    name: "Elmont",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.70094000",
    longitude: "-73.71291000",
  },
  {
    id: 116059,
    name: "Elmsford",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.05510000",
    longitude: "-73.82013000",
  },
  {
    id: 116072,
    name: "Eltingville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.54538000",
    longitude: "-74.16570000",
  },
  {
    id: 116082,
    name: "Elwood",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.84538000",
    longitude: "-73.33512000",
  },
  {
    id: 116095,
    name: "Emerson Hill",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.60872000",
    longitude: "-74.09598000",
  },
  {
    id: 116120,
    name: "Endicott",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.09841000",
    longitude: "-76.04937000",
  },
  {
    id: 116121,
    name: "Endwell",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.11285000",
    longitude: "-76.02103000",
  },
  {
    id: 116168,
    name: "Erie County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.75824000",
    longitude: "-78.77966000",
  },
  {
    id: 116196,
    name: "Essex County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.11722000",
    longitude: "-73.77271000",
  },
  {
    id: 116341,
    name: "Fairmount",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.04729000",
    longitude: "-76.23854000",
  },
  {
    id: 116345,
    name: "Fairport",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.09867000",
    longitude: "-77.44194000",
  },
  {
    id: 116353,
    name: "Fairview",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.72370000",
    longitude: "-73.91986000",
  },
  {
    id: 116367,
    name: "Falconer",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.11867000",
    longitude: "-79.19838000",
  },
  {
    id: 116384,
    name: "Fallsburg",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.73204000",
    longitude: "-74.60127000",
  },
  {
    id: 116395,
    name: "Far Rockaway",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.60538000",
    longitude: "-73.75513000",
  },
  {
    id: 116411,
    name: "Farmingdale",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.73260000",
    longitude: "-73.44540000",
  },
  {
    id: 116424,
    name: "Farmingville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.83121000",
    longitude: "-73.02955000",
  },
  {
    id: 116460,
    name: "Fayetteville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.02979000",
    longitude: "-76.00436000",
  },
  {
    id: 116512,
    name: "Financial District",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.70789000",
    longitude: "-74.00857000",
  },
  {
    id: 116524,
    name: "Firthcliffe",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.43926000",
    longitude: "-74.04514000",
  },
  {
    id: 116532,
    name: "Fishkill",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.53565000",
    longitude: "-73.89903000",
  },
  {
    id: 116548,
    name: "Flanders",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.90343000",
    longitude: "-72.61759000",
  },
  {
    id: 116553,
    name: "Flatbush",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.65205000",
    longitude: "-73.95903000",
  },
  {
    id: 116555,
    name: "Flatlands",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.62122000",
    longitude: "-73.93486000",
  },
  {
    id: 116575,
    name: "Floral Park",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.72371000",
    longitude: "-73.70485000",
  },
  {
    id: 116592,
    name: "Florida",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.33176000",
    longitude: "-74.35682000",
  },
  {
    id: 116600,
    name: "Flower Hill",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.80732000",
    longitude: "-73.68124000",
  },
  {
    id: 116629,
    name: "Fonda",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.95452000",
    longitude: "-74.37652000",
  },
  {
    id: 116639,
    name: "Fordham",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.85927000",
    longitude: "-73.89847000",
  },
  {
    id: 116658,
    name: "Forest Hills",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.71621000",
    longitude: "-73.85014000",
  },
  {
    id: 116702,
    name: "Fort Covington Hamlet",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.97178000",
    longitude: "-74.50757000",
  },
  {
    id: 116708,
    name: "Fort Drum",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.05843000",
    longitude: "-75.76189000",
  },
  {
    id: 116709,
    name: "Fort Edward",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.26702000",
    longitude: "-73.58456000",
  },
  {
    id: 116715,
    name: "Fort Hamilton",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.61872000",
    longitude: "-74.03320000",
  },
  {
    id: 116732,
    name: "Fort Montgomery",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.33148000",
    longitude: "-73.98681000",
  },
  {
    id: 116743,
    name: "Fort Plain",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.93146000",
    longitude: "-74.62264000",
  },
  {
    id: 116748,
    name: "Fort Salonga",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.91260000",
    longitude: "-73.30095000",
  },
  {
    id: 116758,
    name: "Fort Wadsworth",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.60113000",
    longitude: "-74.05738000",
  },
  {
    id: 116823,
    name: "Frankfort",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.03896000",
    longitude: "-75.07044000",
  },
  {
    id: 116861,
    name: "Franklin County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.59293000",
    longitude: "-74.30376000",
  },
  {
    id: 116872,
    name: "Franklin Square",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.70732000",
    longitude: "-73.67596000",
  },
  {
    id: 116876,
    name: "Franklinville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.33701000",
    longitude: "-78.45808000",
  },
  {
    id: 116896,
    name: "Fredonia",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.44006000",
    longitude: "-79.33171000",
  },
  {
    id: 116914,
    name: "Freeport",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.65760000",
    longitude: "-73.58318000",
  },
  {
    id: 116937,
    name: "Fresh Meadows",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.73482000",
    longitude: "-73.79347000",
  },
  {
    id: 116941,
    name: "Frewsburg",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.05450000",
    longitude: "-79.15810000",
  },
  {
    id: 116949,
    name: "Friendship",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.20646000",
    longitude: "-78.13751000",
  },
  {
    id: 116991,
    name: "Fulton",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.32285000",
    longitude: "-76.41716000",
  },
  {
    id: 116997,
    name: "Fulton County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.11385000",
    longitude: "-74.42217000",
  },
  {
    id: 117031,
    name: "Galeville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.09007000",
    longitude: "-76.17298000",
  },
  {
    id: 117050,
    name: "Gang Mills",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.14619000",
    longitude: "-77.11164000",
  },
  {
    id: 117058,
    name: "Garden City",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.72677000",
    longitude: "-73.63430000",
  },
  {
    id: 117062,
    name: "Garden City Park",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.74093000",
    longitude: "-73.66263000",
  },
  {
    id: 117063,
    name: "Garden City South",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.71232000",
    longitude: "-73.66096000",
  },
  {
    id: 117077,
    name: "Gardnertown",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.53509000",
    longitude: "-74.07014000",
  },
  {
    id: 117111,
    name: "Gasport",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.19922000",
    longitude: "-78.57614000",
  },
  {
    id: 117120,
    name: "Gates-North Gates",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.16547000",
    longitude: "-77.70066000",
  },
  {
    id: 117136,
    name: "Genesee County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.00093000",
    longitude: "-78.19371000",
  },
  {
    id: 117138,
    name: "Geneseo",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.79590000",
    longitude: "-77.81695000",
  },
  {
    id: 117144,
    name: "Geneva",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.86896000",
    longitude: "-76.97774000",
  },
  {
    id: 117242,
    name: "Glasco",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.04370000",
    longitude: "-73.94736000",
  },
  {
    id: 117264,
    name: "Glen Cove",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.86232000",
    longitude: "-73.63374000",
  },
  {
    id: 117267,
    name: "Glen Head",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.83538000",
    longitude: "-73.62374000",
  },
  {
    id: 117269,
    name: "Glen Oaks",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.74705000",
    longitude: "-73.71152000",
  },
  {
    id: 117283,
    name: "Glendale",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.70149000",
    longitude: "-73.88680000",
  },
  {
    id: 117304,
    name: "Glens Falls",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.30952000",
    longitude: "-73.64401000",
  },
  {
    id: 117305,
    name: "Glens Falls North",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.33506000",
    longitude: "-73.68251000",
  },
  {
    id: 117317,
    name: "Glenwood Landing",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.83066000",
    longitude: "-73.63874000",
  },
  {
    id: 117328,
    name: "Gloversville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.05285000",
    longitude: "-74.34375000",
  },
  {
    id: 117362,
    name: "Goldens Bridge",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.29343000",
    longitude: "-73.67680000",
  },
  {
    id: 117399,
    name: "Gordon Heights",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.85871000",
    longitude: "-72.97066000",
  },
  {
    id: 117409,
    name: "Goshen",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.40204000",
    longitude: "-74.32432000",
  },
  {
    id: 117419,
    name: "Gouverneur",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.33673000",
    longitude: "-75.46299000",
  },
  {
    id: 117422,
    name: "Gowanda",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.46312000",
    longitude: "-78.93587000",
  },
  {
    id: 117444,
    name: "Gramercy Park",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.73750000",
    longitude: "-73.98611000",
  },
  {
    id: 117462,
    name: "Grand Island",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.03311000",
    longitude: "-78.96254000",
  },
  {
    id: 117485,
    name: "Grandyle Village",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.99645000",
    longitude: "-78.95504000",
  },
  {
    id: 117503,
    name: "Graniteville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.62483000",
    longitude: "-74.14848000",
  },
  {
    id: 117507,
    name: "Grant City",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.58205000",
    longitude: "-74.10486000",
  },
  {
    id: 117536,
    name: "Granville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.40785000",
    longitude: "-73.25955000",
  },
  {
    id: 117548,
    name: "Gravesend",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.59760000",
    longitude: "-73.96514000",
  },
  {
    id: 117574,
    name: "Great Kills",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.55427000",
    longitude: "-74.15153000",
  },
  {
    id: 117575,
    name: "Great Neck",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.80066000",
    longitude: "-73.72846000",
  },
  {
    id: 117576,
    name: "Great Neck Estates",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.78705000",
    longitude: "-73.73680000",
  },
  {
    id: 117577,
    name: "Great Neck Gardens",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.79722000",
    longitude: "-73.72389000",
  },
  {
    id: 117578,
    name: "Great Neck Plaza",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.78677000",
    longitude: "-73.72652000",
  },
  {
    id: 117579,
    name: "Great River",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.72121000",
    longitude: "-73.15761000",
  },
  {
    id: 117584,
    name: "Greece",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.20978000",
    longitude: "-77.69306000",
  },
  {
    id: 117599,
    name: "Green Island",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.74424000",
    longitude: "-73.69151000",
  },
  {
    id: 117622,
    name: "Greenburgh",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.03287000",
    longitude: "-73.84291000",
  },
  {
    id: 117630,
    name: "Greene",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.32924000",
    longitude: "-75.76991000",
  },
  {
    id: 117642,
    name: "Greene County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.27652000",
    longitude: "-74.12271000",
  },
  {
    id: 117658,
    name: "Greenlawn",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.86899000",
    longitude: "-73.36512000",
  },
  {
    id: 117661,
    name: "Greenpoint",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.72371000",
    longitude: "-73.95097000",
  },
  {
    id: 117662,
    name: "Greenport",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.10343000",
    longitude: "-72.35925000",
  },
  {
    id: 117663,
    name: "Greenport West",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.10178000",
    longitude: "-72.37195000",
  },
  {
    id: 117679,
    name: "Greenvale",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.81066000",
    longitude: "-73.62846000",
  },
  {
    id: 117693,
    name: "Greenville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.99315000",
    longitude: "-73.81986000",
  },
  {
    id: 117699,
    name: "Greenwich",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.09063000",
    longitude: "-73.49873000",
  },
  {
    id: 117711,
    name: "Greenwood Lake",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.22259000",
    longitude: "-74.29432000",
  },
  {
    id: 117750,
    name: "Groton",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.58785000",
    longitude: "-76.36688000",
  },
  {
    id: 117775,
    name: "Grymes Hill",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.61872000",
    longitude: "-74.09348000",
  },
  {
    id: 117834,
    name: "Hadley",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.31729000",
    longitude: "-73.84818000",
  },
  {
    id: 117835,
    name: "Hagaman",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.97452000",
    longitude: "-74.15096000",
  },
  {
    id: 117852,
    name: "Halesite",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.88843000",
    longitude: "-73.41540000",
  },
  {
    id: 117884,
    name: "Hamburg",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.71589000",
    longitude: "-78.82948000",
  },
  {
    id: 117893,
    name: "Hamilton",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.82701000",
    longitude: "-75.54462000",
  },
  {
    id: 117903,
    name: "Hamilton County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.66112000",
    longitude: "-74.49736000",
  },
  {
    id: 117909,
    name: "Hamlin",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.30312000",
    longitude: "-77.92112000",
  },
  {
    id: 117935,
    name: "Hampton Bays",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.86899000",
    longitude: "-72.51759000",
  },
  {
    id: 117939,
    name: "Hampton Manor",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.62091000",
    longitude: "-73.72845000",
  },
  {
    id: 117964,
    name: "Hannawa Falls",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.61228000",
    longitude: "-74.97103000",
  },
  {
    id: 117990,
    name: "Harbor Isle",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.60344000",
    longitude: "-73.66457000",
  },
  {
    id: 118023,
    name: "Harlem",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.80788000",
    longitude: "-73.94542000",
  },
  {
    id: 118040,
    name: "Harriman",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.30843000",
    longitude: "-74.14459000",
  },
  {
    id: 118046,
    name: "Harris Hill",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.96478000",
    longitude: "-78.67753000",
  },
  {
    id: 118059,
    name: "Harrison",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.96899000",
    longitude: "-73.71263000",
  },
  {
    id: 118090,
    name: "Hartford",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.36368000",
    longitude: "-73.39372000",
  },
  {
    id: 118101,
    name: "Hartsdale",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.01899000",
    longitude: "-73.79819000",
  },
  {
    id: 118135,
    name: "Hastings-on-Hudson",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.99454000",
    longitude: "-73.87875000",
  },
  {
    id: 118143,
    name: "Hauppauge",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.82565000",
    longitude: "-73.20261000",
  },
  {
    id: 118152,
    name: "Haverstraw",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.19759000",
    longitude: "-73.96458000",
  },
  {
    id: 118153,
    name: "Haviland",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.76676000",
    longitude: "-73.90152000",
  },
  {
    id: 118174,
    name: "Hawthorne",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.10732000",
    longitude: "-73.79597000",
  },
  {
    id: 118215,
    name: "Head of the Harbor",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.90343000",
    longitude: "-73.15789000",
  },
  {
    id: 118258,
    name: "Hell's Kitchen",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.76496000",
    longitude: "-73.99090000",
  },
  {
    id: 118270,
    name: "Hempstead",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.70621000",
    longitude: "-73.61874000",
  },
  {
    id: 118318,
    name: "Heritage Hills",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.33954000",
    longitude: "-73.69735000",
  },
  {
    id: 118323,
    name: "Herkimer",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.02563000",
    longitude: "-74.98599000",
  },
  {
    id: 118324,
    name: "Herkimer County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.41970000",
    longitude: "-74.96250000",
  },
  {
    id: 118337,
    name: "Herricks",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.75538000",
    longitude: "-73.66680000",
  },
  {
    id: 118349,
    name: "Hewlett",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.64316000",
    longitude: "-73.69569000",
  },
  {
    id: 118350,
    name: "Hewlett Harbor",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.63621000",
    longitude: "-73.68152000",
  },
  {
    id: 118371,
    name: "Hicksville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.76843000",
    longitude: "-73.52513000",
  },
  {
    id: 118397,
    name: "Highland",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.72093000",
    longitude: "-73.96014000",
  },
  {
    id: 118405,
    name: "Highland Falls",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.36926000",
    longitude: "-73.96625000",
  },
  {
    id: 118409,
    name: "Highland Mills",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.34704000",
    longitude: "-74.12626000",
  },
  {
    id: 118440,
    name: "Hillcrest",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.12787000",
    longitude: "-74.04097000",
  },
  {
    id: 118465,
    name: "Hillside",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.70788000",
    longitude: "-73.78680000",
  },
  {
    id: 118466,
    name: "Hillside Lake",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.61482000",
    longitude: "-73.79819000",
  },
  {
    id: 118473,
    name: "Hilton",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.28812000",
    longitude: "-77.79334000",
  },
  {
    id: 118518,
    name: "Holbrook",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.81232000",
    longitude: "-73.07844000",
  },
  {
    id: 118520,
    name: "Holcomb",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.90229000",
    longitude: "-77.41971000",
  },
  {
    id: 118540,
    name: "Holland",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.64117000",
    longitude: "-78.54169000",
  },
  {
    id: 118543,
    name: "Holley",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.22645000",
    longitude: "-78.02668000",
  },
  {
    id: 118549,
    name: "Hollis",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.71344000",
    longitude: "-73.76708000",
  },
  {
    id: 118580,
    name: "Holtsville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.81538000",
    longitude: "-73.04511000",
  },
  {
    id: 118596,
    name: "Homer",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.63701000",
    longitude: "-76.17882000",
  },
  {
    id: 118617,
    name: "Honeoye Falls",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.95229000",
    longitude: "-77.59028000",
  },
  {
    id: 118637,
    name: "Hoosick Falls",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.90119000",
    longitude: "-73.35150000",
  },
  {
    id: 118667,
    name: "Hornell",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.32785000",
    longitude: "-77.66110000",
  },
  {
    id: 118673,
    name: "Horseheads",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.16702000",
    longitude: "-76.82051000",
  },
  {
    id: 118674,
    name: "Horseheads North",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.19278000",
    longitude: "-76.80782000",
  },
  {
    id: 118690,
    name: "Houghton",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.42340000",
    longitude: "-78.15723000",
  },
  {
    id: 118710,
    name: "Howard Beach",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.65788000",
    longitude: "-73.83625000",
  },
  {
    id: 118744,
    name: "Hudson",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.25286000",
    longitude: "-73.79096000",
  },
  {
    id: 118750,
    name: "Hudson Falls",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.30063000",
    longitude: "-73.58595000",
  },
  {
    id: 118769,
    name: "Huguenot",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.53733000",
    longitude: "-74.19459000",
  },
  {
    id: 118802,
    name: "Huntington",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.86815000",
    longitude: "-73.42568000",
  },
  {
    id: 118804,
    name: "Huntington Bay",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.89982000",
    longitude: "-73.41484000",
  },
  {
    id: 118808,
    name: "Huntington Station",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.85343000",
    longitude: "-73.41151000",
  },
  {
    id: 118813,
    name: "Hunts Point",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.81260000",
    longitude: "-73.88402000",
  },
  {
    id: 118820,
    name: "Hurley",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.92454000",
    longitude: "-74.06125000",
  },
  {
    id: 118850,
    name: "Hyde Park",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.78482000",
    longitude: "-73.93319000",
  },
  {
    id: 118878,
    name: "Ilion",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.01507000",
    longitude: "-75.03543000",
  },
  {
    id: 118963,
    name: "Inwood",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.86566000",
    longitude: "-73.92680000",
  },
  {
    id: 118996,
    name: "Irondequoit",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.21340000",
    longitude: "-77.57972000",
  },
  {
    id: 119010,
    name: "Irvington",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.03922000",
    longitude: "-73.86823000",
  },
  {
    id: 119027,
    name: "Island Park",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.60427000",
    longitude: "-73.65541000",
  },
  {
    id: 119029,
    name: "Islandia",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.80426000",
    longitude: "-73.16900000",
  },
  {
    id: 119034,
    name: "Islip",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.72982000",
    longitude: "-73.21039000",
  },
  {
    id: 119035,
    name: "Islip Terrace",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.74315000",
    longitude: "-73.19262000",
  },
  {
    id: 119044,
    name: "Ithaca",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.44063000",
    longitude: "-76.49661000",
  },
  {
    id: 119097,
    name: "Jackson Heights",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.75566000",
    longitude: "-73.88541000",
  },
  {
    id: 119111,
    name: "Jamaica",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.69149000",
    longitude: "-73.80569000",
  },
  {
    id: 119119,
    name: "Jamesport",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.94954000",
    longitude: "-72.58148000",
  },
  {
    id: 119124,
    name: "Jamestown",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.09700000",
    longitude: "-79.23533000",
  },
  {
    id: 119127,
    name: "Jamestown West",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.08851000",
    longitude: "-79.28110000",
  },
  {
    id: 119191,
    name: "Jefferson County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.99885000",
    longitude: "-76.05211000",
  },
  {
    id: 119201,
    name: "Jefferson Heights",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.23398000",
    longitude: "-73.88235000",
  },
  {
    id: 119204,
    name: "Jefferson Valley-Yorktown",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.31797000",
    longitude: "-73.80066000",
  },
  {
    id: 119224,
    name: "Jericho",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.79204000",
    longitude: "-73.53985000",
  },
  {
    id: 119259,
    name: "Johnson City",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.11563000",
    longitude: "-75.95881000",
  },
  {
    id: 119284,
    name: "Johnstown",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.00674000",
    longitude: "-74.36764000",
  },
  {
    id: 119316,
    name: "Jordan",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.06534000",
    longitude: "-76.47299000",
  },
  {
    id: 119391,
    name: "Kaser",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.12121000",
    longitude: "-74.06709000",
  },
  {
    id: 119394,
    name: "Katonah",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.25898000",
    longitude: "-73.68541000",
  },
  {
    id: 119423,
    name: "Keeseville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.50505000",
    longitude: "-73.48013000",
  },
  {
    id: 119462,
    name: "Kenmore",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.96589000",
    longitude: "-78.87004000",
  },
  {
    id: 119483,
    name: "Kensington",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.79343000",
    longitude: "-73.72208000",
  },
  {
    id: 119511,
    name: "Kerhonkson",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.77482000",
    longitude: "-74.29821000",
  },
  {
    id: 119529,
    name: "Keuka Park",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.61535000",
    longitude: "-77.09219000",
  },
  {
    id: 119530,
    name: "Kew Gardens",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.71427000",
    longitude: "-73.83097000",
  },
  {
    id: 119531,
    name: "Kew Gardens Hills",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.73002000",
    longitude: "-73.82340000",
  },
  {
    id: 119550,
    name: "Kiantone",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.02200000",
    longitude: "-79.19810000",
  },
  {
    id: 119575,
    name: "Kinderhook",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.39536000",
    longitude: "-73.69790000",
  },
  {
    id: 119599,
    name: "Kings Bridge",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.87871000",
    longitude: "-73.90514000",
  },
  {
    id: 119601,
    name: "Kings County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.63439000",
    longitude: "-73.95027000",
  },
  {
    id: 119605,
    name: "Kings Park",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.88621000",
    longitude: "-73.25734000",
  },
  {
    id: 119608,
    name: "Kings Point",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.81982000",
    longitude: "-73.73513000",
  },
  {
    id: 119627,
    name: "Kingston",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.92704000",
    longitude: "-73.99736000",
  },
  {
    id: 119656,
    name: "Kiryas Joel",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.34204000",
    longitude: "-74.16792000",
  },
  {
    id: 119813,
    name: "Lackawanna",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.82561000",
    longitude: "-78.82337000",
  },
  {
    id: 119875,
    name: "Lake Carmel",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.46148000",
    longitude: "-73.67096000",
  },
  {
    id: 119907,
    name: "Lake Erie Beach",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.62423000",
    longitude: "-79.06698000",
  },
  {
    id: 119914,
    name: "Lake Grove",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.85288000",
    longitude: "-73.11511000",
  },
  {
    id: 119926,
    name: "Lake Katrine",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.98565000",
    longitude: "-73.98819000",
  },
  {
    id: 119935,
    name: "Lake Luzerne",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.31285000",
    longitude: "-73.83484000",
  },
  {
    id: 119945,
    name: "Lake Mohegan",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.31787000",
    longitude: "-73.84625000",
  },
  {
    id: 119963,
    name: "Lake Placid",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.27962000",
    longitude: "-73.98198000",
  },
  {
    id: 119964,
    name: "Lake Pleasant",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.47090000",
    longitude: "-74.41265000",
  },
  {
    id: 119970,
    name: "Lake Ronkonkoma",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.83510000",
    longitude: "-73.13122000",
  },
  {
    id: 119982,
    name: "Lake Success",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.77066000",
    longitude: "-73.71763000",
  },
  {
    id: 120016,
    name: "Lakeland",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.09034000",
    longitude: "-76.24048000",
  },
  {
    id: 120039,
    name: "Lakeview",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.68538000",
    longitude: "-73.65263000",
  },
  {
    id: 120049,
    name: "Lakewood",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.10422000",
    longitude: "-79.33310000",
  },
  {
    id: 120086,
    name: "Lancaster",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.90061000",
    longitude: "-78.67031000",
  },
  {
    id: 120128,
    name: "Lansing",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.48424000",
    longitude: "-76.47994000",
  },
  {
    id: 120141,
    name: "Larchmont",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.92788000",
    longitude: "-73.75180000",
  },
  {
    id: 120168,
    name: "Latham",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.74702000",
    longitude: "-73.75901000",
  },
  {
    id: 120177,
    name: "Lattingtown",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.89538000",
    longitude: "-73.60096000",
  },
  {
    id: 120192,
    name: "Laurel",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.96954000",
    longitude: "-72.56203000",
  },
  {
    id: 120198,
    name: "Laurel Hollow",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.85677000",
    longitude: "-73.46957000",
  },
  {
    id: 120204,
    name: "Laurelton",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.67019000",
    longitude: "-73.74659000",
  },
  {
    id: 120228,
    name: "Lawrence",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.61566000",
    longitude: "-73.72958000",
  },
  {
    id: 120263,
    name: "Le Roy",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.97839000",
    longitude: "-77.98418000",
  },
  {
    id: 120407,
    name: "Levittown",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.72593000",
    longitude: "-73.51429000",
  },
  {
    id: 120415,
    name: "Lewis County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.78469000",
    longitude: "-75.44879000",
  },
  {
    id: 120426,
    name: "Lewiston",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.17256000",
    longitude: "-79.03588000",
  },
  {
    id: 120466,
    name: "Liberty",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.80120000",
    longitude: "-74.74655000",
  },
  {
    id: 120478,
    name: "Lido Beach",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.58899000",
    longitude: "-73.62541000",
  },
  {
    id: 120489,
    name: "Lima",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.90479000",
    longitude: "-77.61139000",
  },
  {
    id: 120543,
    name: "Lincoln Park",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.95065000",
    longitude: "-73.99403000",
  },
  {
    id: 120548,
    name: "Lincolndale",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.32287000",
    longitude: "-73.71819000",
  },
  {
    id: 120568,
    name: "Lindenhurst",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.68677000",
    longitude: "-73.37345000",
  },
  {
    id: 120570,
    name: "Lindley",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.02841000",
    longitude: "-77.13969000",
  },
  {
    id: 120619,
    name: "Little Falls",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.04340000",
    longitude: "-74.85960000",
  },
  {
    id: 120622,
    name: "Little Neck",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.76289000",
    longitude: "-73.73225000",
  },
  {
    id: 120631,
    name: "Little Valley",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.25256000",
    longitude: "-78.80559000",
  },
  {
    id: 120646,
    name: "Liverpool",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.10646000",
    longitude: "-76.21770000",
  },
  {
    id: 120658,
    name: "Livingston County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.72808000",
    longitude: "-77.77549000",
  },
  {
    id: 120659,
    name: "Livingston Manor",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.90037000",
    longitude: "-74.82822000",
  },
  {
    id: 120663,
    name: "Livonia",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.82145000",
    longitude: "-77.66861000",
  },
  {
    id: 120667,
    name: "Lloyd Harbor",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.90343000",
    longitude: "-73.45984000",
  },
  {
    id: 120681,
    name: "Lockport",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.17061000",
    longitude: "-78.69031000",
  },
  {
    id: 120688,
    name: "Locust Valley",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.87593000",
    longitude: "-73.59707000",
  },
  {
    id: 120737,
    name: "Long Beach",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.58844000",
    longitude: "-73.65791000",
  },
  {
    id: 120745,
    name: "Long Island City",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.74482000",
    longitude: "-73.94875000",
  },
  {
    id: 120772,
    name: "Lorenz Park",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.26370000",
    longitude: "-73.76846000",
  },
  {
    id: 120850,
    name: "Lowville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.78674000",
    longitude: "-75.49185000",
  },
  {
    id: 120913,
    name: "Lynbrook",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.65483000",
    longitude: "-73.67180000",
  },
  {
    id: 120917,
    name: "Lyncourt",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.08146000",
    longitude: "-76.12576000",
  },
  {
    id: 120943,
    name: "Lyons",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.06423000",
    longitude: "-76.99025000",
  },
  {
    id: 120954,
    name: "Macedon",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.06923000",
    longitude: "-77.29887000",
  },
  {
    id: 121017,
    name: "Madison County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.91277000",
    longitude: "-75.66967000",
  },
  {
    id: 121046,
    name: "Mahopac",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.37232000",
    longitude: "-73.73346000",
  },
  {
    id: 121066,
    name: "Malone",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.84866000",
    longitude: "-74.29490000",
  },
  {
    id: 121074,
    name: "Malverne",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.67899000",
    longitude: "-73.67402000",
  },
  {
    id: 121075,
    name: "Mamaroneck",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.94871000",
    longitude: "-73.73263000",
  },
  {
    id: 121099,
    name: "Manchester",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.96979000",
    longitude: "-77.23026000",
  },
  {
    id: 121111,
    name: "Manhasset",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.79788000",
    longitude: "-73.69957000",
  },
  {
    id: 121112,
    name: "Manhasset Hills",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.75927000",
    longitude: "-73.67985000",
  },
  {
    id: 121115,
    name: "Manhattan",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.78343000",
    longitude: "-73.96625000",
  },
  {
    id: 121131,
    name: "Manlius",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.00201000",
    longitude: "-75.97686000",
  },
  {
    id: 121140,
    name: "Manorhaven",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.84316000",
    longitude: "-73.71485000",
  },
  {
    id: 121141,
    name: "Manorville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.87371000",
    longitude: "-72.80788000",
  },
  {
    id: 121191,
    name: "Marbletown",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.88343000",
    longitude: "-74.11320000",
  },
  {
    id: 121195,
    name: "Marcellus",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.98284000",
    longitude: "-76.34049000",
  },
  {
    id: 121227,
    name: "Mariners Harbor",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.63677000",
    longitude: "-74.15875000",
  },
  {
    id: 121244,
    name: "Marion",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.14340000",
    longitude: "-77.18915000",
  },
  {
    id: 121275,
    name: "Marlboro",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.60565000",
    longitude: "-73.97153000",
  },
  {
    id: 121386,
    name: "Maspeth",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.72316000",
    longitude: "-73.91264000",
  },
  {
    id: 121391,
    name: "Massapequa",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.68066000",
    longitude: "-73.47429000",
  },
  {
    id: 121392,
    name: "Massapequa Park",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.68038000",
    longitude: "-73.45512000",
  },
  {
    id: 121393,
    name: "Massena",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.92810000",
    longitude: "-74.89186000",
  },
  {
    id: 121394,
    name: "Mastic",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.80204000",
    longitude: "-72.84094000",
  },
  {
    id: 121395,
    name: "Mastic Beach",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.76677000",
    longitude: "-72.85205000",
  },
  {
    id: 121413,
    name: "Mattituck",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.99121000",
    longitude: "-72.53425000",
  },
  {
    id: 121415,
    name: "Mattydale",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.09784000",
    longitude: "-76.14520000",
  },
  {
    id: 121431,
    name: "Maybrook",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.48398000",
    longitude: "-74.21765000",
  },
  {
    id: 121455,
    name: "Mayville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.25395000",
    longitude: "-79.50449000",
  },
  {
    id: 121504,
    name: "McGraw",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.59618000",
    longitude: "-76.09326000",
  },
  {
    id: 121526,
    name: "McKownville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.68397000",
    longitude: "-73.84762000",
  },
  {
    id: 121581,
    name: "Mechanicstown",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.44287000",
    longitude: "-74.38849000",
  },
  {
    id: 121586,
    name: "Mechanicville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.90285000",
    longitude: "-73.68734000",
  },
  {
    id: 121594,
    name: "Medford",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.81760000",
    longitude: "-73.00011000",
  },
  {
    id: 121605,
    name: "Medina",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.22006000",
    longitude: "-78.38697000",
  },
  {
    id: 121630,
    name: "Melrose",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.82455000",
    longitude: "-73.91041000",
  },
  {
    id: 121633,
    name: "Melrose Park",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.90868000",
    longitude: "-76.54022000",
  },
  {
    id: 121635,
    name: "Melville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.79343000",
    longitude: "-73.41512000",
  },
  {
    id: 121645,
    name: "Menands",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.69202000",
    longitude: "-73.72456000",
  },
  {
    id: 121704,
    name: "Merrick",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.66288000",
    longitude: "-73.55152000",
  },
  {
    id: 121715,
    name: "Merritt Park",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.53848000",
    longitude: "-73.87238000",
  },
  {
    id: 121743,
    name: "Mexico",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.45951000",
    longitude: "-76.22882000",
  },
  {
    id: 121761,
    name: "Middle Island",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.88427000",
    longitude: "-72.93733000",
  },
  {
    id: 121764,
    name: "Middle Village",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.71649000",
    longitude: "-73.88125000",
  },
  {
    id: 121770,
    name: "Middleburgh",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.59869000",
    longitude: "-74.33292000",
  },
  {
    id: 121775,
    name: "Middleport",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.21256000",
    longitude: "-78.47641000",
  },
  {
    id: 121791,
    name: "Middletown",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.44593000",
    longitude: "-74.42293000",
  },
  {
    id: 121802,
    name: "Midland Beach",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.57316000",
    longitude: "-74.09459000",
  },
  {
    id: 121866,
    name: "Mill Neck",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.88704000",
    longitude: "-73.55512000",
  },
  {
    id: 121873,
    name: "Millbrook",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.78509000",
    longitude: "-73.69402000",
  },
  {
    id: 121883,
    name: "Miller Place",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.95982000",
    longitude: "-72.99621000",
  },
  {
    id: 121916,
    name: "Milton",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.03369000",
    longitude: "-73.85262000",
  },
  {
    id: 121932,
    name: "Mineola",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.74927000",
    longitude: "-73.64068000",
  },
  {
    id: 121944,
    name: "Minetto",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.39812000",
    longitude: "-76.47744000",
  },
  {
    id: 121945,
    name: "Mineville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.09283000",
    longitude: "-73.51818000",
  },
  {
    id: 121960,
    name: "Minoa",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.07618000",
    longitude: "-76.00075000",
  },
  {
    id: 122021,
    name: "Mohawk",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.01146000",
    longitude: "-75.00404000",
  },
  {
    id: 122062,
    name: "Monroe",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.33065000",
    longitude: "-74.18681000",
  },
  {
    id: 122080,
    name: "Monroe County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.16512000",
    longitude: "-77.63626000",
  },
  {
    id: 122089,
    name: "Monsey",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.11121000",
    longitude: "-74.06848000",
  },
  {
    id: 122103,
    name: "Montauk",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.03594000",
    longitude: "-71.95451000",
  },
  {
    id: 122113,
    name: "Montebello",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.13593000",
    longitude: "-74.11848000",
  },
  {
    id: 122135,
    name: "Montgomery",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.52759000",
    longitude: "-74.23682000",
  },
  {
    id: 122154,
    name: "Montgomery County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.90228000",
    longitude: "-74.43968000",
  },
  {
    id: 122169,
    name: "Monticello",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.65565000",
    longitude: "-74.68933000",
  },
  {
    id: 122174,
    name: "Montour Falls",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.34730000",
    longitude: "-76.84524000",
  },
  {
    id: 122182,
    name: "Montrose",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.25232000",
    longitude: "-73.93153000",
  },
  {
    id: 122223,
    name: "Moravia",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.71257000",
    longitude: "-76.42160000",
  },
  {
    id: 122256,
    name: "Moriches",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.80732000",
    longitude: "-72.82121000",
  },
  {
    id: 122258,
    name: "Morningside Heights",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.81000000",
    longitude: "-73.96250000",
  },
  {
    id: 122272,
    name: "Morris Heights",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.84982000",
    longitude: "-73.91986000",
  },
  {
    id: 122273,
    name: "Morris Park",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.85232000",
    longitude: "-73.85347000",
  },
  {
    id: 122275,
    name: "Morrisania",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.82927000",
    longitude: "-73.90653000",
  },
  {
    id: 122279,
    name: "Morrisonville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.69310000",
    longitude: "-73.56208000",
  },
  {
    id: 122285,
    name: "Morrisville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.89868000",
    longitude: "-75.64018000",
  },
  {
    id: 122313,
    name: "Mott Haven",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.80899000",
    longitude: "-73.92291000",
  },
  {
    id: 122356,
    name: "Mount Ivy",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.18676000",
    longitude: "-74.03486000",
  },
  {
    id: 122360,
    name: "Mount Kisco",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.20426000",
    longitude: "-73.72708000",
  },
  {
    id: 122365,
    name: "Mount Morris",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.72562000",
    longitude: "-77.87417000",
  },
  {
    id: 122389,
    name: "Mount Sinai",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.94704000",
    longitude: "-73.02955000",
  },
  {
    id: 122402,
    name: "Mount Vernon",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.91260000",
    longitude: "-73.83708000",
  },
  {
    id: 122423,
    name: "Mountain Lodge Park",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.38843000",
    longitude: "-74.14181000",
  },
  {
    id: 122476,
    name: "Munsey Park",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.79871000",
    longitude: "-73.67985000",
  },
  {
    id: 122477,
    name: "Munsons Corners",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.58229000",
    longitude: "-76.20910000",
  },
  {
    id: 122516,
    name: "Muttontown",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.82399000",
    longitude: "-73.54763000",
  },
  {
    id: 122517,
    name: "Myers Corner",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.60620000",
    longitude: "-73.87291000",
  },
  {
    id: 122545,
    name: "Nanuet",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.08871000",
    longitude: "-74.01347000",
  },
  {
    id: 122548,
    name: "Napanoch",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.74398000",
    longitude: "-74.37154000",
  },
  {
    id: 122554,
    name: "Naples",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.61535000",
    longitude: "-77.40249000",
  },
  {
    id: 122580,
    name: "Nassau",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.51591000",
    longitude: "-73.61012000",
  },
  {
    id: 122583,
    name: "Nassau County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.73217000",
    longitude: "-73.58545000",
  },
  {
    id: 122612,
    name: "Nedrow",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.97507000",
    longitude: "-76.14131000",
  },
  {
    id: 122636,
    name: "Neponsit",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.57177000",
    longitude: "-73.86152000",
  },
  {
    id: 122639,
    name: "Nesconset",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.85204000",
    longitude: "-73.15400000",
  },
  {
    id: 122672,
    name: "New Brighton",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.64233000",
    longitude: "-74.09292000",
  },
  {
    id: 122682,
    name: "New Cassel",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.75899000",
    longitude: "-73.56957000",
  },
  {
    id: 122695,
    name: "New City",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.14760000",
    longitude: "-73.98931000",
  },
  {
    id: 122699,
    name: "New Dorp",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.57399000",
    longitude: "-74.11598000",
  },
  {
    id: 122700,
    name: "New Dorp Beach",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.56538000",
    longitude: "-74.10292000",
  },
  {
    id: 122712,
    name: "New Hartford",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.07340000",
    longitude: "-75.28767000",
  },
  {
    id: 122720,
    name: "New Hempstead",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.14982000",
    longitude: "-74.03375000",
  },
  {
    id: 122729,
    name: "New Hyde Park",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.73510000",
    longitude: "-73.68791000",
  },
  {
    id: 122759,
    name: "New Paltz",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.74759000",
    longitude: "-74.08681000",
  },
  {
    id: 122773,
    name: "New Rochelle",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.91149000",
    longitude: "-73.78235000",
  },
  {
    id: 122781,
    name: "New Springville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.59344000",
    longitude: "-74.16320000",
  },
  {
    id: 122782,
    name: "New Square",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.13956000",
    longitude: "-74.02942000",
  },
  {
    id: 122794,
    name: "New Windsor",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.47676000",
    longitude: "-74.02375000",
  },
  {
    id: 122795,
    name: "New York City",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.71427000",
    longitude: "-74.00597000",
  },
  {
    id: 122796,
    name: "New York County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.77427000",
    longitude: "-73.96981000",
  },
  {
    id: 122798,
    name: "New York Mills",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.10535000",
    longitude: "-75.29128000",
  },
  {
    id: 122804,
    name: "Newark",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.04673000",
    longitude: "-77.09525000",
  },
  {
    id: 122817,
    name: "Newburgh",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.50343000",
    longitude: "-74.01042000",
  },
  {
    id: 122826,
    name: "Newfane",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.28672000",
    longitude: "-78.71031000",
  },
  {
    id: 122881,
    name: "Niagara County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.17314000",
    longitude: "-78.69095000",
  },
  {
    id: 122882,
    name: "Niagara Falls",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.09450000",
    longitude: "-79.05671000",
  },
  {
    id: 122906,
    name: "Niskayuna",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.77980000",
    longitude: "-73.84568000",
  },
  {
    id: 122907,
    name: "Nissequogue",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.90399000",
    longitude: "-73.19789000",
  },
  {
    id: 122910,
    name: "Niverville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.44092000",
    longitude: "-73.66095000",
  },
  {
    id: 122944,
    name: "Norfolk",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.80089000",
    longitude: "-74.99103000",
  },
  {
    id: 122961,
    name: "North Amityville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.69760000",
    longitude: "-73.42512000",
  },
  {
    id: 122971,
    name: "North Babylon",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.71649000",
    longitude: "-73.32179000",
  },
  {
    id: 122972,
    name: "North Ballston Spa",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.01969000",
    longitude: "-73.85109000",
  },
  {
    id: 122975,
    name: "North Bay Shore",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.73621000",
    longitude: "-73.26262000",
  },
  {
    id: 122981,
    name: "North Bellmore",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.69149000",
    longitude: "-73.53346000",
  },
  {
    id: 122982,
    name: "North Bellport",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.77427000",
    longitude: "-72.94288000",
  },
  {
    id: 122991,
    name: "North Boston",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.68562000",
    longitude: "-78.77670000",
  },
  {
    id: 123001,
    name: "North Castle",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.14000000",
    longitude: "-73.68389000",
  },
  {
    id: 123008,
    name: "North Collins",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.59534000",
    longitude: "-78.94115000",
  },
  {
    id: 123022,
    name: "North Elba",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.24338000",
    longitude: "-73.95431000",
  },
  {
    id: 123030,
    name: "North Gates",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.17645000",
    longitude: "-77.70139000",
  },
  {
    id: 123032,
    name: "North Great River",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.74732000",
    longitude: "-73.16984000",
  },
  {
    id: 123040,
    name: "North Hills",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.78093000",
    longitude: "-73.67652000",
  },
  {
    id: 123058,
    name: "North Lindenhurst",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.71427000",
    longitude: "-73.38151000",
  },
  {
    id: 123064,
    name: "North Massapequa",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.70093000",
    longitude: "-73.46207000",
  },
  {
    id: 123065,
    name: "North Merrick",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.69121000",
    longitude: "-73.56318000",
  },
  {
    id: 123071,
    name: "North New Hyde Park",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.74316000",
    longitude: "-73.69319000",
  },
  {
    id: 123076,
    name: "North Patchogue",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.78704000",
    longitude: "-73.00900000",
  },
  {
    id: 123103,
    name: "North Sea",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.93288000",
    longitude: "-72.41425000",
  },
  {
    id: 123113,
    name: "North Syracuse",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.13479000",
    longitude: "-76.12992000",
  },
  {
    id: 123115,
    name: "North Tonawanda",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.03867000",
    longitude: "-78.86420000",
  },
  {
    id: 123120,
    name: "North Valley Stream",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.68510000",
    longitude: "-73.70180000",
  },
  {
    id: 123124,
    name: "North Wantagh",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.69343000",
    longitude: "-73.50763000",
  },
  {
    id: 123146,
    name: "Northeast Ithaca",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.47032000",
    longitude: "-76.46228000",
  },
  {
    id: 123162,
    name: "Northport",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.90093000",
    longitude: "-73.34317000",
  },
  {
    id: 123165,
    name: "Northumberland",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.12730000",
    longitude: "-73.58817000",
  },
  {
    id: 123172,
    name: "Northville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.97010000",
    longitude: "-72.61898000",
  },
  {
    id: 123174,
    name: "Northwest Harbor",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.00982000",
    longitude: "-72.22119000",
  },
  {
    id: 123178,
    name: "Northwest Ithaca",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.47059000",
    longitude: "-76.54145000",
  },
  {
    id: 123196,
    name: "Norwich",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.53118000",
    longitude: "-75.52351000",
  },
  {
    id: 123201,
    name: "Norwood",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.75145000",
    longitude: "-74.99436000",
  },
  {
    id: 123213,
    name: "Noyack",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.99566000",
    longitude: "-72.34119000",
  },
  {
    id: 123217,
    name: "Nunda",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.57951000",
    longitude: "-77.94250000",
  },
  {
    id: 123221,
    name: "Nyack",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.09065000",
    longitude: "-73.91791000",
  },
  {
    id: 123272,
    name: "Oakdale",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.74399000",
    longitude: "-73.13872000",
  },
  {
    id: 123276,
    name: "Oakfield",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.06589000",
    longitude: "-78.26974000",
  },
  {
    id: 123310,
    name: "Oakwood",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.56399000",
    longitude: "-74.11598000",
  },
  {
    id: 123343,
    name: "Oceanside",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.63871000",
    longitude: "-73.64013000",
  },
  {
    id: 123372,
    name: "Ogdensburg",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.69423000",
    longitude: "-75.48634000",
  },
  {
    id: 123414,
    name: "Olcott",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.33783000",
    longitude: "-78.71476000",
  },
  {
    id: 123415,
    name: "Old Bethpage",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.76260000",
    longitude: "-73.45318000",
  },
  {
    id: 123417,
    name: "Old Brookville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.83204000",
    longitude: "-73.60485000",
  },
  {
    id: 123431,
    name: "Old Westbury",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.78871000",
    longitude: "-73.59957000",
  },
  {
    id: 123435,
    name: "Olean",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.07756000",
    longitude: "-78.42974000",
  },
  {
    id: 123473,
    name: "Oneida",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.09257000",
    longitude: "-75.65129000",
  },
  {
    id: 123475,
    name: "Oneida County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.24175000",
    longitude: "-75.43584000",
  },
  {
    id: 123479,
    name: "Oneonta",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.45286000",
    longitude: "-75.06377000",
  },
  {
    id: 123482,
    name: "Onondaga County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.00580000",
    longitude: "-76.19464000",
  },
  {
    id: 123485,
    name: "Ontario",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.22090000",
    longitude: "-77.28304000",
  },
  {
    id: 123488,
    name: "Ontario County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.85285000",
    longitude: "-77.29982000",
  },
  {
    id: 123519,
    name: "Orange County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.40214000",
    longitude: "-74.30557000",
  },
  {
    id: 123524,
    name: "Orange Lake",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.53982000",
    longitude: "-74.09820000",
  },
  {
    id: 123527,
    name: "Orangeburg",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.04649000",
    longitude: "-73.94958000",
  },
  {
    id: 123538,
    name: "Orchard Park",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.76756000",
    longitude: "-78.74392000",
  },
  {
    id: 123557,
    name: "Oriskany",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.15729000",
    longitude: "-75.33267000",
  },
  {
    id: 123565,
    name: "Orleans County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.25070000",
    longitude: "-78.18901000",
  },
  {
    id: 123617,
    name: "Ossining",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.16287000",
    longitude: "-73.86152000",
  },
  {
    id: 123623,
    name: "Oswego",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.45535000",
    longitude: "-76.51050000",
  },
  {
    id: 123624,
    name: "Oswego County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.46389000",
    longitude: "-76.20868000",
  },
  {
    id: 123630,
    name: "Otisville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.47343000",
    longitude: "-74.53849000",
  },
  {
    id: 123635,
    name: "Otsego County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.63376000",
    longitude: "-75.03261000",
  },
  {
    id: 123660,
    name: "Owego",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.10341000",
    longitude: "-76.26215000",
  },
  {
    id: 123687,
    name: "Oxford",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.44202000",
    longitude: "-75.59769000",
  },
  {
    id: 123693,
    name: "Oyster Bay",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.86565000",
    longitude: "-73.53207000",
  },
  {
    id: 123694,
    name: "Oyster Bay Cove",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.87093000",
    longitude: "-73.51096000",
  },
  {
    id: 123702,
    name: "Ozone Park",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.67677000",
    longitude: "-73.84375000",
  },
  {
    id: 123727,
    name: "Painted Post",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.16202000",
    longitude: "-77.09414000",
  },
  {
    id: 123733,
    name: "Palenville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.17453000",
    longitude: "-74.02014000",
  },
  {
    id: 123776,
    name: "Palmyra",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.06395000",
    longitude: "-77.23332000",
  },
  {
    id: 123854,
    name: "Park Slope",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.67010000",
    longitude: "-73.98597000",
  },
  {
    id: 123856,
    name: "Parkchester",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.83899000",
    longitude: "-73.86041000",
  },
  {
    id: 123910,
    name: "Patchogue",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.76565000",
    longitude: "-73.01511000",
  },
  {
    id: 123932,
    name: "Pawling",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.56204000",
    longitude: "-73.60263000",
  },
  {
    id: 123957,
    name: "Peach Lake",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.36759000",
    longitude: "-73.57790000",
  },
  {
    id: 123968,
    name: "Pearl River",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.05899000",
    longitude: "-74.02181000",
  },
  {
    id: 123984,
    name: "Peekskill",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.29009000",
    longitude: "-73.92042000",
  },
  {
    id: 123992,
    name: "Pelham",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.90982000",
    longitude: "-73.80791000",
  },
  {
    id: 123993,
    name: "Pelham Manor",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.89538000",
    longitude: "-73.80708000",
  },
  {
    id: 124026,
    name: "Penn Yan",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.66090000",
    longitude: "-77.05386000",
  },
  {
    id: 124072,
    name: "Perry",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.71562000",
    longitude: "-78.00556000",
  },
  {
    id: 124093,
    name: "Perth",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.01757000",
    longitude: "-74.19402000",
  },
  {
    id: 124098,
    name: "Peru",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.57838000",
    longitude: "-73.52680000",
  },
  {
    id: 124120,
    name: "Phelps",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.95756000",
    longitude: "-77.05747000",
  },
  {
    id: 124127,
    name: "Philadelphia",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.15450000",
    longitude: "-75.70882000",
  },
  {
    id: 124142,
    name: "Philmont",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.24842000",
    longitude: "-73.65318000",
  },
  {
    id: 124147,
    name: "Phoenix",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.23118000",
    longitude: "-76.30076000",
  },
  {
    id: 124177,
    name: "Piermont",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.04204000",
    longitude: "-73.91819000",
  },
  {
    id: 124216,
    name: "Pine Bush",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.60815000",
    longitude: "-74.29904000",
  },
  {
    id: 124240,
    name: "Pine Plains",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.97981000",
    longitude: "-73.65596000",
  },
  {
    id: 124302,
    name: "Pittsford",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.09062000",
    longitude: "-77.51500000",
  },
  {
    id: 124317,
    name: "Plainedge",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.71732000",
    longitude: "-73.48374000",
  },
  {
    id: 124330,
    name: "Plainview",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.77649000",
    longitude: "-73.46735000",
  },
  {
    id: 124339,
    name: "Plandome",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.80677000",
    longitude: "-73.70346000",
  },
  {
    id: 124340,
    name: "Plandome Heights",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.80260000",
    longitude: "-73.70430000",
  },
  {
    id: 124356,
    name: "Plattekill",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.61759000",
    longitude: "-74.07598000",
  },
  {
    id: 124360,
    name: "Plattsburgh",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.69949000",
    longitude: "-73.45291000",
  },
  {
    id: 124361,
    name: "Plattsburgh West",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.68315000",
    longitude: "-73.50295000",
  },
  {
    id: 124379,
    name: "Pleasant Valley",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.74454000",
    longitude: "-73.82124000",
  },
  {
    id: 124388,
    name: "Pleasantville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.13287000",
    longitude: "-73.79263000",
  },
  {
    id: 124423,
    name: "Poestenkill",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.69036000",
    longitude: "-73.56456000",
  },
  {
    id: 124428,
    name: "Point Lookout",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.59233000",
    longitude: "-73.58068000",
  },
  {
    id: 124458,
    name: "Pomona",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.16704000",
    longitude: "-74.04320000",
  },
  {
    id: 124500,
    name: "Port Byron",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.03451000",
    longitude: "-76.62383000",
  },
  {
    id: 124503,
    name: "Port Chester",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.00176000",
    longitude: "-73.66568000",
  },
  {
    id: 124504,
    name: "Port Dickinson",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.13341000",
    longitude: "-75.89631000",
  },
  {
    id: 124506,
    name: "Port Ewen",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.90537000",
    longitude: "-73.97625000",
  },
  {
    id: 124509,
    name: "Port Henry",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.04839000",
    longitude: "-73.45985000",
  },
  {
    id: 124513,
    name: "Port Jefferson",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.94649000",
    longitude: "-73.06927000",
  },
  {
    id: 124514,
    name: "Port Jefferson Station",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.92538000",
    longitude: "-73.04733000",
  },
  {
    id: 124515,
    name: "Port Jervis",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.37509000",
    longitude: "-74.69266000",
  },
  {
    id: 124520,
    name: "Port Morris",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.80149000",
    longitude: "-73.90958000",
  },
  {
    id: 124530,
    name: "Port Richmond",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.63316000",
    longitude: "-74.13653000",
  },
  {
    id: 124539,
    name: "Port Washington",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.82566000",
    longitude: "-73.69819000",
  },
  {
    id: 124541,
    name: "Port Washington North",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.84482000",
    longitude: "-73.70180000",
  },
  {
    id: 124563,
    name: "Portland",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.37978000",
    longitude: "-79.46755000",
  },
  {
    id: 124588,
    name: "Potsdam",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.66978000",
    longitude: "-74.98131000",
  },
  {
    id: 124602,
    name: "Poughkeepsie",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.70037000",
    longitude: "-73.92097000",
  },
  {
    id: 124605,
    name: "Pound Ridge",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.20871000",
    longitude: "-73.57485000",
  },
  {
    id: 124735,
    name: "Pulaski",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.56701000",
    longitude: "-76.12770000",
  },
  {
    id: 124755,
    name: "Purchase",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.04093000",
    longitude: "-73.71457000",
  },
  {
    id: 124768,
    name: "Putnam County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.42666000",
    longitude: "-73.74951000",
  },
  {
    id: 124769,
    name: "Putnam Lake",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.46204000",
    longitude: "-73.54624000",
  },
  {
    id: 124790,
    name: "Queens",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.68149000",
    longitude: "-73.83652000",
  },
  {
    id: 124791,
    name: "Queens County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.65749000",
    longitude: "-73.83875000",
  },
  {
    id: 124792,
    name: "Queens Village",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.72677000",
    longitude: "-73.74152000",
  },
  {
    id: 124793,
    name: "Queensbury",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.37729000",
    longitude: "-73.61317000",
  },
  {
    id: 124867,
    name: "Randolph",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.16201000",
    longitude: "-78.97532000",
  },
  {
    id: 124889,
    name: "Ransomville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.23867000",
    longitude: "-78.90976000",
  },
  {
    id: 124897,
    name: "Rapids",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.09839000",
    longitude: "-78.64086000",
  },
  {
    id: 124905,
    name: "Ravena",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.46841000",
    longitude: "-73.81624000",
  },
  {
    id: 124948,
    name: "Red Hook",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.99509000",
    longitude: "-73.87541000",
  },
  {
    id: 124958,
    name: "Red Oaks Mill",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.65565000",
    longitude: "-73.87486000",
  },
  {
    id: 125002,
    name: "Rego Park",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.72649000",
    longitude: "-73.85264000",
  },
  {
    id: 125016,
    name: "Remsenburg-Speonk",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.82642000",
    longitude: "-72.69673000",
  },
  {
    id: 125024,
    name: "Rensselaer",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.64258000",
    longitude: "-73.74290000",
  },
  {
    id: 125025,
    name: "Rensselaer County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.71105000",
    longitude: "-73.50972000",
  },
  {
    id: 125045,
    name: "Rhinebeck",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.92676000",
    longitude: "-73.91264000",
  },
  {
    id: 125064,
    name: "Richfield Springs",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.85341000",
    longitude: "-74.98543000",
  },
  {
    id: 125070,
    name: "Richland",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.56951000",
    longitude: "-76.04770000",
  },
  {
    id: 125101,
    name: "Richmond County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.58344000",
    longitude: "-74.14959000",
  },
  {
    id: 125105,
    name: "Richmond Hill",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.69983000",
    longitude: "-73.83125000",
  },
  {
    id: 125114,
    name: "Ridge",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.89399000",
    longitude: "-72.89594000",
  },
  {
    id: 125132,
    name: "Ridgewood",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.70010000",
    longitude: "-73.90569000",
  },
  {
    id: 125195,
    name: "Riverdale",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.90056000",
    longitude: "-73.90639000",
  },
  {
    id: 125200,
    name: "Riverhead",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.91704000",
    longitude: "-72.66204000",
  },
  {
    id: 125207,
    name: "Riverside",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.88121000",
    longitude: "-72.67787000",
  },
  {
    id: 125267,
    name: "Rochester",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.15478000",
    longitude: "-77.61556000",
  },
  {
    id: 125281,
    name: "Rock Hill",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.62593000",
    longitude: "-74.59766000",
  },
  {
    id: 125291,
    name: "Rockaway Point",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.56066000",
    longitude: "-73.91514000",
  },
  {
    id: 125310,
    name: "Rockland County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.15243000",
    longitude: "-74.02409000",
  },
  {
    id: 125325,
    name: "Rockville Centre",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.65871000",
    longitude: "-73.64124000",
  },
  {
    id: 125338,
    name: "Rocky Point",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.95260000",
    longitude: "-72.92538000",
  },
  {
    id: 125346,
    name: "Roessleville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.69508000",
    longitude: "-73.80707000",
  },
  {
    id: 125378,
    name: "Rome",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.21285000",
    longitude: "-75.45573000",
  },
  {
    id: 125388,
    name: "Ronkonkoma",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.81538000",
    longitude: "-73.11233000",
  },
  {
    id: 125391,
    name: "Roosevelt",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.67871000",
    longitude: "-73.58902000",
  },
  {
    id: 125409,
    name: "Rosebank",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.61399000",
    longitude: "-74.06625000",
  },
  {
    id: 125418,
    name: "Rosedale",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.66205000",
    longitude: "-73.73541000",
  },
  {
    id: 125435,
    name: "Rosendale Village",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.85038000",
    longitude: "-74.07379000",
  },
  {
    id: 125447,
    name: "Roslyn",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.79982000",
    longitude: "-73.65096000",
  },
  {
    id: 125448,
    name: "Roslyn Estates",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.79427000",
    longitude: "-73.66041000",
  },
  {
    id: 125449,
    name: "Roslyn Harbor",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.81649000",
    longitude: "-73.63707000",
  },
  {
    id: 125450,
    name: "Roslyn Heights",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.78871000",
    longitude: "-73.64735000",
  },
  {
    id: 125461,
    name: "Rossville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.55566000",
    longitude: "-74.21348000",
  },
  {
    id: 125468,
    name: "Rotterdam",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.78702000",
    longitude: "-73.97096000",
  },
  {
    id: 125475,
    name: "Rouses Point",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.99393000",
    longitude: "-73.36486000",
  },
  {
    id: 125562,
    name: "Rye",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.98065000",
    longitude: "-73.68374000",
  },
  {
    id: 125563,
    name: "Rye Brook",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.01926000",
    longitude: "-73.68346000",
  },
  {
    id: 125575,
    name: "Sackets Harbor",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.94617000",
    longitude: "-76.11909000",
  },
  {
    id: 125584,
    name: "Sag Harbor",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.99788000",
    longitude: "-72.29258000",
  },
  {
    id: 125609,
    name: "Saint Bonaventure",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.08034000",
    longitude: "-78.47502000",
  },
  {
    id: 125656,
    name: "Saint James",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.87899000",
    longitude: "-73.15678000",
  },
  {
    id: 125669,
    name: "Saint Johnsville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.99813000",
    longitude: "-74.68292000",
  },
  {
    id: 125723,
    name: "Salamanca",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.15784000",
    longitude: "-78.71503000",
  },
  {
    id: 125759,
    name: "Salisbury",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.74566000",
    longitude: "-73.56013000",
  },
  {
    id: 125854,
    name: "Sanborn",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.13672000",
    longitude: "-78.88476000",
  },
  {
    id: 125872,
    name: "Sands Point",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.85177000",
    longitude: "-73.71874000",
  },
  {
    id: 125936,
    name: "Saranac Lake",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.32950000",
    longitude: "-74.13127000",
  },
  {
    id: 125943,
    name: "Saratoga County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.10738000",
    longitude: "-73.86390000",
  },
  {
    id: 125944,
    name: "Saratoga Springs",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.08313000",
    longitude: "-73.78457000",
  },
  {
    id: 125959,
    name: "Saugerties",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.07759000",
    longitude: "-73.95291000",
  },
  {
    id: 125960,
    name: "Saugerties South",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.06139000",
    longitude: "-73.95067000",
  },
  {
    id: 125993,
    name: "Sayville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.73593000",
    longitude: "-73.08206000",
  },
  {
    id: 125998,
    name: "Scarsdale",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.00510000",
    longitude: "-73.78458000",
  },
  {
    id: 126003,
    name: "Schenectady",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.81424000",
    longitude: "-73.93957000",
  },
  {
    id: 126004,
    name: "Schenectady County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.81812000",
    longitude: "-74.05857000",
  },
  {
    id: 126015,
    name: "Schoharie",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.66591000",
    longitude: "-74.30958000",
  },
  {
    id: 126016,
    name: "Schoharie County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.58822000",
    longitude: "-74.44212000",
  },
  {
    id: 126024,
    name: "Schuyler County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.39380000",
    longitude: "-76.87518000",
  },
  {
    id: 126025,
    name: "Schuylerville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.10008000",
    longitude: "-73.58178000",
  },
  {
    id: 126033,
    name: "Scotchtown",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.48148000",
    longitude: "-74.36015000",
  },
  {
    id: 126034,
    name: "Scotia",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.82647000",
    longitude: "-73.96429000",
  },
  {
    id: 126065,
    name: "Scottsville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.02590000",
    longitude: "-77.74528000",
  },
  {
    id: 126072,
    name: "Sea Cliff",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.84899000",
    longitude: "-73.64485000",
  },
  {
    id: 126086,
    name: "Seaford",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.66593000",
    longitude: "-73.48818000",
  },
  {
    id: 126094,
    name: "Searingtown",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.77482000",
    longitude: "-73.65568000",
  },
  {
    id: 126098,
    name: "Seaside",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.58316000",
    longitude: "-73.82819000",
  },
  {
    id: 126133,
    name: "Selden",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.86649000",
    longitude: "-73.03566000",
  },
  {
    id: 126159,
    name: "Seneca County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.78108000",
    longitude: "-76.82378000",
  },
  {
    id: 126160,
    name: "Seneca Falls",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.91062000",
    longitude: "-76.79662000",
  },
  {
    id: 126161,
    name: "Seneca Knolls",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.12007000",
    longitude: "-76.28632000",
  },
  {
    id: 126169,
    name: "Setauket-East Setauket",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.93064000",
    longitude: "-73.10179000",
  },
  {
    id: 126258,
    name: "Sheepshead Bay",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.59122000",
    longitude: "-73.94458000",
  },
  {
    id: 126295,
    name: "Shelter Island",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.06815000",
    longitude: "-72.33869000",
  },
  {
    id: 126296,
    name: "Shelter Island Heights",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.08399000",
    longitude: "-72.35592000",
  },
  {
    id: 126308,
    name: "Shenorock",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.33176000",
    longitude: "-73.73819000",
  },
  {
    id: 126315,
    name: "Sherburne",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.67813000",
    longitude: "-75.49851000",
  },
  {
    id: 126338,
    name: "Sherrill",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.07368000",
    longitude: "-75.59824000",
  },
  {
    id: 126354,
    name: "Shinnecock Hills",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.89093000",
    longitude: "-72.46370000",
  },
  {
    id: 126361,
    name: "Shirley",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.80149000",
    longitude: "-72.86760000",
  },
  {
    id: 126366,
    name: "Shokan",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.97343000",
    longitude: "-74.21209000",
  },
  {
    id: 126378,
    name: "Shortsville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.95590000",
    longitude: "-77.22081000",
  },
  {
    id: 126387,
    name: "Shrub Oak",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.32759000",
    longitude: "-73.81958000",
  },
  {
    id: 126397,
    name: "Sidney",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.31480000",
    longitude: "-75.39157000",
  },
  {
    id: 126423,
    name: "Silver Creek",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.54423000",
    longitude: "-79.16671000",
  },
  {
    id: 126477,
    name: "Skaneateles",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.94701000",
    longitude: "-76.42910000",
  },
  {
    id: 126498,
    name: "Sleepy Hollow",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.08565000",
    longitude: "-73.85847000",
  },
  {
    id: 126504,
    name: "Sloan",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.89339000",
    longitude: "-78.79392000",
  },
  {
    id: 126505,
    name: "Sloatsburg",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.15454000",
    longitude: "-74.19292000",
  },
  {
    id: 126526,
    name: "Smithtown",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.85593000",
    longitude: "-73.20067000",
  },
  {
    id: 126564,
    name: "Sodus",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.23784000",
    longitude: "-77.06136000",
  },
  {
    id: 126573,
    name: "Solvay",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.05812000",
    longitude: "-76.20743000",
  },
  {
    id: 126609,
    name: "Sound Beach",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.95621000",
    longitude: "-72.96788000",
  },
  {
    id: 126620,
    name: "South Beach",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.58329000",
    longitude: "-74.07609000",
  },
  {
    id: 126627,
    name: "South Blooming Grove",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.37337000",
    longitude: "-74.17843000",
  },
  {
    id: 126643,
    name: "South Corning",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.12174000",
    longitude: "-77.03719000",
  },
  {
    id: 126653,
    name: "South Fallsburg",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.72065000",
    longitude: "-74.63433000",
  },
  {
    id: 126654,
    name: "South Farmingdale",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.72066000",
    longitude: "-73.44012000",
  },
  {
    id: 126655,
    name: "South Floral Park",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.71427000",
    longitude: "-73.70013000",
  },
  {
    id: 126661,
    name: "South Glens Falls",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.29924000",
    longitude: "-73.63512000",
  },
  {
    id: 126668,
    name: "South Hempstead",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.68094000",
    longitude: "-73.61541000",
  },
  {
    id: 126672,
    name: "South Hill",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.42924000",
    longitude: "-76.49494000",
  },
  {
    id: 126677,
    name: "South Huntington",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.82371000",
    longitude: "-73.39873000",
  },
  {
    id: 126689,
    name: "South Lockport",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.15006000",
    longitude: "-78.69670000",
  },
  {
    id: 126695,
    name: "South Nyack",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.08315000",
    longitude: "-73.92014000",
  },
  {
    id: 126741,
    name: "South Valley Stream",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.65594000",
    longitude: "-73.71763000",
  },
  {
    id: 126758,
    name: "Southampton",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.88427000",
    longitude: "-72.38953000",
  },
  {
    id: 126781,
    name: "Southold",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.06482000",
    longitude: "-72.42620000",
  },
  {
    id: 126785,
    name: "Southport",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.05480000",
    longitude: "-76.81912000",
  },
  {
    id: 126794,
    name: "Spackenkill",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.65593000",
    longitude: "-73.91347000",
  },
  {
    id: 126802,
    name: "Sparkill",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.03121000",
    longitude: "-73.92708000",
  },
  {
    id: 126830,
    name: "Spencerport",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.18645000",
    longitude: "-77.80390000",
  },
  {
    id: 126877,
    name: "Spring Valley",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.11315000",
    longitude: "-74.04375000",
  },
  {
    id: 126903,
    name: "Springfield",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.83618000",
    longitude: "-74.85348000",
  },
  {
    id: 126908,
    name: "Springfield Gardens",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.66312000",
    longitude: "-73.76221000",
  },
  {
    id: 126910,
    name: "Springs",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.01621000",
    longitude: "-72.15924000",
  },
  {
    id: 126917,
    name: "Springville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.50840000",
    longitude: "-78.66725000",
  },
  {
    id: 126923,
    name: "Spuyten Duyvil",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.88121000",
    longitude: "-73.91736000",
  },
  {
    id: 126927,
    name: "St. Lawrence County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.50062000",
    longitude: "-75.11631000",
  },
  {
    id: 126945,
    name: "Stamford",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.40730000",
    longitude: "-74.61432000",
  },
  {
    id: 126976,
    name: "Stapleton",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.62649000",
    longitude: "-74.07764000",
  },
  {
    id: 126995,
    name: "Staten Island",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.56233000",
    longitude: "-74.13986000",
  },
  {
    id: 127019,
    name: "Steinway",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.77455000",
    longitude: "-73.90375000",
  },
  {
    id: 127039,
    name: "Steuben County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.26781000",
    longitude: "-77.38380000",
  },
  {
    id: 127052,
    name: "Stewart Manor",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.71927000",
    longitude: "-73.68846000",
  },
  {
    id: 127062,
    name: "Stillwater",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.93841000",
    longitude: "-73.65317000",
  },
  {
    id: 127085,
    name: "Stone Ridge",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.85315000",
    longitude: "-74.13903000",
  },
  {
    id: 127096,
    name: "Stony Brook",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.92565000",
    longitude: "-73.14094000",
  },
  {
    id: 127100,
    name: "Stony Point",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.22954000",
    longitude: "-73.98708000",
  },
  {
    id: 127108,
    name: "Stottville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.28620000",
    longitude: "-73.73873000",
  },
  {
    id: 127164,
    name: "Suffern",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.11482000",
    longitude: "-74.14959000",
  },
  {
    id: 127168,
    name: "Suffolk County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.94046000",
    longitude: "-72.68524000",
  },
  {
    id: 127190,
    name: "Sullivan County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.71642000",
    longitude: "-74.76814000",
  },
  {
    id: 127258,
    name: "Sunnyside",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.73982000",
    longitude: "-73.93542000",
  },
  {
    id: 127276,
    name: "Sunset Park",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.64548000",
    longitude: "-74.01241000",
  },
  {
    id: 127356,
    name: "Sylvan Beach",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.46479000",
    longitude: "-77.10830000",
  },
  {
    id: 127362,
    name: "Syosset",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.82621000",
    longitude: "-73.50207000",
  },
  {
    id: 127365,
    name: "Syracuse",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.04812000",
    longitude: "-76.14742000",
  },
  {
    id: 127426,
    name: "Tappan",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.02204000",
    longitude: "-73.94736000",
  },
  {
    id: 127436,
    name: "Tarrytown",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.07621000",
    longitude: "-73.85875000",
  },
  {
    id: 127517,
    name: "Terrace Heights",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.72149000",
    longitude: "-73.76930000",
  },
  {
    id: 127536,
    name: "Terryville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.90899000",
    longitude: "-73.06511000",
  },
  {
    id: 127554,
    name: "The Bronx",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.84985000",
    longitude: "-73.86641000",
  },
  {
    id: 127575,
    name: "Thiells",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.21065000",
    longitude: "-74.01764000",
  },
  {
    id: 127586,
    name: "Thomaston",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.78621000",
    longitude: "-73.71374000",
  },
  {
    id: 127606,
    name: "Thornwood",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.12343000",
    longitude: "-73.77902000",
  },
  {
    id: 127625,
    name: "Throgs Neck",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.82260000",
    longitude: "-73.81958000",
  },
  {
    id: 127635,
    name: "Ticonderoga",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.84867000",
    longitude: "-73.42345000",
  },
  {
    id: 127652,
    name: "Tillson",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.82898000",
    longitude: "-74.06848000",
  },
  {
    id: 127669,
    name: "Tioga County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.17030000",
    longitude: "-76.30632000",
  },
  {
    id: 127688,
    name: "Tivoli",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.05842000",
    longitude: "-73.90930000",
  },
  {
    id: 127713,
    name: "Tompkins County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.45202000",
    longitude: "-76.47366000",
  },
  {
    id: 127715,
    name: "Tompkinsville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.63812000",
    longitude: "-74.07795000",
  },
  {
    id: 127718,
    name: "Tonawanda",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.02033000",
    longitude: "-78.88031000",
  },
  {
    id: 127758,
    name: "Town Line",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.89061000",
    longitude: "-78.57780000",
  },
  {
    id: 127795,
    name: "Tremont",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.84954000",
    longitude: "-73.90569000",
  },
  {
    id: 127819,
    name: "Tribes Hill",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.95535000",
    longitude: "-74.28513000",
  },
  {
    id: 127851,
    name: "Troy",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.72841000",
    longitude: "-73.69179000",
  },
  {
    id: 127857,
    name: "Trumansburg",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.54229000",
    longitude: "-76.66606000",
  },
  {
    id: 127869,
    name: "Tuckahoe",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.95038000",
    longitude: "-73.82736000",
  },
  {
    id: 127898,
    name: "Tupper Lake",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "44.22395000",
    longitude: "-74.46406000",
  },
  {
    id: 127959,
    name: "Ulster County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.88815000",
    longitude: "-74.25857000",
  },
  {
    id: 127965,
    name: "Unadilla",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.32536000",
    longitude: "-75.31240000",
  },
  {
    id: 128012,
    name: "Union Springs",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.83979000",
    longitude: "-76.69328000",
  },
  {
    id: 128013,
    name: "Uniondale",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.70038000",
    longitude: "-73.59291000",
  },
  {
    id: 128014,
    name: "Unionport",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.82732000",
    longitude: "-73.85013000",
  },
  {
    id: 128028,
    name: "University Gardens",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.77732000",
    longitude: "-73.72263000",
  },
  {
    id: 128030,
    name: "University Heights",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.86010000",
    longitude: "-73.90930000",
  },
  {
    id: 128042,
    name: "Upper Brookville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.83871000",
    longitude: "-73.56513000",
  },
  {
    id: 128048,
    name: "Upper Nyack",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.10704000",
    longitude: "-73.92014000",
  },
  {
    id: 128067,
    name: "Utica",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.10090000",
    longitude: "-75.23266000",
  },
  {
    id: 128077,
    name: "Vails Gate",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.45426000",
    longitude: "-74.05764000",
  },
  {
    id: 128081,
    name: "Valatie",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.41342000",
    longitude: "-73.67317000",
  },
  {
    id: 128092,
    name: "Valhalla",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.07482000",
    longitude: "-73.77513000",
  },
  {
    id: 128102,
    name: "Valley Cottage",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.11815000",
    longitude: "-73.95542000",
  },
  {
    id: 128117,
    name: "Valley Stream",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.66427000",
    longitude: "-73.70846000",
  },
  {
    id: 128136,
    name: "Van Nest",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.84843000",
    longitude: "-73.86375000",
  },
  {
    id: 128190,
    name: "Vernon",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.07951000",
    longitude: "-75.53934000",
  },
  {
    id: 128207,
    name: "Verplanck",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.25287000",
    longitude: "-73.95986000",
  },
  {
    id: 128217,
    name: "Victor",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.98256000",
    longitude: "-77.40888000",
  },
  {
    id: 128247,
    name: "Village Green",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.13340000",
    longitude: "-76.31299000",
  },
  {
    id: 128254,
    name: "Village of the Branch",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.85621000",
    longitude: "-73.18733000",
  },
  {
    id: 128283,
    name: "Viola",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.13648000",
    longitude: "-74.08236000",
  },
  {
    id: 128301,
    name: "Volney",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.34285000",
    longitude: "-76.35771000",
  },
  {
    id: 128307,
    name: "Voorheesville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.65397000",
    longitude: "-73.92874000",
  },
  {
    id: 128324,
    name: "Wading River",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.95038000",
    longitude: "-72.84260000",
  },
  {
    id: 128363,
    name: "Wakefield",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.89788000",
    longitude: "-73.85236000",
  },
  {
    id: 128368,
    name: "Walden",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.56120000",
    longitude: "-74.18848000",
  },
  {
    id: 128410,
    name: "Wallkill",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.60565000",
    longitude: "-74.18404000",
  },
  {
    id: 128436,
    name: "Walton",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.16953000",
    longitude: "-75.12934000",
  },
  {
    id: 128439,
    name: "Walton Park",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.30982000",
    longitude: "-74.22904000",
  },
  {
    id: 128445,
    name: "Wampsville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.07535000",
    longitude: "-75.70685000",
  },
  {
    id: 128446,
    name: "Wanakah",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.74617000",
    longitude: "-78.90309000",
  },
  {
    id: 128452,
    name: "Wantagh",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.68371000",
    longitude: "-73.51013000",
  },
  {
    id: 128456,
    name: "Wappingers Falls",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.59648000",
    longitude: "-73.91097000",
  },
  {
    id: 128497,
    name: "Warren County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.56098000",
    longitude: "-73.84601000",
  },
  {
    id: 128503,
    name: "Warrensburg",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.49674000",
    longitude: "-73.77623000",
  },
  {
    id: 128521,
    name: "Warsaw",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.74006000",
    longitude: "-78.13279000",
  },
  {
    id: 128524,
    name: "Warwick",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.25648000",
    longitude: "-74.35988000",
  },
  {
    id: 128572,
    name: "Washington County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.31370000",
    longitude: "-73.43076000",
  },
  {
    id: 128581,
    name: "Washington Heights",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.85010000",
    longitude: "-73.93541000",
  },
  {
    id: 128582,
    name: "Washington Mills",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.05007000",
    longitude: "-75.27294000",
  },
  {
    id: 128588,
    name: "Washingtonville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.42787000",
    longitude: "-74.16598000",
  },
  {
    id: 128597,
    name: "Watchtower",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.63776000",
    longitude: "-74.26027000",
  },
  {
    id: 128599,
    name: "Water Mill",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.91959000",
    longitude: "-72.34274000",
  },
  {
    id: 128606,
    name: "Waterford",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.79258000",
    longitude: "-73.68123000",
  },
  {
    id: 128615,
    name: "Waterloo",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.90479000",
    longitude: "-76.86274000",
  },
  {
    id: 128623,
    name: "Watertown",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.97478000",
    longitude: "-75.91076000",
  },
  {
    id: 128628,
    name: "Waterville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.93118000",
    longitude: "-75.37989000",
  },
  {
    id: 128630,
    name: "Watervliet",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.73008000",
    longitude: "-73.70123000",
  },
  {
    id: 128634,
    name: "Watkins Glen",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.38063000",
    longitude: "-76.87329000",
  },
  {
    id: 128672,
    name: "Waverly",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.01035000",
    longitude: "-76.52717000",
  },
  {
    id: 128673,
    name: "Wawarsing",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.75898000",
    longitude: "-74.35738000",
  },
  {
    id: 128677,
    name: "Wayland",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.56784000",
    longitude: "-77.58971000",
  },
  {
    id: 128701,
    name: "Wayne County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.06588000",
    longitude: "-76.97845000",
  },
  {
    id: 128733,
    name: "Webster",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.21229000",
    longitude: "-77.42999000",
  },
  {
    id: 128753,
    name: "Weedsport",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.04868000",
    longitude: "-76.56272000",
  },
  {
    id: 128793,
    name: "Wellsville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.12201000",
    longitude: "-77.94806000",
  },
  {
    id: 128811,
    name: "Wesley Hills",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.15926000",
    longitude: "-74.06986000",
  },
  {
    id: 128816,
    name: "West Albany",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.68313000",
    longitude: "-73.77845000",
  },
  {
    id: 128819,
    name: "West Babylon",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.71816000",
    longitude: "-73.35429000",
  },
  {
    id: 128823,
    name: "West Bay Shore",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.70871000",
    longitude: "-73.28123000",
  },
  {
    id: 128843,
    name: "West Carthage",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.97423000",
    longitude: "-75.61519000",
  },
  {
    id: 128861,
    name: "West Elmira",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.07813000",
    longitude: "-76.84524000",
  },
  {
    id: 128863,
    name: "West End",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.46869000",
    longitude: "-75.09378000",
  },
  {
    id: 128879,
    name: "West Glens Falls",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.30007000",
    longitude: "-73.68401000",
  },
  {
    id: 128891,
    name: "West Haverstraw",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.20954000",
    longitude: "-73.98542000",
  },
  {
    id: 128894,
    name: "West Hempstead",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.70482000",
    longitude: "-73.65013000",
  },
  {
    id: 128895,
    name: "West Hills",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.81621000",
    longitude: "-73.43234000",
  },
  {
    id: 128901,
    name: "West Hurley",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.99731000",
    longitude: "-74.10486000",
  },
  {
    id: 128903,
    name: "West Islip",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.70621000",
    longitude: "-73.30623000",
  },
  {
    id: 128942,
    name: "West Nyack",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.09649000",
    longitude: "-73.97292000",
  },
  {
    id: 128962,
    name: "West Point",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.39148000",
    longitude: "-73.95597000",
  },
  {
    id: 128975,
    name: "West Sand Lake",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.64341000",
    longitude: "-73.60873000",
  },
  {
    id: 128976,
    name: "West Sayville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.72788000",
    longitude: "-73.09761000",
  },
  {
    id: 128979,
    name: "West Seneca",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.85006000",
    longitude: "-78.79975000",
  },
  {
    id: 129015,
    name: "Westbury",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.75566000",
    longitude: "-73.58763000",
  },
  {
    id: 129020,
    name: "Westchester County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.15148000",
    longitude: "-73.75339000",
  },
  {
    id: 129022,
    name: "Westerleigh",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.62121000",
    longitude: "-74.13181000",
  },
  {
    id: 129030,
    name: "Westfield",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.32228000",
    longitude: "-79.57810000",
  },
  {
    id: 129035,
    name: "Westhampton",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.82454000",
    longitude: "-72.66621000",
  },
  {
    id: 129036,
    name: "Westhampton Beach",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.80316000",
    longitude: "-72.61454000",
  },
  {
    id: 129042,
    name: "Westmere",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.69119000",
    longitude: "-73.86873000",
  },
  {
    id: 129066,
    name: "Weston Mills",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.07590000",
    longitude: "-78.37252000",
  },
  {
    id: 129074,
    name: "Westvale",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.04757000",
    longitude: "-76.22048000",
  },
  {
    id: 129111,
    name: "Wheatley Heights",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.76371000",
    longitude: "-73.36984000",
  },
  {
    id: 129155,
    name: "White Plains",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.03399000",
    longitude: "-73.76291000",
  },
  {
    id: 129169,
    name: "Whitehall",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.55562000",
    longitude: "-73.40372000",
  },
  {
    id: 129183,
    name: "Whitesboro",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.12201000",
    longitude: "-75.29156000",
  },
  {
    id: 129187,
    name: "Whitestone",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.79455000",
    longitude: "-73.81847000",
  },
  {
    id: 129270,
    name: "Williamsburg",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.71427000",
    longitude: "-73.95347000",
  },
  {
    id: 129274,
    name: "Williamson",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.22395000",
    longitude: "-77.18609000",
  },
  {
    id: 129292,
    name: "Williamsville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.96395000",
    longitude: "-78.73781000",
  },
  {
    id: 129301,
    name: "Williston Park",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.75649000",
    longitude: "-73.64485000",
  },
  {
    id: 129314,
    name: "Willowbrook",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.60316000",
    longitude: "-74.13848000",
  },
  {
    id: 129331,
    name: "Wilson",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.30978000",
    longitude: "-78.82615000",
  },
  {
    id: 129469,
    name: "Wolcott",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.22062000",
    longitude: "-76.81496000",
  },
  {
    id: 129510,
    name: "Woodbury",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.36454000",
    longitude: "-74.10598000",
  },
  {
    id: 129522,
    name: "Woodhaven",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.68927000",
    longitude: "-73.85791000",
  },
  {
    id: 129537,
    name: "Woodlawn",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.89816000",
    longitude: "-73.86736000",
  },
  {
    id: 129541,
    name: "Woodmere",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.63205000",
    longitude: "-73.71263000",
  },
  {
    id: 129546,
    name: "Woodrow",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.54344000",
    longitude: "-74.19764000",
  },
  {
    id: 129554,
    name: "Woodside",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.74538000",
    longitude: "-73.90541000",
  },
  {
    id: 129565,
    name: "Woodstock",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.04092000",
    longitude: "-74.11820000",
  },
  {
    id: 129585,
    name: "Worcester",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.59146000",
    longitude: "-74.75043000",
  },
  {
    id: 129619,
    name: "Wurtsboro",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.57676000",
    longitude: "-74.48710000",
  },
  {
    id: 129620,
    name: "Wyandanch",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.75399000",
    longitude: "-73.36040000",
  },
  {
    id: 129624,
    name: "Wykagyl",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.94149000",
    longitude: "-73.79902000",
  },
  {
    id: 129628,
    name: "Wynantskill",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.69675000",
    longitude: "-73.64428000",
  },
  {
    id: 129640,
    name: "Wyoming County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.70238000",
    longitude: "-78.22444000",
  },
  {
    id: 129663,
    name: "Yaphank",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.83677000",
    longitude: "-72.91705000",
  },
  {
    id: 129671,
    name: "Yates County",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.63344000",
    longitude: "-77.10546000",
  },
  {
    id: 129688,
    name: "Yonkers",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "40.93121000",
    longitude: "-73.89875000",
  },
  {
    id: 129704,
    name: "Yorkshire",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.53006000",
    longitude: "-78.47280000",
  },
  {
    id: 129708,
    name: "Yorktown Heights",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "41.27093000",
    longitude: "-73.77763000",
  },
  {
    id: 129710,
    name: "Yorkville",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.11285000",
    longitude: "-75.27100000",
  },
  {
    id: 129717,
    name: "Youngstown",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "43.24728000",
    longitude: "-79.05005000",
  },
  {
    id: 129748,
    name: "Zena",
    state_id: 1452,
    state_code: "NY",
    country_id: 233,
    country_code: "US",
    latitude: "42.01676000",
    longitude: "-74.07625000",
  },
  {
    id: 110975,
    name: "Aberdeen",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.13155000",
    longitude: "-79.42948000",
  },
  {
    id: 111049,
    name: "Advance",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.94125000",
    longitude: "-80.40922000",
  },
  {
    id: 111062,
    name: "Ahoskie",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.28682000",
    longitude: "-76.98468000",
  },
  {
    id: 111087,
    name: "Alamance County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.04407000",
    longitude: "-79.39951000",
  },
  {
    id: 111115,
    name: "Albemarle",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.35014000",
    longitude: "-80.20006000",
  },
  {
    id: 111148,
    name: "Alexander County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.92102000",
    longitude: "-81.17702000",
  },
  {
    id: 111183,
    name: "Alleghany County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.49134000",
    longitude: "-81.12719000",
  },
  {
    id: 111328,
    name: "Andrews",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.20175000",
    longitude: "-83.82407000",
  },
  {
    id: 111338,
    name: "Angier",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.50710000",
    longitude: "-78.73918000",
  },
  {
    id: 111361,
    name: "Anson County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.97383000",
    longitude: "-80.10273000",
  },
  {
    id: 111384,
    name: "Apex",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.73265000",
    longitude: "-78.85029000",
  },
  {
    id: 111428,
    name: "Archdale",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.91458000",
    longitude: "-79.97198000",
  },
  {
    id: 111432,
    name: "Archer Lodge",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.69404000",
    longitude: "-78.37556000",
  },
  {
    id: 111519,
    name: "Ashe County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.43416000",
    longitude: "-81.50034000",
  },
  {
    id: 111520,
    name: "Asheboro",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.70791000",
    longitude: "-79.81364000",
  },
  {
    id: 111522,
    name: "Asheville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.60095000",
    longitude: "-82.55402000",
  },
  {
    id: 111596,
    name: "Atlantic Beach",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.69905000",
    longitude: "-76.74021000",
  },
  {
    id: 111681,
    name: "Avery County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.07661000",
    longitude: "-81.92247000",
  },
  {
    id: 111682,
    name: "Avery Creek",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.46345000",
    longitude: "-82.58262000",
  },
  {
    id: 111707,
    name: "Ayden",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.47266000",
    longitude: "-77.41552000",
  },
  {
    id: 111725,
    name: "Badin",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.40597000",
    longitude: "-80.11672000",
  },
  {
    id: 111746,
    name: "Bakersville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.01567000",
    longitude: "-82.15874000",
  },
  {
    id: 111767,
    name: "Balfour",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.34651000",
    longitude: "-82.47206000",
  },
  {
    id: 111801,
    name: "Banner Elk",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.16318000",
    longitude: "-81.87150000",
  },
  {
    id: 111823,
    name: "Barker Heights",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.31123000",
    longitude: "-82.44401000",
  },
  {
    id: 111939,
    name: "Bayboro",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.14294000",
    longitude: "-76.77021000",
  },
  {
    id: 111953,
    name: "Bayshore",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.28961000",
    longitude: "-77.78748000",
  },
  {
    id: 111985,
    name: "Beaufort",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.71822000",
    longitude: "-76.66382000",
  },
  {
    id: 111987,
    name: "Beaufort County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.48583000",
    longitude: "-76.84516000",
  },
  {
    id: 112063,
    name: "Belhaven",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.54017000",
    longitude: "-76.62299000",
  },
  {
    id: 112136,
    name: "Belmont",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.24292000",
    longitude: "-81.03730000",
  },
  {
    id: 112157,
    name: "Belville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.23073000",
    longitude: "-77.96582000",
  },
  {
    id: 112182,
    name: "Benson",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.38211000",
    longitude: "-78.54862000",
  },
  {
    id: 112189,
    name: "Bent Creek",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.50900000",
    longitude: "-82.60790000",
  },
  {
    id: 112241,
    name: "Bermuda Run",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.00375000",
    longitude: "-80.42200000",
  },
  {
    id: 112258,
    name: "Bertie County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.06556000",
    longitude: "-76.96660000",
  },
  {
    id: 112270,
    name: "Bessemer City",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.28486000",
    longitude: "-81.28397000",
  },
  {
    id: 112278,
    name: "Bethel",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.80710000",
    longitude: "-77.37886000",
  },
  {
    id: 112289,
    name: "Bethlehem",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.82569000",
    longitude: "-81.30703000",
  },
  {
    id: 112297,
    name: "Beulaville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.92378000",
    longitude: "-77.77387000",
  },
  {
    id: 112348,
    name: "Biltmore Forest",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.53372000",
    longitude: "-82.52846000",
  },
  {
    id: 112362,
    name: "Biscoe",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.35986000",
    longitude: "-79.77976000",
  },
  {
    id: 112380,
    name: "Black Mountain",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.61790000",
    longitude: "-82.32123000",
  },
  {
    id: 112398,
    name: "Bladen County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.61431000",
    longitude: "-78.56318000",
  },
  {
    id: 112399,
    name: "Bladenboro",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.53878000",
    longitude: "-78.78752000",
  },
  {
    id: 112462,
    name: "Blowing Rock",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.13513000",
    longitude: "-81.67761000",
  },
  {
    id: 112504,
    name: "Boiling Spring Lakes",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.03045000",
    longitude: "-78.06721000",
  },
  {
    id: 112505,
    name: "Boiling Springs",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.25429000",
    longitude: "-81.66704000",
  },
  {
    id: 112523,
    name: "Bolivia",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.06767000",
    longitude: "-78.14833000",
  },
  {
    id: 112552,
    name: "Boone",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.21679000",
    longitude: "-81.67455000",
  },
  {
    id: 112569,
    name: "Boonville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.23264000",
    longitude: "-80.70812000",
  },
  {
    id: 112737,
    name: "Brevard",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.23345000",
    longitude: "-82.73429000",
  },
  {
    id: 112753,
    name: "Brices Creek",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.05599000",
    longitude: "-77.08773000",
  },
  {
    id: 112821,
    name: "Broad Creek",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.72072000",
    longitude: "-76.93633000",
  },
  {
    id: 112830,
    name: "Broadway",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.45793000",
    longitude: "-79.05308000",
  },
  {
    id: 112840,
    name: "Brogden",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.29266000",
    longitude: "-78.03443000",
  },
  {
    id: 112949,
    name: "Brunswick",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.28684000",
    longitude: "-78.70113000",
  },
  {
    id: 112951,
    name: "Brunswick County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.03897000",
    longitude: "-78.22728000",
  },
  {
    id: 112968,
    name: "Bryson City",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.43127000",
    longitude: "-83.44944000",
  },
  {
    id: 113025,
    name: "Buies Creek",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.41322000",
    longitude: "-78.73557000",
  },
  {
    id: 113038,
    name: "Buncombe County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.61122000",
    longitude: "-82.53010000",
  },
  {
    id: 113050,
    name: "Burgaw",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.55211000",
    longitude: "-77.92610000",
  },
  {
    id: 113057,
    name: "Burke County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.74952000",
    longitude: "-81.70470000",
  },
  {
    id: 113068,
    name: "Burlington",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.09569000",
    longitude: "-79.43780000",
  },
  {
    id: 113090,
    name: "Burnsville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.91734000",
    longitude: "-82.30096000",
  },
  {
    id: 113121,
    name: "Butner",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.13209000",
    longitude: "-78.75667000",
  },
  {
    id: 113131,
    name: "Buxton",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.26768000",
    longitude: "-75.54237000",
  },
  {
    id: 113149,
    name: "Cabarrus County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.38687000",
    longitude: "-80.55204000",
  },
  {
    id: 113172,
    name: "Cajahs Mountain",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.83485000",
    longitude: "-81.54148000",
  },
  {
    id: 113174,
    name: "Calabash",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "33.89073000",
    longitude: "-78.56834000",
  },
  {
    id: 113185,
    name: "Caldwell County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.95297000",
    longitude: "-81.54655000",
  },
  {
    id: 113262,
    name: "Camden",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.32849000",
    longitude: "-76.17188000",
  },
  {
    id: 113270,
    name: "Camden County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.34145000",
    longitude: "-76.16112000",
  },
  {
    id: 113341,
    name: "Canton",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.53288000",
    longitude: "-82.83736000",
  },
  {
    id: 113364,
    name: "Cape Carteret",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.69155000",
    longitude: "-77.06300000",
  },
  {
    id: 113435,
    name: "Carolina Beach",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.03517000",
    longitude: "-77.89360000",
  },
  {
    id: 113436,
    name: "Carolina Shores",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "33.90101000",
    longitude: "-78.58057000",
  },
  {
    id: 113442,
    name: "Carrboro",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.91014000",
    longitude: "-79.07529000",
  },
  {
    id: 113487,
    name: "Carteret County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.86401000",
    longitude: "-76.53249000",
  },
  {
    id: 113493,
    name: "Carthage",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.34599000",
    longitude: "-79.41697000",
  },
  {
    id: 113505,
    name: "Cary",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.79154000",
    longitude: "-78.78112000",
  },
  {
    id: 113544,
    name: "Castle Hayne",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.35572000",
    longitude: "-77.89999000",
  },
  {
    id: 113560,
    name: "Caswell County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.39335000",
    longitude: "-79.33359000",
  },
  {
    id: 113567,
    name: "Catawba County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.66261000",
    longitude: "-81.21448000",
  },
  {
    id: 113620,
    name: "Cedar Point",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.68766000",
    longitude: "-77.07245000",
  },
  {
    id: 113706,
    name: "Chadbourn",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.32211000",
    longitude: "-78.82697000",
  },
  {
    id: 113741,
    name: "Chapel Hill",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.91320000",
    longitude: "-79.05584000",
  },
  {
    id: 113776,
    name: "Charlotte",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.22709000",
    longitude: "-80.84313000",
  },
  {
    id: 113802,
    name: "Chatham County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.70258000",
    longitude: "-79.25535000",
  },
  {
    id: 113846,
    name: "Cherokee",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.47427000",
    longitude: "-83.31487000",
  },
  {
    id: 113852,
    name: "Cherokee County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.13384000",
    longitude: "-84.06347000",
  },
  {
    id: 113870,
    name: "Cherryville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.37874000",
    longitude: "-81.37897000",
  },
  {
    id: 113961,
    name: "China Grove",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.56931000",
    longitude: "-80.58173000",
  },
  {
    id: 113992,
    name: "Chowan County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.12656000",
    longitude: "-76.60216000",
  },
  {
    id: 114104,
    name: "Claremont",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.71458000",
    longitude: "-81.14619000",
  },
  {
    id: 114183,
    name: "Clay County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.05719000",
    longitude: "-83.75021000",
  },
  {
    id: 114200,
    name: "Clayton",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.65071000",
    longitude: "-78.45639000",
  },
  {
    id: 114233,
    name: "Clemmons",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.02153000",
    longitude: "-80.38200000",
  },
  {
    id: 114249,
    name: "Cleveland County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.33411000",
    longitude: "-81.55561000",
  },
  {
    id: 114273,
    name: "Clinton",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.99795000",
    longitude: "-78.32333000",
  },
  {
    id: 114318,
    name: "Clyde",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.53344000",
    longitude: "-82.91069000",
  },
  {
    id: 114342,
    name: "Coats",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.40794000",
    longitude: "-78.67196000",
  },
  {
    id: 114470,
    name: "Columbia",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.91766000",
    longitude: "-76.25215000",
  },
  {
    id: 114493,
    name: "Columbus",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.25317000",
    longitude: "-82.19706000",
  },
  {
    id: 114502,
    name: "Columbus County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.26540000",
    longitude: "-78.65507000",
  },
  {
    id: 114536,
    name: "Concord",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.40888000",
    longitude: "-80.58158000",
  },
  {
    id: 114563,
    name: "Connelly Springs",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.74291000",
    longitude: "-81.51343000",
  },
  {
    id: 114567,
    name: "Conover",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.70652000",
    longitude: "-81.21869000",
  },
  {
    id: 114639,
    name: "Cordova",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.91293000",
    longitude: "-79.82200000",
  },
  {
    id: 114647,
    name: "Cornelius",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.48680000",
    longitude: "-80.86007000",
  },
  {
    id: 114730,
    name: "Cove Creek",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.60649000",
    longitude: "-83.01125000",
  },
  {
    id: 114762,
    name: "Cramerton",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.23875000",
    longitude: "-81.07508000",
  },
  {
    id: 114773,
    name: "Craven County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.11722000",
    longitude: "-77.08263000",
  },
  {
    id: 114788,
    name: "Creedmoor",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.12237000",
    longitude: "-78.68611000",
  },
  {
    id: 114819,
    name: "Cricket",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.17152000",
    longitude: "-81.19398000",
  },
  {
    id: 114898,
    name: "Cullowhee",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.31371000",
    longitude: "-83.17653000",
  },
  {
    id: 114914,
    name: "Cumberland County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.04859000",
    longitude: "-78.82744000",
  },
  {
    id: 114925,
    name: "Currituck",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.44988000",
    longitude: "-76.01548000",
  },
  {
    id: 114926,
    name: "Currituck County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.36724000",
    longitude: "-75.93683000",
  },
  {
    id: 114989,
    name: "Dallas",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.31653000",
    longitude: "-81.17619000",
  },
  {
    id: 115011,
    name: "Dana",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.32928000",
    longitude: "-82.37540000",
  },
  {
    id: 115013,
    name: "Danbury",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.40930000",
    longitude: "-80.20588000",
  },
  {
    id: 115044,
    name: "Dare County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.78663000",
    longitude: "-75.78094000",
  },
  {
    id: 115062,
    name: "Davidson",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.50233000",
    longitude: "-80.83912000",
  },
  {
    id: 115063,
    name: "Davidson County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.79328000",
    longitude: "-80.21269000",
  },
  {
    id: 115068,
    name: "Davie County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.92916000",
    longitude: "-80.54447000",
  },
  {
    id: 115246,
    name: "Denton",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.63347000",
    longitude: "-80.11588000",
  },
  {
    id: 115250,
    name: "Denver",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.53125000",
    longitude: "-81.02980000",
  },
  {
    id: 115371,
    name: "Dobson",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.39569000",
    longitude: "-80.72257000",
  },
  {
    id: 115475,
    name: "Drexel",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.75791000",
    longitude: "-81.60426000",
  },
  {
    id: 115546,
    name: "Dunn",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.30627000",
    longitude: "-78.60890000",
  },
  {
    id: 115556,
    name: "Duplin County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.93628000",
    longitude: "-77.93294000",
  },
  {
    id: 115569,
    name: "Durham",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.99403000",
    longitude: "-78.89862000",
  },
  {
    id: 115575,
    name: "Durham County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.03600000",
    longitude: "-78.87632000",
  },
  {
    id: 115660,
    name: "East Flat Rock",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.28012000",
    longitude: "-82.42206000",
  },
  {
    id: 115751,
    name: "East Rockingham",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.91821000",
    longitude: "-79.76256000",
  },
  {
    id: 115760,
    name: "East Spencer",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.68181000",
    longitude: "-80.43228000",
  },
  {
    id: 115792,
    name: "Eastover",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.10000000",
    longitude: "-78.80000000",
  },
  {
    id: 115828,
    name: "Eden",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.48847000",
    longitude: "-79.76670000",
  },
  {
    id: 115834,
    name: "Edenton",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.05794000",
    longitude: "-76.60772000",
  },
  {
    id: 115841,
    name: "Edgecombe County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.91298000",
    longitude: "-77.59707000",
  },
  {
    id: 115885,
    name: "Edneyville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.39401000",
    longitude: "-82.34095000",
  },
  {
    id: 115975,
    name: "Elizabeth City",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.29460000",
    longitude: "-76.25105000",
  },
  {
    id: 115979,
    name: "Elizabethtown",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.62934000",
    longitude: "-78.60529000",
  },
  {
    id: 116004,
    name: "Elkin",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.24430000",
    longitude: "-80.84840000",
  },
  {
    id: 116021,
    name: "Ellerbe",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.07126000",
    longitude: "-79.76144000",
  },
  {
    id: 116042,
    name: "Elm City",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.80655000",
    longitude: "-77.86332000",
  },
  {
    id: 116064,
    name: "Elon",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.10291000",
    longitude: "-79.50669000",
  },
  {
    id: 116066,
    name: "Elroy",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.34266000",
    longitude: "-77.90859000",
  },
  {
    id: 116089,
    name: "Emerald Isle",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.67794000",
    longitude: "-76.95078000",
  },
  {
    id: 116124,
    name: "Enfield",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.18099000",
    longitude: "-77.66664000",
  },
  {
    id: 116143,
    name: "Enochville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.52986000",
    longitude: "-80.66812000",
  },
  {
    id: 116175,
    name: "Erwin",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.32683000",
    longitude: "-78.67613000",
  },
  {
    id: 116217,
    name: "Etowah",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.31762000",
    longitude: "-82.59429000",
  },
  {
    id: 116322,
    name: "Fairfield Harbour",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.07655000",
    longitude: "-76.96356000",
  },
  {
    id: 116334,
    name: "Fairmont",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.49683000",
    longitude: "-79.11420000",
  },
  {
    id: 116343,
    name: "Fairplains",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.19847000",
    longitude: "-81.15286000",
  },
  {
    id: 116348,
    name: "Fairview",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.51401000",
    longitude: "-82.39595000",
  },
  {
    id: 116426,
    name: "Farmville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.59544000",
    longitude: "-77.58525000",
  },
  {
    id: 116456,
    name: "Fayetteville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.05266000",
    longitude: "-78.87836000",
  },
  {
    id: 116461,
    name: "Fearrington Village",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.80376000",
    longitude: "-79.09029000",
  },
  {
    id: 116551,
    name: "Flat Rock",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.27123000",
    longitude: "-82.44151000",
  },
  {
    id: 116564,
    name: "Fletcher",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.43067000",
    longitude: "-82.50123000",
  },
  {
    id: 116647,
    name: "Forest City",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.33401000",
    longitude: "-81.86510000",
  },
  {
    id: 116663,
    name: "Forest Oaks",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.98819000",
    longitude: "-79.70614000",
  },
  {
    id: 116687,
    name: "Forsyth County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.13049000",
    longitude: "-80.25636000",
  },
  {
    id: 116696,
    name: "Fort Bragg",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.13900000",
    longitude: "-79.00603000",
  },
  {
    id: 116771,
    name: "Foscoe",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.16179000",
    longitude: "-81.76566000",
  },
  {
    id: 116792,
    name: "Four Oaks",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.44488000",
    longitude: "-78.42695000",
  },
  {
    id: 116828,
    name: "Franklin",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.18232000",
    longitude: "-83.38154000",
  },
  {
    id: 116853,
    name: "Franklin County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.08279000",
    longitude: "-78.28561000",
  },
  {
    id: 116874,
    name: "Franklinton",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.10182000",
    longitude: "-78.45805000",
  },
  {
    id: 116875,
    name: "Franklinville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.74375000",
    longitude: "-79.69225000",
  },
  {
    id: 116919,
    name: "Fremont",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.54544000",
    longitude: "-77.97471000",
  },
  {
    id: 116971,
    name: "Fruitland",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.39651000",
    longitude: "-82.39317000",
  },
  {
    id: 117000,
    name: "Fuquay-Varina",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.58432000",
    longitude: "-78.80001000",
  },
  {
    id: 117046,
    name: "Gamewell",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.86930000",
    longitude: "-81.59621000",
  },
  {
    id: 117092,
    name: "Garner",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.71126000",
    longitude: "-78.61417000",
  },
  {
    id: 117113,
    name: "Gaston",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.50043000",
    longitude: "-77.64498000",
  },
  {
    id: 117115,
    name: "Gaston County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.29437000",
    longitude: "-81.18025000",
  },
  {
    id: 117116,
    name: "Gastonia",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.26208000",
    longitude: "-81.18730000",
  },
  {
    id: 117119,
    name: "Gates County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.44489000",
    longitude: "-76.70049000",
  },
  {
    id: 117121,
    name: "Gatesville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.40349000",
    longitude: "-76.75301000",
  },
  {
    id: 117194,
    name: "Gibsonville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.10569000",
    longitude: "-79.54225000",
  },
  {
    id: 117260,
    name: "Glen Alpine",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.72902000",
    longitude: "-81.77927000",
  },
  {
    id: 117270,
    name: "Glen Raven",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.11319000",
    longitude: "-79.47641000",
  },
  {
    id: 117364,
    name: "Goldsboro",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.38488000",
    longitude: "-77.99277000",
  },
  {
    id: 117405,
    name: "Gorman",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.03653000",
    longitude: "-78.82334000",
  },
  {
    id: 117434,
    name: "Graham",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.06903000",
    longitude: "-79.40058000",
  },
  {
    id: 117438,
    name: "Graham County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.35016000",
    longitude: "-83.83356000",
  },
  {
    id: 117496,
    name: "Granite Falls",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.79652000",
    longitude: "-81.43065000",
  },
  {
    id: 117500,
    name: "Granite Quarry",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.61236000",
    longitude: "-80.44673000",
  },
  {
    id: 117537,
    name: "Granville County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.30402000",
    longitude: "-78.65302000",
  },
  {
    id: 117603,
    name: "Green Level",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.12097000",
    longitude: "-79.34419000",
  },
  {
    id: 117638,
    name: "Greene County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.48541000",
    longitude: "-77.67587000",
  },
  {
    id: 117667,
    name: "Greensboro",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.07264000",
    longitude: "-79.79198000",
  },
  {
    id: 117687,
    name: "Greenville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.61266000",
    longitude: "-77.36635000",
  },
  {
    id: 117733,
    name: "Grifton",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.37266000",
    longitude: "-77.43746000",
  },
  {
    id: 117787,
    name: "Guilford County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.07945000",
    longitude: "-79.78901000",
  },
  {
    id: 117855,
    name: "Half Moon",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.82600000",
    longitude: "-77.45941000",
  },
  {
    id: 117859,
    name: "Halifax",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.32849000",
    longitude: "-77.58942000",
  },
  {
    id: 117862,
    name: "Halifax County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.25750000",
    longitude: "-77.65188000",
  },
  {
    id: 117907,
    name: "Hamlet",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.88488000",
    longitude: "-79.69422000",
  },
  {
    id: 117924,
    name: "Hampstead",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.36767000",
    longitude: "-77.71053000",
  },
  {
    id: 118014,
    name: "Harkers Island",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.69516000",
    longitude: "-76.55937000",
  },
  {
    id: 118029,
    name: "Harnett County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.36860000",
    longitude: "-78.86931000",
  },
  {
    id: 118049,
    name: "Harrisburg",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.32395000",
    longitude: "-80.65784000",
  },
  {
    id: 118147,
    name: "Havelock",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.87905000",
    longitude: "-76.90133000",
  },
  {
    id: 118156,
    name: "Haw River",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.09153000",
    longitude: "-79.36419000",
  },
  {
    id: 118182,
    name: "Hayesville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.04620000",
    longitude: "-83.81795000",
  },
  {
    id: 118191,
    name: "Hays",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.24985000",
    longitude: "-81.11564000",
  },
  {
    id: 118198,
    name: "Haywood County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.55605000",
    longitude: "-82.98224000",
  },
  {
    id: 118208,
    name: "Hazelwood",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.46871000",
    longitude: "-83.00403000",
  },
  {
    id: 118263,
    name: "Hemby Bridge",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.10371000",
    longitude: "-80.62798000",
  },
  {
    id: 118276,
    name: "Henderson",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.32959000",
    longitude: "-78.39916000",
  },
  {
    id: 118281,
    name: "Henderson County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.33629000",
    longitude: "-82.47991000",
  },
  {
    id: 118285,
    name: "Hendersonville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.31873000",
    longitude: "-82.46095000",
  },
  {
    id: 118342,
    name: "Hertford",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.19016000",
    longitude: "-76.46605000",
  },
  {
    id: 118343,
    name: "Hertford County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.35863000",
    longitude: "-76.98066000",
  },
  {
    id: 118365,
    name: "Hickory",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.73319000",
    longitude: "-81.34120000",
  },
  {
    id: 118386,
    name: "High Point",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.95569000",
    longitude: "-80.00532000",
  },
  {
    id: 118430,
    name: "Hildebran",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.71402000",
    longitude: "-81.42203000",
  },
  {
    id: 118454,
    name: "Hillsborough",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.07542000",
    longitude: "-79.09973000",
  },
  {
    id: 118514,
    name: "Hoke County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.01736000",
    longitude: "-79.23711000",
  },
  {
    id: 118560,
    name: "Holly Ridge",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.49544000",
    longitude: "-77.55497000",
  },
  {
    id: 118562,
    name: "Holly Springs",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.65127000",
    longitude: "-78.83362000",
  },
  {
    id: 118635,
    name: "Hoopers Creek",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.43900000",
    longitude: "-82.46679000",
  },
  {
    id: 118645,
    name: "Hope Mills",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.97044000",
    longitude: "-78.94531000",
  },
  {
    id: 118672,
    name: "Horse Shoe",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.34317000",
    longitude: "-82.55651000",
  },
  {
    id: 118736,
    name: "Hudson",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.84846000",
    longitude: "-81.49593000",
  },
  {
    id: 118792,
    name: "Huntersville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.41069000",
    longitude: "-80.84285000",
  },
  {
    id: 118847,
    name: "Hyde County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.41004000",
    longitude: "-76.14850000",
  },
  {
    id: 118865,
    name: "Icard",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.72735000",
    longitude: "-81.47065000",
  },
  {
    id: 118920,
    name: "Indian Trail",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.07681000",
    longitude: "-80.66924000",
  },
  {
    id: 118985,
    name: "Iredell County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.80708000",
    longitude: "-80.87344000",
  },
  {
    id: 119067,
    name: "Jackson",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.38960000",
    longitude: "-77.42136000",
  },
  {
    id: 119085,
    name: "Jackson County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.28739000",
    longitude: "-83.14083000",
  },
  {
    id: 119103,
    name: "Jacksonville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.75405000",
    longitude: "-77.43024000",
  },
  {
    id: 119115,
    name: "James City",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.08877000",
    longitude: "-77.03495000",
  },
  {
    id: 119121,
    name: "Jamestown",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.99430000",
    longitude: "-79.93531000",
  },
  {
    id: 119165,
    name: "Jefferson",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.42040000",
    longitude: "-81.47344000",
  },
  {
    id: 119281,
    name: "Johnston County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.51761000",
    longitude: "-78.36564000",
  },
  {
    id: 119294,
    name: "Jones County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.02170000",
    longitude: "-77.35526000",
  },
  {
    id: 119310,
    name: "Jonesville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.23930000",
    longitude: "-80.84452000",
  },
  {
    id: 119382,
    name: "Kannapolis",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.48736000",
    longitude: "-80.62173000",
  },
  {
    id: 119442,
    name: "Kenansville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.96239000",
    longitude: "-77.96221000",
  },
  {
    id: 119459,
    name: "Kenly",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.59627000",
    longitude: "-78.12416000",
  },
  {
    id: 119515,
    name: "Kernersville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.11986000",
    longitude: "-80.07365000",
  },
  {
    id: 119557,
    name: "Kill Devil Hills",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.03072000",
    longitude: "-75.67601000",
  },
  {
    id: 119576,
    name: "King",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.28069000",
    longitude: "-80.35922000",
  },
  {
    id: 119602,
    name: "Kings Grant",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.26295000",
    longitude: "-77.86360000",
  },
  {
    id: 119603,
    name: "Kings Mountain",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.24513000",
    longitude: "-81.34119000",
  },
  {
    id: 119643,
    name: "Kinston",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.26266000",
    longitude: "-77.58164000",
  },
  {
    id: 119668,
    name: "Kitty Hawk",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.06461000",
    longitude: "-75.70573000",
  },
  {
    id: 119674,
    name: "Knightdale",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.78765000",
    longitude: "-78.48056000",
  },
  {
    id: 119726,
    name: "Kure Beach",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "33.99684000",
    longitude: "-77.90721000",
  },
  {
    id: 119750,
    name: "La Grange",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.30683000",
    longitude: "-77.78803000",
  },
  {
    id: 119925,
    name: "Lake Junaluska",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.52788000",
    longitude: "-82.95958000",
  },
  {
    id: 119934,
    name: "Lake Lure",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.42790000",
    longitude: "-82.20483000",
  },
  {
    id: 119952,
    name: "Lake Norman of Catawba",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.58680000",
    longitude: "-80.95952000",
  },
  {
    id: 119960,
    name: "Lake Park",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.08626000",
    longitude: "-80.63507000",
  },
  {
    id: 119990,
    name: "Lake Waccamaw",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.31906000",
    longitude: "-78.50001000",
  },
  {
    id: 120099,
    name: "Landis",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.54569000",
    longitude: "-80.61090000",
  },
  {
    id: 120196,
    name: "Laurel Hill",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.80905000",
    longitude: "-79.54783000",
  },
  {
    id: 120200,
    name: "Laurel Park",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.31373000",
    longitude: "-82.49345000",
  },
  {
    id: 120210,
    name: "Laurinburg",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.77405000",
    longitude: "-79.46282000",
  },
  {
    id: 120312,
    name: "Lee County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.47517000",
    longitude: "-79.17143000",
  },
  {
    id: 120350,
    name: "Leland",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.25628000",
    longitude: "-78.04471000",
  },
  {
    id: 120372,
    name: "Lenoir",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.91402000",
    longitude: "-81.53898000",
  },
  {
    id: 120374,
    name: "Lenoir County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.23915000",
    longitude: "-77.64127000",
  },
  {
    id: 120435,
    name: "Lewisville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.09708000",
    longitude: "-80.41922000",
  },
  {
    id: 120442,
    name: "Lexington",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.82403000",
    longitude: "-80.25338000",
  },
  {
    id: 120462,
    name: "Liberty",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.85347000",
    longitude: "-79.57169000",
  },
  {
    id: 120486,
    name: "Lillington",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.39933000",
    longitude: "-78.81585000",
  },
  {
    id: 120522,
    name: "Lincoln County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.48618000",
    longitude: "-81.22387000",
  },
  {
    id: 120552,
    name: "Lincolnton",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.47375000",
    longitude: "-81.25453000",
  },
  {
    id: 120684,
    name: "Locust",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.25987000",
    longitude: "-80.42534000",
  },
  {
    id: 120734,
    name: "Long Beach",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "33.91045000",
    longitude: "-78.11777000",
  },
  {
    id: 120756,
    name: "Longview",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.72930000",
    longitude: "-81.38342000",
  },
  {
    id: 120809,
    name: "Louisburg",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.09904000",
    longitude: "-78.30111000",
  },
  {
    id: 120834,
    name: "Lowell",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.26792000",
    longitude: "-81.10285000",
  },
  {
    id: 120845,
    name: "Lowesville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.41708000",
    longitude: "-81.01119000",
  },
  {
    id: 120859,
    name: "Lucama",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.64544000",
    longitude: "-78.00971000",
  },
  {
    id: 120880,
    name: "Lumberton",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.61834000",
    longitude: "-79.01045000",
  },
  {
    id: 120971,
    name: "Macon County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.15038000",
    longitude: "-83.42210000",
  },
  {
    id: 120990,
    name: "Madison",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.38542000",
    longitude: "-79.95949000",
  },
  {
    id: 121010,
    name: "Madison County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.85809000",
    longitude: "-82.70576000",
  },
  {
    id: 121033,
    name: "Maggie Valley",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.51816000",
    longitude: "-83.09764000",
  },
  {
    id: 121049,
    name: "Maiden",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.57569000",
    longitude: "-81.21175000",
  },
  {
    id: 121156,
    name: "Manteo",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.90823000",
    longitude: "-75.67573000",
  },
  {
    id: 121182,
    name: "Mar-Mac",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.33488000",
    longitude: "-78.05582000",
  },
  {
    id: 121236,
    name: "Marion",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.68401000",
    longitude: "-82.00927000",
  },
  {
    id: 121299,
    name: "Mars Hill",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.82650000",
    longitude: "-82.54930000",
  },
  {
    id: 121304,
    name: "Marshall",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.79733000",
    longitude: "-82.68403000",
  },
  {
    id: 121329,
    name: "Marshville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.98849000",
    longitude: "-80.36701000",
  },
  {
    id: 121340,
    name: "Martin County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.84160000",
    longitude: "-77.10708000",
  },
  {
    id: 121353,
    name: "Marvin",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.99182000",
    longitude: "-80.81479000",
  },
  {
    id: 121383,
    name: "Masonboro",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.17934000",
    longitude: "-77.84748000",
  },
  {
    id: 121412,
    name: "Matthews",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.11681000",
    longitude: "-80.72368000",
  },
  {
    id: 121425,
    name: "Maury",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.48211000",
    longitude: "-77.58608000",
  },
  {
    id: 121429,
    name: "Maxton",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.73516000",
    longitude: "-79.34893000",
  },
  {
    id: 121445,
    name: "Mayodan",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.41236000",
    longitude: "-79.96699000",
  },
  {
    id: 121451,
    name: "Maysville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.90488000",
    longitude: "-77.23134000",
  },
  {
    id: 121495,
    name: "McDowell County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.68124000",
    longitude: "-82.04870000",
  },
  {
    id: 121532,
    name: "McLeansville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.10736000",
    longitude: "-79.65864000",
  },
  {
    id: 121577,
    name: "Mebane",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.09597000",
    longitude: "-79.26696000",
  },
  {
    id: 121587,
    name: "Mecklenburg County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.24671000",
    longitude: "-80.83276000",
  },
  {
    id: 121797,
    name: "Midland",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.22737000",
    longitude: "-80.50062000",
  },
  {
    id: 121816,
    name: "Midway",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.95347000",
    longitude: "-80.21810000",
  },
  {
    id: 121884,
    name: "Millers Creek",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.18930000",
    longitude: "-81.23759000",
  },
  {
    id: 121899,
    name: "Mills River",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.38845000",
    longitude: "-82.56679000",
  },
  {
    id: 121940,
    name: "Mineral Springs",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.93793000",
    longitude: "-80.66868000",
  },
  {
    id: 121968,
    name: "Mint Hill",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.17959000",
    longitude: "-80.64729000",
  },
  {
    id: 122001,
    name: "Mitchell County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.01329000",
    longitude: "-82.16347000",
  },
  {
    id: 122013,
    name: "Mocksville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.89403000",
    longitude: "-80.56145000",
  },
  {
    id: 122059,
    name: "Monroe",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.98543000",
    longitude: "-80.54951000",
  },
  {
    id: 122148,
    name: "Montgomery County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.33246000",
    longitude: "-79.90547000",
  },
  {
    id: 122201,
    name: "Moore County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.31072000",
    longitude: "-79.48131000",
  },
  {
    id: 122210,
    name: "Mooresville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.58486000",
    longitude: "-80.81007000",
  },
  {
    id: 122224,
    name: "Moravian Falls",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.09680000",
    longitude: "-81.18231000",
  },
  {
    id: 122226,
    name: "Morehead City",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.72294000",
    longitude: "-76.72604000",
  },
  {
    id: 122250,
    name: "Morganton",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.74541000",
    longitude: "-81.68482000",
  },
  {
    id: 122284,
    name: "Morrisville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.82348000",
    longitude: "-78.82556000",
  },
  {
    id: 122331,
    name: "Mount Airy",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.49930000",
    longitude: "-80.60729000",
  },
  {
    id: 122344,
    name: "Mount Gilead",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.21487000",
    longitude: "-80.00228000",
  },
  {
    id: 122349,
    name: "Mount Holly",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.29819000",
    longitude: "-81.01591000",
  },
  {
    id: 122369,
    name: "Mount Olive",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.19655000",
    longitude: "-78.06638000",
  },
  {
    id: 122374,
    name: "Mount Pleasant",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.39931000",
    longitude: "-80.43590000",
  },
  {
    id: 122416,
    name: "Mountain Home",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.36956000",
    longitude: "-82.49290000",
  },
  {
    id: 122430,
    name: "Mountain View",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.68319000",
    longitude: "-81.36898000",
  },
  {
    id: 122446,
    name: "Moyock",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.52460000",
    longitude: "-76.17827000",
  },
  {
    id: 122454,
    name: "Mulberry",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.23958000",
    longitude: "-81.18064000",
  },
  {
    id: 122481,
    name: "Murfreesboro",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.44238000",
    longitude: "-77.09858000",
  },
  {
    id: 122485,
    name: "Murphy",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.08758000",
    longitude: "-84.03463000",
  },
  {
    id: 122496,
    name: "Murraysville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.29572000",
    longitude: "-77.84748000",
  },
  {
    id: 122523,
    name: "Myrtle Grove",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.13462000",
    longitude: "-77.88165000",
  },
  {
    id: 122534,
    name: "Nags Head",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.95739000",
    longitude: "-75.62406000",
  },
  {
    id: 122568,
    name: "Nash County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.96722000",
    longitude: "-77.98648000",
  },
  {
    id: 122576,
    name: "Nashville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.97460000",
    longitude: "-77.96554000",
  },
  {
    id: 122603,
    name: "Navassa",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.25545000",
    longitude: "-78.00749000",
  },
  {
    id: 122647,
    name: "Neuse Forest",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.96377000",
    longitude: "-76.94467000",
  },
  {
    id: 122666,
    name: "New Bern",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.10849000",
    longitude: "-77.04411000",
  },
  {
    id: 122711,
    name: "New Hanover County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.18141000",
    longitude: "-77.86561000",
  },
  {
    id: 122833,
    name: "Newland",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.08735000",
    longitude: "-81.92734000",
  },
  {
    id: 122842,
    name: "Newport",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.78655000",
    longitude: "-76.85911000",
  },
  {
    id: 122861,
    name: "Newton",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.66986000",
    longitude: "-81.22147000",
  },
  {
    id: 122947,
    name: "Norlina",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.44570000",
    longitude: "-78.19833000",
  },
  {
    id: 123130,
    name: "North Wilkesboro",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.15847000",
    longitude: "-81.14758000",
  },
  {
    id: 123136,
    name: "Northampton County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.41766000",
    longitude: "-77.39674000",
  },
  {
    id: 123142,
    name: "Northchase",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.30782000",
    longitude: "-77.87749000",
  },
  {
    id: 123159,
    name: "Northlakes",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.78180000",
    longitude: "-81.37509000",
  },
  {
    id: 123197,
    name: "Norwood",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.21959000",
    longitude: "-80.11895000",
  },
  {
    id: 123251,
    name: "Oak Island",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "33.91656000",
    longitude: "-78.16111000",
  },
  {
    id: 123261,
    name: "Oak Ridge",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.17347000",
    longitude: "-79.98893000",
  },
  {
    id: 123267,
    name: "Oakboro",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.22570000",
    longitude: "-80.32895000",
  },
  {
    id: 123367,
    name: "Ogden",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.27239000",
    longitude: "-77.81859000",
  },
  {
    id: 123484,
    name: "Onslow County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.76305000",
    longitude: "-77.39319000",
  },
  {
    id: 123516,
    name: "Orange County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.06130000",
    longitude: "-79.12060000",
  },
  {
    id: 123679,
    name: "Oxford",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.31070000",
    longitude: "-78.59083000",
  },
  {
    id: 123793,
    name: "Pamlico County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.15152000",
    longitude: "-76.66716000",
  },
  {
    id: 123905,
    name: "Pasquotank County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.26490000",
    longitude: "-76.24913000",
  },
  {
    id: 124005,
    name: "Pembroke",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.68016000",
    longitude: "-79.19504000",
  },
  {
    id: 124015,
    name: "Pender County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.51494000",
    longitude: "-77.88887000",
  },
  {
    id: 124065,
    name: "Perquimans County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.17720000",
    longitude: "-76.40767000",
  },
  {
    id: 124092,
    name: "Person County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.39011000",
    longitude: "-78.97171000",
  },
  {
    id: 124200,
    name: "Pilot Mountain",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.38653000",
    longitude: "-80.46950000",
  },
  {
    id: 124231,
    name: "Pine Knoll Shores",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.69738000",
    longitude: "-76.81327000",
  },
  {
    id: 124236,
    name: "Pine Level",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.51322000",
    longitude: "-78.24444000",
  },
  {
    id: 124247,
    name: "Pinebluff",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.10988000",
    longitude: "-79.47226000",
  },
  {
    id: 124250,
    name: "Pinehurst",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.19543000",
    longitude: "-79.46948000",
  },
  {
    id: 124258,
    name: "Pinetops",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.78849000",
    longitude: "-77.63775000",
  },
  {
    id: 124262,
    name: "Pineville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.08320000",
    longitude: "-80.89230000",
  },
  {
    id: 124267,
    name: "Piney Green",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.71600000",
    longitude: "-77.32024000",
  },
  {
    id: 124288,
    name: "Pitt County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.59352000",
    longitude: "-77.37465000",
  },
  {
    id: 124291,
    name: "Pittsboro",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.72015000",
    longitude: "-79.17724000",
  },
  {
    id: 124316,
    name: "Plain View",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.24850000",
    longitude: "-78.55529000",
  },
  {
    id: 124365,
    name: "Pleasant Garden",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.96208000",
    longitude: "-79.76225000",
  },
  {
    id: 124369,
    name: "Pleasant Hill",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.87347000",
    longitude: "-79.48169000",
  },
  {
    id: 124399,
    name: "Plymouth",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.86683000",
    longitude: "-76.74856000",
  },
  {
    id: 124444,
    name: "Polk County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.27929000",
    longitude: "-82.16967000",
  },
  {
    id: 124452,
    name: "Polkton",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.00765000",
    longitude: "-80.20089000",
  },
  {
    id: 124683,
    name: "Princeton",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.46599000",
    longitude: "-78.16055000",
  },
  {
    id: 124694,
    name: "Princeville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.88960000",
    longitude: "-77.53219000",
  },
  {
    id: 124746,
    name: "Pumpkin Center",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.79155000",
    longitude: "-77.37246000",
  },
  {
    id: 124819,
    name: "Raeford",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.98100000",
    longitude: "-79.22420000",
  },
  {
    id: 124831,
    name: "Raleigh",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.77210000",
    longitude: "-78.63861000",
  },
  {
    id: 124839,
    name: "Ramseur",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.73347000",
    longitude: "-79.65253000",
  },
  {
    id: 124864,
    name: "Randleman",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.81791000",
    longitude: "-79.80309000",
  },
  {
    id: 124877,
    name: "Randolph County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.71033000",
    longitude: "-79.80616000",
  },
  {
    id: 124886,
    name: "Ranlo",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.28625000",
    longitude: "-81.13035000",
  },
  {
    id: 124955,
    name: "Red Oak",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.03849000",
    longitude: "-77.90637000",
  },
  {
    id: 124961,
    name: "Red Springs",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.81516000",
    longitude: "-79.18309000",
  },
  {
    id: 125008,
    name: "Reidsville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.35486000",
    longitude: "-79.66447000",
  },
  {
    id: 125047,
    name: "Rhodhiss",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.77402000",
    longitude: "-81.43120000",
  },
  {
    id: 125081,
    name: "Richlands",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.89933000",
    longitude: "-77.54663000",
  },
  {
    id: 125099,
    name: "Richmond County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.00594000",
    longitude: "-79.74783000",
  },
  {
    id: 125176,
    name: "River Bend",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.06905000",
    longitude: "-77.14690000",
  },
  {
    id: 125186,
    name: "River Road",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.50683000",
    longitude: "-76.99078000",
  },
  {
    id: 125232,
    name: "Roanoke Rapids",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.46154000",
    longitude: "-77.65415000",
  },
  {
    id: 125234,
    name: "Robbins",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.43403000",
    longitude: "-79.58697000",
  },
  {
    id: 125237,
    name: "Robbinsville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.32287000",
    longitude: "-83.80740000",
  },
  {
    id: 125239,
    name: "Robersonville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.82516000",
    longitude: "-77.24913000",
  },
  {
    id: 125249,
    name: "Robeson County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.64009000",
    longitude: "-79.10353000",
  },
  {
    id: 125298,
    name: "Rockfish",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.99266000",
    longitude: "-79.06614000",
  },
  {
    id: 125303,
    name: "Rockingham",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.93932000",
    longitude: "-79.77395000",
  },
  {
    id: 125305,
    name: "Rockingham County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.39608000",
    longitude: "-79.77515000",
  },
  {
    id: 125329,
    name: "Rockwell",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.55125000",
    longitude: "-80.40645000",
  },
  {
    id: 125335,
    name: "Rocky Mount",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.93821000",
    longitude: "-77.79053000",
  },
  {
    id: 125337,
    name: "Rocky Point",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.43517000",
    longitude: "-77.88776000",
  },
  {
    id: 125361,
    name: "Rolesville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.92321000",
    longitude: "-78.45750000",
  },
  {
    id: 125404,
    name: "Rose Hill",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.82822000",
    longitude: "-78.02304000",
  },
  {
    id: 125410,
    name: "Roseboro",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.95295000",
    longitude: "-78.50862000",
  },
  {
    id: 125478,
    name: "Rowan County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.63954000",
    longitude: "-80.52464000",
  },
  {
    id: 125479,
    name: "Rowland",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.53655000",
    longitude: "-79.29143000",
  },
  {
    id: 125484,
    name: "Roxboro",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.39375000",
    longitude: "-78.98279000",
  },
  {
    id: 125493,
    name: "Royal Pines",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.47511000",
    longitude: "-82.51595000",
  },
  {
    id: 125516,
    name: "Rural Hall",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.24042000",
    longitude: "-80.29338000",
  },
  {
    id: 125551,
    name: "Rutherford College",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.74846000",
    longitude: "-81.52259000",
  },
  {
    id: 125552,
    name: "Rutherford County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.40225000",
    longitude: "-81.92009000",
  },
  {
    id: 125554,
    name: "Rutherfordton",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.36929000",
    longitude: "-81.95677000",
  },
  {
    id: 125654,
    name: "Saint James",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "33.92934000",
    longitude: "-78.11638000",
  },
  {
    id: 125706,
    name: "Saint Pauls",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.80655000",
    longitude: "-78.97114000",
  },
  {
    id: 125717,
    name: "Saint Stephens",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.76458000",
    longitude: "-81.27314000",
  },
  {
    id: 125731,
    name: "Salem",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.69874000",
    longitude: "-81.69704000",
  },
  {
    id: 125756,
    name: "Salisbury",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.67097000",
    longitude: "-80.47423000",
  },
  {
    id: 125780,
    name: "Sampson County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.99163000",
    longitude: "-78.37152000",
  },
  {
    id: 125887,
    name: "Sanford",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.47988000",
    longitude: "-79.18030000",
  },
  {
    id: 125981,
    name: "Sawmills",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.82485000",
    longitude: "-81.47454000",
  },
  {
    id: 125983,
    name: "Saxapahaw",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.94736000",
    longitude: "-79.32196000",
  },
  {
    id: 126036,
    name: "Scotland County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.84090000",
    longitude: "-79.48043000",
  },
  {
    id: 126038,
    name: "Scotland Neck",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.12960000",
    longitude: "-77.42025000",
  },
  {
    id: 126058,
    name: "Scotts Mill",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.72666000",
    longitude: "-78.88390000",
  },
  {
    id: 126070,
    name: "Sea Breeze",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.06323000",
    longitude: "-77.89137000",
  },
  {
    id: 126087,
    name: "Seagate",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.20934000",
    longitude: "-77.84359000",
  },
  {
    id: 126139,
    name: "Selma",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.53655000",
    longitude: "-78.28444000",
  },
  {
    id: 126173,
    name: "Seven Lakes",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.27849000",
    longitude: "-79.56448000",
  },
  {
    id: 126212,
    name: "Shallotte",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "33.97323000",
    longitude: "-78.38584000",
  },
  {
    id: 126235,
    name: "Sharpsburg",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.86710000",
    longitude: "-77.82915000",
  },
  {
    id: 126268,
    name: "Shelby",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.29235000",
    longitude: "-81.53565000",
  },
  {
    id: 126339,
    name: "Sherrills Ford",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.62041000",
    longitude: "-80.98647000",
  },
  {
    id: 126415,
    name: "Siler City",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.72347000",
    longitude: "-79.46224000",
  },
  {
    id: 126429,
    name: "Silver Lake",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.14878000",
    longitude: "-77.91360000",
  },
  {
    id: 126482,
    name: "Skippers Corner",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.34600000",
    longitude: "-77.90249000",
  },
  {
    id: 126518,
    name: "Smithfield",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.50849000",
    longitude: "-78.33945000",
  },
  {
    id: 126539,
    name: "Sneads Ferry",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.55267000",
    longitude: "-77.39718000",
  },
  {
    id: 126546,
    name: "Snow Hill",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.45155000",
    longitude: "-77.68109000",
  },
  {
    id: 126657,
    name: "South Gastonia",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.21930000",
    longitude: "-81.20563000",
  },
  {
    id: 126669,
    name: "South Henderson",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.30820000",
    longitude: "-78.40666000",
  },
  {
    id: 126719,
    name: "South Rosemary",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.45154000",
    longitude: "-77.69720000",
  },
  {
    id: 126768,
    name: "Southern Pines",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.17405000",
    longitude: "-79.39225000",
  },
  {
    id: 126770,
    name: "Southern Shores",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.13905000",
    longitude: "-75.73157000",
  },
  {
    id: 126779,
    name: "Southmont",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.66792000",
    longitude: "-80.26700000",
  },
  {
    id: 126783,
    name: "Southport",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "33.92156000",
    longitude: "-78.02027000",
  },
  {
    id: 126808,
    name: "Sparta",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.50541000",
    longitude: "-81.12092000",
  },
  {
    id: 126821,
    name: "Spencer",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.69236000",
    longitude: "-80.43478000",
  },
  {
    id: 126835,
    name: "Spindale",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.36012000",
    longitude: "-81.92927000",
  },
  {
    id: 126863,
    name: "Spring Hope",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.94515000",
    longitude: "-78.11193000",
  },
  {
    id: 126865,
    name: "Spring Lake",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.16794000",
    longitude: "-78.97281000",
  },
  {
    id: 126919,
    name: "Spruce Pine",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.91540000",
    longitude: "-82.06456000",
  },
  {
    id: 126940,
    name: "Stallings",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.09070000",
    longitude: "-80.68618000",
  },
  {
    id: 126951,
    name: "Stanfield",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.23348000",
    longitude: "-80.42701000",
  },
  {
    id: 126958,
    name: "Stanley",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.35903000",
    longitude: "-81.09702000",
  },
  {
    id: 126964,
    name: "Stanly County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.31199000",
    longitude: "-80.25092000",
  },
  {
    id: 126998,
    name: "Statesville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.78264000",
    longitude: "-80.88730000",
  },
  {
    id: 127006,
    name: "Stedman",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.01350000",
    longitude: "-78.69391000",
  },
  {
    id: 127078,
    name: "Stokes County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.40190000",
    longitude: "-80.23961000",
  },
  {
    id: 127079,
    name: "Stokesdale",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.23708000",
    longitude: "-79.97948000",
  },
  {
    id: 127090,
    name: "Stoneville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.46653000",
    longitude: "-79.90698000",
  },
  {
    id: 127098,
    name: "Stony Point",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.86347000",
    longitude: "-81.04730000",
  },
  {
    id: 127202,
    name: "Summerfield",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.20875000",
    longitude: "-79.90476000",
  },
  {
    id: 127274,
    name: "Sunset Beach",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "33.88073000",
    longitude: "-78.51223000",
  },
  {
    id: 127285,
    name: "Surf City",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.42711000",
    longitude: "-77.54608000",
  },
  {
    id: 127294,
    name: "Surry County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.41468000",
    longitude: "-80.68749000",
  },
  {
    id: 127319,
    name: "Swain County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.48673000",
    longitude: "-83.49274000",
  },
  {
    id: 127322,
    name: "Swannanoa",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.59789000",
    longitude: "-82.39984000",
  },
  {
    id: 127323,
    name: "Swanquarter",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.40628000",
    longitude: "-76.32909000",
  },
  {
    id: 127324,
    name: "Swansboro",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.68766000",
    longitude: "-77.11912000",
  },
  {
    id: 127344,
    name: "Swepsonville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.02125000",
    longitude: "-79.36141000",
  },
  {
    id: 127355,
    name: "Sylva",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.37371000",
    longitude: "-83.22598000",
  },
  {
    id: 127368,
    name: "Tabor City",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.14878000",
    longitude: "-78.87669000",
  },
  {
    id: 127428,
    name: "Tarboro",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.89682000",
    longitude: "-77.53580000",
  },
  {
    id: 127465,
    name: "Taylorsville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.92180000",
    longitude: "-81.17647000",
  },
  {
    id: 127589,
    name: "Thomasville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.88264000",
    longitude: "-80.08199000",
  },
  {
    id: 127628,
    name: "Thurmond",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.36652000",
    longitude: "-80.92841000",
  },
  {
    id: 127689,
    name: "Toast",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.50041000",
    longitude: "-80.62645000",
  },
  {
    id: 127690,
    name: "Tobaccoville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.23819000",
    longitude: "-80.37144000",
  },
  {
    id: 127780,
    name: "Transylvania County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.20215000",
    longitude: "-82.79830000",
  },
  {
    id: 127800,
    name: "Trent Woods",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.08210000",
    longitude: "-77.08634000",
  },
  {
    id: 127804,
    name: "Trenton",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.06710000",
    longitude: "-77.35274000",
  },
  {
    id: 127826,
    name: "Trinity",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.89458000",
    longitude: "-79.99087000",
  },
  {
    id: 127839,
    name: "Troutman",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.70069000",
    longitude: "-80.88813000",
  },
  {
    id: 127845,
    name: "Troy",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.35847000",
    longitude: "-79.89449000",
  },
  {
    id: 127862,
    name: "Tryon",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.33986000",
    longitude: "-81.32203000",
  },
  {
    id: 127948,
    name: "Tyro",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.80903000",
    longitude: "-80.37283000",
  },
  {
    id: 127951,
    name: "Tyrrell County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.87018000",
    longitude: "-76.17005000",
  },
  {
    id: 127995,
    name: "Union County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.98837000",
    longitude: "-80.53073000",
  },
  {
    id: 128018,
    name: "Unionville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.08737000",
    longitude: "-80.50896000",
  },
  {
    id: 128082,
    name: "Valdese",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.74069000",
    longitude: "-81.56315000",
  },
  {
    id: 128112,
    name: "Valley Hill",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.29845000",
    longitude: "-82.48318000",
  },
  {
    id: 128141,
    name: "Vance County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.36476000",
    longitude: "-78.40833000",
  },
  {
    id: 128149,
    name: "Vander",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.03211000",
    longitude: "-78.79474000",
  },
  {
    id: 128323,
    name: "Wadesboro",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.96821000",
    longitude: "-80.07673000",
  },
  {
    id: 128356,
    name: "Wake County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.79012000",
    longitude: "-78.65022000",
  },
  {
    id: 128357,
    name: "Wake Forest",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.97987000",
    longitude: "-78.50972000",
  },
  {
    id: 128392,
    name: "Walkertown",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.17541000",
    longitude: "-80.15310000",
  },
  {
    id: 128397,
    name: "Wallace",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.73572000",
    longitude: "-77.99526000",
  },
  {
    id: 128400,
    name: "Wallburg",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.01014000",
    longitude: "-80.13921000",
  },
  {
    id: 128415,
    name: "Walnut Cove",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.29541000",
    longitude: "-80.14171000",
  },
  {
    id: 128451,
    name: "Wanchese",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.84267000",
    longitude: "-75.63851000",
  },
  {
    id: 128490,
    name: "Warren County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.39659000",
    longitude: "-78.10690000",
  },
  {
    id: 128506,
    name: "Warrenton",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.39848000",
    longitude: "-78.15527000",
  },
  {
    id: 128516,
    name: "Warsaw",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.99933000",
    longitude: "-78.09110000",
  },
  {
    id: 128541,
    name: "Washington",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.54655000",
    longitude: "-77.05217000",
  },
  {
    id: 128563,
    name: "Washington County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.83887000",
    longitude: "-76.56868000",
  },
  {
    id: 128596,
    name: "Watauga County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.23110000",
    longitude: "-81.69637000",
  },
  {
    id: 128675,
    name: "Waxhaw",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.92459000",
    longitude: "-80.74340000",
  },
  {
    id: 128695,
    name: "Wayne County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.36378000",
    longitude: "-78.00415000",
  },
  {
    id: 128711,
    name: "Waynesville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.48871000",
    longitude: "-82.98875000",
  },
  {
    id: 128722,
    name: "Weaverville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.69705000",
    longitude: "-82.56069000",
  },
  {
    id: 128746,
    name: "Weddington",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.02237000",
    longitude: "-80.76090000",
  },
  {
    id: 128766,
    name: "Welcome",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.90292000",
    longitude: "-80.25699000",
  },
  {
    id: 128769,
    name: "Weldon",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.42710000",
    longitude: "-77.59553000",
  },
  {
    id: 128798,
    name: "Wendell",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.78099000",
    longitude: "-78.36972000",
  },
  {
    id: 128804,
    name: "Wentworth",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.40014000",
    longitude: "-79.77448000",
  },
  {
    id: 128810,
    name: "Wesley Chapel",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.00709000",
    longitude: "-80.67451000",
  },
  {
    id: 128839,
    name: "West Canton",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.53788000",
    longitude: "-82.85819000",
  },
  {
    id: 128904,
    name: "West Jefferson",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.40374000",
    longitude: "-81.49288000",
  },
  {
    id: 128925,
    name: "West Marion",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.65790000",
    longitude: "-82.02539000",
  },
  {
    id: 128965,
    name: "West Raleigh",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.78682000",
    longitude: "-78.66389000",
  },
  {
    id: 129071,
    name: "Westport",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.50125000",
    longitude: "-80.97869000",
  },
  {
    id: 129124,
    name: "Whispering Pines",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.25571000",
    longitude: "-79.37225000",
  },
  {
    id: 129154,
    name: "White Plains",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.44569000",
    longitude: "-80.63340000",
  },
  {
    id: 129189,
    name: "Whiteville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.33878000",
    longitude: "-78.70307000",
  },
  {
    id: 129245,
    name: "Wilkes County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.20621000",
    longitude: "-81.16292000",
  },
  {
    id: 129247,
    name: "Wilkesboro",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.14596000",
    longitude: "-81.16064000",
  },
  {
    id: 129282,
    name: "Williamston",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.85460000",
    longitude: "-77.05551000",
  },
  {
    id: 129322,
    name: "Wilmington",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.22573000",
    longitude: "-77.94471000",
  },
  {
    id: 129329,
    name: "Wilson",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.72127000",
    longitude: "-77.91554000",
  },
  {
    id: 129335,
    name: "Wilson County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.70503000",
    longitude: "-77.91862000",
  },
  {
    id: 129339,
    name: "Wilsons Mills",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.58405000",
    longitude: "-78.35583000",
  },
  {
    id: 129378,
    name: "Windsor",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.99849000",
    longitude: "-76.94606000",
  },
  {
    id: 129400,
    name: "Wingate",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.98432000",
    longitude: "-80.44923000",
  },
  {
    id: 129436,
    name: "Winston-Salem",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.09986000",
    longitude: "-80.24422000",
  },
  {
    id: 129448,
    name: "Winterville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.52905000",
    longitude: "-77.40108000",
  },
  {
    id: 129453,
    name: "Winton",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.39571000",
    longitude: "-76.93190000",
  },
  {
    id: 129518,
    name: "Woodfin",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.63344000",
    longitude: "-82.58207000",
  },
  {
    id: 129611,
    name: "Wrightsboro",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.28850000",
    longitude: "-77.92110000",
  },
  {
    id: 129616,
    name: "Wrightsville Beach",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "34.20850000",
    longitude: "-77.79637000",
  },
  {
    id: 129648,
    name: "Yadkin County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.16054000",
    longitude: "-80.66534000",
  },
  {
    id: 129649,
    name: "Yadkinville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.13458000",
    longitude: "-80.65951000",
  },
  {
    id: 129659,
    name: "Yancey County",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.89888000",
    longitude: "-82.30755000",
  },
  {
    id: 129660,
    name: "Yanceyville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.40403000",
    longitude: "-79.33613000",
  },
  {
    id: 129719,
    name: "Youngsville",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "36.02487000",
    longitude: "-78.47444000",
  },
  {
    id: 129743,
    name: "Zebulon",
    state_id: 1447,
    state_code: "NC",
    country_id: 233,
    country_code: "US",
    latitude: "35.82432000",
    longitude: "-78.31472000",
  },
  {
    id: 111026,
    name: "Adams County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.09684000",
    longitude: "-102.52853000",
  },
  {
    id: 111287,
    name: "Amidon",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.48223000",
    longitude: "-103.32185000",
  },
  {
    id: 111543,
    name: "Ashley",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.03414000",
    longitude: "-99.37150000",
  },
  {
    id: 111826,
    name: "Barnes County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.93611000",
    longitude: "-98.07158000",
  },
  {
    id: 111962,
    name: "Beach",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.91807000",
    longitude: "-104.00437000",
  },
  {
    id: 112052,
    name: "Belcourt",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.83917000",
    longitude: "-99.74487000",
  },
  {
    id: 112058,
    name: "Belfield",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.88529000",
    longitude: "-103.19962000",
  },
  {
    id: 112186,
    name: "Benson County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.06939000",
    longitude: "-99.36603000",
  },
  {
    id: 112296,
    name: "Beulah",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.26334000",
    longitude: "-101.77795000",
  },
  {
    id: 112345,
    name: "Billings County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.02345000",
    longitude: "-103.37643000",
  },
  {
    id: 112367,
    name: "Bismarck",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.80833000",
    longitude: "-100.78374000",
  },
  {
    id: 112598,
    name: "Bottineau",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.82723000",
    longitude: "-100.44570000",
  },
  {
    id: 112599,
    name: "Bottineau County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.79216000",
    longitude: "-100.83333000",
  },
  {
    id: 112621,
    name: "Bowbells",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.80308000",
    longitude: "-102.24600000",
  },
  {
    id: 112632,
    name: "Bowman",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.18306000",
    longitude: "-103.39491000",
  },
  {
    id: 112633,
    name: "Bowman County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.11261000",
    longitude: "-103.52067000",
  },
  {
    id: 113058,
    name: "Burke County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.79100000",
    longitude: "-102.51826000",
  },
  {
    id: 113060,
    name: "Burleigh County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.97739000",
    longitude: "-100.46873000",
  },
  {
    id: 113075,
    name: "Burlington",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.27529000",
    longitude: "-101.42878000",
  },
  {
    id: 113326,
    name: "Cando",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.48667000",
    longitude: "-99.20986000",
  },
  {
    id: 113446,
    name: "Carrington",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.44972000",
    longitude: "-99.12622000",
  },
  {
    id: 113475,
    name: "Carson",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.41778000",
    longitude: "-101.56487000",
  },
  {
    id: 113533,
    name: "Cass County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.93297000",
    longitude: "-97.24804000",
  },
  {
    id: 113536,
    name: "Casselton",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.90053000",
    longitude: "-97.21120000",
  },
  {
    id: 113581,
    name: "Cavalier",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.79388000",
    longitude: "-97.62231000",
  },
  {
    id: 113582,
    name: "Cavalier County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.77230000",
    longitude: "-98.46486000",
  },
  {
    id: 113639,
    name: "Center",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.11638000",
    longitude: "-101.29959000",
  },
  {
    id: 114606,
    name: "Cooperstown",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.44444000",
    longitude: "-98.12398000",
  },
  {
    id: 114844,
    name: "Crosby",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.91420000",
    longitude: "-103.29491000",
  },
  {
    id: 115298,
    name: "Devils Lake",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.11278000",
    longitude: "-98.86512000",
  },
  {
    id: 115323,
    name: "Dickey County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.11012000",
    longitude: "-98.50467000",
  },
  {
    id: 115326,
    name: "Dickinson",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.87918000",
    longitude: "-102.78962000",
  },
  {
    id: 115357,
    name: "Divide County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.81489000",
    longitude: "-103.48735000",
  },
  {
    id: 115548,
    name: "Dunn County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.35675000",
    longitude: "-102.61824000",
  },
  {
    id: 115823,
    name: "Eddy County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.71759000",
    longitude: "-98.90158000",
  },
  {
    id: 116017,
    name: "Ellendale",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.00275000",
    longitude: "-98.52705000",
  },
  {
    id: 116107,
    name: "Emmons County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.28502000",
    longitude: "-100.23878000",
  },
  {
    id: 116396,
    name: "Fargo",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.87719000",
    longitude: "-96.78980000",
  },
  {
    id: 116501,
    name: "Fessenden",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.64917000",
    longitude: "-99.62929000",
  },
  {
    id: 116516,
    name: "Finley",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.51416000",
    longitude: "-97.83593000",
  },
  {
    id: 116676,
    name: "Forman",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.10774000",
    longitude: "-97.63649000",
  },
  {
    id: 116756,
    name: "Fort Totten",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.98000000",
    longitude: "-98.99290000",
  },
  {
    id: 116766,
    name: "Fort Yates",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.08694000",
    longitude: "-100.63013000",
  },
  {
    id: 116777,
    name: "Foster County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.45708000",
    longitude: "-98.88302000",
  },
  {
    id: 117103,
    name: "Garrison",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.65222000",
    longitude: "-101.41572000",
  },
  {
    id: 117359,
    name: "Golden Valley County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.94022000",
    longitude: "-103.84670000",
  },
  {
    id: 117431,
    name: "Grafton",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.41221000",
    longitude: "-97.41063000",
  },
  {
    id: 117457,
    name: "Grand Forks",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.92526000",
    longitude: "-97.03285000",
  },
  {
    id: 117458,
    name: "Grand Forks Air Force Base",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.95493000",
    longitude: "-97.38664000",
  },
  {
    id: 117459,
    name: "Grand Forks County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.92192000",
    longitude: "-97.45693000",
  },
  {
    id: 117518,
    name: "Grant County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.35828000",
    longitude: "-101.63971000",
  },
  {
    id: 117734,
    name: "Griggs County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.45729000",
    longitude: "-98.23704000",
  },
  {
    id: 118114,
    name: "Harvey",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.76972000",
    longitude: "-99.93540000",
  },
  {
    id: 118210,
    name: "Hazen",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.29445000",
    longitude: "-101.62266000",
  },
  {
    id: 118346,
    name: "Hettinger",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.00139000",
    longitude: "-102.63682000",
  },
  {
    id: 118347,
    name: "Hettinger County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.43253000",
    longitude: "-102.46036000",
  },
  {
    id: 118450,
    name: "Hillsboro",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.40387000",
    longitude: "-97.06203000",
  },
  {
    id: 118661,
    name: "Horace",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.75886000",
    longitude: "-96.90370000",
  },
  {
    id: 119123,
    name: "Jamestown",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.91054000",
    longitude: "-98.70844000",
  },
  {
    id: 119461,
    name: "Kenmare",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.67475000",
    longitude: "-102.08266000",
  },
  {
    id: 119552,
    name: "Kidder County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.98014000",
    longitude: "-99.78009000",
  },
  {
    id: 119558,
    name: "Killdeer",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.37196000",
    longitude: "-102.75408000",
  },
  {
    id: 120057,
    name: "Lakota",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.04278000",
    longitude: "-98.33621000",
  },
  {
    id: 119803,
    name: "LaMoure County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.45691000",
    longitude: "-98.53546000",
  },
  {
    id: 120109,
    name: "Langdon",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.76000000",
    longitude: "-98.36817000",
  },
  {
    id: 120146,
    name: "Larimore",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.90666000",
    longitude: "-97.62675000",
  },
  {
    id: 120510,
    name: "Lincoln",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.76249000",
    longitude: "-100.70040000",
  },
  {
    id: 120590,
    name: "Linton",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.26666000",
    longitude: "-100.23289000",
  },
  {
    id: 120600,
    name: "Lisbon",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.44163000",
    longitude: "-97.68121000",
  },
  {
    id: 120701,
    name: "Logan County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.45736000",
    longitude: "-99.47747000",
  },
  {
    id: 121106,
    name: "Mandan",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.82666000",
    longitude: "-100.88958000",
  },
  {
    id: 121136,
    name: "Manning",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.23001000",
    longitude: "-102.77019000",
  },
  {
    id: 121454,
    name: "Mayville",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.49804000",
    longitude: "-97.32454000",
  },
  {
    id: 121472,
    name: "McClusky",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.48583000",
    longitude: "-100.44318000",
  },
  {
    id: 121510,
    name: "McHenry County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.23458000",
    longitude: "-100.63628000",
  },
  {
    id: 121515,
    name: "McIntosh County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.11179000",
    longitude: "-99.44120000",
  },
  {
    id: 121521,
    name: "McKenzie County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.74015000",
    longitude: "-103.39527000",
  },
  {
    id: 121530,
    name: "McLean County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.60696000",
    longitude: "-101.32183000",
  },
  {
    id: 121608,
    name: "Medora",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.91390000",
    longitude: "-103.52435000",
  },
  {
    id: 121681,
    name: "Mercer County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.30922000",
    longitude: "-101.83153000",
  },
  {
    id: 121959,
    name: "Minnewaukan",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.07139000",
    longitude: "-99.25236000",
  },
  {
    id: 121966,
    name: "Minot",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.23251000",
    longitude: "-101.29627000",
  },
  {
    id: 121967,
    name: "Minot Air Force Base",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.42087000",
    longitude: "-101.33914000",
  },
  {
    id: 122018,
    name: "Mohall",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.76336000",
    longitude: "-101.51322000",
  },
  {
    id: 122297,
    name: "Morton County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.71606000",
    longitude: "-101.28117000",
  },
  {
    id: 122312,
    name: "Mott",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.37250000",
    longitude: "-102.32711000",
  },
  {
    id: 122440,
    name: "Mountrail County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.20133000",
    longitude: "-102.35565000",
  },
  {
    id: 122559,
    name: "Napoleon",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.50831000",
    longitude: "-99.77122000",
  },
  {
    id: 122628,
    name: "Nelson County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.92169000",
    longitude: "-98.19204000",
  },
  {
    id: 122774,
    name: "New Rockford",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.68000000",
    longitude: "-99.13790000",
  },
  {
    id: 122787,
    name: "New Town",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.98085000",
    longitude: "-102.49018000",
  },
  {
    id: 123275,
    name: "Oakes",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.13858000",
    longitude: "-98.09038000",
  },
  {
    id: 123443,
    name: "Oliver County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.11528000",
    longitude: "-101.34036000",
  },
  {
    id: 123853,
    name: "Park River",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.39860000",
    longitude: "-97.74120000",
  },
  {
    id: 123889,
    name: "Parshall",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.95335000",
    longitude: "-102.13489000",
  },
  {
    id: 124003,
    name: "Pembina County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.76752000",
    longitude: "-97.55183000",
  },
  {
    id: 124174,
    name: "Pierce County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.24960000",
    longitude: "-99.97182000",
  },
  {
    id: 124844,
    name: "Ramsey County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.26893000",
    longitude: "-98.72014000",
  },
  {
    id: 124888,
    name: "Ransom County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.45616000",
    longitude: "-97.65745000",
  },
  {
    id: 125029,
    name: "Renville County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.71907000",
    longitude: "-101.65778000",
  },
  {
    id: 125076,
    name: "Richland County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.26466000",
    longitude: "-96.94828000",
  },
  {
    id: 125362,
    name: "Rolette County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.77245000",
    longitude: "-99.84099000",
  },
  {
    id: 125364,
    name: "Rolla",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.85778000",
    longitude: "-99.61792000",
  },
  {
    id: 125503,
    name: "Rugby",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.36889000",
    longitude: "-99.99625000",
  },
  {
    id: 125950,
    name: "Sargent County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.10794000",
    longitude: "-97.63072000",
  },
  {
    id: 126288,
    name: "Sheldon",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.58580000",
    longitude: "-97.49120000",
  },
  {
    id: 126293,
    name: "Shell Valley",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.79806000",
    longitude: "-99.86486000",
  },
  {
    id: 126326,
    name: "Sheridan County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.57541000",
    longitude: "-100.34566000",
  },
  {
    id: 126460,
    name: "Sioux County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.11265000",
    longitude: "-101.04034000",
  },
  {
    id: 126507,
    name: "Slope County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.44722000",
    longitude: "-103.45990000",
  },
  {
    id: 126961,
    name: "Stanley",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.31724000",
    longitude: "-102.39045000",
  },
  {
    id: 126970,
    name: "Stanton",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.32111000",
    longitude: "-101.38155000",
  },
  {
    id: 126985,
    name: "Stark County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.81068000",
    longitude: "-102.65513000",
  },
  {
    id: 127009,
    name: "Steele",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.85471000",
    longitude: "-99.91594000",
  },
  {
    id: 127011,
    name: "Steele County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.45615000",
    longitude: "-97.72467000",
  },
  {
    id: 127154,
    name: "Stutsman County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.97925000",
    longitude: "-98.95883000",
  },
  {
    id: 127291,
    name: "Surrey",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.23640000",
    longitude: "-101.13349000",
  },
  {
    id: 127591,
    name: "Thompson",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.77359000",
    longitude: "-97.10980000",
  },
  {
    id: 127668,
    name: "Tioga",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.39724000",
    longitude: "-102.93824000",
  },
  {
    id: 127761,
    name: "Towner",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.34583000",
    longitude: "-100.40541000",
  },
  {
    id: 127762,
    name: "Towner County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.68554000",
    longitude: "-99.24580000",
  },
  {
    id: 127778,
    name: "Traill County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.45418000",
    longitude: "-97.16158000",
  },
  {
    id: 128101,
    name: "Valley City",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.92331000",
    longitude: "-98.00315000",
  },
  {
    id: 128165,
    name: "Velva",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.05612000",
    longitude: "-100.92932000",
  },
  {
    id: 128335,
    name: "Wahpeton",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.26524000",
    longitude: "-96.60591000",
  },
  {
    id: 128427,
    name: "Walsh County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.36946000",
    longitude: "-97.72137000",
  },
  {
    id: 128459,
    name: "Ward County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.22175000",
    longitude: "-101.54183000",
  },
  {
    id: 128535,
    name: "Washburn",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.28916000",
    longitude: "-101.02903000",
  },
  {
    id: 128632,
    name: "Watford City",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.80224000",
    longitude: "-103.28325000",
  },
  {
    id: 128787,
    name: "Wells County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "47.58753000",
    longitude: "-99.66095000",
  },
  {
    id: 128869,
    name: "West Fargo",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "46.87497000",
    longitude: "-96.90036000",
  },
  {
    id: 129264,
    name: "Williams County",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.34368000",
    longitude: "-103.48021000",
  },
  {
    id: 129299,
    name: "Williston",
    state_id: 1418,
    state_code: "ND",
    country_id: 233,
    country_code: "US",
    latitude: "48.14697000",
    longitude: "-103.61797000",
  },
  {
    id: 141096,
    name: "Ada",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.76950000",
    longitude: "-83.82271000",
  },
  {
    id: 141097,
    name: "Adams County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "38.84551000",
    longitude: "-83.47215000",
  },
  {
    id: 141098,
    name: "Akron",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.08144000",
    longitude: "-81.51901000",
  },
  {
    id: 141099,
    name: "Allen County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.77152000",
    longitude: "-84.10578000",
  },
  {
    id: 141100,
    name: "Alliance",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.91534000",
    longitude: "-81.10593000",
  },
  {
    id: 141101,
    name: "Amberley",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.20478000",
    longitude: "-84.42800000",
  },
  {
    id: 141102,
    name: "Amelia",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.02840000",
    longitude: "-84.21771000",
  },
  {
    id: 141103,
    name: "Amherst",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.39782000",
    longitude: "-82.22238000",
  },
  {
    id: 141104,
    name: "Andover",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.60672000",
    longitude: "-80.57230000",
  },
  {
    id: 141105,
    name: "Anna",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.39449000",
    longitude: "-84.17272000",
  },
  {
    id: 141106,
    name: "Ansonia",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.21449000",
    longitude: "-84.63690000",
  },
  {
    id: 141107,
    name: "Antwerp",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.18144000",
    longitude: "-84.74051000",
  },
  {
    id: 141108,
    name: "Apple Creek",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.75172000",
    longitude: "-81.83930000",
  },
  {
    id: 141109,
    name: "Apple Valley",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.43890000",
    longitude: "-82.35391000",
  },
  {
    id: 141110,
    name: "Arcanum",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.99005000",
    longitude: "-84.55329000",
  },
  {
    id: 141111,
    name: "Archbold",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.52144000",
    longitude: "-84.30717000",
  },
  {
    id: 141112,
    name: "Arlington",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.89366000",
    longitude: "-83.65021000",
  },
  {
    id: 141113,
    name: "Ashland",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.86867000",
    longitude: "-82.31822000",
  },
  {
    id: 141114,
    name: "Ashland County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.84602000",
    longitude: "-82.27069000",
  },
  {
    id: 141115,
    name: "Ashley",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.40895000",
    longitude: "-82.95546000",
  },
  {
    id: 141116,
    name: "Ashtabula",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.86505000",
    longitude: "-80.78981000",
  },
  {
    id: 141117,
    name: "Ashtabula County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.89638000",
    longitude: "-80.75901000",
  },
  {
    id: 141118,
    name: "Ashville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.71562000",
    longitude: "-82.95296000",
  },
  {
    id: 141119,
    name: "Athens",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.32924000",
    longitude: "-82.10126000",
  },
  {
    id: 141120,
    name: "Athens County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.33386000",
    longitude: "-82.04513000",
  },
  {
    id: 141121,
    name: "Auglaize County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.56091000",
    longitude: "-84.22174000",
  },
  {
    id: 141122,
    name: "Aurora",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.31755000",
    longitude: "-81.34539000",
  },
  {
    id: 141123,
    name: "Austintown",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.10172000",
    longitude: "-80.76452000",
  },
  {
    id: 141124,
    name: "Avon",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.45171000",
    longitude: "-82.03542000",
  },
  {
    id: 141125,
    name: "Avon Center",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.45976000",
    longitude: "-82.01959000",
  },
  {
    id: 141126,
    name: "Avon Lake",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.50532000",
    longitude: "-82.02820000",
  },
  {
    id: 141127,
    name: "Bainbridge",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.38644000",
    longitude: "-81.33955000",
  },
  {
    id: 141128,
    name: "Ballville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.32783000",
    longitude: "-83.13214000",
  },
  {
    id: 141129,
    name: "Baltimore",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.84534000",
    longitude: "-82.60072000",
  },
  {
    id: 141130,
    name: "Barberton",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.01283000",
    longitude: "-81.60512000",
  },
  {
    id: 141131,
    name: "Barnesville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.98813000",
    longitude: "-81.17650000",
  },
  {
    id: 141132,
    name: "Batavia",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.07701000",
    longitude: "-84.17688000",
  },
  {
    id: 141133,
    name: "Bay Village",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.48477000",
    longitude: "-81.92208000",
  },
  {
    id: 141134,
    name: "Beach City",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.65312000",
    longitude: "-81.58096000",
  },
  {
    id: 141135,
    name: "Beachwood",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.46450000",
    longitude: "-81.50873000",
  },
  {
    id: 141136,
    name: "Beavercreek",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.70923000",
    longitude: "-84.06327000",
  },
  {
    id: 141137,
    name: "Beckett Ridge",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.34700000",
    longitude: "-84.43522000",
  },
  {
    id: 141138,
    name: "Bedford",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.39311000",
    longitude: "-81.53651000",
  },
  {
    id: 141139,
    name: "Bedford Heights",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.41700000",
    longitude: "-81.52734000",
  },
  {
    id: 141140,
    name: "Beechwood Trails",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.02367000",
    longitude: "-82.65072000",
  },
  {
    id: 141141,
    name: "Bellaire",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.01618000",
    longitude: "-80.74231000",
  },
  {
    id: 141142,
    name: "Bellbrook",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.63562000",
    longitude: "-84.07077000",
  },
  {
    id: 141143,
    name: "Bellefontaine",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.36116000",
    longitude: "-83.75966000",
  },
  {
    id: 141144,
    name: "Bellevue",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.27366000",
    longitude: "-82.84158000",
  },
  {
    id: 141145,
    name: "Bellville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.62006000",
    longitude: "-82.51072000",
  },
  {
    id: 141146,
    name: "Belmont County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.01580000",
    longitude: "-80.98854000",
  },
  {
    id: 141147,
    name: "Belpre",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.27396000",
    longitude: "-81.57290000",
  },
  {
    id: 141148,
    name: "Berea",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.36616000",
    longitude: "-81.85430000",
  },
  {
    id: 141149,
    name: "Bethel",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "38.96368000",
    longitude: "-84.08077000",
  },
  {
    id: 141150,
    name: "Bethesda",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.01618000",
    longitude: "-81.07260000",
  },
  {
    id: 141151,
    name: "Beverly",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.54785000",
    longitude: "-81.63957000",
  },
  {
    id: 141152,
    name: "Bexley",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.96895000",
    longitude: "-82.93768000",
  },
  {
    id: 141153,
    name: "Blacklick Estates",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.90506000",
    longitude: "-82.86434000",
  },
  {
    id: 141154,
    name: "Blanchester",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.29312000",
    longitude: "-83.98882000",
  },
  {
    id: 141155,
    name: "Blue Ash",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.23200000",
    longitude: "-84.37827000",
  },
  {
    id: 141156,
    name: "Bluffton",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.89533000",
    longitude: "-83.88883000",
  },
  {
    id: 141157,
    name: "Boardman",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.02423000",
    longitude: "-80.66285000",
  },
  {
    id: 141158,
    name: "Bolindale",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.20728000",
    longitude: "-80.77758000",
  },
  {
    id: 141159,
    name: "Boston Heights",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.26478000",
    longitude: "-81.51317000",
  },
  {
    id: 141160,
    name: "Botkins",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.46783000",
    longitude: "-84.18050000",
  },
  {
    id: 141161,
    name: "Bowling Green",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.37477000",
    longitude: "-83.65132000",
  },
  {
    id: 141162,
    name: "Bradford",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.13227000",
    longitude: "-84.43078000",
  },
  {
    id: 141163,
    name: "Bradner",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.32422000",
    longitude: "-83.43854000",
  },
  {
    id: 141164,
    name: "Bratenahl",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.54255000",
    longitude: "-81.62624000",
  },
  {
    id: 141165,
    name: "Brecksville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.31978000",
    longitude: "-81.62679000",
  },
  {
    id: 141166,
    name: "Bremen",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.70173000",
    longitude: "-82.42682000",
  },
  {
    id: 141167,
    name: "Brewster",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.70700000",
    longitude: "-81.59818000",
  },
  {
    id: 141168,
    name: "Bridgeport",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.06979000",
    longitude: "-80.74008000",
  },
  {
    id: 141169,
    name: "Bridgetown",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.15311000",
    longitude: "-84.63717000",
  },
  {
    id: 141170,
    name: "Brilliant",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.26479000",
    longitude: "-80.62619000",
  },
  {
    id: 141171,
    name: "Brimfield",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.10006000",
    longitude: "-81.34650000",
  },
  {
    id: 141172,
    name: "Broadview Heights",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.31394000",
    longitude: "-81.68513000",
  },
  {
    id: 141173,
    name: "Brook Park",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.39838000",
    longitude: "-81.80458000",
  },
  {
    id: 141174,
    name: "Brookfield Center",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.24061000",
    longitude: "-80.55785000",
  },
  {
    id: 141175,
    name: "Brooklyn",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.43977000",
    longitude: "-81.73541000",
  },
  {
    id: 141176,
    name: "Brooklyn Heights",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.42533000",
    longitude: "-81.68818000",
  },
  {
    id: 141177,
    name: "Brookville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.83672000",
    longitude: "-84.41134000",
  },
  {
    id: 141178,
    name: "Brown County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "38.93405000",
    longitude: "-83.86743000",
  },
  {
    id: 141179,
    name: "Brunswick",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.23811000",
    longitude: "-81.84180000",
  },
  {
    id: 141180,
    name: "Bryan",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.47477000",
    longitude: "-84.55245000",
  },
  {
    id: 141181,
    name: "Buckeye Lake",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.93368000",
    longitude: "-82.47238000",
  },
  {
    id: 141182,
    name: "Bucyrus",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.80839000",
    longitude: "-82.97546000",
  },
  {
    id: 141183,
    name: "Burlington",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "38.40730000",
    longitude: "-82.53571000",
  },
  {
    id: 141184,
    name: "Burton",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.47061000",
    longitude: "-81.14510000",
  },
  {
    id: 141185,
    name: "Butler County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.43865000",
    longitude: "-84.57566000",
  },
  {
    id: 141186,
    name: "Byesville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.96979000",
    longitude: "-81.53651000",
  },
  {
    id: 141187,
    name: "Cadiz",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.27285000",
    longitude: "-80.99676000",
  },
  {
    id: 141188,
    name: "Calcutta",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.67340000",
    longitude: "-80.57646000",
  },
  {
    id: 141189,
    name: "Caldwell",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.74785000",
    longitude: "-81.51651000",
  },
  {
    id: 141190,
    name: "Cambridge",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.03118000",
    longitude: "-81.58846000",
  },
  {
    id: 141191,
    name: "Camden",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.62894000",
    longitude: "-84.64856000",
  },
  {
    id: 141192,
    name: "Campbell",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.07839000",
    longitude: "-80.59924000",
  },
  {
    id: 141193,
    name: "Canal Fulton",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.88978000",
    longitude: "-81.59762000",
  },
  {
    id: 141194,
    name: "Canal Winchester",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.84284000",
    longitude: "-82.80462000",
  },
  {
    id: 141195,
    name: "Canfield",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.02506000",
    longitude: "-80.76091000",
  },
  {
    id: 141196,
    name: "Canton",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.79895000",
    longitude: "-81.37845000",
  },
  {
    id: 141197,
    name: "Cardington",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.50062000",
    longitude: "-82.89351000",
  },
  {
    id: 141198,
    name: "Carey",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.95256000",
    longitude: "-83.38242000",
  },
  {
    id: 141199,
    name: "Carlisle",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.58200000",
    longitude: "-84.32022000",
  },
  {
    id: 141200,
    name: "Carroll County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.57959000",
    longitude: "-81.08972000",
  },
  {
    id: 141201,
    name: "Carrollton",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.57284000",
    longitude: "-81.08565000",
  },
  {
    id: 141202,
    name: "Cedarville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.74423000",
    longitude: "-83.80854000",
  },
  {
    id: 141203,
    name: "Celina",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.54894000",
    longitude: "-84.57023000",
  },
  {
    id: 141204,
    name: "Centerburg",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.30451000",
    longitude: "-82.69628000",
  },
  {
    id: 141205,
    name: "Centerville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.62839000",
    longitude: "-84.15938000",
  },
  {
    id: 141206,
    name: "Chagrin Falls",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.43616000",
    longitude: "-81.38650000",
  },
  {
    id: 141207,
    name: "Champaign County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.13767000",
    longitude: "-83.76950000",
  },
  {
    id: 141208,
    name: "Champion Heights",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.28999000",
    longitude: "-80.84595000",
  },
  {
    id: 141209,
    name: "Chardon",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.61422000",
    longitude: "-81.14899000",
  },
  {
    id: 141210,
    name: "Chauncey",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.39785000",
    longitude: "-82.12931000",
  },
  {
    id: 141211,
    name: "Cherry Grove",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.07256000",
    longitude: "-84.32188000",
  },
  {
    id: 141212,
    name: "Chesterland",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.52227000",
    longitude: "-81.33789000",
  },
  {
    id: 141213,
    name: "Cheviot",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.15700000",
    longitude: "-84.61328000",
  },
  {
    id: 141214,
    name: "Chillicothe",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.33312000",
    longitude: "-82.98240000",
  },
  {
    id: 141215,
    name: "Choctaw Lake",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.96006000",
    longitude: "-83.48492000",
  },
  {
    id: 141216,
    name: "Churchill",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.16200000",
    longitude: "-80.66480000",
  },
  {
    id: 141217,
    name: "Cincinnati",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.12711000",
    longitude: "-84.51439000",
  },
  {
    id: 141218,
    name: "Circleville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.60062000",
    longitude: "-82.94601000",
  },
  {
    id: 141219,
    name: "Clark County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.91678000",
    longitude: "-83.78390000",
  },
  {
    id: 141220,
    name: "Clark-Fulton",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.46402000",
    longitude: "-81.70979000",
  },
  {
    id: 141221,
    name: "Clayton",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.86311000",
    longitude: "-84.36050000",
  },
  {
    id: 141222,
    name: "Clermont County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.04743000",
    longitude: "-84.15192000",
  },
  {
    id: 141223,
    name: "Cleveland",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.49950000",
    longitude: "-81.69541000",
  },
  {
    id: 141224,
    name: "Cleveland Heights",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.52005000",
    longitude: "-81.55624000",
  },
  {
    id: 141225,
    name: "Cleves",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.16172000",
    longitude: "-84.74912000",
  },
  {
    id: 141226,
    name: "Clinton",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.92672000",
    longitude: "-81.63040000",
  },
  {
    id: 141227,
    name: "Clinton County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.41498000",
    longitude: "-83.80838000",
  },
  {
    id: 141228,
    name: "Clyde",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.30422000",
    longitude: "-82.97519000",
  },
  {
    id: 141229,
    name: "Coal Grove",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "38.50341000",
    longitude: "-82.64711000",
  },
  {
    id: 141230,
    name: "Coldwater",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.47977000",
    longitude: "-84.62829000",
  },
  {
    id: 141231,
    name: "Collinwood",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.55838000",
    longitude: "-81.56929000",
  },
  {
    id: 141232,
    name: "Columbiana",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.88839000",
    longitude: "-80.69396000",
  },
  {
    id: 141233,
    name: "Columbiana County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.76843000",
    longitude: "-80.77719000",
  },
  {
    id: 141234,
    name: "Columbus",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.96118000",
    longitude: "-82.99879000",
  },
  {
    id: 141235,
    name: "Columbus Grove",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.91950000",
    longitude: "-84.05689000",
  },
  {
    id: 141236,
    name: "Commercial Point",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.76840000",
    longitude: "-83.05713000",
  },
  {
    id: 141237,
    name: "Conneaut",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.94756000",
    longitude: "-80.55424000",
  },
  {
    id: 141238,
    name: "Continental",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.10033000",
    longitude: "-84.26634000",
  },
  {
    id: 141239,
    name: "Convoy",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.91672000",
    longitude: "-84.70274000",
  },
  {
    id: 141240,
    name: "Copley",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.09894000",
    longitude: "-81.64457000",
  },
  {
    id: 141241,
    name: "Cortland",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.33033000",
    longitude: "-80.72536000",
  },
  {
    id: 141242,
    name: "Coshocton",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.27202000",
    longitude: "-81.85958000",
  },
  {
    id: 141243,
    name: "Coshocton County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.30164000",
    longitude: "-81.92001000",
  },
  {
    id: 141244,
    name: "Covedale",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.12117000",
    longitude: "-84.60633000",
  },
  {
    id: 141245,
    name: "Covington",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.11727000",
    longitude: "-84.35384000",
  },
  {
    id: 141246,
    name: "Craig Beach",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.11700000",
    longitude: "-80.98342000",
  },
  {
    id: 141247,
    name: "Crawford County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.85077000",
    longitude: "-82.91978000",
  },
  {
    id: 141248,
    name: "Crestline",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.78756000",
    longitude: "-82.73657000",
  },
  {
    id: 141249,
    name: "Creston",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.98700000",
    longitude: "-81.89375000",
  },
  {
    id: 141250,
    name: "Cridersville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.65422000",
    longitude: "-84.15078000",
  },
  {
    id: 141251,
    name: "Crooksville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.76896000",
    longitude: "-82.09209000",
  },
  {
    id: 141252,
    name: "Crystal Lakes",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.88923000",
    longitude: "-84.02660000",
  },
  {
    id: 141253,
    name: "Curtice",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.61838000",
    longitude: "-83.36771000",
  },
  {
    id: 141254,
    name: "Cuyahoga County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.47875000",
    longitude: "-81.67786000",
  },
  {
    id: 141255,
    name: "Cuyahoga Falls",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.13394000",
    longitude: "-81.48456000",
  },
  {
    id: 141256,
    name: "Dalton",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.79894000",
    longitude: "-81.69541000",
  },
  {
    id: 141257,
    name: "Danville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.44756000",
    longitude: "-82.26016000",
  },
  {
    id: 141258,
    name: "Darke County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.13323000",
    longitude: "-84.61931000",
  },
  {
    id: 141259,
    name: "Day Heights",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.17395000",
    longitude: "-84.22633000",
  },
  {
    id: 141260,
    name: "Dayton",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.75895000",
    longitude: "-84.19161000",
  },
  {
    id: 141261,
    name: "De Graff",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.31200000",
    longitude: "-83.91577000",
  },
  {
    id: 141262,
    name: "Deer Park",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.20534000",
    longitude: "-84.39466000",
  },
  {
    id: 141263,
    name: "Defiance",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.28449000",
    longitude: "-84.35578000",
  },
  {
    id: 141264,
    name: "Defiance County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.32392000",
    longitude: "-84.49050000",
  },
  {
    id: 141265,
    name: "Delaware",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.29867000",
    longitude: "-83.06797000",
  },
  {
    id: 141266,
    name: "Delaware County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.27839000",
    longitude: "-83.00489000",
  },
  {
    id: 141267,
    name: "Delhi Hills",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.09284000",
    longitude: "-84.61272000",
  },
  {
    id: 141268,
    name: "Delphos",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.84338000",
    longitude: "-84.34162000",
  },
  {
    id: 141269,
    name: "Delta",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.57366000",
    longitude: "-84.00522000",
  },
  {
    id: 141270,
    name: "Dennison",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.39340000",
    longitude: "-81.33372000",
  },
  {
    id: 141271,
    name: "Dent",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.18589000",
    longitude: "-84.65134000",
  },
  {
    id: 141272,
    name: "Deshler",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.20755000",
    longitude: "-83.89911000",
  },
  {
    id: 141273,
    name: "Detroit-Shoreway",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.47772000",
    longitude: "-81.72991000",
  },
  {
    id: 141274,
    name: "Devola",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.47369000",
    longitude: "-81.47901000",
  },
  {
    id: 141275,
    name: "Dillonvale",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.21811000",
    longitude: "-84.40216000",
  },
  {
    id: 141276,
    name: "Dover",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.52062000",
    longitude: "-81.47401000",
  },
  {
    id: 141277,
    name: "Doylestown",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.97005000",
    longitude: "-81.69652000",
  },
  {
    id: 141278,
    name: "Dresden",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.12146000",
    longitude: "-82.01069000",
  },
  {
    id: 141279,
    name: "Drexel",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.74645000",
    longitude: "-84.28661000",
  },
  {
    id: 141280,
    name: "Dry Ridge",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.25922000",
    longitude: "-84.61911000",
  },
  {
    id: 141281,
    name: "Dry Run",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.10423000",
    longitude: "-84.33049000",
  },
  {
    id: 141282,
    name: "Dublin",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.09923000",
    longitude: "-83.11408000",
  },
  {
    id: 141283,
    name: "Dunlap",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.29228000",
    longitude: "-84.61800000",
  },
  {
    id: 141284,
    name: "East Canton",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.78728000",
    longitude: "-81.28261000",
  },
  {
    id: 141285,
    name: "East Cleveland",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.53311000",
    longitude: "-81.57901000",
  },
  {
    id: 141286,
    name: "East Liverpool",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.61868000",
    longitude: "-80.57729000",
  },
  {
    id: 141287,
    name: "East Palestine",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.83395000",
    longitude: "-80.54035000",
  },
  {
    id: 141288,
    name: "Eastlake",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.65394000",
    longitude: "-81.45039000",
  },
  {
    id: 141289,
    name: "Eaton",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.74394000",
    longitude: "-84.63662000",
  },
  {
    id: 141290,
    name: "Eaton Estates",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.30894000",
    longitude: "-82.00570000",
  },
  {
    id: 141291,
    name: "Edgerton",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.44866000",
    longitude: "-84.74801000",
  },
  {
    id: 141292,
    name: "Edgewood",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.87283000",
    longitude: "-80.77286000",
  },
  {
    id: 141293,
    name: "Elida",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.78866000",
    longitude: "-84.20384000",
  },
  {
    id: 141294,
    name: "Elmore",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.47616000",
    longitude: "-83.29576000",
  },
  {
    id: 141295,
    name: "Elmwood Place",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.18728000",
    longitude: "-84.48800000",
  },
  {
    id: 141296,
    name: "Elyria",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.36838000",
    longitude: "-82.10765000",
  },
  {
    id: 141297,
    name: "Englewood",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.87756000",
    longitude: "-84.30217000",
  },
  {
    id: 141298,
    name: "Enon",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.87812000",
    longitude: "-83.93688000",
  },
  {
    id: 141299,
    name: "Erie County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.43209000",
    longitude: "-82.69958000",
  },
  {
    id: 141300,
    name: "Etna",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.95729000",
    longitude: "-82.68183000",
  },
  {
    id: 141301,
    name: "Euclid",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.59310000",
    longitude: "-81.52679000",
  },
  {
    id: 141302,
    name: "Evendale",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.25617000",
    longitude: "-84.41800000",
  },
  {
    id: 141303,
    name: "Fairborn",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.82089000",
    longitude: "-84.01938000",
  },
  {
    id: 141304,
    name: "Fairfax",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.14534000",
    longitude: "-84.39327000",
  },
  {
    id: 141305,
    name: "Fairfield",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.34589000",
    longitude: "-84.56050000",
  },
  {
    id: 141306,
    name: "Fairfield Beach",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.91590000",
    longitude: "-82.47516000",
  },
  {
    id: 141307,
    name: "Fairfield County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.75160000",
    longitude: "-82.63059000",
  },
  {
    id: 141308,
    name: "Fairlawn",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.12783000",
    longitude: "-81.60984000",
  },
  {
    id: 141309,
    name: "Fairport Harbor",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.75004000",
    longitude: "-81.27399000",
  },
  {
    id: 141310,
    name: "Fairview Park",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.44144000",
    longitude: "-81.86430000",
  },
  {
    id: 141311,
    name: "Farmersville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.67950000",
    longitude: "-84.42911000",
  },
  {
    id: 141312,
    name: "Fayette",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.67338000",
    longitude: "-84.32689000",
  },
  {
    id: 141313,
    name: "Fayette County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.55988000",
    longitude: "-83.45610000",
  },
  {
    id: 141314,
    name: "Findlay",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.04422000",
    longitude: "-83.64993000",
  },
  {
    id: 141315,
    name: "Finneytown",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.20034000",
    longitude: "-84.52050000",
  },
  {
    id: 141316,
    name: "Five Points",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.56867000",
    longitude: "-84.19299000",
  },
  {
    id: 141317,
    name: "Forest",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.80172000",
    longitude: "-83.51048000",
  },
  {
    id: 141318,
    name: "Forest Park",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.29034000",
    longitude: "-84.50411000",
  },
  {
    id: 141319,
    name: "Forestville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.07506000",
    longitude: "-84.34494000",
  },
  {
    id: 141320,
    name: "Fort Loramie",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.35144000",
    longitude: "-84.37384000",
  },
  {
    id: 141321,
    name: "Fort McKinley",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.79756000",
    longitude: "-84.25355000",
  },
  {
    id: 141322,
    name: "Fort Recovery",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.41282000",
    longitude: "-84.77635000",
  },
  {
    id: 141323,
    name: "Fort Shawnee",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.68672000",
    longitude: "-84.13773000",
  },
  {
    id: 141324,
    name: "Fostoria",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.15700000",
    longitude: "-83.41687000",
  },
  {
    id: 141325,
    name: "Frankfort",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.40145000",
    longitude: "-83.18074000",
  },
  {
    id: 141326,
    name: "Franklin",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.55895000",
    longitude: "-84.30411000",
  },
  {
    id: 141327,
    name: "Franklin County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.96952000",
    longitude: "-83.00935000",
  },
  {
    id: 141328,
    name: "Franklin Furnace",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "38.64508000",
    longitude: "-82.84878000",
  },
  {
    id: 141329,
    name: "Frazeysburg",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.11729000",
    longitude: "-82.11931000",
  },
  {
    id: 141330,
    name: "Fredericktown",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.48117000",
    longitude: "-82.54072000",
  },
  {
    id: 141331,
    name: "Fremont",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.35033000",
    longitude: "-83.12186000",
  },
  {
    id: 141332,
    name: "Fruit Hill",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.07562000",
    longitude: "-84.36438000",
  },
  {
    id: 141333,
    name: "Fulton County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.60180000",
    longitude: "-84.13007000",
  },
  {
    id: 141334,
    name: "Gahanna",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.01923000",
    longitude: "-82.87934000",
  },
  {
    id: 141335,
    name: "Galion",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.73367000",
    longitude: "-82.78990000",
  },
  {
    id: 141336,
    name: "Gallia County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "38.82467000",
    longitude: "-82.31691000",
  },
  {
    id: 141337,
    name: "Gallipolis",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "38.80980000",
    longitude: "-82.20237000",
  },
  {
    id: 141338,
    name: "Gambier",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.37562000",
    longitude: "-82.39710000",
  },
  {
    id: 141339,
    name: "Garfield Heights",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.41700000",
    longitude: "-81.60596000",
  },
  {
    id: 141340,
    name: "Garrettsville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.28422000",
    longitude: "-81.09649000",
  },
  {
    id: 141341,
    name: "Gates Mills",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.51755000",
    longitude: "-81.40345000",
  },
  {
    id: 141342,
    name: "Geauga County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.49954000",
    longitude: "-81.17865000",
  },
  {
    id: 141343,
    name: "Geneva",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.80505000",
    longitude: "-80.94815000",
  },
  {
    id: 141344,
    name: "Geneva-on-the-Lake",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.85950000",
    longitude: "-80.95398000",
  },
  {
    id: 141345,
    name: "Genoa",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.51811000",
    longitude: "-83.35909000",
  },
  {
    id: 141346,
    name: "Georgetown",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "38.86451000",
    longitude: "-83.90409000",
  },
  {
    id: 141347,
    name: "Germantown",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.62617000",
    longitude: "-84.36939000",
  },
  {
    id: 141348,
    name: "Gibsonburg",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.38450000",
    longitude: "-83.32048000",
  },
  {
    id: 141349,
    name: "Girard",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.15395000",
    longitude: "-80.70147000",
  },
  {
    id: 141350,
    name: "Glandorf",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.02894000",
    longitude: "-84.07911000",
  },
  {
    id: 141351,
    name: "Glendale",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.27061000",
    longitude: "-84.45939000",
  },
  {
    id: 141352,
    name: "Glenmoor",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.66617000",
    longitude: "-80.62313000",
  },
  {
    id: 141353,
    name: "Glenville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.53338000",
    longitude: "-81.61735000",
  },
  {
    id: 141354,
    name: "Glouster",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.50313000",
    longitude: "-82.08459000",
  },
  {
    id: 141355,
    name: "Gnadenhutten",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.35840000",
    longitude: "-81.43428000",
  },
  {
    id: 141356,
    name: "Golf Manor",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.18728000",
    longitude: "-84.44633000",
  },
  {
    id: 141357,
    name: "Goshen",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.23339000",
    longitude: "-84.16132000",
  },
  {
    id: 141358,
    name: "Grafton",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.27255000",
    longitude: "-82.05459000",
  },
  {
    id: 141359,
    name: "Grandview",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.19422000",
    longitude: "-84.72439000",
  },
  {
    id: 141360,
    name: "Grandview Heights",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.97979000",
    longitude: "-83.04074000",
  },
  {
    id: 141361,
    name: "Granville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.06812000",
    longitude: "-82.51960000",
  },
  {
    id: 141362,
    name: "Granville South",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.05207000",
    longitude: "-82.54166000",
  },
  {
    id: 141363,
    name: "Green",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.94589000",
    longitude: "-81.48317000",
  },
  {
    id: 141364,
    name: "Green Meadows",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.86895000",
    longitude: "-83.94438000",
  },
  {
    id: 141365,
    name: "Green Springs",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.25616000",
    longitude: "-83.05158000",
  },
  {
    id: 141366,
    name: "Greene County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.69148000",
    longitude: "-83.88989000",
  },
  {
    id: 141367,
    name: "Greenfield",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.35201000",
    longitude: "-83.38269000",
  },
  {
    id: 141368,
    name: "Greenhills",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.26811000",
    longitude: "-84.52300000",
  },
  {
    id: 141369,
    name: "Greensburg",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.93172000",
    longitude: "-81.46484000",
  },
  {
    id: 141370,
    name: "Greentown",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.92756000",
    longitude: "-81.40261000",
  },
  {
    id: 141371,
    name: "Greenville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.10283000",
    longitude: "-84.63301000",
  },
  {
    id: 141372,
    name: "Greenwich",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.03005000",
    longitude: "-82.51573000",
  },
  {
    id: 141373,
    name: "Groesbeck",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.22311000",
    longitude: "-84.58689000",
  },
  {
    id: 141374,
    name: "Grove City",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.88145000",
    longitude: "-83.09296000",
  },
  {
    id: 141375,
    name: "Groveport",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.87840000",
    longitude: "-82.88379000",
  },
  {
    id: 141376,
    name: "Guernsey County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.05205000",
    longitude: "-81.49426000",
  },
  {
    id: 141377,
    name: "Hamilton",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.39950000",
    longitude: "-84.56134000",
  },
  {
    id: 141378,
    name: "Hamilton County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.19553000",
    longitude: "-84.54277000",
  },
  {
    id: 141379,
    name: "Hancock County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.00194000",
    longitude: "-83.66654000",
  },
  {
    id: 141380,
    name: "Hanover",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.07979000",
    longitude: "-82.26098000",
  },
  {
    id: 141381,
    name: "Harbor Hills",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.93673000",
    longitude: "-82.43515000",
  },
  {
    id: 141382,
    name: "Hardin County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.66151000",
    longitude: "-83.65944000",
  },
  {
    id: 141383,
    name: "Harrison",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.26200000",
    longitude: "-84.81995000",
  },
  {
    id: 141384,
    name: "Harrison County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.29384000",
    longitude: "-81.09114000",
  },
  {
    id: 141385,
    name: "Hartville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.96367000",
    longitude: "-81.33122000",
  },
  {
    id: 141386,
    name: "Haskins",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.46477000",
    longitude: "-83.70605000",
  },
  {
    id: 141387,
    name: "Heath",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.02284000",
    longitude: "-82.44460000",
  },
  {
    id: 141388,
    name: "Hebron",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.96173000",
    longitude: "-82.49127000",
  },
  {
    id: 141389,
    name: "Henry County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.33389000",
    longitude: "-84.06823000",
  },
  {
    id: 141390,
    name: "Hicksville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.29311000",
    longitude: "-84.76190000",
  },
  {
    id: 141391,
    name: "Highland County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.18474000",
    longitude: "-83.60097000",
  },
  {
    id: 141392,
    name: "Highland Heights",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.55200000",
    longitude: "-81.47845000",
  },
  {
    id: 141393,
    name: "Highpoint",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.28839000",
    longitude: "-84.35022000",
  },
  {
    id: 141394,
    name: "Hilliard",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.03340000",
    longitude: "-83.15825000",
  },
  {
    id: 141395,
    name: "Hillsboro",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.20229000",
    longitude: "-83.61159000",
  },
  {
    id: 141396,
    name: "Hiram",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.31256000",
    longitude: "-81.14371000",
  },
  {
    id: 141397,
    name: "Hocking County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.49702000",
    longitude: "-82.47925000",
  },
  {
    id: 141398,
    name: "Holgate",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.24894000",
    longitude: "-84.13300000",
  },
  {
    id: 141399,
    name: "Holiday Valley",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.85617000",
    longitude: "-83.96854000",
  },
  {
    id: 141400,
    name: "Holland",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.62172000",
    longitude: "-83.71160000",
  },
  {
    id: 141401,
    name: "Holmes County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.56120000",
    longitude: "-81.92936000",
  },
  {
    id: 141402,
    name: "Hough",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.51200000",
    longitude: "-81.63652000",
  },
  {
    id: 141403,
    name: "Howland Center",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.25117000",
    longitude: "-80.74536000",
  },
  {
    id: 141404,
    name: "Hubbard",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.15645000",
    longitude: "-80.56924000",
  },
  {
    id: 141405,
    name: "Huber Heights",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.84395000",
    longitude: "-84.12466000",
  },
  {
    id: 141406,
    name: "Huber Ridge",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.08867000",
    longitude: "-82.91657000",
  },
  {
    id: 141407,
    name: "Hudson",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.24006000",
    longitude: "-81.44067000",
  },
  {
    id: 141408,
    name: "Hunter",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.49284000",
    longitude: "-84.28966000",
  },
  {
    id: 141409,
    name: "Huron",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.39505000",
    longitude: "-82.55517000",
  },
  {
    id: 141410,
    name: "Huron County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.14615000",
    longitude: "-82.59841000",
  },
  {
    id: 141411,
    name: "Independence",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.36866000",
    longitude: "-81.63790000",
  },
  {
    id: 141412,
    name: "Ironton",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "38.53675000",
    longitude: "-82.68294000",
  },
  {
    id: 141413,
    name: "Jackson",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.05202000",
    longitude: "-82.63655000",
  },
  {
    id: 141414,
    name: "Jackson Center",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.43949000",
    longitude: "-84.04022000",
  },
  {
    id: 141415,
    name: "Jackson County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.01967000",
    longitude: "-82.61838000",
  },
  {
    id: 141416,
    name: "Jamestown",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.65812000",
    longitude: "-83.73492000",
  },
  {
    id: 141417,
    name: "Jefferson",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.73867000",
    longitude: "-80.76981000",
  },
  {
    id: 141418,
    name: "Jefferson County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.38502000",
    longitude: "-80.76097000",
  },
  {
    id: 141419,
    name: "Jeffersonville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.65367000",
    longitude: "-83.56381000",
  },
  {
    id: 141420,
    name: "Johnstown",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.15367000",
    longitude: "-82.68517000",
  },
  {
    id: 141421,
    name: "Kalida",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.98283000",
    longitude: "-84.19939000",
  },
  {
    id: 141422,
    name: "Kent",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.15367000",
    longitude: "-81.35789000",
  },
  {
    id: 141423,
    name: "Kenton",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.64700000",
    longitude: "-83.60965000",
  },
  {
    id: 141424,
    name: "Kenwood",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.21061000",
    longitude: "-84.36716000",
  },
  {
    id: 141425,
    name: "Kettering",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.68950000",
    longitude: "-84.16883000",
  },
  {
    id: 141426,
    name: "Kings Mills",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.35561000",
    longitude: "-84.24855000",
  },
  {
    id: 141427,
    name: "Kingston",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.47395000",
    longitude: "-82.91073000",
  },
  {
    id: 141428,
    name: "Kirtland",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.62894000",
    longitude: "-81.36150000",
  },
  {
    id: 141429,
    name: "Knox County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.39877000",
    longitude: "-82.42153000",
  },
  {
    id: 141430,
    name: "La Croft",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.64590000",
    longitude: "-80.59785000",
  },
  {
    id: 141431,
    name: "Lagrange",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.23728000",
    longitude: "-82.11987000",
  },
  {
    id: 141432,
    name: "Lake County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.71393000",
    longitude: "-81.24527000",
  },
  {
    id: 141433,
    name: "Lake Darby",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.95728000",
    longitude: "-83.22880000",
  },
  {
    id: 141434,
    name: "Lake Lakengren",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.68843000",
    longitude: "-84.69347000",
  },
  {
    id: 141435,
    name: "Lake Mohawk",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.66673000",
    longitude: "-81.19927000",
  },
  {
    id: 141436,
    name: "Lakemore",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.02089000",
    longitude: "-81.43595000",
  },
  {
    id: 141437,
    name: "Lakeview",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.48477000",
    longitude: "-83.92300000",
  },
  {
    id: 141438,
    name: "Lakewood",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.48199000",
    longitude: "-81.79819000",
  },
  {
    id: 141439,
    name: "Lancaster",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.71368000",
    longitude: "-82.59933000",
  },
  {
    id: 141440,
    name: "Landen",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.31200000",
    longitude: "-84.28299000",
  },
  {
    id: 141441,
    name: "Lawrence County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "38.59847000",
    longitude: "-82.53675000",
  },
  {
    id: 141442,
    name: "Leavittsburg",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.24783000",
    longitude: "-80.87703000",
  },
  {
    id: 141443,
    name: "Lebanon",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.43534000",
    longitude: "-84.20299000",
  },
  {
    id: 141444,
    name: "Leesburg",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.34506000",
    longitude: "-83.55297000",
  },
  {
    id: 141445,
    name: "Leetonia",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.87728000",
    longitude: "-80.75536000",
  },
  {
    id: 141446,
    name: "Leipsic",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.09838000",
    longitude: "-83.98467000",
  },
  {
    id: 141447,
    name: "Lewis Center",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.19840000",
    longitude: "-83.01018000",
  },
  {
    id: 141448,
    name: "Lewisburg",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.84616000",
    longitude: "-84.53967000",
  },
  {
    id: 141449,
    name: "Lexington",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.67867000",
    longitude: "-82.58239000",
  },
  {
    id: 141450,
    name: "Liberty Center",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.44338000",
    longitude: "-84.00883000",
  },
  {
    id: 141451,
    name: "Licking County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.09161000",
    longitude: "-82.48315000",
  },
  {
    id: 141452,
    name: "Lima",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.74255000",
    longitude: "-84.10523000",
  },
  {
    id: 141453,
    name: "Lincoln Heights",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.23895000",
    longitude: "-84.45550000",
  },
  {
    id: 141454,
    name: "Lincoln Village",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.95479000",
    longitude: "-83.13074000",
  },
  {
    id: 141455,
    name: "Lisbon",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.86089000",
    longitude: "-83.63520000",
  },
  {
    id: 141456,
    name: "Lithopolis",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.80284000",
    longitude: "-82.80628000",
  },
  {
    id: 141457,
    name: "Lockland",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.22922000",
    longitude: "-84.45772000",
  },
  {
    id: 141458,
    name: "Lodi",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.03339000",
    longitude: "-82.01209000",
  },
  {
    id: 141459,
    name: "Logan",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.54007000",
    longitude: "-82.40710000",
  },
  {
    id: 141460,
    name: "Logan County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.38845000",
    longitude: "-83.76587000",
  },
  {
    id: 141461,
    name: "Logan Elm Village",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.56978000",
    longitude: "-82.95185000",
  },
  {
    id: 141462,
    name: "London",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.88645000",
    longitude: "-83.44825000",
  },
  {
    id: 141463,
    name: "Lorain",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.45282000",
    longitude: "-82.18237000",
  },
  {
    id: 141464,
    name: "Lorain County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.45252000",
    longitude: "-82.15147000",
  },
  {
    id: 141465,
    name: "Lordstown",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.16561000",
    longitude: "-80.85758000",
  },
  {
    id: 141466,
    name: "Loudonville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.63534000",
    longitude: "-82.23321000",
  },
  {
    id: 141467,
    name: "Louisville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.83728000",
    longitude: "-81.25955000",
  },
  {
    id: 141468,
    name: "Loveland",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.26895000",
    longitude: "-84.26383000",
  },
  {
    id: 141469,
    name: "Loveland Park",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.29978000",
    longitude: "-84.26327000",
  },
  {
    id: 141470,
    name: "Lowellville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.03534000",
    longitude: "-80.53646000",
  },
  {
    id: 141471,
    name: "Lucas County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.68419000",
    longitude: "-83.46826000",
  },
  {
    id: 141472,
    name: "Lucasville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "38.87952000",
    longitude: "-82.99684000",
  },
  {
    id: 141473,
    name: "Luckey",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.45061000",
    longitude: "-83.48743000",
  },
  {
    id: 141474,
    name: "Lynchburg",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.24173000",
    longitude: "-83.79131000",
  },
  {
    id: 141475,
    name: "Lyndhurst",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.52005000",
    longitude: "-81.48873000",
  },
  {
    id: 141476,
    name: "Macedonia",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.31367000",
    longitude: "-81.50845000",
  },
  {
    id: 141477,
    name: "Mack",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.15811000",
    longitude: "-84.64967000",
  },
  {
    id: 141478,
    name: "Madeira",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.19089000",
    longitude: "-84.36355000",
  },
  {
    id: 141479,
    name: "Madison",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.77116000",
    longitude: "-81.04982000",
  },
  {
    id: 141480,
    name: "Madison County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.89403000",
    longitude: "-83.40020000",
  },
  {
    id: 141481,
    name: "Mahoning County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.01464000",
    longitude: "-80.77629000",
  },
  {
    id: 141482,
    name: "Malvern",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.69173000",
    longitude: "-81.18121000",
  },
  {
    id: 141483,
    name: "Manchester",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "38.68813000",
    longitude: "-83.60936000",
  },
  {
    id: 141484,
    name: "Mansfield",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.75839000",
    longitude: "-82.51545000",
  },
  {
    id: 141485,
    name: "Mantua",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.28394000",
    longitude: "-81.22399000",
  },
  {
    id: 141486,
    name: "Maple Heights",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.41533000",
    longitude: "-81.56596000",
  },
  {
    id: 141487,
    name: "Mariemont",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.14506000",
    longitude: "-84.37438000",
  },
  {
    id: 141488,
    name: "Marietta",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.41535000",
    longitude: "-81.45484000",
  },
  {
    id: 141489,
    name: "Marion",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.58867000",
    longitude: "-83.12852000",
  },
  {
    id: 141490,
    name: "Marion County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.58719000",
    longitude: "-83.16087000",
  },
  {
    id: 141491,
    name: "Martins Ferry",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.09591000",
    longitude: "-80.72453000",
  },
  {
    id: 141492,
    name: "Marysville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.23645000",
    longitude: "-83.36714000",
  },
  {
    id: 141493,
    name: "Mason",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.36006000",
    longitude: "-84.30994000",
  },
  {
    id: 141494,
    name: "Massillon",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.79672000",
    longitude: "-81.52151000",
  },
  {
    id: 141495,
    name: "Masury",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.21117000",
    longitude: "-80.53785000",
  },
  {
    id: 141496,
    name: "Maumee",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.56283000",
    longitude: "-83.65382000",
  },
  {
    id: 141497,
    name: "Mayfield",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.55200000",
    longitude: "-81.43928000",
  },
  {
    id: 141498,
    name: "Mayfield Heights",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.51922000",
    longitude: "-81.45790000",
  },
  {
    id: 141499,
    name: "McArthur",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.24646000",
    longitude: "-82.47849000",
  },
  {
    id: 141500,
    name: "McComb",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.10755000",
    longitude: "-83.79271000",
  },
  {
    id: 141501,
    name: "McConnelsville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.64868000",
    longitude: "-81.85319000",
  },
  {
    id: 141502,
    name: "McDonald",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.16367000",
    longitude: "-80.72424000",
  },
  {
    id: 141503,
    name: "McKinley Heights",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.18367000",
    longitude: "-80.71730000",
  },
  {
    id: 141504,
    name: "Mechanicsburg",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.07200000",
    longitude: "-83.55631000",
  },
  {
    id: 141505,
    name: "Medina",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.13839000",
    longitude: "-81.86375000",
  },
  {
    id: 141506,
    name: "Medina County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.11759000",
    longitude: "-81.89971000",
  },
  {
    id: 141507,
    name: "Meigs County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.08224000",
    longitude: "-82.02290000",
  },
  {
    id: 141508,
    name: "Mentor",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.66616000",
    longitude: "-81.33955000",
  },
  {
    id: 141509,
    name: "Mentor-on-the-Lake",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.70504000",
    longitude: "-81.36039000",
  },
  {
    id: 141510,
    name: "Mercer County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.54001000",
    longitude: "-84.62936000",
  },
  {
    id: 141511,
    name: "Miami County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.05345000",
    longitude: "-84.22885000",
  },
  {
    id: 141512,
    name: "Miami Heights",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.16506000",
    longitude: "-84.72050000",
  },
  {
    id: 141513,
    name: "Miamisburg",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.64284000",
    longitude: "-84.28661000",
  },
  {
    id: 141514,
    name: "Miamitown",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.21589000",
    longitude: "-84.70411000",
  },
  {
    id: 141515,
    name: "Middleburg Heights",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.36144000",
    longitude: "-81.81291000",
  },
  {
    id: 141516,
    name: "Middlefield",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.46200000",
    longitude: "-81.07371000",
  },
  {
    id: 141517,
    name: "Middleport",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.00175000",
    longitude: "-82.04875000",
  },
  {
    id: 141518,
    name: "Middletown",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.51506000",
    longitude: "-84.39828000",
  },
  {
    id: 141519,
    name: "Milan",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.29755000",
    longitude: "-82.60545000",
  },
  {
    id: 141520,
    name: "Milford",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.17534000",
    longitude: "-84.29438000",
  },
  {
    id: 141521,
    name: "Millbury",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.56616000",
    longitude: "-83.42465000",
  },
  {
    id: 141522,
    name: "Millersburg",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.55451000",
    longitude: "-81.91792000",
  },
  {
    id: 141523,
    name: "Millersport",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.90006000",
    longitude: "-82.53405000",
  },
  {
    id: 141524,
    name: "Mineral Ridge",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.14006000",
    longitude: "-80.76897000",
  },
  {
    id: 141525,
    name: "Minerva",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.72978000",
    longitude: "-81.10538000",
  },
  {
    id: 141526,
    name: "Minerva Park",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.07645000",
    longitude: "-82.94379000",
  },
  {
    id: 141527,
    name: "Mingo Junction",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.32174000",
    longitude: "-80.60980000",
  },
  {
    id: 141528,
    name: "Minster",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.39310000",
    longitude: "-84.37606000",
  },
  {
    id: 141529,
    name: "Mogadore",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.04645000",
    longitude: "-81.39789000",
  },
  {
    id: 141530,
    name: "Monfort Heights",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.18839000",
    longitude: "-84.59522000",
  },
  {
    id: 141531,
    name: "Monroe",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.44034000",
    longitude: "-84.36216000",
  },
  {
    id: 141532,
    name: "Monroe County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.72735000",
    longitude: "-81.08292000",
  },
  {
    id: 141533,
    name: "Monroeville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.24422000",
    longitude: "-82.69629000",
  },
  {
    id: 141534,
    name: "Montgomery",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.22811000",
    longitude: "-84.35411000",
  },
  {
    id: 141535,
    name: "Montgomery County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.75459000",
    longitude: "-84.29068000",
  },
  {
    id: 141536,
    name: "Montpelier",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.58450000",
    longitude: "-84.60551000",
  },
  {
    id: 141537,
    name: "Montrose-Ghent",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.15380000",
    longitude: "-81.64378000",
  },
  {
    id: 141538,
    name: "Moraine",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.70617000",
    longitude: "-84.21938000",
  },
  {
    id: 141539,
    name: "Moreland Hills",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.44783000",
    longitude: "-81.42762000",
  },
  {
    id: 141540,
    name: "Morgan County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.62037000",
    longitude: "-81.85266000",
  },
  {
    id: 141541,
    name: "Morgandale",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.26561000",
    longitude: "-80.78286000",
  },
  {
    id: 141542,
    name: "Morrow",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.35450000",
    longitude: "-84.12716000",
  },
  {
    id: 141543,
    name: "Morrow County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.52409000",
    longitude: "-82.79407000",
  },
  {
    id: 141544,
    name: "Mount Carmel",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.10589000",
    longitude: "-84.30410000",
  },
  {
    id: 141545,
    name: "Mount Gilead",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.54923000",
    longitude: "-82.82740000",
  },
  {
    id: 141546,
    name: "Mount Healthy",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.23367000",
    longitude: "-84.54578000",
  },
  {
    id: 141547,
    name: "Mount Healthy Heights",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.27033000",
    longitude: "-84.56800000",
  },
  {
    id: 141548,
    name: "Mount Orab",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.02757000",
    longitude: "-83.91965000",
  },
  {
    id: 141549,
    name: "Mount Repose",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.20062000",
    longitude: "-84.22438000",
  },
  {
    id: 141550,
    name: "Mount Sterling",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.71951000",
    longitude: "-83.26519000",
  },
  {
    id: 141551,
    name: "Mount Vernon",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.39340000",
    longitude: "-82.48572000",
  },
  {
    id: 141552,
    name: "Mulberry",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.19339000",
    longitude: "-84.24216000",
  },
  {
    id: 141553,
    name: "Munroe Falls",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.14450000",
    longitude: "-81.43983000",
  },
  {
    id: 141554,
    name: "Muskingum County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.96542000",
    longitude: "-81.94438000",
  },
  {
    id: 141555,
    name: "Napoleon",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.39227000",
    longitude: "-84.12522000",
  },
  {
    id: 141556,
    name: "Navarre",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.72450000",
    longitude: "-81.52207000",
  },
  {
    id: 141557,
    name: "Nelsonville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.45868000",
    longitude: "-82.23182000",
  },
  {
    id: 141558,
    name: "New Albany",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.08117000",
    longitude: "-82.80879000",
  },
  {
    id: 141559,
    name: "New Boston",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "38.75230000",
    longitude: "-82.93684000",
  },
  {
    id: 141560,
    name: "New Bremen",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.43699000",
    longitude: "-84.37967000",
  },
  {
    id: 141561,
    name: "New Burlington",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.25950000",
    longitude: "-84.55717000",
  },
  {
    id: 141562,
    name: "New California",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.15617000",
    longitude: "-83.23658000",
  },
  {
    id: 141563,
    name: "New Carlisle",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.93617000",
    longitude: "-84.02549000",
  },
  {
    id: 141564,
    name: "New Concord",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.99368000",
    longitude: "-81.73402000",
  },
  {
    id: 141565,
    name: "New Franklin",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.94172000",
    longitude: "-81.54151000",
  },
  {
    id: 141566,
    name: "New Lebanon",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.74533000",
    longitude: "-84.38495000",
  },
  {
    id: 141567,
    name: "New Lexington",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.71396000",
    longitude: "-82.20848000",
  },
  {
    id: 141568,
    name: "New London",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.08505000",
    longitude: "-82.39989000",
  },
  {
    id: 141569,
    name: "New Matamoras",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.52452000",
    longitude: "-81.06705000",
  },
  {
    id: 141570,
    name: "New Miami",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.43478000",
    longitude: "-84.53689000",
  },
  {
    id: 141571,
    name: "New Middletown",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.96117000",
    longitude: "-80.55757000",
  },
  {
    id: 141572,
    name: "New Paris",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.85699000",
    longitude: "-84.79329000",
  },
  {
    id: 141573,
    name: "New Philadelphia",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.48979000",
    longitude: "-81.44567000",
  },
  {
    id: 141574,
    name: "New Richmond",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "38.94868000",
    longitude: "-84.27994000",
  },
  {
    id: 141575,
    name: "New Vienna",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.32367000",
    longitude: "-83.69103000",
  },
  {
    id: 141576,
    name: "New Waterford",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.84506000",
    longitude: "-80.61452000",
  },
  {
    id: 141577,
    name: "Newark",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.05812000",
    longitude: "-82.40126000",
  },
  {
    id: 141578,
    name: "Newburgh Heights",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.45005000",
    longitude: "-81.66346000",
  },
  {
    id: 141579,
    name: "Newcomerstown",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.27229000",
    longitude: "-81.60595000",
  },
  {
    id: 141580,
    name: "Newport",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.39091000",
    longitude: "-81.22678000",
  },
  {
    id: 141581,
    name: "Newton Falls",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.18839000",
    longitude: "-80.97815000",
  },
  {
    id: 141582,
    name: "Newtown",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.12450000",
    longitude: "-84.36161000",
  },
  {
    id: 141583,
    name: "Niles",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.18284000",
    longitude: "-80.76536000",
  },
  {
    id: 141584,
    name: "Noble County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.76596000",
    longitude: "-81.45556000",
  },
  {
    id: 141585,
    name: "North Baltimore",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.18283000",
    longitude: "-83.67827000",
  },
  {
    id: 141586,
    name: "North Canton",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.87589000",
    longitude: "-81.40234000",
  },
  {
    id: 141587,
    name: "North College Hill",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.21839000",
    longitude: "-84.55078000",
  },
  {
    id: 141588,
    name: "North Fork Village",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.33590000",
    longitude: "-83.02907000",
  },
  {
    id: 141589,
    name: "North Kingsville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.90589000",
    longitude: "-80.69036000",
  },
  {
    id: 141590,
    name: "North Lewisburg",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.22311000",
    longitude: "-83.55743000",
  },
  {
    id: 141591,
    name: "North Madison",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.80200000",
    longitude: "-81.04899000",
  },
  {
    id: 141592,
    name: "North Olmsted",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.41560000",
    longitude: "-81.92347000",
  },
  {
    id: 141593,
    name: "North Randall",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.43478000",
    longitude: "-81.52568000",
  },
  {
    id: 141594,
    name: "North Ridgeville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.38949000",
    longitude: "-82.01903000",
  },
  {
    id: 141595,
    name: "North Royalton",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.31366000",
    longitude: "-81.72457000",
  },
  {
    id: 141596,
    name: "North Zanesville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.97868000",
    longitude: "-82.00347000",
  },
  {
    id: 141597,
    name: "Northbrook",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.24645000",
    longitude: "-84.58356000",
  },
  {
    id: 141598,
    name: "Northfield",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.34505000",
    longitude: "-81.52845000",
  },
  {
    id: 141599,
    name: "Northgate",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.25283000",
    longitude: "-84.59245000",
  },
  {
    id: 141600,
    name: "Northridge",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.80756000",
    longitude: "-84.19689000",
  },
  {
    id: 141601,
    name: "Northwood",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.47283000",
    longitude: "-83.73243000",
  },
  {
    id: 141602,
    name: "Norton",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.02922000",
    longitude: "-81.63818000",
  },
  {
    id: 141603,
    name: "Norwalk",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.24255000",
    longitude: "-82.61573000",
  },
  {
    id: 141604,
    name: "Norwood",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.15561000",
    longitude: "-84.45966000",
  },
  {
    id: 141605,
    name: "Oak Harbor",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.50672000",
    longitude: "-83.14659000",
  },
  {
    id: 141606,
    name: "Oak Hill",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "38.89396000",
    longitude: "-82.57349000",
  },
  {
    id: 141607,
    name: "Oakwood",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.72534000",
    longitude: "-84.17411000",
  },
  {
    id: 141608,
    name: "Oberlin",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.29394000",
    longitude: "-82.21738000",
  },
  {
    id: 141609,
    name: "Obetz",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.87895000",
    longitude: "-82.95074000",
  },
  {
    id: 141610,
    name: "Olmsted Falls",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.37505000",
    longitude: "-81.90819000",
  },
  {
    id: 141611,
    name: "Ontario",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.75950000",
    longitude: "-82.59017000",
  },
  {
    id: 141612,
    name: "Orange",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.44978000",
    longitude: "-81.48067000",
  },
  {
    id: 141613,
    name: "Oregon",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.64366000",
    longitude: "-83.48688000",
  },
  {
    id: 141614,
    name: "Orrville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.84367000",
    longitude: "-81.76402000",
  },
  {
    id: 141615,
    name: "Orwell",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.53506000",
    longitude: "-80.86814000",
  },
  {
    id: 141616,
    name: "Ottawa",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.01922000",
    longitude: "-84.04717000",
  },
  {
    id: 141617,
    name: "Ottawa County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.49675000",
    longitude: "-82.94128000",
  },
  {
    id: 141618,
    name: "Ottawa Hills",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.66422000",
    longitude: "-83.64327000",
  },
  {
    id: 141619,
    name: "Oxford",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.50700000",
    longitude: "-84.74523000",
  },
  {
    id: 141620,
    name: "Painesville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.72449000",
    longitude: "-81.24566000",
  },
  {
    id: 141621,
    name: "Pandora",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.94811000",
    longitude: "-83.96105000",
  },
  {
    id: 141622,
    name: "Park Layne",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.88645000",
    longitude: "-84.03966000",
  },
  {
    id: 141623,
    name: "Parma",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.40477000",
    longitude: "-81.72291000",
  },
  {
    id: 141624,
    name: "Parma Heights",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.39005000",
    longitude: "-81.75958000",
  },
  {
    id: 141625,
    name: "Pataskala",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.99562000",
    longitude: "-82.67433000",
  },
  {
    id: 141626,
    name: "Paulding",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.13811000",
    longitude: "-84.58051000",
  },
  {
    id: 141627,
    name: "Paulding County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.11662000",
    longitude: "-84.58020000",
  },
  {
    id: 141628,
    name: "Payne",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.07755000",
    longitude: "-84.72718000",
  },
  {
    id: 141629,
    name: "Peebles",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "38.94896000",
    longitude: "-83.40575000",
  },
  {
    id: 141630,
    name: "Pemberville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.41089000",
    longitude: "-83.46104000",
  },
  {
    id: 141631,
    name: "Pepper Pike",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.47839000",
    longitude: "-81.46373000",
  },
  {
    id: 141632,
    name: "Perry",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.76033000",
    longitude: "-81.14093000",
  },
  {
    id: 141633,
    name: "Perry County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.73715000",
    longitude: "-82.23614000",
  },
  {
    id: 141634,
    name: "Perry Heights",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.79534000",
    longitude: "-81.47345000",
  },
  {
    id: 141635,
    name: "Perrysburg",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.55700000",
    longitude: "-83.62716000",
  },
  {
    id: 141636,
    name: "Pickaway County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.64194000",
    longitude: "-83.02439000",
  },
  {
    id: 141637,
    name: "Pickerington",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.88423000",
    longitude: "-82.75350000",
  },
  {
    id: 141638,
    name: "Pike County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.07737000",
    longitude: "-83.06685000",
  },
  {
    id: 141639,
    name: "Piketon",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.06813000",
    longitude: "-83.01434000",
  },
  {
    id: 141640,
    name: "Pioneer",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.68005000",
    longitude: "-84.55301000",
  },
  {
    id: 141641,
    name: "Piqua",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.14477000",
    longitude: "-84.24244000",
  },
  {
    id: 141642,
    name: "Plain City",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.10756000",
    longitude: "-83.26742000",
  },
  {
    id: 141643,
    name: "Pleasant Grove",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.95201000",
    longitude: "-81.95902000",
  },
  {
    id: 141644,
    name: "Pleasant Hill",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.05172000",
    longitude: "-84.34439000",
  },
  {
    id: 141645,
    name: "Pleasant Run",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.29978000",
    longitude: "-84.56356000",
  },
  {
    id: 141646,
    name: "Pleasant Run Farm",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.30311000",
    longitude: "-84.54800000",
  },
  {
    id: 141647,
    name: "Plymouth",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.99561000",
    longitude: "-82.66712000",
  },
  {
    id: 141648,
    name: "Poland",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.02423000",
    longitude: "-80.61480000",
  },
  {
    id: 141649,
    name: "Pomeroy",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.02758000",
    longitude: "-82.03375000",
  },
  {
    id: 141650,
    name: "Port Clinton",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.51200000",
    longitude: "-82.93769000",
  },
  {
    id: 141651,
    name: "Portage County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.16768000",
    longitude: "-81.19740000",
  },
  {
    id: 141652,
    name: "Portage Lakes",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.00728000",
    longitude: "-81.52706000",
  },
  {
    id: 141653,
    name: "Portsmouth",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "38.73174000",
    longitude: "-82.99767000",
  },
  {
    id: 141654,
    name: "Powell",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.15784000",
    longitude: "-83.07519000",
  },
  {
    id: 141655,
    name: "Powhatan Point",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.86008000",
    longitude: "-80.81537000",
  },
  {
    id: 141656,
    name: "Preble County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.74157000",
    longitude: "-84.64802000",
  },
  {
    id: 141657,
    name: "Prospect",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.45034000",
    longitude: "-83.18853000",
  },
  {
    id: 141658,
    name: "Putnam County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.02208000",
    longitude: "-84.13173000",
  },
  {
    id: 141659,
    name: "Ravenna",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.15756000",
    longitude: "-81.24205000",
  },
  {
    id: 141660,
    name: "Reading",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.22367000",
    longitude: "-84.44216000",
  },
  {
    id: 141661,
    name: "Reminderville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.34589000",
    longitude: "-81.39511000",
  },
  {
    id: 141662,
    name: "Reno",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.37285000",
    longitude: "-81.39567000",
  },
  {
    id: 141663,
    name: "Reynoldsburg",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.95479000",
    longitude: "-82.81212000",
  },
  {
    id: 141664,
    name: "Richfield",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.23978000",
    longitude: "-81.63818000",
  },
  {
    id: 141665,
    name: "Richland County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.77468000",
    longitude: "-82.53648000",
  },
  {
    id: 141666,
    name: "Richmond Heights",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.55283000",
    longitude: "-81.51012000",
  },
  {
    id: 141667,
    name: "Richville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.75117000",
    longitude: "-81.47790000",
  },
  {
    id: 141668,
    name: "Richwood",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.42645000",
    longitude: "-83.29686000",
  },
  {
    id: 141669,
    name: "Ripley",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "38.74563000",
    longitude: "-83.84492000",
  },
  {
    id: 141670,
    name: "Rittman",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.97811000",
    longitude: "-81.78208000",
  },
  {
    id: 141671,
    name: "Riverside",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.77978000",
    longitude: "-84.12410000",
  },
  {
    id: 141672,
    name: "Roaming Shores",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.64311000",
    longitude: "-80.82342000",
  },
  {
    id: 141673,
    name: "Rockford",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.68783000",
    longitude: "-84.64663000",
  },
  {
    id: 141674,
    name: "Rocky River",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.47560000",
    longitude: "-81.83930000",
  },
  {
    id: 141675,
    name: "Rosemount",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "38.78619000",
    longitude: "-82.97906000",
  },
  {
    id: 141676,
    name: "Roseville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.80729000",
    longitude: "-82.07125000",
  },
  {
    id: 141677,
    name: "Ross",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.31228000",
    longitude: "-84.65050000",
  },
  {
    id: 141678,
    name: "Ross County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.33763000",
    longitude: "-83.05703000",
  },
  {
    id: 141679,
    name: "Rossford",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.60977000",
    longitude: "-83.56438000",
  },
  {
    id: 141680,
    name: "Rossmoyne",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.21367000",
    longitude: "-84.38688000",
  },
  {
    id: 141681,
    name: "Russells Point",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.47116000",
    longitude: "-83.89272000",
  },
  {
    id: 141682,
    name: "Sabina",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.48867000",
    longitude: "-83.63687000",
  },
  {
    id: 141683,
    name: "Saint Bernard",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.16700000",
    longitude: "-84.49855000",
  },
  {
    id: 141684,
    name: "Saint Clairsville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.08063000",
    longitude: "-80.90009000",
  },
  {
    id: 141685,
    name: "Saint Henry",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.41755000",
    longitude: "-84.63968000",
  },
  {
    id: 141686,
    name: "Saint Marys",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.54227000",
    longitude: "-84.38940000",
  },
  {
    id: 141687,
    name: "Saint Paris",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.12839000",
    longitude: "-83.95966000",
  },
  {
    id: 141688,
    name: "Salem",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.90089000",
    longitude: "-80.85675000",
  },
  {
    id: 141689,
    name: "Salem Heights",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.07173000",
    longitude: "-84.37827000",
  },
  {
    id: 141690,
    name: "Salineville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.62256000",
    longitude: "-80.83786000",
  },
  {
    id: 141691,
    name: "Sandusky",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.44894000",
    longitude: "-82.70796000",
  },
  {
    id: 141692,
    name: "Sandusky County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.35742000",
    longitude: "-83.14391000",
  },
  {
    id: 141693,
    name: "Sawyerwood",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.03783000",
    longitude: "-81.44095000",
  },
  {
    id: 141694,
    name: "Scioto County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "38.80396000",
    longitude: "-82.99283000",
  },
  {
    id: 141695,
    name: "Sciotodale",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "38.75480000",
    longitude: "-82.86878000",
  },
  {
    id: 141696,
    name: "Sebring",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.92284000",
    longitude: "-81.01898000",
  },
  {
    id: 141697,
    name: "Seneca County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.12388000",
    longitude: "-83.12771000",
  },
  {
    id: 141698,
    name: "Seven Hills",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.39533000",
    longitude: "-81.67624000",
  },
  {
    id: 141699,
    name: "Seville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.01006000",
    longitude: "-81.86236000",
  },
  {
    id: 141700,
    name: "Shadyside",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.97091000",
    longitude: "-80.75064000",
  },
  {
    id: 141701,
    name: "Shaker Heights",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.47394000",
    longitude: "-81.53707000",
  },
  {
    id: 141702,
    name: "Sharonville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.26811000",
    longitude: "-84.41327000",
  },
  {
    id: 141703,
    name: "Shawnee Hills",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.65284000",
    longitude: "-83.78687000",
  },
  {
    id: 141704,
    name: "Sheffield",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.42115000",
    longitude: "-82.09626000",
  },
  {
    id: 141705,
    name: "Sheffield Lake",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.48754000",
    longitude: "-82.10154000",
  },
  {
    id: 141706,
    name: "Shelby",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.88145000",
    longitude: "-82.66184000",
  },
  {
    id: 141707,
    name: "Shelby County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.33153000",
    longitude: "-84.20473000",
  },
  {
    id: 141708,
    name: "Sherwood",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.08478000",
    longitude: "-84.36077000",
  },
  {
    id: 141709,
    name: "Shiloh",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.81867000",
    longitude: "-84.22855000",
  },
  {
    id: 141710,
    name: "Shreve",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.68145000",
    longitude: "-82.02181000",
  },
  {
    id: 141711,
    name: "Sidney",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.28422000",
    longitude: "-84.15550000",
  },
  {
    id: 141712,
    name: "Silver Lake",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.15895000",
    longitude: "-81.45428000",
  },
  {
    id: 141713,
    name: "Silverton",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.19284000",
    longitude: "-84.40050000",
  },
  {
    id: 141714,
    name: "Sixteen Mile Stand",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.27284000",
    longitude: "-84.32744000",
  },
  {
    id: 141715,
    name: "Skyline Acres",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.22867000",
    longitude: "-84.56689000",
  },
  {
    id: 141716,
    name: "Smithville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.86228000",
    longitude: "-81.86180000",
  },
  {
    id: 141717,
    name: "Solon",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.38978000",
    longitude: "-81.44123000",
  },
  {
    id: 141718,
    name: "Somerset",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.80701000",
    longitude: "-82.29709000",
  },
  {
    id: 141719,
    name: "South Amherst",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.35588000",
    longitude: "-82.25377000",
  },
  {
    id: 141720,
    name: "South Bloomfield",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.71840000",
    longitude: "-82.98685000",
  },
  {
    id: 141721,
    name: "South Canal",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.17728000",
    longitude: "-80.98676000",
  },
  {
    id: 141722,
    name: "South Charleston",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.82534000",
    longitude: "-83.63437000",
  },
  {
    id: 141723,
    name: "South Euclid",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.52311000",
    longitude: "-81.51846000",
  },
  {
    id: 141724,
    name: "South Lebanon",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.37089000",
    longitude: "-84.21327000",
  },
  {
    id: 141725,
    name: "South Point",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "38.41786000",
    longitude: "-82.58627000",
  },
  {
    id: 141726,
    name: "South Russell",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.43144000",
    longitude: "-81.36539000",
  },
  {
    id: 141727,
    name: "South Zanesville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.89923000",
    longitude: "-82.00625000",
  },
  {
    id: 141728,
    name: "Spencerville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.70894000",
    longitude: "-84.35356000",
  },
  {
    id: 141729,
    name: "Springboro",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.55228000",
    longitude: "-84.23327000",
  },
  {
    id: 141730,
    name: "Springdale",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.28700000",
    longitude: "-84.48522000",
  },
  {
    id: 141731,
    name: "Springfield",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.92423000",
    longitude: "-83.80882000",
  },
  {
    id: 141732,
    name: "Stark County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.81389000",
    longitude: "-81.36564000",
  },
  {
    id: 141733,
    name: "Steubenville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.36979000",
    longitude: "-80.63396000",
  },
  {
    id: 141734,
    name: "Stony Prairie",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.35144000",
    longitude: "-83.15520000",
  },
  {
    id: 141735,
    name: "Stow",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.15950000",
    longitude: "-81.44039000",
  },
  {
    id: 141736,
    name: "Strasburg",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.59478000",
    longitude: "-81.52679000",
  },
  {
    id: 141737,
    name: "Streetsboro",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.23922000",
    longitude: "-81.34594000",
  },
  {
    id: 141738,
    name: "Strongsville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.31450000",
    longitude: "-81.83569000",
  },
  {
    id: 141739,
    name: "Struthers",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.05256000",
    longitude: "-80.60785000",
  },
  {
    id: 141740,
    name: "Stryker",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.50366000",
    longitude: "-84.41412000",
  },
  {
    id: 141741,
    name: "Sugarcreek",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.50312000",
    longitude: "-81.64096000",
  },
  {
    id: 141742,
    name: "Sugarcreek Police Dept",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.50253000",
    longitude: "-81.64176000",
  },
  {
    id: 141743,
    name: "Summerside",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.10478000",
    longitude: "-84.28827000",
  },
  {
    id: 141744,
    name: "Summit County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.12598000",
    longitude: "-81.53217000",
  },
  {
    id: 141745,
    name: "Sunbury",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.24256000",
    longitude: "-82.85907000",
  },
  {
    id: 141746,
    name: "Swanton",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.58866000",
    longitude: "-83.89105000",
  },
  {
    id: 141747,
    name: "Sylvania",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.71894000",
    longitude: "-83.71299000",
  },
  {
    id: 141748,
    name: "Tallmadge",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.10145000",
    longitude: "-81.44178000",
  },
  {
    id: 141749,
    name: "Terrace Park",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.15923000",
    longitude: "-84.30716000",
  },
  {
    id: 141750,
    name: "The Plains",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.36896000",
    longitude: "-82.13237000",
  },
  {
    id: 141751,
    name: "The Village of Indian Hill",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.17949000",
    longitude: "-84.33517000",
  },
  {
    id: 141752,
    name: "Thornport",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.91312000",
    longitude: "-82.41099000",
  },
  {
    id: 141753,
    name: "Tiffin",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.11450000",
    longitude: "-83.17797000",
  },
  {
    id: 141754,
    name: "Tiltonsville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.16674000",
    longitude: "-80.69980000",
  },
  {
    id: 141755,
    name: "Tipp City",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.95839000",
    longitude: "-84.17216000",
  },
  {
    id: 141756,
    name: "Toledo",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.66394000",
    longitude: "-83.55521000",
  },
  {
    id: 141757,
    name: "Toronto",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.46423000",
    longitude: "-80.60091000",
  },
  {
    id: 141758,
    name: "Trenton",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.48089000",
    longitude: "-84.45772000",
  },
  {
    id: 141759,
    name: "Trotwood",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.79728000",
    longitude: "-84.31133000",
  },
  {
    id: 141760,
    name: "Troy",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.03950000",
    longitude: "-84.20328000",
  },
  {
    id: 141761,
    name: "Trumbull County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.31717000",
    longitude: "-80.76116000",
  },
  {
    id: 141762,
    name: "Turpin Hills",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.11006000",
    longitude: "-84.37994000",
  },
  {
    id: 141763,
    name: "Tuscarawas",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.39479000",
    longitude: "-81.40706000",
  },
  {
    id: 141764,
    name: "Tuscarawas County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.44096000",
    longitude: "-81.47377000",
  },
  {
    id: 141765,
    name: "Twinsburg",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.31256000",
    longitude: "-81.44011000",
  },
  {
    id: 141766,
    name: "Uhrichsville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.39312000",
    longitude: "-81.34650000",
  },
  {
    id: 141767,
    name: "Union",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.89783000",
    longitude: "-84.30633000",
  },
  {
    id: 141768,
    name: "Union City",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.19938000",
    longitude: "-84.80353000",
  },
  {
    id: 141769,
    name: "Union County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.29940000",
    longitude: "-83.37158000",
  },
  {
    id: 141770,
    name: "Uniontown",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.97506000",
    longitude: "-81.40817000",
  },
  {
    id: 141771,
    name: "University Heights",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.49783000",
    longitude: "-81.53735000",
  },
  {
    id: 141772,
    name: "Upper Arlington",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.99451000",
    longitude: "-83.06241000",
  },
  {
    id: 141773,
    name: "Upper Sandusky",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.82728000",
    longitude: "-83.28131000",
  },
  {
    id: 141774,
    name: "Urbana",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.10839000",
    longitude: "-83.75243000",
  },
  {
    id: 141775,
    name: "Urbancrest",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.89756000",
    longitude: "-83.08685000",
  },
  {
    id: 141776,
    name: "Utica",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.23423000",
    longitude: "-82.45127000",
  },
  {
    id: 141777,
    name: "Valley View",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.38783000",
    longitude: "-81.60457000",
  },
  {
    id: 141778,
    name: "Van Wert",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.86949000",
    longitude: "-84.58412000",
  },
  {
    id: 141779,
    name: "Van Wert County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.85540000",
    longitude: "-84.58610000",
  },
  {
    id: 141780,
    name: "Vandalia",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.89061000",
    longitude: "-84.19883000",
  },
  {
    id: 141781,
    name: "Vermilion",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.42199000",
    longitude: "-82.36461000",
  },
  {
    id: 141782,
    name: "Vermilion-on-the-Lake",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.42838000",
    longitude: "-82.32377000",
  },
  {
    id: 141783,
    name: "Versailles",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.22255000",
    longitude: "-84.48440000",
  },
  {
    id: 141784,
    name: "Vinton County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.25099000",
    longitude: "-82.48535000",
  },
  {
    id: 141785,
    name: "Wadsworth",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.02561000",
    longitude: "-81.72985000",
  },
  {
    id: 141786,
    name: "Wakeman",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.25450000",
    longitude: "-82.39961000",
  },
  {
    id: 141787,
    name: "Walbridge",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.58783000",
    longitude: "-83.49327000",
  },
  {
    id: 141788,
    name: "Walton Hills",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.36561000",
    longitude: "-81.56123000",
  },
  {
    id: 141789,
    name: "Wapakoneta",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.56783000",
    longitude: "-84.19356000",
  },
  {
    id: 141790,
    name: "Warren",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.23756000",
    longitude: "-80.81842000",
  },
  {
    id: 141791,
    name: "Warren County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.42758000",
    longitude: "-84.16676000",
  },
  {
    id: 141792,
    name: "Warrensville Heights",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.43505000",
    longitude: "-81.53623000",
  },
  {
    id: 141793,
    name: "Washington County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.45532000",
    longitude: "-81.49525000",
  },
  {
    id: 141794,
    name: "Washington Court House",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.53645000",
    longitude: "-83.43908000",
  },
  {
    id: 141795,
    name: "Waterville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.50089000",
    longitude: "-83.71827000",
  },
  {
    id: 141796,
    name: "Wauseon",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.54922000",
    longitude: "-84.14161000",
  },
  {
    id: 141797,
    name: "Waverly",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.12673000",
    longitude: "-82.98546000",
  },
  {
    id: 141798,
    name: "Wayne County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.82887000",
    longitude: "-81.88803000",
  },
  {
    id: 141799,
    name: "Waynesville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.52978000",
    longitude: "-84.08660000",
  },
  {
    id: 141800,
    name: "Wellington",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.16894000",
    longitude: "-82.21794000",
  },
  {
    id: 141801,
    name: "Wellston",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.12341000",
    longitude: "-82.53294000",
  },
  {
    id: 141802,
    name: "Wellsville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.60284000",
    longitude: "-80.64896000",
  },
  {
    id: 141803,
    name: "West Alexandria",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.74450000",
    longitude: "-84.53217000",
  },
  {
    id: 141804,
    name: "West Carrollton City",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.67228000",
    longitude: "-84.25216000",
  },
  {
    id: 141805,
    name: "West Hill",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.23283000",
    longitude: "-80.51924000",
  },
  {
    id: 141806,
    name: "West Jefferson",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.94478000",
    longitude: "-83.26880000",
  },
  {
    id: 141807,
    name: "West Lafayette",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.27535000",
    longitude: "-81.75096000",
  },
  {
    id: 141808,
    name: "West Liberty",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.25228000",
    longitude: "-83.75577000",
  },
  {
    id: 141809,
    name: "West Milton",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.96255000",
    longitude: "-84.32800000",
  },
  {
    id: 141810,
    name: "West Portsmouth",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "38.75841000",
    longitude: "-83.02906000",
  },
  {
    id: 141811,
    name: "West Salem",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.97144000",
    longitude: "-82.10987000",
  },
  {
    id: 141812,
    name: "West Union",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "38.79452000",
    longitude: "-83.54519000",
  },
  {
    id: 141813,
    name: "West Unity",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.58616000",
    longitude: "-84.43495000",
  },
  {
    id: 141814,
    name: "Westerville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.12617000",
    longitude: "-82.92907000",
  },
  {
    id: 141815,
    name: "Westfield Center",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.02644000",
    longitude: "-81.93320000",
  },
  {
    id: 141816,
    name: "Westlake",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.45532000",
    longitude: "-81.91792000",
  },
  {
    id: 141817,
    name: "Weston",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.34477000",
    longitude: "-83.79716000",
  },
  {
    id: 141818,
    name: "Wetherington",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.36367000",
    longitude: "-84.37744000",
  },
  {
    id: 141819,
    name: "Wheelersburg",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "38.73035000",
    longitude: "-82.85545000",
  },
  {
    id: 141820,
    name: "White Oak",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.21311000",
    longitude: "-84.59939000",
  },
  {
    id: 141821,
    name: "Whitehall",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.96673000",
    longitude: "-82.88546000",
  },
  {
    id: 141822,
    name: "Whitehouse",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.51894000",
    longitude: "-83.80383000",
  },
  {
    id: 141823,
    name: "Wickliffe",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.60533000",
    longitude: "-81.45345000",
  },
  {
    id: 141824,
    name: "Wilberforce",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.71617000",
    longitude: "-83.87771000",
  },
  {
    id: 141825,
    name: "Willard",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.05311000",
    longitude: "-82.72629000",
  },
  {
    id: 141826,
    name: "Williams County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.56029000",
    longitude: "-84.58814000",
  },
  {
    id: 141827,
    name: "Williamsburg",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.05423000",
    longitude: "-84.05299000",
  },
  {
    id: 141828,
    name: "Williamsport",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.58590000",
    longitude: "-83.12046000",
  },
  {
    id: 141829,
    name: "Willoughby",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.63977000",
    longitude: "-81.40650000",
  },
  {
    id: 141830,
    name: "Willoughby Hills",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.59838000",
    longitude: "-81.41845000",
  },
  {
    id: 141831,
    name: "Willowick",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.63310000",
    longitude: "-81.46873000",
  },
  {
    id: 141832,
    name: "Wilmington",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.44534000",
    longitude: "-83.82854000",
  },
  {
    id: 141833,
    name: "Winchester",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "38.94174000",
    longitude: "-83.65075000",
  },
  {
    id: 141834,
    name: "Windham",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.23506000",
    longitude: "-81.04926000",
  },
  {
    id: 141835,
    name: "Wintersville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.37535000",
    longitude: "-80.70369000",
  },
  {
    id: 141836,
    name: "Withamsville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.06228000",
    longitude: "-84.28827000",
  },
  {
    id: 141837,
    name: "Wolfhurst",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.06924000",
    longitude: "-80.78370000",
  },
  {
    id: 141838,
    name: "Wood County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.36169000",
    longitude: "-83.62299000",
  },
  {
    id: 141839,
    name: "Woodlawn",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.25200000",
    longitude: "-84.47022000",
  },
  {
    id: 141840,
    name: "Woodsfield",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.76257000",
    longitude: "-81.11538000",
  },
  {
    id: 141841,
    name: "Woodville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.45144000",
    longitude: "-83.36576000",
  },
  {
    id: 141842,
    name: "Wooster",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.80517000",
    longitude: "-81.93646000",
  },
  {
    id: 141843,
    name: "Worthington",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.09312000",
    longitude: "-83.01796000",
  },
  {
    id: 141844,
    name: "Wright-Patterson AFB",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.81113000",
    longitude: "-84.05731000",
  },
  {
    id: 141845,
    name: "Wyandot County",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.84237000",
    longitude: "-83.30437000",
  },
  {
    id: 141846,
    name: "Wyoming",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.23117000",
    longitude: "-84.46578000",
  },
  {
    id: 141847,
    name: "Xenia",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.68478000",
    longitude: "-83.92965000",
  },
  {
    id: 141848,
    name: "Yellow Springs",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.80645000",
    longitude: "-83.88687000",
  },
  {
    id: 141849,
    name: "Yorkville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "40.15452000",
    longitude: "-80.71036000",
  },
  {
    id: 141850,
    name: "Youngstown",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "41.09978000",
    longitude: "-80.64952000",
  },
  {
    id: 141851,
    name: "Zanesville",
    state_id: 4851,
    state_code: "OH",
    country_id: 233,
    country_code: "US",
    latitude: "39.94035000",
    longitude: "-82.01319000",
  },
  {
    id: 111005,
    name: "Ada",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.77453000",
    longitude: "-96.67834000",
  },
  {
    id: 111009,
    name: "Adair County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.88393000",
    longitude: "-94.65868000",
  },
  {
    id: 111052,
    name: "Afton",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.69369000",
    longitude: "-94.96302000",
  },
  {
    id: 111158,
    name: "Alfalfa County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.73098000",
    longitude: "-98.32400000",
  },
  {
    id: 111252,
    name: "Altus",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.63813000",
    longitude: "-99.33398000",
  },
  {
    id: 111256,
    name: "Alva",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.80499000",
    longitude: "-98.66718000",
  },
  {
    id: 111298,
    name: "Anadarko",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.07256000",
    longitude: "-98.24366000",
  },
  {
    id: 111374,
    name: "Antlers",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.23121000",
    longitude: "-95.62025000",
  },
  {
    id: 111379,
    name: "Apache",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.89368000",
    longitude: "-98.36589000",
  },
  {
    id: 111412,
    name: "Arapaho",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.57783000",
    longitude: "-98.96453000",
  },
  {
    id: 111442,
    name: "Ardmore",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.17426000",
    longitude: "-97.14363000",
  },
  {
    id: 111459,
    name: "Arkoma",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.35454000",
    longitude: "-94.43410000",
  },
  {
    id: 111483,
    name: "Arnett",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.13504000",
    longitude: "-99.77484000",
  },
  {
    id: 111603,
    name: "Atoka",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.38593000",
    longitude: "-96.12833000",
  },
  {
    id: 111606,
    name: "Atoka County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.37372000",
    longitude: "-96.03783000",
  },
  {
    id: 111833,
    name: "Barnsdall",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.56202000",
    longitude: "-96.16167000",
  },
  {
    id: 111860,
    name: "Bartlesville",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.74731000",
    longitude: "-95.98082000",
  },
  {
    id: 111994,
    name: "Beaver",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.81614000",
    longitude: "-100.51987000",
  },
  {
    id: 111998,
    name: "Beaver County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.74971000",
    longitude: "-100.47678000",
  },
  {
    id: 112016,
    name: "Beckham County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.26871000",
    longitude: "-99.68192000",
  },
  {
    id: 112045,
    name: "Beggs",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.74260000",
    longitude: "-96.07027000",
  },
  {
    id: 112273,
    name: "Bethany",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.51867000",
    longitude: "-97.63226000",
  },
  {
    id: 112283,
    name: "Bethel Acres",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.30868000",
    longitude: "-97.02558000",
  },
  {
    id: 112370,
    name: "Bixby",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.94204000",
    longitude: "-95.88332000",
  },
  {
    id: 112396,
    name: "Blackwell",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.80448000",
    longitude: "-97.28282000",
  },
  {
    id: 112405,
    name: "Blaine County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.87525000",
    longitude: "-98.43346000",
  },
  {
    id: 112417,
    name: "Blanchard",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.13784000",
    longitude: "-97.65809000",
  },
  {
    id: 112509,
    name: "Boise City",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.72947000",
    longitude: "-102.51324000",
  },
  {
    id: 112513,
    name: "Boley",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.49341000",
    longitude: "-96.48362000",
  },
  {
    id: 112702,
    name: "Bray",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.63786000",
    longitude: "-97.81753000",
  },
  {
    id: 112816,
    name: "Bristow",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.83063000",
    longitude: "-96.39112000",
  },
  {
    id: 112841,
    name: "Broken Arrow",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.05260000",
    longitude: "-95.79082000",
  },
  {
    id: 112842,
    name: "Broken Bow",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.02928000",
    longitude: "-94.73910000",
  },
  {
    id: 112961,
    name: "Bryan County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "33.96230000",
    longitude: "-96.25975000",
  },
  {
    id: 113008,
    name: "Buffalo",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.83559000",
    longitude: "-99.63040000",
  },
  {
    id: 113088,
    name: "Burns Flat",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.34894000",
    longitude: "-99.17036000",
  },
  {
    id: 113104,
    name: "Bushyhead",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.46148000",
    longitude: "-95.49414000",
  },
  {
    id: 113137,
    name: "Byng",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.86120000",
    longitude: "-96.66557000",
  },
  {
    id: 113155,
    name: "Cache",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.62952000",
    longitude: "-98.62867000",
  },
  {
    id: 113159,
    name: "Caddo",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.12676000",
    longitude: "-96.26332000",
  },
  {
    id: 113160,
    name: "Caddo County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.17438000",
    longitude: "-98.37515000",
  },
  {
    id: 113195,
    name: "Calera",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "33.93454000",
    longitude: "-96.42860000",
  },
  {
    id: 113315,
    name: "Canadian County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.54244000",
    longitude: "-97.98238000",
  },
  {
    id: 113425,
    name: "Carnegie",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.10367000",
    longitude: "-98.60367000",
  },
  {
    id: 113482,
    name: "Carter County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.25086000",
    longitude: "-97.28579000",
  },
  {
    id: 113576,
    name: "Catoosa",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.18899000",
    longitude: "-95.74582000",
  },
  {
    id: 113677,
    name: "Central High",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.62313000",
    longitude: "-98.08976000",
  },
  {
    id: 113729,
    name: "Chandler",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.70173000",
    longitude: "-96.88086000",
  },
  {
    id: 113823,
    name: "Checotah",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.47010000",
    longitude: "-95.52304000",
  },
  {
    id: 113830,
    name: "Chelsea",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.53565000",
    longitude: "-95.43247000",
  },
  {
    id: 113847,
    name: "Cherokee",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.75447000",
    longitude: "-98.35674000",
  },
  {
    id: 113853,
    name: "Cherokee County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.90663000",
    longitude: "-94.99966000",
  },
  {
    id: 113925,
    name: "Cheyenne",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.61394000",
    longitude: "-99.67149000",
  },
  {
    id: 113941,
    name: "Chickasha",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.05257000",
    longitude: "-97.93643000",
  },
  {
    id: 113985,
    name: "Choctaw",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.49756000",
    longitude: "-97.26892000",
  },
  {
    id: 113988,
    name: "Choctaw County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.02662000",
    longitude: "-95.55214000",
  },
  {
    id: 113990,
    name: "Chouteau",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.18593000",
    longitude: "-95.34302000",
  },
  {
    id: 114024,
    name: "Cimarron County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.74831000",
    longitude: "-102.51771000",
  },
  {
    id: 114107,
    name: "Claremore",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.31260000",
    longitude: "-95.61609000",
  },
  {
    id: 114237,
    name: "Cleora",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.57897000",
    longitude: "-94.97107000",
  },
  {
    id: 114245,
    name: "Cleveland",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.31032000",
    longitude: "-96.46584000",
  },
  {
    id: 114250,
    name: "Cleveland County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.20302000",
    longitude: "-97.32641000",
  },
  {
    id: 114276,
    name: "Clinton",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.51561000",
    longitude: "-98.96731000",
  },
  {
    id: 114328,
    name: "Coal County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.58819000",
    longitude: "-96.29786000",
  },
  {
    id: 114336,
    name: "Coalgate",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.53815000",
    longitude: "-96.21861000",
  },
  {
    id: 114378,
    name: "Colbert",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "33.85316000",
    longitude: "-96.50249000",
  },
  {
    id: 114436,
    name: "Collinsville",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.36454000",
    longitude: "-95.83888000",
  },
  {
    id: 114510,
    name: "Comanche",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.36897000",
    longitude: "-97.96392000",
  },
  {
    id: 114513,
    name: "Comanche County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.66210000",
    longitude: "-98.47166000",
  },
  {
    id: 114523,
    name: "Commerce",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.93340000",
    longitude: "-94.87301000",
  },
  {
    id: 114615,
    name: "Copeland",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.65591000",
    longitude: "-94.82829000",
  },
  {
    id: 114636,
    name: "Cordell",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.29061000",
    longitude: "-98.98841000",
  },
  {
    id: 114697,
    name: "Cotton County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.29024000",
    longitude: "-98.37223000",
  },
  {
    id: 114746,
    name: "Coweta",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.95177000",
    longitude: "-95.65081000",
  },
  {
    id: 114757,
    name: "Craig County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.76176000",
    longitude: "-95.20850000",
  },
  {
    id: 114789,
    name: "Creek County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.90268000",
    longitude: "-96.37094000",
  },
  {
    id: 114794,
    name: "Crescent",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.95254000",
    longitude: "-97.59477000",
  },
  {
    id: 114931,
    name: "Cushing",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.98506000",
    longitude: "-96.76697000",
  },
  {
    id: 114935,
    name: "Custer County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.63886000",
    longitude: "-99.00150000",
  },
  {
    id: 114959,
    name: "Cyril",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.89646000",
    longitude: "-98.20060000",
  },
  {
    id: 115072,
    name: "Davis",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.50453000",
    longitude: "-97.11946000",
  },
  {
    id: 115187,
    name: "Del City",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.44201000",
    longitude: "-97.44087000",
  },
  {
    id: 115204,
    name: "Delaware County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.40820000",
    longitude: "-94.80265000",
  },
  {
    id: 115302,
    name: "Dewey",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.79592000",
    longitude: "-95.93554000",
  },
  {
    id: 115303,
    name: "Dewey County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.98766000",
    longitude: "-99.00789000",
  },
  {
    id: 115330,
    name: "Dickson",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.18732000",
    longitude: "-96.98446000",
  },
  {
    id: 115482,
    name: "Drumright",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.98840000",
    longitude: "-96.60113000",
  },
  {
    id: 115521,
    name: "Duncan",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.50230000",
    longitude: "-97.95781000",
  },
  {
    id: 115567,
    name: "Durant",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "33.99399000",
    longitude: "-96.37082000",
  },
  {
    id: 115877,
    name: "Edmond",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.65283000",
    longitude: "-97.47810000",
  },
  {
    id: 115935,
    name: "El Reno",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.53227000",
    longitude: "-97.95505000",
  },
  {
    id: 115964,
    name: "Elgin",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.78035000",
    longitude: "-98.29227000",
  },
  {
    id: 115983,
    name: "Elk City",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.41199000",
    longitude: "-99.40426000",
  },
  {
    id: 116031,
    name: "Ellis County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.21836000",
    longitude: "-99.75460000",
  },
  {
    id: 116138,
    name: "Enid",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.39559000",
    longitude: "-97.87839000",
  },
  {
    id: 116163,
    name: "Erick",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.21533000",
    longitude: "-99.86649000",
  },
  {
    id: 116225,
    name: "Eufaula",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.28722000",
    longitude: "-95.58250000",
  },
  {
    id: 116302,
    name: "Fairfax",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.57366000",
    longitude: "-96.70420000",
  },
  {
    id: 116329,
    name: "Fairland",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.75118000",
    longitude: "-94.84746000",
  },
  {
    id: 116350,
    name: "Fairview",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.26892000",
    longitude: "-98.47980000",
  },
  {
    id: 116565,
    name: "Fletcher",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.82312000",
    longitude: "-98.24422000",
  },
  {
    id: 116665,
    name: "Forest Park",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.50423000",
    longitude: "-97.44615000",
  },
  {
    id: 116713,
    name: "Fort Gibson",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.79760000",
    longitude: "-95.25052000",
  },
  {
    id: 116886,
    name: "Frederick",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.39203000",
    longitude: "-99.01841000",
  },
  {
    id: 117082,
    name: "Garfield County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.37906000",
    longitude: "-97.78272000",
  },
  {
    id: 117104,
    name: "Garvin County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.70457000",
    longitude: "-97.30932000",
  },
  {
    id: 117130,
    name: "Geary",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.63116000",
    longitude: "-98.31729000",
  },
  {
    id: 117176,
    name: "Geronimo",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.48119000",
    longitude: "-98.38311000",
  },
  {
    id: 117302,
    name: "Glenpool",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.95537000",
    longitude: "-96.00888000",
  },
  {
    id: 117365,
    name: "Goldsby",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.14118000",
    longitude: "-97.47698000",
  },
  {
    id: 117392,
    name: "Goodwell",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.59530000",
    longitude: "-101.63655000",
  },
  {
    id: 117427,
    name: "Grady County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.01694000",
    longitude: "-97.88411000",
  },
  {
    id: 117491,
    name: "Granite",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.96228000",
    longitude: "-99.38064000",
  },
  {
    id: 117510,
    name: "Grant County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.79614000",
    longitude: "-97.78616000",
  },
  {
    id: 117714,
    name: "Greer County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.93570000",
    longitude: "-99.56080000",
  },
  {
    id: 117754,
    name: "Grove",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.59369000",
    longitude: "-94.76912000",
  },
  {
    id: 117812,
    name: "Guthrie",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.87894000",
    longitude: "-97.42532000",
  },
  {
    id: 117818,
    name: "Guymon",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.68280000",
    longitude: "-101.48155000",
  },
  {
    id: 117867,
    name: "Hall Park",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.23701000",
    longitude: "-97.40642000",
  },
  {
    id: 118028,
    name: "Harmon County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.74412000",
    longitude: "-99.84628000",
  },
  {
    id: 118034,
    name: "Harper County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.78873000",
    longitude: "-99.66736000",
  },
  {
    id: 118038,
    name: "Harrah",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.48951000",
    longitude: "-97.16364000",
  },
  {
    id: 118103,
    name: "Hartshorne",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.84510000",
    longitude: "-95.55748000",
  },
  {
    id: 118123,
    name: "Haskell",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.82038000",
    longitude: "-95.67415000",
  },
  {
    id: 118125,
    name: "Haskell County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.22482000",
    longitude: "-95.11660000",
  },
  {
    id: 118218,
    name: "Healdton",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.23315000",
    longitude: "-97.48780000",
  },
  {
    id: 118226,
    name: "Heavener",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.88927000",
    longitude: "-94.60078000",
  },
  {
    id: 118250,
    name: "Helena",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.54614000",
    longitude: "-98.27007000",
  },
  {
    id: 118292,
    name: "Hennessey",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.10920000",
    longitude: "-97.89867000",
  },
  {
    id: 118307,
    name: "Henryetta",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.43983000",
    longitude: "-95.98194000",
  },
  {
    id: 118488,
    name: "Hinton",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.47144000",
    longitude: "-98.35562000",
  },
  {
    id: 118496,
    name: "Hobart",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.02950000",
    longitude: "-99.09313000",
  },
  {
    id: 118526,
    name: "Holdenville",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.08036000",
    longitude: "-96.39918000",
  },
  {
    id: 118547,
    name: "Hollis",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.68839000",
    longitude: "-99.91205000",
  },
  {
    id: 118609,
    name: "Hominy",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.41424000",
    longitude: "-96.39530000",
  },
  {
    id: 118628,
    name: "Hooker",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.86003000",
    longitude: "-101.21350000",
  },
  {
    id: 118758,
    name: "Hughes County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.04834000",
    longitude: "-96.25024000",
  },
  {
    id: 118765,
    name: "Hugo",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.01066000",
    longitude: "-95.50968000",
  },
  {
    id: 118868,
    name: "Idabel",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "33.89566000",
    longitude: "-94.82633000",
  },
  {
    id: 118948,
    name: "Inola",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.15121000",
    longitude: "-95.50942000",
  },
  {
    id: 119086,
    name: "Jackson County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.58797000",
    longitude: "-99.41476000",
  },
  {
    id: 119153,
    name: "Jay",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.42119000",
    longitude: "-94.79690000",
  },
  {
    id: 119185,
    name: "Jefferson County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.11101000",
    longitude: "-97.83585000",
  },
  {
    id: 119217,
    name: "Jenks",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.02287000",
    longitude: "-95.96833000",
  },
  {
    id: 119282,
    name: "Johnston County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.31649000",
    longitude: "-96.66066000",
  },
  {
    id: 119291,
    name: "Jones",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.56589000",
    longitude: "-97.28698000",
  },
  {
    id: 119344,
    name: "Justice",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.29287000",
    longitude: "-95.56664000",
  },
  {
    id: 119401,
    name: "Kay County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.81801000",
    longitude: "-97.14392000",
  },
  {
    id: 119430,
    name: "Kellyville",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.94370000",
    longitude: "-96.21361000",
  },
  {
    id: 119503,
    name: "Kenwood",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.31453000",
    longitude: "-94.98579000",
  },
  {
    id: 119553,
    name: "Kiefer",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.94482000",
    longitude: "-96.06528000",
  },
  {
    id: 119592,
    name: "Kingfisher",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.86143000",
    longitude: "-97.93172000",
  },
  {
    id: 119593,
    name: "Kingfisher County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.94539000",
    longitude: "-97.94211000",
  },
  {
    id: 119622,
    name: "Kingston",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "33.99871000",
    longitude: "-96.71972000",
  },
  {
    id: 119647,
    name: "Kiowa County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.91637000",
    longitude: "-98.98087000",
  },
  {
    id: 119704,
    name: "Konawa",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.95953000",
    longitude: "-96.75280000",
  },
  {
    id: 119715,
    name: "Krebs",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.92787000",
    longitude: "-95.71582000",
  },
  {
    id: 120116,
    name: "Langston",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.94505000",
    longitude: "-97.25531000",
  },
  {
    id: 120173,
    name: "Latimer County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.87606000",
    longitude: "-95.25042000",
  },
  {
    id: 120218,
    name: "Laverne",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.70975000",
    longitude: "-99.89346000",
  },
  {
    id: 120250,
    name: "Lawton",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.60869000",
    longitude: "-98.39033000",
  },
  {
    id: 120259,
    name: "Le Flore County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.90030000",
    longitude: "-94.70339000",
  },
  {
    id: 120445,
    name: "Lexington",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.01479000",
    longitude: "-97.33558000",
  },
  {
    id: 120523,
    name: "Lincoln County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.70300000",
    longitude: "-96.88092000",
  },
  {
    id: 120572,
    name: "Lindsay",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.83480000",
    longitude: "-97.60253000",
  },
  {
    id: 120687,
    name: "Locust Grove",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.20009000",
    longitude: "-95.16774000",
  },
  {
    id: 120698,
    name: "Logan County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.91934000",
    longitude: "-97.44332000",
  },
  {
    id: 120726,
    name: "Lone Grove",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.17537000",
    longitude: "-97.26279000",
  },
  {
    id: 120755,
    name: "Longtown",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.24538000",
    longitude: "-95.51276000",
  },
  {
    id: 120820,
    name: "Love County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "33.94986000",
    longitude: "-97.24418000",
  },
  {
    id: 120894,
    name: "Luther",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.66173000",
    longitude: "-97.19559000",
  },
  {
    id: 120983,
    name: "Madill",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.09038000",
    longitude: "-96.77167000",
  },
  {
    id: 121053,
    name: "Major County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.31164000",
    longitude: "-98.53595000",
  },
  {
    id: 121110,
    name: "Mangum",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.87200000",
    longitude: "-99.50426000",
  },
  {
    id: 121133,
    name: "Mannford",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.13341000",
    longitude: "-96.35446000",
  },
  {
    id: 121217,
    name: "Marietta",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "33.93704000",
    longitude: "-97.11668000",
  },
  {
    id: 121285,
    name: "Marlow",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.64813000",
    longitude: "-97.95809000",
  },
  {
    id: 121314,
    name: "Marshall County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.02449000",
    longitude: "-96.76913000",
  },
  {
    id: 121416,
    name: "Maud",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.13036000",
    longitude: "-96.77585000",
  },
  {
    id: 121435,
    name: "Mayes County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.30188000",
    longitude: "-95.23085000",
  },
  {
    id: 121452,
    name: "Maysville",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.81730000",
    longitude: "-97.40586000",
  },
  {
    id: 121462,
    name: "McAlester",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.93343000",
    longitude: "-95.76971000",
  },
  {
    id: 121469,
    name: "McClain County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.00934000",
    longitude: "-97.44430000",
  },
  {
    id: 121481,
    name: "McCord",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.67847000",
    longitude: "-97.04000000",
  },
  {
    id: 121490,
    name: "McCurtain County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.11529000",
    longitude: "-94.77133000",
  },
  {
    id: 121514,
    name: "McIntosh County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.37366000",
    longitude: "-95.66684000",
  },
  {
    id: 121536,
    name: "McLoud",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.43590000",
    longitude: "-97.09142000",
  },
  {
    id: 121591,
    name: "Medford",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.80697000",
    longitude: "-97.73366000",
  },
  {
    id: 121612,
    name: "Meeker",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.50340000",
    longitude: "-96.90280000",
  },
  {
    id: 121691,
    name: "Meridian",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.42731000",
    longitude: "-97.97809000",
  },
  {
    id: 121747,
    name: "Miami",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.87451000",
    longitude: "-94.87746000",
  },
  {
    id: 121824,
    name: "Midwest City",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.44951000",
    longitude: "-97.39670000",
  },
  {
    id: 121927,
    name: "Minco",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.31284000",
    longitude: "-97.94449000",
  },
  {
    id: 122200,
    name: "Moore",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.33951000",
    longitude: "-97.48670000",
  },
  {
    id: 122206,
    name: "Mooreland",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.43920000",
    longitude: "-99.20482000",
  },
  {
    id: 122266,
    name: "Morris",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.60760000",
    longitude: "-95.86027000",
  },
  {
    id: 122325,
    name: "Mounds",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.87648000",
    longitude: "-96.06111000",
  },
  {
    id: 122456,
    name: "Muldrow",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.40620000",
    longitude: "-94.59883000",
  },
  {
    id: 122494,
    name: "Murray County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.48233000",
    longitude: "-97.06792000",
  },
  {
    id: 122512,
    name: "Muskogee",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.74788000",
    longitude: "-95.36969000",
  },
  {
    id: 122513,
    name: "Muskogee County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.61613000",
    longitude: "-95.37953000",
  },
  {
    id: 122515,
    name: "Mustang",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.38423000",
    longitude: "-97.72449000",
  },
  {
    id: 122820,
    name: "Newcastle",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.24729000",
    longitude: "-97.59976000",
  },
  {
    id: 122832,
    name: "Newkirk",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.88225000",
    longitude: "-97.05337000",
  },
  {
    id: 122891,
    name: "Nichols Hills",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.55089000",
    longitude: "-97.54893000",
  },
  {
    id: 122897,
    name: "Nicoma Park",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.49117000",
    longitude: "-97.32309000",
  },
  {
    id: 122903,
    name: "Ninnekah",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.94785000",
    longitude: "-97.92393000",
  },
  {
    id: 122916,
    name: "Noble",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.13924000",
    longitude: "-97.39475000",
  },
  {
    id: 122917,
    name: "Noble County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.38860000",
    longitude: "-97.23051000",
  },
  {
    id: 122949,
    name: "Norman",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.22257000",
    longitude: "-97.43948000",
  },
  {
    id: 123209,
    name: "Nowata",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.70065000",
    longitude: "-95.63803000",
  },
  {
    id: 123210,
    name: "Nowata County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.79848000",
    longitude: "-95.61740000",
  },
  {
    id: 123279,
    name: "Oakhurst",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.07537000",
    longitude: "-96.06444000",
  },
  {
    id: 123287,
    name: "Oakland",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.10010000",
    longitude: "-96.79389000",
  },
  {
    id: 123389,
    name: "Oilton",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.08451000",
    longitude: "-96.58363000",
  },
  {
    id: 123395,
    name: "Okarche",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.72588000",
    longitude: "-97.97644000",
  },
  {
    id: 123400,
    name: "Okeene",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.11615000",
    longitude: "-98.31702000",
  },
  {
    id: 123401,
    name: "Okemah",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.43259000",
    longitude: "-96.30501000",
  },
  {
    id: 123403,
    name: "Okfuskee County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.46546000",
    longitude: "-96.32280000",
  },
  {
    id: 123404,
    name: "Oklahoma City",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.46756000",
    longitude: "-97.51643000",
  },
  {
    id: 123405,
    name: "Oklahoma County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.55152000",
    longitude: "-97.40720000",
  },
  {
    id: 123406,
    name: "Okmulgee",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.62344000",
    longitude: "-95.96055000",
  },
  {
    id: 123407,
    name: "Okmulgee County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.64666000",
    longitude: "-95.96431000",
  },
  {
    id: 123492,
    name: "Oologah",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.44704000",
    longitude: "-95.70832000",
  },
  {
    id: 123591,
    name: "Osage County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.62919000",
    longitude: "-96.39849000",
  },
  {
    id: 123639,
    name: "Ottawa County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.83551000",
    longitude: "-94.81044000",
  },
  {
    id: 123658,
    name: "Owasso",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.26954000",
    longitude: "-95.85471000",
  },
  {
    id: 123797,
    name: "Panama",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.16732000",
    longitude: "-94.67245000",
  },
  {
    id: 123847,
    name: "Park Hill",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.86120000",
    longitude: "-94.95884000",
  },
  {
    id: 123925,
    name: "Pauls Valley",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.74008000",
    longitude: "-97.22225000",
  },
  {
    id: 123930,
    name: "Pawhuska",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.66784000",
    longitude: "-96.33723000",
  },
  {
    id: 123934,
    name: "Pawnee",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.33783000",
    longitude: "-96.80392000",
  },
  {
    id: 123937,
    name: "Pawnee County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.31693000",
    longitude: "-96.69930000",
  },
  {
    id: 123946,
    name: "Payne County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.07732000",
    longitude: "-96.97577000",
  },
  {
    id: 124062,
    name: "Perkins",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.97394000",
    longitude: "-97.03364000",
  },
  {
    id: 124069,
    name: "Perry",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.28949000",
    longitude: "-97.28810000",
  },
  {
    id: 124167,
    name: "Piedmont",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.64200000",
    longitude: "-97.74643000",
  },
  {
    id: 124270,
    name: "Pink",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.26063000",
    longitude: "-97.11975000",
  },
  {
    id: 124295,
    name: "Pittsburg County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.92391000",
    longitude: "-95.74840000",
  },
  {
    id: 124419,
    name: "Pocola",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.23121000",
    longitude: "-94.47800000",
  },
  {
    id: 124464,
    name: "Ponca City",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.70698000",
    longitude: "-97.08559000",
  },
  {
    id: 124478,
    name: "Pontotoc County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.72800000",
    longitude: "-96.68444000",
  },
  {
    id: 124579,
    name: "Poteau",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.05371000",
    longitude: "-94.62356000",
  },
  {
    id: 124590,
    name: "Pottawatomie County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.20672000",
    longitude: "-96.94830000",
  },
  {
    id: 124622,
    name: "Prague",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.48674000",
    longitude: "-96.68502000",
  },
  {
    id: 124726,
    name: "Pryor",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.30843000",
    longitude: "-95.31691000",
  },
  {
    id: 124727,
    name: "Pryor Creek",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.30862000",
    longitude: "-95.31777000",
  },
  {
    id: 124753,
    name: "Purcell",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.01368000",
    longitude: "-97.36114000",
  },
  {
    id: 124759,
    name: "Pushmataha County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.41620000",
    longitude: "-95.37579000",
  },
  {
    id: 124804,
    name: "Quinton",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.12288000",
    longitude: "-95.37109000",
  },
  {
    id: 125144,
    name: "Ringling",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.17843000",
    longitude: "-97.59253000",
  },
  {
    id: 125347,
    name: "Roger Mills County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.68839000",
    longitude: "-99.69569000",
  },
  {
    id: 125352,
    name: "Rogers County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.37157000",
    longitude: "-95.60436000",
  },
  {
    id: 125359,
    name: "Roland",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.42120000",
    longitude: "-94.51466000",
  },
  {
    id: 125522,
    name: "Rush Springs",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.78257000",
    longitude: "-97.95698000",
  },
  {
    id: 125745,
    name: "Salina",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.29287000",
    longitude: "-95.15330000",
  },
  {
    id: 125762,
    name: "Sallisaw",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.46037000",
    longitude: "-94.78745000",
  },
  {
    id: 125861,
    name: "Sand Springs",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.13981000",
    longitude: "-96.10889000",
  },
  {
    id: 125933,
    name: "Sapulpa",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.99870000",
    longitude: "-96.11417000",
  },
  {
    id: 125989,
    name: "Sayre",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.29116000",
    longitude: "-99.64010000",
  },
  {
    id: 126145,
    name: "Seminole",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.22452000",
    longitude: "-96.67057000",
  },
  {
    id: 126149,
    name: "Seminole County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.16749000",
    longitude: "-96.61552000",
  },
  {
    id: 126165,
    name: "Sequoyah County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.49535000",
    longitude: "-94.75524000",
  },
  {
    id: 126241,
    name: "Shattuck",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.27614000",
    longitude: "-99.88276000",
  },
  {
    id: 126250,
    name: "Shawnee",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.32729000",
    longitude: "-96.92530000",
  },
  {
    id: 126478,
    name: "Skiatook",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.36842000",
    longitude: "-96.00138000",
  },
  {
    id: 126494,
    name: "Slaughterville",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.08729000",
    longitude: "-97.33503000",
  },
  {
    id: 126549,
    name: "Snyder",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.65896000",
    longitude: "-98.95174000",
  },
  {
    id: 126822,
    name: "Spencer",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.52284000",
    longitude: "-97.37726000",
  },
  {
    id: 126833,
    name: "Sperry",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.29731000",
    longitude: "-95.99139000",
  },
  {
    id: 126840,
    name: "Spiro",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.24121000",
    longitude: "-94.61994000",
  },
  {
    id: 127022,
    name: "Stephens County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.48559000",
    longitude: "-97.85148000",
  },
  {
    id: 127057,
    name: "Stigler",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.25371000",
    longitude: "-95.12302000",
  },
  {
    id: 127060,
    name: "Stillwater",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.11561000",
    longitude: "-97.05837000",
  },
  {
    id: 127064,
    name: "Stilwell",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.81453000",
    longitude: "-94.62856000",
  },
  {
    id: 127123,
    name: "Stratford",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.79675000",
    longitude: "-96.95946000",
  },
  {
    id: 127139,
    name: "Stroud",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.74868000",
    longitude: "-96.65807000",
  },
  {
    id: 127195,
    name: "Sulphur",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.50787000",
    longitude: "-96.96835000",
  },
  {
    id: 127377,
    name: "Tahlequah",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.91537000",
    longitude: "-94.96996000",
  },
  {
    id: 127388,
    name: "Talihina",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.75149000",
    longitude: "-95.04802000",
  },
  {
    id: 127398,
    name: "Taloga",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.03865000",
    longitude: "-98.96371000",
  },
  {
    id: 127478,
    name: "Tecumseh",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.25785000",
    longitude: "-96.93669000",
  },
  {
    id: 127543,
    name: "Texanna",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.34732000",
    longitude: "-95.43692000",
  },
  {
    id: 127548,
    name: "Texas County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.74791000",
    longitude: "-101.49001000",
  },
  {
    id: 127564,
    name: "The Village",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.56089000",
    longitude: "-97.55143000",
  },
  {
    id: 127578,
    name: "Thomas",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.74422000",
    longitude: "-98.74758000",
  },
  {
    id: 127650,
    name: "Tillman County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.37285000",
    longitude: "-98.92424000",
  },
  {
    id: 127682,
    name: "Tishomingo",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.23621000",
    longitude: "-96.67861000",
  },
  {
    id: 127721,
    name: "Tonkawa",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.67837000",
    longitude: "-97.31004000",
  },
  {
    id: 127888,
    name: "Tulsa",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.15398000",
    longitude: "-95.99277000",
  },
  {
    id: 127889,
    name: "Tulsa County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.12108000",
    longitude: "-95.94148000",
  },
  {
    id: 127899,
    name: "Turley",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.24204000",
    longitude: "-95.97583000",
  },
  {
    id: 127918,
    name: "Tuttle",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.29089000",
    longitude: "-97.81227000",
  },
  {
    id: 127981,
    name: "Union City",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.39172000",
    longitude: "-97.94144000",
  },
  {
    id: 128181,
    name: "Verdigris",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.23482000",
    longitude: "-95.69109000",
  },
  {
    id: 128214,
    name: "Vian",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.49843000",
    longitude: "-94.96967000",
  },
  {
    id: 128277,
    name: "Vinita",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.63869000",
    longitude: "-95.15413000",
  },
  {
    id: 128329,
    name: "Wagoner",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.95954000",
    longitude: "-95.36941000",
  },
  {
    id: 128330,
    name: "Wagoner County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.96110000",
    longitude: "-95.52118000",
  },
  {
    id: 128429,
    name: "Walters",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.35981000",
    longitude: "-98.30783000",
  },
  {
    id: 128473,
    name: "Warner",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.49426000",
    longitude: "-95.30552000",
  },
  {
    id: 128475,
    name: "Warr Acres",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.52256000",
    longitude: "-97.61893000",
  },
  {
    id: 128564,
    name: "Washington County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.71524000",
    longitude: "-95.90437000",
  },
  {
    id: 128589,
    name: "Washita County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.29038000",
    longitude: "-98.99223000",
  },
  {
    id: 128636,
    name: "Watonga",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.84477000",
    longitude: "-98.41313000",
  },
  {
    id: 128651,
    name: "Waukomis",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.28031000",
    longitude: "-97.89811000",
  },
  {
    id: 128659,
    name: "Waurika",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.16704000",
    longitude: "-97.99754000",
  },
  {
    id: 128717,
    name: "Weatherford",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.52616000",
    longitude: "-98.70757000",
  },
  {
    id: 129077,
    name: "Westville",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.99258000",
    longitude: "-94.56800000",
  },
  {
    id: 129091,
    name: "Wetumka",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.23759000",
    longitude: "-96.24167000",
  },
  {
    id: 129096,
    name: "Wewoka",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.15869000",
    longitude: "-96.49335000",
  },
  {
    id: 129227,
    name: "Wilburton",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.91871000",
    longitude: "-95.30914000",
  },
  {
    id: 129330,
    name: "Wilson",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.16204000",
    longitude: "-97.42586000",
  },
  {
    id: 129463,
    name: "Wister",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.96732000",
    longitude: "-94.72467000",
  },
  {
    id: 129549,
    name: "Woods County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.76694000",
    longitude: "-98.86512000",
  },
  {
    id: 129575,
    name: "Woodward",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.43365000",
    longitude: "-99.39039000",
  },
  {
    id: 129577,
    name: "Woodward County",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.42267000",
    longitude: "-99.26497000",
  },
  {
    id: 129633,
    name: "Wynnewood",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "34.64342000",
    longitude: "-97.16447000",
  },
  {
    id: 129654,
    name: "Yale",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "36.11423000",
    longitude: "-96.69919000",
  },
  {
    id: 129730,
    name: "Yukon",
    state_id: 1421,
    state_code: "OK",
    country_id: 233,
    country_code: "US",
    latitude: "35.50672000",
    longitude: "-97.76254000",
  },
  {
    id: 111111,
    name: "Albany",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.63651000",
    longitude: "-123.10593000",
  },
  {
    id: 111214,
    name: "Aloha",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.49428000",
    longitude: "-122.86705000",
  },
  {
    id: 111236,
    name: "Altamont",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "42.20681000",
    longitude: "-121.73722000",
  },
  {
    id: 111290,
    name: "Amity",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.11567000",
    longitude: "-123.20733000",
  },
  {
    id: 111540,
    name: "Ashland",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "42.19458000",
    longitude: "-122.70948000",
  },
  {
    id: 111563,
    name: "Astoria",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "46.18788000",
    longitude: "-123.83125000",
  },
  {
    id: 111572,
    name: "Athena",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.81180000",
    longitude: "-118.49053000",
  },
  {
    id: 111655,
    name: "Aumsville",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.84095000",
    longitude: "-122.87092000",
  },
  {
    id: 111740,
    name: "Baker City",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.77487000",
    longitude: "-117.83438000",
  },
  {
    id: 111743,
    name: "Baker County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.70923000",
    longitude: "-117.67534000",
  },
  {
    id: 111790,
    name: "Bandon",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.11900000",
    longitude: "-124.40845000",
  },
  {
    id: 111797,
    name: "Banks",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.61872000",
    longitude: "-123.11428000",
  },
  {
    id: 111874,
    name: "Barview",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.35428000",
    longitude: "-124.31317000",
  },
  {
    id: 111923,
    name: "Bay City",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.52260000",
    longitude: "-123.88930000",
  },
  {
    id: 112005,
    name: "Beavercreek",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.28790000",
    longitude: "-122.53564000",
  },
  {
    id: 112010,
    name: "Beaverton",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.48706000",
    longitude: "-122.80371000",
  },
  {
    id: 112166,
    name: "Bend",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.05817000",
    longitude: "-121.31531000",
  },
  {
    id: 112206,
    name: "Benton County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.49176000",
    longitude: "-123.42931000",
  },
  {
    id: 112276,
    name: "Bethany",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.55789000",
    longitude: "-122.86760000",
  },
  {
    id: 112489,
    name: "Boardman",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.83986000",
    longitude: "-119.70058000",
  },
  {
    id: 112862,
    name: "Brookings",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "42.05261000",
    longitude: "-124.28398000",
  },
  {
    id: 112935,
    name: "Brownsville",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.39346000",
    longitude: "-122.98481000",
  },
  {
    id: 113041,
    name: "Bunker Hill",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.35595000",
    longitude: "-124.20483000",
  },
  {
    id: 113087,
    name: "Burns",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.58626000",
    longitude: "-119.05410000",
  },
  {
    id: 113322,
    name: "Canby",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.26290000",
    longitude: "-122.69259000",
  },
  {
    id: 113332,
    name: "Cannon Beach",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.89177000",
    longitude: "-123.96153000",
  },
  {
    id: 113355,
    name: "Canyon City",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.38960000",
    longitude: "-118.95023000",
  },
  {
    id: 113361,
    name: "Canyonville",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "42.92734000",
    longitude: "-123.28117000",
  },
  {
    id: 113412,
    name: "Carlton",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.29428000",
    longitude: "-123.17649000",
  },
  {
    id: 113516,
    name: "Cascade Locks",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.66984000",
    longitude: "-121.89064000",
  },
  {
    id: 113586,
    name: "Cave Junction",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "42.16289000",
    longitude: "-123.64812000",
  },
  {
    id: 113615,
    name: "Cedar Hills",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.50484000",
    longitude: "-122.79843000",
  },
  {
    id: 113618,
    name: "Cedar Mill",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.52484000",
    longitude: "-122.81093000",
  },
  {
    id: 113680,
    name: "Central Point",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "42.37596000",
    longitude: "-122.91643000",
  },
  {
    id: 113842,
    name: "Chenoweth",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.62762000",
    longitude: "-121.24341000",
  },
  {
    id: 114090,
    name: "Clackamas",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.40762000",
    longitude: "-122.57037000",
  },
  {
    id: 114091,
    name: "Clackamas County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.18816000",
    longitude: "-122.22094000",
  },
  {
    id: 114160,
    name: "Clatskanie",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "46.10122000",
    longitude: "-123.20679000",
  },
  {
    id: 114161,
    name: "Clatsop County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "46.01747000",
    longitude: "-123.71677000",
  },
  {
    id: 114348,
    name: "Coburg",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.13707000",
    longitude: "-123.06648000",
  },
  {
    id: 114476,
    name: "Columbia City",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.89011000",
    longitude: "-122.80705000",
  },
  {
    id: 114483,
    name: "Columbia County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.94377000",
    longitude: "-123.08805000",
  },
  {
    id: 114549,
    name: "Condon",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.23430000",
    longitude: "-120.18503000",
  },
  {
    id: 114610,
    name: "Coos Bay",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.36650000",
    longitude: "-124.21789000",
  },
  {
    id: 114612,
    name: "Coos County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.18501000",
    longitude: "-124.09333000",
  },
  {
    id: 114623,
    name: "Coquille",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.17705000",
    longitude: "-124.18761000",
  },
  {
    id: 114648,
    name: "Cornelius",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.51984000",
    longitude: "-123.05983000",
  },
  {
    id: 114680,
    name: "Corvallis",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.56457000",
    longitude: "-123.26204000",
  },
  {
    id: 114693,
    name: "Cottage Grove",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.79762000",
    longitude: "-123.05952000",
  },
  {
    id: 114813,
    name: "Creswell",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.91790000",
    longitude: "-123.02453000",
  },
  {
    id: 114836,
    name: "Crook County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.14219000",
    longitude: "-120.35658000",
  },
  {
    id: 114899,
    name: "Culp Creek",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.70346000",
    longitude: "-122.84757000",
  },
  {
    id: 114903,
    name: "Culver",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.52568000",
    longitude: "-121.21310000",
  },
  {
    id: 114928,
    name: "Curry County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "42.46661000",
    longitude: "-124.21534000",
  },
  {
    id: 114992,
    name: "Dallas",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.91928000",
    longitude: "-123.31705000",
  },
  {
    id: 115010,
    name: "Damascus",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.41762000",
    longitude: "-122.45898000",
  },
  {
    id: 115099,
    name: "Dayton",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.22067000",
    longitude: "-123.07621000",
  },
  {
    id: 115257,
    name: "Depoe Bay",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.80845000",
    longitude: "-124.06317000",
  },
  {
    id: 115277,
    name: "Deschutes County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.91488000",
    longitude: "-121.22789000",
  },
  {
    id: 115278,
    name: "Deschutes River Woods",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.99151000",
    longitude: "-121.35836000",
  },
  {
    id: 115393,
    name: "Donald",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.22234000",
    longitude: "-122.83926000",
  },
  {
    id: 115435,
    name: "Douglas County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.28536000",
    longitude: "-123.17942000",
  },
  {
    id: 115467,
    name: "Drain",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.65873000",
    longitude: "-123.31870000",
  },
  {
    id: 115531,
    name: "Dundee",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.27817000",
    longitude: "-123.01094000",
  },
  {
    id: 115536,
    name: "Dunes City",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.88318000",
    longitude: "-124.11512000",
  },
  {
    id: 115574,
    name: "Durham",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.40206000",
    longitude: "-122.75287000",
  },
  {
    id: 115609,
    name: "Eagle Point",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "42.47263000",
    longitude: "-122.80282000",
  },
  {
    id: 115968,
    name: "Elgin",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.56486000",
    longitude: "-117.91743000",
  },
  {
    id: 116150,
    name: "Enterprise",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.42626000",
    longitude: "-117.27878000",
  },
  {
    id: 116202,
    name: "Estacada",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.28957000",
    longitude: "-122.33370000",
  },
  {
    id: 116226,
    name: "Eugene",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.05207000",
    longitude: "-123.08675000",
  },
  {
    id: 116356,
    name: "Fairview",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.53845000",
    longitude: "-122.43398000",
  },
  {
    id: 116586,
    name: "Florence",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.98262000",
    longitude: "-124.09984000",
  },
  {
    id: 116653,
    name: "Forest Grove",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.51984000",
    longitude: "-123.11066000",
  },
  {
    id: 116772,
    name: "Fossil",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.99819000",
    longitude: "-120.21614000",
  },
  {
    id: 116789,
    name: "Four Corners",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.92790000",
    longitude: "-122.98371000",
  },
  {
    id: 116969,
    name: "Fruitdale",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "42.42206000",
    longitude: "-123.30811000",
  },
  {
    id: 117066,
    name: "Garden Home-Whitford",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.46400000",
    longitude: "-122.75891000",
  },
  {
    id: 117129,
    name: "Gearhart",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "46.02427000",
    longitude: "-123.91125000",
  },
  {
    id: 117178,
    name: "Gervais",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.10818000",
    longitude: "-122.89760000",
  },
  {
    id: 117218,
    name: "Gilliam County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.37806000",
    longitude: "-120.21087000",
  },
  {
    id: 117239,
    name: "Gladstone",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.38068000",
    longitude: "-122.59481000",
  },
  {
    id: 117320,
    name: "Glide",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.30151000",
    longitude: "-123.10118000",
  },
  {
    id: 117340,
    name: "Gold Beach",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "42.40733000",
    longitude: "-124.42177000",
  },
  {
    id: 117343,
    name: "Gold Hill",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "42.43179000",
    longitude: "-123.05060000",
  },
  {
    id: 117474,
    name: "Grand Ronde",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.06011000",
    longitude: "-123.60928000",
  },
  {
    id: 117520,
    name: "Grant County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.49125000",
    longitude: "-119.00738000",
  },
  {
    id: 117528,
    name: "Grants Pass",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "42.43933000",
    longitude: "-123.33067000",
  },
  {
    id: 117589,
    name: "Green",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.16039000",
    longitude: "-123.36785000",
  },
  {
    id: 117722,
    name: "Gresham",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.49818000",
    longitude: "-122.43148000",
  },
  {
    id: 117983,
    name: "Happy Valley",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.44679000",
    longitude: "-122.53037000",
  },
  {
    id: 117987,
    name: "Harbor",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "42.05317000",
    longitude: "-124.26759000",
  },
  {
    id: 118030,
    name: "Harney County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.06402000",
    longitude: "-118.96787000",
  },
  {
    id: 118053,
    name: "Harrisburg",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.27401000",
    longitude: "-123.17065000",
  },
  {
    id: 118183,
    name: "Hayesville",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.98595000",
    longitude: "-122.98287000",
  },
  {
    id: 118310,
    name: "Heppner",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.35318000",
    longitude: "-119.55780000",
  },
  {
    id: 118327,
    name: "Hermiston",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.84041000",
    longitude: "-119.28946000",
  },
  {
    id: 118452,
    name: "Hillsboro",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.52289000",
    longitude: "-122.98983000",
  },
  {
    id: 118480,
    name: "Hines",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.56404000",
    longitude: "-119.08105000",
  },
  {
    id: 118626,
    name: "Hood River",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.70540000",
    longitude: "-121.52146000",
  },
  {
    id: 118627,
    name: "Hood River County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.51911000",
    longitude: "-121.65111000",
  },
  {
    id: 118731,
    name: "Hubbard",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.18234000",
    longitude: "-122.80787000",
  },
  {
    id: 118897,
    name: "Independence",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.85123000",
    longitude: "-123.18677000",
  },
  {
    id: 119002,
    name: "Irrigon",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.89569000",
    longitude: "-119.49141000",
  },
  {
    id: 119023,
    name: "Island City",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.34097000",
    longitude: "-118.04466000",
  },
  {
    id: 119095,
    name: "Jackson County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "42.43215000",
    longitude: "-122.72843000",
  },
  {
    id: 119105,
    name: "Jacksonville",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "42.31346000",
    longitude: "-122.96699000",
  },
  {
    id: 119172,
    name: "Jefferson",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.71957000",
    longitude: "-123.01037000",
  },
  {
    id: 119197,
    name: "Jefferson County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.62941000",
    longitude: "-121.17541000",
  },
  {
    id: 119221,
    name: "Jennings Lodge",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.39123000",
    longitude: "-122.61259000",
  },
  {
    id: 119251,
    name: "John Day",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.41599000",
    longitude: "-118.95301000",
  },
  {
    id: 119318,
    name: "Joseph",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.35432000",
    longitude: "-117.22961000",
  },
  {
    id: 119321,
    name: "Josephine County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "42.36546000",
    longitude: "-123.55558000",
  },
  {
    id: 119335,
    name: "Junction City",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.21929000",
    longitude: "-123.20565000",
  },
  {
    id: 119426,
    name: "Keizer",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.99012000",
    longitude: "-123.02621000",
  },
  {
    id: 119498,
    name: "Kenton",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.58178000",
    longitude: "-122.68149000",
  },
  {
    id: 119580,
    name: "King City",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.40262000",
    longitude: "-122.80399000",
  },
  {
    id: 119670,
    name: "Klamath County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "42.68634000",
    longitude: "-121.65013000",
  },
  {
    id: 119671,
    name: "Klamath Falls",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "42.22487000",
    longitude: "-121.78167000",
  },
  {
    id: 119748,
    name: "La Grande",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.32458000",
    longitude: "-118.08772000",
  },
  {
    id: 119772,
    name: "La Pine",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.67040000",
    longitude: "-121.50364000",
  },
  {
    id: 119837,
    name: "Lafayette",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.24428000",
    longitude: "-123.11483000",
  },
  {
    id: 119899,
    name: "Lake County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "42.79339000",
    longitude: "-120.38747000",
  },
  {
    id: 119955,
    name: "Lake Oswego",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.42067000",
    longitude: "-122.67065000",
  },
  {
    id: 120034,
    name: "Lakeside",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.57567000",
    longitude: "-124.17511000",
  },
  {
    id: 120041,
    name: "Lakeview",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "42.18877000",
    longitude: "-120.34579000",
  },
  {
    id: 120106,
    name: "Lane County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.94155000",
    longitude: "-122.87655000",
  },
  {
    id: 120294,
    name: "Lebanon",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.53651000",
    longitude: "-122.90703000",
  },
  {
    id: 120377,
    name: "Lents",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.47984000",
    longitude: "-122.56731000",
  },
  {
    id: 120514,
    name: "Lincoln Beach",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.85039000",
    longitude: "-124.04678000",
  },
  {
    id: 120515,
    name: "Lincoln City",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.95816000",
    longitude: "-124.01789000",
  },
  {
    id: 120536,
    name: "Lincoln County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.64568000",
    longitude: "-123.90770000",
  },
  {
    id: 120584,
    name: "Linn County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.48857000",
    longitude: "-122.53419000",
  },
  {
    id: 120838,
    name: "Lowell",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.91846000",
    longitude: "-122.78368000",
  },
  {
    id: 120944,
    name: "Lyons",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.77457000",
    longitude: "-122.61509000",
  },
  {
    id: 121028,
    name: "Madras",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.63345000",
    longitude: "-121.12949000",
  },
  {
    id: 121061,
    name: "Malheur County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.19341000",
    longitude: "-117.62307000",
  },
  {
    id: 121262,
    name: "Marion County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.90319000",
    longitude: "-122.58473000",
  },
  {
    id: 121541,
    name: "McMinnville",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.21012000",
    longitude: "-123.19872000",
  },
  {
    id: 121596,
    name: "Medford",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "42.32652000",
    longitude: "-122.87559000",
  },
  {
    id: 121701,
    name: "Merlin",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "42.51734000",
    longitude: "-123.41979000",
  },
  {
    id: 121739,
    name: "Metzger",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.44651000",
    longitude: "-122.75899000",
  },
  {
    id: 121862,
    name: "Mill City",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.75401000",
    longitude: "-122.47814000",
  },
  {
    id: 121887,
    name: "Millersburg",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.68095000",
    longitude: "-123.06148000",
  },
  {
    id: 121922,
    name: "Milton-Freewater",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.93263000",
    longitude: "-118.38774000",
  },
  {
    id: 121925,
    name: "Milwaukie",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.44623000",
    longitude: "-122.63926000",
  },
  {
    id: 121981,
    name: "Mission",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.67041000",
    longitude: "-118.68359000",
  },
  {
    id: 122026,
    name: "Molalla",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.14734000",
    longitude: "-122.57703000",
  },
  {
    id: 122044,
    name: "Monmouth",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.84845000",
    longitude: "-123.23399000",
  },
  {
    id: 122259,
    name: "Moro",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.48401000",
    longitude: "-120.73117000",
  },
  {
    id: 122290,
    name: "Morrow County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.41883000",
    longitude: "-119.58430000",
  },
  {
    id: 122332,
    name: "Mount Angel",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.06790000",
    longitude: "-122.80009000",
  },
  {
    id: 122352,
    name: "Mount Hood Village",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.35540000",
    longitude: "-121.98064000",
  },
  {
    id: 122458,
    name: "Mulino",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.22151000",
    longitude: "-122.58203000",
  },
  {
    id: 122463,
    name: "Multnomah County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.54687000",
    longitude: "-122.41534000",
  },
  {
    id: 122521,
    name: "Myrtle Creek",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.02012000",
    longitude: "-123.29312000",
  },
  {
    id: 122524,
    name: "Myrtle Point",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.06483000",
    longitude: "-124.13899000",
  },
  {
    id: 122728,
    name: "New Hope",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "42.36234000",
    longitude: "-123.36784000",
  },
  {
    id: 122808,
    name: "Newberg",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.30012000",
    longitude: "-122.97316000",
  },
  {
    id: 122851,
    name: "Newport",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.63678000",
    longitude: "-124.05345000",
  },
  {
    id: 122984,
    name: "North Bend",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.40650000",
    longitude: "-124.22428000",
  },
  {
    id: 123081,
    name: "North Plains",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.59706000",
    longitude: "-122.99344000",
  },
  {
    id: 123086,
    name: "North Portland",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.61039000",
    longitude: "-122.70343000",
  },
  {
    id: 123223,
    name: "Nyssa",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.87683000",
    longitude: "-116.99488000",
  },
  {
    id: 123240,
    name: "Oak Grove",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.41679000",
    longitude: "-122.64009000",
  },
  {
    id: 123249,
    name: "Oak Hills",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.54123000",
    longitude: "-122.84121000",
  },
  {
    id: 123304,
    name: "Oakridge",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.74651000",
    longitude: "-122.46172000",
  },
  {
    id: 123314,
    name: "Oatfield",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.41418000",
    longitude: "-122.60007000",
  },
  {
    id: 123354,
    name: "Odell",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.62706000",
    longitude: "-121.54313000",
  },
  {
    id: 123487,
    name: "Ontario",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.02655000",
    longitude: "-116.96294000",
  },
  {
    id: 123548,
    name: "Oregon City",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.35734000",
    longitude: "-122.60676000",
  },
  {
    id: 123708,
    name: "Pacific City",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.20233000",
    longitude: "-123.96289000",
  },
  {
    id: 124018,
    name: "Pendleton",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.67207000",
    longitude: "-118.78860000",
  },
  {
    id: 124144,
    name: "Philomath",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.54012000",
    longitude: "-123.36760000",
  },
  {
    id: 124149,
    name: "Phoenix",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "42.27541000",
    longitude: "-122.81809000",
  },
  {
    id: 124202,
    name: "Pilot Rock",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.48318000",
    longitude: "-118.82998000",
  },
  {
    id: 124451,
    name: "Polk County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.90348000",
    longitude: "-123.41337000",
  },
  {
    id: 124526,
    name: "Port Orford",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "42.74566000",
    longitude: "-124.49733000",
  },
  {
    id: 124565,
    name: "Portland",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.52345000",
    longitude: "-122.67621000",
  },
  {
    id: 124697,
    name: "Prineville",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.29985000",
    longitude: "-120.83447000",
  },
  {
    id: 124826,
    name: "Rainier",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "46.08900000",
    longitude: "-122.93594000",
  },
  {
    id: 124833,
    name: "Raleigh Hills",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.48067000",
    longitude: "-122.76204000",
  },
  {
    id: 124978,
    name: "Redmond",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.27262000",
    longitude: "-121.17392000",
  },
  {
    id: 124985,
    name: "Redwood",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "42.42206000",
    longitude: "-123.38728000",
  },
  {
    id: 124995,
    name: "Reedsport",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.70234000",
    longitude: "-124.09678000",
  },
  {
    id: 125113,
    name: "Riddle",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "42.95095000",
    longitude: "-123.36423000",
  },
  {
    id: 125290,
    name: "Rockaway Beach",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.61344000",
    longitude: "-123.94291000",
  },
  {
    id: 125294,
    name: "Rockcreek",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.55012000",
    longitude: "-122.87705000",
  },
  {
    id: 125357,
    name: "Rogue River",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "42.43595000",
    longitude: "-123.17200000",
  },
  {
    id: 125406,
    name: "Rose Lodge",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.01039000",
    longitude: "-123.88039000",
  },
  {
    id: 125414,
    name: "Roseburg",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.21650000",
    longitude: "-123.34174000",
  },
  {
    id: 125415,
    name: "Roseburg North",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.26452000",
    longitude: "-123.30331000",
  },
  {
    id: 125649,
    name: "Saint Helens",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.86400000",
    longitude: "-122.80649000",
  },
  {
    id: 125740,
    name: "Salem",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.94290000",
    longitude: "-123.03510000",
  },
  {
    id: 125880,
    name: "Sandy",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.39734000",
    longitude: "-122.26148000",
  },
  {
    id: 125996,
    name: "Scappoose",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.75428000",
    longitude: "-122.87760000",
  },
  {
    id: 126100,
    name: "Seaside",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.99316000",
    longitude: "-123.92264000",
  },
  {
    id: 126203,
    name: "Shady Cove",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "42.61068000",
    longitude: "-122.81254000",
  },
  {
    id: 126322,
    name: "Sheridan",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.09928000",
    longitude: "-123.39483000",
  },
  {
    id: 126335,
    name: "Sherman County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.40521000",
    longitude: "-120.68932000",
  },
  {
    id: 126342,
    name: "Sherwood",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.35651000",
    longitude: "-122.84010000",
  },
  {
    id: 126416,
    name: "Siletz",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.72178000",
    longitude: "-123.92011000",
  },
  {
    id: 126442,
    name: "Silverton",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.00512000",
    longitude: "-122.78315000",
  },
  {
    id: 126468,
    name: "Sisters",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.29095000",
    longitude: "-121.54921000",
  },
  {
    id: 126688,
    name: "South Lebanon",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.50623000",
    longitude: "-122.90314000",
  },
  {
    id: 126907,
    name: "Springfield",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.04624000",
    longitude: "-123.02203000",
  },
  {
    id: 126935,
    name: "Stafford",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.35734000",
    longitude: "-122.72259000",
  },
  {
    id: 126952,
    name: "Stanfield",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.78041000",
    longitude: "-119.21724000",
  },
  {
    id: 127002,
    name: "Stayton",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.80068000",
    longitude: "-122.79453000",
  },
  {
    id: 127159,
    name: "Sublimity",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.82957000",
    longitude: "-122.79453000",
  },
  {
    id: 127270,
    name: "Sunriver",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.88401000",
    longitude: "-121.43864000",
  },
  {
    id: 127307,
    name: "Sutherlin",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.39012000",
    longitude: "-123.31258000",
  },
  {
    id: 127337,
    name: "Sweet Home",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.39762000",
    longitude: "-122.73620000",
  },
  {
    id: 127386,
    name: "Talent",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "42.24568000",
    longitude: "-122.78865000",
  },
  {
    id: 127413,
    name: "Tangent",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.54123000",
    longitude: "-123.10815000",
  },
  {
    id: 127524,
    name: "Terrebonne",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.35290000",
    longitude: "-121.17781000",
  },
  {
    id: 127557,
    name: "The Dalles",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.59456000",
    longitude: "-121.17868000",
  },
  {
    id: 127621,
    name: "Three Rivers",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.82012000",
    longitude: "-121.46919000",
  },
  {
    id: 127643,
    name: "Tigard",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.43123000",
    longitude: "-122.77149000",
  },
  {
    id: 127648,
    name: "Tillamook",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.45640000",
    longitude: "-123.84553000",
  },
  {
    id: 127649,
    name: "Tillamook County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.45645000",
    longitude: "-123.75877000",
  },
  {
    id: 127700,
    name: "Toledo",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.62151000",
    longitude: "-123.93845000",
  },
  {
    id: 127816,
    name: "Tri-City",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "42.98456000",
    longitude: "-123.31173000",
  },
  {
    id: 127838,
    name: "Troutdale",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.53929000",
    longitude: "-122.38731000",
  },
  {
    id: 127865,
    name: "Tualatin",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.38401000",
    longitude: "-122.76399000",
  },
  {
    id: 127902,
    name: "Turner",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.84318000",
    longitude: "-122.95287000",
  },
  {
    id: 127962,
    name: "Umatilla",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.91735000",
    longitude: "-119.34252000",
  },
  {
    id: 127963,
    name: "Umatilla County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.59190000",
    longitude: "-118.73683000",
  },
  {
    id: 127978,
    name: "Union",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.20847000",
    longitude: "-117.86521000",
  },
  {
    id: 128003,
    name: "Union County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.31035000",
    longitude: "-118.00900000",
  },
  {
    id: 128086,
    name: "Vale",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.98211000",
    longitude: "-117.23823000",
  },
  {
    id: 128168,
    name: "Veneta",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.04873000",
    longitude: "-123.35093000",
  },
  {
    id: 128197,
    name: "Vernonia",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.85872000",
    longitude: "-123.19289000",
  },
  {
    id: 128376,
    name: "Waldport",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.42679000",
    longitude: "-124.06873000",
  },
  {
    id: 128411,
    name: "Wallowa County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.57983000",
    longitude: "-117.18107000",
  },
  {
    id: 128471,
    name: "Warm Springs",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.76345000",
    longitude: "-121.26616000",
  },
  {
    id: 128485,
    name: "Warren",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.81900000",
    longitude: "-122.84899000",
  },
  {
    id: 128508,
    name: "Warrenton",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "46.16510000",
    longitude: "-123.92376000",
  },
  {
    id: 128529,
    name: "Wasco County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.16005000",
    longitude: "-121.16815000",
  },
  {
    id: 128580,
    name: "Washington County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.56007000",
    longitude: "-123.09849000",
  },
  {
    id: 128889,
    name: "West Haven",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.51762000",
    longitude: "-122.76954000",
  },
  {
    id: 128890,
    name: "West Haven-Sylvan",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.51613000",
    longitude: "-122.76809000",
  },
  {
    id: 128919,
    name: "West Linn",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.36568000",
    longitude: "-122.61231000",
  },
  {
    id: 128983,
    name: "West Slope",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.49873000",
    longitude: "-122.76454000",
  },
  {
    id: 129119,
    name: "Wheeler County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "44.72606000",
    longitude: "-120.02746000",
  },
  {
    id: 129131,
    name: "White City",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "42.43735000",
    longitude: "-122.85893000",
  },
  {
    id: 129256,
    name: "Willamina",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.07873000",
    longitude: "-123.48594000",
  },
  {
    id: 129262,
    name: "Williams",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "42.21873000",
    longitude: "-123.27394000",
  },
  {
    id: 129341,
    name: "Wilsonville",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.29984000",
    longitude: "-122.77371000",
  },
  {
    id: 129433,
    name: "Winston",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.12234000",
    longitude: "-123.41257000",
  },
  {
    id: 129490,
    name: "Wood Village",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.53429000",
    longitude: "-122.41870000",
  },
  {
    id: 129504,
    name: "Woodburn",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.14373000",
    longitude: "-122.85537000",
  },
  {
    id: 129657,
    name: "Yamhill",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.34150000",
    longitude: "-123.18733000",
  },
  {
    id: 129658,
    name: "Yamhill County",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "45.23260000",
    longitude: "-123.30815000",
  },
  {
    id: 129687,
    name: "Yoncalla",
    state_id: 1415,
    state_code: "OR",
    country_id: 233,
    country_code: "US",
    latitude: "43.59845000",
    longitude: "-123.28342000",
  },
  {
    id: 110971,
    name: "Abbottstown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.88649000",
    longitude: "-76.98470000",
  },
  {
    id: 111019,
    name: "Adams County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.87145000",
    longitude: "-77.21789000",
  },
  {
    id: 111030,
    name: "Adamstown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.24120000",
    longitude: "-76.05633000",
  },
  {
    id: 111079,
    name: "Akron",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.15676000",
    longitude: "-76.20217000",
  },
  {
    id: 111129,
    name: "Albion",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.89061000",
    longitude: "-80.36645000",
  },
  {
    id: 111131,
    name: "Alburtis",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.51093000",
    longitude: "-75.60297000",
  },
  {
    id: 111135,
    name: "Aldan",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.92150000",
    longitude: "-75.28796000",
  },
  {
    id: 111174,
    name: "Aliquippa",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.63673000",
    longitude: "-80.24006000",
  },
  {
    id: 111185,
    name: "Allegheny County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.46883000",
    longitude: "-79.98119000",
  },
  {
    id: 111186,
    name: "Alleghenyville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.23426000",
    longitude: "-75.98855000",
  },
  {
    id: 111199,
    name: "Allentown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.60843000",
    longitude: "-75.49018000",
  },
  {
    id: 111202,
    name: "Allison Park",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.55951000",
    longitude: "-79.95867000",
  },
  {
    id: 111212,
    name: "Almedia",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.01453000",
    longitude: "-76.38105000",
  },
  {
    id: 111248,
    name: "Altoona",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.51868000",
    longitude: "-78.39474000",
  },
  {
    id: 111264,
    name: "Ambler",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.15455000",
    longitude: "-75.22157000",
  },
  {
    id: 111267,
    name: "Ambridge",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.58923000",
    longitude: "-80.22506000",
  },
  {
    id: 111291,
    name: "Amity Gardens",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.27426000",
    longitude: "-75.73519000",
  },
  {
    id: 111307,
    name: "Ancient Oaks",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.54732000",
    longitude: "-75.58935000",
  },
  {
    id: 111357,
    name: "Annville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.32954000",
    longitude: "-76.51524000",
  },
  {
    id: 111387,
    name: "Apollo",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.58145000",
    longitude: "-79.56643000",
  },
  {
    id: 111427,
    name: "Archbald",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.49480000",
    longitude: "-75.53685000",
  },
  {
    id: 111443,
    name: "Ardmore",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.00678000",
    longitude: "-75.28546000",
  },
  {
    id: 111473,
    name: "Arlington Heights",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.99009000",
    longitude: "-75.21629000",
  },
  {
    id: 111480,
    name: "Armstrong County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.81229000",
    longitude: "-79.46454000",
  },
  {
    id: 111487,
    name: "Arnold",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.58007000",
    longitude: "-79.76672000",
  },
  {
    id: 111537,
    name: "Ashland",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.78175000",
    longitude: "-76.34578000",
  },
  {
    id: 111544,
    name: "Ashley",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.21036000",
    longitude: "-75.89659000",
  },
  {
    id: 111555,
    name: "Aspinwall",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.49146000",
    longitude: "-79.90477000",
  },
  {
    id: 111571,
    name: "Atglen",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.94927000",
    longitude: "-75.97356000",
  },
  {
    id: 111580,
    name: "Athens",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.95730000",
    longitude: "-76.51800000",
  },
  {
    id: 111640,
    name: "Audubon",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.12788000",
    longitude: "-75.43185000",
  },
  {
    id: 111674,
    name: "Avalon",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.50090000",
    longitude: "-80.06756000",
  },
  {
    id: 111685,
    name: "Avis",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.18479000",
    longitude: "-77.31386000",
  },
  {
    id: 111688,
    name: "Avoca",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.33980000",
    longitude: "-75.73630000",
  },
  {
    id: 111694,
    name: "Avon",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.34565000",
    longitude: "-76.38996000",
  },
  {
    id: 111699,
    name: "Avondale",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.82344000",
    longitude: "-75.78327000",
  },
  {
    id: 111703,
    name: "Avonia",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "42.04561000",
    longitude: "-80.26979000",
  },
  {
    id: 111718,
    name: "Back Mountain",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.33591000",
    longitude: "-75.99631000",
  },
  {
    id: 111723,
    name: "Baden",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.63507000",
    longitude: "-80.22812000",
  },
  {
    id: 111729,
    name: "Baidland",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.19479000",
    longitude: "-79.97088000",
  },
  {
    id: 111734,
    name: "Bainbridge",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.09093000",
    longitude: "-76.66747000",
  },
  {
    id: 111745,
    name: "Bakerstown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.65090000",
    longitude: "-79.93644000",
  },
  {
    id: 111748,
    name: "Bala-Cynwyd",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.00761000",
    longitude: "-75.23407000",
  },
  {
    id: 111757,
    name: "Baldwin",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.33813000",
    longitude: "-79.97894000",
  },
  {
    id: 111777,
    name: "Bally",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.40232000",
    longitude: "-75.58713000",
  },
  {
    id: 111793,
    name: "Bangor",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.86565000",
    longitude: "-75.20657000",
  },
  {
    id: 111828,
    name: "Barnesboro",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.66257000",
    longitude: "-78.78003000",
  },
  {
    id: 111899,
    name: "Bath",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.72565000",
    longitude: "-75.39407000",
  },
  {
    id: 111978,
    name: "Bear Rocks",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.12285000",
    longitude: "-79.46170000",
  },
  {
    id: 111993,
    name: "Beaver",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.69534000",
    longitude: "-80.30478000",
  },
  {
    id: 111997,
    name: "Beaver County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.68226000",
    longitude: "-80.34929000",
  },
  {
    id: 112004,
    name: "Beaver Falls",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.75201000",
    longitude: "-80.31923000",
  },
  {
    id: 112006,
    name: "Beaverdale",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.32202000",
    longitude: "-78.69696000",
  },
  {
    id: 112026,
    name: "Bedford",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.01869000",
    longitude: "-78.50391000",
  },
  {
    id: 112029,
    name: "Bedford County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.00656000",
    longitude: "-78.49032000",
  },
  {
    id: 112032,
    name: "Bedminster",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.42594000",
    longitude: "-75.17906000",
  },
  {
    id: 112038,
    name: "Beech Mountain Lakes",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.04158000",
    longitude: "-75.93545000",
  },
  {
    id: 112057,
    name: "Belfast",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.78065000",
    longitude: "-75.27796000",
  },
  {
    id: 112067,
    name: "Bell Acres",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.59007000",
    longitude: "-80.16645000",
  },
  {
    id: 112093,
    name: "Belle Vernon",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.12507000",
    longitude: "-79.86644000",
  },
  {
    id: 112099,
    name: "Bellefonte",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.91339000",
    longitude: "-77.77833000",
  },
  {
    id: 112107,
    name: "Belleville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.60507000",
    longitude: "-77.72555000",
  },
  {
    id: 112114,
    name: "Bellevue",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.49396000",
    longitude: "-80.05172000",
  },
  {
    id: 112132,
    name: "Bellwood",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.60340000",
    longitude: "-78.32474000",
  },
  {
    id: 112141,
    name: "Belmont",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.28730000",
    longitude: "-78.88947000",
  },
  {
    id: 112161,
    name: "Ben Avon",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.50812000",
    longitude: "-80.08311000",
  },
  {
    id: 112190,
    name: "Bentleyville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.11674000",
    longitude: "-80.00839000",
  },
  {
    id: 112232,
    name: "Berks County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.41630000",
    longitude: "-75.92600000",
  },
  {
    id: 112236,
    name: "Berlin",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.92064000",
    longitude: "-78.95780000",
  },
  {
    id: 112263,
    name: "Berwick",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.05453000",
    longitude: "-76.23327000",
  },
  {
    id: 112265,
    name: "Berwyn",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.04483000",
    longitude: "-75.43881000",
  },
  {
    id: 112269,
    name: "Bessemer",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.97478000",
    longitude: "-80.49368000",
  },
  {
    id: 112287,
    name: "Bethel Park",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.32757000",
    longitude: "-80.03950000",
  },
  {
    id: 112291,
    name: "Bethlehem",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.62593000",
    longitude: "-75.37046000",
  },
  {
    id: 112314,
    name: "Big Bass Lake",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.25383000",
    longitude: "-75.47644000",
  },
  {
    id: 112317,
    name: "Big Beaver",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.82451000",
    longitude: "-80.36284000",
  },
  {
    id: 112341,
    name: "Biglerville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.93037000",
    longitude: "-77.24804000",
  },
  {
    id: 112356,
    name: "Birchwood Lakes",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.25454000",
    longitude: "-74.91850000",
  },
  {
    id: 112357,
    name: "Birdsboro",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.26454000",
    longitude: "-75.80409000",
  },
  {
    id: 112379,
    name: "Black Lick",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.47250000",
    longitude: "-79.18688000",
  },
  {
    id: 112411,
    name: "Blair County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.48100000",
    longitude: "-78.34860000",
  },
  {
    id: 112413,
    name: "Blairsville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.43118000",
    longitude: "-79.26087000",
  },
  {
    id: 112415,
    name: "Blakely",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.48091000",
    longitude: "-75.59463000",
  },
  {
    id: 112423,
    name: "Blandon",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.44120000",
    longitude: "-75.88687000",
  },
  {
    id: 112426,
    name: "Blawnox",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.49340000",
    longitude: "-79.86061000",
  },
  {
    id: 112439,
    name: "Bloomfield",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.46090000",
    longitude: "-79.95089000",
  },
  {
    id: 112454,
    name: "Bloomsburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.00370000",
    longitude: "-76.45495000",
  },
  {
    id: 112455,
    name: "Blossburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.67952000",
    longitude: "-77.06386000",
  },
  {
    id: 112463,
    name: "Blue Ball",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.11871000",
    longitude: "-76.04717000",
  },
  {
    id: 112464,
    name: "Blue Bell",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.15233000",
    longitude: "-75.26629000",
  },
  {
    id: 112488,
    name: "Boalsburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.77562000",
    longitude: "-77.79250000",
  },
  {
    id: 112507,
    name: "Boiling Springs",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.14981000",
    longitude: "-77.12831000",
  },
  {
    id: 112541,
    name: "Bonneauville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.81204000",
    longitude: "-77.13721000",
  },
  {
    id: 112574,
    name: "Boothwyn",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.83011000",
    longitude: "-75.44158000",
  },
  {
    id: 112592,
    name: "Boswell",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.16147000",
    longitude: "-79.02892000",
  },
  {
    id: 112634,
    name: "Bowmansville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.19676000",
    longitude: "-76.01744000",
  },
  {
    id: 112645,
    name: "Boyertown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.33371000",
    longitude: "-75.63741000",
  },
  {
    id: 112656,
    name: "Brackenridge",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.60812000",
    longitude: "-79.74116000",
  },
  {
    id: 112659,
    name: "Braddock",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.40340000",
    longitude: "-79.86838000",
  },
  {
    id: 112661,
    name: "Braddock Hills",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.41729000",
    longitude: "-79.86505000",
  },
  {
    id: 112666,
    name: "Bradford",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.95590000",
    longitude: "-78.64392000",
  },
  {
    id: 112669,
    name: "Bradford County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.78867000",
    longitude: "-76.51545000",
  },
  {
    id: 112670,
    name: "Bradford Woods",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.63757000",
    longitude: "-80.08172000",
  },
  {
    id: 112719,
    name: "Breinigsville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.53676000",
    longitude: "-75.63130000",
  },
  {
    id: 112732,
    name: "Brentwood",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.37063000",
    longitude: "-79.97477000",
  },
  {
    id: 112735,
    name: "Bressler",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.22953000",
    longitude: "-76.81997000",
  },
  {
    id: 112754,
    name: "Brickerville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.22593000",
    longitude: "-76.30246000",
  },
  {
    id: 112764,
    name: "Bridgeport",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.10511000",
    longitude: "-75.34518000",
  },
  {
    id: 112773,
    name: "Bridgeville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.35618000",
    longitude: "-80.11006000",
  },
  {
    id: 112808,
    name: "Bristol",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.10067000",
    longitude: "-74.85183000",
  },
  {
    id: 112818,
    name: "Brittany Farms-Highlands",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.26901000",
    longitude: "-75.21401000",
  },
  {
    id: 112835,
    name: "Brockway",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.24923000",
    longitude: "-78.79947000",
  },
  {
    id: 112839,
    name: "Brodheadsville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.92454000",
    longitude: "-75.39379000",
  },
  {
    id: 112858,
    name: "Brookhaven",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.86928000",
    longitude: "-75.38241000",
  },
  {
    id: 112896,
    name: "Brookville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.16117000",
    longitude: "-79.08309000",
  },
  {
    id: 112898,
    name: "Broomall",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.98150000",
    longitude: "-75.35658000",
  },
  {
    id: 112927,
    name: "Brownstown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.12371000",
    longitude: "-76.21384000",
  },
  {
    id: 112934,
    name: "Brownsville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.02369000",
    longitude: "-79.88394000",
  },
  {
    id: 112936,
    name: "Browntown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.30980000",
    longitude: "-75.78742000",
  },
  {
    id: 112965,
    name: "Bryn Athyn",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.13150000",
    longitude: "-75.06739000",
  },
  {
    id: 112966,
    name: "Bryn Mawr",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.01983000",
    longitude: "-75.30463000",
  },
  {
    id: 112991,
    name: "Bucks County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.33694000",
    longitude: "-75.10687000",
  },
  {
    id: 113051,
    name: "Burgettstown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.38201000",
    longitude: "-80.39284000",
  },
  {
    id: 113085,
    name: "Burnham",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.63868000",
    longitude: "-77.56861000",
  },
  {
    id: 113111,
    name: "Butler",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.86118000",
    longitude: "-79.89533000",
  },
  {
    id: 113120,
    name: "Butler County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.91172000",
    longitude: "-79.91299000",
  },
  {
    id: 113217,
    name: "California",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.06563000",
    longitude: "-79.89171000",
  },
  {
    id: 113228,
    name: "Caln",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.99094000",
    longitude: "-75.78022000",
  },
  {
    id: 113229,
    name: "Calumet",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.21090000",
    longitude: "-79.48532000",
  },
  {
    id: 113247,
    name: "Cambria County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.49529000",
    longitude: "-78.71370000",
  },
  {
    id: 113258,
    name: "Cambridge Springs",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.80367000",
    longitude: "-80.05644000",
  },
  {
    id: 113278,
    name: "Cameron County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.43680000",
    longitude: "-78.20378000",
  },
  {
    id: 113287,
    name: "Camp Hill",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.23981000",
    longitude: "-76.91997000",
  },
  {
    id: 113306,
    name: "Campbelltown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.27759000",
    longitude: "-76.58525000",
  },
  {
    id: 113336,
    name: "Canonsburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.26257000",
    longitude: "-80.18728000",
  },
  {
    id: 113349,
    name: "Canton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.65646000",
    longitude: "-76.85329000",
  },
  {
    id: 113382,
    name: "Carbon County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.91818000",
    longitude: "-75.70882000",
  },
  {
    id: 113389,
    name: "Carbondale",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.57369000",
    longitude: "-75.50185000",
  },
  {
    id: 113405,
    name: "Carlisle",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.20148000",
    longitude: "-77.18887000",
  },
  {
    id: 113426,
    name: "Carnegie",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.40868000",
    longitude: "-80.08339000",
  },
  {
    id: 113430,
    name: "Carnot-Moon",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.51856000",
    longitude: "-80.21736000",
  },
  {
    id: 113462,
    name: "Carroll Valley",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.74926000",
    longitude: "-77.38304000",
  },
  {
    id: 113541,
    name: "Castanea",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.12479000",
    longitude: "-77.42970000",
  },
  {
    id: 113551,
    name: "Castle Shannon",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.36479000",
    longitude: "-80.02228000",
  },
  {
    id: 113565,
    name: "Catasauqua",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.65482000",
    longitude: "-75.47463000",
  },
  {
    id: 113568,
    name: "Catawissa",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.95203000",
    longitude: "-76.45967000",
  },
  {
    id: 113599,
    name: "Cecil-Bishop",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.31819000",
    longitude: "-80.19331000",
  },
  {
    id: 113633,
    name: "Cementon",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.68926000",
    longitude: "-75.50768000",
  },
  {
    id: 113641,
    name: "Center City",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.95120000",
    longitude: "-75.15923000",
  },
  {
    id: 113661,
    name: "Centerville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.04535000",
    longitude: "-79.97561000",
  },
  {
    id: 113670,
    name: "Central City",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.11063000",
    longitude: "-78.80197000",
  },
  {
    id: 113689,
    name: "Centre County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.91934000",
    longitude: "-77.81995000",
  },
  {
    id: 113690,
    name: "Centre Hall",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.84756000",
    longitude: "-77.68611000",
  },
  {
    id: 113704,
    name: "Cetronia",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.58676000",
    longitude: "-75.52963000",
  },
  {
    id: 113712,
    name: "Chalfont",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.28844000",
    longitude: "-75.20906000",
  },
  {
    id: 113722,
    name: "Chambersburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.93759000",
    longitude: "-77.66110000",
  },
  {
    id: 113753,
    name: "Charleroi",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.13785000",
    longitude: "-79.89810000",
  },
  {
    id: 113871,
    name: "Cherryville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.75398000",
    longitude: "-75.53852000",
  },
  {
    id: 113884,
    name: "Chester",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.84967000",
    longitude: "-75.35707000",
  },
  {
    id: 113897,
    name: "Chester County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.97314000",
    longitude: "-75.74845000",
  },
  {
    id: 113898,
    name: "Chester Heights",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.89011000",
    longitude: "-75.47548000",
  },
  {
    id: 113899,
    name: "Chester Springs",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.09510000",
    longitude: "-75.61687000",
  },
  {
    id: 113900,
    name: "Chesterbrook",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.07566000",
    longitude: "-75.45908000",
  },
  {
    id: 113913,
    name: "Cheswick",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.54173000",
    longitude: "-79.79922000",
  },
  {
    id: 113922,
    name: "Chevy Chase Heights",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.63673000",
    longitude: "-79.14420000",
  },
  {
    id: 113946,
    name: "Chicora",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.94812000",
    longitude: "-79.74283000",
  },
  {
    id: 113965,
    name: "Chinchilla",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.47508000",
    longitude: "-75.67713000",
  },
  {
    id: 113999,
    name: "Christiana",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.95483000",
    longitude: "-75.99689000",
  },
  {
    id: 114008,
    name: "Church Hill",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.68201000",
    longitude: "-77.59861000",
  },
  {
    id: 114011,
    name: "Churchill",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.43840000",
    longitude: "-79.84310000",
  },
  {
    id: 114014,
    name: "Churchville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.18622000",
    longitude: "-75.01878000",
  },
  {
    id: 114096,
    name: "Clairton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.29229000",
    longitude: "-79.88171000",
  },
  {
    id: 114117,
    name: "Clarion",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.21479000",
    longitude: "-79.38532000",
  },
  {
    id: 114118,
    name: "Clarion County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.19239000",
    longitude: "-79.42096000",
  },
  {
    id: 114141,
    name: "Clarks Green",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.49286000",
    longitude: "-75.69964000",
  },
  {
    id: 114142,
    name: "Clarks Summit",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.48869000",
    longitude: "-75.70852000",
  },
  {
    id: 114168,
    name: "Clay",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.21843000",
    longitude: "-76.25551000",
  },
  {
    id: 114195,
    name: "Claysburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.29674000",
    longitude: "-78.44974000",
  },
  {
    id: 114216,
    name: "Clearfield",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.02728000",
    longitude: "-78.43919000",
  },
  {
    id: 114218,
    name: "Clearfield County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.00019000",
    longitude: "-78.47411000",
  },
  {
    id: 114236,
    name: "Cleona",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.33731000",
    longitude: "-76.47552000",
  },
  {
    id: 114263,
    name: "Clifton Heights",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.92928000",
    longitude: "-75.29630000",
  },
  {
    id: 114296,
    name: "Clinton County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.23405000",
    longitude: "-77.63811000",
  },
  {
    id: 114323,
    name: "Clymer",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.66812000",
    longitude: "-79.01170000",
  },
  {
    id: 114334,
    name: "Coaldale",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.82287000",
    longitude: "-75.90687000",
  },
  {
    id: 114341,
    name: "Coatesville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.98316000",
    longitude: "-75.82384000",
  },
  {
    id: 114353,
    name: "Cochranton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.52005000",
    longitude: "-80.04839000",
  },
  {
    id: 114420,
    name: "Collegeville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.18566000",
    longitude: "-75.45157000",
  },
  {
    id: 114427,
    name: "Collingdale",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.91178000",
    longitude: "-75.27713000",
  },
  {
    id: 114432,
    name: "Collinsburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.22424000",
    longitude: "-79.76838000",
  },
  {
    id: 114450,
    name: "Colonial Park",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.30064000",
    longitude: "-76.80969000",
  },
  {
    id: 114453,
    name: "Colony Park",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.34683000",
    longitude: "-75.98240000",
  },
  {
    id: 114473,
    name: "Columbia",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.03371000",
    longitude: "-76.50441000",
  },
  {
    id: 114481,
    name: "Columbia County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.04865000",
    longitude: "-76.40515000",
  },
  {
    id: 114508,
    name: "Colwyn",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.91234000",
    longitude: "-75.25379000",
  },
  {
    id: 114532,
    name: "Conashaugh Lakes",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.30593000",
    longitude: "-74.98962000",
  },
  {
    id: 114554,
    name: "Conemaugh",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.32674000",
    longitude: "-78.90808000",
  },
  {
    id: 114555,
    name: "Conestoga",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.94066000",
    longitude: "-76.34635000",
  },
  {
    id: 114560,
    name: "Conneaut Lakeshore",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.62711000",
    longitude: "-80.31008000",
  },
  {
    id: 114562,
    name: "Connellsville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.01785000",
    longitude: "-79.58948000",
  },
  {
    id: 114571,
    name: "Conshohocken",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.07928000",
    longitude: "-75.30157000",
  },
  {
    id: 114586,
    name: "Conway",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.65979000",
    longitude: "-80.23923000",
  },
  {
    id: 114590,
    name: "Conyngham",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.99203000",
    longitude: "-76.05659000",
  },
  {
    id: 114604,
    name: "Coopersburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.51149000",
    longitude: "-75.39046000",
  },
  {
    id: 114618,
    name: "Coplay",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.67010000",
    longitude: "-75.49546000",
  },
  {
    id: 114630,
    name: "Coraopolis",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.51840000",
    longitude: "-80.16672000",
  },
  {
    id: 114659,
    name: "Cornwall",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.27370000",
    longitude: "-76.40607000",
  },
  {
    id: 114660,
    name: "Cornwells Heights",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.07678000",
    longitude: "-74.94878000",
  },
  {
    id: 114669,
    name: "Corry",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.92033000",
    longitude: "-79.64033000",
  },
  {
    id: 114709,
    name: "Coudersport",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.77479000",
    longitude: "-78.02056000",
  },
  {
    id: 114754,
    name: "Crafton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.43507000",
    longitude: "-80.06617000",
  },
  {
    id: 114763,
    name: "Cranberry Township",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.68496000",
    longitude: "-80.10714000",
  },
  {
    id: 114782,
    name: "Crawford County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.68470000",
    longitude: "-80.10628000",
  },
  {
    id: 114800,
    name: "Cresson",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.45979000",
    longitude: "-78.59168000",
  },
  {
    id: 114801,
    name: "Cressona",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.62676000",
    longitude: "-76.19272000",
  },
  {
    id: 114870,
    name: "Croydon",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.08733000",
    longitude: "-74.90350000",
  },
  {
    id: 114919,
    name: "Cumberland County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.16363000",
    longitude: "-77.26555000",
  },
  {
    id: 114929,
    name: "Curtisville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.64229000",
    longitude: "-79.85089000",
  },
  {
    id: 114930,
    name: "Curwensville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.97561000",
    longitude: "-78.52502000",
  },
  {
    id: 114980,
    name: "Dale",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.31285000",
    longitude: "-78.90419000",
  },
  {
    id: 114991,
    name: "Dallas",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.33619000",
    longitude: "-75.96325000",
  },
  {
    id: 114999,
    name: "Dallastown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.89954000",
    longitude: "-76.64025000",
  },
  {
    id: 115003,
    name: "Dalton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.53424000",
    longitude: "-75.73603000",
  },
  {
    id: 115037,
    name: "Danville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.96342000",
    longitude: "-76.61273000",
  },
  {
    id: 115041,
    name: "Darby",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.91845000",
    longitude: "-75.25907000",
  },
  {
    id: 115056,
    name: "Dauphin County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.41544000",
    longitude: "-76.77947000",
  },
  {
    id: 115066,
    name: "Davidsville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.22702000",
    longitude: "-78.93641000",
  },
  {
    id: 115208,
    name: "Delaware County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.91681000",
    longitude: "-75.39890000",
  },
  {
    id: 115219,
    name: "Delmont",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.41312000",
    longitude: "-79.57032000",
  },
  {
    id: 115252,
    name: "Denver",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.23315000",
    longitude: "-76.13717000",
  },
  {
    id: 115266,
    name: "Derry",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.33396000",
    longitude: "-79.29976000",
  },
  {
    id: 115300,
    name: "Devon",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.04928000",
    longitude: "-75.42908000",
  },
  {
    id: 115301,
    name: "Dewart",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.10925000",
    longitude: "-76.87663000",
  },
  {
    id: 115332,
    name: "Dickson City",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.47147000",
    longitude: "-75.60769000",
  },
  {
    id: 115346,
    name: "Dillsburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.11093000",
    longitude: "-77.03498000",
  },
  {
    id: 115401,
    name: "Donora",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.17340000",
    longitude: "-79.85755000",
  },
  {
    id: 115412,
    name: "Dormont",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.39590000",
    longitude: "-80.03311000",
  },
  {
    id: 115413,
    name: "Dorneyville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.57510000",
    longitude: "-75.51963000",
  },
  {
    id: 115451,
    name: "Dover",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.00176000",
    longitude: "-76.85025000",
  },
  {
    id: 115462,
    name: "Downingtown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.00650000",
    longitude: "-75.70327000",
  },
  {
    id: 115463,
    name: "Doylestown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.31011000",
    longitude: "-75.12989000",
  },
  {
    id: 115470,
    name: "Dravosburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.35063000",
    longitude: "-79.88616000",
  },
  {
    id: 115472,
    name: "Dresher",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.14094000",
    longitude: "-75.16684000",
  },
  {
    id: 115477,
    name: "Drexel Hill",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.94706000",
    longitude: "-75.29213000",
  },
  {
    id: 115495,
    name: "Dublin",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.37177000",
    longitude: "-75.20156000",
  },
  {
    id: 115487,
    name: "DuBois",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.11923000",
    longitude: "-78.76003000",
  },
  {
    id: 115499,
    name: "Duboistown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.22258000",
    longitude: "-77.03691000",
  },
  {
    id: 115519,
    name: "Dunbar",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.97785000",
    longitude: "-79.61448000",
  },
  {
    id: 115523,
    name: "Duncannon",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.39814000",
    longitude: "-77.02303000",
  },
  {
    id: 115524,
    name: "Duncansville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.42341000",
    longitude: "-78.43390000",
  },
  {
    id: 115545,
    name: "Dunmore",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.41980000",
    longitude: "-75.63241000",
  },
  {
    id: 115552,
    name: "Dunnstown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.14590000",
    longitude: "-77.42137000",
  },
  {
    id: 115558,
    name: "Dupont",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.32508000",
    longitude: "-75.74547000",
  },
  {
    id: 115561,
    name: "Duquesne",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.38146000",
    longitude: "-79.85977000",
  },
  {
    id: 115576,
    name: "Duryea",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.34397000",
    longitude: "-75.73853000",
  },
  {
    id: 115614,
    name: "Eagleview",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.05938000",
    longitude: "-75.68076000",
  },
  {
    id: 115615,
    name: "Eagleville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.15955000",
    longitude: "-75.40824000",
  },
  {
    id: 115620,
    name: "Earlston",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.00619000",
    longitude: "-78.37001000",
  },
  {
    id: 115629,
    name: "East Bangor",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.87954000",
    longitude: "-75.18379000",
  },
  {
    id: 115631,
    name: "East Berlin",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.93760000",
    longitude: "-76.97859000",
  },
  {
    id: 115633,
    name: "East Berwick",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.06203000",
    longitude: "-76.22243000",
  },
  {
    id: 115648,
    name: "East Conemaugh",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.34868000",
    longitude: "-78.88364000",
  },
  {
    id: 115654,
    name: "East Earl",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.11010000",
    longitude: "-76.03272000",
  },
  {
    id: 115673,
    name: "East Greenville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.40649000",
    longitude: "-75.50185000",
  },
  {
    id: 115702,
    name: "East Lansdowne",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.94567000",
    longitude: "-75.26129000",
  },
  {
    id: 115709,
    name: "East McKeesport",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.38312000",
    longitude: "-79.80644000",
  },
  {
    id: 115735,
    name: "East Petersburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.10010000",
    longitude: "-76.35413000",
  },
  {
    id: 115736,
    name: "East Pittsburgh",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.39562000",
    longitude: "-79.83866000",
  },
  {
    id: 115761,
    name: "East Stroudsburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.99954000",
    longitude: "-75.18129000",
  },
  {
    id: 115767,
    name: "East Uniontown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.89980000",
    longitude: "-79.69782000",
  },
  {
    id: 115770,
    name: "East Washington",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.17368000",
    longitude: "-80.23756000",
  },
  {
    id: 115775,
    name: "East York",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.97371000",
    longitude: "-76.68636000",
  },
  {
    id: 115782,
    name: "Eastlawn Gardens",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.75065000",
    longitude: "-75.29573000",
  },
  {
    id: 115789,
    name: "Easton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.68843000",
    longitude: "-75.22073000",
  },
  {
    id: 115812,
    name: "Ebensburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.48507000",
    longitude: "-78.72474000",
  },
  {
    id: 115817,
    name: "Economy",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.60007000",
    longitude: "-80.22478000",
  },
  {
    id: 115822,
    name: "Eddington",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.08456000",
    longitude: "-74.94489000",
  },
  {
    id: 115825,
    name: "Eddystone",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.86011000",
    longitude: "-75.34436000",
  },
  {
    id: 115862,
    name: "Edgewood",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.43201000",
    longitude: "-79.88144000",
  },
  {
    id: 115865,
    name: "Edgeworth",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.55118000",
    longitude: "-80.19284000",
  },
  {
    id: 115868,
    name: "Edinboro",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.87422000",
    longitude: "-80.13172000",
  },
  {
    id: 115895,
    name: "Edwardsville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.26953000",
    longitude: "-75.91631000",
  },
  {
    id: 115900,
    name: "Effort",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.93926000",
    longitude: "-75.43491000",
  },
  {
    id: 115905,
    name: "Egypt",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.68010000",
    longitude: "-75.52991000",
  },
  {
    id: 115969,
    name: "Elim",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.29785000",
    longitude: "-78.94253000",
  },
  {
    id: 115973,
    name: "Elizabeth",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.26924000",
    longitude: "-79.88977000",
  },
  {
    id: 115981,
    name: "Elizabethtown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.15287000",
    longitude: "-76.60275000",
  },
  {
    id: 115982,
    name: "Elizabethville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.54897000",
    longitude: "-76.81192000",
  },
  {
    id: 115985,
    name: "Elk County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.42523000",
    longitude: "-78.64909000",
  },
  {
    id: 116007,
    name: "Elkland",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.98618000",
    longitude: "-77.31081000",
  },
  {
    id: 116036,
    name: "Ellport",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.86395000",
    longitude: "-80.25895000",
  },
  {
    id: 116041,
    name: "Ellwood City",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.86173000",
    longitude: "-80.28645000",
  },
  {
    id: 116074,
    name: "Elverson",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.15676000",
    longitude: "-75.83271000",
  },
  {
    id: 116087,
    name: "Elysburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.86453000",
    longitude: "-76.55246000",
  },
  {
    id: 116091,
    name: "Emerald Lakes",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.08842000",
    longitude: "-75.41963000",
  },
  {
    id: 116098,
    name: "Emigsville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.02176000",
    longitude: "-76.72802000",
  },
  {
    id: 116101,
    name: "Emmaus",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.53954000",
    longitude: "-75.49685000",
  },
  {
    id: 116113,
    name: "Emporium",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.51145000",
    longitude: "-78.23529000",
  },
  {
    id: 116114,
    name: "Emsworth",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.51007000",
    longitude: "-80.09450000",
  },
  {
    id: 116137,
    name: "Enhaut",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.23176000",
    longitude: "-76.82692000",
  },
  {
    id: 116140,
    name: "Enlow",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.45423000",
    longitude: "-80.23311000",
  },
  {
    id: 116144,
    name: "Enola",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.29009000",
    longitude: "-76.93386000",
  },
  {
    id: 116155,
    name: "Ephrata",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.17982000",
    longitude: "-76.17884000",
  },
  {
    id: 116166,
    name: "Erie",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "42.12922000",
    longitude: "-80.08506000",
  },
  {
    id: 116169,
    name: "Erie County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "42.11748000",
    longitude: "-80.09811000",
  },
  {
    id: 116190,
    name: "Espy",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.00620000",
    longitude: "-76.40994000",
  },
  {
    id: 116216,
    name: "Etna",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.50424000",
    longitude: "-79.94894000",
  },
  {
    id: 116247,
    name: "Evans City",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.76923000",
    longitude: "-80.06284000",
  },
  {
    id: 116249,
    name: "Evansburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.18094000",
    longitude: "-75.42907000",
  },
  {
    id: 116259,
    name: "Everett",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.01147000",
    longitude: "-78.37335000",
  },
  {
    id: 116272,
    name: "Exeter",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.32064000",
    longitude: "-75.81908000",
  },
  {
    id: 116277,
    name: "Exton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.02900000",
    longitude: "-75.62077000",
  },
  {
    id: 116280,
    name: "Factoryville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.56313000",
    longitude: "-75.78269000",
  },
  {
    id: 116296,
    name: "Fairchance",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.82480000",
    longitude: "-79.75449000",
  },
  {
    id: 116300,
    name: "Fairdale",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.88702000",
    longitude: "-79.96811000",
  },
  {
    id: 116327,
    name: "Fairhope",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.11368000",
    longitude: "-79.83977000",
  },
  {
    id: 116332,
    name: "Fairless Hills",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.17955000",
    longitude: "-74.85516000",
  },
  {
    id: 116354,
    name: "Fairview",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "42.03145000",
    longitude: "-80.25534000",
  },
  {
    id: 116361,
    name: "Fairview-Ferndale",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.78037000",
    longitude: "-76.57528000",
  },
  {
    id: 116383,
    name: "Falls Creek",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.14506000",
    longitude: "-78.80447000",
  },
  {
    id: 116430,
    name: "Farrell",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.21228000",
    longitude: "-80.49674000",
  },
  {
    id: 116437,
    name: "Faxon",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.24841000",
    longitude: "-76.97719000",
  },
  {
    id: 116452,
    name: "Fayette County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.91990000",
    longitude: "-79.64737000",
  },
  {
    id: 116458,
    name: "Fayetteville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.91120000",
    longitude: "-77.54999000",
  },
  {
    id: 116462,
    name: "Feasterville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.14400000",
    longitude: "-75.00517000",
  },
  {
    id: 116468,
    name: "Fellsburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.18313000",
    longitude: "-79.82421000",
  },
  {
    id: 116488,
    name: "Ferndale",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.28896000",
    longitude: "-78.91475000",
  },
  {
    id: 116492,
    name: "Fernway",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.69479000",
    longitude: "-80.13089000",
  },
  {
    id: 116541,
    name: "Fivepointville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.18287000",
    longitude: "-76.05106000",
  },
  {
    id: 116558,
    name: "Fleetwood",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.45398000",
    longitude: "-75.81798000",
  },
  {
    id: 116563,
    name: "Flemington",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.12646000",
    longitude: "-77.47165000",
  },
  {
    id: 116599,
    name: "Flourtown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.10344000",
    longitude: "-75.21240000",
  },
  {
    id: 116615,
    name: "Flying Hills",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.27676000",
    longitude: "-75.91410000",
  },
  {
    id: 116618,
    name: "Folcroft",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.89095000",
    longitude: "-75.28380000",
  },
  {
    id: 116625,
    name: "Folsom",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.88983000",
    longitude: "-75.32519000",
  },
  {
    id: 116634,
    name: "Ford City",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.77229000",
    longitude: "-79.52977000",
  },
  {
    id: 116649,
    name: "Forest City",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.65147000",
    longitude: "-75.46657000",
  },
  {
    id: 116650,
    name: "Forest County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.51307000",
    longitude: "-79.23601000",
  },
  {
    id: 116659,
    name: "Forest Hills",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.41979000",
    longitude: "-79.85005000",
  },
  {
    id: 116762,
    name: "Fort Washington",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.14178000",
    longitude: "-75.20906000",
  },
  {
    id: 116770,
    name: "Forty Fort",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.27897000",
    longitude: "-75.87825000",
  },
  {
    id: 116775,
    name: "Foster Brook",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.97506000",
    longitude: "-78.61725000",
  },
  {
    id: 116781,
    name: "Fountain Hill",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.60149000",
    longitude: "-75.39518000",
  },
  {
    id: 116799,
    name: "Fox Chapel",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.51340000",
    longitude: "-79.87977000",
  },
  {
    id: 116800,
    name: "Fox Chase",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.39557000",
    longitude: "-75.96216000",
  },
  {
    id: 116808,
    name: "Fox Run",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.70229000",
    longitude: "-80.08284000",
  },
  {
    id: 116810,
    name: "Frackville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.78398000",
    longitude: "-76.23022000",
  },
  {
    id: 116840,
    name: "Franklin",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.39784000",
    longitude: "-79.83144000",
  },
  {
    id: 116862,
    name: "Franklin County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.92742000",
    longitude: "-77.72127000",
  },
  {
    id: 116870,
    name: "Franklin Park",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.58340000",
    longitude: "-80.08784000",
  },
  {
    id: 116892,
    name: "Fredericksburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.44370000",
    longitude: "-76.42829000",
  },
  {
    id: 116902,
    name: "Freedom",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.68562000",
    longitude: "-80.25173000",
  },
  {
    id: 116906,
    name: "Freeland",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.01675000",
    longitude: "-75.89714000",
  },
  {
    id: 116909,
    name: "Freemansburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.62649000",
    longitude: "-75.34574000",
  },
  {
    id: 116915,
    name: "Freeport",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.67395000",
    longitude: "-79.68477000",
  },
  {
    id: 116945,
    name: "Friedens",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.05008000",
    longitude: "-78.99836000",
  },
  {
    id: 116982,
    name: "Fullerton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.63176000",
    longitude: "-75.47324000",
  },
  {
    id: 116998,
    name: "Fulton County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.92534000",
    longitude: "-78.11268000",
  },
  {
    id: 117030,
    name: "Galeton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.73312000",
    longitude: "-77.64193000",
  },
  {
    id: 117038,
    name: "Gallitzin",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.48229000",
    longitude: "-78.55168000",
  },
  {
    id: 117052,
    name: "Gap",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.98732000",
    longitude: "-76.02051000",
  },
  {
    id: 117068,
    name: "Garden View",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.25424000",
    longitude: "-77.04608000",
  },
  {
    id: 117117,
    name: "Gastonville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.25729000",
    longitude: "-79.99588000",
  },
  {
    id: 117132,
    name: "Geistown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.29091000",
    longitude: "-78.86891000",
  },
  {
    id: 117158,
    name: "Georgetown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.93760000",
    longitude: "-76.08329000",
  },
  {
    id: 117179,
    name: "Gettysburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.83093000",
    longitude: "-77.23110000",
  },
  {
    id: 117192,
    name: "Gibsonia",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.63007000",
    longitude: "-79.96950000",
  },
  {
    id: 117207,
    name: "Gilbertsville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.32010000",
    longitude: "-75.61018000",
  },
  {
    id: 117228,
    name: "Girard",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "42.00033000",
    longitude: "-80.31812000",
  },
  {
    id: 117229,
    name: "Girardville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.79148000",
    longitude: "-76.28356000",
  },
  {
    id: 117254,
    name: "Glassport",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.32479000",
    longitude: "-79.89227000",
  },
  {
    id: 117268,
    name: "Glen Lyon",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.17508000",
    longitude: "-76.07465000",
  },
  {
    id: 117272,
    name: "Glen Rock",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.79316000",
    longitude: "-76.73025000",
  },
  {
    id: 117301,
    name: "Glenolden",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.90011000",
    longitude: "-75.28907000",
  },
  {
    id: 117306,
    name: "Glenshaw",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.53285000",
    longitude: "-79.96755000",
  },
  {
    id: 117307,
    name: "Glenside",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.10233000",
    longitude: "-75.15212000",
  },
  {
    id: 117344,
    name: "Gold Key Lake",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.30593000",
    longitude: "-74.93850000",
  },
  {
    id: 117526,
    name: "Grantley",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.94038000",
    longitude: "-76.72913000",
  },
  {
    id: 117608,
    name: "Green Tree",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.41174000",
    longitude: "-80.04561000",
  },
  {
    id: 117625,
    name: "Greencastle",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.79037000",
    longitude: "-77.72777000",
  },
  {
    id: 117643,
    name: "Greene County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.85380000",
    longitude: "-80.22287000",
  },
  {
    id: 117655,
    name: "Greenfields",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.35990000",
    longitude: "-75.95199000",
  },
  {
    id: 117660,
    name: "Greenock",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.31229000",
    longitude: "-79.80671000",
  },
  {
    id: 117672,
    name: "Greensburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.30146000",
    longitude: "-79.53893000",
  },
  {
    id: 117694,
    name: "Greenville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.40450000",
    longitude: "-80.39118000",
  },
  {
    id: 117707,
    name: "Greenwood",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.53590000",
    longitude: "-78.35751000",
  },
  {
    id: 117736,
    name: "Grill",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.29870000",
    longitude: "-75.94049000",
  },
  {
    id: 117756,
    name: "Grove City",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.15784000",
    longitude: "-80.08867000",
  },
  {
    id: 117785,
    name: "Guilford",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.91541000",
    longitude: "-77.60105000",
  },
  {
    id: 117788,
    name: "Guilford Siding",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.86537000",
    longitude: "-77.61249000",
  },
  {
    id: 117858,
    name: "Halfway House",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.28204000",
    longitude: "-75.64324000",
  },
  {
    id: 117868,
    name: "Hallam",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.00482000",
    longitude: "-76.60413000",
  },
  {
    id: 117874,
    name: "Hallstead",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.96119000",
    longitude: "-75.74324000",
  },
  {
    id: 117885,
    name: "Hamburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.55565000",
    longitude: "-75.98188000",
  },
  {
    id: 117968,
    name: "Hanover",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.80066000",
    longitude: "-76.98304000",
  },
  {
    id: 118020,
    name: "Harleigh",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.98064000",
    longitude: "-75.97131000",
  },
  {
    id: 118025,
    name: "Harleysville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.27955000",
    longitude: "-75.38712000",
  },
  {
    id: 118050,
    name: "Harrisburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.27370000",
    longitude: "-76.88442000",
  },
  {
    id: 118116,
    name: "Harveys Lake",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.38341000",
    longitude: "-76.02465000",
  },
  {
    id: 118130,
    name: "Hasson Heights",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.44895000",
    longitude: "-79.67700000",
  },
  {
    id: 118134,
    name: "Hastings",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.66507000",
    longitude: "-78.71225000",
  },
  {
    id: 118136,
    name: "Hatboro",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.17428000",
    longitude: "-75.10684000",
  },
  {
    id: 118139,
    name: "Hatfield",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.27983000",
    longitude: "-75.29934000",
  },
  {
    id: 118169,
    name: "Hawley",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.47592000",
    longitude: "-75.18212000",
  },
  {
    id: 118213,
    name: "Hazleton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.95842000",
    longitude: "-75.97465000",
  },
  {
    id: 118239,
    name: "Hebron",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.33898000",
    longitude: "-76.39940000",
  },
  {
    id: 118245,
    name: "Heidelberg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.39229000",
    longitude: "-80.09089000",
  },
  {
    id: 118259,
    name: "Hellertown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.57954000",
    longitude: "-75.34073000",
  },
  {
    id: 118266,
    name: "Hemlock Farms",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.32676000",
    longitude: "-75.03656000",
  },
  {
    id: 118329,
    name: "Hermitage",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.23339000",
    longitude: "-80.44868000",
  },
  {
    id: 118341,
    name: "Hershey",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.28592000",
    longitude: "-76.65025000",
  },
  {
    id: 118414,
    name: "Highland Park",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.62091000",
    longitude: "-77.56805000",
  },
  {
    id: 118423,
    name: "Highspire",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.21092000",
    longitude: "-76.79108000",
  },
  {
    id: 118442,
    name: "Hilldale",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.28925000",
    longitude: "-75.83631000",
  },
  {
    id: 118443,
    name: "Hiller",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.01035000",
    longitude: "-79.90088000",
  },
  {
    id: 118515,
    name: "Hokendauqua",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.66204000",
    longitude: "-75.49102000",
  },
  {
    id: 118545,
    name: "Hollidaysburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.42729000",
    longitude: "-78.38890000",
  },
  {
    id: 118588,
    name: "Homeacre-Lyndora",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.87206000",
    longitude: "-79.92060000",
  },
  {
    id: 118598,
    name: "Homer City",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.54340000",
    longitude: "-79.16226000",
  },
  {
    id: 118602,
    name: "Homestead",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.40590000",
    longitude: "-79.91199000",
  },
  {
    id: 118606,
    name: "Hometown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.82370000",
    longitude: "-75.98020000",
  },
  {
    id: 118618,
    name: "Honesdale",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.57676000",
    longitude: "-75.25879000",
  },
  {
    id: 118619,
    name: "Honey Brook",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.09427000",
    longitude: "-75.91133000",
  },
  {
    id: 118659,
    name: "Hopwood",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.87702000",
    longitude: "-79.70199000",
  },
  {
    id: 118677,
    name: "Horsham",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.17844000",
    longitude: "-75.12851000",
  },
  {
    id: 118696,
    name: "Houserville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.82395000",
    longitude: "-77.82889000",
  },
  {
    id: 118700,
    name: "Houston",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.24646000",
    longitude: "-80.21145000",
  },
  {
    id: 118745,
    name: "Hudson",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.27480000",
    longitude: "-75.83603000",
  },
  {
    id: 118761,
    name: "Hughestown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.32702000",
    longitude: "-75.77325000",
  },
  {
    id: 118763,
    name: "Hughesville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.24119000",
    longitude: "-76.72385000",
  },
  {
    id: 118782,
    name: "Hummels Wharf",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.83175000",
    longitude: "-76.83580000",
  },
  {
    id: 118783,
    name: "Hummelstown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.26537000",
    longitude: "-76.70830000",
  },
  {
    id: 118796,
    name: "Huntingdon",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.48480000",
    longitude: "-78.01028000",
  },
  {
    id: 118797,
    name: "Huntingdon County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.41693000",
    longitude: "-77.98121000",
  },
  {
    id: 118846,
    name: "Hyde",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.00256000",
    longitude: "-78.46252000",
  },
  {
    id: 118851,
    name: "Hyde Park",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.37732000",
    longitude: "-75.92521000",
  },
  {
    id: 118882,
    name: "Imperial",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.44951000",
    longitude: "-80.24450000",
  },
  {
    id: 118911,
    name: "Indian Mountain Lake",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.00314000",
    longitude: "-75.50824000",
  },
  {
    id: 118922,
    name: "Indiana",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.62146000",
    longitude: "-79.15253000",
  },
  {
    id: 118923,
    name: "Indiana County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.65205000",
    longitude: "-79.08755000",
  },
  {
    id: 118930,
    name: "Industry",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.64451000",
    longitude: "-80.41618000",
  },
  {
    id: 118941,
    name: "Ingram",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.44618000",
    longitude: "-80.06755000",
  },
  {
    id: 118942,
    name: "Inkerman",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.29897000",
    longitude: "-75.81269000",
  },
  {
    id: 118949,
    name: "Intercourse",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.03760000",
    longitude: "-76.10495000",
  },
  {
    id: 119012,
    name: "Irwin",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.32451000",
    longitude: "-79.70115000",
  },
  {
    id: 119052,
    name: "Ivyland",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.20789000",
    longitude: "-75.07267000",
  },
  {
    id: 119107,
    name: "Jacksonwald",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.32454000",
    longitude: "-75.84965000",
  },
  {
    id: 119108,
    name: "Jacobus",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.88316000",
    longitude: "-76.71052000",
  },
  {
    id: 119159,
    name: "Jeannette",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.32812000",
    longitude: "-79.61532000",
  },
  {
    id: 119192,
    name: "Jefferson County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.12815000",
    longitude: "-78.99942000",
  },
  {
    id: 119202,
    name: "Jefferson Hills",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.29118000",
    longitude: "-79.93199000",
  },
  {
    id: 119216,
    name: "Jenkintown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.09594000",
    longitude: "-75.12517000",
  },
  {
    id: 119226,
    name: "Jermyn",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.53091000",
    longitude: "-75.54546000",
  },
  {
    id: 119228,
    name: "Jerome",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.20896000",
    longitude: "-78.98364000",
  },
  {
    id: 119233,
    name: "Jersey Shore",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.20202000",
    longitude: "-77.26442000",
  },
  {
    id: 119238,
    name: "Jessup",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.46869000",
    longitude: "-75.56213000",
  },
  {
    id: 119247,
    name: "Jim Thorpe",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.87592000",
    longitude: "-75.73241000",
  },
  {
    id: 119274,
    name: "Johnsonburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.49062000",
    longitude: "-78.67503000",
  },
  {
    id: 119285,
    name: "Johnstown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.32674000",
    longitude: "-78.92197000",
  },
  {
    id: 119308,
    name: "Jonestown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.41370000",
    longitude: "-76.47830000",
  },
  {
    id: 119340,
    name: "Juniata County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.53106000",
    longitude: "-77.40216000",
  },
  {
    id: 119376,
    name: "Kane",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.66284000",
    longitude: "-78.81114000",
  },
  {
    id: 119455,
    name: "Kenhorst",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.31065000",
    longitude: "-75.93938000",
  },
  {
    id: 119458,
    name: "Kenilworth",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.23149000",
    longitude: "-75.63408000",
  },
  {
    id: 119460,
    name: "Kenmar",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.25341000",
    longitude: "-76.95941000",
  },
  {
    id: 119474,
    name: "Kennett Square",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.84678000",
    longitude: "-75.71160000",
  },
  {
    id: 119590,
    name: "King of Prussia",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.08927000",
    longitude: "-75.39602000",
  },
  {
    id: 119628,
    name: "Kingston",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.26175000",
    longitude: "-75.89686000",
  },
  {
    id: 119661,
    name: "Kittanning",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.81645000",
    longitude: "-79.52199000",
  },
  {
    id: 119683,
    name: "Knox",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.23451000",
    longitude: "-79.53727000",
  },
  {
    id: 119723,
    name: "Kulpmont",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.79342000",
    longitude: "-76.47245000",
  },
  {
    id: 119724,
    name: "Kulpsville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.24288000",
    longitude: "-75.33656000",
  },
  {
    id: 119728,
    name: "Kutztown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.51732000",
    longitude: "-75.77742000",
  },
  {
    id: 119814,
    name: "Lackawanna County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.43679000",
    longitude: "-75.60920000",
  },
  {
    id: 119843,
    name: "Lafayette Hill",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.09245000",
    longitude: "-75.25330000",
  },
  {
    id: 119845,
    name: "Laflin",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.28897000",
    longitude: "-75.80547000",
  },
  {
    id: 119885,
    name: "Lake City",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "42.01422000",
    longitude: "-80.34534000",
  },
  {
    id: 119920,
    name: "Lake Heritage",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.80954000",
    longitude: "-77.18387000",
  },
  {
    id: 119929,
    name: "Lake Latonka",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.29039000",
    longitude: "-80.18129000",
  },
  {
    id: 119940,
    name: "Lake Meade",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.98510000",
    longitude: "-77.03720000",
  },
  {
    id: 120001,
    name: "Lake Wynonah",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.59926000",
    longitude: "-76.16383000",
  },
  {
    id: 120021,
    name: "Lakemont",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.47285000",
    longitude: "-78.38835000",
  },
  {
    id: 120077,
    name: "Lampeter",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.99010000",
    longitude: "-76.23968000",
  },
  {
    id: 120087,
    name: "Lancaster",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.03788000",
    longitude: "-76.30551000",
  },
  {
    id: 120093,
    name: "Lancaster County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.04244000",
    longitude: "-76.24770000",
  },
  {
    id: 120100,
    name: "Landisville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.09537000",
    longitude: "-76.40996000",
  },
  {
    id: 120110,
    name: "Langhorne",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.17455000",
    longitude: "-74.92267000",
  },
  {
    id: 120111,
    name: "Langhorne Manor",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.16705000",
    longitude: "-74.91767000",
  },
  {
    id: 120121,
    name: "Lansdale",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.24150000",
    longitude: "-75.28379000",
  },
  {
    id: 120123,
    name: "Lansdowne",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.93817000",
    longitude: "-75.27185000",
  },
  {
    id: 120124,
    name: "Lansford",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.83176000",
    longitude: "-75.88242000",
  },
  {
    id: 120135,
    name: "Laporte",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.42397000",
    longitude: "-76.49411000",
  },
  {
    id: 120149,
    name: "Larksville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.24508000",
    longitude: "-75.93075000",
  },
  {
    id: 120175,
    name: "Latrobe",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.32118000",
    longitude: "-79.37948000",
  },
  {
    id: 120202,
    name: "Laureldale",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.38815000",
    longitude: "-75.91798000",
  },
  {
    id: 120212,
    name: "Laurys Station",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.72315000",
    longitude: "-75.53018000",
  },
  {
    id: 120224,
    name: "Lawnton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.25842000",
    longitude: "-76.80386000",
  },
  {
    id: 120237,
    name: "Lawrence County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.99127000",
    longitude: "-80.33419000",
  },
  {
    id: 120239,
    name: "Lawrence Park",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "42.15228000",
    longitude: "-80.02311000",
  },
  {
    id: 120248,
    name: "Lawson Heights",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.29174000",
    longitude: "-79.38920000",
  },
  {
    id: 120293,
    name: "Lebanon",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.34093000",
    longitude: "-76.41135000",
  },
  {
    id: 120295,
    name: "Lebanon County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.36723000",
    longitude: "-76.45771000",
  },
  {
    id: 120297,
    name: "Lebanon South",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.32804000",
    longitude: "-76.40644000",
  },
  {
    id: 120319,
    name: "Leechburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.62701000",
    longitude: "-79.60560000",
  },
  {
    id: 120327,
    name: "Leesport",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.44704000",
    longitude: "-75.96632000",
  },
  {
    id: 120330,
    name: "Leetsdale",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.56312000",
    longitude: "-80.20839000",
  },
  {
    id: 120335,
    name: "Lehigh County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.61271000",
    longitude: "-75.59237000",
  },
  {
    id: 120336,
    name: "Lehighton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.83370000",
    longitude: "-75.71380000",
  },
  {
    id: 120348,
    name: "Leith-Hatfield",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.87744000",
    longitude: "-79.73133000",
  },
  {
    id: 120361,
    name: "Lemont",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.81062000",
    longitude: "-77.81833000",
  },
  {
    id: 120364,
    name: "Lemoyne",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.24120000",
    longitude: "-76.89414000",
  },
  {
    id: 120367,
    name: "Lenape Heights",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.76423000",
    longitude: "-79.52060000",
  },
  {
    id: 120380,
    name: "Leola",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.08787000",
    longitude: "-76.18495000",
  },
  {
    id: 120402,
    name: "Level Green",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.39340000",
    longitude: "-79.72032000",
  },
  {
    id: 120408,
    name: "Levittown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.15511000",
    longitude: "-74.82877000",
  },
  {
    id: 120421,
    name: "Lewisburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.96453000",
    longitude: "-76.88441000",
  },
  {
    id: 120432,
    name: "Lewistown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.59924000",
    longitude: "-77.57138000",
  },
  {
    id: 120467,
    name: "Liberty",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.32535000",
    longitude: "-79.85616000",
  },
  {
    id: 120479,
    name: "Light Street",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.03620000",
    longitude: "-76.42356000",
  },
  {
    id: 120482,
    name: "Ligonier",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.24313000",
    longitude: "-79.23753000",
  },
  {
    id: 120488,
    name: "Lima",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.91733000",
    longitude: "-75.44047000",
  },
  {
    id: 120491,
    name: "Limerick",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.23093000",
    longitude: "-75.52212000",
  },
  {
    id: 120507,
    name: "Lincoln",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.31896000",
    longitude: "-79.85477000",
  },
  {
    id: 120544,
    name: "Lincoln Park",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.31481000",
    longitude: "-75.98549000",
  },
  {
    id: 120579,
    name: "Linglestown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.33398000",
    longitude: "-76.78914000",
  },
  {
    id: 120586,
    name: "Linntown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.95897000",
    longitude: "-76.89913000",
  },
  {
    id: 120593,
    name: "Linwood",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.82650000",
    longitude: "-75.42547000",
  },
  {
    id: 120594,
    name: "Lionville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.05372000",
    longitude: "-75.65993000",
  },
  {
    id: 120612,
    name: "Lititz",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.15732000",
    longitude: "-76.30690000",
  },
  {
    id: 120634,
    name: "Littlestown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.74454000",
    longitude: "-77.08804000",
  },
  {
    id: 120674,
    name: "Lock Haven",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.13701000",
    longitude: "-77.44693000",
  },
  {
    id: 120709,
    name: "Loganville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.85566000",
    longitude: "-76.70747000",
  },
  {
    id: 120769,
    name: "Lorane",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.28843000",
    longitude: "-75.85465000",
  },
  {
    id: 120775,
    name: "Loretto",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.50313000",
    longitude: "-78.63030000",
  },
  {
    id: 120839,
    name: "Lower Allen",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.22648000",
    longitude: "-76.90053000",
  },
  {
    id: 120840,
    name: "Lower Burrell",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.55312000",
    longitude: "-79.75727000",
  },
  {
    id: 120854,
    name: "Loyalhanna",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.32257000",
    longitude: "-79.36226000",
  },
  {
    id: 120903,
    name: "Luzerne",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.28564000",
    longitude: "-75.90103000",
  },
  {
    id: 120904,
    name: "Luzerne County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.17701000",
    longitude: "-75.98903000",
  },
  {
    id: 120905,
    name: "Lycoming County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.34338000",
    longitude: "-77.06451000",
  },
  {
    id: 120907,
    name: "Lykens",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.56675000",
    longitude: "-76.70052000",
  },
  {
    id: 120932,
    name: "Lynnwood-Pricedale",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.13071000",
    longitude: "-79.85135000",
  },
  {
    id: 120974,
    name: "Macungie",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.51593000",
    longitude: "-75.55519000",
  },
  {
    id: 121040,
    name: "Mahanoy City",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.81259000",
    longitude: "-76.14160000",
  },
  {
    id: 121073,
    name: "Malvern",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.03622000",
    longitude: "-75.51381000",
  },
  {
    id: 121100,
    name: "Manchester",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.06315000",
    longitude: "-76.71830000",
  },
  {
    id: 121118,
    name: "Manheim",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.16343000",
    longitude: "-76.39496000",
  },
  {
    id: 121139,
    name: "Manor",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.33396000",
    longitude: "-79.67004000",
  },
  {
    id: 121147,
    name: "Mansfield",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.80730000",
    longitude: "-77.07747000",
  },
  {
    id: 121166,
    name: "Maple Glen",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.17928000",
    longitude: "-75.18045000",
  },
  {
    id: 121200,
    name: "Marcus Hook",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.81928000",
    longitude: "-75.41853000",
  },
  {
    id: 121210,
    name: "Marianne",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.24645000",
    longitude: "-79.42893000",
  },
  {
    id: 121214,
    name: "Marienville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.46895000",
    longitude: "-79.12310000",
  },
  {
    id: 121218,
    name: "Marietta",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.05704000",
    longitude: "-76.55219000",
  },
  {
    id: 121298,
    name: "Mars",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.69590000",
    longitude: "-80.01173000",
  },
  {
    id: 121322,
    name: "Marshallton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.78675000",
    longitude: "-76.53940000",
  },
  {
    id: 121347,
    name: "Martinsburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.31119000",
    longitude: "-78.32418000",
  },
  {
    id: 121359,
    name: "Marysville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.34259000",
    longitude: "-76.92997000",
  },
  {
    id: 121384,
    name: "Masontown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.84674000",
    longitude: "-79.89978000",
  },
  {
    id: 121398,
    name: "Matamoras",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.36870000",
    longitude: "-74.70016000",
  },
  {
    id: 121437,
    name: "Mayfield",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.53814000",
    longitude: "-75.53602000",
  },
  {
    id: 121453,
    name: "Maytown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.07537000",
    longitude: "-76.58219000",
  },
  {
    id: 121461,
    name: "McAdoo",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.90127000",
    longitude: "-75.99106000",
  },
  {
    id: 121477,
    name: "McConnellsburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.93259000",
    longitude: "-77.99889000",
  },
  {
    id: 121478,
    name: "McConnellstown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.45257000",
    longitude: "-78.08167000",
  },
  {
    id: 121491,
    name: "McDonald",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.37090000",
    longitude: "-80.23478000",
  },
  {
    id: 121503,
    name: "McGovern",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.22896000",
    longitude: "-80.21645000",
  },
  {
    id: 121516,
    name: "McKean County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.80775000",
    longitude: "-78.56903000",
  },
  {
    id: 121518,
    name: "McKees Rocks",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.46562000",
    longitude: "-80.06561000",
  },
  {
    id: 121519,
    name: "McKeesport",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.34785000",
    longitude: "-79.86422000",
  },
  {
    id: 121543,
    name: "McMurray",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.27785000",
    longitude: "-80.08394000",
  },
  {
    id: 121551,
    name: "McSherrystown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.80732000",
    longitude: "-77.01137000",
  },
  {
    id: 121570,
    name: "Meadowood",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.84201000",
    longitude: "-79.89394000",
  },
  {
    id: 121575,
    name: "Meadville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.64144000",
    longitude: "-80.15145000",
  },
  {
    id: 121580,
    name: "Mechanicsburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.21426000",
    longitude: "-77.00859000",
  },
  {
    id: 121585,
    name: "Mechanicsville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.96648000",
    longitude: "-76.58662000",
  },
  {
    id: 121598,
    name: "Media",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.91678000",
    longitude: "-75.38769000",
  },
  {
    id: 121674,
    name: "Mercer",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.22700000",
    longitude: "-80.23979000",
  },
  {
    id: 121680,
    name: "Mercer County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.30218000",
    longitude: "-80.25770000",
  },
  {
    id: 121683,
    name: "Mercersburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.82787000",
    longitude: "-77.90333000",
  },
  {
    id: 121693,
    name: "Meridian",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.84840000",
    longitude: "-79.96200000",
  },
  {
    id: 121745,
    name: "Meyersdale",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.81369000",
    longitude: "-79.02475000",
  },
  {
    id: 121769,
    name: "Middleburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.78592000",
    longitude: "-77.04720000",
  },
  {
    id: 121792,
    name: "Middletown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.19981000",
    longitude: "-76.73108000",
  },
  {
    id: 121799,
    name: "Midland",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.63257000",
    longitude: "-80.44645000",
  },
  {
    id: 121818,
    name: "Midway",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.80843000",
    longitude: "-77.00276000",
  },
  {
    id: 121825,
    name: "Mifflin County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.61041000",
    longitude: "-77.61704000",
  },
  {
    id: 121826,
    name: "Mifflinburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.91758000",
    longitude: "-77.04775000",
  },
  {
    id: 121827,
    name: "Mifflintown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.56980000",
    longitude: "-77.39693000",
  },
  {
    id: 121828,
    name: "Mifflinville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.03231000",
    longitude: "-76.30799000",
  },
  {
    id: 121845,
    name: "Milesburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.94173000",
    longitude: "-77.78500000",
  },
  {
    id: 121857,
    name: "Milford",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.32232000",
    longitude: "-74.80239000",
  },
  {
    id: 121865,
    name: "Mill Hall",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.10729000",
    longitude: "-77.48443000",
  },
  {
    id: 121870,
    name: "Millbourne",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.96345000",
    longitude: "-75.25018000",
  },
  {
    id: 121886,
    name: "Millersburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.53953000",
    longitude: "-76.96081000",
  },
  {
    id: 121889,
    name: "Millersville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.99788000",
    longitude: "-76.35413000",
  },
  {
    id: 121903,
    name: "Millvale",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.48007000",
    longitude: "-79.97839000",
  },
  {
    id: 121910,
    name: "Milroy",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.71396000",
    longitude: "-77.59055000",
  },
  {
    id: 121917,
    name: "Milton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.01203000",
    longitude: "-76.84774000",
  },
  {
    id: 121943,
    name: "Minersville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.69065000",
    longitude: "-76.26217000",
  },
  {
    id: 122022,
    name: "Mohnton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.28593000",
    longitude: "-75.98438000",
  },
  {
    id: 122032,
    name: "Monaca",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.68729000",
    longitude: "-80.27145000",
  },
  {
    id: 122038,
    name: "Monessen",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.14841000",
    longitude: "-79.88783000",
  },
  {
    id: 122055,
    name: "Monongahela",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.20313000",
    longitude: "-79.92616000",
  },
  {
    id: 122081,
    name: "Monroe County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.05807000",
    longitude: "-75.33948000",
  },
  {
    id: 122086,
    name: "Monroeville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.42118000",
    longitude: "-79.78810000",
  },
  {
    id: 122092,
    name: "Mont Alto",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.84426000",
    longitude: "-77.55832000",
  },
  {
    id: 122136,
    name: "Montgomery",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.17036000",
    longitude: "-76.87691000",
  },
  {
    id: 122155,
    name: "Montgomery County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.21083000",
    longitude: "-75.36730000",
  },
  {
    id: 122157,
    name: "Montgomeryville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.24733000",
    longitude: "-75.24379000",
  },
  {
    id: 122173,
    name: "Montour County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.02792000",
    longitude: "-76.65856000",
  },
  {
    id: 122175,
    name: "Montoursville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.25425000",
    longitude: "-76.92052000",
  },
  {
    id: 122183,
    name: "Montrose",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.83397000",
    longitude: "-75.87714000",
  },
  {
    id: 122216,
    name: "Moosic",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.35341000",
    longitude: "-75.73825000",
  },
  {
    id: 122286,
    name: "Morrisville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.21150000",
    longitude: "-74.78794000",
  },
  {
    id: 122292,
    name: "Morton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.90983000",
    longitude: "-75.32352000",
  },
  {
    id: 122299,
    name: "Moscow",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.33675000",
    longitude: "-75.51852000",
  },
  {
    id: 122337,
    name: "Mount Carmel",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.79703000",
    longitude: "-76.41190000",
  },
  {
    id: 122340,
    name: "Mount Cobb",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.41342000",
    longitude: "-75.49324000",
  },
  {
    id: 122351,
    name: "Mount Holly Springs",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.11842000",
    longitude: "-77.18998000",
  },
  {
    id: 122358,
    name: "Mount Joy",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.10982000",
    longitude: "-76.50330000",
  },
  {
    id: 122362,
    name: "Mount Lebanon",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.35535000",
    longitude: "-80.04950000",
  },
  {
    id: 122370,
    name: "Mount Oliver",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.41424000",
    longitude: "-79.98783000",
  },
  {
    id: 122373,
    name: "Mount Penn",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.32815000",
    longitude: "-75.89076000",
  },
  {
    id: 122380,
    name: "Mount Pleasant",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.14896000",
    longitude: "-79.54115000",
  },
  {
    id: 122384,
    name: "Mount Pocono",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.12203000",
    longitude: "-75.36463000",
  },
  {
    id: 122392,
    name: "Mount Union",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.38452000",
    longitude: "-77.88222000",
  },
  {
    id: 122407,
    name: "Mount Wolf",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.06315000",
    longitude: "-76.70386000",
  },
  {
    id: 122427,
    name: "Mountain Top",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.16953000",
    longitude: "-75.87742000",
  },
  {
    id: 122437,
    name: "Mountainhome",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.17370000",
    longitude: "-75.27102000",
  },
  {
    id: 122441,
    name: "Mountville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.03926000",
    longitude: "-76.43080000",
  },
  {
    id: 122449,
    name: "Muhlenberg Park",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.38482000",
    longitude: "-75.94132000",
  },
  {
    id: 122466,
    name: "Muncy",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.20564000",
    longitude: "-76.78552000",
  },
  {
    id: 122469,
    name: "Mundys Corner",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.44479000",
    longitude: "-78.84113000",
  },
  {
    id: 122473,
    name: "Munhall",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.39229000",
    longitude: "-79.90005000",
  },
  {
    id: 122500,
    name: "Murrysville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.42840000",
    longitude: "-79.69755000",
  },
  {
    id: 122507,
    name: "Muse",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.29285000",
    longitude: "-80.20034000",
  },
  {
    id: 122518,
    name: "Myerstown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.37454000",
    longitude: "-76.30273000",
  },
  {
    id: 122541,
    name: "Nanticoke",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.20536000",
    longitude: "-76.00492000",
  },
  {
    id: 122544,
    name: "Nanty Glo",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.47229000",
    longitude: "-78.83336000",
  },
  {
    id: 122563,
    name: "Narberth",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.00845000",
    longitude: "-75.26046000",
  },
  {
    id: 122606,
    name: "Nazareth",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.74038000",
    longitude: "-75.30962000",
  },
  {
    id: 122640,
    name: "Nescopeck",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.05203000",
    longitude: "-76.22077000",
  },
  {
    id: 122642,
    name: "Nesquehoning",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.86453000",
    longitude: "-75.81103000",
  },
  {
    id: 122661,
    name: "New Beaver",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.87645000",
    longitude: "-80.37062000",
  },
  {
    id: 122665,
    name: "New Berlinville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.34537000",
    longitude: "-75.63296000",
  },
  {
    id: 122667,
    name: "New Bloomfield",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.41981000",
    longitude: "-77.18637000",
  },
  {
    id: 122673,
    name: "New Brighton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.73034000",
    longitude: "-80.31006000",
  },
  {
    id: 122675,
    name: "New Britain",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.29900000",
    longitude: "-75.18101000",
  },
  {
    id: 122688,
    name: "New Castle",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.00367000",
    longitude: "-80.34701000",
  },
  {
    id: 122691,
    name: "New Castle Northwest",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.02208000",
    longitude: "-80.35682000",
  },
  {
    id: 122696,
    name: "New Columbia",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.04092000",
    longitude: "-76.86691000",
  },
  {
    id: 122697,
    name: "New Cumberland",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.23231000",
    longitude: "-76.88470000",
  },
  {
    id: 122702,
    name: "New Eagle",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.20785000",
    longitude: "-79.94699000",
  },
  {
    id: 122707,
    name: "New Freedom",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.73788000",
    longitude: "-76.70136000",
  },
  {
    id: 122721,
    name: "New Holland",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.10176000",
    longitude: "-76.08523000",
  },
  {
    id: 122727,
    name: "New Hope",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.36427000",
    longitude: "-74.95128000",
  },
  {
    id: 122733,
    name: "New Kensington",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.56979000",
    longitude: "-79.76477000",
  },
  {
    id: 122757,
    name: "New Oxford",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.86371000",
    longitude: "-77.05581000",
  },
  {
    id: 122762,
    name: "New Philadelphia",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.71953000",
    longitude: "-76.11577000",
  },
  {
    id: 122783,
    name: "New Stanton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.21924000",
    longitude: "-79.60948000",
  },
  {
    id: 122792,
    name: "New Wilmington",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.12228000",
    longitude: "-80.33284000",
  },
  {
    id: 122835,
    name: "Newmanstown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.34954000",
    longitude: "-76.21328000",
  },
  {
    id: 122848,
    name: "Newport",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.47786000",
    longitude: "-77.13054000",
  },
  {
    id: 122874,
    name: "Newtown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.22928000",
    longitude: "-74.93683000",
  },
  {
    id: 122876,
    name: "Newtown Grant",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.26011000",
    longitude: "-74.95489000",
  },
  {
    id: 122877,
    name: "Newville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.17314000",
    longitude: "-77.39860000",
  },
  {
    id: 122914,
    name: "Nixon",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.78340000",
    longitude: "-79.92950000",
  },
  {
    id: 122957,
    name: "Norristown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.12150000",
    longitude: "-75.33990000",
  },
  {
    id: 122964,
    name: "North Apollo",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.59618000",
    longitude: "-79.55560000",
  },
  {
    id: 122980,
    name: "North Belle Vernon",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.12924000",
    longitude: "-79.86810000",
  },
  {
    id: 122992,
    name: "North Braddock",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.39896000",
    longitude: "-79.84088000",
  },
  {
    id: 123002,
    name: "North Catasauqua",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.65982000",
    longitude: "-75.47685000",
  },
  {
    id: 123004,
    name: "North Charleroi",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.15118000",
    longitude: "-79.90755000",
  },
  {
    id: 123018,
    name: "North East",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "42.21561000",
    longitude: "-79.83422000",
  },
  {
    id: 123122,
    name: "North Versailles",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.37979000",
    longitude: "-79.80949000",
  },
  {
    id: 123123,
    name: "North Wales",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.21094000",
    longitude: "-75.27823000",
  },
  {
    id: 123125,
    name: "North Warren",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.87423000",
    longitude: "-79.15227000",
  },
  {
    id: 123133,
    name: "North York",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.97815000",
    longitude: "-76.73302000",
  },
  {
    id: 123135,
    name: "Northampton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.68621000",
    longitude: "-75.49685000",
  },
  {
    id: 123138,
    name: "Northampton County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.75423000",
    longitude: "-75.30742000",
  },
  {
    id: 123147,
    name: "Northern Cambria",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.65923000",
    longitude: "-78.78169000",
  },
  {
    id: 123166,
    name: "Northumberland",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.89175000",
    longitude: "-76.79747000",
  },
  {
    id: 123168,
    name: "Northumberland County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.85198000",
    longitude: "-76.70932000",
  },
  {
    id: 123175,
    name: "Northwest Harborcreek",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "42.14944000",
    longitude: "-79.99463000",
  },
  {
    id: 123198,
    name: "Norwood",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.89178000",
    longitude: "-75.29963000",
  },
  {
    id: 123247,
    name: "Oak Hills",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.82479000",
    longitude: "-79.91311000",
  },
  {
    id: 123273,
    name: "Oakdale",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.39812000",
    longitude: "-80.18561000",
  },
  {
    id: 123292,
    name: "Oakland",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.30646000",
    longitude: "-78.88752000",
  },
  {
    id: 123302,
    name: "Oakmont",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.52173000",
    longitude: "-79.84227000",
  },
  {
    id: 123311,
    name: "Oakwood",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.01062000",
    longitude: "-80.37951000",
  },
  {
    id: 123385,
    name: "Ohioville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.67923000",
    longitude: "-80.49479000",
  },
  {
    id: 123387,
    name: "Oil City",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.43395000",
    longitude: "-79.70644000",
  },
  {
    id: 123419,
    name: "Old Forge",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.37119000",
    longitude: "-75.73491000",
  },
  {
    id: 123424,
    name: "Old Orchard",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.65788000",
    longitude: "-75.26212000",
  },
  {
    id: 123436,
    name: "Oley",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.38759000",
    longitude: "-75.78964000",
  },
  {
    id: 123442,
    name: "Oliver",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.91869000",
    longitude: "-79.71782000",
  },
  {
    id: 123462,
    name: "Olyphant",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.46841000",
    longitude: "-75.60297000",
  },
  {
    id: 123534,
    name: "Orchard Hills",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.58618000",
    longitude: "-79.53143000",
  },
  {
    id: 123550,
    name: "Oreland",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.11844000",
    longitude: "-75.17768000",
  },
  {
    id: 123585,
    name: "Orwigsburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.65481000",
    longitude: "-76.10077000",
  },
  {
    id: 123606,
    name: "Osceola Mills",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.85006000",
    longitude: "-78.27057000",
  },
  {
    id: 123680,
    name: "Oxford",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.78539000",
    longitude: "-75.97883000",
  },
  {
    id: 123756,
    name: "Palmdale",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.29787000",
    longitude: "-76.61858000",
  },
  {
    id: 123761,
    name: "Palmer Heights",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.68732000",
    longitude: "-75.26240000",
  },
  {
    id: 123763,
    name: "Palmerton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.80140000",
    longitude: "-75.61190000",
  },
  {
    id: 123777,
    name: "Palmyra",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.30898000",
    longitude: "-76.59330000",
  },
  {
    id: 123780,
    name: "Palo Alto",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.68731000",
    longitude: "-76.17216000",
  },
  {
    id: 123812,
    name: "Paoli",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.04205000",
    longitude: "-75.47631000",
  },
  {
    id: 123817,
    name: "Paradise",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.00982000",
    longitude: "-76.12857000",
  },
  {
    id: 123846,
    name: "Park Forest Village",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.80673000",
    longitude: "-77.91695000",
  },
  {
    id: 123869,
    name: "Parkesburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.95872000",
    longitude: "-75.91939000",
  },
  {
    id: 123875,
    name: "Parkside",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.86428000",
    longitude: "-75.37853000",
  },
  {
    id: 123879,
    name: "Parkville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.78121000",
    longitude: "-76.96331000",
  },
  {
    id: 123918,
    name: "Patton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.63396000",
    longitude: "-78.65030000",
  },
  {
    id: 123940,
    name: "Paxtang",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.25898000",
    longitude: "-76.83192000",
  },
  {
    id: 123943,
    name: "Paxtonia",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.31731000",
    longitude: "-76.79442000",
  },
  {
    id: 124011,
    name: "Pen Argyl",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.86871000",
    longitude: "-75.25490000",
  },
  {
    id: 124012,
    name: "Penbrook",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.27537000",
    longitude: "-76.84803000",
  },
  {
    id: 124022,
    name: "Penn Estates",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.03750000",
    longitude: "-75.23956000",
  },
  {
    id: 124023,
    name: "Penn Hills",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.50118000",
    longitude: "-79.83922000",
  },
  {
    id: 124025,
    name: "Penn Wynne",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.98622000",
    longitude: "-75.27546000",
  },
  {
    id: 124027,
    name: "Penndel",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.15205000",
    longitude: "-74.91656000",
  },
  {
    id: 124036,
    name: "Pennsburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.39093000",
    longitude: "-75.49212000",
  },
  {
    id: 124037,
    name: "Pennside",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.33732000",
    longitude: "-75.87854000",
  },
  {
    id: 124038,
    name: "Pennsport",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.92761000",
    longitude: "-75.15045000",
  },
  {
    id: 124040,
    name: "Pennville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.78954000",
    longitude: "-76.99804000",
  },
  {
    id: 124044,
    name: "Penryn",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.20509000",
    longitude: "-76.36829000",
  },
  {
    id: 124061,
    name: "Perkasie",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.37205000",
    longitude: "-75.29268000",
  },
  {
    id: 124082,
    name: "Perry County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.39841000",
    longitude: "-77.26230000",
  },
  {
    id: 124086,
    name: "Perryopolis",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.08702000",
    longitude: "-79.75060000",
  },
  {
    id: 124126,
    name: "Philadelphia",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.95233000",
    longitude: "-75.16379000",
  },
  {
    id: 124128,
    name: "Philadelphia County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.00764000",
    longitude: "-75.13396000",
  },
  {
    id: 124131,
    name: "Philipsburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.89645000",
    longitude: "-78.22057000",
  },
  {
    id: 124151,
    name: "Phoenixville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.13038000",
    longitude: "-75.51491000",
  },
  {
    id: 124193,
    name: "Pike County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.33199000",
    longitude: "-75.03383000",
  },
  {
    id: 124221,
    name: "Pine Grove",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.54842000",
    longitude: "-76.38468000",
  },
  {
    id: 124223,
    name: "Pine Grove Mills",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.73367000",
    longitude: "-77.88556000",
  },
  {
    id: 124244,
    name: "Pine Ridge",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.14598000",
    longitude: "-74.99116000",
  },
  {
    id: 124285,
    name: "Pitcairn",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.40312000",
    longitude: "-79.77810000",
  },
  {
    id: 124296,
    name: "Pittsburgh",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.44062000",
    longitude: "-79.99589000",
  },
  {
    id: 124304,
    name: "Pittston",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.32591000",
    longitude: "-75.78936000",
  },
  {
    id: 124323,
    name: "Plains",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.27536000",
    longitude: "-75.85020000",
  },
  {
    id: 124364,
    name: "Pleasant Gap",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.86812000",
    longitude: "-77.74667000",
  },
  {
    id: 124371,
    name: "Pleasant Hill",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.33620000",
    longitude: "-76.44163000",
  },
  {
    id: 124374,
    name: "Pleasant Hills",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.33563000",
    longitude: "-79.96061000",
  },
  {
    id: 124392,
    name: "Plum",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.50035000",
    longitude: "-79.74949000",
  },
  {
    id: 124398,
    name: "Plumsteadville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.38733000",
    longitude: "-75.14656000",
  },
  {
    id: 124407,
    name: "Plymouth",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.24036000",
    longitude: "-75.94464000",
  },
  {
    id: 124411,
    name: "Plymouth Meeting",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.10233000",
    longitude: "-75.27435000",
  },
  {
    id: 124421,
    name: "Pocono Pines",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.10675000",
    longitude: "-75.45435000",
  },
  {
    id: 124422,
    name: "Pocono Ranch Lands",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.16454000",
    longitude: "-74.95212000",
  },
  {
    id: 124429,
    name: "Point Marion",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.73897000",
    longitude: "-79.89867000",
  },
  {
    id: 124491,
    name: "Port Allegany",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.81090000",
    longitude: "-78.27974000",
  },
  {
    id: 124501,
    name: "Port Carbon",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.69648000",
    longitude: "-76.16883000",
  },
  {
    id: 124538,
    name: "Port Vue",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.33590000",
    longitude: "-79.86977000",
  },
  {
    id: 124545,
    name: "Portage",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.38868000",
    longitude: "-78.67224000",
  },
  {
    id: 124592,
    name: "Potter County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.74492000",
    longitude: "-77.89581000",
  },
  {
    id: 124598,
    name: "Pottsgrove",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.26482000",
    longitude: "-75.61185000",
  },
  {
    id: 124599,
    name: "Pottstown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.24537000",
    longitude: "-75.64963000",
  },
  {
    id: 124601,
    name: "Pottsville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.68565000",
    longitude: "-76.19550000",
  },
  {
    id: 124702,
    name: "Progress",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.28509000",
    longitude: "-76.83136000",
  },
  {
    id: 124707,
    name: "Prospect",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.90451000",
    longitude: "-80.04645000",
  },
  {
    id: 124709,
    name: "Prospect Park",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.88789000",
    longitude: "-75.30824000",
  },
  {
    id: 124751,
    name: "Punxsutawney",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.94368000",
    longitude: "-78.97087000",
  },
  {
    id: 124772,
    name: "Pymatuning Central",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.58546000",
    longitude: "-80.47960000",
  },
  {
    id: 124778,
    name: "Quakertown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.44177000",
    longitude: "-75.34157000",
  },
  {
    id: 124781,
    name: "Quarryville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.89705000",
    longitude: "-76.16357000",
  },
  {
    id: 124818,
    name: "Radnor",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.04622000",
    longitude: "-75.35991000",
  },
  {
    id: 124883,
    name: "Rankin",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.41257000",
    longitude: "-79.87922000",
  },
  {
    id: 124902,
    name: "Raubsville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.63566000",
    longitude: "-75.19295000",
  },
  {
    id: 124931,
    name: "Reading",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.33565000",
    longitude: "-75.92687000",
  },
  {
    id: 124934,
    name: "Reamstown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.21148000",
    longitude: "-76.12328000",
  },
  {
    id: 124947,
    name: "Red Hill",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.37288000",
    longitude: "-75.48101000",
  },
  {
    id: 124953,
    name: "Red Lion",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.90093000",
    longitude: "-76.60580000",
  },
  {
    id: 125009,
    name: "Reiffton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.31981000",
    longitude: "-75.87354000",
  },
  {
    id: 125011,
    name: "Reinholds",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.26676000",
    longitude: "-76.11550000",
  },
  {
    id: 125018,
    name: "Rennerdale",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.39840000",
    longitude: "-80.14145000",
  },
  {
    id: 125022,
    name: "Renovo",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.32646000",
    longitude: "-77.75082000",
  },
  {
    id: 125031,
    name: "Republic",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.96258000",
    longitude: "-79.87671000",
  },
  {
    id: 125041,
    name: "Reynolds Heights",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.34506000",
    longitude: "-80.39423000",
  },
  {
    id: 125042,
    name: "Reynoldsville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.09701000",
    longitude: "-78.88864000",
  },
  {
    id: 125044,
    name: "Rheems",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.13009000",
    longitude: "-76.57052000",
  },
  {
    id: 125060,
    name: "Richboro",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.21511000",
    longitude: "-75.01072000",
  },
  {
    id: 125071,
    name: "Richland",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.35926000",
    longitude: "-76.25828000",
  },
  {
    id: 125083,
    name: "Richlandtown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.47010000",
    longitude: "-75.32046000",
  },
  {
    id: 125133,
    name: "Ridgway",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.42034000",
    longitude: "-78.72864000",
  },
  {
    id: 125134,
    name: "Ridley Park",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.88122000",
    longitude: "-75.32380000",
  },
  {
    id: 125189,
    name: "River View Park",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.39259000",
    longitude: "-75.95882000",
  },
  {
    id: 125208,
    name: "Riverside",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.95536000",
    longitude: "-76.62885000",
  },
  {
    id: 125233,
    name: "Roaring Spring",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.33591000",
    longitude: "-78.39085000",
  },
  {
    id: 125250,
    name: "Robesonia",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.35176000",
    longitude: "-76.13439000",
  },
  {
    id: 125268,
    name: "Rochester",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.70229000",
    longitude: "-80.28645000",
  },
  {
    id: 125312,
    name: "Rockledge",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.08122000",
    longitude: "-75.08962000",
  },
  {
    id: 125438,
    name: "Roseto",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.88065000",
    longitude: "-75.21462000",
  },
  {
    id: 125466,
    name: "Rothsville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.15121000",
    longitude: "-76.25107000",
  },
  {
    id: 125497,
    name: "Royalton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.18731000",
    longitude: "-76.72997000",
  },
  {
    id: 125498,
    name: "Royersford",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.18427000",
    longitude: "-75.53796000",
  },
  {
    id: 125535,
    name: "Russell",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.94145000",
    longitude: "-79.13505000",
  },
  {
    id: 125541,
    name: "Russellton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.61146000",
    longitude: "-79.83700000",
  },
  {
    id: 125550,
    name: "Rutherford",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.26898000",
    longitude: "-76.76803000",
  },
  {
    id: 125620,
    name: "Saint Clair",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.72065000",
    longitude: "-76.19105000",
  },
  {
    id: 125678,
    name: "Saint Lawrence",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.32704000",
    longitude: "-75.87187000",
  },
  {
    id: 125694,
    name: "Saint Marys",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.42784000",
    longitude: "-78.56086000",
  },
  {
    id: 125761,
    name: "Salix",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.30008000",
    longitude: "-78.76530000",
  },
  {
    id: 125775,
    name: "Salunga",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.10093000",
    longitude: "-76.42469000",
  },
  {
    id: 125852,
    name: "Sanatoga",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.24510000",
    longitude: "-75.59518000",
  },
  {
    id: 125858,
    name: "Sand Hill",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.35954000",
    longitude: "-76.43163000",
  },
  {
    id: 125879,
    name: "Sandy",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.10784000",
    longitude: "-78.77114000",
  },
  {
    id: 125979,
    name: "Saw Creek",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.11259000",
    longitude: "-75.05073000",
  },
  {
    id: 125985,
    name: "Saxonburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.75395000",
    longitude: "-79.81005000",
  },
  {
    id: 125987,
    name: "Saylorsburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.89565000",
    longitude: "-75.32352000",
  },
  {
    id: 125990,
    name: "Sayre",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.97896000",
    longitude: "-76.51550000",
  },
  {
    id: 126010,
    name: "Schlusser",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.24175000",
    longitude: "-77.17693000",
  },
  {
    id: 126011,
    name: "Schnecksville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.67514000",
    longitude: "-75.62044000",
  },
  {
    id: 126012,
    name: "Schoeneck",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.24148000",
    longitude: "-76.17411000",
  },
  {
    id: 126026,
    name: "Schuylkill County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.70580000",
    longitude: "-76.21595000",
  },
  {
    id: 126027,
    name: "Schuylkill Haven",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.63065000",
    longitude: "-76.17105000",
  },
  {
    id: 126028,
    name: "Schwenksville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.25621000",
    longitude: "-75.46379000",
  },
  {
    id: 126035,
    name: "Scotland",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.96870000",
    longitude: "-77.58721000",
  },
  {
    id: 126056,
    name: "Scottdale",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.10035000",
    longitude: "-79.58698000",
  },
  {
    id: 126067,
    name: "Scranton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.40916000",
    longitude: "-75.66490000",
  },
  {
    id: 126134,
    name: "Selinsgrove",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.79897000",
    longitude: "-76.86219000",
  },
  {
    id: 126136,
    name: "Sellersville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.35399000",
    longitude: "-75.30490000",
  },
  {
    id: 126158,
    name: "Seneca",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.37867000",
    longitude: "-79.70394000",
  },
  {
    id: 126172,
    name: "Seven Fields",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.69173000",
    longitude: "-80.06256000",
  },
  {
    id: 126191,
    name: "Sewickley",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.53646000",
    longitude: "-80.18450000",
  },
  {
    id: 126214,
    name: "Shamokin",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.78897000",
    longitude: "-76.55885000",
  },
  {
    id: 126215,
    name: "Shamokin Dam",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.84870000",
    longitude: "-76.81969000",
  },
  {
    id: 126223,
    name: "Shanor-Northvue",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.91045000",
    longitude: "-79.91562000",
  },
  {
    id: 126229,
    name: "Sharon",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.23311000",
    longitude: "-80.49340000",
  },
  {
    id: 126231,
    name: "Sharon Hill",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.90650000",
    longitude: "-75.27157000",
  },
  {
    id: 126236,
    name: "Sharpsburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.49451000",
    longitude: "-79.92644000",
  },
  {
    id: 126237,
    name: "Sharpsville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.25922000",
    longitude: "-80.47201000",
  },
  {
    id: 126243,
    name: "Shavertown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.31980000",
    longitude: "-75.93798000",
  },
  {
    id: 126261,
    name: "Sheffield",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.70395000",
    longitude: "-79.03560000",
  },
  {
    id: 126304,
    name: "Shenandoah",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.82037000",
    longitude: "-76.20077000",
  },
  {
    id: 126307,
    name: "Shenandoah Heights",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.82759000",
    longitude: "-76.20688000",
  },
  {
    id: 126347,
    name: "Shillington",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.30787000",
    longitude: "-75.96549000",
  },
  {
    id: 126349,
    name: "Shiloh",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.97815000",
    longitude: "-76.79719000",
  },
  {
    id: 126352,
    name: "Shinglehouse",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.96368000",
    longitude: "-78.19084000",
  },
  {
    id: 126357,
    name: "Shippensburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.05065000",
    longitude: "-77.52026000",
  },
  {
    id: 126359,
    name: "Shiremanstown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.22342000",
    longitude: "-76.95359000",
  },
  {
    id: 126365,
    name: "Shoemakersville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.50093000",
    longitude: "-75.96993000",
  },
  {
    id: 126384,
    name: "Shrewsbury",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.76871000",
    longitude: "-76.67969000",
  },
  {
    id: 126405,
    name: "Sierra View",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.01207000",
    longitude: "-75.45900000",
  },
  {
    id: 126448,
    name: "Simpson",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.59175000",
    longitude: "-75.48518000",
  },
  {
    id: 126455,
    name: "Sinking Spring",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.32731000",
    longitude: "-76.01105000",
  },
  {
    id: 126481,
    name: "Skippack",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.22288000",
    longitude: "-75.39879000",
  },
  {
    id: 126487,
    name: "Skyline View",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.33926000",
    longitude: "-76.72553000",
  },
  {
    id: 126492,
    name: "Slatington",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.74843000",
    longitude: "-75.61185000",
  },
  {
    id: 126503,
    name: "Slippery Rock",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.06395000",
    longitude: "-80.05645000",
  },
  {
    id: 126509,
    name: "Smethport",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.81117000",
    longitude: "-78.44474000",
  },
  {
    id: 126551,
    name: "Snyder County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.76984000",
    longitude: "-77.07019000",
  },
  {
    id: 126584,
    name: "Somerset",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.00841000",
    longitude: "-79.07808000",
  },
  {
    id: 126589,
    name: "Somerset County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.97244000",
    longitude: "-79.02827000",
  },
  {
    id: 126607,
    name: "Souderton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.31177000",
    longitude: "-75.32518000",
  },
  {
    id: 126640,
    name: "South Coatesville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.97427000",
    longitude: "-75.81995000",
  },
  {
    id: 126642,
    name: "South Connellsville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.99674000",
    longitude: "-79.58587000",
  },
  {
    id: 126663,
    name: "South Greensburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.27840000",
    longitude: "-79.54476000",
  },
  {
    id: 126704,
    name: "South Park Township",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.29864000",
    longitude: "-79.99405000",
  },
  {
    id: 126715,
    name: "South Pottstown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.23954000",
    longitude: "-75.65102000",
  },
  {
    id: 126734,
    name: "South Temple",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.40000000",
    longitude: "-75.90000000",
  },
  {
    id: 126738,
    name: "South Uniontown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.89285000",
    longitude: "-79.74699000",
  },
  {
    id: 126744,
    name: "South Waverly",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.99757000",
    longitude: "-76.53717000",
  },
  {
    id: 126750,
    name: "South Williamsport",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.23202000",
    longitude: "-76.99913000",
  },
  {
    id: 126780,
    name: "Southmont",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.31063000",
    longitude: "-78.93864000",
  },
  {
    id: 126789,
    name: "Southwest Greensburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.29118000",
    longitude: "-79.54698000",
  },
  {
    id: 126797,
    name: "Spangler",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.64285000",
    longitude: "-78.77280000",
  },
  {
    id: 126819,
    name: "Speers",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.12452000",
    longitude: "-79.87977000",
  },
  {
    id: 126837,
    name: "Spinnerstown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.43899000",
    longitude: "-75.43712000",
  },
  {
    id: 126852,
    name: "Spring City",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.17677000",
    longitude: "-75.54769000",
  },
  {
    id: 126857,
    name: "Spring Grove",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.87454000",
    longitude: "-76.86581000",
  },
  {
    id: 126864,
    name: "Spring House",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.18539000",
    longitude: "-75.22768000",
  },
  {
    id: 126870,
    name: "Spring Mount",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.27566000",
    longitude: "-75.45657000",
  },
  {
    id: 126873,
    name: "Spring Ridge",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.35287000",
    longitude: "-75.98994000",
  },
  {
    id: 126887,
    name: "Springdale",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.54090000",
    longitude: "-79.78394000",
  },
  {
    id: 126894,
    name: "Springfield",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.93067000",
    longitude: "-75.32019000",
  },
  {
    id: 126920,
    name: "Spry",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.91843000",
    longitude: "-76.68497000",
  },
  {
    id: 126992,
    name: "State College",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.79339000",
    longitude: "-77.86000000",
  },
  {
    id: 126993,
    name: "State Line",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.72482000",
    longitude: "-77.72444000",
  },
  {
    id: 127013,
    name: "Steelton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.23537000",
    longitude: "-76.84136000",
  },
  {
    id: 127053,
    name: "Stewartstown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.75371000",
    longitude: "-76.59136000",
  },
  {
    id: 127058,
    name: "Stiles",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.66537000",
    longitude: "-75.50824000",
  },
  {
    id: 127086,
    name: "Stoneboro",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.33922000",
    longitude: "-80.10506000",
  },
  {
    id: 127097,
    name: "Stony Creek Mills",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.34565000",
    longitude: "-75.86993000",
  },
  {
    id: 127101,
    name: "Stonybrook",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.98704000",
    longitude: "-76.64413000",
  },
  {
    id: 127104,
    name: "Stormstown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.79339000",
    longitude: "-78.01667000",
  },
  {
    id: 127113,
    name: "Stowe",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.25260000",
    longitude: "-75.67741000",
  },
  {
    id: 127119,
    name: "Strasburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.98316000",
    longitude: "-76.18412000",
  },
  {
    id: 127140,
    name: "Stroudsburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.98676000",
    longitude: "-75.19462000",
  },
  {
    id: 127148,
    name: "Sturgeon",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.38479000",
    longitude: "-80.21089000",
  },
  {
    id: 127174,
    name: "Sugarcreek",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.42145000",
    longitude: "-79.88117000",
  },
  {
    id: 127191,
    name: "Sullivan County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.44616000",
    longitude: "-76.51214000",
  },
  {
    id: 127218,
    name: "Summit Hill",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.82481000",
    longitude: "-75.87103000",
  },
  {
    id: 127239,
    name: "Sun Valley",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.98203000",
    longitude: "-75.46602000",
  },
  {
    id: 127245,
    name: "Sunbury",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.86259000",
    longitude: "-76.79441000",
  },
  {
    id: 127268,
    name: "Sunrise Lake",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.30981000",
    longitude: "-74.96656000",
  },
  {
    id: 127297,
    name: "Susquehanna",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.94341000",
    longitude: "-75.59963000",
  },
  {
    id: 127298,
    name: "Susquehanna County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.82133000",
    longitude: "-75.80068000",
  },
  {
    id: 127299,
    name: "Susquehanna Trails",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.75872000",
    longitude: "-76.36802000",
  },
  {
    id: 127330,
    name: "Swarthmore",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.90206000",
    longitude: "-75.34991000",
  },
  {
    id: 127333,
    name: "Swartzville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.23315000",
    longitude: "-76.07828000",
  },
  {
    id: 127348,
    name: "Swissvale",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.42368000",
    longitude: "-79.88283000",
  },
  {
    id: 127350,
    name: "Swoyersville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.29175000",
    longitude: "-75.87464000",
  },
  {
    id: 127353,
    name: "Sykesville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.05034000",
    longitude: "-78.82225000",
  },
  {
    id: 127370,
    name: "Tacony",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.03122000",
    longitude: "-75.04434000",
  },
  {
    id: 127404,
    name: "Tamaqua",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.79731000",
    longitude: "-75.96937000",
  },
  {
    id: 127419,
    name: "Tannersville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.04009000",
    longitude: "-75.30574000",
  },
  {
    id: 127429,
    name: "Tarentum",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.60146000",
    longitude: "-79.75977000",
  },
  {
    id: 127437,
    name: "Tatamy",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.74093000",
    longitude: "-75.25712000",
  },
  {
    id: 127449,
    name: "Taylor",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.39480000",
    longitude: "-75.70658000",
  },
  {
    id: 127487,
    name: "Telford",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.32205000",
    longitude: "-75.32795000",
  },
  {
    id: 127500,
    name: "Temple",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.40870000",
    longitude: "-75.92160000",
  },
  {
    id: 127522,
    name: "Terre Hill",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.15732000",
    longitude: "-76.05050000",
  },
  {
    id: 127560,
    name: "The Hideout",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.42736000",
    longitude: "-75.35255000",
  },
  {
    id: 127595,
    name: "Thompsonville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.29090000",
    longitude: "-80.10811000",
  },
  {
    id: 127599,
    name: "Thorndale",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.99288000",
    longitude: "-75.74522000",
  },
  {
    id: 127626,
    name: "Throop",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.45147000",
    longitude: "-75.61185000",
  },
  {
    id: 127665,
    name: "Tinicum",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.44844000",
    longitude: "-75.10767000",
  },
  {
    id: 127670,
    name: "Tioga County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.77216000",
    longitude: "-77.25426000",
  },
  {
    id: 127671,
    name: "Tionesta",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.49534000",
    longitude: "-79.45588000",
  },
  {
    id: 127677,
    name: "Tipton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.63590000",
    longitude: "-78.29585000",
  },
  {
    id: 127686,
    name: "Titusville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.62700000",
    longitude: "-79.67366000",
  },
  {
    id: 127696,
    name: "Toftrees",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.82604000",
    longitude: "-77.88110000",
  },
  {
    id: 127737,
    name: "Topton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.50343000",
    longitude: "-75.70130000",
  },
  {
    id: 127748,
    name: "Toughkenamon",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.83150000",
    longitude: "-75.75744000",
  },
  {
    id: 127750,
    name: "Towamensing Trails",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.00787000",
    longitude: "-75.58463000",
  },
  {
    id: 127752,
    name: "Towanda",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.76758000",
    longitude: "-76.44272000",
  },
  {
    id: 127754,
    name: "Tower City",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.58925000",
    longitude: "-76.55246000",
  },
  {
    id: 127776,
    name: "Trafford",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.38562000",
    longitude: "-79.75893000",
  },
  {
    id: 127779,
    name: "Trainer",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.82761000",
    longitude: "-75.41436000",
  },
  {
    id: 127782,
    name: "Trappe",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.19899000",
    longitude: "-75.47629000",
  },
  {
    id: 127791,
    name: "Treasure Lake",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.17339000",
    longitude: "-78.71586000",
  },
  {
    id: 127796,
    name: "Tremont",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.62842000",
    longitude: "-76.38718000",
  },
  {
    id: 127813,
    name: "Trevorton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.78120000",
    longitude: "-76.67302000",
  },
  {
    id: 127814,
    name: "Trevose",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.13928000",
    longitude: "-74.98100000",
  },
  {
    id: 127815,
    name: "Trexlertown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.54815000",
    longitude: "-75.60574000",
  },
  {
    id: 127833,
    name: "Trooper",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.14983000",
    longitude: "-75.40185000",
  },
  {
    id: 127852,
    name: "Troy",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.78591000",
    longitude: "-76.78801000",
  },
  {
    id: 127854,
    name: "Trucksville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.30397000",
    longitude: "-75.93214000",
  },
  {
    id: 127887,
    name: "Tullytown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.13928000",
    longitude: "-74.81461000",
  },
  {
    id: 127894,
    name: "Tunkhannock",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.53869000",
    longitude: "-75.94659000",
  },
  {
    id: 127907,
    name: "Turtle Creek",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.40590000",
    longitude: "-79.82505000",
  },
  {
    id: 127950,
    name: "Tyrone",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.67062000",
    longitude: "-78.23862000",
  },
  {
    id: 127986,
    name: "Union City",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.89950000",
    longitude: "-79.84533000",
  },
  {
    id: 128000,
    name: "Union County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.96297000",
    longitude: "-77.06225000",
  },
  {
    id: 128016,
    name: "Uniontown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.90008000",
    longitude: "-79.71643000",
  },
  {
    id: 128037,
    name: "Upland",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.85261000",
    longitude: "-75.38269000",
  },
  {
    id: 128051,
    name: "Upper Saint Clair",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.33590000",
    longitude: "-80.08339000",
  },
  {
    id: 128111,
    name: "Valley Green",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.15731000",
    longitude: "-76.79275000",
  },
  {
    id: 128118,
    name: "Valley View",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.95010000",
    longitude: "-76.70108000",
  },
  {
    id: 128152,
    name: "Vandergrift",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.60284000",
    longitude: "-79.56477000",
  },
  {
    id: 128166,
    name: "Venango County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.40097000",
    longitude: "-79.75795000",
  },
  {
    id: 128204,
    name: "Verona",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.50646000",
    longitude: "-79.84310000",
  },
  {
    id: 128211,
    name: "Versailles",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.31563000",
    longitude: "-79.83116000",
  },
  {
    id: 128248,
    name: "Village Green-Green Ridge",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.86363000",
    longitude: "-75.42548000",
  },
  {
    id: 128251,
    name: "Village Shires",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.20316000",
    longitude: "-74.97045000",
  },
  {
    id: 128267,
    name: "Vinco",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.40507000",
    longitude: "-78.85558000",
  },
  {
    id: 128402,
    name: "Wallenpaupack Lake Estates",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.39898000",
    longitude: "-75.27402000",
  },
  {
    id: 128424,
    name: "Walnutport",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.75426000",
    longitude: "-75.59880000",
  },
  {
    id: 128472,
    name: "Warminster Heights",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.18705000",
    longitude: "-75.08156000",
  },
  {
    id: 128483,
    name: "Warren",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.84395000",
    longitude: "-79.14504000",
  },
  {
    id: 128498,
    name: "Warren County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.81457000",
    longitude: "-79.27414000",
  },
  {
    id: 128549,
    name: "Washington",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.17396000",
    longitude: "-80.24617000",
  },
  {
    id: 128573,
    name: "Washington County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.18940000",
    longitude: "-80.24824000",
  },
  {
    id: 128607,
    name: "Waterford",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.94283000",
    longitude: "-79.98450000",
  },
  {
    id: 128640,
    name: "Watsontown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.08453000",
    longitude: "-76.86385000",
  },
  {
    id: 128680,
    name: "Waymart",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.58036000",
    longitude: "-75.40824000",
  },
  {
    id: 128687,
    name: "Wayne",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.04400000",
    longitude: "-75.38769000",
  },
  {
    id: 128702,
    name: "Wayne County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.64873000",
    longitude: "-75.30326000",
  },
  {
    id: 128704,
    name: "Wayne Heights",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.74371000",
    longitude: "-77.55388000",
  },
  {
    id: 128707,
    name: "Waynesboro",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.75593000",
    longitude: "-77.57777000",
  },
  {
    id: 128710,
    name: "Waynesburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.89646000",
    longitude: "-80.17923000",
  },
  {
    id: 128719,
    name: "Weatherly",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.94175000",
    longitude: "-75.82964000",
  },
  {
    id: 128757,
    name: "Weigelstown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.98371000",
    longitude: "-76.82247000",
  },
  {
    id: 128762,
    name: "Weissport East",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.83697000",
    longitude: "-75.68643000",
  },
  {
    id: 128788,
    name: "Wellsboro",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.74868000",
    longitude: "-77.30053000",
  },
  {
    id: 128806,
    name: "Wernersville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.33009000",
    longitude: "-76.08050000",
  },
  {
    id: 128807,
    name: "Wescosville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.56676000",
    longitude: "-75.55296000",
  },
  {
    id: 128812,
    name: "Wesleyville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "42.14033000",
    longitude: "-80.01506000",
  },
  {
    id: 128845,
    name: "West Chester",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.96097000",
    longitude: "-75.60804000",
  },
  {
    id: 128851,
    name: "West Conshohocken",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.06983000",
    longitude: "-75.31630000",
  },
  {
    id: 128859,
    name: "West Easton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.67871000",
    longitude: "-75.23684000",
  },
  {
    id: 128866,
    name: "West Fairview",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.27509000",
    longitude: "-76.91553000",
  },
  {
    id: 128881,
    name: "West Grove",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.82205000",
    longitude: "-75.82744000",
  },
  {
    id: 128883,
    name: "West Hamburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.54759000",
    longitude: "-76.00216000",
  },
  {
    id: 128892,
    name: "West Hazleton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.95870000",
    longitude: "-75.99604000",
  },
  {
    id: 128896,
    name: "West Hills",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.82423000",
    longitude: "-79.54310000",
  },
  {
    id: 128900,
    name: "West Homestead",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.39396000",
    longitude: "-79.91199000",
  },
  {
    id: 128907,
    name: "West Kittanning",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.81034000",
    longitude: "-79.52949000",
  },
  {
    id: 128914,
    name: "West Lawn",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.32981000",
    longitude: "-75.99438000",
  },
  {
    id: 128915,
    name: "West Leechburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.62229000",
    longitude: "-79.61282000",
  },
  {
    id: 128926,
    name: "West Mayfield",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.78006000",
    longitude: "-80.33840000",
  },
  {
    id: 128931,
    name: "West Mifflin",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.36340000",
    longitude: "-79.86644000",
  },
  {
    id: 128940,
    name: "West Newton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.20979000",
    longitude: "-79.76699000",
  },
  {
    id: 128941,
    name: "West Norriton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.12955000",
    longitude: "-75.37852000",
  },
  {
    id: 128955,
    name: "West Pittston",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.32758000",
    longitude: "-75.79297000",
  },
  {
    id: 128967,
    name: "West Reading",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.33370000",
    longitude: "-75.94743000",
  },
  {
    id: 128999,
    name: "West View",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.52229000",
    longitude: "-80.03422000",
  },
  {
    id: 129006,
    name: "West Wyoming",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.31980000",
    longitude: "-75.84603000",
  },
  {
    id: 129007,
    name: "West Wyomissing",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.32454000",
    longitude: "-75.99077000",
  },
  {
    id: 129010,
    name: "West York",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.95260000",
    longitude: "-76.75136000",
  },
  {
    id: 129031,
    name: "Westfield",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.91924000",
    longitude: "-77.53887000",
  },
  {
    id: 129050,
    name: "Westmont",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.31563000",
    longitude: "-78.95169000",
  },
  {
    id: 129056,
    name: "Westmoreland County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.31073000",
    longitude: "-79.46696000",
  },
  {
    id: 129101,
    name: "Wharton",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.92678000",
    longitude: "-75.15712000",
  },
  {
    id: 129125,
    name: "Whitaker",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.39840000",
    longitude: "-79.88977000",
  },
  {
    id: 129141,
    name: "White Haven",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.06064000",
    longitude: "-75.77408000",
  },
  {
    id: 129150,
    name: "White Oak",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.33757000",
    longitude: "-79.80921000",
  },
  {
    id: 129170,
    name: "Whitehall",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.36118000",
    longitude: "-79.99089000",
  },
  {
    id: 129173,
    name: "Whitehall Township",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.66676000",
    longitude: "-75.49991000",
  },
  {
    id: 129194,
    name: "Whitfield",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.33593000",
    longitude: "-76.00605000",
  },
  {
    id: 129202,
    name: "Whitman",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.91678000",
    longitude: "-75.15546000",
  },
  {
    id: 129220,
    name: "Wickerham Manor-Fisher",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.17749000",
    longitude: "-79.90684000",
  },
  {
    id: 129246,
    name: "Wilkes-Barre",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.24591000",
    longitude: "-75.88131000",
  },
  {
    id: 129249,
    name: "Wilkinsburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.44174000",
    longitude: "-79.88199000",
  },
  {
    id: 129271,
    name: "Williamsburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.46202000",
    longitude: "-78.19973000",
  },
  {
    id: 129281,
    name: "Williamsport",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.24119000",
    longitude: "-77.00108000",
  },
  {
    id: 129290,
    name: "Williamstown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.58009000",
    longitude: "-76.61774000",
  },
  {
    id: 129306,
    name: "Willow Grove",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.14400000",
    longitude: "-75.11573000",
  },
  {
    id: 129311,
    name: "Willow Street",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.97927000",
    longitude: "-76.27635000",
  },
  {
    id: 129319,
    name: "Wilmerding",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.39090000",
    longitude: "-79.81005000",
  },
  {
    id: 129332,
    name: "Wilson",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.68399000",
    longitude: "-75.24184000",
  },
  {
    id: 129363,
    name: "Wind Gap",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.84815000",
    longitude: "-75.29157000",
  },
  {
    id: 129366,
    name: "Windber",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.23980000",
    longitude: "-78.83502000",
  },
  {
    id: 129380,
    name: "Windsor",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.91621000",
    longitude: "-76.58441000",
  },
  {
    id: 129475,
    name: "Wolfdale",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.19285000",
    longitude: "-80.28784000",
  },
  {
    id: 129482,
    name: "Womelsdorf",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.36176000",
    longitude: "-76.18411000",
  },
  {
    id: 129497,
    name: "Woodbourne",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.19233000",
    longitude: "-74.88878000",
  },
  {
    id: 129529,
    name: "Woodland Heights",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.40978000",
    longitude: "-79.71172000",
  },
  {
    id: 129539,
    name: "Woodlyn",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.87233000",
    longitude: "-75.33713000",
  },
  {
    id: 129555,
    name: "Woodside",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.22178000",
    longitude: "-74.87544000",
  },
  {
    id: 129590,
    name: "Wormleysburg",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.26287000",
    longitude: "-76.91386000",
  },
  {
    id: 129600,
    name: "Woxall",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.31066000",
    longitude: "-75.44879000",
  },
  {
    id: 129615,
    name: "Wrightsville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.02565000",
    longitude: "-76.52997000",
  },
  {
    id: 129629,
    name: "Wyncote",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.09455000",
    longitude: "-75.14879000",
  },
  {
    id: 129631,
    name: "Wyndmoor",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.08122000",
    longitude: "-75.18934000",
  },
  {
    id: 129638,
    name: "Wyoming",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.31175000",
    longitude: "-75.83742000",
  },
  {
    id: 129641,
    name: "Wyoming County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.51833000",
    longitude: "-76.01655000",
  },
  {
    id: 129642,
    name: "Wyomissing",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.32954000",
    longitude: "-75.96521000",
  },
  {
    id: 129643,
    name: "Wyomissing Hills",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.33759000",
    longitude: "-75.97966000",
  },
  {
    id: 129664,
    name: "Yardley",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.24566000",
    longitude: "-74.84600000",
  },
  {
    id: 129675,
    name: "Yeadon",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.93900000",
    longitude: "-75.25546000",
  },
  {
    id: 129676,
    name: "Yeagertown",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.64313000",
    longitude: "-77.58055000",
  },
  {
    id: 129685,
    name: "Yoe",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.90899000",
    longitude: "-76.63691000",
  },
  {
    id: 129692,
    name: "York",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.96260000",
    longitude: "-76.72774000",
  },
  {
    id: 129699,
    name: "York County",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.91996000",
    longitude: "-76.72651000",
  },
  {
    id: 129702,
    name: "Yorklyn",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "39.99232000",
    longitude: "-76.64635000",
  },
  {
    id: 129720,
    name: "Youngsville",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "41.85228000",
    longitude: "-79.31866000",
  },
  {
    id: 129722,
    name: "Youngwood",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.24035000",
    longitude: "-79.57671000",
  },
  {
    id: 129746,
    name: "Zelienople",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.79451000",
    longitude: "-80.13673000",
  },
  {
    id: 129758,
    name: "Zion",
    state_id: 1422,
    state_code: "PA",
    country_id: 233,
    country_code: "US",
    latitude: "40.91423000",
    longitude: "-77.68472000",
  },
  {
    id: 142982,
    name: "Adjuntas",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.16277778",
    longitude: "-66.72222222",
  },
  {
    id: 142983,
    name: "Aguada",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.37944444",
    longitude: "-67.18833333",
  },
  {
    id: 142984,
    name: "Aguadilla",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.42745000",
    longitude: "-67.15407000",
  },
  {
    id: 142985,
    name: "Aguas Buenas",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.25694400",
    longitude: "-66.10305600",
  },
  {
    id: 142986,
    name: "Aguas Claras",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.24417000",
    longitude: "-65.66278000",
  },
  {
    id: 142987,
    name: "Aguilita",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.02333333",
    longitude: "-66.53472222",
  },
  {
    id: 142988,
    name: "Aibonito",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.14000000",
    longitude: "-66.26611100",
  },
  {
    id: 142993,
    name: "Añasco",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.31611111",
    longitude: "-67.13972222",
  },
  {
    id: 142989,
    name: "Animas",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.44555556",
    longitude: "-66.63500000",
  },
  {
    id: 142990,
    name: "Antón Ruiz",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.18527778",
    longitude: "-65.80861111",
  },
  {
    id: 142991,
    name: "Arecibo",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.47055556",
    longitude: "-66.72083333",
  },
  {
    id: 142992,
    name: "Arroyo",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "17.96583300",
    longitude: "-66.06138900",
  },
  {
    id: 142994,
    name: "Bairoa",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.25916667",
    longitude: "-66.04055556",
  },
  {
    id: 142995,
    name: "Bajadero",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.42666667",
    longitude: "-66.68333333",
  },
  {
    id: 142996,
    name: "Bajandas",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.16278000",
    longitude: "-65.78167000",
  },
  {
    id: 142997,
    name: "Barahona",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.35138889",
    longitude: "-66.44555556",
  },
  {
    id: 142998,
    name: "Barceloneta",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.45633000",
    longitude: "-66.54128000",
  },
  {
    id: 142999,
    name: "Barranquitas",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.20357000",
    longitude: "-66.31211000",
  },
  {
    id: 143000,
    name: "Bartolo",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.36138889",
    longitude: "-65.83861111",
  },
  {
    id: 143001,
    name: "Bayamon",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.17777778",
    longitude: "-66.11333333",
  },
  {
    id: 143002,
    name: "Benitez",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.27361111",
    longitude: "-65.87916667",
  },
  {
    id: 143003,
    name: "Betances",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.02861111",
    longitude: "-67.13500000",
  },
  {
    id: 143004,
    name: "Boqueron",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.20750000",
    longitude: "-65.84861111",
  },
  {
    id: 143005,
    name: "Boquerón, Cabo Rojo",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.02691000",
    longitude: "-67.16907000",
  },
  {
    id: 143006,
    name: "Brenas",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.46722222",
    longitude: "-66.34111111",
  },
  {
    id: 143007,
    name: "Buena Vista",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "17.99638889",
    longitude: "-66.05194444",
  },
  {
    id: 143008,
    name: "Bufalo",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.41833333",
    longitude: "-66.57333333",
  },
  {
    id: 143009,
    name: "Caban",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.44333333",
    longitude: "-67.13611111",
  },
  {
    id: 143010,
    name: "Cabo Rojo",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.08666667",
    longitude: "-67.14583333",
  },
  {
    id: 143011,
    name: "Cabo Rojo Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.05635000",
    longitude: "-67.14685000",
  },
  {
    id: 143012,
    name: "Cacao",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.44028000",
    longitude: "-66.93861000",
  },
  {
    id: 143013,
    name: "Caguas",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.23333333",
    longitude: "-66.03333333",
  },
  {
    id: 143014,
    name: "Campanilla",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.42138889",
    longitude: "-66.23694444",
  },
  {
    id: 143015,
    name: "Campo Rico",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.33722222",
    longitude: "-65.89805556",
  },
  {
    id: 143016,
    name: "Camuy",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.48388900",
    longitude: "-66.84500000",
  },
  {
    id: 143017,
    name: "Candelaria",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.40416667",
    longitude: "-66.20888889",
  },
  {
    id: 143018,
    name: "Candelaria Arenas",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.41722222",
    longitude: "-66.21750000",
  },
  {
    id: 143019,
    name: "Candelero Arriba",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.10560000",
    longitude: "-65.83420000",
  },
  {
    id: 143020,
    name: "Canóvanas",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.37916700",
    longitude: "-65.90138900",
  },
  {
    id: 143021,
    name: "Capitanejo",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.01454000",
    longitude: "-66.53372000",
  },
  {
    id: 143022,
    name: "Carolina",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.38888889",
    longitude: "-65.96666667",
  },
  {
    id: 143023,
    name: "Carrizales",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.48194444",
    longitude: "-66.79000000",
  },
  {
    id: 143024,
    name: "Cataño",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.44134000",
    longitude: "-66.11822000",
  },
  {
    id: 143025,
    name: "Cayey",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.11500000",
    longitude: "-66.16139000",
  },
  {
    id: 143026,
    name: "Cayuco",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.29194000",
    longitude: "-66.73528000",
  },
  {
    id: 143027,
    name: "Ceiba",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.44638889",
    longitude: "-66.35083333",
  },
  {
    id: 143028,
    name: "Ceiba Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.26051000",
    longitude: "-65.67183000",
  },
  {
    id: 143029,
    name: "Celada",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.27166667",
    longitude: "-65.96611111",
  },
  {
    id: 143030,
    name: "Central Aguirre",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "17.95472000",
    longitude: "-66.22611000",
  },
  {
    id: 143031,
    name: "Ciales",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.33611100",
    longitude: "-66.46888900",
  },
  {
    id: 143032,
    name: "Ciales Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.29162000",
    longitude: "-66.51628000",
  },
  {
    id: 143033,
    name: "Cidra",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.17583333",
    longitude: "-66.16138889",
  },
  {
    id: 143034,
    name: "Coamo",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.08000000",
    longitude: "-66.35805556",
  },
  {
    id: 143035,
    name: "Coco",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.00722222",
    longitude: "-66.25944444",
  },
  {
    id: 143036,
    name: "Comerío",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.21801000",
    longitude: "-66.22600000",
  },
  {
    id: 143037,
    name: "Comerío Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.22718000",
    longitude: "-66.22183000",
  },
  {
    id: 143038,
    name: "Comunas",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.08722222",
    longitude: "-65.84388889",
  },
  {
    id: 143039,
    name: "Coquí",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "17.97416667",
    longitude: "-66.22722222",
  },
  {
    id: 143040,
    name: "Corazón",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "17.99277778",
    longitude: "-66.08500000",
  },
  {
    id: 143041,
    name: "Corcovado",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.45861111",
    longitude: "-66.77638889",
  },
  {
    id: 143042,
    name: "Corozal",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.34166667",
    longitude: "-66.31694444",
  },
  {
    id: 143043,
    name: "Corozal Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.30912000",
    longitude: "-66.32600000",
  },
  {
    id: 143044,
    name: "Coto Laurel",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.04969000",
    longitude: "-66.55128000",
  },
  {
    id: 143045,
    name: "Coto Norte",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.43078000",
    longitude: "-66.43989000",
  },
  {
    id: 143046,
    name: "Culebra",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.31666667",
    longitude: "-65.29027778",
  },
  {
    id: 143047,
    name: "Culebra barrio-pueblo",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.30646950",
    longitude: "-65.30258000",
  },
  {
    id: 143048,
    name: "Daguao",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.22638889",
    longitude: "-65.68333333",
  },
  {
    id: 143049,
    name: "Dorado",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.45888900",
    longitude: "-66.26777800",
  },
  {
    id: 143050,
    name: "Dorado Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.43967000",
    longitude: "-66.28295000",
  },
  {
    id: 143051,
    name: "El Mangó",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.23416667",
    longitude: "-65.87972222",
  },
  {
    id: 143052,
    name: "El Negro",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.03750000",
    longitude: "-65.85138889",
  },
  {
    id: 143053,
    name: "El Ojo",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.00388889",
    longitude: "-66.39166667",
  },
  {
    id: 143054,
    name: "Emajagua",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.00055556",
    longitude: "-65.88277778",
  },
  {
    id: 143055,
    name: "Esperanza",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.09722222",
    longitude: "-65.47083333",
  },
  {
    id: 143056,
    name: "Espino",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.27638889",
    longitude: "-67.11944444",
  },
  {
    id: 143057,
    name: "Estancias de Florida",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.36666667",
    longitude: "-66.56972222",
  },
  {
    id: 143058,
    name: "Fajardo",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.32579000",
    longitude: "-65.65238000",
  },
  {
    id: 143059,
    name: "Fajardo Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.31051000",
    longitude: "-65.67600000",
  },
  {
    id: 143060,
    name: "Florida",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.36495300",
    longitude: "-66.56616100",
  },
  {
    id: 143061,
    name: "Franquez",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.34027778",
    longitude: "-66.42777778",
  },
  {
    id: 143062,
    name: "Fuig",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "17.98777778",
    longitude: "-66.91611111",
  },
  {
    id: 143063,
    name: "G. L. Garcia",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.12750000",
    longitude: "-66.10416667",
  },
  {
    id: 143064,
    name: "Galateo",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.36250000",
    longitude: "-66.25888889",
  },
  {
    id: 143065,
    name: "Garrochales",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.45361111",
    longitude: "-66.56638889",
  },
  {
    id: 143073,
    name: "Guánica",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "17.97166667",
    longitude: "-66.90805556",
  },
  {
    id: 143074,
    name: "Guánica Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "17.98830000",
    longitude: "-66.91212000",
  },
  {
    id: 143066,
    name: "Guayabal",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.08138889",
    longitude: "-66.50138889",
  },
  {
    id: 143067,
    name: "Guayama",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "17.99361111",
    longitude: "-66.13083333",
  },
  {
    id: 143068,
    name: "Guayama Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.01663000",
    longitude: "-66.13072000",
  },
  {
    id: 143069,
    name: "Guayanilla",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.01916667",
    longitude: "-66.79194444",
  },
  {
    id: 143070,
    name: "Guaynabo",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.36666667",
    longitude: "-66.10000000",
  },
  {
    id: 143071,
    name: "Gurabo",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.25440000",
    longitude: "-65.97294000",
  },
  {
    id: 143072,
    name: "Gurabo Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.27162000",
    longitude: "-65.97183000",
  },
  {
    id: 143075,
    name: "H. Rivera Colon",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.34777778",
    longitude: "-66.27388889",
  },
  {
    id: 143076,
    name: "Hatillo",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.48633000",
    longitude: "-66.82545000",
  },
  {
    id: 143077,
    name: "Hatillo Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.40773000",
    longitude: "-66.79323000",
  },
  {
    id: 143078,
    name: "Hato Arriba",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.35583333",
    longitude: "-67.03416667",
  },
  {
    id: 143079,
    name: "Hato Candal",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.37444444",
    longitude: "-65.78722222",
  },
  {
    id: 143080,
    name: "Hormigueros",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.13968000",
    longitude: "-67.12740000",
  },
  {
    id: 143081,
    name: "Hormigueros Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.14162000",
    longitude: "-67.11629000",
  },
  {
    id: 143082,
    name: "Humacao",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.14972200",
    longitude: "-65.82750000",
  },
  {
    id: 143083,
    name: "Imbery",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.43694444",
    longitude: "-66.55250000",
  },
  {
    id: 143084,
    name: "Indios",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "17.99416667",
    longitude: "-66.81944444",
  },
  {
    id: 143085,
    name: "Ingenio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.44222222",
    longitude: "-66.22611111",
  },
  {
    id: 143086,
    name: "Isabel Segunda",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.14916667",
    longitude: "-65.44277778",
  },
  {
    id: 143087,
    name: "Isabela",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.51305556",
    longitude: "-67.07000000",
  },
  {
    id: 143088,
    name: "Jagual",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.16166667",
    longitude: "-65.99555556",
  },
  {
    id: 143089,
    name: "Jauca",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "17.96916667",
    longitude: "-66.36583333",
  },
  {
    id: 143090,
    name: "Jayuya",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.21861100",
    longitude: "-66.59166700",
  },
  {
    id: 143091,
    name: "Jobos",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "17.95527778",
    longitude: "-66.16555556",
  },
  {
    id: 143092,
    name: "Juana Díaz",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.05246000",
    longitude: "-66.50656000",
  },
  {
    id: 143093,
    name: "Juana Díaz Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.04802000",
    longitude: "-66.49267000",
  },
  {
    id: 143094,
    name: "Juncal",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.31388889",
    longitude: "-66.91916667",
  },
  {
    id: 143095,
    name: "Juncos",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.22750000",
    longitude: "-65.92111100",
  },
  {
    id: 143096,
    name: "La Alianza",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.39750000",
    longitude: "-66.60250000",
  },
  {
    id: 143097,
    name: "La Dolores",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.37555556",
    longitude: "-65.85583333",
  },
  {
    id: 143098,
    name: "La Fermina",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.17417000",
    longitude: "-65.85139000",
  },
  {
    id: 143099,
    name: "La Luisa",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.44888889",
    longitude: "-66.51000000",
  },
  {
    id: 143100,
    name: "La Parguera",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "17.97497000",
    longitude: "-67.04657000",
  },
  {
    id: 143101,
    name: "La Playa",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.28750000",
    longitude: "-67.18694444",
  },
  {
    id: 143102,
    name: "La Plena",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.04663000",
    longitude: "-66.20461000",
  },
  {
    id: 143103,
    name: "Lajas",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.05193889",
    longitude: "-67.05971944",
  },
  {
    id: 143104,
    name: "Lajas Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.01330000",
    longitude: "-67.03990000",
  },
  {
    id: 143105,
    name: "Lamboglia",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "17.98136000",
    longitude: "-65.98572000",
  },
  {
    id: 143106,
    name: "Lares",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.29467000",
    longitude: "-66.87712000",
  },
  {
    id: 143107,
    name: "Las Marias",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.29333333",
    longitude: "-67.14638889",
  },
  {
    id: 143108,
    name: "Las Marías Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.25087000",
    longitude: "-66.99240000",
  },
  {
    id: 143109,
    name: "Las Ochenta",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "17.98469000",
    longitude: "-66.31795000",
  },
  {
    id: 143110,
    name: "Las Ollas",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.03305556",
    longitude: "-66.42444444",
  },
  {
    id: 143111,
    name: "Las Piedras",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.18301000",
    longitude: "-65.86627000",
  },
  {
    id: 143112,
    name: "Las Piedras Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.18829000",
    longitude: "-65.87044000",
  },
  {
    id: 143113,
    name: "Levittown",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.44861111",
    longitude: "-66.18027778",
  },
  {
    id: 143114,
    name: "Liborio Negron Torres",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.04305556",
    longitude: "-66.94250000",
  },
  {
    id: 143115,
    name: "Lluveras",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.03833333",
    longitude: "-66.90472222",
  },
  {
    id: 143118,
    name: "Loíza",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.43134000",
    longitude: "-65.88016000",
  },
  {
    id: 143116,
    name: "Lomas",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.26861111",
    longitude: "-65.90888889",
  },
  {
    id: 143117,
    name: "Los Llanos",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.05527778",
    longitude: "-66.40583333",
  },
  {
    id: 143119,
    name: "Luis Lloréns Torres",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.05691000",
    longitude: "-66.52684000",
  },
  {
    id: 143120,
    name: "Luis M. Cintron",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.29972222",
    longitude: "-65.63861111",
  },
  {
    id: 143121,
    name: "Luquillo",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.37250000",
    longitude: "-65.71666667",
  },
  {
    id: 143122,
    name: "Luquillo Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.33967000",
    longitude: "-65.72461000",
  },
  {
    id: 143123,
    name: "Luyando",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.36444444",
    longitude: "-67.15750000",
  },
  {
    id: 143124,
    name: "Magas Arriba",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.01750000",
    longitude: "-66.76916667",
  },
  {
    id: 143125,
    name: "Manatí",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.43250000",
    longitude: "-66.48444444",
  },
  {
    id: 143126,
    name: "Maria Antonia",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "17.97833333",
    longitude: "-66.88944444",
  },
  {
    id: 143127,
    name: "Mariano Colón",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.02333333",
    longitude: "-66.33250000",
  },
  {
    id: 143128,
    name: "Maricao",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.18083300",
    longitude: "-66.98000000",
  },
  {
    id: 143129,
    name: "Maricao Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.17301000",
    longitude: "-66.94546000",
  },
  {
    id: 143130,
    name: "Martorell",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.07472000",
    longitude: "-65.89861000",
  },
  {
    id: 143131,
    name: "Marueño",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.05772000",
    longitude: "-66.65603000",
  },
  {
    id: 143132,
    name: "Maunabo",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.00719000",
    longitude: "-65.89933000",
  },
  {
    id: 143133,
    name: "Maunabo Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.01691000",
    longitude: "-65.92738000",
  },
  {
    id: 143134,
    name: "Mayagüez",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.20111111",
    longitude: "-67.13972222",
  },
  {
    id: 143135,
    name: "Miranda",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.38666667",
    longitude: "-66.38388889",
  },
  {
    id: 143136,
    name: "Moca",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.39467000",
    longitude: "-67.11324000",
  },
  {
    id: 143137,
    name: "Moca Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.38550000",
    longitude: "-67.08435000",
  },
  {
    id: 143138,
    name: "Monserrate",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.43694000",
    longitude: "-66.35639000",
  },
  {
    id: 143139,
    name: "Monte Grande",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.08750000",
    longitude: "-67.10750000",
  },
  {
    id: 143140,
    name: "Mora",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.46305556",
    longitude: "-67.03277778",
  },
  {
    id: 143141,
    name: "Morovis",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.33333333",
    longitude: "-66.41666667",
  },
  {
    id: 143142,
    name: "Mucarabones",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.39083333",
    longitude: "-66.21611111",
  },
  {
    id: 143143,
    name: "Naguabo",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.21944444",
    longitude: "-65.73666667",
  },
  {
    id: 143144,
    name: "Naguabo Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.23135000",
    longitude: "-65.75988000",
  },
  {
    id: 143145,
    name: "Naranjito",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.30083300",
    longitude: "-66.24500000",
  },
  {
    id: 143146,
    name: "Naranjito Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.28968000",
    longitude: "-66.25517000",
  },
  {
    id: 143147,
    name: "Olimpo",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.00194444",
    longitude: "-66.10833333",
  },
  {
    id: 143148,
    name: "Orocovis",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.22694400",
    longitude: "-66.39111100",
  },
  {
    id: 143149,
    name: "Orocovis Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.21885000",
    longitude: "-66.43712000",
  },
  {
    id: 143174,
    name: "Pájaros",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.37592060",
    longitude: "-66.18625500",
  },
  {
    id: 143150,
    name: "Pajonal",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.38222222",
    longitude: "-66.55583333",
  },
  {
    id: 143151,
    name: "Palmarejo",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.04027778",
    longitude: "-67.07694444",
  },
  {
    id: 143152,
    name: "Palmas",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "17.98722222",
    longitude: "-66.02555556",
  },
  {
    id: 143153,
    name: "Palmer",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.37055556",
    longitude: "-65.77416667",
  },
  {
    id: 143154,
    name: "Palo Seco",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.00747000",
    longitude: "-65.93683000",
  },
  {
    id: 143155,
    name: "Palomas",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.01361111",
    longitude: "-66.87333333",
  },
  {
    id: 143156,
    name: "Parcelas La Milagrosa",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.17083333",
    longitude: "-66.18833333",
  },
  {
    id: 143157,
    name: "Parcelas Nuevas",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.14027778",
    longitude: "-66.17250000",
  },
  {
    id: 143158,
    name: "Parcelas Peñuelas",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "17.99888889",
    longitude: "-66.34138889",
  },
  {
    id: 143159,
    name: "Pastos",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.11777778",
    longitude: "-66.25888889",
  },
  {
    id: 143160,
    name: "Patillas",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.00635000",
    longitude: "-66.01572000",
  },
  {
    id: 143161,
    name: "Patillas Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.04163000",
    longitude: "-66.01766000",
  },
  {
    id: 143162,
    name: "Peña Pobre",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.21555556",
    longitude: "-65.82222222",
  },
  {
    id: 143163,
    name: "Peñuelas",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.05937500",
    longitude: "-66.72254400",
  },
  {
    id: 143164,
    name: "Piedra Gorda",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.43416667",
    longitude: "-66.88777778",
  },
  {
    id: 143165,
    name: "Playa Fortuna",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.37972222",
    longitude: "-65.74527778",
  },
  {
    id: 143166,
    name: "Playita",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "17.96055556",
    longitude: "-66.28972222",
  },
  {
    id: 143167,
    name: "Playita Cortada",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "17.98500000",
    longitude: "-66.43916667",
  },
  {
    id: 143168,
    name: "Pole Ojea",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "17.97500000",
    longitude: "-67.18527778",
  },
  {
    id: 143169,
    name: "Ponce",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.00000000",
    longitude: "-66.61666667",
  },
  {
    id: 143170,
    name: "Potala Pastillo",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "17.99138889",
    longitude: "-66.49666667",
  },
  {
    id: 143171,
    name: "Pueblito del Rio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.22805556",
    longitude: "-65.86305556",
  },
  {
    id: 143172,
    name: "Puerto Real",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.07500000",
    longitude: "-67.18722222",
  },
  {
    id: 143173,
    name: "Punta Santiago",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.16638889",
    longitude: "-65.74833333",
  },
  {
    id: 143175,
    name: "Quebrada",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.35666667",
    longitude: "-66.83222222",
  },
  {
    id: 143176,
    name: "Quebradillas",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.47388889",
    longitude: "-66.93861111",
  },
  {
    id: 143177,
    name: "Quebradillas Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.43967000",
    longitude: "-66.92462000",
  },
  {
    id: 143178,
    name: "Rafael Capo",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.40722222",
    longitude: "-66.78222222",
  },
  {
    id: 143179,
    name: "Rafael Gonzalez",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.42750000",
    longitude: "-66.78694444",
  },
  {
    id: 143180,
    name: "Rafael Hernandez",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.47138889",
    longitude: "-67.07916667",
  },
  {
    id: 143181,
    name: "Ramos",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.33972222",
    longitude: "-65.71277778",
  },
  {
    id: 143182,
    name: "Rincón",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.34023000",
    longitude: "-67.24990000",
  },
  {
    id: 143183,
    name: "Rincón Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.33967000",
    longitude: "-67.23713000",
  },
  {
    id: 143186,
    name: "Río Blanco",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.21833333",
    longitude: "-65.78861111",
  },
  {
    id: 143187,
    name: "Río Cañas Abajo",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.03833333",
    longitude: "-66.46777778",
  },
  {
    id: 143188,
    name: "Río Grande",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.38023000",
    longitude: "-65.83127000",
  },
  {
    id: 143189,
    name: "Río Grande Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.34162000",
    longitude: "-65.81766000",
  },
  {
    id: 143184,
    name: "Rio Lajas",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.39719150",
    longitude: "-66.26453900",
  },
  {
    id: 143190,
    name: "Río Piedras",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.39972222",
    longitude: "-66.05000000",
  },
  {
    id: 143185,
    name: "Rosa Sanchez",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.06166667",
    longitude: "-65.91361111",
  },
  {
    id: 143191,
    name: "Sabana",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.46083333",
    longitude: "-66.35861111",
  },
  {
    id: 143192,
    name: "Sabana Eneas",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.08611111",
    longitude: "-67.08111111",
  },
  {
    id: 143193,
    name: "Sabana Grande",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.07972222",
    longitude: "-66.96083333",
  },
  {
    id: 143194,
    name: "Sabana Grande Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.09385000",
    longitude: "-66.94962000",
  },
  {
    id: 143195,
    name: "Sabana Hoyos",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.43388889",
    longitude: "-66.61388889",
  },
  {
    id: 143196,
    name: "Sabana Seca",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.42694444",
    longitude: "-66.18472222",
  },
  {
    id: 143197,
    name: "Salinas",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.01746000",
    longitude: "-66.25378000",
  },
  {
    id: 143198,
    name: "San Antonio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.45166667",
    longitude: "-66.94972222",
  },
  {
    id: 143199,
    name: "San Germán Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.11468000",
    longitude: "-67.03573000",
  },
  {
    id: 143200,
    name: "San Isidro",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.39222222",
    longitude: "-65.88555556",
  },
  {
    id: 143201,
    name: "San José",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.39833333",
    longitude: "-66.25583333",
  },
  {
    id: 143202,
    name: "San Juan",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.45000000",
    longitude: "-66.06666667",
  },
  {
    id: 143203,
    name: "San Lorenzo",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.18988900",
    longitude: "-65.96869400",
  },
  {
    id: 143204,
    name: "San Sebastián",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.33722222",
    longitude: "-66.99055556",
  },
  {
    id: 143205,
    name: "Santa Barbara",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.39361111",
    longitude: "-65.91888889",
  },
  {
    id: 143206,
    name: "Santa Clara",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.21861111",
    longitude: "-66.12888889",
  },
  {
    id: 143207,
    name: "Santa Isabel",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "17.96611100",
    longitude: "-66.40500000",
  },
  {
    id: 143208,
    name: "Santa Isabel Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "17.99663000",
    longitude: "-66.38711000",
  },
  {
    id: 143209,
    name: "Santo Domingo",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.06333333",
    longitude: "-66.75250000",
  },
  {
    id: 143210,
    name: "Santurce",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.44083333",
    longitude: "-66.04722222",
  },
  {
    id: 143211,
    name: "Stella",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.32194444",
    longitude: "-67.24694444",
  },
  {
    id: 143213,
    name: "Suárez",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.43027778",
    longitude: "-65.85361111",
  },
  {
    id: 143212,
    name: "Sumidero",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.21861111",
    longitude: "-66.12888889",
  },
  {
    id: 143214,
    name: "Tallaboa",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "17.99500000",
    longitude: "-66.71638889",
  },
  {
    id: 143215,
    name: "Tallaboa Alta",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.05111111",
    longitude: "-66.70027778",
  },
  {
    id: 143216,
    name: "Tiburones",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.43166667",
    longitude: "-66.58083333",
  },
  {
    id: 143217,
    name: "Tierras Nuevas Poniente",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.46189000",
    longitude: "-66.48850000",
  },
  {
    id: 143218,
    name: "Toa Alta",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.38828000",
    longitude: "-66.24822000",
  },
  {
    id: 143219,
    name: "Toa Alta Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.36606000",
    longitude: "-66.25378000",
  },
  {
    id: 143220,
    name: "Toa Baja",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.44388900",
    longitude: "-66.25972200",
  },
  {
    id: 143221,
    name: "Trujillo Alto",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.36277800",
    longitude: "-66.01750000",
  },
  {
    id: 143222,
    name: "Trujillo Alto Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.34162000",
    longitude: "-66.00517000",
  },
  {
    id: 143223,
    name: "Utuado",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.27301000",
    longitude: "-66.69962000",
  },
  {
    id: 143224,
    name: "Utuado barrio-pueblo",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.26580000",
    longitude: "-66.70640000",
  },
  {
    id: 143232,
    name: "Vázquez",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.06583333",
    longitude: "-66.23861111",
  },
  {
    id: 143225,
    name: "Vega Alta",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.41217000",
    longitude: "-66.33128000",
  },
  {
    id: 143226,
    name: "Vega Alta Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.41050000",
    longitude: "-66.33295000",
  },
  {
    id: 143227,
    name: "Vega Baja",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.44611111",
    longitude: "-66.38750000",
  },
  {
    id: 143228,
    name: "Vieques",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.42500000",
    longitude: "-65.83305556",
  },
  {
    id: 143229,
    name: "Vieques Municipality",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.12913000",
    longitude: "-65.43710000",
  },
  {
    id: 143230,
    name: "Villalba",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.12722200",
    longitude: "-66.49222200",
  },
  {
    id: 143231,
    name: "Villalba Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.12913000",
    longitude: "-66.47739000",
  },
  {
    id: 143233,
    name: "Yabucoa",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.05052000",
    longitude: "-65.87933000",
  },
  {
    id: 143234,
    name: "Yabucoa Municipio",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.07302000",
    longitude: "-65.88711000",
  },
  {
    id: 143235,
    name: "Yauco",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.03694444",
    longitude: "-66.85027778",
  },
  {
    id: 143236,
    name: "Yaurel",
    state_id: 1449,
    state_code: "PR",
    country_id: 233,
    country_code: "US",
    latitude: "18.02694444",
    longitude: "-66.05722222",
  },
  {
    id: 111512,
    name: "Ashaway",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.42343000",
    longitude: "-71.78562000",
  },
  {
    id: 111848,
    name: "Barrington",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.74066000",
    longitude: "-71.30866000",
  },
  {
    id: 112667,
    name: "Bradford",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.39899000",
    longitude: "-71.73701000",
  },
  {
    id: 112812,
    name: "Bristol",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.67705000",
    longitude: "-71.26616000",
  },
  {
    id: 112815,
    name: "Bristol County",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.70554000",
    longitude: "-71.28612000",
  },
  {
    id: 113673,
    name: "Central Falls",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.89066000",
    longitude: "-71.39228000",
  },
  {
    id: 113773,
    name: "Charlestown",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.38316000",
    longitude: "-71.64173000",
  },
  {
    id: 113843,
    name: "Chepachet",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.91510000",
    longitude: "-71.67146000",
  },
  {
    id: 114732,
    name: "Coventry",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.70010000",
    longitude: "-71.68284000",
  },
  {
    id: 114772,
    name: "Cranston",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.77982000",
    longitude: "-71.43728000",
  },
  {
    id: 114909,
    name: "Cumberland",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.96677000",
    longitude: "-71.43284000",
  },
  {
    id: 114921,
    name: "Cumberland Hill",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.97454000",
    longitude: "-71.46700000",
  },
  {
    id: 115674,
    name: "East Greenwich",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.66038000",
    longitude: "-71.45589000",
  },
  {
    id: 115741,
    name: "East Providence",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.81371000",
    longitude: "-71.37005000",
  },
  {
    id: 116273,
    name: "Exeter",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.57760000",
    longitude: "-71.53756000",
  },
  {
    id: 116774,
    name: "Foster",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.85371000",
    longitude: "-71.75812000",
  },
  {
    id: 117695,
    name: "Greenville",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.87121000",
    longitude: "-71.55201000",
  },
  {
    id: 118076,
    name: "Harrisville",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.96565000",
    longitude: "-71.67451000",
  },
  {
    id: 118646,
    name: "Hope Valley",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.50760000",
    longitude: "-71.71618000",
  },
  {
    id: 118658,
    name: "Hopkinton",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.46121000",
    longitude: "-71.77757000",
  },
  {
    id: 119125,
    name: "Jamestown",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.49705000",
    longitude: "-71.36728000",
  },
  {
    id: 119279,
    name: "Johnston",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.82186000",
    longitude: "-71.50675000",
  },
  {
    id: 119493,
    name: "Kent County",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.67334000",
    longitude: "-71.57895000",
  },
  {
    id: 119629,
    name: "Kingston",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.48038000",
    longitude: "-71.52256000",
  },
  {
    id: 120513,
    name: "Lincoln",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.92111000",
    longitude: "-71.43500000",
  },
  {
    id: 121636,
    name: "Melville",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.58705000",
    longitude: "-71.28338000",
  },
  {
    id: 121793,
    name: "Middletown",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.54566000",
    longitude: "-71.29144000",
  },
  {
    id: 122564,
    name: "Narragansett",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.45010000",
    longitude: "-71.44950000",
  },
  {
    id: 122565,
    name: "Narragansett Pier",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.43232000",
    longitude: "-71.45644000",
  },
  {
    id: 122778,
    name: "New Shoreham",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.17233000",
    longitude: "-71.55783000",
  },
  {
    id: 122849,
    name: "Newport",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.49010000",
    longitude: "-71.31283000",
  },
  {
    id: 122854,
    name: "Newport County",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.49980000",
    longitude: "-71.28100000",
  },
  {
    id: 122855,
    name: "Newport East",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.51579000",
    longitude: "-71.28752000",
  },
  {
    id: 123048,
    name: "North Kingstown",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.55010000",
    longitude: "-71.46617000",
  },
  {
    id: 123089,
    name: "North Providence",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.85010000",
    longitude: "-71.46617000",
  },
  {
    id: 123102,
    name: "North Scituate",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.83177000",
    longitude: "-71.58729000",
  },
  {
    id: 123108,
    name: "North Smithfield",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.96677000",
    longitude: "-71.54951000",
  },
  {
    id: 123903,
    name: "Pascoag",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.95565000",
    longitude: "-71.70229000",
  },
  {
    id: 123939,
    name: "Pawtucket",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.87871000",
    longitude: "-71.38256000",
  },
  {
    id: 124571,
    name: "Portsmouth",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.60232000",
    longitude: "-71.25033000",
  },
  {
    id: 124716,
    name: "Providence",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.82399000",
    longitude: "-71.41283000",
  },
  {
    id: 124719,
    name: "Providence County",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.87136000",
    longitude: "-71.57860000",
  },
  {
    id: 126520,
    name: "Smithfield",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.92204000",
    longitude: "-71.54951000",
  },
  {
    id: 126683,
    name: "South Kingstown",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.44718000",
    longitude: "-71.52494000",
  },
  {
    id: 127687,
    name: "Tiverton",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.62594000",
    longitude: "-71.21338000",
  },
  {
    id: 128108,
    name: "Valley Falls",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.90677000",
    longitude: "-71.39061000",
  },
  {
    id: 128364,
    name: "Wakefield-Peacedale",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.44606000",
    longitude: "-71.50040000",
  },
  {
    id: 128484,
    name: "Warren",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.73038000",
    longitude: "-71.28255000",
  },
  {
    id: 128525,
    name: "Warwick",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.70010000",
    longitude: "-71.41617000",
  },
  {
    id: 128574,
    name: "Washington County",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.39649000",
    longitude: "-71.61966000",
  },
  {
    id: 128880,
    name: "West Greenwich",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.63700000",
    longitude: "-71.66004000",
  },
  {
    id: 129002,
    name: "West Warwick",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.69689000",
    longitude: "-71.52194000",
  },
  {
    id: 129023,
    name: "Westerly",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "41.37760000",
    longitude: "-71.82729000",
  },
  {
    id: 129582,
    name: "Woonsocket",
    state_id: 1461,
    state_code: "RI",
    country_id: 233,
    country_code: "US",
    latitude: "42.00288000",
    longitude: "-71.51478000",
  },
  {
    id: 110967,
    name: "Abbeville",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.17817000",
    longitude: "-82.37901000",
  },
  {
    id: 110969,
    name: "Abbeville County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.22257000",
    longitude: "-82.45871000",
  },
  {
    id: 111066,
    name: "Aiken",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.56042000",
    longitude: "-81.71955000",
  },
  {
    id: 111067,
    name: "Aiken County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.54437000",
    longitude: "-81.63474000",
  },
  {
    id: 111193,
    name: "Allendale",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.00793000",
    longitude: "-81.30844000",
  },
  {
    id: 111197,
    name: "Allendale County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.98811000",
    longitude: "-81.35820000",
  },
  {
    id: 111311,
    name: "Anderson",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.50344000",
    longitude: "-82.65013000",
  },
  {
    id: 111317,
    name: "Anderson County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.51909000",
    longitude: "-82.63788000",
  },
  {
    id: 111329,
    name: "Andrews",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.45128000",
    longitude: "-79.56090000",
  },
  {
    id: 111422,
    name: "Arcadia",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.95818000",
    longitude: "-81.99066000",
  },
  {
    id: 111451,
    name: "Arial",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.84595000",
    longitude: "-82.64152000",
  },
  {
    id: 111706,
    name: "Awendaw",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.03767000",
    longitude: "-79.61313000",
  },
  {
    id: 111786,
    name: "Bamberg",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.29710000",
    longitude: "-81.03482000",
  },
  {
    id: 111787,
    name: "Bamberg County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.21477000",
    longitude: "-81.05423000",
  },
  {
    id: 111838,
    name: "Barnwell",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.24487000",
    longitude: "-81.35872000",
  },
  {
    id: 111839,
    name: "Barnwell County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.26606000",
    longitude: "-81.43502000",
  },
  {
    id: 111890,
    name: "Batesburg",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.90792000",
    longitude: "-81.54733000",
  },
  {
    id: 111891,
    name: "Batesburg-Leesville",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.91014000",
    longitude: "-81.53733000",
  },
  {
    id: 111986,
    name: "Beaufort",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.43158000",
    longitude: "-80.66983000",
  },
  {
    id: 111988,
    name: "Beaufort County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.35706000",
    longitude: "-80.69217000",
  },
  {
    id: 112148,
    name: "Belton",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.52289000",
    longitude: "-82.49429000",
  },
  {
    id: 112152,
    name: "Belvedere",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.53097000",
    longitude: "-81.94484000",
  },
  {
    id: 112175,
    name: "Bennettsville",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.61738000",
    longitude: "-79.68478000",
  },
  {
    id: 112214,
    name: "Berea",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.88540000",
    longitude: "-82.45596000",
  },
  {
    id: 112224,
    name: "Berkeley County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.19768000",
    longitude: "-79.95099000",
  },
  {
    id: 112365,
    name: "Bishopville",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.21821000",
    longitude: "-80.24841000",
  },
  {
    id: 112389,
    name: "Blacksburg",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "35.12124000",
    longitude: "-81.51592000",
  },
  {
    id: 112394,
    name: "Blackville",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.35793000",
    longitude: "-81.27066000",
  },
  {
    id: 112483,
    name: "Bluffton",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.23715000",
    longitude: "-80.86039000",
  },
  {
    id: 112487,
    name: "Blythewood",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.21432000",
    longitude: "-80.97398000",
  },
  {
    id: 112506,
    name: "Boiling Springs",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "35.04651000",
    longitude: "-81.98177000",
  },
  {
    id: 112540,
    name: "Bonneau Beach",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.31989000",
    longitude: "-80.00036000",
  },
  {
    id: 112849,
    name: "Brookdale",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.50682000",
    longitude: "-80.82342000",
  },
  {
    id: 113009,
    name: "Buffalo",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.72569000",
    longitude: "-81.68343000",
  },
  {
    id: 113081,
    name: "Burnettown",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.51541000",
    longitude: "-81.84900000",
  },
  {
    id: 113096,
    name: "Burton",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.43575000",
    longitude: "-80.72400000",
  },
  {
    id: 113206,
    name: "Calhoun County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.67486000",
    longitude: "-80.78028000",
  },
  {
    id: 113211,
    name: "Calhoun Falls",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.09234000",
    longitude: "-82.59569000",
  },
  {
    id: 113264,
    name: "Camden",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.24654000",
    longitude: "-80.60702000",
  },
  {
    id: 113327,
    name: "Cane Savannah",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.90182000",
    longitude: "-80.45036000",
  },
  {
    id: 113566,
    name: "Catawba",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.85292000",
    longitude: "-80.91119000",
  },
  {
    id: 113591,
    name: "Cayce",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.96571000",
    longitude: "-81.07398000",
  },
  {
    id: 113656,
    name: "Centerville",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.53205000",
    longitude: "-82.70402000",
  },
  {
    id: 113665,
    name: "Central",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.72427000",
    longitude: "-82.78125000",
  },
  {
    id: 113743,
    name: "Chapin",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.16598000",
    longitude: "-81.34982000",
  },
  {
    id: 113765,
    name: "Charleston",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.77657000",
    longitude: "-79.93092000",
  },
  {
    id: 113769,
    name: "Charleston County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.74917000",
    longitude: "-79.94202000",
  },
  {
    id: 113844,
    name: "Cheraw",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.69766000",
    longitude: "-79.88340000",
  },
  {
    id: 113854,
    name: "Cherokee County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "35.04820000",
    longitude: "-81.62039000",
  },
  {
    id: 113869,
    name: "Cherryvale",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.95571000",
    longitude: "-80.45814000",
  },
  {
    id: 113885,
    name: "Chester",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.70486000",
    longitude: "-81.21426000",
  },
  {
    id: 113895,
    name: "Chester County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.69206000",
    longitude: "-81.15953000",
  },
  {
    id: 113902,
    name: "Chesterfield",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.73599000",
    longitude: "-80.08812000",
  },
  {
    id: 113906,
    name: "Chesterfield County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.63978000",
    longitude: "-80.15872000",
  },
  {
    id: 114044,
    name: "City View",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.86151000",
    longitude: "-82.43151000",
  },
  {
    id: 114113,
    name: "Clarendon County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.66581000",
    longitude: "-80.21640000",
  },
  {
    id: 114224,
    name: "Clearwater",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.49680000",
    longitude: "-81.89206000",
  },
  {
    id: 114234,
    name: "Clemson",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.68344000",
    longitude: "-82.83737000",
  },
  {
    id: 114277,
    name: "Clinton",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.47263000",
    longitude: "-81.88066000",
  },
  {
    id: 114307,
    name: "Clover",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "35.11125000",
    longitude: "-81.22646000",
  },
  {
    id: 114421,
    name: "Colleton County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.84343000",
    longitude: "-80.64968000",
  },
  {
    id: 114471,
    name: "Columbia",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.00071000",
    longitude: "-81.03481000",
  },
  {
    id: 114583,
    name: "Conway",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.83600000",
    longitude: "-79.04781000",
  },
  {
    id: 114750,
    name: "Cowpens",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "35.01679000",
    longitude: "-81.80399000",
  },
  {
    id: 115007,
    name: "Dalzell",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.01682000",
    longitude: "-80.43008000",
  },
  {
    id: 115049,
    name: "Darlington",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.29988000",
    longitude: "-79.87617000",
  },
  {
    id: 115051,
    name: "Darlington County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.33235000",
    longitude: "-79.95769000",
  },
  {
    id: 115239,
    name: "Denmark",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.32265000",
    longitude: "-81.14232000",
  },
  {
    id: 115249,
    name: "Dentsville",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.06404000",
    longitude: "-80.95815000",
  },
  {
    id: 115342,
    name: "Dillon",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.41655000",
    longitude: "-79.37116000",
  },
  {
    id: 115344,
    name: "Dillon County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.39152000",
    longitude: "-79.37893000",
  },
  {
    id: 115411,
    name: "Dorchester County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.07949000",
    longitude: "-80.40556000",
  },
  {
    id: 115506,
    name: "Due West",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.33345000",
    longitude: "-82.38790000",
  },
  {
    id: 115522,
    name: "Duncan",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.93790000",
    longitude: "-82.14511000",
  },
  {
    id: 115533,
    name: "Dunean",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.82484000",
    longitude: "-82.41929000",
  },
  {
    id: 115625,
    name: "Easley",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.82984000",
    longitude: "-82.60152000",
  },
  {
    id: 115666,
    name: "East Gaffney",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "35.08013000",
    longitude: "-81.63287000",
  },
  {
    id: 115762,
    name: "East Sumter",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.92544000",
    longitude: "-80.29619000",
  },
  {
    id: 115842,
    name: "Edgefield",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.78958000",
    longitude: "-81.92956000",
  },
  {
    id: 115843,
    name: "Edgefield County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.77229000",
    longitude: "-81.96658000",
  },
  {
    id: 115876,
    name: "Edisto",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.47654000",
    longitude: "-80.89870000",
  },
  {
    id: 115963,
    name: "Elgin",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.67293000",
    longitude: "-80.71896000",
  },
  {
    id: 116211,
    name: "Estill",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.75489000",
    longitude: "-81.24205000",
  },
  {
    id: 116239,
    name: "Eureka Mill",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.71764000",
    longitude: "-81.19370000",
  },
  {
    id: 116301,
    name: "Fairfax",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.95905000",
    longitude: "-81.23650000",
  },
  {
    id: 116319,
    name: "Fairfield County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.39511000",
    longitude: "-81.12123000",
  },
  {
    id: 116324,
    name: "Fairforest",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.95651000",
    longitude: "-82.01011000",
  },
  {
    id: 116539,
    name: "Five Forks",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.80484000",
    longitude: "-82.22956000",
  },
  {
    id: 116580,
    name: "Florence",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.19543000",
    longitude: "-79.76256000",
  },
  {
    id: 116587,
    name: "Florence County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.02439000",
    longitude: "-79.70282000",
  },
  {
    id: 116623,
    name: "Folly Beach",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.65518000",
    longitude: "-79.94037000",
  },
  {
    id: 116645,
    name: "Forest Acres",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.01932000",
    longitude: "-80.98981000",
  },
  {
    id: 116668,
    name: "Forestbrook",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.72239000",
    longitude: "-78.95809000",
  },
  {
    id: 116730,
    name: "Fort Mill",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "35.00737000",
    longitude: "-80.94508000",
  },
  {
    id: 116783,
    name: "Fountain Inn",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.68901000",
    longitude: "-82.19567000",
  },
  {
    id: 117005,
    name: "Gadsden",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.84571000",
    longitude: "-80.76592000",
  },
  {
    id: 117007,
    name: "Gaffney",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "35.07179000",
    longitude: "-81.64982000",
  },
  {
    id: 117051,
    name: "Gantt",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.80012000",
    longitude: "-82.42429000",
  },
  {
    id: 117056,
    name: "Garden City",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.59295000",
    longitude: "-79.00865000",
  },
  {
    id: 117114,
    name: "Gaston",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.81710000",
    longitude: "-81.10093000",
  },
  {
    id: 117159,
    name: "Georgetown",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.37683000",
    longitude: "-79.29450000",
  },
  {
    id: 117166,
    name: "Georgetown County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.41275000",
    longitude: "-79.29934000",
  },
  {
    id: 117329,
    name: "Gloverville",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.52597000",
    longitude: "-81.83011000",
  },
  {
    id: 117350,
    name: "Golden Grove",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.73401000",
    longitude: "-82.44374000",
  },
  {
    id: 117394,
    name: "Goose Creek",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.98101000",
    longitude: "-80.03259000",
  },
  {
    id: 117502,
    name: "Graniteville",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.56375000",
    longitude: "-81.80789000",
  },
  {
    id: 117571,
    name: "Great Falls",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.57514000",
    longitude: "-80.90202000",
  },
  {
    id: 117688,
    name: "Greenville",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.85262000",
    longitude: "-82.39401000",
  },
  {
    id: 117697,
    name: "Greenville County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.89431000",
    longitude: "-82.37072000",
  },
  {
    id: 117706,
    name: "Greenwood",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.19540000",
    longitude: "-82.16179000",
  },
  {
    id: 117710,
    name: "Greenwood County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.15383000",
    longitude: "-82.12593000",
  },
  {
    id: 117713,
    name: "Greer",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.93873000",
    longitude: "-82.22706000",
  },
  {
    id: 117929,
    name: "Hampton",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.87794000",
    longitude: "-81.12761000",
  },
  {
    id: 117937,
    name: "Hampton County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.77628000",
    longitude: "-81.14070000",
  },
  {
    id: 117942,
    name: "Hanahan",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.91851000",
    longitude: "-80.02203000",
  },
  {
    id: 117994,
    name: "Hardeeville",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.28714000",
    longitude: "-81.08067000",
  },
  {
    id: 118104,
    name: "Hartsville",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.37404000",
    longitude: "-80.07340000",
  },
  {
    id: 118474,
    name: "Hilton Head",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.21632000",
    longitude: "-80.75261000",
  },
  {
    id: 118475,
    name: "Hilton Head Island",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.19382000",
    longitude: "-80.73816000",
  },
  {
    id: 118557,
    name: "Holly Hill",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.32266000",
    longitude: "-80.41370000",
  },
  {
    id: 118566,
    name: "Hollywood",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.73434000",
    longitude: "-80.24177000",
  },
  {
    id: 118591,
    name: "Homeland Park",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.47066000",
    longitude: "-82.67069000",
  },
  {
    id: 118616,
    name: "Honea Path",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.44650000",
    longitude: "-82.39151000",
  },
  {
    id: 118651,
    name: "Hopkins",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.90432000",
    longitude: "-80.87703000",
  },
  {
    id: 118669,
    name: "Horry County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.90448000",
    longitude: "-78.97615000",
  },
  {
    id: 118900,
    name: "India Hook",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "35.00737000",
    longitude: "-81.02174000",
  },
  {
    id: 118945,
    name: "Inman",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "35.04706000",
    longitude: "-82.09011000",
  },
  {
    id: 118946,
    name: "Inman Mills",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "35.04151000",
    longitude: "-82.10428000",
  },
  {
    id: 118987,
    name: "Irmo",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.08598000",
    longitude: "-81.18315000",
  },
  {
    id: 119011,
    name: "Irwin",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.69376000",
    longitude: "-80.82229000",
  },
  {
    id: 119032,
    name: "Isle of Palms",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.78684000",
    longitude: "-79.79480000",
  },
  {
    id: 119047,
    name: "Iva",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.30650000",
    longitude: "-82.66374000",
  },
  {
    id: 119069,
    name: "Jackson",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.32542000",
    longitude: "-81.78789000",
  },
  {
    id: 119117,
    name: "James Island",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.72374000",
    longitude: "-79.96284000",
  },
  {
    id: 119149,
    name: "Jasper County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.42195000",
    longitude: "-81.02327000",
  },
  {
    id: 119250,
    name: "Joanna",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.41491000",
    longitude: "-81.81240000",
  },
  {
    id: 119276,
    name: "Johnsonville",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.81794000",
    longitude: "-79.44922000",
  },
  {
    id: 119277,
    name: "Johnston",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.83208000",
    longitude: "-81.80094000",
  },
  {
    id: 119327,
    name: "Judson",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.83317000",
    longitude: "-82.42762000",
  },
  {
    id: 119520,
    name: "Kershaw",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.55182000",
    longitude: "-80.58368000",
  },
  {
    id: 119521,
    name: "Kershaw County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.33876000",
    longitude: "-80.59026000",
  },
  {
    id: 119551,
    name: "Kiawah Island",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.60824000",
    longitude: "-80.08482000",
  },
  {
    id: 119634,
    name: "Kingstree",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.66766000",
    longitude: "-79.83063000",
  },
  {
    id: 119827,
    name: "Ladson",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.98573000",
    longitude: "-80.10981000",
  },
  {
    id: 119881,
    name: "Lake City",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.87100000",
    longitude: "-79.75535000",
  },
  {
    id: 119949,
    name: "Lake Murray of Richland",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.12048000",
    longitude: "-81.26450000",
  },
  {
    id: 119975,
    name: "Lake Secession",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.28455000",
    longitude: "-82.59457000",
  },
  {
    id: 120000,
    name: "Lake Wylie",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "35.10848000",
    longitude: "-81.04285000",
  },
  {
    id: 120045,
    name: "Lakewood",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.84683000",
    longitude: "-80.35008000",
  },
  {
    id: 120081,
    name: "Lancaster",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.72043000",
    longitude: "-80.77090000",
  },
  {
    id: 120090,
    name: "Lancaster County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.68670000",
    longitude: "-80.70543000",
  },
  {
    id: 120094,
    name: "Lancaster Mill",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.70931000",
    longitude: "-80.79479000",
  },
  {
    id: 120104,
    name: "Landrum",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "35.17511000",
    longitude: "-82.18928000",
  },
  {
    id: 120113,
    name: "Langley",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.51791000",
    longitude: "-81.84400000",
  },
  {
    id: 120176,
    name: "Latta",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.33710000",
    longitude: "-79.43116000",
  },
  {
    id: 120194,
    name: "Laurel Bay",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.45019000",
    longitude: "-80.78483000",
  },
  {
    id: 120206,
    name: "Laurens",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.49901000",
    longitude: "-82.01426000",
  },
  {
    id: 120209,
    name: "Laurens County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.48357000",
    longitude: "-82.00593000",
  },
  {
    id: 120313,
    name: "Lee County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.16329000",
    longitude: "-80.25452000",
  },
  {
    id: 120329,
    name: "Leesville",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.91653000",
    longitude: "-81.51344000",
  },
  {
    id: 120398,
    name: "Lesslie",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.89070000",
    longitude: "-80.95647000",
  },
  {
    id: 120443,
    name: "Lexington",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.98154000",
    longitude: "-81.23621000",
  },
  {
    id: 120453,
    name: "Lexington County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.90233000",
    longitude: "-81.27219000",
  },
  {
    id: 120464,
    name: "Liberty",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.78789000",
    longitude: "-82.69236000",
  },
  {
    id: 120553,
    name: "Lincolnville",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.00684000",
    longitude: "-80.15537000",
  },
  {
    id: 120623,
    name: "Little River",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.87323000",
    longitude: "-78.61418000",
  },
  {
    id: 120776,
    name: "Loris",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.05628000",
    longitude: "-78.89030000",
  },
  {
    id: 120873,
    name: "Lugoff",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.22737000",
    longitude: "-80.68925000",
  },
  {
    id: 120909,
    name: "Lyman",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.94817000",
    longitude: "-82.12733000",
  },
  {
    id: 121134,
    name: "Manning",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.69516000",
    longitude: "-80.21091000",
  },
  {
    id: 121238,
    name: "Marion",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.17822000",
    longitude: "-79.40061000",
  },
  {
    id: 121257,
    name: "Marion County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.08006000",
    longitude: "-79.36251000",
  },
  {
    id: 121276,
    name: "Marlboro County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.60199000",
    longitude: "-79.67863000",
  },
  {
    id: 121420,
    name: "Mauldin",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.77873000",
    longitude: "-82.31012000",
  },
  {
    id: 121444,
    name: "Mayo",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "35.08401000",
    longitude: "-81.85983000",
  },
  {
    id: 121473,
    name: "McColl",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.66877000",
    longitude: "-79.54533000",
  },
  {
    id: 121483,
    name: "McCormick",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.91346000",
    longitude: "-82.29346000",
  },
  {
    id: 121484,
    name: "McCormick County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.89955000",
    longitude: "-82.30988000",
  },
  {
    id: 121615,
    name: "Meggett",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.71796000",
    longitude: "-80.23899000",
  },
  {
    id: 122034,
    name: "Monarch Mill",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.71610000",
    longitude: "-81.58500000",
  },
  {
    id: 122035,
    name: "Moncks Corner",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.19632000",
    longitude: "-80.01429000",
  },
  {
    id: 122376,
    name: "Mount Pleasant",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.79407000",
    longitude: "-79.86259000",
  },
  {
    id: 122462,
    name: "Mullins",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.20572000",
    longitude: "-79.25449000",
  },
  {
    id: 122489,
    name: "Murphys Estates",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.60125000",
    longitude: "-81.94428000",
  },
  {
    id: 122497,
    name: "Murrells Inlet",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.55100000",
    longitude: "-79.04143000",
  },
  {
    id: 122520,
    name: "Myrtle Beach",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.68906000",
    longitude: "-78.88669000",
  },
  {
    id: 122704,
    name: "New Ellenton",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.42153000",
    longitude: "-81.68567000",
  },
  {
    id: 122811,
    name: "Newberry",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.27458000",
    longitude: "-81.61872000",
  },
  {
    id: 122813,
    name: "Newberry County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.28981000",
    longitude: "-81.60012000",
  },
  {
    id: 122844,
    name: "Newport",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.98986000",
    longitude: "-81.10091000",
  },
  {
    id: 122902,
    name: "Ninety Six",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.17513000",
    longitude: "-82.02401000",
  },
  {
    id: 122969,
    name: "North Augusta",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.50180000",
    longitude: "-81.96512000",
  },
  {
    id: 123005,
    name: "North Charleston",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.85462000",
    longitude: "-79.97481000",
  },
  {
    id: 123036,
    name: "North Hartsville",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.39377000",
    longitude: "-80.06951000",
  },
  {
    id: 123070,
    name: "North Myrtle Beach",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.81601000",
    longitude: "-78.68002000",
  },
  {
    id: 123156,
    name: "Northlake",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.56622000",
    longitude: "-82.68402000",
  },
  {
    id: 123241,
    name: "Oak Grove",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.98090000",
    longitude: "-81.14286000",
  },
  {
    id: 123286,
    name: "Oakland",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.98293000",
    longitude: "-80.48842000",
  },
  {
    id: 123349,
    name: "Oconee County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.75351000",
    longitude: "-83.06588000",
  },
  {
    id: 123526,
    name: "Orangeburg",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.49182000",
    longitude: "-80.85565000",
  },
  {
    id: 123528,
    name: "Orangeburg County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.43899000",
    longitude: "-80.80030000",
  },
  {
    id: 123712,
    name: "Pacolet",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.89902000",
    longitude: "-81.76177000",
  },
  {
    id: 123721,
    name: "Pageland",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.77321000",
    longitude: "-80.39173000",
  },
  {
    id: 123795,
    name: "Pamplico",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.99600000",
    longitude: "-79.57006000",
  },
  {
    id: 123859,
    name: "Parker",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.85067000",
    longitude: "-82.45346000",
  },
  {
    id: 124017,
    name: "Pendleton",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.65177000",
    longitude: "-82.78375000",
  },
  {
    id: 124155,
    name: "Pickens",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.88345000",
    longitude: "-82.70736000",
  },
  {
    id: 124158,
    name: "Pickens County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.88752000",
    longitude: "-82.72532000",
  },
  {
    id: 124166,
    name: "Piedmont",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.70234000",
    longitude: "-82.46457000",
  },
  {
    id: 124256,
    name: "Pineridge",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.91043000",
    longitude: "-81.10454000",
  },
  {
    id: 124531,
    name: "Port Royal",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.37908000",
    longitude: "-80.69261000",
  },
  {
    id: 124610,
    name: "Powdersville",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.79178000",
    longitude: "-82.49291000",
  },
  {
    id: 124699,
    name: "Privateer",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.83321000",
    longitude: "-80.41425000",
  },
  {
    id: 124712,
    name: "Prosperity",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.20931000",
    longitude: "-81.53316000",
  },
  {
    id: 124906,
    name: "Ravenel",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.76323000",
    longitude: "-80.25010000",
  },
  {
    id: 124936,
    name: "Red Bank",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.93209000",
    longitude: "-81.23843000",
  },
  {
    id: 124946,
    name: "Red Hill",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.82073000",
    longitude: "-79.01892000",
  },
  {
    id: 125075,
    name: "Richland County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.02180000",
    longitude: "-80.90304000",
  },
  {
    id: 125124,
    name: "Ridgeland",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.48074000",
    longitude: "-80.98039000",
  },
  {
    id: 125129,
    name: "Ridgeville",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.09572000",
    longitude: "-80.31537000",
  },
  {
    id: 125280,
    name: "Rock Hill",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.92487000",
    longitude: "-81.02508000",
  },
  {
    id: 125344,
    name: "Roebuck",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.87957000",
    longitude: "-81.96621000",
  },
  {
    id: 125597,
    name: "Saint Andrews",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.04543000",
    longitude: "-81.12926000",
  },
  {
    id: 125642,
    name: "Saint George",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.18600000",
    longitude: "-80.57565000",
  },
  {
    id: 125696,
    name: "Saint Matthews",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.66488000",
    longitude: "-80.77787000",
  },
  {
    id: 125716,
    name: "Saint Stephen",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.40433000",
    longitude: "-79.92174000",
  },
  {
    id: 125772,
    name: "Saluda",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.00152000",
    longitude: "-81.77205000",
  },
  {
    id: 125774,
    name: "Saluda County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.00614000",
    longitude: "-81.72692000",
  },
  {
    id: 125890,
    name: "Sangaree",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.03545000",
    longitude: "-80.12787000",
  },
  {
    id: 125897,
    name: "Sans Souci",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.87790000",
    longitude: "-82.42401000",
  },
  {
    id: 125984,
    name: "Saxon",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.96123000",
    longitude: "-81.96733000",
  },
  {
    id: 126082,
    name: "Seabrook Island",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.57713000",
    longitude: "-80.17065000",
  },
  {
    id: 126156,
    name: "Seneca",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.68566000",
    longitude: "-82.95320000",
  },
  {
    id: 126174,
    name: "Seven Oaks",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.04876000",
    longitude: "-81.14648000",
  },
  {
    id: 126291,
    name: "Shell Point",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.38353000",
    longitude: "-80.73594000",
  },
  {
    id: 126452,
    name: "Simpsonville",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.73706000",
    longitude: "-82.25428000",
  },
  {
    id: 126491,
    name: "Slater-Marietta",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "35.03472000",
    longitude: "-82.49265000",
  },
  {
    id: 126554,
    name: "Socastee",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.68350000",
    longitude: "-78.99837000",
  },
  {
    id: 126641,
    name: "South Congaree",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.91099000",
    longitude: "-81.13565000",
  },
  {
    id: 126732,
    name: "South Sumter",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.90571000",
    longitude: "-80.34619000",
  },
  {
    id: 126769,
    name: "Southern Shops",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.98595000",
    longitude: "-81.99455000",
  },
  {
    id: 126814,
    name: "Spartanburg",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.94957000",
    longitude: "-81.93205000",
  },
  {
    id: 126815,
    name: "Spartanburg County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.93126000",
    longitude: "-81.99068000",
  },
  {
    id: 126886,
    name: "Springdale",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.95932000",
    longitude: "-81.10898000",
  },
  {
    id: 126994,
    name: "Stateburg",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.95765000",
    longitude: "-80.53481000",
  },
  {
    id: 127192,
    name: "Sullivans Island",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.76323000",
    longitude: "-79.83675000",
  },
  {
    id: 127210,
    name: "Summerville",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.01850000",
    longitude: "-80.17565000",
  },
  {
    id: 127227,
    name: "Sumter",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.92044000",
    longitude: "-80.34147000",
  },
  {
    id: 127231,
    name: "Sumter County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.91617000",
    longitude: "-80.38232000",
  },
  {
    id: 127288,
    name: "Surfside Beach",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.60600000",
    longitude: "-78.97309000",
  },
  {
    id: 127461,
    name: "Taylors",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.92039000",
    longitude: "-82.29623000",
  },
  {
    id: 127482,
    name: "Tega Cay",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "35.02431000",
    longitude: "-81.02785000",
  },
  {
    id: 127645,
    name: "Tigerville",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "35.06845000",
    longitude: "-82.36845000",
  },
  {
    id: 127662,
    name: "Timmonsville",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.13488000",
    longitude: "-79.93979000",
  },
  {
    id: 127783,
    name: "Travelers Rest",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.96762000",
    longitude: "-82.44345000",
  },
  {
    id: 127974,
    name: "Union",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.71541000",
    longitude: "-81.62371000",
  },
  {
    id: 127996,
    name: "Union County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.68928000",
    longitude: "-81.61942000",
  },
  {
    id: 128065,
    name: "Utica",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.67816000",
    longitude: "-82.93154000",
  },
  {
    id: 128107,
    name: "Valley Falls",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "35.01595000",
    longitude: "-81.97483000",
  },
  {
    id: 128156,
    name: "Varnville",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.85044000",
    longitude: "-81.07927000",
  },
  {
    id: 128320,
    name: "Wade Hampton",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.90373000",
    longitude: "-82.33317000",
  },
  {
    id: 128382,
    name: "Walhalla",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.76482000",
    longitude: "-83.06404000",
  },
  {
    id: 128428,
    name: "Walterboro",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "32.90517000",
    longitude: "-80.66677000",
  },
  {
    id: 128464,
    name: "Ware Shoals",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.39845000",
    longitude: "-82.24679000",
  },
  {
    id: 128509,
    name: "Warrenville",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.55097000",
    longitude: "-81.80400000",
  },
  {
    id: 128643,
    name: "Watts Mills",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.51641000",
    longitude: "-81.98580000",
  },
  {
    id: 128748,
    name: "Wedgefield",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.89266000",
    longitude: "-80.51814000",
  },
  {
    id: 128749,
    name: "Wedgewood",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.88377000",
    longitude: "-80.51258000",
  },
  {
    id: 128767,
    name: "Welcome",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.82651000",
    longitude: "-82.43901000",
  },
  {
    id: 128775,
    name: "Wellford",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.95095000",
    longitude: "-82.10594000",
  },
  {
    id: 128848,
    name: "West Columbia",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.99349000",
    longitude: "-81.07398000",
  },
  {
    id: 129045,
    name: "Westminster",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.66482000",
    longitude: "-83.09654000",
  },
  {
    id: 129205,
    name: "Whitmire",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.50291000",
    longitude: "-81.61149000",
  },
  {
    id: 129252,
    name: "Wilkinson Heights",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.50210000",
    longitude: "-80.83315000",
  },
  {
    id: 129272,
    name: "Williamsburg County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.61993000",
    longitude: "-79.72771000",
  },
  {
    id: 129283,
    name: "Williamston",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.61845000",
    longitude: "-82.47791000",
  },
  {
    id: 129297,
    name: "Williston",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "33.40265000",
    longitude: "-81.42011000",
  },
  {
    id: 129419,
    name: "Winnsboro",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.38070000",
    longitude: "-81.08648000",
  },
  {
    id: 129421,
    name: "Winnsboro Mills",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.36181000",
    longitude: "-81.08537000",
  },
  {
    id: 129517,
    name: "Woodfield",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.05932000",
    longitude: "-80.93092000",
  },
  {
    id: 129547,
    name: "Woodruff",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.73957000",
    longitude: "-82.03705000",
  },
  {
    id: 129691,
    name: "York",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.99430000",
    longitude: "-81.24202000",
  },
  {
    id: 129695,
    name: "York County",
    state_id: 1443,
    state_code: "SC",
    country_id: 233,
    country_code: "US",
    latitude: "34.97475000",
    longitude: "-81.18442000",
  },
  {
    id: 110977,
    name: "Aberdeen",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.46470000",
    longitude: "-98.48648000",
  },
  {
    id: 111156,
    name: "Alexandria",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.65359000",
    longitude: "-97.78285000",
  },
  {
    id: 111479,
    name: "Armour",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.31860000",
    longitude: "-98.34675000",
  },
  {
    id: 111664,
    name: "Aurora County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.71801000",
    longitude: "-98.56155000",
  },
  {
    id: 111782,
    name: "Baltic",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.76136000",
    longitude: "-96.74033000",
  },
  {
    id: 111970,
    name: "Beadle County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.41448000",
    longitude: "-98.27811000",
  },
  {
    id: 112081,
    name: "Belle Fourche",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.67137000",
    longitude: "-103.85215000",
  },
  {
    id: 112174,
    name: "Bennett County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.19492000",
    longitude: "-101.66397000",
  },
  {
    id: 112215,
    name: "Beresford",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.08054000",
    longitude: "-96.77366000",
  },
  {
    id: 112368,
    name: "Bison",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.52026000",
    longitude: "-102.46127000",
  },
  {
    id: 112388,
    name: "Blackhawk",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.15110000",
    longitude: "-103.30796000",
  },
  {
    id: 112528,
    name: "Bon Homme County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "42.98837000",
    longitude: "-97.88463000",
  },
  {
    id: 112636,
    name: "Box Elder",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.11249000",
    longitude: "-103.06823000",
  },
  {
    id: 112691,
    name: "Brandon",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.59470000",
    longitude: "-96.57199000",
  },
  {
    id: 112819,
    name: "Britton",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.79162000",
    longitude: "-97.75094000",
  },
  {
    id: 112861,
    name: "Brookings",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.31136000",
    longitude: "-96.79839000",
  },
  {
    id: 112863,
    name: "Brookings County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.36968000",
    longitude: "-96.79042000",
  },
  {
    id: 112913,
    name: "Brown County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.58972000",
    longitude: "-98.35161000",
  },
  {
    id: 112945,
    name: "Brule County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.71806000",
    longitude: "-99.08094000",
  },
  {
    id: 113015,
    name: "Buffalo",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.58416000",
    longitude: "-103.54603000",
  },
  {
    id: 113019,
    name: "Buffalo County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.07635000",
    longitude: "-99.20496000",
  },
  {
    id: 113055,
    name: "Burke",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.18250000",
    longitude: "-99.29205000",
  },
  {
    id: 113127,
    name: "Butte County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.90583000",
    longitude: "-103.50802000",
  },
  {
    id: 113302,
    name: "Campbell County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.77113000",
    longitude: "-100.05163000",
  },
  {
    id: 113350,
    name: "Canton",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.30081000",
    longitude: "-96.59282000",
  },
  {
    id: 113718,
    name: "Chamberlain",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.81083000",
    longitude: "-99.33066000",
  },
  {
    id: 113758,
    name: "Charles Mix County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.20791000",
    longitude: "-98.58789000",
  },
  {
    id: 114120,
    name: "Clark",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.87774000",
    longitude: "-97.73314000",
  },
  {
    id: 114127,
    name: "Clark County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.85825000",
    longitude: "-97.72950000",
  },
  {
    id: 114190,
    name: "Clay County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "42.91465000",
    longitude: "-96.97566000",
  },
  {
    id: 114210,
    name: "Clear Lake",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.74580000",
    longitude: "-96.68256000",
  },
  {
    id: 114363,
    name: "Codington County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.97785000",
    longitude: "-97.18862000",
  },
  {
    id: 114451,
    name: "Colonial Pine Hills",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.00777000",
    longitude: "-103.31546000",
  },
  {
    id: 114671,
    name: "Corson County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.70860000",
    longitude: "-101.19687000",
  },
  {
    id: 114839,
    name: "Crooks",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.66470000",
    longitude: "-96.81089000",
  },
  {
    id: 114934,
    name: "Custer",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.76665000",
    longitude: "-103.59881000",
  },
  {
    id: 114940,
    name: "Custer County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.67763000",
    longitude: "-103.45154000",
  },
  {
    id: 114978,
    name: "Dakota Dunes",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "42.48749000",
    longitude: "-96.48642000",
  },
  {
    id: 115079,
    name: "Davison County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.67474000",
    longitude: "-98.14600000",
  },
  {
    id: 115089,
    name: "Day County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.36715000",
    longitude: "-97.60741000",
  },
  {
    id: 115114,
    name: "De Smet",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.38747000",
    longitude: "-97.55035000",
  },
  {
    id: 115142,
    name: "Deadwood",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.37665000",
    longitude: "-103.72964000",
  },
  {
    id: 115214,
    name: "Dell Rapids",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.82608000",
    longitude: "-96.70616000",
  },
  {
    id: 115294,
    name: "Deuel County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.76006000",
    longitude: "-96.66797000",
  },
  {
    id: 115304,
    name: "Dewey County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.15662000",
    longitude: "-100.87186000",
  },
  {
    id: 115431,
    name: "Douglas County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.38692000",
    longitude: "-98.36610000",
  },
  {
    id: 115559,
    name: "Dupree",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.04748000",
    longitude: "-101.60099000",
  },
  {
    id: 115599,
    name: "Eagle Butte",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.00248000",
    longitude: "-101.23349000",
  },
  {
    id: 115883,
    name: "Edmunds County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.41880000",
    longitude: "-99.21533000",
  },
  {
    id: 115990,
    name: "Elk Point",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "42.68333000",
    longitude: "-96.68365000",
  },
  {
    id: 116375,
    name: "Fall River County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.23939000",
    longitude: "-103.52756000",
  },
  {
    id: 116433,
    name: "Faulk County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.07101000",
    longitude: "-99.14525000",
  },
  {
    id: 116435,
    name: "Faulkton",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.03497000",
    longitude: "-99.12400000",
  },
  {
    id: 116549,
    name: "Flandreau",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.04942000",
    longitude: "-96.59532000",
  },
  {
    id: 116742,
    name: "Fort Pierre",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.35359000",
    longitude: "-100.37374000",
  },
  {
    id: 116755,
    name: "Fort Thompson",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.06860000",
    longitude: "-99.43788000",
  },
  {
    id: 116908,
    name: "Freeman",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.35249000",
    longitude: "-97.43729000",
  },
  {
    id: 117097,
    name: "Garretson",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.71747000",
    longitude: "-96.50282000",
  },
  {
    id: 117180,
    name: "Gettysburg",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.01165000",
    longitude: "-99.95567000",
  },
  {
    id: 117514,
    name: "Grant County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.17194000",
    longitude: "-96.76769000",
  },
  {
    id: 117717,
    name: "Gregory",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.23222000",
    longitude: "-99.43038000",
  },
  {
    id: 117718,
    name: "Gregory County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.19238000",
    longitude: "-99.18561000",
  },
  {
    id: 117751,
    name: "Groton",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.44746000",
    longitude: "-98.09871000",
  },
  {
    id: 117823,
    name: "Haakon County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.29443000",
    longitude: "-101.53999000",
  },
  {
    id: 117911,
    name: "Hamlin County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.67376000",
    longitude: "-97.18833000",
  },
  {
    id: 117959,
    name: "Hand County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.54778000",
    longitude: "-99.00494000",
  },
  {
    id: 117979,
    name: "Hanson County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.67482000",
    longitude: "-97.78734000",
  },
  {
    id: 118005,
    name: "Harding County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.58035000",
    longitude: "-103.49577000",
  },
  {
    id: 118051,
    name: "Harrisburg",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.43137000",
    longitude: "-96.69727000",
  },
  {
    id: 118093,
    name: "Hartford",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.62303000",
    longitude: "-96.94255000",
  },
  {
    id: 118195,
    name: "Hayti",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.65719000",
    longitude: "-97.20507000",
  },
  {
    id: 118422,
    name: "Highmore",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.52137000",
    longitude: "-99.44150000",
  },
  {
    id: 118683,
    name: "Hot Springs",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.43165000",
    longitude: "-103.47436000",
  },
  {
    id: 118709,
    name: "Howard",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.01081000",
    longitude: "-97.52674000",
  },
  {
    id: 118759,
    name: "Hughes County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.38903000",
    longitude: "-99.99605000",
  },
  {
    id: 118824,
    name: "Huron",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.36332000",
    longitude: "-98.21426000",
  },
  {
    id: 118837,
    name: "Hutchinson County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.33485000",
    longitude: "-97.75442000",
  },
  {
    id: 118848,
    name: "Hyde County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.54736000",
    longitude: "-99.48711000",
  },
  {
    id: 118983,
    name: "Ipswich",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.44442000",
    longitude: "-99.02928000",
  },
  {
    id: 119096,
    name: "Jackson County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.69421000",
    longitude: "-101.62813000",
  },
  {
    id: 119223,
    name: "Jerauld County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.06635000",
    longitude: "-98.62973000",
  },
  {
    id: 119297,
    name: "Jones County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.96061000",
    longitude: "-100.68972000",
  },
  {
    id: 119350,
    name: "Kadoka",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.83388000",
    longitude: "-101.50987000",
  },
  {
    id: 119464,
    name: "Kennebec",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.90360000",
    longitude: "-99.86178000",
  },
  {
    id: 119611,
    name: "Kingsbury County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.36959000",
    longitude: "-97.49148000",
  },
  {
    id: 119863,
    name: "Lake Andes",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.15638000",
    longitude: "-98.54147000",
  },
  {
    id: 119895,
    name: "Lake County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.02204000",
    longitude: "-97.12938000",
  },
  {
    id: 120238,
    name: "Lawrence County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.35863000",
    longitude: "-103.79222000",
  },
  {
    id: 120270,
    name: "Lead",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.35221000",
    longitude: "-103.76520000",
  },
  {
    id: 120357,
    name: "Lemmon",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.94083000",
    longitude: "-102.15932000",
  },
  {
    id: 120370,
    name: "Lennox",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.35415000",
    longitude: "-96.89200000",
  },
  {
    id: 120381,
    name: "Leola",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.72275000",
    longitude: "-98.94094000",
  },
  {
    id: 120528,
    name: "Lincoln County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.27892000",
    longitude: "-96.72182000",
  },
  {
    id: 120911,
    name: "Lyman County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.89582000",
    longitude: "-99.84738000",
  },
  {
    id: 121000,
    name: "Madison",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.00608000",
    longitude: "-97.11395000",
  },
  {
    id: 121321,
    name: "Marshall County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.75867000",
    longitude: "-97.59850000",
  },
  {
    id: 121336,
    name: "Martin",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.17250000",
    longitude: "-101.73265000",
  },
  {
    id: 121480,
    name: "McCook County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.67431000",
    longitude: "-97.36844000",
  },
  {
    id: 121512,
    name: "McIntosh",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.92139000",
    longitude: "-101.34958000",
  },
  {
    id: 121547,
    name: "McPherson County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.76637000",
    longitude: "-99.22146000",
  },
  {
    id: 121558,
    name: "Meade County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.56684000",
    longitude: "-102.71687000",
  },
  {
    id: 121625,
    name: "Mellette County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.58127000",
    longitude: "-100.75999000",
  },
  {
    id: 121842,
    name: "Milbank",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.21913000",
    longitude: "-96.63562000",
  },
  {
    id: 121879,
    name: "Miller",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.51831000",
    longitude: "-98.98843000",
  },
  {
    id: 121933,
    name: "Miner County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.02195000",
    longitude: "-97.61025000",
  },
  {
    id: 121953,
    name: "Minnehaha County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.67418000",
    longitude: "-96.79144000",
  },
  {
    id: 121982,
    name: "Mission",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.30584000",
    longitude: "-100.65819000",
  },
  {
    id: 121997,
    name: "Mitchell",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.70943000",
    longitude: "-98.02980000",
  },
  {
    id: 122012,
    name: "Mobridge",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.53722000",
    longitude: "-100.42791000",
  },
  {
    id: 122197,
    name: "Moody County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.02201000",
    longitude: "-96.67092000",
  },
  {
    id: 122323,
    name: "Mound City",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.72527000",
    longitude: "-100.06845000",
  },
  {
    id: 122479,
    name: "Murdo",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.88832000",
    longitude: "-100.71291000",
  },
  {
    id: 123016,
    name: "North Eagle Butte",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.00415000",
    longitude: "-101.23376000",
  },
  {
    id: 123106,
    name: "North Sioux City",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "42.52722000",
    longitude: "-96.48309000",
  },
  {
    id: 123109,
    name: "North Spearfish",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.50665000",
    longitude: "-103.89215000",
  },
  {
    id: 123374,
    name: "Oglala",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.18859000",
    longitude: "-102.73962000",
  },
  {
    id: 123375,
    name: "Oglala Lakota County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.33559000",
    longitude: "-102.55162000",
  },
  {
    id: 123448,
    name: "Olivet",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.24082000",
    longitude: "-97.67534000",
  },
  {
    id: 123480,
    name: "Onida",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.70804000",
    longitude: "-100.05984000",
  },
  {
    id: 123862,
    name: "Parker",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.39748000",
    longitude: "-97.13645000",
  },
  {
    id: 123876,
    name: "Parkston",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.39888000",
    longitude: "-97.98368000",
  },
  {
    id: 124031,
    name: "Pennington County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.00373000",
    longitude: "-102.82383000",
  },
  {
    id: 124064,
    name: "Perkins County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.49051000",
    longitude: "-102.47563000",
  },
  {
    id: 124129,
    name: "Philip",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.03943000",
    longitude: "-101.66514000",
  },
  {
    id: 124178,
    name: "Pierre",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.36832000",
    longitude: "-100.35097000",
  },
  {
    id: 124243,
    name: "Pine Ridge",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.02554000",
    longitude: "-102.55627000",
  },
  {
    id: 124341,
    name: "Plankinton",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.71555000",
    longitude: "-98.48509000",
  },
  {
    id: 124351,
    name: "Platte",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.38694000",
    longitude: "-98.84453000",
  },
  {
    id: 124490,
    name: "Porcupine",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.23971000",
    longitude: "-102.33099000",
  },
  {
    id: 124593,
    name: "Potter County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.06450000",
    longitude: "-99.95725000",
  },
  {
    id: 124894,
    name: "Rapid City",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.08054000",
    longitude: "-103.23101000",
  },
  {
    id: 124895,
    name: "Rapid Valley",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.06249000",
    longitude: "-103.14629000",
  },
  {
    id: 124968,
    name: "Redfield",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.87581000",
    longitude: "-98.51871000",
  },
  {
    id: 125242,
    name: "Roberts County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.62965000",
    longitude: "-96.94612000",
  },
  {
    id: 125412,
    name: "Rosebud",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.23278000",
    longitude: "-100.85348000",
  },
  {
    id: 125737,
    name: "Salem",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.72415000",
    longitude: "-97.38895000",
  },
  {
    id: 125855,
    name: "Sanborn County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.02345000",
    longitude: "-98.09139000",
  },
  {
    id: 126130,
    name: "Selby",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.50638000",
    longitude: "-100.03207000",
  },
  {
    id: 126462,
    name: "Sioux Falls",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.54997000",
    longitude: "-96.70033000",
  },
  {
    id: 126466,
    name: "Sisseton",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.66468000",
    longitude: "-97.04980000",
  },
  {
    id: 126816,
    name: "Spearfish",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.49082000",
    longitude: "-103.85937000",
  },
  {
    id: 126836,
    name: "Spink County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.93802000",
    longitude: "-98.34619000",
  },
  {
    id: 126905,
    name: "Springfield",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "42.85417000",
    longitude: "-97.89729000",
  },
  {
    id: 126962,
    name: "Stanley County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.41232000",
    longitude: "-100.73594000",
  },
  {
    id: 127152,
    name: "Sturgis",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.40971000",
    longitude: "-103.50908000",
  },
  {
    id: 127193,
    name: "Sully County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.71558000",
    longitude: "-100.13220000",
  },
  {
    id: 127207,
    name: "Summerset",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.18998000",
    longitude: "-103.34384000",
  },
  {
    id: 127473,
    name: "Tea",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.44637000",
    longitude: "-96.83588000",
  },
  {
    id: 127656,
    name: "Timber Lake",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.42915000",
    longitude: "-101.07403000",
  },
  {
    id: 127694,
    name: "Todd County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.19337000",
    longitude: "-100.71841000",
  },
  {
    id: 127832,
    name: "Tripp County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.34587000",
    longitude: "-99.88400000",
  },
  {
    id: 127904,
    name: "Turner County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.31087000",
    longitude: "-97.14866000",
  },
  {
    id: 127945,
    name: "Tyndall",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "42.99333000",
    longitude: "-97.86285000",
  },
  {
    id: 128001,
    name: "Union County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "42.83249000",
    longitude: "-96.65609000",
  },
  {
    id: 128185,
    name: "Vermillion",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "42.77944000",
    longitude: "-96.92921000",
  },
  {
    id: 128300,
    name: "Volga",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.32358000",
    longitude: "-96.92645000",
  },
  {
    id: 128328,
    name: "Wagner",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.07972000",
    longitude: "-98.29313000",
  },
  {
    id: 128441,
    name: "Walworth County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.42995000",
    longitude: "-100.03156000",
  },
  {
    id: 128625,
    name: "Watertown",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.89941000",
    longitude: "-97.11507000",
  },
  {
    id: 128734,
    name: "Webster",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "45.33218000",
    longitude: "-97.52009000",
  },
  {
    id: 128813,
    name: "Wessington Springs",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.07916000",
    longitude: "-98.56954000",
  },
  {
    id: 129156,
    name: "White River",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.56805000",
    longitude: "-100.74542000",
  },
  {
    id: 129412,
    name: "Winner",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.37667000",
    longitude: "-99.85901000",
  },
  {
    id: 129583,
    name: "Woonsocket",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.05360000",
    longitude: "-98.27564000",
  },
  {
    id: 129661,
    name: "Yankton",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "42.87111000",
    longitude: "-97.39728000",
  },
  {
    id: 129662,
    name: "Yankton County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "43.00897000",
    longitude: "-97.39475000",
  },
  {
    id: 129753,
    name: "Ziebach County",
    state_id: 1445,
    state_code: "SD",
    country_id: 233,
    country_code: "US",
    latitude: "44.98041000",
    longitude: "-101.66586000",
  },
  {
    id: 111031,
    name: "Adamsville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.23591000",
    longitude: "-88.39060000",
  },
  {
    id: 111091,
    name: "Alamo",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.78479000",
    longitude: "-89.11729000",
  },
  {
    id: 111132,
    name: "Alcoa",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.78953000",
    longitude: "-83.97379000",
  },
  {
    id: 111168,
    name: "Algood",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.19589000",
    longitude: "-85.44858000",
  },
  {
    id: 111234,
    name: "Altamont",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.42952000",
    longitude: "-85.72303000",
  },
  {
    id: 111318,
    name: "Anderson County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.11844000",
    longitude: "-84.19846000",
  },
  {
    id: 111385,
    name: "Apison",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.02396000",
    longitude: "-85.02384000",
  },
  {
    id: 111441,
    name: "Ardmore",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "34.99203000",
    longitude: "-86.84667000",
  },
  {
    id: 111462,
    name: "Arlington",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.29620000",
    longitude: "-89.66147000",
  },
  {
    id: 111541,
    name: "Ashland City",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.27422000",
    longitude: "-87.06417000",
  },
  {
    id: 111575,
    name: "Athens",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.44285000",
    longitude: "-84.59299000",
  },
  {
    id: 111604,
    name: "Atoka",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.44119000",
    longitude: "-89.77814000",
  },
  {
    id: 111802,
    name: "Banner Hill",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.13066000",
    longitude: "-82.42458000",
  },
  {
    id: 111861,
    name: "Bartlett",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.20453000",
    longitude: "-89.87398000",
  },
  {
    id: 111914,
    name: "Baxter",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.15367000",
    longitude: "-85.64359000",
  },
  {
    id: 111973,
    name: "Bean Station",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.34370000",
    longitude: "-83.28406000",
  },
  {
    id: 112028,
    name: "Bedford County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.51380000",
    longitude: "-86.45889000",
  },
  {
    id: 112087,
    name: "Belle Meade",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.09589000",
    longitude: "-86.85694000",
  },
  {
    id: 112126,
    name: "Bells",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.71118000",
    longitude: "-89.08756000",
  },
  {
    id: 112196,
    name: "Benton",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.17424000",
    longitude: "-84.65355000",
  },
  {
    id: 112202,
    name: "Benton County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.06978000",
    longitude: "-88.06827000",
  },
  {
    id: 112402,
    name: "Blaine",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.15425000",
    longitude: "-83.70407000",
  },
  {
    id: 112428,
    name: "Bledsoe County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.59642000",
    longitude: "-85.20516000",
  },
  {
    id: 112446,
    name: "Bloomingdale",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.58455000",
    longitude: "-82.48932000",
  },
  {
    id: 112458,
    name: "Blount County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.68724000",
    longitude: "-83.92553000",
  },
  {
    id: 112461,
    name: "Blountville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.53316000",
    longitude: "-82.32681000",
  },
  {
    id: 112481,
    name: "Bluff City",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.47427000",
    longitude: "-82.26097000",
  },
  {
    id: 112518,
    name: "Bolivar",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.25619000",
    longitude: "-88.98784000",
  },
  {
    id: 112527,
    name: "Bon Aqua Junction",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.92784000",
    longitude: "-87.31084000",
  },
  {
    id: 112664,
    name: "Bradford",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.07645000",
    longitude: "-88.81006000",
  },
  {
    id: 112676,
    name: "Bradley County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.15411000",
    longitude: "-84.85960000",
  },
  {
    id: 112729,
    name: "Brentwood",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.03312000",
    longitude: "-86.78278000",
  },
  {
    id: 112734,
    name: "Brentwood Estates",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.02506000",
    longitude: "-86.77917000",
  },
  {
    id: 112788,
    name: "Brighton",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.48397000",
    longitude: "-89.72508000",
  },
  {
    id: 112803,
    name: "Bristol",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.59511000",
    longitude: "-82.18874000",
  },
  {
    id: 112931,
    name: "Brownsville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.59397000",
    longitude: "-89.26229000",
  },
  {
    id: 112943,
    name: "Bruceton",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.03812000",
    longitude: "-88.24449000",
  },
  {
    id: 113086,
    name: "Burns",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.05339000",
    longitude: "-87.31251000",
  },
  {
    id: 113141,
    name: "Byrdstown",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.57451000",
    longitude: "-85.12884000",
  },
  {
    id: 113265,
    name: "Camden",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.05895000",
    longitude: "-88.09782000",
  },
  {
    id: 113299,
    name: "Campbell County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.40351000",
    longitude: "-84.14938000",
  },
  {
    id: 113333,
    name: "Cannon County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.80868000",
    longitude: "-86.06174000",
  },
  {
    id: 113456,
    name: "Carroll County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.97317000",
    longitude: "-88.45026000",
  },
  {
    id: 113483,
    name: "Carter County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.29272000",
    longitude: "-82.12743000",
  },
  {
    id: 113494,
    name: "Carthage",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.25228000",
    longitude: "-85.95165000",
  },
  {
    id: 113507,
    name: "Caryville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.29897000",
    longitude: "-84.22326000",
  },
  {
    id: 113630,
    name: "Celina",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.55006000",
    longitude: "-85.50525000",
  },
  {
    id: 113655,
    name: "Centerville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.77896000",
    longitude: "-87.46696000",
  },
  {
    id: 113664,
    name: "Central",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.32622000",
    longitude: "-82.28958000",
  },
  {
    id: 113742,
    name: "Chapel Hill",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.62646000",
    longitude: "-86.69333000",
  },
  {
    id: 113777,
    name: "Charlotte",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.17728000",
    longitude: "-87.33973000",
  },
  {
    id: 113811,
    name: "Chattanooga",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.04563000",
    longitude: "-85.30968000",
  },
  {
    id: 113819,
    name: "Cheatham County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.26117000",
    longitude: "-87.08678000",
  },
  {
    id: 113896,
    name: "Chester County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.42175000",
    longitude: "-88.61346000",
  },
  {
    id: 113998,
    name: "Christiana",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.71007000",
    longitude: "-86.39944000",
  },
  {
    id: 114007,
    name: "Church Hill",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.52232000",
    longitude: "-82.71349000",
  },
  {
    id: 114094,
    name: "Claiborne County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.48585000",
    longitude: "-83.66042000",
  },
  {
    id: 114154,
    name: "Clarksville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.52977000",
    longitude: "-87.35945000",
  },
  {
    id: 114184,
    name: "Clay County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.55116000",
    longitude: "-85.54386000",
  },
  {
    id: 114244,
    name: "Cleveland",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.15952000",
    longitude: "-84.87661000",
  },
  {
    id: 114255,
    name: "Clifton",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.38702000",
    longitude: "-87.99531000",
  },
  {
    id: 114275,
    name: "Clinton",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.10341000",
    longitude: "-84.13186000",
  },
  {
    id: 114335,
    name: "Coalfield",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.02897000",
    longitude: "-84.42076000",
  },
  {
    id: 114354,
    name: "Cocke County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.92542000",
    longitude: "-83.12116000",
  },
  {
    id: 114369,
    name: "Coffee County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.49062000",
    longitude: "-86.07476000",
  },
  {
    id: 114418,
    name: "Collegedale",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.05313000",
    longitude: "-85.05023000",
  },
  {
    id: 114424,
    name: "Collierville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.04204000",
    longitude: "-89.66453000",
  },
  {
    id: 114448,
    name: "Colonial Heights",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.48510000",
    longitude: "-82.50320000",
  },
  {
    id: 114472,
    name: "Columbia",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.61507000",
    longitude: "-87.03528000",
  },
  {
    id: 114548,
    name: "Condon",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.18119000",
    longitude: "-83.79380000",
  },
  {
    id: 114595,
    name: "Cookeville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.16284000",
    longitude: "-85.50164000",
  },
  {
    id: 114609,
    name: "Coopertown",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.43755000",
    longitude: "-86.96722000",
  },
  {
    id: 114650,
    name: "Cornersville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.36146000",
    longitude: "-86.83972000",
  },
  {
    id: 114738,
    name: "Covington",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.56425000",
    longitude: "-89.64647000",
  },
  {
    id: 114744,
    name: "Cowan",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.16453000",
    longitude: "-86.01054000",
  },
  {
    id: 114831,
    name: "Crockett County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.81354000",
    longitude: "-89.13953000",
  },
  {
    id: 114852,
    name: "Cross Plains",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.54866000",
    longitude: "-86.69611000",
  },
  {
    id: 114856,
    name: "Crossville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.94896000",
    longitude: "-85.02690000",
  },
  {
    id: 114873,
    name: "Crump",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.22175000",
    longitude: "-88.31809000",
  },
  {
    id: 114916,
    name: "Cumberland County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.95039000",
    longitude: "-84.99835000",
  },
  {
    id: 115018,
    name: "Dandridge",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.01537000",
    longitude: "-83.41489000",
  },
  {
    id: 115064,
    name: "Davidson County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.17069000",
    longitude: "-86.77753000",
  },
  {
    id: 115092,
    name: "Dayton",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.49396000",
    longitude: "-85.01245000",
  },
  {
    id: 115154,
    name: "Decatur",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.51479000",
    longitude: "-84.79022000",
  },
  {
    id: 115161,
    name: "Decatur County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.60302000",
    longitude: "-88.10877000",
  },
  {
    id: 115164,
    name: "Decaturville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.58424000",
    longitude: "-88.11948000",
  },
  {
    id: 115165,
    name: "Decherd",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.20980000",
    longitude: "-86.07943000",
  },
  {
    id: 115131,
    name: "DeKalb County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.97986000",
    longitude: "-85.83275000",
  },
  {
    id: 115331,
    name: "Dickson",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.07700000",
    longitude: "-87.38779000",
  },
  {
    id: 115333,
    name: "Dickson County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.14905000",
    longitude: "-87.35666000",
  },
  {
    id: 115382,
    name: "Dodson Branch",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.31256000",
    longitude: "-85.53220000",
  },
  {
    id: 115447,
    name: "Dover",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.48783000",
    longitude: "-87.83836000",
  },
  {
    id: 115471,
    name: "Dresden",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.29145000",
    longitude: "-88.70811000",
  },
  {
    id: 115542,
    name: "Dunlap",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.37146000",
    longitude: "-85.39052000",
  },
  {
    id: 115585,
    name: "Dyer",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.06673000",
    longitude: "-88.99395000",
  },
  {
    id: 115587,
    name: "Dyer County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.05906000",
    longitude: "-89.41377000",
  },
  {
    id: 115588,
    name: "Dyersburg",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.03452000",
    longitude: "-89.38563000",
  },
  {
    id: 115613,
    name: "Eagleton Village",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.79508000",
    longitude: "-83.93185000",
  },
  {
    id: 115635,
    name: "East Brainerd",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "34.99591000",
    longitude: "-85.15023000",
  },
  {
    id: 115644,
    name: "East Chattanooga",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.06535000",
    longitude: "-85.24912000",
  },
  {
    id: 115646,
    name: "East Cleveland",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.16091000",
    longitude: "-84.85772000",
  },
  {
    id: 115747,
    name: "East Ridge",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.01424000",
    longitude: "-85.25190000",
  },
  {
    id: 115976,
    name: "Elizabethton",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.34872000",
    longitude: "-82.21069000",
  },
  {
    id: 116130,
    name: "Englewood",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.42452000",
    longitude: "-84.48743000",
  },
  {
    id: 116170,
    name: "Erin",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.31839000",
    longitude: "-87.69474000",
  },
  {
    id: 116176,
    name: "Erwin",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.14511000",
    longitude: "-82.41681000",
  },
  {
    id: 116213,
    name: "Estill Springs",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.27064000",
    longitude: "-86.12804000",
  },
  {
    id: 116218,
    name: "Etowah",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.32341000",
    longitude: "-84.52493000",
  },
  {
    id: 116321,
    name: "Fairfield Glade",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.00035000",
    longitude: "-84.88634000",
  },
  {
    id: 116339,
    name: "Fairmount",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.18146000",
    longitude: "-85.32357000",
  },
  {
    id: 116349,
    name: "Fairview",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.98201000",
    longitude: "-87.12140000",
  },
  {
    id: 116370,
    name: "Fall Branch",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.41816000",
    longitude: "-82.62376000",
  },
  {
    id: 116377,
    name: "Falling Water",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.20312000",
    longitude: "-85.25357000",
  },
  {
    id: 116429,
    name: "Farragut",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.88452000",
    longitude: "-84.15353000",
  },
  {
    id: 116448,
    name: "Fayette County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.19708000",
    longitude: "-89.41437000",
  },
  {
    id: 116457,
    name: "Fayetteville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.15203000",
    longitude: "-86.57055000",
  },
  {
    id: 116476,
    name: "Fentress County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.38049000",
    longitude: "-84.93246000",
  },
  {
    id: 116513,
    name: "Fincastle",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.40980000",
    longitude: "-84.04770000",
  },
  {
    id: 116656,
    name: "Forest Hills",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.06839000",
    longitude: "-86.84417000",
  },
  {
    id: 116829,
    name: "Franklin",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.92506000",
    longitude: "-86.86889000",
  },
  {
    id: 116854,
    name: "Franklin County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.15496000",
    longitude: "-86.09218000",
  },
  {
    id: 117013,
    name: "Gainesboro",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.35561000",
    longitude: "-85.65887000",
  },
  {
    id: 117033,
    name: "Gallatin",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.38838000",
    longitude: "-86.44666000",
  },
  {
    id: 117125,
    name: "Gatlinburg",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.71453000",
    longitude: "-83.51189000",
  },
  {
    id: 117173,
    name: "Germantown",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.08676000",
    longitude: "-89.81009000",
  },
  {
    id: 117190,
    name: "Gibson County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.99661000",
    longitude: "-88.93262000",
  },
  {
    id: 117210,
    name: "Giles County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.20215000",
    longitude: "-87.03478000",
  },
  {
    id: 117257,
    name: "Gleason",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.21367000",
    longitude: "-88.61255000",
  },
  {
    id: 117386,
    name: "Goodlettsville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.32311000",
    longitude: "-86.71333000",
  },
  {
    id: 117400,
    name: "Gordonsville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.17256000",
    longitude: "-85.92971000",
  },
  {
    id: 117441,
    name: "Grainger County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.27625000",
    longitude: "-83.50962000",
  },
  {
    id: 117552,
    name: "Gray",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.41983000",
    longitude: "-82.47654000",
  },
  {
    id: 117567,
    name: "Graysville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.44701000",
    longitude: "-85.08440000",
  },
  {
    id: 117598,
    name: "Green Hill",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.22283000",
    longitude: "-86.54944000",
  },
  {
    id: 117615,
    name: "Greenback",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.66119000",
    longitude: "-84.17214000",
  },
  {
    id: 117620,
    name: "Greenbrier",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.42755000",
    longitude: "-86.80472000",
  },
  {
    id: 117639,
    name: "Greene County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.17536000",
    longitude: "-82.84582000",
  },
  {
    id: 117644,
    name: "Greeneville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.16316000",
    longitude: "-82.83099000",
  },
  {
    id: 117648,
    name: "Greenfield",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.15340000",
    longitude: "-88.80062000",
  },
  {
    id: 117739,
    name: "Grimsley",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.26701000",
    longitude: "-84.98440000",
  },
  {
    id: 117767,
    name: "Gruetli-Laager",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.37230000",
    longitude: "-85.61803000",
  },
  {
    id: 117770,
    name: "Grundy County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.38837000",
    longitude: "-85.72258000",
  },
  {
    id: 117873,
    name: "Halls",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.87563000",
    longitude: "-89.39618000",
  },
  {
    id: 117880,
    name: "Hamblen County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.21715000",
    longitude: "-83.26666000",
  },
  {
    id: 117898,
    name: "Hamilton County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.18086000",
    longitude: "-85.16479000",
  },
  {
    id: 117954,
    name: "Hancock County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.52365000",
    longitude: "-83.22183000",
  },
  {
    id: 117995,
    name: "Hardeman County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.20687000",
    longitude: "-88.99308000",
  },
  {
    id: 118001,
    name: "Hardin County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.19868000",
    longitude: "-88.18448000",
  },
  {
    id: 118039,
    name: "Harriman",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.93396000",
    longitude: "-84.55244000",
  },
  {
    id: 118055,
    name: "Harrison",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.11368000",
    longitude: "-85.13801000",
  },
  {
    id: 118079,
    name: "Harrogate",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.58230000",
    longitude: "-83.65686000",
  },
  {
    id: 118105,
    name: "Hartsville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.39088000",
    longitude: "-86.16721000",
  },
  {
    id: 118166,
    name: "Hawkins County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.44117000",
    longitude: "-82.94468000",
  },
  {
    id: 118199,
    name: "Haywood County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.58322000",
    longitude: "-89.28384000",
  },
  {
    id: 118277,
    name: "Henderson",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.43924000",
    longitude: "-88.64144000",
  },
  {
    id: 118282,
    name: "Henderson County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.65426000",
    longitude: "-88.38799000",
  },
  {
    id: 118286,
    name: "Hendersonville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.30477000",
    longitude: "-86.62000000",
  },
  {
    id: 118302,
    name: "Henry County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.33183000",
    longitude: "-88.30122000",
  },
  {
    id: 118364,
    name: "Hickman County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.80325000",
    longitude: "-87.47331000",
  },
  {
    id: 118370,
    name: "Hickory Withe",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.24398000",
    longitude: "-89.58869000",
  },
  {
    id: 118512,
    name: "Hohenwald",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.54785000",
    longitude: "-87.55196000",
  },
  {
    id: 118648,
    name: "Hopewell",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.23479000",
    longitude: "-84.88800000",
  },
  {
    id: 118704,
    name: "Houston County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.28597000",
    longitude: "-87.71704000",
  },
  {
    id: 118776,
    name: "Humboldt",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.81979000",
    longitude: "-88.91590000",
  },
  {
    id: 118785,
    name: "Humphreys County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.04082000",
    longitude: "-87.77563000",
  },
  {
    id: 118788,
    name: "Hunter",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.37178000",
    longitude: "-82.15874000",
  },
  {
    id: 118795,
    name: "Huntingdon",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.00062000",
    longitude: "-88.42811000",
  },
  {
    id: 118817,
    name: "Huntsville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.40980000",
    longitude: "-84.49049000",
  },
  {
    id: 119058,
    name: "Jacksboro",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.33008000",
    longitude: "-84.18382000",
  },
  {
    id: 119068,
    name: "Jackson",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.61452000",
    longitude: "-88.81395000",
  },
  {
    id: 119087,
    name: "Jackson County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.35921000",
    longitude: "-85.67315000",
  },
  {
    id: 119122,
    name: "Jamestown",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.42757000",
    longitude: "-84.93189000",
  },
  {
    id: 119143,
    name: "Jasper",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.07424000",
    longitude: "-85.62608000",
  },
  {
    id: 119174,
    name: "Jefferson City",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.12231000",
    longitude: "-83.49240000",
  },
  {
    id: 119186,
    name: "Jefferson County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.05099000",
    longitude: "-83.44631000",
  },
  {
    id: 119209,
    name: "Jellico",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.58786000",
    longitude: "-84.12687000",
  },
  {
    id: 119257,
    name: "Johnson City",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.31344000",
    longitude: "-82.35347000",
  },
  {
    id: 119267,
    name: "Johnson County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.45494000",
    longitude: "-81.85175000",
  },
  {
    id: 119275,
    name: "Johnsonville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.06006000",
    longitude: "-87.95281000",
  },
  {
    id: 119304,
    name: "Jonesborough",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.29427000",
    longitude: "-82.47348000",
  },
  {
    id: 119497,
    name: "Kenton",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.20229000",
    longitude: "-89.01229000",
  },
  {
    id: 119565,
    name: "Kimball",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.04786000",
    longitude: "-85.67191000",
  },
  {
    id: 119619,
    name: "Kingsport",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.54843000",
    longitude: "-82.56182000",
  },
  {
    id: 119621,
    name: "Kingston",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.88091000",
    longitude: "-84.50854000",
  },
  {
    id: 119632,
    name: "Kingston Springs",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.10200000",
    longitude: "-87.11501000",
  },
  {
    id: 119687,
    name: "Knox County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.99322000",
    longitude: "-83.93709000",
  },
  {
    id: 119694,
    name: "Knoxville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.96064000",
    longitude: "-83.92074000",
  },
  {
    id: 119792,
    name: "La Vergne",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.01562000",
    longitude: "-86.58194000",
  },
  {
    id: 119833,
    name: "Lafayette",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.52116000",
    longitude: "-86.02637000",
  },
  {
    id: 119800,
    name: "LaFollette",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.38286000",
    longitude: "-84.11993000",
  },
  {
    id: 119890,
    name: "Lake County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.33538000",
    longitude: "-89.49347000",
  },
  {
    id: 119984,
    name: "Lake Tansi",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.87285000",
    longitude: "-85.05440000",
  },
  {
    id: 120014,
    name: "Lakeland",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.23064000",
    longitude: "-89.74036000",
  },
  {
    id: 120036,
    name: "Lakesite",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.20868000",
    longitude: "-85.12690000",
  },
  {
    id: 120046,
    name: "Lakewood",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.24311000",
    longitude: "-86.63555000",
  },
  {
    id: 120181,
    name: "Lauderdale County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.76101000",
    longitude: "-89.63144000",
  },
  {
    id: 120236,
    name: "Lawrence County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.21727000",
    longitude: "-87.39559000",
  },
  {
    id: 120242,
    name: "Lawrenceburg",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.24230000",
    longitude: "-87.33474000",
  },
  {
    id: 120287,
    name: "Lebanon",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.20811000",
    longitude: "-86.29110000",
  },
  {
    id: 120373,
    name: "Lenoir City",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.79730000",
    longitude: "-84.25603000",
  },
  {
    id: 120412,
    name: "Lewis County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.52728000",
    longitude: "-87.49310000",
  },
  {
    id: 120419,
    name: "Lewisburg",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.44924000",
    longitude: "-86.78889000",
  },
  {
    id: 120444,
    name: "Lexington",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.65090000",
    longitude: "-88.39338000",
  },
  {
    id: 120524,
    name: "Lincoln County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.14052000",
    longitude: "-86.58894000",
  },
  {
    id: 120561,
    name: "Linden",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.61729000",
    longitude: "-87.83947000",
  },
  {
    id: 120649,
    name: "Livingston",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.38340000",
    longitude: "-85.32302000",
  },
  {
    id: 120728,
    name: "Lone Oak",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.20063000",
    longitude: "-85.36413000",
  },
  {
    id: 120766,
    name: "Lookout Mountain",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "34.99424000",
    longitude: "-85.34940000",
  },
  {
    id: 120774,
    name: "Loretto",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.07786000",
    longitude: "-87.43974000",
  },
  {
    id: 120799,
    name: "Loudon",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.73285000",
    longitude: "-84.33381000",
  },
  {
    id: 120800,
    name: "Loudon County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.73478000",
    longitude: "-84.31187000",
  },
  {
    id: 120815,
    name: "Louisville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.82175000",
    longitude: "-84.04796000",
  },
  {
    id: 120897,
    name: "Luttrell",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.19953000",
    longitude: "-83.74185000",
  },
  {
    id: 120915,
    name: "Lynchburg",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.28314000",
    longitude: "-86.37416000",
  },
  {
    id: 120972,
    name: "Macon County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.53203000",
    longitude: "-86.00727000",
  },
  {
    id: 121011,
    name: "Madison County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.60814000",
    longitude: "-88.83847000",
  },
  {
    id: 121026,
    name: "Madisonville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.51980000",
    longitude: "-84.36353000",
  },
  {
    id: 121093,
    name: "Manchester",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.48174000",
    longitude: "-86.08860000",
  },
  {
    id: 121258,
    name: "Marion County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.12935000",
    longitude: "-85.62203000",
  },
  {
    id: 121315,
    name: "Marshall County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.46886000",
    longitude: "-86.76502000",
  },
  {
    id: 121335,
    name: "Martin",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.34340000",
    longitude: "-88.85034000",
  },
  {
    id: 121364,
    name: "Maryville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.75647000",
    longitude: "-83.97046000",
  },
  {
    id: 121367,
    name: "Mascot",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.06120000",
    longitude: "-83.74573000",
  },
  {
    id: 121371,
    name: "Mason",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.41175000",
    longitude: "-89.53285000",
  },
  {
    id: 121426,
    name: "Maury County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.61694000",
    longitude: "-87.07701000",
  },
  {
    id: 121441,
    name: "Maynardville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.25064000",
    longitude: "-83.79741000",
  },
  {
    id: 121498,
    name: "McEwen",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.10784000",
    longitude: "-87.63307000",
  },
  {
    id: 121520,
    name: "McKenzie",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.13256000",
    longitude: "-88.51866000",
  },
  {
    id: 121539,
    name: "McMinn County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.42475000",
    longitude: "-84.61747000",
  },
  {
    id: 121540,
    name: "McMinnville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.68340000",
    longitude: "-85.76998000",
  },
  {
    id: 121544,
    name: "McNairy County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.17545000",
    longitude: "-88.56364000",
  },
  {
    id: 121602,
    name: "Medina",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.80285000",
    longitude: "-88.77478000",
  },
  {
    id: 121618,
    name: "Meigs County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.51283000",
    longitude: "-84.81339000",
  },
  {
    id: 121639,
    name: "Memphis",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.14953000",
    longitude: "-90.04898000",
  },
  {
    id: 121763,
    name: "Middle Valley",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.19590000",
    longitude: "-85.18468000",
  },
  {
    id: 121810,
    name: "Midtown",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.87952000",
    longitude: "-84.56410000",
  },
  {
    id: 121817,
    name: "Midway",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.30011000",
    longitude: "-82.42375000",
  },
  {
    id: 121836,
    name: "Milan",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.91979000",
    longitude: "-88.75895000",
  },
  {
    id: 121888,
    name: "Millersville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.37116000",
    longitude: "-86.71000000",
  },
  {
    id: 121891,
    name: "Millington",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.34147000",
    longitude: "-89.89731000",
  },
  {
    id: 122076,
    name: "Monroe County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.44265000",
    longitude: "-84.25279000",
  },
  {
    id: 122112,
    name: "Monteagle",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.24008000",
    longitude: "-85.83970000",
  },
  {
    id: 122118,
    name: "Monterey",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.14756000",
    longitude: "-85.26830000",
  },
  {
    id: 122149,
    name: "Montgomery County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.49686000",
    longitude: "-87.38289000",
  },
  {
    id: 122202,
    name: "Moore County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.28462000",
    longitude: "-86.35877000",
  },
  {
    id: 122242,
    name: "Morgan County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.13502000",
    longitude: "-84.64920000",
  },
  {
    id: 122281,
    name: "Morristown",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.21398000",
    longitude: "-83.29489000",
  },
  {
    id: 122304,
    name: "Mosheim",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.18954000",
    longitude: "-82.95849000",
  },
  {
    id: 122336,
    name: "Mount Carmel",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.54538000",
    longitude: "-82.66099000",
  },
  {
    id: 122359,
    name: "Mount Juliet",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.20005000",
    longitude: "-86.51861000",
  },
  {
    id: 122375,
    name: "Mount Pleasant",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.53424000",
    longitude: "-87.20695000",
  },
  {
    id: 122412,
    name: "Mountain City",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.47456000",
    longitude: "-81.80484000",
  },
  {
    id: 122443,
    name: "Mowbray Mountain",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.27536000",
    longitude: "-85.22246000",
  },
  {
    id: 122471,
    name: "Munford",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.44925000",
    longitude: "-89.81508000",
  },
  {
    id: 122482,
    name: "Murfreesboro",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.84562000",
    longitude: "-86.39027000",
  },
  {
    id: 122577,
    name: "Nashville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.16589000",
    longitude: "-86.78444000",
  },
  {
    id: 122725,
    name: "New Hope",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.00508000",
    longitude: "-85.65830000",
  },
  {
    id: 122732,
    name: "New Johnsonville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.02117000",
    longitude: "-87.96698000",
  },
  {
    id: 122750,
    name: "New Market",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.10398000",
    longitude: "-83.55268000",
  },
  {
    id: 122780,
    name: "New South Memphis",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.08676000",
    longitude: "-90.05676000",
  },
  {
    id: 122785,
    name: "New Tazewell",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.44258000",
    longitude: "-83.59963000",
  },
  {
    id: 122789,
    name: "New Union",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.53258000",
    longitude: "-86.08082000",
  },
  {
    id: 122809,
    name: "Newbern",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.11285000",
    longitude: "-89.26174000",
  },
  {
    id: 122843,
    name: "Newport",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.96704000",
    longitude: "-83.18766000",
  },
  {
    id: 122933,
    name: "Nolensville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.95229000",
    longitude: "-86.66944000",
  },
  {
    id: 122955,
    name: "Norris",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.19563000",
    longitude: "-84.06797000",
  },
  {
    id: 123237,
    name: "Oak Grove",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.41177000",
    longitude: "-82.42459000",
  },
  {
    id: 123244,
    name: "Oak Hill",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.08784000",
    longitude: "-86.78305000",
  },
  {
    id: 123262,
    name: "Oak Ridge",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.01036000",
    longitude: "-84.26964000",
  },
  {
    id: 123285,
    name: "Oakland",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.22898000",
    longitude: "-89.51508000",
  },
  {
    id: 123317,
    name: "Obion",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.25896000",
    longitude: "-89.19174000",
  },
  {
    id: 123318,
    name: "Obion County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.35825000",
    longitude: "-89.14880000",
  },
  {
    id: 123444,
    name: "Oliver Springs",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.04452000",
    longitude: "-84.34437000",
  },
  {
    id: 123446,
    name: "Olivet",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.21425000",
    longitude: "-88.20031000",
  },
  {
    id: 123472,
    name: "Oneida",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.49813000",
    longitude: "-84.51272000",
  },
  {
    id: 123654,
    name: "Overton County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.34500000",
    longitude: "-85.28808000",
  },
  {
    id: 123833,
    name: "Paris",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.30200000",
    longitude: "-88.32671000",
  },
  {
    id: 123838,
    name: "Park City",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.08203000",
    longitude: "-86.57111000",
  },
  {
    id: 123892,
    name: "Parsons",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.64979000",
    longitude: "-88.12670000",
  },
  {
    id: 123985,
    name: "Pegram",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.10061000",
    longitude: "-87.05112000",
  },
  {
    id: 124081,
    name: "Perry County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.64263000",
    longitude: "-87.85897000",
  },
  {
    id: 124159,
    name: "Pickett County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.55841000",
    longitude: "-85.07499000",
  },
  {
    id: 124183,
    name: "Pigeon Forge",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.78842000",
    longitude: "-83.55433000",
  },
  {
    id: 124199,
    name: "Pikeville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.60562000",
    longitude: "-85.18885000",
  },
  {
    id: 124220,
    name: "Pine Crest",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.29927000",
    longitude: "-82.31792000",
  },
  {
    id: 124276,
    name: "Piperton",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.04509000",
    longitude: "-89.62175000",
  },
  {
    id: 124332,
    name: "Plainview",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.17731000",
    longitude: "-83.79534000",
  },
  {
    id: 124380,
    name: "Pleasant View",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.39422000",
    longitude: "-87.03667000",
  },
  {
    id: 124445,
    name: "Polk County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.11990000",
    longitude: "-84.52330000",
  },
  {
    id: 124558,
    name: "Portland",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.58171000",
    longitude: "-86.51638000",
  },
  {
    id: 124614,
    name: "Powells Crossroads",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.18952000",
    longitude: "-85.48580000",
  },
  {
    id: 124733,
    name: "Pulaski",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.19980000",
    longitude: "-87.03084000",
  },
  {
    id: 124764,
    name: "Putnam County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.14083000",
    longitude: "-85.49519000",
  },
  {
    id: 124937,
    name: "Red Bank",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.11229000",
    longitude: "-85.29413000",
  },
  {
    id: 124941,
    name: "Red Boiling Springs",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.53339000",
    longitude: "-85.84998000",
  },
  {
    id: 125043,
    name: "Rhea County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.60871000",
    longitude: "-84.92440000",
  },
  {
    id: 125126,
    name: "Ridgely",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.26340000",
    longitude: "-89.48785000",
  },
  {
    id: 125128,
    name: "Ridgetop",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.39505000",
    longitude: "-86.77944000",
  },
  {
    id: 125163,
    name: "Ripley",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.74536000",
    longitude: "-89.52980000",
  },
  {
    id: 125223,
    name: "Roan Mountain",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.19623000",
    longitude: "-82.07040000",
  },
  {
    id: 125224,
    name: "Roane County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.84786000",
    longitude: "-84.52324000",
  },
  {
    id: 125246,
    name: "Robertson County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.52546000",
    longitude: "-86.87057000",
  },
  {
    id: 125332,
    name: "Rockwood",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.86563000",
    longitude: "-84.68494000",
  },
  {
    id: 125340,
    name: "Rocky Top",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.21786000",
    longitude: "-84.15465000",
  },
  {
    id: 125356,
    name: "Rogersville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.40732000",
    longitude: "-83.00544000",
  },
  {
    id: 125517,
    name: "Rural Hill",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.11673000",
    longitude: "-86.47916000",
  },
  {
    id: 125548,
    name: "Rutherford",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.12757000",
    longitude: "-88.98590000",
  },
  {
    id: 125553,
    name: "Rutherford County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.84270000",
    longitude: "-86.41674000",
  },
  {
    id: 125559,
    name: "Rutledge",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.28092000",
    longitude: "-83.51490000",
  },
  {
    id: 125725,
    name: "Sale Creek",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.38229000",
    longitude: "-85.10884000",
  },
  {
    id: 125976,
    name: "Savannah",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.22480000",
    longitude: "-88.24920000",
  },
  {
    id: 126049,
    name: "Scott County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.42854000",
    longitude: "-84.50352000",
  },
  {
    id: 126142,
    name: "Selmer",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.17008000",
    longitude: "-88.59227000",
  },
  {
    id: 126163,
    name: "Sequatchie County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.37115000",
    longitude: "-85.41059000",
  },
  {
    id: 126181,
    name: "Sevier County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.78466000",
    longitude: "-83.52418000",
  },
  {
    id: 126183,
    name: "Sevierville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.86815000",
    longitude: "-83.56184000",
  },
  {
    id: 126185,
    name: "Sewanee",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.20314000",
    longitude: "-85.92109000",
  },
  {
    id: 126194,
    name: "Seymour",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.89064000",
    longitude: "-83.72462000",
  },
  {
    id: 126200,
    name: "Shackle Island",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.37060000",
    longitude: "-86.61666000",
  },
  {
    id: 126277,
    name: "Shelby County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.18400000",
    longitude: "-89.89560000",
  },
  {
    id: 126284,
    name: "Shelbyville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.48341000",
    longitude: "-86.46027000",
  },
  {
    id: 126412,
    name: "Signal Mountain",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.12257000",
    longitude: "-85.34385000",
  },
  {
    id: 126514,
    name: "Smith County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.25053000",
    longitude: "-85.95671000",
  },
  {
    id: 126530,
    name: "Smithville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.96062000",
    longitude: "-85.81415000",
  },
  {
    id: 126536,
    name: "Smyrna",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.98284000",
    longitude: "-86.51860000",
  },
  {
    id: 126540,
    name: "Sneedville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.52981000",
    longitude: "-83.21740000",
  },
  {
    id: 126563,
    name: "Soddy-Daisy",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.23590000",
    longitude: "-85.19079000",
  },
  {
    id: 126593,
    name: "Somerville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.24370000",
    longitude: "-89.35007000",
  },
  {
    id: 126635,
    name: "South Carthage",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.24200000",
    longitude: "-85.95193000",
  },
  {
    id: 126639,
    name: "South Cleveland",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.14119000",
    longitude: "-84.87217000",
  },
  {
    id: 126656,
    name: "South Fulton",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.50089000",
    longitude: "-88.87534000",
  },
  {
    id: 126710,
    name: "South Pittsburg",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.01230000",
    longitude: "-85.70441000",
  },
  {
    id: 126810,
    name: "Sparta",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.92590000",
    longitude: "-85.46414000",
  },
  {
    id: 126823,
    name: "Spencer",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.74729000",
    longitude: "-85.46664000",
  },
  {
    id: 126851,
    name: "Spring City",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.69201000",
    longitude: "-84.86078000",
  },
  {
    id: 126862,
    name: "Spring Hill",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.75118000",
    longitude: "-86.93000000",
  },
  {
    id: 126895,
    name: "Springfield",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.50921000",
    longitude: "-86.88500000",
  },
  {
    id: 126922,
    name: "Spurgeon",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.43955000",
    longitude: "-82.45570000",
  },
  {
    id: 127051,
    name: "Stewart County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.50110000",
    longitude: "-87.83846000",
  },
  {
    id: 127187,
    name: "Sullivan County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.51292000",
    longitude: "-82.30414000",
  },
  {
    id: 127225,
    name: "Sumner County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.46941000",
    longitude: "-86.46036000",
  },
  {
    id: 127289,
    name: "Surgoinsville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.47093000",
    longitude: "-82.85183000",
  },
  {
    id: 127341,
    name: "Sweetwater",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.60146000",
    longitude: "-84.46104000",
  },
  {
    id: 127468,
    name: "Tazewell",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.45425000",
    longitude: "-83.56935000",
  },
  {
    id: 127490,
    name: "Tellico Village",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.68321000",
    longitude: "-84.25518000",
  },
  {
    id: 127510,
    name: "Tennessee Ridge",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.31200000",
    longitude: "-87.77336000",
  },
  {
    id: 127593,
    name: "Thompson's Station",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.80201000",
    longitude: "-86.91139000",
  },
  {
    id: 127622,
    name: "Three Way",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.77590000",
    longitude: "-88.85950000",
  },
  {
    id: 127679,
    name: "Tipton County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.49687000",
    longitude: "-89.75924000",
  },
  {
    id: 127681,
    name: "Tiptonville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.37840000",
    longitude: "-89.47202000",
  },
  {
    id: 127772,
    name: "Tracy City",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.26036000",
    longitude: "-85.73608000",
  },
  {
    id: 127805,
    name: "Trenton",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.98062000",
    longitude: "-88.94145000",
  },
  {
    id: 127837,
    name: "Trousdale County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.39204000",
    longitude: "-86.15675000",
  },
  {
    id: 127847,
    name: "Troy",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.33868000",
    longitude: "-89.16396000",
  },
  {
    id: 127886,
    name: "Tullahoma",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.36202000",
    longitude: "-86.20943000",
  },
  {
    id: 127913,
    name: "Tusculum",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.17510000",
    longitude: "-82.75876000",
  },
  {
    id: 127969,
    name: "Unicoi",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.19539000",
    longitude: "-82.34958000",
  },
  {
    id: 127970,
    name: "Unicoi County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.11082000",
    longitude: "-82.43224000",
  },
  {
    id: 127982,
    name: "Union City",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.42423000",
    longitude: "-89.05701000",
  },
  {
    id: 127997,
    name: "Union County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.28787000",
    longitude: "-83.83751000",
  },
  {
    id: 128019,
    name: "Unionville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.62174000",
    longitude: "-86.59250000",
  },
  {
    id: 128130,
    name: "Van Buren County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.69598000",
    longitude: "-85.45261000",
  },
  {
    id: 128305,
    name: "Vonore",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.59008000",
    longitude: "-84.24186000",
  },
  {
    id: 128367,
    name: "Walden",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.16479000",
    longitude: "-85.30135000",
  },
  {
    id: 128421,
    name: "Walnut Hill",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.56983000",
    longitude: "-82.25680000",
  },
  {
    id: 128491,
    name: "Warren County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.67868000",
    longitude: "-85.77850000",
  },
  {
    id: 128523,
    name: "Wartburg",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.10480000",
    longitude: "-84.59716000",
  },
  {
    id: 128565,
    name: "Washington County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.29330000",
    longitude: "-82.49743000",
  },
  {
    id: 128619,
    name: "Watertown",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.10034000",
    longitude: "-86.13193000",
  },
  {
    id: 128666,
    name: "Waverly",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.08395000",
    longitude: "-87.79475000",
  },
  {
    id: 128696,
    name: "Wayne County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.23992000",
    longitude: "-87.78801000",
  },
  {
    id: 128708,
    name: "Waynesboro",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.31952000",
    longitude: "-87.76225000",
  },
  {
    id: 128714,
    name: "Weakley County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.29830000",
    longitude: "-88.71774000",
  },
  {
    id: 129053,
    name: "Westmoreland",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.56199000",
    longitude: "-86.24804000",
  },
  {
    id: 129127,
    name: "White Bluff",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.10756000",
    longitude: "-87.22084000",
  },
  {
    id: 129137,
    name: "White County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.92635000",
    longitude: "-85.45518000",
  },
  {
    id: 129143,
    name: "White House",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.47032000",
    longitude: "-86.65138000",
  },
  {
    id: 129152,
    name: "White Pine",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.10759000",
    longitude: "-83.28683000",
  },
  {
    id: 129190,
    name: "Whiteville",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.32647000",
    longitude: "-89.14951000",
  },
  {
    id: 129212,
    name: "Whitwell",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.20146000",
    longitude: "-85.51913000",
  },
  {
    id: 129239,
    name: "Wildwood",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.80370000",
    longitude: "-83.87129000",
  },
  {
    id: 129242,
    name: "Wildwood Lake",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.09202000",
    longitude: "-84.85439000",
  },
  {
    id: 129277,
    name: "Williamson County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.89378000",
    longitude: "-86.89860000",
  },
  {
    id: 129336,
    name: "Wilson County",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "36.15486000",
    longitude: "-86.29763000",
  },
  {
    id: 129355,
    name: "Winchester",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.18592000",
    longitude: "-86.11221000",
  },
  {
    id: 129507,
    name: "Woodbury",
    state_id: 1454,
    state_code: "TN",
    country_id: 233,
    country_code: "US",
    latitude: "35.82757000",
    longitude: "-86.07166000",
  },
  {
    id: 110981,
    name: "Abernathy",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.83230000",
    longitude: "-101.84295000",
  },
  {
    id: 110983,
    name: "Abilene",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.44874000",
    longitude: "-99.73314000",
  },
  {
    id: 110988,
    name: "Abram",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.19980000",
    longitude: "-98.41113000",
  },
  {
    id: 111034,
    name: "Addison",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.96179000",
    longitude: "-96.82917000",
  },
  {
    id: 111059,
    name: "Agua Dulce",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.65511000",
    longitude: "-106.13887000",
  },
  {
    id: 111092,
    name: "Alamo",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.18369000",
    longitude: "-98.12306000",
  },
  {
    id: 111096,
    name: "Alamo Heights",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.48495000",
    longitude: "-98.46585000",
  },
  {
    id: 111104,
    name: "Albany",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.72345000",
    longitude: "-99.29730000",
  },
  {
    id: 111140,
    name: "Aldine",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.93245000",
    longitude: "-95.38021000",
  },
  {
    id: 111141,
    name: "Aledo",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.69596000",
    longitude: "-97.60225000",
  },
  {
    id: 111171,
    name: "Alice",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "27.75225000",
    longitude: "-98.06972000",
  },
  {
    id: 111173,
    name: "Alief",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.71106000",
    longitude: "-95.59633000",
  },
  {
    id: 111187,
    name: "Allen",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.10317000",
    longitude: "-96.67055000",
  },
  {
    id: 111224,
    name: "Alpine",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.35862000",
    longitude: "-103.66206000",
  },
  {
    id: 111240,
    name: "Alto",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.65045000",
    longitude: "-95.07272000",
  },
  {
    id: 111244,
    name: "Alton",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.28729000",
    longitude: "-98.31335000",
  },
  {
    id: 111246,
    name: "Alton North (historical)",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.29535000",
    longitude: "-98.30446000",
  },
  {
    id: 111257,
    name: "Alvarado",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.40653000",
    longitude: "-97.21168000",
  },
  {
    id: 111258,
    name: "Alvin",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.42385000",
    longitude: "-95.24410000",
  },
  {
    id: 111259,
    name: "Alvord",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.35845000",
    longitude: "-97.69475000",
  },
  {
    id: 111263,
    name: "Amarillo",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "35.22200000",
    longitude: "-101.83130000",
  },
  {
    id: 111276,
    name: "Ames",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.05383000",
    longitude: "-94.74353000",
  },
  {
    id: 111301,
    name: "Anahuac",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.77300000",
    longitude: "-94.68270000",
  },
  {
    id: 111312,
    name: "Anderson",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.48715000",
    longitude: "-95.98690000",
  },
  {
    id: 111319,
    name: "Anderson County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.81333000",
    longitude: "-95.65255000",
  },
  {
    id: 111320,
    name: "Anderson Mill",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.45492000",
    longitude: "-97.80584000",
  },
  {
    id: 111331,
    name: "Andrews",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.31872000",
    longitude: "-102.54572000",
  },
  {
    id: 111333,
    name: "Andrews County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.30503000",
    longitude: "-102.63781000",
  },
  {
    id: 111336,
    name: "Angelina County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.25476000",
    longitude: "-94.61185000",
  },
  {
    id: 111339,
    name: "Angleton",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.16941000",
    longitude: "-95.43188000",
  },
  {
    id: 111347,
    name: "Anna",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.34900000",
    longitude: "-96.54860000",
  },
  {
    id: 111354,
    name: "Annetta",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.70930000",
    longitude: "-97.67614000",
  },
  {
    id: 111360,
    name: "Anson",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.75650000",
    longitude: "-99.89621000",
  },
  {
    id: 111370,
    name: "Anthony",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.99927000",
    longitude: "-106.60555000",
  },
  {
    id: 111375,
    name: "Anton",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.81120000",
    longitude: "-102.16379000",
  },
  {
    id: 111410,
    name: "Aransas County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.01501000",
    longitude: "-97.07382000",
  },
  {
    id: 111411,
    name: "Aransas Pass",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "27.90947000",
    longitude: "-97.14999000",
  },
  {
    id: 111430,
    name: "Archer City",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.59566000",
    longitude: "-98.62561000",
  },
  {
    id: 111431,
    name: "Archer County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.61525000",
    longitude: "-98.68765000",
  },
  {
    id: 111437,
    name: "Arcola",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.49607000",
    longitude: "-95.46578000",
  },
  {
    id: 111450,
    name: "Argyle",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.12123000",
    longitude: "-97.18335000",
  },
  {
    id: 111464,
    name: "Arlington",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.73569000",
    longitude: "-97.10807000",
  },
  {
    id: 111481,
    name: "Armstrong County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.96493000",
    longitude: "-101.35740000",
  },
  {
    id: 111521,
    name: "Asherton",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.44193000",
    longitude: "-99.76033000",
  },
  {
    id: 111554,
    name: "Aspermont",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.13343000",
    longitude: "-100.22733000",
  },
  {
    id: 111565,
    name: "Atascocita",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.99883000",
    longitude: "-95.17660000",
  },
  {
    id: 111566,
    name: "Atascosa County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.89352000",
    longitude: "-98.52713000",
  },
  {
    id: 111576,
    name: "Athens",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.20487000",
    longitude: "-95.85552000",
  },
  {
    id: 111591,
    name: "Atlanta",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.11374000",
    longitude: "-94.16435000",
  },
  {
    id: 111618,
    name: "Aubrey",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.30428000",
    longitude: "-96.98612000",
  },
  {
    id: 111658,
    name: "Aurora",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.06068000",
    longitude: "-97.50336000",
  },
  {
    id: 111668,
    name: "Austin",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.26715000",
    longitude: "-97.74306000",
  },
  {
    id: 111670,
    name: "Austin County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.88702000",
    longitude: "-96.27791000",
  },
  {
    id: 111710,
    name: "Azle",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.89513000",
    longitude: "-97.54586000",
  },
  {
    id: 111719,
    name: "Bacliff",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.50690000",
    longitude: "-94.99243000",
  },
  {
    id: 111730,
    name: "Bailey County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.06852000",
    longitude: "-102.82987000",
  },
  {
    id: 111736,
    name: "Baird",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.39402000",
    longitude: "-99.39424000",
  },
  {
    id: 111749,
    name: "Balch Springs",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.72874000",
    longitude: "-96.62277000",
  },
  {
    id: 111750,
    name: "Balcones Heights",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.48801000",
    longitude: "-98.55169000",
  },
  {
    id: 111773,
    name: "Ballinger",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.73821000",
    longitude: "-99.94731000",
  },
  {
    id: 111788,
    name: "Bandera",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.72661000",
    longitude: "-99.07365000",
  },
  {
    id: 111789,
    name: "Bandera County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.74721000",
    longitude: "-99.24624000",
  },
  {
    id: 111796,
    name: "Bangs",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.71710000",
    longitude: "-99.13255000",
  },
  {
    id: 111844,
    name: "Barrett",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.87995000",
    longitude: "-95.06298000",
  },
  {
    id: 111862,
    name: "Bartlett",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.79491000",
    longitude: "-97.42556000",
  },
  {
    id: 111868,
    name: "Barton Creek",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.28521000",
    longitude: "-97.86917000",
  },
  {
    id: 111870,
    name: "Bartonville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.07318000",
    longitude: "-97.13168000",
  },
  {
    id: 111884,
    name: "Bastrop",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.11049000",
    longitude: "-97.31527000",
  },
  {
    id: 111885,
    name: "Bastrop County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.10361000",
    longitude: "-97.31201000",
  },
  {
    id: 111895,
    name: "Batesville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.95108000",
    longitude: "-99.61783000",
  },
  {
    id: 111921,
    name: "Bay City",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.98276000",
    longitude: "-95.96940000",
  },
  {
    id: 111943,
    name: "Baylor County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.61649000",
    longitude: "-99.21351000",
  },
  {
    id: 111950,
    name: "Bayou Vista",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.32635000",
    longitude: "-94.93853000",
  },
  {
    id: 111958,
    name: "Baytown",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.73550000",
    longitude: "-94.97743000",
  },
  {
    id: 111963,
    name: "Beach City",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.66217000",
    longitude: "-94.88992000",
  },
  {
    id: 111989,
    name: "Beaumont",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.08605000",
    longitude: "-94.10185000",
  },
  {
    id: 112021,
    name: "Bedford",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.84402000",
    longitude: "-97.14307000",
  },
  {
    id: 112033,
    name: "Bee Cave",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.30854000",
    longitude: "-97.94501000",
  },
  {
    id: 112034,
    name: "Bee County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.41739000",
    longitude: "-97.74119000",
  },
  {
    id: 112044,
    name: "Beeville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.40095000",
    longitude: "-97.74974000",
  },
  {
    id: 112069,
    name: "Bell County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.03767000",
    longitude: "-97.47820000",
  },
  {
    id: 112075,
    name: "Bellaire",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.70579000",
    longitude: "-95.45883000",
  },
  {
    id: 112122,
    name: "Bellmead",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.59405000",
    longitude: "-97.10889000",
  },
  {
    id: 112127,
    name: "Bells",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.61038000",
    longitude: "-96.41082000",
  },
  {
    id: 112129,
    name: "Bellville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.95023000",
    longitude: "-96.25719000",
  },
  {
    id: 112149,
    name: "Belton",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.05601000",
    longitude: "-97.46445000",
  },
  {
    id: 112164,
    name: "Benavides",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "27.59892000",
    longitude: "-98.40807000",
  },
  {
    id: 112165,
    name: "Benbrook",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.67319000",
    longitude: "-97.46058000",
  },
  {
    id: 112169,
    name: "Benjamin",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.58398000",
    longitude: "-99.79231000",
  },
  {
    id: 112256,
    name: "Berryville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.08849000",
    longitude: "-95.47190000",
  },
  {
    id: 112259,
    name: "Bertram",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.74380000",
    longitude: "-98.05558000",
  },
  {
    id: 112299,
    name: "Beverly",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.52517000",
    longitude: "-97.14195000",
  },
  {
    id: 112304,
    name: "Beverly Hills",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.52156000",
    longitude: "-97.15389000",
  },
  {
    id: 112307,
    name: "Bevil Oaks",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.15021000",
    longitude: "-94.26963000",
  },
  {
    id: 112308,
    name: "Bexar County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.44896000",
    longitude: "-98.52002000",
  },
  {
    id: 112324,
    name: "Big Lake",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.19154000",
    longitude: "-101.46039000",
  },
  {
    id: 112333,
    name: "Big Sandy",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.58375000",
    longitude: "-95.10883000",
  },
  {
    id: 112335,
    name: "Big Spring",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.25040000",
    longitude: "-101.47874000",
  },
  {
    id: 112363,
    name: "Bishop",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "27.58614000",
    longitude: "-97.79916000",
  },
  {
    id: 112418,
    name: "Blanco",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.09799000",
    longitude: "-98.42141000",
  },
  {
    id: 112419,
    name: "Blanco County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.26638000",
    longitude: "-98.39987000",
  },
  {
    id: 112450,
    name: "Bloomington",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.64777000",
    longitude: "-96.89249000",
  },
  {
    id: 112456,
    name: "Blossom",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.66150000",
    longitude: "-95.38579000",
  },
  {
    id: 112472,
    name: "Blue Mound",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.85652000",
    longitude: "-97.33891000",
  },
  {
    id: 112497,
    name: "Boerne",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.79466000",
    longitude: "-98.73197000",
  },
  {
    id: 112500,
    name: "Bogata",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.47067000",
    longitude: "-95.21384000",
  },
  {
    id: 112515,
    name: "Boling",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.26441000",
    longitude: "-95.94384000",
  },
  {
    id: 112522,
    name: "Bolivar Peninsula",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.47829000",
    longitude: "-94.57991000",
  },
  {
    id: 112535,
    name: "Bonham",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.57733000",
    longitude: "-96.17831000",
  },
  {
    id: 112551,
    name: "Booker",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "36.45336000",
    longitude: "-100.53737000",
  },
  {
    id: 112575,
    name: "Borden County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.74369000",
    longitude: "-101.43175000",
  },
  {
    id: 112577,
    name: "Borger",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "35.66782000",
    longitude: "-101.39739000",
  },
  {
    id: 112584,
    name: "Bosque County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.90040000",
    longitude: "-97.63435000",
  },
  {
    id: 112619,
    name: "Bovina",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.51368000",
    longitude: "-102.88300000",
  },
  {
    id: 112624,
    name: "Bowie",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.55900000",
    longitude: "-97.84865000",
  },
  {
    id: 112625,
    name: "Bowie County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.44576000",
    longitude: "-94.42332000",
  },
  {
    id: 112641,
    name: "Boyd",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.07873000",
    longitude: "-97.56530000",
  },
  {
    id: 112657,
    name: "Brackettville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.31051000",
    longitude: "-100.41786000",
  },
  {
    id: 112678,
    name: "Brady",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.13517000",
    longitude: "-99.33506000",
  },
  {
    id: 112704,
    name: "Brazoria",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.04441000",
    longitude: "-95.56911000",
  },
  {
    id: 112705,
    name: "Brazoria County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.16783000",
    longitude: "-95.43426000",
  },
  {
    id: 112706,
    name: "Brazos County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.66080000",
    longitude: "-96.30239000",
  },
  {
    id: 112710,
    name: "Breckenridge",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.75568000",
    longitude: "-98.90229000",
  },
  {
    id: 112724,
    name: "Brenham",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.16688000",
    longitude: "-96.39774000",
  },
  {
    id: 112745,
    name: "Brewster County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.81195000",
    longitude: "-103.25176000",
  },
  {
    id: 112748,
    name: "Briar",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.99512000",
    longitude: "-97.54280000",
  },
  {
    id: 112749,
    name: "Briarcliff",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.40742000",
    longitude: "-98.04446000",
  },
  {
    id: 112756,
    name: "Bridge City",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.02077000",
    longitude: "-93.84573000",
  },
  {
    id: 112759,
    name: "Bridgeport",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.21012000",
    longitude: "-97.75476000",
  },
  {
    id: 112801,
    name: "Briscoe County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.53026000",
    longitude: "-101.20859000",
  },
  {
    id: 112884,
    name: "Brooks County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "27.03157000",
    longitude: "-98.21872000",
  },
  {
    id: 112885,
    name: "Brookshire",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.78606000",
    longitude: "-95.95107000",
  },
  {
    id: 112888,
    name: "Brookside Village",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.58690000",
    longitude: "-95.32522000",
  },
  {
    id: 112910,
    name: "Brown County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.77426000",
    longitude: "-98.99979000",
  },
  {
    id: 112917,
    name: "Brownfield",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.18120000",
    longitude: "-102.27435000",
  },
  {
    id: 112923,
    name: "Brownsboro",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.30237000",
    longitude: "-95.61357000",
  },
  {
    id: 112932,
    name: "Brownsville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "25.90175000",
    longitude: "-97.49748000",
  },
  {
    id: 112940,
    name: "Brownwood",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.70932000",
    longitude: "-98.99116000",
  },
  {
    id: 112944,
    name: "Bruceville-Eddy",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.30517000",
    longitude: "-97.25167000",
  },
  {
    id: 112956,
    name: "Brushy Creek",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.51353000",
    longitude: "-97.73973000",
  },
  {
    id: 112959,
    name: "Bryan",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.67436000",
    longitude: "-96.36996000",
  },
  {
    id: 112976,
    name: "Buchanan Dam",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.73990000",
    longitude: "-98.43114000",
  },
  {
    id: 112993,
    name: "Buda",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.08521000",
    longitude: "-97.84028000",
  },
  {
    id: 113010,
    name: "Buffalo",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.46379000",
    longitude: "-96.05802000",
  },
  {
    id: 113030,
    name: "Bullard",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.13988000",
    longitude: "-95.32023000",
  },
  {
    id: 113035,
    name: "Bulverde",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.74383000",
    longitude: "-98.45307000",
  },
  {
    id: 113036,
    name: "Buna",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.43298000",
    longitude: "-93.96240000",
  },
  {
    id: 113042,
    name: "Bunker Hill Village",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.76745000",
    longitude: "-95.52994000",
  },
  {
    id: 113053,
    name: "Burkburnett",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.09787000",
    longitude: "-98.57061000",
  },
  {
    id: 113061,
    name: "Burleson",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.54208000",
    longitude: "-97.32085000",
  },
  {
    id: 113062,
    name: "Burleson County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.49248000",
    longitude: "-96.62146000",
  },
  {
    id: 113078,
    name: "Burnet",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.75824000",
    longitude: "-98.22836000",
  },
  {
    id: 113079,
    name: "Burnet County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.78830000",
    longitude: "-98.18245000",
  },
  {
    id: 113100,
    name: "Bushland",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "35.19200000",
    longitude: "-102.06464000",
  },
  {
    id: 113157,
    name: "Cactus",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "36.05226000",
    longitude: "-102.00240000",
  },
  {
    id: 113161,
    name: "Caddo Mills",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.06567000",
    longitude: "-96.22776000",
  },
  {
    id: 113180,
    name: "Caldwell",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.53132000",
    longitude: "-96.69303000",
  },
  {
    id: 113186,
    name: "Caldwell County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.83712000",
    longitude: "-97.62000000",
  },
  {
    id: 113207,
    name: "Calhoun County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.43911000",
    longitude: "-96.61507000",
  },
  {
    id: 113223,
    name: "Callahan County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.29766000",
    longitude: "-99.37349000",
  },
  {
    id: 113233,
    name: "Calvert",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.97797000",
    longitude: "-96.67386000",
  },
  {
    id: 113275,
    name: "Cameron",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.85325000",
    longitude: "-96.97693000",
  },
  {
    id: 113277,
    name: "Cameron County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.15150000",
    longitude: "-97.45286000",
  },
  {
    id: 113280,
    name: "Cameron Park",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "25.96452000",
    longitude: "-97.47665000",
  },
  {
    id: 113282,
    name: "Cameron Park Colonia",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "25.97147000",
    longitude: "-97.47832000",
  },
  {
    id: 113286,
    name: "Camp County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.97322000",
    longitude: "-94.97848000",
  },
  {
    id: 113294,
    name: "Camp Swift",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.19077000",
    longitude: "-97.29221000",
  },
  {
    id: 113314,
    name: "Canadian",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "35.91282000",
    longitude: "-100.38208000",
  },
  {
    id: 113342,
    name: "Canton",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.55652000",
    longitude: "-95.86330000",
  },
  {
    id: 113353,
    name: "Canutillo",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.91149000",
    longitude: "-106.60027000",
  },
  {
    id: 113354,
    name: "Canyon",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.98033000",
    longitude: "-101.91880000",
  },
  {
    id: 113358,
    name: "Canyon Lake",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.87522000",
    longitude: "-98.26251000",
  },
  {
    id: 113447,
    name: "Carrizo Springs",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.52193000",
    longitude: "-99.86061000",
  },
  {
    id: 113470,
    name: "Carrollton",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.95373000",
    longitude: "-96.89028000",
  },
  {
    id: 113479,
    name: "Carson County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "35.40351000",
    longitude: "-101.35418000",
  },
  {
    id: 113495,
    name: "Carthage",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.15738000",
    longitude: "-94.33742000",
  },
  {
    id: 113528,
    name: "Cass County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.07754000",
    longitude: "-94.34359000",
  },
  {
    id: 113545,
    name: "Castle Hills",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.52329000",
    longitude: "-98.51641000",
  },
  {
    id: 113556,
    name: "Castro County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.52996000",
    longitude: "-102.26167000",
  },
  {
    id: 113558,
    name: "Castroville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.35579000",
    longitude: "-98.87864000",
  },
  {
    id: 113614,
    name: "Cedar Hill",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.58847000",
    longitude: "-96.95612000",
  },
  {
    id: 113619,
    name: "Cedar Park",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.50520000",
    longitude: "-97.82029000",
  },
  {
    id: 113631,
    name: "Celina",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.32456000",
    longitude: "-96.78444000",
  },
  {
    id: 113636,
    name: "Center",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.79545000",
    longitude: "-94.17909000",
  },
  {
    id: 113657,
    name: "Centerville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.25796000",
    longitude: "-95.97829000",
  },
  {
    id: 113675,
    name: "Central Gardens",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.99549000",
    longitude: "-94.01406000",
  },
  {
    id: 114960,
    name: "César Chávez",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.30340000",
    longitude: "-98.11529000",
  },
  {
    id: 113721,
    name: "Chambers County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.70826000",
    longitude: "-94.67138000",
  },
  {
    id: 113730,
    name: "Chandler",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.30793000",
    longitude: "-95.47996000",
  },
  {
    id: 113736,
    name: "Channelview",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.77606000",
    longitude: "-95.11465000",
  },
  {
    id: 113737,
    name: "Channing",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "35.68365000",
    longitude: "-102.33020000",
  },
  {
    id: 113778,
    name: "Charlotte",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.86192000",
    longitude: "-98.70641000",
  },
  {
    id: 113855,
    name: "Cherokee County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.83695000",
    longitude: "-95.16518000",
  },
  {
    id: 113942,
    name: "Chico",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.29595000",
    longitude: "-97.79892000",
  },
  {
    id: 113950,
    name: "Childress",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.42645000",
    longitude: "-100.20400000",
  },
  {
    id: 113951,
    name: "Childress County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.52920000",
    longitude: "-100.20757000",
  },
  {
    id: 113959,
    name: "China",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.04799000",
    longitude: "-94.33574000",
  },
  {
    id: 113962,
    name: "China Grove",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.38885000",
    longitude: "-98.34890000",
  },
  {
    id: 114018,
    name: "Cibolo",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.56162000",
    longitude: "-98.22696000",
  },
  {
    id: 114022,
    name: "Cienegas Terrace",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.36745000",
    longitude: "-100.94371000",
  },
  {
    id: 114026,
    name: "Cinco Ranch",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.73884000",
    longitude: "-95.75800000",
  },
  {
    id: 114029,
    name: "Circle D-KC Estates",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.16100000",
    longitude: "-97.23473000",
  },
  {
    id: 114031,
    name: "Cisco",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.38819000",
    longitude: "-98.97923000",
  },
  {
    id: 114035,
    name: "Citrus City",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.32646000",
    longitude: "-98.38530000",
  },
  {
    id: 114112,
    name: "Clarendon",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.93783000",
    longitude: "-100.88820000",
  },
  {
    id: 114155,
    name: "Clarksville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.61066000",
    longitude: "-95.05272000",
  },
  {
    id: 114162,
    name: "Claude",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "35.11172000",
    longitude: "-101.36322000",
  },
  {
    id: 114185,
    name: "Clay County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.78553000",
    longitude: "-98.20850000",
  },
  {
    id: 114214,
    name: "Clear Lake Shores",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.54745000",
    longitude: "-95.03215000",
  },
  {
    id: 114229,
    name: "Cleburne",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.34764000",
    longitude: "-97.38668000",
  },
  {
    id: 114246,
    name: "Cleveland",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.34132000",
    longitude: "-95.08549000",
  },
  {
    id: 114256,
    name: "Clifton",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.78238000",
    longitude: "-97.57669000",
  },
  {
    id: 114266,
    name: "Clint",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.59234000",
    longitude: "-106.22414000",
  },
  {
    id: 114312,
    name: "Cloverleaf",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.77828000",
    longitude: "-95.17188000",
  },
  {
    id: 114317,
    name: "Clute",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.02469000",
    longitude: "-95.39883000",
  },
  {
    id: 114319,
    name: "Clyde",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.40596000",
    longitude: "-99.49369000",
  },
  {
    id: 114352,
    name: "Cochran County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.60414000",
    longitude: "-102.82846000",
  },
  {
    id: 114356,
    name: "Cockrell Hill",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.73624000",
    longitude: "-96.88695000",
  },
  {
    id: 114377,
    name: "Coke County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.88853000",
    longitude: "-100.52991000",
  },
  {
    id: 114390,
    name: "Coldspring",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.59242000",
    longitude: "-95.12938000",
  },
  {
    id: 114398,
    name: "Coleman",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.82737000",
    longitude: "-99.42645000",
  },
  {
    id: 114400,
    name: "Coleman County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.77321000",
    longitude: "-99.45364000",
  },
  {
    id: 114417,
    name: "College Station",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.62798000",
    longitude: "-96.33441000",
  },
  {
    id: 114422,
    name: "Colleyville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.88096000",
    longitude: "-97.15501000",
  },
  {
    id: 114426,
    name: "Collin County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.18791000",
    longitude: "-96.57237000",
  },
  {
    id: 114430,
    name: "Collingsworth County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.96488000",
    longitude: "-100.27007000",
  },
  {
    id: 114437,
    name: "Collinsville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.56150000",
    longitude: "-96.91111000",
  },
  {
    id: 114456,
    name: "Colorado City",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.38817000",
    longitude: "-100.86456000",
  },
  {
    id: 114457,
    name: "Colorado County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.62082000",
    longitude: "-96.52628000",
  },
  {
    id: 114495,
    name: "Columbus",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.70662000",
    longitude: "-96.53969000",
  },
  {
    id: 114509,
    name: "Comal County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.80818000",
    longitude: "-98.27825000",
  },
  {
    id: 114511,
    name: "Comanche",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.89737000",
    longitude: "-98.60366000",
  },
  {
    id: 114514,
    name: "Comanche County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.94798000",
    longitude: "-98.55826000",
  },
  {
    id: 114516,
    name: "Combes",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.24869000",
    longitude: "-97.73388000",
  },
  {
    id: 114517,
    name: "Combine",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.58847000",
    longitude: "-96.50860000",
  },
  {
    id: 114520,
    name: "Comfort",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.96771000",
    longitude: "-98.90503000",
  },
  {
    id: 114524,
    name: "Commerce",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.24706000",
    longitude: "-95.89997000",
  },
  {
    id: 114533,
    name: "Concho County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.32650000",
    longitude: "-99.86396000",
  },
  {
    id: 114570,
    name: "Conroe",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.31188000",
    longitude: "-95.45605000",
  },
  {
    id: 114578,
    name: "Converse",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.51801000",
    longitude: "-98.31612000",
  },
  {
    id: 114594,
    name: "Cooke County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.63919000",
    longitude: "-97.21262000",
  },
  {
    id: 114601,
    name: "Cooper",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.24324000",
    longitude: "-95.58217000",
  },
  {
    id: 114619,
    name: "Coppell",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.95457000",
    longitude: "-97.01501000",
  },
  {
    id: 114620,
    name: "Copper Canyon",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.09595000",
    longitude: "-97.09668000",
  },
  {
    id: 114621,
    name: "Copperas Cove",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.12406000",
    longitude: "-97.90308000",
  },
  {
    id: 114644,
    name: "Corinth",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.15401000",
    longitude: "-97.06473000",
  },
  {
    id: 114665,
    name: "Corpus Christi",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "27.80058000",
    longitude: "-97.39638000",
  },
  {
    id: 114668,
    name: "Corrigan",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.99686000",
    longitude: "-94.82715000",
  },
  {
    id: 114670,
    name: "Corsicana",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.09543000",
    longitude: "-96.46887000",
  },
  {
    id: 114683,
    name: "Coryell County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.39091000",
    longitude: "-97.79920000",
  },
  {
    id: 114695,
    name: "Cottle County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.07765000",
    longitude: "-100.27874000",
  },
  {
    id: 114706,
    name: "Cottonwood Shores",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.55575000",
    longitude: "-98.32391000",
  },
  {
    id: 114708,
    name: "Cotulla",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.43693000",
    longitude: "-99.23503000",
  },
  {
    id: 114765,
    name: "Crandall",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.62791000",
    longitude: "-96.45582000",
  },
  {
    id: 114769,
    name: "Crane",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.39736000",
    longitude: "-102.35014000",
  },
  {
    id: 114770,
    name: "Crane County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.42850000",
    longitude: "-102.51557000",
  },
  {
    id: 114829,
    name: "Crockett",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.31824000",
    longitude: "-95.45661000",
  },
  {
    id: 114832,
    name: "Crockett County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.72298000",
    longitude: "-101.41215000",
  },
  {
    id: 114842,
    name: "Crosby",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.91189000",
    longitude: "-95.06215000",
  },
  {
    id: 114845,
    name: "Crosby County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.61462000",
    longitude: "-101.29996000",
  },
  {
    id: 114846,
    name: "Crosbyton",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.66008000",
    longitude: "-101.23793000",
  },
  {
    id: 114851,
    name: "Cross Mountain",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.64550000",
    longitude: "-98.65947000",
  },
  {
    id: 114862,
    name: "Crowell",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.98397000",
    longitude: "-99.72482000",
  },
  {
    id: 114864,
    name: "Crowley",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.57903000",
    longitude: "-97.36252000",
  },
  {
    id: 114876,
    name: "Crystal City",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.67748000",
    longitude: "-99.82811000",
  },
  {
    id: 114892,
    name: "Cuero",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.09387000",
    longitude: "-97.28916000",
  },
  {
    id: 114893,
    name: "Culberson County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.44716000",
    longitude: "-104.51742000",
  },
  {
    id: 114943,
    name: "Cut and Shoot",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.33327000",
    longitude: "-95.35799000",
  },
  {
    id: 114953,
    name: "Cypress",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.96911000",
    longitude: "-95.69717000",
  },
  {
    id: 114972,
    name: "Daingerfield",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.03179000",
    longitude: "-94.72187000",
  },
  {
    id: 114986,
    name: "Dalhart",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "36.05948000",
    longitude: "-102.51325000",
  },
  {
    id: 114987,
    name: "Dallam County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "36.27789000",
    longitude: "-102.60222000",
  },
  {
    id: 114990,
    name: "Dallas",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.78306000",
    longitude: "-96.80667000",
  },
  {
    id: 114997,
    name: "Dallas County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.76663000",
    longitude: "-96.77787000",
  },
  {
    id: 115005,
    name: "Dalworthington Gardens",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.70291000",
    longitude: "-97.15529000",
  },
  {
    id: 115014,
    name: "Danbury",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.22830000",
    longitude: "-95.34494000",
  },
  {
    id: 115085,
    name: "Dawson County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.74253000",
    longitude: "-101.94768000",
  },
  {
    id: 115093,
    name: "Dayton",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.04661000",
    longitude: "-94.88520000",
  },
  {
    id: 115107,
    name: "De Kalb",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.50873000",
    longitude: "-94.61632000",
  },
  {
    id: 115109,
    name: "De Leon",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.11097000",
    longitude: "-98.53588000",
  },
  {
    id: 115143,
    name: "Deaf Smith County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.96602000",
    longitude: "-102.60494000",
  },
  {
    id: 115155,
    name: "Decatur",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.23428000",
    longitude: "-97.58614000",
  },
  {
    id: 115125,
    name: "DeCordova",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.42986000",
    longitude: "-97.69503000",
  },
  {
    id: 115175,
    name: "Deer Park",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.70523000",
    longitude: "-95.12382000",
  },
  {
    id: 115195,
    name: "Del Rio",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.36273000",
    longitude: "-100.89676000",
  },
  {
    id: 115224,
    name: "Delta County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.38629000",
    longitude: "-95.67227000",
  },
  {
    id: 115237,
    name: "Denison",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.75566000",
    longitude: "-96.53666000",
  },
  {
    id: 115247,
    name: "Denton",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.21484000",
    longitude: "-97.13307000",
  },
  {
    id: 115248,
    name: "Denton County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.20526000",
    longitude: "-97.11697000",
  },
  {
    id: 115254,
    name: "Denver City",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.96455000",
    longitude: "-102.82910000",
  },
  {
    id: 115138,
    name: "DeSoto",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.58986000",
    longitude: "-96.85695000",
  },
  {
    id: 115299,
    name: "Devine",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.13996000",
    longitude: "-98.90531000",
  },
  {
    id: 115306,
    name: "Deweyville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.29771000",
    longitude: "-93.74350000",
  },
  {
    id: 115141,
    name: "DeWitt County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.08208000",
    longitude: "-97.35678000",
  },
  {
    id: 115319,
    name: "Diboll",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.18713000",
    longitude: "-94.78104000",
  },
  {
    id: 115320,
    name: "Dickens",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.62175000",
    longitude: "-100.83652000",
  },
  {
    id: 115321,
    name: "Dickens County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.61661000",
    longitude: "-100.77886000",
  },
  {
    id: 115325,
    name: "Dickinson",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.46079000",
    longitude: "-95.05132000",
  },
  {
    id: 115339,
    name: "Dilley",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.66748000",
    longitude: "-99.17059000",
  },
  {
    id: 115348,
    name: "Dimmit County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.42254000",
    longitude: "-99.75673000",
  },
  {
    id: 115349,
    name: "Dimmitt",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.55090000",
    longitude: "-102.31186000",
  },
  {
    id: 115384,
    name: "Doffing",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.27452000",
    longitude: "-98.38585000",
  },
  {
    id: 115399,
    name: "Donley County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.96542000",
    longitude: "-100.81399000",
  },
  {
    id: 115400,
    name: "Donna",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.17035000",
    longitude: "-98.05195000",
  },
  {
    id: 115403,
    name: "Doolittle",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.35337000",
    longitude: "-98.11666000",
  },
  {
    id: 115416,
    name: "Double Oak",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.06512000",
    longitude: "-97.11057000",
  },
  {
    id: 115479,
    name: "Dripping Springs",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.19021000",
    longitude: "-98.08668000",
  },
  {
    id: 115492,
    name: "Dublin",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.08514000",
    longitude: "-98.34199000",
  },
  {
    id: 115515,
    name: "Dumas",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "35.86559000",
    longitude: "-101.97324000",
  },
  {
    id: 115525,
    name: "Duncanville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.65180000",
    longitude: "-96.90834000",
  },
  {
    id: 115581,
    name: "Duval County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "27.68133000",
    longitude: "-98.50891000",
  },
  {
    id: 115603,
    name: "Eagle Lake",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.58968000",
    longitude: "-96.33358000",
  },
  {
    id: 115606,
    name: "Eagle Mountain",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.89346000",
    longitude: "-97.44446000",
  },
  {
    id: 115608,
    name: "Eagle Pass",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.70914000",
    longitude: "-100.49952000",
  },
  {
    id: 115622,
    name: "Early",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.74210000",
    longitude: "-98.94561000",
  },
  {
    id: 115624,
    name: "Earth",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.23314000",
    longitude: "-102.41075000",
  },
  {
    id: 115632,
    name: "East Bernard",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.53107000",
    longitude: "-96.07107000",
  },
  {
    id: 115780,
    name: "Eastland",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.40152000",
    longitude: "-98.81756000",
  },
  {
    id: 115781,
    name: "Eastland County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.32707000",
    longitude: "-98.83232000",
  },
  {
    id: 115819,
    name: "Ector County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.86916000",
    longitude: "-102.54276000",
  },
  {
    id: 115820,
    name: "Edcouch",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.29396000",
    longitude: "-97.96056000",
  },
  {
    id: 115829,
    name: "Eden",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.21628000",
    longitude: "-99.84563000",
  },
  {
    id: 115839,
    name: "Edgecliff Village",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.65763000",
    longitude: "-97.34279000",
  },
  {
    id: 115860,
    name: "Edgewood",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.69818000",
    longitude: "-95.88524000",
  },
  {
    id: 115870,
    name: "Edinburg",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.30174000",
    longitude: "-98.16334000",
  },
  {
    id: 115884,
    name: "Edna",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.97859000",
    longitude: "-96.64609000",
  },
  {
    id: 115891,
    name: "Edwards County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.98263000",
    longitude: "-100.30474000",
  },
  {
    id: 115908,
    name: "Eidson Road",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.67720000",
    longitude: "-100.48702000",
  },
  {
    id: 115912,
    name: "El Campo",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.19664000",
    longitude: "-96.26969000",
  },
  {
    id: 115913,
    name: "El Cenizo",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "27.35224000",
    longitude: "-99.49254000",
  },
  {
    id: 115926,
    name: "El Lago",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.56356000",
    longitude: "-95.04521000",
  },
  {
    id: 115930,
    name: "El Paso",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.75872000",
    longitude: "-106.48693000",
  },
  {
    id: 115932,
    name: "El Paso County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.76855000",
    longitude: "-106.23483000",
  },
  {
    id: 115954,
    name: "Eldorado",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.86017000",
    longitude: "-100.60093000",
  },
  {
    id: 115959,
    name: "Electra",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.02926000",
    longitude: "-98.91896000",
  },
  {
    id: 115965,
    name: "Elgin",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.34965000",
    longitude: "-97.37027000",
  },
  {
    id: 115997,
    name: "Elkhart",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.62517000",
    longitude: "-95.57940000",
  },
  {
    id: 116032,
    name: "Ellis County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.34843000",
    longitude: "-96.79448000",
  },
  {
    id: 116043,
    name: "Elm Creek",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.77442000",
    longitude: "-100.49174000",
  },
  {
    id: 116048,
    name: "Elmendorf",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.25607000",
    longitude: "-98.33279000",
  },
  {
    id: 116068,
    name: "Elsa",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.29340000",
    longitude: "-97.99306000",
  },
  {
    id: 116108,
    name: "Emory",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.87457000",
    longitude: "-95.76552000",
  },
  {
    id: 116115,
    name: "Encantada-Ranchito-El Calaboz",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.03344000",
    longitude: "-97.63307000",
  },
  {
    id: 116141,
    name: "Ennis",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.32931000",
    longitude: "-96.62527000",
  },
  {
    id: 116161,
    name: "Erath County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.23626000",
    longitude: "-98.21797000",
  },
  {
    id: 116183,
    name: "Escobares",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.41062000",
    longitude: "-98.96253000",
  },
  {
    id: 116228,
    name: "Euless",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.83707000",
    longitude: "-97.08195000",
  },
  {
    id: 116243,
    name: "Evadale",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.35493000",
    longitude: "-94.07268000",
  },
  {
    id: 116266,
    name: "Everman",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.63097000",
    longitude: "-97.28918000",
  },
  {
    id: 116279,
    name: "Fabens",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.50234000",
    longitude: "-106.15859000",
  },
  {
    id: 116287,
    name: "Fair Oaks Ranch",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.74578000",
    longitude: "-98.64336000",
  },
  {
    id: 116298,
    name: "Fairchilds",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.43135000",
    longitude: "-95.78023000",
  },
  {
    id: 116311,
    name: "Fairfield",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.72461000",
    longitude: "-96.16525000",
  },
  {
    id: 116351,
    name: "Fairview",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.15790000",
    longitude: "-96.63166000",
  },
  {
    id: 116366,
    name: "Falcon Lake Estates",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.87282000",
    longitude: "-99.25531000",
  },
  {
    id: 116368,
    name: "Falfurrias",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "27.22699000",
    longitude: "-98.14417000",
  },
  {
    id: 116382,
    name: "Falls County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.25327000",
    longitude: "-96.93585000",
  },
  {
    id: 116391,
    name: "Fannett",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.92605000",
    longitude: "-94.25074000",
  },
  {
    id: 116393,
    name: "Fannin County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.59381000",
    longitude: "-96.10683000",
  },
  {
    id: 116403,
    name: "Farmers Branch",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.92651000",
    longitude: "-96.89612000",
  },
  {
    id: 116406,
    name: "Farmersville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.16345000",
    longitude: "-96.35998000",
  },
  {
    id: 116431,
    name: "Farwell",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.38341000",
    longitude: "-103.03800000",
  },
  {
    id: 116432,
    name: "Fate",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.94151000",
    longitude: "-96.38137000",
  },
  {
    id: 116449,
    name: "Fayette County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.87679000",
    longitude: "-96.91976000",
  },
  {
    id: 116494,
    name: "Ferris",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.53403000",
    longitude: "-96.66555000",
  },
  {
    id: 116506,
    name: "Fifth Street",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.59829000",
    longitude: "-95.55133000",
  },
  {
    id: 116528,
    name: "Fisher County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.74282000",
    longitude: "-100.40217000",
  },
  {
    id: 116556,
    name: "Flatonia",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.68773000",
    longitude: "-97.10860000",
  },
  {
    id: 116581,
    name: "Florence",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.84130000",
    longitude: "-97.79363000",
  },
  {
    id: 116590,
    name: "Floresville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.13358000",
    longitude: "-98.15612000",
  },
  {
    id: 116601,
    name: "Flower Mound",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.01457000",
    longitude: "-97.09696000",
  },
  {
    id: 116611,
    name: "Floyd County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.07242000",
    longitude: "-101.30323000",
  },
  {
    id: 116612,
    name: "Floydada",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.98452000",
    longitude: "-101.33766000",
  },
  {
    id: 116616,
    name: "Foard County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.97462000",
    longitude: "-99.77798000",
  },
  {
    id: 116655,
    name: "Forest Hill",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.67208000",
    longitude: "-97.26918000",
  },
  {
    id: 116677,
    name: "Forney",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.74818000",
    longitude: "-96.47193000",
  },
  {
    id: 116692,
    name: "Fort Bend County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.52749000",
    longitude: "-95.77089000",
  },
  {
    id: 116694,
    name: "Fort Bliss",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.81357000",
    longitude: "-106.41224000",
  },
  {
    id: 116700,
    name: "Fort Clark Springs",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.30607000",
    longitude: "-100.42202000",
  },
  {
    id: 116703,
    name: "Fort Davis",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.58821000",
    longitude: "-103.89463000",
  },
  {
    id: 116716,
    name: "Fort Hancock",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.29846000",
    longitude: "-105.84525000",
  },
  {
    id: 116717,
    name: "Fort Hood",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.13489000",
    longitude: "-97.77561000",
  },
  {
    id: 116752,
    name: "Fort Stockton",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.89404000",
    longitude: "-102.87932000",
  },
  {
    id: 116764,
    name: "Fort Worth",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.72541000",
    longitude: "-97.32085000",
  },
  {
    id: 116788,
    name: "Four Corners",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.66857000",
    longitude: "-95.65772000",
  },
  {
    id: 116830,
    name: "Franklin",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.02602000",
    longitude: "-96.48524000",
  },
  {
    id: 116855,
    name: "Franklin County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.17555000",
    longitude: "-95.21842000",
  },
  {
    id: 116877,
    name: "Frankston",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.05266000",
    longitude: "-95.50635000",
  },
  {
    id: 116890,
    name: "Fredericksburg",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.27520000",
    longitude: "-98.87198000",
  },
  {
    id: 116911,
    name: "Freeport",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.95414000",
    longitude: "-95.35966000",
  },
  {
    id: 116916,
    name: "Freer",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "27.88280000",
    longitude: "-98.61779000",
  },
  {
    id: 116917,
    name: "Freestone County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.70489000",
    longitude: "-96.14909000",
  },
  {
    id: 116938,
    name: "Fresno",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.53885000",
    longitude: "-95.44744000",
  },
  {
    id: 116952,
    name: "Friendswood",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.52940000",
    longitude: "-95.20104000",
  },
  {
    id: 116953,
    name: "Frio County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.86782000",
    longitude: "-99.10827000",
  },
  {
    id: 116954,
    name: "Friona",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.64173000",
    longitude: "-102.72410000",
  },
  {
    id: 116955,
    name: "Frisco",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.15067000",
    longitude: "-96.82361000",
  },
  {
    id: 116958,
    name: "Fritch",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "35.63977000",
    longitude: "-101.60323000",
  },
  {
    id: 116984,
    name: "Fulshear",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.68996000",
    longitude: "-95.89968000",
  },
  {
    id: 116989,
    name: "Fulton",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.06140000",
    longitude: "-97.04110000",
  },
  {
    id: 117011,
    name: "Gail",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.77038000",
    longitude: "-101.44541000",
  },
  {
    id: 117012,
    name: "Gaines County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.74073000",
    longitude: "-102.63518000",
  },
  {
    id: 117017,
    name: "Gainesville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.62594000",
    longitude: "-97.13335000",
  },
  {
    id: 117025,
    name: "Galena Park",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.73356000",
    longitude: "-95.23021000",
  },
  {
    id: 117042,
    name: "Galveston",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.30135000",
    longitude: "-94.79770000",
  },
  {
    id: 117044,
    name: "Galveston County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.29767000",
    longitude: "-94.81087000",
  },
  {
    id: 117047,
    name: "Ganado",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.04054000",
    longitude: "-96.51358000",
  },
  {
    id: 117060,
    name: "Garden City",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.86402000",
    longitude: "-101.48123000",
  },
  {
    id: 117067,
    name: "Garden Ridge",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.63467000",
    longitude: "-98.30529000",
  },
  {
    id: 117071,
    name: "Gardendale",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.02040000",
    longitude: "-102.38015000",
  },
  {
    id: 117080,
    name: "Garfield",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.18744000",
    longitude: "-97.55778000",
  },
  {
    id: 117088,
    name: "Garland",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.91262000",
    longitude: "-96.63888000",
  },
  {
    id: 117108,
    name: "Garza County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.17988000",
    longitude: "-101.29842000",
  },
  {
    id: 117122,
    name: "Gatesville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.43516000",
    longitude: "-97.74391000",
  },
  {
    id: 117154,
    name: "George West",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.33250000",
    longitude: "-98.11751000",
  },
  {
    id: 117160,
    name: "Georgetown",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.63269000",
    longitude: "-97.67723000",
  },
  {
    id: 117177,
    name: "Geronimo",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.66300000",
    longitude: "-97.96695000",
  },
  {
    id: 117181,
    name: "Gholson",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.70100000",
    longitude: "-97.21640000",
  },
  {
    id: 117195,
    name: "Giddings",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.18272000",
    longitude: "-96.93637000",
  },
  {
    id: 117215,
    name: "Gillespie County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.31806000",
    longitude: "-98.94648000",
  },
  {
    id: 117221,
    name: "Gilmer",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.72875000",
    longitude: "-94.94244000",
  },
  {
    id: 117235,
    name: "Gladewater",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.53653000",
    longitude: "-94.94272000",
  },
  {
    id: 117252,
    name: "Glasscock County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.86946000",
    longitude: "-101.52080000",
  },
  {
    id: 117274,
    name: "Glen Rose",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.23459000",
    longitude: "-97.75531000",
  },
  {
    id: 117298,
    name: "Glenn Heights",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.54875000",
    longitude: "-96.85667000",
  },
  {
    id: 117335,
    name: "Godley",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.44903000",
    longitude: "-97.52669000",
  },
  {
    id: 117366,
    name: "Goldthwaite",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.44989000",
    longitude: "-98.57088000",
  },
  {
    id: 117368,
    name: "Goliad",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.66833000",
    longitude: "-97.38833000",
  },
  {
    id: 117369,
    name: "Goliad County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.65710000",
    longitude: "-97.42649000",
  },
  {
    id: 117371,
    name: "Gonzales",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.50163000",
    longitude: "-97.45249000",
  },
  {
    id: 117373,
    name: "Gonzales County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.45673000",
    longitude: "-97.49252000",
  },
  {
    id: 117406,
    name: "Gorman",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.21375000",
    longitude: "-98.67061000",
  },
  {
    id: 117435,
    name: "Graham",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.10706000",
    longitude: "-98.58950000",
  },
  {
    id: 117445,
    name: "Granbury",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.44208000",
    longitude: "-97.79420000",
  },
  {
    id: 117471,
    name: "Grand Prairie",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.74596000",
    longitude: "-96.99778000",
  },
  {
    id: 117475,
    name: "Grand Saline",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.67346000",
    longitude: "-95.70941000",
  },
  {
    id: 117480,
    name: "Grandview",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.27042000",
    longitude: "-97.17918000",
  },
  {
    id: 117486,
    name: "Granger",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.71769000",
    longitude: "-97.44278000",
  },
  {
    id: 117501,
    name: "Granite Shoals",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.58908000",
    longitude: "-98.38392000",
  },
  {
    id: 117538,
    name: "Grape Creek",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.57932000",
    longitude: "-100.54760000",
  },
  {
    id: 117539,
    name: "Grapeland",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.49185000",
    longitude: "-95.47856000",
  },
  {
    id: 117540,
    name: "Grapevine",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.93429000",
    longitude: "-97.07807000",
  },
  {
    id: 117554,
    name: "Gray County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "35.40116000",
    longitude: "-100.81256000",
  },
  {
    id: 117563,
    name: "Grayson County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.62681000",
    longitude: "-96.67772000",
  },
  {
    id: 117583,
    name: "Greatwood",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.55413000",
    longitude: "-95.67578000",
  },
  {
    id: 117612,
    name: "Green Valley Farms",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.12202000",
    longitude: "-97.56054000",
  },
  {
    id: 117689,
    name: "Greenville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.13845000",
    longitude: "-96.11081000",
  },
  {
    id: 117715,
    name: "Gregg County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.48047000",
    longitude: "-94.81695000",
  },
  {
    id: 117716,
    name: "Gregory",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "27.92224000",
    longitude: "-97.28999000",
  },
  {
    id: 117738,
    name: "Grimes County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.54347000",
    longitude: "-95.98550000",
  },
  {
    id: 117742,
    name: "Groesbeck",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.52434000",
    longitude: "-96.53387000",
  },
  {
    id: 117761,
    name: "Groves",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.94827000",
    longitude: "-93.91712000",
  },
  {
    id: 117762,
    name: "Groveton",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.05491000",
    longitude: "-95.12577000",
  },
  {
    id: 117774,
    name: "Gruver",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "36.26503000",
    longitude: "-101.40627000",
  },
  {
    id: 117778,
    name: "Guadalupe County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.58305000",
    longitude: "-97.94858000",
  },
  {
    id: 117800,
    name: "Gun Barrel City",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.33459000",
    longitude: "-96.15136000",
  },
  {
    id: 117805,
    name: "Gunter",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.44789000",
    longitude: "-96.74749000",
  },
  {
    id: 117813,
    name: "Guthrie",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.62064000",
    longitude: "-100.32289000",
  },
  {
    id: 117827,
    name: "Hackberry",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.15234000",
    longitude: "-96.91778000",
  },
  {
    id: 117847,
    name: "Hale Center",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.06424000",
    longitude: "-101.84379000",
  },
  {
    id: 117849,
    name: "Hale County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.07051000",
    longitude: "-101.82688000",
  },
  {
    id: 117866,
    name: "Hall County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.53078000",
    longitude: "-100.68113000",
  },
  {
    id: 117870,
    name: "Hallettsville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.44385000",
    longitude: "-96.94109000",
  },
  {
    id: 117876,
    name: "Hallsville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.50432000",
    longitude: "-94.57409000",
  },
  {
    id: 117878,
    name: "Haltom City",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.79957000",
    longitude: "-97.26918000",
  },
  {
    id: 117890,
    name: "Hamilton",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.70377000",
    longitude: "-98.12392000",
  },
  {
    id: 117899,
    name: "Hamilton County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.70480000",
    longitude: "-98.11073000",
  },
  {
    id: 117910,
    name: "Hamlin",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.88483000",
    longitude: "-100.12649000",
  },
  {
    id: 117977,
    name: "Hansford County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "36.27745000",
    longitude: "-101.35454000",
  },
  {
    id: 117996,
    name: "Hardeman County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.29029000",
    longitude: "-99.74572000",
  },
  {
    id: 118002,
    name: "Hardin County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.33237000",
    longitude: "-94.39022000",
  },
  {
    id: 118013,
    name: "Harker Heights",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.08351000",
    longitude: "-97.65974000",
  },
  {
    id: 118026,
    name: "Harlingen",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.19063000",
    longitude: "-97.69610000",
  },
  {
    id: 118032,
    name: "Harper",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.29992000",
    longitude: "-99.24421000",
  },
  {
    id: 118045,
    name: "Harris County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.85728000",
    longitude: "-95.39234000",
  },
  {
    id: 118065,
    name: "Harrison County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.54813000",
    longitude: "-94.37149000",
  },
  {
    id: 118081,
    name: "Hart",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.38507000",
    longitude: "-102.11574000",
  },
  {
    id: 118100,
    name: "Hartley County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "35.84002000",
    longitude: "-102.60289000",
  },
  {
    id: 118124,
    name: "Haskell",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.15760000",
    longitude: "-99.73370000",
  },
  {
    id: 118126,
    name: "Haskell County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.17823000",
    longitude: "-99.73030000",
  },
  {
    id: 118128,
    name: "Haslet",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.97485000",
    longitude: "-97.34780000",
  },
  {
    id: 118165,
    name: "Hawkins",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.58847000",
    longitude: "-95.20411000",
  },
  {
    id: 118192,
    name: "Hays County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.05815000",
    longitude: "-98.03106000",
  },
  {
    id: 118221,
    name: "Hearne",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.87852000",
    longitude: "-96.59303000",
  },
  {
    id: 118222,
    name: "Heath",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.83651000",
    longitude: "-96.47499000",
  },
  {
    id: 118227,
    name: "Hebbronville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "27.30688000",
    longitude: "-98.68032000",
  },
  {
    id: 118242,
    name: "Hedwig Village",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.77745000",
    longitude: "-95.51716000",
  },
  {
    id: 118244,
    name: "Heidelberg",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.19702000",
    longitude: "-97.88028000",
  },
  {
    id: 118261,
    name: "Helotes",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.57801000",
    longitude: "-98.68975000",
  },
  {
    id: 118267,
    name: "Hemphill",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.34074000",
    longitude: "-93.84685000",
  },
  {
    id: 118268,
    name: "Hemphill County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "35.83757000",
    longitude: "-100.27047000",
  },
  {
    id: 118269,
    name: "Hempstead",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.09744000",
    longitude: "-96.07829000",
  },
  {
    id: 118273,
    name: "Henderson",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.15322000",
    longitude: "-94.79938000",
  },
  {
    id: 118283,
    name: "Henderson County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.21189000",
    longitude: "-95.85356000",
  },
  {
    id: 118295,
    name: "Henrietta",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.81732000",
    longitude: "-98.19532000",
  },
  {
    id: 118315,
    name: "Hereford",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.81521000",
    longitude: "-102.39932000",
  },
  {
    id: 118348,
    name: "Hewitt",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.46239000",
    longitude: "-97.19584000",
  },
  {
    id: 118367,
    name: "Hickory Creek",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.12234000",
    longitude: "-97.04306000",
  },
  {
    id: 118372,
    name: "Hico",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.98293000",
    longitude: "-98.03365000",
  },
  {
    id: 118373,
    name: "Hidalgo",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.10035000",
    longitude: "-98.26307000",
  },
  {
    id: 118374,
    name: "Hidalgo County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.39672000",
    longitude: "-98.18107000",
  },
  {
    id: 118382,
    name: "Hideaway",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.49042000",
    longitude: "-95.45746000",
  },
  {
    id: 118410,
    name: "Highland Park",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.83346000",
    longitude: "-96.79195000",
  },
  {
    id: 118416,
    name: "Highland Village",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.09179000",
    longitude: "-97.04668000",
  },
  {
    id: 118417,
    name: "Highlands",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.81884000",
    longitude: "-95.05604000",
  },
  {
    id: 118435,
    name: "Hill Country Village",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.58245000",
    longitude: "-98.49085000",
  },
  {
    id: 118436,
    name: "Hill County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.99068000",
    longitude: "-97.13243000",
  },
  {
    id: 118449,
    name: "Hillsboro",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.01099000",
    longitude: "-97.13001000",
  },
  {
    id: 118469,
    name: "Hilltop Lakes",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.07935000",
    longitude: "-96.20385000",
  },
  {
    id: 118492,
    name: "Hitchcock",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.34829000",
    longitude: "-95.01604000",
  },
  {
    id: 118505,
    name: "Hockley County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.60764000",
    longitude: "-102.34320000",
  },
  {
    id: 118534,
    name: "Holiday Lakes",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.20969000",
    longitude: "-95.51689000",
  },
  {
    id: 118537,
    name: "Holland",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.87824000",
    longitude: "-97.40167000",
  },
  {
    id: 118544,
    name: "Holliday",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.81621000",
    longitude: "-98.69506000",
  },
  {
    id: 118559,
    name: "Holly Lake Ranch",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.71336000",
    longitude: "-95.19756000",
  },
  {
    id: 118568,
    name: "Hollywood Park",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.60050000",
    longitude: "-98.48724000",
  },
  {
    id: 118603,
    name: "Homestead Meadows North",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.84963000",
    longitude: "-106.17285000",
  },
  {
    id: 118604,
    name: "Homestead Meadows South",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.81097000",
    longitude: "-106.16427000",
  },
  {
    id: 118615,
    name: "Hondo",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.34746000",
    longitude: "-99.14142000",
  },
  {
    id: 118620,
    name: "Honey Grove",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.58344000",
    longitude: "-95.90997000",
  },
  {
    id: 118625,
    name: "Hood County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.42993000",
    longitude: "-97.83227000",
  },
  {
    id: 118630,
    name: "Hooks",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.46623000",
    longitude: "-94.28853000",
  },
  {
    id: 118654,
    name: "Hopkins County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.14956000",
    longitude: "-95.56395000",
  },
  {
    id: 118664,
    name: "Horizon City",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.69261000",
    longitude: "-106.20748000",
  },
  {
    id: 118668,
    name: "Hornsby Bend",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.24743000",
    longitude: "-97.58333000",
  },
  {
    id: 118675,
    name: "Horseshoe Bay",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.54429000",
    longitude: "-98.37394000",
  },
  {
    id: 118699,
    name: "Houston",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.76328000",
    longitude: "-95.36327000",
  },
  {
    id: 118705,
    name: "Houston County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.31773000",
    longitude: "-95.42268000",
  },
  {
    id: 118718,
    name: "Howard County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.30617000",
    longitude: "-101.43551000",
  },
  {
    id: 118721,
    name: "Howe",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.50872000",
    longitude: "-96.61221000",
  },
  {
    id: 118730,
    name: "Hubbard",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.84849000",
    longitude: "-96.79721000",
  },
  {
    id: 118737,
    name: "Hudson",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.32268000",
    longitude: "-94.77826000",
  },
  {
    id: 118748,
    name: "Hudson Bend",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.41714000",
    longitude: "-97.92918000",
  },
  {
    id: 118752,
    name: "Hudson Oaks",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.75707000",
    longitude: "-97.70670000",
  },
  {
    id: 118754,
    name: "Hudspeth County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.45618000",
    longitude: "-105.38646000",
  },
  {
    id: 118760,
    name: "Hughes Springs",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.99846000",
    longitude: "-94.63076000",
  },
  {
    id: 118774,
    name: "Humble",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.99883000",
    longitude: "-95.26216000",
  },
  {
    id: 118786,
    name: "Hunt County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.12360000",
    longitude: "-96.08545000",
  },
  {
    id: 118791,
    name: "Hunters Creek Village",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.77051000",
    longitude: "-95.49550000",
  },
  {
    id: 118798,
    name: "Huntington",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.27769000",
    longitude: "-94.57659000",
  },
  {
    id: 118818,
    name: "Huntsville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.72353000",
    longitude: "-95.55078000",
  },
  {
    id: 118829,
    name: "Hurst",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.82346000",
    longitude: "-97.17057000",
  },
  {
    id: 118834,
    name: "Hutchins",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.64930000",
    longitude: "-96.71305000",
  },
  {
    id: 118838,
    name: "Hutchinson County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "35.84003000",
    longitude: "-101.35470000",
  },
  {
    id: 118840,
    name: "Hutto",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.54270000",
    longitude: "-97.54667000",
  },
  {
    id: 118873,
    name: "Idalou",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.66647000",
    longitude: "-101.68294000",
  },
  {
    id: 118907,
    name: "Indian Hills",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.21285000",
    longitude: "-97.91639000",
  },
  {
    id: 118932,
    name: "Inez",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.90388000",
    longitude: "-96.78804000",
  },
  {
    id: 118936,
    name: "Ingleside",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "27.87780000",
    longitude: "-97.21166000",
  },
  {
    id: 118940,
    name: "Ingram",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.07743000",
    longitude: "-99.24032000",
  },
  {
    id: 118977,
    name: "Iowa Colony",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.48246000",
    longitude: "-95.41550000",
  },
  {
    id: 118981,
    name: "Iowa Park",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.95148000",
    longitude: "-98.66867000",
  },
  {
    id: 118984,
    name: "Iraan",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.91405000",
    longitude: "-101.89791000",
  },
  {
    id: 118986,
    name: "Irion County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.30392000",
    longitude: "-100.98242000",
  },
  {
    id: 119005,
    name: "Irving",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.81402000",
    longitude: "-96.94889000",
  },
  {
    id: 119038,
    name: "Italy",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.18404000",
    longitude: "-96.88472000",
  },
  {
    id: 119039,
    name: "Itasca",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.15959000",
    longitude: "-97.15001000",
  },
  {
    id: 119055,
    name: "Jacinto City",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.76745000",
    longitude: "-95.23382000",
  },
  {
    id: 119056,
    name: "Jack County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.23346000",
    longitude: "-98.17246000",
  },
  {
    id: 119059,
    name: "Jacksboro",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.21845000",
    longitude: "-98.15866000",
  },
  {
    id: 119088,
    name: "Jackson County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.95424000",
    longitude: "-96.57768000",
  },
  {
    id: 119104,
    name: "Jacksonville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.96378000",
    longitude: "-95.27050000",
  },
  {
    id: 119113,
    name: "Jamaica Beach",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.18968000",
    longitude: "-94.97965000",
  },
  {
    id: 119134,
    name: "Jarrell",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.82491000",
    longitude: "-97.60445000",
  },
  {
    id: 119144,
    name: "Jasper",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.92020000",
    longitude: "-93.99658000",
  },
  {
    id: 119150,
    name: "Jasper County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.74396000",
    longitude: "-94.02509000",
  },
  {
    id: 119156,
    name: "Jayton",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.24815000",
    longitude: "-100.57373000",
  },
  {
    id: 119161,
    name: "Jeff Davis County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.71548000",
    longitude: "-104.14002000",
  },
  {
    id: 119166,
    name: "Jefferson",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.75736000",
    longitude: "-94.34519000",
  },
  {
    id: 119187,
    name: "Jefferson County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.85373000",
    longitude: "-94.15344000",
  },
  {
    id: 119234,
    name: "Jersey Village",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.88772000",
    longitude: "-95.56300000",
  },
  {
    id: 119244,
    name: "Jewett",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.36157000",
    longitude: "-96.14413000",
  },
  {
    id: 119246,
    name: "Jim Hogg County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "27.04340000",
    longitude: "-98.69731000",
  },
  {
    id: 119248,
    name: "Jim Wells County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "27.73130000",
    longitude: "-98.08994000",
  },
  {
    id: 119258,
    name: "Johnson City",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.27687000",
    longitude: "-98.41197000",
  },
  {
    id: 119268,
    name: "Johnson County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.37901000",
    longitude: "-97.36633000",
  },
  {
    id: 119290,
    name: "Jollyville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.44270000",
    longitude: "-97.77501000",
  },
  {
    id: 119295,
    name: "Jones County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.73990000",
    longitude: "-99.87874000",
  },
  {
    id: 119298,
    name: "Jones Creek",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.96858000",
    longitude: "-95.45522000",
  },
  {
    id: 119307,
    name: "Jonestown",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.49547000",
    longitude: "-97.92335000",
  },
  {
    id: 119320,
    name: "Josephine",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.06123000",
    longitude: "-96.30720000",
  },
  {
    id: 119322,
    name: "Joshua",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.46153000",
    longitude: "-97.38807000",
  },
  {
    id: 119324,
    name: "Jourdanton",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.91803000",
    longitude: "-98.54641000",
  },
  {
    id: 119331,
    name: "Junction",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.48936000",
    longitude: "-99.77201000",
  },
  {
    id: 119346,
    name: "Justin",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.08484000",
    longitude: "-97.29613000",
  },
  {
    id: 119389,
    name: "Karnes City",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.88498000",
    longitude: "-97.90084000",
  },
  {
    id: 119390,
    name: "Karnes County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.90574000",
    longitude: "-97.85940000",
  },
  {
    id: 119395,
    name: "Katy",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.78579000",
    longitude: "-95.82440000",
  },
  {
    id: 119397,
    name: "Kaufman",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.58902000",
    longitude: "-96.30887000",
  },
  {
    id: 119398,
    name: "Kaufman County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.59930000",
    longitude: "-96.28780000",
  },
  {
    id: 119420,
    name: "Keene",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.39681000",
    longitude: "-97.32390000",
  },
  {
    id: 119428,
    name: "Keller",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.93457000",
    longitude: "-97.25168000",
  },
  {
    id: 119433,
    name: "Kemah",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.54273000",
    longitude: "-95.02048000",
  },
  {
    id: 119435,
    name: "Kemp",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.44264000",
    longitude: "-96.22998000",
  },
  {
    id: 119438,
    name: "Kempner",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.08101000",
    longitude: "-98.00252000",
  },
  {
    id: 119446,
    name: "Kendall County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.94469000",
    longitude: "-98.71156000",
  },
  {
    id: 119453,
    name: "Kenedy",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.81915000",
    longitude: "-97.84861000",
  },
  {
    id: 119454,
    name: "Kenedy County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.92802000",
    longitude: "-97.63646000",
  },
  {
    id: 119468,
    name: "Kennedale",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.64680000",
    longitude: "-97.22585000",
  },
  {
    id: 119494,
    name: "Kent County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.18142000",
    longitude: "-100.77757000",
  },
  {
    id: 119510,
    name: "Kerens",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.13321000",
    longitude: "-96.22775000",
  },
  {
    id: 119513,
    name: "Kermit",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.85763000",
    longitude: "-103.09267000",
  },
  {
    id: 119517,
    name: "Kerr County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.06148000",
    longitude: "-99.35016000",
  },
  {
    id: 119518,
    name: "Kerrville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.04743000",
    longitude: "-99.14032000",
  },
  {
    id: 119556,
    name: "Kilgore",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.38626000",
    longitude: "-94.87577000",
  },
  {
    id: 119559,
    name: "Killeen",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.11712000",
    longitude: "-97.72780000",
  },
  {
    id: 119572,
    name: "Kimble County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.48678000",
    longitude: "-99.74870000",
  },
  {
    id: 119581,
    name: "King County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.61648000",
    longitude: "-100.25585000",
  },
  {
    id: 119616,
    name: "Kingsland",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.65824000",
    longitude: "-98.44058000",
  },
  {
    id: 119636,
    name: "Kingsville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "27.51587000",
    longitude: "-97.85611000",
  },
  {
    id: 119638,
    name: "Kingwood Area",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.03355000",
    longitude: "-95.26104000",
  },
  {
    id: 119640,
    name: "Kinney County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.35021000",
    longitude: "-100.41795000",
  },
  {
    id: 119649,
    name: "Kirby",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.46329000",
    longitude: "-98.38557000",
  },
  {
    id: 119650,
    name: "Kirbyville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.66048000",
    longitude: "-93.89268000",
  },
  {
    id: 119672,
    name: "Kleberg County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "27.42671000",
    longitude: "-97.66839000",
  },
  {
    id: 119684,
    name: "Knox City",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.41815000",
    longitude: "-99.81898000",
  },
  {
    id: 119688,
    name: "Knox County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.60613000",
    longitude: "-99.74143000",
  },
  {
    id: 119713,
    name: "Kountze",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.37160000",
    longitude: "-94.31241000",
  },
  {
    id: 119719,
    name: "Krugerville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.28151000",
    longitude: "-96.99056000",
  },
  {
    id: 119720,
    name: "Krum",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.26151000",
    longitude: "-97.23807000",
  },
  {
    id: 119729,
    name: "Kyle",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.98911000",
    longitude: "-97.87723000",
  },
  {
    id: 119735,
    name: "La Blanca",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.29285000",
    longitude: "-98.03778000",
  },
  {
    id: 119740,
    name: "La Coste",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.31079000",
    longitude: "-98.81003000",
  },
  {
    id: 119747,
    name: "La Feria",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.15896000",
    longitude: "-97.82389000",
  },
  {
    id: 119751,
    name: "La Grange",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.90550000",
    longitude: "-96.87665000",
  },
  {
    id: 119754,
    name: "La Grulla",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.26951000",
    longitude: "-98.64725000",
  },
  {
    id: 119758,
    name: "La Homa",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.25007000",
    longitude: "-98.36363000",
  },
  {
    id: 119761,
    name: "La Joya",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.24702000",
    longitude: "-98.48141000",
  },
  {
    id: 119764,
    name: "La Marque",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.36857000",
    longitude: "-94.97131000",
  },
  {
    id: 119770,
    name: "La Paloma",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.04591000",
    longitude: "-97.66749000",
  },
  {
    id: 119776,
    name: "La Porte",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.66578000",
    longitude: "-95.01937000",
  },
  {
    id: 119780,
    name: "La Pryor",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.94108000",
    longitude: "-99.84978000",
  },
  {
    id: 119787,
    name: "La Salle County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.34507000",
    longitude: "-99.09966000",
  },
  {
    id: 119794,
    name: "La Vernia",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.35635000",
    longitude: "-98.11556000",
  },
  {
    id: 119795,
    name: "La Villa",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.29868000",
    longitude: "-97.92861000",
  },
  {
    id: 119815,
    name: "Lackland Air Force Base",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.38663000",
    longitude: "-98.61797000",
  },
  {
    id: 119821,
    name: "Lacy-Lakeview",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.62933000",
    longitude: "-97.10278000",
  },
  {
    id: 119847,
    name: "Lago Vista",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.46020000",
    longitude: "-97.98835000",
  },
  {
    id: 119853,
    name: "Laguna Heights",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.08008000",
    longitude: "-97.25386000",
  },
  {
    id: 119856,
    name: "Laguna Park",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.85932000",
    longitude: "-97.37974000",
  },
  {
    id: 119857,
    name: "Laguna Vista",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.10091000",
    longitude: "-97.29025000",
  },
  {
    id: 119872,
    name: "Lake Brownwood",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.83570000",
    longitude: "-99.00783000",
  },
  {
    id: 119902,
    name: "Lake Dallas",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.11929000",
    longitude: "-97.02556000",
  },
  {
    id: 119904,
    name: "Lake Dunlap",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.67578000",
    longitude: "-98.07223000",
  },
  {
    id: 119924,
    name: "Lake Jackson",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.03386000",
    longitude: "-95.43439000",
  },
  {
    id: 119927,
    name: "Lake Kiowa",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.57705000",
    longitude: "-97.01306000",
  },
  {
    id: 119998,
    name: "Lake Worth",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.80485000",
    longitude: "-97.44502000",
  },
  {
    id: 120010,
    name: "Lakehills",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.60467000",
    longitude: "-98.94309000",
  },
  {
    id: 120030,
    name: "Lakeside",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.82235000",
    longitude: "-97.49335000",
  },
  {
    id: 120044,
    name: "Lakeway",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.36377000",
    longitude: "-97.97959000",
  },
  {
    id: 120064,
    name: "Lamar County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.66726000",
    longitude: "-95.57120000",
  },
  {
    id: 120066,
    name: "Lamb County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.06862000",
    longitude: "-102.35171000",
  },
  {
    id: 120071,
    name: "Lamesa",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.73760000",
    longitude: "-101.95099000",
  },
  {
    id: 120075,
    name: "Lampasas",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.06378000",
    longitude: "-98.18170000",
  },
  {
    id: 120076,
    name: "Lampasas County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.19619000",
    longitude: "-98.24145000",
  },
  {
    id: 120082,
    name: "Lancaster",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.59208000",
    longitude: "-96.75611000",
  },
  {
    id: 120130,
    name: "Lantana",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.09073000",
    longitude: "-97.12416000",
  },
  {
    id: 120142,
    name: "Laredo",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "27.50641000",
    longitude: "-99.50754000",
  },
  {
    id: 120157,
    name: "Las Lomas",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.36479000",
    longitude: "-98.77530000",
  },
  {
    id: 120160,
    name: "Las Palmas II",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.20171000",
    longitude: "-97.73760000",
  },
  {
    id: 120161,
    name: "Las Quintas Fronterizas",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.69053000",
    longitude: "-100.46869000",
  },
  {
    id: 120162,
    name: "Las Quintas Fronterizas Colonia",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.69137000",
    longitude: "-100.46924000",
  },
  {
    id: 120165,
    name: "Lasara",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.46479000",
    longitude: "-97.91111000",
  },
  {
    id: 120186,
    name: "Laughlin Air Force Base",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.35663000",
    longitude: "-100.78353000",
  },
  {
    id: 120203,
    name: "Laureles",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.10924000",
    longitude: "-97.49415000",
  },
  {
    id: 120214,
    name: "Lavaca County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.38435000",
    longitude: "-96.93015000",
  },
  {
    id: 120219,
    name: "Lavon",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.02762000",
    longitude: "-96.43415000",
  },
  {
    id: 120274,
    name: "League City",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.50745000",
    longitude: "-95.09493000",
  },
  {
    id: 120277,
    name: "Leakey",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.72884000",
    longitude: "-99.76145000",
  },
  {
    id: 120279,
    name: "Leander",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.57881000",
    longitude: "-97.85307000",
  },
  {
    id: 120314,
    name: "Lee County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.31068000",
    longitude: "-96.96570000",
  },
  {
    id: 120385,
    name: "Leon County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.29651000",
    longitude: "-95.99569000",
  },
  {
    id: 120386,
    name: "Leon Valley",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.49523000",
    longitude: "-98.61863000",
  },
  {
    id: 120388,
    name: "Leonard",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.37955000",
    longitude: "-96.24748000",
  },
  {
    id: 120405,
    name: "Levelland",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.58732000",
    longitude: "-102.37796000",
  },
  {
    id: 120436,
    name: "Lewisville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.04623000",
    longitude: "-96.99417000",
  },
  {
    id: 120446,
    name: "Lexington",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.41910000",
    longitude: "-97.01165000",
  },
  {
    id: 120465,
    name: "Liberty",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.05799000",
    longitude: "-94.79548000",
  },
  {
    id: 120469,
    name: "Liberty City",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.44543000",
    longitude: "-94.94855000",
  },
  {
    id: 120472,
    name: "Liberty County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.15161000",
    longitude: "-94.81221000",
  },
  {
    id: 120474,
    name: "Liberty Hill",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.66491000",
    longitude: "-97.92252000",
  },
  {
    id: 120495,
    name: "Limestone County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.54546000",
    longitude: "-96.58053000",
  },
  {
    id: 120559,
    name: "Lindale",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.51570000",
    longitude: "-95.40940000",
  },
  {
    id: 120562,
    name: "Linden",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.01235000",
    longitude: "-94.36547000",
  },
  {
    id: 120573,
    name: "Lindsay",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.63594000",
    longitude: "-97.22279000",
  },
  {
    id: 120596,
    name: "Lipscomb County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "36.27771000",
    longitude: "-100.27312000",
  },
  {
    id: 120616,
    name: "Little Elm",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.16262000",
    longitude: "-96.93751000",
  },
  {
    id: 120625,
    name: "Little River-Academy",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.98629000",
    longitude: "-97.35861000",
  },
  {
    id: 120632,
    name: "Littlefield",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.91731000",
    longitude: "-102.32490000",
  },
  {
    id: 120639,
    name: "Live Oak",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.56523000",
    longitude: "-98.33640000",
  },
  {
    id: 120641,
    name: "Live Oak County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.35137000",
    longitude: "-98.12479000",
  },
  {
    id: 120650,
    name: "Livingston",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.71103000",
    longitude: "-94.93299000",
  },
  {
    id: 120664,
    name: "Llano",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.75935000",
    longitude: "-98.67504000",
  },
  {
    id: 120665,
    name: "Llano County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.70574000",
    longitude: "-98.68410000",
  },
  {
    id: 120666,
    name: "Llano Grande",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.12980000",
    longitude: "-97.96806000",
  },
  {
    id: 120677,
    name: "Lockhart",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.88494000",
    longitude: "-97.67000000",
  },
  {
    id: 120678,
    name: "Lockney",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.12452000",
    longitude: "-101.44155000",
  },
  {
    id: 120730,
    name: "Lone Star",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.94402000",
    longitude: "-94.70715000",
  },
  {
    id: 120757,
    name: "Longview",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.50070000",
    longitude: "-94.74049000",
  },
  {
    id: 120768,
    name: "Lopezville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.23813000",
    longitude: "-98.15973000",
  },
  {
    id: 120771,
    name: "Lorena",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.38656000",
    longitude: "-97.21556000",
  },
  {
    id: 120773,
    name: "Lorenzo",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.67064000",
    longitude: "-101.53516000",
  },
  {
    id: 120788,
    name: "Los Fresnos",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.07174000",
    longitude: "-97.47637000",
  },
  {
    id: 120790,
    name: "Los Indios",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.04924000",
    longitude: "-97.74499000",
  },
  {
    id: 120797,
    name: "Lost Creek",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.29548000",
    longitude: "-97.84445000",
  },
  {
    id: 120829,
    name: "Loving County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.84923000",
    longitude: "-103.57996000",
  },
  {
    id: 120849,
    name: "Lowry Crossing",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.15484000",
    longitude: "-96.54721000",
  },
  {
    id: 120856,
    name: "Lubbock",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.57786000",
    longitude: "-101.85517000",
  },
  {
    id: 120857,
    name: "Lubbock County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.61021000",
    longitude: "-101.82055000",
  },
  {
    id: 120860,
    name: "Lucas",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.08429000",
    longitude: "-96.57666000",
  },
  {
    id: 120872,
    name: "Lufkin",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.33824000",
    longitude: "-94.72910000",
  },
  {
    id: 120877,
    name: "Luling",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.68051000",
    longitude: "-97.64750000",
  },
  {
    id: 120881,
    name: "Lumberton",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.26577000",
    longitude: "-94.19963000",
  },
  {
    id: 120906,
    name: "Lyford",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.41229000",
    longitude: "-97.78972000",
  },
  {
    id: 120928,
    name: "Lynn County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.17682000",
    longitude: "-101.81612000",
  },
  {
    id: 120946,
    name: "Lytle",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.23329000",
    longitude: "-98.79641000",
  },
  {
    id: 120948,
    name: "Mabank",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.36653000",
    longitude: "-96.10081000",
  },
  {
    id: 121012,
    name: "Madison County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.96554000",
    longitude: "-95.92841000",
  },
  {
    id: 121027,
    name: "Madisonville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.94991000",
    longitude: "-95.91162000",
  },
  {
    id: 121038,
    name: "Magnolia",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.20938000",
    longitude: "-95.75078000",
  },
  {
    id: 121058,
    name: "Malakoff",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.16960000",
    longitude: "-96.01247000",
  },
  {
    id: 121088,
    name: "Manchaca",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.14077000",
    longitude: "-97.83306000",
  },
  {
    id: 121138,
    name: "Manor",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.34076000",
    longitude: "-97.55695000",
  },
  {
    id: 121145,
    name: "Mansfield",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.56319000",
    longitude: "-97.14168000",
  },
  {
    id: 121161,
    name: "Manvel",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.46274000",
    longitude: "-95.35799000",
  },
  {
    id: 121187,
    name: "Marble Falls",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.57841000",
    longitude: "-98.27507000",
  },
  {
    id: 121204,
    name: "Marfa",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.30973000",
    longitude: "-104.02134000",
  },
  {
    id: 121239,
    name: "Marion",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.57134000",
    longitude: "-98.14029000",
  },
  {
    id: 121259,
    name: "Marion County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.79798000",
    longitude: "-94.35722000",
  },
  {
    id: 121269,
    name: "Markham",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.96026000",
    longitude: "-96.06524000",
  },
  {
    id: 121283,
    name: "Marlin",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.30629000",
    longitude: "-96.89804000",
  },
  {
    id: 121305,
    name: "Marshall",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.54487000",
    longitude: "-94.36742000",
  },
  {
    id: 121332,
    name: "Mart",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.54239000",
    longitude: "-96.83360000",
  },
  {
    id: 121342,
    name: "Martin County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.30603000",
    longitude: "-101.95122000",
  },
  {
    id: 121343,
    name: "Martindale",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.84550000",
    longitude: "-97.84084000",
  },
  {
    id: 121372,
    name: "Mason",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.74879000",
    longitude: "-99.23061000",
  },
  {
    id: 121378,
    name: "Mason County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.71772000",
    longitude: "-99.22613000",
  },
  {
    id: 121396,
    name: "Matador",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.01202000",
    longitude: "-100.82208000",
  },
  {
    id: 121397,
    name: "Matagorda County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.78565000",
    longitude: "-96.00398000",
  },
  {
    id: 121405,
    name: "Mathis",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.09446000",
    longitude: "-97.82805000",
  },
  {
    id: 121417,
    name: "Maud",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.33290000",
    longitude: "-94.34270000",
  },
  {
    id: 121424,
    name: "Mauriceville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.20354000",
    longitude: "-93.86628000",
  },
  {
    id: 121428,
    name: "Maverick County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.74259000",
    longitude: "-100.31451000",
  },
  {
    id: 121463,
    name: "McAllen",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.20341000",
    longitude: "-98.23001000",
  },
  {
    id: 121466,
    name: "McCamey",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.13598000",
    longitude: "-102.22430000",
  },
  {
    id: 121488,
    name: "McCulloch County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.19887000",
    longitude: "-99.34748000",
  },
  {
    id: 121506,
    name: "McGregor",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.44406000",
    longitude: "-97.40918000",
  },
  {
    id: 121525,
    name: "McKinney",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.19762000",
    longitude: "-96.61527000",
  },
  {
    id: 121533,
    name: "McLendon-Chisholm",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.84235000",
    longitude: "-96.38054000",
  },
  {
    id: 121534,
    name: "McLennan County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.55238000",
    longitude: "-97.20179000",
  },
  {
    id: 121542,
    name: "McMullen County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.35269000",
    longitude: "-98.56784000",
  },
  {
    id: 121549,
    name: "McQueeney",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.59217000",
    longitude: "-98.03334000",
  },
  {
    id: 121569,
    name: "Meadowlakes",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.56245000",
    longitude: "-98.29867000",
  },
  {
    id: 121571,
    name: "Meadows Place",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.65134000",
    longitude: "-95.58800000",
  },
  {
    id: 121603,
    name: "Medina",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.79661000",
    longitude: "-99.24643000",
  },
  {
    id: 121607,
    name: "Medina County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.35570000",
    longitude: "-99.11013000",
  },
  {
    id: 121624,
    name: "Melissa",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.28595000",
    longitude: "-96.57277000",
  },
  {
    id: 121642,
    name: "Memphis",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.72478000",
    longitude: "-100.53401000",
  },
  {
    id: 121646,
    name: "Menard",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.91767000",
    longitude: "-99.78646000",
  },
  {
    id: 121647,
    name: "Menard County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.88978000",
    longitude: "-99.82064000",
  },
  {
    id: 121668,
    name: "Mentone",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.70513000",
    longitude: "-103.59935000",
  },
  {
    id: 121673,
    name: "Mercedes",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.14980000",
    longitude: "-97.91361000",
  },
  {
    id: 121692,
    name: "Meridian",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.92321000",
    longitude: "-97.65669000",
  },
  {
    id: 121700,
    name: "Merkel",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.47068000",
    longitude: "-100.01287000",
  },
  {
    id: 121719,
    name: "Mertzon",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.26183000",
    longitude: "-100.81733000",
  },
  {
    id: 121726,
    name: "Mesquite",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.76680000",
    longitude: "-96.59916000",
  },
  {
    id: 121740,
    name: "Mexia",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.67989000",
    longitude: "-96.48220000",
  },
  {
    id: 121749,
    name: "Miami",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "35.69143000",
    longitude: "-100.63819000",
  },
  {
    id: 121800,
    name: "Midland",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.99735000",
    longitude: "-102.07791000",
  },
  {
    id: 121805,
    name: "Midland County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.86917000",
    longitude: "-102.03162000",
  },
  {
    id: 121807,
    name: "Midlothian",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.48236000",
    longitude: "-96.99445000",
  },
  {
    id: 121822,
    name: "Midway North",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.18776000",
    longitude: "-98.01708000",
  },
  {
    id: 121823,
    name: "Midway South",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.15694000",
    longitude: "-98.02011000",
  },
  {
    id: 121831,
    name: "Mila Doce",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.22618000",
    longitude: "-97.95889000",
  },
  {
    id: 121833,
    name: "Milam",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.43240000",
    longitude: "-93.84574000",
  },
  {
    id: 121834,
    name: "Milam County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.78634000",
    longitude: "-96.97685000",
  },
  {
    id: 121897,
    name: "Mills County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.49519000",
    longitude: "-98.59546000",
  },
  {
    id: 121931,
    name: "Mineola",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.66319000",
    longitude: "-95.48829000",
  },
  {
    id: 121941,
    name: "Mineral Wells",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.80846000",
    longitude: "-98.11282000",
  },
  {
    id: 121980,
    name: "Mission",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.21591000",
    longitude: "-98.32529000",
  },
  {
    id: 121983,
    name: "Mission Bend",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.69384000",
    longitude: "-95.66495000",
  },
  {
    id: 121993,
    name: "Missouri City",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.61857000",
    longitude: "-95.53772000",
  },
  {
    id: 122003,
    name: "Mitchell County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.30621000",
    longitude: "-100.92123000",
  },
  {
    id: 122033,
    name: "Monahans",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.59430000",
    longitude: "-102.89265000",
  },
  {
    id: 122093,
    name: "Mont Belvieu",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.84772000",
    longitude: "-94.89076000",
  },
  {
    id: 122095,
    name: "Montague",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.66483000",
    longitude: "-97.72059000",
  },
  {
    id: 122099,
    name: "Montague County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.67563000",
    longitude: "-97.72465000",
  },
  {
    id: 122108,
    name: "Monte Alto",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.37312000",
    longitude: "-97.97167000",
  },
  {
    id: 122150,
    name: "Montgomery County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.30021000",
    longitude: "-95.50301000",
  },
  {
    id: 122196,
    name: "Moody",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.30823000",
    longitude: "-97.36140000",
  },
  {
    id: 122203,
    name: "Moore County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "35.83769000",
    longitude: "-101.89292000",
  },
  {
    id: 122249,
    name: "Morgans Point Resort",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.14823000",
    longitude: "-97.46334000",
  },
  {
    id: 122270,
    name: "Morris County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.11348000",
    longitude: "-94.73265000",
  },
  {
    id: 122294,
    name: "Morton",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.72510000",
    longitude: "-102.75938000",
  },
  {
    id: 122311,
    name: "Motley County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.07409000",
    longitude: "-100.77983000",
  },
  {
    id: 122377,
    name: "Mount Pleasant",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.15679000",
    longitude: "-94.96827000",
  },
  {
    id: 122399,
    name: "Mount Vernon",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.18873000",
    longitude: "-95.22133000",
  },
  {
    id: 122447,
    name: "Muenster",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.65177000",
    longitude: "-97.37641000",
  },
  {
    id: 122457,
    name: "Muleshoe",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.22647000",
    longitude: "-102.72383000",
  },
  {
    id: 122467,
    name: "Munday",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.44926000",
    longitude: "-99.62286000",
  },
  {
    id: 122475,
    name: "Muniz",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.25646000",
    longitude: "-98.08945000",
  },
  {
    id: 122483,
    name: "Murillo Colonia",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.25646000",
    longitude: "-98.11334000",
  },
  {
    id: 122486,
    name: "Murphy",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.01512000",
    longitude: "-96.61305000",
  },
  {
    id: 122532,
    name: "Nacogdoches",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.60351000",
    longitude: "-94.65549000",
  },
  {
    id: 122533,
    name: "Nacogdoches County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.61598000",
    longitude: "-94.61587000",
  },
  {
    id: 122553,
    name: "Naples",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.20318000",
    longitude: "-94.68021000",
  },
  {
    id: 122567,
    name: "Nash",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.44235000",
    longitude: "-94.13075000",
  },
  {
    id: 122581,
    name: "Nassau Bay",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.54468000",
    longitude: "-95.09104000",
  },
  {
    id: 122586,
    name: "Natalia",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.18968000",
    longitude: "-98.86253000",
  },
  {
    id: 122601,
    name: "Navarro County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.04693000",
    longitude: "-96.47249000",
  },
  {
    id: 122602,
    name: "Navasota",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.38798000",
    longitude: "-96.08773000",
  },
  {
    id: 122610,
    name: "Nederland",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.97438000",
    longitude: "-93.99240000",
  },
  {
    id: 122615,
    name: "Needville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.39941000",
    longitude: "-95.83773000",
  },
  {
    id: 122649,
    name: "Nevada",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.04234000",
    longitude: "-96.37387000",
  },
  {
    id: 122668,
    name: "New Boston",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.45984000",
    longitude: "-94.41548000",
  },
  {
    id: 122670,
    name: "New Braunfels",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.70300000",
    longitude: "-98.12445000",
  },
  {
    id: 122784,
    name: "New Summerfield",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.98072000",
    longitude: "-95.09383000",
  },
  {
    id: 122786,
    name: "New Territory",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.59412000",
    longitude: "-95.68078000",
  },
  {
    id: 122790,
    name: "New Waverly",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.53770000",
    longitude: "-95.48328000",
  },
  {
    id: 122801,
    name: "Newark",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.00124000",
    longitude: "-97.48447000",
  },
  {
    id: 122862,
    name: "Newton",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.84853000",
    longitude: "-93.75740000",
  },
  {
    id: 122872,
    name: "Newton County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.78631000",
    longitude: "-93.74474000",
  },
  {
    id: 122913,
    name: "Nixon",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.26746000",
    longitude: "-97.76444000",
  },
  {
    id: 122923,
    name: "Nocona",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.78677000",
    longitude: "-97.72586000",
  },
  {
    id: 122931,
    name: "Nolan County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.30349000",
    longitude: "-100.40605000",
  },
  {
    id: 122932,
    name: "Nolanville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.07879000",
    longitude: "-97.60557000",
  },
  {
    id: 122959,
    name: "North Alamo",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.21730000",
    longitude: "-98.12890000",
  },
  {
    id: 123093,
    name: "North Richland Hills",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.83430000",
    longitude: "-97.22890000",
  },
  {
    id: 123143,
    name: "Northcliff",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.62106000",
    longitude: "-98.22473000",
  },
  {
    id: 123144,
    name: "Northcrest",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.63655000",
    longitude: "-97.09972000",
  },
  {
    id: 123157,
    name: "Northlake",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.12734000",
    longitude: "-97.26557000",
  },
  {
    id: 123215,
    name: "Nueces County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "27.73506000",
    longitude: "-97.51632000",
  },
  {
    id: 123218,
    name: "Nurillo",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.26702000",
    longitude: "-98.12140000",
  },
  {
    id: 123231,
    name: "Oak Cliff Place",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.92712000",
    longitude: "-95.62672000",
  },
  {
    id: 123253,
    name: "Oak Leaf",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.52042000",
    longitude: "-96.85472000",
  },
  {
    id: 123259,
    name: "Oak Point",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.19012000",
    longitude: "-96.99167000",
  },
  {
    id: 123263,
    name: "Oak Ridge North",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.16022000",
    longitude: "-95.44438000",
  },
  {
    id: 123264,
    name: "Oak Trail Shores",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.48875000",
    longitude: "-97.83420000",
  },
  {
    id: 123345,
    name: "Ochiltree County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "36.27841000",
    longitude: "-100.81565000",
  },
  {
    id: 123355,
    name: "Odem",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "27.95057000",
    longitude: "-97.58222000",
  },
  {
    id: 123360,
    name: "Odessa",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.84568000",
    longitude: "-102.36764000",
  },
  {
    id: 123426,
    name: "Old River-Winfree",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.86828000",
    longitude: "-94.83270000",
  },
  {
    id: 123433,
    name: "Oldham County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "35.40502000",
    longitude: "-102.60287000",
  },
  {
    id: 123438,
    name: "Olivarez",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.22841000",
    longitude: "-97.99223000",
  },
  {
    id: 123452,
    name: "Olmito",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.02174000",
    longitude: "-97.53415000",
  },
  {
    id: 123453,
    name: "Olmos Park",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.47884000",
    longitude: "-98.48752000",
  },
  {
    id: 123457,
    name: "Olney",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.37066000",
    longitude: "-98.75284000",
  },
  {
    id: 123458,
    name: "Olton",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.18341000",
    longitude: "-102.13463000",
  },
  {
    id: 123467,
    name: "Onalaska",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.80575000",
    longitude: "-95.11633000",
  },
  {
    id: 123481,
    name: "Onion Creek",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.13660000",
    longitude: "-97.78417000",
  },
  {
    id: 123505,
    name: "Orange",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.09299000",
    longitude: "-93.73655000",
  },
  {
    id: 123517,
    name: "Orange County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.12131000",
    longitude: "-93.89390000",
  },
  {
    id: 123523,
    name: "Orange Grove",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "27.95668000",
    longitude: "-97.93694000",
  },
  {
    id: 123544,
    name: "Ore City",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.80014000",
    longitude: "-94.72076000",
  },
  {
    id: 123653,
    name: "Overton",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.27460000",
    longitude: "-94.97855000",
  },
  {
    id: 123657,
    name: "Ovilla",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.52653000",
    longitude: "-96.88639000",
  },
  {
    id: 123695,
    name: "Oyster Creek",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.00302000",
    longitude: "-95.33188000",
  },
  {
    id: 123701,
    name: "Ozona",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.71017000",
    longitude: "-101.20067000",
  },
  {
    id: 123716,
    name: "Paducah",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.01230000",
    longitude: "-100.30206000",
  },
  {
    id: 123726,
    name: "Paint Rock",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.50849000",
    longitude: "-99.92008000",
  },
  {
    id: 123730,
    name: "Palacios",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.70805000",
    longitude: "-96.21747000",
  },
  {
    id: 123737,
    name: "Palestine",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.76212000",
    longitude: "-95.63079000",
  },
  {
    id: 123755,
    name: "Palm Valley",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.20174000",
    longitude: "-97.75416000",
  },
  {
    id: 123758,
    name: "Palmer",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.43125000",
    longitude: "-96.66777000",
  },
  {
    id: 123768,
    name: "Palmhurst",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.25841000",
    longitude: "-98.31807000",
  },
  {
    id: 123770,
    name: "Palmview",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.23341000",
    longitude: "-98.37085000",
  },
  {
    id: 123771,
    name: "Palmview South",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.21563000",
    longitude: "-98.37863000",
  },
  {
    id: 123784,
    name: "Palo Pinto",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.76735000",
    longitude: "-98.29866000",
  },
  {
    id: 123785,
    name: "Palo Pinto County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.75318000",
    longitude: "-98.31302000",
  },
  {
    id: 123786,
    name: "Paloma Creek",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.22530000",
    longitude: "-96.93742000",
  },
  {
    id: 123787,
    name: "Paloma Creek South",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.21136000",
    longitude: "-96.93554000",
  },
  {
    id: 123794,
    name: "Pampa",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "35.53616000",
    longitude: "-100.95987000",
  },
  {
    id: 123801,
    name: "Panhandle",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "35.34560000",
    longitude: "-101.38044000",
  },
  {
    id: 123804,
    name: "Panola County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.16236000",
    longitude: "-94.30565000",
  },
  {
    id: 123806,
    name: "Panorama Village",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.38104000",
    longitude: "-95.49355000",
  },
  {
    id: 123807,
    name: "Pantego",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.71430000",
    longitude: "-97.15640000",
  },
  {
    id: 123834,
    name: "Paris",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.66094000",
    longitude: "-95.55551000",
  },
  {
    id: 123860,
    name: "Parker",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.05540000",
    longitude: "-96.62194000",
  },
  {
    id: 123865,
    name: "Parker County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.77765000",
    longitude: "-97.80510000",
  },
  {
    id: 123886,
    name: "Parmer County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.53010000",
    longitude: "-102.78452000",
  },
  {
    id: 123896,
    name: "Pasadena",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.69106000",
    longitude: "-95.20910000",
  },
  {
    id: 123919,
    name: "Patton Village",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.19299000",
    longitude: "-95.16882000",
  },
  {
    id: 123970,
    name: "Pearland",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.56357000",
    longitude: "-95.28605000",
  },
  {
    id: 123972,
    name: "Pearsall",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.89219000",
    longitude: "-99.09503000",
  },
  {
    id: 123975,
    name: "Pecan Acres",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.97013000",
    longitude: "-97.47474000",
  },
  {
    id: 123976,
    name: "Pecan Grove",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.62607000",
    longitude: "-95.73162000",
  },
  {
    id: 123977,
    name: "Pecan Plantation",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.36042000",
    longitude: "-97.67558000",
  },
  {
    id: 123980,
    name: "Pecos",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.42291000",
    longitude: "-103.49323000",
  },
  {
    id: 123981,
    name: "Pecos County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.78106000",
    longitude: "-102.72357000",
  },
  {
    id: 123995,
    name: "Pelican Bay",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.92096000",
    longitude: "-97.51836000",
  },
  {
    id: 124021,
    name: "Penitas",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.23063000",
    longitude: "-98.44474000",
  },
  {
    id: 124058,
    name: "Perezville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.22452000",
    longitude: "-98.40057000",
  },
  {
    id: 124087,
    name: "Perryton",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "36.40003000",
    longitude: "-100.80265000",
  },
  {
    id: 124109,
    name: "Petersburg",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.86952000",
    longitude: "-101.59739000",
  },
  {
    id: 124117,
    name: "Pflugerville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.43937000",
    longitude: "-97.62000000",
  },
  {
    id: 124118,
    name: "Pharr",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.19480000",
    longitude: "-98.18362000",
  },
  {
    id: 124201,
    name: "Pilot Point",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.39650000",
    longitude: "-96.96056000",
  },
  {
    id: 124227,
    name: "Pine Island",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.05800000",
    longitude: "-96.03746000",
  },
  {
    id: 124251,
    name: "Pinehurst",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.17105000",
    longitude: "-95.68245000",
  },
  {
    id: 124265,
    name: "Pinewood Estates",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.16438000",
    longitude: "-94.32158000",
  },
  {
    id: 124268,
    name: "Piney Point Village",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.75995000",
    longitude: "-95.51716000",
  },
  {
    id: 124293,
    name: "Pittsburg",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.99540000",
    longitude: "-94.96577000",
  },
  {
    id: 124324,
    name: "Plains",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.18871000",
    longitude: "-102.82799000",
  },
  {
    id: 124331,
    name: "Plainview",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.18479000",
    longitude: "-101.70684000",
  },
  {
    id: 124343,
    name: "Plano",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.01984000",
    longitude: "-96.69889000",
  },
  {
    id: 124363,
    name: "Pleak",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.48913000",
    longitude: "-95.80773000",
  },
  {
    id: 124383,
    name: "Pleasanton",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.96719000",
    longitude: "-98.47863000",
  },
  {
    id: 124394,
    name: "Plum Grove",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.19570000",
    longitude: "-96.98945000",
  },
  {
    id: 124446,
    name: "Polk County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.79272000",
    longitude: "-94.83002000",
  },
  {
    id: 124467,
    name: "Ponder",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.18290000",
    longitude: "-97.28724000",
  },
  {
    id: 124495,
    name: "Port Aransas",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "27.83392000",
    longitude: "-97.06110000",
  },
  {
    id: 124496,
    name: "Port Arthur",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.88519000",
    longitude: "-93.94233000",
  },
  {
    id: 124512,
    name: "Port Isabel",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.07341000",
    longitude: "-97.20858000",
  },
  {
    id: 124517,
    name: "Port Lavaca",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.61500000",
    longitude: "-96.62609000",
  },
  {
    id: 124521,
    name: "Port Neches",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.99132000",
    longitude: "-93.95851000",
  },
  {
    id: 124523,
    name: "Port O'Connor",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.44834000",
    longitude: "-96.40581000",
  },
  {
    id: 124554,
    name: "Porter Heights",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.15188000",
    longitude: "-95.32188000",
  },
  {
    id: 124557,
    name: "Portland",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "27.87725000",
    longitude: "-97.32388000",
  },
  {
    id: 124576,
    name: "Post",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.19122000",
    longitude: "-101.37900000",
  },
  {
    id: 124580,
    name: "Poteet",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.04052000",
    longitude: "-98.56807000",
  },
  {
    id: 124581,
    name: "Poth",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.06969000",
    longitude: "-98.08195000",
  },
  {
    id: 124587,
    name: "Potosi",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.32930000",
    longitude: "-99.65647000",
  },
  {
    id: 124594,
    name: "Potter County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "35.40127000",
    longitude: "-101.89400000",
  },
  {
    id: 124597,
    name: "Pottsboro",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.75927000",
    longitude: "-96.66944000",
  },
  {
    id: 124609,
    name: "Powderly",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.81122000",
    longitude: "-95.52440000",
  },
  {
    id: 124631,
    name: "Prairie View",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.09327000",
    longitude: "-95.98773000",
  },
  {
    id: 124639,
    name: "Premont",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "27.36059000",
    longitude: "-98.12362000",
  },
  {
    id: 124647,
    name: "Presidio",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.56074000",
    longitude: "-104.37215000",
  },
  {
    id: 124648,
    name: "Presidio County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.99980000",
    longitude: "-104.24052000",
  },
  {
    id: 124654,
    name: "Preston",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.88232000",
    longitude: "-96.63305000",
  },
  {
    id: 124670,
    name: "Primera",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.22591000",
    longitude: "-97.75805000",
  },
  {
    id: 124684,
    name: "Princeton",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.18012000",
    longitude: "-96.49804000",
  },
  {
    id: 124701,
    name: "Progreso",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.09230000",
    longitude: "-97.95722000",
  },
  {
    id: 124711,
    name: "Prosper",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.23623000",
    longitude: "-96.80111000",
  },
  {
    id: 124718,
    name: "Providence",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.23340000",
    longitude: "-96.96158000",
  },
  {
    id: 124775,
    name: "Quail Creek",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.77640000",
    longitude: "-97.08232000",
  },
  {
    id: 124779,
    name: "Quanah",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.29785000",
    longitude: "-99.74037000",
  },
  {
    id: 124787,
    name: "Queen City",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.14874000",
    longitude: "-94.15019000",
  },
  {
    id: 124802,
    name: "Quinlan",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.91040000",
    longitude: "-96.13553000",
  },
  {
    id: 124807,
    name: "Quitman",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.79596000",
    longitude: "-95.45106000",
  },
  {
    id: 124828,
    name: "Rains County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.87034000",
    longitude: "-95.79338000",
  },
  {
    id: 124834,
    name: "Ralls",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.67425000",
    longitude: "-101.38766000",
  },
  {
    id: 124847,
    name: "Rancho Alegre",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "27.74169000",
    longitude: "-98.09473000",
  },
  {
    id: 124859,
    name: "Rancho Viejo",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.03952000",
    longitude: "-97.55638000",
  },
  {
    id: 124862,
    name: "Randall County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.96585000",
    longitude: "-101.89695000",
  },
  {
    id: 124882,
    name: "Ranger",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.46985000",
    longitude: "-98.67895000",
  },
  {
    id: 124884,
    name: "Rankin",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.22265000",
    longitude: "-101.93791000",
  },
  {
    id: 124887,
    name: "Ransom Canyon",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.53342000",
    longitude: "-101.67961000",
  },
  {
    id: 124921,
    name: "Raymondville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.48146000",
    longitude: "-97.78305000",
  },
  {
    id: 124932,
    name: "Reagan County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.36619000",
    longitude: "-101.52301000",
  },
  {
    id: 124933,
    name: "Real County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.83174000",
    longitude: "-99.82217000",
  },
  {
    id: 124952,
    name: "Red Lick",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.46485000",
    longitude: "-94.17103000",
  },
  {
    id: 124956,
    name: "Red Oak",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.51764000",
    longitude: "-96.80444000",
  },
  {
    id: 124959,
    name: "Red River County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.62068000",
    longitude: "-95.05036000",
  },
  {
    id: 124975,
    name: "Redland",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.40435000",
    longitude: "-94.72132000",
  },
  {
    id: 124982,
    name: "Redwater",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.35818000",
    longitude: "-94.25436000",
  },
  {
    id: 124984,
    name: "Redwood",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.81022000",
    longitude: "-97.91139000",
  },
  {
    id: 124998,
    name: "Reeves County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.32318000",
    longitude: "-103.69317000",
  },
  {
    id: 125000,
    name: "Refugio",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.30528000",
    longitude: "-97.27527000",
  },
  {
    id: 125001,
    name: "Refugio County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.32158000",
    longitude: "-97.15952000",
  },
  {
    id: 125017,
    name: "Rendon",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.57625000",
    longitude: "-97.24140000",
  },
  {
    id: 125019,
    name: "Reno",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.66316000",
    longitude: "-95.46245000",
  },
  {
    id: 125048,
    name: "Rhome",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.05346000",
    longitude: "-97.47197000",
  },
  {
    id: 125051,
    name: "Ricardo",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "27.42143000",
    longitude: "-97.85111000",
  },
  {
    id: 125058,
    name: "Richardson",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.94818000",
    longitude: "-96.72972000",
  },
  {
    id: 125079,
    name: "Richland Hills",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.81596000",
    longitude: "-97.22807000",
  },
  {
    id: 125087,
    name: "Richmond",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.58218000",
    longitude: "-95.76078000",
  },
  {
    id: 125111,
    name: "Richwood",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.05608000",
    longitude: "-95.40994000",
  },
  {
    id: 125135,
    name: "Riesel",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.47489000",
    longitude: "-96.92333000",
  },
  {
    id: 125149,
    name: "Rio Bravo",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "27.36419000",
    longitude: "-99.48004000",
  },
  {
    id: 125154,
    name: "Rio Grande City",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.37979000",
    longitude: "-98.82030000",
  },
  {
    id: 125156,
    name: "Rio Hondo",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.23535000",
    longitude: "-97.58193000",
  },
  {
    id: 125183,
    name: "River Oaks",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.77707000",
    longitude: "-97.39446000",
  },
  {
    id: 125227,
    name: "Roanoke",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.00401000",
    longitude: "-97.22585000",
  },
  {
    id: 125240,
    name: "Robert Lee",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.89237000",
    longitude: "-100.48482000",
  },
  {
    id: 125243,
    name: "Roberts County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "35.83849000",
    longitude: "-100.81344000",
  },
  {
    id: 125247,
    name: "Robertson County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.02704000",
    longitude: "-96.51279000",
  },
  {
    id: 125254,
    name: "Robinson",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.46767000",
    longitude: "-97.11472000",
  },
  {
    id: 125256,
    name: "Robstown",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "27.79030000",
    longitude: "-97.66888000",
  },
  {
    id: 125257,
    name: "Roby",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.74483000",
    longitude: "-100.37761000",
  },
  {
    id: 125295,
    name: "Rockdale",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.65548000",
    longitude: "-97.00137000",
  },
  {
    id: 125316,
    name: "Rockport",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.02077000",
    longitude: "-97.05601000",
  },
  {
    id: 125319,
    name: "Rocksprings",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.01576000",
    longitude: "-100.20536000",
  },
  {
    id: 125326,
    name: "Rockwall",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.93123000",
    longitude: "-96.45971000",
  },
  {
    id: 125327,
    name: "Rockwall County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.89773000",
    longitude: "-96.40777000",
  },
  {
    id: 125349,
    name: "Rogers",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.93157000",
    longitude: "-97.22666000",
  },
  {
    id: 125369,
    name: "Rollingwood",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.27687000",
    longitude: "-97.79112000",
  },
  {
    id: 125372,
    name: "Roma",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.40526000",
    longitude: "-99.01581000",
  },
  {
    id: 125373,
    name: "Roma-Los Saenz",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.40506000",
    longitude: "-99.01586000",
  },
  {
    id: 125374,
    name: "Roman Forest",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.17910000",
    longitude: "-95.16243000",
  },
  {
    id: 125400,
    name: "Roscoe",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.44595000",
    longitude: "-100.53872000",
  },
  {
    id: 125411,
    name: "Rosebud",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.07296000",
    longitude: "-96.97860000",
  },
  {
    id: 125433,
    name: "Rosenberg",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.55718000",
    longitude: "-95.80856000",
  },
  {
    id: 125443,
    name: "Rosharon",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.35218000",
    longitude: "-95.46022000",
  },
  {
    id: 125445,
    name: "Rosita North",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.65644000",
    longitude: "-100.42218000",
  },
  {
    id: 125446,
    name: "Rosita South",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.62375000",
    longitude: "-100.42837000",
  },
  {
    id: 125464,
    name: "Rotan",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.85205000",
    longitude: "-100.46566000",
  },
  {
    id: 125473,
    name: "Round Rock",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.50826000",
    longitude: "-97.67890000",
  },
  {
    id: 125481,
    name: "Rowlett",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.90290000",
    longitude: "-96.56388000",
  },
  {
    id: 125499,
    name: "Royse City",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.97512000",
    longitude: "-96.33248000",
  },
  {
    id: 125510,
    name: "Runaway Bay",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.16789000",
    longitude: "-97.87837000",
  },
  {
    id: 125511,
    name: "Runge",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.88331000",
    longitude: "-97.71305000",
  },
  {
    id: 125512,
    name: "Runnels County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.83110000",
    longitude: "-99.97623000",
  },
  {
    id: 125528,
    name: "Rusk",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.79601000",
    longitude: "-95.15022000",
  },
  {
    id: 125529,
    name: "Rusk County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.10772000",
    longitude: "-94.76188000",
  },
  {
    id: 125568,
    name: "Sabinal",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.31746000",
    longitude: "-99.46644000",
  },
  {
    id: 125569,
    name: "Sabine County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.34320000",
    longitude: "-93.85180000",
  },
  {
    id: 125574,
    name: "Sachse",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.97623000",
    longitude: "-96.59527000",
  },
  {
    id: 125587,
    name: "Saginaw",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.86013000",
    longitude: "-97.36391000",
  },
  {
    id: 125645,
    name: "Saint Hedwig",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.41440000",
    longitude: "-98.20001000",
  },
  {
    id: 125659,
    name: "Saint Jo",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.69483000",
    longitude: "-97.52252000",
  },
  {
    id: 125702,
    name: "Saint Paul",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.04123000",
    longitude: "-96.55027000",
  },
  {
    id: 125722,
    name: "Salado",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.94713000",
    longitude: "-97.53862000",
  },
  {
    id: 125777,
    name: "Sam Rayburn",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.06408000",
    longitude: "-94.03575000",
  },
  {
    id: 125784,
    name: "San Angelo",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.46377000",
    longitude: "-100.43704000",
  },
  {
    id: 125787,
    name: "San Antonio",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.42412000",
    longitude: "-98.49363000",
  },
  {
    id: 125789,
    name: "San Augustine",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.52990000",
    longitude: "-94.10603000",
  },
  {
    id: 125790,
    name: "San Augustine County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.39422000",
    longitude: "-94.16819000",
  },
  {
    id: 125791,
    name: "San Benito",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.13258000",
    longitude: "-97.63110000",
  },
  {
    id: 125796,
    name: "San Carlos",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.29563000",
    longitude: "-98.07195000",
  },
  {
    id: 125801,
    name: "San Diego",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "27.76391000",
    longitude: "-98.23890000",
  },
  {
    id: 125806,
    name: "San Elizario",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.58511000",
    longitude: "-106.27276000",
  },
  {
    id: 125812,
    name: "San Jacinto County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.57953000",
    longitude: "-95.16690000",
  },
  {
    id: 125817,
    name: "San Juan",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.18924000",
    longitude: "-98.15529000",
  },
  {
    id: 125825,
    name: "San Leon",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.48329000",
    longitude: "-94.92215000",
  },
  {
    id: 125832,
    name: "San Marcos",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.88327000",
    longitude: "-97.94139000",
  },
  {
    id: 125844,
    name: "San Patricio County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.00878000",
    longitude: "-97.51827000",
  },
  {
    id: 125848,
    name: "San Saba",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.19572000",
    longitude: "-98.71810000",
  },
  {
    id: 125849,
    name: "San Saba County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.15520000",
    longitude: "-98.81758000",
  },
  {
    id: 125864,
    name: "Sanderson",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.14241000",
    longitude: "-102.39403000",
  },
  {
    id: 125891,
    name: "Sanger",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.36317000",
    longitude: "-97.17390000",
  },
  {
    id: 125898,
    name: "Sansom Park",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.80596000",
    longitude: "-97.40307000",
  },
  {
    id: 125900,
    name: "Santa Anna",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.74209000",
    longitude: "-99.32173000",
  },
  {
    id: 125913,
    name: "Santa Fe",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.37801000",
    longitude: "-95.10576000",
  },
  {
    id: 125921,
    name: "Santa Rosa",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.25674000",
    longitude: "-97.82500000",
  },
  {
    id: 125951,
    name: "Sarita",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "27.22171000",
    longitude: "-97.78916000",
  },
  {
    id: 125977,
    name: "Savannah",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.22603000",
    longitude: "-96.90786000",
  },
  {
    id: 126000,
    name: "Scenic Oaks",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.71078000",
    longitude: "-98.67586000",
  },
  {
    id: 126006,
    name: "Schertz",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.55217000",
    longitude: "-98.26973000",
  },
  {
    id: 126008,
    name: "Schleicher County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.89745000",
    longitude: "-100.53855000",
  },
  {
    id: 126020,
    name: "Schulenburg",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.68190000",
    longitude: "-96.90304000",
  },
  {
    id: 126029,
    name: "Scissors",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.13980000",
    longitude: "-98.05389000",
  },
  {
    id: 126069,
    name: "Scurry County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.74632000",
    longitude: "-100.91641000",
  },
  {
    id: 126079,
    name: "Seabrook",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.56412000",
    longitude: "-95.02548000",
  },
  {
    id: 126084,
    name: "Seadrift",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.41528000",
    longitude: "-96.71359000",
  },
  {
    id: 126088,
    name: "Seagoville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.63958000",
    longitude: "-96.53832000",
  },
  {
    id: 126089,
    name: "Seagraves",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.94427000",
    longitude: "-102.56491000",
  },
  {
    id: 126091,
    name: "Sealy",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.78079000",
    longitude: "-96.15718000",
  },
  {
    id: 126106,
    name: "Sebastian",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.34285000",
    longitude: "-97.79027000",
  },
  {
    id: 126128,
    name: "Seguin",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.56884000",
    longitude: "-97.96473000",
  },
  {
    id: 126140,
    name: "Selma",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.58439000",
    longitude: "-98.30585000",
  },
  {
    id: 126146,
    name: "Seminole",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.71899000",
    longitude: "-102.64491000",
  },
  {
    id: 126166,
    name: "Serenada",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.69936000",
    longitude: "-97.69195000",
  },
  {
    id: 126170,
    name: "Seth Ward",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.21174000",
    longitude: "-101.69017000",
  },
  {
    id: 126175,
    name: "Seven Points",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.32042000",
    longitude: "-96.21303000",
  },
  {
    id: 126195,
    name: "Seymour",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.59426000",
    longitude: "-99.26035000",
  },
  {
    id: 126199,
    name: "Shackelford County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.73597000",
    longitude: "-99.35407000",
  },
  {
    id: 126205,
    name: "Shady Hollow",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.16493000",
    longitude: "-97.86223000",
  },
  {
    id: 126206,
    name: "Shady Shores",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.16512000",
    longitude: "-97.02945000",
  },
  {
    id: 126213,
    name: "Shallowater",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.68897000",
    longitude: "-101.99823000",
  },
  {
    id: 126216,
    name: "Shamrock",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "35.21422000",
    longitude: "-100.24901000",
  },
  {
    id: 126242,
    name: "Shavano Park",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.58495000",
    longitude: "-98.55252000",
  },
  {
    id: 126278,
    name: "Shelby County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.79241000",
    longitude: "-94.14502000",
  },
  {
    id: 126285,
    name: "Sheldon",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.86800000",
    longitude: "-95.12826000",
  },
  {
    id: 126301,
    name: "Shenandoah",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.18022000",
    longitude: "-95.45577000",
  },
  {
    id: 126309,
    name: "Shepherd",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.49798000",
    longitude: "-94.99660000",
  },
  {
    id: 126330,
    name: "Sherman",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.63566000",
    longitude: "-96.60888000",
  },
  {
    id: 126334,
    name: "Sherman County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "36.27771000",
    longitude: "-101.89348000",
  },
  {
    id: 126344,
    name: "Sherwood Shores",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.85232000",
    longitude: "-96.81778000",
  },
  {
    id: 126350,
    name: "Shiner",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.42913000",
    longitude: "-97.17054000",
  },
  {
    id: 126367,
    name: "Shoreacres",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.62023000",
    longitude: "-95.00993000",
  },
  {
    id: 126400,
    name: "Sienna Plantation",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.48607000",
    longitude: "-95.50800000",
  },
  {
    id: 126401,
    name: "Sierra Blanca",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.17457000",
    longitude: "-105.35718000",
  },
  {
    id: 126408,
    name: "Siesta Acres",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.75799000",
    longitude: "-100.49019000",
  },
  {
    id: 126410,
    name: "Siesta Shores",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.85810000",
    longitude: "-99.25365000",
  },
  {
    id: 126418,
    name: "Silsbee",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.34910000",
    longitude: "-94.17796000",
  },
  {
    id: 126443,
    name: "Silverton",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.47423000",
    longitude: "-101.30461000",
  },
  {
    id: 126456,
    name: "Sinton",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.03668000",
    longitude: "-97.50916000",
  },
  {
    id: 126493,
    name: "Slaton",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.43731000",
    longitude: "-101.64349000",
  },
  {
    id: 126515,
    name: "Smith County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.37504000",
    longitude: "-95.26918000",
  },
  {
    id: 126529,
    name: "Smithville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.00855000",
    longitude: "-97.15943000",
  },
  {
    id: 126550,
    name: "Snyder",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.71789000",
    longitude: "-100.91762000",
  },
  {
    id: 126558,
    name: "Socorro",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.65456000",
    longitude: "-106.30331000",
  },
  {
    id: 126560,
    name: "Socorro Mission Number 1 Colonia",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.63622000",
    longitude: "-106.29054000",
  },
  {
    id: 126581,
    name: "Somerset",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.22635000",
    longitude: "-98.65780000",
  },
  {
    id: 126592,
    name: "Somervell County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.22229000",
    longitude: "-97.77434000",
  },
  {
    id: 126594,
    name: "Somerville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.34604000",
    longitude: "-96.52830000",
  },
  {
    id: 126601,
    name: "Sonora",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.56685000",
    longitude: "-100.64343000",
  },
  {
    id: 126610,
    name: "Sour Lake",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.14021000",
    longitude: "-94.41102000",
  },
  {
    id: 126611,
    name: "South Alamo",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.15702000",
    longitude: "-98.10862000",
  },
  {
    id: 126676,
    name: "South Houston",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.66301000",
    longitude: "-95.23549000",
  },
  {
    id: 126700,
    name: "South Padre Island",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.10369000",
    longitude: "-97.16469000",
  },
  {
    id: 126712,
    name: "South Point",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "25.86869000",
    longitude: "-97.38359000",
  },
  {
    id: 126777,
    name: "Southlake",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.94124000",
    longitude: "-97.13418000",
  },
  {
    id: 126778,
    name: "Southmayd",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.63038000",
    longitude: "-96.76916000",
  },
  {
    id: 126788,
    name: "Southside Place",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.70606000",
    longitude: "-95.43688000",
  },
  {
    id: 126804,
    name: "Sparks",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.67261000",
    longitude: "-106.23970000",
  },
  {
    id: 126817,
    name: "Spearman",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "36.19837000",
    longitude: "-101.19238000",
  },
  {
    id: 126841,
    name: "Splendora",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.23299000",
    longitude: "-95.16104000",
  },
  {
    id: 126849,
    name: "Spring",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.07994000",
    longitude: "-95.41716000",
  },
  {
    id: 126874,
    name: "Spring Valley",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.78967000",
    longitude: "-95.50355000",
  },
  {
    id: 126911,
    name: "Springtown",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.96596000",
    longitude: "-97.68364000",
  },
  {
    id: 126921,
    name: "Spur",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.47648000",
    longitude: "-100.85569000",
  },
  {
    id: 126932,
    name: "Stafford",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.61607000",
    longitude: "-95.55772000",
  },
  {
    id: 126943,
    name: "Stamford",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.94539000",
    longitude: "-99.80287000",
  },
  {
    id: 126971,
    name: "Stanton",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.12929000",
    longitude: "-101.78846000",
  },
  {
    id: 126990,
    name: "Starr County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.56215000",
    longitude: "-98.73840000",
  },
  {
    id: 127023,
    name: "Stephens County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.73586000",
    longitude: "-98.83617000",
  },
  {
    id: 127025,
    name: "Stephenville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.22070000",
    longitude: "-98.20226000",
  },
  {
    id: 127032,
    name: "Sterling City",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.83625000",
    longitude: "-100.98483000",
  },
  {
    id: 127033,
    name: "Sterling County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.82788000",
    longitude: "-101.05001000",
  },
  {
    id: 127065,
    name: "Stinnett",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "35.82698000",
    longitude: "-101.44294000",
  },
  {
    id: 127070,
    name: "Stockdale",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.23691000",
    longitude: "-97.96000000",
  },
  {
    id: 127093,
    name: "Stonewall County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.17912000",
    longitude: "-100.25331000",
  },
  {
    id: 127115,
    name: "Stowell",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.78994000",
    longitude: "-94.38324000",
  },
  {
    id: 127128,
    name: "Stratford",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "36.33614000",
    longitude: "-102.07212000",
  },
  {
    id: 127173,
    name: "Sugar Land",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.61968000",
    longitude: "-95.63495000",
  },
  {
    id: 127185,
    name: "Sullivan City",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.27757000",
    longitude: "-98.56363000",
  },
  {
    id: 127197,
    name: "Sulphur Springs",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.13845000",
    longitude: "-95.60107000",
  },
  {
    id: 127250,
    name: "Sundown",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.45621000",
    longitude: "-102.48936000",
  },
  {
    id: 127264,
    name: "Sunnyvale",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.79652000",
    longitude: "-96.56082000",
  },
  {
    id: 127266,
    name: "Sunray",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "36.01670000",
    longitude: "-101.82462000",
  },
  {
    id: 127315,
    name: "Sutton County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.49829000",
    longitude: "-100.53819000",
  },
  {
    id: 127335,
    name: "Sweeny",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.03886000",
    longitude: "-95.69856000",
  },
  {
    id: 127342,
    name: "Sweetwater",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.47095000",
    longitude: "-100.40594000",
  },
  {
    id: 127347,
    name: "Swisher County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.53039000",
    longitude: "-101.73503000",
  },
  {
    id: 127372,
    name: "Taft",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "27.97891000",
    longitude: "-97.39860000",
  },
  {
    id: 127376,
    name: "Taft Southwest (historical)",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "27.97391000",
    longitude: "-97.40305000",
  },
  {
    id: 127379,
    name: "Tahoka",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.16676000",
    longitude: "-101.79377000",
  },
  {
    id: 127399,
    name: "Talty",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.68319000",
    longitude: "-96.38554000",
  },
  {
    id: 127435,
    name: "Tarrant County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.77156000",
    longitude: "-97.29124000",
  },
  {
    id: 127440,
    name: "Tatum",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.31599000",
    longitude: "-94.51659000",
  },
  {
    id: 127446,
    name: "Taylor",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.57076000",
    longitude: "-97.40944000",
  },
  {
    id: 127454,
    name: "Taylor County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.30147000",
    longitude: "-99.89012000",
  },
  {
    id: 127459,
    name: "Taylor Lake Village",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.57523000",
    longitude: "-95.05021000",
  },
  {
    id: 127474,
    name: "Teague",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.62711000",
    longitude: "-96.28386000",
  },
  {
    id: 127498,
    name: "Temple",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.09823000",
    longitude: "-97.34278000",
  },
  {
    id: 127508,
    name: "Tenaha",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.94378000",
    longitude: "-94.24409000",
  },
  {
    id: 127526,
    name: "Terrell",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.73596000",
    longitude: "-96.27526000",
  },
  {
    id: 127528,
    name: "Terrell County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.22496000",
    longitude: "-102.07649000",
  },
  {
    id: 127529,
    name: "Terrell Hills",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.47495000",
    longitude: "-98.45085000",
  },
  {
    id: 127532,
    name: "Terry County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.17381000",
    longitude: "-102.33520000",
  },
  {
    id: 127545,
    name: "Texarkana",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.42513000",
    longitude: "-94.04769000",
  },
  {
    id: 127546,
    name: "Texas City",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.38385000",
    longitude: "-94.90270000",
  },
  {
    id: 127555,
    name: "The Colony",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.08901000",
    longitude: "-96.88639000",
  },
  {
    id: 127561,
    name: "The Hills",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.34798000",
    longitude: "-97.98501000",
  },
  {
    id: 127566,
    name: "The Woodlands",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.15799000",
    longitude: "-95.48938000",
  },
  {
    id: 127600,
    name: "Thorndale",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.61381000",
    longitude: "-97.20555000",
  },
  {
    id: 127617,
    name: "Three Rivers",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.46027000",
    longitude: "-98.18251000",
  },
  {
    id: 127623,
    name: "Throckmorton",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.17872000",
    longitude: "-99.17758000",
  },
  {
    id: 127624,
    name: "Throckmorton County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.17749000",
    longitude: "-99.21237000",
  },
  {
    id: 127646,
    name: "Tiki Island",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.29709000",
    longitude: "-94.91709000",
  },
  {
    id: 127647,
    name: "Tilden",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.46194000",
    longitude: "-98.54918000",
  },
  {
    id: 127661,
    name: "Timberwood Park",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.70578000",
    longitude: "-98.47835000",
  },
  {
    id: 127664,
    name: "Timpson",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.90378000",
    longitude: "-94.39520000",
  },
  {
    id: 127684,
    name: "Titus County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.21660000",
    longitude: "-94.96567000",
  },
  {
    id: 127706,
    name: "Tom Bean",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.52011000",
    longitude: "-96.48387000",
  },
  {
    id: 127707,
    name: "Tom Green County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.40440000",
    longitude: "-100.46207000",
  },
  {
    id: 127710,
    name: "Tomball",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.09716000",
    longitude: "-95.61605000",
  },
  {
    id: 127727,
    name: "Tool",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.26792000",
    longitude: "-96.17025000",
  },
  {
    id: 127740,
    name: "Tornillo",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.44540000",
    longitude: "-106.08831000",
  },
  {
    id: 127787,
    name: "Travis County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.33469000",
    longitude: "-97.78195000",
  },
  {
    id: 127788,
    name: "Travis Ranch",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.80356000",
    longitude: "-96.47347000",
  },
  {
    id: 127827,
    name: "Trinity",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.94519000",
    longitude: "-95.37550000",
  },
  {
    id: 127828,
    name: "Trinity County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.08882000",
    longitude: "-95.13551000",
  },
  {
    id: 127834,
    name: "Trophy Club",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.99790000",
    longitude: "-97.18362000",
  },
  {
    id: 127835,
    name: "Troup",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.14460000",
    longitude: "-95.12050000",
  },
  {
    id: 127846,
    name: "Troy",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.20684000",
    longitude: "-97.30278000",
  },
  {
    id: 127885,
    name: "Tulia",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.53589000",
    longitude: "-101.75852000",
  },
  {
    id: 127938,
    name: "Tye",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.45762000",
    longitude: "-99.87148000",
  },
  {
    id: 127940,
    name: "Tyler",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.35126000",
    longitude: "-95.30106000",
  },
  {
    id: 127942,
    name: "Tyler County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.77123000",
    longitude: "-94.37659000",
  },
  {
    id: 127954,
    name: "Uhland",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.95772000",
    longitude: "-97.78611000",
  },
  {
    id: 128022,
    name: "Universal City",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.54801000",
    longitude: "-98.29112000",
  },
  {
    id: 128033,
    name: "University Park",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.85013000",
    longitude: "-96.80028000",
  },
  {
    id: 128052,
    name: "Upshur County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.73628000",
    longitude: "-94.94148000",
  },
  {
    id: 128057,
    name: "Upton County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.36873000",
    longitude: "-102.04304000",
  },
  {
    id: 128068,
    name: "Uvalde",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.20968000",
    longitude: "-99.78617000",
  },
  {
    id: 128069,
    name: "Uvalde County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.35734000",
    longitude: "-99.76221000",
  },
  {
    id: 128070,
    name: "Uvalde Estates",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.16524000",
    longitude: "-99.83228000",
  },
  {
    id: 128079,
    name: "Val Verde County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.89296000",
    longitude: "-101.15172000",
  },
  {
    id: 128080,
    name: "Val Verde Park",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.37495000",
    longitude: "-100.83176000",
  },
  {
    id: 128113,
    name: "Valley Mills",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.65933000",
    longitude: "-97.47224000",
  },
  {
    id: 128124,
    name: "Van",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.52486000",
    longitude: "-95.63718000",
  },
  {
    id: 128125,
    name: "Van Alstyne",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.42150000",
    longitude: "-96.57721000",
  },
  {
    id: 128133,
    name: "Van Horn",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.04029000",
    longitude: "-104.83073000",
  },
  {
    id: 128138,
    name: "Van Vleck",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.01775000",
    longitude: "-95.88940000",
  },
  {
    id: 128139,
    name: "Van Zandt County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.56372000",
    longitude: "-95.83651000",
  },
  {
    id: 128162,
    name: "Vega",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "35.24283000",
    longitude: "-102.42826000",
  },
  {
    id: 128177,
    name: "Venus",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.43347000",
    longitude: "-97.10251000",
  },
  {
    id: 128189,
    name: "Vernon",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.15536000",
    longitude: "-99.26628000",
  },
  {
    id: 128220,
    name: "Victoria",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.80527000",
    longitude: "-97.00360000",
  },
  {
    id: 128223,
    name: "Victoria County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.79635000",
    longitude: "-96.97153000",
  },
  {
    id: 128229,
    name: "Vidor",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.13160000",
    longitude: "-94.01545000",
  },
  {
    id: 128282,
    name: "Vinton",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.95121000",
    longitude: "-106.60249000",
  },
  {
    id: 128304,
    name: "Von Ormy",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.28913000",
    longitude: "-98.64446000",
  },
  {
    id: 128316,
    name: "Waco",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.54933000",
    longitude: "-97.14667000",
  },
  {
    id: 128358,
    name: "Wake Village",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.42679000",
    longitude: "-94.10630000",
  },
  {
    id: 128388,
    name: "Walker County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.73905000",
    longitude: "-95.57228000",
  },
  {
    id: 128403,
    name: "Waller",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.05661000",
    longitude: "-95.92690000",
  },
  {
    id: 128405,
    name: "Waller County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.01081000",
    longitude: "-95.98765000",
  },
  {
    id: 128409,
    name: "Wallis",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.63135000",
    longitude: "-96.06524000",
  },
  {
    id: 128458,
    name: "Ward County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.50947000",
    longitude: "-103.10243000",
  },
  {
    id: 128566,
    name: "Washington County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.21453000",
    longitude: "-96.40344000",
  },
  {
    id: 128594,
    name: "Waskom",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.47876000",
    longitude: "-94.05963000",
  },
  {
    id: 128595,
    name: "Watauga",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.85791000",
    longitude: "-97.25474000",
  },
  {
    id: 128674,
    name: "Waxahachie",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.38653000",
    longitude: "-96.84833000",
  },
  {
    id: 128718,
    name: "Weatherford",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.75930000",
    longitude: "-97.79725000",
  },
  {
    id: 128726,
    name: "Webb County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "27.76106000",
    longitude: "-99.33157000",
  },
  {
    id: 128730,
    name: "Webster",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.53773000",
    longitude: "-95.11826000",
  },
  {
    id: 128758,
    name: "Weimar",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.70301000",
    longitude: "-96.78053000",
  },
  {
    id: 128780,
    name: "Wellington",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.85617000",
    longitude: "-100.21373000",
  },
  {
    id: 128785,
    name: "Wells Branch",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.44604000",
    longitude: "-97.67945000",
  },
  {
    id: 128808,
    name: "Weslaco",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.15952000",
    longitude: "-97.99084000",
  },
  {
    id: 128815,
    name: "West",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.80238000",
    longitude: "-97.09167000",
  },
  {
    id: 128849,
    name: "West Columbia",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.14386000",
    longitude: "-95.64522000",
  },
  {
    id: 128909,
    name: "West Lake Hills",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.29798000",
    longitude: "-97.80195000",
  },
  {
    id: 128921,
    name: "West Livingston",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.69825000",
    longitude: "-95.00188000",
  },
  {
    id: 128944,
    name: "West Odessa",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.84235000",
    longitude: "-102.49876000",
  },
  {
    id: 128945,
    name: "West Orange",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.08215000",
    longitude: "-93.75822000",
  },
  {
    id: 128980,
    name: "West Sharyland",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.27257000",
    longitude: "-98.32863000",
  },
  {
    id: 128988,
    name: "West Tawakoni",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.89373000",
    longitude: "-96.02941000",
  },
  {
    id: 128995,
    name: "West University Place",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.71801000",
    longitude: "-95.43383000",
  },
  {
    id: 129024,
    name: "Western Lake",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.62338000",
    longitude: "-97.81155000",
  },
  {
    id: 129039,
    name: "Westlake",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.99124000",
    longitude: "-97.19501000",
  },
  {
    id: 129065,
    name: "Weston Lakes",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.68324000",
    longitude: "-95.93571000",
  },
  {
    id: 129080,
    name: "Westway",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.95871000",
    longitude: "-106.57805000",
  },
  {
    id: 129089,
    name: "Westworth",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.75735000",
    longitude: "-97.41085000",
  },
  {
    id: 129102,
    name: "Wharton",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.31164000",
    longitude: "-96.10274000",
  },
  {
    id: 129104,
    name: "Wharton County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.27786000",
    longitude: "-96.22210000",
  },
  {
    id: 129115,
    name: "Wheeler",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "35.44533000",
    longitude: "-100.27096000",
  },
  {
    id: 129118,
    name: "Wheeler County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "35.40128000",
    longitude: "-100.26965000",
  },
  {
    id: 129149,
    name: "White Oak",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.52792000",
    longitude: "-94.86133000",
  },
  {
    id: 129161,
    name: "White Settlement",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.75957000",
    longitude: "-97.45835000",
  },
  {
    id: 129174,
    name: "Whitehouse",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.22682000",
    longitude: "-95.22550000",
  },
  {
    id: 129182,
    name: "Whitesboro",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.65622000",
    longitude: "-96.90695000",
  },
  {
    id: 129192,
    name: "Whitewright",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.51289000",
    longitude: "-96.39248000",
  },
  {
    id: 129208,
    name: "Whitney",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.95182000",
    longitude: "-97.32140000",
  },
  {
    id: 129216,
    name: "Wichita County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.98798000",
    longitude: "-98.70361000",
  },
  {
    id: 129218,
    name: "Wichita Falls",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.91371000",
    longitude: "-98.49339000",
  },
  {
    id: 129224,
    name: "Wilbarger County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "34.08072000",
    longitude: "-99.24108000",
  },
  {
    id: 129230,
    name: "Wild Peach Village",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.08358000",
    longitude: "-95.63384000",
  },
  {
    id: 129240,
    name: "Wildwood",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.52409000",
    longitude: "-94.44158000",
  },
  {
    id: 129255,
    name: "Willacy County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.47701000",
    longitude: "-97.59182000",
  },
  {
    id: 129278,
    name: "Williamson County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.64804000",
    longitude: "-97.60076000",
  },
  {
    id: 129295,
    name: "Willis",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.42493000",
    longitude: "-95.47994000",
  },
  {
    id: 129308,
    name: "Willow Park",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.76263000",
    longitude: "-97.65058000",
  },
  {
    id: 129317,
    name: "Wills Point",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.70930000",
    longitude: "-96.00830000",
  },
  {
    id: 129318,
    name: "Wilmer",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.58902000",
    longitude: "-96.68527000",
  },
  {
    id: 129337,
    name: "Wilson County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.17401000",
    longitude: "-98.08657000",
  },
  {
    id: 129349,
    name: "Wimberley",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.99744000",
    longitude: "-98.09862000",
  },
  {
    id: 129367,
    name: "Windcrest",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.51551000",
    longitude: "-98.38029000",
  },
  {
    id: 129368,
    name: "Windemere",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.45909000",
    longitude: "-97.64917000",
  },
  {
    id: 129401,
    name: "Wink",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.75124000",
    longitude: "-103.15989000",
  },
  {
    id: 129402,
    name: "Winkler County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.85005000",
    longitude: "-103.04817000",
  },
  {
    id: 129417,
    name: "Winnie",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.82022000",
    longitude: "-94.38408000",
  },
  {
    id: 129420,
    name: "Winnsboro",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.95734000",
    longitude: "-95.29022000",
  },
  {
    id: 129444,
    name: "Winters",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.95653000",
    longitude: "-99.96231000",
  },
  {
    id: 129460,
    name: "Wise County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.21590000",
    longitude: "-97.65445000",
  },
  {
    id: 129476,
    name: "Wolfe City",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.37067000",
    longitude: "-96.06886000",
  },
  {
    id: 129479,
    name: "Wolfforth",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.50592000",
    longitude: "-102.00906000",
  },
  {
    id: 129484,
    name: "Wood County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "32.78641000",
    longitude: "-95.38206000",
  },
  {
    id: 129498,
    name: "Woodbranch",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.18105000",
    longitude: "-95.18882000",
  },
  {
    id: 129515,
    name: "Woodcreek",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.02827000",
    longitude: "-98.11112000",
  },
  {
    id: 129553,
    name: "Woodsboro",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.23834000",
    longitude: "-97.31999000",
  },
  {
    id: 129572,
    name: "Woodville",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.77520000",
    longitude: "-94.41548000",
  },
  {
    id: 129578,
    name: "Woodway",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.50600000",
    longitude: "-97.20501000",
  },
  {
    id: 129595,
    name: "Wortham",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "31.78794000",
    longitude: "-96.46248000",
  },
  {
    id: 129625,
    name: "Wyldwood",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "30.12938000",
    longitude: "-97.47277000",
  },
  {
    id: 129626,
    name: "Wylie",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.01512000",
    longitude: "-96.53888000",
  },
  {
    id: 129683,
    name: "Yoakum",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "29.28775000",
    longitude: "-97.15193000",
  },
  {
    id: 129684,
    name: "Yoakum County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.17297000",
    longitude: "-102.82780000",
  },
  {
    id: 129706,
    name: "Yorktown",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.98109000",
    longitude: "-97.50277000",
  },
  {
    id: 129714,
    name: "Young County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "33.17670000",
    longitude: "-98.68777000",
  },
  {
    id: 129739,
    name: "Zapata",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "26.90726000",
    longitude: "-99.27143000",
  },
  {
    id: 129740,
    name: "Zapata County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "27.00078000",
    longitude: "-99.16861000",
  },
  {
    id: 129741,
    name: "Zavala County",
    state_id: 1407,
    state_code: "TX",
    country_id: 233,
    country_code: "US",
    latitude: "28.86621000",
    longitude: "-99.76060000",
  },
  {
    id: 111225,
    name: "Alpine",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.45328000",
    longitude: "-111.77799000",
  },
  {
    id: 111273,
    name: "American Fork",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.37690000",
    longitude: "-111.79576000",
  },
  {
    id: 111663,
    name: "Aurora",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "38.92219000",
    longitude: "-111.93409000",
  },
  {
    id: 111770,
    name: "Ballard",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.28940000",
    longitude: "-109.94320000",
  },
  {
    id: 111995,
    name: "Beaver",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "38.27691000",
    longitude: "-112.64105000",
  },
  {
    id: 111999,
    name: "Beaver County",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "38.35771000",
    longitude: "-113.23576000",
  },
  {
    id: 112170,
    name: "Benjamin",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.09829000",
    longitude: "-111.73132000",
  },
  {
    id: 112185,
    name: "Benson",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.78743000",
    longitude: "-111.93022000",
  },
  {
    id: 112422,
    name: "Blanding",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "37.62433000",
    longitude: "-109.47966000",
  },
  {
    id: 112482,
    name: "Bluffdale",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.48967000",
    longitude: "-111.93882000",
  },
  {
    id: 112610,
    name: "Bountiful",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.88939000",
    longitude: "-111.88077000",
  },
  {
    id: 112637,
    name: "Box Elder County",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.52097000",
    longitude: "-113.08209000",
  },
  {
    id: 112784,
    name: "Brigham City",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.51021000",
    longitude: "-112.01550000",
  },
  {
    id: 113156,
    name: "Cache County",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.72237000",
    longitude: "-111.74356000",
  },
  {
    id: 113360,
    name: "Canyon Rim",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.70661000",
    longitude: "-111.82188000",
  },
  {
    id: 113383,
    name: "Carbon County",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "39.64817000",
    longitude: "-110.58898000",
  },
  {
    id: 113391,
    name: "Carbonville",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "39.61996000",
    longitude: "-110.83433000",
  },
  {
    id: 113543,
    name: "Castle Dale",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "39.21219000",
    longitude: "-111.01961000",
  },
  {
    id: 113603,
    name: "Cedar City",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "37.67748000",
    longitude: "-113.06189000",
  },
  {
    id: 113616,
    name: "Cedar Hills",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.41412000",
    longitude: "-111.75854000",
  },
  {
    id: 113649,
    name: "Centerfield",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "39.12524000",
    longitude: "-111.81909000",
  },
  {
    id: 113662,
    name: "Centerville",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.91800000",
    longitude: "-111.87216000",
  },
  {
    id: 114217,
    name: "Clearfield",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.11078000",
    longitude: "-112.02605000",
  },
  {
    id: 114288,
    name: "Clinton",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.13967000",
    longitude: "-112.05050000",
  },
  {
    id: 114339,
    name: "Coalville",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.91773000",
    longitude: "-111.39936000",
  },
  {
    id: 114705,
    name: "Cottonwood Heights",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.61967000",
    longitude: "-111.81021000",
  },
  {
    id: 114969,
    name: "Daggett County",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.88727000",
    longitude: "-109.50765000",
  },
  {
    id: 115022,
    name: "Daniel",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.47079000",
    longitude: "-111.41463000",
  },
  {
    id: 115075,
    name: "Davis County",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.99061000",
    longitude: "-112.11124000",
  },
  {
    id: 115223,
    name: "Delta",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "39.35218000",
    longitude: "-112.57717000",
  },
  {
    id: 115469,
    name: "Draper",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.52467000",
    longitude: "-111.86382000",
  },
  {
    id: 115502,
    name: "Duchesne",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.16329000",
    longitude: "-110.40293000",
  },
  {
    id: 115503,
    name: "Duchesne County",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.29751000",
    longitude: "-110.42476000",
  },
  {
    id: 115607,
    name: "Eagle Mountain",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.31412000",
    longitude: "-112.00688000",
  },
  {
    id: 115642,
    name: "East Carbon City",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "39.54774000",
    longitude: "-110.41488000",
  },
  {
    id: 115712,
    name: "East Millcreek",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.69995000",
    longitude: "-111.81049000",
  },
  {
    id: 115992,
    name: "Elk Ridge",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.01134000",
    longitude: "-111.67687000",
  },
  {
    id: 116083,
    name: "Elwood",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.69048000",
    longitude: "-112.14106000",
  },
  {
    id: 116096,
    name: "Emery County",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "38.99677000",
    longitude: "-110.70067000",
  },
  {
    id: 116142,
    name: "Enoch",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "37.77331000",
    longitude: "-113.02439000",
  },
  {
    id: 116151,
    name: "Enterprise",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "37.57359000",
    longitude: "-113.71913000",
  },
  {
    id: 116154,
    name: "Ephraim",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "39.35968000",
    longitude: "-111.58631000",
  },
  {
    id: 116162,
    name: "Erda",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.61272000",
    longitude: "-112.30439000",
  },
  {
    id: 116357,
    name: "Fairview",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "39.62635000",
    longitude: "-111.43963000",
  },
  {
    id: 116422,
    name: "Farmington",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.98050000",
    longitude: "-111.88744000",
  },
  {
    id: 116428,
    name: "Farr West",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.29717000",
    longitude: "-112.02772000",
  },
  {
    id: 116496,
    name: "Ferron",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "39.09358000",
    longitude: "-111.13322000",
  },
  {
    id: 116509,
    name: "Fillmore",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "38.96885000",
    longitude: "-112.32355000",
  },
  {
    id: 116780,
    name: "Fountain Green",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "39.62996000",
    longitude: "-111.63520000",
  },
  {
    id: 116814,
    name: "Francis",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.61051000",
    longitude: "-111.28074000",
  },
  {
    id: 116967,
    name: "Fruit Heights",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.03217000",
    longitude: "-111.90216000",
  },
  {
    id: 117085,
    name: "Garfield County",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "37.85492000",
    longitude: "-111.44313000",
  },
  {
    id: 117090,
    name: "Garland",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.74104000",
    longitude: "-112.16162000",
  },
  {
    id: 117149,
    name: "Genola",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "39.99634000",
    longitude: "-111.84327000",
  },
  {
    id: 117455,
    name: "Grand County",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "38.98174000",
    longitude: "-109.56971000",
  },
  {
    id: 117492,
    name: "Granite",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.57300000",
    longitude: "-111.80604000",
  },
  {
    id: 117531,
    name: "Grantsville",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.59994000",
    longitude: "-112.46440000",
  },
  {
    id: 117803,
    name: "Gunnison",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "39.15524000",
    longitude: "-111.81826000",
  },
  {
    id: 118077,
    name: "Harrisville",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.28133000",
    longitude: "-111.98828000",
  },
  {
    id: 118229,
    name: "Heber City",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.50690000",
    longitude: "-111.41324000",
  },
  {
    id: 118262,
    name: "Helper",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "39.68413000",
    longitude: "-110.85461000",
  },
  {
    id: 118338,
    name: "Herriman",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.51411000",
    longitude: "-112.03299000",
  },
  {
    id: 118400,
    name: "Highland",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.42548000",
    longitude: "-111.79447000",
  },
  {
    id: 118429,
    name: "Hildale",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "37.00360000",
    longitude: "-112.96688000",
  },
  {
    id: 118433,
    name: "Hill Air Force Base",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.11118000",
    longitude: "-111.97712000",
  },
  {
    id: 118536,
    name: "Holladay",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.66884000",
    longitude: "-111.82466000",
  },
  {
    id: 118621,
    name: "Honeyville",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.63854000",
    longitude: "-112.07939000",
  },
  {
    id: 118633,
    name: "Hooper",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.16383000",
    longitude: "-112.12244000",
  },
  {
    id: 118803,
    name: "Huntington",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "39.32664000",
    longitude: "-110.96461000",
  },
  {
    id: 118828,
    name: "Hurricane",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "37.17526000",
    longitude: "-113.28995000",
  },
  {
    id: 118853,
    name: "Hyde Park",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.79882000",
    longitude: "-111.81911000",
  },
  {
    id: 118857,
    name: "Hyrum",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.63410000",
    longitude: "-111.85217000",
  },
  {
    id: 118991,
    name: "Iron County",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "37.85917000",
    longitude: "-113.28927000",
  },
  {
    id: 119051,
    name: "Ivins",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "37.16859000",
    longitude: "-113.67941000",
  },
  {
    id: 119325,
    name: "Juab County",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "39.70262000",
    longitude: "-112.78477000",
  },
  {
    id: 119332,
    name: "Junction",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "38.23748000",
    longitude: "-112.21993000",
  },
  {
    id: 119370,
    name: "Kamas",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.64301000",
    longitude: "-111.28074000",
  },
  {
    id: 119372,
    name: "Kanab",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "37.04749000",
    longitude: "-112.52631000",
  },
  {
    id: 119378,
    name: "Kane County",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "37.28507000",
    longitude: "-111.88785000",
  },
  {
    id: 119403,
    name: "Kaysville",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.03522000",
    longitude: "-111.93855000",
  },
  {
    id: 119411,
    name: "Kearns",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.65995000",
    longitude: "-111.99633000",
  },
  {
    id: 119807,
    name: "LaVerkin",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "37.20109000",
    longitude: "-113.26967000",
  },
  {
    id: 120254,
    name: "Layton",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.06022000",
    longitude: "-111.97105000",
  },
  {
    id: 120333,
    name: "Lehi",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.39162000",
    longitude: "-111.85077000",
  },
  {
    id: 120429,
    name: "Lewiston",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.97576000",
    longitude: "-111.85634000",
  },
  {
    id: 120468,
    name: "Liberty",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.33355000",
    longitude: "-111.86355000",
  },
  {
    id: 120571,
    name: "Lindon",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.34329000",
    longitude: "-111.72076000",
  },
  {
    id: 120615,
    name: "Little Cottonwood Creek Valley",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.60439000",
    longitude: "-111.82938000",
  },
  {
    id: 120668,
    name: "Loa",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "38.40276000",
    longitude: "-111.64296000",
  },
  {
    id: 120695,
    name: "Logan",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.73549000",
    longitude: "-111.83439000",
  },
  {
    id: 121030,
    name: "Maeser",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.47718000",
    longitude: "-109.58681000",
  },
  {
    id: 121034,
    name: "Magna",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.70911000",
    longitude: "-112.10161000",
  },
  {
    id: 121120,
    name: "Manila",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.98801000",
    longitude: "-109.72265000",
  },
  {
    id: 121157,
    name: "Manti",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "39.26830000",
    longitude: "-111.63686000",
  },
  {
    id: 121176,
    name: "Mapleton",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.13023000",
    longitude: "-111.57853000",
  },
  {
    id: 121297,
    name: "Marriott-Slaterville",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.25161000",
    longitude: "-112.02550000",
  },
  {
    id: 121655,
    name: "Mendon",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.70993000",
    longitude: "-111.97773000",
  },
  {
    id: 121811,
    name: "Midvale",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.61106000",
    longitude: "-111.89994000",
  },
  {
    id: 121819,
    name: "Midway",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.51218000",
    longitude: "-111.47435000",
  },
  {
    id: 121859,
    name: "Milford",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "38.39691000",
    longitude: "-113.01079000",
  },
  {
    id: 121869,
    name: "Millard County",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "39.07381000",
    longitude: "-113.10046000",
  },
  {
    id: 121875,
    name: "Millcreek",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.68689000",
    longitude: "-111.87549000",
  },
  {
    id: 121906,
    name: "Millville",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.68160000",
    longitude: "-111.82300000",
  },
  {
    id: 122006,
    name: "Moab",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "38.57332000",
    longitude: "-109.54984000",
  },
  {
    id: 122031,
    name: "Mona",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "39.81607000",
    longitude: "-111.85549000",
  },
  {
    id: 122064,
    name: "Monroe",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "38.62997000",
    longitude: "-112.12076000",
  },
  {
    id: 122171,
    name: "Monticello",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "37.87138000",
    longitude: "-109.34289000",
  },
  {
    id: 122234,
    name: "Morgan",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.03606000",
    longitude: "-111.67688000",
  },
  {
    id: 122245,
    name: "Morgan County",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.08932000",
    longitude: "-111.57312000",
  },
  {
    id: 122262,
    name: "Moroni",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "39.52496000",
    longitude: "-111.59047000",
  },
  {
    id: 122372,
    name: "Mount Olympus",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.68550000",
    longitude: "-111.78854000",
  },
  {
    id: 122381,
    name: "Mount Pleasant",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "39.54691000",
    longitude: "-111.45547000",
  },
  {
    id: 122413,
    name: "Mountain Green",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.14300000",
    longitude: "-111.79160000",
  },
  {
    id: 122492,
    name: "Murray",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.66689000",
    longitude: "-111.88799000",
  },
  {
    id: 122555,
    name: "Naples",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.42691000",
    longitude: "-109.49930000",
  },
  {
    id: 122635,
    name: "Nephi",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "39.71023000",
    longitude: "-111.83632000",
  },
  {
    id: 122884,
    name: "Nibley",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.67438000",
    longitude: "-111.83300000",
  },
  {
    id: 123060,
    name: "North Logan",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.76937000",
    longitude: "-111.80467000",
  },
  {
    id: 123074,
    name: "North Ogden",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.30716000",
    longitude: "-111.96022000",
  },
  {
    id: 123099,
    name: "North Salt Lake",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.84856000",
    longitude: "-111.90688000",
  },
  {
    id: 123300,
    name: "Oakley",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.71467000",
    longitude: "-111.30074000",
  },
  {
    id: 123369,
    name: "Ogden",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.22300000",
    longitude: "-111.97383000",
  },
  {
    id: 123501,
    name: "Oquirrh",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.63050000",
    longitude: "-112.03383000",
  },
  {
    id: 123531,
    name: "Orangeville",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "39.22719000",
    longitude: "-111.05350000",
  },
  {
    id: 123551,
    name: "Orem",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.29690000",
    longitude: "-111.69465000",
  },
  {
    id: 123800,
    name: "Panguitch",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "37.82276000",
    longitude: "-112.43576000",
  },
  {
    id: 123840,
    name: "Park City",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.64606000",
    longitude: "-111.49797000",
  },
  {
    id: 123888,
    name: "Parowan",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "37.84220000",
    longitude: "-112.82800000",
  },
  {
    id: 123950,
    name: "Payson",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.04440000",
    longitude: "-111.73215000",
  },
  {
    id: 124073,
    name: "Perry",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.46494000",
    longitude: "-112.03245000",
  },
  {
    id: 124307,
    name: "Piute County",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "38.33645000",
    longitude: "-112.12695000",
  },
  {
    id: 124315,
    name: "Plain City",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.29800000",
    longitude: "-112.08605000",
  },
  {
    id: 124367,
    name: "Pleasant Grove",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.36412000",
    longitude: "-111.73854000",
  },
  {
    id: 124381,
    name: "Pleasant View",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.31828000",
    longitude: "-111.99216000",
  },
  {
    id: 124663,
    name: "Price",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "39.59941000",
    longitude: "-110.81071000",
  },
  {
    id: 124717,
    name: "Providence",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.70632000",
    longitude: "-111.81717000",
  },
  {
    id: 124721,
    name: "Provo",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.23384000",
    longitude: "-111.65853000",
  },
  {
    id: 124870,
    name: "Randolph",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.66578000",
    longitude: "-111.18214000",
  },
  {
    id: 125056,
    name: "Rich County",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.63232000",
    longitude: "-111.24445000",
  },
  {
    id: 125063,
    name: "Richfield",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "38.77247000",
    longitude: "-112.08409000",
  },
  {
    id: 125097,
    name: "Richmond",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.92271000",
    longitude: "-111.81356000",
  },
  {
    id: 125181,
    name: "River Heights",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.72160000",
    longitude: "-111.82133000",
  },
  {
    id: 125197,
    name: "Riverdale",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.17689000",
    longitude: "-112.00383000",
  },
  {
    id: 125215,
    name: "Riverton",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.52189000",
    longitude: "-111.93910000",
  },
  {
    id: 125392,
    name: "Roosevelt",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.29940000",
    longitude: "-109.98876000",
  },
  {
    id: 125487,
    name: "Roy",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.16161000",
    longitude: "-112.02633000",
  },
  {
    id: 125644,
    name: "Saint George",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "37.10415000",
    longitude: "-113.58412000",
  },
  {
    id: 125739,
    name: "Salem",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.05301000",
    longitude: "-111.67354000",
  },
  {
    id: 125746,
    name: "Salina",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "38.95774000",
    longitude: "-111.85993000",
  },
  {
    id: 125767,
    name: "Salt Lake City",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.76078000",
    longitude: "-111.89105000",
  },
  {
    id: 125768,
    name: "Salt Lake County",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.66758000",
    longitude: "-111.92403000",
  },
  {
    id: 125822,
    name: "San Juan County",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "37.62601000",
    longitude: "-109.80457000",
  },
  {
    id: 125881,
    name: "Sandy",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.59161000",
    longitude: "-111.88410000",
  },
  {
    id: 125882,
    name: "Sandy Hills",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.58106000",
    longitude: "-111.85077000",
  },
  {
    id: 125896,
    name: "Sanpete County",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "39.37396000",
    longitude: "-111.57634000",
  },
  {
    id: 125905,
    name: "Santa Clara",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "37.13304000",
    longitude: "-113.65413000",
  },
  {
    id: 125929,
    name: "Santaquin",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "39.97551000",
    longitude: "-111.78521000",
  },
  {
    id: 125945,
    name: "Saratoga Springs",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.34912000",
    longitude: "-111.90466000",
  },
  {
    id: 126182,
    name: "Sevier County",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "38.74764000",
    longitude: "-111.80464000",
  },
  {
    id: 126438,
    name: "Silver Summit",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.74144000",
    longitude: "-111.48775000",
  },
  {
    id: 126521,
    name: "Smithfield",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.83826000",
    longitude: "-111.83272000",
  },
  {
    id: 126552,
    name: "Snyderville",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.69439000",
    longitude: "-111.54381000",
  },
  {
    id: 126680,
    name: "South Jordan",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.56217000",
    longitude: "-111.92966000",
  },
  {
    id: 126681,
    name: "South Jordan Heights",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.56384000",
    longitude: "-111.94938000",
  },
  {
    id: 126696,
    name: "South Ogden",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.19189000",
    longitude: "-111.97133000",
  },
  {
    id: 126722,
    name: "South Salt Lake",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.71884000",
    longitude: "-111.88827000",
  },
  {
    id: 126745,
    name: "South Weber",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.13244000",
    longitude: "-111.93022000",
  },
  {
    id: 126749,
    name: "South Willard",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.36327000",
    longitude: "-112.03578000",
  },
  {
    id: 126798,
    name: "Spanish Fork",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.11496000",
    longitude: "-111.65492000",
  },
  {
    id: 126853,
    name: "Spring City",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "39.48246000",
    longitude: "-111.49602000",
  },
  {
    id: 126855,
    name: "Spring Glen",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "39.65941000",
    longitude: "-110.85349000",
  },
  {
    id: 126918,
    name: "Springville",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.16523000",
    longitude: "-111.61075000",
  },
  {
    id: 126965,
    name: "Stansbury park",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.63772000",
    longitude: "-112.29606000",
  },
  {
    id: 127217,
    name: "Summit County",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.86815000",
    longitude: "-110.95567000",
  },
  {
    id: 127219,
    name: "Summit Park",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.74578000",
    longitude: "-111.61159000",
  },
  {
    id: 127273,
    name: "Sunset",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.13633000",
    longitude: "-112.03105000",
  },
  {
    id: 127367,
    name: "Syracuse",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.08939000",
    longitude: "-112.06467000",
  },
  {
    id: 127466,
    name: "Taylorsville",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.66772000",
    longitude: "-111.93883000",
  },
  {
    id: 127725,
    name: "Tooele",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.53078000",
    longitude: "-112.29828000",
  },
  {
    id: 127726,
    name: "Tooele County",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.44875000",
    longitude: "-113.13106000",
  },
  {
    id: 127738,
    name: "Toquerville",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "37.25332000",
    longitude: "-113.28467000",
  },
  {
    id: 127797,
    name: "Tremonton",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.71187000",
    longitude: "-112.16551000",
  },
  {
    id: 127956,
    name: "Uintah",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.14411000",
    longitude: "-111.92327000",
  },
  {
    id: 127957,
    name: "Uintah County",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.12495000",
    longitude: "-109.51839000",
  },
  {
    id: 128064,
    name: "Utah County",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.11995000",
    longitude: "-111.67031000",
  },
  {
    id: 128187,
    name: "Vernal",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.45552000",
    longitude: "-109.52875000",
  },
  {
    id: 128272,
    name: "Vineyard",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.29704000",
    longitude: "-111.74670000",
  },
  {
    id: 128526,
    name: "Wasatch County",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.33035000",
    longitude: "-111.16847000",
  },
  {
    id: 128551,
    name: "Washington",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "37.13054000",
    longitude: "-113.50829000",
  },
  {
    id: 128578,
    name: "Washington County",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "37.28036000",
    longitude: "-113.50494000",
  },
  {
    id: 128586,
    name: "Washington Terrace",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.17272000",
    longitude: "-111.97661000",
  },
  {
    id: 128703,
    name: "Wayne County",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "38.32436000",
    longitude: "-110.90367000",
  },
  {
    id: 128729,
    name: "Weber County",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.26988000",
    longitude: "-111.91327000",
  },
  {
    id: 128779,
    name: "Wellington",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "39.54247000",
    longitude: "-110.73543000",
  },
  {
    id: 128794,
    name: "Wellsville",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.63854000",
    longitude: "-111.93383000",
  },
  {
    id: 128801,
    name: "Wendover",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.73715000",
    longitude: "-114.03751000",
  },
  {
    id: 128829,
    name: "West Bountiful",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.89383000",
    longitude: "-111.90188000",
  },
  {
    id: 128888,
    name: "West Haven",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.20300000",
    longitude: "-112.05105000",
  },
  {
    id: 128905,
    name: "West Jordan",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.60967000",
    longitude: "-111.93910000",
  },
  {
    id: 128937,
    name: "West Mountain",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.06079000",
    longitude: "-111.78827000",
  },
  {
    id: 128963,
    name: "West Point",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.11828000",
    longitude: "-112.08411000",
  },
  {
    id: 128997,
    name: "West Valley City",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.69161000",
    longitude: "-112.00105000",
  },
  {
    id: 129132,
    name: "White City",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.56578000",
    longitude: "-111.86438000",
  },
  {
    id: 129258,
    name: "Willard",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.40911000",
    longitude: "-112.03606000",
  },
  {
    id: 129471,
    name: "Wolf Creek",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "41.33327000",
    longitude: "-111.82716000",
  },
  {
    id: 129531,
    name: "Woodland Hills",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.01532000",
    longitude: "-111.64868000",
  },
  {
    id: 129551,
    name: "Woods Cross",
    state_id: 1414,
    state_code: "UT",
    country_id: 233,
    country_code: "US",
    latitude: "40.87161000",
    longitude: "-111.89216000",
  },
  {
    id: 111039,
    name: "Addison",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.08867000",
    longitude: "-73.30262000",
  },
  {
    id: 111040,
    name: "Addison County",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.03091000",
    longitude: "-73.14094000",
  },
  {
    id: 111469,
    name: "Arlington",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "43.07480000",
    longitude: "-73.15400000",
  },
  {
    id: 111842,
    name: "Barre",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.19701000",
    longitude: "-72.50205000",
  },
  {
    id: 112124,
    name: "Bellows Falls",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "43.13341000",
    longitude: "-72.44398000",
  },
  {
    id: 112177,
    name: "Bennington",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "42.87813000",
    longitude: "-73.19677000",
  },
  {
    id: 112178,
    name: "Bennington County",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "43.03546000",
    longitude: "-73.09295000",
  },
  {
    id: 112690,
    name: "Brandon",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "43.79812000",
    longitude: "-73.08761000",
  },
  {
    id: 112699,
    name: "Brattleboro",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "42.85092000",
    longitude: "-72.55787000",
  },
  {
    id: 112780,
    name: "Bridport",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "43.98506000",
    longitude: "-73.31262000",
  },
  {
    id: 112810,
    name: "Bristol",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.13339000",
    longitude: "-73.07901000",
  },
  {
    id: 113072,
    name: "Burlington",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.47588000",
    longitude: "-73.21207000",
  },
  {
    id: 113193,
    name: "Caledonia County",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.46472000",
    longitude: "-72.10219000",
  },
  {
    id: 113552,
    name: "Castleton",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "43.61062000",
    longitude: "-73.17983000",
  },
  {
    id: 113780,
    name: "Charlotte",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.30977000",
    longitude: "-73.26096000",
  },
  {
    id: 113835,
    name: "Chelsea",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "43.98979000",
    longitude: "-72.44760000",
  },
  {
    id: 113891,
    name: "Chester",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "43.26285000",
    longitude: "-72.59509000",
  },
  {
    id: 113982,
    name: "Chittenden",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "43.70784000",
    longitude: "-72.94816000",
  },
  {
    id: 113983,
    name: "Chittenden County",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.46098000",
    longitude: "-73.08092000",
  },
  {
    id: 114111,
    name: "Clarendon",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "43.51618000",
    longitude: "-72.96983000",
  },
  {
    id: 114384,
    name: "Colchester",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.54394000",
    longitude: "-73.14791000",
  },
  {
    id: 115017,
    name: "Danby",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "43.34618000",
    longitude: "-72.99538000",
  },
  {
    id: 115452,
    name: "Dover",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "42.94369000",
    longitude: "-72.80399000",
  },
  {
    id: 116146,
    name: "Enosburg Falls",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.90699000",
    longitude: "-72.80652000",
  },
  {
    id: 116197,
    name: "Essex County",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.72779000",
    longitude: "-71.73605000",
  },
  {
    id: 116199,
    name: "Essex Junction",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.49061000",
    longitude: "-73.11096000",
  },
  {
    id: 116283,
    name: "Fair Haven",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "43.59479000",
    longitude: "-73.26567000",
  },
  {
    id: 116495,
    name: "Ferrisburgh",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.20561000",
    longitude: "-73.24623000",
  },
  {
    id: 116863,
    name: "Franklin County",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.85748000",
    longitude: "-72.91200000",
  },
  {
    id: 117464,
    name: "Grand Isle County",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.79683000",
    longitude: "-73.29483000",
  },
  {
    id: 117783,
    name: "Guildhall",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.56506000",
    longitude: "-71.55981000",
  },
  {
    id: 118010,
    name: "Hardwick",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.50478000",
    longitude: "-72.36816000",
  },
  {
    id: 118092,
    name: "Hartford",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "43.66063000",
    longitude: "-72.33842000",
  },
  {
    id: 118481,
    name: "Hinesburg",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.32922000",
    longitude: "-73.11068000",
  },
  {
    id: 118852,
    name: "Hyde Park",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.59394000",
    longitude: "-72.61651000",
  },
  {
    id: 119112,
    name: "Jamaica",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "43.10036000",
    longitude: "-72.77843000",
  },
  {
    id: 119225,
    name: "Jericho",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.50394000",
    longitude: "-72.99763000",
  },
  {
    id: 119255,
    name: "Johnson",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.63561000",
    longitude: "-72.68040000",
  },
  {
    id: 120072,
    name: "Lamoille County",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.60576000",
    longitude: "-72.64145000",
  },
  {
    id: 120338,
    name: "Leicester",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "43.86673000",
    longitude: "-73.10789000",
  },
  {
    id: 120508,
    name: "Lincoln",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.10589000",
    longitude: "-72.99706000",
  },
  {
    id: 120724,
    name: "Londonderry",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "43.22646000",
    longitude: "-72.80649000",
  },
  {
    id: 120888,
    name: "Lunenburg",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.46311000",
    longitude: "-71.68203000",
  },
  {
    id: 120924,
    name: "Lyndon",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.51422000",
    longitude: "-72.01093000",
  },
  {
    id: 120925,
    name: "Lyndonville",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.53367000",
    longitude: "-72.00315000",
  },
  {
    id: 121103,
    name: "Manchester Center",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "43.17702000",
    longitude: "-73.05705000",
  },
  {
    id: 121654,
    name: "Mendon",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "43.65198000",
    longitude: "-72.92780000",
  },
  {
    id: 121773,
    name: "Middlebury (village)",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.01553000",
    longitude: "-73.16937000",
  },
  {
    id: 121919,
    name: "Milton",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.63977000",
    longitude: "-73.11041000",
  },
  {
    id: 122137,
    name: "Montgomery",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.90255000",
    longitude: "-72.63818000",
  },
  {
    id: 122177,
    name: "Montpelier",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.26006000",
    longitude: "-72.57539000",
  },
  {
    id: 122232,
    name: "Moretown",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.25089000",
    longitude: "-72.76095000",
  },
  {
    id: 122283,
    name: "Morristown",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.55727000",
    longitude: "-72.62373000",
  },
  {
    id: 122287,
    name: "Morrisville",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.56172000",
    longitude: "-72.59845000",
  },
  {
    id: 122350,
    name: "Mount Holly",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "43.45229000",
    longitude: "-72.82482000",
  },
  {
    id: 122827,
    name: "Newfane",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "42.98564000",
    longitude: "-72.65593000",
  },
  {
    id: 122850,
    name: "Newport",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.93644000",
    longitude: "-72.20510000",
  },
  {
    id: 122986,
    name: "North Bennington",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "42.93036000",
    longitude: "-73.24261000",
  },
  {
    id: 123038,
    name: "North Hero",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.83125000",
    longitude: "-73.27323000",
  },
  {
    id: 123154,
    name: "Northfield",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.15117000",
    longitude: "-72.65650000",
  },
  {
    id: 123520,
    name: "Orange County",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.00560000",
    longitude: "-72.37661000",
  },
  {
    id: 123566,
    name: "Orleans County",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.82881000",
    longitude: "-72.24381000",
  },
  {
    id: 123931,
    name: "Pawlet",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "43.34674000",
    longitude: "-73.17622000",
  },
  {
    id: 124604,
    name: "Poultney",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "43.51701000",
    longitude: "-73.23622000",
  },
  {
    id: 124619,
    name: "Pownal",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "42.76564000",
    longitude: "-73.23594000",
  },
  {
    id: 124868,
    name: "Randolph",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "43.92507000",
    longitude: "-72.66594000",
  },
  {
    id: 125065,
    name: "Richford",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.99699000",
    longitude: "-72.67124000",
  },
  {
    id: 125304,
    name: "Rockingham",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "43.18758000",
    longitude: "-72.48898000",
  },
  {
    id: 125557,
    name: "Rutland",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "43.61062000",
    longitude: "-72.97261000",
  },
  {
    id: 125558,
    name: "Rutland County",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "43.58009000",
    longitude: "-73.03661000",
  },
  {
    id: 125596,
    name: "Saint Albans",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.81088000",
    longitude: "-73.08319000",
  },
  {
    id: 125668,
    name: "Saint Johnsbury",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.41922000",
    longitude: "-72.01509000",
  },
  {
    id: 125760,
    name: "Salisbury",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "43.89645000",
    longitude: "-73.09984000",
  },
  {
    id: 126616,
    name: "South Barre",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.17701000",
    longitude: "-72.50566000",
  },
  {
    id: 126634,
    name: "South Burlington",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.46699000",
    longitude: "-73.17096000",
  },
  {
    id: 126904,
    name: "Springfield",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "43.29841000",
    longitude: "-72.48231000",
  },
  {
    id: 126925,
    name: "St Johnsbury",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.42526000",
    longitude: "-72.01512000",
  },
  {
    id: 126988,
    name: "Starksboro",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.22728000",
    longitude: "-73.05734000",
  },
  {
    id: 127114,
    name: "Stowe",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.46533000",
    longitude: "-72.68456000",
  },
  {
    id: 127327,
    name: "Swanton",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.91810000",
    longitude: "-73.12430000",
  },
  {
    id: 127767,
    name: "Townshend",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "43.04730000",
    longitude: "-72.66759000",
  },
  {
    id: 128182,
    name: "Vergennes",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.16728000",
    longitude: "-73.25401000",
  },
  {
    id: 128550,
    name: "Washington",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.10562000",
    longitude: "-72.43260000",
  },
  {
    id: 128575,
    name: "Washington County",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.27342000",
    longitude: "-72.61490000",
  },
  {
    id: 128603,
    name: "Waterbury",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.33783000",
    longitude: "-72.75623000",
  },
  {
    id: 128834,
    name: "West Brattleboro",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "42.85592000",
    longitude: "-72.60315000",
  },
  {
    id: 128970,
    name: "West Rutland",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "43.59312000",
    longitude: "-73.04511000",
  },
  {
    id: 129157,
    name: "White River Junction",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "43.64896000",
    longitude: "-72.31926000",
  },
  {
    id: 129232,
    name: "Wilder",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "43.67285000",
    longitude: "-72.30870000",
  },
  {
    id: 129289,
    name: "Williamstown",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.12173000",
    longitude: "-72.54149000",
  },
  {
    id: 129298,
    name: "Williston",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.43755000",
    longitude: "-73.06818000",
  },
  {
    id: 129374,
    name: "Windham County",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "42.99059000",
    longitude: "-72.71384000",
  },
  {
    id: 129384,
    name: "Windsor",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "43.48035000",
    longitude: "-72.38481000",
  },
  {
    id: 129388,
    name: "Windsor County",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "43.58000000",
    longitude: "-72.58624000",
  },
  {
    id: 129427,
    name: "Winooski",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "44.49144000",
    longitude: "-73.18568000",
  },
  {
    id: 129566,
    name: "Woodstock",
    state_id: 1409,
    state_code: "VT",
    country_id: 233,
    country_code: "US",
    latitude: "43.62424000",
    longitude: "-72.51843000",
  },
  {
    id: 110984,
    name: "Abingdon",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.70983000",
    longitude: "-81.97735000",
  },
  {
    id: 110994,
    name: "Accomac",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.71957000",
    longitude: "-75.66548000",
  },
  {
    id: 110995,
    name: "Accomack County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.76494000",
    longitude: "-75.75656000",
  },
  {
    id: 111050,
    name: "Adwolf",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.78928000",
    longitude: "-81.58206000",
  },
  {
    id: 111116,
    name: "Albemarle County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.02289000",
    longitude: "-78.55654000",
  },
  {
    id: 111151,
    name: "Alexandria",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.80484000",
    longitude: "-77.04692000",
  },
  {
    id: 111184,
    name: "Alleghany County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.78760000",
    longitude: "-80.00699000",
  },
  {
    id: 111238,
    name: "Altavista",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.11181000",
    longitude: "-79.28558000",
  },
  {
    id: 111269,
    name: "Amelia County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.33603000",
    longitude: "-77.97614000",
  },
  {
    id: 111270,
    name: "Amelia Court House",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.34293000",
    longitude: "-77.98056000",
  },
  {
    id: 111281,
    name: "Amherst",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.58514000",
    longitude: "-79.05141000",
  },
  {
    id: 111286,
    name: "Amherst County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.60477000",
    longitude: "-79.14511000",
  },
  {
    id: 111349,
    name: "Annandale",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.83039000",
    longitude: "-77.19637000",
  },
  {
    id: 111390,
    name: "Appalachia",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.90676000",
    longitude: "-82.78183000",
  },
  {
    id: 111392,
    name: "Apple Mountain Lake",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.92428000",
    longitude: "-78.10139000",
  },
  {
    id: 111401,
    name: "Appomattox",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.35709000",
    longitude: "-78.82529000",
  },
  {
    id: 111402,
    name: "Appomattox County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.37229000",
    longitude: "-78.81212000",
  },
  {
    id: 111406,
    name: "Aquia Harbour",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.46262000",
    longitude: "-77.38887000",
  },
  {
    id: 111463,
    name: "Arlington",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.88101000",
    longitude: "-77.10428000",
  },
  {
    id: 111471,
    name: "Arlington County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.87862000",
    longitude: "-77.10096000",
  },
  {
    id: 111514,
    name: "Ashburn",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "39.04372000",
    longitude: "-77.48749000",
  },
  {
    id: 111532,
    name: "Ashland",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.75903000",
    longitude: "-77.47998000",
  },
  {
    id: 111585,
    name: "Atkins",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.86734000",
    longitude: "-81.42344000",
  },
  {
    id: 111652,
    name: "Augusta County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.16452000",
    longitude: "-79.13383000",
  },
  {
    id: 111731,
    name: "Baileys Crossroads",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.85039000",
    longitude: "-77.12970000",
  },
  {
    id: 111881,
    name: "Bassett",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.75930000",
    longitude: "-79.99032000",
  },
  {
    id: 111886,
    name: "Basye",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.80706000",
    longitude: "-78.79224000",
  },
  {
    id: 111902,
    name: "Bath County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.05869000",
    longitude: "-79.74109000",
  },
  {
    id: 111972,
    name: "Bealeton",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.57179000",
    longitude: "-77.76388000",
  },
  {
    id: 112020,
    name: "Bedford",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.33431000",
    longitude: "-79.52309000",
  },
  {
    id: 112027,
    name: "Bedford County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.31494000",
    longitude: "-79.52422000",
  },
  {
    id: 112085,
    name: "Belle Haven",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.78539000",
    longitude: "-77.06303000",
  },
  {
    id: 112130,
    name: "Bellwood",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.42181000",
    longitude: "-77.43748000",
  },
  {
    id: 112137,
    name: "Belmont",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "39.06483000",
    longitude: "-77.50999000",
  },
  {
    id: 112144,
    name: "Belmont Estates",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.45271000",
    longitude: "-78.91979000",
  },
  {
    id: 112181,
    name: "Bensley",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.44681000",
    longitude: "-77.44332000",
  },
  {
    id: 112255,
    name: "Berryville",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "39.15177000",
    longitude: "-77.98222000",
  },
  {
    id: 112337,
    name: "Big Stone Gap",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.88176000",
    longitude: "-82.74710000",
  },
  {
    id: 112390,
    name: "Blacksburg",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.22957000",
    longitude: "-80.41394000",
  },
  {
    id: 112392,
    name: "Blackstone",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.08043000",
    longitude: "-77.99723000",
  },
  {
    id: 112420,
    name: "Bland",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.10206000",
    longitude: "-81.11620000",
  },
  {
    id: 112421,
    name: "Bland County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.13397000",
    longitude: "-81.13028000",
  },
  {
    id: 112476,
    name: "Blue Ridge",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.37847000",
    longitude: "-79.80698000",
  },
  {
    id: 112478,
    name: "Bluefield",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.25262000",
    longitude: "-81.27121000",
  },
  {
    id: 112526,
    name: "Bon Air",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.52487000",
    longitude: "-77.55777000",
  },
  {
    id: 112593,
    name: "Boswell's Corner",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.50541000",
    longitude: "-77.37263000",
  },
  {
    id: 112594,
    name: "Botetourt County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.55712000",
    longitude: "-79.81233000",
  },
  {
    id: 112631,
    name: "Bowling Green",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.04958000",
    longitude: "-77.34665000",
  },
  {
    id: 112644,
    name: "Boydton",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.66764000",
    longitude: "-78.38750000",
  },
  {
    id: 112654,
    name: "Bracey",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.59959000",
    longitude: "-78.14305000",
  },
  {
    id: 112683,
    name: "Brambleton",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.98205000",
    longitude: "-77.53860000",
  },
  {
    id: 112687,
    name: "Brandermill",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.43209000",
    longitude: "-77.64971000",
  },
  {
    id: 112774,
    name: "Bridgewater",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.38207000",
    longitude: "-78.97670000",
  },
  {
    id: 112795,
    name: "Brightwood",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.42152000",
    longitude: "-78.19361000",
  },
  {
    id: 112804,
    name: "Bristol",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.59649000",
    longitude: "-82.18847000",
  },
  {
    id: 112824,
    name: "Broadlands",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "39.01816000",
    longitude: "-77.52027000",
  },
  {
    id: 112831,
    name: "Broadway",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.61317000",
    longitude: "-78.79891000",
  },
  {
    id: 112879,
    name: "Brookneal",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.05014000",
    longitude: "-78.94418000",
  },
  {
    id: 112952,
    name: "Brunswick County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.76478000",
    longitude: "-77.85902000",
  },
  {
    id: 112970,
    name: "Buchanan",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.52736000",
    longitude: "-79.67976000",
  },
  {
    id: 112974,
    name: "Buchanan County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.26663000",
    longitude: "-82.03603000",
  },
  {
    id: 112980,
    name: "Buckhall",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.73178000",
    longitude: "-77.43110000",
  },
  {
    id: 112985,
    name: "Buckingham",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.55015000",
    longitude: "-78.55556000",
  },
  {
    id: 112986,
    name: "Buckingham County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.57224000",
    longitude: "-78.52871000",
  },
  {
    id: 113001,
    name: "Buena Vista",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.73430000",
    longitude: "-79.35392000",
  },
  {
    id: 113026,
    name: "Bull Run",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.78373000",
    longitude: "-77.52055000",
  },
  {
    id: 113027,
    name: "Bull Run Mountain Estates",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.90372000",
    longitude: "-77.66166000",
  },
  {
    id: 113054,
    name: "Burke",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.79345000",
    longitude: "-77.27165000",
  },
  {
    id: 113301,
    name: "Campbell County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.20564000",
    longitude: "-79.09635000",
  },
  {
    id: 113311,
    name: "Cana",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.58958000",
    longitude: "-80.67173000",
  },
  {
    id: 113365,
    name: "Cape Charles",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.27008000",
    longitude: "-76.01649000",
  },
  {
    id: 113379,
    name: "Captains Cove",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.98996000",
    longitude: "-75.42272000",
  },
  {
    id: 113438,
    name: "Caroline County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.02682000",
    longitude: "-77.34696000",
  },
  {
    id: 113457,
    name: "Carroll County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.73154000",
    longitude: "-80.73390000",
  },
  {
    id: 113469,
    name: "Carrollton",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.94682000",
    longitude: "-76.56051000",
  },
  {
    id: 113554,
    name: "Castlewood",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.89010000",
    longitude: "-82.27959000",
  },
  {
    id: 113588,
    name: "Cave Spring",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.22764000",
    longitude: "-80.01282000",
  },
  {
    id: 113602,
    name: "Cedar Bluff",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.08761000",
    longitude: "-81.75900000",
  },
  {
    id: 113674,
    name: "Central Garage",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.74403000",
    longitude: "-77.13164000",
  },
  {
    id: 113695,
    name: "Centreville",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.84039000",
    longitude: "-77.42888000",
  },
  {
    id: 113719,
    name: "Chamberlayne",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.62653000",
    longitude: "-77.42859000",
  },
  {
    id: 113738,
    name: "Chantilly",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.89428000",
    longitude: "-77.43110000",
  },
  {
    id: 113755,
    name: "Charles City",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.34348000",
    longitude: "-77.07303000",
  },
  {
    id: 113756,
    name: "Charles City County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.35435000",
    longitude: "-77.05913000",
  },
  {
    id: 113782,
    name: "Charlotte County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.01161000",
    longitude: "-78.66164000",
  },
  {
    id: 113783,
    name: "Charlotte Court House",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.05654000",
    longitude: "-78.63833000",
  },
  {
    id: 113787,
    name: "Charlottesville",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.02931000",
    longitude: "-78.47668000",
  },
  {
    id: 113791,
    name: "Chase City",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.79931000",
    longitude: "-78.45833000",
  },
  {
    id: 113796,
    name: "Chatham",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.82569000",
    longitude: "-79.39808000",
  },
  {
    id: 113803,
    name: "Chatmoss",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.65680000",
    longitude: "-79.81226000",
  },
  {
    id: 113861,
    name: "Cherry Hill",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.56984000",
    longitude: "-77.26693000",
  },
  {
    id: 113874,
    name: "Chesapeake",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.81904000",
    longitude: "-76.27494000",
  },
  {
    id: 113886,
    name: "Chester",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.35682000",
    longitude: "-77.44165000",
  },
  {
    id: 113907,
    name: "Chesterfield County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.37852000",
    longitude: "-77.58694000",
  },
  {
    id: 113908,
    name: "Chesterfield Court House",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.37709000",
    longitude: "-77.50499000",
  },
  {
    id: 113952,
    name: "Chilhowie",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.79845000",
    longitude: "-81.68234000",
  },
  {
    id: 113966,
    name: "Chincoteague",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.93318000",
    longitude: "-75.37881000",
  },
  {
    id: 114000,
    name: "Christiansburg",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.12985000",
    longitude: "-80.40894000",
  },
  {
    id: 114047,
    name: "City of Alexandria",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.80484000",
    longitude: "-77.04692000",
  },
  {
    id: 114049,
    name: "City of Bedford",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.33431000",
    longitude: "-79.52309000",
  },
  {
    id: 114050,
    name: "City of Bristol",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.59649000",
    longitude: "-82.18847000",
  },
  {
    id: 114051,
    name: "City of Buena Vista",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.73430000",
    longitude: "-79.35392000",
  },
  {
    id: 114052,
    name: "City of Charlottesville",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.02931000",
    longitude: "-78.47668000",
  },
  {
    id: 114053,
    name: "City of Chesapeake",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.68765000",
    longitude: "-76.31216000",
  },
  {
    id: 114054,
    name: "City of Colonial Heights",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.24404000",
    longitude: "-77.41026000",
  },
  {
    id: 114055,
    name: "City of Covington",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.79346000",
    longitude: "-79.99395000",
  },
  {
    id: 114056,
    name: "City of Danville",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.58597000",
    longitude: "-79.39502000",
  },
  {
    id: 114057,
    name: "City of Emporia",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.68598000",
    longitude: "-77.54248000",
  },
  {
    id: 114058,
    name: "City of Fairfax",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.84622000",
    longitude: "-77.30637000",
  },
  {
    id: 114059,
    name: "City of Falls Church",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.88233000",
    longitude: "-77.17109000",
  },
  {
    id: 114060,
    name: "City of Franklin",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.67765000",
    longitude: "-76.92246000",
  },
  {
    id: 114061,
    name: "City of Fredericksburg",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.30318000",
    longitude: "-77.46054000",
  },
  {
    id: 114062,
    name: "City of Galax",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.66124000",
    longitude: "-80.92397000",
  },
  {
    id: 114063,
    name: "City of Hampton",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.06265000",
    longitude: "-76.33300000",
  },
  {
    id: 114064,
    name: "City of Harrisonburg",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.44957000",
    longitude: "-78.86892000",
  },
  {
    id: 114065,
    name: "City of Hopewell",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.30432000",
    longitude: "-77.28720000",
  },
  {
    id: 114066,
    name: "City of Lexington",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.77309000",
    longitude: "-79.45244000",
  },
  {
    id: 114067,
    name: "City of Lynchburg",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.41375000",
    longitude: "-79.14225000",
  },
  {
    id: 114068,
    name: "City of Manassas",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.75095000",
    longitude: "-77.47527000",
  },
  {
    id: 114069,
    name: "City of Manassas Park",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.78400000",
    longitude: "-77.46971000",
  },
  {
    id: 114070,
    name: "City of Martinsville",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.69153000",
    longitude: "-79.87254000",
  },
  {
    id: 114072,
    name: "City of Newport News",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.06265000",
    longitude: "-76.50801000",
  },
  {
    id: 114073,
    name: "City of Norfolk",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.89126000",
    longitude: "-76.26188000",
  },
  {
    id: 114074,
    name: "City of Norton",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.93343000",
    longitude: "-82.62905000",
  },
  {
    id: 114075,
    name: "City of Petersburg",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.22793000",
    longitude: "-77.40193000",
  },
  {
    id: 114076,
    name: "City of Poquoson",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.12237000",
    longitude: "-76.34578000",
  },
  {
    id: 114077,
    name: "City of Portsmouth",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.83649000",
    longitude: "-76.30795000",
  },
  {
    id: 114078,
    name: "City of Radford",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.13179000",
    longitude: "-80.57645000",
  },
  {
    id: 114079,
    name: "City of Richmond",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.55376000",
    longitude: "-77.46026000",
  },
  {
    id: 114080,
    name: "City of Roanoke",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.27097000",
    longitude: "-79.94143000",
  },
  {
    id: 114082,
    name: "City of Salem",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.29347000",
    longitude: "-80.05476000",
  },
  {
    id: 114084,
    name: "City of Staunton",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.14931000",
    longitude: "-79.05963000",
  },
  {
    id: 114085,
    name: "City of Suffolk",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.70848000",
    longitude: "-76.60801000",
  },
  {
    id: 114086,
    name: "City of Virginia Beach",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.73765000",
    longitude: "-76.00521000",
  },
  {
    id: 114087,
    name: "City of Waynesboro",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.06847000",
    longitude: "-78.88947000",
  },
  {
    id: 114088,
    name: "City of Williamsburg",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.27070000",
    longitude: "-76.70746000",
  },
  {
    id: 114089,
    name: "City of Winchester",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "39.18566000",
    longitude: "-78.16333000",
  },
  {
    id: 114137,
    name: "Clarke County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "39.11226000",
    longitude: "-77.99673000",
  },
  {
    id: 114157,
    name: "Clarksville",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.62403000",
    longitude: "-78.55694000",
  },
  {
    id: 114194,
    name: "Claypool Hill",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.06261000",
    longitude: "-81.75178000",
  },
  {
    id: 114262,
    name: "Clifton Forge",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.81624000",
    longitude: "-79.82449000",
  },
  {
    id: 114300,
    name: "Clintwood",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.15011000",
    longitude: "-82.45598000",
  },
  {
    id: 114310,
    name: "Cloverdale",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.36514000",
    longitude: "-79.90560000",
  },
  {
    id: 114365,
    name: "Coeburn",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.94399000",
    longitude: "-82.46404000",
  },
  {
    id: 114438,
    name: "Collinsville",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.71514000",
    longitude: "-79.91532000",
  },
  {
    id: 114447,
    name: "Colonial Beach",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.25457000",
    longitude: "-76.96358000",
  },
  {
    id: 114449,
    name: "Colonial Heights",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.26804000",
    longitude: "-77.40726000",
  },
  {
    id: 114537,
    name: "Concord",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.34264000",
    longitude: "-78.97502000",
  },
  {
    id: 114725,
    name: "Countryside",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "39.04094000",
    longitude: "-77.41360000",
  },
  {
    id: 114728,
    name: "Courtland",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.71626000",
    longitude: "-77.06802000",
  },
  {
    id: 114739,
    name: "Covington",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.79346000",
    longitude: "-79.99395000",
  },
  {
    id: 114758,
    name: "Craig County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.48125000",
    longitude: "-80.21234000",
  },
  {
    id: 114818,
    name: "Crewe",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.17321000",
    longitude: "-78.12333000",
  },
  {
    id: 114820,
    name: "Crimora",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.15402000",
    longitude: "-78.85030000",
  },
  {
    id: 114871,
    name: "Crozet",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.06958000",
    longitude: "-78.70058000",
  },
  {
    id: 114900,
    name: "Culpeper",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.47318000",
    longitude: "-77.99666000",
  },
  {
    id: 114901,
    name: "Culpeper County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.48606000",
    longitude: "-77.95589000",
  },
  {
    id: 114908,
    name: "Cumberland",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.49598000",
    longitude: "-78.24527000",
  },
  {
    id: 114917,
    name: "Cumberland County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.51210000",
    longitude: "-78.24496000",
  },
  {
    id: 114970,
    name: "Dahlgren",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.33124000",
    longitude: "-77.05109000",
  },
  {
    id: 114981,
    name: "Dale City",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.63706000",
    longitude: "-77.31109000",
  },
  {
    id: 114984,
    name: "Daleville",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.40986000",
    longitude: "-79.91254000",
  },
  {
    id: 115039,
    name: "Danville",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.58597000",
    longitude: "-79.39502000",
  },
  {
    id: 115094,
    name: "Dayton",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.41485000",
    longitude: "-78.93864000",
  },
  {
    id: 115228,
    name: "Deltaville",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.55486000",
    longitude: "-76.33689000",
  },
  {
    id: 115322,
    name: "Dickenson County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.12574000",
    longitude: "-82.35035000",
  },
  {
    id: 115352,
    name: "Dinwiddie County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.07590000",
    longitude: "-77.63236000",
  },
  {
    id: 115405,
    name: "Dooms",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.10902000",
    longitude: "-78.85752000",
  },
  {
    id: 115468,
    name: "Dranesville",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "39.00067000",
    longitude: "-77.34582000",
  },
  {
    id: 115484,
    name: "Dryden",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.77759000",
    longitude: "-82.94155000",
  },
  {
    id: 115493,
    name: "Dublin",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.10568000",
    longitude: "-80.68534000",
  },
  {
    id: 115511,
    name: "Dulles Town Center",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "39.03761000",
    longitude: "-77.41582000",
  },
  {
    id: 115516,
    name: "Dumbarton",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.60376000",
    longitude: "-77.49137000",
  },
  {
    id: 115517,
    name: "Dumfries",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.56762000",
    longitude: "-77.32804000",
  },
  {
    id: 115549,
    name: "Dunn Loring",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.89344000",
    longitude: "-77.22165000",
  },
  {
    id: 115679,
    name: "East Hampton",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.03737000",
    longitude: "-76.33161000",
  },
  {
    id: 115690,
    name: "East Highland Park",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.58098000",
    longitude: "-77.40693000",
  },
  {
    id: 115704,
    name: "East Lexington",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.79291000",
    longitude: "-79.42532000",
  },
  {
    id: 115798,
    name: "Eastville",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.35264000",
    longitude: "-75.94576000",
  },
  {
    id: 115871,
    name: "Edinburg",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.82095000",
    longitude: "-78.56585000",
  },
  {
    id: 116014,
    name: "Elkton",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.40790000",
    longitude: "-78.62363000",
  },
  {
    id: 116109,
    name: "Emory",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.77289000",
    longitude: "-81.83623000",
  },
  {
    id: 116112,
    name: "Emporia",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.68598000",
    longitude: "-77.54248000",
  },
  {
    id: 116145,
    name: "Enon",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.33070000",
    longitude: "-77.32276000",
  },
  {
    id: 116193,
    name: "Essex County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.93906000",
    longitude: "-76.94090000",
  },
  {
    id: 116220,
    name: "Ettrick",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.24015000",
    longitude: "-77.42998000",
  },
  {
    id: 116275,
    name: "Exmore",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.53180000",
    longitude: "-75.82299000",
  },
  {
    id: 116303,
    name: "Fairfax",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.84622000",
    longitude: "-77.30637000",
  },
  {
    id: 116307,
    name: "Fairfax County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.83469000",
    longitude: "-77.27622000",
  },
  {
    id: 116308,
    name: "Fairfax Station",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.80095000",
    longitude: "-77.32554000",
  },
  {
    id: 116330,
    name: "Fairlawn",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.14846000",
    longitude: "-80.57839000",
  },
  {
    id: 116380,
    name: "Falls Church",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.88233000",
    longitude: "-77.17109000",
  },
  {
    id: 116387,
    name: "Falmouth",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.32402000",
    longitude: "-77.46832000",
  },
  {
    id: 116427,
    name: "Farmville",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.30210000",
    longitude: "-78.39194000",
  },
  {
    id: 116436,
    name: "Fauquier County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.73855000",
    longitude: "-77.80927000",
  },
  {
    id: 116497,
    name: "Ferrum",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.92292000",
    longitude: "-80.01337000",
  },
  {
    id: 116514,
    name: "Fincastle",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.49930000",
    longitude: "-79.87726000",
  },
  {
    id: 116530,
    name: "Fishersville",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.09902000",
    longitude: "-78.96919000",
  },
  {
    id: 116596,
    name: "Floris",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.93706000",
    longitude: "-77.41277000",
  },
  {
    id: 116605,
    name: "Floyd",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.91124000",
    longitude: "-80.32005000",
  },
  {
    id: 116609,
    name: "Floyd County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.93149000",
    longitude: "-80.36255000",
  },
  {
    id: 116614,
    name: "Fluvanna County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.84187000",
    longitude: "-78.27745000",
  },
  {
    id: 116644,
    name: "Forest",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.36375000",
    longitude: "-79.28975000",
  },
  {
    id: 116691,
    name: "Fort Belvoir",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.71190000",
    longitude: "-77.14589000",
  },
  {
    id: 116718,
    name: "Fort Hunt",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.73289000",
    longitude: "-77.05803000",
  },
  {
    id: 116724,
    name: "Fort Lee",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.24694000",
    longitude: "-77.33442000",
  },
  {
    id: 116816,
    name: "Franconia",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.78206000",
    longitude: "-77.14637000",
  },
  {
    id: 116831,
    name: "Franklin",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.67765000",
    longitude: "-76.92246000",
  },
  {
    id: 116856,
    name: "Franklin County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.99194000",
    longitude: "-79.88104000",
  },
  {
    id: 116889,
    name: "Frederick County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "39.20456000",
    longitude: "-78.26258000",
  },
  {
    id: 116891,
    name: "Fredericksburg",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.30318000",
    longitude: "-77.46054000",
  },
  {
    id: 116960,
    name: "Front Royal",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.91817000",
    longitude: "-78.19444000",
  },
  {
    id: 117018,
    name: "Gainesville",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.79567000",
    longitude: "-77.61388000",
  },
  {
    id: 117020,
    name: "Galax",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.66124000",
    longitude: "-80.92397000",
  },
  {
    id: 117118,
    name: "Gate City",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.63788000",
    longitude: "-82.58099000",
  },
  {
    id: 117211,
    name: "Giles County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.31403000",
    longitude: "-80.70374000",
  },
  {
    id: 117232,
    name: "Glade Spring",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.79122000",
    longitude: "-81.77123000",
  },
  {
    id: 117248,
    name: "Glasgow",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.63402000",
    longitude: "-79.45031000",
  },
  {
    id: 117259,
    name: "Glen Allen",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.66598000",
    longitude: "-77.50637000",
  },
  {
    id: 117325,
    name: "Gloucester County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.40121000",
    longitude: "-76.52297000",
  },
  {
    id: 117326,
    name: "Gloucester Courthouse",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.40986000",
    longitude: "-76.52662000",
  },
  {
    id: 117327,
    name: "Gloucester Point",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.25403000",
    longitude: "-76.49689000",
  },
  {
    id: 117375,
    name: "Goochland",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.68431000",
    longitude: "-77.88527000",
  },
  {
    id: 117376,
    name: "Goochland County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.72198000",
    longitude: "-77.91636000",
  },
  {
    id: 117401,
    name: "Gordonsville",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.13736000",
    longitude: "-78.18778000",
  },
  {
    id: 117564,
    name: "Grayson County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.65659000",
    longitude: "-81.22505000",
  },
  {
    id: 117572,
    name: "Great Falls",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.99817000",
    longitude: "-77.28832000",
  },
  {
    id: 117617,
    name: "Greenbriar",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.87345000",
    longitude: "-77.40082000",
  },
  {
    id: 117640,
    name: "Greene County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.29760000",
    longitude: "-78.46688000",
  },
  {
    id: 117673,
    name: "Greensville County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.67585000",
    longitude: "-77.55958000",
  },
  {
    id: 117726,
    name: "Gretna",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.95375000",
    longitude: "-79.35891000",
  },
  {
    id: 117753,
    name: "Grottoes",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.26735000",
    longitude: "-78.82586000",
  },
  {
    id: 117763,
    name: "Groveton",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.76734000",
    longitude: "-77.08470000",
  },
  {
    id: 117768,
    name: "Grundy",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.27789000",
    longitude: "-82.09902000",
  },
  {
    id: 117860,
    name: "Halifax",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.76597000",
    longitude: "-78.92834000",
  },
  {
    id: 117863,
    name: "Halifax County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.76695000",
    longitude: "-78.93662000",
  },
  {
    id: 117919,
    name: "Hampden Sydney",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.24237000",
    longitude: "-78.45972000",
  },
  {
    id: 117931,
    name: "Hampton",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.02987000",
    longitude: "-76.34522000",
  },
  {
    id: 117969,
    name: "Hanover",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.76653000",
    longitude: "-77.37026000",
  },
  {
    id: 117974,
    name: "Hanover County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.76015000",
    longitude: "-77.49087000",
  },
  {
    id: 118070,
    name: "Harrisonburg",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.44957000",
    longitude: "-78.86892000",
  },
  {
    id: 118184,
    name: "Hayfield",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.75178000",
    longitude: "-77.13581000",
  },
  {
    id: 118187,
    name: "Haymarket",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.81206000",
    longitude: "-77.63638000",
  },
  {
    id: 118225,
    name: "Heathsville",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.91763000",
    longitude: "-76.47217000",
  },
  {
    id: 118294,
    name: "Henrico County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.45771000",
    longitude: "-77.29646000",
  },
  {
    id: 118303,
    name: "Henry County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.68470000",
    longitude: "-79.98152000",
  },
  {
    id: 118306,
    name: "Henry Fork",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.96625000",
    longitude: "-79.87031000",
  },
  {
    id: 118336,
    name: "Herndon",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.96955000",
    longitude: "-77.38610000",
  },
  {
    id: 118404,
    name: "Highland County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.36233000",
    longitude: "-79.56854000",
  },
  {
    id: 118415,
    name: "Highland Springs",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.54598000",
    longitude: "-77.32776000",
  },
  {
    id: 118468,
    name: "Hillsville",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.76263000",
    longitude: "-80.73479000",
  },
  {
    id: 118546,
    name: "Hollins",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.34125000",
    longitude: "-79.94310000",
  },
  {
    id: 118564,
    name: "Hollymead",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.11708000",
    longitude: "-78.44168000",
  },
  {
    id: 118612,
    name: "Honaker",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.01622000",
    longitude: "-81.97429000",
  },
  {
    id: 118649,
    name: "Hopewell",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.30432000",
    longitude: "-77.28720000",
  },
  {
    id: 118671,
    name: "Horse Pasture",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.62847000",
    longitude: "-79.95087000",
  },
  {
    id: 118799,
    name: "Huntington",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.79234000",
    longitude: "-77.07081000",
  },
  {
    id: 118832,
    name: "Hurt",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.09264000",
    longitude: "-79.29641000",
  },
  {
    id: 118845,
    name: "Hybla Valley",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.74761000",
    longitude: "-77.08303000",
  },
  {
    id: 118876,
    name: "Idylwood",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.89511000",
    longitude: "-77.21165000",
  },
  {
    id: 118894,
    name: "Independence",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.62245000",
    longitude: "-81.15309000",
  },
  {
    id: 118899,
    name: "Independent Hill",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.63595000",
    longitude: "-77.43776000",
  },
  {
    id: 119033,
    name: "Isle of Wight County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.90673000",
    longitude: "-76.70913000",
  },
  {
    id: 119116,
    name: "James City County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.31332000",
    longitude: "-76.77376000",
  },
  {
    id: 119167,
    name: "Jefferson",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.86456000",
    longitude: "-77.18776000",
  },
  {
    id: 119289,
    name: "Jolivue",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.10985000",
    longitude: "-79.07308000",
  },
  {
    id: 119311,
    name: "Jonesville",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.68898000",
    longitude: "-83.11100000",
  },
  {
    id: 119443,
    name: "Kenbridge",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.96209000",
    longitude: "-78.12500000",
  },
  {
    id: 119563,
    name: "Kilmarnock",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.71041000",
    longitude: "-76.37967000",
  },
  {
    id: 119588,
    name: "King and Queen County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.71862000",
    longitude: "-76.89527000",
  },
  {
    id: 119589,
    name: "King and Queen Court House",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.66986000",
    longitude: "-76.87746000",
  },
  {
    id: 119584,
    name: "King George",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.26818000",
    longitude: "-77.18442000",
  },
  {
    id: 119585,
    name: "King George County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.27374000",
    longitude: "-77.15651000",
  },
  {
    id: 119586,
    name: "King William",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.68736000",
    longitude: "-77.01358000",
  },
  {
    id: 119587,
    name: "King William County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.70660000",
    longitude: "-77.08839000",
  },
  {
    id: 119604,
    name: "Kings Park",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.80622000",
    longitude: "-77.24332000",
  },
  {
    id: 119606,
    name: "Kings Park West",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.81443000",
    longitude: "-77.29582000",
  },
  {
    id: 119868,
    name: "Lake Barcroft",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.84789000",
    longitude: "-77.15581000",
  },
  {
    id: 119947,
    name: "Lake Monticello",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.92320000",
    longitude: "-78.33473000",
  },
  {
    id: 119968,
    name: "Lake Ridge",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.68789000",
    longitude: "-77.29776000",
  },
  {
    id: 120031,
    name: "Lakeside",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.60765000",
    longitude: "-77.47693000",
  },
  {
    id: 120091,
    name: "Lancaster County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.70171000",
    longitude: "-76.42023000",
  },
  {
    id: 120191,
    name: "Laurel",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.64292000",
    longitude: "-77.50887000",
  },
  {
    id: 120197,
    name: "Laurel Hill",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.71663000",
    longitude: "-77.23686000",
  },
  {
    id: 120245,
    name: "Lawrenceville",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.75765000",
    longitude: "-77.84694000",
  },
  {
    id: 120253,
    name: "Laymantown",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.36569000",
    longitude: "-79.85754000",
  },
  {
    id: 120288,
    name: "Lebanon",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.90094000",
    longitude: "-82.08013000",
  },
  {
    id: 120315,
    name: "Lee County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.70545000",
    longitude: "-83.12853000",
  },
  {
    id: 120326,
    name: "Leesburg",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "39.11566000",
    longitude: "-77.56360000",
  },
  {
    id: 120447,
    name: "Lexington",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.78402000",
    longitude: "-79.44282000",
  },
  {
    id: 120549,
    name: "Lincolnia",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.81845000",
    longitude: "-77.14331000",
  },
  {
    id: 120591,
    name: "Linton Hall",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.75984000",
    longitude: "-77.57499000",
  },
  {
    id: 120669,
    name: "Loch Lomond",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.78623000",
    longitude: "-77.47804000",
  },
  {
    id: 120777,
    name: "Lorton",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.70428000",
    longitude: "-77.22776000",
  },
  {
    id: 120801,
    name: "Loudoun County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "39.09068000",
    longitude: "-77.63572000",
  },
  {
    id: 120802,
    name: "Loudoun Valley Estates",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.98081000",
    longitude: "-77.50790000",
  },
  {
    id: 120805,
    name: "Louisa",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.02514000",
    longitude: "-78.00416000",
  },
  {
    id: 120806,
    name: "Louisa County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.97821000",
    longitude: "-77.96298000",
  },
  {
    id: 120827,
    name: "Lovettsville",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "39.27260000",
    longitude: "-77.63666000",
  },
  {
    id: 120830,
    name: "Lovingston",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.75986000",
    longitude: "-78.87086000",
  },
  {
    id: 120844,
    name: "Lowes Island",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "39.05983000",
    longitude: "-77.35221000",
  },
  {
    id: 120886,
    name: "Lunenburg",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.96098000",
    longitude: "-78.26555000",
  },
  {
    id: 120889,
    name: "Lunenburg County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.94621000",
    longitude: "-78.24057000",
  },
  {
    id: 120890,
    name: "Luray",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.66540000",
    longitude: "-78.45945000",
  },
  {
    id: 120916,
    name: "Lynchburg",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.41375000",
    longitude: "-79.14225000",
  },
  {
    id: 120920,
    name: "Lyndhurst",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.02930000",
    longitude: "-78.94502000",
  },
  {
    id: 120991,
    name: "Madison",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.38041000",
    longitude: "-78.25750000",
  },
  {
    id: 121013,
    name: "Madison County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.41369000",
    longitude: "-78.27924000",
  },
  {
    id: 121020,
    name: "Madison Heights",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.43098000",
    longitude: "-79.12308000",
  },
  {
    id: 121082,
    name: "Manassas",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.75095000",
    longitude: "-77.47527000",
  },
  {
    id: 121083,
    name: "Manassas Park",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.78400000",
    longitude: "-77.46971000",
  },
  {
    id: 121160,
    name: "Mantua",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.85372000",
    longitude: "-77.25943000",
  },
  {
    id: 121241,
    name: "Marion",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.83484000",
    longitude: "-81.51484000",
  },
  {
    id: 121306,
    name: "Marshall",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.86484000",
    longitude: "-77.85777000",
  },
  {
    id: 121350,
    name: "Martinsville",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.69153000",
    longitude: "-79.87254000",
  },
  {
    id: 121389,
    name: "Massanetta Springs",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.40040000",
    longitude: "-78.83419000",
  },
  {
    id: 121390,
    name: "Massanutten",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.40957000",
    longitude: "-78.73780000",
  },
  {
    id: 121403,
    name: "Mathews",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.43708000",
    longitude: "-76.31994000",
  },
  {
    id: 121404,
    name: "Mathews County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.41731000",
    longitude: "-76.27129000",
  },
  {
    id: 121406,
    name: "Matoaca",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.23043000",
    longitude: "-77.47749000",
  },
  {
    id: 121527,
    name: "McLean",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.93428000",
    longitude: "-77.17748000",
  },
  {
    id: 121566,
    name: "Meadowbrook",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.44882000",
    longitude: "-77.47353000",
  },
  {
    id: 121583,
    name: "Mechanicsville",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.60876000",
    longitude: "-77.37331000",
  },
  {
    id: 121588,
    name: "Mecklenburg County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.68036000",
    longitude: "-78.36273000",
  },
  {
    id: 121706,
    name: "Merrifield",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.87428000",
    longitude: "-77.22693000",
  },
  {
    id: 121709,
    name: "Merrimac",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.18957000",
    longitude: "-80.42561000",
  },
  {
    id: 121778,
    name: "Middlesex County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.59737000",
    longitude: "-76.57814000",
  },
  {
    id: 121789,
    name: "Middletown",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "39.02761000",
    longitude: "-78.28056000",
  },
  {
    id: 122105,
    name: "Montclair",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.61095000",
    longitude: "-77.33971000",
  },
  {
    id: 122119,
    name: "Monterey",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.41234000",
    longitude: "-79.58060000",
  },
  {
    id: 122151,
    name: "Montgomery County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.17404000",
    longitude: "-80.38700000",
  },
  {
    id: 122179,
    name: "Montrose",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.52070000",
    longitude: "-77.37831000",
  },
  {
    id: 122186,
    name: "Montross",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.09513000",
    longitude: "-76.82746000",
  },
  {
    id: 122310,
    name: "Motley",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.06959000",
    longitude: "-79.34114000",
  },
  {
    id: 122346,
    name: "Mount Hermon",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.67847000",
    longitude: "-79.42225000",
  },
  {
    id: 122357,
    name: "Mount Jackson",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.74595000",
    longitude: "-78.64224000",
  },
  {
    id: 122404,
    name: "Mount Vernon",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.71919000",
    longitude: "-77.10726000",
  },
  {
    id: 122426,
    name: "Mountain Road",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.75958000",
    longitude: "-78.98696000",
  },
  {
    id: 122566,
    name: "Narrows",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.33151000",
    longitude: "-80.81119000",
  },
  {
    id: 122623,
    name: "Nellysford",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.89042000",
    longitude: "-78.87224000",
  },
  {
    id: 122627,
    name: "Nelson County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.78741000",
    longitude: "-78.88676000",
  },
  {
    id: 122659,
    name: "New Baltimore",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.76734000",
    longitude: "-77.72833000",
  },
  {
    id: 122686,
    name: "New Castle",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.50013000",
    longitude: "-80.11088000",
  },
  {
    id: 122734,
    name: "New Kent",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.51765000",
    longitude: "-76.97886000",
  },
  {
    id: 122735,
    name: "New Kent County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.50514000",
    longitude: "-76.99713000",
  },
  {
    id: 122751,
    name: "New Market",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.64790000",
    longitude: "-78.67141000",
  },
  {
    id: 122830,
    name: "Newington",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.73845000",
    longitude: "-77.18498000",
  },
  {
    id: 122856,
    name: "Newport News",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.08339000",
    longitude: "-76.46965000",
  },
  {
    id: 122928,
    name: "Nokesville",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.69873000",
    longitude: "-77.57971000",
  },
  {
    id: 122941,
    name: "Norfolk",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.84681000",
    longitude: "-76.28522000",
  },
  {
    id: 123105,
    name: "North Shore",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.08209000",
    longitude: "-79.65836000",
  },
  {
    id: 123110,
    name: "North Springfield",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.80428000",
    longitude: "-77.20470000",
  },
  {
    id: 123137,
    name: "Northampton County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.30078000",
    longitude: "-75.92854000",
  },
  {
    id: 123167,
    name: "Northumberland County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.88244000",
    longitude: "-76.47171000",
  },
  {
    id: 123183,
    name: "Norton",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.93343000",
    longitude: "-82.62905000",
  },
  {
    id: 123206,
    name: "Nottoway County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.14306000",
    longitude: "-78.05126000",
  },
  {
    id: 123238,
    name: "Oak Grove",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.98400000",
    longitude: "-77.40388000",
  },
  {
    id: 123246,
    name: "Oak Hill",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.92580000",
    longitude: "-77.40156000",
  },
  {
    id: 123305,
    name: "Oakton",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.88095000",
    longitude: "-77.30082000",
  },
  {
    id: 123322,
    name: "Occoquan",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.68373000",
    longitude: "-77.26026000",
  },
  {
    id: 123469,
    name: "Onancock",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.71180000",
    longitude: "-75.74910000",
  },
  {
    id: 123506,
    name: "Orange",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.24541000",
    longitude: "-78.11083000",
  },
  {
    id: 123518,
    name: "Orange County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.24624000",
    longitude: "-78.01349000",
  },
  {
    id: 123718,
    name: "Page County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.61998000",
    longitude: "-78.48413000",
  },
  {
    id: 123773,
    name: "Palmyra",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.86097000",
    longitude: "-78.26334000",
  },
  {
    id: 123802,
    name: "Pannill Fork",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.70903000",
    longitude: "-80.01310000",
  },
  {
    id: 123809,
    name: "Pantops",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.03379000",
    longitude: "-78.45507000",
  },
  {
    id: 123909,
    name: "Passapatanzy",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.29735000",
    longitude: "-77.31415000",
  },
  {
    id: 123912,
    name: "Patrick County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.67833000",
    longitude: "-80.28435000",
  },
  {
    id: 123913,
    name: "Patrick Springs",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.64180000",
    longitude: "-80.19505000",
  },
  {
    id: 123962,
    name: "Pearisburg",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.32673000",
    longitude: "-80.73702000",
  },
  {
    id: 124006,
    name: "Pembroke",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.31957000",
    longitude: "-80.63895000",
  },
  {
    id: 124032,
    name: "Pennington Gap",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.75842000",
    longitude: "-83.02711000",
  },
  {
    id: 124105,
    name: "Petersburg",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.22793000",
    longitude: "-77.40193000",
  },
  {
    id: 124205,
    name: "Pimmit Hills",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.91289000",
    longitude: "-77.20081000",
  },
  {
    id: 124306,
    name: "Pittsylvania County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.82133000",
    longitude: "-79.39711000",
  },
  {
    id: 124393,
    name: "Plum Creek",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.12984000",
    longitude: "-80.50060000",
  },
  {
    id: 124489,
    name: "Poquoson",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.12237000",
    longitude: "-76.34578000",
  },
  {
    id: 124569,
    name: "Portsmouth",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.83543000",
    longitude: "-76.29827000",
  },
  {
    id: 124572,
    name: "Portsmouth Heights",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.82098000",
    longitude: "-76.36883000",
  },
  {
    id: 124584,
    name: "Potomac Mills",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.64595000",
    longitude: "-77.29415000",
  },
  {
    id: 124618,
    name: "Powhatan County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.55020000",
    longitude: "-77.91519000",
  },
  {
    id: 124665,
    name: "Prices Fork",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.20985000",
    longitude: "-80.49005000",
  },
  {
    id: 124672,
    name: "Prince Edward County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.22430000",
    longitude: "-78.44108000",
  },
  {
    id: 124674,
    name: "Prince George",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.22043000",
    longitude: "-77.28803000",
  },
  {
    id: 124675,
    name: "Prince George County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.18653000",
    longitude: "-77.22413000",
  },
  {
    id: 124677,
    name: "Prince William County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.70167000",
    longitude: "-77.47766000",
  },
  {
    id: 124734,
    name: "Pulaski",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.04790000",
    longitude: "-80.77979000",
  },
  {
    id: 124742,
    name: "Pulaski County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.06361000",
    longitude: "-80.71434000",
  },
  {
    id: 124754,
    name: "Purcellville",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "39.13677000",
    longitude: "-77.71472000",
  },
  {
    id: 124780,
    name: "Quantico Station",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.52263000",
    longitude: "-77.31834000",
  },
  {
    id: 124816,
    name: "Radford",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.13179000",
    longitude: "-80.57645000",
  },
  {
    id: 124898,
    name: "Rappahannock County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.68471000",
    longitude: "-78.15925000",
  },
  {
    id: 124904,
    name: "Raven",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.08706000",
    longitude: "-81.85512000",
  },
  {
    id: 124911,
    name: "Ravensworth",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.80400000",
    longitude: "-77.22054000",
  },
  {
    id: 125036,
    name: "Reston",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.96872000",
    longitude: "-77.34110000",
  },
  {
    id: 125082,
    name: "Richlands",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.09317000",
    longitude: "-81.79373000",
  },
  {
    id: 125088,
    name: "Richmond",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.55376000",
    longitude: "-77.46026000",
  },
  {
    id: 125100,
    name: "Richmond County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.93705000",
    longitude: "-76.72968000",
  },
  {
    id: 125228,
    name: "Roanoke",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.27097000",
    longitude: "-79.94143000",
  },
  {
    id: 125231,
    name: "Roanoke County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.20907000",
    longitude: "-80.05085000",
  },
  {
    id: 125292,
    name: "Rockbridge County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.81461000",
    longitude: "-79.44758000",
  },
  {
    id: 125306,
    name: "Rockingham County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.51213000",
    longitude: "-78.87576000",
  },
  {
    id: 125336,
    name: "Rocky Mount",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.99764000",
    longitude: "-79.89198000",
  },
  {
    id: 125405,
    name: "Rose Hill",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.78872000",
    longitude: "-77.11276000",
  },
  {
    id: 125452,
    name: "Rosslyn",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.89678000",
    longitude: "-77.07248000",
  },
  {
    id: 125502,
    name: "Ruckersville",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.23319000",
    longitude: "-78.36917000",
  },
  {
    id: 125518,
    name: "Rural Retreat",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.89373000",
    longitude: "-81.27593000",
  },
  {
    id: 125524,
    name: "Rushmere",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.06681000",
    longitude: "-76.67635000",
  },
  {
    id: 125539,
    name: "Russell County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.93376000",
    longitude: "-82.09564000",
  },
  {
    id: 125546,
    name: "Rustburg",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.27681000",
    longitude: "-79.10085000",
  },
  {
    id: 125734,
    name: "Salem",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.29347000",
    longitude: "-80.05476000",
  },
  {
    id: 125771,
    name: "Saltville",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.88150000",
    longitude: "-81.76206000",
  },
  {
    id: 125773,
    name: "Saluda",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.60597000",
    longitude: "-76.59495000",
  },
  {
    id: 125873,
    name: "Sandston",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.52348000",
    longitude: "-77.31581000",
  },
  {
    id: 126050,
    name: "Scott County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.71422000",
    longitude: "-82.60298000",
  },
  {
    id: 126171,
    name: "Seven Corners",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.87206000",
    longitude: "-77.15526000",
  },
  {
    id: 126252,
    name: "Shawnee Land",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "39.19149000",
    longitude: "-78.34556000",
  },
  {
    id: 126254,
    name: "Shawsville",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.16847000",
    longitude: "-80.25532000",
  },
  {
    id: 126303,
    name: "Shenandoah",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.48512000",
    longitude: "-78.62502000",
  },
  {
    id: 126305,
    name: "Shenandoah County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.85839000",
    longitude: "-78.57060000",
  },
  {
    id: 126306,
    name: "Shenandoah Farms",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.98178000",
    longitude: "-78.07555000",
  },
  {
    id: 126377,
    name: "Short Pump",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.65042000",
    longitude: "-77.61249000",
  },
  {
    id: 126519,
    name: "Smithfield",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.98237000",
    longitude: "-76.63107000",
  },
  {
    id: 126537,
    name: "Smyth County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.84388000",
    longitude: "-81.53702000",
  },
  {
    id: 126628,
    name: "South Boston",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.69875000",
    longitude: "-78.90140000",
  },
  {
    id: 126671,
    name: "South Hill",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.72653000",
    longitude: "-78.12889000",
  },
  {
    id: 126716,
    name: "South Riding",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.92094000",
    longitude: "-77.50388000",
  },
  {
    id: 126731,
    name: "South Suffolk",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.71709000",
    longitude: "-76.59023000",
  },
  {
    id: 126759,
    name: "Southampton County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.72040000",
    longitude: "-77.10609000",
  },
  {
    id: 126767,
    name: "Southern Gateway",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.34507000",
    longitude: "-77.50352000",
  },
  {
    id: 126847,
    name: "Spotsylvania County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.18502000",
    longitude: "-77.65597000",
  },
  {
    id: 126848,
    name: "Spotsylvania Courthouse",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.19791000",
    longitude: "-77.58777000",
  },
  {
    id: 126896,
    name: "Springfield",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.78928000",
    longitude: "-77.18720000",
  },
  {
    id: 126916,
    name: "Springville",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.19651000",
    longitude: "-81.40288000",
  },
  {
    id: 126933,
    name: "Stafford",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.42207000",
    longitude: "-77.40832000",
  },
  {
    id: 126937,
    name: "Stafford County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.42070000",
    longitude: "-77.45743000",
  },
  {
    id: 126948,
    name: "Stanardsville",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.29735000",
    longitude: "-78.44001000",
  },
  {
    id: 126959,
    name: "Stanley",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.57540000",
    longitude: "-78.50251000",
  },
  {
    id: 126963,
    name: "Stanleytown",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.74430000",
    longitude: "-79.96282000",
  },
  {
    id: 127001,
    name: "Staunton",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.14991000",
    longitude: "-79.07320000",
  },
  {
    id: 127020,
    name: "Stephens City",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "39.08344000",
    longitude: "-78.21806000",
  },
  {
    id: 127027,
    name: "Sterling",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "39.00622000",
    longitude: "-77.42860000",
  },
  {
    id: 127120,
    name: "Strasburg",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.98872000",
    longitude: "-78.35862000",
  },
  {
    id: 127144,
    name: "Stuart",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.64097000",
    longitude: "-80.26561000",
  },
  {
    id: 127145,
    name: "Stuarts Draft",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.03014000",
    longitude: "-79.03364000",
  },
  {
    id: 127163,
    name: "Sudley",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.79289000",
    longitude: "-77.49749000",
  },
  {
    id: 127166,
    name: "Suffolk",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.72836000",
    longitude: "-76.58496000",
  },
  {
    id: 127175,
    name: "Sugarland Run",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "39.03761000",
    longitude: "-77.37526000",
  },
  {
    id: 127292,
    name: "Surry",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.13793000",
    longitude: "-76.83524000",
  },
  {
    id: 127295,
    name: "Surry County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.11691000",
    longitude: "-76.88831000",
  },
  {
    id: 127300,
    name: "Sussex",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.91515000",
    longitude: "-77.27914000",
  },
  {
    id: 127304,
    name: "Sussex County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.92175000",
    longitude: "-77.26179000",
  },
  {
    id: 127425,
    name: "Tappahannock",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.92541000",
    longitude: "-76.85913000",
  },
  {
    id: 127469,
    name: "Tazewell",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.11484000",
    longitude: "-81.51955000",
  },
  {
    id: 127470,
    name: "Tazewell County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.12497000",
    longitude: "-81.56066000",
  },
  {
    id: 127658,
    name: "Timberlake",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.32070000",
    longitude: "-79.25753000",
  },
  {
    id: 127660,
    name: "Timberville",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.63901000",
    longitude: "-78.77391000",
  },
  {
    id: 127818,
    name: "Triangle",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.54679000",
    longitude: "-77.33665000",
  },
  {
    id: 127868,
    name: "Tuckahoe",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.59015000",
    longitude: "-77.55638000",
  },
  {
    id: 127933,
    name: "Twin Lakes",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.24927000",
    longitude: "-78.44378000",
  },
  {
    id: 127952,
    name: "Tysons Corner",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.91872000",
    longitude: "-77.23109000",
  },
  {
    id: 128006,
    name: "Union Hall",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.01875000",
    longitude: "-79.68642000",
  },
  {
    id: 128026,
    name: "University Center",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "39.05705000",
    longitude: "-77.44415000",
  },
  {
    id: 128202,
    name: "Verona",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.20208000",
    longitude: "-79.00836000",
  },
  {
    id: 128221,
    name: "Victoria",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.99487000",
    longitude: "-78.22722000",
  },
  {
    id: 128234,
    name: "Vienna",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.90122000",
    longitude: "-77.26526000",
  },
  {
    id: 128281,
    name: "Vinton",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.28097000",
    longitude: "-79.89698000",
  },
  {
    id: 128288,
    name: "Virginia Beach",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.85293000",
    longitude: "-75.97799000",
  },
  {
    id: 128469,
    name: "Warm Springs",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.04624000",
    longitude: "-79.79061000",
  },
  {
    id: 128492,
    name: "Warren County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.90878000",
    longitude: "-78.20746000",
  },
  {
    id: 128507,
    name: "Warrenton",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.71345000",
    longitude: "-77.79527000",
  },
  {
    id: 128518,
    name: "Warsaw",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.95874000",
    longitude: "-76.75801000",
  },
  {
    id: 128543,
    name: "Washington",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.71345000",
    longitude: "-78.15944000",
  },
  {
    id: 128567,
    name: "Washington County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.72448000",
    longitude: "-81.95966000",
  },
  {
    id: 128644,
    name: "Wattsville",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.93401000",
    longitude: "-75.49965000",
  },
  {
    id: 128667,
    name: "Waverly",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.03598000",
    longitude: "-77.09524000",
  },
  {
    id: 128709,
    name: "Waynesboro",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.06847000",
    longitude: "-78.88947000",
  },
  {
    id: 128728,
    name: "Weber City",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.75514000",
    longitude: "-78.28389000",
  },
  {
    id: 128867,
    name: "West Falls Church",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.89094000",
    longitude: "-77.18443000",
  },
  {
    id: 128877,
    name: "West Gate",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.78289000",
    longitude: "-77.49749000",
  },
  {
    id: 128924,
    name: "West Lynchburg",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.40320000",
    longitude: "-79.17808000",
  },
  {
    id: 128960,
    name: "West Point",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.53153000",
    longitude: "-76.79635000",
  },
  {
    id: 128984,
    name: "West Springfield",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.77261000",
    longitude: "-77.22109000",
  },
  {
    id: 129055,
    name: "Westmoreland County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.11282000",
    longitude: "-76.79991000",
  },
  {
    id: 129099,
    name: "Weyers Cave",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.28846000",
    longitude: "-78.91308000",
  },
  {
    id: 129268,
    name: "Williamsburg",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.27070000",
    longitude: "-76.70746000",
  },
  {
    id: 129356,
    name: "Winchester",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "39.18566000",
    longitude: "-78.16333000",
  },
  {
    id: 129381,
    name: "Windsor",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.80848000",
    longitude: "-76.74412000",
  },
  {
    id: 129459,
    name: "Wise",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.97593000",
    longitude: "-82.57571000",
  },
  {
    id: 129461,
    name: "Wise County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.97522000",
    longitude: "-82.62124000",
  },
  {
    id: 129474,
    name: "Wolf Trap",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.93983000",
    longitude: "-77.28609000",
  },
  {
    id: 129500,
    name: "Woodbridge",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.65817000",
    longitude: "-77.24970000",
  },
  {
    id: 129505,
    name: "Woodburn",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.84745000",
    longitude: "-77.23605000",
  },
  {
    id: 129525,
    name: "Woodlake",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.42106000",
    longitude: "-77.67931000",
  },
  {
    id: 129535,
    name: "Woodlawn",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.72235000",
    longitude: "-80.82285000",
  },
  {
    id: 129562,
    name: "Woodstock",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.88178000",
    longitude: "-78.50584000",
  },
  {
    id: 129630,
    name: "Wyndham",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.69848000",
    longitude: "-77.61249000",
  },
  {
    id: 129644,
    name: "Wythe County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.91713000",
    longitude: "-81.07859000",
  },
  {
    id: 129645,
    name: "Wytheville",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "36.94845000",
    longitude: "-81.08481000",
  },
  {
    id: 129696,
    name: "York County",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.22541000",
    longitude: "-76.52046000",
  },
  {
    id: 129703,
    name: "Yorkshire",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "38.79317000",
    longitude: "-77.44777000",
  },
  {
    id: 129705,
    name: "Yorktown",
    state_id: 1427,
    state_code: "VA",
    country_id: 233,
    country_code: "US",
    latitude: "37.23876000",
    longitude: "-76.50967000",
  },
  {
    id: 110979,
    name: "Aberdeen",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.97537000",
    longitude: "-123.81572000",
  },
  {
    id: 111027,
    name: "Adams County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.98338000",
    longitude: "-118.56050000",
  },
  {
    id: 111063,
    name: "Ahtanum",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.55957000",
    longitude: "-120.62201000",
  },
  {
    id: 111072,
    name: "Airway Heights",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.64461000",
    longitude: "-117.59327000",
  },
  {
    id: 111138,
    name: "Alderton",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.16955000",
    longitude: "-122.22928000",
  },
  {
    id: 111139,
    name: "Alderwood Manor",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.82204000",
    longitude: "-122.28207000",
  },
  {
    id: 111164,
    name: "Algona",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.27899000",
    longitude: "-122.25206000",
  },
  {
    id: 111205,
    name: "Allyn",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.38565000",
    longitude: "-122.82764000",
  },
  {
    id: 111266,
    name: "Amboy",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "45.91011000",
    longitude: "-122.44649000",
  },
  {
    id: 111278,
    name: "Ames Lake",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.63288000",
    longitude: "-121.96623000",
  },
  {
    id: 111297,
    name: "Anacortes",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.51260000",
    longitude: "-122.61267000",
  },
  {
    id: 111470,
    name: "Arlington",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.19871000",
    longitude: "-122.12514000",
  },
  {
    id: 111474,
    name: "Arlington Heights",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.20205000",
    longitude: "-122.06208000",
  },
  {
    id: 111501,
    name: "Artondale",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.29954000",
    longitude: "-122.62069000",
  },
  {
    id: 111550,
    name: "Asotin",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.33933000",
    longitude: "-117.04821000",
  },
  {
    id: 111551,
    name: "Asotin County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.19186000",
    longitude: "-117.20307000",
  },
  {
    id: 111632,
    name: "Auburn",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.30732000",
    longitude: "-122.22845000",
  },
  {
    id: 111654,
    name: "Ault Field",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.33812000",
    longitude: "-122.67441000",
  },
  {
    id: 111735,
    name: "Bainbridge Island",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.62621000",
    longitude: "-122.52124000",
  },
  {
    id: 111795,
    name: "Bangor Trident Base",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.72274000",
    longitude: "-122.71446000",
  },
  {
    id: 111813,
    name: "Barberton",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "45.69317000",
    longitude: "-122.59899000",
  },
  {
    id: 111879,
    name: "Basin City",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.59403000",
    longitude: "-119.15223000",
  },
  {
    id: 111907,
    name: "Battle Ground",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "45.78095000",
    longitude: "-122.53343000",
  },
  {
    id: 112055,
    name: "Belfair",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.45065000",
    longitude: "-122.82737000",
  },
  {
    id: 112117,
    name: "Bellevue",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.61038000",
    longitude: "-122.20068000",
  },
  {
    id: 112120,
    name: "Bellingham",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.75955000",
    longitude: "-122.48822000",
  },
  {
    id: 112198,
    name: "Benton City",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.26319000",
    longitude: "-119.48780000",
  },
  {
    id: 112207,
    name: "Benton County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.23978000",
    longitude: "-119.51120000",
  },
  {
    id: 112282,
    name: "Bethel",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.49398000",
    longitude: "-122.63125000",
  },
  {
    id: 112326,
    name: "Big Lake",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.40288000",
    longitude: "-122.24127000",
  },
  {
    id: 112353,
    name: "Birch Bay",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.91789000",
    longitude: "-122.74462000",
  },
  {
    id: 112374,
    name: "Black Diamond",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.30871000",
    longitude: "-122.00317000",
  },
  {
    id: 112404,
    name: "Blaine",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.99372000",
    longitude: "-122.74712000",
  },
  {
    id: 112547,
    name: "Bonney Lake",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.17705000",
    longitude: "-122.18651000",
  },
  {
    id: 112595,
    name: "Bothell",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.76232000",
    longitude: "-122.20540000",
  },
  {
    id: 112596,
    name: "Bothell East",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.80631000",
    longitude: "-122.18427000",
  },
  {
    id: 112597,
    name: "Bothell West",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.80527000",
    longitude: "-122.24064000",
  },
  {
    id: 112607,
    name: "Boulevard Park",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.48927000",
    longitude: "-122.31512000",
  },
  {
    id: 112723,
    name: "Bremerton",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.56732000",
    longitude: "-122.63264000",
  },
  {
    id: 112744,
    name: "Brewster",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.09598000",
    longitude: "-119.78062000",
  },
  {
    id: 112768,
    name: "Bridgeport",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.00820000",
    longitude: "-119.67116000",
  },
  {
    id: 112782,
    name: "Brier",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.78454000",
    longitude: "-122.27429000",
  },
  {
    id: 112922,
    name: "Browns Point",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.30038000",
    longitude: "-122.44124000",
  },
  {
    id: 112955,
    name: "Brush Prairie",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "45.73289000",
    longitude: "-122.54649000",
  },
  {
    id: 112964,
    name: "Bryant",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.23899000",
    longitude: "-122.15792000",
  },
  {
    id: 112967,
    name: "Bryn Mawr-Skyway",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.49430000",
    longitude: "-122.24092000",
  },
  {
    id: 112988,
    name: "Buckley",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.16316000",
    longitude: "-122.02678000",
  },
  {
    id: 113039,
    name: "Bunk Foss",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.96171000",
    longitude: "-122.09441000",
  },
  {
    id: 113048,
    name: "Burbank",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.19986000",
    longitude: "-119.01306000",
  },
  {
    id: 113052,
    name: "Burien",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.47038000",
    longitude: "-122.34679000",
  },
  {
    id: 113064,
    name: "Burley",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.41787000",
    longitude: "-122.63097000",
  },
  {
    id: 113076,
    name: "Burlington",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.47566000",
    longitude: "-122.32544000",
  },
  {
    id: 113240,
    name: "Camano",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.17399000",
    longitude: "-122.52821000",
  },
  {
    id: 113243,
    name: "Camas",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "45.58706000",
    longitude: "-122.39954000",
  },
  {
    id: 113338,
    name: "Canterwood",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.37510000",
    longitude: "-122.58930000",
  },
  {
    id: 113424,
    name: "Carnation",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.64788000",
    longitude: "-121.91401000",
  },
  {
    id: 113476,
    name: "Carson",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "45.72539000",
    longitude: "-121.81924000",
  },
  {
    id: 113517,
    name: "Cascade Valley",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.13459000",
    longitude: "-119.32808000",
  },
  {
    id: 113522,
    name: "Cashmere",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.52235000",
    longitude: "-120.46980000",
  },
  {
    id: 113550,
    name: "Castle Rock",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.27511000",
    longitude: "-122.90761000",
  },
  {
    id: 113569,
    name: "Cathcart",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.84788000",
    longitude: "-122.09929000",
  },
  {
    id: 113571,
    name: "Cathlamet",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.20317000",
    longitude: "-123.38318000",
  },
  {
    id: 113679,
    name: "Central Park",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.97343000",
    longitude: "-123.69239000",
  },
  {
    id: 113687,
    name: "Centralia",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.71621000",
    longitude: "-122.95430000",
  },
  {
    id: 113825,
    name: "Chehalis",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.66205000",
    longitude: "-122.96402000",
  },
  {
    id: 113826,
    name: "Chelan",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.84097000",
    longitude: "-120.01646000",
  },
  {
    id: 113827,
    name: "Chelan County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.86910000",
    longitude: "-120.61891000",
  },
  {
    id: 113840,
    name: "Cheney",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.48739000",
    longitude: "-117.57576000",
  },
  {
    id: 113924,
    name: "Chewelah",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.27629000",
    longitude: "-117.71552000",
  },
  {
    id: 113944,
    name: "Chico",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.61148000",
    longitude: "-122.71042000",
  },
  {
    id: 114083,
    name: "City of Sammamish",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.60444000",
    longitude: "-122.03768000",
  },
  {
    id: 114097,
    name: "Clallam County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.11044000",
    longitude: "-123.93432000",
  },
  {
    id: 114131,
    name: "Clark County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "45.77927000",
    longitude: "-122.48259000",
  },
  {
    id: 114150,
    name: "Clarkston",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.41629000",
    longitude: "-117.04557000",
  },
  {
    id: 114151,
    name: "Clarkston Heights-Vineland",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.38742000",
    longitude: "-117.08300000",
  },
  {
    id: 114207,
    name: "Cle Elum",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.19540000",
    longitude: "-120.93925000",
  },
  {
    id: 114212,
    name: "Clear Lake",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.46427000",
    longitude: "-122.23404000",
  },
  {
    id: 114221,
    name: "Clearview",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.83371000",
    longitude: "-122.12596000",
  },
  {
    id: 114321,
    name: "Clyde Hill",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.63177000",
    longitude: "-122.21790000",
  },
  {
    id: 114409,
    name: "Colfax",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.88017000",
    longitude: "-117.36435000",
  },
  {
    id: 114415,
    name: "College Place",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.04930000",
    longitude: "-118.38830000",
  },
  {
    id: 114484,
    name: "Columbia County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.29755000",
    longitude: "-117.90788000",
  },
  {
    id: 114506,
    name: "Colville",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.54657000",
    longitude: "-117.90554000",
  },
  {
    id: 114561,
    name: "Connell",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.66347000",
    longitude: "-118.86111000",
  },
  {
    id: 114685,
    name: "Cosmopolis",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.95537000",
    longitude: "-123.77378000",
  },
  {
    id: 114694,
    name: "Cottage Lake",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.74427000",
    longitude: "-122.07735000",
  },
  {
    id: 114710,
    name: "Coulee Dam",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.96543000",
    longitude: "-118.97613000",
  },
  {
    id: 114720,
    name: "Country Homes",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.74850000",
    longitude: "-117.40439000",
  },
  {
    id: 114727,
    name: "Coupeville",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.21982000",
    longitude: "-122.68628000",
  },
  {
    id: 114741,
    name: "Covington",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.35818000",
    longitude: "-122.12216000",
  },
  {
    id: 114749,
    name: "Cowlitz County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.19329000",
    longitude: "-122.68078000",
  },
  {
    id: 114828,
    name: "Crocker",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.08091000",
    longitude: "-122.10383000",
  },
  {
    id: 115000,
    name: "Dallesport",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "45.61734000",
    longitude: "-121.17952000",
  },
  {
    id: 115054,
    name: "Darrington",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.25539000",
    longitude: "-121.60151000",
  },
  {
    id: 115060,
    name: "Davenport",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.65405000",
    longitude: "-118.14997000",
  },
  {
    id: 115101,
    name: "Dayton",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.32375000",
    longitude: "-117.97244000",
  },
  {
    id: 115179,
    name: "Deer Park",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.95434000",
    longitude: "-117.47689000",
  },
  {
    id: 115272,
    name: "Des Moines",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.40177000",
    longitude: "-122.32429000",
  },
  {
    id: 115279,
    name: "Desert Aire",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.67930000",
    longitude: "-119.91727000",
  },
  {
    id: 115354,
    name: "Dishman",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.66007000",
    longitude: "-117.27596000",
  },
  {
    id: 115389,
    name: "Dollar Corner",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "45.78012000",
    longitude: "-122.60010000",
  },
  {
    id: 115436,
    name: "Douglas County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.73607000",
    longitude: "-119.69172000",
  },
  {
    id: 115489,
    name: "DuPont",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.09676000",
    longitude: "-122.63124000",
  },
  {
    id: 115582,
    name: "Duvall",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.74232000",
    longitude: "-121.98568000",
  },
  {
    id: 115691,
    name: "East Hill-Meridian",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.41052000",
    longitude: "-122.17369000",
  },
  {
    id: 115738,
    name: "East Port Orchard",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.52343000",
    longitude: "-122.62430000",
  },
  {
    id: 115745,
    name: "East Renton Highlands",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.48482000",
    longitude: "-122.11234000",
  },
  {
    id: 115771,
    name: "East Wenatchee",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.41568000",
    longitude: "-120.29313000",
  },
  {
    id: 115772,
    name: "East Wenatchee Bench",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.42568000",
    longitude: "-120.28118000",
  },
  {
    id: 115777,
    name: "Eastgate",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.57266000",
    longitude: "-122.14578000",
  },
  {
    id: 115784,
    name: "Eastmont",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.89740000",
    longitude: "-122.18154000",
  },
  {
    id: 115809,
    name: "Eatonville",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.86733000",
    longitude: "-122.26650000",
  },
  {
    id: 115864,
    name: "Edgewood",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.25010000",
    longitude: "-122.29373000",
  },
  {
    id: 115878,
    name: "Edmonds",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.81065000",
    longitude: "-122.37736000",
  },
  {
    id: 115960,
    name: "Electric City",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.93237000",
    longitude: "-119.03808000",
  },
  {
    id: 115989,
    name: "Elk Plain",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.05316000",
    longitude: "-122.39762000",
  },
  {
    id: 116018,
    name: "Ellensburg",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.99651000",
    longitude: "-120.54785000",
  },
  {
    id: 116046,
    name: "Elma",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.00343000",
    longitude: "-123.40877000",
  },
  {
    id: 116123,
    name: "Enetai",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.58482000",
    longitude: "-122.59875000",
  },
  {
    id: 116152,
    name: "Entiat",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.67596000",
    longitude: "-120.20841000",
  },
  {
    id: 116153,
    name: "Enumclaw",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.20427000",
    longitude: "-121.99150000",
  },
  {
    id: 116156,
    name: "Ephrata",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.31764000",
    longitude: "-119.55365000",
  },
  {
    id: 116171,
    name: "Erlands Point-Kitsap Lake",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.59719000",
    longitude: "-122.70225000",
  },
  {
    id: 116189,
    name: "Esperance",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.78899000",
    longitude: "-122.35541000",
  },
  {
    id: 116260,
    name: "Everett",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.97898000",
    longitude: "-122.20208000",
  },
  {
    id: 116267,
    name: "Everson",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.92012000",
    longitude: "-122.34266000",
  },
  {
    id: 116297,
    name: "Fairchild Air Force Base",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.61879000",
    longitude: "-117.64826000",
  },
  {
    id: 116363,
    name: "Fairwood",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.44843000",
    longitude: "-122.15734000",
  },
  {
    id: 116371,
    name: "Fall City",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.56732000",
    longitude: "-121.88873000",
  },
  {
    id: 116465,
    name: "Federal Way",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.32232000",
    longitude: "-122.31262000",
  },
  {
    id: 116467,
    name: "Felida",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "45.70956000",
    longitude: "-122.70732000",
  },
  {
    id: 116484,
    name: "Fern Prairie",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "45.63651000",
    longitude: "-122.39870000",
  },
  {
    id: 116490,
    name: "Ferndale",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.84650000",
    longitude: "-122.59101000",
  },
  {
    id: 116498,
    name: "Ferry County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.47007000",
    longitude: "-118.51649000",
  },
  {
    id: 116504,
    name: "Fife",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.23927000",
    longitude: "-122.35707000",
  },
  {
    id: 116505,
    name: "Fife Heights",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.25899000",
    longitude: "-122.34568000",
  },
  {
    id: 116517,
    name: "Finley",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.15402000",
    longitude: "-119.03390000",
  },
  {
    id: 116519,
    name: "Fircrest",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.23954000",
    longitude: "-122.51596000",
  },
  {
    id: 116538,
    name: "Five Corners",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "45.68456000",
    longitude: "-122.57510000",
  },
  {
    id: 116617,
    name: "Fobes Hill",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.94899000",
    longitude: "-122.11985000",
  },
  {
    id: 116641,
    name: "Fords Prairie",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.73510000",
    longitude: "-122.98902000",
  },
  {
    id: 116675,
    name: "Forks",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.95036000",
    longitude: "-124.38549000",
  },
  {
    id: 116802,
    name: "Fox Island",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.25149000",
    longitude: "-122.62902000",
  },
  {
    id: 116865,
    name: "Franklin County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.53477000",
    longitude: "-118.89889000",
  },
  {
    id: 116893,
    name: "Frederickson",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.09621000",
    longitude: "-122.35873000",
  },
  {
    id: 116907,
    name: "Freeland",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.00954000",
    longitude: "-122.52598000",
  },
  {
    id: 116943,
    name: "Friday Harbor",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.53427000",
    longitude: "-123.01712000",
  },
  {
    id: 117087,
    name: "Garfield County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.43156000",
    longitude: "-117.54519000",
  },
  {
    id: 117099,
    name: "Garrett",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.05208000",
    longitude: "-118.40275000",
  },
  {
    id: 117145,
    name: "Geneva",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.74567000",
    longitude: "-122.40183000",
  },
  {
    id: 117199,
    name: "Gig Harbor",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.32926000",
    longitude: "-122.58013000",
  },
  {
    id: 117258,
    name: "Gleed",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.65818000",
    longitude: "-120.61340000",
  },
  {
    id: 117339,
    name: "Gold Bar",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.85677000",
    longitude: "-121.69706000",
  },
  {
    id: 117360,
    name: "Goldendale",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "45.82068000",
    longitude: "-120.82173000",
  },
  {
    id: 117436,
    name: "Graham",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.05288000",
    longitude: "-122.29428000",
  },
  {
    id: 117454,
    name: "Grand Coulee",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.94154000",
    longitude: "-119.00335000",
  },
  {
    id: 117469,
    name: "Grand Mound",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.78788000",
    longitude: "-123.01125000",
  },
  {
    id: 117481,
    name: "Grandview",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.25097000",
    longitude: "-119.90170000",
  },
  {
    id: 117489,
    name: "Granger",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.34207000",
    longitude: "-120.18727000",
  },
  {
    id: 117498,
    name: "Granite Falls",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.08399000",
    longitude: "-121.96874000",
  },
  {
    id: 117521,
    name: "Grant County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.20566000",
    longitude: "-119.45177000",
  },
  {
    id: 117558,
    name: "Grays Harbor County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.14445000",
    longitude: "-123.82847000",
  },
  {
    id: 117980,
    name: "Hansville",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.91870000",
    longitude: "-122.55431000",
  },
  {
    id: 118203,
    name: "Hazel Dell",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "45.67151000",
    longitude: "-122.66288000",
  },
  {
    id: 118399,
    name: "Highland",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.13152000",
    longitude: "-119.11418000",
  },
  {
    id: 118498,
    name: "Hobart",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.42177000",
    longitude: "-121.97289000",
  },
  {
    id: 118504,
    name: "Hockinson",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "45.73789000",
    longitude: "-122.48704000",
  },
  {
    id: 118585,
    name: "Home",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.27482000",
    longitude: "-122.76375000",
  },
  {
    id: 118660,
    name: "Hoquiam",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.98092000",
    longitude: "-123.88933000",
  },
  {
    id: 118927,
    name: "Indianola",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.74704000",
    longitude: "-122.52569000",
  },
  {
    id: 118938,
    name: "Inglewood-Finn Hill",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.72049000",
    longitude: "-122.23167000",
  },
  {
    id: 119024,
    name: "Island County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.20820000",
    longitude: "-122.66922000",
  },
  {
    id: 119036,
    name: "Issaquah",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.53010000",
    longitude: "-122.03262000",
  },
  {
    id: 119198,
    name: "Jefferson County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.77655000",
    longitude: "-123.57431000",
  },
  {
    id: 119287,
    name: "Joint Base Lewis McChord",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.10787000",
    longitude: "-122.57694000",
  },
  {
    id: 119359,
    name: "Kalama",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.00845000",
    longitude: "-122.84455000",
  },
  {
    id: 119432,
    name: "Kelso",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.14678000",
    longitude: "-122.90844000",
  },
  {
    id: 119463,
    name: "Kenmore",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.75732000",
    longitude: "-122.24401000",
  },
  {
    id: 119475,
    name: "Kennewick",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.21125000",
    longitude: "-119.13723000",
  },
  {
    id: 119487,
    name: "Kent",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.38093000",
    longitude: "-122.23484000",
  },
  {
    id: 119527,
    name: "Kettle Falls",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.61074000",
    longitude: "-118.05582000",
  },
  {
    id: 119538,
    name: "Key Center",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.34065000",
    longitude: "-122.74541000",
  },
  {
    id: 119582,
    name: "King County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.49084000",
    longitude: "-121.83583000",
  },
  {
    id: 119614,
    name: "Kingsgate",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.72704000",
    longitude: "-122.17957000",
  },
  {
    id: 119630,
    name: "Kingston",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.79850000",
    longitude: "-122.49806000",
  },
  {
    id: 119652,
    name: "Kirkland",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.68149000",
    longitude: "-122.20874000",
  },
  {
    id: 119660,
    name: "Kitsap County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.63983000",
    longitude: "-122.64900000",
  },
  {
    id: 119664,
    name: "Kittitas",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.98318000",
    longitude: "-120.41701000",
  },
  {
    id: 119665,
    name: "Kittitas County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.12417000",
    longitude: "-120.67972000",
  },
  {
    id: 119669,
    name: "Klahanie",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.43121000",
    longitude: "-122.43652000",
  },
  {
    id: 119673,
    name: "Klickitat County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "45.87378000",
    longitude: "-120.78926000",
  },
  {
    id: 119738,
    name: "La Center",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "45.86234000",
    longitude: "-122.67038000",
  },
  {
    id: 119812,
    name: "Lacey",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.03426000",
    longitude: "-122.82319000",
  },
  {
    id: 119912,
    name: "Lake Forest Park",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.75676000",
    longitude: "-122.28096000",
  },
  {
    id: 119938,
    name: "Lake Marcel-Stillwater",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.69263000",
    longitude: "-121.91513000",
  },
  {
    id: 119948,
    name: "Lake Morton-Berrydale",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.33251000",
    longitude: "-122.10286000",
  },
  {
    id: 119978,
    name: "Lake Shore",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "45.69067000",
    longitude: "-122.69093000",
  },
  {
    id: 119980,
    name: "Lake Stevens",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.01510000",
    longitude: "-122.06374000",
  },
  {
    id: 119981,
    name: "Lake Stickney",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.87655000",
    longitude: "-122.26214000",
  },
  {
    id: 120018,
    name: "Lakeland North",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.33343000",
    longitude: "-122.27695000",
  },
  {
    id: 120019,
    name: "Lakeland South",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.27843000",
    longitude: "-122.28326000",
  },
  {
    id: 120052,
    name: "Lakewood",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.17176000",
    longitude: "-122.51846000",
  },
  {
    id: 120114,
    name: "Langley",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.04009000",
    longitude: "-122.40626000",
  },
  {
    id: 120140,
    name: "Larch Way",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.84290000",
    longitude: "-122.25275000",
  },
  {
    id: 120268,
    name: "Lea Hill",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.32621000",
    longitude: "-122.18151000",
  },
  {
    id: 120281,
    name: "Leavenworth",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.59623000",
    longitude: "-120.66148000",
  },
  {
    id: 120417,
    name: "Lewis County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.57773000",
    longitude: "-122.39241000",
  },
  {
    id: 120437,
    name: "Lewisville",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "45.80984000",
    longitude: "-122.52315000",
  },
  {
    id: 120475,
    name: "Liberty Lake",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.67591000",
    longitude: "-117.11821000",
  },
  {
    id: 120537,
    name: "Lincoln County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.57619000",
    longitude: "-118.41879000",
  },
  {
    id: 120673,
    name: "Lochsloy",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.05149000",
    longitude: "-122.03208000",
  },
  {
    id: 120692,
    name: "Lofall",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.81204000",
    longitude: "-122.65821000",
  },
  {
    id: 120739,
    name: "Long Beach",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.35232000",
    longitude: "-124.05432000",
  },
  {
    id: 120752,
    name: "Longbranch",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.20898000",
    longitude: "-122.75680000",
  },
  {
    id: 120758,
    name: "Longview",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.13817000",
    longitude: "-122.93817000",
  },
  {
    id: 120759,
    name: "Longview Heights",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.18039000",
    longitude: "-122.95706000",
  },
  {
    id: 120919,
    name: "Lynden",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.94650000",
    longitude: "-122.45211000",
  },
  {
    id: 120931,
    name: "Lynnwood",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.82093000",
    longitude: "-122.31513000",
  },
  {
    id: 120951,
    name: "Mabton",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.21485000",
    longitude: "-119.99671000",
  },
  {
    id: 120957,
    name: "Machias",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.98149000",
    longitude: "-122.04596000",
  },
  {
    id: 121069,
    name: "Maltby",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.80510000",
    longitude: "-122.11318000",
  },
  {
    id: 121102,
    name: "Manchester",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.55566000",
    longitude: "-122.54507000",
  },
  {
    id: 121151,
    name: "Manson",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.88486000",
    longitude: "-120.15841000",
  },
  {
    id: 121168,
    name: "Maple Heights-Lake Desire",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.44413000",
    longitude: "-122.09736000",
  },
  {
    id: 121173,
    name: "Maple Valley",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.39272000",
    longitude: "-122.04641000",
  },
  {
    id: 121180,
    name: "Maplewood",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.40176000",
    longitude: "-122.55707000",
  },
  {
    id: 121219,
    name: "Marietta",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.78705000",
    longitude: "-122.58045000",
  },
  {
    id: 121220,
    name: "Marietta-Alderwood",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.78965000",
    longitude: "-122.55369000",
  },
  {
    id: 121333,
    name: "Martha Lake",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.85093000",
    longitude: "-122.23930000",
  },
  {
    id: 121361,
    name: "Marysville",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.05176000",
    longitude: "-122.17708000",
  },
  {
    id: 121382,
    name: "Mason County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.35048000",
    longitude: "-123.18309000",
  },
  {
    id: 121409,
    name: "Mattawa",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.73791000",
    longitude: "-119.90282000",
  },
  {
    id: 121468,
    name: "McChord Air Force Base",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.13397000",
    longitude: "-122.49157000",
  },
  {
    id: 121470,
    name: "McCleary",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.05315000",
    longitude: "-123.26543000",
  },
  {
    id: 121538,
    name: "McMillin",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.13982000",
    longitude: "-122.23651000",
  },
  {
    id: 121553,
    name: "Mead",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.76739000",
    longitude: "-117.35494000",
  },
  {
    id: 121559,
    name: "Meadow Glade",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "45.75845000",
    longitude: "-122.56038000",
  },
  {
    id: 121568,
    name: "Meadowdale",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.85287000",
    longitude: "-122.33347000",
  },
  {
    id: 121600,
    name: "Medical Lake",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.57294000",
    longitude: "-117.68216000",
  },
  {
    id: 121606,
    name: "Medina",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.62093000",
    longitude: "-122.22762000",
  },
  {
    id: 121682,
    name: "Mercer Island",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.57065000",
    longitude: "-122.22207000",
  },
  {
    id: 121801,
    name: "Midland",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.16704000",
    longitude: "-122.40484000",
  },
  {
    id: 121863,
    name: "Mill Creek",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.86010000",
    longitude: "-122.20430000",
  },
  {
    id: 121864,
    name: "Mill Creek East",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.83602000",
    longitude: "-122.18766000",
  },
  {
    id: 121867,
    name: "Mill Plain",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "45.64290000",
    longitude: "-122.49398000",
  },
  {
    id: 121907,
    name: "Millwood",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.68128000",
    longitude: "-117.28271000",
  },
  {
    id: 121921,
    name: "Milton",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.24816000",
    longitude: "-122.31290000",
  },
  {
    id: 121952,
    name: "Minnehaha",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "45.65901000",
    longitude: "-122.64871000",
  },
  {
    id: 121975,
    name: "Mirrormont",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.46232000",
    longitude: "-121.99567000",
  },
  {
    id: 122065,
    name: "Monroe",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.85538000",
    longitude: "-121.97096000",
  },
  {
    id: 122083,
    name: "Monroe North",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.88225000",
    longitude: "-121.98729000",
  },
  {
    id: 122124,
    name: "Montesano",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.98121000",
    longitude: "-123.60266000",
  },
  {
    id: 122295,
    name: "Morton",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.55844000",
    longitude: "-122.27510000",
  },
  {
    id: 122302,
    name: "Moses Lake",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.13014000",
    longitude: "-119.27808000",
  },
  {
    id: 122303,
    name: "Moses Lake North",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.19433000",
    longitude: "-119.31719000",
  },
  {
    id: 122403,
    name: "Mount Vernon",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.42122000",
    longitude: "-122.33405000",
  },
  {
    id: 122405,
    name: "Mount Vista",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "45.73428000",
    longitude: "-122.63288000",
  },
  {
    id: 122439,
    name: "Mountlake Terrace",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.78815000",
    longitude: "-122.30874000",
  },
  {
    id: 122450,
    name: "Mukilteo",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.94454000",
    longitude: "-122.30458000",
  },
  {
    id: 122549,
    name: "Napavine",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.57455000",
    longitude: "-122.90818000",
  },
  {
    id: 122605,
    name: "Navy Yard City",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.55343000",
    longitude: "-122.66458000",
  },
  {
    id: 122823,
    name: "Newcastle",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.53899000",
    longitude: "-122.15568000",
  },
  {
    id: 122852,
    name: "Newport",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.17963000",
    longitude: "-117.04326000",
  },
  {
    id: 122936,
    name: "Nooksack",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.92762000",
    longitude: "-122.32155000",
  },
  {
    id: 122952,
    name: "Normandy Park",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.43621000",
    longitude: "-122.34068000",
  },
  {
    id: 122985,
    name: "North Bend",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.49566000",
    longitude: "-121.78678000",
  },
  {
    id: 123011,
    name: "North Creek",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.81954000",
    longitude: "-122.17624000",
  },
  {
    id: 123028,
    name: "North Fort Lewis",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.12131000",
    longitude: "-122.59452000",
  },
  {
    id: 123090,
    name: "North Puyallup",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.20677000",
    longitude: "-122.28234000",
  },
  {
    id: 123132,
    name: "North Yelm",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.96315000",
    longitude: "-122.60290000",
  },
  {
    id: 123242,
    name: "Oak Harbor",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.29316000",
    longitude: "-122.64322000",
  },
  {
    id: 123332,
    name: "Ocean Park",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.49177000",
    longitude: "-124.05208000",
  },
  {
    id: 123336,
    name: "Ocean Shores",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.97370000",
    longitude: "-124.15629000",
  },
  {
    id: 123393,
    name: "Okanogan",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.36126000",
    longitude: "-119.58339000",
  },
  {
    id: 123394,
    name: "Okanogan County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.54885000",
    longitude: "-119.74079000",
  },
  {
    id: 123459,
    name: "Olympia",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.03787000",
    longitude: "-122.90070000",
  },
  {
    id: 123464,
    name: "Omak",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.41099000",
    longitude: "-119.52755000",
  },
  {
    id: 123499,
    name: "Opportunity",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.64995000",
    longitude: "-117.23991000",
  },
  {
    id: 123539,
    name: "Orchards",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "45.66651000",
    longitude: "-122.56093000",
  },
  {
    id: 123579,
    name: "Oroville",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.93905000",
    longitude: "-119.43562000",
  },
  {
    id: 123582,
    name: "Orting",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.09788000",
    longitude: "-122.20428000",
  },
  {
    id: 123627,
    name: "Othello",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.82597000",
    longitude: "-119.17529000",
  },
  {
    id: 123629,
    name: "Otis Orchards-East Farms",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.70988000",
    longitude: "-117.07975000",
  },
  {
    id: 123707,
    name: "Pacific",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.26455000",
    longitude: "-122.25012000",
  },
  {
    id: 123709,
    name: "Pacific County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.55128000",
    longitude: "-123.77886000",
  },
  {
    id: 123792,
    name: "Palouse",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.91017000",
    longitude: "-117.07573000",
  },
  {
    id: 123872,
    name: "Parkland",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.15538000",
    longitude: "-122.43401000",
  },
  {
    id: 123883,
    name: "Parkwood",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.53315000",
    longitude: "-122.61014000",
  },
  {
    id: 123901,
    name: "Pasco",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.23958000",
    longitude: "-119.10057000",
  },
  {
    id: 123955,
    name: "Peaceful Valley",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.93815000",
    longitude: "-122.14733000",
  },
  {
    id: 124013,
    name: "Pend Oreille County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.53230000",
    longitude: "-117.27397000",
  },
  {
    id: 124160,
    name: "Picnic Point",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.88111000",
    longitude: "-122.32840000",
  },
  {
    id: 124161,
    name: "Picnic Point-North Lynnwood",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.86278000",
    longitude: "-122.29497000",
  },
  {
    id: 124175,
    name: "Pierce County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.03764000",
    longitude: "-122.13735000",
  },
  {
    id: 124433,
    name: "Point Roberts",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.98538000",
    longitude: "-123.07797000",
  },
  {
    id: 124456,
    name: "Pomeroy",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.47487000",
    longitude: "-117.60269000",
  },
  {
    id: 124493,
    name: "Port Angeles",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.11815000",
    longitude: "-123.43074000",
  },
  {
    id: 124494,
    name: "Port Angeles East",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.10667000",
    longitude: "-123.37172000",
  },
  {
    id: 124508,
    name: "Port Hadlock-Irondale",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.03273000",
    longitude: "-122.78529000",
  },
  {
    id: 124518,
    name: "Port Ludlow",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.92537000",
    longitude: "-122.68349000",
  },
  {
    id: 124525,
    name: "Port Orchard",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.54037000",
    longitude: "-122.63625000",
  },
  {
    id: 124537,
    name: "Port Townsend",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.11704000",
    longitude: "-122.76045000",
  },
  {
    id: 124603,
    name: "Poulsbo",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.73593000",
    longitude: "-122.64654000",
  },
  {
    id: 124629,
    name: "Prairie Heights",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.14933000",
    longitude: "-122.10530000",
  },
  {
    id: 124630,
    name: "Prairie Ridge",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.13760000",
    longitude: "-122.14873000",
  },
  {
    id: 124714,
    name: "Prosser",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.20680000",
    longitude: "-119.76892000",
  },
  {
    id: 124744,
    name: "Pullman",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.73127000",
    longitude: "-117.17962000",
  },
  {
    id: 124757,
    name: "Purdy",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.38899000",
    longitude: "-122.62541000",
  },
  {
    id: 124771,
    name: "Puyallup",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.18538000",
    longitude: "-122.29290000",
  },
  {
    id: 124800,
    name: "Quincy",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.23430000",
    longitude: "-119.85255000",
  },
  {
    id: 124827,
    name: "Rainier",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.88815000",
    longitude: "-122.68846000",
  },
  {
    id: 124909,
    name: "Ravensdale",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.35232000",
    longitude: "-121.98373000",
  },
  {
    id: 124920,
    name: "Raymond",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.68649000",
    longitude: "-123.73294000",
  },
  {
    id: 124979,
    name: "Redmond",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.67399000",
    longitude: "-122.12151000",
  },
  {
    id: 125026,
    name: "Renton",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.48288000",
    longitude: "-122.21707000",
  },
  {
    id: 125032,
    name: "Republic",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.64822000",
    longitude: "-118.73781000",
  },
  {
    id: 125072,
    name: "Richland",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.28569000",
    longitude: "-119.28446000",
  },
  {
    id: 125121,
    name: "Ridgefield",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "45.81511000",
    longitude: "-122.74260000",
  },
  {
    id: 125174,
    name: "Ritzville",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.12755000",
    longitude: "-118.37999000",
  },
  {
    id: 125191,
    name: "Riverbend",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.46649000",
    longitude: "-121.75039000",
  },
  {
    id: 125213,
    name: "Riverton",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.48427000",
    longitude: "-122.29457000",
  },
  {
    id: 125270,
    name: "Rochester",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.82177000",
    longitude: "-123.09625000",
  },
  {
    id: 125339,
    name: "Rocky Point",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.59287000",
    longitude: "-122.66848000",
  },
  {
    id: 125420,
    name: "Rosedale",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.33149000",
    longitude: "-122.65235000",
  },
  {
    id: 125488,
    name: "Royal City",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.90097000",
    longitude: "-119.63059000",
  },
  {
    id: 125765,
    name: "Salmon Creek",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "45.71067000",
    longitude: "-122.64899000",
  },
  {
    id: 125778,
    name: "Sammamish",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.64177000",
    longitude: "-122.08040000",
  },
  {
    id: 125823,
    name: "San Juan County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.53116000",
    longitude: "-123.02490000",
  },
  {
    id: 126077,
    name: "Seabeck",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.63954000",
    longitude: "-122.82849000",
  },
  {
    id: 126076,
    name: "SeaTac",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.44846000",
    longitude: "-122.29217000",
  },
  {
    id: 126104,
    name: "Seattle",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.60621000",
    longitude: "-122.33207000",
  },
  {
    id: 126122,
    name: "Sedro-Woolley",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.50389000",
    longitude: "-122.23611000",
  },
  {
    id: 126129,
    name: "Selah",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.65402000",
    longitude: "-120.53007000",
  },
  {
    id: 126164,
    name: "Sequim",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.07963000",
    longitude: "-123.10234000",
  },
  {
    id: 126299,
    name: "Shelton",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.21509000",
    longitude: "-123.10071000",
  },
  {
    id: 126368,
    name: "Shoreline",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.75565000",
    longitude: "-122.34152000",
  },
  {
    id: 126424,
    name: "Silver Firs",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.86602000",
    longitude: "-122.15510000",
  },
  {
    id: 126439,
    name: "Silverdale",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.64454000",
    longitude: "-122.69487000",
  },
  {
    id: 126464,
    name: "Sisco Heights",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.11538000",
    longitude: "-122.09708000",
  },
  {
    id: 126474,
    name: "Skagit County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.48215000",
    longitude: "-121.80227000",
  },
  {
    id: 126476,
    name: "Skamania County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.02276000",
    longitude: "-121.91510000",
  },
  {
    id: 126533,
    name: "Smokey Point",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.15232000",
    longitude: "-122.18264000",
  },
  {
    id: 126542,
    name: "Snohomish",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.91288000",
    longitude: "-122.09818000",
  },
  {
    id: 126543,
    name: "Snohomish County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.04602000",
    longitude: "-121.72218000",
  },
  {
    id: 126544,
    name: "Snoqualmie",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.52871000",
    longitude: "-121.82539000",
  },
  {
    id: 126553,
    name: "Soap Lake",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.38931000",
    longitude: "-119.49059000",
  },
  {
    id: 126625,
    name: "South Bend",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.66315000",
    longitude: "-123.80461000",
  },
  {
    id: 126673,
    name: "South Hill",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.14121000",
    longitude: "-122.27012000",
  },
  {
    id: 126746,
    name: "South Wenatchee",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.39012000",
    longitude: "-120.28958000",
  },
  {
    id: 126793,
    name: "Southworth",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.51204000",
    longitude: "-122.50180000",
  },
  {
    id: 126796,
    name: "Spanaway",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.10399000",
    longitude: "-122.43457000",
  },
  {
    id: 126842,
    name: "Spokane",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.65966000",
    longitude: "-117.42908000",
  },
  {
    id: 126843,
    name: "Spokane County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.62064000",
    longitude: "-117.40401000",
  },
  {
    id: 126844,
    name: "Spokane Valley",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.67323000",
    longitude: "-117.23937000",
  },
  {
    id: 126974,
    name: "Stanwood",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.24121000",
    longitude: "-122.37071000",
  },
  {
    id: 127017,
    name: "Steilacoom",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.16982000",
    longitude: "-122.60263000",
  },
  {
    id: 127042,
    name: "Stevens County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.39906000",
    longitude: "-117.85514000",
  },
  {
    id: 127045,
    name: "Stevenson",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "45.69567000",
    longitude: "-121.88452000",
  },
  {
    id: 127162,
    name: "Sudden Valley",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.72289000",
    longitude: "-122.34655000",
  },
  {
    id: 127198,
    name: "Sultan",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.86260000",
    longitude: "-121.81651000",
  },
  {
    id: 127199,
    name: "Sumas",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "49.00012000",
    longitude: "-122.26488000",
  },
  {
    id: 127215,
    name: "Summit",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.16177000",
    longitude: "-122.35707000",
  },
  {
    id: 127220,
    name: "Summit View",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.13632000",
    longitude: "-122.35202000",
  },
  {
    id: 127223,
    name: "Sumner",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.20316000",
    longitude: "-122.24040000",
  },
  {
    id: 127260,
    name: "Sunnyside",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.32374000",
    longitude: "-120.00865000",
  },
  {
    id: 127263,
    name: "Sunnyslope",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.47290000",
    longitude: "-120.33674000",
  },
  {
    id: 127284,
    name: "Suquamish",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.73121000",
    longitude: "-122.55236000",
  },
  {
    id: 127369,
    name: "Tacoma",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.25288000",
    longitude: "-122.44429000",
  },
  {
    id: 127416,
    name: "Tanglewilde",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.05150000",
    longitude: "-122.78241000",
  },
  {
    id: 127417,
    name: "Tanglewilde-Thompson Place",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.05116000",
    longitude: "-122.78081000",
  },
  {
    id: 127418,
    name: "Tanner",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.47538000",
    longitude: "-121.74622000",
  },
  {
    id: 127509,
    name: "Tenino",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.85677000",
    longitude: "-122.85291000",
  },
  {
    id: 127518,
    name: "Terrace Heights",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.60624000",
    longitude: "-120.43979000",
  },
  {
    id: 127613,
    name: "Three Lakes",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.94482000",
    longitude: "-122.01152000",
  },
  {
    id: 127631,
    name: "Thurston County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.92950000",
    longitude: "-122.83208000",
  },
  {
    id: 127639,
    name: "Tieton",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.70207000",
    longitude: "-120.75535000",
  },
  {
    id: 127717,
    name: "Tonasket",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.70515000",
    longitude: "-119.43950000",
  },
  {
    id: 127734,
    name: "Toppenish",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.37735000",
    longitude: "-120.30867000",
  },
  {
    id: 127760,
    name: "Town and Country",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.72739000",
    longitude: "-117.42161000",
  },
  {
    id: 127773,
    name: "Tracyton",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.60898000",
    longitude: "-122.65514000",
  },
  {
    id: 127811,
    name: "Trentwood",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.69656000",
    longitude: "-117.21076000",
  },
  {
    id: 127879,
    name: "Tukwila",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.47399000",
    longitude: "-122.26096000",
  },
  {
    id: 127880,
    name: "Tulalip",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.06843000",
    longitude: "-122.29181000",
  },
  {
    id: 127881,
    name: "Tulalip Bay",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.03732000",
    longitude: "-122.31014000",
  },
  {
    id: 127890,
    name: "Tumwater",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.00732000",
    longitude: "-122.90931000",
  },
  {
    id: 128004,
    name: "Union Gap",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.55735000",
    longitude: "-120.47506000",
  },
  {
    id: 128007,
    name: "Union Hill-Novelty Hill",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.67887000",
    longitude: "-122.02833000",
  },
  {
    id: 128036,
    name: "University Place",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.23565000",
    longitude: "-122.55040000",
  },
  {
    id: 128144,
    name: "Vancouver",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "45.63873000",
    longitude: "-122.66149000",
  },
  {
    id: 128157,
    name: "Vashon",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.44732000",
    longitude: "-122.45985000",
  },
  {
    id: 128167,
    name: "Venersborg",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "45.77373000",
    longitude: "-122.42454000",
  },
  {
    id: 128178,
    name: "Veradale",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.64995000",
    longitude: "-117.20738000",
  },
  {
    id: 128332,
    name: "Wahkiakum County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.29125000",
    longitude: "-123.43316000",
  },
  {
    id: 128354,
    name: "Waitsburg",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.27042000",
    longitude: "-118.15329000",
  },
  {
    id: 128393,
    name: "Walla Walla",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.06458000",
    longitude: "-118.34302000",
  },
  {
    id: 128394,
    name: "Walla Walla County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.22980000",
    longitude: "-118.47845000",
  },
  {
    id: 128395,
    name: "Walla Walla East",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.05184000",
    longitude: "-118.30403000",
  },
  {
    id: 128404,
    name: "Waller",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.20066000",
    longitude: "-122.36929000",
  },
  {
    id: 128420,
    name: "Walnut Grove",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "45.66789000",
    longitude: "-122.59899000",
  },
  {
    id: 128453,
    name: "Wapato",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.44763000",
    longitude: "-120.42034000",
  },
  {
    id: 128460,
    name: "Warden",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.96764000",
    longitude: "-119.03973000",
  },
  {
    id: 128467,
    name: "Warm Beach",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.17065000",
    longitude: "-122.36460000",
  },
  {
    id: 128591,
    name: "Washougal",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "45.58262000",
    longitude: "-122.35342000",
  },
  {
    id: 128629,
    name: "Waterville",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.64708000",
    longitude: "-120.07118000",
  },
  {
    id: 128653,
    name: "Wauna",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.37899000",
    longitude: "-122.64263000",
  },
  {
    id: 128797,
    name: "Wenatchee",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.42346000",
    longitude: "-120.31035000",
  },
  {
    id: 128847,
    name: "West Clarkston-Highland",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.40287000",
    longitude: "-117.06395000",
  },
  {
    id: 128910,
    name: "West Lake Sammamish",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.57760000",
    longitude: "-122.10123000",
  },
  {
    id: 128911,
    name: "West Lake Stevens",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.99343000",
    longitude: "-122.10180000",
  },
  {
    id: 128923,
    name: "West Longview",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.16789000",
    longitude: "-122.99900000",
  },
  {
    id: 128951,
    name: "West Pasco",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.24541000",
    longitude: "-119.18279000",
  },
  {
    id: 128968,
    name: "West Richland",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.30430000",
    longitude: "-119.36141000",
  },
  {
    id: 128981,
    name: "West Side Highway",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.18399000",
    longitude: "-122.91715000",
  },
  {
    id: 128996,
    name: "West Valley",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.59207000",
    longitude: "-120.60507000",
  },
  {
    id: 129003,
    name: "West Wenatchee",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.44374000",
    longitude: "-120.35341000",
  },
  {
    id: 129073,
    name: "Westport",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.89009000",
    longitude: "-124.10406000",
  },
  {
    id: 129105,
    name: "Whatcom County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "48.82975000",
    longitude: "-121.87283000",
  },
  {
    id: 129129,
    name: "White Center",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.51732000",
    longitude: "-122.35485000",
  },
  {
    id: 129159,
    name: "White Salmon",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "45.72762000",
    longitude: "-121.48646000",
  },
  {
    id: 129204,
    name: "Whitman County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.90117000",
    longitude: "-117.52299000",
  },
  {
    id: 129234,
    name: "Wilderness Rim",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.44697000",
    longitude: "-121.76857000",
  },
  {
    id: 129403,
    name: "Winlock",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.49122000",
    longitude: "-122.93790000",
  },
  {
    id: 129480,
    name: "Wollochet",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.26871000",
    longitude: "-122.58402000",
  },
  {
    id: 129523,
    name: "Woodinville",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.75427000",
    longitude: "-122.16346000",
  },
  {
    id: 129527,
    name: "Woodland",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "45.90456000",
    longitude: "-122.74399000",
  },
  {
    id: 129550,
    name: "Woods Creek",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.87871000",
    longitude: "-121.89846000",
  },
  {
    id: 129579,
    name: "Woodway",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.79621000",
    longitude: "-122.38291000",
  },
  {
    id: 129647,
    name: "Yacolt",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "45.86595000",
    longitude: "-122.40621000",
  },
  {
    id: 129650,
    name: "Yakima",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.60207000",
    longitude: "-120.50590000",
  },
  {
    id: 129651,
    name: "Yakima County",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.45685000",
    longitude: "-120.73870000",
  },
  {
    id: 129669,
    name: "Yarrow Point",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "47.64621000",
    longitude: "-122.21735000",
  },
  {
    id: 129681,
    name: "Yelm",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.94204000",
    longitude: "-122.60596000",
  },
  {
    id: 129754,
    name: "Zillah",
    state_id: 1462,
    state_code: "WA",
    country_id: 233,
    country_code: "US",
    latitude: "46.40207000",
    longitude: "-120.26200000",
  },
  {
    id: 111137,
    name: "Alderson",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.72595000",
    longitude: "-80.64202000",
  },
  {
    id: 111253,
    name: "Alum Creek",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.28676000",
    longitude: "-81.80513000",
  },
  {
    id: 111363,
    name: "Ansted",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.13622000",
    longitude: "-81.09955000",
  },
  {
    id: 111814,
    name: "Barbour County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.13293000",
    longitude: "-80.00303000",
  },
  {
    id: 111817,
    name: "Barboursville",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.40953000",
    longitude: "-82.29459000",
  },
  {
    id: 111840,
    name: "Barrackville",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.50370000",
    longitude: "-80.16675000",
  },
  {
    id: 111992,
    name: "Beaver",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.74781000",
    longitude: "-81.14352000",
  },
  {
    id: 112017,
    name: "Beckley",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.77817000",
    longitude: "-81.18816000",
  },
  {
    id: 112064,
    name: "Belington",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.02510000",
    longitude: "-79.93563000",
  },
  {
    id: 112079,
    name: "Belle",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.23205000",
    longitude: "-81.53762000",
  },
  {
    id: 112211,
    name: "Benwood",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "40.01813000",
    longitude: "-80.73425000",
  },
  {
    id: 112225,
    name: "Berkeley County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.46407000",
    longitude: "-78.02754000",
  },
  {
    id: 112228,
    name: "Berkeley Springs",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.62480000",
    longitude: "-78.22472000",
  },
  {
    id: 112275,
    name: "Bethany",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "40.20563000",
    longitude: "-80.55674000",
  },
  {
    id: 112290,
    name: "Bethlehem",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.39203000",
    longitude: "-80.28064000",
  },
  {
    id: 112429,
    name: "Blennerhassett",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.26369000",
    longitude: "-81.62929000",
  },
  {
    id: 112479,
    name: "Bluefield",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.26984000",
    longitude: "-81.22232000",
  },
  {
    id: 112480,
    name: "Bluewell",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.31262000",
    longitude: "-81.25982000",
  },
  {
    id: 112491,
    name: "Boaz",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.36146000",
    longitude: "-81.50207000",
  },
  {
    id: 112519,
    name: "Bolivar",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.32343000",
    longitude: "-77.75277000",
  },
  {
    id: 112557,
    name: "Boone County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.02300000",
    longitude: "-81.71121000",
  },
  {
    id: 112671,
    name: "Bradley",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.86539000",
    longitude: "-81.19399000",
  },
  {
    id: 112701,
    name: "Braxton County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.69987000",
    longitude: "-80.71929000",
  },
  {
    id: 112760,
    name: "Bridgeport",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.28648000",
    longitude: "-80.25620000",
  },
  {
    id: 112852,
    name: "Brooke County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "40.27381000",
    longitude: "-80.57642000",
  },
  {
    id: 112859,
    name: "Brookhaven",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.61175000",
    longitude: "-79.90451000",
  },
  {
    id: 112954,
    name: "Brush Fork",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.28095000",
    longitude: "-81.25593000",
  },
  {
    id: 112981,
    name: "Buckhannon",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.99399000",
    longitude: "-80.23203000",
  },
  {
    id: 113011,
    name: "Buffalo",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.61759000",
    longitude: "-81.98180000",
  },
  {
    id: 113151,
    name: "Cabell County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.42030000",
    longitude: "-82.24172000",
  },
  {
    id: 113208,
    name: "Calhoun County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.84450000",
    longitude: "-81.11757000",
  },
  {
    id: 113699,
    name: "Ceredo",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.39647000",
    longitude: "-82.55877000",
  },
  {
    id: 113745,
    name: "Chapmanville",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.97371000",
    longitude: "-82.01735000",
  },
  {
    id: 113759,
    name: "Charles Town",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.28899000",
    longitude: "-77.85972000",
  },
  {
    id: 113766,
    name: "Charleston",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.34982000",
    longitude: "-81.63262000",
  },
  {
    id: 113818,
    name: "Cheat Lake",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.67202000",
    longitude: "-79.85339000",
  },
  {
    id: 113873,
    name: "Chesapeake",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.22344000",
    longitude: "-81.53623000",
  },
  {
    id: 113890,
    name: "Chester",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "40.61312000",
    longitude: "-80.56285000",
  },
  {
    id: 114144,
    name: "Clarksburg",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.28065000",
    longitude: "-80.34453000",
  },
  {
    id: 114167,
    name: "Clay",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.46038000",
    longitude: "-81.08511000",
  },
  {
    id: 114186,
    name: "Clay County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.46253000",
    longitude: "-81.07509000",
  },
  {
    id: 114235,
    name: "Clendenin",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.48871000",
    longitude: "-81.34817000",
  },
  {
    id: 114326,
    name: "Coal City",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.67900000",
    longitude: "-81.21038000",
  },
  {
    id: 114330,
    name: "Coal Fork",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.31760000",
    longitude: "-81.52095000",
  },
  {
    id: 114753,
    name: "Crab Orchard",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.74067000",
    longitude: "-81.23066000",
  },
  {
    id: 114760,
    name: "Craigsville",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.33067000",
    longitude: "-80.65315000",
  },
  {
    id: 114850,
    name: "Cross Lanes",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.42037000",
    longitude: "-81.79068000",
  },
  {
    id: 114897,
    name: "Culloden",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.42009000",
    longitude: "-82.05542000",
  },
  {
    id: 115023,
    name: "Daniels",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.74327000",
    longitude: "-81.12408000",
  },
  {
    id: 115288,
    name: "Despard",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.28870000",
    longitude: "-80.30592000",
  },
  {
    id: 115374,
    name: "Doddridge County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.26917000",
    longitude: "-80.70697000",
  },
  {
    id: 115520,
    name: "Dunbar",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.36065000",
    longitude: "-81.73735000",
  },
  {
    id: 115958,
    name: "Eleanor",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.53759000",
    longitude: "-81.93236000",
  },
  {
    id: 115971,
    name: "Elizabeth",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.06341000",
    longitude: "-81.39512000",
  },
  {
    id: 116006,
    name: "Elkins",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.92594000",
    longitude: "-79.84673000",
  },
  {
    id: 116015,
    name: "Elkview",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.44288000",
    longitude: "-81.48040000",
  },
  {
    id: 116331,
    name: "Fairlea",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.78068000",
    longitude: "-80.45702000",
  },
  {
    id: 116335,
    name: "Fairmont",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.48508000",
    longitude: "-80.14258000",
  },
  {
    id: 116450,
    name: "Fayette County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.02878000",
    longitude: "-81.08119000",
  },
  {
    id: 116459,
    name: "Fayetteville",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.05289000",
    longitude: "-81.10399000",
  },
  {
    id: 116622,
    name: "Follansbee",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "40.32757000",
    longitude: "-80.59591000",
  },
  {
    id: 116688,
    name: "Fort Ashby",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.50315000",
    longitude: "-78.76863000",
  },
  {
    id: 116832,
    name: "Franklin",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.64289000",
    longitude: "-79.33115000",
  },
  {
    id: 117205,
    name: "Gilbert Creek",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.57594000",
    longitude: "-81.89484000",
  },
  {
    id: 117223,
    name: "Gilmer County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.92406000",
    longitude: "-80.85708000",
  },
  {
    id: 117282,
    name: "Glendale",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.94924000",
    longitude: "-80.75425000",
  },
  {
    id: 117310,
    name: "Glenville",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.93426000",
    longitude: "-80.83760000",
  },
  {
    id: 117428,
    name: "Grafton",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.34092000",
    longitude: "-80.01897000",
  },
  {
    id: 117511,
    name: "Grant County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.10513000",
    longitude: "-79.19557000",
  },
  {
    id: 117530,
    name: "Grantsville",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.92342000",
    longitude: "-81.09595000",
  },
  {
    id: 117533,
    name: "Granville",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.64591000",
    longitude: "-79.98729000",
  },
  {
    id: 117621,
    name: "Greenbrier County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.94693000",
    longitude: "-80.45295000",
  },
  {
    id: 117908,
    name: "Hamlin",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.27870000",
    longitude: "-82.10292000",
  },
  {
    id: 117921,
    name: "Hampshire County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.31707000",
    longitude: "-78.61417000",
  },
  {
    id: 117958,
    name: "Hancock County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "40.52185000",
    longitude: "-80.57389000",
  },
  {
    id: 118011,
    name: "Hardy County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.00750000",
    longitude: "-78.85792000",
  },
  {
    id: 118066,
    name: "Harrison County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.28353000",
    longitude: "-80.37987000",
  },
  {
    id: 118073,
    name: "Harrisville",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.20952000",
    longitude: "-81.05178000",
  },
  {
    id: 118489,
    name: "Hinton",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.67401000",
    longitude: "-80.88925000",
  },
  {
    id: 118639,
    name: "Hooverson Heights",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "40.32479000",
    longitude: "-80.57757000",
  },
  {
    id: 118800,
    name: "Huntington",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.41925000",
    longitude: "-82.44515000",
  },
  {
    id: 118827,
    name: "Hurricane",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.43259000",
    longitude: "-82.02014000",
  },
  {
    id: 118962,
    name: "Inwood",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.35788000",
    longitude: "-78.04000000",
  },
  {
    id: 119089,
    name: "Jackson County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.83447000",
    longitude: "-81.67479000",
  },
  {
    id: 119188,
    name: "Jefferson County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.30762000",
    longitude: "-77.86274000",
  },
  {
    id: 119374,
    name: "Kanawha County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.33657000",
    longitude: "-81.52812000",
  },
  {
    id: 119478,
    name: "Kenova",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.39897000",
    longitude: "-82.57821000",
  },
  {
    id: 119545,
    name: "Keyser",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.44093000",
    longitude: "-78.97392000",
  },
  {
    id: 119637,
    name: "Kingwood",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.47176000",
    longitude: "-79.68339000",
  },
  {
    id: 120215,
    name: "Lavalette",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.32286000",
    longitude: "-82.44682000",
  },
  {
    id: 120395,
    name: "Lesage",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.50647000",
    longitude: "-82.29848000",
  },
  {
    id: 120413,
    name: "Lewis County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.99587000",
    longitude: "-80.50216000",
  },
  {
    id: 120420,
    name: "Lewisburg",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.80179000",
    longitude: "-80.44563000",
  },
  {
    id: 120525,
    name: "Lincoln County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.17536000",
    longitude: "-82.07039000",
  },
  {
    id: 120693,
    name: "Logan",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.84871000",
    longitude: "-81.99346000",
  },
  {
    id: 120699,
    name: "Logan County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.83153000",
    longitude: "-81.93534000",
  },
  {
    id: 120858,
    name: "Lubeck",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.23535000",
    longitude: "-81.63124000",
  },
  {
    id: 120950,
    name: "Mabscott",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.77095000",
    longitude: "-81.20843000",
  },
  {
    id: 120952,
    name: "MacArthur",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.75845000",
    longitude: "-81.21260000",
  },
  {
    id: 120993,
    name: "Madison",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.06705000",
    longitude: "-81.81929000",
  },
  {
    id: 121063,
    name: "Mallory",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.73066000",
    longitude: "-81.83790000",
  },
  {
    id: 121137,
    name: "Mannington",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.53092000",
    longitude: "-80.34342000",
  },
  {
    id: 121260,
    name: "Marion County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.51000000",
    longitude: "-80.24340000",
  },
  {
    id: 121284,
    name: "Marlinton",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.22345000",
    longitude: "-80.09451000",
  },
  {
    id: 121290,
    name: "Marmet",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.24538000",
    longitude: "-81.56706000",
  },
  {
    id: 121316,
    name: "Marshall County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.86061000",
    longitude: "-80.66339000",
  },
  {
    id: 121346,
    name: "Martinsburg",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.45621000",
    longitude: "-77.96389000",
  },
  {
    id: 121379,
    name: "Mason County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.76974000",
    longitude: "-82.02654000",
  },
  {
    id: 121496,
    name: "McDowell County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.37850000",
    longitude: "-81.65358000",
  },
  {
    id: 121537,
    name: "McMechen",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.98813000",
    longitude: "-80.73147000",
  },
  {
    id: 121676,
    name: "Mercer County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.40552000",
    longitude: "-81.11144000",
  },
  {
    id: 121767,
    name: "Middlebourne",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.49230000",
    longitude: "-80.90372000",
  },
  {
    id: 121914,
    name: "Milton",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.43453000",
    longitude: "-82.13236000",
  },
  {
    id: 121934,
    name: "Mineral County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.41472000",
    longitude: "-78.94375000",
  },
  {
    id: 121942,
    name: "Mineral Wells",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.19035000",
    longitude: "-81.53207000",
  },
  {
    id: 121946,
    name: "Mingo County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.72640000",
    longitude: "-82.13476000",
  },
  {
    id: 122054,
    name: "Monongah",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.46258000",
    longitude: "-80.21814000",
  },
  {
    id: 122056,
    name: "Monongalia County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.63028000",
    longitude: "-80.04654000",
  },
  {
    id: 122077,
    name: "Monroe County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.56037000",
    longitude: "-80.55055000",
  },
  {
    id: 122132,
    name: "Montgomery",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.18038000",
    longitude: "-81.32845000",
  },
  {
    id: 122205,
    name: "Moorefield",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.06233000",
    longitude: "-78.96947000",
  },
  {
    id: 122243,
    name: "Morgan County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.56043000",
    longitude: "-78.25773000",
  },
  {
    id: 122253,
    name: "Morgantown",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.62953000",
    longitude: "-79.95590000",
  },
  {
    id: 122327,
    name: "Moundsville",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.92035000",
    longitude: "-80.74314000",
  },
  {
    id: 122343,
    name: "Mount Gay-Shamrock",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.83857000",
    longitude: "-82.02970000",
  },
  {
    id: 122353,
    name: "Mount Hope",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.89539000",
    longitude: "-81.16427000",
  },
  {
    id: 122460,
    name: "Mullens",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.58317000",
    longitude: "-81.38038000",
  },
  {
    id: 122698,
    name: "New Cumberland",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "40.49673000",
    longitude: "-80.60674000",
  },
  {
    id: 122715,
    name: "New Haven",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.98647000",
    longitude: "-81.97347000",
  },
  {
    id: 122753,
    name: "New Martinsville",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.64452000",
    longitude: "-80.85760000",
  },
  {
    id: 122824,
    name: "Newell",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "40.61840000",
    longitude: "-80.60424000",
  },
  {
    id: 122888,
    name: "Nicholas County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.29170000",
    longitude: "-80.79933000",
  },
  {
    id: 122909,
    name: "Nitro",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.41481000",
    longitude: "-81.84402000",
  },
  {
    id: 123220,
    name: "Nutter Fort",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.26342000",
    longitude: "-80.31981000",
  },
  {
    id: 123245,
    name: "Oak Hill",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.97233000",
    longitude: "-81.14871000",
  },
  {
    id: 123339,
    name: "Oceana",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.69206000",
    longitude: "-81.62400000",
  },
  {
    id: 123384,
    name: "Ohio County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "40.09692000",
    longitude: "-80.61906000",
  },
  {
    id: 123714,
    name: "Paden City",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.60285000",
    longitude: "-80.93677000",
  },
  {
    id: 123868,
    name: "Parkersburg",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.26674000",
    longitude: "-81.56151000",
  },
  {
    id: 123893,
    name: "Parsons",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.09649000",
    longitude: "-79.68090000",
  },
  {
    id: 123952,
    name: "Pea Ridge",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.41397000",
    longitude: "-82.31987000",
  },
  {
    id: 124020,
    name: "Pendleton County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.68073000",
    longitude: "-79.35089000",
  },
  {
    id: 124035,
    name: "Pennsboro",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.28508000",
    longitude: "-80.96844000",
  },
  {
    id: 124104,
    name: "Petersburg",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.99261000",
    longitude: "-79.12392000",
  },
  {
    id: 124130,
    name: "Philippi",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.15232000",
    longitude: "-80.04036000",
  },
  {
    id: 124208,
    name: "Pinch",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.40871000",
    longitude: "-81.48179000",
  },
  {
    id: 124263,
    name: "Pineville",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.58317000",
    longitude: "-81.53705000",
  },
  {
    id: 124378,
    name: "Pleasant Valley",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.45536000",
    longitude: "-80.14175000",
  },
  {
    id: 124385,
    name: "Pleasants County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.37094000",
    longitude: "-81.16063000",
  },
  {
    id: 124415,
    name: "Pocahontas County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.33180000",
    longitude: "-80.00775000",
  },
  {
    id: 124430,
    name: "Point Pleasant",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.84453000",
    longitude: "-82.13709000",
  },
  {
    id: 124659,
    name: "Preston County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.46930000",
    longitude: "-79.66816000",
  },
  {
    id: 124685,
    name: "Princeton",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.36623000",
    longitude: "-81.10259000",
  },
  {
    id: 124713,
    name: "Prosperity",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.83650000",
    longitude: "-81.20177000",
  },
  {
    id: 124765,
    name: "Putnam County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.50862000",
    longitude: "-81.90899000",
  },
  {
    id: 124825,
    name: "Rainelle",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.96873000",
    longitude: "-80.76703000",
  },
  {
    id: 124832,
    name: "Raleigh County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.77136000",
    longitude: "-81.24863000",
  },
  {
    id: 124861,
    name: "Rand",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.28260000",
    longitude: "-81.56234000",
  },
  {
    id: 124878,
    name: "Randolph County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.77472000",
    longitude: "-79.87580000",
  },
  {
    id: 124890,
    name: "Ranson",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.29510000",
    longitude: "-77.86055000",
  },
  {
    id: 124910,
    name: "Ravenswood",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.94814000",
    longitude: "-81.76096000",
  },
  {
    id: 125112,
    name: "Richwood",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.22484000",
    longitude: "-80.53314000",
  },
  {
    id: 125164,
    name: "Ripley",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.81870000",
    longitude: "-81.71069000",
  },
  {
    id: 125173,
    name: "Ritchie County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.17827000",
    longitude: "-81.06295000",
  },
  {
    id: 125225,
    name: "Roane County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.71403000",
    longitude: "-81.34835000",
  },
  {
    id: 125383,
    name: "Romney",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.34204000",
    longitude: "-78.75668000",
  },
  {
    id: 125387,
    name: "Ronceverte",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.74984000",
    longitude: "-80.46285000",
  },
  {
    id: 125594,
    name: "Saint Albans",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.38565000",
    longitude: "-81.83624000",
  },
  {
    id: 125693,
    name: "Saint Marys",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.39174000",
    longitude: "-81.20511000",
  },
  {
    id: 125733,
    name: "Salem",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.28287000",
    longitude: "-80.55899000",
  },
  {
    id: 126208,
    name: "Shady Spring",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.70567000",
    longitude: "-81.09843000",
  },
  {
    id: 126222,
    name: "Shannondale",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.21705000",
    longitude: "-77.80749000",
  },
  {
    id: 126311,
    name: "Shepherdstown",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.43010000",
    longitude: "-77.80416000",
  },
  {
    id: 126355,
    name: "Shinnston",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.39564000",
    longitude: "-80.30009000",
  },
  {
    id: 126467,
    name: "Sissonville",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.52815000",
    longitude: "-81.63096000",
  },
  {
    id: 126469,
    name: "Sistersville",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.56424000",
    longitude: "-80.99594000",
  },
  {
    id: 126603,
    name: "Sophia",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.70761000",
    longitude: "-81.25066000",
  },
  {
    id: 126636,
    name: "South Charleston",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.36843000",
    longitude: "-81.69957000",
  },
  {
    id: 126824,
    name: "Spencer",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.80203000",
    longitude: "-81.35095000",
  },
  {
    id: 126947,
    name: "Stanaford",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.81595000",
    longitude: "-81.15232000",
  },
  {
    id: 126980,
    name: "Star City",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.65841000",
    longitude: "-79.98645000",
  },
  {
    id: 127094,
    name: "Stonewood",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.25092000",
    longitude: "-80.31231000",
  },
  {
    id: 127206,
    name: "Summers County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.65587000",
    longitude: "-80.85857000",
  },
  {
    id: 127208,
    name: "Summersville",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.28122000",
    longitude: "-80.85260000",
  },
  {
    id: 127311,
    name: "Sutton",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.66454000",
    longitude: "-80.70982000",
  },
  {
    id: 127455,
    name: "Taylor County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.33599000",
    longitude: "-80.04618000",
  },
  {
    id: 127477,
    name: "Teays Valley",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.45009000",
    longitude: "-81.92930000",
  },
  {
    id: 127514,
    name: "Terra Alta",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.44565000",
    longitude: "-79.54644000",
  },
  {
    id: 127739,
    name: "Tornado",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.34287000",
    longitude: "-81.84430000",
  },
  {
    id: 127871,
    name: "Tucker County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.11360000",
    longitude: "-79.56497000",
  },
  {
    id: 127943,
    name: "Tyler County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.46528000",
    longitude: "-80.88483000",
  },
  {
    id: 127976,
    name: "Union",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.59151000",
    longitude: "-80.54368000",
  },
  {
    id: 128053,
    name: "Upshur County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.89783000",
    longitude: "-80.23342000",
  },
  {
    id: 128233,
    name: "Vienna",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.32702000",
    longitude: "-81.54846000",
  },
  {
    id: 128544,
    name: "Washington",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.26119000",
    longitude: "-81.67180000",
  },
  {
    id: 128681,
    name: "Wayne",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.22147000",
    longitude: "-82.44237000",
  },
  {
    id: 128697,
    name: "Wayne County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.14595000",
    longitude: "-82.42695000",
  },
  {
    id: 128740,
    name: "Webster County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.49474000",
    longitude: "-80.42187000",
  },
  {
    id: 128745,
    name: "Webster Springs",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.47927000",
    longitude: "-80.41342000",
  },
  {
    id: 128759,
    name: "Weirton",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "40.41896000",
    longitude: "-80.58952000",
  },
  {
    id: 128760,
    name: "Weirton Heights",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "40.40840000",
    longitude: "-80.53924000",
  },
  {
    id: 128765,
    name: "Welch",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.43289000",
    longitude: "-81.58455000",
  },
  {
    id: 128789,
    name: "Wellsburg",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "40.27201000",
    longitude: "-80.60952000",
  },
  {
    id: 128918,
    name: "West Liberty",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "40.16979000",
    longitude: "-80.59369000",
  },
  {
    id: 128994,
    name: "West Union",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.29647000",
    longitude: "-80.77705000",
  },
  {
    id: 129060,
    name: "Weston",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.03843000",
    longitude: "-80.46731000",
  },
  {
    id: 129068,
    name: "Westover",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.63453000",
    longitude: "-79.96979000",
  },
  {
    id: 129093,
    name: "Wetzel County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.60526000",
    longitude: "-80.63910000",
  },
  {
    id: 129121,
    name: "Wheeling",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "40.06396000",
    longitude: "-80.72091000",
  },
  {
    id: 129162,
    name: "White Sulphur Springs",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.79651000",
    longitude: "-80.29757000",
  },
  {
    id: 129243,
    name: "Wiley Ford",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.61453000",
    longitude: "-78.77502000",
  },
  {
    id: 129273,
    name: "Williamson",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.67427000",
    longitude: "-82.27736000",
  },
  {
    id: 129287,
    name: "Williamstown",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.40063000",
    longitude: "-81.44818000",
  },
  {
    id: 129396,
    name: "Winfield",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "38.53314000",
    longitude: "-81.89347000",
  },
  {
    id: 129455,
    name: "Wirt County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.02242000",
    longitude: "-81.37862000",
  },
  {
    id: 129485,
    name: "Wood County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "39.21113000",
    longitude: "-81.51497000",
  },
  {
    id: 129639,
    name: "Wyoming County",
    state_id: 1429,
    state_code: "WV",
    country_id: 233,
    country_code: "US",
    latitude: "37.60961000",
    longitude: "-81.54918000",
  },
  {
    id: 110970,
    name: "Abbotsford",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.94636000",
    longitude: "-90.31597000",
  },
  {
    id: 111015,
    name: "Adams",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.95608000",
    longitude: "-89.81818000",
  },
  {
    id: 111023,
    name: "Adams County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.96963000",
    longitude: "-89.77064000",
  },
  {
    id: 111038,
    name: "Addison",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.42278000",
    longitude: "-88.37454000",
  },
  {
    id: 111109,
    name: "Albany",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.70778000",
    longitude: "-89.43706000",
  },
  {
    id: 111162,
    name: "Algoma",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.60889000",
    longitude: "-87.43259000",
  },
  {
    id: 111203,
    name: "Allouez",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.47749000",
    longitude: "-88.01621000",
  },
  {
    id: 111211,
    name: "Alma",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.31997000",
    longitude: "-91.91488000",
  },
  {
    id: 111241,
    name: "Alto",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.67665000",
    longitude: "-88.79511000",
  },
  {
    id: 111249,
    name: "Altoona",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.80468000",
    longitude: "-91.44321000",
  },
  {
    id: 111275,
    name: "Amery",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.30691000",
    longitude: "-92.36214000",
  },
  {
    id: 111284,
    name: "Amherst",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.45081000",
    longitude: "-89.28484000",
  },
  {
    id: 111371,
    name: "Antigo",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.14025000",
    longitude: "-89.15234000",
  },
  {
    id: 111397,
    name: "Appleton",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.26193000",
    longitude: "-88.41538000",
  },
  {
    id: 111424,
    name: "Arcadia",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.25274000",
    longitude: "-91.50154000",
  },
  {
    id: 111525,
    name: "Ashford",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.58694000",
    longitude: "-88.37066000",
  },
  {
    id: 111539,
    name: "Ashland",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "46.59244000",
    longitude: "-90.88380000",
  },
  {
    id: 111542,
    name: "Ashland County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "46.50974000",
    longitude: "-90.71960000",
  },
  {
    id: 111549,
    name: "Ashwaubenon",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.48221000",
    longitude: "-88.07010000",
  },
  {
    id: 111581,
    name: "Athens",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.03302000",
    longitude: "-90.07402000",
  },
  {
    id: 111651,
    name: "Augusta",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.68024000",
    longitude: "-91.11988000",
  },
  {
    id: 111711,
    name: "Aztalan",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.07278000",
    longitude: "-88.86233000",
  },
  {
    id: 111758,
    name: "Baldwin",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.96663000",
    longitude: "-92.37436000",
  },
  {
    id: 111780,
    name: "Balsam Lake",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.45218000",
    longitude: "-92.45464000",
  },
  {
    id: 111794,
    name: "Bangor",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.89302000",
    longitude: "-90.99041000",
  },
  {
    id: 111808,
    name: "Baraboo",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.47109000",
    longitude: "-89.74429000",
  },
  {
    id: 111831,
    name: "Barneveld",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.01555000",
    longitude: "-89.89540000",
  },
  {
    id: 111850,
    name: "Barron",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.40135000",
    longitude: "-91.84906000",
  },
  {
    id: 111851,
    name: "Barron County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.42372000",
    longitude: "-91.84831000",
  },
  {
    id: 111865,
    name: "Barton",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.44361000",
    longitude: "-88.18065000",
  },
  {
    id: 111942,
    name: "Bayfield County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "46.63544000",
    longitude: "-91.18068000",
  },
  {
    id: 111956,
    name: "Bayside",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.18056000",
    longitude: "-87.90064000",
  },
  {
    id: 112001,
    name: "Beaver Dam",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.45777000",
    longitude: "-88.83733000",
  },
  {
    id: 112060,
    name: "Belgium",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.49972000",
    longitude: "-87.85037000",
  },
  {
    id: 112091,
    name: "Belle Plaine",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.71526000",
    longitude: "-88.66621000",
  },
  {
    id: 112108,
    name: "Belleville",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.85972000",
    longitude: "-89.53818000",
  },
  {
    id: 112115,
    name: "Bellevue",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.44416000",
    longitude: "-87.92010000",
  },
  {
    id: 112146,
    name: "Beloit",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.50835000",
    longitude: "-89.03178000",
  },
  {
    id: 112239,
    name: "Berlin",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.96804000",
    longitude: "-88.94345000",
  },
  {
    id: 112298,
    name: "Bevent",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.77053000",
    longitude: "-89.38956000",
  },
  {
    id: 112318,
    name: "Big Bend",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.88140000",
    longitude: "-88.20676000",
  },
  {
    id: 112372,
    name: "Black Creek",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.47749000",
    longitude: "-88.45066000",
  },
  {
    id: 112375,
    name: "Black Earth",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.13722000",
    longitude: "-89.74679000",
  },
  {
    id: 112383,
    name: "Black River Falls",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.29468000",
    longitude: "-90.85153000",
  },
  {
    id: 112410,
    name: "Blair",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.29440000",
    longitude: "-91.23516000",
  },
  {
    id: 112432,
    name: "Bloomer",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.10024000",
    longitude: "-91.48877000",
  },
  {
    id: 112503,
    name: "Bohners Lake",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.62307000",
    longitude: "-88.28037000",
  },
  {
    id: 112533,
    name: "Bonduel",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.74027000",
    longitude: "-88.44482000",
  },
  {
    id: 112583,
    name: "Boscobel",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.13443000",
    longitude: "-90.70540000",
  },
  {
    id: 112640,
    name: "Boyceville",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.04357000",
    longitude: "-92.04101000",
  },
  {
    id: 112752,
    name: "Brice Prairie",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.93857000",
    longitude: "-91.29986000",
  },
  {
    id: 112796,
    name: "Brillion",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.17721000",
    longitude: "-88.06427000",
  },
  {
    id: 112811,
    name: "Bristol",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.55891000",
    longitude: "-88.04925000",
  },
  {
    id: 112838,
    name: "Brodhead",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.61834000",
    longitude: "-89.37623000",
  },
  {
    id: 112855,
    name: "Brookfield",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.06057000",
    longitude: "-88.10648000",
  },
  {
    id: 112873,
    name: "Brooklyn",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.85361000",
    longitude: "-89.37040000",
  },
  {
    id: 112902,
    name: "Brothertown",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.96805000",
    longitude: "-88.30899000",
  },
  {
    id: 112914,
    name: "Brown County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.47433000",
    longitude: "-87.99287000",
  },
  {
    id: 112915,
    name: "Brown Deer",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.16334000",
    longitude: "-87.96453000",
  },
  {
    id: 112920,
    name: "Browns Lake",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.69252000",
    longitude: "-88.23120000",
  },
  {
    id: 112958,
    name: "Brussels",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.73611000",
    longitude: "-87.62093000",
  },
  {
    id: 113020,
    name: "Buffalo County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.37983000",
    longitude: "-91.75447000",
  },
  {
    id: 113073,
    name: "Burlington",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.67807000",
    longitude: "-88.27620000",
  },
  {
    id: 113080,
    name: "Burnett County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.86272000",
    longitude: "-92.36757000",
  },
  {
    id: 113112,
    name: "Butler",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.10584000",
    longitude: "-88.06953000",
  },
  {
    id: 113166,
    name: "Cadott",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.94802000",
    longitude: "-91.15070000",
  },
  {
    id: 113192,
    name: "Caledonia",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.80780000",
    longitude: "-87.92425000",
  },
  {
    id: 113231,
    name: "Calumet County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.08160000",
    longitude: "-88.21806000",
  },
  {
    id: 113255,
    name: "Cambridge",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.00361000",
    longitude: "-89.01650000",
  },
  {
    id: 113276,
    name: "Cameron",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.40857000",
    longitude: "-91.74406000",
  },
  {
    id: 113288,
    name: "Camp Lake",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.53474000",
    longitude: "-88.14370000",
  },
  {
    id: 113304,
    name: "Campbellsport",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.59777000",
    longitude: "-88.27899000",
  },
  {
    id: 113523,
    name: "Cashton",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.74191000",
    longitude: "-90.77930000",
  },
  {
    id: 113574,
    name: "Cato",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.14277000",
    longitude: "-87.86120000",
  },
  {
    id: 113612,
    name: "Cedar Grove",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.56972000",
    longitude: "-87.82342000",
  },
  {
    id: 113624,
    name: "Cedarburg",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.29667000",
    longitude: "-87.98759000",
  },
  {
    id: 113915,
    name: "Chetek",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.31413000",
    longitude: "-91.65100000",
  },
  {
    id: 113956,
    name: "Chilton",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.02888000",
    longitude: "-88.16288000",
  },
  {
    id: 113975,
    name: "Chippewa County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.06940000",
    longitude: "-91.27989000",
  },
  {
    id: 113976,
    name: "Chippewa Falls",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.93691000",
    longitude: "-91.39293000",
  },
  {
    id: 114128,
    name: "Clark County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.73471000",
    longitude: "-90.61208000",
  },
  {
    id: 114211,
    name: "Clear Lake",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.25191000",
    longitude: "-92.27130000",
  },
  {
    id: 114247,
    name: "Cleveland",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.91499000",
    longitude: "-87.74731000",
  },
  {
    id: 114286,
    name: "Clinton",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.55779000",
    longitude: "-88.86511000",
  },
  {
    id: 114299,
    name: "Clintonville",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.62053000",
    longitude: "-88.76232000",
  },
  {
    id: 114380,
    name: "Colby",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.90997000",
    longitude: "-90.31569000",
  },
  {
    id: 114407,
    name: "Colfax",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.99746000",
    longitude: "-91.72712000",
  },
  {
    id: 114482,
    name: "Columbia County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.46660000",
    longitude: "-89.33373000",
  },
  {
    id: 114497,
    name: "Columbus",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.33805000",
    longitude: "-89.01539000",
  },
  {
    id: 114518,
    name: "Combined Locks",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.26582000",
    longitude: "-88.31427000",
  },
  {
    id: 114528,
    name: "Como",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.61224000",
    longitude: "-88.48232000",
  },
  {
    id: 114542,
    name: "Concord",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.06945000",
    longitude: "-88.59871000",
  },
  {
    id: 114607,
    name: "Cooperstown",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.31277000",
    longitude: "-87.77453000",
  },
  {
    id: 114649,
    name: "Cornell",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.16719000",
    longitude: "-91.14931000",
  },
  {
    id: 114692,
    name: "Cottage Grove",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.07611000",
    longitude: "-89.19956000",
  },
  {
    id: 114766,
    name: "Crandon",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.57191000",
    longitude: "-88.90289000",
  },
  {
    id: 114783,
    name: "Crawford County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.23946000",
    longitude: "-90.93105000",
  },
  {
    id: 114853,
    name: "Cross Plains",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.11444000",
    longitude: "-89.65568000",
  },
  {
    id: 114888,
    name: "Cuba City",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.60555000",
    longitude: "-90.42985000",
  },
  {
    id: 114889,
    name: "Cudahy",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.95974000",
    longitude: "-87.86147000",
  },
  {
    id: 114910,
    name: "Cumberland",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.53218000",
    longitude: "-92.01935000",
  },
  {
    id: 114973,
    name: "Dakota",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.99025000",
    longitude: "-89.35651000",
  },
  {
    id: 115019,
    name: "Dane",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.25055000",
    longitude: "-89.50151000",
  },
  {
    id: 115020,
    name: "Dane County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.06735000",
    longitude: "-89.41832000",
  },
  {
    id: 115048,
    name: "Darien",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.60168000",
    longitude: "-88.70760000",
  },
  {
    id: 115050,
    name: "Darlington",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.68306000",
    longitude: "-90.11763000",
  },
  {
    id: 115105,
    name: "De Forest",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.24777000",
    longitude: "-89.34373000",
  },
  {
    id: 115112,
    name: "De Pere",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.44888000",
    longitude: "-88.06038000",
  },
  {
    id: 115158,
    name: "Decatur",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.63433000",
    longitude: "-89.41155000",
  },
  {
    id: 115183,
    name: "Deerfield",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.05194000",
    longitude: "-89.07567000",
  },
  {
    id: 115196,
    name: "Delafield",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.06084000",
    longitude: "-88.40371000",
  },
  {
    id: 115201,
    name: "Delavan",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.63307000",
    longitude: "-88.64371000",
  },
  {
    id: 115202,
    name: "Delavan Lake",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.58418000",
    longitude: "-88.63260000",
  },
  {
    id: 115241,
    name: "Denmark",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.34777000",
    longitude: "-87.82732000",
  },
  {
    id: 115324,
    name: "Dickeyville",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.62722000",
    longitude: "-90.59207000",
  },
  {
    id: 115380,
    name: "Dodge County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.41630000",
    longitude: "-88.70752000",
  },
  {
    id: 115381,
    name: "Dodgeville",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.96027000",
    longitude: "-90.13012000",
  },
  {
    id: 115406,
    name: "Door County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.83834000",
    longitude: "-87.35779000",
  },
  {
    id: 115432,
    name: "Douglas County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "46.46411000",
    longitude: "-91.89940000",
  },
  {
    id: 115441,
    name: "Dousman",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.01418000",
    longitude: "-88.47260000",
  },
  {
    id: 115547,
    name: "Dunn County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.94659000",
    longitude: "-91.89641000",
  },
  {
    id: 115564,
    name: "Durand",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.62635000",
    longitude: "-91.96573000",
  },
  {
    id: 115596,
    name: "Eagle",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.87946000",
    longitude: "-88.47427000",
  },
  {
    id: 115605,
    name: "Eagle Lake",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.70696000",
    longitude: "-88.12814000",
  },
  {
    id: 115611,
    name: "Eagle River",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.91718000",
    longitude: "-89.24430000",
  },
  {
    id: 115766,
    name: "East Troy",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.78529000",
    longitude: "-88.40510000",
  },
  {
    id: 115790,
    name: "Easton",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.83803000",
    longitude: "-89.80679000",
  },
  {
    id: 115810,
    name: "Eau Claire",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.81135000",
    longitude: "-91.49849000",
  },
  {
    id: 115811,
    name: "Eau Claire County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.72677000",
    longitude: "-91.28600000",
  },
  {
    id: 115835,
    name: "Edgar",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.92719000",
    longitude: "-89.96346000",
  },
  {
    id: 115850,
    name: "Edgerton",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.83528000",
    longitude: "-89.06761000",
  },
  {
    id: 115953,
    name: "Eldorado",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.82471000",
    longitude: "-88.62178000",
  },
  {
    id: 116002,
    name: "Elkhorn",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.67279000",
    longitude: "-88.54454000",
  },
  {
    id: 116039,
    name: "Ellsworth",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.73219000",
    longitude: "-92.48741000",
  },
  {
    id: 116044,
    name: "Elm Grove",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.04307000",
    longitude: "-88.07898000",
  },
  {
    id: 116067,
    name: "Elroy",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.74080000",
    longitude: "-90.27235000",
  },
  {
    id: 116254,
    name: "Evansville",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.78028000",
    longitude: "-89.29928000",
  },
  {
    id: 116262,
    name: "Evergreen",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.84247000",
    longitude: "-89.63762000",
  },
  {
    id: 116372,
    name: "Fall Creek",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.76357000",
    longitude: "-91.27710000",
  },
  {
    id: 116374,
    name: "Fall River",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.38443000",
    longitude: "-89.04511000",
  },
  {
    id: 116472,
    name: "Fennimore",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.98360000",
    longitude: "-90.65540000",
  },
  {
    id: 116535,
    name: "Fitchburg",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.96083000",
    longitude: "-89.46984000",
  },
  {
    id: 116584,
    name: "Florence",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.92218000",
    longitude: "-88.25180000",
  },
  {
    id: 116588,
    name: "Florence County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.84845000",
    longitude: "-88.39816000",
  },
  {
    id: 116627,
    name: "Fond du Lac",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.77500000",
    longitude: "-88.43883000",
  },
  {
    id: 116628,
    name: "Fond du Lac County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.75359000",
    longitude: "-88.48826000",
  },
  {
    id: 116630,
    name: "Fontana",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.55141000",
    longitude: "-88.57510000",
  },
  {
    id: 116651,
    name: "Forest County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.66726000",
    longitude: "-88.77038000",
  },
  {
    id: 116689,
    name: "Fort Atkinson",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.92889000",
    longitude: "-88.83705000",
  },
  {
    id: 116804,
    name: "Fox Lake",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.56554000",
    longitude: "-88.90650000",
  },
  {
    id: 116806,
    name: "Fox Point",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.15751000",
    longitude: "-87.90175000",
  },
  {
    id: 116841,
    name: "Franklin",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.88863000",
    longitude: "-88.03842000",
  },
  {
    id: 116878,
    name: "Franksville",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.76002000",
    longitude: "-87.91341000",
  },
  {
    id: 116884,
    name: "Frederic",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.65912000",
    longitude: "-92.46714000",
  },
  {
    id: 116897,
    name: "Fredonia",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.47056000",
    longitude: "-87.95065000",
  },
  {
    id: 116930,
    name: "French Island",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.85830000",
    longitude: "-91.26042000",
  },
  {
    id: 116950,
    name: "Friendship",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.97053000",
    longitude: "-89.81679000",
  },
  {
    id: 117029,
    name: "Galesville",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.08163000",
    longitude: "-91.34904000",
  },
  {
    id: 117148,
    name: "Genoa City",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.49835000",
    longitude: "-88.32815000",
  },
  {
    id: 117174,
    name: "Germantown",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.22862000",
    longitude: "-88.11037000",
  },
  {
    id: 117216,
    name: "Gillett",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.88999000",
    longitude: "-88.30732000",
  },
  {
    id: 117284,
    name: "Glendale",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.13529000",
    longitude: "-87.93564000",
  },
  {
    id: 117295,
    name: "Glenmore",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.38583000",
    longitude: "-87.92732000",
  },
  {
    id: 117316,
    name: "Glenwood City",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.05857000",
    longitude: "-92.17241000",
  },
  {
    id: 117432,
    name: "Grafton",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.31973000",
    longitude: "-87.95342000",
  },
  {
    id: 117515,
    name: "Grant County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.86749000",
    longitude: "-90.70622000",
  },
  {
    id: 117529,
    name: "Grantsburg",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.77634000",
    longitude: "-92.68270000",
  },
  {
    id: 117591,
    name: "Green Bay",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.51916000",
    longitude: "-88.01983000",
  },
  {
    id: 117593,
    name: "Green County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.67999000",
    longitude: "-89.60221000",
  },
  {
    id: 117601,
    name: "Green Lake",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.84415000",
    longitude: "-88.96011000",
  },
  {
    id: 117602,
    name: "Green Lake County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.80038000",
    longitude: "-89.04486000",
  },
  {
    id: 117627,
    name: "Greendale",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.94057000",
    longitude: "-87.99592000",
  },
  {
    id: 117653,
    name: "Greenfield",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.96140000",
    longitude: "-88.01259000",
  },
  {
    id: 117708,
    name: "Greenwood",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.77024000",
    longitude: "-90.59931000",
  },
  {
    id: 117851,
    name: "Hales Corners",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.93751000",
    longitude: "-88.04870000",
  },
  {
    id: 117914,
    name: "Hammond",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.97886000",
    longitude: "-92.43575000",
  },
  {
    id: 118061,
    name: "Harrison",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.22776000",
    longitude: "-88.33591000",
  },
  {
    id: 118091,
    name: "Hartford",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.31778000",
    longitude: "-88.37899000",
  },
  {
    id: 118097,
    name: "Hartland",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.10501000",
    longitude: "-88.34204000",
  },
  {
    id: 118196,
    name: "Hayward",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "46.01301000",
    longitude: "-91.48462000",
  },
  {
    id: 118205,
    name: "Hazel Green",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.53278000",
    longitude: "-90.43457000",
  },
  {
    id: 118428,
    name: "Hilbert",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.14027000",
    longitude: "-88.16399000",
  },
  {
    id: 118451,
    name: "Hillsboro",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.65220000",
    longitude: "-90.34402000",
  },
  {
    id: 118499,
    name: "Hobart",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.49925000",
    longitude: "-88.14986000",
  },
  {
    id: 118569,
    name: "Holmen",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.96330000",
    longitude: "-91.25625000",
  },
  {
    id: 118663,
    name: "Horicon",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.45138000",
    longitude: "-88.63121000",
  },
  {
    id: 118679,
    name: "Hortonville",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.33470000",
    longitude: "-88.63816000",
  },
  {
    id: 118708,
    name: "Howard",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.54360000",
    longitude: "-88.08816000",
  },
  {
    id: 118720,
    name: "Howards Grove",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.83388000",
    longitude: "-87.82009000",
  },
  {
    id: 118746,
    name: "Hudson",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.97469000",
    longitude: "-92.75687000",
  },
  {
    id: 118821,
    name: "Hurley",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "46.44967000",
    longitude: "-90.18656000",
  },
  {
    id: 118833,
    name: "Hustisford",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.34611000",
    longitude: "-88.60066000",
  },
  {
    id: 118896,
    name: "Independence",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.35691000",
    longitude: "-91.42043000",
  },
  {
    id: 118967,
    name: "Iola",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.50803000",
    longitude: "-89.13067000",
  },
  {
    id: 118979,
    name: "Iowa County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.00048000",
    longitude: "-90.13543000",
  },
  {
    id: 118990,
    name: "Iron County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "46.31706000",
    longitude: "-90.26445000",
  },
  {
    id: 119053,
    name: "Ixonia",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.14389000",
    longitude: "-88.59732000",
  },
  {
    id: 119072,
    name: "Jackson",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.32389000",
    longitude: "-88.16676000",
  },
  {
    id: 119093,
    name: "Jackson County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.31913000",
    longitude: "-90.80518000",
  },
  {
    id: 119131,
    name: "Janesville",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.68279000",
    longitude: "-89.01872000",
  },
  {
    id: 119171,
    name: "Jefferson",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.00556000",
    longitude: "-88.80733000",
  },
  {
    id: 119193,
    name: "Jefferson County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.02082000",
    longitude: "-88.77587000",
  },
  {
    id: 119272,
    name: "Johnson Creek",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.07611000",
    longitude: "-88.77427000",
  },
  {
    id: 119337,
    name: "Juneau",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.40555000",
    longitude: "-88.70510000",
  },
  {
    id: 119339,
    name: "Juneau County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.92444000",
    longitude: "-90.11402000",
  },
  {
    id: 119399,
    name: "Kaukauna",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.27804000",
    longitude: "-88.27205000",
  },
  {
    id: 119476,
    name: "Kenosha",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.58474000",
    longitude: "-87.82119000",
  },
  {
    id: 119477,
    name: "Kenosha County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.57280000",
    longitude: "-87.83981000",
  },
  {
    id: 119522,
    name: "Keshena",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.88387000",
    longitude: "-88.63372000",
  },
  {
    id: 119533,
    name: "Kewaskum",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.52083000",
    longitude: "-88.22899000",
  },
  {
    id: 119534,
    name: "Kewaunee",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.45833000",
    longitude: "-87.50314000",
  },
  {
    id: 119535,
    name: "Kewaunee County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.48279000",
    longitude: "-87.51434000",
  },
  {
    id: 119554,
    name: "Kiel",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.91249000",
    longitude: "-88.03565000",
  },
  {
    id: 119570,
    name: "Kimberly",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.27221000",
    longitude: "-88.33900000",
  },
  {
    id: 119577,
    name: "King",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.33748000",
    longitude: "-89.14178000",
  },
  {
    id: 119701,
    name: "Kohler",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.73916000",
    longitude: "-87.78175000",
  },
  {
    id: 119717,
    name: "Kronenwetter",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.82219000",
    longitude: "-89.59040000",
  },
  {
    id: 119744,
    name: "La Crosse",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.80136000",
    longitude: "-91.23958000",
  },
  {
    id: 119745,
    name: "La Crosse County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.90653000",
    longitude: "-91.11522000",
  },
  {
    id: 119810,
    name: "Lac du Flambeau",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.96967000",
    longitude: "-89.89210000",
  },
  {
    id: 119830,
    name: "Ladysmith",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.46302000",
    longitude: "-91.10404000",
  },
  {
    id: 119842,
    name: "Lafayette County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.66050000",
    longitude: "-90.13172000",
  },
  {
    id: 119903,
    name: "Lake Delton",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.60109000",
    longitude: "-89.79374000",
  },
  {
    id: 119913,
    name: "Lake Geneva",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.59168000",
    longitude: "-88.43343000",
  },
  {
    id: 119915,
    name: "Lake Hallie",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.87579000",
    longitude: "-91.44071000",
  },
  {
    id: 119928,
    name: "Lake Koshkonong",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.90973000",
    longitude: "-88.91955000",
  },
  {
    id: 119943,
    name: "Lake Mills",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.08139000",
    longitude: "-88.91177000",
  },
  {
    id: 119951,
    name: "Lake Nebagamon",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "46.51494000",
    longitude: "-91.69991000",
  },
  {
    id: 119969,
    name: "Lake Ripley",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.00583000",
    longitude: "-88.98622000",
  },
  {
    id: 119992,
    name: "Lake Wazeecha",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.37108000",
    longitude: "-89.75651000",
  },
  {
    id: 119995,
    name: "Lake Wisconsin",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.37360000",
    longitude: "-89.57568000",
  },
  {
    id: 119996,
    name: "Lake Wissota",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.92635000",
    longitude: "-91.30099000",
  },
  {
    id: 120065,
    name: "Lamartine",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.73332000",
    longitude: "-88.56872000",
  },
  {
    id: 120088,
    name: "Lancaster",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.84749000",
    longitude: "-90.71068000",
  },
  {
    id: 120112,
    name: "Langlade County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.26236000",
    longitude: "-89.07191000",
  },
  {
    id: 120120,
    name: "Lannon",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.14612000",
    longitude: "-88.16620000",
  },
  {
    id: 120332,
    name: "Legend Lake",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.89095000",
    longitude: "-88.54406000",
  },
  {
    id: 120529,
    name: "Lincoln County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.33746000",
    longitude: "-89.73461000",
  },
  {
    id: 120614,
    name: "Little Chute",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.27999000",
    longitude: "-88.31844000",
  },
  {
    id: 120629,
    name: "Little Round Lake",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.96495000",
    longitude: "-91.36795000",
  },
  {
    id: 120690,
    name: "Lodi",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.31388000",
    longitude: "-89.52651000",
  },
  {
    id: 120715,
    name: "Lomira",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.59138000",
    longitude: "-88.44371000",
  },
  {
    id: 120853,
    name: "Loyal",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.73691000",
    longitude: "-90.49597000",
  },
  {
    id: 120867,
    name: "Luck",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.57607000",
    longitude: "-92.48270000",
  },
  {
    id: 120901,
    name: "Luxemburg",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.53861000",
    longitude: "-87.70398000",
  },
  {
    id: 120999,
    name: "Madison",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.07305000",
    longitude: "-89.40123000",
  },
  {
    id: 121050,
    name: "Maine",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.02716000",
    longitude: "-89.69039000",
  },
  {
    id: 121086,
    name: "Manawa",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.46443000",
    longitude: "-88.91983000",
  },
  {
    id: 121101,
    name: "Manchester",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.69054000",
    longitude: "-89.04845000",
  },
  {
    id: 121127,
    name: "Manitowoc",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.08861000",
    longitude: "-87.65758000",
  },
  {
    id: 121128,
    name: "Manitowoc County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.09054000",
    longitude: "-87.69974000",
  },
  {
    id: 121165,
    name: "Maple Bluff",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.11833000",
    longitude: "-89.37956000",
  },
  {
    id: 121185,
    name: "Marathon",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.92913000",
    longitude: "-89.84040000",
  },
  {
    id: 121186,
    name: "Marathon County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.89829000",
    longitude: "-89.75906000",
  },
  {
    id: 121228,
    name: "Marinette",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.09998000",
    longitude: "-87.63066000",
  },
  {
    id: 121229,
    name: "Marinette County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.35090000",
    longitude: "-88.00222000",
  },
  {
    id: 121245,
    name: "Marion",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.67081000",
    longitude: "-88.88927000",
  },
  {
    id: 121268,
    name: "Markesan",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.70720000",
    longitude: "-88.99011000",
  },
  {
    id: 121294,
    name: "Marquette County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.81958000",
    longitude: "-89.39875000",
  },
  {
    id: 121309,
    name: "Marshall",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.16833000",
    longitude: "-89.06678000",
  },
  {
    id: 121327,
    name: "Marshfield",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.66885000",
    longitude: "-90.17180000",
  },
  {
    id: 121427,
    name: "Mauston",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.79719000",
    longitude: "-90.07735000",
  },
  {
    id: 121456,
    name: "Mayville",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.49416000",
    longitude: "-88.54482000",
  },
  {
    id: 121460,
    name: "Mazomanie",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.17666000",
    longitude: "-89.79485000",
  },
  {
    id: 121499,
    name: "McFarland",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.01250000",
    longitude: "-89.28984000",
  },
  {
    id: 121595,
    name: "Medford",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.13858000",
    longitude: "-90.34014000",
  },
  {
    id: 121649,
    name: "Menasha",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.20221000",
    longitude: "-88.44650000",
  },
  {
    id: 121664,
    name: "Menominee County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.00437000",
    longitude: "-88.71001000",
  },
  {
    id: 121665,
    name: "Menomonee Falls",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.17890000",
    longitude: "-88.11731000",
  },
  {
    id: 121666,
    name: "Menomonie",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.87552000",
    longitude: "-91.91934000",
  },
  {
    id: 121669,
    name: "Mequon",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.21555000",
    longitude: "-88.03001000",
  },
  {
    id: 121707,
    name: "Merrill",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.18052000",
    longitude: "-89.68346000",
  },
  {
    id: 121718,
    name: "Merton",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.14667000",
    longitude: "-88.30676000",
  },
  {
    id: 121783,
    name: "Middleton",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.09722000",
    longitude: "-89.50429000",
  },
  {
    id: 121858,
    name: "Milford",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.10083000",
    longitude: "-88.84677000",
  },
  {
    id: 121918,
    name: "Milton",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.77556000",
    longitude: "-88.94400000",
  },
  {
    id: 121923,
    name: "Milwaukee",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.03890000",
    longitude: "-87.90647000",
  },
  {
    id: 121924,
    name: "Milwaukee County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.02172000",
    longitude: "-87.92908000",
  },
  {
    id: 121938,
    name: "Mineral Point",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.86000000",
    longitude: "-90.17985000",
  },
  {
    id: 121977,
    name: "Mishicot",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.23916000",
    longitude: "-87.64119000",
  },
  {
    id: 122036,
    name: "Mondovi",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.56774000",
    longitude: "-91.67099000",
  },
  {
    id: 122052,
    name: "Monona",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.06222000",
    longitude: "-89.33401000",
  },
  {
    id: 122063,
    name: "Monroe",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.60112000",
    longitude: "-89.63845000",
  },
  {
    id: 122082,
    name: "Monroe County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.94575000",
    longitude: "-90.61787000",
  },
  {
    id: 122117,
    name: "Montello",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.79137000",
    longitude: "-89.31984000",
  },
  {
    id: 122170,
    name: "Monticello",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.74556000",
    longitude: "-89.59484000",
  },
  {
    id: 122305,
    name: "Mosinee",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.79302000",
    longitude: "-89.70318000",
  },
  {
    id: 122354,
    name: "Mount Horeb",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.00861000",
    longitude: "-89.73846000",
  },
  {
    id: 122366,
    name: "Mount Morris",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.11442000",
    longitude: "-89.19067000",
  },
  {
    id: 122382,
    name: "Mount Pleasant",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.69743000",
    longitude: "-87.85577000",
  },
  {
    id: 122451,
    name: "Mukwonago",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.86668000",
    longitude: "-88.33343000",
  },
  {
    id: 122504,
    name: "Muscoda",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.18499000",
    longitude: "-90.44318000",
  },
  {
    id: 122508,
    name: "Muskego",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.90585000",
    longitude: "-88.13898000",
  },
  {
    id: 122569,
    name: "Nashotah",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.09779000",
    longitude: "-88.40232000",
  },
  {
    id: 122579,
    name: "Nashville",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.52274000",
    longitude: "-89.02484000",
  },
  {
    id: 122616,
    name: "Neenah",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.18582000",
    longitude: "-88.46261000",
  },
  {
    id: 122618,
    name: "Neillsville",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.55996000",
    longitude: "-90.59625000",
  },
  {
    id: 122619,
    name: "Nekoosa",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.31246000",
    longitude: "-89.90429000",
  },
  {
    id: 122664,
    name: "New Berlin",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.97640000",
    longitude: "-88.10842000",
  },
  {
    id: 122708,
    name: "New Glarus",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.81445000",
    longitude: "-89.63512000",
  },
  {
    id: 122722,
    name: "New Holstein",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.94999000",
    longitude: "-88.08426000",
  },
  {
    id: 122738,
    name: "New Lisbon",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.87914000",
    longitude: "-90.16541000",
  },
  {
    id: 122745,
    name: "New London",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.39276000",
    longitude: "-88.73983000",
  },
  {
    id: 122770,
    name: "New Richmond",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.12302000",
    longitude: "-92.53659000",
  },
  {
    id: 122815,
    name: "Newburg",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.43167000",
    longitude: "-88.04648000",
  },
  {
    id: 122880,
    name: "Niagara",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.77135000",
    longitude: "-87.99485000",
  },
  {
    id: 123026,
    name: "North Fond du Lac",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.81138000",
    longitude: "-88.48344000",
  },
  {
    id: 123043,
    name: "North Hudson",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.99302000",
    longitude: "-92.75687000",
  },
  {
    id: 123049,
    name: "North La Crosse",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.84635000",
    longitude: "-91.24819000",
  },
  {
    id: 123088,
    name: "North Prairie",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.93446000",
    longitude: "-88.40537000",
  },
  {
    id: 123232,
    name: "Oak Creek",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.88585000",
    longitude: "-87.86314000",
  },
  {
    id: 123277,
    name: "Oakfield",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.68610000",
    longitude: "-88.54650000",
  },
  {
    id: 123350,
    name: "Oconomowoc",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.11167000",
    longitude: "-88.49927000",
  },
  {
    id: 123351,
    name: "Oconto",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.88721000",
    longitude: "-87.86455000",
  },
  {
    id: 123352,
    name: "Oconto County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.99851000",
    longitude: "-88.22056000",
  },
  {
    id: 123353,
    name: "Oconto Falls",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.87388000",
    longitude: "-88.14288000",
  },
  {
    id: 123396,
    name: "Okauchee Lake",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.12334000",
    longitude: "-88.44065000",
  },
  {
    id: 123466,
    name: "Omro",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.03943000",
    longitude: "-88.74428000",
  },
  {
    id: 123468,
    name: "Onalaska",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.88441000",
    longitude: "-91.23514000",
  },
  {
    id: 123474,
    name: "Oneida",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.49860000",
    longitude: "-88.18288000",
  },
  {
    id: 123476,
    name: "Oneida County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.70559000",
    longitude: "-89.52171000",
  },
  {
    id: 123493,
    name: "Oostburg",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.62277000",
    longitude: "-87.79453000",
  },
  {
    id: 123547,
    name: "Oregon",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.92611000",
    longitude: "-89.38456000",
  },
  {
    id: 123553,
    name: "Orfordville",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.62751000",
    longitude: "-89.25317000",
  },
  {
    id: 123602,
    name: "Osceola",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.32052000",
    longitude: "-92.70493000",
  },
  {
    id: 123609,
    name: "Oshkosh",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.02471000",
    longitude: "-88.54261000",
  },
  {
    id: 123615,
    name: "Osseo",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.57218000",
    longitude: "-91.22738000",
  },
  {
    id: 123648,
    name: "Outagamie County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.41609000",
    longitude: "-88.46493000",
  },
  {
    id: 123700,
    name: "Ozaukee County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.40305000",
    longitude: "-87.89063000",
  },
  {
    id: 123713,
    name: "Paddock Lake",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.57752000",
    longitude: "-88.10509000",
  },
  {
    id: 123778,
    name: "Palmyra",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.87779000",
    longitude: "-88.58621000",
  },
  {
    id: 123828,
    name: "Pardeeville",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.53776000",
    longitude: "-89.30012000",
  },
  {
    id: 123844,
    name: "Park Falls",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.93440000",
    longitude: "-90.44155000",
  },
  {
    id: 123998,
    name: "Pell Lake",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.53807000",
    longitude: "-88.35093000",
  },
  {
    id: 124053,
    name: "Pepin County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.48265000",
    longitude: "-92.14969000",
  },
  {
    id: 124099,
    name: "Peshtigo",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.05443000",
    longitude: "-87.74927000",
  },
  {
    id: 124115,
    name: "Pewaukee",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.08057000",
    longitude: "-88.26120000",
  },
  {
    id: 124134,
    name: "Phillips",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.69663000",
    longitude: "-90.40043000",
  },
  {
    id: 124173,
    name: "Pierce County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.71963000",
    longitude: "-92.42249000",
  },
  {
    id: 124301,
    name: "Pittsfield",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.59944000",
    longitude: "-88.24510000",
  },
  {
    id: 124357,
    name: "Platteville",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.73416000",
    longitude: "-90.47846000",
  },
  {
    id: 124375,
    name: "Pleasant Prairie",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.55308000",
    longitude: "-87.93341000",
  },
  {
    id: 124391,
    name: "Plover",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.45636000",
    longitude: "-89.54401000",
  },
  {
    id: 124408,
    name: "Plymouth",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.74861000",
    longitude: "-87.97704000",
  },
  {
    id: 124450,
    name: "Polk County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.46142000",
    longitude: "-92.44139000",
  },
  {
    id: 124505,
    name: "Port Edwards",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.35080000",
    longitude: "-89.86540000",
  },
  {
    id: 124540,
    name: "Port Washington",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.38722000",
    longitude: "-87.87564000",
  },
  {
    id: 124546,
    name: "Portage",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.53915000",
    longitude: "-89.46262000",
  },
  {
    id: 124547,
    name: "Portage County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.47604000",
    longitude: "-89.50142000",
  },
  {
    id: 124564,
    name: "Portland",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.76886000",
    longitude: "-90.85819000",
  },
  {
    id: 124595,
    name: "Potter Lake",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.82168000",
    longitude: "-88.34898000",
  },
  {
    id: 124616,
    name: "Powers Lake",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.55363000",
    longitude: "-88.29454000",
  },
  {
    id: 124621,
    name: "Poynette",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.39026000",
    longitude: "-89.40290000",
  },
  {
    id: 124633,
    name: "Prairie du Chien",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.05165000",
    longitude: "-91.14124000",
  },
  {
    id: 124634,
    name: "Prairie du Sac",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.28693000",
    longitude: "-89.72401000",
  },
  {
    id: 124644,
    name: "Prescott",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.74886000",
    longitude: "-92.80215000",
  },
  {
    id: 124664,
    name: "Price County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.68038000",
    longitude: "-90.36137000",
  },
  {
    id: 124691,
    name: "Princeton",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.85081000",
    longitude: "-89.12178000",
  },
  {
    id: 124736,
    name: "Pulaski",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.67222000",
    longitude: "-88.24260000",
  },
  {
    id: 124813,
    name: "Racine",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.72613000",
    longitude: "-87.78285000",
  },
  {
    id: 124814,
    name: "Racine County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.72987000",
    longitude: "-87.81235000",
  },
  {
    id: 124869,
    name: "Randolph",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.53915000",
    longitude: "-89.00678000",
  },
  {
    id: 124880,
    name: "Random Lake",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.55222000",
    longitude: "-87.96176000",
  },
  {
    id: 124970,
    name: "Redgranite",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.04192000",
    longitude: "-89.09845000",
  },
  {
    id: 124994,
    name: "Reedsburg",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.53248000",
    longitude: "-90.00263000",
  },
  {
    id: 124996,
    name: "Reedsville",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.15360000",
    longitude: "-87.95676000",
  },
  {
    id: 125046,
    name: "Rhinelander",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.63662000",
    longitude: "-89.41208000",
  },
  {
    id: 125050,
    name: "Rib Mountain",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.91275000",
    longitude: "-89.67540000",
  },
  {
    id: 125055,
    name: "Rice Lake",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.50607000",
    longitude: "-91.73823000",
  },
  {
    id: 125062,
    name: "Richfield",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.25612000",
    longitude: "-88.19398000",
  },
  {
    id: 125073,
    name: "Richland Center",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.33471000",
    longitude: "-90.38679000",
  },
  {
    id: 125077,
    name: "Richland County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.37564000",
    longitude: "-90.42948000",
  },
  {
    id: 125095,
    name: "Richmond",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.71473000",
    longitude: "-88.74927000",
  },
  {
    id: 125146,
    name: "Rio",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.44776000",
    longitude: "-89.23984000",
  },
  {
    id: 125167,
    name: "Ripon",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.84220000",
    longitude: "-88.83594000",
  },
  {
    id: 125178,
    name: "River Falls",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.86136000",
    longitude: "-92.62381000",
  },
  {
    id: 125182,
    name: "River Hills",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.17418000",
    longitude: "-87.92425000",
  },
  {
    id: 125241,
    name: "Roberts",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.98386000",
    longitude: "-92.55603000",
  },
  {
    id: 125269,
    name: "Rochester",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.74141000",
    longitude: "-88.22426000",
  },
  {
    id: 125274,
    name: "Rock County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.67122000",
    longitude: "-89.07158000",
  },
  {
    id: 125379,
    name: "Rome",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.22060000",
    longitude: "-89.80843000",
  },
  {
    id: 125434,
    name: "Rosendale",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.80776000",
    longitude: "-88.67483000",
  },
  {
    id: 125465,
    name: "Rothschild",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.88719000",
    longitude: "-89.62012000",
  },
  {
    id: 125486,
    name: "Roxbury",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.24943000",
    longitude: "-89.67540000",
  },
  {
    id: 125530,
    name: "Rusk County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.47513000",
    longitude: "-91.13312000",
  },
  {
    id: 125556,
    name: "Rutland",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.87889000",
    longitude: "-89.35012000",
  },
  {
    id: 125628,
    name: "Saint Croix County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.03407000",
    longitude: "-92.45278000",
  },
  {
    id: 125629,
    name: "Saint Croix Falls",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.40996000",
    longitude: "-92.63965000",
  },
  {
    id: 125634,
    name: "Saint Francis",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.96752000",
    longitude: "-87.87758000",
  },
  {
    id: 125709,
    name: "Saint Peter",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.83638000",
    longitude: "-88.34149000",
  },
  {
    id: 125738,
    name: "Salem",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.55474000",
    longitude: "-88.11092000",
  },
  {
    id: 125963,
    name: "Sauk City",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.27082000",
    longitude: "-89.72207000",
  },
  {
    id: 125964,
    name: "Sauk County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.42678000",
    longitude: "-89.94837000",
  },
  {
    id: 125967,
    name: "Saukville",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.38167000",
    longitude: "-87.94065000",
  },
  {
    id: 125982,
    name: "Sawyer County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.87997000",
    longitude: "-91.14467000",
  },
  {
    id: 126013,
    name: "Schofield",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.90969000",
    longitude: "-89.60457000",
  },
  {
    id: 126197,
    name: "Seymour",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.51499000",
    longitude: "-88.33038000",
  },
  {
    id: 126230,
    name: "Sharon",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.50252000",
    longitude: "-88.72899000",
  },
  {
    id: 126247,
    name: "Shawano",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.78221000",
    longitude: "-88.60899000",
  },
  {
    id: 126248,
    name: "Shawano County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.78915000",
    longitude: "-88.76544000",
  },
  {
    id: 126255,
    name: "Sheboygan",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.75083000",
    longitude: "-87.71453000",
  },
  {
    id: 126256,
    name: "Sheboygan County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.75622000",
    longitude: "-87.79999000",
  },
  {
    id: 126257,
    name: "Sheboygan Falls",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.73197000",
    longitude: "-87.82213000",
  },
  {
    id: 126290,
    name: "Shell Lake",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.73939000",
    longitude: "-91.92545000",
  },
  {
    id: 126341,
    name: "Sherwood",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.17360000",
    longitude: "-88.25983000",
  },
  {
    id: 126372,
    name: "Shorewood",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.08918000",
    longitude: "-87.88758000",
  },
  {
    id: 126374,
    name: "Shorewood Hills",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.07750000",
    longitude: "-89.44568000",
  },
  {
    id: 126388,
    name: "Shullsburg",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.57334000",
    longitude: "-90.23096000",
  },
  {
    id: 126430,
    name: "Silver Lake",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.05526000",
    longitude: "-89.22623000",
  },
  {
    id: 126463,
    name: "Siren",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.78578000",
    longitude: "-92.38103000",
  },
  {
    id: 126502,
    name: "Slinger",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.33361000",
    longitude: "-88.28621000",
  },
  {
    id: 126576,
    name: "Somers",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.64030000",
    longitude: "-87.91036000",
  },
  {
    id: 126585,
    name: "Somerset",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.12441000",
    longitude: "-92.67354000",
  },
  {
    id: 126693,
    name: "South Milwaukee",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.91057000",
    longitude: "-87.86064000",
  },
  {
    id: 126813,
    name: "Sparta",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.94413000",
    longitude: "-90.81291000",
  },
  {
    id: 126827,
    name: "Spencer",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.75774000",
    longitude: "-90.29680000",
  },
  {
    id: 126845,
    name: "Spooner",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.82245000",
    longitude: "-91.88934000",
  },
  {
    id: 126856,
    name: "Spring Green",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.17527000",
    longitude: "-90.06790000",
  },
  {
    id: 126879,
    name: "Spring Valley",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.84524000",
    longitude: "-92.23880000",
  },
  {
    id: 126960,
    name: "Stanley",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.95997000",
    longitude: "-90.93708000",
  },
  {
    id: 127043,
    name: "Stevens Point",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.52358000",
    longitude: "-89.57456000",
  },
  {
    id: 127110,
    name: "Stoughton",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.91695000",
    longitude: "-89.21789000",
  },
  {
    id: 127126,
    name: "Stratford",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.80108000",
    longitude: "-90.07930000",
  },
  {
    id: 127141,
    name: "Strum",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.54969000",
    longitude: "-91.39266000",
  },
  {
    id: 127149,
    name: "Sturgeon Bay",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.83416000",
    longitude: "-87.37704000",
  },
  {
    id: 127153,
    name: "Sturtevant",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.69807000",
    longitude: "-87.89452000",
  },
  {
    id: 127156,
    name: "Suamico",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.63194000",
    longitude: "-88.03927000",
  },
  {
    id: 127237,
    name: "Sun Prairie",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.18360000",
    longitude: "-89.21373000",
  },
  {
    id: 127279,
    name: "Superior",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "46.72077000",
    longitude: "-92.10408000",
  },
  {
    id: 127302,
    name: "Sussex",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.13390000",
    longitude: "-88.22204000",
  },
  {
    id: 127381,
    name: "Tainter Lake",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.98913000",
    longitude: "-91.84767000",
  },
  {
    id: 127457,
    name: "Taylor County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.21153000",
    longitude: "-90.50125000",
  },
  {
    id: 127569,
    name: "Theresa",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.51722000",
    longitude: "-88.45121000",
  },
  {
    id: 127576,
    name: "Thiensville",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.23751000",
    longitude: "-87.97870000",
  },
  {
    id: 127607,
    name: "Thorp",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.96108000",
    longitude: "-90.79986000",
  },
  {
    id: 127634,
    name: "Tichigan",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.82890000",
    longitude: "-88.19759000",
  },
  {
    id: 127708,
    name: "Tomah",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.97858000",
    longitude: "-90.50402000",
  },
  {
    id: 127709,
    name: "Tomahawk",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.47108000",
    longitude: "-89.72986000",
  },
  {
    id: 127798,
    name: "Trempealeau",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.00552000",
    longitude: "-91.44209000",
  },
  {
    id: 127799,
    name: "Trempealeau County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.30395000",
    longitude: "-91.35846000",
  },
  {
    id: 127908,
    name: "Turtle Lake",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.39440000",
    longitude: "-92.14241000",
  },
  {
    id: 127929,
    name: "Twin Lakes",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.53113000",
    longitude: "-88.24815000",
  },
  {
    id: 127936,
    name: "Two Rivers",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.15388000",
    longitude: "-87.56925000",
  },
  {
    id: 128005,
    name: "Union Grove",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.68807000",
    longitude: "-88.05147000",
  },
  {
    id: 128193,
    name: "Vernon County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.59386000",
    longitude: "-90.83438000",
  },
  {
    id: 128205,
    name: "Verona",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.99083000",
    longitude: "-89.53318000",
  },
  {
    id: 128240,
    name: "Vilas County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "46.05289000",
    longitude: "-89.51477000",
  },
  {
    id: 128292,
    name: "Viroqua",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.55692000",
    longitude: "-90.88874000",
  },
  {
    id: 128380,
    name: "Wales",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.00445000",
    longitude: "-88.37676000",
  },
  {
    id: 128440,
    name: "Walworth",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.53113000",
    longitude: "-88.59955000",
  },
  {
    id: 128442,
    name: "Walworth County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.66848000",
    longitude: "-88.54192000",
  },
  {
    id: 128534,
    name: "Washburn",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "46.67327000",
    longitude: "-90.89491000",
  },
  {
    id: 128536,
    name: "Washburn County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "45.89918000",
    longitude: "-91.79123000",
  },
  {
    id: 128576,
    name: "Washington County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.36847000",
    longitude: "-88.23078000",
  },
  {
    id: 128608,
    name: "Waterford",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.76307000",
    longitude: "-88.21426000",
  },
  {
    id: 128616,
    name: "Waterloo",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.18388000",
    longitude: "-88.98844000",
  },
  {
    id: 128624,
    name: "Watertown",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.19472000",
    longitude: "-88.72899000",
  },
  {
    id: 128649,
    name: "Waukesha",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.01168000",
    longitude: "-88.23148000",
  },
  {
    id: 128650,
    name: "Waukesha County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.01819000",
    longitude: "-88.30453000",
  },
  {
    id: 128654,
    name: "Waunakee",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.19194000",
    longitude: "-89.45567000",
  },
  {
    id: 128655,
    name: "Waupaca",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.35803000",
    longitude: "-89.08595000",
  },
  {
    id: 128656,
    name: "Waupaca County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.47043000",
    longitude: "-88.96482000",
  },
  {
    id: 128657,
    name: "Waupun",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.63332000",
    longitude: "-88.72955000",
  },
  {
    id: 128660,
    name: "Wausau",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.95914000",
    longitude: "-89.63012000",
  },
  {
    id: 128661,
    name: "Waushara County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.11312000",
    longitude: "-89.24288000",
  },
  {
    id: 128662,
    name: "Wautoma",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.07470000",
    longitude: "-89.28790000",
  },
  {
    id: 128663,
    name: "Wauwatosa",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.04946000",
    longitude: "-88.00759000",
  },
  {
    id: 128817,
    name: "West Allis",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.01668000",
    longitude: "-88.00703000",
  },
  {
    id: 128820,
    name: "West Baraboo",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.47443000",
    longitude: "-89.77040000",
  },
  {
    id: 128825,
    name: "West Bend",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.42528000",
    longitude: "-88.18343000",
  },
  {
    id: 128933,
    name: "West Milwaukee",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.01251000",
    longitude: "-87.97259000",
  },
  {
    id: 128973,
    name: "West Salem",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.89913000",
    longitude: "-91.08125000",
  },
  {
    id: 129016,
    name: "Westby",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.65692000",
    longitude: "-90.85430000",
  },
  {
    id: 129032,
    name: "Westfield",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.88359000",
    longitude: "-89.49346000",
  },
  {
    id: 129063,
    name: "Weston",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.89080000",
    longitude: "-89.54762000",
  },
  {
    id: 129098,
    name: "Weyauwega",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.32137000",
    longitude: "-88.93372000",
  },
  {
    id: 129167,
    name: "Whitefish Bay",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.11334000",
    longitude: "-87.90009000",
  },
  {
    id: 129171,
    name: "Whitehall",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.36746000",
    longitude: "-91.31655000",
  },
  {
    id: 129191,
    name: "Whitewater",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.83362000",
    longitude: "-88.73233000",
  },
  {
    id: 129197,
    name: "Whiting",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.49358000",
    longitude: "-89.55873000",
  },
  {
    id: 129263,
    name: "Williams Bay",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.57807000",
    longitude: "-88.54093000",
  },
  {
    id: 129364,
    name: "Wind Lake",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.82946000",
    longitude: "-88.15870000",
  },
  {
    id: 129365,
    name: "Wind Point",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "42.78446000",
    longitude: "-87.76619000",
  },
  {
    id: 129385,
    name: "Windsor",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.21833000",
    longitude: "-89.34151000",
  },
  {
    id: 129409,
    name: "Winnebago County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.06888000",
    longitude: "-88.64466000",
  },
  {
    id: 129410,
    name: "Winneconne",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.11082000",
    longitude: "-88.71261000",
  },
  {
    id: 129457,
    name: "Wisconsin Dells",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "43.62748000",
    longitude: "-89.77096000",
  },
  {
    id: 129458,
    name: "Wisconsin Rapids",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.38358000",
    longitude: "-89.81735000",
  },
  {
    id: 129464,
    name: "Wittenberg",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.82720000",
    longitude: "-89.16956000",
  },
  {
    id: 129486,
    name: "Wood County",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.45532000",
    longitude: "-90.04158000",
  },
  {
    id: 129573,
    name: "Woodville",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.95302000",
    longitude: "-92.29130000",
  },
  {
    id: 129612,
    name: "Wrightstown",
    state_id: 1441,
    state_code: "WI",
    country_id: 233,
    country_code: "US",
    latitude: "44.32582000",
    longitude: "-88.16288000",
  },
  {
    id: 111054,
    name: "Afton",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "42.72493000",
    longitude: "-110.93187000",
  },
  {
    id: 111113,
    name: "Albany County",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "41.65447000",
    longitude: "-105.72391000",
  },
  {
    id: 111366,
    name: "Antelope Valley-Crestview",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "44.22488000",
    longitude: "-105.47409000",
  },
  {
    id: 111414,
    name: "Arapahoe",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "42.96218000",
    longitude: "-108.48983000",
  },
  {
    id: 111807,
    name: "Bar Nunn",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "42.91358000",
    longitude: "-106.34336000",
  },
  {
    id: 111878,
    name: "Basin",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "44.37996000",
    longitude: "-108.03899000",
  },
  {
    id: 112322,
    name: "Big Horn County",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "44.52682000",
    longitude: "-107.99521000",
  },
  {
    id: 113016,
    name: "Buffalo",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "44.34831000",
    longitude: "-106.69894000",
  },
  {
    id: 113303,
    name: "Campbell County",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "44.24839000",
    longitude: "-105.54831000",
  },
  {
    id: 113385,
    name: "Carbon County",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "41.69459000",
    longitude: "-106.93061000",
  },
  {
    id: 113524,
    name: "Casper",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "42.86663000",
    longitude: "-106.31308000",
  },
  {
    id: 113926,
    name: "Cheyenne",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "41.13998000",
    longitude: "-104.82025000",
  },
  {
    id: 114364,
    name: "Cody",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "44.52634000",
    longitude: "-109.05653000",
  },
  {
    id: 114580,
    name: "Converse County",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "42.97233000",
    longitude: "-105.50706000",
  },
  {
    id: 114837,
    name: "Crook County",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "44.58860000",
    longitude: "-104.56994000",
  },
  {
    id: 115424,
    name: "Douglas",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "42.75969000",
    longitude: "-105.38221000",
  },
  {
    id: 116214,
    name: "Ethete",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "43.02496000",
    longitude: "-108.77262000",
  },
  {
    id: 116252,
    name: "Evanston",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "41.26828000",
    longitude: "-110.96324000",
  },
  {
    id: 116255,
    name: "Evansville",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "42.85997000",
    longitude: "-106.26836000",
  },
  {
    id: 116760,
    name: "Fort Washakie",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "43.00635000",
    longitude: "-108.88235000",
  },
  {
    id: 116801,
    name: "Fox Farm-College",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "41.11203000",
    longitude: "-104.78546000",
  },
  {
    id: 116928,
    name: "Fremont County",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "43.04053000",
    longitude: "-108.63042000",
  },
  {
    id: 117217,
    name: "Gillette",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "44.29109000",
    longitude: "-105.50222000",
  },
  {
    id: 117303,
    name: "Glenrock",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "42.86136000",
    longitude: "-105.87223000",
  },
  {
    id: 117411,
    name: "Goshen County",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "42.08794000",
    longitude: "-104.35326000",
  },
  {
    id: 117606,
    name: "Green River",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "41.52858000",
    longitude: "-109.46625000",
  },
  {
    id: 117728,
    name: "Greybull",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "44.48912000",
    longitude: "-108.05621000",
  },
  {
    id: 117781,
    name: "Guernsey",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "42.26969000",
    longitude: "-104.74163000",
  },
  {
    id: 118495,
    name: "Hoback",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "43.28187000",
    longitude: "-110.78381000",
  },
  {
    id: 118684,
    name: "Hot Springs County",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "43.71893000",
    longitude: "-108.44210000",
  },
  {
    id: 119074,
    name: "Jackson",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "43.47993000",
    longitude: "-110.76243000",
  },
  {
    id: 119271,
    name: "Johnson County",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "44.03877000",
    longitude: "-106.58463000",
  },
  {
    id: 119434,
    name: "Kemmerer",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "41.79245000",
    longitude: "-110.53767000",
  },
  {
    id: 120096,
    name: "Lander",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "42.83301000",
    longitude: "-108.73067000",
  },
  {
    id: 120138,
    name: "Laramie",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "41.31137000",
    longitude: "-105.59110000",
  },
  {
    id: 120139,
    name: "Laramie County",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "41.30707000",
    longitude: "-104.68962000",
  },
  {
    id: 120538,
    name: "Lincoln County",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "42.26404000",
    longitude: "-110.65597000",
  },
  {
    id: 120824,
    name: "Lovell",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "44.83745000",
    longitude: "-108.38956000",
  },
  {
    id: 120892,
    name: "Lusk",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "42.76247000",
    longitude: "-104.45217000",
  },
  {
    id: 120910,
    name: "Lyman",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "41.32745000",
    longitude: "-110.29293000",
  },
  {
    id: 121190,
    name: "Marbleton",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "42.55355000",
    longitude: "-110.10932000",
  },
  {
    id: 121896,
    name: "Mills",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "42.84052000",
    longitude: "-106.36586000",
  },
  {
    id: 122199,
    name: "Moorcroft",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "44.26331000",
    longitude: "-104.95025000",
  },
  {
    id: 122215,
    name: "Moose Wilson Road",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "43.52521000",
    longitude: "-110.84466000",
  },
  {
    id: 122433,
    name: "Mountain View",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "41.26884000",
    longitude: "-110.33988000",
  },
  {
    id: 122594,
    name: "Natrona County",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "42.96224000",
    longitude: "-106.79849000",
  },
  {
    id: 122822,
    name: "Newcastle",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "43.85470000",
    longitude: "-104.20494000",
  },
  {
    id: 122904,
    name: "Niobrara County",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "43.05650000",
    longitude: "-104.47538000",
  },
  {
    id: 123097,
    name: "North Rock Springs",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "41.64358000",
    longitude: "-109.26568000",
  },
  {
    id: 123843,
    name: "Park County",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "44.52057000",
    longitude: "-109.58853000",
  },
  {
    id: 124215,
    name: "Pine Bluffs",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "41.18193000",
    longitude: "-104.06912000",
  },
  {
    id: 124249,
    name: "Pinedale",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "42.86661000",
    longitude: "-109.86099000",
  },
  {
    id: 124355,
    name: "Platte County",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "42.13305000",
    longitude: "-104.96582000",
  },
  {
    id: 124611,
    name: "Powell",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "44.75384000",
    longitude: "-108.75735000",
  },
  {
    id: 124820,
    name: "Rafter J Ranch",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "43.42604000",
    longitude: "-110.79909000",
  },
  {
    id: 124846,
    name: "Ranchettes",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "41.21859000",
    longitude: "-104.79025000",
  },
  {
    id: 124912,
    name: "Rawlins",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "41.79107000",
    longitude: "-107.23866000",
  },
  {
    id: 125214,
    name: "Riverton",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "43.02496000",
    longitude: "-108.38010000",
  },
  {
    id: 125287,
    name: "Rock Springs",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "41.58746000",
    longitude: "-109.20290000",
  },
  {
    id: 125942,
    name: "Saratoga",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "41.45496000",
    longitude: "-106.80643000",
  },
  {
    id: 126323,
    name: "Sheridan",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "44.79719000",
    longitude: "-106.95618000",
  },
  {
    id: 126328,
    name: "Sheridan County",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "44.79004000",
    longitude: "-106.87948000",
  },
  {
    id: 126500,
    name: "Sleepy Hollow",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "44.24109000",
    longitude: "-105.42222000",
  },
  {
    id: 126662,
    name: "South Greeley",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "41.09693000",
    longitude: "-104.80636000",
  },
  {
    id: 126703,
    name: "South Park",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "43.42215000",
    longitude: "-110.79326000",
  },
  {
    id: 126982,
    name: "Star Valley Ranch",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "42.97139000",
    longitude: "-110.95556000",
  },
  {
    id: 127158,
    name: "Sublette County",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "42.76691000",
    longitude: "-109.91471000",
  },
  {
    id: 127248,
    name: "Sundance",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "44.40637000",
    longitude: "-104.37578000",
  },
  {
    id: 127343,
    name: "Sweetwater County",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "41.65950000",
    longitude: "-108.87942000",
  },
  {
    id: 127539,
    name: "Teton County",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "43.93476000",
    longitude: "-110.58974000",
  },
  {
    id: 127572,
    name: "Thermopolis",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "43.64607000",
    longitude: "-108.21204000",
  },
  {
    id: 127745,
    name: "Torrington",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "42.06246000",
    longitude: "-104.18439000",
  },
  {
    id: 127955,
    name: "Uinta County",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "41.28756000",
    longitude: "-110.54759000",
  },
  {
    id: 128056,
    name: "Upton",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "44.09970000",
    longitude: "-104.62802000",
  },
  {
    id: 128532,
    name: "Washakie County",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "43.90500000",
    longitude: "-107.68286000",
  },
  {
    id: 129064,
    name: "Weston County",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "43.84049000",
    longitude: "-104.56783000",
  },
  {
    id: 129109,
    name: "Wheatland",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "42.05407000",
    longitude: "-104.95295000",
  },
  {
    id: 129333,
    name: "Wilson",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "43.50076000",
    longitude: "-110.87521000",
  },
  {
    id: 129589,
    name: "Worland",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "44.01690000",
    longitude: "-107.95537000",
  },
  {
    id: 129606,
    name: "Wright",
    state_id: 1442,
    state_code: "WY",
    country_id: 233,
    country_code: "US",
    latitude: "43.75124000",
    longitude: "-105.49201000",
  },
];
