import React from "react";

import { Card } from "react-bootstrap";
import FalconCardHeader from "../../../../../components/common/FalconCardHeader";

// import Image from "./ImageUser";
// import coverSrc from "assets/img/logos/unsplash.png";
import { Avatar } from "@mui/material";
import { startCase } from "lodash";

const ProfileUserTable = ({ currentUser }) => {
  const getUserShortName = () => {
    if (typeof currentUser === "object") {
      if (
        currentUser.firstname !== undefined &&
        currentUser.lastname !== undefined
      ) {
        return (
          startCase(currentUser?.firstname[0]) +
          startCase(currentUser?.lastname[0])
        );
      }
      if (
        currentUser.firstname !== undefined &&
        currentUser.lastname === undefined
      ) {
        return startCase(currentUser?.firstname[0]);
      }
    }
  };

  return (
    <Card className="mb-3" style={{ minHeight: "300px" }}>
      <FalconCardHeader title="Profile Image" />
      <Card.Body className="bg-light text-center d-flex flex-column justify-content-center align-items-center">
        {/* <Image
          src={currentUser.profile}
          firstname={currentUser.firstname || "First Name"}
          lastname={currentUser.lastname || "Last Name"}
          bgColor={currentUser.bgColor || "black"}
        /> */}
        <div className=" d-flex justify-content-center">
          {/* <img
            // src="https://mdbcdn.b-cdn.net/img/new/avatars/8.webp"
            // src={coverSrc}
            // src={
            //   propertyProfileImage.logo_url ||
            //   propertyProfileImage.img ||
            //   coverSrc
            // }
            src={currentUser.profile || coverSrc}
            firstname={currentUser.firstname || "First Name"}
            lastname={currentUser.lastname || "Last Name"}
            bgColor={currentUser.bgColor || "black"}
            className="rounded-circle mb-3 shadow-sm"
            style={{
              width: "150px",
              height: "145px",
              border: "5px solid #fff",
              // maxHeight:'100%',
              // maxWidth:'100%',
            }}
            alt="Avatar"
          /> */}
          <Avatar
            sx={{
              bgcolor: currentUser?.bgColor,
              fontSize: "2rem",
              width: "100px",
              height: "100px",
            }}
            className="img-thumbnail"
          >
            {getUserShortName()}
          </Avatar>
        </div>
        <br />
        <h5 className="mb-3 fw-bold">
          {currentUser.firstname + " " + currentUser.lastname}{" "}
        </h5>
      </Card.Body>
    </Card>
  );
};

export default ProfileUserTable;
