import React from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import FalconCardHeader from "../../../../components/common/FalconCardHeader";
// import Cookies from 'universal-cookie';
// import { toast } from 'react-toastify';
// import axios from 'axios';
// import Cookies from "universal-cookie";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Loader from "../../../../components/common/Loader";
import axios from "axios";
import { toast } from "react-toastify";

const ProfileSettings = ({
  userProfile,
  updateUserProfile,
  userData,
  jwtToken,
  hostname,
}) => {
  const [initiateVerification, setInitateVerification] = React.useState(false);
  const [newEmail, setNewEmail] = React.useState("");
  const [emailSent, setEmailSent] = React.useState(false);

  const [loader, setLoader] = React.useState(false);

  React.useEffect(() => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 400);
  }, []);

  const handleUpdate = (data) => {
    console.log(data);
    if (data.email !== userProfile.email) {
      setNewEmail(data.email);
      console.log("email differs");
      if (!emailSent) {
        verifyNewEmail(data);
      } else {
        console.log(data);
        data.email = userProfile.email;
        data.email2 = newEmail;
        // setTimeout(() => {
        updateUserProfile(data);
        setInitateVerification(false);
        setNewEmail("");
        setEmailSent(false);
        // }, 200);
      }
    } else {
      console.log("email no hanged");
      data.email2 = data.email;
      updateUserProfile(data);
    }
  };

  const verifyNewEmail = (user) => {
    // const cookies = new Cookies();
    const user_id = userData.data;
    let data = { email: user.email, firstName: userProfile.firstname, user_id };
    console.log(data);
    console.log(userProfile);
    axios
      .post(
        `${hostname}/api/auth/emailcheck`,
        { data },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setInitateVerification(true);
        setEmailSent(true);
        toast.success("Email Sent.", { theme: "colored" });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.error || "Something went wrong!", {
          theme: "colored",
        });
      });
  };

  // const verifyOtp = (user) => {
  //   let data = { email: user.email, firstName: userProfile.firstname };
  //   console.log(data);

  //   axios
  //     .post("${hostname}/api/auth/emailverify", { data })
  //     .then((res) => {
  //       console.log(res.data);
  //       setInitateVerification(true);
  //       setEmailSent(true);
  //       toast.success("Email Sent.", { theme: "colored" });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       toast.success("Something went wrong!", { theme: "colored" });
  //     });
  // };

  const cancelVerifyEmail = () => {
    setInitateVerification(false);
    setNewEmail("");
    setEmailSent(false);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  };

  try {
    console.log(userProfile);
    if (loader) return <Loader />;
    return (
      <Card className="mb-3">
        <FalconCardHeader title="Profile Settings" />
        <Card.Body className="bg-light">
          <Formik
            initialValues={{
              firstname: userProfile.firstname || "",
              lastname: userProfile.lastname || "",
              email: userProfile.email || "",
              otp: "",
              phone: userProfile.phone || "",
            }}
            validate={(values) => {
              const errors = {};
              if (initiateVerification) {
                if (!values.otp) errors.otp = "verification code is required!";
              }
              return errors;
            }}
            validationSchema={Yup.object({
              firstname: Yup.string().required("first name is required!"),
              lastname: Yup.string().required("last name is required!"),
              email: Yup.string().required("email is required!"),
              // phone: Yup.string().required("phone is required!"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              handleUpdate(values);
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <FormikForm>
                <Row className="mb-3 g-3">
                  <Form.Group as={Col} lg={6}>
                    <Form.Label>First Name</Form.Label>
                    <Field
                      type="text"
                      placeholder="First Name"
                      className="form-control"
                      name="firstname"
                    />
                    <ErrorMessage
                      name="firstname"
                      className="text-danger fs--1"
                      component="div"
                    />
                  </Form.Group>

                  <Form.Group as={Col} lg={6}>
                    <Form.Label>Last Name</Form.Label>
                    <Field
                      type="text"
                      placeholder="Last Name"
                      className="form-control"
                      name="lastname"
                    />
                    <ErrorMessage
                      name="lastname"
                      className="text-danger fs--1"
                      component="div"
                    />
                  </Form.Group>
                </Row>
                <Row className="g-3 mb-3">
                  <Form.Group as={Col} lg={6}>
                    <Form.Label>Email</Form.Label>
                    <Field
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      // value={formData.email2}
                      name="email"
                      // onChange={handleEmailChange}
                    />
                    <ErrorMessage
                      name="email"
                      className="text-danger fs--1"
                      component="div"
                    />
                  </Form.Group>

                  <Form.Group as={Col} lg={6}>
                    <Form.Label>Phone</Form.Label>
                    <Field
                      type="text"
                      placeholder="Phone"
                      name="phone"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="phone"
                      className="text-danger fs--1"
                      component="div"
                    />
                  </Form.Group>
                </Row>

                <div className="text-end pt-2">
                  {initiateVerification && (
                    <>
                      <div className="mb-3">
                        <p className="text-start text-info">
                          verification email sent to
                          <span className="text-dark fw-bold ms-2">
                            {newEmail}
                          </span>
                        </p>
                        <Field
                          name="otp"
                          className="form-control"
                          placeholder={`enter otp sent to ${newEmail}`}
                        />
                        <div className="text-start">
                          <ErrorMessage
                            name="otp"
                            className="text-danger fs--1"
                            component="div"
                          />
                        </div>
                      </div>
                      <Button
                        variant="danger"
                        type="button"
                        onClick={cancelVerifyEmail}
                        className="me-3"
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Update
                  </Button>
                </div>
              </FormikForm>
            )}
          </Formik>
          {/* <Form onSubmit={handleSubmit}>
            <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={6} controlId="firstname">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  value={formData.firstname}
                  name="firstname"
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} lg={6} controlId="lastname">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  value={formData.lastname}
                  name="lastname"
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>
            <Row className="g-3">
              <Form.Group as={Col} lg={6} controlId="email2">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  value={formData.email2}
                  name="email2"
                  onChange={handleEmailChange}
                />
              </Form.Group>

              <Form.Group as={Col} lg={6} controlId="phone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Phone"
                  value={formData.phone}
                  name="phone"
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>

            {showOtpField && (
              <Form.Group as={Col} lg={6} controlId="otp">
                <Form.Label>OTP</Form.Label>
                <Form.Control type="text" placeholder="OTP" />
              </Form.Group>
            )}
            <Form.Group className="mt-4 d-flex justify-content-between align-items-center">
              {showButton && (
                <h6 className="text-info">please verify your new email!</h6>
              )}
              <div className="text-end">
                {showButton && (
                  <Button variant="info" className="" onClick={handleSendClick}>
                    Send OTP
                  </Button>
                )}
                <div className="text-end">
                  <Button
                    variant="primary"
                    type="submit"
                    // className="float-end"
                    // disabled={showButton}
                    // style={{ cursor: showButton ? 'not-allowed' : 'pointer' }}
                  >
                    Update
                  </Button>
                </div>
              </div>
            </Form.Group>
          </Form> */}
        </Card.Body>
      </Card>
    );
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
};

export default ProfileSettings;
