import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Avatar from 'components/common/Avatar';
// import Image from './Image';
// import SoftBadge from "components/common/SoftBadge";
import { Table, Dropdown } from "react-bootstrap";
// import { Dropdown } from 'react-bootstrap';
// import { Table } from "react-bootstrap";
// import { Link } from "react-router-dom";
import CardDropdown from "../../../../../../../components/common/CardDropdown";
import { formatLogInfo } from "../../../../../../../helpers/utils";
import SoftBadge from "../../../../../../../components/common/SoftBadge";

const TableRow = ({
  data,
  propLength,
  handleShowInvoiceModelFunction,
  handleReceivePayment,
  userData,
  // getAllInvoicesData,
  handleStripePayment,
}) => {
  // const TableRow = ({ data, selectBuilding, propLength }) => {
  // console.log(data.status.type);

  return (
    <tr
      className="align-middle"
      style={{ height: propLength === 1 ? "100px" : "" }}
    >
      <td
        className="text-center text-primary cursor-pointer"
        onClick={() => handleShowInvoiceModelFunction(data)}
      >
        {data?.booking_id?._id?.slice(0, 8) || "id"}
      </td>
      <td className="text-center">{data.invoice_type || "type"}</td>

      <td className="text-center">{`$${data.amount}.00 ` || "amount"}</td>

      <td className="text-center">
        {formatLogInfo(data.issue_date) || "issue_date"}
      </td>
      <td className="text-center">
        {formatLogInfo(data.due_date, true) || "due_date"}
      </td>

      <td className="text-center">
        {data.currency?.toUpperCase() || "currency"}
      </td>

      <td className="text-center">
        {/* <SoftBadge pill bg={data.status.type}>
          {data.status.title}
          <FontAwesomeIcon icon={data.status.icon} className="ms-2" />
        </SoftBadge> */}
        <SoftBadge bg={data.status.type}>
          {data.status.title}
          <FontAwesomeIcon icon={data.status.icon} className="ms-2" />
        </SoftBadge>
      </td>
      <td className="text-center">
        <CardDropdown>
          <div className="py-2">
            <Dropdown.Item onClick={() => handleShowInvoiceModelFunction(data)}>
              Print
            </Dropdown.Item>
            {(userData.role === "unit_owner" || userData.role === "supp_resident" || userData.role === "supp_tenant") &&
              data?.status?.title === "Unpaid" && (
                <Dropdown.Item
                  className="text-success"
                  onClick={() => handleStripePayment(data)}
                >
                  Pay Now
                </Dropdown.Item>
              )}
            {(userData.role === "Site-Administrators" ||
              userData.role === "Condozak-Basic-Users") &&
              data?.status?.title === "Unpaid" && (
                <Dropdown.Item
                  className="text-success"
                  onClick={() => handleReceivePayment(data)}
                >
                  Receive Payment
                </Dropdown.Item>
              )}
          </div>
        </CardDropdown>
        {data.sent}
      </td>
    </tr>
  );

  // return (
  //   <tr
  //     className="align-middle"
  //     style={{ height: propLength === 1 ? "100px" : "" }}
  //   >
  //     <td className="text-center">
  //       {data?.amenity_booking_id?.amenity_id?._id?.slice(0, 8) ||
  //         data?.event_booking_id?.slice(0, 8) ||
  //         "id"}
  //     </td>
  //     <td className="text-center">{data.invoice_type || "type"}</td>

  //     <td className="text-center">{`$${data.amount}.00 ` || "amount"}</td>

  //     <td className="text-center">
  //       {formatLogInfo(data.issue_date) || "issue_date"}
  //     </td>
  //     <td className="text-center">
  //       {formatLogInfo(data.due_date, true) || "due_date"}
  //     </td>

  //     <td className="text-center">
  //       {data.currency?.toUpperCase() || "currency"}
  //     </td>

  //     <td className="text-center">
  //       {/* <SoftBadge pill bg={data.status.type}>
  //         {data.status.title}
  //         <FontAwesomeIcon icon={data.status.icon} className="ms-2" />
  //       </SoftBadge> */}
  //       <SoftBadge bg={data.status.type}>
  //         {data.status.title}
  //         <FontAwesomeIcon icon={data.status.icon} className="ms-2" />
  //       </SoftBadge>
  //     </td>
  //     <td className="text-center">
  //       <CardDropdown>
  //         <div className="py-2">
  //           <Dropdown.Item onClick={() => handleShowInvoiceModelFunction(data)}>
  //             Print
  //           </Dropdown.Item>
  //           {userData.role === "unit_owner" || userData.role === "supp_resident" || userData.role === "supp_tenant" &&
  //             data?.status?.title === "Unpaid" && (
  //               <Dropdown.Item
  //                 className="text-success"
  //                 onClick={handleShowModal}
  //               >
  //                 Pay Now 1
  //               </Dropdown.Item>
  //             )}
  //           {userData.role === "Site-Administrators" &&
  //             data?.status?.title === "Unpaid" && (
  //               <Dropdown.Item
  //                 className="text-success"
  //                 onClick={() => handleReceivePayment(data)}
  //               >
  //                 Receive Payment
  //               </Dropdown.Item>
  //             )}
  //         </div>
  //       </CardDropdown>
  //       {data.sent}
  //     </td>
  //     <Modal show={showModal} onHide={handleCloseModal}>
  //       <Modal.Header closeButton>
  //         <Modal.Title>Payment Modal </Modal.Title>
  //       </Modal.Header>
  //       <Modal.Body>
  //         <Elements stripe={stripePromise}>
  //           <Paymenttest
  //             clientSecret={clientSecret}
  //             handleCloseModal={handleCloseModal}
  //             invoiceId={data._id} // Pass the function to close the modal
  //             getAllInvoicesData={getAllInvoicesData}
  //           />
  //         </Elements>
  //       </Modal.Body>
  //       <Modal.Footer>
  //         <Button onClick={handleCloseModal}>Close</Button>
  //       </Modal.Footer>
  //     </Modal>
  //   </tr>
  // );
};

const MailOutsTable = ({
  properties,
  handleShowInvoiceModelFunction,
  handleReceivePayment,
  userData,
  getAllInvoicesData,
  handleStripePayment,
}) => {
  // console.log(properties);

  return (
    <Table
      responsive
      striped
      hover
      bordered
      style={{ fontSize: "14px" }}
      className="pb-5"
    >
      <thead>
        <tr>
          <th scope="col" className="text-center">
            Reference ID
          </th>
          <th scope="col" className="text-center">
            Invoice Type
          </th>
          <th scope="col" className="text-center">
            Amount
          </th>
          <th scope="col" className="text-center">
            Invoice Date
          </th>
          <th scope="col" className="text-center">
            Due Date
          </th>
          <th scope="col" className="text-center">
            Currency
          </th>
          <th scope="col" className="text-center">
            Status
          </th>
          <th scope="col" className="text-center">
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        {properties?.map((property, index) => (
          <TableRow
            data={property}
            key={index}
            propLength={properties.length}
            handleShowInvoiceModelFunction={handleShowInvoiceModelFunction}
            handleReceivePayment={handleReceivePayment}
            userData={userData}
            getAllInvoicesData={getAllInvoicesData}
            handleStripePayment={handleStripePayment}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default MailOutsTable;
