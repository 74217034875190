import React from "react";
import { Modal, Table, Form as BSForm, Button } from "react-bootstrap";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { PostAdd, Image, Clear } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";

import { Editor } from "@tinymce/tinymce-react";
import { detectForbiddenWords, getFileExtData } from "../../../../../helpers/utils";
import axios from "axios";
import Loader from "../../../../../components/common/Loader";
import { toArray } from "lodash";
import { toast } from "react-toastify";

export default function BulletinBoardModal({
  show,
  handleClose,
  payload,
  getAllData,
  userData,
  property_id,
  hostname,
  jwtToken,
  //scrollPosition=0,
}) {
  //console.log(scrollPosition);

  const editorRef = React.useRef(null);

  const [editorLoader, setEditorLoader] = React.useState(true);
  const [errorMessageReview, setErrorMessageReview] = React.useState(true);

  const handleFiles = (e, setFieldValue, values) => {
    const validTypes = [
      "txt",
      "doc",
      "docx",
      "pdf",
      "csv",
      "xls",
      "xlsx",
      "ppt",
      "pptx",
      "jpeg",
      "jpg",
      "png",
      "zip",
      "rar",
      "rtf",
    ];

    // console.log(e.target.files);
    let data = new Set([...e.target.files]);
    data = toArray(data);
    // console.log(data);
    data = data?.map((file) => {
      let entry = {};
      entry.tmp_id = uuidv4();
      entry.mimeType = file.type;
      entry.type = file.type?.split("/")[1];
      entry.file = file;
      const type = file.name.substr(file.name.lastIndexOf(".") + 1);
      const size = file.size / 1024 / 1024;

      if (!validTypes.includes(type)) {
        entry.type_error = `invalid file ${type} !`;
      } else {
        entry.type_error = "";
      }
      if (validTypes.includes(type) && size > 2) {
        entry.size_error = `max file limit is 2 MB !`;
      } else {
        entry.size_error = "";
      }

      // RootDriveFilesList?.forEach((v) => {
      //   if (v.file_name === file.name) {
      //     let nameIndex = file.name.lastIndexOf(".");
      //     let name = file.name.substr(0, nameIndex);
      //     let ext = file.name.substr(nameIndex);
      //     let count = file.name.indexOf(")." + ext);
      //     count--;
      //     if (count < 0) count = 1;
      //     else {
      //       count = parseInt(file.name[count]);
      //       count++;
      //     }
      //     // can't rename entry.file.name as it is constant
      //     // adding a new key of name
      //     // but backend will receive files with const attributes.
      //     let new_name = `${name}(${count})${ext}`;
      //     entry.file = new File([file], new_name, {
      //       type: file.type,
      //       lastModified: file.lastModified,
      //     });
      //     entry.dublicate_error = `file already exists! suffix updated`;
      //   } else entry.dublicate_error = "";
      // });

      return entry;
    });
    // console.log(data);

    setFieldValue("drive_files", values.drive_files?.concat(data));

    e.target.value = null;
  };

  const handleRemoveFile = (e, setFieldValue, values, id) => {
    let data = [];
    data = values.drive_files?.filter((v) => v.tmp_id !== id);

    setFieldValue("drive_files", data);
  };

  const getFileValidation = (values) => {
    let i = 0;

    values?.drive_files?.forEach((v) => {
      if (
        v.type_error !== "" ||
        v.size_error !== ""
        // v.dublicate_error !== ""
      )
        i++;
    });
    // console.log(i);
    return i;
  };

  const creatPostDB = (values = {}) => {
    console.log(values);

    let stillInfected = false;

    stillInfected = detectForbiddenWords(values.postContent);

    if (stillInfected) {
      setErrorMessageReview(
        "Error: Offensive words detected. Please review your input."
      );
      toast.error("Please review your input!", { theme: "colored" });

      return;
    } else {
      setErrorMessageReview("");
    }

    if (values?.drive_files?.length > 5) {
      toast.error("Maximum 5 images allowed!", { theme: "colored" });
      return;
    }

    let filesObj = {};
    filesObj.post_images = values.drive_files;
    filesObj.created_by_id = userData.data;
    filesObj.p_id = property_id;
    filesObj.post_text = values.postContent;

    console.log(filesObj);

    console.log("ready for db");

    // return;

    axios
      .post(
        `${hostname}/api/property/createpost`,
        {
          ...filesObj,
        },
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        getAllData("post_create", "", res.data.Data);
        toast.success("Post created", { theme: "colored" });
        handleClose();
      })
      .catch((err) => {
        console.log(err);

        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };

  const handlePostContentChange = (text, setFieldValue) => {
    setFieldValue("postContent", text);
  };

  const deletePost = () => {
    let postData = {
      post_id: payload._id,
      user_id: userData.data,
    };

    console.log(postData);

    axios
      .post(
        `${hostname}/api/property/deletepost`,
        {
          ...postData,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.warning("Post deleted.", { theme: "colored" });
        handleClose();
        getAllData("post_delete", payload.post_id, res.data.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // React.useEffect(() => {
  //   editorRef.current.focus();
  // }, []);

  const addComment = (values) => {
    let commentData = {
      post_id: payload.post_id,
      text: values.comment_text,
      user_id: userData.data,
    };

    let isInfected = false;
    isInfected = detectForbiddenWords(values.comment_text);

    if (isInfected) {
      toast.error("Immoral words detected!, review your input!", {
        theme: "colored",
      });
      return;
    }

    if (values?.comment_text?.length > 400) {
      toast.error("Maximum input length exceeded! 400", { theme: "colored" });
      return;
    }

    axios
      .post(
        `${hostname}/api/property/addpostcomment`,
        {
          ...commentData,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.success("Comment added.", { theme: "colored" });
        handleClose();
        // let sc = document.documentElement.scrollTop || document.body.scrollTop;
        // console.log(sc);
        // window.scrollTo({ left: 0, top: 0 });
        getAllData("comment_add", payload.post_id, res.data.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateComment = (values) => {
    let commentData = {
      comment_id: payload.comment_id,
      text: values.comment_text,
      user_id: userData.data,
    };

    let isInfected = false;
    isInfected = detectForbiddenWords(values.comment_text);

    if (isInfected) {
      toast.error("Immoral words detected!, review your input!", {
        theme: "colored",
      });
      return;
    }

    if (values?.comment_text?.length > 400) {
      toast.error("Maximum input length exceeded! 400", { theme: "colored" });
      return;
    }

    axios
      .post(
        `${hostname}/api/property/updatepostcomment`,
        {
          ...commentData,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.success("Comment updated.", { theme: "colored" });
        handleClose();
        // window.scrollTo({ left: 0, top: 0 });

        getAllData("comment_update", payload.post_id, res.data.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteComment = () => {
    let commentData = {
      comment_id: payload.comment_id,
      user_id: userData.data,
    };

    axios
      .post(
        `${hostname}/api/property/deletepostcomment`,
        {
          ...commentData,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.warning("Comment deleted.", { theme: "colored" });
        handleClose();
        getAllData("comment_delete", payload.post_id, res.data.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size={
        payload.loadType === "Create Post" || payload.loadType === "Update Post"
          ? "lg"
          : "md"
      }
      centered
      style={{ backdropFilter: "blur(3px)" }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{payload.loadType}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-light">
        {payload.loadType === "Create Post" && (
          <div className="p-3">
            <Formik
              initialValues={{
                drive_files: [],
                postContent: "", // Initial expiry date
              }}
              validationSchema={Yup.object().shape({
                postContent: Yup.string()
                  .required("content cant be empty!")
                  .max(1000, "max character limit is 1000"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                console.log(values);
                setSubmitting(false);
                creatPostDB(values);
              }}
            >
              {({
                isSubmitting,
                values,
                handleChange,
                handleBlur,
                setFieldValue,
              }) => (
                <Form>
                  <div className="border border-0 border-200">
                    {editorLoader && <Loader />}
                    {/* {!editorLoader && ( */}
                    <Editor
                      onInit={(evt, editor) => {
                        editorRef.current = editor;
                        //console.log('ok edotoooooor');
                        setEditorLoader(false);
                      }}
                      value={values.postContent}
                      onEditorChange={(text) =>
                        handlePostContentChange(text, setFieldValue)
                      }
                      // apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                      // initialValue={""}
                      init={{
                        license_key: "gpl",
                        promotion: false,
                        suffix: ".min", // Suffix to use for minified files

                        height: "45vh",
                        menubar: false,
                        content_style: `body { color: 
                          "black"
                         } p {margin: 0; padding: 0;} div {margin: 0; padding: 0;}
                      
                    `,
                        placeholder: "What do you want to talk about?",
                        browser_spellcheck: true,
                        mobile: {
                          theme: "mobile",
                          toolbar: ["undo", "bold"],
                        },
                        statusbar: false,
                        plugins: "link lists table directionality",
                        toolbar:
                          "styleselect | bold italic link bullist numlist blockquote table undo redo",

                        directionality: "ltr",
                      }}
                    />
                    {/* )} */}
                  </div>
                  <p className="text-info pt-3">
                    Note: “Your post will be live for 90 days.”
                  </p>
                  <ErrorMessage
                    name="postContent"
                    className="text-danger fs--1"
                    component={"div"}
                  />
                  <div className="text-danger">{errorMessageReview}</div>
                  <br />
                  {values?.drive_files?.length > 0 && (
                    <Table responsive className="mt-3 mb-5">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {typeof values.drive_files === "object" &&
                          values?.drive_files?.map((file) => (
                            <tr key={file.tmp_id}>
                              <td style={{ minWidth: "400px" }}>
                                <div className="d-flex align-items-center">
                                  <span style={{ width: "25px" }}>
                                    {getFileExtData(
                                      file.file.name.substr(
                                        file.file.name.lastIndexOf(".") + 1
                                      )
                                    )}
                                  </span>
                                  <span className="ms-3"></span>
                                  {file.file.name}
                                </div>
                                <div className="text-danger fs--1">
                                  {file.type_error}
                                </div>
                                <div className="text-danger fs--1">
                                  {file.size_error}
                                </div>
                                <div className="text-danger fs--1">
                                  {file.dublicate_error}
                                </div>
                              </td>

                              <td>
                                <Button
                                  variant="falcon-danger"
                                  size="sm"
                                  onClick={(e) =>
                                    handleRemoveFile(
                                      e,
                                      setFieldValue,
                                      values,
                                      file.tmp_id
                                    )
                                  }
                                >
                                  <Clear />
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  )}

                  <div>
                    <label className="btn btn-falcon-primary">
                      <Image /> Upload Images
                      <input
                        onChange={(e) => handleFiles(e, setFieldValue, values)}
                        multiple={true}
                        onBlur={handleBlur}
                        id="drive_files"
                        name="drive_files"
                        type="file"
                        accept="image/*"
                        hidden
                        className="form-control"
                      />
                    </label>

                    {getFileValidation(values) > 0 && (
                      <span className="float-end text-danger ">
                        please remove unsupported files ...
                      </span>
                    )}
                    {getFileValidation(values) < 1 && (
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        variant="primary"
                        className="float-end"
                      >
                        <PostAdd /> Post
                      </Button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
        {payload.loadType === "Update Post" && (
          <div className="p-3">
            <Formik
              initialValues={{
                drive_files: [],
                postContent: payload.post_text || "", // Initial expiry date
              }}
              validationSchema={Yup.object().shape({
                postContent: Yup.string()
                  .required("content cant be empty!")
                  .max(1000, "max character limit is 1000"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                console.log(values);
                setSubmitting(false);
                creatPostDB(values);
              }}
            >
              {({
                isSubmitting,
                values,
                handleChange,
                handleBlur,
                setFieldValue,
                setFieldError,
              }) => (
                <Form>
                  <div className="border border-0 border-200">
                    {editorLoader && <Loader />}
                    {/* {!editorLoader && ( */}
                    <Editor
                      onInit={(evt, editor) => {
                        editorRef.current = editor;
                        //console.log('ok edotoooooor');
                        setEditorLoader(false);
                      }}
                      value={values.postContent}
                      onEditorChange={(text) =>
                        handlePostContentChange(
                          text,
                          setFieldValue,
                          setFieldError
                        )
                      }
                      // apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                      // initialValue={""}
                      init={{
                        license_key: "gpl",
                        promotion: false,
                        suffix: ".min", // Suffix to use for minified files

                        height: "45vh",
                        menubar: false,
                        content_style: `body { color: 
                          "black"
                         } p {margin: 0; padding: 0;} div {margin: 0; padding: 0;}
                      
                    `,
                        placeholder: "What do you want to talk about?",

                        mobile: {
                          theme: "mobile",
                          toolbar: ["undo", "bold"],
                        },
                        statusbar: false,
                        plugins: "link lists table directionality",
                        toolbar:
                          "styleselect | bold italic link bullist numlist blockquote table undo redo",

                        directionality: "ltr",
                      }}
                    />
                    {/* )} */}
                  </div>
                  <p className="text-info py-3">
                    Note: “Your post will be live for 90 days.”
                  </p>
                  <ErrorMessage
                    name="postContent"
                    className="text-danger fs--1"
                    component={"div"}
                  />
                  <div className="text-warning fs--1">{errorMessageReview}</div>

                  {values?.drive_files?.length > 0 && (
                    <Table responsive className="mt-3 mb-5">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {typeof values.drive_files === "object" &&
                          values?.drive_files?.map((file, index) => (
                            <tr key={file.tmp_id}>
                              <td style={{ minWidth: "400px" }}>
                                <div className="d-flex align-items-center">
                                  <span style={{ width: "25px" }}>
                                    {getFileExtData(
                                      file.file.name.substr(
                                        file.file.name.lastIndexOf(".") + 1
                                      )
                                    )}
                                  </span>
                                  <span className="ms-3"></span>
                                  {file.file.name}
                                </div>
                                <div className="text-danger fs--1">
                                  {file.type_error}
                                </div>
                                <div className="text-danger fs--1">
                                  {file.size_error}
                                </div>
                                <div className="text-warning fs--1">
                                  {file.dublicate_error}
                                </div>
                              </td>

                              <td>
                                <Button
                                  variant="falcon-danger"
                                  size="sm"
                                  onClick={(e) =>
                                    handleRemoveFile(
                                      e,
                                      setFieldValue,
                                      values,
                                      file.tmp_id
                                    )
                                  }
                                >
                                  <Clear />
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  )}

                  <div>
                    <label className="btn btn-falcon-primary">
                      <Image /> Upload Images
                      <input
                        onChange={(e) => handleFiles(e, setFieldValue, values)}
                        multiple={true}
                        onBlur={handleBlur}
                        id="drive_files"
                        name="drive_files"
                        type="file"
                        accept="image/*"
                        hidden
                        className="form-control"
                      />
                    </label>

                    {getFileValidation(values) > 0 && (
                      <span className="float-end text-danger ">
                        please remove unsupported files ...
                      </span>
                    )}
                    {getFileValidation(values) < 1 && (
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        variant="primary"
                        className="float-end"
                      >
                        <PostAdd /> Post
                      </Button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
        {payload.loadType === "Delete Post" && (
          <>
            <p className="" style={{ fontSize: "15px" }}>
              Are you sure you want to delete this post ?
            </p>
            <div className="text-end">
              <Button
                variant="falcon-warning"
                className="me-3 "
                size="md"
                onClick={() => deletePost()}
                style={{ width: 80 }}
              >
                Yes
              </Button>
              <Button
                variant="falcon-default"
                size="md"
                onClick={handleClose}
                style={{ width: 80 }}
              >
                No
              </Button>
            </div>
          </>
        )}
        {payload.loadType === "Add Comment" && (
          <div>
            <Formik
              initialValues={{
                comment_text: "",
              }}
              onSubmit={(values, { setSubmitting }) => {
                addComment(values);
                setSubmitting(false);
              }}
              validationSchema={Yup.object({
                comment_text: Yup.string()
                  .required("text is required!")
                  .max(300, "max character limit is 300"),
              })}
            >
              {({ isSubmitting, values, handleChange, handleBlur }) => (
                <Form>
                  <BSForm.Control
                    autoFocus
                    as={"textarea"}
                    rows={4}
                    value={values.comment_text}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="comment_text"
                    name="comment_text"
                    className="form-control"
                    placeholder=" add comment here..."
                  />
                  <ErrorMessage
                    name="comment_text"
                    component={"div"}
                    className="fs--1 text-danger"
                  />

                  <br />
                  <div className="text-end">
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={isSubmitting}
                    >
                      Done
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
        {payload.loadType === "Update Comment" && (
          <div>
            <Formik
              initialValues={{
                comment_text: payload.oldText || "",
              }}
              onSubmit={(values, { setSubmitting }) => {
                updateComment(values);
                setSubmitting(false);
              }}
              validationSchema={Yup.object({
                comment_text: Yup.string()
                  .required("text is required!")
                  .max(300, "max character limit is 300"),
              })}
            >
              {({ isSubmitting, values, handleChange, handleBlur }) => (
                <Form>
                  <BSForm.Control
                    autoFocus
                    as={"textarea"}
                    rows={4}
                    value={values.comment_text}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="comment_text"
                    name="comment_text"
                    className="form-control"
                    placeholder=" add comment here..."
                  />
                  <ErrorMessage
                    name="comment_text"
                    component={"div"}
                    className="fs--1 text-danger"
                  />

                  <br />
                  <div className="text-end">
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={isSubmitting}
                    >
                      Done
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
        {payload.loadType === "Delete Comment" && (
          <>
            <p className="" style={{ fontSize: "15px" }}>
              Are you sure you want to delete this comment ?
            </p>
            <div className="text-end">
              <Button
                variant="falcon-warning"
                className="me-3 "
                size="md"
                onClick={() => deleteComment()}
                style={{ width: 80 }}
              >
                Yes
              </Button>
              <Button
                variant="falcon-default"
                size="md"
                onClick={handleClose}
                style={{ width: 80 }}
              >
                No
              </Button>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
