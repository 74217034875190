import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Form from 'react-bootstrap/Form';
import { getHostName } from "../../helpers/utils";

const ForgetPasswordForm = () => {
  const { t } = useTranslation();

  let hostname = getHostName();
  // State
  const [email, setEmail] = useState("");
  // const [error, setError] = useState("");
  // const [success, setSuccess] = useState("");

  // Handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };
    try {
      const { data } = await axios.post(
        `${hostname}/api/auth/forgotpassword`,
        {
          email,
          siteName: window.location.hostname,
          port: window.location.port,
        },
        config
      );

      // setSuccess(data.data);
      toast.success(`An email is sent to ${email} with password reset link`, {
        theme: "colored",
      });
    } catch (err) {
      //setError(error.response.data.error);

      toast.error(`User does not exist `, {
        theme: "colored",
      });
      // setEmail("");
      // setTimeout(() => {
      //   setError("");
      // }, 5000);
    }
  };

  return (
    <Form className="mt-4" onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Control
          placeholder={t("forgot_ps.email_address")}
          value={email}
          name="email"
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Button className="w-100" type="submit" disabled={!email}>
          {t("forgot_ps.send_reset_link")}
        </Button>
      </Form.Group>

      <Link
        className="fs--1 text-600"
        to="#!"
        
        onClick={() => window.history.back()}
      >
        {t("forgot_ps.back_to_login")}
        {/* <span className="d-inline-block ms-1"> &rarr;</span> */}
      </Link>
    </Form>
  );
};

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string,
};

// ForgetPasswordForm.defaultProps = { layout: "simple" };

export default ForgetPasswordForm;
