import React, { useEffect, useState } from "react";
// import Address from './Address';
import AddressCompany from "./AddressCompany";
import Profile from "./Profile";
import axios from "axios";
import { useParams } from "react-router-dom";
// import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import { Col, Row } from "react-bootstrap";
import ProfileImageCompany from "./ProfileImageCompany";
import { dataURLtoFile } from "../../../../components/pages/user/dataURLtoFile";
// import seperate from 'assets/img/team/avatar.png';
import Loader from "../../../../components/common/Logo";

import CustomBreadCrumbs from "../../../../components/common/CustomBreadCrumbs";
import CompanyLetterHead from "./CompanyLetterHead";
import CompanyProfileModal from "./CompanyProfileModal";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName } from "../../../../helpers/utils";

export default function CompanyProfile() {
  const { company_id } = useParams();

  const hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const [loader, setLoader] = useState(false);
  const [company, setCompany] = useState({});
  const [companyLetterHead, setCompanyLetterHead] = useState("");
  const [companyProfileImage, setCompanyProfileImage] = React.useState({});
  const [addressCompanyData, setAddressCompanyData] = useState({});

  const showCompanyAddress = () => {
    setLoader(true);

    axios
      .post(
        `${hostname}/api/auth/showaddress`,
        {
          user_id: userData.data,
          company_id: userData.compid,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setAddressCompanyData({ ...res.data.compaddress });
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const updateCompanyAddress = (addressData) => {
    let alldata = {
      ...addressData,
      user_id: userData.data,
      company_id: userData.compid,
    };
    // console.log(alldata);
    setLoader(true);
    axios
      .post(
        `${hostname}/api/auth/Address`,
        {
          ...alldata,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setAddressCompanyData(addressData);
        setLoader(false);

        toast.success(res.data.data, { theme: "colored" });
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);

        toast.error(err.response.data.error, { theme: "colored" });
      });
  };

  useEffect(() => {
    setLoader(true);
    getCompanyData(company_id);
    // showCompanyProfile();
    getCompanyLetterHeadData();
    showCompanyAddress();
  }, []);

  const getCompanyLetterHeadData = () => {
    axios
      .post(
        `${hostname}/api/property/GetLetterHeadCompany`,
        {
          c_id: company_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        //console.log(res.data);
        const blobFromFetch = `data:application/pdf;base64,${res.data.Data}`;

        setCompanyLetterHead(blobFromFetch);
        // setTimeout(() => {

        //   setLoader(false);
        // }, 500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCompanyData = (compid) => {
    // console.log(compid);
    if (compid) {
      axios
        .post(
          `${hostname}/api/auth/companydata`,
          { compid },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          //console.log(res.data);
          // setTimeout(() => {
          // }, 500);
          setCompany(res.data);
          setCompanyProfileImage(res.data);
          //   setLoader(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const updateCompanyData = (company) => {
    setLoader(true);
    //console.log(company);
    let c = {};
    c.compid = company_id;
    c.company_name = company.c_name;
    c.site_name = company.c_site?.toLowerCase();
    c.buisness_type = company.c_type;
    c.user_id = userData.data;
    //console.log(c);
    axios
      .post(
        `${hostname}/api/auth/companyupdate`,
        { ...c },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setTimeout(() => {
          setLoader(false);
        }, 500);
        setCompany(res.data.data);
        let updatedCookie = { ...userData };
        updatedCookie.company_name = c.company_name;
        toast.success("update successfull", { theme: "colored" });
        cookies.set("userData", updatedCookie);
        setTimeout(() => {
          window.location.reload();
        }, 100);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!", { theme: "colored" });
      });
  };

  // const img

  const [showButton, setShowButton] = useState(false);
  const [CancelButton, setCancelButton] = useState(false);

  const handleClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";

    input.onchange = (event) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        setCompanyProfileImage({ img: event.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
      setShowButton(true);
      setCancelButton(true);
    };

    input.click();
  };

  const handleSave = async () => {
    setShowButton(false);
    setCancelButton(false);
    setLoader(true);
    try {
      const data = dataURLtoFile(companyProfileImage.img, "profile-picture");
      const formData = new FormData();
      formData.append("croppedImage", data);
      formData.append("comp_id", userData.compid);
      await axios.post(`${hostname}/api/auth/companylogo`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      setTimeout(() => {
        setLoader(false);
      }, 300);
      toast.success("Logo Updated Successfully", {
        theme: "colored",
      });
    } catch (error) {
      toast.error("Error" + error, {
        theme: "colored",
      });
      console.error(error);
    }
  };

  const handleCancel = async () => {
    setShowButton(false);
    setCancelButton(false);
    setLoader(false);
    setCompanyProfileImage({});
    // showCompanyProfile();
  };

  const handleDelete = () => {
    axios
      .post(
        `${hostname}/api/property/DeleteCompanyLogo`,
        {
          c_id: userData.compid,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then(() => {
        //console.log(res.data);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // modal

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const [payload, setPayload] = React.useState({});
  const [payloadLH, setPayloadLH] = React.useState({});

  // const [letterHead,setLetterHead] = React.useState({});

  // addletterhead
  const addLetterHead = () => {
    let obj = {};
    obj.loadType = "Upload LetterHead";
    setPayloadLH({ ...obj });
    handleShow();
  };
  const viewLetterHead = () => {
    let obj = {};
    obj.loadType = "View/Change LetterHead";
    setPayloadLH({ ...obj });
    handleShow();
  };
  const deleteLetterHead = () => {
    let obj = {};
    obj.loadType = "Delete LetterHead";
    setPayloadLH({ ...obj });
    handleShow();
  };

  // render

  if (loader) {
    return <Loader />;
  } else {
    return (
      <div>
        <Row className="g-3 mb-3">
          <Col>
            <CustomBreadCrumbs
              links={[
                {
                  path: "/dashboard",
                  label: "Home",
                },
                {
                  path: '',
                  label: "Company Profile",
                },
              ]}
            />
          </Col>
        </Row>
        <Row className="">
          {/* <Col lg={7} className="" style={{'paddingRight':'0px'}}> */}
          <Col lg={7} className="pe-lg-0">
            <Profile company={company} updateCompanyData={updateCompanyData} />
          </Col>
          <Col lg={5} className="">
            <ProfileImageCompany
              company={company}
              companyProfileImage={companyProfileImage}
              userData={userData}
              // avatarProp={formData.avatar}
              showButton={showButton}
              CancelButton={CancelButton}
              // uploadedFileName={uploadedFileName}
              handleClick={handleClick}
              handleCancel={handleCancel}
              handleSave={handleSave}
              handleDelete={handleDelete}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={7} className="pe-lg-0">
            <AddressCompany
              addressCompanyData={addressCompanyData}
              updateCompanyAddress={updateCompanyAddress}
            />
          </Col>
          <Col lg={5}>
            <CompanyLetterHead
              companyLetterHead={companyLetterHead}
              addLetterHead={addLetterHead}
              deleteLetterHead={deleteLetterHead}
              viewLetterHead={viewLetterHead}
            />
          </Col>
        </Row>
        <CompanyProfileModal
          show={show}
          handleClose={handleClose}
          payload={payloadLH}
          setPayload={setPayloadLH}
          company_id={company_id}
          getLetterHeadData={getCompanyLetterHeadData}
          companyLetterHead={companyLetterHead}
          setCompanyLetterHead={setCompanyLetterHead}
          hostname={hostname}
          jwtToken={jwtToken}
        />
      </div>
    );
  }
}
