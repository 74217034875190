import { AuthWizardContext } from "../../../../context/Context";
import Lottie from "lottie-react";
import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import celebration from "../../../../assets/img/animated-icons/celebration.json";

const Success = () => {
  const { user } = useContext(AuthWizardContext);
  // const navigate = useNavigate();
  // const emptyData = () => {
  //   setStep(1);
  //   setUser({});
  //   reset();
  // };
  console.log(user);
  // const cookies = new Cookies();

  useEffect(() => {
    // console.log(user.siteName);
    setTimeout(() => {
      handleSubmit();
    }, 1000);
  }, []);

  const handleSubmit = async () => {
    let server = "";
    if (process.env.REACT_APP_SERVER_LIVE === "https://truzakdemo.com")
      server = "truzakdemo.com";
    if (process.env.REACT_APP_SERVER_LIVE === "https://truzakdemo.cloud")
      server = "truzakdemo.cloud";

    let host =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_TruzLocal
        : server;

    let link = "http://" + user.site_name + "." + host + "/login";
    console.log(link);
    
     window.location.href = link;
  };

  return (
    <>
      <Row>
        <Col className="text-center">
          <div className="wizard-lottie-wrapper">
            <div className="wizard-lottie mx-auto">
              <Lottie animationData={celebration} loop={true} />
            </div>
          </div>
          <h4 className="mb-1">Your account is all set! </h4>
          <p className="fs-0">Redirecting you to Login page in 5 sec...</p>
          {/* <Link
            to="/login"
            className="px-5 my-3 btn btn-primary"
            onClick={emptyData}
          >
            Login
          </Link> */}
        </Col>
      </Row>
    </>
  );
};

Success.propTypes = {
  reset: PropTypes.func.isRequired,
};

export default Success;
