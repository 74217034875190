import React from "react";
// import ImageUnit from './ImageUnit';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import SoftBadge from 'components/common/SoftBadge';
import { Table, Dropdown } from "react-bootstrap";
// import { Link } from 'react-router-dom';
import CardDropdown from "../../../../../../../components/common/CardDropdown";
// import Avatar from 'components/common/Avatar';
// import './UserTable.css';
// import { isArray } from 'lodash';
// import BSSliderCustom from 'components/common/BSSliderCustom';
// import GroupMember from 'components/app/kanban/GroupMember';

// import img1 from 'assets/img/products/poolroom.jpg';
// import img2 from 'assets/img/products/partyroom.jpg';
// import img3 from 'assets/img/products/gymroom.jpg';
// import StackedImagesComponent from 'components/common/StackedImagesComponent';
// import libDate from 'date-and-time';
import { formatLogInfo, formatToCurrency } from "../../../../../../../helpers/utils";

// const TableRow = ({ data, cancelBooking, receivePayment }) => {
const TableRow = ({ data,receivePayment }) => {
  // console.log(data);
  // let date = libDate.format(new Date(data.date), 'YYYY/MM/DD HH:mm:ss');
  try {
    // let sampleAmenities = [img1, img2, img3];
    return (
      <tr className="align-middle">
        <td className="text-nowrap text-center">{data?._id.slice(0, 8)}</td>
        <td className="text-nowrap text-center">
          {data?.Invoice_id?.amenity_booking_id?.amenity_id?.amenityname ||
            "name"}
        </td>
        <td className="text-nowrap text-center">
          {data?.Invoice_id?.user_id?.firstname || "name"}
        </td>
        <td className="text-nowrap text-center">
          {data?.accepted_by?.firstname || "name"}
        </td>
        <td className="text-nowrap text-center">
          {data?.Invoice_id?.unit_id?.unit_number || "number"}
        </td>
        <td className="text-nowrap text-center">{formatToCurrency(+data.paid_amount) || "$0"}</td>
        <td className="text-nowrap text-center">{data.payment_method}</td>
        <td className="text-nowrap text-center">
          {formatLogInfo(data.payment_date)}
        </td>
        {/* <td className="text-center">
          {data.status}
          {/* <SoftBadge pill bg={data.status.type}>
            {data.status.title}
            <FontAwesomeIcon icon={data.status.icon} className="ms-2" />
          </SoftBadge> */}
        {/* </td> */}
        <td className="text-center">
          <CardDropdown>
            <div className="py-2">
              <Dropdown.Item
                onClick={() => receivePayment(data)}
                className="text-success"
              >
                Payment Receipt
              </Dropdown.Item>
            </div>
          </CardDropdown>
        </td>
      </tr>
    );
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
};

const EmptyRow = () => {
  return (
    <tr className="text-center text-info fw-bold fs-1">
      <td colSpan={8}>Nothing Found!</td>
    </tr>
  );
};

const BookedAmenitiesTable = ({ list, cancelBooking, receivePayment }) => {
  try {
    return (
      <Table
        responsive
        striped
        hover
        bordered
        style={{ fontSize: "14px" }}
        className="text-center"
      >
        <thead>
          <tr>
            <th scope="col">Reference #</th>
            <th scope="col">Payment for</th>
            <th scope="col">Payment By</th>
            <th scope="col">Accepted By</th>
            <th scope="col">Unit #</th>
            <th scope="col">Amount</th>
            <th scope="col">Payment Method</th>
            <th scope="col">Payment Date</th>
            <th scope="col" className="text-center">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {list?.length > 0 ? (
            list?.map((amenity) => (
              <TableRow
                data={amenity}
                key={amenity._id}
                cancelBooking={cancelBooking}
                receivePayment={receivePayment}
              />
            ))
          ) : (
            <EmptyRow />
          )}
        </tbody>
      </Table>
    );
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
};

export default BookedAmenitiesTable;
