import FalconCardHeader from "../../../../components/common/FalconCardHeader";
// import TooltipBadge from 'components/common/TooltipBadge';
import React from "react";
import TimezonePicker from "react-bootstrap-timezone-picker";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Card, Form, Col, Button } from "react-bootstrap";
//import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';
import axios from "axios";

const PreferencesForm = ({ userData, jwtToken, hostname }) => {
  const [Tvalue, setValue] = React.useState();
  const options = ["English", "French"];
  const [selected, setSelected] = useState(options[0]);

  const user_id = userData.data;
  //   useEffect(async () => {

  //     //e.preventDefault();
  //     const config = {

  //       headers: {
  //           "Content-Type": "application/json",

  //       },
  //   };
  //   try {

  //     const  info  = await axios.post(

  //         `http://localhost:5000/api/auth/ShowPreference`,
  //         {
  //           user_id
  //         },
  //         config

  //     );
  //   console.log(info);

  //   try{
  //     setSelected(info.data.user.language);
  //     setValue(info.data.user.timezone);
  //   }
  //   catch{
  //     setSelected("English");
  //     setValue("America/New_York");
  //   }

  //   }
  //   catch (error) {

  //       console.log("Error"+error);
  //   }

  // }, []);

  const handleSubmit = async () => {
    console.log(Tvalue);
    console.log(selected);
    console.log(user_id);
    if (Tvalue) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      };

      try {
        const language = selected;
        const timezone = Tvalue;
        const info = await axios.post(
          `${hostname}/api/auth/Preference`,
          {
            user_id,
            language,
            timezone,
          },
          config
        );
        if (info) {
          //do nothing
        }
        toast.success("Update successfull", {
          theme: "colored",
        });
      } catch (error) {
        console.log("Error" + error);
      }
    } else {
      toast.error("All fields required", {
        theme: "colored",
      });
    }
  };

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Preferences" />
      <Card.Body className="bg-light">
        <Form.Group as={Col} controlId="firstname">
          <Form.Label>Language</Form.Label>
          <Form.Select
            aria-label="Default select example"
            value={selected}
            onChange={(e) => setSelected(e.target.value)}
          >
            {options.map((value) => (
              <option value={value} key={value}>
                {value}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <br />
        <Form.Group as={Col} controlId="timezone">
          <Form.Label>Timezone</Form.Label>

          <TimezonePicker
            onChange={setValue}
            value={Tvalue}
            defaultValue={"America/New_York"}
          />
          {/* <Form.Select aria-label="Default select example">
            <option>Select you Timezone</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
  </Form.Select>*/}
        </Form.Group>
        <br />
        <div className="text-end">
          <Button variant="primary" type="submit" onClick={handleSubmit}>
            Update
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export default PreferencesForm;
