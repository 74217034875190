import React from "react";
// import React, { useContext } from 'react';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Avatar from 'components/common/Avatar';
// import Image from './Image';
// import SoftBadge from "../../../../../components/common/SoftBadge";
// import { Badge, Table, Dropdown } from 'react-bootstrap';
import { Dropdown } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import CardDropdown from "../../../../../components/common/CardDropdown";
import { toast } from "react-toastify";
// import AppContext from 'context/Context';
import axios from "axios";

const TableRow = ({
  data,
  // addUnitOwner,
  // changeUnitOwner,
  propLength,
  // handleShowUnitDetail
  // handleAttachToUnitLater,
  // handleChangeNonCondoUnit,
  // handleDeAttachUnit,
  // ######################
  // updateUnit,
  addUnitOwnerNew,
  changeUnitOwnerNew,
  reinviteUnitOwnerNew,
  attachUnitWithCondoUnit,
  detachUnitFromCondoUnit,
  userData,
  jwtToken,
  hostname,
}) => {
  const navigate = useNavigate();

  const handleCopyInviteLink = (data) => {
    axios
      .post(
        `${hostname}/api/property/getOwnerlink`,
        {
          email: data.Inviteduser,
          unit_id: data._id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        let textToCopy = res.data.data || "sample text";

        navigator.clipboard.writeText(textToCopy);
        toast.success("Link copied to clipboard.");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      });
  };

  // console.log(data.status.type);
  if (!Object.hasOwnProperty.call(data, "status")) {
    return (
      <tr className="align-middle " style={{ marginBottom: "100px" }}>
        <td className="text-center text-warning" colSpan={5}>
          old entry of unit, with missing data
        </td>
      </tr>
    );
  }
  if (data.unit_type !== "condo") {
    if (
      Object.hasOwnProperty.call(data, "attachedToUnit") &&
      data.attachedToUnit !== null
    )
      return (
        <tr
          className="align-middle "
          style={{ height: propLength === 1 ? "100px" : "" }}
        >
          <td className="text-center ">
            {/* <Link to={`/${data.p_id}/${data.p_name}/${data.unit_number}/${data._id}/owners`}> */}
            {data.unit_number || "id"}
            {/* </Link> */}
          </td>
          <td className="text-center">{data.unit_type || "name"}</td>
          <td className="text-center">{`${data.cef}%` || "address"}</td>
          <td className="text-center">-</td>
          <td className="text-center">
            {data.attachedToUnit.unit_number || "unit no"}
          </td>
          {(userData.role === "Site-Administrators" ||
            userData.role === "Condozak-Basic-Users") && (
            <td className="text-center">
              <CardDropdown>
                <div className="py-2">
                  <Dropdown.Item
                    className="text-dark"
                    onClick={() => detachUnitFromCondoUnit(data)}
                  >
                    {/* Change Unit */}
                    Detach Unit
                  </Dropdown.Item>
                  {/* <Dropdown.Item
                    className="text-dark"
                    onClick={() => updateUnit(data)}
                  >
                    Update
                  </Dropdown.Item> */}
                </div>
              </CardDropdown>
            </td>
          )}
        </tr>
      );
    else
      return (
        <tr
          className="align-middle "
          style={{ height: propLength === 1 ? "100px" : "" }}
        >
          <td className="text-center">
            {/* <Link to={`/${data.p_id}/${data.p_name}/${data.unit_number}/${data._id}/details`}> */}
            {data.unit_number || "id"}
            {/* </Link> */}
          </td>
          <td className="text-center">{data.unit_type || "name"}</td>
          <td className="text-center">{`${data.cef}%` || "address"}</td>
          <td className="text-center">-</td>
          <td className="text-center">-</td>
          {(userData.role === "Site-Administrators" ||
            userData.role === "Condozak-Basic-Users") && (
            <td className="text-center">
              <CardDropdown>
                <div className="py-2">
                  <Dropdown.Item
                    className="text-dark"
                    onClick={() => attachUnitWithCondoUnit(data)}
                  >
                    Attach to unit
                  </Dropdown.Item>
                  {/* <Dropdown.Item
                    className="text-dark"
                    onClick={() => updateUnit(data)}
                  >
                    Update
                  </Dropdown.Item> */}
                </div>
              </CardDropdown>
            </td>
          )}
        </tr>
      );
  } else
    return (
      <tr
        className="align-middle "
        style={{ height: propLength === 1 ? "100px" : "" }}
      >
        <td className="text-center text-primary">
          <Link
            to={`/${data.p_id}/${data.p_name}/${data.unit_number}/${data._id}/attached-units`}
          >
            {data.unit_number || "id"}
          </Link>
        </td>
        <td className="text-center">{data.unit_type || "name"}</td>
        <td className="text-center">{`${data.cef}%` || "address"}</td>
        <td className="text-left">
          {/* {Object.hasOwnProperty.call(data, "Inviteduser") ? (
            <>
              {data.Inviteduser}{" "}
              <SoftBadge pill bg={"info"}>
                Invited
                <FontAwesomeIcon icon={"check"} className="ms-2" />
              </SoftBadge>
            </>
          ) : Object.hasOwnProperty.call(data, "userinfo") ? (
            <>
              {data.userinfo.firstname} {data.userinfo.lastname}{" "}
              <SoftBadge pill bg={"success"}>
                Active
                <FontAwesomeIcon icon={"check"} className="ms-2" />
              </SoftBadge>
            </>
          ) : (
            <>
              Owner Not Linked{" "}
              <SoftBadge pill bg={"secondary"}>
                Pending
                <FontAwesomeIcon icon={"check"} className="ms-2" />
              </SoftBadge>
            </>
          )} */}
          {Object.hasOwnProperty.call(data, "Inviteduser") ? (
            <>{data.Inviteduser} </>
          ) : Object.hasOwnProperty.call(data, "userinfo") ? (
            <>
              {data.userinfo.firstname} {data.userinfo.lastname}{" "}
            </>
          ) : (
            <>Owner Not Linked </>
          )}
          {data.resident_status === "Active" && (
            <div className="badge bg-success">Active</div>
          )}
          {data.resident_status === "Not Invited" && (
            <div className="badge bg-primary">Pending</div>
          )}
          {data.resident_status === "Ignored" && (
            <div className="badge bg-secondary">Ignored</div>
          )}
          {data.resident_status === "Invited" && (
            <div className="badge bg-info">Invited</div>
          )}
        </td>
        <td className="text-center">-</td>
        {(userData.role === "Site-Administrators" ||
          userData.role === "Condozak-Basic-Users") && (
          <td className="text-center">
            <CardDropdown>
              <div className="py-2">
                {/* <Dropdown.Item
                  className="text-dark"
                  // style={{'fontWeight':'bold'}}
                  onClick={() => updateUnit(data)}
                >
                  Update
                </Dropdown.Item> */}
                {data.resident_status === "Not Invited" && (
                  <>
                    <Dropdown.Item
                      className="text-dark"
                      // style={{'fontWeight':'bold'}}
                      onClick={() => addUnitOwnerNew(data)}
                    >
                      Add Unit Owner
                    </Dropdown.Item>
                  </>
                )}
                {data.resident_status === "Invited" && (
                  <>
                    <Dropdown.Item
                      className="text-dark"
                      // style={{'fontWeight':'bold'}}
                      onClick={() => reinviteUnitOwnerNew(data)}
                    >
                      Reinvite Unit Owner
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="text-primary"
                      // style={{'fontWeight':'bold'}}
                      onClick={() => handleCopyInviteLink(data)}
                    >
                      Copy Invite Link
                    </Dropdown.Item>
                  </>
                )}
                {data.resident_status === "Ignored" && (
                  <>
                    <Dropdown.Item
                      className="text-dark"
                      // style={{'fontWeight':'bold'}}
                      onClick={() => reinviteUnitOwnerNew(data)}
                    >
                      Reinvite Unit Owner
                    </Dropdown.Item>
                  </>
                )}
                {data.resident_status === "Active" && (
                  <Dropdown.Item
                    className="text-dark"
                    // style={{'fontWeight':'bold'}}
                    // onClick={() => changeUnitOwnerNew(data)}
                    onClick={() =>
                      navigate(
                        `/${data.p_id}/${data.p_name}/${data.unit_number}/${data._id}/attached-units`
                      )
                    }
                  >
                    View Unit Details
                  </Dropdown.Item>
                )}
              </div>
            </CardDropdown>
          </td>
        )}
      </tr>
    );
};

const UnitInfoTable = ({
  list,
  updateUnitInfo,
  addUnitOwner,
  changeUnitOwner,
  handleShowUnitDetail,
  handleAttachToUnitLater,
  handleChangeNonCondoUnit,
  handleDeAttachUnit,
  updateUnit,
  addUnitOwnerNew,
  changeUnitOwnerNew,
  reinviteUnitOwnerNew,
  attachUnitWithCondoUnit,
  detachUnitFromCondoUnit,
  userData,
  jwtToken,
  hostname,
}) => {
  // console.log(units);

  return (
    <Table responsive striped hover bordered style={{ fontSize: "14px" }}>
      <thead>
        <tr>
          <th scope="col" className="text-center">
            Unit Number
          </th>
          <th scope="col" className="text-center">
            Unit Type
          </th>
          <th scope="col" className="text-center">
            %CEF
          </th>
          <th scope="col" className="text-center">
            Unit Owner
          </th>
          <th scope="col" className="text-center">
            Attached To
          </th>
          {(userData.role === "Site-Administrators" ||
            userData.role === "Condozak-Basic-Users") && (
            <th scope="col" className="text-center">
              Actions
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {list?.map((unit, index) => (
          <TableRow
            data={unit}
            key={index}
            updateUnitInfo={updateUnitInfo}
            addUnitOwner={addUnitOwner}
            changeUnitOwner={changeUnitOwner}
            handleShowUnitDetail={handleShowUnitDetail}
            propLength={list.length}
            handleAttachToUnitLater={handleAttachToUnitLater}
            handleChangeNonCondoUnit={handleChangeNonCondoUnit}
            handleDeAttachUnit={handleDeAttachUnit}
            // ###################################################
            updateUnit={updateUnit}
            addUnitOwnerNew={addUnitOwnerNew}
            changeUnitOwnerNew={changeUnitOwnerNew}
            reinviteUnitOwnerNew={reinviteUnitOwnerNew}
            attachUnitWithCondoUnit={attachUnitWithCondoUnit}
            detachUnitFromCondoUnit={detachUnitFromCondoUnit}
            userData={userData}
            jwtToken={jwtToken}
            hostname={hostname}
          />
        ))}
        {/* <br /> */}
      </tbody>
    </Table>
  );
};

export default UnitInfoTable;
