import React from "react";
import { Modal, Card, Table, Row, Col } from "react-bootstrap";
import FalconCloseButton from "../../../components/common/FalconCloseButton";

import IconButton from "../../../components/common/IconButton";
import SimpleBarReact from "simplebar-react";

import { startCase } from "lodash";
import { formatLogInfo, generatePdfByJsPdf } from "../../../helpers/utils";
import { useParams } from "react-router-dom";

export default function InvoiceModel(props) {
  const printRef = React.useRef();

  const { loadType, userData } = props;

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      style={{ backdropFilter: "blur(3px)" }}
      keyboard={false}
      size="lg"
    >
      <Modal.Header>
        <Modal.Title style={{ minWidth: "95%" }}>
          <Row className="justify-content-between align-items-center">
            <Col md>
              <h3 className="mb-2 mb-md-0">Invoice</h3>
            </Col>
            <Col xs="auto">
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="print"
                iconClassName="me-1"
                className="me-1 mb-2 mb-sm-0"
                onClick={() =>
                  generatePdfByJsPdf(printRef.current, "preview", 50)
                }
              >
                Print
              </IconButton>
              {loadType?.status?.title !== "Paid" && (
                <IconButton
                  variant="falcon-success"
                  size="sm"
                  icon="dollar-sign"
                  className="mb-2 mb-sm-0"
                  onClick={() => props.handleStripePayment(loadType)}
                >
                  Pay Now
                </IconButton>
              )}
            </Col>
          </Row>
        </Modal.Title>
        <FalconCloseButton onClick={props.handleClose} />
      </Modal.Header>
      <Modal.Body className="">
        <Invoice
          // handleDownloadPdf={handleDownloadPdf}
          printRef={printRef}
          loadType={loadType}
          userData={userData}
        />
      </Modal.Body>
    </Modal>
  );
}

const Invoice = ({ printRef, loadType, userData }) => {
  const { unit_number } = useParams();

  return (
    <>
      <Card className="mb-3 shadow-none" ref={printRef}>
        <Card.Body>
          <Row className="align-items-center text-center mb-3">
            <Col sm={6} className="text-sm-start">
              {loadType.property_logo &&
                loadType.property_logo_type !== "link" && (
                  <img
                    src={`data:image/${loadType.property_logo_type};base64,${loadType.property_logo}`}
                    alt="property-logo"
                    width={130}
                  />
                )}
              {/* {!loadType.property_logo && (
                <img src={logoInvoice} alt="invoice" width={250} />
              )} */}
            </Col>
            <Col className="text-sm-end mt-3 mt-sm-0">
              {/* <h2 className="mb-3">Invoice</h2> */}
              <h5>
                {userData.role === "Site-Administrators" ||
                userData.role === "Condozak-Basic-Users"
                  ? startCase(userData?.property_name) || "property name here"
                  : ""}
                {(userData.role === "unit_owner" || userData.role === "supp_resident" || userData.role === "supp_tenant")
                  ? startCase(userData?.property_name) || "property name here"
                  : ""}
              </h5>
              <p className="fs--1 mb-0">
                {loadType?.property_address?.address}
                <br />
                {`${loadType?.property_address?.city}, ${loadType?.property_address?.state}, ${loadType?.property_address?.country}.`}
              </p>
            </Col>
            <Col xs={12}>
              <hr />
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col>
              <h6 className="text-500">Invoice to</h6>
              {(userData.role === "Site-Administrators" ||
                userData.role === "Condozak-Basic-Users") && (
                <h5>{`${startCase(loadType?.user_id.firstname)} ${startCase(
                  loadType?.user_id.lastname
                )}`}</h5>
              )}
              {(userData.role === "unit_owner" || userData.role === "supp_resident" || userData.role === "supp_tenant") && (
                <h5>{`${startCase(userData.firstname)} ${startCase(
                  userData.lastname
                )}`}</h5>
              )}
              {(userData.role === "Site-Administrators" ||
                userData.role === "Condozak-Basic-Users") && (
                <p className="fs--1 mb-0">
                  Unit # {loadType?.unit_id?.unit_number}
                  <br />
                  {loadType?.property_address?.address}
                  <br />
                  {`${loadType?.property_address?.city || ""}, ${
                    loadType?.property_address?.state || ""
                  }, ${loadType?.property_address?.zipcode || ""}.`}
                  <br />
                  {loadType?.property_address?.country || ""}
                </p>
              )}
              {(userData.role === "unit_owner" || userData.role === "supp_resident" || userData.role === "supp_tenant") && (
                <p className="fs--1 mb-0">
                  Unit # {unit_number}
                  <br />
                  {loadType?.property_address?.address}
                  <br />
                  {`${loadType?.property_address?.city}, ${loadType?.property_address?.state}, ${loadType?.property_address?.zipcode}.`}
                  <br />
                  {loadType?.property_address?.country}
                </p>
              )}

              <br />
              <p className="fs--1">
                {/* <a href="mailto:example@gmail.com">
                  {userData.email || "example@gmail.com"}
                </a> */}
                {/* <br /> */}
                {/* <a href="tel:444466667777">+4444-6666-7777</a> */}
              </p>
            </Col>
            <Col sm="auto" className="ms-auto">
              <div className="table-responsive">
                <Table borderless size="sm" className="fs--1">
                  <tbody>
                    <tr>
                      <th className="text-sm-end">Invoice No:</th>
                      <td>{loadType?.invoice_number || "1212"}</td>
                    </tr>
                    <tr>
                      <th className="text-sm-end">Reference Number:</th>
                      <td>
                        {loadType?.booking_id?.referred_id?._id?.slice(0, 8)}
                      </td>
                    </tr>
                    <tr>
                      <th className="text-sm-end">Invoice Date:</th>
                      <td>{formatLogInfo(loadType?.issue_date)}</td>
                    </tr>
                    <tr>
                      <th className="text-sm-end">Payment Due:</th>
                      <td>Upon receipt</td>
                    </tr>
                    {/* <tr className="alert alert-success fw-bold">
                      <th className="text-sm-end">Amount Due:</th>
                      <td>${loadType?.amount}.00</td>
                    </tr> */}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>

          <div className="mt-4 fs--1">
            <SimpleBarReact>
              <Table striped className="border-bottom">
                <thead className="light">
                  <tr className="bg-secondary text-white">
                    <th className="border-0">Description</th>
                    <th className="border-0 text-center">Quantity</th>
                    <th className="border-0 text-end">Rate</th>
                    <th className="border-0 text-end">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {loadType?.booking_id?.referred_id?.Product_info?.map(
                    (v, i) => (
                      <tr key={v?._id + i}>
                        <td className="align-middle">
                          <h6 className="mb-0 text-nowrap">
                            {startCase(v.selected_option_ids?.name)}
                          </h6>
                        </td>
                        <td className="align-middle text-center">
                          {v.quantity}
                        </td>
                        <td className="align-middle text-end">
                          ${v.selected_option_ids?.price}
                        </td>
                        <td className="align-middle text-end">
                          {`$${v.quantity * v.selected_option_ids?.price}.00` ||
                            ""}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </SimpleBarReact>
          </div>

          <Row className="justify-content-end">
            <Col xs="auto">
              <br />
              <br />
              <Table borderless size="sm" className="fs--1 text-end">
                <tbody>
                  {/* <tr>
                    <th className="text-900">Subtotal:</th>
                    <td className="fw-semi-bold">$18,230.00</td>
                  </tr> */}
                  {/* <tr>
                    <th className="text-900">Tax 0%:</th>
                    <td className="fw-semi-bold">${loadType.amount}.00</td>
                  </tr> */}
                  <tr className="">
                    <th className="text-900">Total:</th>
                    <td className="fw-semi-bold">${loadType?.amount}.00</td>
                  </tr>
                  <tr className="border-top border-top-2 fw-bolder text-900">
                    <th>Amount Due:</th>
                    <td>${loadType?.amount}.00</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Card.Body>
        {/* <Card.Footer className="bg-light">
          <p className="fs--1 mb-0">
            <strong>Notes: </strong> We really appreciate your business and if
            there’s anything else we can do, please let us know!
          </p>
        </Card.Footer> */}
      </Card>
    </>
  );
};
