import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { Accordion, Row, Col, Button, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';

export default function TimeSlots({ values, user, setValues }) {
  try {
    const createDays = () => {
      let arr = [
        {
          day: 'Sunday',
          start: 'start_time_sun',
          end: 'end_time_sun',
          start_zone: 'start_zone_sun',
          end_zone: 'end_zone_sun'
        },
        {
          day: 'Monday',
          start: 'start_time_mon',
          end: 'end_time_mon',
          start_zone: 'start_zone_mon',
          end_zone: 'end_zone_mon'
        },
        {
          day: 'Tuesday',
          start: 'start_time_tue',
          end: 'end_time_tue',
          start_zone: 'start_zone_tue',
          end_zone: 'end_zone_tue'
        },
        {
          day: 'Wednesday',
          start: 'start_time_wed',
          end: 'end_time_wed',
          start_zone: 'start_zone_wed',
          end_zone: 'end_zone_wed'
        },
        {
          day: 'Thursday',
          start: 'start_time_thu',
          end: 'end_time_thu',
          start_zone: 'start_zone_thu',
          end_zone: 'end_zone_thu'
        },
        {
          day: 'Friday',
          start: 'start_time_fri',
          end: 'end_time_fri',
          start_zone: 'start_zone_fri',
          end_zone: 'end_zone_fri'
        },
        {
          day: 'Saturday',
          start: 'start_time_sat',
          end: 'end_time_sat',
          start_zone: 'start_zone_sat',
          end_zone: 'end_zone_sat'
        }
      ];

      return arr;
    };
    const creatBlackouts = (day = 'sun', count = 0) => {
      let blackouts = [
        {
          start_time: `blackout_${day}_start_1`,
          start_zone: `blackout_${day}_start_zone_1`,
          end_time: `blackout_${day}_end_1`,
          end_zone: `blackout_${day}_end_zone_1`
        },
        {
          start_time: `blackout_${day}_start_2`,
          start_zone: `blackout_${day}_start_zone_2`,
          end_time: `blackout_${day}_end_2`,
          end_zone: `blackout_${day}_end_zone_2`
        },
        {
          start_time: `blackout_${day}_start_3`,
          start_zone: `blackout_${day}_start_zone_3`,
          end_time: `blackout_${day}_end_3`,
          end_zone: `blackout_${day}_end_zone_3`
        },
        {
          start_time: `blackout_${day}_start_4`,
          start_zone: `blackout_${day}_start_zone_4`,
          end_time: `blackout_${day}_end_4`,
          end_zone: `blackout_${day}_end_zone_4`
        },
        {
          start_time: `blackout_${day}_start_5`,
          start_zone: `blackout_${day}_start_zone_5`,
          end_time: `blackout_${day}_end_5`,
          end_zone: `blackout_${day}_end_zone_5`
        }
      ];
      // console.log(blackouts);
      if (count !== 0) return blackouts.slice(0, count);
      else return blackouts;
    };
    // const pageState = user => {
    //   let obj = user;
    //   console.log(obj);
    //   return {
    //     // approval: obj.approval || '',
    //     // amenity_rules: obj.amenity_rules || '',
    //     max_book_length: obj.max_book_length || '',
    //     volume_of_booking: obj.volume_of_booking || '',
    //     // allow_same_booking: obj.allow_same_booking || '',
    //     // limit_by_unit_value: obj.limit_by_unit_value || '',
    //     // limit_by_unit_date: obj.limit_by_unit_date || '',
    //     booking_day: obj.booking_day || '',
    //     booking_start_date: obj.booking_start_date || '',
    //     booking_end_date: obj.booking_end_date || '',
    //     // duration_in_hours: obj.duration_in_hours || '',
    //     // max_guest_limit: obj.max_guest_limit || '',
    //     // allow_same_booking: obj.allow_same_booking || '',
    //     // multiple_bookings_on_time_slots:
    //     //   obj.multiple_bookings_on_time_slots || '',

    //     // for sunday
    //     start_time_sun: obj.start_time_sun || '',
    //     end_time_sun: obj.end_time_sun || '',
    //     start_zone_sun: obj.start_zone_sun || '',
    //     end_zone_sun: obj.end_zone_sun || '',
    //     blackout_sun_start_1: obj.blackout_sun_start_1 || '',
    //     blackout_sun_start_zone_1: obj.blackout_sun_start_zone_1 || '',
    //     blackout_sun_start_2: obj.blackout_sun_start_2 || '',
    //     blackout_sun_start_zone_2: obj.blackout_sun_start_zone_2 || '',
    //     blackout_sun_start_3: obj.blackout_sun_start_3 || '',
    //     blackout_sun_start_zone_3: obj.blackout_sun_start_zone_3 || '',
    //     blackout_sun_start_4: obj.blackout_sun_start_4 || '',
    //     blackout_sun_start_zone_4: obj.blackout_sun_start_zone_4 || '',
    //     blackout_sun_start_5: obj.blackout_sun_start_5 || '',
    //     blackout_sun_start_zone_5: obj.blackout_sun_start_zone_5 || '',
    //     blackout_sun_end_1: obj.blackout_sun_end_1 || '',
    //     blackout_sun_end_zone_1: obj.blackout_sun_end_zone_1 || '',
    //     blackout_sun_end_2: obj.blackout_sun_end_2 || '',
    //     blackout_sun_end_zone_2: obj.blackout_sun_end_zone_2 || '',
    //     blackout_sun_end_3: obj.blackout_sun_end_3 || '',
    //     blackout_sun_end_zone_3: obj.blackout_sun_end_zone_3 || '',
    //     blackout_sun_end_4: obj.blackout_sun_end_4 || '',
    //     blackout_sun_end_zone_4: obj.blackout_sun_end_zone_4 || '',
    //     blackout_sun_end_5: obj.blackout_sun_end_5 || '',
    //     blackout_sun_end_zone_5: obj.blackout_sun_end_zone_5 || '',

    //     // for monday
    //     start_time_mon: obj.start_time_mon || '',
    //     end_time_mon: obj.end_time_mon || '',
    //     start_zone_mon: obj.start_zone_mon || '',
    //     end_zone_mon: obj.end_zone_mon || '',
    //     blackout_mon_start_1: obj.blackout_mon_start_1 || '',
    //     blackout_mon_start_zone_1: obj.blackout_mon_start_zone_1 || '',
    //     blackout_mon_start_2: obj.blackout_mon_start_2 || '',
    //     blackout_mon_start_zone_2: obj.blackout_mon_start_zone_2 || '',
    //     blackout_mon_start_3: obj.blackout_mon_start_3 || '',
    //     blackout_mon_start_zone_3: obj.blackout_mon_start_zone_3 || '',
    //     blackout_mon_start_4: obj.blackout_mon_start_4 || '',
    //     blackout_mon_start_zone_4: obj.blackout_mon_start_zone_4 || '',
    //     blackout_mon_start_5: obj.blackout_mon_start_5 || '',
    //     blackout_mon_start_zone_5: obj.blackout_mon_start_zone_5 || '',
    //     blackout_mon_end_1: obj.blackout_mon_end_1 || '',
    //     blackout_mon_end_zone_1: obj.blackout_mon_end_zone_1 || '',
    //     blackout_mon_end_2: obj.blackout_mon_end_2 || '',
    //     blackout_mon_end_zone_2: obj.blackout_mon_end_zone_2 || '',
    //     blackout_mon_end_3: obj.blackout_mon_end_3 || '',
    //     blackout_mon_end_zone_3: obj.blackout_mon_end_zone_3 || '',
    //     blackout_mon_end_4: obj.blackout_mon_end_4 || '',
    //     blackout_mon_end_zone_4: obj.blackout_mon_end_zone_4 || '',
    //     blackout_mon_end_5: obj.blackout_mon_end_5 || '',
    //     blackout_mon_end_zone_5: obj.blackout_mon_end_zone_5 || '',

    //     // for tuesday
    //     start_time_tue: obj.start_time_tue || '',
    //     end_time_tue: obj.end_time_tue || '',
    //     start_zone_tue: obj.start_zone_tue || '',
    //     end_zone_tue: obj.end_zone_tue || '',
    //     blackout_tue_start_1: obj.blackout_tue_start_1 || '',
    //     blackout_tue_start_zone_1: obj.blackout_tue_start_zone_1 || '',
    //     blackout_tue_start_2: obj.blackout_tue_start_2 || '',
    //     blackout_tue_start_zone_2: obj.blackout_tue_start_zone_2 || '',
    //     blackout_tue_start_3: obj.blackout_tue_start_3 || '',
    //     blackout_tue_start_zone_3: obj.blackout_tue_start_zone_3 || '',
    //     blackout_tue_start_4: obj.blackout_tue_start_4 || '',
    //     blackout_tue_start_zone_4: obj.blackout_tue_start_zone_4 || '',
    //     blackout_tue_start_5: obj.blackout_tue_start_5 || '',
    //     blackout_tue_start_zone_5: obj.blackout_tue_start_zone_5 || '',
    //     blackout_tue_end_1: obj.blackout_tue_end_1 || '',
    //     blackout_tue_end_zone_1: obj.blackout_tue_end_zone_1 || '',
    //     blackout_tue_end_2: obj.blackout_tue_end_2 || '',
    //     blackout_tue_end_zone_2: obj.blackout_tue_end_zone_2 || '',
    //     blackout_tue_end_3: obj.blackout_tue_end_3 || '',
    //     blackout_tue_end_zone_3: obj.blackout_tue_end_zone_3 || '',
    //     blackout_tue_end_4: obj.blackout_tue_end_4 || '',
    //     blackout_tue_end_zone_4: obj.blackout_tue_end_zone_4 || '',
    //     blackout_tue_end_5: obj.blackout_tue_end_5 || '',
    //     blackout_tue_end_zone_5: obj.blackout_tue_end_zone_5 || '',

    //     // for wednesday
    //     start_time_wed: obj.start_time_wed || '',
    //     end_time_wed: obj.end_time_wed || '',
    //     start_zone_wed: obj.start_zone_wed || '',
    //     end_zone_wed: obj.end_zone_wed || '',
    //     blackout_wed_start_1: obj.blackout_wed_start_1 || '',
    //     blackout_wed_start_zone_1: obj.blackout_wed_start_zone_1 || '',
    //     blackout_wed_start_2: obj.blackout_wed_start_2 || '',
    //     blackout_wed_start_zone_2: obj.blackout_wed_start_zone_2 || '',
    //     blackout_wed_start_3: obj.blackout_wed_start_3 || '',
    //     blackout_wed_start_zone_3: obj.blackout_wed_start_zone_3 || '',
    //     blackout_wed_start_4: obj.blackout_wed_start_4 || '',
    //     blackout_wed_start_zone_4: obj.blackout_wed_start_zone_4 || '',
    //     blackout_wed_start_5: obj.blackout_wed_start_5 || '',
    //     blackout_wed_start_zone_5: obj.blackout_wed_start_zone_5 || '',
    //     blackout_wed_end_1: obj.blackout_wed_end_1 || '',
    //     blackout_wed_end_zone_1: obj.blackout_wed_end_zone_1 || '',
    //     blackout_wed_end_2: obj.blackout_wed_end_2 || '',
    //     blackout_wed_end_zone_2: obj.blackout_wed_end_zone_2 || '',
    //     blackout_wed_end_3: obj.blackout_wed_end_3 || '',
    //     blackout_wed_end_zone_3: obj.blackout_wed_end_zone_3 || '',
    //     blackout_wed_end_4: obj.blackout_wed_end_4 || '',
    //     blackout_wed_end_zone_4: obj.blackout_wed_end_zone_4 || '',
    //     blackout_wed_end_5: obj.blackout_wed_end_5 || '',
    //     blackout_wed_end_zone_5: obj.blackout_wed_end_zone_5 || '',

    //     // for thrusday
    //     start_time_thu: obj.start_time_thu || '',
    //     end_time_thu: obj.end_time_thu || '',
    //     start_zone_thu: obj.start_zone_thu || '',
    //     end_zone_thu: obj.end_zone_thu || '',
    //     blackout_thu_start_1: obj.blackout_thu_start_1 || '',
    //     blackout_thu_start_zone_1: obj.blackout_thu_start_zone_1 || '',
    //     blackout_thu_start_2: obj.blackout_thu_start_2 || '',
    //     blackout_thu_start_zone_2: obj.blackout_thu_start_zone_2 || '',
    //     blackout_thu_start_3: obj.blackout_thu_start_3 || '',
    //     blackout_thu_start_zone_3: obj.blackout_thu_start_zone_3 || '',
    //     blackout_thu_start_4: obj.blackout_thu_start_4 || '',
    //     blackout_thu_start_zone_4: obj.blackout_thu_start_zone_4 || '',
    //     blackout_thu_start_5: obj.blackout_thu_start_5 || '',
    //     blackout_thu_start_zone_5: obj.blackout_thu_start_zone_5 || '',
    //     blackout_thu_end_1: obj.blackout_thu_end_1 || '',
    //     blackout_thu_end_zone_1: obj.blackout_thu_end_zone_1 || '',
    //     blackout_thu_end_2: obj.blackout_thu_end_2 || '',
    //     blackout_thu_end_zone_2: obj.blackout_thu_end_zone_2 || '',
    //     blackout_thu_end_3: obj.blackout_thu_end_3 || '',
    //     blackout_thu_end_zone_3: obj.blackout_thu_end_zone_3 || '',
    //     blackout_thu_end_4: obj.blackout_thu_end_4 || '',
    //     blackout_thu_end_zone_4: obj.blackout_thu_end_zone_4 || '',
    //     blackout_thu_end_5: obj.blackout_thu_end_5 || '',
    //     blackout_thu_end_zone_5: obj.blackout_thu_end_zone_5 || '',

    //     // for friday
    //     start_time_fri: obj.start_time_fri || '',
    //     end_time_fri: obj.end_time_fri || '',
    //     start_zone_fri: obj.start_zone_fri || '',
    //     end_zone_fri: obj.end_zone_fri || '',
    //     blackout_fri_start_1: obj.blackout_fri_start_1 || '',
    //     blackout_fri_start_zone_1: obj.blackout_fri_start_zone_1 || '',
    //     blackout_fri_start_2: obj.blackout_fri_start_2 || '',
    //     blackout_fri_start_zone_2: obj.blackout_fri_start_zone_2 || '',
    //     blackout_fri_start_3: obj.blackout_fri_start_3 || '',
    //     blackout_fri_start_zone_3: obj.blackout_fri_start_zone_3 || '',
    //     blackout_fri_start_4: obj.blackout_fri_start_4 || '',
    //     blackout_fri_start_zone_4: obj.blackout_fri_start_zone_4 || '',
    //     blackout_fri_start_5: obj.blackout_fri_start_5 || '',
    //     blackout_fri_start_zone_5: obj.blackout_fri_start_zone_5 || '',
    //     blackout_fri_end_1: obj.blackout_fri_end_1 || '',
    //     blackout_fri_end_zone_1: obj.blackout_fri_end_zone_1 || '',
    //     blackout_fri_end_2: obj.blackout_fri_end_2 || '',
    //     blackout_fri_end_zone_2: obj.blackout_fri_end_zone_2 || '',
    //     blackout_fri_end_3: obj.blackout_fri_end_3 || '',
    //     blackout_fri_end_zone_3: obj.blackout_fri_end_zone_3 || '',
    //     blackout_fri_end_4: obj.blackout_fri_end_4 || '',
    //     blackout_fri_end_zone_4: obj.blackout_fri_end_zone_4 || '',
    //     blackout_fri_end_5: obj.blackout_fri_end_5 || '',
    //     blackout_fri_end_zone_5: obj.blackout_fri_end_zone_5 || '',

    //     // for satureday
    //     start_time_sat: obj.start_time_sat || '',
    //     end_time_sat: obj.end_time_sat || '',
    //     start_zone_sat: obj.start_zone_sat || '',
    //     end_zone_sat: obj.end_zone_sat || '',
    //     blackout_sat_start_1: obj.blackout_sat_start_1 || '',
    //     blackout_sat_start_zone_1: obj.blackout_sat_start_zone_1 || '',
    //     blackout_sat_start_2: obj.blackout_sat_start_2 || '',
    //     blackout_sat_start_zone_2: obj.blackout_sat_start_zone_2 || '',
    //     blackout_sat_start_3: obj.blackout_sat_start_3 || '',
    //     blackout_sat_start_zone_3: obj.blackout_sat_start_zone_3 || '',
    //     blackout_sat_start_4: obj.blackout_sat_start_4 || '',
    //     blackout_sat_start_zone_4: obj.blackout_sat_start_zone_4 || '',
    //     blackout_sat_start_5: obj.blackout_sat_start_5 || '',
    //     blackout_sat_start_zone_5: obj.blackout_sat_start_zone_5 || '',
    //     blackout_sat_end_1: obj.blackout_sat_end_1 || '',
    //     blackout_sat_end_zone_1: obj.blackout_sat_end_zone_1 || '',
    //     blackout_sat_end_2: obj.blackout_sat_end_2 || '',
    //     blackout_sat_end_zone_2: obj.blackout_sat_end_zone_2 || '',
    //     blackout_sat_end_3: obj.blackout_sat_end_3 || '',
    //     blackout_sat_end_zone_3: obj.blackout_sat_end_zone_3 || '',
    //     blackout_sat_end_4: obj.blackout_sat_end_4 || '',
    //     blackout_sat_end_zone_4: obj.blackout_sat_end_zone_4 || '',
    //     blackout_sat_end_5: obj.blackout_sat_end_5 || '',
    //     blackout_sat_end_zone_5: obj.blackout_sat_end_zone_5 || ''
    //   };
    // };
    
    const copyFieldsToAll = (
      values = {},
      entry = {},
      blackouts = {},
      setValues
    ) => {
      // console.log(blackouts);
      // return;
      // if (blackouts) {
      // }

      ['mon', 'tue', 'wed', 'thu', 'fri', 'sat'].forEach(v => {
        values[`start_time_${v}`] = entry.start;
        values[`end_time_${v}`] = entry.end;
        values[`start_zone_${v}`] = entry.start_zone;
        values[`end_zone_${v}`] = entry.end_zone;

        for (let i = 0; i < 5; i++) {
          values[`blackout_${v}_start_${i + 1}`] = blackouts[`start_${i + 1}`];
          values[`blackout_${v}_end_${i + 1}`] = blackouts[`end_${i + 1}`];
          values[`blackout_${v}_start_zone_${i + 1}`] =
            blackouts[`start_zone_${i + 1}`];
          values[`blackout_${v}_end_zone_${i + 1}`] =
            blackouts[`end_zone_${i + 1}`];
        }
      });
      console.log(blackouts);
      console.log(values);
      // return;
      // console.log(Object.assign({ ...values }, mainObj));
      setValues(Object.assign({}, user, values));
      toast.success('Copied current entry in all Fields!', {
        theme: 'colored'
      });
    };
    return (
      <>
        <div className="mb-3">
          <label>Time Slots Duration</label>
          <Field
            component="select"
            id="max_book_length"
            name="max_book_length"
            multiple={false}
            className="form-select"
          >
            <option value="">Select duration</option>
            <option value="30">30 Minutes</option>
            <option value="45">45 Minutes</option>
            <option value="60">1 Hour</option>
            <option value="180">3 Hours</option>
            <option value="300">5 Hours</option>
          </Field>
          <ErrorMessage
            name="max_book_length"
            component={'div'}
            className="text-danger fs--1"
          />
        </div>
        {/* <h5 className="text-center mb-3">{values.booking_type}</h5> */}

        <Row className="mb-3">
          {createDays().map((d, i) => (
            <Accordion key={i + 5} className="mb-3">
              <Accordion.Item eventKey={i}>
                <Accordion.Header>
                  <h6>
                    Available Time for{' '}
                    <span className="text-primary">{d.day}</span>
                  </h6>
                </Accordion.Header>
                <Accordion.Body>
                  {d.day === 'Sunday' && (
                    <div className="d-flex align-items-center justify-content-end mb-3">
                      {/* <h5 className="">Available Time </h5> */}
                      <Button
                        variant="falcon-success"
                        size="sm"
                        className=""
                        disabled={
                          !values.start_time_sun ||
                          !values.end_time_sun ||
                          !values.start_zone_sun ||
                          !values.end_zone_sun
                        }
                        onClick={() =>
                          copyFieldsToAll(
                            values,
                            {
                              start: values.start_time_sun,
                              end: values.end_time_sun,
                              start_zone: values.start_zone_sun,
                              end_zone: values.end_zone_sun
                            },
                            {
                              start_1: values.blackout_sun_start_1,
                              end_1: values.blackout_sun_end_1,
                              start_zone_1: values.blackout_sun_start_zone_1,
                              end_zone_1: values.blackout_sun_end_zone_1,
                              start_2: values.blackout_sun_start_2,
                              end_2: values.blackout_sun_end_2,
                              start_zone_2: values.blackout_sun_start_zone_2,
                              end_zone_2: values.blackout_sun_end_zone_2,
                              start_3: values.blackout_sun_start_3,
                              end_3: values.blackout_sun_end_3,
                              start_zone_3: values.blackout_sun_start_zone_3,
                              end_zone_3: values.blackout_sun_end_zone_3,
                              start_4: values.blackout_sun_start_4,
                              end_4: values.blackout_sun_end_4,
                              start_zone_4: values.blackout_sun_start_zone_4,
                              end_zone_4: values.blackout_sun_end_zone_4,
                              start_5: values.blackout_sun_start_5,
                              end_5: values.blackout_sun_end_5,
                              start_zone_5: values.blackout_sun_start_zone_5,
                              end_zone_5: values.blackout_sun_end_zone_5
                            },
                            setValues
                          )
                        }
                      >
                        Copy entries in All days{' '}
                      </Button>
                    </div>
                  )}
                  <Row className="mb-3 bg-light p-2 pb-3">
                    <Col md={6}>
                      <label>Start Time</label>
                      <Field
                        type="text"
                        name={d.start}
                        className="form-control"
                        placeholder="HH : MM"
                      />
                      <ErrorMessage
                        name={d.start}
                        component={'div'}
                        className="text-danger fs--1"
                      />
                      <div className="d-flex justify-content-end mt-3">
                        <label className="mx-3 ">
                          <span className="h6">AM</span>{' '}
                          <Field type="radio" name={d.start_zone} value="AM" />
                        </label>
                        <label className="mx-3">
                          {' '}
                          <span className="h6">PM</span>{' '}
                          <Field type="radio" name={d.start_zone} value="PM" />
                        </label>
                      </div>
                      <div className="text-end">
                        <ErrorMessage
                          name={d.start_zone}
                          component={'div'}
                          className="text-danger fs--1"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <label>End Time</label>
                      <Field
                        type="text"
                        name={d.end}
                        className="form-control"
                        placeholder="HH : MM"
                      />
                      <ErrorMessage
                        name={d.end}
                        component={'div'}
                        className="text-danger fs--1"
                      />
                      <div className="d-flex justify-content-end mt-3">
                        <label className="mx-3 ">
                          <span className="h6">AM</span>{' '}
                          <Field type="radio" name={d.end_zone} value="AM" />
                        </label>
                        <label className="mx-3">
                          {' '}
                          <span className="h6">PM</span>{' '}
                          <Field type="radio" name={d.end_zone} value="PM" />
                        </label>
                      </div>
                      <div className="text-end">
                        <ErrorMessage
                          name={d.end_zone}
                          component={'div'}
                          className="text-danger fs--1"
                        />
                      </div>
                    </Col>
                  </Row>
                  <hr />
                  <Row className="bg-light p-2">
                    <Accordion>
                      <Accordion.Item eventKey="blackout_table">
                        <Accordion.Header>
                          <p>
                          Blackout Times{' '}
                          <span className="text-info fs--1 ms-3">
                            * click to expand
                          </span>
                            </p>
                        </Accordion.Header>
                        <Accordion.Body>
                          <Table bordered striped responsive className="text-center">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Start</th>
                                <th>End</th>
                              </tr>
                            </thead>
                            <tbody>
                              {creatBlackouts(
                                d.day.toLowerCase().slice(0, 3)
                              )?.map((v, i) => (
                                <tr key={i + 219} className='overflow-scroll'>
                                  <td>{i + 1}</td>
                                  <td>
                                    <div>
                                      <Field
                                        type="text"
                                        name={v.start_time}
                                        className="form-control"
                                      />
                                      <ErrorMessage
                                        name={v.start_time}
                                        component={'div'}
                                        className="text-danger fs--1 mt-2"
                                      />
                                    </div>
                                    <div className="d-flex justify-content-end mt-3">
                                      <label className="mx-3 ">
                                        <span className="h6">AM</span>{' '}
                                        <Field
                                          type="radio"
                                          name={v.start_zone}
                                          value="AM"
                                        />
                                      </label>
                                      <label className="mx-3">
                                        {' '}
                                        <span className="h6">PM</span>{' '}
                                        <Field
                                          type="radio"
                                          name={v.start_zone}
                                          value="PM"
                                        />
                                      </label>
                                      
                                    </div>
                                      <ErrorMessage
                                        name={v.start_zone}
                                        component={'div'}
                                        className="text-danger fs--1"
                                      />
                                  </td>
                                  <td>
                                    <div>
                                      <Field
                                        type="text"
                                        name={v.end_time}
                                        className="form-control"
                                      />
                                      <ErrorMessage
                                        name={v.end_time}
                                        component={'div'}
                                        className="text-danger fs--1"
                                      />
                                    </div>
                                    <div className="d-flex justify-content-end mt-3">
                                      <label className="mx-3 ">
                                        <span className="h6">AM</span>{' '}
                                        <Field
                                          type="radio"
                                          name={v.end_zone}
                                          value="AM"
                                        />
                                      </label>
                                      <label className="mx-3">
                                        {' '}
                                        <span className="h6">PM</span>{' '}
                                        <Field
                                          type="radio"
                                          name={v.end_zone}
                                          value="PM"
                                        />
                                      </label>
                                      </div>
                                      <ErrorMessage
                                        name={v.end_zone}
                                        component={'div'}
                                        className="text-danger fs--1"
                                      />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))}
        </Row>
        {/* <Row>
          <Col>
            <div className="mb-3">
              <label>Amenity unavailable for certain days</label>
              <Field
                name="amenity_unavailable_for_timeslots"
                type="date"
                className="form-control"
              />
              <ErrorMessage
                name="amenity_unavailable_for_timeslots"
                component={'div'}
                className="text-danger fs--1"
              />
            </div>
          </Col>
        </Row> */}
      </>
    );
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
}
