import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Form, Nav, ProgressBar } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import AccountForm from './AccountForm';
import EmailVerify from './EmailVerify';

import CompleteProfile from './CompleteProfile';
import Success from './Success';
import AppContext, { AuthWizardContext } from '../../../../context/Context';
import WizardModal from './WizardModal';
import Flex from '../../../../components/common/Flex';
// import axios from 'axios';
import Address from './Address';

const WizardLayout = ({ variant, validation, progressBar }) => {
  // loader
  const [loader, setLoader] = useState(false);

  const { isRTL } = useContext(AppContext);
  const { user, setUser, step, setStep } = useContext(AuthWizardContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
    clearErrors
  } = useForm();

  const [modal, setModal] = useState(false);

  const navItems = [
    {
      icon: 'user',
      label: 'Account'
    },
    {
      icon: 'lock',
      label: 'Verifications'
    },
    {
      icon: 'user',
      label: 'Company Profile'
    },
    {
      icon: 'home',
      label: 'Address'
    },
    {
      icon: 'thumbs-up',
      label: 'Done'
    }
  ];

  const onSubmitData = async data => {
    setLoader(true);
    // setUser({ ...user, ...data });
    //setStep(step + 1);
    // if (step == 1) {
    //   const config = {
    //     header: {
    //       'Content-Type': 'application/json'
    //     }
    //   };

    //   try {
    //     const data1 = await axios.post(
    //       '/api/auth/emailverify',

    //       {
    //         data
    //       },
    //       config
    //     );

    //     setTimeout(() => {
    //       setLoader(false);
    //       if (data1.data.Check == 'Verified') {
    //         setStep(step + 2);
    //       } else if (data1.data.Check == 'Pending') {
    //         setStep(step + 1);
    //       } else {
    //         setStep(step + 1);
    //       }
    //     }, 1000);
    //   } catch (error) {
    //     setTimeout(() => {
    //       setLoader(false);
    //       toast.error(`Email already registered`, {
    //         theme: 'colored'
    //       });
    //       // console.log('Error ' + error);
    //     }, 1000);
    //   }
    // }

    // if (step == 2) {
    //   const otpPass = data.verifyCode;
    //   const email = data.email;
    //   const config = {
    //     header: {
    //       'Content-Type': 'application/json'
    //     }
    //   };
    //   try {
    //     const { data } = await axios.post(
    //       '/api/auth/VerifyCompany',
    //       { otpPass, email },
    //       config
    //     );
    //     if (data) {
    //       //do nothing
    //     }
    //     //localStorage.setItem("authToken",data.token);
    //     setTimeout(() => {
    //       setLoader(false);
    //       toast.success(`Verified as ${email}`, {
    //         theme: 'colored'
    //       });

    //       setStep(step + 1);
    //     }, 1000);
    //   } catch (error) {
    //     setTimeout(() => {
    //       setLoader(false);
    //       toast.error(`Invalid OTP : ${email}`, {
    //         theme: 'colored'
    //       });
    //       // console.log('Error : ' + error);
    //     }, 1000);
    //   }
    // }

    // if (step == 3) {
    //   // data.siteName = data.siteName + '@condozak.app';
    //   // console.log(data);
    //   // return;
    //   const config = {
    //     header: {
    //       'Content-Type': 'application/json'
    //     }
    //   };

    //   try {
    //     const { datab } = await axios.post(
    //       '/api/auth/companyreg',

    //       {
    //         data
    //       },
    //       config
    //     );
    //     if (datab) {
    //       //do nothing
    //     }
    //     setTimeout(() => {
    //       setLoader(false);
    //       setStep(step + 1);
    //     }, 1000);
    //   } catch (error) {
    //     ///setStep(step - 1);
    //     setTimeout(() => {
    //       setLoader(false);

    //       toast.error(`Try Again : ${error.response.data.error}`, {
    //         theme: 'colored'
    //       });
    //     }, 1000);
    //   }
    // }
  };
  const onError = () => {
    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
  };

  const toggle = () => setModal(!modal);

  const handleNavs = targetStep => {
    if (step !== 5) {
      if (targetStep < step) {
        if (step == 3 && targetStep == 2) {
          setStep(targetStep - 1);
        } else {
          setStep(targetStep);
        }
      } else {
        // handleSubmit(onSubmitData, onError)();
      }
    } else {
      toggle();
    }
  };

  return (
    <>
      <WizardModal modal={modal} setModal={setModal} />
      <Card
        // as={Form}
        // noValidate
        // onSubmit={handleSubmit(onSubmitData, onError)}
        className="theme-wizard"
      >
        <Card.Header
          className={classNames('bg-light', {
            'px-4 py-3': variant === 'pills',
            'pb-2': !variant
          })}
        >
          <Nav className="justify-content-center" variant={variant}>
            {variant === 'pills'
              ? navItems.map((item, index) => (
                  <NavItemPill
                    key={item.label}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))
              : navItems.map((item, index) => (
                  <NavItem
                    key={item.label}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))}
          </Nav>
        </Card.Header>
        {progressBar && <ProgressBar now={step * 20} style={{ height: 2 }} />}
        <Card.Body className="fw-normal px-md-6 py-4">
          {step === 1 && <AccountForm />}
          {step === 2 && <EmailVerify />}
          {step === 3 && <CompleteProfile />}
          {step === 4 && <Address />}
          {step === 5 && <Success reset={reset} />}
        </Card.Body>

        {/* <Card.Footer
          className={classNames('px-md-6 bg-light', {
            'd-none': step === 4,
            ' d-flex': step < 4
          })}
        >
          <IconButton
            variant="link"
            icon={isRTL ? 'chevron-right' : 'chevron-left'}
            iconAlign="left"
            transform="down-1 shrink-4"
            className={classNames('px-0 fw-semi-bold', {
              'd-none': step === 1
            })}
            onClick={() => {
              if (step == 3) {
                setStep(step - 2);
              } else {
                setStep(step - 1);
              }
            }}
          >
            Prev
          </IconButton>

          <IconButton
            variant="primary"
            className="ms-auto px-5"
            type="submit"
            icon={isRTL ? 'chevron-left' : 'chevron-right'}
            iconAlign="right"
            transform="down-1 shrink-4"
          >
            {loader ? (
              <RotatingLines
                strokeColor="white"
                strokeWidth="5"
                animationDuration="0.75"
                width="25"
                visible={true}
              />
            ) : (
              'Next'
            )}
          </IconButton>
        </Card.Footer> */}
      </Card>
    </>
  );
};

const NavItem = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: index < 5 ? step > index : step > 4,
          active: step === index
        })}
        // onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs--1">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

const NavItemPill = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: step > index,
          active: step === index
        })}
        // onClick={() => handleNavs(index)}
      >
        <Flex alignItems="center" justifyContent="center">
          <FontAwesomeIcon icon={icon} />
          <span className="d-none d-md-block mt-1 fs--1 ms-2">{label}</span>
        </Flex>
      </Nav.Link>
    </Nav.Item>
  );
};

WizardLayout.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool
};

NavItemPill.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  handleNavs: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

NavItem.propTypes = NavItemPill.propTypes;

export default WizardLayout;
