export default [
  {
    id: 39,
    name: "Canada",
    iso3: "CAN",
    iso2: "CA",
    phone_code: "1",
    capital: "Ottawa",
    currency: "CAD",
    native: "Canada",
    region: "Americas",
    subregion: "Northern America",
    timezones: [
      {
        zoneName: "America/Atikokan",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America)",
      },
      {
        zoneName: "America/Blanc-Sablon",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
      {
        zoneName: "America/Cambridge_Bay",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America)",
      },
      {
        zoneName: "America/Creston",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America)",
      },
      {
        zoneName: "America/Dawson",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America)",
      },
      {
        zoneName: "America/Dawson_Creek",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America)",
      },
      {
        zoneName: "America/Edmonton",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America)",
      },
      {
        zoneName: "America/Fort_Nelson",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America)",
      },
      {
        zoneName: "America/Glace_Bay",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
      {
        zoneName: "America/Goose_Bay",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
      {
        zoneName: "America/Halifax",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
      {
        zoneName: "America/Inuvik",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America",
      },
      {
        zoneName: "America/Iqaluit",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Moncton",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
      {
        zoneName: "America/Nipigon",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Pangnirtung",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Rainy_River",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Rankin_Inlet",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Regina",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Resolute",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/St_Johns",
        gmtOffset: -12600,
        gmtOffsetName: "UTC-03:30",
        abbreviation: "NST",
        tzName: "Newfoundland Standard Time",
      },
      {
        zoneName: "America/Swift_Current",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Thunder_Bay",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Toronto",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Vancouver",
        gmtOffset: -28800,
        gmtOffsetName: "UTC-08:00",
        abbreviation: "PST",
        tzName: "Pacific Standard Time (North America",
      },
      {
        zoneName: "America/Whitehorse",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America",
      },
      {
        zoneName: "America/Winnipeg",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Yellowknife",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America",
      },
    ],
    emoji: "🇨🇦",
    emojiU: "U+1F1E8 U+1F1E6",
  },
  {
    "id": 233,
    "name": "United States",
    "iso3": "USA",
    "iso2": "US",
    "phone_code": "1",
    "capital": "Washington",
    "currency": "USD",
    "native": "United States",
    "region": "Americas",
    "subregion": "Northern America",
    "timezones": [
        {
            "zoneName": "America\/Adak",
            "gmtOffset": -36000,
            "gmtOffsetName": "UTC-10:00",
            "abbreviation": "HST",
            "tzName": "Hawaii–Aleutian Standard Time"
        },
        {
            "zoneName": "America\/Anchorage",
            "gmtOffset": -32400,
            "gmtOffsetName": "UTC-09:00",
            "abbreviation": "AKST",
            "tzName": "Alaska Standard Time"
        },
        {
            "zoneName": "America\/Boise",
            "gmtOffset": -25200,
            "gmtOffsetName": "UTC-07:00",
            "abbreviation": "MST",
            "tzName": "Mountain Standard Time (North America"
        },
        {
            "zoneName": "America\/Chicago",
            "gmtOffset": -21600,
            "gmtOffsetName": "UTC-06:00",
            "abbreviation": "CST",
            "tzName": "Central Standard Time (North America"
        },
        {
            "zoneName": "America\/Denver",
            "gmtOffset": -25200,
            "gmtOffsetName": "UTC-07:00",
            "abbreviation": "MST",
            "tzName": "Mountain Standard Time (North America"
        },
        {
            "zoneName": "America\/Detroit",
            "gmtOffset": -18000,
            "gmtOffsetName": "UTC-05:00",
            "abbreviation": "EST",
            "tzName": "Eastern Standard Time (North America"
        },
        {
            "zoneName": "America\/Indiana\/Indianapolis",
            "gmtOffset": -18000,
            "gmtOffsetName": "UTC-05:00",
            "abbreviation": "EST",
            "tzName": "Eastern Standard Time (North America"
        },
        {
            "zoneName": "America\/Indiana\/Knox",
            "gmtOffset": -21600,
            "gmtOffsetName": "UTC-06:00",
            "abbreviation": "CST",
            "tzName": "Central Standard Time (North America"
        },
        {
            "zoneName": "America\/Indiana\/Marengo",
            "gmtOffset": -18000,
            "gmtOffsetName": "UTC-05:00",
            "abbreviation": "EST",
            "tzName": "Eastern Standard Time (North America"
        },
        {
            "zoneName": "America\/Indiana\/Petersburg",
            "gmtOffset": -18000,
            "gmtOffsetName": "UTC-05:00",
            "abbreviation": "EST",
            "tzName": "Eastern Standard Time (North America"
        },
        {
            "zoneName": "America\/Indiana\/Tell_City",
            "gmtOffset": -21600,
            "gmtOffsetName": "UTC-06:00",
            "abbreviation": "CST",
            "tzName": "Central Standard Time (North America"
        },
        {
            "zoneName": "America\/Indiana\/Vevay",
            "gmtOffset": -18000,
            "gmtOffsetName": "UTC-05:00",
            "abbreviation": "EST",
            "tzName": "Eastern Standard Time (North America"
        },
        {
            "zoneName": "America\/Indiana\/Vincennes",
            "gmtOffset": -18000,
            "gmtOffsetName": "UTC-05:00",
            "abbreviation": "EST",
            "tzName": "Eastern Standard Time (North America"
        },
        {
            "zoneName": "America\/Indiana\/Winamac",
            "gmtOffset": -18000,
            "gmtOffsetName": "UTC-05:00",
            "abbreviation": "EST",
            "tzName": "Eastern Standard Time (North America"
        },
        {
            "zoneName": "America\/Juneau",
            "gmtOffset": -32400,
            "gmtOffsetName": "UTC-09:00",
            "abbreviation": "AKST",
            "tzName": "Alaska Standard Time"
        },
        {
            "zoneName": "America\/Kentucky\/Louisville",
            "gmtOffset": -18000,
            "gmtOffsetName": "UTC-05:00",
            "abbreviation": "EST",
            "tzName": "Eastern Standard Time (North America"
        },
        {
            "zoneName": "America\/Kentucky\/Monticello",
            "gmtOffset": -18000,
            "gmtOffsetName": "UTC-05:00",
            "abbreviation": "EST",
            "tzName": "Eastern Standard Time (North America"
        },
        {
            "zoneName": "America\/Los_Angeles",
            "gmtOffset": -28800,
            "gmtOffsetName": "UTC-08:00",
            "abbreviation": "PST",
            "tzName": "Pacific Standard Time (North America"
        },
        {
            "zoneName": "America\/Menominee",
            "gmtOffset": -21600,
            "gmtOffsetName": "UTC-06:00",
            "abbreviation": "CST",
            "tzName": "Central Standard Time (North America"
        },
        {
            "zoneName": "America\/Metlakatla",
            "gmtOffset": -32400,
            "gmtOffsetName": "UTC-09:00",
            "abbreviation": "AKST",
            "tzName": "Alaska Standard Time"
        },
        {
            "zoneName": "America\/New_York",
            "gmtOffset": -18000,
            "gmtOffsetName": "UTC-05:00",
            "abbreviation": "EST",
            "tzName": "Eastern Standard Time (North America"
        },
        {
            "zoneName": "America\/Nome",
            "gmtOffset": -32400,
            "gmtOffsetName": "UTC-09:00",
            "abbreviation": "AKST",
            "tzName": "Alaska Standard Time"
        },
        {
            "zoneName": "America\/North_Dakota\/Beulah",
            "gmtOffset": -21600,
            "gmtOffsetName": "UTC-06:00",
            "abbreviation": "CST",
            "tzName": "Central Standard Time (North America"
        },
        {
            "zoneName": "America\/North_Dakota\/Center",
            "gmtOffset": -21600,
            "gmtOffsetName": "UTC-06:00",
            "abbreviation": "CST",
            "tzName": "Central Standard Time (North America"
        },
        {
            "zoneName": "America\/North_Dakota\/New_Salem",
            "gmtOffset": -21600,
            "gmtOffsetName": "UTC-06:00",
            "abbreviation": "CST",
            "tzName": "Central Standard Time (North America"
        },
        {
            "zoneName": "America\/Phoenix",
            "gmtOffset": -25200,
            "gmtOffsetName": "UTC-07:00",
            "abbreviation": "MST",
            "tzName": "Mountain Standard Time (North America"
        },
        {
            "zoneName": "America\/Sitka",
            "gmtOffset": -32400,
            "gmtOffsetName": "UTC-09:00",
            "abbreviation": "AKST",
            "tzName": "Alaska Standard Time"
        },
        {
            "zoneName": "America\/Yakutat",
            "gmtOffset": -32400,
            "gmtOffsetName": "UTC-09:00",
            "abbreviation": "AKST",
            "tzName": "Alaska Standard Time"
        },
        {
            "zoneName": "Pacific\/Honolulu",
            "gmtOffset": -36000,
            "gmtOffsetName": "UTC-10:00",
            "abbreviation": "HST",
            "tzName": "Hawaii–Aleutian Standard Time"
        }
    ],
    "emoji": "🇺🇸",
    "emojiU": "U+1F1FA U+1F1F8"
},
];
