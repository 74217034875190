import React from "react";

import axios from "axios";

import { flatten } from "lodash";

import { Button, Card, Col, Row } from "react-bootstrap";

import Flex from "../../../components/common/Flex";
//import { toast } from "react-toastify";

// import Cookies from "universal-cookie";

import { formatLogInfo, getHostName } from "../../../helpers/utils";

import Loader from "../../../components/common/Loader";

import PaginateComponentCustom from "../../../components/common/PaginateComponentCustom";
import CustomBreadCrumbs from "../../../components/common/CustomBreadCrumbs";

import UserTable from "./UserTable";

// import InviteUserModal from "./InviteUserModal";
// import DeleteInvitedUser from "./DeleteInvitedUser";

// import ChangeGroupModal from "./ChangeGroup";

// import GrantUserAccessModal from "./GrantUserAccess";
// import RevokeUserAccessModal from "./RevokeUserAccess";

import UserDetailsComponent from "./userDetails/UserDetailsComponent";

import CustomFilterbar from "../../../components/common/CustomFilterbar";
import CustomSearchbar from "../../../components/common/CustomSearchbar";

import UsersManagementModel from "../sharedUI/UsersManagementModel";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";

const Users = () => {
  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const hostname = getHostName();

  const [usersList, setUsersList] = React.useState([]);
  const [usersListTmp, setUsersListTemp] = React.useState([...usersList]);

  const [headerData, setHeaderData] = React.useState({
    adminCount: 0,
    usersCount: 0,
    activeCount: 0,
  });

  // UsersManagementModel

  const [showUsersManagementModel, setShowUsersManagementModel] =
    React.useState(false);
  const handleCloseUsersManagementModel = () =>
    setShowUsersManagementModel(false);
  const handleShowUsersManagementModel = () =>
    setShowUsersManagementModel(true);

  // loadType
  const [payload, setPayload] = React.useState({});

  // user detail tab
  const [showUserDetailTab, setShowUserDetailTab] = React.useState(false);
  const handleCloseUserDetailTab = () => setShowUserDetailTab(false);
  const handleShowUserDetailTab = () => setShowUserDetailTab(true);

  //hange  group modal
  const [showChangeGroupDetailModal, setShowChangeGroupDetailModal] =
    React.useState(false);
  const handleCloseChangeGroupDetailModal = () =>
    setShowChangeGroupDetailModal(false);
  const handleShowChangeGroupDetailModal = () =>
    setShowChangeGroupDetailModal(true);

  //

  // const [loader, setLoader] = React.useState(false);
  const [userForGroup, setUserForGroup] = React.useState("");

  const [loader, setLoader] = React.useState(false);

  React.useEffect(() => {
    setLoader(true);
    getUsersData();
  }, []);

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  const getUsersData = () => {
    let compid = userData.compid;

    if (compid) {
      axios
        .post(
          `${hostname}/api/auth/RoleTable`,
          {
            c_id: compid,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          //console.log(res.data);

          let usersTmpArry = [
            flatten(res.data.data),
            flatten(res.data.invited),
          ];
          usersTmpArry = flatten(usersTmpArry);

          usersTmpArry = usersTmpArry.map((user) => {
            let tmp = {};

            if (user.verified === undefined) {
              if (user.Status === "1") {
                tmp = Object.assign(
                  {
                    status: {
                      title: "Invited",
                      type: "info",
                      icon: "redo",
                    },
                    c_id: compid,
                  },
                  user
                );
              }
              if (user.Status === "0") {
                // console.log(user);
                tmp = Object.assign(
                  {
                    status: {
                      title: "Ignored",
                      type: "secondary",
                      icon: "redo",
                    },
                    c_id: compid,
                  },
                  user
                );
                // console.log(tmp);
                // }
              }

              // tmp = Object.assign(
              //   {
              //     status: { title: 'Invited', type: 'info', icon: 'check' },
              //     c_id: compid
              //   },
              //   user
              // );
            }

            if (user.verified === "Verified") {
              // if (user.Status !== undefined) {
              if (user.Status === "1") {
                tmp = Object.assign(
                  {
                    status: {
                      title: "Active",
                      type: "success",
                      icon: "check",
                    },
                    c_id: compid,
                  },
                  user
                );
              }

              if (user.Status === "0") {
                tmp = Object.assign(
                  {
                    status: {
                      title: "Revoked",
                      type: "danger",
                      icon: "ban",
                    },
                    c_id: compid,
                  },
                  user
                );
                // }
              } else {
                tmp = Object.assign(
                  {
                    status: { title: "Active", type: "success", icon: "check" },
                    c_id: compid,
                  },
                  user
                );
              }
            }

            return tmp;
          });

          // formatting log info
          usersTmpArry = usersTmpArry.map((user) => {
            if (user.LogInfo !== undefined) {
              let LogInfo = formatLogInfo(user.LogInfo);
              delete user.LogInfo;
              let obj = Object.assign({ LogInfo }, user);
              return obj;
            }
            return user;
          });

          // making loggedinuser flag
          usersTmpArry = usersTmpArry.map((user) => {
            let obj = {};
            if (user.user_id === userData.data) {
              obj = Object.assign({ isLoggedInUser: true }, user);
            } else {
              obj = Object.assign({ isLoggedInUser: false }, user);
            }
            return obj;
          });

          let admins = 0;
          let users = 0;
          let actives = 0;
          usersTmpArry?.forEach((v) => {
            admins +=
              v.groups?.filter((group) => group === "Site-Administrators")
                .length && v.status.title === "Active";
          });
          users = usersTmpArry.length;
          actives = usersTmpArry.filter(
            (v) => v.status.title === "Active"
          ).length;

          let hdata = {};

          hdata.adminCount = admins;
          hdata.usersCount = users;
          hdata.activeCount = actives;

          setHeaderData({ ...hdata });

          localStorage.setItem("top", admins);

          usersTmpArry.sort(dynamicSort("email"));

          // console.log(usersT);
          // console.log(usersTmpArry);
          setUsersList(usersTmpArry);
          setUsersListTemp(usersTmpArry);
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  };

  const inviteUser = () => {
    let obj = {};
    obj.loadType = "Invite Users";
    setPayload(obj);
    handleShowUsersManagementModel();
  };

  const resendInvite = (data_user = {}) => {
    data_user.loadType = "Reinvite User";
    setPayload({ ...data_user });
    handleShowUsersManagementModel();
  };

  const deleteInvitedUser = (user) => {
    user.loadType = "Delete User";
    setPayload(user);
    handleShowUsersManagementModel();
  };

  const grantUserAccess = (user) => {
    user.loadType = "Grant Access";
    setPayload(user);
    handleShowUsersManagementModel();
  };

  const revokeUserAccess = (user) => {
    user.loadType = "Revoke Access";
    setPayload(user);
    handleShowUsersManagementModel();
  };

  const addUserToGroup = (user = {}, fromDetail = false) => {
    console.log(user);
    setUserForGroup({ ...user, fromDetail });
    if (fromDetail) handleShowChangeGroupDetailModal();
  };

  const changeUserGroup = (user) => {
    user.loadType = "Change Group";
    // console.log(user);
    setPayload(user);
    handleShowUsersManagementModel();
  };

  const showUserDetails = (user) => {
    user.loadType = "Change Group";
    setPayload(user);
    handleShowUserDetailTab();
  };

  if (loader) return <Loader />;
  else if (showUserDetailTab)
    return (
      <UserDetailsComponent
        handleCloseUserDetailTab={handleCloseUserDetailTab}
        currentUser={payload}
        addUserToGroup={addUserToGroup}
        showChangeGroupDetailModal={showChangeGroupDetailModal}
        handleCloseChangeGroupDetailModal={handleCloseChangeGroupDetailModal}
        userData={userData}
        getUsersData={getUsersData}
        userForGroup={userForGroup}
        payload={payload}
        setPayload={setPayload}
        hostname={hostname}
        jwtToken={jwtToken}
      />
    );
  else {
    return (
      <>
        <Row className="g-3 mb-3">
          <Col>
            <CustomBreadCrumbs
              links={[
                { path: "/dashboard", label: "Home" },
                { path: "", label: "Users" },
              ]}
            />
          </Col>
        </Row>
        <Card>
          <Card.Body className="" style={{ minHeight: "90vh" }}>
            <Flex
              justifyContent={"between"}
              alignItems={"start"}
              className="mb-3"
            >
              <h4>Users</h4>

              <Button variant="primary" size="sm" onClick={inviteUser}>
                Invite Users
              </Button>
            </Flex>

            <Row>
              <Col md="6">
                <Flex justifyContent={"between"} alignItems={"start"}>
                  <h6>Total Users</h6>
                  <h6>Active Users</h6>
                  <h6>Administrators</h6>
                </Flex>
                <Flex
                  justifyContent={"between"}
                  alignItems={"start"}
                  className="px-5"
                >
                  <h5 className="text-primary">
                    {headerData.usersCount || "0"}
                  </h5>
                  <h5 className="text-primary">
                    {headerData.activeCount || "0"}
                  </h5>
                  <h5 className="text-primary">
                    {headerData.adminCount || "0"}
                  </h5>
                </Flex>
              </Col>
            </Row>
            <Row className="flex-end-center mb-3 mt-3">
              <Col className=" d-flex justify-content-sm-end">
                <CustomFilterbar
                  filterFlags={[
                    "Status Active",
                    // "Status Disabled",
                    "Status Invited",
                    "Status Ignored",
                    "Status Revoked",
                    // "Role Admin",
                  ]}
                  dataListTemp={usersListTmp}
                  stateUpdator={setUsersList}
                  from="Users Table"
                />

                <CustomSearchbar
                  searchFlags={["firstname", "lastname", "email"]}
                  dataListTemp={usersListTmp}
                  stateUpdator={setUsersList}
                  placeholders={["name", " email"]}
                />
              </Col>
            </Row>

            <PaginateComponentCustom
              itemsPerPage={20}
              TableElement={UserTable}
              dataList={usersList}
              inviteUser={inviteUser}
              resendInvite={resendInvite}
              deleteInvitedUser={deleteInvitedUser}
              grantUserAccess={grantUserAccess}
              revokeUserAccess={revokeUserAccess}
              addUserToGroup={addUserToGroup}
              showUserDetails={showUserDetails}
              adminCount={headerData.adminCount}
              handleShowUserDetailTab={handleShowUserDetailTab}
              changeUserGroup={changeUserGroup}
              userData={userData}
              jwtToken={jwtToken}
              hostname={hostname}
            />

            <UsersManagementModel
              show={showUsersManagementModel}
              handleClose={handleCloseUsersManagementModel}
              userData={userData}
              getUsersData={getUsersData}
              payload={payload}
              setPayload={setPayload}
              hostname={hostname}
              jwtToken={jwtToken}
            />
          </Card.Body>
        </Card>
      </>
    );
  }
};

export default Users;
