// import FalconCardHeader from 'components/common/FalconCardHeader';
import React, { useContext } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import csc from "../../../../helpers/worldData";
// import Cookies from 'universal-cookie';
import axios from "axios";
import { AuthWizardContext } from "../../../../context/Context";

import { Formik, Field, ErrorMessage } from "formik";

import * as Yup from "yup";

const Address = ({ userData, hostname, jwtToken }) => {

  const { step, setStep, user, setUser } = useContext(AuthWizardContext);
  console.log(user);

  const handleSubmit = (values) => {
    console.log(values);

    let addressData = {};

    addressData.country_id = values.country;
    addressData.state_id = values.state;
    addressData.city_id = values.city;

    addressData.country = csc.getCountryById(+values.country).name;
    addressData.state = csc.getStateById(+values.state).name;
    addressData.city = csc.getCityById(+values.city).name;

    addressData.address = values.address;
    addressData.address2 = values.address2;
    addressData.zipcode = values.zipcode;

    addressData.phone = user.property_phone;

    console.log(addressData);

    setUser(() =>
      Object.assign(
        {
          ...addressData,
          user_id: userData.data,
          c_id: userData.compid,
        },
        user
      )
    );
    let alldata = {
      ...user,
      ...addressData,
      user_id: userData.data,
      c_id: userData.compid,
    };
    // console.log(alldata);
    // return;
    axios
      .post(`${hostname}/api/property/propertyregister`, {
        ...alldata,
      },{
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        }
      })
      .then((res) => {
        // console.log(res.data);
        toast.success(res.data.data, { theme: "colored" });
        setStep(step + 1);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.error, { theme: "colored" });
      });
  };

  const handleSetCountry = (e, setFieldValue) => {
    const { value } = e.target;

    setFieldValue("country", +value);
    setFieldValue("city", "");
  };

  return (
    <div className="d-flex justify-content-center">
      <div className="col-xl-8 col-lg-8 col-md-8">
        <Formik
          initialValues={{
            country: "",
            state: "",
            city: "",
            zipcode: "",
            address: "",
            address2: "",
          }}
          validationSchema={Yup.object({
            country: Yup.string().required("country is required!"),
            state: Yup.string().required("state is required!"),
            city: Yup.string().required("city is required!"),
            zipcode: Yup.string().required("zipcode is required!"),
            address: Yup.string().required("address is required!"),
            address2: Yup.string("address2 is text!"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            handleSubmit(values);
          }}
        >
          {({
            values,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={12} className="mb-3">
                  <label htmlFor="country">Country</label>
                  <Field
                    component="select"
                    id="countryProperty"
                    name="country"
                    className="form-select"
                    onChange={(e) => handleSetCountry(e, setFieldValue)}
                  >
                    <option>select</option>
                    {csc.getAllCountries()?.map((v, i) => (
                      <option key={v.id} value={v.id}>
                        {v.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="country"
                    className="text-danger fs--1"
                    component={"div"}
                  />
                </Col>
                <Col md={12}>
                  <label htmlFor="state">State/Province</label>
                  <Field
                    component="select"
                    id="stateProperty"
                    name="state"
                    className="form-select"
                    // multiple={true}
                  >
                    <option value="">select</option>
                    {csc.getStatesOfCountry(+values.country)?.map((v, i) => (
                      <option key={v.id} value={v.id}>
                        {v.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="state"
                    className="text-danger fs--1"
                    component={"div"}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12} className="mb-3">
                  <label htmlFor="city">City</label>
                  <Field
                    component="select"
                    id="cityProperty"
                    name="city"
                    className="form-select"
                    // multiple={true}
                  >
                    <option value="">select</option>
                    {csc.getCitiesOfState(+values.state)?.map((v, i) => (
                      <option key={v.id} value={v.id}>
                        {v.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="city"
                    className="text-danger fs--1"
                    component={"div"}
                  />
                </Col>
                <Col md={12}>
                  <label htmlFor="zipcode">Zip/Postal Code</label>
                  <Field
                    name="zipcode"
                    className="form-control"
                    placeholder="enter zipcode"
                  />
                  <ErrorMessage
                    name="zipcode"
                    className="text-danger fs--1"
                    component={"div"}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12} className="mb-3">
                  <label htmlFor="address">Address</label>
                  <Field
                    name="address"
                    className="form-control"
                    placeholder="enter address"
                  />
                  <ErrorMessage
                    name="address"
                    className="text-danger fs--1"
                    component={"div"}
                  />
                </Col>
                <Col md={12} className="mb-3">
                  <label htmlFor="address2">Address 2 (if any)</label>
                  <Field
                    name="address2"
                    className="form-control"
                    placeholder="enter 2nd address here"
                  />
                  <ErrorMessage
                    name="address2"
                    className="text-danger fs--1"
                    component={"div"}
                  />
                </Col>
              </Row>
              <div className="">
                <Button
                  variant="secondary"
                  type="button"
                  onClick={() => setStep(step - 1)}
                >
                  Previous
                </Button>
                <Button
                  variant="primary"
                  className="float-end"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Next
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Address;
