import React, { useContext, useState } from "react";

import { Card, Form, Nav, ProgressBar } from "react-bootstrap";

import classNames from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


import Success from "./Success";

import VisitorDetails from "./VisitorDetails";
import VisitorBookingDetails from "./VisitorBookingDetails";
import VisitorReviewDetails from "./VisitorReviewDetails";

import AppContext, { AuthWizardContext } from "../../../../../../context/Context";

import WizardModal from "./WizardModal";
import Flex from "../../../../../../components/common/Flex";

const WizardLayout = ({
  variant,
  validation,
  progressBar,
  handleNewVisitor,
  
}) => {
  // loader

  const { user, setUser, step, setStep } = useContext(AuthWizardContext);

  const [modal, setModal] = useState(false);

  const navItems = [
    {
      icon: "book",
      label: "Visitor Details",
    },
    {
      icon: "book",
      label: "Booking Details",
    },
    {
      icon: "thumbs-up",
      label: " Done",
    },
  ];


  const toggle = () => setModal(!modal);

  const handleNavs = (targetStep) => {
    if (step !== 4) {
      if (targetStep < step) {
        if (step == 3 && targetStep == 2) {
          setStep(targetStep - 1);
        } else {
          setStep(targetStep);
        }
      } else {
        // handleSubmit(onSubmitData, onError)();
      }
    } else {
      toggle();
    }
  };

  return (
    <>
      <WizardModal modal={modal} setModal={setModal} />
      <Card
        // as={Form}
        // noValidate
        // onSubmit={handleSubmit(onSubmitData, onError)}
        className="theme-wizard"
      >
        <Card.Header
          className={classNames("bg-light", {
            "px-4 py-3": variant === "pills",
            "pb-2": !variant,
          })}
        >
          <Nav className="justify-content-center" variant={variant}>
            {variant === "pills"
              ? navItems.map((item, index) => (
                  <NavItemPill
                    key={item.label}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))
              : navItems.map((item, index) => (
                  <NavItem
                    key={item.label}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))}
          </Nav>
        </Card.Header>
        {progressBar && <ProgressBar now={step * 25} style={{ height: 2 }} />}
        <Card.Body className="fw-normal px-md-6 py-4">
          {step === 1 && <VisitorDetails />}
          {step === 2 && <VisitorBookingDetails />}
          {step === 3 && (
            <VisitorReviewDetails handleNewVisitor={handleNewVisitor} />
          )}

          {step === 4 && (
            <Success
             // reset={reset}
              // handleClose={handleClose}
            />
          )}
        </Card.Body>

        {/* <Card.Footer
          className={classNames('px-md-6 bg-light', {
            'd-none': step === 4,
            ' d-flex': step < 4
          })}
        >
          <IconButton
            variant="link"
            icon={isRTL ? 'chevron-right' : 'chevron-left'}
            iconAlign="left"
            transform="down-1 shrink-4"
            className={classNames('px-0 fw-semi-bold', {
              'd-none': step === 1
            })}
            onClick={() => {
              if (step == 3) {
                setStep(step - 2);
              } else {
                setStep(step - 1);
              }
            }}
          >
            Prev
          </IconButton>

          <IconButton
            variant="primary"
            className="ms-auto px-5"
            type="submit"
            icon={isRTL ? 'chevron-left' : 'chevron-right'}
            iconAlign="right"
            transform="down-1 shrink-4"
          >
            {loader ? (
              <RotatingLines
                strokeColor="white"
                strokeWidth="5"
                animationDuration="0.75"
                width="25"
                visible={true}
              />
            ) : (
              'Next'
            )}
          </IconButton>
        </Card.Footer> */}
      </Card>
    </>
  );
};

const NavItem = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames("fw-semi-bold", {
          done: index < 5 ? step > index : step > 4,
          active: step === index,
        })}
        // onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs--1">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

const NavItemPill = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames("fw-semi-bold", {
          done: step > index,
          active: step === index,
        })}
        // onClick={() => handleNavs(index)}
      >
        <Flex alignItems="center" justifyContent="center">
          <FontAwesomeIcon icon={icon} />
          <span className="d-none d-md-block mt-1 fs--1 ms-2">{label}</span>
        </Flex>
      </Nav.Link>
    </Nav.Item>
  );
};

export default WizardLayout;
