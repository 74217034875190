import FalconCardHeader from "../../../../../components/common/FalconCardHeader";
import React from "react";
import { Card } from "react-bootstrap";

export default function EventDetailDescription({ event = {} }) {
  return (
    <Card className="mb-3">
      <FalconCardHeader title='Description'/>
      <Card.Body style={{ minHeight: "300px" }} className="bg-light">
        <h5 className="fs-0 mb-3">{event.event_name}</h5>
        <p style={{ whiteSpace: "pre-line" }}>{event.event_description}</p>
      </Card.Body>
    </Card>
  );
}
