import React from "react";
import VisitorParkingPermit from "../../../../../../../components/pages/Features/VisitorParkingPermit/VisitorParkingPermit";

export default function UnitVisitorParking() {
  return (
    <>
      <VisitorParkingPermit from='singleUnit'/>
    </>
  );
}
