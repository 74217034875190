import FalconCardHeader from "../../../../../components/common/FalconCardHeader";
import React from "react";
import { Card, Button } from "react-bootstrap";
import Loader from "../../../../../components/common/Loader";
export default function PropertyManager({
  // managerProfile = [],
  propertyProfile,
  updatePropertyManager,

  managersList,
  currentManagerID,
  setNewManager,
  // newManager,
  userData,
}) {
  const [pmTmp, setPmTmp] = React.useState({});

  const [loader, setLoader] = React.useState(false);

  const setInitialData = () => {
    setLoader(true);
    let pm = managersList?.filter((v) => v._id === currentManagerID)[0];
    // console.log(pm);
    setPmTmp(pm);
    setNewManager({ ...pm });
    setLoader(false);
  };

  React.useEffect(() => {
    setInitialData();
  }, []);

  // console.log(managerProfile);
  // console.log(typeof managerProfile);
  // const { property_manager } = propertyProfile;
  // console.log(property_manager);
  // console.log(propertyProfile);

  // let pm = managerProfile?.filter((v) => v._id === property_manager)[0];
  // console.log(pm);

  // if (pm === undefined)
  //   return (
  //     <div>
  //       <Card style={{ minHeight: "320px" }}>
  //         <FalconCardHeader title="Property Manager" />
  //         <Card.Body className="bg-light">
  //           {/* <h5>{`${pm.firstname} ${pm.lastname || ""}`}</h5> */}
  //           <br />
  //           <p className="text-justify">
  //             This Property manager is responsiable for all the matters related
  //             to{" "}
  //             <span className="text-primary">
  //               {propertyProfile.property_name || "Green Front Holdings."}
  //             </span>{" "}
  //           </p>

  //           <br />
  //           <Button
  //             variant="falcon-info"
  //             className="w-100"
  //             onClick={() => updatePropertyManager(pm)}
  //           >
  //             Add Manager
  //           </Button>
  //         </Card.Body>
  //       </Card>
  //     </div>
  //   );

  return (
    <div>
      <Card style={{ minHeight: "320px" }}>
        <FalconCardHeader title="Property Manager" />
        {loader ? (
          <Loader noHeight />
        ) : (
          <Card.Body className="bg-light">
            {pmTmp !== undefined ? (
              <h5>{`${pmTmp?.firstname} ${pmTmp?.lastname || ""}`}</h5>
            ) : (
              ""
            )}
            <br />
            <p className="text-justify">
              This Property manager is responsible for all the matters related
              to{" "}
              <span className="text-primary">
                {propertyProfile.property_name || "Green Front Holdings."}
              </span>{" "}
            </p>

            <br />
            {(userData.role === "Site-Administrators" ||
              userData.role === "Condozak-Basic-Users") && (
              <Button
                variant={
                  pmTmp !== undefined ? "falcon-warning" : "falcon-default"
                }
                className="w-100"
                onClick={() => updatePropertyManager(pmTmp)}
              >
                {pmTmp !== undefined ? "Transfer Property" : "Add Manager"}
              </Button>
            )}
          </Card.Body>
        )}
      </Card>
    </div>
  );
}
