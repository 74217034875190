import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "../../../../../components/common/IconButton";
import React, { useState } from "react";
import {
  Button,
  Card,
  Form,
  FormControl,
  InputGroup,
  Table,
} from "react-bootstrap";
// import PropTypes from 'prop-types';
import SimpleBarReact from "simplebar-react";

const TicketRow = ({
  name,
  price,
  checked = false,
  id,
  handleChange,
  handleRemove,
  propLength,
  priceError,
}) => {
  return (
    <tr>
      <td>
        <Form.Control
          size="sm"
          type="text"
          placeholder="Option Name"
          value={name}
          onChange={({ target }) => handleChange(id, "name", target.value)}
        />
        <div className="text-danger fs--1">{priceError?.name}</div>
      </td>
      <td>
        <InputGroup size="sm">
          <InputGroup.Text className="d-none d-sm-block">$</InputGroup.Text>
          <FormControl
            type="number"
            placeholder="Price"
            value={price}
            aria-label="Dollar amount (with dot and two decimal places)"
            step="0.01"
            onChange={({ target }) => handleChange(id, "price", target.value)}
          />
        </InputGroup>
        <div className="text-danger fs--1">{priceError?.price}</div>
      </td>
      <td className="text-center align-middle">
        <Form.Check
          type="radio"
          id={`ticketChecked${id + 1}`}
          checked={checked}
          onChange={({ target }) => handleChange(id, "checked", target.checked)}
        />
      </td>
      {propLength > 1 && (
        <td className="text-center align-middle">
          <Button
            variant="link"
            size="sm"
            disabled={propLength < 2}
            onClick={() => handleRemove(id)}
          >
            <FontAwesomeIcon className="text-danger" icon="times-circle" />
          </Button>
        </td>
      )}
    </tr>
  );
};

const TicketsPriceTable = ({ tickets = [], setTickets, priceErrors }) => {
  // Change Ticket
  const changeTicket = (id, name, value) => {
    const updatedTickets =
      name === "checked"
        ? tickets.map((ticket) => ({ ...ticket, checked: false }))
        : [...tickets];
    const updatedTicket = { ...tickets[id], [name]: value };

    setTickets([
      ...updatedTickets.slice(0, id),
      updatedTicket,
      ...updatedTickets.slice(id + 1),
    ]);
  };

  // Remove Ticket
  const removeTicket = (id) =>
    setTickets([...tickets.slice(0, id), ...tickets.slice(id + 1)]);

  const handleAddTicket = () => {
    setTickets([...tickets, { name: "", price: 0 }]);
  };

  return (
    <div className="mb-3">
      <SimpleBarReact>
        <Table className="bg-white mb-2 dark__bg-1100 mt-3" bordered>
          <thead>
            <tr className="fs--1">
              <th scope="col">Option Name</th>
              <th scope="col">Price</th>
              <th scope="col">Default</th>
              {tickets.length > 1 && <th></th>}
            </tr>
          </thead>
          <tbody className="event-ticket-body">
            {tickets.map((item, index) => (
              <TicketRow
                {...item}
                id={index}
                key={index}
                handleChange={changeTicket}
                handleRemove={removeTicket}
                propLength={tickets.length}
                priceError={priceErrors[index]}
              />
            ))}
          </tbody>
        </Table>
      </SimpleBarReact>
      <div className="mt-3"></div>
      <IconButton
        onClick={handleAddTicket}
        variant="falcon-default"
        size="sm"
        icon="plus"
        transform="shrink-3"
      >
        Add Item
      </IconButton>
    </div>
  );
};

// TicketRow.propTypes = {
//   name: PropTypes.string.isRequired,
//   price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
//   checked: PropTypes.bool,
//   id: PropTypes.number.isRequired,
//   handleRemove: PropTypes.func.isRequired,
//   handleChange: PropTypes.func.isRequired
// };

export default TicketsPriceTable;
