import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SoftBadge from "../../../../components/common/SoftBadge";
import { Badge, Table, Dropdown } from "react-bootstrap";
// import { Link } from "react-router-dom";
import CardDropdown from "../../../../components/common/CardDropdown";
import { formatLogInfo } from "../../../../helpers/utils";

import "./UnitOwnersPortalTable.css";
import { startCase } from "lodash";
import { Avatar } from "@mui/material";
import { toast } from "react-toastify";

import axios from "axios";
const TableRow = ({
  data,
  propLength,
  reinviteUser,
  changeOwner,
  deactivateUser,
  activateUser,
  handleShowOwnersDetailTab,
  deleteInvitedUser,
  userData,
  jwtToken,
  hostname,
}) => {
  try {
    // console.log(data.role);

    const handleCopyInviteLink = (data) => {
      console.log(data);

      axios
        .post(
          `${hostname}/api/property/getResidentlink`,
          {
            email: data.email,
            unit_id: userData.UnitsOwner[0]._id,
            group: data.group,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          let textToCopy = res.data.data || "sample text";

          navigator.clipboard.writeText(textToCopy);
          toast.success("Link copied to clipboard.");
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong!");
        });
    };

    return (
      <tr
        className="align-middle"
        style={{ height: propLength === 1 ? "100px" : "" }}
      >
        <td className="text-nowrap">
          <div className="d-flex align-items-center">
            <div className="me-2">
              {/* <ImageUnit
                src={data?.avatar}
                firstname={data?.firstname || "First Name"}
                lastname={data?.lastname || "Last Name"}
                bgColor={data?.bgColor}
              /> */}
              <Avatar
                sx={{ bgcolor: data?.bgColor, fontSize: "16px" }}
                className="img-thumbnail"
              >
                {startCase(data?.firstname[0]) + startCase(data?.lastname[0])}
              </Avatar>
            </div>
            <div>
              <div
                className="ms-1 text-primary"
                onClick={() =>
                  data?.isLoggedInUser ? handleShowOwnersDetailTab(data) : ""
                }
              >
                <span
                  className={
                    data?.isLoggedInUser
                      ? "text-primary text-underline cursor-pointer"
                      : "text-dark"
                  }
                >
                  {`${startCase(data?.firstname) || "First Name"} ${
                    startCase(data?.lastname) || ""
                  }`}
                </span>

                {data?.role.type === "bg-custom" && (
                  <span className="ms-2 custom-badge">{data?.role.title}</span>
                )}
                {data?.role.type !== "bg-custom" && (
                  <Badge className={`ms-2 bg-${data?.role.type} fw-light`}>
                    {data?.role.title}
                  </Badge>
                )}
              </div>
              <div className="ms-1">{data?.email?.toLowerCase()}</div>
            </div>
          </div>
        </td>
        <td className="text-nowrap text-center">
          {data?.LogInfo === undefined ? "-" : formatLogInfo(data?.LogInfo)}
        </td>
        <td className="text-center">
          <SoftBadge pill bg={data?.status?.type}>
            {data?.status?.title}
            <FontAwesomeIcon icon={data?.status?.icon} className="ms-2" />
          </SoftBadge>
        </td>
        {userData.role === "unit_owner" && (
          <td className="text-center">
            <CardDropdown>
              <div className="py-2">
                {data?.status?.title === "Active" &&
                  data?.type === "primary_owner" && (
                    <>
                      <Dropdown.Item
                        onClick={() => handleShowOwnersDetailTab(data)}
                      >
                        View Details
                      </Dropdown.Item>
                    </>
                  )}
                {data?.status?.title === "Active" &&
                  data?.type !== "primary_owner" && (
                    <>
                      {/* <Dropdown.Item>Make Primary</Dropdown.Item> */}
                      <Dropdown.Item className="text-danger" onClick={() => deactivateUser(data)}>
                        Revoke Access
                      </Dropdown.Item>
                    </>
                  )}
                {data?.status?.title === "Invited" && (
                  <>
                    <Dropdown.Item onClick={() => reinviteUser(data)}>
                      Reinvite
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="text-primary"
                      // style={{'fontWeight':'bold'}}
                      onClick={() => handleCopyInviteLink(data)}
                    >
                      Copy Invite Link
                    </Dropdown.Item>
                    <Dropdown.Item className="text-danger" onClick={() => deleteInvitedUser(data)}>
                      Delete User
                    </Dropdown.Item>
                  </>
                )}
                {data?.status?.title === "Ignored" && (
                  <>
                    <Dropdown.Item onClick={() => reinviteUser(data)}>
                      Reinvite
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => deleteInvitedUser(data)}>
                      Delete
                    </Dropdown.Item>
                  </>
                )}

                {data?.status?.title === "Revoked" && (
                  <Dropdown.Item onClick={() => activateUser(data)}>
                    Reactivate
                  </Dropdown.Item>
                )}
              </div>
            </CardDropdown>
          </td>
        )}
      </tr>
    );
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
};

const EmptyRow = () => {
  return (
    <tr className="text-center text-info fw-bold fs-1">
      <td colSpan={4}>Nothing Found!</td>
    </tr>
  );
};

const UnitOwnersPortalTable = ({
  list,
  inviteUser,
  adminCount,
  reinviteUser,
  changeOwner,
  deactivateUser,
  activateUser,
  handleShowOwnersDetailTab,
  deleteInvitedUser,
  userData,
  jwtToken,
  hostname,
}) => {
  console.log(list);
  return (
    <Table responsive striped hover bordered style={{ fontSize: "14px" }}>
      <thead>
        <tr>
          <th scope="col">Resident</th>
          <th scope="col" className="text-center">
            Last Active
          </th>
          <th scope="col" className="text-center">
            Status
          </th>
          {userData.role === "unit_owner" && (
            <th scope="col" className="text-center">
              Actions
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {list?.length > 0 ? (
          list?.map((user, i) => (
            <TableRow
              data={user}
              key={user?._id}
              inviteUser={inviteUser}
              adminCount={adminCount}
              propLength={list?.length}
              reinviteUser={reinviteUser}
              changeOwner={changeOwner}
              deactivateUser={deactivateUser}
              activateUser={activateUser}
              handleShowOwnersDetailTab={handleShowOwnersDetailTab}
              deleteInvitedUser={deleteInvitedUser}
              userData={userData}
              jwtToken={jwtToken}
              hostname={hostname}
            />
          ))
        ) : (
          <EmptyRow />
        )}
      </tbody>
    </Table>
  );
};

export default UnitOwnersPortalTable;
