import React from "react";
// import PropTypes from "prop-types";
import { Form as BSForm, Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { toast } from "react-toastify";

import { AuthWizardContext } from "../../../../context/Context";
import { getHostName } from "../../../../helpers/utils";

const AccountForm = () => {

let hostname = getHostName();

  const { step, setStep, user, setUser } = React.useContext(AuthWizardContext);
  console.log(user);
  const [show, setShow] = React.useState(false);

  const handleSubmit = (data) => {
    console.log(data);
    // return;
    axios
      .post(`${hostname}/api/auth/emailverify`, { data })
      .then((res) => {
        console.log(res.data);
        setUser(Object.assign({}, user, data));

        if (res.data.Check == "Verified") {
          // setUser({verified : true , ...user});
          setStep(step + 2);
          toast.success(`Already verified .`, {
            theme: "colored",
          });
          return;
        } else if (res.data.Check == "Pending") {
          setStep(step + 1);
        } else {
          setStep(step + 1);
        }

        toast.success(`Success, Email Sent.`, {
          theme: "colored",
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Email already registered`, {
          theme: "colored",
        });
      });
  };

  return (
    <>
      <Formik
        initialValues={{
          first_name: user.first_name || "",
          last_name: user.last_name || "",
          email: user.email || "",
          password: user.password || "",
          confirm_password: user.confirm_password || "",
          rules_accepted: user.rules_accepted || "",
        }}
        validationSchema={Yup.object({
          first_name: Yup.string().required("First name is required!"),
          last_name: Yup.string().required("Last name is required!"),
          email: Yup.string()
            .email("must be valid email!")
            .required("Email is required!"),
          password: Yup.string()
            .min(6, "Must be greater than 6")
            .required("Password is required!"),
          confirm_password: Yup.string()
            .oneOf([Yup.ref("password"), null], "Passwords must match!")
            .required("confirm password is required!"),
          rules_accepted: Yup.string().required(
            "Please accept all Terms and Conditions!"
          ),
        })}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <BSForm.Group className="mb-3">
              <BSForm.Label>First Name</BSForm.Label>
              <Field name="first_name" type="text" className="form-control" />
              <ErrorMessage
                name="first_name"
                component={"div"}
                className="fs--1 text-danger"
              />
            </BSForm.Group>
            <BSForm.Group className="mb-3">
              <BSForm.Label>Last Name</BSForm.Label>
              <Field name="last_name" type="text" className="form-control" />
              <ErrorMessage
                name="last_name"
                component={"div"}
                className="fs--1 text-danger"
              />
            </BSForm.Group>
            <BSForm.Group className="mb-3">
              <BSForm.Label>Buisness Email</BSForm.Label>
              <Field name="email" type="text" className="form-control" />
              <ErrorMessage
                name="email"
                component={"div"}
                className="fs--1 text-danger"
              />
            </BSForm.Group>
            <BSForm.Group className="mb-3">
              <BSForm.Label>Password</BSForm.Label>
              <div className="position-relative">
                <Field
                  name="password"
                  type={show ? "text" : "password"}
                  className="form-control"
                />
                <span
                  className="translate-middle cursor-pointer"
                  onClick={() => setShow(!show)}
                  style={{ position: "absolute", top: "50%", left: "95%" }}
                >
                  {show ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </span>
              </div>
              <ErrorMessage
                name="password"
                component={"div"}
                className="fs--1 text-danger"
              />
            </BSForm.Group>
            <BSForm.Group className="mb-3">
              <BSForm.Label>Confirm Password</BSForm.Label>
              <div className="position-relative">
                <Field
                  name="confirm_password"
                  type={show ? "text" : "password"}
                  className="form-control"
                />
                <span
                  className="translate-middle cursor-pointer"
                  onClick={() => setShow(!show)}
                  style={{ position: "absolute", top: "50%", left: "95%" }}
                >
                  {show ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </span>
              </div>
              <ErrorMessage
                name="confirm_password"
                component={"div"}
                className="fs--1 text-danger"
              />
            </BSForm.Group>
            <BSForm.Group className="mb-3">
              <Field
                name="rules_accepted"
                type="checkbox"
                className="form-check-input"
              />
              <BSForm.Label className="ms-3"> Agreed to terms and conditions!</BSForm.Label>
              <ErrorMessage
                name="rules_accepted"
                component={"div"}
                className="fs--1 text-danger"
              />
            </BSForm.Group>
            <div className="text-end">
              <Button variant="primary" type="submit" disabled={isSubmitting}>
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AccountForm;
