import CustomFilterbar from "../../../../components/common/CustomFilterbar";
import CustomSearchbar from "../../../../components/common/CustomSearchbar";
import Flex from "../../../../components/common/Flex";
import React from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Breadcrumb,
  Dropdown,
  ProgressBar,
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import DocumentDriveTable from "./DocumentDriveTable";
import DocumentDriveModel from "./DocumentDriveModel";
// import Cookies from "universal-cookie";
import Loader from "../../../../components/common/Loader";
// import { v4 as uuidv4 } from "uuid";
import { byteConverter, getSize, sortByLatest } from "../../../../helpers/utils";
import axios from "axios";
import { ControlCamera, Delete } from "@mui/icons-material";
import PreviewFileComp from "../../../../components/common/PreviewFileFullScreen";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName } from "../../../../helpers/utils";

export default function DocumentDrive() {
  let hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const navigate = useNavigate();

  const { property_name, property_id } = useParams();

  const [loader, setLoader] = React.useState(false);
  const [tableLoader, setTableLoader] = React.useState(false);

  const [DriveFilesList, setDriveFilesList] = React.useState([]);
  const [DriveFilesListTemp, setDriveFilesListTemp] = React.useState([]);
  const [storageInfo, setStorageInfo] = React.useState({
    total: 1024,
    used: 0.0,
    percent: 0,
  });
  const [base_folder_id, set_base_folder_id] = React.useState("");
  const [current_folder_id, set_current_folder_id] = React.useState("");
  const [DriveTree, setDriveTree] = React.useState([]);

  const [bulkFiles, setBulkFiles] = React.useState([]);

  const [showModal, setShowModal] = React.useState(false);
  const [payload, setPayload] = React.useState({});

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  // previewr state
  const [previewModel, setPreviewModel] = React.useState({
    state: false,
    file: "",
  });

  const handleShowPreviewComponent = (file, downladMode = false) => {
    console.log(file);

    if (downladMode) {
      window.open(file.file, "_blank");
      return;
    }

    setPreviewModel({
      state: true,
      file: {
        mimetype: file.mimetype,
        data: file.file,
        doc_id: file._id,
        downladMode,
      },
    });
  };
  const handleClosePreviewComponent = () => {
    setPreviewModel({ state: false, file: "" });
  };

  React.useEffect(() => {
    getDriveFilesBaseFolderData();
    getUsedStorage();
  }, []);

  // React.useEffect(() => {
  //   getUsedStorage();
  // }, [current_folder_id,payload]);

  const getDriveFilesBaseFolderData = async () => {
    if (current_folder_id) {
      setTableLoader(true);
      let folder_data = await getFolderContents(current_folder_id);
      setDriveFilesList(folder_data);
      setDriveFilesListTemp(folder_data);

      setTableLoader(false);

      return;
    }

    setLoader(true);

    axios
      .post(
        `${hostname}/api/property/GetBaseFolderData`,
        {
          p_id: property_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        set_base_folder_id(res.data.base_id);
        set_current_folder_id(res.data.base_id);
        setDriveTree([
          { folder_id: res.data.base_id, folder_name: "Document Drive" },
        ]);

        // let sumStorageUsed = 0;
        let arr = res.data.data
          ?.filter((v) => v.status === "Active")
          .map((v, index) => {
            let obj = { ...v };

            obj.file_name = obj.name;
            obj.file_name_short = obj.name.substr(0, obj.name.lastIndexOf("."));
            obj.extention = obj.name.substr(obj.name.lastIndexOf(".") + 1);
            obj.file_type = obj.type;
            obj.first_name = obj.uploaded_by_id.firstname;
            obj.last_name = obj.uploaded_by_id.lastname;
            obj.user_id = obj.uploaded_by_id._id;
            obj.bgColor = obj.uploaded_by_id.bgColor;
            obj.file = obj.data;
            // // obj.size_in_mb = byteConverter(obj.size, 0, "MB");
            // let today = new Date().toDateString();
            // let permitDate = new Date(obj.createdAt).toDateString();
            // console.log(obj.createdAt);

            obj.isExpiredDate = checkIfItemExpired(obj.expiry_date);
            // sumStorageUsed += obj.size;

            if (obj.file_type === "folder") obj.mimetype = "folder";
            // if (index === 0) obj.expiry_date = "-";
            // if (index === 1) obj.expiry_date = new Date().toUTCString();

            return obj;
          });

        console.log(arr);

        arr = sortByLatest(arr, "createdAt");

        setDriveFilesList(arr);
        setDriveFilesListTemp(arr);

        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });

    // return;
  };

  const checkIfItemExpired = (expiry_date) => {
    if (!expiry_date) return false;

    // ####
    // Convert it to a Date object
    const dateFromBackend = new Date(expiry_date);

    // Get the current date (local time)
    const now = new Date();

    // Create a new Date object representing only the date portion (ignoring the time)
    const backendDateOnly = new Date(
      dateFromBackend.getFullYear(),
      dateFromBackend.getMonth(),
      dateFromBackend.getDate()
    );
    const todayOnly = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    );

    // Compare the dates
    if (backendDateOnly < todayOnly) {
      console.log("The date is in the past.");
      return true;
    } else if (backendDateOnly > todayOnly) {
      console.log("The date is in the future.");
      return false;
    } else {
      console.log("The date is today.");
      return false;
    }

    // ###

    // // Convert it to a Date object
    // const dateFromBackend = new Date(expiry_date);

    // // Get the current date and time
    // const now = new Date();

    // // Check if the date is in the past
    // if (dateFromBackend.getTime() < now.getTime()) {
    //   console.log("The date is in the past.");
    //   return true;
    // } else {
    //   console.log("The date is not in the past.");
    //   return false;
    // }
  };

  const getUsedStorage = () => {
    axios
      .post(
        `${hostname}/api/property/docdriveSize`,
        {
          p_id: property_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        let total, used, percent;
        total = used = percent = 0.0;

        total = res.data.Data.Total_Space / (1024 * 1024 * 1024);
        // total = total.toFixed(2);
        used = getSize(res.data.Data.Used_Space);
        percent = res.data.Data.Used_Space / res.data.Data.Total_Space;
        percent *= 100.0;
        console.log(percent);

        percent = percent.toFixed(2);

        setStorageInfo({ total: total, used: used, percent: percent });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const createFolder = () => {
    setPayload({});

    let obj = {};
    obj.loadType = "Create Folder";
    obj.p_id = property_id;
    setPayload(obj);
    handleShow();
  };

  const uploadFiles = () => {
    setPayload({});

    let obj = {};
    obj.loadType = "Upload Files";
    obj.p_id = property_id;
    setPayload(obj);
    handleShow();
  };

  const renameAction = (obj = {}) => {
    setPayload({});

    obj.loadType = "Edit";
    obj.p_id = property_id;
    setPayload(obj);
    handleShow();
  };

  const moveAction = async (obj = {}) => {
    setPayload({});

    obj.loadType = "Move";
    obj.p_id = property_id;

    let folder_data = await getFolderContents(base_folder_id);

    obj.baseFolderData = folder_data;

    console.log(obj);

    setPayload(obj);
    handleShow();
  };

  const deleteAction = (obj = {}) => {
    setPayload({});

    obj.loadType = "Delete";
    obj.p_id = property_id;
    setPayload(obj);
    handleShow();
  };

  const viewFolderContents = async (obj = {}) => {
    setPayload({});

    setTableLoader(true);
    let drive_tree_obj = {};

    if (Object.hasOwnProperty.call(obj, "parent_id"))
      drive_tree_obj.parent_id_drive_tree = obj.parent_id;
    else drive_tree_obj.parent_id_drive_tree = base_folder_id;

    drive_tree_obj.parent_name_drive_tree = obj.file_name;

    setDriveTree(
      DriveTree.concat({ folder_id: obj._id, folder_name: obj.file_name })
    );

    let folder_contents_to_view = await getFolderContents(obj._id);

    let finalObject = [];

    finalObject = folder_contents_to_view?.map((v) => {
      let obj = { ...v, ...drive_tree_obj };
      return obj;
    });

    console.log(finalObject);
    setDriveFilesList(finalObject); // array
    setDriveFilesListTemp(finalObject);
    set_current_folder_id(obj._id);
    setTableLoader(false);
  };

  const getFolderContents = async (parent_id = "") => {
    const res = await axios.post(
      `${hostname}/api/property/GetFolderData`,
      {
        parent_id,
      },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      }
    );
    console.log(res.data);

    let arr = res.data.data
      ?.filter((v) => v.status === "Active")
      .map((v) => {
        let obj = { ...v };

        obj.file_name = obj.name;
        obj.extention = obj.name.substr(obj.name.lastIndexOf(".") + 1);
        obj.file_type = obj.type;
        obj.first_name = obj.uploaded_by_id.firstname;
        obj.last_name = obj.uploaded_by_id.lastname;
        obj.user_id = obj.uploaded_by_id._id;
        obj.bgColor = obj.uploaded_by_id.bgColor;
        obj.file = obj.data;
        obj.size_in_mb = byteConverter(obj.size, 0, "MB");

        // let today = new Date().toDateString();
        // let permitDate = new Date(obj.createdAt).toDateString();

        obj.isExpiredDate = checkIfItemExpired(obj.expiry_date);

        if (obj.file_type === "folder") obj.mimetype = "folder";

        return obj;
      });
    arr = sortByLatest(arr, "createdAt");
    getUsedStorage();
    return arr;
  };

  const goBack = async () => {
    setPayload({});

    setTableLoader(true);
    let arr = [...DriveTree];
    arr.pop();
    const { folder_id } = arr[arr.length - 1];

    let folder_data = await getFolderContents(folder_id);

    setDriveFilesList(folder_data);
    setDriveFilesListTemp(folder_data);
    setDriveTree(arr);
    set_current_folder_id(folder_id);
    setTableLoader(false);
  };

  const handleDriveTreeLink = async (
    folder_id_drive = "",
    folder_name_drive = ""
  ) => {
    setPayload({});
    setTableLoader(true);
    console.log(DriveTree);
    let arr = [...DriveTree];

    if (folder_id_drive === base_folder_id) {
      let folder_data = await getFolderContents(folder_id_drive);

      setDriveFilesList(folder_data);
      setDriveFilesListTemp(folder_data);
      set_current_folder_id(folder_id_drive);
      setDriveTree([
        { folder_id: base_folder_id, folder_name: "Document Drive" },
      ]);
      setTableLoader(false);
      return;
    }

    let folder_index = arr.indexOf({
      folder_id: folder_id_drive,
      folder_name: folder_name_drive,
    });
    let slicedArr = arr.slice(0, folder_index);
    console.log(slicedArr);

    const { folder_id } = slicedArr[slicedArr.length - 1];

    let folder_data = await getFolderContents(folder_id);

    let driveArr = slicedArr?.map((v) => {
      return { folder_id: v._id, folder_name: v.file_name };
    });

    console.log(driveArr);

    setDriveFilesList(folder_data);
    setDriveFilesListTemp(folder_data);
    setDriveTree(driveArr);
    set_current_folder_id(folder_id);
    setTableLoader(false);
  };

  const addBulkAction = (v) => {
    console.log(v);
    if (bulkFiles.some((j) => j._id === v._id)) {
      let state = bulkFiles?.filter((j) => j._id !== v._id);
      setBulkFiles(state);
    } else {
      setBulkFiles(bulkFiles?.concat(v));
    }
  };

  const applyBulkAction = async (actionType = "") => {
    console.log(bulkFiles);
    setPayload({});

    if (bulkFiles.length < 1) return;

    if (actionType === "move") {
      let obj = {};
      obj.loadType = "Bulk Move";
      obj.bulkFiles = bulkFiles;
      obj.setBulkFiles = setBulkFiles;

      obj.p_id = property_id;

      let folder_data = await getFolderContents(base_folder_id);

      obj.baseFolderData = folder_data;

      setPayload({ ...obj });
      handleShow();
    }

    if (actionType === "delete") {
      let obj = {};
      obj.loadType = "Bulk Delete";
      obj.bulkFiles = bulkFiles;
      obj.setBulkFiles = setBulkFiles;
      setPayload({ ...obj });
      handleShow();
    }

    setBulkFiles([]);
  };

  if (loader) return <Loader />;
  return (
    <>
      <Row className="g-3 mb-3">
        <Col>
          <Card>
            <Card.Body className="">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link className="text-secondary" to={"/"}>
                    Home
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link
                    className="text-secondary"
                    to={"/property/management/buildings"}
                  >
                    Properties
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link className="text-secondary" to={"#!"}>
                    {property_name}
                  </Link>
                </Breadcrumb.Item>{" "}
                <Breadcrumb.Item active>
                  <Link to={"#!"}>Document Drive</Link>
                </Breadcrumb.Item>
              </Breadcrumb>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Card>
        <Card.Body className="" style={{ minHeight: "90vh" }}>
          <Flex justifyContent={"between"} alignItems={"start"} className="">
            <h4>Document Drive</h4>
            <div className="d-flex">
              <Dropdown>
                <Dropdown.Toggle size="sm">+ New </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={createFolder}>
                    Create Folder
                  </Dropdown.Item>
                  <Dropdown.Item onClick={uploadFiles}>
                    Upload Files
                  </Dropdown.Item>
                  {/* <Dropdown.Item onClick={uploadFiles}>
                    Upload Folder
                  </Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
              <Button
                variant="falcon-primary"
                size="sm"
                className="ms-3"
                onClick={() =>
                  navigate(
                    `/property/features/${property_id}/${property_name}/document-drive/trash`
                  )
                }
              >
                Trash
              </Button>
            </div>
          </Flex>
          <br />

          <Row className="flex-end-center mt-3">
            <Col className=" d-flex justify-content-sm-end">
              {bulkFiles.length > 1 && (
                <div>
                  <Button
                    variant="falcon-primary"
                    onClick={() => applyBulkAction("move")}
                    disabled={bulkFiles.length < 1}
                    className="me-3"
                  >
                    <span className="me-3">
                      <ControlCamera fontSize="small" />
                    </span>
                    Move
                  </Button>
                  <Button
                    variant="falcon-danger"
                    onClick={() => applyBulkAction("delete")}
                    disabled={bulkFiles.length < 1}
                    className="me-3"
                  >
                    <span className="me-3">
                      <Delete fontSize="small" />
                    </span>
                    Delete
                  </Button>
                </div>
              )}
              <CustomFilterbar
                filterFlags={["Folders", "Files", "Expired"]}
                dataListTemp={DriveFilesListTemp}
                stateUpdator={setDriveFilesList}
                from="Document Drive Table"
              />

              <CustomSearchbar
                dataListTemp={DriveFilesListTemp}
                stateUpdator={setDriveFilesList}
                searchFlags={[
                  "file_name",
                  "first_name",
                  "last_name",
                  "createdAt",
                ]}
                placeholders={["file name", "user name", "uploaded at"]}
              />
            </Col>
          </Row>

          <div className="bg-light px-3 mb-3">
            {DriveTree?.map((v, i) => (
              <React.Fragment key={v.folder_id}>
                {i > 0 && <span className="mx-1">/</span>} {"  "}
                <Link
                  // variant="link"
                  // size="sm"
                  to={"#!"}
                  className="text-secondary"
                  onClick={() =>
                    handleDriveTreeLink(v.folder_id, v.folder_name)
                  }
                >
                  {v.folder_name}
                </Link>{" "}
              </React.Fragment>
            ))}
          </div>

          <DocumentDriveTable
            list={DriveFilesList}
            bulkFiles={bulkFiles}
            addBulkAction={addBulkAction}
            renameAction={renameAction}
            deleteAction={deleteAction}
            tableLoader={tableLoader}
            viewFolderContents={viewFolderContents}
            DriveTree={DriveTree}
            goBack={goBack}
            moveAction={moveAction}
            handleShowPreviewComponent={handleShowPreviewComponent}
          />
          <br />
          <div className="ms-3">
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={6}>
                    <ProgressBar
                      now={storageInfo.used}
                      style={{ height: "6px" }}
                    />
                  </Col>
                </Row>
                <p className="lead pt-1">
                  {storageInfo.used} ( {storageInfo.percent} ) % of{" "}
                  {storageInfo.total} GB Used
                  {/* {((storageInfo.used / 1024) * 100).toFixed(2)}%) of 1 GB Used */}
                  {/* 23.70 MB of 1 GB Used */}
                </p>
              </Col>
            </Row>
          </div>

          <br />
          <PreviewFileComp
            fileUrl={previewModel.file}
            isVisible={previewModel.state}
            handleClose={handleClosePreviewComponent}
          />
          <br />

          <DocumentDriveModel
            show={showModal}
            handleClose={handleClose}
            payload={payload}
            userData={userData}
            property_id={property_id}
            base_folder_id={base_folder_id}
            current_folder_id={current_folder_id}
            getAllData={getDriveFilesBaseFolderData}
            getFolderContents={getFolderContents}
            RootDriveFilesList={DriveFilesList}
            hostname={hostname}
            jwtToken={jwtToken}
          />
        </Card.Body>
      </Card>
    </>
  );
}
