import React from "react";
import { Card } from "react-bootstrap";
import FalconCardHeader from "../../../common/FalconCardHeader";
import { Link, useNavigate } from "react-router-dom";
import Flex from "../../../common/Flex";

import axios from "axios";

import { Avatar } from "@mui/material";
import { sortByLatest, timeAgo } from "../../../../helpers/utils";
import { convert } from "html-to-text";
import { startCase } from "lodash";

const BulletinPosts = ({ userData = {}, hostname, jwtToken }) => {
  const [postsList, setPostsList] = React.useState([]);

  React.useEffect(() => {
    getAllPostsData();
  }, []);

  const getAllPostsData = () => {
    let p_id = "";

    // console.log(userData);

    if (userData.role === "Site-Administrators") {
      p_id = userData.compid;
    }
    if (
      userData.role === "unit_owner" ||
      userData.role === "supp_resident" ||
      userData.role === "supp_tenant"
    ) {
      p_id = userData.propid;
    }
    if (userData.role === "Condozak-Basic-Users") {
      p_id = userData.compid;
    }

    // if(!p_id){
    //   toast.error('no pid');
    //   return;
    // }

    axios
      .post(
        `${hostname}/api/property/readAllPosts`,
        {
          p_id,
          skiper: 0,
          role: userData.role,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        let arr = [...res.data.Data] || [];

        arr = arr?.map((v) => {
          let obj = { ...v };
          // console.log(obj.post_text);
          let text = obj.post_text.replace(/(\r\n|\n|\r)/gm, "");
          text = obj.post_text.replace(/nbsp;/g, "");
          // console.log(text);
          obj.post_text = convert(text, { wordwrap: 130 });
          // console.log(obj.post_text);

          return obj;
        });

        // console.log(arr);
        let arr2 = sortByLatest(arr, "createdAt");

        arr2 = arr2?.slice(0, 5);

        setPostsList(arr2);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Card
      className={` h-lg-100`}
      style={{
        minHeight:
          userData.role === "unit_owner" ||
          userData.role === "supp_resident" ||
          userData.role === "supp_tenant"
            ? "400px"
            : "",
      }}
    >
      {/* <FalconCardHeader
        title="Bulletin Board"
        titleTag="h6"
        className="py-2"
        endEl={
          userData.role === "unit_owner" ||
          userData.role === "supp_resident" ||
          userData.role === "supp_tenant" ? (
            <Link
              className="py-1 fs--1 font-sans-serif"
              to={"/owner-portal/bulletin-board"}
            >
              View All
            </Link>
          ) : (
            <span />
          )
        }
      /> */}
      <div className="px-3 mt-3 d-flex justify-content-between align-items-center">
        <h6>Bulletin Board </h6>
        {userData.role === "unit_owner" ||
        userData.role === "supp_resident" ||
        userData.role === "supp_tenant" ? (
          <Link
            className="py-1 fs--1 font-sans-serif"
            to={"/owner-portal/bulletin-board"}
          >
            View All
          </Link>
        ) : (
          <span />
        )}
      </div>
      <Card.Body className="pb-0">
        {postsList?.map((post, index) => (
          <SharedFile
            key={post._id}
            post={post}
            isLast={index === postsList.length - 1}
            userData={userData}
          />
        ))}
        {postsList?.length < 1 && (
          <div className="d-flex justify-content-center align-items-center h-100">
            Bulletin Posts will be shown here.
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

const SharedFile = ({ post, isLast, userData }) => {
  // const { img, name, user, time, border } = file;

  const navigate = useNavigate();

  const { firstname, lastname, bgColor } = post.created_by_id;

  let link = `/property/features/${post.p_id._id}/${post.p_id.property_name}/bulletins`;

  if (
    userData.role === "unit_owner" ||
    userData.role === "supp_resident" ||
    userData.role === "supp_tenant"
  )
    link = `/owner-portal/bulletin-board`;

  return (
    <>
      <Flex alignItems="center" className="mb-3 hover-actions-trigger">
        <div className="" onClick={() => navigate(link)}>
          {/* <img
            className={classNames("h-100 w-100 fit-cover rounded-2", {
              border: border,
            })}
            src={img}
            alt=""
          /> */}
          <Avatar
            sx={{
              bgcolor: bgColor || "silver",
              fontSize: "14px",
              width: "2.50rem",
              // height:'40px',
            }}
            className="img-thumbnail rounded-circle"
          >
            {firstname && startCase(firstname[0])}
            {lastname && startCase(lastname[0])}
          </Avatar>
        </div>
        <div className="ms-3 flex-shrink-1 flex-grow-1">
          <h6 className="mb-1">
            <Link className="stretched-link text-900 fw-semi-bold" to={link}>
              <span>{post?.post_text?.slice(0, 90)}</span>
              {/* <span
                dangerouslySetInnerHTML={{
                  __html: post?.post_text?.slice(0, 60),
                }}
              ></span> */}
            </Link>
          </h6>
          <div className="fs--1">
            <span className="fw-semi-bold">{`${firstname} ${lastname}`}</span>
            <span className="fw-medium text-600 ms-3">
              {timeAgo(post.createdAt)}
            </span>
          </div>
          {/* <div className="hover-actions end-0 top-50 translate-middle-y">
            <OverlayTrigger
              overlay={<Tooltip id="nextTooltip">Download</Tooltip>}
            >
              <Button
                variant="light"
                size="sm"
                className="border-300 me-1 text-600"
                as={"a"}
                href={img}
                download
              >
                <img src={cloudDownload} alt="Download" width={15} />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip id="nextTooltip">Edit</Tooltip>}>
              <Button variant="light" size="sm" className="border-300 text-600">
                <img src={editAlt} alt="Edit" width={15} />
              </Button>
            </OverlayTrigger>
          </div> */}
        </div>
      </Flex>
      {!isLast && <hr className="text-200" />}
    </>
  );
};

export default BulletinPosts;
