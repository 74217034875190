import axios from "axios";
import React from "react";
import {
  Modal,
  Row,
  Col,
  Button,
  Form as BSForm,
  Table,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// import { useParams } from "react-router-dom";
import BUF from "../../../assets/Bulk Units Template.xlsx";
import * as xlsx from "xlsx";
import Loader from "../../../components/common/Loader";

export default function UnitsManagementModel({
  show,
  handleClose,
  payload,
  setPayload,
  userData,
  getAllData,
  unitsData,
  headerData,
  hostname,
  jwtToken,
  property_id,
}) {
  // console.log(payload);

  function isEmail(emailAdress) {
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (emailAdress.match(regex)) return true;
    else return false;
  }

  const [isFull, setIsFull] = React.useState(false);
  const [loader, setLoader] = React.useState(false);

  // file handle for bulk unit
  const [unitBulkFileErrors, setUnitsBulkFileErrors] = React.useState([]);

  const handleBulkUnitFile = async (e) => {
    // try {
    setLoader(true);
    const file = e.target.files[0];
    e.target.value = null;
    // e.target.files = [];
    if (
      // file instanceof File &&
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      const data = await file.arrayBuffer();
      const workbook = xlsx.read(data);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = xlsx.utils.sheet_to_json(worksheet, {
        header: 1,
        defval: "",
      });

      console.log(jsonData);

      let tempArr = [...jsonData];

      let headings = tempArr.shift();
      let headingIncorrect = false;

      console.log(headings);

      //headings validations
      if (headings[0] !== "Unit Number") {
        toast.error("Unit Number heading is incorrect!", {
          autoClose: false,
        });
        headingIncorrect = true;
      }
      if (headings[1] !== "Unit Type") {
        toast.error("Unit Type heading is incorrect!", { autoClose: false });
        headingIncorrect = true;
      }
      if (headings[2] !== "CEF %") {
        toast.error("CEF % heading is incorrect!", { autoClose: false });
        headingIncorrect = true;
      }
      if (headings[3] !== "Primary Owner Email") {
        toast.error("Primary Owner Email heading is incorrect!", {
          autoClose: false,
        });
        headingIncorrect = true;
      }
      if (headings[4] !== "Attached To Condo Unit") {
        toast.error("Attached To Condo Unit heading is incorrect!", {
          autoClose: false,
        });
        headingIncorrect = true;
      }
      if (headings[5] !== "Notes") {
        toast.error("Notes heading is incorrect!", { autoClose: false });
        headingIncorrect = true;
      }

      if (headingIncorrect) {
        setLoader(false);
        return;
      }

      // processing files data;

      let unitsFileArr = [];
      let unitsFileArrInfected = [];
      let sumOfCef = 0.0;
      let unit_numbers = [];

      tempArr?.forEach((row, i) => {
        let isInfected = false;

        // data formatting
        let obj = {};

        obj.unit_owner = row[3];
        obj.attached_to = row[4];
        obj.notes = row[5];
        obj.line_number = i + 1;

        // if unit_number or type or cef is missing!

        if (row[0]) {
          if (!Number.isInteger(+row[0])) {
            isInfected = true;
            obj.infected = true;
            obj.cef = <span className="text-danger">invalid value!</span>;
          } else obj.unit_number = row[0];
        } else {
          isInfected = true;
          obj.infected = true;
          obj.unit_number = <span className="text-danger">Missing Entry!</span>;
        }

        if (row[1]) obj.unit_type = row[1];
        else {
          isInfected = true;
          obj.infected = true;
          obj.unit_type = <span className="text-danger">Missing Entry!</span>;
        }

        if (row[2]) {
          if (!Number.isInteger(+row[2])) {
            isInfected = true;
            obj.infected = true;
            obj.cef = <span className="text-danger">invalid value!</span>;
          } else obj.cef = row[2];
        } else {
          isInfected = true;
          obj.infected = true;
          obj.cef = <span className="text-danger">Missing Entry!</span>;
        }

        // if unit_number or type or cef is present and inner details are missing!

        if (row[0] && row[1] && row[2]) {
          if (row[1] === "Condo" && row[3]) {
            if (isEmail(row[3])) obj.unit_owner = row[3];
            else {
              obj.unit_owner = (
                <span className="text-danger">Invalid Email!</span>
              );
              isInfected = true;
              obj.infected = true;
            }
          }

          if ((row[1] === "Parking Spot" || row[1] === "Locker") && row[4]) {
            if (!Number.isInteger(+row[4])) {
              isInfected = true;
              obj.infected = true;
              obj.cef = <span className="text-danger">invalid value!</span>;
            } else obj.unit_owner = row[4];
          }

          if ((row[1] === "Parking Spot" || row[1] === "Locker") && row[3]) {
            obj.unit_owner = <span className="text-danger">Invalid Link!</span>;
            isInfected = true;
            obj.infected = true;
          }

          if (row[1] === "Condo" && row[4]) {
            obj.attached_to = (
              <span className="text-danger">Invalid Link!</span>
            );
            isInfected = true;
            obj.infected = true;
          }
        }
        console.log(row[2]);
        sumOfCef += row[2];

        if (isInfected) unitsFileArrInfected.push(obj);
        else unitsFileArr.push(obj);
      });

      //end
      console.log(unitsFileArr);
      console.log(unitsFileArrInfected);
      console.log(sumOfCef);

      const ids = unitsFileArr.map((o) => o.unit_number);
      let filtered = [];
      let dublicates = [];

      unitsFileArr?.forEach((v, index) => {
        if (!ids.includes(v.unit_number, index + 1)) filtered.push(v);
        else dublicates.push(v);
      });

      console.log(filtered);
      console.log(dublicates);
      if (dublicates.length > 0) {
        dublicates = dublicates.map((v) => {
          let obj = { ...v };

          obj.infected = true;
          obj.unit_number = (
            <span className="text-danger">Duplicate Entry!</span>
          );
          return obj;
        });
        unitsFileArrInfected = unitsFileArrInfected?.concat(dublicates);
      }

      if (unitsFileArrInfected.length > 0) {
        setUnitsBulkFileErrors(unitsFileArrInfected);
        toast.error("There are some errors in file! \n correct and try again.");
        setLoader(false);

        return;
      } else {
        setUnitsBulkFileErrors([]);
        console.log("ready to ship");
      }

      console.log(unitsFileArr);
      // return;

      try {
        // verify file
        const res = await axios.post(
          `${hostname}/api/unit/bulkunitVerificaiton`,
          {
            p_id: property_id,
            validUnitsFile: unitsFileArr,
            InfectedUnitsFile: unitsFileArrInfected,
            bulk_unit_file: file,
          },
          {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );

        console.log(res.data);
        let totalcef = res.data.totalCef;
        if (totalcef > 100.0) {
          toast.error(
            "CEF fee limit exceeded! \n remove some uints and try again."
          );
          setLoader(false);

          return;
        }
        // setLoader(false);
        // return;
      } catch (e) {
        console.log(e);
        setLoader(false);
        toast.error("Something went wrong!");
      }

      try {
        // create file
        const res2 = await axios.post(
          `${hostname}/api/unit/createBulkFiles`,
          {
            p_id: property_id,
            validUnitsFile: unitsFileArr,
            InfectedUnitsFile: unitsFileArrInfected,
            bulk_unit_file: file,
            firstname: userData.firstname,
            lastname: userData.lastname,
          },
          {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );

        console.log(res2.data);
        setLoader(false);
        toast.success("Bulk Unit File Uploaded Successfully");
        getAllData();
        handleCloseExt();
      } catch (e) {
        console.log(e);
        setLoader(false);
        toast.error("something went wrong!");
      }
    } else {
      toast.error("Only excel file type is supported!", { theme: "colored" });
      setLoader(false);
      return;
    }
  };

  let unitsForCondo = [];

  unitsData?.forEach((v, i) => {
    if (v.unit_type === "condo")
      unitsForCondo.push(
        <option value={v._id} key={v._id}>
          {v.unit_number}
        </option>
      );
  });

  const getInitialUpdateData = () => {
    let obj = {
      unit_number: payload.unit_number || "",
      unit_type: payload.unit_type || "",
      cef: payload.cef || "",
      description: payload.description || "",
      Inviteduser: "",
      attachtoUnit: "",
    };
    if (payload.type === "no-owner") {
      obj.Inviteduser = "";
      obj.attachtoUnit = "";
    }
    if (payload.type === "invited-owner") {
      obj.Inviteduser = payload.Inviteduser;
      obj.attachtoUnit = "";
    }
    if (payload.type === "linked-owner") {
      obj.Inviteduser = payload.userinfo.email;
      obj.attachtoUnit = "";
    }
    // obj.Inviteduser =
    // obj = {
    //   unit_number: payload.unit_number || "",
    //   unit_type: payload.unit_type || "condo",
    //   cef: payload.cef | "",
    //   description: payload.description || "",
    //   Inviteduser: payload.Inviteduser | "",
    //   attachtoUnit: payload.attachedToUnit?._id || "",
    // };
    return obj;
  };

  const attachCurrentUnitFromDetail = (unit) => {
    // console.log(unit);

    let apiLink = `${hostname}/api/amenity/AttachUnit`;

    unit = Object.assign(
      {
        firstname: userData.firstname,
        lastname: userData.lastname,
        p_id: payload.property_id,
      },
      unit
    );
    unit.Condo_Unit_id = payload.unit_id;
    // let id = unitsData?.filter((v) => v.unit_number === unit.unit_number);
    // console.log(id);
    unit.Parking_Locker_Unit_id = unit.unit_number;

    // console.log(unit);

    // return;
    axios
      .post(
        apiLink,
        { ...unit },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        getAllData();
        handleClose();
        toast.success(res.data.data, { theme: "colored" });
        setPayload({});
      })
      .catch((err) => {
        console.log(err);
        getAllData();
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };

  const createNewUnit = (unit) => {
    // console.log(unit);

    let apiLink = `${hostname}/api/property/propertyUnits`;

    if (unit.unit_type === "condo") {
      if (unit.description === "") unit.description = "none";

      unit = Object.assign(
        {
          firstname: userData.firstname,
          lastname: userData.lastname,
          p_id: payload.property_id,
        },
        unit
      );
    }

    if (unit.unit_type !== "condo") {
      if (payload.loadType !== "Attach Unit") {
        unit = Object.assign(
          {
            firstname: userData.firstname,
            lastname: userData.lastname,
            p_id: payload.property_id,
          },
          unit
        );
      } else {
        unit = Object.assign(
          {
            firstname: userData.firstname,
            lastname: userData.lastname,
            p_id: payload.property_id,
          },
          unit
        );
        unit.Condo_Unit_id = unit.attachtoUnit;
        let id = unitsData?.filter((v) => v.unit_number === unit.unit_number);
        console.log(id);
        unit.Parking_Locker_Unit_id = id[0]._id;
        apiLink = `${hostname}/api/amenity/AttachUnit`;
      }
    }

    // console.log(unit);

    // return;
    axios
      .post(
        apiLink,
        { ...unit },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        getAllData();
        handleClose();
        toast.success(res.data.data, { theme: "colored" });
        setPayload({});
      })
      .catch((err) => {
        console.log(err);
        getAllData();
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };

  const updateunitinfo = (values) => {
    const unit = {
      unit_id: payload._id,
      unit_number: values.unit_number,
      cef: values.cef,
      description: values.description,
      p_id: payload.property_id,
    };
    console.log(unit);
    axios
      .post(
        `${hostname}/api/property/UpdateUnit`,
        {
          ...unit,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        getAllData();
        handleClose();
        setPayload({});
        toast.success("unit updated.", { theme: "colored" });
      })
      .catch((err) => {
        console.log(err);
        getAllData();
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };

  const detachunit = () => {
    console.log(payload);

    axios
      .post(
        `${hostname}/api/property/DetachUnit`,
        {
          condo_unit_id: payload.attachedToUnit._id,
          Facility_unit_id: payload._id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        getAllData();
        handleClose();
        setPayload({});
        toast.success(res.data || "unit unlinked.", { theme: "colored" });
      })
      .catch((err) => {
        console.log(err);
        getAllData();
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };

  const addunitowner = (data = {}) => {
    // console.log(data);
    data = Object.assign(
      { firstname: userData.firstname, lastname: userData.lastname },
      data
    );
    data.email = data.email?.toLowerCase();
    console.log(data);
    // return;
    axios
      .post(
        `${hostname}/api/owner/AddOwner`,
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then(() => {
        // console.log(res.data);
        getAllData();
        handleClose();
        toast.success("Owner Added.", { theme: "colored" });
        setPayload({});
      })
      .catch((err) => {
        console.log(err);
        getAllData();
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };

  const changeunitowner = (data = {}) => {
    // console.log(data);
    data = Object.assign(
      { firstname: userData.firstname, lastname: userData.lastname },
      data
    );
    data.email = data.email?.toLowerCase();

    console.log(data);
    // return;
    axios

      .post(
        `${hostname}/api/owner/ChangeOwner`,
        {
          ...data,
          Inviteduser: data.email?.toLowerCase(),
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then(() => {
        // console.log(res.data);
        getAllData();
        handleClose();
        toast.success("Owner changed", { theme: "colored" });
        setPayload({});
      })
      .catch((err) => {
        console.log(err);
        getAllData();
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };

  const reinviteunitowner = (data = {}) => {
    // console.log(data);
    data = Object.assign(
      { firstname: userData.firstname, lastname: userData.lastname },
      data
    );
    data.email = data.email?.toLowerCase();
    
    console.log(data);
    // return;
    axios
      .post(
        `${hostname}/api/owner/AddOwner`,
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then(() => {
        // console.log(res.data);
        getAllData();
        handleClose();
        toast.success("Invite sent!", { theme: "colored" });
        setPayload({});
      })
      .catch((err) => {
        console.log(err);
        getAllData();
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };

  const handleCloseExt = () => {
    setUnitsBulkFileErrors([]);
    setLoader(false);
    setIsFull(false);
    handleClose();
  };

  const handleCefChange = (e, setFieldValue) => {
    let cef = e.target.value;
    if (headerData.cef + +cef > 100.0) setIsFull(true);
    else setIsFull(false);
    setFieldValue("cef", cef);
  };

  return (
    <Modal
      show={show}
      onHide={handleCloseExt}
      backdrop="static"
      keyboard={false}
      centered
      size={payload.loadType === "Add Bulk Units" ? "lg" : "md"}
    >
      <Modal.Header closeButton>
        <Modal.Title>{payload.loadType}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {payload.loadType === "Add Unit" && (
          <div>
            <Formik
              initialValues={{
                unit_number: "",
                unit_type: "condo",
                cef: "",
                description: "",
                Inviteduser: "",
                attachtoUnit: "",
              }}
              validate={(values) => {
                const errors = {};
                if (
                  values.unit_type !== "condo" &&
                  payload.loadType === "Attach Unit" &&
                  !values.attachtoUnit
                ) {
                  errors.attachtoUnit = "please enter unit number to continue!";
                }
                if (
                  values.unit_type !== "condo" &&
                  payload.loadType === "Detach Unit" &&
                  !values.attachtoUnit
                ) {
                  errors.attachtoUnit = "please enter unit number to continue!";
                }

                return errors;
              }}
              validationSchema={Yup.object().shape({
                unit_number: Yup.string().required(
                  "please enter unit number to continue!"
                ),
                cef: Yup.string().required("condo element fee is required!"),
                description: Yup.string("must be valid value!"),
                Inviteduser: Yup.string()
                  .email("Must be a valid email")
                  .max(255, "long email!"),
                unit_type: Yup.string().required("please select unit type!"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                if (headerData.cef + +values.cef > 100.0) {
                  setIsFull(true);
                  return;
                } else {
                  setIsFull(false);
                }
                // props.addUnitInfo(values);
                setSubmitting(false);
                createNewUnit(values);
              }}
            >
              {({
                isSubmitting,
                handleChange,
                handleBlur,
                values,
                setFieldValue,
              }) => (
                <Form>
                  <Row className="mb-3">
                    <Col>
                      <BSForm.Label>
                        Unit Type <span className="text-danger">*</span>
                      </BSForm.Label>
                      <Field
                        component="select"
                        id="unit_type"
                        name="unit_type"
                        className="form-select"
                        // disabled
                      >
                        {/* <option value="">Select unit type</option> */}
                        <option value="condo">Condo</option>
                        <option value="Parking Spot">Parking Spot</option>
                        <option value="Locker">Locker</option>
                      </Field>
                      <ErrorMessage
                        name="unit_type"
                        component="div"
                        className="text-danger m-2 fs--1"
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={6}>
                      <BSForm.Label>
                        Unit Number <span className="text-danger">*</span>
                      </BSForm.Label>
                      <Field
                        type="number"
                        name="unit_number"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="unit_number"
                        component="div"
                        className="text-danger m-2 fs--1"
                      />
                    </Col>
                    <Col md={6}>
                      <BSForm.Label>
                        % CEF <span className="text-danger">*</span>
                      </BSForm.Label>
                      <Field
                        type="number"
                        name="cef"
                        className="form-control"
                        onChange={(e) => handleCefChange(e, setFieldValue)}
                      />
                      <ErrorMessage
                        name="cef"
                        component="div"
                        className="text-danger m-2 fs--1"
                      />
                    </Col>
                  </Row>

                  {values.unit_type === "condo" && (
                    <>
                      <Row className="mb-3">
                        <Col>
                          <BSForm.Label>
                            Primary Owner Email
                            <span className="text-danger"></span>
                          </BSForm.Label>
                          <Field
                            type="email"
                            name="Inviteduser"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="Inviteduser"
                            component="div"
                            className="text-danger m-2 fs--1"
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col>
                          <BSForm.Label>
                            Notes <span className="fs--1"></span>
                          </BSForm.Label>

                          <BSForm.Control
                            as={"textarea"}
                            type="text"
                            name="description"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.description}
                          />
                          <ErrorMessage
                            name="description"
                            component="div"
                            className="text-danger m-2 fs--1"
                          />
                        </Col>
                      </Row>

                      <br />
                      <span className="text-danger">
                        {isFull ? "You cannot add cef above than 100!" : ""}
                      </span>
                    </>
                  )}
                  {(values.unit_type === "Parking Spot" ||
                    values.unit_type === "Locker") && (
                    <Row className="mb-3">
                      <Col>
                        <BSForm.Label>
                          Attach to Condo Unit
                          <span className="text-danger"></span>
                        </BSForm.Label>
                        <Field
                          component="select"
                          id="attachtoUnit"
                          name="attachtoUnit"
                          className="form-select"
                          // multiple={true}
                        >
                          <option value="">Select</option>
                          {unitsForCondo}
                        </Field>
                        {/* <Select
                        defaultValue={[options[2], options[3]]}
                        isMulti
                        name="colors"
                        options={options}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      /> */}
                        <ErrorMessage
                          name="attachtoUnit"
                          component="div"
                          className="text-danger m-2 fs--1"
                        />
                      </Col>
                    </Row>
                  )}
                  <br />
                  <button
                    type="submit"
                    disabled={isSubmitting && isFull}
                    className="btn btn-primary float-end"
                  >
                    {payload.loadType?.split(" ")[0]}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        )}

        {payload.loadType === "Update Unit" && (
          <div>
            <Formik
              initialValues={{ ...getInitialUpdateData() }}
              validate={(values) => {
                const errors = {};
                if (
                  values.unit_type !== "condo" &&
                  payload.loadType === "Attach Unit" &&
                  !values.attachtoUnit
                ) {
                  errors.attachtoUnit = "please enter unit number to continue!";
                }
                if (
                  values.unit_type !== "condo" &&
                  payload.loadType === "Detach Unit" &&
                  !values.attachtoUnit
                ) {
                  errors.attachtoUnit = "please enter unit number to continue!";
                }

                return errors;
              }}
              validationSchema={Yup.object().shape({
                unit_number: Yup.string().required(
                  "please enter unit number to continue!"
                ),
                cef: Yup.string().required("condo element fee is required!"),
                description: Yup.string("must be valid value!"),
                Inviteduser: Yup.string()
                  .email("Must be a valid email")
                  .max(255, "long email!"),
                unit_type: Yup.string().required("please select unit type!"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                if (headerData.cef + +values.cef > 100) {
                  setIsFull(true);
                  return;
                } else {
                  setIsFull(false);
                }
                // props.addUnitInfo(values);
                updateunitinfo(values);
                setSubmitting(false);
              }}
            >
              {({
                isSubmitting,
                handleChange,
                handleBlur,
                values,
                setFieldValue,
              }) => (
                <Form>
                  <Row className="mb-3">
                    <Col>
                      <BSForm.Label>
                        Unit Type <span className="text-danger">*</span>
                      </BSForm.Label>
                      <Field
                        component="select"
                        id="unit_type"
                        name="unit_type"
                        className="form-select"
                        disabled
                      >
                        {/* <option value="">Select unit type</option> */}
                        <option value="condo">Condo</option>
                        <option value="Parking Spot">Parking Spot</option>
                        <option value="Locker">Locker</option>
                      </Field>
                      <ErrorMessage
                        name="unit_type"
                        component="div"
                        className="text-danger m-2 fs--1"
                      />
                    </Col>
                  </Row>
                  <Row className="">
                    <Col md={6}>
                      <BSForm.Label>
                        Unit Number <span className="text-danger">*</span>
                      </BSForm.Label>
                      <Field
                        type="number"
                        name="unit_number"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="unit_number"
                        component="div"
                        className="text-danger m-2 fs--1"
                      />
                    </Col>
                    <Col md={6}>
                      <BSForm.Label>
                        % CEF <span className="text-danger">*</span>
                      </BSForm.Label>
                      <Field
                        type="number"
                        name="cef"
                        className="form-control"
                        onChange={(e) => handleCefChange(e, setFieldValue)}
                      />
                      <ErrorMessage
                        name="cef"
                        component="div"
                        className="text-danger m-2 fs--1"
                      />
                    </Col>
                  </Row>

                  {values.unit_type === "condo" && (
                    <>
                      <Row className="mb-3">
                        <Col className="d-none">
                          <BSForm.Label>
                            Primary Owner Email
                            <span className="text-danger"></span>
                          </BSForm.Label>
                          <Field
                            type="email"
                            name="Inviteduser"
                            className="form-control"
                            disabled
                            // hidden
                          />
                          <ErrorMessage
                            name="Inviteduser"
                            component="div"
                            className="text-danger m-2 fs--1"
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col>
                          <BSForm.Label>
                            Notes <span className="fs--1"></span>
                          </BSForm.Label>

                          <BSForm.Control
                            as={"textarea"}
                            type="text"
                            name="description"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.description}
                          />
                          <ErrorMessage
                            name="description"
                            component="div"
                            className="text-danger m-2 fs--1"
                          />
                        </Col>
                      </Row>

                      <br />
                      <span className="text-danger">
                        {isFull && "No room in units! remove some to move on!"}
                      </span>
                    </>
                  )}
                  {/* {(values.unit_type === "Parking Spot" ||
                    values.unit_type === "Locker") && (
                    <Row className="mb-3">
                      <Col>
                        <BSForm.Label>
                          Attach to Condo Unit
                          <span className="text-danger"></span>
                        </BSForm.Label>
                        <Field
                          component="select"
                          id="attachtoUnit"
                          name="attachtoUnit"
                          className="form-select"
                          // multiple={true}
                        >
                          <option value="">Select</option>
                          {unitsForCondo}
                        </Field>
                        
                        <ErrorMessage
                          name="attachtoUnit"
                          component="div"
                          className="text-danger m-2 fs--1"
                        />
                      </Col>
                    </Row>
                  )} */}
                  <br />
                  <button
                    type="submit"
                    disabled={isSubmitting && isFull}
                    className="btn btn-primary float-end"
                  >
                    {payload.loadType?.split(" ")[0]}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        )}

        {payload.loadType === "Attach Unit" && (
          <div>
            <Formik
              initialValues={{
                unit_number: payload.unit_number || "",
                unit_type: payload.unit_type || "condo",
                cef: payload.cef || "",
                description: payload.description || "",
                Inviteduser: payload.Inviteduser || "",
                attachtoUnit: payload.attachedToUnit?._id || "",
              }}
              validate={(values) => {
                const errors = {};
                if (
                  values.unit_type !== "condo" &&
                  payload.loadType === "Attach Unit" &&
                  !values.attachtoUnit
                ) {
                  errors.attachtoUnit = "please enter unit number to continue!";
                }
                if (
                  values.unit_type !== "condo" &&
                  payload.loadType === "Detach Unit" &&
                  !values.attachtoUnit
                ) {
                  errors.attachtoUnit = "please enter unit number to continue!";
                }
                console.log(errors);
                return errors;
              }}
              validationSchema={Yup.object().shape({
                unit_number: Yup.string().required(
                  "please enter unit number to continue!"
                ),
                cef: Yup.string().required("condo element fee is required!"),
                description: Yup.string("must be valid value!"),
                Inviteduser: Yup.string()
                  .email("Must be a valid email")
                  .max(255, "long email!"),
                unit_type: Yup.string().required("please select unit type!"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                if (headerData.cef + +values.cef > 100) {
                  setIsFull(true);
                  return;
                } else {
                  setIsFull(false);
                }
                // props.addUnitInfo(values);
                setSubmitting(false);
                createNewUnit(values);
              }}
            >
              {({
                isSubmitting,
                handleChange,
                handleBlur,
                values,
                setFieldValue,
              }) => (
                <Form>
                  <Row className="mb-3">
                    <Col>
                      <BSForm.Label>
                        Unit Type <span className="text-danger">*</span>
                      </BSForm.Label>
                      <Field
                        // component="select"
                        id="unit_type"
                        name="unit_type"
                        className="form-control"
                        disabled
                      >
                        {/* <option value="">Select unit type</option> */}
                        {/* <option value="condo">Condo</option>
                        <option value="Parking Spot">Parking Spot</option>
                        <option value="Locker">Locker</option> */}
                      </Field>
                      <ErrorMessage
                        name="unit_type"
                        component="div"
                        className="text-danger m-2 fs--1"
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={6}>
                      <BSForm.Label>
                        Unit Number <span className="text-danger">*</span>
                      </BSForm.Label>
                      <Field
                        type="number"
                        name="unit_number"
                        className="form-control"
                        disabled
                      />
                      <ErrorMessage
                        name="unit_number"
                        component="div"
                        className="text-danger m-2 fs--1"
                      />
                    </Col>
                    <Col md={6}>
                      <BSForm.Label>
                        % CEF <span className="text-danger">*</span>
                      </BSForm.Label>
                      <Field
                        type="number"
                        name="cef"
                        className="form-control"
                        disabled
                        onChange={(e) => handleCefChange(e, setFieldValue)}
                      />
                      <ErrorMessage
                        name="cef"
                        component="div"
                        className="text-danger m-2 fs--1"
                      />
                    </Col>
                  </Row>

                  {values.unit_type === "condo" && (
                    <>
                      <Row className="mb-3">
                        <Col>
                          <BSForm.Label>
                            Primary Owner Email
                            <span className="text-danger"></span>
                          </BSForm.Label>
                          <Field
                            type="email"
                            name="Inviteduser"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="Inviteduser"
                            component="div"
                            className="text-danger m-2 fs--1"
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col>
                          <BSForm.Label>
                            Notes <span className="fs--1"></span>
                          </BSForm.Label>

                          <BSForm.Control
                            as={"textarea"}
                            type="text"
                            name="description"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.description}
                          />
                          <ErrorMessage
                            name="description"
                            component="div"
                            className="text-danger m-2 fs--1"
                          />
                        </Col>
                      </Row>

                      <br />
                      <span className="text-danger">
                        {isFull && "No room in units! remove some to move on!"}
                      </span>
                    </>
                  )}
                  {(values.unit_type === "Parking Spot" ||
                    values.unit_type === "Locker") && (
                    <Row className="mb-3">
                      <Col>
                        <BSForm.Label>
                          Attach to Condo Unit
                          <span className="text-danger"></span>
                        </BSForm.Label>
                        <Field
                          component="select"
                          id="attachtoUnit"
                          name="attachtoUnit"
                          className="form-select"
                          // multiple={true}
                        >
                          <option value="">Select</option>
                          {unitsForCondo}
                        </Field>
                        {/* <Select
                        defaultValue={[options[2], options[3]]}
                        isMulti
                        name="colors"
                        options={options}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      /> */}
                        <ErrorMessage
                          name="attachtoUnit"
                          component="div"
                          className="text-danger m-2 fs--1"
                        />
                      </Col>
                    </Row>
                  )}
                  <br />
                  <button
                    type="submit"
                    disabled={isSubmitting && isFull}
                    className="btn btn-primary float-end"
                  >
                    {payload.loadType?.split(" ")[0]}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        )}

        {payload.loadType === "Add Unit Owner" && (
          <div>
            <Formik
              initialValues={{
                email: "",
                unit_id: payload._id,
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Must be a valid email")
                  .max(255)
                  .required("Email is required"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                // console.log(values);
                setSubmitting(false);
                addunitowner(values);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Row className="mb-3">
                    <Col>
                      <BSForm.Label>
                        Primary Owner Email{" "}
                        <span className="text-danger">*</span>
                      </BSForm.Label>
                      <Field
                        type="email"
                        name="email"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-danger m-2 fs--1"
                      />
                    </Col>
                  </Row>

                  <br />

                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-primary float-end"
                  >
                    Add
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        )}

        {payload.loadType === "Change Unit Owner" && (
          <div>
            <Formik
              initialValues={{
                email: payload.userinfo.email,
                unit_id: payload._id,
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Must be a valid email")
                  .max(255)
                  .required("Email is required"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                // console.log(values);
                setSubmitting(false);
                changeunitowner(values);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Row className="mb-3">
                    <Col>
                      <BSForm.Label>
                        Primary Owner Email{" "}
                        <span className="text-danger">*</span>
                      </BSForm.Label>
                      <Field
                        type="email"
                        name="email"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-danger m-2 fs--1"
                      />
                    </Col>
                  </Row>

                  <br />

                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-primary float-end"
                  >
                    Change
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        )}

        {payload.loadType === "Reinvite Unit Owner" && (
          <div>
            <Formik
              initialValues={{
                email: payload.Inviteduser,
                unit_id: payload._id,
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Must be a valid email")
                  .max(255)
                  .required("Email is required"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                // console.log(values);
                setSubmitting(false);
                reinviteunitowner(values);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Row className="mb-3">
                    <Col>
                      <BSForm.Label>
                        Primary Owner Email{" "}
                        <span className="text-danger">*</span>
                      </BSForm.Label>
                      <Field
                        type="email"
                        name="email"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-danger m-2 fs--1"
                      />
                    </Col>
                  </Row>

                  <br />

                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-primary float-end"
                  >
                    Reinvite
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        )}

        {payload.loadType === "Detach Unit" && (
          <div>
            <div>
              <p className="" style={{ fontSize: "15px" }}>
                Do you want to detach unit {payload.unit_number} (
                {payload.unit_type}) with Condo unit{" "}
                {payload.attachedToUnit?.unit_number}?
              </p>
              <div>
                <div className="text-end">
                  <Button
                    variant="falcon-warning"
                    className="me-3 "
                    size="md"
                    onClick={detachunit}
                    style={{ width: 80 }}
                  >
                    Yes
                  </Button>
                  <Button
                    variant="falcon-default"
                    size="md"
                    onClick={handleClose}
                    style={{ width: 80 }}
                  >
                    No
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}

        {payload.loadType === "Attach Unit " && (
          <div>
            <Formik
              initialValues={{
                unit_number: payload.unit_number || "",
                unit_type: payload.unit_type || "condo",
                attachtoUnit: payload.attachedToUnit?._id || "",
              }}
              validate={(values) => {
                const errors = {};

                console.log(errors);
                return errors;
              }}
              validationSchema={Yup.object().shape({
                unit_number: Yup.string().required(
                  "please select unit to continue!"
                ),
                unit_type: Yup.string().required("please select unit type!"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                attachCurrentUnitFromDetail(values);
                setSubmitting(false);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Row className="mb-3">
                    <Col>
                      <BSForm.Label>
                        Unit Type <span className="text-danger">*</span>
                      </BSForm.Label>
                      <Field
                        component="select"
                        id="unit_type"
                        name="unit_type"
                        className="form-select"
                        // disabled
                      >
                        <option value="">Select unit type</option>
                        {/* <option value="condo">Condo</option> */}
                        <option value="Parking Spot">Parking Spot</option>
                        <option value="Locker">Locker</option>
                      </Field>
                      <ErrorMessage
                        name="unit_type"
                        component="div"
                        className="text-danger m-2 fs--1"
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={12}>
                      <BSForm.Label>
                        Select Unit to Attach{" "}
                        <span className="text-danger">*</span>
                      </BSForm.Label>
                      <Field
                        component="select"
                        id="unit_number"
                        name="unit_number"
                        className="form-select"
                        // disabled
                      >
                        <option value="">Select</option>
                        {payload?.availabeFacility?.map((v) => (
                          <option value={v._id} key={v._id}>
                            {v.unit_number}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="unit_number"
                        component="div"
                        className="text-danger m-2 fs--1"
                      />
                    </Col>
                  </Row>

                  <br />
                  <button
                    type="submit"
                    disabled={isSubmitting && isFull}
                    className="btn btn-primary float-end"
                  >
                    {payload.loadType?.split(" ")[0]}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        )}

        {payload.loadType === "Add Bulk Units" && (
          <div>
            <ul style={{ lineHeight: "30px" }}>
              <li>
                Download our provided template to ensure all required fields are
                included and formatted correctly.
              </li>

              <li>
                Enter the details for each unit in the appropriate columns.
              </li>

              <li>
                {" "}
                "The system will check your file for errors. If errors are
                found, a list will be displayed below this page. Please correct
                the errors and re-upload the file."
              </li>

              <li>
                {" "}
                Once your file is validated successfully, it is uploaded to the
                System.
              </li>
              <li> Here is the file to download below.</li>
              <li className="text-warning">
                {" "}
                Do not change the column headings or add columns in bulk unit
                file.
              </li>
              <li className="text-warning">
                If finding duplicate entries, only the first entry will be kept,
                and the additional duplicates will be ignored.
              </li>
            </ul>

            <div>
              <div>
                <p className="ms-3">
                  <a href={BUF} download="Bulk Units Template.xlsx">
                    Bulk Units Template.xlsx
                  </a>{" "}
                  <br />
                  {/* <small className="text-danger">
                    Warning: Do not change the column headings or add columns in
                    bulk unit file.
                  </small> */}
                </p>
                <div className="mx-3">
                  <h4>Or</h4>
                  <p>
                    If you have completed above steps, Upload Filled file by
                    clicking below.
                  </p>
                  <div className="d-flex justify-content-center align-items-center">
                    <label className="btn btn-primary">
                      <input
                        type="file"
                        hidden
                        onChange={handleBulkUnitFile}
                        // value={unitBulkFile.current}
                      />
                      Upload Updated Exel file
                    </label>
                    <br />
                    <br />
                    {loader && <Loader noHeight />}
                    <br />
                  </div>
                </div>
              </div>

              <div>
                {unitBulkFileErrors?.length > 0 && (
                  <div>
                    <p className="text-danger">
                      Some errors found below! please correct them and upload
                      file again.
                    </p>
                    <Table className="text-center" bordered responsive>
                      <thead>
                        <tr>
                          <th>Line #</th>
                          <th>Unit #</th>
                          <th>Type</th>
                          <th>CEF </th>
                          <th>Owner</th>
                          <th>Attached To</th>
                        </tr>
                      </thead>
                      <tbody>
                        {unitBulkFileErrors?.map((v, i) => (
                          <tr key={v.unit_number + i}>
                            <td>{v.line_number}</td>
                            <td>{v.unit_number}</td>
                            <td>{v.unit_type}</td>
                            <td>{v.cef}</td>
                            <td>{v.unit_owner}</td>
                            <td>{v.attached_to}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <br />
                    <br />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
