import React from "react";

import { Card, Col, Row } from "react-bootstrap";

import ProfileUserTable from "./ProfileUserTable";
import StudentInfo from "./StudentInfo";
import UserGroups from "./UserGroups";
import FalconCloseButton from "../../../../components/common/FalconCloseButton";

import UsersManagementModel from "../../../../components/pages/sharedUI/UsersManagementModel";

const UserDetails = ({
  handleCloseUserDetailTab,
  currentUser,
  addUserToGroup,
  showChangeGroupDetailModal,
  handleCloseChangeGroupDetailModal,
  userData,
  userForGroup,
  getUsersData,
  payload,
  setPayload,
  jwtToken,
  hostname,
}) => {
  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="">
      <Row className="pe-lg-0 mb-3">
        <Col md={12}>
          <Card>
            <Card.Body className="d-flex justify-content-between">
              <h5>
                {`${currentUser.firstname}  ${currentUser.lastname}`}
                <span className="ms-3 badge bg-success">
                  {currentUser.groups?.includes("Site-Administrators")
                    ? "Site-Admin"
                    : ""}
                </span>
              </h5>
              <FalconCloseButton onClick={handleCloseUserDetailTab} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="">
        <Col md={8} className="pe-lg-0 mb-3">
          <StudentInfo currentUser={currentUser} />
        </Col>
        <Col md={4}>
          <ProfileUserTable currentUser={currentUser} />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <UserGroups
            currentUser={currentUser}
            addUserToGroup={addUserToGroup}
          />
        </Col>
      </Row>
      {/* <ChangeGroupModal
        show={showChangeGroupDetailModal}
        handleClose={handleCloseChangeGroupDetailModal}
        userData={userData}
        addUserToGroup={addUserToGroup}
        currentUser={userForGroup}
        getUsersData={getUsersData}
      /> */}
      <UsersManagementModel
        show={showChangeGroupDetailModal}
        handleClose={handleCloseChangeGroupDetailModal}
        userData={userData}
        getUsersData={getUsersData}
        payload={payload}
        setPayload={setPayload}
        handleCloseUserDetailTab={handleCloseUserDetailTab}
        hostname={hostname}
        jwtToken={jwtToken}
      />
    </div>
  );
};

export default UserDetails;
