import React from "react";
import { Button, Card } from "react-bootstrap";

import { ErrorMessage, Formik, Field } from "formik";
import FalconCardHeader from "../../../../../../components/common/FalconCardHeader";
export default function Billing({ updateAmenityBilling, amenityCurrent }) {
  try {
    // console.log(amenityCurrent);
    // const handleSubmit = event => {
    //   const form = event.currentTarget;
    //   if (form.checkValidity() === false) {
    //     event.preventDefault();
    //     event.stopPropagation();
    //   } else {
    //     event.preventDefault();
    //     setValidated(true);

    //     const { p_id, p_name, p_url, p_phone } = event.target;
    //     let property_id = p_id.value;
    //     let property_name = p_name.value;
    //     let property_url = p_url.value;
    //     let property_phone = p_phone.value;

    //     setUser(() =>
    //       Object.assign(
    //         { property_id, property_name, property_url, property_phone },
    //         user
    //       )
    //     );

    //     axios
    //       .post('http://localhost:5000/api/property/propertyverify', {
    //         property_id
    //       })
    //       .then(res => {
    //         console.log(res.data);
    //         toast.success(res.data.data, { theme: 'colored' });
    //         setTimeout(() => {
    //           setStep(step + 1);
    //         }, 500);
    //       })
    //       .catch(err => {
    //         console.log(err);
    //         toast.error(err.response.data.error, { theme: 'colored' });
    //       });
    //   }
    // };

    // const handleDisplayPaymentMethod = () => {};
    // let allow_cancel_old = '';

    // if(amenityCurrent.allow_cancel_lead === '24 Hours')
    // allow_cancel_old = '24h'

    let booking_type = amenityCurrent.type;
    return (
      <div>
        <Formik
          initialValues={{
            payment_type: amenityCurrent.payment_type || "",
            amenity_price: amenityCurrent.amenity_price || "",
            // payment_method: amenityCurrent.payment_method || [],
            // allow_cancel: amenityCurrent.allow_cancel_lead || '',
            payment_instructions_cash: amenityCurrent.cash_description || "",
            payment_instructions_credit_card: "Ycard details for this type.",
            payment_instructions_check: amenityCurrent.check_description || "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.payment_type) {
              errors.payment_type = "payment type is required!";
            }
            if (values.payment_type && values.payment_type !== "No Fee") {
              if (!values.amenity_price) {
                errors.amenity_price = "price is required!";
              }
              if (!values.amenity_price.match(/^[0-9]*$/)) {
                errors.amenity_price = "only numbers are allowed!";
              }
              // let payment_method_arr = [];
              // payment_method_arr = [...values.payment_method];
              // if (payment_method_arr.length < 1) {
              //   errors.payment_method = 'plese select payment method!';
              // }
            }
            // if (!values.allow_cancel) {
            //   errors.allow_cancel = 'required option!';
            // }
            console.log(errors);
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            updateAmenityBilling(values);
            // console.log(values);
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            // setValues
            /* and other goodies */
          }) => (
            <div className="">
              <div className="">
                <Card style={{ minHeight: "300px" }} className="mb-3">
                  <FalconCardHeader title="Amenity Billing" />
                  <Card.Body className="bg-light">
                    <form onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label>Payment Type *</label>
                        <Field
                          component="select"
                          id="payment_type"
                          name="payment_type"
                          multiple={false}
                          className="form-select"
                        >
                          <option value="">select type</option>
                          <option value="No Fee">No Fee</option>
                          {booking_type === "Hotel Style" && (
                            <option value="Night Fee">Night Fee</option>
                          )}
                          {booking_type === "Daily" && (
                            <option value="Daily">Daily Rate</option>
                          )}
                          {booking_type === "Time Slots" && (
                            <option value="Per Hour">Hourly Rate</option>
                          )}
                          {/* {booking_type === "Hotel Style" ? (
                            <option value="Night Fee">Night Fee</option>
                          ) : (
                            <>
                              <option value="Flat Rate">Flat Rate</option>
                              <option value="Per Hour">Hourly Rate</option>
                              <option value="Daily">Daily Rate</option>
                            </>
                          )} */}
                        </Field>
                        <ErrorMessage
                          name="payment_type"
                          component={"div"}
                          className="text-danger fs--1"
                        />
                      </div>
                      {values.payment_type !== "No Fee" && (
                        <>
                          <div className="mb-3">
                            <label>Amenity Price *</label>
                            <input
                              type="text"
                              name="amenity_price"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.amenity_price}
                              className="form-control"
                            />
                            <ErrorMessage
                              name="amenity_price"
                              component={"div"}
                              className="text-danger fs--1"
                            />
                          </div>
                          {/* <div className="mb-3">
                      <label>Payment Method *</label>
                      <Field
                        component="select"
                        id="payment_method"
                        name="payment_method"
                        multiple={true}
                        className="form-select"
                        >
                        <option value="">Select method</option>
                        <option value="Cash">Cash</option>
                        <option value="Online Payment">Online Payment</option>
                        <option value="Certified Cheque">
                        Certified Cheque
                        </option>
                        </Field>
                        <ErrorMessage
                        name="payment_method"
                        component={'div'}
                        className="text-danger fs--1"
                        />
                      </div> */}
                          {/* <div className="mb-3">
                            <label>Payment Methods</label>
                            <br />

                            <div className="form-check form-check-inline">
                              <Field
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox2"
                                value="Credit Card"
                                name="payment_method"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox2"
                              >
                                Credit Card
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <Field
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox3"
                                value="Check"
                                name="payment_method"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox3"
                              >
                                Check
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <Field
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                value="Cash"
                                name="payment_method"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Cash
                              </label>
                            </div>
                            <ErrorMessage
                              name="payment_method"
                              className="fs--1 text-danger"
                              component={'div'}
                            />
                          </div> */}
                        </>
                      )}
                      {/* {values.payment_method?.map(v => {
                        if (v !== 'Credit Card')
                          return (
                            <div className="mb-3" key={v + ';83'}>
                              <label>Payment Instructions for {v}</label>
                              <textarea
                                rows={5}
                                type="text"
                                name={`payment_instructions${
                                  v === 'Cash'
                                    ? '_cash'
                                    : v === 'Credit Card'
                                    ? 'credit_card'
                                    : v === 'Check'
                                    ? '_check'
                                    : ''
                                }`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  values[
                                    `payment_instructions${
                                      v === 'Cash'
                                        ? '_cash'
                                        : v === 'Credit Card'
                                        ? 'credit_card'
                                        : v === 'Check'
                                        ? '_check'
                                        : ''
                                    }`
                                  ]
                                }
                                className="form-control"
                              />
                              <ErrorMessage
                                name={`payment_instructions${
                                  v === 'Cash'
                                    ? '_cash'
                                    : v === 'Credit Card'
                                    ? '_credit_card'
                                    : v === 'Check'
                                    ? '_check'
                                    : ''
                                }`}
                                component={'div'}
                                className="text-danger fs--1"
                              />
                            </div>
                          );
                      })} */}

                      {/* <div className="mb-3">
                        <label>Allow Cancel *</label>
                        <Field
                          component="select"
                          id="allow_cancel_1"
                          name="allow_cancel"
                          multiple={false}
                          className="form-select"
                          value={values.allow_cancel}
                        >
                          <option value="">Select </option>
                          <option value="Until Booking Begins">
                            Until Booking Begins
                          </option>
                          <option value="24 Hours">24 Hour</option>
                          <option value="48 Hours">48 Hours</option>
                        </Field>
                        <ErrorMessage
                          name="allow_cancel"
                          component={'div'}
                          className="text-danger fs--1"
                        />
                      </div> */}
                      <br />
                      <div>
                        <Button
                          variant="info"
                          className=" float-end"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Update
                        </Button>
                      </div>
                    </form>
                  </Card.Body>
                </Card>
              </div>
            </div>
          )}
        </Formik>
      </div>
    );
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
}
