// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CardDropdown from "../../../../../components/common/CardDropdown";
import IconButton from "../../../../../components/common/IconButton";
import React from "react";
import {
  Button,
  // Card,
  Form,
  FormControl,
  InputGroup,
  Dropdown,
  Table,
} from "react-bootstrap";
// import PropTypes from 'prop-types';
import SimpleBarReact from "simplebar-react";

const TicketRow = ({
  item,
  handleChange,
  handleRemove,
  propLength,
  event,
  enableTicket,
  disableTicket,
}) => {
  return (
    <tr style={{ height: propLength < 4 ? "80px" : "" }}>
      <td>
        {item.name}{" "}
        {item.checked && (
          <div className="badge bg-success badge-sm">Default</div>
        )}
        {!item.enabled && (
          <div className="badge bg-secondary badge-sm">disabled</div>
        )}
      </td>
      <td>${item.price}</td>

      {propLength > 1 && (
        <td className="text-center align-middle">
          <CardDropdown>
            <div className="py-2">
              <Dropdown.Item
                className="text-info"
                onClick={() => handleChange(item)}
              >
                Edit
              </Dropdown.Item>
              {/* {event.event_ticket.event_sold_tickets_total < 1 && ( */}
              <Dropdown.Item
                className="text-danger"
                onClick={() => handleRemove(item)}
              >
                Delete
              </Dropdown.Item>
              {/*  )} */}
              {!item.checked && (
                <Dropdown.Item
                  className="text-success"
                  onClick={() => handleChange({ ...item, checked: true })}
                >
                  Make Default
                </Dropdown.Item>
              )}
              {item.enabled ? (
                <Dropdown.Item
                  className="text-warning"
                  onClick={() => disableTicket(item)}
                >
                  Disable
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  className="text-success"
                  onClick={() => enableTicket(item)}
                >
                  Enable
                </Dropdown.Item>
              )}
            </div>
          </CardDropdown>
        </td>
      )}
    </tr>
  );
};

const TicketsEditPriceTable = ({
  tickets = [],
  priceErrors,
  from,
  addTicketD,
  removeTicketD,
  updateTicketD,
  actionData,
  setActionData,
  getAllTicketsData,
  enableTicket,
  disableTicket,
  event,
}) => {
  console.log(tickets);

  const [ticketFields, setTicketFields] = React.useState({
    name: "",
    price: 0.0,
    checked: false,
  });

  // Change Ticket
  const changeTicket = (item) => {
    setActionData({
      type: "Update",
      isShown: true,
      currentList: tickets,
      currentEntry: { name: "", price: 0, checked: false },
    });
    console.log(item);
    setTicketFields({ ...item });
  };

  // Remove Ticket
  const removeTicket = (item) => {
    // setActionData({
    //   type: "Remove",
    //   isShown: true,
    //   currentList: tickets,
    //   currentEntry: { name: "", price: 0, checked: false },
    // });
    removeTicketD(item);
  };

  const handleAddTicket = () => {
    setActionData({
      type: "Add",
      isShown: true,
      currentList: tickets,
      currentEntry: { name: "", price: 0, checked: false },
    });
  };

  return (
    <div className="mb-3">
      <SimpleBarReact>
        <Table className={`bg-white mb-2 dark__bg-1100 mt-3`} bordered>
          <thead>
            <tr className="fs--1">
              <th scope="col">Option Name</th>
              <th scope="col">Price</th>
              <th></th>
            </tr>
          </thead>
          <tbody className="event-ticket-body">
            {tickets?.map((item, index) => (
              <TicketRow
                item={item}
                key={index}
                handleChange={changeTicket}
                handleRemove={removeTicket}
                propLength={tickets.length}
                event={event}
                enableTicket={enableTicket}
                disableTicket={disableTicket}
              />
            ))}
          </tbody>
        </Table>
      </SimpleBarReact>
      {(actionData.type === "Add" || actionData.type === "Update") && (
        <Table className="bg-white mb-2 dark__bg-1100 mt-3" bordered>
          <tbody>
            <tr>
              <td>
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="Option Name"
                  value={ticketFields.name}
                  onChange={({ target }) =>
                    setTicketFields({ ...ticketFields, name: target.value })
                  }
                  required
                />
                {/* <div className="text-danger fs--1">{priceError?.name}</div> */}
              </td>
              <td>
                <InputGroup size="sm">
                  <InputGroup.Text className="d-none d-sm-block">
                    $
                  </InputGroup.Text>
                  <FormControl
                    type="number"
                    placeholder="Price"
                    aria-label="Dollar amount (with dot and two decimal places)"
                    step="0.01"
                    value={ticketFields.price}
                    onChange={({ target }) =>
                      setTicketFields({ ...ticketFields, price: target.value })
                    }
                    required
                  />
                </InputGroup>
                {/* <div className="text-danger fs--1">{priceError?.price}</div> */}
              </td>
              <td className="text-center align-middle">
                <Form.Check
                  type="checkbox"
                  // id={`ticketChecked${id + 1}`}
                  defaultChecked={ticketFields.checked}
                  onChange={({ target }) =>
                    setTicketFields({
                      ...ticketFields,
                      checked: !ticketFields.checked,
                    })
                  }
                />
              </td>
            </tr>
          </tbody>
        </Table>
      )}
      <div className="mt-3"></div>
      <div className="d-flex justify-content-between align-items-center">
        <IconButton
          onClick={handleAddTicket}
          variant="falcon-default"
          size="sm"
          icon="plus"
          transform="shrink-3"
          disabled={actionData.type === "Add" || actionData.type === "Update"}
        >
          Add Item
        </IconButton>
        {actionData.type === "Add" && (
          <div>
            <Button
              onClick={() => {
                setActionData({ type: "", isShown: false });
                setTicketFields({ name: "", price: 0.0, checked: false });
              }}
              variant="secondary"
              size="sm"
            >
              Cancel
            </Button>
            <Button
              onClick={() => addTicketD(ticketFields, setTicketFields)}
              className="ms-3"
              variant="primary"
              size="sm"
              disabled={!ticketFields.name || !ticketFields.price}
            >
              Add
            </Button>
          </div>
        )}
        {actionData.type === "Update" && (
          <div>
            <Button
              onClick={() => {
                setActionData({ type: "", isShown: false });
                setTicketFields({ name: "", price: 0.0, checked: false });
              }}
              variant="secondary"
              size="sm"
            >
              Cancel
            </Button>
            <Button
              onClick={() => updateTicketD(ticketFields, setTicketFields)}
              className="ms-3"
              variant="primary"
              size="sm"
              disabled={!ticketFields.name || !ticketFields.price}
            >
              Update
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

// TicketRow.propTypes = {
//   name: PropTypes.string.isRequired,
//   price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
//   checked: PropTypes.bool,
//   id: PropTypes.number.isRequired,
//   handleRemove: PropTypes.func.isRequired,
//   handleChange: PropTypes.func.isRequired
// };

export default TicketsEditPriceTable;
