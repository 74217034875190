// import React, { useState, useEffect } from 'react';
import React from "react";
import { Card } from "react-bootstrap";
import Table from "react-bootstrap/Table";

function GroupDetailsComponent({ group, users }) {
  console.log(users);

  try {
    return (
      <Card style={{ minHeight: "90vh" }}>
        <Card.Body>
          {/* <CloseButton
            className="float-end"
            onClick={() => navigate('/admin/management/groups')}
          /> */}
          <h5 className="text-center">
            {group === "Condozak-Basic-Users"
              ? "Condozak-Users"
              : group || "group"}
          </h5>
          <br />
          <Table striped responsive bordered hover className="text-center">
            <thead>
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                {/* <th>Status</th> */}
                {/* <th>Actions</th> */}
              </tr>
            </thead>
            <tbody>
              {users?.map(
                (group_user, i) =>
                  group_user.Status === "1" &&
                  group_user.verified === "Verified" && (
                    <tr key={i + group_user + "98"}>
                      <td>{i + 1}</td>
                      <td>{group_user.firstname}</td>
                      <td>{group_user.lastname}</td>
                      <td>{group_user.email}</td>
                      {/* <td>
                  <SoftBadge pill bg={group_user.status.type}>
                    {group_user.status.title}
                    <FontAwesomeIcon
                      icon={group_user.status.icon}
                      className="ms-2"
                    />
                  </SoftBadge>
                </td> */}
                      {/* <td>
                    {!group_user.isLoggedInUser ? (
                      <CardDropdown>
                        <div className="py-2">
                          <Dropdown.Item
                            className="text-danger"
                            onClick={() =>
                              deleteGroup(
                                group_user.user_id,
                                group_user.groups[0]
                              )
                            }
                          >
                            delete group
                          </Dropdown.Item>
                        </div>
                      </CardDropdown>
                    ) : null}
                  </td> */}
                    </tr>
                  )
              )}
            </tbody>
          </Table>
          {/* <DeleteGroupGroup
          show={show}
          handleClose={handleClose}
          userData={userData}
          group={group}
          getUsersnGroupsData={getUsersnGroupsData}
        /> */}
        </Card.Body>
      </Card>
    );
  } catch (e) {
    console.log(e);
  }
}

export default GroupDetailsComponent;
