import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Avatar from 'components/common/Avatar';
// import Image from './Image';
// import SoftBadge from "components/common/SoftBadge";
// import { Badge, Table, Dropdown } from 'react-bootstrap';
import { Button, Dropdown, Form } from "react-bootstrap";
import { Table } from "react-bootstrap";

import CardDropdown from "../../../../components/common/CardDropdown";
import { formatLogInfo, getFileType, getFileExtData } from "../../../../helpers/utils";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
import { Avatar } from "@mui/material";
// import { startCase } from "lodash";
import {
  ControlCamera,
  Delete,
  Download,
  Edit,
  Undo,
  Visibility,
} from "@mui/icons-material";
import Loader from "../../../../components/common/Loader";
// import makeStyles from '@emotion/styled';

const TableRow = ({
  data,
  propLength,
  bulkFiles,
  addBulkAction,
  renameAction,
  deleteAction,
  viewFolderContents,
  moveAction,
  handleShowPreviewComponent,
}) => {
  console.log(data.extention);

  return (
    <tr
      className="align-middle"
      style={{
        height: propLength === 1 ? "200px" : propLength === 2 ? "150px" : "",
      }}
    >
      <td className="fw-bold">
        <Form.Check
          type="checkbox"
          id={data._id}
          onChange={() =>
            addBulkAction({ _id: data._id, file_name: data.file_name })
          }
          checked={bulkFiles?.some((v) => v._id === data._id)}
        />
      </td>
      <td className="fw-bold">
        <div
          className="d-flex align-items-center cursor-pointer"
          onClick={() =>
            data.file_type === "folder" ? viewFolderContents(data) : ""
          }
        >
          {data.file_type === "file" && (
            <span className="me-3" style={{ width: "25px" }}>
              {/* {getFileType({ mimetype: data.mimetype }, true)} */}
              {getFileExtData(data.extention)}
            </span>
          )}
          {data.file_type === "folder" && (
            <span className="me-3" style={{ width: "30px" }}>
              {getFileType({ mimetype: data.mimetype }, true)}
            </span>
          )}
          <span
            onClick={() =>
              data.file_type === "file" ? handleShowPreviewComponent(data) : ""
            }
          >
            {data.file_name}
          </span>
          {/* <CardDropdown customTextMode customText={data.file_name}>
            <div className="py-2">
              <Dropdown.Item onClick={() => renameAction(data)}>
                <span className="me-3">
                  <Edit fontSize="small" />
                </span>
                Edit
              </Dropdown.Item>
              <Dropdown.Item onClick={() => moveAction(data)}>
                <span className="me-3">
                  <ControlCamera fontSize="small" />
                </span>
                Move
              </Dropdown.Item>
              {data.file_type === "folder" && (
                <Dropdown.Item onClick={() => viewFolderContents(data)}>
                  <span className="me-3">
                    <Visibility fontSize="small" />
                  </span>{" "}
                  View Inside
                </Dropdown.Item>
              )}
              <Dropdown.Item
                onClick={() => deleteAction(data)}
              >
                <span className="me-3">
                  <Delete fontSize="small" />
                </span>
                Delete
              </Dropdown.Item>
            </div>
          </CardDropdown> */}
        </div>
      </td>
      <td className="text-center">{formatLogInfo(data.createdAt, true)}</td>
      <td className="text-center ">
        <div className="d-flex align-items-center justify-content-center">
          <Avatar
            sx={{
              bgcolor: data?.bgColor || "silver",
              fontSize: "14px",
              // width: "100px",
              // height: "100px",
            }}
            className="img-thumbnail"
          >
            {data.first_name[0] + data.last_name[0]}
          </Avatar>
          <span className="ms-3">{`${data.first_name} ${data.last_name}`}</span>
        </div>
      </td>
      <td className="text-center">
        {data.file_type === "folder" && "-"}
        {data.file_type === "file" && (
          <span className={data.isExpiredDate ? "text-danger" : ""}>
            {data.expiry_date !== null
              ? formatLogInfo(data.expiry_date, true)
              : "-"}
          </span>
        )}
      </td>
      <td className="text-center">
        <CardDropdown>
          <div className="py-2">
            <Dropdown.Item
              className="text-primary"
              onClick={() => renameAction(data)}
            >
              <span className="me-3">
                <Edit fontSize="small" />
              </span>
              Edit
            </Dropdown.Item>
            <Dropdown.Item
              // className="text-primary"
              onClick={() => moveAction(data)}
            >
              <span className="me-3">
                <ControlCamera fontSize="small" />
              </span>
              Move
            </Dropdown.Item>
            {data.file_type === "folder" && (
              <Dropdown.Item onClick={() => viewFolderContents(data)}>
                <span className="me-3">
                  <Visibility fontSize="small" />
                </span>{" "}
                View Inside
              </Dropdown.Item>
            )}
            {data.file_type === "file" && (
              <Dropdown.Item
                onClick={() => handleShowPreviewComponent(data, true)}
              >
                <span className="me-3">
                  <Download fontSize="small" />
                </span>{" "}
                Download
              </Dropdown.Item>
            )}
            <Dropdown.Item
              className="text-danger"
              onClick={() => deleteAction(data)}
            >
              <span className="me-3">
                <Delete fontSize="small" />
              </span>
              Delete
            </Dropdown.Item>
          </div>
        </CardDropdown>
      </td>
    </tr>
  );
};

const DocumentDriveTable = ({
  list,
  bulkFiles,
  addBulkAction,
  renameAction,
  deleteAction,
  tableLoader,
  viewFolderContents,
  DriveTree,
  goBack,
  moveAction,
  handleShowPreviewComponent,
}) => {
  return (
    <>
      {tableLoader && (
        <div>
          <Loader />
        </div>
      )}
      {!tableLoader && (
        <Table
          responsive
          //striped
          hover
          //bordered
          style={{ fontSize: "14px" }}
          className="pb-5"
        >
          <thead>
            <tr>
              <th scope="col" className="fw-bold">
                {/* {DriveTree?.length > 1 && (
                  <Button variant="falcon-default" size="sm" onClick={goBack}>
                    <Undo fontSize="small" />
                  </Button>
                )} */}
              </th>
              <th scope="col" className="fw-bold">
                Name
              </th>
              <th scope="col" className="text-center fw-bold">
                Uploaded On
              </th>
              <th scope="col" className="text-center fw-bold">
                Uploaded By
              </th>
              <th scope="col" className="text-center fw-bold">
                Expiry
              </th>
              <th scope="col" className="text-center fw-bold">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {list?.map((item) => (
              <TableRow
                data={item}
                key={item._id}
                propLength={list.length}
                bulkFiles={bulkFiles}
                addBulkAction={addBulkAction}
                renameAction={renameAction}
                deleteAction={deleteAction}
                viewFolderContents={viewFolderContents}
                moveAction={moveAction}
                handleShowPreviewComponent={handleShowPreviewComponent}
              />
            ))}
            {list.length < 0 && (
              <tr>
                <td>
                  <div style={{ minHeight: "40vh" }}>
                    <p>This Folder is empty</p>
                    <p>try adding some files</p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default DocumentDriveTable;
