import Modal from "react-bootstrap/Modal";
import { Card, Table } from "react-bootstrap";
import { toast } from "react-toastify";

import axios from "axios";
import VisitorWizard from "./wizard/Wizard";
import { formatLogInfo } from "../../../../../helpers/utils";
// import FalconCardHeader from "components/common/FalconCardHeader";

function formatTime(timeObj) {
  if (timeObj.$d) {
    return timeObj.$d.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  }
  return timeObj.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
}
export default function VisitorsModal({
  show,
  handleClose,
  payload,
  setPayload,
  getAllData,
  userData,
  hostname,
  jwtToken,
}) {
  console.log(payload);

  const handleNewVisitor = (values) => {
    console.log(values);
    // return;
    let visitorData = {
      p_id: payload.userData.propid,
      unit_id: payload.userData.UnitsOwner[0]._id,
      added_by_id: payload.userData.data,
      name: values.visitor_name,
      category: values.visitor_category,
      email: values.visitor_email,
      phone: values.visitor_phone,
      arrival_date: values.start_date,
      arrival_time: values.start_time,
      dep_date: values.end_date,
      dep_time: values.end_time,
      req_images: values.visitor_profile_img,
    };



    axios
      .post(
        `${hostname}/api/property/AddVisitor`,
        {
          ...visitorData,
          formatedArrivalTime: formatTime(visitorData.arrival_time),
          formateddepTime: formatTime(visitorData.dep_time),
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.success("Visitor added.", { theme: "colored" });
        setPayload({});
        handleClose();
        getAllData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{payload.loadType}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {payload.loadType === "View User Details" && (
            <div>
              <Card>
                {/* <FalconCardHeader title="Booking Details" /> */}
                <Card.Body className="bg-light px-0">
                  <Table striped bordered>
                    <tbody>
                      <tr>
                        <td style={{ width: "40%" }}>Visitor ID</td>
                        <td className="fw-bold">{payload?._id?.slice(0, 8)}</td>
                      </tr>
                      <tr>
                        <td>Visitor Name</td>
                        <td className="fw-bold">{payload.name}</td>
                      </tr>
                      <tr>
                        <td>Visitor Category</td>
                        <td className="fw-bold">{payload.category}</td>
                      </tr>
                      <tr>
                        <td>Visitor Email </td>
                        <td className="fw-bold">{payload.email}</td>
                      </tr>
                      <tr>
                        <td>Visitor Contact #</td>
                        <td className="fw-bold">{payload.phone}</td>
                      </tr>
                      <tr>
                        <td>Arrival Date</td>
                        <td className="fw-bold">
                          {formatLogInfo(payload.arrival_date, true)}
                        </td>
                      </tr>
                      <tr>
                        <td>Arrival Time</td>
                        <td className="fw-bold">
                          {formatLogInfo(payload.arrival_time)?.split("-")[1]}
                        </td>
                      </tr>
                      <tr>
                        <td>Departure Date</td>
                        <td className="fw-bold">
                          {formatLogInfo(payload.dep_date, true)}
                        </td>
                      </tr>
                      <tr>
                        <td>Departure Time</td>
                        <td className="fw-bold">
                          {formatLogInfo(payload.dep_time)?.split("-")[1]}
                        </td>
                      </tr>
                      <tr>
                        <td>Profile Image</td>
                        <td>
                          {payload.picture ? (
                            <img
                              src={payload.picture}
                              // src={URL.createObjectURL(
                              //   payload.visitor_profile_img
                              // )}
                              width={130}
                              alt="condozak"
                            />
                          ) : (
                            "not uploaded!"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </div>
          )}
          {payload.loadType === "Add New Visitor" && (
            <VisitorWizard progressBar handleNewVisitor={handleNewVisitor} />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
