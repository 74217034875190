import React, { useEffect, useState } from "react";
import ProfileInfo from "./ProfileInfo";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
// import { RotatingLines } from "react-loader-spinner";
import { Col, Row, Card, Breadcrumb } from "react-bootstrap";
import ProfileImageProperty from "./ProfileImageProperty";
// import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import { dataURLtoFile } from "../../../../../components/pages/user/dataURLtoFile";
// import seperate from 'assets/img/team/avatar.png';
import AddressProperty from "./AddressProperty";
// import PropertyProfile from "./PropertyManager";
import PropertyManager from "./PropertyManager";
import { flatten} from "lodash";
import PMModel from "./PMModel";
import PropertyLetterHead from "./PropertyLetterHead";
import Loader from "../../../../../components/common/Loader";
import PropertyProfileModal from "./PropertyProfileModal";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName } from "../../../../../helpers/utils";
import CustomBreadCrumbs from "../../../../common/CustomBreadCrumbs";
import startCase from "lodash/startCase";

export default function Profile() {
  const { property_id,property_name } = useParams();

  let hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const [propertyProfile, setPropertyProfile] = useState({});
  const [propertyProfileImage, setPropertyProfileImage] = React.useState({});
  const [propertyAddress, setPropertyAddress] = useState({});
  const [managerProfile, setManagerProfile] = useState([]);

  const [managersList, setManagersList] = useState([]);
  const [currentManagerID, setCurrentManagerID] = useState("");
  const [newManager, setNewManager] = useState({});

  const [loader, setLoader] = useState(false);

  const [propertyLetterHead, setPropertyLetterHead] = useState("");

  const [showPmModel, setShowPMModel] = React.useState(false);

  const handleClosePMModel = () => setShowPMModel(false);
  const handleShowPMModel = () => setShowPMModel(true);

  const [payload, setPayload] = React.useState({});

  // console.log(property_id);
  useEffect(() => {
    setLoader(true);
    getPropertyData(property_id);
    getManagersData();
    getPropertyLetterHeadData();
  }, []);

  const getPropertyData = (p_id) => {
    // console.log(p_id);
    if (p_id) {
      axios
        .post(
          `${hostname}/api/property/showproperty`,
          {
            p_id,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          setTimeout(() => {
            setLoader(false);
          }, 500);
          // console.log(res.data);
          setPropertyProfile(res.data.Propertyinfo.p_info);
          setPropertyProfileImage(res.data.Propertyinfo.p_info);
          localStorage.setItem("url", res.data.Propertyinfo.p_info.logo_url);
          // cookies.set('propertyDetail', res.data.Propertyinfo.p_info);
          // localStorage.setItem('propertyDetail',res.data)
          setPropertyAddress(res.data.Propertyinfo.p_address);
          setCurrentManagerID(res.data.Propertyinfo.p_info.property_manager);
        })
        .catch((err) => {
          setTimeout(() => {
            setLoader(false);
          }, 500);
          console.log(err);
        });
    }
  };

  const updateProperty = (
    property = {},
    oldProperty = {},
    forManagerUpdate = false,
    property_manager
  ) => {
    // console.log(property);
    // console.log(oldProperty);
    // return;
    // setLoader(true);
    let propertyData = {};

    if (forManagerUpdate) {
      // console.log(propertyProfile);
      propertyData.user_id = userData.data;
      propertyData.p_id = propertyProfile._id;
      propertyData.c_id = userData.compid;
      propertyData.property_id = propertyProfile.property_id;
      propertyData.property_name = propertyProfile.property_name;
      propertyData.property_url = propertyProfile.property_url?.toLowerCase();
      propertyData.phone = "00988765";
      propertyData.property_manager = property_manager;
    } else {
      propertyData.user_id = userData.data;
      propertyData.p_id = oldProperty._id;
      propertyData.c_id = userData.compid;
      propertyData.property_id = property.p_id;
      propertyData.property_name = property.p_name;
      propertyData.property_url = property.p_url?.toLowerCase();
      propertyData.phone = property.phone;
    }

    // console.log(propertyData);
    // return;

    if (userData.compid) {
      axios
        .post(
          `${hostname}/api/property/propertyUpdate`,
          {
            ...propertyData,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then(() => {
          getPropertyData(property_id);
          // setLoader(false);
          // console.log(res.data);

          toast.success("Details Updated", { theme: "colored" });
        })
        .catch((err) => {
          getPropertyData(property_id);
          setLoader(false);
          console.log(err);
          toast.error("Something went wrong!", { theme: "colored" });
        });
    }

    // console.log(propertyData);
  };

  const updateAddress = (address = {}) => {
    // setLoader(true);
    address = Object.assign(
      { p_id: property_id, user_id: userData.data },
      address
    );
    // console.log(address);
    // return;
    if (property_id) {
      axios
        .post(
          `${hostname}/api/property/propertyaddress`,
          {
            ...address,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then(() => {
          // console.log(res.data);
          getPropertyData(property_id);
          // setPropertyAddress({ ...address });
          setLoader(false);
          toast.success("Updated Successfully", { theme: "colored" });
        })
        .catch((err) => {
          getPropertyData(property_id);
          // setPropertyAddress({ ...address });
          setLoader(false);
          console.log(err);
          toast.error("Something went wrong!", { theme: "colored" });
        });
    }
  };

  // const img

  const [showButton, setShowButton] = useState(false);
  const [CancelButton, setCancelButton] = useState(false);

  const handleClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";

    input.onchange = (event) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        setPropertyProfileImage({ img: event.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
      setShowButton(true);
      setCancelButton(true);
    };

    input.click();
  };
  const handleSave = async () => {
    setShowButton(false);
    setCancelButton(false);
    setLoader(true);
    try {
      const data = dataURLtoFile(propertyProfileImage.img, "profile-picture");
      const formData = new FormData();
      formData.append("croppedImage", data);
      formData.append("comp_id", userData.compid);
      formData.append("prop_id", property_id);
      await axios.post(`${hostname}/api/property/propertylogo`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      setTimeout(() => {
        setLoader(false);
      }, 300);
      toast.success("Logo Updated Successfully", {
        theme: "colored",
      });
    } catch (error) {
      toast.error("Error" + error, {
        theme: "colored",
      });
      console.error(error);
    }
  };
  const handleCancel = async () => {
    setShowButton(false);
    setCancelButton(false);
    setLoader(false);
    setPropertyProfileImage({});
  };

  const handleDelete = () => {
    axios
      .post(
        `${hostname}/api/property/DeletePropertyLogo`,
        {
          p_id: property_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getManagersData = () => {
    axios
      .post(
        `${hostname}/api/auth/RoleTable`,
        {
          c_id: userData.compid,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        let usersTmpArry = [flatten(res.data.data), flatten(res.data.invited)];
        usersTmpArry = flatten(usersTmpArry);
        // console.log(usersTmpArry);
        // let arr = usersTmpArry?.map(v => (
        //   <option key={v._id} value={v._id}>{`${v.firstname} ${v.lastname}`}</option>
        // ));
        setManagerProfile([...usersTmpArry]);
        setManagersList([...usersTmpArry]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updatePropertyManager = (pm = {}) => {
    // console.log(manager);
    handleShowPMModel();
    
    setPayload(pm);
    // updateProperty({},{},true,manager);
  };

  // modal

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [payloadLH, setPayloadLH] = React.useState({});

  // const [letterHead,setLetterHead] = React.useState({});

  // addletterhead
  const addLetterHead = () => {
    let obj = {};
    obj.loadType = "Upload LetterHead";
    setPayloadLH({ ...obj });
    handleShow();
  };
  const viewLetterHead = () => {
    let obj = {};
    obj.loadType = "View/Change LetterHead";
    setPayloadLH({ ...obj });
    handleShow();
  };
  const deleteLetterHead = () => {
    let obj = {};
    obj.loadType = "Delete LetterHead";
    setPayloadLH({ ...obj });
    handleShow();
  };

  const getPropertyLetterHeadData = () => {
    axios
      .post(
        `${hostname}/api/property/GetLetterHead`,
        {
          p_id: property_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        // setPropertyLetterHead(res.data.Data);
        const blobFromFetch = `data:application/pdf;base64,${res.data.Data}`;

        setPropertyLetterHead(blobFromFetch);

        // var blob = new Blob([blobFromFetch], {type: "application/pdf"});

        // const blobUrl = URL.createObjectURL(blob);

        // setTimeout(() => {
        //   setLoader(false);
        // }, 500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (loader) {
    return <Loader />;
  } else {
    return (
      <div className="">
        <Row className="g-3 mb-3">
          <Col>
          <CustomBreadCrumbs
            links={[
              { path: "/dashboard", label: "Home" },
              { path: "/property/management/buildings", label: "Properties" },
              {
                path: `/property/management/buildings/${property_id}/${property_name}/profile`,
                label: startCase(property_name),
              },
              { path: "", label: "Profile" },
            ]} />
          </Col>
        </Row>
        <Row className="g-3">
          <Col lg={8} className="">
            <ProfileInfo
              property={propertyProfile}
              updateProperty={updateProperty}
              userData={userData}
            />
            <AddressProperty
              addressPropertyData={propertyAddress}
              updatePropertyAddress={updateAddress}
              userData={userData}
            />

            <PropertyLetterHead
              propertyLetterHead={propertyLetterHead}
              addLetterHead={addLetterHead}
              deleteLetterHead={deleteLetterHead}
              viewLetterHead={viewLetterHead}
            />
          </Col>
          <Col lg={4} className="">
            <div className="sticky-sidebar">
              <ProfileImageProperty
                propertyProfileImage={propertyProfileImage}
                propertyProfile={propertyProfile}
                // avatarProp={formData.avatar}
                showButton={showButton}
                CancelButton={CancelButton}
                // uploadedFileName={uploadedFileName}
                handleClick={handleClick}
                handleCancel={handleCancel}
                handleSave={handleSave}
                userData={userData}
                handleDelete={handleDelete}
              />
              <PropertyManager
                managerProfile={managerProfile} //all users
                propertyProfile={propertyProfile} //property profile
                updatePropertyManager={updatePropertyManager}
                managersList={managersList}
                currentManagerID={currentManagerID}
                newManager={newManager}
                setNewManager={setNewManager}
                userData={userData}
              />
            </div>
          </Col>
        </Row>

        <PMModel
          show={showPmModel}
          handleClose={handleClosePMModel}
          payload={payload}
          updateProperty={updateProperty}
          managerProfile={managerProfile}
          getPropertyData={getPropertyData}
          getManagersData={getManagersData}
          setPayload={setPayload}
          managersList={managersList}
          currentManagerID={currentManagerID}
          setCurrentManagerID={setCurrentManagerID}
          newManager={newManager}
          setNewManager={setNewManager}
          hostname={hostname}
          jwtToken={jwtToken}
        />
        <PropertyProfileModal
          show={show}
          handleClose={handleClose}
          payload={payloadLH}
          setPayload={setPayloadLH}
          property_id={property_id}
          getLetterHeadData={getPropertyLetterHeadData}
          propertyLetterHead={propertyLetterHead}
          setPropertyLetterHead={setPropertyLetterHead}
          hostname={hostname}
          jwtToken={jwtToken}
        />
      </div>
    );
  }
}
