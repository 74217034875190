import React from "react";

import {
  CloseButton,
  Col,
  Modal,
  Row,
  Dropdown,
  Button,
  Image,
  Nav,
  Form as BSForm,
} from "react-bootstrap";

import Flex from "../../../../components/common/Flex";
import { Link } from "react-router-dom";
import ModalMediaContent from "./ModalMediaContent";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { formatLogInfo } from "../../../../helpers/utils";
import { Avatar } from "@mui/material";
import axios from "axios";
// import Cookies from "universal-cookie";
import { Check, Clear } from "@mui/icons-material";
import { toast } from "react-toastify";
import { flatten, startCase } from "lodash";

import Fuse from "fuse.js";

export default function ServiceRequestsModel({
  show,
  handleClose,
  payload,
  setPayload,
  getAllData,
  hostname,
  jwtToken,
  userData,
}) {
  const [loader, setLoader] = React.useState(false);

  const [managersList, setManagersList] = React.useState([]);
  const [managersListTemp, setManagersListTemp] = React.useState([]);

  const [editFieldComment, setEditFieldComment] = React.useState({
    text: "",
    shown: false,
    show_id: "",
    defaultText: "",
  });

  React.useEffect(() => {
    getManagersData(userData.compid);
  }, []);

  const getManagersData = (compid) => {
    axios
      .post(
        `${hostname}/api/auth/RoleTable`,
        {
          c_id: compid,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
       // console.log(res.data);
        let usersTmpArry = [flatten(res.data.data), flatten(res.data.invited)];
        usersTmpArry = flatten(usersTmpArry);
        console.log(usersTmpArry);
        usersTmpArry = usersTmpArry?.filter(
          (v) => v.verified === "Verified" && v.Status === "1"
        );
        console.log(usersTmpArry);
        // let arr = usersTmpArry?.map((v) => (
        // <Dropdown.Item
        //   className="item-hover"
        //   onClick={() => handleAssigneField("Irshad Khan")}
        // >
        //   <div className="d-flex align-items-center">
        //     <Avatar
        //       sx={{
        //         height: "28px",
        //         width: "28px",
        //         fontSize: "12px",
        //         marginRight: "5px",
        //       }}
        //     >
        //       NH
        //     </Avatar>
        //     <span className="ms-3 fs--1">Irshad Khan</span>
        //   </div>
        // </Dropdown.Item>
        // <option key={v._id} value={v._id}>{`${v.firstname} ${v.lastname} ${
        //   v.groups.includes("Site-Administrators") ? "" : ""
        // }`}</option>
        // ));
        setManagersList([...usersTmpArry]);
        setManagersListTemp([...usersTmpArry]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // React.useEffect(() => {
  //   setLoader(true);
  //   setTimeout(() => {
  //     setLoader(false);
  //   }, 400);
  // }, [payload]);

  const [showImgOverlay, setShowImgOverlay] = React.useState({
    shown: false,
    src: "",
  });

  console.log(showImgOverlay);

  const [editField, showEditField] = React.useState(false);
  const [editFieldD, showEditFieldD] = React.useState(false);

  const createServiceRequest = (values = {}) => {
    // setLoader(true);
    console.log(values);

    let reqData = {
      request_type: values.request_type,
      other_request_type: "",
      subject: values.subject,
      description: values.description,
      priority_level: values.priority_level,
      unit_permission: values.unit_permission,
      req_images: values.req_images,
    };
    reqData.generated_by_id = userData.data;
    reqData.unit_id = payload?.unit_id;
    reqData.p_id = payload?.property_id;

    console.log(reqData);

    axios
      .post(
        `${hostname}/api/unit/CreateMaintenance`,
        { ...reqData },
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${jwtToken}`,

          },
        }
      )
      .then((res) => {
        console.log(res.data);

        // if (values.comment !== "") {
        //   addComment(values.comment, "", reporter.data);
        //   return;

        getAllData();
        setLoader(false);
        handleClose();
        toast.success("Request Added.", { theme: "colored" });
        setPayload({});
      })
      .catch((err) => {
        console.log(err);
        getAllData();
        setLoader(false);
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };

  const updateServiceRequest = (
    values = {},
    mediaDeleteRequest = false,
    extra = {}
  ) => {
    // setLoader(true);
    console.log(values);

    // request_type,
    //   assigned_to_id, ////if manager want to change this and assign to some other employee Send his id in this
    //   other_request_type,
    //   subject,
    //   description,
    //   priority_level,
    //   unit_permission,
    //   updated_by,
    //   status,

    let reqData = {
      request_type: values.request_type,
      other_request_type: "",
      subject: values.subject,
      description: values.description,
      priority_level: values.priority_level,
      unit_permission: values.unit_permission,
      status: extra?.title,
      assigned_to_id: values.assigne?._id,
      // req_images: values.req_images?.filter((v) => v.type !== "link"),
    };
    // reqData.generated_by_id = userData.data;
    reqData.unit_id = payload?.unit_id;
    reqData.p_id = payload?.property_id;
    reqData.updated_by = userData.data;
    reqData.maintenance_id = payload?._id;
    reqData.deleted_link = values.deleted_link;

    if (!mediaDeleteRequest) {
      reqData.req_images = [];

      values.req_images?.forEach((v) => {
        if (v.type !== "link") {
          reqData.req_images?.push(v.file);
        }
      });
    }

    // else {
    //   reqData.deleted_link = [];

    //   values.req_images?.forEach((v) => {
    //     if (v.type === "link") {
    //       reqData.deleted_link?.push(v.file);
    //     }
    //   });
    // }

    //console.log(reqData);
    // return;

    axios
      .post(
        `${hostname}/api/unit/UpdateMaintenance`,
        { ...reqData },
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${jwtToken}`,

          },
        }
      )
      .then(() => {
        //console.log(res.data);

        // if (values.comment !== "") {
        //   addComment(values.comment, "", reporter.data);
        //   return;
        getAllData(false);
        // setLoader(false);
        // handleClose();
        toast.success("Status Updated.", { theme: "colored" });
        // setPayload({});
      })
      .catch((err) => {
        console.log(err);
        getAllData(false);
        setLoader(false);
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };

  const addComment = (values = {}, setFieldValue = () => {}) => {
    let commentData = {
      maintenance_id: payload?._id,
      text: values.comment,
      user_id: payload?.userData.data, ///loggedin user
    };

    console.log(commentData);

    axios
      .post(`${hostname}/api/unit/AddComment`, { ...commentData },{
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        }
      })
      .then((res) => {
        //console.log(res.data);
        // setFieldValue('');
        let updatedComments = res.data.data[0].comments?.map((v) => {
          let inObj = {};
          inObj.text = v.text;
          inObj.comment_id = v._id;
          inObj.user_id = v.user_id._id;
          inObj.firstname = v.user_id.firstname;
          inObj.lastname = v.user_id.lastname;
          inObj.bgColor = v.user_id.bgColor;
          inObj.time = formatLogInfo(v.updatedAt);
          inObj.isEdited = v.isEdited;

          return inObj;
        });

        setFieldValue("comment", "");

        if (values.sortOrder === "asc")
          updatedComments.sort(dynamicSort("time"));
        if (values.sortOrder === "dsc")
          updatedComments.sort(dynamicSort("-time"));

        setFieldValue("comments", updatedComments);

        getAllData(false);
        // setLoader(false);
        // handleClose();

        toast.success("Comment Added.", { theme: "colored" });
      })
      .catch((err) => {
        console.log(err);
        getAllData(false);
        setLoader(false);
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };

  const updateComment = (comment = "", setFieldValue, values) => {
    let commentData = {
      maintenance_id: payload?._id,
      text: editFieldComment.text,
      user_id: payload?.userData.data,
      comment_id: comment.comment_id,
      ///loggedin user
    };

    console.log(commentData);

    axios
      .post(`${hostname}/api/unit/UpdateComment`, { ...commentData },{
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        }
      })
      .then((res) => {
        //console.log(res.data);
        getAllData(false);
        // setFieldValue("comment", "");
        let updatedComments = res.data.data[0].comments?.map((v) => {
          let inObj = {};
          inObj.text = v.text;
          inObj.comment_id = v._id;
          inObj.user_id = v.user_id._id;
          inObj.firstname = v.user_id.firstname;
          inObj.lastname = v.user_id.lastname;
          inObj.bgColor = v.user_id.bgColor;
          inObj.time = formatLogInfo(v.updatedAt);
          inObj.isEdited = v.isEdited;

          return inObj;
        });

        if (values.sortOrder === "asc")
          updatedComments.sort(dynamicSort("time"));
        if (values.sortOrder === "dsc")
          updatedComments.sort(dynamicSort("-time"));

        setFieldValue("comments", updatedComments);
        setEditFieldComment({
          text: "",
          shown: false,
          show_id: "",
          defaultText: "",
        });
        // setLoader(false);
        // handleClose();
        toast.success("Status Updated.", { theme: "colored" });
        // setPayload({});
      })
      .catch((err) => {
        console.log(err);
        getAllData(false);
        setLoader(false);
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };

  const deleteComment = (comment, setFieldValue, values) => {
    axios
      .post(`${hostname}/api/unit/DeleteComment`, {
        maintenance_id: payload?._id,
        comment_id: comment?.comment_id,
      },{
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        }
      })
      .then((res) => {
       // console.log(res.data);
        let updatedComments = res.data.data[0].comments?.map((v) => {
          let inObj = {};
          inObj.text = v.text;
          inObj.comment_id = v._id;
          inObj.user_id = v.user_id._id;
          inObj.firstname = v.user_id.firstname;
          inObj.lastname = v.user_id.lastname;
          inObj.bgColor = v.user_id.bgColor;
          inObj.time = formatLogInfo(v.updatedAt);
          inObj.isEdited = v.isEdited;

          return inObj;
        });

        if (values.sortOrder === "asc")
          updatedComments.sort(dynamicSort("time"));
        if (values.sortOrder === "dsc")
          updatedComments.sort(dynamicSort("-time"));

        setFieldValue("comments", updatedComments);

        getAllData(false);
        // setLoader(false);
        // handleClose();
        toast.warning("Comment Deleted.", { theme: "colored" });
        // setPayload({});
      })
      .catch((err) => {
        console.log(err);
        getAllData(false);
        setLoader(false);
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };

  const handleDynamicUpdateOwner = (
    values = {},
    fieldName = "",
    action = "create comment"
  ) => {
    console.log(values);
    console.log(fieldName);
    if (fieldName === "comment" && action === "create comment") {
      addComment(values.comment, payload?._id, payload?.userData?.data);
    }
    if (fieldName === "comment" && action === "delete comment") {
      // deleteComment(cm_id);
    }
  };

  const handleFiles = (e, setFieldValue, values) => {
    console.log(e.target.files);
    let data = [...e.target.files];
    data = data?.map((file) => {
      let entry = {};
      entry.tmp_id = uuidv4();
      entry.type = file.type?.split("/")[0];
      entry.file = file;

      return entry;
    });
    console.log(data);
    setFieldValue("req_images", values.req_images?.concat(data));
    e.target.value = null;
    let obj = { ...values };
    obj.req_images = values.req_images?.concat(data);
    console.log(obj);
    if (payload?.loadType === "View Request Owner") updateServiceRequest(obj);

    // setFieldValue("req_images", data);
    // setFiles(files?.concat(data));
  };

  const handleRemoveFile = (e, setFieldValue, values, id) => {
    let data = [];
    let todelete = {};
    data = values.req_images?.filter((v) => v.tmp_id !== id);
    todelete = values.req_images?.filter((v) => v.tmp_id === id)[0];
    // setFiles(data);
    todelete = todelete.file;
    console.log(data);
    console.log(todelete);
    setFieldValue("req_images", data);
    let obj = { ...values };
    obj.deleted_link = todelete;
    obj.req_images = data;

    if (payload?.loadType === "View Request Owner")
      updateServiceRequest(obj, true);
  };

  const validate = (values) => {
    let errors = [];
    values.req_images?.forEach((v) => {
      console.log(v);
      let obj = {};
      if (v.type !== "image" && v.type !== "audio" && v.type !== "video") {
        obj.msg = "invalid file type!";
        obj.id = v.tmp_id;
        errors.push(obj);
      }
    });
    console.log(errors);
    return errors;
  };

  const handleAssigneeUpdate = (manager, values, setFieldValue) => {
    console.log(manager);
    setFieldValue("assignee", manager);
    let data = { ...values };
    data.assigne = manager;
    updateServiceRequest(data);
  };

  const handleFilesActions = (file, actionType = "view") => {
    console.log(file);

    if (actionType === "view") {
      console.log("working fine");
      setShowImgOverlay({ shown: true, src: file });
    }

    if (actionType === "download") downloadFile(file);
    // window.open(file,"_blank");
  };

  function downloadFile(filePath) {
    var link = document.createElement("a");
    link.href = filePath;
    link.download = filePath.substr(filePath.lastIndexOf("/") + 1);
    link.click();
  }

  // if (loader)
  //   return (
  //     <Modal
  //       style={{ backdropFilter: "blur(3px)" }}
  //       show={show}
  //       // size={payload?.loadType === "Create Request" ? "md" : "lg"}
  //       size={"lg"}
  //       onHide={handleClose}
  //       contentClassName="border-0"
  //       dialogClassName=""
  //     >
  //       <Modal.Body style={{ minHeight: "100vh" }}>
  //         <Loader />
  //       </Modal.Body>
  //     </Modal>
  //   );

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  const revertSortOrder = (comments = [], setFieldValue, sortOrder = "") => {
    let arr = [...comments];
    let res = [];

    if (sortOrder === "asc") {
      setFieldValue("sortOrder", sortOrder);
      arr.sort(dynamicSort("time"));
    }
    if (sortOrder === "dsc") {
      setFieldValue("sortOrder", sortOrder);
      arr.sort(dynamicSort("-time"));
    }

    console.log(arr);
    setFieldValue("comments", arr);
  };

  const handleMiniSearch = (e) => {
    try {
      const fuse = new Fuse([...managersListTemp], {
        keys: ["firstname", "lastname"],
        isCaseSensitive: false,
      });

      let res = fuse.search(e.target.value);

      res = res.map((v) => {
        return v.item;
      });

      console.log(res);

      if (res.length) setManagersList([...res]);
      else setManagersList([...managersListTemp]);
    } catch (e) {
      console.log(e);
    }
  };

  if (showImgOverlay.shown)
    return (
      <Modal
        style={{ backdropFilter: "blur(3px)" }}
        show={show}
        size={"xl"}
        onHide={handleClose}
        contentClassName="border-0"
        dialogClassName=""
      >
        <div>
          <div className="d-flex justify-content-end p-3">
            <CloseButton
              onClick={() => {
                setShowImgOverlay({ shown: false, src: "" });
              }}
            />
          </div>
          <br />
          <div className="d-flex justify-content-center p-3">
            <Image
              src={
                showImgOverlay.src instanceof File
                  ? URL.createObjectURL(showImgOverlay.src)
                  : showImgOverlay.src
              }
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div>
      </Modal>
    );

  return (
    <Modal
      style={{ backdropFilter: "blur(3px)" }}
      show={show}
      size={"lg"}
      onHide={handleClose}
      contentClassName="border-0"
      dialogClassName=""
    >
      {payload?.loadType !== "Create Request" && (
        <div className="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
          <CloseButton
            onClick={handleClose}
            className="btn btn-sm btn-circle d-flex flex-center transition-base"
          />
        </div>
      )}

      <Modal.Body className={"p-0"}>
        {payload?.loadType === "Create Request" && (
          <>
            <Formik
              initialValues={{
                status: "Open",
                assignee: "",
                reporter: payload?.userData || "",
                request_type: "",
                other_request_type: "",
                subject: "",
                description: "",
                priority_level: "Medium",
                req_images: [],
                unit_permission: "No",
                comment: "",
                createdAt: payload?.generated_by_id?.createdAt || new Date(),
                updatedAt: new Date(),
              }}
              validate={(values) => {
                const errors = {};

                return errors;
              }}
              validationSchema={Yup.object().shape({
                request_type: Yup.string("Enter valid value").required(
                  "Please select request type!"
                ),
                // other_request_type: Yup.string("Enter valid value").required(
                //   "Please enter other request type!"
                // ),
                subject: Yup.string("Enter valid value!").required(
                  "Please enter subject!"
                ),
                // priority_level: Yup.string("Enter valid value").required(
                //   "select priority level!"
                // ),
                description: Yup.string("Enter valid value"),
                // img : Yup.string(''),
                // unit_permission: Yup.string("Enter valid value").required(
                //   "select access level!"
                // ),
              })}
              onSubmit={(values, { setSubmitting }) => {
                createServiceRequest(values);
                setSubmitting(false);
              }}
            >
              {({
                isSubmitting,
                values,
                setFieldValue,
                handleChange,
                handleBlur,
              }) => (
                <Form>
                  <div className="bg-light">
                    <div className="d-flex justify-content-between align-items-center px-3 pt-3 pb-0">
                      <h5>Create Request</h5>
                      <CloseButton
                        onClick={handleClose}
                        className="btn btn-sm btn-circle d-flex flex-center transition-base"
                      />
                    </div>
                    <br />
                  </div>
                  <div className="p-4">
                    <Row>
                      <Col lg={8}>
                        <ModalMediaContent
                          title="Request Type"
                          icon="align-left"
                          isHr={false}
                        >
                          <BSForm.Group className="mb-3 mt-3">
                            <Field
                              component="select"
                              id="request_type"
                              name="request_type"
                              className="form-select"
                              // multiple={true}
                            >
                              <option value="">Select Type</option>
                              <option value="Electrical">Electrical</option>
                              <option value="Plumbing">Plumbing</option>
                              <option value="Doors / Locks">
                                Doors / Locks
                              </option>
                              <option value="Garbage">Garbage</option>
                              <option value="Other">Other</option>
                            </Field>
                            <ErrorMessage
                              name="request_type"
                              component="div"
                              className="text-danger m-2 fs--1"
                            />
                          </BSForm.Group>

                          <BSForm.Group className="mb-3">
                            <BSForm.Label>
                              Subject{" "}
                              {/* <span className="text-danger">*</span> */}
                            </BSForm.Label>
                            <Field
                              type="text"
                              name="subject"
                              placeholder="Add subject here"
                              className="me-3 form-control"
                            />
                            <ErrorMessage
                              name="subject"
                              component="div"
                              className="text-danger m-2 fs--1"
                            />
                          </BSForm.Group>
                          <BSForm.Group className="mb-3">
                            <BSForm.Label>
                              Description
                              {/* <span className="text-danger">*</span> */}
                            </BSForm.Label>
                            <BSForm.Control
                              name="description"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              as={"textarea"}
                              rows={4}
                              placeholder="Description here...."
                              style={{ width: "300px !important" }}
                            />
                            <ErrorMessage
                              name="description"
                              component="div"
                              className="text-danger m-2 fs--1"
                            />
                          </BSForm.Group>
                          <div style={{ marginBottom: "50px" }}></div>
                        </ModalMediaContent>

                        <ModalMediaContent
                          title="Attachments"
                          icon="paperclip"
                          headingClass="d-flex justify-content-between"
                          headingContent={
                            <label className="btn btn-white shadow-sm cursor-pointer ">
                              <input
                                type="file"
                                name="req_images"
                                hidden
                                onChange={(e) =>
                                  handleFiles(e, setFieldValue, values)
                                }
                                accept="image/*"
                                multiple
                              />
                              <FontAwesomeIcon icon="plus" />
                            </label>
                          }
                        >
                          {values?.req_images?.length < 1 && (
                            <div>
                              <br />
                              <p className="text-info fs--1">
                                No Attachments Added.
                              </p>
                              {/* <br /> */}
                            </div>
                          )}
                          {values?.req_images?.map((file, index) => (
                            <div className="d-flex mb-3" key={index * 2 + "kk"}>
                              <Image
                                src={URL.createObjectURL(file.file)}
                                // src={file}
                                className="shadow-sm"
                                style={{
                                  width: "150px",
                                  height: "100px",
                                  backgroundPosition: "center",
                                  marginBottom: "10px",
                                  marginRight: "20px",
                                  borderRadius: "5px",
                                }}
                              />
                              <span style={{ fontSize: "12px" }}>
                                <br />

                                <div className="mb-1">
                                  <span className="text-primary">
                                    Attachment # {index + 1}
                                    {/* {file.name} */}
                                  </span>
                                </div>

                                <div className="mb-1">
                                  {/* <span
                                    className="cursor-pointer"
                                    onClick={(e) =>
                                      handleFilesActions(file.file, "view")
                                    }
                                  >
                                    View
                                  </span>
                                  <span> | </span> */}
                                  <span
                                    // style={{letterSpacing:'2px'}}
                                    className="cursor-pointer"
                                    onClick={(e) =>
                                      handleRemoveFile(
                                        e,
                                        setFieldValue,
                                        values,
                                        file.tmp_id
                                      )
                                    }
                                  >
                                    Remove
                                  </span>
                                </div>
                                <div>
                                  uploaded at {formatLogInfo(new Date(), true)}
                                </div>
                              </span>
                            </div>
                          ))}
                        </ModalMediaContent>
                      </Col>
                      <Col lg={4}>
                        <>
                          <div className="d-flex justify-content-between align-items-baseline">
                            <h6 className="mt-5 mt-lg-0">Status</h6>
                            <div
                              className={`text-seconadry badge badge-soft-${"warning"}`}
                              style={{ fontSize: "14px" }}
                            >
                              {values?.status || "value "}
                            </div>
                          </div>

                          <div style={{ marginTop: "30px" }}></div>

                          <h6 className="mt-5 mt-lg-0">Reporter</h6>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="light"
                              className="form-control shadow-none cursor-df border-0"
                              style={{ height: "40px", overflow: "hidden" }}
                            >
                              <div className="d-flex align-items-center">
                                <Avatar
                                  sx={{
                                    height: "28px",
                                    width: "28px",
                                    fontSize: "12px",
                                    marginRight: "5px",
                                    bgcolor:
                                      values?.reporter?.bgColor || "brown",
                                  }}
                                >
                                  {startCase(values?.reporter?.firstname[0])}
                                  {startCase(values?.reporter?.lastname[0])}
                                </Avatar>
                                <span className="ms-3 fs--1">
                                  {values?.reporter?.firstname}{" "}
                                  {values?.reporter?.lastname}
                                </span>
                              </div>
                            </Dropdown.Toggle>
                          </Dropdown>

                          <div style={{ marginTop: "40px" }}></div>
                          <h6 className="mt-lg-0">Created At</h6>
                          <Nav className="flex-lg-column fs--1">
                            <Nav.Item className="me-2 me-lg-0">
                              <Nav.Link
                                as={Link}
                                to="#!"
                                className="nav-link-card-details cursor-df "
                              >
                                {/* <FontAwesomeIcon icon={"pen"} className="me-2" /> */}
                                <div>{formatLogInfo(values?.createdAt)}</div>
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                          <div style={{ marginTop: "30px" }}></div>
                        </>
                      </Col>
                    </Row>
                    <br />
                    <div className="text-end">
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Create
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        )}
        {/*  */}
        {payload?.loadType === "View Request Owner" && (
          <>
            <Formik
              initialValues={{
                status: payload?.status || "",
                assignee: payload?.assigned_to_id || "",
                reporter: payload?.generated_by_id || "",
                request_type: payload?.request_type || "",
                other_request_type: payload?.other_request_type || "",
                subject: payload?.subject || "",
                description: payload?.description || "",
                priority_level: "Medium",
                req_images: payload?.attached_images || [],
                unit_permission: "No",
                comment: "",
                comments: payload?.comments || [],
                createdAt: payload?.createdAt || new Date(),
                updatedAt: payload?.updatedAt || new Date(),
                sortOrder: "asc",
              }}
              validate={(values) => {
                const errors = {};

                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
              }}
            >
              {({
                isSubmitting,
                values,
                setFieldValue,
                handleChange,
                handleBlur,
              }) => (
                <Form>
                  <div className="bg-light rounded-top-lg px-4 py-3">
                    {editField && (
                      <div className="" style={{ width: "70%" }}>
                        <Field
                          name="subject"
                          className="custom-fc me-3 mb-3 form-control"
                        />
                        <div className="d-flex mb-3">
                          <Button
                            variant="falcon-default"
                            size="sm"
                            onClick={() => {
                              // handleDynamicUpdateOwner(values, "subject");
                              updateServiceRequest(values);
                              showEditField(false);
                            }}
                          >
                            <Check fontSize="small" />
                          </Button>
                          <Button
                            variant="falcon-default"
                            size="sm"
                            className="ms-2"
                            onClick={() => showEditField(false)}
                          >
                            <Clear fontSize="small" />
                          </Button>
                        </div>
                      </div>
                    )}

                    {!editField && (
                      <h4
                        className={`mb-1`}
                        onClick={() => showEditField(true)}
                      >
                        {values?.subject ||
                          "Add a new illustration to the owner"}
                      </h4>
                    )}
                    <p className="fs--2 mb-0 mt-2">
                      Added by{" "}
                      {/* <Link to="#!" className="text-600 fw-semi-bold"> */}
                      {values?.reporter?.firstname} {values?.reporter?.lastname}
                      {/* </Link> */}
                    </p>
                  </div>
                  <div className="p-4">
                    <Row>
                      <Col lg={8}>
                        <ModalMediaContent
                          title="Description"
                          icon="align-left"
                        >
                          {editFieldD && (
                            <div>
                              <BSForm.Control
                                onChange={handleChange}
                                onBlur={handleBlur}
                                as={"textarea"}
                                name="description"
                                rows={5}
                                value={values.description}
                                style={{ width: "300px !important" }}
                                placeholder="Add description here..."
                              />
                              <div className="my-2">
                                <Button
                                  variant="primary"
                                  size="sm"
                                  onClick={() => {
                                    // handleDynamicUpdateOwner(
                                    //   values,
                                    //   "description"
                                    // );
                                    updateServiceRequest(values);
                                    showEditFieldD(false);
                                  }}
                                >
                                  Save
                                </Button>
                                <Button
                                  variant="falcon-default"
                                  size="sm"
                                  className="ms-2"
                                  onClick={() => showEditFieldD(false)}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </div>
                          )}
                          {!editFieldD && (
                            <p
                              className="text-word-break fs--1"
                              onClick={() => showEditFieldD(true)}
                            >
                              {values?.description ||
                                "The illustration must match to the contrast of ."}
                            </p>
                          )}
                        </ModalMediaContent>

                        <ModalMediaContent
                          title="Attachments"
                          icon="paperclip"
                          headingClass="d-flex justify-content-between"
                          headingContent={
                            <label className="btn btn-white shadow-sm cursor-pointer ">
                              <input
                                type="file"
                                name="req_images"
                                hidden
                                onChange={(e) =>
                                  handleFiles(e, setFieldValue, values)
                                }
                                accept="image/*"

                                multiple
                              />
                              <FontAwesomeIcon icon="plus" />
                            </label>
                          }
                        >
                          {values?.req_images?.length < 1 && (
                            <div>
                              <br />
                              <p className="text-info fs--1">
                                No Attachments Added.
                              </p>
                              {/* <br /> */}
                            </div>
                          )}
                          {values?.req_images?.map((file, index) => (
                            <div className="d-flex mb-1" key={index * 2 + "kk"}>
                              <Image
                                // src={URL.createObjectURL(file.file)}
                                src={
                                  file.type === "link"
                                    ? file.file
                                    : URL.createObjectURL(file.file)
                                }
                                className="shadow-sm"
                                style={{
                                  width: "150px",
                                  height: "100px",
                                  backgroundPosition: "center",
                                  marginBottom: "10px",
                                  marginRight: "20px",
                                  borderRadius: "5px",
                                }}
                              />
                              <span style={{ fontSize: "12px" }}>
                                <br />

                                <div className="mb-1">
                                  <span className="text-primary">
                                    Attachment # {index + 1}
                                    {/* {file.name} */}
                                  </span>
                                </div>

                                <div className="mb-1">
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      handleFilesActions(file.file, "view");
                                    }}
                                  >
                                    View
                                  </span>
                                  <span> | </span>
                                  <span
                                    className="cursor-pointer"
                                    onClick={(e) =>
                                      handleRemoveFile(
                                        e,
                                        setFieldValue,
                                        values,
                                        file.tmp_id
                                      )
                                    }
                                  >
                                    Remove
                                  </span>
                                </div>
                                <div>
                                  uploaded at{" "}
                                  {formatLogInfo(values.createdAt, true)}
                                </div>
                              </span>
                            </div>
                          ))}
                        </ModalMediaContent>

                        <ModalMediaContent
                          title="Comments"
                          icon={["far", "comment"]}
                          headingClass="mb-3 d-flex justify-content-between"
                          headingContent={
                            <div>
                              {values.sortOrder === "asc" && (
                                <>
                                  <span>Oldest first </span>
                                  <Button
                                    variant="falcon-primary"
                                    size="sm"
                                    onClick={() =>
                                      revertSortOrder(
                                        values.comments,
                                        setFieldValue,
                                        "dsc"
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon icon="fa-solid fa-arrow-down-wide-short" />
                                  </Button>
                                </>
                              )}
                              {values.sortOrder === "dsc" && (
                                <>
                                  <span>Newest first </span>
                                  <Button
                                    variant="falcon-primary"
                                    size="sm"
                                    onClick={() =>
                                      revertSortOrder(
                                        values.comments,
                                        setFieldValue,
                                        "asc"
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon icon="fa-solid fa-arrow-up-wide-short" />
                                  </Button>
                                </>
                              )}
                            </div>
                          }
                        >
                          <>
                            <Flex>
                              <Avatar
                                sx={{
                                  height: "28px",
                                  width: "28px",
                                  fontSize: "12px",
                                  marginRight: "14px",
                                  bgcolor:
                                    payload?.userData?.bgColor || "blueviolet",
                                }}
                              >
                                {startCase(payload?.userData?.firstname[0]) ||
                                  "N"}
                                {startCase(payload?.userData?.lastname[0]) ||
                                  "H"}
                              </Avatar>
                              <div className="flex-1 fs--1">
                                <div className="position-relative border rounded mb-3">
                                  <>
                                    <BSForm.Control
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      name="comment"
                                      as="textarea"
                                      value={values.comment}
                                      className="border-0 rounded-bottom-0 resize-none"
                                      rows={2}
                                    />
                                    <Flex
                                      justifyContent="end"
                                      alignItems="center"
                                      className="bg-light rounded-bottom p-2 mt-1"
                                    >
                                      <Button
                                        size="sm"
                                        color="primary"
                                        type="submit"
                                        disabled={!values.comment}
                                        // onClick={() =>
                                        //   handleDynamicUpdateOwner(
                                        //     values,
                                        //     "comment"
                                        //   )
                                        // }
                                        onClick={() =>
                                          addComment(values, setFieldValue)
                                        }
                                      >
                                        Save
                                      </Button>
                                    </Flex>
                                  </>
                                </div>
                              </div>
                            </Flex>

                            {values?.comments.map((comment) => (
                              <Flex key={comment.comment_id} className="mb-3">
                                <Link to="#!">
                                  <Avatar
                                    sx={{
                                      height: "28px",
                                      width: "28px",
                                      fontSize: "12px",
                                      marginRight: "5px",
                                      bgcolor: comment?.bgColor || "blueviolet",
                                    }}
                                  >
                                    {startCase(comment?.firstname[0]) || "N"}
                                    {startCase(
                                      comment?.lastname !== null
                                        ? comment?.lastname[0]
                                        : ""
                                    ) || "H"}
                                  </Avatar>
                                </Link>
                                {comment.comment_id ===
                                  editFieldComment.show_id && (
                                  <div className="">
                                    <BSForm.Control
                                      as={"textarea"}
                                      rows={4}
                                      cols={50}
                                      // name="e"
                                      value={editFieldComment.text}
                                      onChange={(e) =>
                                        setEditFieldComment({
                                          ...editFieldComment,
                                          text: e.target.value,
                                        })
                                      }
                                      className="me-3 mb-2 form-control"
                                    />
                                    <div className="d-flex mb-3">
                                      <Button
                                        variant="falcon-default"
                                        size="sm"
                                        onClick={() => {
                                          updateComment(
                                            comment,
                                            setFieldValue,
                                            values
                                          );
                                        }}
                                      >
                                        <Check fontSize="small" />
                                      </Button>
                                      <Button
                                        variant="falcon-default"
                                        size="sm"
                                        className="ms-2"
                                        onClick={() =>
                                          setEditFieldComment({
                                            ...editFieldComment,
                                            shown: false,
                                            show_id: "",
                                          })
                                        }
                                      >
                                        <Clear fontSize="small" />
                                      </Button>
                                    </div>
                                  </div>
                                )}
                                {comment.comment_id !==
                                  editFieldComment.show_id && (
                                  <div className="flex-1 ms-2 fs--1">
                                    <p className="mb-1 bg-200 rounded-3 p-2">
                                      <Link to="#!" className="fw-semi-bold">
                                        {comment?.firstname}
                                      </Link>{" "}
                                      {" -->  "}
                                      {comment?.text}
                                    </p>
                                    {payload?.userData?.data ===
                                      comment.user_id && (
                                      <>
                                        <Link
                                          to="#!"
                                          onClick={() =>
                                            // updateComment(comment, setFieldValue)
                                            setEditFieldComment({
                                              ...editFieldComment,
                                              shown: true,
                                              show_id: comment.comment_id,
                                              text: comment.text,
                                              defaultText: comment.text,
                                            })
                                          }
                                        >
                                          {" "}
                                          Edit{" "}
                                        </Link>{" "}
                                        &bull;
                                        <Link
                                          to="#!"
                                          onClick={() =>
                                            deleteComment(
                                              comment,
                                              setFieldValue,
                                              values
                                            )
                                          }
                                        >
                                          {" "}
                                          Delete{" "}
                                        </Link>{" "}
                                        &bull; {/* {comment.time} */}
                                      </>
                                    )}
                                    <span
                                      className="fs--1"
                                      // to="#!"
                                      // onClick={() =>
                                      //   deleteComment(comment, setFieldValue)
                                      // }
                                    >
                                      {" "}
                                      {comment?.time}{" "}
                                      {comment?.isEdited ? "(edited)" : ""}
                                    </span>{" "}
                                  </div>
                                )}
                              </Flex>
                            ))}
                          </>
                        </ModalMediaContent>
                      </Col>
                      <Col lg={4}>
                        <>
                          <div className="d-flex justify-content-between align-items-baseline">
                            <h6 className="mt-5 mt-lg-0">Status</h6>

                            <div
                              className={`text-seconadry badge badge-soft-${
                                values?.status?.type || "warning"
                              }`}
                              style={{ fontSize: "14px" }}
                            >
                              {values?.status?.title || "value "}
                            </div>
                          </div>

                          <br />
                          <h6 className="mt-5 mt-lg-0">Assignee</h6>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="light"
                              className="form-control shadow-none cursor-df border-0"
                              style={{ height: "40px", overflow: "hidden" }}
                            >
                              <div className="d-flex align-items-center">
                                <Avatar
                                  sx={{
                                    height: "28px",
                                    width: "28px",
                                    fontSize: "12px",
                                    marginRight: "5px",
                                    bgcolor: values?.assignee?.bgColor || "red",
                                  }}
                                >
                                  {startCase(values?.assignee?.firstname[0])}
                                  {startCase(values?.assignee?.lastname[0])}
                                </Avatar>
                                <span className="ms-3 fs--1">
                                  {values?.assignee?.firstname}{" "}
                                  {values?.assignee?.lastname}
                                </span>
                              </div>
                            </Dropdown.Toggle>
                          </Dropdown>

                          <br />

                          <h6 className="mt-5 mt-lg-0">Reporter</h6>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="light"
                              className="form-control shadow-none cursor-df border-0"
                              style={{ height: "40px", overflow: "hidden" }}
                            >
                              <div className="d-flex align-items-center">
                                <Avatar
                                  sx={{
                                    height: "28px",
                                    width: "28px",
                                    fontSize: "12px",
                                    marginRight: "5px",
                                    bgcolor:
                                      values?.reporter?.bgColor || "brown",
                                  }}
                                >
                                  {startCase(values?.reporter?.firstname[0])}
                                  {startCase(values?.reporter?.lastname[0])}
                                </Avatar>
                                <span className="ms-3 fs--1">
                                  {values?.reporter?.firstname}{" "}
                                  {values?.reporter?.lastname}
                                </span>
                              </div>
                            </Dropdown.Toggle>
                          </Dropdown>

                          <br />

                          <h6 className="mt-5 mt-lg-0">Created At</h6>
                          <Nav className="flex-lg-column fs--1">
                            <Nav.Item className="me-2 me-lg-0">
                              <Nav.Link
                                as={Link}
                                to="#!"
                                className="nav-link-card-details cursor-df "
                              >
                                {/* <FontAwesomeIcon icon={"pen"} className="me-2" /> */}
                                <div>{formatLogInfo(values?.createdAt)}</div>
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>

                          <br />

                          <h6 className="mt-5 mt-lg-0">Updated At</h6>
                          <Nav className="flex-lg-column fs--1">
                            <Nav.Item className="me-2 me-lg-0">
                              <Nav.Link
                                as={Link}
                                to="#!"
                                className="nav-link-card-details cursor-df "
                              >
                                <div>{formatLogInfo(values?.updatedAt)}</div>
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>

                          {/* <br />
                          <BSForm.Group className="mb-3">
                            <BSForm.Label>
                              Request Type{" "}
                              <span className="text-danger">*</span>
                            </BSForm.Label>
                            <Field
                              component="select"
                              id="request_type"
                              name="request_type"
                              className="form-select"
                              onChange={(e) => {
                                handleDynamicUpdateOwner(
                                  values,
                                  "request_type"
                                );
                                handleChange(e);
                              }}
                              // multiple={true}
                            >
                              <option value="">Select Type</option>
                              <option value="Electrical">Electrical</option>
                              <option value="Plumbing">Plumbing</option>
                              <option value="Doors / Locks">
                                Doors / Locks
                              </option>
                              <option value="Garbage">Garbage</option>
                              <option value="Other">Other</option>
                            </Field>
                            <ErrorMessage
                              name="request_type"
                              component="div"
                              className="text-danger m-2 fs--1"
                            />
                          </BSForm.Group>

                          <BSForm.Group className="mb-4">
                            <BSForm.Label>
                              Priority Level{" "}
                              <span className="text-danger">*</span>
                            </BSForm.Label>
                            <Field
                              onChange={(e) => {
                                handleDynamicUpdateOwner(
                                  values,
                                  "priority_level"
                                );
                                handleChange(e);
                              }}
                              component="select"
                              id="priority_level"
                              name="priority_level"
                              className="form-select"
                              // multiple={true}
                            >
                              <option value="">Select Type</option>
                              <option value="Low">Low</option>
                              <option value="Medium">Medium</option>
                              <option value="High">High</option>
                            </Field>
                            <ErrorMessage
                              name="priority_level"
                              component="div"
                              className="text-danger m-2 fs--1"
                            />
                          </BSForm.Group>
                          <BSForm.Group className="mb-3">
                            <div className="">
                              <label>Permission to enter in unit</label>
                              <div className="d-flex justify-content-end ">
                                <label className="mx-3 ">
                                  <span className="h6">Yes</span>{" "}
                                  <Field
                                    type="radio"
                                    name={"unit_permission"}
                                    value="Yes"
                                    className="form-check-input"
                                  />
                                </label>
                                <label className="mx-3">
                                  {" "}
                                  <span className="h6">No</span>{" "}
                                  <Field
                                    type="radio"
                                    name={"unit_permission"}
                                    value="No"
                                    className="form-check-input"
                                  />
                                </label>
                              </div>
                            </div>

                            <ErrorMessage
                              name="unit_permission"
                              component="div"
                              className="text-danger m-2 fs--1"
                            />
                          </BSForm.Group> */}
                        </>
                      </Col>
                    </Row>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        )}
        {/*  */}
        {payload?.loadType === "View Request Manager" && (
          <>
            <Formik
              initialValues={{
                status: payload?.status || "",
                assignee: payload?.assigned_to_id || "",
                reporter: payload?.generated_by_id || "",
                request_type: payload?.request_type || "",
                other_request_type: payload?.other_request_type || "",
                subject: payload?.subject || "",
                description: payload?.description || "",
                priority_level: "Medium",
                req_images: payload?.attached_images || [],
                unit_permission: "No",
                comment: "",
                comments: payload?.comments || [],
                createdAt: payload?.createdAt || new Date(),
                updatedAt: payload?.updatedAt || new Date(),
                sortOrder: "asc",
              }}
              validate={(values) => {
                const errors = {};

                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
              }}
            >
              {({
                isSubmitting,
                values,
                setFieldValue,
                handleChange,
                handleBlur,
              }) => (
                <Form>
                  <div className="bg-light rounded-top-lg px-4 py-3">
                    {editField && (
                      <div className="d-flex">
                        <Field
                          name="subject"
                          className="custom-fc me-3 mb-3 form-control"
                        />
                        <div className="d-flex mb-3">
                          <Button
                            variant="falcon-default"
                            size="sm"
                            onClick={() => {
                              // handleDynamicUpdateOwner(values, "subject");
                              updateServiceRequest(values);
                              showEditField(false);
                            }}
                          >
                            <Check fontSize="small" />
                          </Button>
                          <Button
                            variant="falcon-default"
                            size="sm"
                            className="ms-2"
                            onClick={() => showEditField(false)}
                          >
                            <Clear fontSize="small" />
                          </Button>
                        </div>
                      </div>
                    )}

                    {!editField && (
                      <h4
                        className={`mb-1`}
                        // onClick={() => showEditField(true)}
                      >
                        {values?.subject ||
                          "Add a new illustration to the owner"}
                      </h4>
                    )}
                    <p className="fs--2 mb-0 mt-2">
                      Added by{" "}
                      {/* <Link to="#!" className="text-600 fw-semi-bold"> */}
                      {values?.reporter?.firstname} {values?.reporter?.lastname}
                      {/* </Link> */}
                    </p>
                  </div>
                  <div className="p-4">
                    <Row>
                      <Col lg={8}>
                        <ModalMediaContent
                          title="Description"
                          icon="align-left"
                        >
                          {editFieldD && (
                            <div>
                              <BSForm.Control
                                onChange={handleChange}
                                onBlur={handleBlur}
                                as={"textarea"}
                                name="description"
                                rows={5}
                                value={values.description}
                                style={{ width: "300px !important" }}
                                placeholder="Add description here..."
                              />
                              <div className="my-2">
                                <Button
                                  variant="primary"
                                  size="sm"
                                  onClick={() => {
                                    // handleDynamicUpdateOwner(
                                    //   values,
                                    //   "description"
                                    // );
                                    updateServiceRequest(values);
                                    showEditFieldD(false);
                                  }}
                                >
                                  Save
                                </Button>
                                <Button
                                  variant="falcon-default"
                                  size="sm"
                                  className="ms-2"
                                  onClick={() => showEditFieldD(false)}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </div>
                          )}
                          {!editFieldD && (
                            <p
                              className="text-word-break fs--1"
                              // onClick={() => showEditFieldD(true)}
                            >
                              {values?.description ||
                                "The illustration must match to the contrast of ."}
                            </p>
                          )}
                        </ModalMediaContent>

                        <ModalMediaContent title="Attachments" icon="paperclip">
                          <div style={{ marginBottom: "40px" }}></div>
                          {values?.req_images?.length < 1 && (
                            <div className="">
                              <br />
                              <p className="text-info fs--1">
                                No Attachments Added.
                              </p>
                              {/* <br /> */}
                            </div>
                          )}
                          {values?.req_images?.map((file, index) => (
                            <div className="d-flex mb-1" key={index * 2 + "kk"}>
                              <Image
                                // src={URL.createObjectURL(file.file)}
                                src={
                                  file.type === "link"
                                    ? file.file
                                    : URL.createObjectURL(file.file)
                                }
                                className="shadow-sm"
                                style={{
                                  width: "150px",
                                  height: "100px",
                                  backgroundPosition: "center",
                                  marginBottom: "10px",
                                  marginRight: "20px",
                                  borderRadius: "5px",
                                }}
                              />
                              <span style={{ fontSize: "12px" }}>
                                <br />

                                <div className="mb-1">
                                  <span className="text-primary">
                                    Attachment # {index + 1}
                                    {/* {file.name} */}
                                  </span>
                                </div>

                                <div className="mb-1">
                                  <span
                                    className="cursor-pointer"
                                    onClick={(e) =>
                                      handleFilesActions(file.file, "view")
                                    }
                                  >
                                    View
                                  </span>
                                  <span> | </span>
                                  <span
                                    className="cursor-pointer"
                                    onClick={(e) =>
                                      handleFilesActions(file.file, "download")
                                    }
                                  >
                                    Download
                                  </span>
                                </div>
                                <div>
                                  uploaded at{" "}
                                  {formatLogInfo(values.createdAt, true)}
                                </div>
                              </span>
                            </div>
                          ))}
                        </ModalMediaContent>

                        <ModalMediaContent
                          title="Comments"
                          icon={["far", "comment"]}
                          headingClass="mb-3 d-flex justify-content-between"
                          headingContent={
                            <div>
                              {values.sortOrder === "asc" && (
                                <>
                                  <span>Oldest first </span>
                                  <Button
                                    variant="falcon-primary"
                                    size="sm"
                                    onClick={() =>
                                      revertSortOrder(
                                        values.comments,
                                        setFieldValue,
                                        "dsc"
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon icon="fa-solid fa-arrow-down-wide-short" />
                                  </Button>
                                </>
                              )}
                              {values.sortOrder === "dsc" && (
                                <>
                                  <span>Newest first </span>
                                  <Button
                                    variant="falcon-primary"
                                    size="sm"
                                    onClick={() =>
                                      revertSortOrder(
                                        values.comments,
                                        setFieldValue,
                                        "asc"
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon icon="fa-solid fa-arrow-up-wide-short" />
                                  </Button>
                                </>
                              )}
                            </div>
                          }
                        >
                          <>
                            <Flex>
                              <Avatar
                                sx={{
                                  height: "28px",
                                  width: "28px",
                                  fontSize: "12px",
                                  marginRight: "14px",
                                  bgcolor:
                                    payload?.userData?.bgColor || "blueviolet",
                                }}
                              >
                                {startCase(payload?.userData?.firstname[0]) ||
                                  "N"}
                                {startCase(payload?.userData?.lastname[0]) ||
                                  "H"}
                              </Avatar>
                              <div className="flex-1 fs--1">
                                <div className="position-relative border rounded mb-3">
                                  <>
                                    <BSForm.Control
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      name="comment"
                                      as="textarea"
                                      value={values.comment}
                                      className="border-0 rounded-bottom-0 resize-none"
                                      rows={2}
                                    />
                                    <Flex
                                      justifyContent="end"
                                      alignItems="center"
                                      className="bg-light rounded-bottom p-2 mt-1"
                                    >
                                      <Button
                                        size="sm"
                                        color="primary"
                                        type="submit"
                                        disabled={!values.comment}
                                        // onClick={() =>
                                        //   handleDynamicUpdateOwner(
                                        //     values,
                                        //     "comment"
                                        //   )
                                        // }
                                        onClick={() =>
                                          addComment(values, setFieldValue)
                                        }
                                      >
                                        Save
                                      </Button>
                                    </Flex>
                                  </>
                                </div>
                              </div>
                            </Flex>

                            {values?.comments.map((comment) => (
                              <Flex key={comment.comment_id} className="mb-3">
                                <Link to="#!">
                                  <Avatar
                                    sx={{
                                      height: "28px",
                                      width: "28px",
                                      fontSize: "12px",
                                      marginRight: "5px",
                                      bgcolor: comment?.bgColor || "blueviolet",
                                    }}
                                  >
                                    {startCase(comment?.firstname[0]) || "N"}
                                    {startCase(
                                      comment?.lastname !== null
                                        ? comment?.lastname[0]
                                        : ""
                                    ) || "H"}
                                  </Avatar>
                                </Link>
                                {comment.comment_id ===
                                  editFieldComment.show_id && (
                                  <div className="">
                                    <BSForm.Control
                                      as={"textarea"}
                                      rows={4}
                                      cols={50}
                                      // name="e"
                                      value={editFieldComment.text}
                                      onChange={(e) =>
                                        setEditFieldComment({
                                          ...editFieldComment,
                                          text: e.target.value,
                                        })
                                      }
                                      className="me-3 mb-2 form-control"
                                    />
                                    <div className="d-flex mb-3">
                                      <Button
                                        variant="falcon-default"
                                        size="sm"
                                        onClick={() => {
                                          updateComment(
                                            comment,
                                            setFieldValue,
                                            values
                                          );
                                        }}
                                      >
                                        <Check fontSize="small" />
                                      </Button>
                                      <Button
                                        variant="falcon-default"
                                        size="sm"
                                        className="ms-2"
                                        onClick={() =>
                                          setEditFieldComment({
                                            ...editFieldComment,
                                            shown: false,
                                            show_id: "",
                                          })
                                        }
                                      >
                                        <Clear fontSize="small" />
                                      </Button>
                                    </div>
                                  </div>
                                )}
                                {comment.comment_id !==
                                  editFieldComment.show_id && (
                                  <div className="flex-1 ms-2 fs--1">
                                    <p className="mb-1 bg-200 rounded-3 p-2">
                                      <Link to="#!" className="fw-semi-bold">
                                        {comment?.firstname}
                                      </Link>{" "}
                                      {" -->  "}
                                      {comment?.text}
                                    </p>
                                    {payload?.userData?.data ===
                                      comment.user_id && (
                                      <>
                                        <Link
                                          to="#!"
                                          onClick={() =>
                                            // updateComment(comment, setFieldValue)
                                            setEditFieldComment({
                                              ...editFieldComment,
                                              shown: true,
                                              show_id: comment.comment_id,
                                              text: comment.text,
                                              defaultText: comment.text,
                                            })
                                          }
                                        >
                                          {" "}
                                          Edit{" "}
                                        </Link>{" "}
                                        &bull;
                                        <Link
                                          to="#!"
                                          onClick={() =>
                                            deleteComment(
                                              comment,
                                              setFieldValue,
                                              values
                                            )
                                          }
                                        >
                                          {" "}
                                          Delete{" "}
                                        </Link>{" "}
                                        &bull; {/* {comment.time} */}
                                      </>
                                    )}
                                    <span
                                      className="fs--1"
                                      // to="#!"
                                      // onClick={() =>
                                      //   deleteComment(comment, setFieldValue)
                                      // }
                                    >
                                      {" "}
                                      {comment?.time}{" "}
                                      {comment?.isEdited ? "(edited)" : ""}
                                    </span>{" "}
                                  </div>
                                )}
                              </Flex>
                            ))}
                          </>
                        </ModalMediaContent>
                      </Col>
                      <Col lg={4}>
                        <>
                          <div className="d-flex justify-content-between align-items-baseline">
                            <h6 className="mt-5 mt-lg-0">Status</h6>

                            {/* <div
                              className={`text-seconadry badge badge-soft-${
                                values?.status?.type || "warning"
                              }`}
                              style={{ fontSize: "14px" }}
                            >
                              {values?.status?.title || "value "}
                            </div> */}
                            <Dropdown className="bg-light">
                              <Dropdown.Toggle
                                variant=""
                                className={`text-dark form-control shadow-none border-0`}
                                style={{
                                  backgroundColor:
                                    values?.status?.variant || "danger",
                                }}
                              >
                                <div
                                  className={`text-dark badge badge-${values?.status?.type}`}
                                  style={{ fontSize: "14px" }}
                                >
                                  {values?.status?.title}
                                </div>
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                              // style={{ minWidth: `70px !important`,backgroundColor:'red' }}
                              // className="bg-light"
                              >
                                {[
                                  {
                                    type: "warning",
                                    title: "Open",
                                    icon: "check",
                                    variant: "#FDE6D8",
                                  },
                                  {
                                    type: "info",
                                    title: "Processing",
                                    icon: "redo",
                                    variant: "#D4F2FF",
                                  },
                                  {
                                    type: "success",
                                    title: "Closed",
                                    icon: "check",
                                    variant: "#CCF6E4",
                                  },
                                ]?.map((v, i) => (
                                  <Dropdown.Item
                                    key={v.type + v.title + i}
                                    className="mb-3 item-hover d-flex justify-content-between"
                                    onClick={() => {
                                      setFieldValue("status", { ...v });
                                      updateServiceRequest(values, false, v);
                                    }}
                                  >
                                    <div
                                      className={`badge badge-soft-${v.type}`}
                                      style={{ fontSize: "14px" }}
                                    >
                                      {v.title}
                                    </div>
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>

                          <br />
                          <h6 className="mt-5 mt-lg-0">Assignee</h6>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="light"
                              className="form-control shadow-none cursor-df border-0"
                              style={{ height: "40px", overflow: "hidden" }}
                            >
                              <div className="d-flex align-items-center">
                                <Avatar
                                  sx={{
                                    height: "28px",
                                    width: "28px",
                                    fontSize: "12px",
                                    marginRight: "5px",
                                    bgcolor: values?.assignee?.bgColor || "red",
                                  }}
                                >
                                  {startCase(values?.assignee?.firstname[0])}
                                  {startCase(values?.assignee?.lastname[0])}
                                </Avatar>
                                <span className="ms-3 fs--1">
                                  {values?.assignee?.firstname}{" "}
                                  {values?.assignee?.lastname}
                                </span>
                              </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              style={{ width: "100%" }}
                              // className="bg-light"
                            >
                              <Dropdown.Item
                                className="mb-3 item-hover"
                                // onClick={() => handleAssigneField("Akram Khan")}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                              >
                                <div className="d-flex align-items-center">
                                  <BSForm.Control
                                    placeholder="search here.."
                                    onKeyUp={handleMiniSearch}
                                  />
                                </div>
                              </Dropdown.Item>
                              {managersList?.map((manager) => (
                                <Dropdown.Item
                                  key={manager?._id}
                                  className="item-hover mb-3"
                                  onClick={() =>
                                    handleAssigneeUpdate(
                                      manager,
                                      values,
                                      setFieldValue
                                    )
                                  }
                                >
                                  <div className="d-flex align-items-center">
                                    <Avatar
                                      sx={{
                                        height: "28px",
                                        width: "28px",
                                        fontSize: "12px",
                                        marginRight: "5px",
                                        bgcolor: manager?.bgColor,
                                      }}
                                    >
                                      {`${startCase(
                                        manager.firstname[0]
                                      )}${startCase(manager.lastname[0])}`}
                                    </Avatar>
                                    <span className="ms-3 fs--1">
                                      {`${startCase(
                                        manager.firstname
                                      )} ${startCase(manager.lastname)}`}
                                    </span>
                                  </div>
                                </Dropdown.Item>
                              ))}
                              {/* <Dropdown.Item
                                className="item-hover"
                                onClick={() =>
                                  handleAssigneField("Irshad Khan")
                                }
                              >
                                <div className="d-flex align-items-center">
                                  <Avatar
                                    sx={{
                                      height: "28px",
                                      width: "28px",
                                      fontSize: "12px",
                                      marginRight: "5px",
                                    }}
                                  >
                                    NH
                                  </Avatar>
                                  <span className="ms-3 fs--1">
                                    Irshad Khan
                                  </span>
                                </div>
                              </Dropdown.Item> */}
                            </Dropdown.Menu>
                          </Dropdown>

                          <br />

                          <h6 className="mt-5 mt-lg-0">Reporter</h6>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="light"
                              className="form-control shadow-none cursor-df border-0"
                              style={{ height: "40px", overflow: "hidden" }}
                            >
                              <div className="d-flex align-items-center">
                                <Avatar
                                  sx={{
                                    height: "28px",
                                    width: "28px",
                                    fontSize: "12px",
                                    marginRight: "5px",
                                    bgcolor:
                                      values?.reporter?.bgColor || "brown",
                                  }}
                                >
                                  {startCase(values?.reporter?.firstname[0])}
                                  {startCase(values?.reporter?.lastname[0])}
                                </Avatar>
                                <span className="ms-3 fs--1">
                                  {values?.reporter?.firstname}{" "}
                                  {values?.reporter?.lastname}
                                </span>
                              </div>
                            </Dropdown.Toggle>
                          </Dropdown>

                          <br />

                          <h6 className="mt-5 mt-lg-0">Created At</h6>
                          <Nav className="flex-lg-column fs--1">
                            <Nav.Item className="me-2 me-lg-0">
                              <Nav.Link
                                as={Link}
                                to="#!"
                                className="nav-link-card-details cursor-df "
                              >
                                {/* <FontAwesomeIcon icon={"pen"} className="me-2" /> */}
                                <div>{formatLogInfo(values?.createdAt)}</div>
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>

                          <br />

                          <h6 className="mt-5 mt-lg-0">Updated At</h6>
                          <Nav className="flex-lg-column fs--1">
                            <Nav.Item className="me-2 me-lg-0">
                              <Nav.Link
                                as={Link}
                                to="#!"
                                className="nav-link-card-details cursor-df "
                              >
                                <div>{formatLogInfo(values?.updatedAt)}</div>
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>

                          {/* <br />
                          <BSForm.Group className="mb-3">
                            <BSForm.Label>
                              Request Type{" "}
                              <span className="text-danger">*</span>
                            </BSForm.Label>
                            <Field
                              component="select"
                              id="request_type"
                              name="request_type"
                              className="form-select"
                              onChange={(e) => {
                                handleDynamicUpdateOwner(
                                  values,
                                  "request_type"
                                );
                                handleChange(e);
                              }}
                              // multiple={true}
                            >
                              <option value="">Select Type</option>
                              <option value="Electrical">Electrical</option>
                              <option value="Plumbing">Plumbing</option>
                              <option value="Doors / Locks">
                                Doors / Locks
                              </option>
                              <option value="Garbage">Garbage</option>
                              <option value="Other">Other</option>
                            </Field>
                            <ErrorMessage
                              name="request_type"
                              component="div"
                              className="text-danger m-2 fs--1"
                            />
                          </BSForm.Group>

                          <BSForm.Group className="mb-4">
                            <BSForm.Label>
                              Priority Level{" "}
                              <span className="text-danger">*</span>
                            </BSForm.Label>
                            <Field
                              onChange={(e) => {
                                handleDynamicUpdateOwner(
                                  values,
                                  "priority_level"
                                );
                                handleChange(e);
                              }}
                              component="select"
                              id="priority_level"
                              name="priority_level"
                              className="form-select"
                              // multiple={true}
                            >
                              <option value="">Select Type</option>
                              <option value="Low">Low</option>
                              <option value="Medium">Medium</option>
                              <option value="High">High</option>
                            </Field>
                            <ErrorMessage
                              name="priority_level"
                              component="div"
                              className="text-danger m-2 fs--1"
                            />
                          </BSForm.Group>
                          <BSForm.Group className="mb-3">
                            <div className="">
                              <label>Permission to enter in unit</label>
                              <div className="d-flex justify-content-end ">
                                <label className="mx-3 ">
                                  <span className="h6">Yes</span>{" "}
                                  <Field
                                    type="radio"
                                    name={"unit_permission"}
                                    value="Yes"
                                    className="form-check-input"
                                  />
                                </label>
                                <label className="mx-3">
                                  {" "}
                                  <span className="h6">No</span>{" "}
                                  <Field
                                    type="radio"
                                    name={"unit_permission"}
                                    value="No"
                                    className="form-check-input"
                                  />
                                </label>
                              </div>
                            </div>

                            <ErrorMessage
                              name="unit_permission"
                              component="div"
                              className="text-danger m-2 fs--1"
                            />
                          </BSForm.Group> */}
                        </>
                      </Col>
                    </Row>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
