import React from "react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import Avatar from 'components/common/Avatar';
// import Image from './Image';
import SoftBadge from "../../../components/common/SoftBadge";
// import { Badge, Table, Dropdown } from 'react-bootstrap';
import { Table } from "react-bootstrap";
// import { Link } from 'react-router-dom';
// import CardDropdown from 'components/common/CardDropdown';
import { isArray } from "lodash";
let groupsDes = [
  `Site-Administrators have full access to the account with | maximum permissions to all features. |They can add and remove users and update company profile.`,
  "Basic Users have access to all features of the application. |They will not be able to manage the company account.",
  "Primary Owners have full access to the unit details with maximum permissions to all features. They are also the main point of contact for announcements and notifications.",
  "Supplementary Owners will have limited access to the features which are relevant to their needs.",
  "Tenants will have limited access to the features which are relevant to their needs. ",
];
const TableRow = ({ data, ShowGroupDetail, index }) => (
  <tr className="align-middle">
    <td className="text-nowrap">
      <div
        className="ms-1 text-primary cursor-pointer"
        onClick={() => ShowGroupDetail(data.name)}
      >
        <p className="lead">
          {data.name === "Condozak-Basic-Users"
            ? "Condozak-Users"
            : data.name || "no name"}
        </p>
      </div>
      <div className="ms-1" style={{ fontSize: "14px" }}>
        {groupsDes[index]?.split("|")[0] || "no description"}
        <br />
        {groupsDes[index]?.split("|")[1] || "no description"}
        <br />
        {groupsDes[index]?.split("|")[2]}
      </div>
    </td>

    <td className="text-center" style={{ fontSize: "16px" }}>
      <SoftBadge pill bg={"success"}>
        {data.count || "None"}
      </SoftBadge>
    </td>
    {/* <td className="text-nowrap text-center">
      <div className="ms-1">{`'0' Products`}</div>
    </td> */}
    {/* <td className="text-center">
      <Link to={`/admin/management/groups/${data.group}`}>show details</Link>
    </td> */}
  </tr>
);

const UserTable = ({ groups, users, ShowGroupDetail }) => {
  let headerData = {};
  headerData.adminCount = 0;
  users?.forEach((v) => {
    if (v.Status === "1" && v.verified === "Verified") {
      if (isArray(v.groups)) {
        headerData.adminCount += v.groups.filter(
          (group) => group === "Site-Administrators"
        ).length;
      } else if (v.groups === "Site-Administrators") {
        headerData.adminCount += 1;
      }
    }
  });

  headerData.usersCount = 0;
  users?.forEach((v) => {
    if (v.Status === "1" && v.verified === "Verified") {
      if (isArray(v.groups)) {
        headerData.usersCount += v.groups.filter(
          (group) => group === "Condozak-Basic-Users"
        ).length;
      } else if (v.groups === "Condozak-Basic-Users") {
        headerData.usersCount += 1;
      }
    }
  });

  // headerData.usersCount = users?.filter(
  //   v => v.groups === 'Site-Administrators'
  // ).length;
  // headerData.usersCount = users?.filter(
  //   v => v.groups !== 'Site-Administrators'
  // ).length;
  // headerData.usersCount = users.length;
  // headerData.usersCount = users.filter(
  //   v => v.groups === 'condozak-basic-users'
  // ).length;

  // console.log(headerData);

  let groupsData = [
    {
      name: groups[0],
      count: headerData.adminCount,
      group: "Site-Administrators",
    },
    {
      name: groups[1],
      count: headerData.usersCount,
      group: "Condozak-Basic-Users",
    },
    // { name: groups[2], count: 0, group: 'managers' }
  ];

  // console.log(groups);

  return (
    <Table responsive striped hover bordered style={{ fontSize: "14px" }}>
      <thead>
        <tr>
          <th scope="col">Group</th>

          <th scope="col" className="text-center">
            Members
          </th>
          {/* <th scope="col" className="text-center">
            Access to
          </th> */}
          {/* <th scope="col" className="text-center">
            Actions
          </th> */}
        </tr>
      </thead>
      <tbody>
        {groupsData?.map((group, index) => (
          <TableRow
            data={group}
            key={index + "23"}
            index={index}
            ShowGroupDetail={ShowGroupDetail}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default UserTable;
