import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Card, Form, Nav, ProgressBar,Button } from "react-bootstrap";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import AccountForm from "./AccountForm";
import EmailVerify from "./EmailVerify";
import { RotatingLines } from "react-loader-spinner";
import { toast } from "react-toastify";
import Success from "./Success";
import AppContext, { AuthWizardContext } from "../../../../context/Context";
import IconButton from "../../../../components/common/IconButton";
import WizardModal from "./WizardModal";
import Flex from "../../../../components/common/Flex";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { getHostName } from "../../../../helpers/utils";
// import Cookies from "universal-cookie";

const WizardLayout = ({ variant, validation, progressBar }) => {
  const { portal } = useParams();

  let hostname = getHostName();

  // loader
  const [loader, setLoader] = useState(false);

  const { isRTL } = useContext(AppContext);
  const { user, setUser, step, setStep } = useContext(AuthWizardContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
    clearErrors,
  } = useForm();

  const [modal, setModal] = useState(false);
  const navigate = useNavigate();

  const navItems = [
    {
      icon: "user",
      label: "Account",
    },
    {
      icon: "lock",
      label: "Verifications",
    },
    {
      icon: "thumbs-up",
      label: "Done",
    },
  ];

  const loginCompanyUser = (email, password) => {
    let link = "/login";
    let dblink = "";

    if (portal === "company_portal") link = "/login";
    if (portal === "owner_portal") link = "/property/login";

    if (!portal && window.location.pathname === "/register") link = "/login";

    if (portal === "company_portal") dblink = `${hostname}/api/auth/login`;
    if (portal === "owner_portal") dblink = `${hostname}/api/auth/Ologin`;

    if (!portal && window.location.pathname === "/register")
      dblink = `${hostname}/api/auth/login`;

    window.location.href = link;
  };

  const onSubmitData = async (data) => {
    setLoader(true);
    setUser({ ...user, ...data });
    // console.log(data);
    if (step == 1) {
      const config = {
        header: {
          "Content-Type": "application/json",
        },
      };

      try {
        const data1 = await axios.post(
          `${hostname}/api/auth/emailverify`,

          {
            data,
          },
          config
        );

        setTimeout(() => {
          setLoader(false);
          if (data1.data.Check == "Verified") {
            setStep(step + 2);
          } else if (data1.data.Check == "Pending") {
            setStep(step + 1);
          } else {
            setStep(step + 1);
          }
        }, 1000);
      } catch (error) {
        setTimeout(() => {
          setLoader(false);
          toast.error(`Email already registered`, {
            theme: "colored",
          });
          // console.log('Error ' + error);
        }, 1000);
      }
    }

    if (step == 2) {
      ////data.verify code

      const config = {
        header: {
          "Content-Type": "application/json",
        },
      };
      try {
        const { data2 } = await axios.post(
          `${hostname}/api/auth/Registeruser`,
          {
            data,
          },
          config
        );
        if (data2) {
          //do nothing
        }
        //localStorage.setItem("authToken",data.token);
        setTimeout(() => {
          setLoader(false);
          toast.success(`Verified as ${data.email}`, {
            theme: "colored",
          });

          setStep(step + 1);
          setTimeout(() => {
            loginCompanyUser(data.email, data.password);
          }, 3000);
        }, 1000);
      } catch (error) {
        setTimeout(() => {
          setLoader(false);
          toast.error(`Invalid OTP : ${data.email}`, {
            theme: "colored",
          });
          // console.log('Error : ' + error);
        }, 1000);
      }
    }
  };

  const onError = () => {
    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
  };

  const toggle = () => setModal(!modal);

  const handleNavs = (targetStep) => {
    if (step !== 3) {
      if (targetStep < step) {
        if (step == 3 && targetStep == 2) {
          setStep(targetStep - 1);
        } else {
          setStep(targetStep);
        }
      } else {
        handleSubmit(onSubmitData, onError)();
      }
    } else {
      toggle();
    }
  };

  return (
    <>
      <WizardModal modal={modal} setModal={setModal} />
      <Card
        as={Form}
        noValidate
        onSubmit={handleSubmit(onSubmitData, onError)}
        className="theme-wizard mb-5"
      >
        <Card.Header
          className={classNames("bg-light", {
            "px-4 py-3": variant === "pills",
            "pb-2": !variant,
          })}
        >
          <Nav className="justify-content-center" variant={variant}>
            {variant === "pills"
              ? navItems.map((item, index) => (
                  <NavItemPill
                    key={item.label}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))
              : navItems.map((item, index) => (
                  <NavItem
                    key={item.label}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))}
          </Nav>
        </Card.Header>
        {progressBar && <ProgressBar now={step * 25} style={{ height: 2 }} />}
        <Card.Body className="fw-normal px-md-6 py-4">
          {step === 1 && (
            <AccountForm register={register} errors={errors} watch={watch} setValue={setValue}/>
          )}
          {step === 2 && (
            <EmailVerify
              register={register}
              errors={errors}
              setValue={setValue}
              regData={user}
            />
          )}
          {step === 3 && <Success reset={reset} />}
        </Card.Body>
        <Card.Footer
          className={classNames("px-md-6 bg-light", {
            "d-none": step === 3,
            " d-flex": step < 3,
          })}
        >
          <Button
            variant="secondary"
            // icon={isRTL ? "chevron-right" : "chevron-left"}
            // iconAlign="left"
            // transform="down-1 shrink-4"
            className={classNames("px-3 fw-semi-bold", {
              "d-none": step === 1,
            })}
            onClick={() => {
              if (step == 3) {
                setStep(step - 2);
              } else {
                setStep(step - 1);
              }
            }}
          >
            Previous
          </Button>

          <Button
            variant="primary"
            className="ms-auto px-3"
            type="submit"
            // icon={isRTL ? "chevron-left" : "chevron-right"}
            // iconAlign="right"
            // transform="down-1 shrink-4"
          >
            {loader ? (
              <RotatingLines
                strokeColor="white"
                strokeWidth="5"
                animationDuration="0.75"
                width="25"
                visible={true}
              />
            ) : (
              "Next"
            )}
          </Button>
        </Card.Footer>
      </Card>
    </>
  );
};

const NavItem = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames("fw-semi-bold", {
          done: index < 4 ? step > index : step > 3,
          active: step === index,
        })}
        onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs--1">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

const NavItemPill = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames("fw-semi-bold", {
          done: step > index,
          active: step === index,
        })}
        onClick={() => handleNavs(index)}
      >
        <Flex alignItems="center" justifyContent="center">
          <FontAwesomeIcon icon={icon} />
          <span className="d-none d-md-block mt-1 fs--1 ms-2">{label}</span>
        </Flex>
      </Nav.Link>
    </Nav.Item>
  );
};

WizardLayout.propTypes = {
  variant: PropTypes.oneOf(["pills"]),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool,
};

NavItemPill.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  handleNavs: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

NavItem.propTypes = NavItemPill.propTypes;

export default WizardLayout;
