import dateLib from "date-and-time";
import html2PDF from "jspdf-html2canvas";

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
// import axios from "axios";
import badwords from "./badwords";

import { FileIcon, defaultStyles } from "react-file-icon";

import pdfImgIcon from "../assets/img/image-icons/pdf-img.png";
import textImgIcon from "../assets/img/image-icons/text-img.png";
import docImgIcon from "../assets/img/image-icons/doc-img.png";
import zipImgIcon from "../assets/img/image-icons/zip-img.png";
import ImgIcon from "../assets/img/image-icons/picture.png";
import folderImgIcon from "../assets/img/image-icons/folder.png";
import { Image } from "react-bootstrap";

const {
  RegExpMatcher,
  TextCensor,
  englishDataset,
  englishRecommendedTransformers,
} = require("obscenity");

dayjs.extend(duration);

const matcher = new RegExpMatcher({
  ...englishDataset.build(),
  ...englishRecommendedTransformers,
});

const censor = new TextCensor();

export const isIterableArray = (array) =>
  Array.isArray(array) && !!array.length;

//===============================
// Breakpoints
//===============================
export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1540,
};

export const getItemFromStore = (key, defaultValue, store = localStorage) => {
  try {
    return store.getItem(key) === null
      ? defaultValue
      : JSON.parse(store.getItem(key));
  } catch {
    return store.getItem(key) || defaultValue;
  }
};

export const setItemToStore = (key, payload, store = localStorage) =>
  store.setItem(key, payload);

export const getStoreSpace = (store = localStorage) =>
  parseFloat(
    (
      escape(encodeURIComponent(JSON.stringify(store))).length /
      (1024 * 1024)
    ).toFixed(2)
  );

//===============================
// Cookie
//===============================
export const getCookieValue = (name) => {
  const value = document.cookie.match(
    "(^|[^;]+)\\s*" + name + "\\s*=\\s*([^;]+)"
  );
  return value ? value.pop() : null;
};

export const createCookie = (name, value, cookieExpireTime) => {
  const date = new Date();
  date.setTime(date.getTime() + cookieExpireTime);
  const expires = "; expires=" + date.toUTCString();
  document.cookie = name + "=" + value + expires + "; path=/";
};

export const numberFormatter = (number, fixed = 2) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(number)) >= 1.0e9
    ? (Math.abs(Number(number)) / 1.0e9).toFixed(fixed) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(number)) >= 1.0e6
    ? (Math.abs(Number(number)) / 1.0e6).toFixed(fixed) + "M"
    : // Three Zeroes for Thousands
    Math.abs(Number(number)) >= 1.0e3
    ? (Math.abs(Number(number)) / 1.0e3).toFixed(fixed) + "K"
    : Math.abs(Number(number)).toFixed(fixed);
};

//===============================
// Colors
//===============================
export const hexToRgb = (hexValue) => {
  let hex;
  hexValue.indexOf("#") === 0
    ? (hex = hexValue.substring(1))
    : (hex = hexValue);
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)
  );
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null;
};

export const rgbColor = (color = colors[0]) => `rgb(${hexToRgb(color)})`;
export const rgbaColor = (color = colors[0], alpha = 0.5) =>
  `rgba(${hexToRgb(color)},${alpha})`;

export const colors = [
  "#2c7be5",
  "#00d97e",
  "#e63757",
  "#39afd1",
  "#fd7e14",
  "#02a8b5",
  "#727cf5",
  "#6b5eae",
  "#ff679b",
  "#f6c343",
];

export const themeColors = {
  primary: "#2c7be5",
  secondary: "#748194",
  success: "#00d27a",
  info: "#27bcfd",
  warning: "#f5803e",
  danger: "#e63757",
  light: "#f9fafd",
  dark: "#0b1727",
};

export const grays = {
  white: "#fff",
  100: "#f9fafd",
  200: "#edf2f9",
  300: "#d8e2ef",
  400: "#b6c1d2",
  500: "#9da9bb",
  600: "#748194",
  700: "#5e6e82",
  800: "#4d5969",
  900: "#344050",
  1000: "#232e3c",
  1100: "#0b1727",
  black: "#000",
};

export const darkGrays = {
  white: "#fff",
  1100: "#f9fafd",
  1000: "#edf2f9",
  900: "#d8e2ef",
  800: "#b6c1d2",
  700: "#9da9bb",
  600: "#748194",
  500: "#5e6e82",
  400: "#4d5969",
  300: "#344050",
  200: "#232e3c",
  100: "#0b1727",
  black: "#000",
};

export const getGrays = (isDark) => (isDark ? darkGrays : grays);

export const rgbColors = colors.map((color) => rgbColor(color));
export const rgbaColors = colors.map((color) => rgbaColor(color));

export const getColor = (name, dom = document.documentElement) => {
  return getComputedStyle(dom).getPropertyValue(`--falcon-${name}`).trim();
};

//===============================

// Echarts
//===============================
export const getPosition = (pos, params, dom, rect, size) => ({
  top: pos[1] - size.contentSize[1] - 10,
  left: pos[0] - size.contentSize[0] / 2,
});
//===============================
// E-Commerce
//===============================
export const calculateSale = (base, less = 0, fix = 2) =>
  (base - base * (less / 100)).toFixed(fix);
export const getTotalPrice = (cart, baseItems) =>
  cart.reduce((accumulator, currentValue) => {
    const { id, quantity } = currentValue;
    const { price, sale } = baseItems.find((item) => item.id === id);
    return accumulator + calculateSale(price, sale) * quantity;
  }, 0);
export const getSubtotal = (items) =>
  items.reduce((acc, curr) => curr.price * curr.quantity + acc, 0);
export const getDiscountPrice = (total, discount) =>
  total - total * (discount / 100);

export const getProductsQuantity = (products) =>
  products.reduce((acc, product) => product.quantity + acc, 0);

//===============================
// Helpers
//===============================
export const getPaginationArray = (totalSize, sizePerPage) => {
  const noOfPages = Math.ceil(totalSize / sizePerPage);
  const array = [];
  let pageNo = 1;
  while (pageNo <= noOfPages) {
    array.push(pageNo);
    pageNo = pageNo + 1;
  }
  return array;
};

export const capitalize = (str) =>
  (str.charAt(0).toUpperCase() + str.slice(1)).replace(/-/g, " ");

export const camelize = (str) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};

export const dashed = (str) => {
  return str.toLowerCase().replaceAll(" ", "-");
};

//routes helper

export const flatRoutes = (childrens) => {
  const allChilds = [];

  const flatChild = (childrens) => {
    childrens.forEach((child) => {
      if (child.children) {
        flatChild(child.children);
      } else {
        allChilds.push(child);
      }
    });
  };
  flatChild(childrens);

  return allChilds;
};

export const getFlatRoutes = (children) =>
  children.reduce(
    (acc, val) => {
      if (val.children) {
        return {
          ...acc,
          [camelize(val.name)]: flatRoutes(val.children),
        };
      } else {
        return {
          ...acc,
          unTitled: [...acc.unTitled, val],
        };
      }
    },
    { unTitled: [] }
  );

export const routesSlicer = ({ routes, columns = 3, rows }) => {
  const routesCollection = [];
  routes.map((route) => {
    if (route.children) {
      return route.children.map((item) => {
        if (item.children) {
          return routesCollection.push(...item.children);
        }
        return routesCollection.push(item);
      });
    }
    return routesCollection.push(route);
  });

  const totalRoutes = routesCollection.length;
  const calculatedRows = rows || Math.ceil(totalRoutes / columns);
  const routesChunks = [];
  for (let i = 0; i < totalRoutes; i += calculatedRows) {
    routesChunks.push(routesCollection.slice(i, i + calculatedRows));
  }
  return routesChunks;
};

export const getPageName = (pageName) => {
  return window.location.pathname.split("/").slice(-1)[0] === pageName;
};

export const copyToClipBoard = (textFieldRef) => {
  const textField = textFieldRef.current;
  textField.focus();
  textField.select();
  document.execCommand("copy");
};

export const reactBootstrapDocsUrl = "https://react-bootstrap.github.io";

export const pagination = (currentPage, size) => {
  const pages = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  let prev = currentPage - 1 - Math.floor(size / 2);

  if (currentPage - 1 - Math.floor(size / 2) < 0) {
    prev = 0;
  }
  if (currentPage - 1 - Math.floor(size / 2) > pages.length - size) {
    prev = pages.length - size;
  }
  const next = prev + size;

  return pages.slice(prev, next);
};

export const tooltipFormatter = (params) => {
  let tooltipItem = ``;
  params.forEach((el) => {
    tooltipItem =
      tooltipItem +
      `<div class='ms-1'> 
        <h6 class="text-700"><span class="fas fa-circle me-1 fs--2" style="color:${
          el.borderColor ? el.borderColor : el.color
        }"></span>
          ${el.seriesName} : ${
        typeof el.value === "object" ? el.value[1] : el.value
      }
        </h6>
      </div>`;
  });
  return `<div>
            <p class='mb-2 text-600'>
              ${
                dayjs(params[0].axisValue).isValid()
                  ? dayjs(params[0].axisValue).format("MMMM DD")
                  : params[0].axisValue
              }
            </p>
            ${tooltipItem}
          </div>`;
};

export const addIdField = (items) => {
  return items.map((item, index) => ({
    id: index + 1,
    ...item,
  }));
};

// get file size

export const getSize = (size) => {
  if (size < 1024) {
    return `${size} Byte`;
  } else if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(2)} KB`;
  } else {
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  }
};

/* Get A Random Number */
export const getRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min) + min);
};

/* get Dates between */

export const getDates = (
  startDate,
  endDate,
  interval = 1000 * 60 * 60 * 24
) => {
  const duration = endDate - startDate;
  const steps = duration / interval;
  return Array.from(
    { length: steps + 1 },
    (v, i) => new Date(startDate.valueOf() + interval * i)
  );
};

/* Get Past Dates */
export const getPastDates = (duration) => {
  let days;

  switch (duration) {
    case "week":
      days = 7;
      break;
    case "month":
      days = 30;
      break;
    case "year":
      days = 365;
      break;

    default:
      days = duration;
  }

  const date = new Date();
  const endDate = date;
  const startDate = new Date(new Date().setDate(date.getDate() - (days - 1)));
  return getDates(startDate, endDate);
};

// Add id to items in array
export const addId = (items) =>
  items.map((item, index) => ({
    id: index + 1,
    ...item,
  }));

//
export const getTimeDuration = (startDate, endDate, format = "") => {
  return dayjs.duration(endDate.diff(startDate)).format(format);
};

// Get Percentage
export const getPercentage = (number, percent) => {
  return (Number(number) / 100) * Number(percent);
};

//get chunk from array
export const chunk = (arr, chunkSize = 1, cache = []) => {
  const tmp = [...arr];
  if (chunkSize <= 0) return cache;
  while (tmp.length) cache.push(tmp.splice(0, chunkSize));
  return cache;
};



export const formatLogInfo = (info, noTime = false) => {
  // console.log(noTime);
  // console.log(info);
  info = new Date(info);
  if (noTime) {
    info = dateLib.format(info, "MMM DD, YYYY");
  } else {
    info = dateLib.format(info, "MMM DD, YYYY - hh:mm A");
  }
  info = info === undefined ? "-" : info;
  return info;
};

// Slugify text
export const slugifyText = (str) =>
  str
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/--+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");

export const formatToCurrency = (amount) => {
  return "$" + amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export const getFormattedSentence = (data = "") => {
  if (data?.length) {
    if (data?.length > 60) return data?.slice(0, 15) + "...";
    else return data;
  } else {
    return "-";
  }
};

export const generatePdfByJsPdf = async (
  htmlNode,
  actionMode = "preview",
  topMargin = 20,
  leftMargin = 20,
  rightMargin = 20,
  bottomMargin = 0
) => {
  const pdf = await html2PDF(htmlNode, {
    jsPDF: {
      format: "a4",
    },
    imageType: "image/jpeg",
    margin: {
      top: topMargin,
      left: leftMargin,
      right: rightMargin,
      bottom: bottomMargin,
    },
    // output: "./pdf/generate.pdf",
    success: () => {
      console.log("pdf generated");
    },
  });

  let pdfFile = "";
  // console.log(pdfFile);

  if (actionMode === "preview") {
    pdfFile = await pdf.output("blob", { filename: "sample.pdf" });
    window.open(URL.createObjectURL(pdfFile));
  }
  if (actionMode === "download") {
    pdf.save("sample.file");
  }
  if (actionMode === "return") {
    pdfFile = await pdf.output("datauristring", { filename: "sample.pdf" });
    return pdfFile;
  }
};

export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const wait = (milliseconds) => {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
};

export const addDays = (date, days) => {
  let dateNew = getPureDate(false, date);
  const copy = getPureDate(false, Number(date));
  copy.setDate(dateNew.getDate() + days);
  return copy;
};

export const getDaysFromDatesRange = (start, end, includesLastDay = true) => {
  let days = (end - start) / (1000 * 3600 * 24);
  days = Math.ceil(days);
  if (includesLastDay) days++; // includes last day
  // console.log("days from dates range=" + days);
  return days;
};

export const getBase64Type = (base64String = "") => {
  let logoType = "";
  if (base64String?.startsWith("http")) logoType = "link";
  if (base64String?.startsWith("/9g")) logoType = "jpg";
  if (base64String?.startsWith("/9j")) logoType = "jpeg";
  if (base64String?.startsWith("iVBORw0KGgo=")) logoType = "png";
  return logoType;
};

export const getTodayItemsFromList = (
  list = [],
  isCustomDate = false,
  customDate
) => {
  let todayData = [];
  // console.log(customDate);
  let today = new Date().toDateString();

  todayData = list?.filter((v) => {
    let permitDate = new Date(v.start_date).toDateString();
    // console.log(permitDate);

    if (isCustomDate) return permitDate === customDate.toDateString();
    else return permitDate === today;
  });
  // console.log(todayData);
  return todayData;
};

export const getDaysInMonth = (date) => {
  let d = new Date(date);
  d.setHours(0, 0, 0, 0);
  let y = d.getFullYear();
  let m = d.getMonth();
  return new Date(parseInt(y), parseInt(m) + 1, 0).getDate();
};

export const getPureDate = (today = true, date, last_midnight = true) => {
  if (today && last_midnight) {
    // default date obj with time 0
    return new Date(new Date().setHours(0, 0, 0, 0));
  }
  if (!today && last_midnight) {
    // given date obj with time 0
    return new Date(new Date(date).setHours(0, 0, 0, 0));
  }
  if (today && !last_midnight) {
    // default date obj with time 0 future midnight
    return new Date(new Date().setHours(0, 0, 0, 0));
  }
  if (!today && !last_midnight) {
    // given date obj with time 0 future midnight
    return new Date(new Date(date).setHours(0, 0, 0, 0));
  }
};

export const isDateOfCurrentYearAndMonth = (date1, date2) => {
  date1 = getPureDate(false, date1);
  date2 = getPureDate(false, date2);
  if (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth()
  ) {
    // console.log("date is same & true");
    return true;
  } else {
    // console.log("date is not same & true");
    return false;
  }
};

export function highlightMatch(text, matchString) {
  let textArr = text.split(" ");
  let returnArr = [];

  for (let i = 0; i < textArr.length; i++) {
    let subStrMatch = textArr[i]
      .toLowerCase()
      .indexOf(matchString.toLowerCase());

    if (subStrMatch !== -1) {
      let subStr = textArr[i].split("");
      let subStrReturn = [];

      for (let j = 0; j < subStr.length; j++) {
        if (j === subStrMatch) {
          subStrReturn.push("<strong>" + subStr[j]);
        } else if (j === subStrMatch + (matchString.length - 1)) {
          subStrReturn.push(subStr[j] + "<strong>");
        } else {
          subStrReturn.push(subStr[j]);
        }
      }
      returnArr.push(subStrReturn.join(""));
    } else {
      returnArr.push(textArr[i]);
    }
  }
  return returnArr;
}

// export function downloadFile(file = "", name = "") {
//   const linkSource = pdf;
//   if (pdf && pdf.startsWith("data:") === false)
//     linkSource = `data:application/pdf;base64,${pdf}`;
//   const downloadLink = document.createElement("a");
//   const fileName = name || "abc.pdf";
//   downloadLink.href = linkSource;
//   downloadLink.download = fileName;
//   downloadLink.click();
// }

export const getFileType = (fileObj, fromDriveTable = false) => {
  // console.log(fileObj);
  let file = {};
  if (fileObj.file instanceof File) file.mimetype = fileObj.mimeType;
  else file.mimetype = fileObj.mimetype;
  // console.log(file);

  let srcImg = textImgIcon;
  let widthImg = "30px";

  if (
    file.mimetype === "image/png" ||
    file.mimetype === "image/jpeg" ||
    file.mimetype === "image/jpg"
  ) {
    srcImg = ImgIcon;
    widthImg = fromDriveTable ? "30px" : "70px";
  }

  if (file.mimetype === "application/x-zip-compressed") {
    srcImg = zipImgIcon;
    widthImg = fromDriveTable ? "30px" : "70px";
  }

  if (file.mimetype === "text/plain") {
    srcImg = textImgIcon;
    widthImg = fromDriveTable ? "30px" : "60px";
  }

  if (file.mimetype === "application/pdf") {
    srcImg = pdfImgIcon;
    widthImg = fromDriveTable ? "30px" : "100px";
  }

  if (file.mimetype === "application/octect-stream") {
    srcImg = textImgIcon;
    widthImg = fromDriveTable ? "30px" : "80px";
  }

  if (
    file.mimetype ===
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ) {
    srcImg = docImgIcon;
    widthImg = fromDriveTable ? "30px" : "80px";
  }

  if (file.mimetype === "folder") {
    srcImg = folderImgIcon;
    widthImg = fromDriveTable ? "30px" : "80px";
  }

  if (fromDriveTable) return <Image src={srcImg} style={{ width: widthImg }} />;
  else {
    return (
      <div
        className="d-flex justify-content-center align-items-center p-3"
        style={{ height: "100%" }}
      >
        <Image src={srcImg} style={{ width: widthImg }} />
      </div>
    );
  }
};

export const getFileExtData = (ext) => {
  // console.log(ext);

  let obj = defaultStyles[ext];

  if (ext === "bmp") {
    obj.labelColor = "#000";
  }
  if (ext === "rtf") {
    obj.labelColor = "#000";
  }
  if (ext === "txt") {
    obj.labelColor = "#000";
  }

  if (ext === "pdf") {
    obj.color = "red";
    obj.glyphColor = "#fff";
  }
  if (ext === "zip") {
    obj.color = "gold";
    obj.glyphColor = "#fff";
  }
  if (ext === "png" || ext === "jpg" || ext === "jpeg") {
    obj.color = "green";
    obj.glyphColor = "#fff";
  }

  return <FileIcon extension={ext} {...obj} />;
};

export function byteConverter(bytes, decimals, only, smartReply = false) {
  const K_UNIT = 1024;
  const SIZES = ["Bytes", "KB", "MB", "GB", "TB", "PB"];

  if (bytes == 0) return "0 Byte";

  if (only === "MB" && !smartReply)
    return (bytes / (K_UNIT * K_UNIT)).toFixed(decimals);

  if (only === "MB" && smartReply) {
    if ((bytes / (K_UNIT * K_UNIT)).toFixed(decimals) > 0)
      return (bytes / (K_UNIT * K_UNIT)).toFixed(decimals);
    else return "< 0";
  }

  let i = Math.floor(Math.log(bytes) / Math.log(K_UNIT));
  let resp =
    parseFloat((bytes / Math.pow(K_UNIT, i)).toFixed(decimals)) +
    " " +
    SIZES[i];

  return resp;
}

export function timeAgo(dateString) {
  const date = new Date(dateString);
  const now = new Date();

  const seconds = Math.floor((now - date) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  if (years > 0) {
    return years === 1 ? "1 year ago" : `${years} years ago`;
  } else if (months > 0) {
    return months === 1 ? "1 month ago" : `${months} months ago`;
  } else if (days > 0) {
    return days === 1 ? "1 day ago" : `${days} days ago`;
  } else if (hours > 0) {
    return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
  } else if (minutes > 0) {
    return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
  } else {
    return "Just now";
  }
}

export function sortByLatest(objectsArray, dateProperty) {
  if (!Array.isArray(objectsArray) || objectsArray.length === 0) {
    
    return [];
  }

  if (!dateProperty || typeof dateProperty !== "string") {
    
    return objectsArray;
  }

  return objectsArray.sort(
    (a, b) => new Date(b[dateProperty]) - new Date(a[dateProperty])
  );
}

export function detectForbiddenWords(text) {
  let isInfected = false;
  const matches = matcher.getAllMatches(text, true);

  for (const match of matches) {
    const { phraseMetadata, startIndex, endIndex } =
      englishDataset.getPayloadWithPhraseMetadata(match);
    // console.log(
    //   `Match for word ${phraseMetadata.originalWord} found between ${startIndex} and ${endIndex}.`
    // );
    isInfected = true;
  }

  // console.log(censor.applyTo(text, matches));

  /////2

  const immoralWords = ["explicit", "offensive", "indecent"];
  // Placeholder immoral words
  const illegalWords = ["contraband", "illegal", "prohibited", "restricted"];
  const dangerousWords = [
    "hazardous",
    "threatening",
    "harmful",
    "dangerous",
    "drugs",
  ];

  const wordRegex = /\b\w+\b/g;

  const detectedImmoralWords = [];
  const detectedIllegalWords = [];
  const detectedDangerousWords = [];

  let match;
  while ((match = wordRegex.exec(text)) !== null) {
    const word = match[0].toLowerCase();
    if (immoralWords.includes(word)) {
      detectedImmoralWords.push(word);
    }
    if (illegalWords.includes(word)) {
      detectedIllegalWords.push(word);
    }
    if (dangerousWords.includes(word)) {
      detectedDangerousWords.push(word);
    }
    if (badwords.includes(word)) {
      detectedDangerousWords.push(word);
    }
  }

  const resultArrays = {
    immoralWords: detectedImmoralWords,
    illegalWords: detectedIllegalWords,
    dangerousWords: detectedDangerousWords,
  };

  if (detectedImmoralWords.length > 0) isInfected = true;
  if (detectedIllegalWords.length > 0) isInfected = true;
  if (detectedDangerousWords.length > 0) isInfected = true;

  return isInfected;
}

export const getHostName = () => {
  
  let host = "http://localhost:5000";

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    // dev code
    host = process.env.REACT_APP_SERVER_LOCAL;
  } else {
    // production code
    host = process.env.REACT_APP_SERVER_LIVE;
  }

  // console.log(host);
  return host;
};

export function convertUppercaseToLowercase(input) {
  // Create a new string with only the letters converted to lowercase
  return input.replace(/[A-Z]/g, match => match.toLowerCase());
}

