import React from "react";
import {
  Button,
  Modal,
  Card,
  Table,
  // Form as BSForm,
  // Row,
  // Col
} from "react-bootstrap";
// import Wizard from './wizardRemastered/Wizard';
import FalconCloseButton from "../../../components/common/FalconCloseButton";
import FalconCardHeader from "../../../components/common/FalconCardHeader";
import PrintIcon from "@mui/icons-material/Print";

import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { formatLogInfo } from "../../../helpers/utils";
import { useParams } from "react-router-dom";

export default function PaymentReceipt(props) {
  const printRef = React.useRef();

  console.log(props);
  

  const handleDownloadPdf = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    // pdf.save(`${props.booking.amenityName}-${new Date().toISOString()}.pdf`);
    window.open(
      pdf.output(
        "bloburi",
        `${props.booking.amenityName}-${new Date().toISOString()}.pdf`
      )
    );
    // pdf.output('datauri')
  };

  //const { property_name } = useParams();
  try {
    const { booking, userData } = props;

    return (
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        // className='bg-info'
        style={{ backdropFilter: "blur(3px)" }}
        keyboard={false}
        size="lg"
        // fullscreen
        // centered
        // id="report"
      >
        <Modal.Header>
          <Modal.Title>Payment Details </Modal.Title>
          <FalconCloseButton onClick={props.handleClose} />
        </Modal.Header>
        <Modal.Body className="">
          {booking.loadType === "Manager" && (
            <Card className="">
              <FalconCardHeader
                // className="bg-light"
                title="Payment Receipt"
                endEl={
                  <Button
                    variant="falcon-primary"
                    // onClick={generatePDF}
                    onClick={handleDownloadPdf}
                    type="button"
                  >
                    <PrintIcon /> Print
                  </Button>
                }
              />
              <Card.Body className="bg-light" ref={printRef}>
                <div className="text-center border-bottom border-bottom-3">
                  <h5 className="mb-3">
                    {props.propertyAddress.property_name || "Property Name"}
                  </h5>
                  <p className="text-secondary fs-0">
                    {props.propertyAddress.Address} <br />
                    {props.propertyAddress.city} {props.propertyAddress.state}{" "}
                    {props.propertyAddress.Zip} <br />
                    {props.propertyAddress.country}{" "}
                  </p>
                </div>
                <div>
                  <Table borderless className="ms-3">
                    <tbody>
                      <tr>
                        <td>Booking ID</td>
                        <td className="fw-bold text-center text-center">
                          {booking._id
                            ? booking._id?.slice(0, 8)
                            : "" || "4547453"}
                        </td>
                      </tr>
                      <tr>
                        <td>Date</td>
                        <td className="fw-bold text-center">
                          {new Date().toISOString().slice(0, 10) ||
                            "19 March,2023 "}
                        </td>
                      </tr>
                      <tr>
                        <td>Time</td>
                        <td className="fw-bold text-center">05:40 PM</td>
                      </tr>
                      <tr>
                        <td>Payment Method</td>
                        <td className="fw-bold text-center">Credit Card</td>
                      </tr>
                      <tr>
                        <td>Credit Card Number</td>
                        <td className="fw-bold text-center">*********1234</td>
                      </tr>
                      <tr>
                        <td>Amount</td>
                        <td className="fw-bold text-center">
                          {"$" + booking.amenityPrice + ".00" || "$150.00"}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <div className="border-top border-top-3 text-center">
                  <br />
                  <h5 style={{ fontStyle: "italic" }}>** THANK YOU ! **</h5>
                </div>
              </Card.Body>
            </Card>
          )}
          {booking.loadType === "Owner" && (
            <Card className="">
              <FalconCardHeader
                // className="bg-light"
                title="Payment Receipt"
                endEl={
                  <Button variant="falcon-primary" onClick={handleDownloadPdf}>
                    <PrintIcon /> Print
                  </Button>
                }
              />
              <Card.Body className="bg-light" ref={printRef}>
              <div className="text-center border-bottom border-bottom-3">
                  <h5 className="mb-3">
                    {props.propertyAddress.property_name}
                  </h5>
                  <p className="text-secondary fs-0">
                    {props.propertyAddress.Address} <br />
                    {props.propertyAddress.city} {props.propertyAddress.state}{" "}
                    {props.propertyAddress.Zip} <br />
                    {props.propertyAddress.country}{" "}
                  </p>
                </div>
                <div>
                  <Table borderless className="ms-3">
                    <tbody>
                      <tr>
                        <td>Booking ID</td>
                        <td className="fw-bold text-center text-center">
                          {booking?._id?.slice(0, 8)}
                        </td>
                      </tr>
                      <tr>
                        <td>Payment Date</td>
                        <td className="fw-bold text-center">
                          {formatLogInfo(booking?.payment_date)}
                        </td>
                      </tr>

                      <tr>
                        <td>Payment Method</td>
                        <td className="fw-bold text-center">
                          {booking?.payment_method === 'Check' ? 'Cheque' : booking?.payment_method}
                        </td>
                      </tr>
                      {/* <tr>
                        <td>Credit Card Number</td>
                        <td className="fw-bold text-center">*********1234</td>
                      </tr> */}
                      <tr>
                        <td>Amount</td>
                        <td className="fw-bold text-center">
                          ${booking?.paid_amount}.00
                        </td>
                      </tr>
                      <tr>
                        <td>Payment By</td>
                        <td className="fw-bold text-center">
                          {booking?.Invoice_id?.user_id?.firstname || "name"}
                          {booking?.Invoice_id?.user_id?.lastname || "name"}
                        </td>
                      </tr>
                      <tr>
                        <td>Received By</td>
                        <td className="fw-bold text-center">
                          {booking?.accepted_by?.firstname || "name"}
                          {booking?.accepted_by?.lastname || "name"}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <div className="border-top border-top-3 text-center">
                  <br />
                  <h5 style={{ fontStyle: "italic" }}>** THANK YOU ! **</h5>
                </div>
              </Card.Body>
            </Card>
          )}
        </Modal.Body>
      </Modal>
    );
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
}
