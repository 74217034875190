import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Avatar from 'components/common/Avatar';
// import Image from './Image';
// import SoftBadge from "components/common/SoftBadge";
// import { Badge, Table, Dropdown } from 'react-bootstrap';
import { Dropdown } from "react-bootstrap";
import { Table } from "react-bootstrap";

import CardDropdown from "../../../../components/common/CardDropdown";
import { formatLogInfo } from "../../../../helpers/utils";

const TableRow = ({ data, propLength, viewPermitDetails, printPermit }) => {
  return (
    <tr
      className="align-middle"
      style={{ height: propLength === 1 ? "100px" : "" }}
    >
      <td
        className="text-center text-primary cursor-pointer"
        onClick={() => viewPermitDetails(data)}
      >
        {data?._id?.slice(0, 8)}
      </td>
      <td className="text-center">{data?.unit_id?.unit_number}</td>
      <td className="text-center">{data?.license_plate}</td>

      <td className="text-center">{formatLogInfo(data?.start_date, true)}</td>
      <td className="text-center">{formatLogInfo(data?.end_date, true)}</td>
      {/* <td className="text-center">
        {data?.status === "Reserved" && (
          <SoftBadge pill bg={"warning"}>
            {data.status}
            <FontAwesomeIcon icon={"check"} className="mx-2" />
          </SoftBadge>
        )}
        {data?.status !== "Reserved" && (
          <SoftBadge pill bg={"success"}>
            {data.status}
            <FontAwesomeIcon icon={"check"} className="mx-2" />
          </SoftBadge>
        )}
      </td> */}
      <td className="text-center">
        <CardDropdown>
          <div className="py-2">
            <Dropdown.Item
              className="text-primary"
              // onClick={() => printPermit(data)}
              onClick={() => viewPermitDetails(data)}
            >
              Print Pass
            </Dropdown.Item>
          </div>
        </CardDropdown>
      </td>
    </tr>
  );
};

const VisitorParkingTable = ({ list, viewPermitDetails, printPermit }) => {
  // console.log(properties);

  return (
    <Table
      responsive
      striped
      hover
      bordered
      style={{ fontSize: "14px" }}
      className="pb-5"
    >
      <thead>
        <tr>
          <th scope="col" className="text-center">
            Permit #
          </th>
          <th scope="col" className="text-center">
            Visiting Unit #
          </th>
          <th scope="col" className="text-center">
            License Plate #
          </th>
          <th scope="col" className="text-center">
            Start Date
          </th>
          <th scope="col" className="text-center">
            End Date
          </th>
          {/* <th scope="col" className="text-center">
            Status
          </th> */}
          <th scope="col" className="text-center">
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        {list?.map((item, index) => (
          <TableRow
            data={item}
            key={index}
            propLength={list.length}
            viewPermitDetails={viewPermitDetails}
            printPermit={printPermit}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default VisitorParkingTable;
