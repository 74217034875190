import React from "react";
import { Modal, Form as BSForm, Button } from "react-bootstrap";
import FalconCloseButton from "../../../components/common/FalconCloseButton";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";

let colors = [
  "purple",
  "black",
  "red",
  "blue",
  "orange",
  "crimson",
  "blueviolet",
  "brown",
  "slateblue",
  "chocolate",
  "coral",
  "cornflowerblue",
  "sienna",
  "rebeccapurple",
];

export default function UsersManagementModel({
  show,
  handleClose,
  userData,
  getUsersData,
  payload,
  setPayload,
  handleCloseUserDetailTab,
  hostname,
  jwtToken,
}) {
  console.log(payload);

  const inviteUser = (values) => {
    let rn = Math.floor(Math.random() * colors.length);

    let data = {
      firstname: userData.firstname,
      c_id: userData.compid,
      email: values.email?.toLowerCase(), 
      group: values.userGroup,
      bgColor: colors[rn],
      invitee_email: userData.email,
    };
    axios
      .post(
        `${hostname}/api/auth/inviteUser`,
        { data },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then(() => {
        // console.log(res.data);
        setTimeout(() => {
          toast.success(`invite sent to ${values.email}.`, {
            theme: "colored",
          });
        }, 500);
        getUsersData();
        setPayload({});
        handleClose();
      })
      .catch((err) => {
        console.log(err.response.data.error);
        setPayload({});
        handleClose();
        toast.error(err.response.data.error || "Error occured!", {
          theme: "colored",
        });
      });
  };

  const reinviteUser = (values) => {
    let data = {
      firstname: userData.firstname,
      c_id: userData.compid,
      email: values.email?.toLowerCase(),
      group: values.userGroup,
      bgColor: payload.bgColor,
      invitee_email: userData.email,
      old_email: payload.email?.toLowerCase(),
    };

    // console.log(data);

    // return;

    axios
      .post(
        `${hostname}/api/auth/resendinvite`,
        { data },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        // setTimeout(() => {
        toast.success(`invite sent to ${values.email}.`, {
          theme: "colored",
        });
        getUsersData();
        handleClose();
        // }, 500);
      })
      .catch((err) => {

        console.log(err);
        // getUsersData();
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });

    // axios
    //   .post(
    //     `${hostname}/api/auth/inviteUser`,
    //     { data },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${jwtToken}`,
    //       },
    //     }
    //   )
    //   .then(() => {
    //     // console.log(res.data);
    //     setTimeout(() => {
    //       toast.success(`invite sent to ${values.email}.`, {
    //         theme: "colored",
    //       });
    //     }, 500);
    //     getUsersData();
    //     setPayload({});
    //     handleClose();
    //   })
    //   .catch((err) => {
    //     console.log(err.response.data.error);
    //     setPayload({});
    //     handleClose();
    //     toast.error(err.response.data.error || "Error occured!", {
    //       theme: "colored",
    //     });
    //   });
  };

  const revokeUser = () => {
    // console.log(props);
    let data = {
      c_id: userData.compid,
      email: payload.email,
      user_id: payload._id,
      admin_id: userData.data,
    };
    console.log(data);
    axios
      .post(
        `${hostname}/api/auth/SuspendUser`,
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then(() => {
        // console.log(res.data);
        getUsersData();
        handleClose();
        setPayload({});
        toast.success("Success! Access Revoked", { theme: "colored" });
      })
      .catch((err) => {
        console.log(err);
        if ("response" in err)
          toast.error(err.response.data.error, { theme: "colored" });
        else toast.error("something went wrong!", { theme: "colored" });
      });
  };

  const grantAccess = () => {
    // console.log(props);
    let data = {
      c_id: userData.compid,
      email: payload.email,
      user_id: payload._id,
      admin_id: userData.data,
    };
    // console.log(data);
    axios
      .post(
        `${hostname}/api/auth/ActivateUser`,
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then(() => {
        // console.log(res.data);
        getUsersData();
        handleClose();
        setPayload({});

        toast.success(
          <span className="text-white">Success! Access granted</span>
        );
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error! something went wrong!");
        setPayload({});
        getUsersData();
      });
  };

  const deleteInvitedUser = () => {
    let data = { c_id: userData.compid, email: payload.email };

    
  };

  const changeUserGroup = (values) => {
    let userToGroup = {};
    userToGroup.user_id = payload._id;
    userToGroup.c_id = payload.c_id;
    userToGroup.group_name = values.groupOfUser;
    userToGroup.email = payload.email;

    console.log(userToGroup);
    // return;
    axios
      .post(
        `${hostname}/api/auth/changegroup`,
        { ...userToGroup },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.success('Group Updated Successfully', { theme: "colored" });
        getUsersData();
        // setPayload({});
        if (handleCloseUserDetailTab) handleCloseUserDetailTab();

        handleClose();
      })
      .catch((err) => {
        console.log(err);
        if ("response" in err)
          toast.warning(err.response.data.error, { theme: "colored" });
        else toast.warning("something went wrong!", { theme: "colored" });
        // setPayload({});

        // getUsersData();
      });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>{payload.loadType}</Modal.Title>
        <FalconCloseButton onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        {payload.loadType === "Invite Users" && (
          <Formik
            initialValues={{ email: "", userGroup: "" }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
              userGroup: Yup.string().required("user group is required!"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              inviteUser(values);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <BSForm.Label>
                  Email Address <span className="text-danger">*</span>
                </BSForm.Label>
                <Field type="email" name="email" className="form-control" />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-danger m-2 fs--1"
                />
                <br />
                <BSForm.Label>
                  User Group <span className="text-danger">*</span>
                </BSForm.Label>
                <Field
                  component="select"
                  id="userGroup"
                  name="userGroup"
                  // multiple={true}
                  className="form-select"
                >
                  <option value="">Select Group</option>
                  <option value="Condozak-Basic-Users">Condozak-Users</option>
                  <option value="Site-Administrators">
                    Site-Administrators
                  </option>
                </Field>
                <ErrorMessage
                  name="userGroup"
                  component="div"
                  className="text-danger m-2 fs--1"
                />
                <br />
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-primary float-end"
                >
                  Add
                </button>
              </Form>
            )}
          </Formik>
        )}
        {payload.loadType === "Reinvite User" && (
          <Formik
            initialValues={{
              email: payload.email,
              userGroup: payload.groups[0] || "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
              userGroup: Yup.string().required("user group is required!"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              reinviteUser(values);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <BSForm.Label>
                  Email Address <span className="text-danger">*</span>
                </BSForm.Label>
                <Field type="email" name="email" className="form-control" />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-danger m-2 fs--1"
                />
                <br />
                <BSForm.Label>
                  User Group <span className="text-danger">*</span>
                </BSForm.Label>
                <Field
                  component="select"
                  id="userGroup"
                  name="userGroup"
                  // multiple={true}
                  className="form-select"
                >
                  <option value="">Select Group</option>
                  <option value="Condozak-Basic-Users">Condozak-Users</option>
                  <option value="Site-Administrators">
                    Site-Administrators
                  </option>
                </Field>
                <ErrorMessage
                  name="userGroup"
                  component="div"
                  className="text-danger m-2 fs--1"
                />
                <br />
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-primary float-end"
                >
                  Send
                </button>
              </Form>
            )}
          </Formik>
        )}
        {payload.loadType === "Revoke Access" && (
          <>
            <p className="" style={{ fontSize: "15px" }}>
              Are you sure you want to revoke access for this user ?
            </p>
            {(payload.groups?.includes("Site-Administrators") ||
              payload.groups?.includes("Condozak-Basic-Users")) &&
              payload.status.title === "Active" &&
              payload.admin_properties_count > 0 && (
                <p className="text-warning">
                  This user is managing {payload.admin_properties_count || 0}{" "}
                  {payload.admin_properties_count === 1
                    ? "property"
                    : "properties"}
                  . Revoking access will impact email communications, as their
                  name is used in emails and other services. Are you sure you
                  want to proceed?
                </p>
              )}
              {(payload.groups?.includes("Site-Administrators") ||
              payload.groups?.includes("Condozak-Basic-Users")) &&
              payload.status.title === "Active" &&
              payload.admin_properties_count < 1 && (
                <div className="text-end">
              <Button
                variant="falcon-warning"
                className="me-3 "
                size="md"
                onClick={revokeUser}
                style={{ width: 80 }}
              >
                Yes
              </Button>
              <Button
                variant="falcon-default"
                size="md"
                onClick={handleClose}
                style={{ width: 80 }}
              >
                No
              </Button>
            </div>
              )}
            
          </>
        )}
        {payload.loadType === "Grant Access" && (
          <>
            <p className="" style={{ fontSize: "15px" }}>
              Are you sure you want to grant access for this user ?
            </p>
            <div className="text-end">
              <Button
                variant="primary"
                className="me-3 "
                size="md"
                onClick={grantAccess}
                style={{ width: 80 }}
              >
                Yes
              </Button>
              <Button
                variant="falcon-default"
                size="md"
                onClick={handleClose}
                style={{ width: 80 }}
              >
                No
              </Button>
            </div>
          </>
        )}
        {payload.loadType === "Delete User" && (
          <>
            <p className="" style={{ fontSize: "15px" }}>
              Are you sure you want to delete this user ?
            </p>
            <div className="text-end">
              <Button
                variant="falcon-warning"
                className="me-3 "
                size="md"
                onClick={deleteInvitedUser}
                style={{ width: 80 }}
              >
                Yes
              </Button>
              <Button
                variant="falcon-default"
                size="md"
                onClick={handleClose}
                style={{ width: 80 }}
              >
                No
              </Button>
            </div>
          </>
        )}
        {payload.loadType === "Change Group" && (
          <Formik
            initialValues={{ groupOfUser: payload.groups[0] || "" }}
            validate={(values) => {
              const errors = {};
              if (!values.groupOfUser)
                errors.groupOfUser = "user group is required!";
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              changeUserGroup(values);
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div>
                  <label>Group Name</label>
                  <Field
                    component="select"
                    id="groupOfUser"
                    name="groupOfUser"
                    className="form-select"
                  >
                    <option value="Site-Administrators">
                      Site-Administrators
                    </option>
                    <option value="Condozak-Basic-Users">Condozak-Users</option>
                  </Field>
                  <ErrorMessage
                    name="groupOfUser"
                    component="div"
                    className="text-danger fs--1"
                  />
                </div>
                <br />
                <div className="text-end">
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Change
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </Modal.Body>
    </Modal>
  );
}
