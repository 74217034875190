import React, { useState } from 'react';
import FalconCardHeader from '../../../../../../components/common/FalconCardHeader';
import { Button, Card, Row, Col } from 'react-bootstrap';
// import { Form, Formik, Field, ErrorMessage } from 'formik';
import { Form, Formik } from 'formik';

import CloseButton from '../../../../../../components/common/FalconCloseButton';
import './ImagesDes.css';
import { v4 as uuidv4 } from 'uuid';
// import { values } from 'lodash';
export default function ImagesDes({ amenityCurrent, updateAmenityImages }) {
  
    //for images
    // const [imgs, setImgs] = useState([img1, img2, img3]);
    // const [imgs, setImgs] = useState([img1, img2, img3]);
    // const [imgs, setImgs] = useState(amenityCurrent.amenityimages || []);

    const [imgsOld, setImgsOld] = useState(amenityCurrent.amenityimages);
    const [imgs, setImgs] = useState([]);
    const [imgsErrors, setImgsErrors] = useState({});
    const [toDelete, setToDelete] = useState([]);

    const handleSetToDelete = link => {
      setToDelete(toDelete.concat(link));
    };

    React.useEffect(() => {
      makeFirstObj();
    }, []);

    const makeFirstObj = () => {
      let imgsObj = [];
      imgsObj = imgsOld?.map((v, i) => {
        let obj = {};
        obj.img = v;
        obj.count = i;
        obj.isLast = false;
        obj.id = uuidv4();
        return obj;
      });
      console.log(imgsObj);
      setImgsOld(imgsObj);
    };

    // console.log(imgsObj);

    const handleImgChangeCustom = e => {
      console.log(e.target.files);
      let files = [...e.target.files];

      let updatedFiles = [];

      files.forEach((v, i) => {
        let obj = {};
        obj.img = v;
        obj.count = i;
        obj.isLast = false;
        obj.id = uuidv4();
        updatedFiles.push(obj);
      });
      // imgsObj = imgs?.map((v, i) => {
      //   let obj = {};
      //   obj.img = v;
      //   obj.count = i;
      //   obj.isLast = false;
      //   obj.id = uuidv4();
      //   return obj;
      // });
      console.log(updatedFiles);
      setImgs(imgs.concat(updatedFiles));
    };

    const handleImgDeleteCustom = id => {
      let img = imgs.filter(v => v.id === id);

      if (img[0].img instanceof File) {
        let updatedFiles = imgs.filter(v => v.id !== id);

        setImgs(updatedFiles.filter(v => v.img));
      }
    };
    const handleImgOldDeleteCustom = id => {

      let img = imgsOld.filter(v => v.id === id);

      handleSetToDelete(img[0].img);
      let updatedFiles = imgsOld.filter(v => v.id !== id);

      setImgsOld(updatedFiles.filter(v => v.img));
    };

    const handleImgCustomValidation = () => {
      let errors = {};
      imgs?.forEach((v, i) => {
        if (v.img instanceof File) {
          if (v.img.size > 2000000)
            errors[`img__${i}`] = 'Max file limit is 2MB !';
          if (
            v.img.type === 'image/png' ||
            v.img.type === 'image/jpg' ||
            v.img.type === 'image/jpeg'
          ) {
            //do nothing
          } else {
            errors[`img__${i}`] = 'only jpg/png files are accepted !';
          }
        } else {
          // do nothing
        }
      });
      setImgsErrors(errors);
      return errors;
    };
    //end for images

    return (
      <div>
        <Card style={{ minHeight: '230px' }} className="mb-3">
          <FalconCardHeader title="Amenity Images" />
          <Card.Body className="bg-light">
            <Formik
              initialValues={{ email: '', password: '' }}
              validate={() => {
                let errors = handleImgCustomValidation();
                console.log(errors);
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                // setTimeout(() => {
                // alert(JSON.stringify(values, null, 2));
                console.log(values);
                setSubmitting(false);
                updateAmenityImages({
                  toDelete,
                  toUpload: imgs.filter(v => v.img instanceof File)
                });
                // }, 400);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div>
                    <Row>
                      {imgsOld?.map((v, i) => (
                        <Col key={v.id} md={12} className="mb-3 px-4">
                          <div>
                            <div className="d-flex justify-content-md-between align-items-md-center">
                              <img
                                // src={v.img === URL.createObjectURL(v.img)}
                                src={v.img}
                                // src={v}
                                alt={i}
                                className="img-backdrop rounded-3"
                                name={`img__${i}`}
                                width={'150px'}
                              />
                              {/* <CloseButton
                                className="my-dropzone-closebtn1"
                                onClick={() => handleImgDeleteCustom(v.id)}
                              /> */}
                              <Button
                                variant="falcon-danger"
                                onClick={() => handleImgOldDeleteCustom(v.id)}
                                className="ms-3"
                                size="sm"
                              >
                                <CloseButton />
                              </Button>
                            </div>
                            <p className=" ms-1 fs--1 text-danger">
                              {/* {imgsErrors[`img__${i}`]} */}
                              {/* error */}
                            </p>
                          </div>
                        </Col>
                      ))}
                      {imgs?.map((v, i) => (
                        <Col key={v.id + 'jkh'} md={12} className="mb-3 px-4">
                          <div>
                            <div className="d-flex justify-content-md-between align-items-md-center">
                              <img
                                // src={v.img === URL.createObjectURL(v.img)}
                                src={
                                  v.img instanceof File
                                    ? URL.createObjectURL(v.img)
                                    : v.img
                                }
                                // src={v}
                                alt={i}
                                className="img-backdrop rounded-3"
                                name={`img__${i}`}
                                width={'150px'}
                              />
                              {/* <CloseButton
                                className="my-dropzone-closebtn1"
                                onClick={() => handleImgDeleteCustom(v.id)}
                              /> */}
                              <Button
                                variant="falcon-danger"
                                onClick={() => handleImgDeleteCustom(v.id)}
                                className="ms-3"
                                size="sm"
                              >
                                <CloseButton />
                              </Button>
                            </div>
                            <p className=" ms-1 fs--1 text-danger">
                              {imgsErrors[`img__${i}`]}
                              {/* error */}
                            </p>
                          </div>
                        </Col>
                      ))}
                      <div className="px-4">
                        <label className="my-dropzone1 img-backdrop">
                          <input
                            name={`amenity_img`}
                            accept="image/*"
                            type="file"
                            multiple={true}
                            hidden
                            onChange={handleImgChangeCustom}
                          />
                          <span className="my-dropzone-text1">Add Images</span>
                        </label>
                      </div>
                    </Row>
                  </div>

                  {/* <div className="">
                  <label className="my-dropzone1 img-backdrop">
                  <input
                        name={`amenity_img`}
                        accept="image/*"
                        type="file"
                        multiple={true}
                        hidden
                        onChange={handleImgChangeCustom}
                      />
                      <span className="my-dropzone-text1">Add Images</span>
                    </label>
                  </div> */}
                  <br />

                  <div className="text-end mt-3">
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      variant="info"
                    >
                      Update
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </div>
    );
  
}
