
import PaymentsHistory from "../../../../../components/pages/UnitOnwer&Tennent/PaymentsHistory/PaymentsHistory";

const PaymentsReceived = () => {
   return <PaymentsHistory />

  
};

export default PaymentsReceived;
