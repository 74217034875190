import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Avatar from 'components/common/Avatar';
// import Image from './Image';
import SoftBadge from "../../../../components/common/SoftBadge";
// import { Badge, Table, Dropdown } from 'react-bootstrap';
import { Dropdown } from "react-bootstrap";
import { Table } from "react-bootstrap";
// import { Link } from "react-router-dom";
import CardDropdown from "../../../../components/common/CardDropdown";
import { formatLogInfo } from "../../../../helpers/utils";

const TableRow = ({ data, propLength, viewMail, deleteMail }) => {
  const getFormattedMailData = (data = "") => {
    if (data.length) {
      if (data.length > 60) return data.slice(0, 15) + "...";
      else return data;
    } else {
      return "(no subject)";
    }
  };

  return (
    <tr
      className="align-middle"
      style={{ height: propLength === 1 ? "100px" : "" }}
    >
      <td
        className="text-left text-primary cursor-pointer"
        onClick={() => viewMail(data)}
      >
        {/* {data.mail_type || "(no subject)"} */}
        {getFormattedMailData(data.mail_subject)}
      </td>
      <td className="text-center">
        {data.groups?.map((v) => <h6>{v}</h6>) || "-"}
        {data.groups?.length < 1 && "-"}
      </td>

      <td className="text-center">{formatLogInfo(data.createdAt) || "date"}</td>
      <td className="text-center">
        <SoftBadge pill bg={data.status.type}>
          {data.status.title}
          <FontAwesomeIcon icon={data.status.icon} className="mx-2" />
          {data.status.title === "Sent" && (data.sent_percentage || 0) + " %"}
        </SoftBadge>
      </td>
      <td className="text-center">
        <CardDropdown>
          <div className="py-2">
            {data.status.title === "Sent" && (
              <Dropdown.Item
                // className="text-success"
                onClick={() => viewMail(data)}
                // onClick={() => inviteUser(data)}
              >
                View Details
              </Dropdown.Item>
            )}
            {data.status.title === "Draft" && (
              <Dropdown.Item
                className="text-info"
                onClick={() => viewMail(data)}
                // onClick={() => inviteUser(data)}
              >
                Edit
              </Dropdown.Item>
            )}
            {data.status.title !== "Sent" && (
              <Dropdown.Item
                className="text-danger"
                onClick={() => deleteMail(data)}
              >
                Delete
              </Dropdown.Item>
            )}
          </div>
        </CardDropdown>
      </td>
    </tr>
  );
};

const MailOutsTable = ({ properties, viewMail, deleteMail }) => {
  // console.log(properties);

  return (
    <Table
      responsive
      striped
      hover
      bordered
      style={{ fontSize: "14px" }}
      className="pb-5"
    >
      <thead>
        <tr>
          <th scope="col" className="text-center">
            Subject
          </th>
          <th scope="col" className="text-center">
            Sent To
          </th>
          <th scope="col" className="text-center">
            Created On
          </th>
          <th scope="col" className="text-center">
            Status
          </th>
          <th scope="col" className="text-center">
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        {properties?.map((property, index) => (
          <TableRow
            data={property}
            key={property._id}
            propLength={properties.length}
            viewMail={viewMail}
            deleteMail={deleteMail}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default MailOutsTable;
