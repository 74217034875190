import React, { useEffect } from "react";
// import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import logoutImg from "../../../assets/img/icons/spot-illustrations/45.png";
import Cookies from "universal-cookie";
import axios from "axios";
import { toast } from "react-toastify";

const Logout = ({ titleTag: TitleTag }) => {
  // console.log(layout);
  const cookies = new Cookies(); ///Clearing cookies
  let userData = cookies.get("userData");
  const navigate = useNavigate();

  useEffect(() => {
    clearCoolkiesnData();
  }, []);

  const clearTokenCookie = () => {
    axios.post("/api/auth/clearTokenCookie");
  };

  const clearCoolkiesnData = () => {
    if (userData === null || userData === undefined) {
      navigate("/login");
      return;
    }
    if (
      userData?.role === "Site-Administrators" || // manager
      userData?.role === "Condozak-Basic-Users" || // company basic user
      userData?.role === "No Roles Assigned" // company invited user
    )
      navigate("/login");
    if (
      userData?.role === "unit_owner" || // owner
      userData?.role === "none" // invited owner
    )
      navigate("/property/login");

    toast.success("Logout Successful", { theme: "colored" });

    // cookies.remove("authToken", { path: "/" });
    // cookies.remove("email", { path: "/" });
    // cookies.remove("xyz", { path: "/" });

    cookies.remove("token", { path: "/" });
    cookies.remove("userData", { path: "/" });
    // cookies.remove("rp", { path: "/" });
    // cookies.remove("rme", { path: "/" });

    /// cookies.remove('token');
    clearTokenCookie();

    // cookies.remove("buildingDetails");
  };

  return (
    <>
      {/* <img
        className="d-block mx-auto mb-4"
        src={logoutImg}
        alt="shield"
        width={100}
      /> */}
      <TitleTag>See you again!</TitleTag>
      <p>
        Thanks for using Condozak. You are <br className="d-none d-sm-block" />
        now successfully signed out.
      </p>
      <Button
        as={Link}
        color="primary"
        size="sm"
        className="mt-3"
        to={`/login`}
      >
        <FontAwesomeIcon
          icon="chevron-left"
          transform="shrink-4 down-1"
          className="me-1"
        />
        Return to Login
      </Button>
    </>
  );
};

// Logout.defaultProps = {
//   layout: "simple",
//   titleTag: "h4",
// };

export default Logout;
