import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";

export default function OwnersManagementModel({
  show,
  handleClose,
  userData,
  getUsersData,
  payload,
  hostname,
  jwtToken,
}) {
  const adduser = (values) => {
    let data = { ...values };
    data.invitee = userData.firstname;
    data.prop_id = userData.propid;
    data.unit_id = payload.unit_id;
    data.email = data.email?.toLowerCase();

    data.inviteeL = userData.lastname;
    console.log(data);
    // return;
    axios
      .post(
        `${hostname}/api/owner/AddResidents`,
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.success("User Added", { theme: "colored" });
        getUsersData();
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.error || "something went wrong!", {
          theme: "colored",
        });
      });
  };

  const reinviteUser = (values) => {
    // console.log(values);

    let obj = {
      email: values.email?.toLowerCase(),
      invite_id: payload._id,
      invitee: payload.invitee,
      group: values.userGroup,
      prop_id: payload.prop_id,
      unit_id: payload.unit_id,
    };

    console.log(obj);

    // return;

    axios
      .post(
        `${hostname}/api/owner/ReinviteResidents`,
        {
          ...obj,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.success("Invitation sent", { theme: "colored" });
        getUsersData();
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.error || "something went wrong!", {
          theme: "colored",
        });
      });
  };

  const revokeUser = () => {
    console.log(payload);
    axios
      .post(
        `${hostname}/api/owner/RevokeResidentAccess`,
        {
          group: payload.group,
          resident_id: payload._id,
          unit_id: payload.unit_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.success("User Access Revoked!", { theme: "colored" });
        getUsersData();
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        if ("response" in err) {
          toast.error(err.response.data.error || "something went wrong!", {
            theme: "colored",
          });
        } else {
          toast.error("something went wrong!", {
            theme: "colored",
          });
        }
      });
  };

  const deleteInvitedUser = () => {
    console.log(payload);
    axios
      .post(
        `${hostname}/api/owner/DeleteResidentInvite`,
        {
          group: payload.group,
          // resident_id: payload._id,
          unit_id: payload.unit_id,

          email: payload.email,
          invitee: payload.invitee,
          prop_id: payload.prop_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.warning("User is Deleted!", { theme: "colored" });
        getUsersData();
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        if ("response" in err) {
          toast.error(err.response.data.error || "something went wrong!", {
            theme: "colored",
          });
        } else {
          toast.error("something went wrong!", {
            theme: "colored",
          });
        }
      });
  };

  const reactivateUser = () => {
    console.log(payload);
    axios
      .post(
        `${hostname}/api/owner/ReactivateResidentAccess`,
        {
          group: payload.group,
          resident_id: payload._id,
          unit_id: payload.unit_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.info("User Reactivated!", { theme: "colored" });
        getUsersData();
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        if ("response" in err) {
          toast.error(err.response.data.error || "something went wrong!", {
            theme: "colored",
          });
        } else {
          toast.error("something went wrong!", {
            theme: "colored",
          });
        }
      });
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{payload.loadType}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {payload.loadType === "Add Resident" && (
            <Formik
              initialValues={{ email: "", group: "" }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .email("invalid email!")
                  .required("email is required!"),
                group: Yup.string().required("please select resident group"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                adduser(values);
                setSubmitting(false);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="mb-3 px-3">
                    <label>Email Address</label>
                    <Field
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="email here..."
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-danger fs--1"
                    />
                  </div>
                  <div className="mb-4 px-3">
                    <label>Group</label>
                    <Field
                      component="select"
                      name="group"
                      className="form-select"
                    >
                        {/* ABDULLAH*/}
                      <option>select</option>
                      <option value="Supplementary">Supplementary Owner</option>
                      <option value="Supplementary Tenant">
                        Supplementary Tenant
                      </option>
                      {/* <option value="Tenant">
                        Tenant
                      </option> */}
                    </Field>
                    <ErrorMessage
                      name="group"
                      component="div"
                      className="text-danger fs--1"
                    />
                  </div>
                  <div className="text-end px-3">
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Add
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
          {payload.loadType === "Deactivate User" && (
            <>
              <p className="" style={{ fontSize: "15px" }}>
                Are you sure you want to revoke access for this user ?
              </p>
              <div className="text-end">
                <Button
                  variant="falcon-warning"
                  className="me-3 "
                  size="md"
                  onClick={revokeUser}
                  style={{ width: 80 }}
                >
                  Yes
                </Button>
                <Button
                  variant="falcon-default"
                  size="md"
                  onClick={handleClose}
                  style={{ width: 80 }}
                >
                  No
                </Button>
              </div>
            </>
          )}
          {payload.loadType === "Activate User" && (
            <>
              <p className="" style={{ fontSize: "15px" }}>
                Are you sure you want to grant access for this user ?
              </p>
              <div className="text-end">
                <Button
                  variant="primary"
                  className="me-3 "
                  size="md"
                  onClick={reactivateUser}
                  style={{ width: 80 }}
                >
                  Yes
                </Button>
                <Button
                  variant="falcon-default"
                  size="md"
                  onClick={handleClose}
                  style={{ width: 80 }}
                >
                  No
                </Button>
              </div>
            </>
          )}
          {payload.loadType === "Delete Invited User" && (
            <>
              <p className="" style={{ fontSize: "15px" }}>
                Are you sure you want to delete this user ?
              </p>
              <div className="text-end">
                <Button
                  variant="falcon-warning"
                  className="me-3 "
                  size="md"
                  onClick={deleteInvitedUser}
                  style={{ width: 80 }}
                >
                  Yes
                </Button>
                <Button
                  variant="falcon-default"
                  size="md"
                  onClick={handleClose}
                  style={{ width: 80 }}
                >
                  No
                </Button>
              </div>
            </>
          )}
          {payload.loadType === "Change Owner" && (
            <Formik
              initialValues={{ groupOfUser: "" }}
              validate={(values) => {
                const errors = {};
                if (!values.groupOfUser)
                  errors.groupOfUser = "user group is required!";
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                // changeUserGroup(values);
                setSubmitting(false);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div>
                    <label>Group Name</label>
                    <Field
                      component="select"
                      id="groupOfUser"
                      name="groupOfUser"
                      className="form-select"
                    >
                      <option value="Site-Administrators">
                        Site-Administrators
                      </option>
                      <option value="Condozak-Basic-Users">
                        Condozak-Basic-Users
                      </option>
                    </Field>
                    <ErrorMessage
                      name="groupOfUser"
                      component="div"
                      className="text-danger fs--1"
                    />
                  </div>
                  <br />
                  <div className="text-end">
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Change
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
          {payload.loadType === "Reinvite User" && (
            <Formik
              initialValues={{
                email: payload.email,
                userGroup: payload.group || "",
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Must be a valid email")
                  .max(255)
                  .required("Email is required"),
                userGroup: Yup.string().required("user group is required!"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                reinviteUser(values);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <label>
                    Email Address <span className="text-danger">*</span>
                  </label>
                  <Field type="email" name="email" className="form-control" />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger m-2 fs--1"
                  />
                  <br />
                  <label>
                    User Group <span className="text-danger">*</span>
                  </label>
                  <Field
                    component="select"
                    id="userGroup"
                    name="userGroup"
                    // multiple={true}
                    className="form-select"
                  >
                    <option value="">Select Group</option>
                    <option value="Supplementary">Supplementary Owner</option>
                    <option value="Supplementary Tenant">
                      {/* ABDULLAH*/}
                      Supplementary Tenant
                    </option>
                  </Field>
                  <ErrorMessage
                    name="userGroup"
                    component="div"
                    className="text-danger m-2 fs--1"
                  />
                  <br />
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-primary float-end"
                  >
                    Send
                  </button>
                </Form>
              )}
            </Formik>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
